import React from 'react';
import { connect } from 'react-redux';
import { HubContent } from '../../../../../core/components';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { DataTable } from '../../../../../core/components';
import { Loading } from '../../../../../core/components/common';


class ApcFund extends BaseComponent {
    state = {
        data: null,
    }

    componentDidMount() {
        // setTimeout is for test loader before implementing API
        setTimeout(() => {
            this.setState({
                data: [],
            });
        }, 2000);
    }
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();

        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        // }
    };
    render() {
        const data = [];
        this.state.data && this.state.data.map(item => {
            return data.push({
                id: item.id,

            })
        });

        const heading = ['Fund source', 'Name', 'Currency', 'Amount', 'Date', 'Added by']

        return (
            <HubContent title="Apc fund list" onArticleDownloadClickHandler={this.onArticleDownloadClick}
            >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            {this.state.data ?
                                <DataTable
                                    heading={heading}
                                    data={data}
                                    noAction={true}
                                    count={this.state.count}
                                    pageChange={this.pageChange}
                                    pageSize={10}
                                /> : <Loading type="flat" />
                            }
                        </div>
                    </div>
                </div>

            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export const ApcFundList = connect(mapStateToProps, mapDispatchToProps)(ApcFund)