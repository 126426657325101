import React from "react";
import { Select } from "antd";

import { Button } from "../../../core/components/common";

const { Option } = Select;

class ApcViewForm extends React.Component {
  state = {
    data: this.props.data,
  };

  onCloseModal = () => {
    this.props.onClose();
  };

  putThousandsSeparators = (amount, sep) => {
    if (sep == null) {
      sep = ",";
    }
    // check if it needs formatting
    if (amount.toString() === amount.toLocaleString()) {
      // split decimals
      var parts = amount.toString().split(".");
      // format whole numbers
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep);
      // put them back together
      amount = parts[1] ? parts.join(".") : parts[0];
    } else {
      amount = amount.toLocaleString();
    }
    return amount;
  };

  onChangeHandler = (value) => {
    this.setState({
      submission_currency: this.state.data.submission_charge
        ? this.putThousandsSeparators(
            parseFloat(this.state.data.submission_charge[value]).toFixed(2)
          )
        : null,
      color_currency: this.state.data.color_charge
        ? this.putThousandsSeparators(
            parseFloat(this.state.data.color_charge[value]).toFixed(2)
          )
        : null,
      total_price: this.state.data.price_total
        ? this.putThousandsSeparators(
            parseFloat(this.state.data.price_total[value]).toFixed(2)
          )
        : null,
      page_charge_currency: this.state.data.page_charge
        ? this.putThousandsSeparators(
            parseFloat(this.state.data.page_charge[value]).toFixed(2)
          )
        : null,
      pub_fee_currency: this.state.data.pub_fee
        ? this.putThousandsSeparators(
            parseFloat(this.state.data.pub_fee[value]).toFixed(2)
          )
        : null,
      other_charge_currency: this.state.data.other_charge
        ? this.putThousandsSeparators(
            parseFloat(this.state.data.other_charge[value]).toFixed(2)
          )
        : null,
    });
  };

  render() {
    const {
      submission_currency,
      color_currency,
      total_price,
      page_charge_currency,
      pub_fee_currency,
      other_charge_currency,
    } = this.state;
    const { isShow, data, article_type } = this.props;
    return isShow ? (
      <div className="apc-eligibilty-model" style={modal.overlay}>
        <div className={`col-sm-8 bg-white`} style={modal.boxStyle}>
          <div className="row">
            <div className="col-md-12">
              <div
                className="email-view-area p-10"
                style={{ paddingTop: 20, paddingLeft: 50, paddingRight: 50 ,marginBottom:30}}
              >
                <div className="apc-publisher-header">
                  <div className="apc-logo">
                    <img
                      src="https://image.flaticon.com/icons/svg/2411/2411002.svg"
                      alt=""
                    />
                  </div>
                  <div className="publisher-address">
                    <label>{data.publisher.name}</label>
                    <p>{data.publisher.address.address_line1}</p>
                    <p>{data.publisher.address.address_line2}</p>
                  </div>
                  <div className="vat-tax-button email">
                    <Button
                      type="danger"
                      name="danger"
                      text="X"
                      onClick={() => this.onCloseModal()}
                    />
                  </div>
                </div>

                <div className="apc-publisher-content">
                  <div className="apc-journal">
                    <h4>{data.journal.name}</h4>
                    <ul>
                      <li>
                        <strong>pISSN: </strong>
                        {data.journal.pissn}
                      </li>
                      <li>
                        <strong>eISSN: </strong> {data.journal.eissn}
                      </li>
                      <li>
                        <strong>Journal Type: </strong>
                        {data.journal.journal_type}
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="apc-publisher-price"
                  style={{ marginBottom: 15 }}
                >
                  <div
                    className="single-apc-price"
                    style={{ marginBottom: 15 }}
                  >
                    {data.price_total && (
                      <div className="price-currency">
                        <label>Select currency:</label>
                        <div className="apc-currency pricelist">
                          <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={this.onChangeHandler}
                            defaultValue="GBP"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="GBP">GBP</Option>
                            <Option value="EUR">EUR</Option>
                            <Option value="USD">USD</Option>
                          </Select>
                        </div>
                      </div>
                    )}
                  </div>
                  {data.pub_fee && (
                    <div className="single-apc-price">
                      <div className="price-details">
                        <label>
                          Estimated author publishing charge (APC) for{" "}
                          {article_type} excluding any VAT or Tax
                        </label>
                      </div>
                      <div className="apc-currency" style={{ marginTop: 10 }}>
                        {pub_fee_currency
                          ? pub_fee_currency
                          : this.putThousandsSeparators(
                              parseFloat(data.pub_fee.GBP).toFixed(2)
                            )}
                      </div>
                    </div>
                  )}
                  {data.submission_charge && (
                    <div className="single-apc-price">
                      <div className="price-details">
                        <label>Submission Charge</label>
                      </div>
                      <div className="apc-currency">
                        <p>
                          {submission_currency
                            ? submission_currency
                            : this.putThousandsSeparators(
                                parseFloat(data.submission_charge.GBP).toFixed(
                                  2
                                )
                              )}
                        </p>
                      </div>
                    </div>
                  )}
                  {data.color_charge && (
                    <div className="single-apc-price">
                      <div className="price-details">
                        <label>Colour Charge</label>
                      </div>
                      <div className="apc-currency">
                        <p>
                          {color_currency
                            ? color_currency
                            : this.putThousandsSeparators(
                                parseFloat(data.color_charge.GBP).toFixed(2)
                              )}
                        </p>
                      </div>
                    </div>
                  )}
                  {data.page_charge && (
                    <div className="single-apc-price">
                      <div className="price-details">
                        <label>Page Charge</label>
                      </div>
                      <div className="apc-currency">
                        <p>
                          {page_charge_currency
                            ? page_charge_currency
                            : this.putThousandsSeparators(
                                parseFloat(data.page_charge.GBP).toFixed(2)
                              )}
                        </p>
                      </div>
                    </div>
                  )}

                  {data.other_charge && (
                    <div className="single-apc-price">
                      <div className="price-details">
                        <label>Other Charge</label>
                      </div>
                      <div className="apc-currency">
                        <p>
                          {other_charge_currency
                            ? other_charge_currency
                            : this.putThousandsSeparators(
                                parseFloat(data.other_charge.GBP).toFixed(2)
                              )}
                        </p>
                      </div>
                    </div>
                  )}

                  {data.price_total && (
                    <div className="single-apc-price">
                      <div className="apc-total-price">
                        <label>Total</label>
                        <p>
                          {total_price
                            ? total_price
                            : this.putThousandsSeparators(
                                parseFloat(data.price_total.GBP).toFixed(2)
                              )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="apc-message" style={{ marginTop: 10 }}>
                  {data.deal_message && (
                    <p>
                      <span>APC Eligibility:</span>
                      {data.deal_message}
                    </p>
                  )}
                  {data.geo_message && (
                    <p>
                      <span>Geographical Discount:</span>
                      {data.geo_message}
                    </p>
                  )}

                  {data.society_discount_message && (
                    <p>
                      <span>Society Discount:</span>
                      {data.society_discount_message}
                    </p>
                  )}
                  {data.waive_message && (
                    <p>
                      <span>APC Waiver:</span>
                      {data.waive_message}
                    </p>
                  )}

                  {data.publisher_contact_message && (
                    <p>
                      <span>Contact:</span>
                      {data.publisher_contact_message}
                    </p>
                  )}
                </div>
                <div className="apc-close" style={{ marginTop: 20 }}>
                  <Button
                    type="secondary"
                    name="secondary"
                    text="Close"
                    onClick={() => this.onCloseModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0, 10px)",
    height: boxHeight,
  },
};

export const ApcEligibilityForm = ApcViewForm;
