import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import {
  SkeletonForm,
  PopupBoxTable,
  ExpandHeadingTable,
} from "../../../../../../core/components/common";
import { ClearStatus, singleJournal } from "../../../../../actions";
import { api } from "../../../../../../core/api";
import JournalPrice from "./JournalPrice";
import { journalPriceHeading, column } from "../../../../../../core/lib";
class JournalView extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    user: {},
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
    journal: this.props.journal,
  };

  getSingle = (id) => {
    this.setState({
      loader: true,
    });
    api
      .get("publication/" + id + "/")
      .then(
        (res) => {
          this.setState(
            {
              journalList: res,
            },
            function () {
              this.setState({
                loader: false,
              });
            }
          );
        },
        (error) => {
          this.setState({
            loader: false,
          });
        }
      )
      .catch();
  };

  componentDidMount() {
    this.getSingle(this.props.match.params.id);
  }
  componentWillUnmount() {
    this.getSingle(this.props.match.params.id);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getSingle(this.props.match.params.id);
    }
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };
  renderListItems = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <p className="oadeal-table-view" key={ item.id}> {item.name}</p>
      ));
    }

    return "";
  };
  render() {
    const journalPrices = [];
    this.state.journalList &&
      this.state.journalList.prices.map((item) =>
        journalPrices.push({
          ...item,
          id: item.id,
          content_type_name: item.content_type_name,
          APC_fee_eur: item.pub_fee_eur,
          APC_fee_gbp: item.pub_fee_gbp,
          APC_fee_usd: item.pub_fee_usd,
        })
      );
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBoxTable
            Title="Following field cannot be empty"
            prices={[this.state.singlePrices]}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return (
      <HubContent
        title={
          this.state.journalList ? (
            this.state.journalList.name
          ) : (
            <SkeletonForm rows={1} />
          )
        }
      >
        {errorHander}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body profile">
              <button
                    type="danger"
                    className="btn btn-danger float-right "
                    onClick={() => this.props.history.goBack()}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    X

</button>            
                {this.state.journalList ? (
                  <div className="row">
                    <div className="col-md-4 ">
                      <label className="oadeal-view">Publisher </label>
               

<p className="col-md-12">
                    {  this.state.journalList.publisher_name}
                      </p>
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">Journal type </label>
         
<p className="col-md-12">
                    {  this.state.journalList.journal_type_name}
                      </p>
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">Publication type </label>
              
                               <p className="col-md-12">
                    {  this.state.journalList.publication_type_name}
                      </p>
                   
                    </div>
                    <div className="col-md-4">
                      <label className="oadeal-view">Journal </label>
               
                              <p className="">
                    {  this.state.journalList.name}
                      </p>
                   
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view"> Owner</label>
                 
                             <p className="col-md-12">
                    {  this.state.journalList.owner_name}
                      </p>
                   
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view"> Acronym </label>
                   
                            <p className="col-md-12">
                    {  this.state.journalList.pub_acronym}
                      </p>
                   
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">Site</label>
                  
                          <p className="col-md-12">
                    {  this.state.journalList.sub_sys_acronym}
                      </p>
                   
                     
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">pISSN/pISBN </label>
                      <p className="col-md-12">
                    {  this.state.journalList.pissn}
                      </p>
                   
                     
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">eISSN/eISBN</label>
                      <p className="col-md-12">
                    {  this.state.journalList.eissn}
                      </p>
                   
                    </div>
                    <div className="col-md-4">
                      <label className="oadeal-view">Disciplines</label>
                      {this.state.journalList &&
                      this.state.journalList.disciplines_list.length > 0
                        ? this.renderListItems(
                            this.state.journalList.disciplines_list
                          )
                        : `All disciplines`}
                    </div>
                    <div className="col-md-4">
                      <label className="oadeal-view">Subjects </label>
                      {this.state.journalList &&
                      this.state.journalList.subjects_list.length > 0
                        ? this.renderListItems(
                            this.state.journalList.subjects_list
                          )
                        : `All subjects`}
                    </div>

                    <div className="col-md-4 ">
                      <label className="oadeal-view">Editor Manager</label>
                      <p className="col-md-12">
                    {  this.state.journalList.editor_manager}
                      </p>
                     
                    </div>

                    <div className="col-md-4 ">
                      <label className="oadeal-view">Editor Support</label>
                      <p className="col-md-12">
                    {  this.state.journalList.editor_support}
                      </p>
                     

                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">Editor Co-Support</label>
                      <p className="col-md-12">
                    {  this.state.journalList.editor_co_support}
                      </p>
                     
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">IF </label>
                      <p className="col-md-12">
                    {  this.state.journalList.impact_factor}
                      </p>
                     
                    
                    </div>

                    <div className="col-md-4 ">
                      <label className="oadeal-view">Indexed</label>
                      <p className="col-md-12">
                    {  this.state.journalList.indexed}
                      </p>
                     
                    
                    
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">H-index</label>
       
                                  <p className="col-md-12">
                    {  this.state.journalList.hindex}
                      </p>
                     
                    
                    </div>
                    <div className="col-md-4 ">
                      <label className="oadeal-view">Status</label>

                      <p className="col-md-12">
                    {  this.state.journalList.status}
                      </p>
                     
                    </div>
                  </div>
                ) : (
                  <SkeletonForm rows={4} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div
                id="collapse1"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul
                        className="nav nav-tabs nav-tabs-custom nav-justified"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#main-info-1"
                            role="tab"
                          >
                            Prices
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#other-info-1"
                            role="tab"
                          >
                            Other
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane active p-3"
                          id="main-info-1"
                          role="tabpanel"
                        >
                          <ExpandHeadingTable
                            heading={journalPriceHeading}
                            column={column}
                          />

                          {this.state.journalList &&
                          this.state.journalList.prices
                            ? this.state.journalList &&
                              this.state.journalList.prices.map(
                                (item, index) => (
                                  <div id="accordion" key={item.id}>
                                    <JournalPrice
                                      key={index}
                                      item={item}
                                      column={column}
                                    />
                                  </div>
                                )
                              )
                            : null}
                        </div>
                        <div
                          className="tab-pane p-3"
                          id="other-info-1"
                          role="tabpanel"
                        >
                          Other Info
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.user.failed || false,
  journal: state.journals.single_journal || false,
});

const mapDispatchToProps = (dispatch) => ({
  ClearStatus: () => dispatch(ClearStatus()),
  singleJournal: (id) => dispatch(singleJournal(id)),
});

export const SingleJournal = connect(mapStateToProps, mapDispatchToProps)(JournalView);
