import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { ViewRow } from "../../../common";
import {
    ClearStatus,
    UpdateUser,
    singleOrg,
    getVatConfig
} from "../../../../actions";
import { PopupBox, Loading } from "../../../../../core/components/common";
import CustomInvoiceTemplate from "./CustomInvoiceTemplate";
import Licence from "./getLicenselist";
import { VATNumberForm } from "./VATNumberForm";
import App from "./App";
import { api } from "../../../../../core/api";
class Add extends BaseComponent {
    constructor(props) {
        super(props);
        this.error = props.failed;
        this.status = 100;
    }
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        showAddForm: false,
        isEmpty: false
    };

    getSingle = id => {
        this.setState({
            loader: true
        });
        api.get("organisation/" + id + "/")
            .then(
                res => {
                   
                    this.setState(
                        {
                            org: res
                        },
                        function() {
                            this.setState({
                                loader: false
                            });
                        }
                    );
                },
                error => {
                    this.setState({
                        loader: false
                    });
                }
            )
            .catch();
    };

    async componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            this.getSingle(id);
        }

        this.getVatConfig();
    }

    componentDidUpdate() {
        this.state.msg &&
            setTimeout(() => {
                this.setState({
                    msg: false
                });
            }, 2000);

        // this.props.getSingleUser(userId)

        if (this.props.failed) {
            if (this.props.failed.status === 500 && this.state.status !== 500) {
                this.setState({
                    status: 500
                });
            } else if (
                this.props.failed.status === 400 &&
                this.state.status !== 400
            ) {
                this.setState({
                    status: 400
                });
            }
        }
    }

    getVatConfig = async () => {
        await this.props.getVatConfig();
        this.props.vat_config_list &&
            this.setState({
                vat_config_list: this.props.vat_config_list.results
            });
    };

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false,
            showAddForm: false
        });
        await this.props.ClearStatus();
    };

    render() {
        let { vat_config_list } = this.state;

        let errorHander = (
            <>
                {this.state.status === 400 && (
                    <PopupBox
                        title="Invalid form"
                        msg="You submitted the form is not valid. Please Check Your Form and Try again"
                        onCancel={this.cancelLoader}
                    />
                )}
                {this.state.status === 500 && (
                    <PopupBox
                        title="Network Error Found"
                        msg="Please Check Internet Connection and Try Again"
                        onCancel={this.cancelLoader}
                    />
                )}

                {this.state.isEmpty && (
                    <PopupBox
                        Title="Following field cannot be empty"
                        msg={this.state.isEmpty}
                        onCancel={this.cancelLoader}
                    />
                )}

                {this.state.msg && (
                    <PopupBox
                        Title={this.state.msg}
                        msg={this.state.msg}
                        onCancel={this.cancelLoader}
                    />
                )}
            </>
        );
        return (
            <HubContent title={this.state.org ? this.state.org.name : ""}>
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                {/* LoadinG Handling */}
                {!this.state.org && <Loading />}
                {/* LoadinG Handling */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <div className="useremail-right licence">
                                    {this.props.group === "INSTITUTION" ? (
                                        <ul
                                            className="nav nav-pills nav-justified"
                                            role="tablist"
                                        >
                                            <li className="nav-item waves-effect waves-light">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="tab"
                                                    href="#home-profile"
                                                    role="tab"
                                                >
                                                    Profile
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#home-vat"
                                                    role="tab"
                                                >
                                                    VAT
                                                </a>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul
                                            className="nav nav-pills nav-justified"
                                            role="tablist"
                                        >
                                            <li className="nav-item waves-effect waves-light">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#home-profile"
                                                    role="tab"
                                                >
                                                    Profile
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#home-vat"
                                                    role="tab"
                                                >
                                                    VAT
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#home-invoice"
                                                    role="tab"
                                                >
                                                    Invoice
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#home-licence"
                                                    role="tab"
                                                >
                                                    Licence
                                                </a>
                                            </li>
                                            <li className="nav-item waves-effect waves-light">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href="#app"
                                                    role="tab"
                                                >
                                                    Apps
                                                </a>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <div className="tab-content">
                                    <div
                                        className="tab-pane active p-3"
                                        id="home-profile"
                                        role="tabpanel"
                                    >
                                        <ViewRow
                                            label="Group"
                                            value={
                                                this.state.org
                                                    ? this.state.org
                                                          .dommain_name
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Organization Name"
                                            value={
                                                this.state.org
                                                    ? this.state.org.name
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Parent Organization"
                                            value={
                                                this.state.org
                                                    ? this.state.org.parent_name
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Name in other language one"
                                            value={
                                                this.state.org
                                                    ? this.state.org.meta &&
                                                      this.state.org.meta
                                                          .name_1_other_lang
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Name in other language two"
                                            value={
                                                this.state.org
                                                    ? this.state.org.meta &&
                                                      this.state.org.meta
                                                          .name_2_other_lang
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Country"
                                            value={
                                                this.state.org
                                                    ? this.state.org.address &&
                                                      this.state.org.address
                                                          .country_name
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="State"
                                            value={
                                                this.state.org
                                                    ? this.state.org.address &&
                                                      this.state.org.address
                                                          .state
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="City"
                                            value={
                                                this.state.org
                                                    ? this.state.org.address &&
                                                      this.state.org.address
                                                          .city_name
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Address 1"
                                            value={
                                                this.state.org
                                                    ? this.state.org.address &&
                                                      this.state.org.address
                                                          .address1
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Address 2"
                                            value={
                                                this.state.org
                                                    ? this.state.org.address &&
                                                      this.state.org.address
                                                          .address2
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Post/Zip Code"
                                            value={
                                                this.state.org
                                                    ? this.state.org.address &&
                                                      this.state.org.address
                                                          .postal_code
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Email Domain"
                                            value={
                                                this.state.org
                                                    ? this.state.org
                                                          .email_domain
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Website"
                                            value={
                                                this.state.org
                                                    ? this.state.org.website
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="VAT"
                                            value={
                                                this.state.org
                                                    ? this.state.org.vat_number
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="GRID NO."
                                            value={
                                                this.state.org
                                                    ? this.state.org.meta &&
                                                      this.state.org.meta
                                                          .grid_number
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Ringgold No."
                                            value={
                                                this.state.org
                                                    ? this.state.org.meta &&
                                                      this.state.org.meta
                                                          .ring_gold_number
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="ISNI No."
                                            value={
                                                this.state.org
                                                    ? this.state.org.meta &&
                                                      this.state.org.meta
                                                          .isni_number
                                                    : ""
                                            }
                                        />
                                        <ViewRow
                                            label="Note"
                                            value={
                                                this.state.org
                                                    ? this.state.org.note
                                                    : ""
                                            }
                                        />
                                    </div>
                                    <div
                                        className="tab-pane p-3"
                                        id="home-vat"
                                        role="tabpanel"
                                    >
                                        {vat_config_list &&
                                            vat_config_list.length && (
                                                <VATNumberForm
                                                    data={vat_config_list[0]}
                                                />
                                            )}

                                        {!(
                                            vat_config_list &&
                                            vat_config_list.length
                                        ) ? (
                                            <VATNumberForm />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div
                                        className="tab-pane p-3"
                                        id="home-invoice"
                                        role="tabpanel"
                                    >
                                        <CustomInvoiceTemplate />
                                    </div>
                                    <div
                                        className="tab-pane p-3"
                                        id="home-licence"
                                        role="tabpanel"
                                    >
                                        <Licence />
                                    </div>
                                    <div
                                        className="tab-pane p-3"
                                        id="app"
                                        role="tabpanel"
                                    >
                                        <App />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = state => ({
    failed: state.user.failed || false,
    getUser: userId => {
        return state.user.users
            ? state.user.users.find(user => {
                  return user.id.toString() === userId.toString() && user;
              })
            : false;
    },
    demo_state: "demo",
    org: state.orgs.org_single || false,
    group: (state.auth && state.auth.group) || false,
    vat_config_list: state.vat.vat_config_list || false,
    
});

const mapDispatchToProps = dispatch => ({
    UpdateUser: payload => dispatch(UpdateUser(payload)),
    ClearStatus: () => dispatch(ClearStatus()),
    singleOrg: id => dispatch(singleOrg(id)),
    getVatConfig: () => dispatch(getVatConfig())
});

export const SingleOrg = connect(
    mapStateToProps,
    mapDispatchToProps
)(Add);
