import React from 'react';
import BaseComponent from '../../../../../core/components';
import { Card } from '../../../../../core/components/common';


const styles = {
  th: {
    paddingTop: 15,
    paddingBottom: 15,
    fontWeight: 900
  },
  td: {
    paddingTop: 15,
    paddingBottom: 15,
  }
}

class APC extends BaseComponent {

  state = {
    error: null,
    errorInfo: null,
    showpop:false,
    onClose:false
  }
    HandleClick = ()=>{
    this.setState({
      showpop:!this.state.showpop,
      onClose:!this.state.onClose
    })
  }
   HandleOnClose = ()=>{
    this.setState({
      showpop:false,
      onClose:false
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }
  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  capitalize(s)
  {
    return s[0].toUpperCase() + s.slice(1);
  }

  renderTableBody = (data) => (
    <tbody>
      {Object.keys(data).map((key) => {
        return (
          <tr key={key}>
            <td style={styles.td}>{this.capitalize(String(key.replace(/_/g, ' ')))}</td>
            <td style={styles.td}>{this.numberWithCommas(parseFloat(data[key]['gbp']).toFixed(2))}</td>
            <td style={styles.td}>{this.numberWithCommas(parseFloat(data[key]['eur']).toFixed(2))}</td>
            <td style={styles.td}>{this.numberWithCommas(parseFloat(data[key]['usd']).toFixed(2))}</td>
          </tr>
        )
      })}
    </tbody>
  )

  render() {
    let { data } = this.props;
        
    if (this.state.errorInfo) {
      return <Card
        title="Something Error"
        className={this.props.className || "col-md-4"}
        height={this.props.height || 358}
      />
    } else {
      return (
        !data ? <Card
          title="Fetching..."
          className={this.props.className || "col-md-4"}
        /> :
          <Card
            title="APC"
            onClick={(e)=>this.HandleClick(e)}
            showpop={this.state.showpop}
            onClose={(e)=>this.HandleClick(e)}
                  showtitle={this.props.showtitle}
                titleIcon={this.props.titleIcon}
            className={this.props.className || "col-md-4"}
            height={this.props.height || 358}
          >
            <div className="morris-chart-height">
              <table className="table table-hover mb-0 mb-0">
                <thead>
                  <tr>
                    <th style={styles.th}></th>
                    <th style={styles.th}>£</th>
                    <th style={styles.th}>€</th>
                    <th style={styles.th}>$</th>
                  </tr>
                </thead>
                {this.renderTableBody(data)}
              </table>
            </div>
          </Card>
      );
    }
  }
}

export default APC;