import React from "react";
import { HubContent } from "../../../../../core/components";
import { connect } from "react-redux";
import {
  createDashboardDataControl,
  getDashboardDataControl,
  getGroupList,
  getOrgList,
  getDashboardDataControlSingle,
} from "../../../../actions";
import { Checkbox } from "antd";

let dashboard_control = {
  allocated_offset_component: {
    label: "Allocated Offset",
  },
  article_summary_component: {
    label: "Article summary",
  },
  pub_available_fund_component: {
    label: "Pub available fund",
  },
  available_offset_component: {
    label: "Available offset",
  },
  pub_deposit_component: {
    label: "Pub deposit",
  },
  oa_income_component: {
    label: "Oa income",
  },
  oa_deal_list_component: {
    label: "Oa deal list",
  },
  spent_offset_component: {
    label: "Spent offset",
  },
  token_summary_component: {
    label: "Token summary",
  },
  apc_revenue_compare_component: {
    label: "Apc revenue compare",
  },
  number_of_articles_compare_component: {
    label: "Number of articles compare",
  },
  number_of_days_compare_component: {
    label: "Number of days compare",
  },
  read_and_publish_deal_component: {
    label: "Read and publish deal",
  },
  publisher_new_deals_component: {
    label: "Publisher new deals",
  },
  credit_pending_component: {
    label: "Credit pending",
  },
  credit_payment_component: {
    label: "Credit payment",
  },
  book_summary_component: {
    label: "Book summary",
  },
  publisher_funder_component: {
    label: "Publisher funder",
  },
  publisher_institution_component: {
    label: "Publisher institution",
  },
  publisher_journal_component: {
    label: "Publisher journal",
  },
  publisher_content_type_component: {
    label: "Publisher content type",
  },
  estimated_income_summary_component: {
    label: "Estimated income summary",
  },
  publisher_payment_invoice_component: {
    label: "Publisher payment invoice",
  },
  oa_inst_membership_component: {
    label: "Oa inst membership",
  },
};

class DashboardControlViewUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      permissionValue: Object.entries(dashboard_control).map(
        ([key, value]) => key
      ),
      groupKey: Object.entries(dashboard_control).map(([key, value]) => ({
        label: value.label,
        key: key,
      })),
    };
  }
  async componentDidMount() {
    if (this.props.match.params.id) {
      let data = await this.props.getDashboardDataControlSingle(
        this.props.match.params.id
      );
      this.setState({
        organisation: data.organisation,
        group: data.group,
        checkedList: Object.entries(data.components).map(([key, value]) => key),
      });
    }
    this.props.user &&
      this.setState(
        {
          user: this.props.user,
        },
        async () => {
          const org =
            this.state.user.group &&
            (await this.props.getOrgList({ group: this.state.user.group }));
          org &&
            org.status &&
            this.setState((prevState) => {
              prevState.orgStatus = true;
              return prevState;
            });
        }
      );

    if (
      this.props.group !== "INSTITUTION" &&
      this.props.group !== "PUBLISHER"
    ) {
      this.props.getGroupList();
    }
  }
  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.user, value);
    });
  };

  // using this event handler
  onChangeGroupHandler = async (value) => {
    this.setState((prevState) => {
      prevState.user = Object.assign(prevState.user, value);
      return prevState;
    });
    const org = value.group && (await this.props.getOrgList(value));
    org &&
      org.status &&
      this.setState((prevState) => {
        prevState.orgStatus = true;
        return prevState;
      });
  };

  onChange = (checkedList) => {
    this.setState({
      checkedList,
    });
  };

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? this.state.permissionValue : [],
    });
  };

  onCheckItem = (value) => (e) => {
    this.setState({
      checkedList: this.state.checkedList.includes(value)
        ? this.state.checkedList.filter((x) => x !== value)
        : [...this.state.checkedList, value],
    });
  };
  onSubmitHandler = () => {
    let mainData = Object.fromEntries(
      this.state.checkedList && this.state.checkedList.map((key) => [key, {}])
    );
    let data = {
      organisation: this.state.user.organisation,
      components: mainData,
    };

    if (this.props.match.params.id) {
    }
    this.props.createDashboardDataControl(data);
  };

  render() {
    const { checkedList } = this.state;

    return (
      <HubContent>
        <div className="user-permission-content dashboard-control">
          <div className="form-group row">
            {/* <SelectBox
              label="Group"
              onChange={this.onChangeGroupHandler}
              field="group"
              data={this.props.groups}
              isRequired={true}
              // defaultValue={user ? user.group : ""}
            />
            <SelectBox
              label="Organisation"
              onChange={this.onChangeHandler}
              field="organisation"
              data={this.props.orgs || ["Loading"]}
              isRequired={true}
              onRestFields={this.onChangeEmailDomain}
              defaultValue={this.state.user ? this.state.user.organisation : ""}
              isData={true}
            /> */}
          </div>
          <div className="heading">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>

          <div className="user-permission">
            <div className="checkbox-all">
              <div className="permission-select-all">
                <Checkbox
                  indeterminate={
                    checkedList.length < this.state.permissionValue.length &&
                    checkedList.length > 0
                  }
                  disabled
                  onChange={this.onCheckAllChange}
                  checked={
                    checkedList.length === this.state.permissionValue.length
                  }
                ></Checkbox>
                Select all
              </div>
              <div className="checkbox-info">
                {" "}
                Click on the below checkboxes to allow appropriate permissions
              </div>
            </div>

            <div className="permission-checkbox">
              <div className="uermission-key">
                {this.state.groupKey &&
                  this.state.groupKey.map((item, idx) => (
                    <div className="userpermission-content" key={idx}>
                      <div className="content-item">
                        <div className="item">
                          <label>{item.label}</label>
                        </div>
                        <div className="item-checkbox">
                          <Checkbox
                            key={item.key}
                            onChange={this.onCheckItem(item.key)}
                            checked={
                              checkedList && checkedList.includes(item.key)
                            }
                            disabled={true}
                          >
                            {item.key}
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getDashboardDataControl: (payload) =>
    dispatch(getDashboardDataControl(payload)),
  createDashboardDataControl: (payload) =>
    dispatch(createDashboardDataControl(payload)),
  getOrgList: (payload) => dispatch(getOrgList(payload)),
  getDashboardDataControlSingle: (id) =>
    dispatch(getDashboardDataControlSingle(id)),

  getGroupList: (payload) => dispatch(getGroupList(payload)),
});

export const DashboardControlView = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardControlViewUI);
