import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import {
  Button, Loading,PopupBox
} from "../../../../../core/components/common";
import {
  getMembershipDiscountList,
  deleteMembershipDiscount,
} from "../../../../actions";
import { Link } from "react-router-dom";
import moment from "moment";
import { Popover } from "antd";


const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Membership Discount </a>
    <br />

  </div>
);
class MemberShipDiscount extends BaseComponent {
  state = {};
  getMembershListDiscount = async (pageNum = 1, page = 10) => {
    await this.props.getMembershipDiscountList({
      pageNum: pageNum,
      pageSize: page,
    });
    this.setState({
      data: this.props.member_discount_lists.results,
      count: this.props.member_discount_lists.count,
    });
  };
  async componentDidMount() {
    this.getMembershListDiscount();
  }
  pageChange = (pageNumber) => {
    this.getMembershListDiscount(pageNumber);
  };

  onEditSocietyDiscount = (id, data) => {
    this.props.history.push(
      "/membership-discounts/edit-membership-discount/" + id
    );
  };

  onViewSocietyDiscount = (id, data) => {
    this.props.history.push(
      "/membership-discounts/view-membership-discount/" + id
    );
  };



  onDeleteMemberShipDiscount = (deleteId) => {
    if (deleteId) {
      this.setState({
        deleteConfirm: true,
        deleteId,
      });
    }
  };
  onDeleteHandler = async () => {
    this.setState({
      waitingMsg: "Deleting...",
    });
    const deleteResponse = await this.props.deleteMembershipDiscount(this.state.deleteId);
    this.setState({
      waitingMsg: null,
      deleteId: null,
      deleteConfirm: false,
      deleteStatus: deleteResponse.status,
    });

   this.getMembershListDiscount()
  
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      deleteId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

 
  amountFormatting = (offerAmount, offerType) => {
    if (offerType === "DISCOUNT") {
      return `${offerAmount}%`;
    } else {
      return offerAmount;
    }
  };

  render() {
    const heading = [
      "Institution",
      "Currency",
      "Fees",
      "offer",
      "Currency(Offer)",
      "amount/Dis",
      "Expiry",
    ];
    const data = [];
    this.state.data &&
      this.state.data.map((item) => {
        return data.push({
          id: item.id,
          Institution: item.society_name,
          Expiry: item.offer_expiry ? moment(item.offer_expiry).format("Do MMM YYYY") : moment().format("Do MMM YYYY"),
          Fees: item.membership_fees,
          Currency:
            item.membership_fees_currency === 3
              ? "GBP"
              : item.membership_fees_currency === 2
                ? "EUR"
                : "USD",
          "Currency(Offer)": item.offer_currency_name,
          offer:
            item.offer_type === "SPECIAL_APC"
              ? "SPECIAL APC"
              : item.offer_type === "AMOUNT"
                ? "AMOUNT"
                : "DISCOUNT",

          "amount/Dis": this.amountFormatting(
            item.offer_amount,
            item.offer_type
          ),
        });
      });

    return (
      <HubContent title="Membership discount list">
               {this.state.deleteConfirm && (
          <PopupBox
            title="Are you sure to delete this Membership Discount?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteHandler}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        <div className="vat-tax-button email d-flex justify-content-end">
          <Link
            to="/membership-discounts/membership-society-discount"
            className="btn btn-primary float-right mb-2"
            style={{ marginRight: 10 }}
          >
            Add Membership discount
            </Link>

          <Popover placement="leftTop" content={content} trigger="click">
            <Button
              className="btn btn-secondary float-right mb-2"
              type=""
              name=""
              text="Help"
            >Help</Button>
          </Popover>
          <div className="danger float-right"
          >
            <button
              type="danger"
              className={`btn btn-danger btn- sm" waves-effect`}
              onClick={() => this.props.history.goBack()}
              style={{ marginLeft: 10 }}
            >
              X

            </button>

          </div>

        </div>

 <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data ? (
                <DataTable
                  heading={heading}
                  data={data}
                  onEdit={this.onEditSocietyDiscount}
                  onDelete={this.onDeleteMemberShipDiscount}
                  onView={this.onViewSocietyDiscount}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : <Loading type="flat" />}
            
            </div>
          </div>
        </div> 
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  member_discount_lists: state.membershipDiscount.member_discount_lists || [],
  isLoading: state.membershipDiscount.isLoading

});

const mapDispatchToProps = (dispatch) => ({
  getMembershipDiscountList: (payload) =>
    dispatch(getMembershipDiscountList(payload)),
  deleteMembershipDiscount: (id) => dispatch(deleteMembershipDiscount(id)),
});

export const MemberShipDiscountList = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberShipDiscount);