import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { ProgramForm } from '../../../partials';
import { CreateUser, ClearStatus,createProgram } from '../../../../actions';
import { PopupBox, Loading  } from '../../../../../core/components/common';

class Add extends BaseComponent {
    constructor(props) {
        super(props)
        this.error = props.failed;
        this.status = 100;
    }
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        isEmpty: false
    }

    componentDidUpdate() {

    }

    onSubmitHandler = async (data) => {
       
        await this.props.createProgram(data);
        this.props.history.push('/program/program-list')
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        })
        await this.props.ClearStatus()
    }


    render() {
        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Bad Request"
                msg="Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <HubContent title="Add program form">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}

                {/* LoadinG Handling */}
                {this.state.isRegistering && !this.state.status && <Loading />}
                {/* LoadinG Handling */}

                <div className="row">
                    <div className="col-lg-11" style={{margin:'0 auto'}}>
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Add program</a>
                                    </li>
                                </ul>
                                <ProgramForm
                                    onSubmitHandler={this.onSubmitHandler}
                                    msg={this.state.msg}
                                    user={this.state.user}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    failed: state.user.failed || false,
})

const mapDispatchToProps = (dispatch) => ({
    CreateUser: (payload) => dispatch(CreateUser(payload)),
    ClearStatus: () => dispatch(ClearStatus()),
    createProgram: (payload) => dispatch(createProgram(payload))
})


export const AddProgram= connect(mapStateToProps, mapDispatchToProps)(Add);
