import React from 'react';
import { connect } from 'react-redux'
import BaseComponent, { HubContent } from '../../../../../core/components';
import { getSingleOaDeal } from '../../../../actions'
  ;
import { Card, Skeleton } from 'antd';
import moment from 'moment';

class ViewTable extends BaseComponent {
  state = {
    info: null
  }

  async componentDidMount() {
    let id = this.props.match.params.id;
    try {
      await this.props.getSingle(id)
      this.setState({
        info: this.props.singleOaDeal
      })
    } catch (e) {
  
    }
  }

  dateOfDeal = (date) => {

    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  }

  priceofOadeal = (price) => {
    let dataPrice = Number(price).toFixed(2);
    return dataPrice
  }

  renderDetails = () => {
    let info = null
    if (this.props.location.state) {
      info = this.props.location.state
    } else {
      info = this.props.singleOaDeal
    }


    return (
      info &&
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body row">
              <div className="col-sm-12">
                <table style={styles.table} >{/******** User Basic Info *******/}
                  <tbody>
                    <tr style={styles.tr}>
                      <th className="mb-5"><h6>OA Deal Info</h6></th>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Organisation</td>
                      <td style={styles.td2}>{this.props.singleOaDeal.oadeal.organisation_name}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Deal Type</td>
                      <td style={styles.td2}>{this.props.singleOaDeal.oadeal.deal_type}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Currency</td>
                      <td style={styles.td2}>{this.props.singleOaDeal.oadeal.currency_name}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Discount</td>
                      <td style={styles.td2}>{this.priceofOadeal(this.props.singleOaDeal.oadeal.discount != null ? this.props.singleOaDeal.oadeal.discount : null)}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Expiry</td>
                      <td style={styles.td2}>{this.props.singleOaDeal.oadeal.expire_date !== null ? this.dateOfDeal(this.props.singleOaDeal.oadeal.expire_date) : "No Date Included"}</td>
                    </tr>

                    <tr style={styles.tr}>
                      <td style={styles.td}>Vat Country</td>
                      <td style={styles.td2}>{this.props.singleOaDeal.oadeal.vat_country_name != null ? this.props.singleOaDeal.oadeal.vat_country_name : "UK"}</td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {


    return (
      <HubContent title="OA Deal">
        <Card>
          {this.state.info ? this.renderDetails() :
            <Skeleton
              active
              paragraph={{ row: 10 }}
            />
          }
        </Card>
      </HubContent>
    )
  }
}

const mapStateToProps = (state) => ({
  singleOaDeal: state.OaDeals
})

const mapDispatchToProps = (dispatch) => ({
  getSingle: (id) => dispatch(getSingleOaDeal(id))
})

export const Singletransition = connect(mapStateToProps, mapDispatchToProps)(ViewTable)


const styles = {
  table: {
    borderCollapse: "collapse",
    width: '100%'
  },
  tr: {
    marginTop: 20,
    verticalAlign: 'baseline'
  },
  td: {
    paddingTop: 10
  },
  td2: {
    paddingTop: 10,
    width: '75%'
  }
};
