import React from 'react';
import { connect } from 'react-redux'
import BaseComponent, { HubContent } from '../../../../../core/components';
import { Button } from '../../../../../core/components/common';

import { getOrganisationSingleToken } from '../../../../actions';
import moment from 'moment';

class ViewTable extends BaseComponent {
  state = {
    info: null
  }

  async componentDidMount() {
    let id = this.props.match.params.id;

      await this.props.getOrganisationSingleToken(id)
    
  }

  dateOfDeal = (date) => {
    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  }

  renderDetails = () => {


    return (
     
        <div className="row">
           
        <div className="col-lg-12">
                <div className="card">
                <div className="vat-tax-button">
            <Button
              type="danger"
              name="danger"
              text="X"
              onClick={() =>  this.props.history.goBack()}
            />
          </div>
            <div className="card-body row">
              <div className="col-sm-12">
                <table style={styles.table} >{/******** User Basic Info *******/}
                  <tbody>
        
                    <tr style={styles.tr}>
                      <td style={styles.td}>Organisation</td>
                      <td style={styles.td2}>{this.props.OaToken && this.props.OaToken.organisation_name}</td>
                                    </tr>
                                    <tr style={styles.tr}>
                      <td style={styles.td}>Reference</td>
                      <td style={styles.td2}>{this.props.OaToken && this.props.OaToken.token_details && this.props.OaToken.token_details.name}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Instituation</td>
                      <td style={styles.td2}>{this.props.OaToken  && this.props.OaToken.institution_name}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Cur/Percent</td>
                      <td style={styles.td2}>{this.props.OaToken &&this.props.OaToken.token_details && this.props.OaToken.token_details.reward_currency}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Amt/Dis</td>
                      <td style={styles.td2}>{this.props.OaToken &&this.props.OaToken.token_details && this.props.OaToken.token_details.reward
          ? this.props.OaToken &&this.props.OaToken.token_details && this.props.OaToken.token_details.reward && this.props.OaToken.token_details.reward.amount
          : "-"}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Valid from</td>
                      <td style={styles.td2}>{this.props.OaToken &&this.props.OaToken.token_details && this.props.OaToken.token_details.valid_from !== null ? this.dateOfDeal(this.props.OaToken &&this.props.OaToken.token_details && this.props.OaToken.token_details.valid_from) : "No Date Included"}</td>
                    </tr>

                    <tr style={styles.tr}>
                      <td style={styles.td}>Valid to</td>
                      <td style={styles.td2}>{this.props.OaToken &&this.props.OaToken.token_details && this.props.OaToken.token_details.valid_to !== null ? this.dateOfDeal(this.props.OaToken &&this.props.OaToken.token_details && this.props.OaToken.token_details.valid_to) : "No Date Included"}</td>

                                    </tr>
                                    
                    <tr style={styles.tr}>
                      <td style={styles.td}>Status</td>
                      <td style={styles.td2}>{this.props.OaToken && this.props.OaToken.is_used ? "Already used" : "Not used"}</td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {


    return (
      <HubContent title="Token Info">
        
{            this.renderDetails() 
}
    
      </HubContent>
    )
  }
}

const mapStateToProps = (state) => ({
    OaToken: state.oaToken.organisation_token_list ,
})

const mapDispatchToProps = (dispatch) => ({
    getOrganisationSingleToken: (id) => dispatch(getOrganisationSingleToken(id))
})

export const tokenView = connect(mapStateToProps, mapDispatchToProps)(ViewTable)


const styles = {
  table: {
    borderCollapse: "collapse",
    width: '100%'
  },
  tr: {
    marginTop: 20,
    verticalAlign: 'baseline'
  },
  td: {
    paddingTop: 10
  },
  td2: {
    paddingTop: 10,
    width: '75%'
  }
};
