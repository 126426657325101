import React from "react";
import BaseComponent, { HubContent, DataTable } from "../../../../../core/components";

import { connect } from "react-redux";
import {
    getRefundRequestAllInfo
} from "../../../../actions";

class RefundRequest extends BaseComponent {

    state = {

    }
    getReundDataList = async (pageNum = 1, page = 10) => {
        await this.props.getRefundRequestAllInfo({ pageNum: pageNum, pageSize: page });
        let data = this.props.author_refund_request_list.results;
        let dataOrg = [];
        data.map((item) => {
            return dataOrg.push({
                "article_info": item.article_info.title,
                "publisher": item.publisher_info.name,

                id: item.id
            });
        })
        this.setState({
            data: dataOrg,
            // count: this.props.email_history_log.count
        })
    }
     componentDidMount() {
        this.getReundDataList();
    }
    
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
}
    pageChange = (pageNumber) => {
        this.getReundDataList(pageNumber);
    }

    onViewCustomEmail = (id) => {
        this.props.history.push('/author-custom-email/author-custom-email-message/' + id)
    }

    render() {
        const heading = [
            "article_info",
            "publisher",
        ];
        return (
            <HubContent
                title="Refund Request List"
            >


                <div className="row filter">

                    <div className="col-lg-12">
                        <div className="card m-b-20">

                            <DataTable
                                heading={heading}
                                data={this.state.data}

                                onView={this.onViewCustomEmail}
                                count={this.state.count}
                                pageChange={this.pageChange}
                                pageSize={10}
                            />


                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = (state) => ({
    author_refund_request_list: state.RefundRequest.publisher_refund_request_list || []

});
const mapDispatchToProps = (dispatch) => ({
    getRefundRequestAllInfo: (payload) => dispatch(getRefundRequestAllInfo(payload)),

});

export const RefundRequestList = connect(mapStateToProps, mapDispatchToProps)(RefundRequest);
