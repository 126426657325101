import React from "react";
import { DragAndDrop } from "../../../partials";
import BaseComponent, { HubContent } from "../../../../../core/components";

class AddPub extends BaseComponent {
  render() {
    return (
      <HubContent title="Publisher Form">
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Enter publisher details
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload publishers details
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <h4 className="mt-0 header-title">Publisher information</h4>

                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        Publisher
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="publisher name"
                        />
                      </div>

                      <label className="col-sm-2 col-form-label">
                        Category
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="custom-select"
                          defaultValue="Publisher"
                        >
                          <option value="">select category</option>
                          <option value="Publisher">Publisher</option>
                          <option value="Publisher Imprint">Imprint</option>
                          <option value="Society publisher">
                            Society publisher
                          </option>
                          <option value="University">University</option>
                          <option value="Research organisation">
                            Research organisation
                          </option>
                          <option value="Funder">Funder</option>
                          <option value="Consortia">Consortia</option>
                          <option value="University deapartment">
                            University department
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        Address line 1
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="address line"
                        />
                      </div>
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        address line 2
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="address line"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        City/Town
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="City or Town"
                        />
                      </div>
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        Post/Zip code
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="Poost/Zip code"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        County/State
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="County/State/Province"
                        />
                      </div>

                      <label htmlFor="" className="col-sm-2 col-form-label">
                        Country
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="country"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        Website
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="website"
                        />
                      </div>

                      <label htmlFor="" className="col-sm-2 col-form-label">
                        VAT number
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="VAT number"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        Domain
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="enter your domain as @doamin.com or @domain.ac.uk, @domain.net etc"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="" className="col-sm-2 col-form-label">
                        Note
                      </label>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                          id=""
                          placeholder="Note"
                        />
                      </div>
                    </div>
                    <div className="text-center m-t-15">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <h4 className="mt-0 header-title">
                      Publishier information
                    </h4>
                    <p className="text-muted m-b-30">
                      Please drop your file. The sequense of columns will be
                      Publisher name, address line 1, address line 2, City/Town,
                      County/State, Post or zip code, Country, website, VAT,
                      Note
                    </p>

                    <div className="m-b-30">
                      <DragAndDrop />
                    </div>

                    <div className="text-center m-t-15">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

export const AddPublisher = AddPub;
