import React, { Component } from 'react'
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

class Loader extends Component {
    render() {
        const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
        return (
            this.props.offerLoading === true ? <Spin /> : <Spin indicator={antIcon} />
        )
    }
}

export const SearchLoader = Loader
