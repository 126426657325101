import { 
    GET_OA_SUBJECT_LIST, 
    GET_OA_SUBJECT, 
    CREATE_UPDATE_OA_SUBJECT, 
    DELETE_OA_SUBJECT,
    UPLOAD_OA_SUBJECT,
} from "../../config";

const OaSubjectReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_OA_SUBJECT_LIST: 
            return state = {
                ...state,
                oa_subjects: action.payload
            }
        case GET_OA_SUBJECT: 
            return state = {
                ...state,
                oa_subject: action.payload
            }
        case CREATE_UPDATE_OA_SUBJECT: 
            return state = {
                ...state,
                oa_subject: action.payload
            }
        case DELETE_OA_SUBJECT: 
            return state = {
                ...state,
            }
        case UPLOAD_OA_SUBJECT: 
            return state = {
                ...state,
                oa_subject_file_upload: action.payload,
            }
        default:
            return state;
    }
}

export default OaSubjectReducer;
