import {
  FunderPubs,
  PubSingle,
  FunderInstitutions,
  InstitutionSingle,
  UniArticles,
  ArticleDecForm,
  ArticalAprForm,
  OrgOffsetFundList,
  OrgTokenList,
  ApcRequestList,
  UniAddUser,
  OrgList,
  SingleOrg,
  ProfileUser,
} from "../components/views/funder";
import {
  ArticleCustomEmailMessage,
  ArticleCustomEmail,
  ArticleOutBoxEmail,
  PaymentHistory,
  InvoiceHistory,
} from "../../openaccess/components/views/user";

import {
  TrxDeposit,
  TrxOffset,
  TrxToken,
} from "../components/views/_transaction";
import { Dashboard, Settings } from "../components/views";

export const FunderRoot = {
  dashboard: "/",
  publishers: "/funder-publishers",
  institutions: "/funder-institutions",
  affiliated_organisations: "/affiliated-organisations",
  users: "/users",
  articles: "/articles",
  apc_fund: "/apc-fund",
  author_request: "/author-request",
  pub_fund: "/publisher-fund",
  oa_account: "/oa-account",
  article_approval: "/article-approval",
  article_decline: "/article-decline",
  token: "/institute",
  transaction: "/transaction",
  reports: "/reports",
  action: "/action",
  settings: "/settings",
  custom_email: "/email",
};

export const FunderSidebar = [
  {
    icon: "mdi-view-dashboard",
    label: "Dashboard",
    to: FunderRoot.dashboard,
    exact: true,
    component: Dashboard,
  },
  {
    icon: "mdi-settings",
    label: "Settings",
    to: FunderRoot.settings,
    exact: true,
    component: Settings,
  },

  {
    icon: " mdi-bank",
    label: "Publishers",
    to: FunderRoot.publishers,
    exact: true,
    component: FunderPubs,
  },
  {
    to: FunderRoot.publishers + "/:id",
    exact: true,
    component: PubSingle,
    isHide: true,
  },
  {
    icon: "mdi-border-all",
    label: "Institutions",
    to: FunderRoot.institutions,
    exact: true,
    component: FunderInstitutions,
  },
  {
    to: FunderRoot.institutions + "/:id",
    exact: true,
    component: InstitutionSingle,
    isHide: true,
  },
  {
    icon: " mdi-pencil-box",
    label: "Articles",
    to: FunderRoot.articles,
    exact: true,
    component: UniArticles,
  },
  {
    icon: "mdi-file-send",
    label: "Author request",
    exact: true,
    to: FunderRoot.author_request,
    content: [
      {
        label: "APC request list",
        to: "/apc-request-list",
        component: ApcRequestList,
      },
    ],
  },
  {
    icon: " mdi-pencil-box",
    label: "ArticleApprovalForm",
    to: FunderRoot.article_approval + "/:id",
    exact: true,
    component: ArticalAprForm,
    isHide: true,
  },
  {
    icon: " mdi-pencil-box",
    label: "Article Decline Form",
    to: FunderRoot.article_decline + "/:id",
    exact: true,
    component: ArticleDecForm,
    isHide: true,
  },
  /* {
    icon: "mdi mdi-cash-usd",
    label: "APC fund",
    exact: true,
    to: FunderRoot.apc_fund,
    content: [
      {
        label: "Add fund",
        to: "/add-apc-fund",
        component: AddApcFund,
        exact: true,
      },
      {
        label: "APC fund list",
        to: "/apc-fund-list",
        component: ApcFundList,
        exact: true,
      },
    ],
  },*/
  {
    icon: "mdi-email",
    label: "Emails",
    exact: true,
    to: FunderRoot.custom_email,
    role: "public",
    content: [
      {
        label: "Inbox ",
        to: "/email-custom-log",
        component: ArticleCustomEmail,
        role: "public",
      },
      {
        label: "Outbox",
        to: "/email-outbox-list",
        component: ArticleOutBoxEmail,
        role: "public",
      },
      {
        isHide: true,
        to: "/custom-email-message/:id",
        component: ArticleCustomEmailMessage,
      },
    ],
  } /*
  {
    icon: "mdi-cash-multiple",
    label: "Publisher fund",
    exact: true,
    to: FunderRoot.pub_fund,
    content: [
      {
        label: "Deposit & payment",
        to: "/deposit-fund",
        component: DepositFund,
        exact: true,
      },
      {
        label: "Deposit fund list",
        to: "/deposit-list",
        component: DepositList,
        exact: true,
      },
    ],
  }, */,
  {
    icon: "mdi-key-variant",
    label: "Tokens & Offsets",
    to: FunderRoot.token,
    exact: true,
    content: [
      {
        label: "Tokens",
        to: "/org-token-list",
        component: OrgTokenList,
      },
      {
        label: "Offset funds",
        to: "/org-offset-fund-list",
        component: OrgOffsetFundList,
      },
    ],
  },

  /*
  {
    icon: "ion-clipboard",
    label: "OA policies",
    exact: true,
    to: "/oa-policies",
    component: OaPolicies,
  },
  {
    icon: "ion-gear-b",
    label: "Action",
    exact: true,
    to: FunderRoot.action,
    content: [
      {
        label: "OA account requests",
        to: "/oa-account-request",
        component: oaAccountApproval,
      },
      {
        label: "Correction request",
        to: "/add-apc-fund",
        component: CorrectionRequest,
      },
      {
        label: "OA account list",
        to: "/oa-account-list",
        component: oaDealApproveList,
      },
      {
        label: "Correction list",
        to: "/correction-list",
        component: CorrectionList,
      },
      {
        label: "Correction Single",
        to: "/correction-list/:id",
        component: CorrectionView,
        isHide: true,
      },
    ],
  }, */ {
    icon: "mdi-credit-card",
    label: "Transaction",
    exact: true,
    to: FunderRoot.transaction,
    content: [
      {
        label: "Deposit fund",
        to: "/deposit-fund",
        component: TrxDeposit,
      },
      {
        label: "Offset fund",
        to: "/offset-fund",
        component: TrxOffset,
      },
      {
        label: "Token",
        to: "/token",
        component: TrxToken,
      },
      {
        label: "Card Payment ",
        to: "/paymenthistory",
        component: PaymentHistory,
      },
      {
        label: "Invoices ",
        to: "/invoice",
        component: InvoiceHistory,
      },
    ],
  },

  {
    icon: "ion-university",
    label: "Affiliated organisations",
    to: FunderRoot.affiliated_organisations,
    content: [
      {
        label: "Affiliated Organisations List",
        to: "/affiliated-organisation-list",
        component: OrgList,
      },
      {
        label: "Organisation View",
        to: "/single-org/:id",
        component: SingleOrg,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-account-multiple",
    label: "Users",
    exact: true,
    to: FunderRoot.users,
    content: [
      {
        label: "Add user",
        to: "/add-user",
        exact: true,
        component: UniAddUser,
      },
      {
        label: "View User",
        to: "/user/:id",
        component: ProfileUser,
        isHide: true,
      },
    ],
  },
];
