import React from 'react';
import BaseComponent from '../../../../../core/components';
import { connect } from 'react-redux';
import { InputBox, SelectBox, TextBox } from '../../../../../core/components/common';
import { getCurrency, GetOaPubList, getOaDealBalanceList, getRefundRequest } from '../../../../actions';

class Form extends BaseComponent{
    state = {
        refundRequest: {},
        oa_deal_balance: null,
        currency: null,
        publisher: null,
    }

    async componentDidMount() { 
        let publisher = await this.props.getOaPubList();
        let newOaDeals = false;

        if (this.props.match) { 
            const id = this.props.match.params.id;
            if (id) {
                await this.props.getRefundRequest(id);
                this.props.refund_request && this.setState({
                    refundRequest: this.props.refund_request,
                });

                let oa_deal_balance = false;
                if (this.props.refund_request.counter_organisation) { 
                    let payload = {
                        deal_organisation: this.props.refund_request.counter_organisation
                    };

                    oa_deal_balance = await this.props.getOaDealBalanceList(payload);
                }

                newOaDeals = oa_deal_balance && oa_deal_balance.results.map((deal) => {
                    return Object.assign(deal, {
                        name: deal.label
                    })
                });

                this.setState((prevState) => {
                    prevState.refundRequest = this.props.refund_request || {};
                    prevState.oa_deal_balance = newOaDeals || [];
        
                    return prevState;
                });
            }
        }

        this.setState((prevState) => {
            prevState.publisher = publisher;

            return prevState;
        })
    }

    onChangeHandler = async (value) => {
        this.setState((prevState) => {
            Object.assign(prevState.refundRequest, value);
            
            return prevState;
        })
    }

    onChangePubHandler = async (value) => { 
        let oa_deal_balance = false;
        if (value.counter_organisation) { 
            let payload = {
                deal_organisation: value.counter_organisation
            };

            oa_deal_balance = await this.props.getOaDealBalanceList(payload);
        }

        let newOaDeals = oa_deal_balance && oa_deal_balance.results.map((deal) => {
            return Object.assign(deal, {
                name: deal.label
            })
        });
        
        this.setState((prevState) => { 
            Object.assign(prevState.refundRequest, value);
            prevState.oa_deal_balance = newOaDeals;
            return prevState;
        })
    } 

    onSubmitHandler = async (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.refundRequest)
    }

    render() {
        return(
            <div className="tab-content">
                <div className="tab-pane active p-3" id="home-1" role="tabpanel"></div>
                <form>
                    <div className="form-group row">
                        <SelectBox
                            label="Publisher" // Need Info
                            onChange={this.onChangePubHandler}
                            field='counter_organisation'
                            data={this.state.publisher}
                            isRequired={true}
                            className='col-md-10'
                            defaultValue={this.state.refundRequest.counter_organisation}
                        />
                    </div>
                    <div className="form-group row">
                        <SelectBox
                            label="OA deal"
                            onChange={this.onChangeHandler}
                            field='oa_deal'
                            data={this.state.oa_deal_balance}
                            isRequired={true}
                            defaultValue={this.state.refundRequest.oa_deal}
                            // isData={true}
                        />
                        <InputBox
                            label="Amount"
                            onChange={this.onChangeHandler}
                            field='requested_amount'
                            placeholder="Requested amount"
                            isRequired={true}
                            defaultValue={this.state.refundRequest.requested_amount}
                        />
                    </div>
                    
                    <div className="form-group row">
                        
                        <TextBox 
                            label="Note"
                            onChange={this.onChangeHandler}
                            field='approval_note'
                            placeholder="Write note" 
                            rows= { 3 }
                            className="col-sm-10"
                            defaultValue={this.state.refundRequest.approval_note}
                        />
                    </div>
                    
                    <div className="text-center m-t-15">
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={this.onSubmitHandler}
                        >Submit</button>
                    </div>

                </form>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    group_id: state.auth.user.group_id || null,
    oaDealBalanceList: state.RefundRequest.oadeal_balance_list || false,
    refund_request: state.RefundRequest.refund_request || false,
})

const mapDispatchToProps = (dispatch) => ({
    getOaPubList: () => dispatch(GetOaPubList()),
    getRefundRequest: (id) => dispatch(getRefundRequest(id)),
    getOaDealBalanceList: (payload) => dispatch(getOaDealBalanceList(payload)),
    getCurrency: () => dispatch((getCurrency()))
})


export const RefundRequestForm = connect(mapStateToProps, mapDispatchToProps)(Form);
