import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
    getAllApcRequests, getAllApcRequestsDownload,
    oADealListApproved,getPublisherArticleFullList,
    mutateApcRequestStatus, 
} from "../../../../actions";
import {
    Loading,
    ExpandHeadingTable,
    PaginationForm,
    ModalCustom,
    TextBox,
    PopupBox, 
} from "../../../../../core/components/common";
import ExpandTable from "./DataTable"
import {
    apcRequestHeading,
    authoApcRequestHeading,
    apcPubRequestUnivHeading,
    column,

} from "../../../../../core/lib";
import { UnivRoot } from "../../../../data";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";

class ApcRequest extends BaseComponent {
    state = {
        filterParams: {},
        activeItem: null,
        data: 0,
        count: 0,
        current: 1
    };
     componentDidMount() {
        this.getApcRequestsData();
    }

    getApcRequestsData = async (pageNum = 1, page = 20) => {
        await this.props.getAllApcRequests({ pageNum: pageNum, pageSize: page});

        this.props.apc_requests && this.setState({
            data: this.props.apc_requests.results,
            count: this.props.apc_requests.count,
        });
        

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
    }
    pageChange = (pageNumber) => {
        this.setState({
            current: pageNumber
        })
        this.getApcRequestsData(pageNumber);
    }

    onItemActive = item => {
        this.setState({ activeItem: item });
    };


    onChangeHandler = (value) => {
        this.setState({
        ...value,
        });
    };

    onSubmit = async () => {
        
        let {
            id,
            mutate_action,
            note,
        } = this.state;

        if (!note) {
            this.setState({
                isEmpty: 'Note field is required.'
            })
        } else {

            if (id && mutate_action) {
                let data = {
                    note,
                };
    
                this.props.mutateApcRequestStatus(id, mutate_action, data).then(response => { 
                    this.onClose()
                    
                    confirmAlert({
                        title: "",
                        message: "APC request declined.",
                        buttons: [
                          {
                            label: "Close",
                            onClick: () => { 
    
                                this.getApcRequestsData();
    
                            },
                          },
                        ],
                    });
                })
    
            }
        }
    };

    onClose = () => {
        this.setState({
            showConfirmationModal: false,
            modalTitle: "",
            note: "",
            mutate_action: false,
            id: false,
        });
    };

    cancelLoader = () => {
        this.setState({
            isEmpty: false,
        });
    };

    modalForm = () => {
        return (
        <form>
            <div className="form-group row">
                <TextBox
                    label="Note"
                    labelClass="col-sm-4"
                    onChange={this.onChangeHandler}
                    field="note"
                    placeholder="Note"
                    rows={3}
                    className="col-sm-8"
                />
            </div>
        </form>
        );
    };
    
    onActionHandler = async (action, id, title) => {
        try {

            switch (action && action.toLowerCase()) {
                case "approve":

                   let filter = serialize({q:title})
                    await this.props.getPublisherArticleFullList({filter});
                   let articlePublisher = this.props.articles &&  this.props.articles.find(item => item.id ===id)
                    await this.props.oADealListApproved();
                    let OaDeal = false;
                    try {
                        if (articlePublisher && articlePublisher.publisher) {
                            OaDeal = await this.props.OaDealCurrency(articlePublisher && articlePublisher.publisher&& articlePublisher.publisher.id);
                        }
                    } catch (error) {
                        this.log("OaDeal error: ", error);
                    }

                    if (!OaDeal) {
                        this.setState({
                            msg: "You don't have any OaDeal. Please Create One",
                        });
                    } else {
                       
                        history.push(UnivRoot.article_approval + "/" + id, {
                            article:articlePublisher,
                            OaDeal,
                            id,
                        });
                    }
                    break;
                case "article_payment":
                    this.props.history.push(`/payment/addpayment/${id}`, {
                        name: action
                    });
                    break;
                case "article_invoice":
                    this.props.history.push(`/payment/addpayment/${id}`, {
                        name: action
                    });
                    break;
                case "decline_author_apc_fund_request":
                    this.setState({
                        showConfirmationModal: true,
                        modalTitle: 'Decline APC Request',
                        mutate_action: 'rejected',
                        id: id,
                    })
                    break;
                default:
                    break;
            }
        } catch (error) {
            this.log("error: ", error);
        }
    };

    render() {
        const { group } = this.props
        let title = group === "AUTHOR" ? "APC fund and Non OA request list" : group === "INSTITUTION" ? "APC request list" : "Non OA request list"
        const data = [];
        this.state.data && this.state.data.map(item => {
            return data.push({
                id: item.article,
                apc_request_id: item.id,
                licence: item.licence_name,
                category: item.kind,
                date: item.date_created,
                title: item.article_title,
                author_name: item.article_author_name,
                organisation_name: group === 'AUTHOR' ? item.counter_organisation_name : item.organisation_name,
                note: item.note,
                status: item.approval_status === 'rejected' ? 'Declined' : item.approval_status,

            })
        });

        let apcHeading = [];

        if (group === 'AUTHOR') {
            apcHeading = authoApcRequestHeading;
        } else if (group === "PUBLISHER") {
            apcHeading = apcRequestHeading;
        } else {
            apcHeading = apcPubRequestUnivHeading;

        }

        let errorHandler = (
            <>
            {this.state.isEmpty && (
                <PopupBox msg={this.state.isEmpty} onCancel={this.cancelLoader} />
            )}
            </>
        );

        return (

            <HubContent
                title={title}
            >
                {this.state.showConfirmationModal && (
                    <ModalCustom
                        title={this.state.modalTitle}
                        submitBtnTxt="Submit"
                        onClose={this.onClose}
                        onSubmit={this.onSubmit}
                        modalClass="col-sm-6"
                        isShow={this.state.showConfirmationModal}
                    >
                        {this.modalForm()}
                    </ModalCustom>
                )}

                { errorHandler }

                <div className="col-12">
                    <div className="card m-b-20">
                        <ExpandHeadingTable heading={apcHeading} />
                        {
                            this.props.isLoading !== true ? <div>
                                {
                                    data && data.map((item, index) => (
                                        <div id="accordion" key={index}>
                                            <ExpandTable
                                                invoiceUpdate={true}
                                                key={index}
                                                item={item}
                                                onActionHandler={(action, id = item.id) =>
                                                    this.onActionHandler(action, id,item.title)
                                                }
                                                column={column}
                                                downloadName="Invoice download"
                                                group={group}
                                            />
                                        </div>
                                    ))

                                }
                                {data && data.length > 0 ? <PaginationForm
                                    pageSize={10}
                                    pageChange={this.pageChange}
                                    current={this.state.current}
                                    count={this.state.count} />
                                    : 
                                        <div className="card-body no-data-found">No Data Found</div>
                                  
                                }
                            </div> : <Loading type="flat" />
                        }



                    </div>
                </div>
            </HubContent >
        );
    }
}


const mapStateToProps = (state) => ({
    group: state.auth.group,
    isLoading: state.authorRequests.isLoading,
    apc_requests: state.authorRequests.apc_requests,
    apc_requests_download: state.authorRequests.apc_requests_download,
    articles: state.articles.article_full,
    OaDealCurrency: (publisher) =>
        state.OaDeals.oadeal_approved_list
            ? state.OaDeals.oadeal_approved_list.find((OaDeal) => {
                if (publisher.toString() === OaDeal.organisation.toString()) {
                    return OaDeal;
                } else {
                    return false;
                }
            })
            : null,
})

const mapDispatchToProps = (dispatch) => ({
    getAllApcRequests: (payload) => dispatch(getAllApcRequests(payload)),
    mutateApcRequestStatus: (ids, action, payload = {}) => dispatch(mutateApcRequestStatus(ids, action, payload)),
    oADealListApproved: () => dispatch(oADealListApproved()),
    getAllApcRequestsDownload: () => dispatch(getAllApcRequestsDownload()),
    getPublisherArticleFullList: (payload = {}) => dispatch(getPublisherArticleFullList(payload)),

})
export const ApcRequestList = connect(mapStateToProps, mapDispatchToProps)(ApcRequest)