import React from 'react';
import BaseComponent from '../../../../core/components';
import { connect } from "react-redux";
import { history } from '../../../route';

import {
  Button,
  PopupBox,
  RadioButtonGroup,
} from '../../../../core/components/common';


class ApproveForm extends BaseComponent {

  state = {
    approval_type: 1,
  }

 

  onChangeHandler = value => {
    this.setState({
      ...value
    });
  };

  onClickHandler = (articleId) => {

    let mutateAction = 'manualapprove'

    this.props.mutateArticleStatus(articleId, mutateAction).then(response => { 
       
        this.setState({
          successMsg: response && response.data && response.data.message
        })
        
        this.props.onManualApproveSuccessHandler()
    }).catch(error => { 
        
    })
  }

  onCancel = () => {
    this.setState({
      successMsg: false,
    });
  };

  render() {

    let { article_id } = this.props
    let { approval_type } = this.state

    let successErrMsgPop = <>
            
            {this.state.successMsg && <PopupBox
                title="Success!"
                msg={this.state.successMsg}
                onCancel={this.onCancel}
            />}
        </>;

    return (
      <div>
        
        { successErrMsgPop }

        <div className="form-group">
          <RadioButtonGroup
            field="approval_type"
            options={[
              { id: 1, name: "Approve under an OA deal" },
              { id: 2, name: "Payment" }
            ]}
            radioStyle={{
              display: "block",
              height: "30px",
              lineHeight: "30px"
            }}
            defaultValue={approval_type || false}
            disabled={false}
            value={approval_type || false}
            onChange={this.onChangeHandler}
          />
        </div>
        <div className="d-flex justify-content-center mb-4">

          {approval_type === 1 && <Button 
            text="Approve"
            onClick={()=> this.onClickHandler(article_id)}
            className="btn-primary"
          />}

          {approval_type === 2 && <Button 
            text="Go to manual approval page"
            onClick={() => history.push(`/payment/addpayment/${article_id}`, {
                name: 'MANUAL_APPROVAL_PAYMENT'
            })}
            className="btn-primary"
          />}
        </div>
        
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({

});

export const ManualApproveForm = connect(mapStateToProps, mapDispatchToProps)(ApproveForm);
