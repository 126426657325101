import {
  GET_LICENCE_ALL_LIST,
  REQUEST_LICENCE_ALL_LIST,
  GET_TERMS_PDFDWNLOAD,
  GET_LICENCE_PDFPDFDWNLOAD,
  UPDATE_LICENCE_LIST,
  SINGLE_TERMS_CONDITION_LIST,
  UPDATE_TERMS_CONDITION_LIST,
  GET_TERMS_CONDITION_LIST,
  REQUEST_GET_TERMS_CONDITION_LIST,
  SINGLE_LICENCE_LIST,
  POST_TERMS_CONDITION,
  POST_ADD_LICENSE,
  GET_ALL_POST_LICENSE,
  REQUEST_ALL_POST_LICENSE,
  ORGANISATION_LICENSE_AGREEMENTS,
  ORGANISATION_LICENSE_DOWNLOAD,
  ARTICLE_FULL_LICENCE_UPDATE,
  REQUEST_SINGLE_LICENSE_ACTION,
  PRICING_LICENCE_LIST,
} from "../../config";

const LicenceReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_ADD_LICENSE:
      return (state = {
        ...state,
        recived_license: action.payload,
      });

    case REQUEST_ALL_POST_LICENSE:
      return (state = {
        ...state,
        isLoading: true,
      });
    case GET_ALL_POST_LICENSE:
      return (state = {
        ...state,
        isLoading: false,
        get_all_license: action.payload,
      });
    case POST_TERMS_CONDITION:
      return (state = {
        ...state,
        recived_terms: action.payload,
      });
    case GET_LICENCE_ALL_LIST:
      return (state = {
        ...state,
        isTagLoading: false,
        licenseTagList: action.payload,
      });
    case REQUEST_LICENCE_ALL_LIST:
      return (state = {
        ...state,
        isTagLoading: true,
      });
    case UPDATE_LICENCE_LIST:
      return (state = {
        ...state,
        update_lience: action.payload,
      });
    case SINGLE_LICENCE_LIST:
      return (state = {
        ...state,
        isLoading: false,
        single_lience: action.payload,
      });
    case REQUEST_SINGLE_LICENSE_ACTION:
      return (state = {
        ...state,
        isLoading: true,
      });

    case GET_TERMS_CONDITION_LIST:
      return (state = {
        ...state,
        isLoading: false,
        get_terms_condition: action.payload,
      });
    case REQUEST_GET_TERMS_CONDITION_LIST:
      return (state = {
        ...state,
        isLoading: true,
      });
    case UPDATE_TERMS_CONDITION_LIST:
      return (state = {
        ...state,
        update_terms_condition: action.payload,
      });
    case SINGLE_TERMS_CONDITION_LIST:
      return (state = {
        ...state,
        isLoading: false,
        single_terms_condition: action.payload,
      });
    case GET_TERMS_PDFDWNLOAD:
      return (state = {
        ...state,
        license_download: action.payload,
      });
    case GET_LICENCE_PDFPDFDWNLOAD:
      return (state = {
        ...state,
        terms_condition_download: action.payload,
      });
    case ORGANISATION_LICENSE_AGREEMENTS:
      return (state = {
        ...state,
        organisation_licence_agreements: action.payload,
      });
    case ORGANISATION_LICENSE_DOWNLOAD:
      return (state = {
        ...state,
        organisation_licence_download: action.payload,
      });
    case ARTICLE_FULL_LICENCE_UPDATE:
      return (state = {
        ...state,
        article_full_licence_update: action.payload,
      });
    case PRICING_LICENCE_LIST:
      return (state = {
        ...state,
        pricing_licence: action.payload,
      });

    default:
      return state;
  }
};
export default LicenceReducer;
