import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
// import { PopupBox }  from '../../../../../core/components/common';
// import { DataTable } from '../../../../../core/components';
import { connect } from 'react-redux';
// import { oADealListPending,deleteOaDeal,oADealListApproval,oADealListReject } from '../../../../actions';


class DB extends BaseComponent {
    async componentDidMount(){
        await this.props.oADealListPending();
    }
    render() {
        return (
            <HubContent title="Correction Request">


            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({

})
const mapDispatchToProps = (dispatch) => ({

})

export const CorrectionRequest = connect(mapStateToProps, mapDispatchToProps)(DB);