import React from "react";
import { connect } from "react-redux";
import { AutoComplete } from "antd";

import {
  getInstitutionDataLists,
  getAPCElgFunders,
  getAPCElgJournals,
  getAPCElgCountries,
  getAPCElgContentTypes,
  getAPCElgSocieties,
  submitAPCEligibility,
} from "../../actions";
import BaseComponent from "../../../core/components";
import { SelectBox, Button } from "../../../core/components/common";
import { ApcEligibilityForm } from "./index";
export class CheckAPCEligibilityComponent extends BaseComponent {
  state = {
    submitBtnDisabled: true,
    apc_eligibilty: {},
  };

  async componentDidMount() {
    this.props.getAPCElgJournals();
    this.props.getAPCElgContentTypes();
    this.props.getAPCElgSocieties();

    let funders;
    funders = await this.props.getAPCElgFunders();
    funders =
      funders.results &&
      funders.results.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    funders = [
      {
        id: "funder",
        name: "Select research funder",
      },
    ].concat(funders);

    let countries;
    countries = await this.props.getAPCElgCountries();
    countries =
      countries.results &&
      countries.results.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    countries = [
      {
        id: "country",
        name: "Select your Country",
      },
    ].concat(countries);

    this.setState({
      funders: funders,
      countries: countries,
    });
  }

  onContentTypeChangeHandler = (value) => {
    this.setState((prevState) =>
      Object.assign(prevState.apc_eligibilty, value)
    );

    let content_types =
      this.props.content_types &&
      this.props.content_types.find((item) => item.id === value.content_type);
    this.setState({
      content_types: content_types.name,
    });
  };
  onSelectChangeHandler = (value) => {
    if (value.funder === "funder") {
      this.setState((prevState) =>
        Object.assign(prevState.apc_eligibilty, { funder: "" })
      );
    } else if (value.country === "country") {
      this.setState((prevState) =>
        Object.assign(prevState.apc_eligibilty, { country: "" })
      );
    } else {
      this.setState((prevState) =>
        Object.assign(prevState.apc_eligibilty, value)
      );
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();

    let res = await this.props.submitAPCEligibility(this.state.apc_eligibilty);
    this.setState({
      data: res.data,
      isModelOpen: true,
    });
  };

  onCloseModel = () => {
    this.setState({
      isModelOpen: false,
    });
  };

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState((prevState) => {
        Object.assign(prevState.apc_eligibilty, { affiliation: item.id });
      });
    }
  };
  onChange = (value) => {
    let data = this.props.institution_list && this.props.institution_list.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        affiliation: value,
        orgsError: false,
      });
    } else {
      this.setState({
        orgsError: true,
        org_name:value
      });
    }

  };
  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getInstitutionDataLists(value);
      this.setState({

          InstDataList:
          this.props.institution_list &&
          this.props.institution_list.map((item) => ({
            id: item.id,
            value: item.name,
          })),
      });
    }, 200);
  };






  render() {
 
    return (
      <form className="apc-eligibility">
        {this.state.isModelOpen && (
          <ApcEligibilityForm
            isShow={this.state.isModelOpen}
            onClose={this.onCloseModel}
            data={this.state.data}
            article_type={this.state.content_types}
          />
        )}
        <div className="row">
          <div className="col-md-12 text-center">
            <h3
              style={{
                color: "#fff",
                fontSize: "18px",
                borderBottom: "1px solid #fff",
                paddingBottom: 15,
              }}
            >
              APC Funding Eligibility.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3
              style={{
                marginTop: 15,
                lineHeight: 2,
                textAlign: "center",
                color: "#fff",
                fontSize: "14px",
                marginBottom: 15,
              }}
            >
              Enter your information to check your funding eligibility and
              estimated APC fee.
            </h3>
          </div>
        </div>
        <div className="col-md-12 apc-input">
          <div className="col-md-12">
            <AutoComplete
              options={this.state.InstDataList}
              style={{
                width: "100%",
              }}
              notFoundContent={this.state.org_name && "The institution name you have typed has no match on OaMetrix."}
              placeholder="Type your institution name and select from the drop down"

              onSelect={(val, option) => this.onSelect(val, option)}
              onSearch={this.onSearch}
              onChange={this.onChange}
            />
       {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure you have selected Institution/Consortia name from dropdown.
</span>}
          </div>
        </div>
        <div className="col-md-12 apc-input">
          <SelectBox
            onChange={this.onSelectChangeHandler}
            field="journal"
            className="col-md-12"
            data={this.props.journals}
            isRequired={false}
            placeholder="Select journal [Required]"
            defaultValue={this.state.journal}
          />
        </div>

        <div className="col-md-12 apc-input">
          <SelectBox
            onChange={this.onContentTypeChangeHandler}
            field="content_type"
            isRequired={true}
            className="col-md-12"
            defaultValue={this.state.content_types}
            placeholder="Select article type [Required]"
            data={this.props.content_types}
          />
        </div>
        <div className="col-md-12 apc-input">
          <SelectBox
            onChange={this.onSelectChangeHandler}
            field="funder"
            isRequired={false}
            placeholder="Select research funder"
            className="col-md-12"
            // defaultValue={this.state.funder}
            data={this.state.funders}
          />
        </div>
        <div className="col-md-12 apc-input">
          <SelectBox
            onChange={this.onSelectChangeHandler}
            field="country"
            isRequired={true}
            className="col-md-12"
            placeholder="Select your Country"
            defaultValue={this.state.country}
            data={this.state.countries}
          />
        </div>
        <div className="col-md-12 apc-input">
          <SelectBox
            onChange={this.onSelectChangeHandler}
            field="societies"
            data={this.props.societies}
            defaultValue={this.state.societies}
            isRequired={false}
            placeholder="Are you a member of any society?"
            className="col-md-12 apc-society"
            multiple={true}
          />
        </div>
        <div className="row" style={{ marginTop: 35 }}>
          <div className="col-md-12 text-center">
            <Button
              onClick={this.onSubmit}
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              size="160px"
              text="Submit"
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  institution_list:(state.institutions &&  state.institutions.institution_list) || [],
  funders: state.apcelg.funders,
  journals: state.apcelg.journals,
  countries: state.apcelg.countries,
  content_types: state.apcelg.content_types,
  societies: state.apcelg.societies,
  submit_response: state.apcelg.response,
});

const mapDispatchToProps = (dispatch) => ({
  getInstitutionDataLists: (value) => dispatch(getInstitutionDataLists(value)),
  getAPCElgFunders: () => dispatch(getAPCElgFunders()),
  getAPCElgJournals: () => dispatch(getAPCElgJournals()),
  getAPCElgCountries: () => dispatch(getAPCElgCountries()),
  getAPCElgContentTypes: () => dispatch(getAPCElgContentTypes()),
  getAPCElgSocieties: () => dispatch(getAPCElgSocieties()),
  submitAPCEligibility: (payload) => dispatch(submitAPCEligibility(payload)),
});

export const CheckAPCEligibility = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckAPCEligibilityComponent);
