import React from 'react';

export const PrimaryPublisherCard = (props) => {
    let icon = '';
    if (props.icon) {
        icon = (
            <div className=" mini-stat-icon">
                <i className={`${props.icon} float-right`}></i>
            </div>
        )
    }
    return (
        <div className={`${props.className || 'col-xl-3 col-md-6'}`}>
            <div className="card mini-stat bg-primary flot-chart-height">
                <div className="card-body mini-stat-img">
                    {icon}
                    <div className="text-white">
                        <h6 className="text-uppercase text-white mb-3">{props.title}</h6>
                        {typeof props.body === "object" ? props.body.map((content) => {
                            return <p className="mb-0" key={content.toString()}>{content}</p>
                        }) : <p className="mt-5">{props.body}</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}