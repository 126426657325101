import { api } from "../../core/api";
import config, {
  OA_TOKEN_TRXN,
  // OFFSET_FUND_TRXN,
  DEPOSIT_FUND_PUB,
  DEPOSIT_OFFSET_PUB,
  PAYMENT_TRANSACTION_PUB,
  GET_ORGS_TOKEN_DOWNLOAD,
  OFFSET_FUND_DOWNLOAD,
  DEPOSIT_FUND_TRANX_SEARCH,
  OFFSET_TRANSACTION_SEARCH,
  TOKEN_FUND_TRANX_SEARCH,
  FAILED_DEPOSIT_FUND_TRXN,
  SUCCESS_DEPOSIT_FUND_TRXN,
  REQUEST_DEPOSIT_FUND_TRXN,
} from "../../config";

const OaToken = (payload) => ({
  type: OA_TOKEN_TRXN,
  payload,
});

const requestDepositFund = () => ({
  type: REQUEST_DEPOSIT_FUND_TRXN,
});
const successDepositFund = (payload) => ({
  type: SUCCESS_DEPOSIT_FUND_TRXN,
  payload,
});
const failureDepositFund = (payload) => ({
  type: FAILED_DEPOSIT_FUND_TRXN,
  payload,
});
// const OffsetFund = payload => ({
//   type: OFFSET_FUND_TRXN,
//   payload,
// })
const tokendownload = (payload) => ({
  type: GET_ORGS_TOKEN_DOWNLOAD,
  payload,
});
const OffsetFundDownload = (payload) => ({
  type: OFFSET_FUND_DOWNLOAD,
  payload,
});

const OaTokenSearch = (payload) => ({
  type: TOKEN_FUND_TRANX_SEARCH,
  payload,
});

const DepositFundSearch = (payload) => ({
  type: DEPOSIT_FUND_TRANX_SEARCH,
  payload,
});
const OffsetFundSearch = (payload) => ({
  type: OFFSET_TRANSACTION_SEARCH,
  payload,
});

export const getTokenDownload = () => {
  const url = `${config.endpoint.oa_token_trxn}download?fields=date,publisher_name,institution_name,reference,token_id,currency_or_discount,amount_or_discount,article_id,article_title&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(tokendownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

export const OaTokenTrxn = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.oa_token_trxn}?fields=oa-token/?fields=publisher_name,article_info,article_title,institution_name,date_created,transaction_type,currency,amount,article_id,token_info&page=${payload.pageNum}&page_size=${payload.pageSize}`;
    api.get(url).then(
      (res) => {
        dispatch(OaToken(res));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(OaToken({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(OaToken({ success: null })); // Unknown Error
        } else {
          dispatch(OaToken({ success: false })); // No Internet Connection
        }
      }
    );
  };
};

export const DepositFundTrxn = (payload) => {
  return (dispatch) => {
    const params = payload ? payload.params || "" : "";
    const filter = payload ? payload.filter || "" : "";
    let url = `${config.endpoint.deposit_credit_trxn}?${
      params && params + "&"
    }${filter}`;
    dispatch(requestDepositFund());

    api.get(url).then(
      (res) => {
        dispatch(successDepositFund(res));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(failureDepositFund({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(failureDepositFund({ success: null })); // Unknown Error
        } else {
          dispatch(failureDepositFund({ success: false })); // No Internet Connection
        }
      }
    );
  };
};

// export const OffsetFundTrxn = () => {
//   return dispatch => {
//     let url = config.endpoint.offset_fund_trxn
//     api.get(url).then(
//       res => {
//         dispatch(OffsetFund(res))
//       },
//       error => {
//         if (error.code === 'ECONNABORTED') {
//           dispatch(OffsetFund({ success: undefined })) // Timeout
//         } else if (error.response) {
//           dispatch(OffsetFund({ success: null })) // Unknown Error
//         } else {
//           dispatch(OffsetFund({ success: false })) // No Internet Connection
//         }
//       },
//     )
//   }
// }
export const OffsetpubTrxnDownload = () => {
  const url = `${config.endpoint.offset_fund_trxn}download?fields=date,publisher_name,institution_name,currency_name,amount,article_title&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(OffsetFundDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Pub Transction

const DepositFundPub = (payload) => ({
  type: DEPOSIT_FUND_PUB,
  payload,
});

const OffsetPub = (payload) => ({
  type: DEPOSIT_OFFSET_PUB,
  payload,
});
export const DepositFundTrxnPub = () => {
  return (dispatch) => {
    let url = config.endpoint.deposit_fund;
    api.get(url).then(
      (res) => {
        dispatch(DepositFundPub(res));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(DepositFundPub({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(DepositFundPub({ success: null })); // Unknown Error
        } else {
          dispatch(DepositFundPub({ success: false })); // No Internet Connection
        }
      }
    );
  };
};

export const DepositPubTrxnOffset = (payload) => {
  return (dispatch) => {
    let url = payload.pageNum
      ? `${config.endpoint.offset_fund_trxn}?page=${payload.pageNum}&page_size=${payload.pageSize}`
      : `${config.endpoint.offset_fund_trxn}?${payload}`;
    api.get(url).then(
      (res) => {
        dispatch(OffsetPub(res));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(OffsetPub({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(OffsetPub({ success: null })); // Unknown Error
        } else {
          dispatch(OffsetPub({ success: false })); // No Internet Connection
        }
      }
    );
  };
};

export const DepositTrxnOffsetSearchList = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.offset_fund_trxn}?page=${payload.pageNum}&page_size=${payload.pageSize}`;
    api.get(url).then(
      (res) => {
        dispatch(DepositFundSearch(res));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(DepositFundSearch({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(DepositFundSearch({ success: null })); // Unknown Error
        } else {
          dispatch(DepositFundSearch({ success: false })); // No Internet Connection
        }
      }
    );
  };
};

export const TokenTrxnOffsetSearchList = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.offset_fund_trxn}?page=${payload.pageNum}&page_size=${payload.pageSize}`;
    api.get(url).then(
      (res) => {
        dispatch(OaTokenSearch(res));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(OaTokenSearch({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(OaTokenSearch({ success: null })); // Unknown Error
        } else {
          dispatch(OaTokenSearch({ success: false })); // No Internet Connection
        }
      }
    );
  };
};
export const OffsetTrxnOffsetSearchList = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.offset_fund_trxn}?page=${payload.pageNum}&page_size=${payload.pageSize}`;
    api.get(url).then(
      (res) => {
        dispatch(OffsetFundSearch(res));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(OffsetFundSearch({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(OffsetFundSearch({ success: null })); // Unknown Error
        } else {
          dispatch(OffsetFundSearch({ success: false })); // No Internet Connection
        }
      }
    );
  };
};

// // Loaded into App Reducers
// export const getPaymentSummary = (payload) => ({
//     type: ARTICLE_PAYMENT_SUMMARY,
//     payload
// })

// // Loaded into App Reducers
// export const paymentBillingAddressReducer = (payload) => ({
//     type: PAYMENT_BILLING_ADDRESS,
//     payload
// })

// Loaded into App Reducers
export const paymentTransactionReducerPub = (payload) => ({
  type: PAYMENT_TRANSACTION_PUB,
  payload,
});

// // Get Article Payment Summary
// export const articlePaymentSummary = (payload) => {
//     return (dispatch) => {
//         const url = payload.isParam ? `${config.endpoint.article_payment_summary}${payload.id}/?currency_code=${payload.currency_code}` : `${config.endpoint.article_payment_summary}${payload.id}`;
//         return new Promise((resolve, reject) => {
//             api.get(url).then((res) => {
//               dispatch(getPaymentSummary(res))
//               resolve(Object.assign(res, { status: true }));
//             }).catch(e => {
//               reject(e)
//             })
//         })
//     }
// }

// Get article payment transaction
export const paymentTransactionPub = (payload) => {
  return (dispatch) => {
    const url = `${config.endpoint.payment_transaction}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(paymentTransactionReducerPub(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// export const DepositoffsetDownload = (payload) => {
//   const params = payload ? (payload.params || '') : '';
//   const filter = payload ? (payload.filter || '') : '';
//   const url = config.endpoint.organisation_offset__fund_download + `?fields=currency&${params && params + '&'}${filter}`;

//   return (dispatch, getState) => {
//     const token = getState().auth.token;
//     let options = {
//       responseType: 'blob',
//     };

//     return new Promise((resolve, reject) => {
//       api.get(url, token, options).then((res) => {
//         dispatch(OffsetFundDownload(res));
//         resolve(Object.assign(res, { status: true }));
//       }).catch(function (error) {
//         reject(Object.assign(error.response, { status: false }));
//       })
//     })
//   }
// }
