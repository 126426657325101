import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { getOrganisationList, deleteOrg } from "../../../../actions";
import {
  PopupBox,
  Loading,
  InputGroup,
} from "../../../../../core/components/common";

class UL extends BaseComponent {
  state = {
    deleteConfirm: false,
    orgId: null,
    waitingMsg: null,
    deleteStatus: null,
    deleteing: false,
  };
  async componentDidMount() {
    this.getOrgData();
  }

  getOrgData = async (payload = {}) => {
    payload.pageNum = payload.page || 1;

    if (!payload.pageSize) {
      payload.pageSize = 10;
    }

    await this.props.getOrganisationList(payload);
    let orgObj = this.props.orgs;
    let dataOrg = [];
    orgObj && orgObj.map((item) => {
      return dataOrg.push({
        organization_name: item.name,
        email_domain: item.email_domain,
        group: item.dommain_name,
        grid_number: item.meta ? item.meta.grid_number : "",
        country: item.address ? item.address.country_name : "",
        id: item.id,
      });
    });
    this.setState({
      data: dataOrg,
    });
  };

  onEditOrg = (id) => {
    this.props.history.push("/organizations/edit-org/" + id);
  };

  onView = (id) => {
    this.props.history.push("/organizations/single-org/" + id);
  };

  onDeleteOrg = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        orgId: id,
      });
    }
  };

  onDeleteOrgHander = async () => {
    const orgId = this.state.orgId;
    this.setState({
      deleteing: true,
      waitingMsg: "Deleting...",
    });
    const org = await this.props.deleteOrg(orgId);
    this.setState({
      waitingMsg: null,
      orgId: null,
      deleteConfirm: false,
      deleteStatus: org.status,
      deleteing: false,
    });
  };

  filterData = async (value) => {
    value.page = 1;

    this.setState({
      ...value,
    });

    this.getOrgData(value);
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      orgId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };
  pageChange = (pageNum) => {
    let payload = {
      page: pageNum,
    };

    if (this.state.q) {
      payload["q"] = this.state.q;
    }

    this.getOrgData(payload);
  };
  onArticleDownloadClick = async () => {
    // try {
    //   let params = await serialize(this.state.params);
    //   let filter = await serialize(this.state.filterParams);
    //   await this.props.articleDownload({ params, filter });
    //   const url = window.URL.createObjectURL(this.props.article_download_data);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   var d = new Date();
    //   link.setAttribute(
    //     "download",
    //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (error) {
    
    // }
  };
  render() {
    const heading = [
      "organization_name",
      "group",

      "email_domain",
      "grid_number",
      "country",
    ];
    return (
      <HubContent
        title="Organization List"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteOrgHander}
            waitingMsg={this.state.waitingMsg}
            deleting={this.state.deleteing}
          />
        )}

        {this.state.deleteStatus && (
          <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
        )}

        {this.state.deleteStatus === false && (
          <PopupBox
            title="Failed... Try Again"
            onCancel={this.onCancel}
            NoBtnType="danger"
          />
        )}

        <div className="row filter">
          <div className="col-md-4">
            <InputGroup
              onClick={this.filterData}
              field="q"
              placeholder="Search"
              className="col-md-12 mb-2 free-text-search"
            />
          </div>{" "}
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data ? (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditOrg}
                  onDelete={this.onDeleteOrg}
                  onView={this.onView}
                  count={this.props.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  orgs: state.app.organizations || false,
  count: state.orgs.org_list_count || false,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganisationList: (payload) => dispatch(getOrganisationList(payload)),
  deleteOrg: (id) => dispatch(deleteOrg(id)),
});

export const OrgList = connect(mapStateToProps, mapDispatchToProps)(UL);
