import React from "react";
import { connect } from "react-redux";

import {
  SelectDropDownBox,
  InputBox,
  Button as Buttons,
  PopupBox

} from "../../../../../core/components/common";

import { HubContent } from "../../../../../core/components";
import {
  getAllCountry,
  getVatRatesPubVatConfig,
  getStatePubVatConfig,
  getCAStatePubVatConfig,
  publisherVatCreateConfigure,
  createVatConfig,
  createUpdateVatConfig,
  getSingleVatConfig,
  getVatConfig,
  DeleteVatConfig,
  publisherGetAllVatCreateConfigure
} from "../../../../actions";
import StateData from "./vatStateData";
import { Steps, Button } from "antd";
import { PoweroffOutlined } from '@ant-design/icons';

import {
  vatUsaStatesApiData,
  vatUsaCountryApiData,
  vatSelfCountryApiData,
  vatEuBillingApiData,
  vatCanadaCountryApiData,
  vatCanadaProviceApiData,
  restVatOrTaxesApiData,
  VatRestBillingApiData,
  VatWavierBillingApiData,
  vatDataCountry,
  vatStatesNProvince, 
  vatSubmitSelfCountry,
  vatSubmittedBillingAddressData,
  vatSubmittedCountryVatOrTaxesData,
  VatSubmittedRestBillingApiData,
  EUStateDataFormat,
  canadaStateDataFormat,
  USAStateDataFormat
} from "./Data/vatUtils"

import {VatUSA,VatSelfCountryName,VatRestOfTheCountry,VatEu,VatCanada} from "../vat/Data/index"

const { Step } = Steps;


class VatForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = StateData;
  }
  componentDidMount() {
    this.apiDataList()
  }
  apiDataList =async () => {
    this.props.getAllCountry();
    const data = await this.props.getVatConfig();
    const vatData = Object.assign({}, data.results);
   
   
      this.setState({
        id: vatData && vatData[0] && vatData[0].id ? vatData[0].id : null,
        vat_number:
          vatData && vatData[0] && vatData[0].vat_number
            ? vatData[0].vat_number
            : null,
      });
    
  }
 
  // ONCHANGE METHOD FOR RADIO BOX
  onChangeHandleRadioBox = (value) => {
    this.setState({
      ...value,
    });
  };

  next = () => {
    const current = this.state.current + 1; 

    this.setState({ current });
  }

  prev = () =>{
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onChangeSelect = (value) => {
    let data;
    data = this.props.countries
      .filter((item) => item.id === value.country)
      .map((item) => item);
    this.setState({
      ...value,
    });

    //For SELF COUNTRY
    this.props
      .getVatRatesPubVatConfig({ country: value.country })
      .then((res) => {
        let countryVatList =
          res.results &&
          res.results.map((item) => {
            delete item.vat_rate;

            return item;
          });
        this.setState({
          selfCountryVat_1: countryVatList,
          selfCountryVat_2: countryVatList,
          selfCountryVat_3: countryVatList,
          selfCountryVat_4: countryVatList,
        });
      });

    //For EU COUNTRY
    this.props
      .getVatRatesPubVatConfig({ eucontext: "EU", id: value.country })
      .then((res) => {
        let countryVatList =
          res.results &&
          res.results.map((item) => {
            delete item.vat_rate;

            return item;
          });

        this.setState({
          vatforeu: countryVatList,
        });
      });
    //For US COUNTRY
    this.props.getVatRatesPubVatConfig({ context: "US" }).then((res) => {
      this.props.getStatePubVatConfig(res.results[0].id);
      let countryVatList =
        res.results &&
        res.results.map((item) => {
          delete item.vat_rate;

          return item;
        });

      this.setState({
        vatforus: countryVatList,
      });
    });

    //For CA COUNTRY
    this.props.getVatRatesPubVatConfig({ context: "CA" }).then((res) => {
      this.props.getCAStatePubVatConfig(res.results[0].id);
      let countryVatList =
        res.results &&
        res.results.map((item) => {
          delete item.vat_rate;

          return item;
        });

      this.setState({
        vatforca: countryVatList,
      });
    });
    //For CA COUNTRY
    this.props
      .getVatRatesPubVatConfig({ context: "OTHER_COUNTRY" })
      .then((res) => {
        let countryVatList =
          res.results &&
          res.results.map((item) => {
            delete item.vat_rate;

            return item;
          });

        this.setState({
          other_countries: countryVatList,
        });
      });
    if (data[0].name !== "United States" && data[0].name !== "Canada") {
      this.setState({
        tab_1: data[0].name,
        tab_2: "EU",
        tab_3: "United States",
        tab_4: "Canada",
        tab_5: "Rest of the World",
        origin: data[0].name,
      });
    } else if (data[0].name === "United States") {
      this.setState({
        tab_1: "United States",
        tab_2: "EU",
        tab_3: "Canada",
        tab_4: "Rest of the World",
      });
    } else {
      this.setState({
        tab_1: "Canada",
        tab_2: "EU",
        tab_3: "United States",
        tab_4: "Rest of the World",
      });
    }
  };

  onSubmit = async () => {
    let vatRatesForCharges;
    let vatRatesNotForCharges;
    let vatState;
    let vatnocharge;
    let statePosition;

    //for Rest of the World
    let billingaddressforrest;
    let othercountires;
    let othercountireswaive;

    //VATNOCHARGE DUPLICATE LOGIC

    if (this.state.charge_vat_number === true) {
      if (this.state.charge_no_vat_number_1 === true) {
        vatnocharge = this.state.charge_no_vat_number_1;
      } else {
        vatnocharge = this.state.charge_no_vat_number_1;
      }
    } else if (this.state.charge_vat_number === false) {
      if (this.state.not_charge_vat_number_2 === true) {
        vatnocharge = this.state.not_charge_vat_number_2;
      } else {
        vatnocharge = this.state.not_charge_vat_number_2;
      }
    }

    //VAT RATES
    const vat_country_self = vatSubmitSelfCountry(this.state.selfCountryVat_1);
    const vat_country_self_2 = vatSubmitSelfCountry(this.state.selfCountryVat_2);
  
    if (this.state.charge_vat_number === true) {
      vatRatesForCharges = vat_country_self;
      if (this.state.charge_no_vat_number_1 === true) {
        vatRatesNotForCharges = vat_country_self_2;
      }
    } else if (this.state.charge_vat_number === false) {
      if (this.state.not_charge_vat_number_2 === true) {
        vatRatesNotForCharges = vat_country_self_2;
      }
    }

    //  FOR EU COUNTRY
    let datanotvalidboolean;

    if (this.state.charge_vat_number_eu === true) {
      if (this.state.charge_no_vat_number_eu_1) {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_1;
      } else {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_1;
      }
    } else if (this.state.charge_vat_number_eu === false) {
      if (this.state.charge_no_vat_number_eu_2) {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_2;
      } else {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_2;
      }
    }

    let chargebilling_address;
    let ratesbasedonAddressnotvat;
    let ratesbasedonAddress;

      let billingAddress2 = vatSubmittedBillingAddressData(this.state.billingAdress2)
      let billingAddress3 = vatSubmittedBillingAddressData(this.state.billingAdress3)
      let billingAddress = vatSubmittedBillingAddressData(this.state.billingAdress)
      let vat_country_self_3 = vatSubmitSelfCountry(this.state.selfCountryVat_3 )
      let vat_country_self_4 = vatSubmitSelfCountry(this.state.selfCountryVat_4 )
      
      
    if (this.state.charge_vat_number_eu === true) {
      if (this.state.charge_vat_billing_address_eu_1 === true) {
        chargebilling_address = "vat:customer_billing_address";
        ratesbasedonAddress = billingAddress; //rates_vat //billing address

        if (this.state.charge_no_vat_number_eu_1 === true) {
          if (this.state.charge_no_vat_billing_address_eu_2 === true) {
            chargebilling_address =
              "vat:customer_billing_address,no_vat:customer_billing_address";
            ratesbasedonAddressnotvat = billingAddress2; //rates_no_vat but billing address
          } else {
            chargebilling_address =
              "vat:customer_billing_address,no_vat:publisher_location";
            ratesbasedonAddressnotvat = vat_country_self_4; //rates_no_vat
          }
        }
      } else {
        chargebilling_address = "vat:publisher_location";
        ratesbasedonAddress = vat_country_self_3; //rates_vat
        if (this.state.charge_no_vat_number_eu_1 === true) {
          if (this.state.charge_no_vat_billing_address_eu_2 === true) {
            chargebilling_address =
              "vat:publisher_location,no_vat:customer_billing_address";
            ratesbasedonAddressnotvat = billingAddress2; // rates_no_vat
          } else {
            chargebilling_address =
              "vat:publisher_location,no_vat:publisher_location";
            ratesbasedonAddressnotvat = vat_country_self_4; //rates_no_vat
          }
        }
      }
    } else if (this.state.charge_vat_number_eu === false) {
      if (this.state.charge_no_vat_number_eu_2 === true) {
        if (this.state.charge_no_vat_billing_address_eu_3 === true) {
          chargebilling_address = "no_vat:customer_billing_address";
          ratesbasedonAddressnotvat = billingAddress3; // no_vates
        } else {
          chargebilling_address = "no_vat:publisher_location";
          ratesbasedonAddressnotvat = vat_country_self_4; //no_vates
        }
      }
    }

    // LET SETUP CONTEX RULES FOR USA COUNTRY

    let notcharge;
    let dataStates = vatStatesNProvince(this.state.SelectedDataBasedonStates_1);
    let dataStates2 = vatStatesNProvince(this.state.SelectedDataBasedonStates_4);
    let dataStates3 = vatStatesNProvince(this.state.SelectedDataBasedonStates_2);
    let dataCountry = vatDataCountry(this.state.SelectedDataBasedonCountry_1 )
    let dataCountry2 = vatDataCountry(this.state.SelectedDataBasedonCountry_4 )
    let dataCountry3 = vatDataCountry(this.state.SelectedDataBasedonCountry_2 )
    
    let vatnostate;
    if (this.state.charge_vat_number_usa === true) {
      if (this.state.charge_vat_states_usa === true) {
        vatState = dataStates;
      } else {
        vatState = dataCountry;
      }
    } else if (this.state.charge_vat_number_usa === false) {
      if (this.state.charge_no_vat_number_usa_2 === true) {
        if (this.state.charge_no_vat_states_usa_2 === true) {
          vatnostate = dataStates2;
        } else {
          vatnostate = dataCountry2;
        }
      }
    }

    if (this.state.charge_vat_number_usa === true) {
      if (this.state.charge_no_vat_states_usa_2 === true) {
        statePosition = this.state.charge_vat_states_usa;
      } else {
        statePosition = this.state.charge_vat_states_usa;
      }
    } else if (this.state.charge_vat_number_usa === false) {
      if (this.state.charge_no_vat_number_usa_2 === true) {
        if (this.state.charge_no_vat_states_usa_2 === true) {
          statePosition = this.state.charge_no_vat_states_usa_2;
        } else {
          statePosition = this.state.charge_no_vat_states_usa_2;
        }
      } else if (this.state.charge_no_vat_number_usa_2 === false) {
        statePosition = null;
      }
    }

    let charge_no_vat_states;
    if (this.state.charge_vat_number_usa === true) {
      if (this.state.charge_vat_states_usa === true) {
        if (this.state.charage_no_vat_usa === true) {
          notcharge = this.state.charage_no_vat_usa;
          if (this.state.charge_no_vat_states_usa === true) {
            charge_no_vat_states = this.state.charge_no_vat_states_usa;
            vatnostate = dataStates3;
          } else {
            charge_no_vat_states = this.state.charge_no_vat_states_usa;

            vatnostate = dataCountry3;
          }
        } else {
          charge_no_vat_states = null;
          notcharge = this.state.charage_no_vat_usa;
        }
      } else {
        if (this.state.charage_no_vat_usa === true) {
          notcharge = this.state.charage_no_vat_usa;
          if (this.state.charge_no_vat_states_usa === true) {
            charge_no_vat_states = this.state.charge_no_vat_states_usa;
            vatnostate = dataStates3;
          } else {
            charge_no_vat_states = this.state.charge_no_vat_states_usa;

            vatnostate = dataCountry3;
          }
        } else {
          charge_no_vat_states = null;
          notcharge = this.state.charage_no_vat_usa;
        }
      }
    } else {
      // charge_no_vat_states = null;
      notcharge = this.state.charge_no_vat_number_usa_2;
    }

    // LET SETUP CONTEX RULES FOR CA COUNTRY

    let CanadaCountry_1 = vatDataCountry(  this.state.selecteddatabasedoncanadacountry_1)
    let CanadaCountry_2 = vatDataCountry(  this.state.selecteddatabasedoncanadacountry_2)
    let CanadaCountry_3 = vatDataCountry(  this.state.selecteddatabasedoncanadacountry_3)
    let CanadaCountry_4 = vatDataCountry(  this.state.selecteddatabasedoncanadacountry_4)

    let CanadaProvince_1 = vatStatesNProvince(
        this.state.selecteddatabasedonprovince_1
      );
      let canadaprovince_2 = vatStatesNProvince(
        this.state.selecteddatabasedonprovince_2
      );
    
      let canadaprovince_3 = vatStatesNProvince(
        this.state.selecteddatabasedonprovince_3
      );
      let canadaProvince_4 = vatStatesNProvince(
        this.state.selecteddatabasedonprovince_4
      );
    
    let charge_no_vat_canada;
    let vatprovincenorate;
    let vatprovincerate;
    let statePositionforca;
    let no_charge_vat_province;

    if (this.state.charge_vat_number_canada === true) {
      if (this.state.proviencebasedoncharge === true) {
        vatprovincerate = CanadaProvince_1;
      } else {
        vatprovincerate = CanadaCountry_1;
      }
    } else if (this.state.charge_vat_number_canada === false) {
      if (this.state.charge_no_vat_canada_3 === true) {
        if (this.state.provience_basedon_vat_no_charge_3 === true) {
          vatprovincenorate = canadaProvince_4;
        } else {
          vatprovincenorate = CanadaCountry_4;
        }
      }
    }

    if (this.state.charge_vat_number_canada === true) {
      if (this.state.provience_basedon_vat_no_charge_3 === true) {
        statePositionforca = this.state.proviencebasedoncharge;
      } else {
        statePositionforca = this.state.proviencebasedoncharge;
      }
    } else if (this.state.charge_vat_number_canada === false) {
      if (this.state.charge_no_vat_canada_3 === true) {
        if (this.state.provience_basedon_vat_no_charge_3 === true) {
          statePositionforca = this.state.provience_basedon_vat_no_charge_3;
        } else {
          statePositionforca = this.state.provience_basedon_vat_no_charge_3;
        }
      } else if (this.state.charge_no_vat_canada_3 === false) {
        statePositionforca = null;
      }
    }

    if (this.state.charge_vat_number_canada === true) {
      if (this.state.proviencebasedoncharge === true) {
        if (this.state.charge_no_vat_canada_1 === true) {
          charge_no_vat_canada = this.state.charge_no_vat_canada_1;
          if (this.state.provience_basedon_vat_no_charge_1 === true) {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_1;
            vatprovincenorate = canadaprovince_2;
          } else {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_1;
            vatprovincenorate = CanadaCountry_2;
          }
        } else {
          no_charge_vat_province = null;
          charge_no_vat_canada = this.state.charge_no_vat_canada_1;
        }
      } else {
        if (this.state.charge_no_vat_canada_2 === true) {
          charge_no_vat_canada = this.state.charge_no_vat_canada_2;
          if (this.state.provience_basedon_vat_no_charge_2 === true) {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_2;
            vatprovincenorate = canadaprovince_3;
          } else {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_2;
            vatprovincenorate = CanadaCountry_3;
          }
        } else {
          no_charge_vat_province = null;

          charge_no_vat_canada = this.state.charge_no_vat_canada_2;
        }
      }
    } else {
      charge_no_vat_canada = this.state.charge_no_vat_canada_3;
    }

    //LETS SETUP FOR Rest of the World
    let vatOrSalesTax;
    let ChargeCustomerBilling;

    const waivercountry =
      this.state.countrychargewaive &&
      this.state.countrychargewaive.map((item) => ({
        country: item.id,

      })); 
    let VatRestBillingApiData_1 = VatSubmittedRestBillingApiData(this.state.billingaddressCountry)

let VatRestBillingApiData_2 = VatSubmittedRestBillingApiData(this.state.billingaddressCountry2)
let countryvatortaxesvatrate2 = vatSubmittedCountryVatOrTaxesData(
  this.state.countryvatortaxes2
);

let countryvatortaxesvatrate = vatSubmittedCountryVatOrTaxesData(
  this.state.countryvatortaxes
);



    if (this.state.waive_countries_charge_vat === true) {
      othercountireswaive = waivercountry;
      if (this.state.other_countries_vat_charge_1 === true) {
        vatOrSalesTax = this.state.other_countries_vat_charge_1;
        othercountires = countryvatortaxesvatrate;
        if (this.state.charge_policy_other_coutries_1 === true) {
          ChargeCustomerBilling = this.state.charge_policy_other_coutries_1;
          billingaddressforrest = VatRestBillingApiData_1;
        } else {
          ChargeCustomerBilling = this.state.charge_policy_other_coutries_1;
          billingaddressforrest = null;
        }
      } else {
        othercountires = null;
      }
    } else if (this.state.waive_countries_charge_vat === false) {
      if (this.state.vat_tax_other_countries_charge2 === true) {
        vatOrSalesTax = this.state.vat_tax_other_countries_charge2;
        othercountires = countryvatortaxesvatrate2;
        if (this.state.charge_policy_other_coutries_2 === true) {
          ChargeCustomerBilling = this.state.charge_policy_other_coutries_2;

          billingaddressforrest = VatRestBillingApiData_2;
        } else {
          ChargeCustomerBilling = this.state.charge_policy_other_coutries_2;
          billingaddressforrest = null;
        }
      } else {
        othercountires = null;
      }
    }
    //new context rules
    let contextrules;

    if (this.state.tab_1 !== "United States" && this.state.tab_1 !== "Canada") {
      contextrules = [
        {
          country: this.state.country,

          charge_vat: this.state.charge_vat_number,
          charge_no_vat: vatnocharge,
          charge_based_on_states_vat: null,
          charge_based_on_states_no_vat: null,
          context: "SELF",
          charge_policy: null,
          vat_based_billing_address: false,
          no_vat_based_billing_address: false,
          rates_vat: vatRatesForCharges ? vatRatesForCharges : [],
          rates_no_vat: vatRatesNotForCharges ? vatRatesNotForCharges : [],
        },

        {
          context: "EU",
          ...EUStateDataFormat(this.state.charge_vat_number_eu,datanotvalidboolean,chargebilling_address,ratesbasedonAddress,ratesbasedonAddressnotvat),

        },

        {
          ...USAStateDataFormat(this.state.charge_vat_number_usa,notcharge,statePosition,charge_no_vat_states,vatState,vatnostate)

        },
        {
          ...canadaStateDataFormat(this.state.charge_vat_number_canada,charge_no_vat_canada,statePositionforca,no_charge_vat_province,vatprovincerate,vatprovincenorate)
        },
      ];
    } else if (this.state.tab_1 === "United States") {
      contextrules = [
        {
          context: "SELF",
          charge_vat: this.state.charge_vat_number_usa,
          charge_no_vat: notcharge,
          charge_based_on_states_vat: statePosition,
          charge_based_on_states_no_vat: charge_no_vat_states
            ? charge_no_vat_states
            : null,
          country: this.state.country,
          charge_policy: null,
          rates_vat: vatState ? vatState : [],
          rates_no_vat: vatnostate ? vatnostate : [],
        },
        {
          context: "EU",
          ...EUStateDataFormat(this.state.charge_vat_number_eu,datanotvalidboolean,chargebilling_address,ratesbasedonAddress,ratesbasedonAddressnotvat),
        },
        {
          ...canadaStateDataFormat(this.state.charge_vat_number_canada,charge_no_vat_canada,statePositionforca,no_charge_vat_province,vatprovincerate,vatprovincenorate)

        },
      ];
    } else if (this.state.tab_1 === "Canada") {
      contextrules = [
        {
          context: "SELF",
          charge_vat: this.state.charge_vat_number_canada,
          charge_no_vat: charge_no_vat_canada,
          charge_based_on_states_vat: statePositionforca,
          charge_based_on_states_no_vat: no_charge_vat_province,
          country: this.state.country,
          charge_policy: null,
          rates_vat: vatprovincerate ? vatprovincerate : [],
          rates_no_vat: vatprovincenorate ? vatprovincenorate : [],
        },

        {
          context: "EU",
          ...EUStateDataFormat(this.state.charge_vat_number_eu,datanotvalidboolean,chargebilling_address,ratesbasedonAddress,ratesbasedonAddressnotvat),
        },

        {
          ...USAStateDataFormat(this.state.charge_vat_number_usa,notcharge,statePosition,charge_no_vat_states,vatState,vatnostate)
        },
      ];
    }

    const data = {
      country: this.state.country,
      has_other_country_vat: vatOrSalesTax ? vatOrSalesTax : false,
      has_other_country_charge_policy: ChargeCustomerBilling
        ? ChargeCustomerBilling
        : false,
      apc_include_vat: this.state.apc_including_vat
        ? this.state.apc_including_vat
        : false,
      other_country_vat: othercountires ? othercountires : [],
      rules: this.state.apc_including_vat === true ? [] : contextrules,
      other_country_chg_policies: billingaddressforrest
        ? billingaddressforrest
        : [],
    };
    const offices = this.state.offices.concat(data);
    //APC INCLUDING VAT
    const mainData = {
      offices,
      other_waived_countries: othercountireswaive ? othercountireswaive : [],
    };

    const contexData = {
      offices: [
        {
          apc_include_vat: this.state.apc_including_vat,
          country: this.state.country,
          other_country_vat: [],
          rules: [],
          has_other_country_vat: false,
          has_other_country_charge_policy: true,
          other_country_chg_policies: [],
        },
      ],
      waive_other_country_vat: false,
      other_waived_countries: [],
    };

    let apc_include_data = this.state.apc_including_vat ? contexData : mainData;
    let result = await this.props.publisherVatCreateConfigure(apc_include_data);
    if (result.status === true) {
      this.setState({
        success: true,
        createVate:result &&result.data
      });
    }
  };

  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push("/settings/vat-configuration", {
      id:this.state.createVate && this.state.createVate.id
    });
  }
;


  selectCountryBillingAddressforeu = (props) => {
    this.setState({ billingAdress: props });
  };

  selectCountryBillingAddressforeu2 = (props) => {
    this.setState({ billingAdress2: props });
  };

  selectCountryBillingAddressforeu3 = (props) => {
    this.setState({ billingAdress3: props });
  };

  //Multi CheckBox Selected Method FOR USA
  SelectCheckBoxBasedOnCountry_1 = (props) => {
    this.setState({ SelectedDataBasedonCountry_1: props });
  };
  SelectCheckBoxBasedOnCountry_2 = (props) => {
    this.setState({ SelectedDataBasedonCountry_2: props });
  };
  SelectCheckBoxBasedOnCountry_4 = (props) => {
    this.setState({ SelectedDataBasedonCountry_4: props });
  };

  SelectCheckBoxBasedOnStates_1 = (props) => {
    this.setState({ SelectedDataBasedonStates_1: props });
  };
  SelectCheckBoxBasedOnStates_2 = (props) => {
    this.setState({ SelectedDataBasedonStates_2: props });
  };

  SelectCheckBoxBasedOnStates_4 = (props) => {
    this.setState({ SelectedDataBasedonStates_4: props });
  };

  //Multi CheckBox Selected Method FOR CA

  SelectCheckBoxBasedOnProvince_1 = (props) => {
    this.setState({ selecteddatabasedonprovince_1: props });
  };
  SelectCheckBoxBasedOnProvince_2 = (props) => {
    this.setState({ selecteddatabasedonprovince_2: props });
  };

  SelectCheckBoxBasedOnProvince_3 = (props) => {
    this.setState({ selecteddatabasedonprovince_3: props });

  };
  SelectCheckBoxBasedOnProvince_4 = (props) => {
    this.setState({ selecteddatabasedonprovince_4: props });
  };

  SelectCheckBoxBasedCanadaCountry_1 = (props) => {
    this.setState({
      selecteddatabasedoncanadacountry_1: props });
  };

  SelectCheckBoxBasedCanadaCountry_2 = (props) => {
    this.setState({ selecteddatabasedoncanadacountry_2: props });
  };
  SelectCheckBoxBasedCanadaCountry_3 = (props) => {
    this.setState({ selecteddatabasedoncanadacountry_3: props });
  };

  SelectCheckBoxBasedCanadaCountry_4 = (props) => {
    this.setState({ selecteddatabasedoncanadacountry_4: props });
  };

  //Rest of the World
  selectCountrywaiveCountry = (props) => {
    this.setState({ countrychargewaive: props });
  };
  selectCountryVATorTaxesCountry = (props) => {
    this.setState({ countryvatortaxes: props });
  };
  selectCountryVATorTaxesCountry2 = (props) => {
    this.setState({ countryvatortaxes2: props });
  };
  selectCountrybillingaddressCountry = (props) => {
    this.setState({ billingaddressCountry: props });
  };
  selectCountrybillingaddressCountry2 = (props) => {
    this.setState({ billingaddressCountry2: props });
  };

  onSubmitVatForPublisher = async () => {
    const data = await this.props.createVatConfig(this.state.vat_number);
    this.setState({
      id: data.data.id,
      vat_number: data.data.vat_number,
    });
  };
  updateHandle = async () => {
    await this.props.createUpdateVatConfig({
      vat_number: this.state.vat_number,
      id: this.state.id,
    });
    this.setState({
      enable: true,
    });
  };
  onHandleDelete = async (id) => {
    await this.props.DeleteVatConfig(id);


    const data = await this.props.getVatConfig();
    const vatData = Object.assign({}, data.results);
    this.setState({
      id: vatData && vatData[0] && vatData[0].id ? vatData[0].id : null,
      vat_number:
        vatData && vatData[0] && vatData[0].vat_number
          ? vatData[0].vat_number
          : null,
    });
  };

  onTextFieldChange = (value) => {
    this.setState((prevState) => Object.assign(prevState.vat_number, value));
  };
  onTextField = (value) => {
    this.setState({
      vat_number: value,
    });
  };

  EditHandler = (value) => {
    this.setState({
      selfCountryVat_1: value,
    });
  };
  EditHandler_2 = (value) => {
    this.setState({
      selfCountryVat_2: value,
    });
  };

  EditHandler_3 = (value) => {
    this.setState({
      selfCountryVat_3: value,
    });
  };
  EditHandler_4 = (value) => {
    this.setState({
      selfCountryVat_4: value,
    });
  };
  //EU
  EditHandlerforeu_1 = (value) => {
    this.setState({
      billingAdress: value,
    });
  };
  EditHandlerforeu_2 = (value) => {
    this.setState({
      billingAdress2: value,
    });
  };
  EditHandlerforeu_3 = (value) => {
    this.setState({
      billingAdress3: value,
    });
  };

  //USA FOR OVERRIDE EDIT HANDLER
  EditHandler_for_us_1 = (value) => {
    this.setState({
      SelectedDataBasedonStates_1: value,
    });
  };
  EditHandler_for_us_2 = (value) => {
    this.setState({
      SelectedDataBasedonStates_4: value,
    });
  };
  EditHandler_for_us_3 = (value) => {
    this.setState({
      countrychargeStates3: value,
    });
  };
  EditHandler_for_us_4 = (value) => {
    this.setState({
      SelectedDataBasedonStates_2: value,
    });
  };

  EditHandler_country_us_1 = (value) => {
    this.setState({
      SelectedDataBasedonCountry_1: value,
    });
  };
  EditHandler_country_us_2 = (value) => {
    this.setState({
      SelectedDataBasedonCountry_4: value,
    });
  };
  EditHandler_country_us_3 = (value) => {
    this.setState({
      countrychargeusa3: value,
    });
  };
  EditHandler_country_us_4 = (value) => {
    this.setState({
      SelectedDataBasedonCountry_2: value,
    });
  };
  //CA FOR OVERRIDE EDIT HANDLER
  EditHandler_for_ca_1 = (value) => {
    this.setState({
      selecteddatabasedonprovince_1: value,
    });
  };
  EditHandler_for_ca_2 = (value) => {
    this.setState({
      selecteddatabasedonprovince_2: value,
    });
  };

  EditHandler_for_ca_3 = (value) => {
    this.setState({
      selecteddatabasedonprovince_3: value,
    });
  };
  EditHandler_for_ca_4 = (value) => {
    this.setState({
      selecteddatabasedonprovince_4: value,
    });
  };

  EditHandler_country_ca_1 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_1: value,
    });
  };
  EditHandler_country_ca_2 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_2: value,
    });
  };
  EditHandler_country_ca_3 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_3: value,
    });
  };
  EditHandler_country_ca_4 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_4: value,
    });
  };
  //REST
  editHandler_waiver_1 = (value) => {
    this.setState({
      countrychargewaive: value,
    });
  };
  editHandler_rest_1 = (value) => {
    this.setState({
      countryvatortaxes: value,
    });
  };
  editHandler_rest_2 = (value) => {
    this.setState({
      countryvatortaxes2: value,
    });
  };
  editHandler_rest_3 = (value) => {
    this.setState({
      billingaddressCountry: value,
    });
  };
  editHandler_rest_4 = (value) => {
    this.setState({
      billingaddressCountry2: value,
    });
  };

  render() {
    const canadaState =
      this.props.state_list_ca && this.props.state_list_ca.results;
    //EU HEADING
    const headingforeu = [
      "Country",
      "Default VAT %",
      "Set your own VAT rate %",
    ];
    let vatUsaStatesApiData_1 = vatUsaStatesApiData(this.state.SelectedDataBasedonStates_1)
    let vatUsaStatesApiData_2 = vatUsaStatesApiData(this.state.SelectedDataBasedonStates_2)
    let vatUsaStatesApiData_3 = vatUsaStatesApiData(this.state.SelectedDataBasedonStates_4)

    let vatUsaCountryApiData_1 = vatUsaCountryApiData(this.state.SelectedDataBasedonCountry_1)
    let vatUsaCountryApiData_2 = vatUsaCountryApiData(this.state.SelectedDataBasedonCountry_2)
    let vatUsaCountryApiData_3 = vatUsaCountryApiData(this.state.SelectedDataBasedonCountry_4)

    //CANADA ALL SELECT CHECKBOX
    let vatCanadaProviceApiData_1 = vatCanadaProviceApiData(this.state.selecteddatabasedonprovince_1)

    let vatCanadaProviceApiData_2 = vatCanadaProviceApiData(this.state.selecteddatabasedonprovince_2)

    let vatCanadaProviceApiData_3 = vatCanadaProviceApiData(this.state.selecteddatabasedonprovince_3)
    let vatCanadaProviceApiData_4 = vatCanadaProviceApiData(this.state.selecteddatabasedonprovince_4)

    let vatCanadaCountryApiData_1 = vatCanadaCountryApiData(this.state.selecteddatabasedoncanadacountry_1)
    let vatCanadaCountryApiData_2 = vatCanadaCountryApiData(this.state.selecteddatabasedoncanadacountry_2)
    let vatCanadaCountryApiData_3 = vatCanadaCountryApiData(this.state.selecteddatabasedoncanadacountry_3)
    let vatCanadaCountryApiData_4 = vatCanadaCountryApiData(this.state.selecteddatabasedoncanadacountry_4)

    //Rest of the World
    let restVatOrTaxesApiData_1 = restVatOrTaxesApiData(this.state.countryvatortaxes)
    let restVatOrTaxesApiData_2 = restVatOrTaxesApiData(this.state.countryvatortaxes2)

    let VatRestBillingApiData_1 = VatRestBillingApiData(this.state.billingaddressCountry)
    let VatRestBillingApiData_2 =VatRestBillingApiData(this.state.billingaddressCountry2)
    let waiverscountries =VatWavierBillingApiData(this.state.countrychargewaive)

    
    let selfCountryVat_1 = vatSelfCountryApiData(this.state.selfCountryVat_1)
    let selfCountryVat_2 = vatSelfCountryApiData(this.state.selfCountryVat_2)
    let selfCountryVat_3 = vatSelfCountryApiData(this.state.selfCountryVat_3)
    let selfCountryVat_4 = vatSelfCountryApiData(this.state.selfCountryVat_4)


    //EU SELECT MULTICHECKBOX DATA

    let vatEuBillingApiData_1 = vatEuBillingApiData( this.state.billingAdress )
    let vatEuBillingApiData_2 = vatEuBillingApiData( this.state.billingAdress2 )
    let vatEuBillingApiData_3 = vatEuBillingApiData( this.state.billingAdress3 )


  let nextBtn =(this.state.other_countries_vat_charge_1 ===false || this.state.vat_tax_other_countries_charge2  ===false) 
    || (this.state.charge_policy_other_coutries_1 === true || this.state.charge_policy_other_coutries_1 === false) || (this.state.charge_policy_other_coutries_2 === true || this.state.charge_policy_other_coutries_2 === false)
    const steps = [
      {
        title: "Start",
        content: (
          <div className="vat-step">
            <p className="eu_vat_para">
              Let's set up your VAT/Tax for your customers
            </p>

            <div className="">
              <div className="">
                {this.state.id ? (
                  <div className="">
                    <div className="vat-question-panel">
                      {this.state.enable === true ? (
                        <p className="vat-number-para float-left">
                          Your VAT & Taxes Number
                        </p>
                      ) : (
                          <p className="vat-number-para float-left">
                            Add your VAT or Tax Number
                          </p>
                        )}

                      <div className="vat-input">
                        <div className="vat-input-box view">
                          {this.state.enable === true ? (
                            <p>{this.state.vat_number}</p>
                          ) : (
                              <InputBox
                                placeholder="Name"
                                field="vat_number"
                                className="col-md-12"
                                onChange={this.onTextFieldChange}
                                defaultValue={this.state.vat_number}
                              />
                            )}
                        </div>
                        <div className="vat-input-btn">
                          {this.state.enable !== true ? (
                            <Buttons
                              type="primary"
                              name="primary"
                              text="Update"
                              onClick={this.updateHandle}
                            />
                          ) : null}
                          {this.state.enable === true ? (
                            <div className="">
                              <Buttons
                                type="danger"
                                name="danger"
                                text="Delete"
                                onClick={() =>
                                  this.onHandleDelete(this.state.id)
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="vat-question-panel">
                      <p className="vat-number-para float-left">
                        Add your VAT or Tax Number{" "}
                      </p>
                      <div className="vat-input">
                        <div className="vat-input-box">
                          <InputBox
                            className="col-md-12"
                            placeholder="Enter your VAT or Tax Number"
                            field="vat_number"
                            onChange={this.onTextField}
                            defaultValue={this.state.vat_number}
                          />
                        </div>
                        <div className="vat-input-btn">
                          <Buttons
                            text="Add VAT"
                            type="primary"
                            name="primary"
                            size="200"
                            onClick={this.onSubmitVatForPublisher}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <p className="margin-10">
                Please select the country from where you will raise an invoice
                for open access APC
              </p>
              <div className="row">
                
              <label className="col-md-2 vat-country">Country :</label>
                <div className="col-md-8 vat-county-list">

                <SelectDropDownBox
                  onChange={this.onChangeSelect}
                  field="country"
                  className="col-md-8"
                  data={this.props.countries}
                  placeholder="Select a Country"
                  defaultValue={this.state.tab_1}
                />
              </div>
</div>
            </div>
          </div>
        ),
      },
      {
        title: this.state.tab_1 || "Self Origin",

        content:
          this.state.tab_1 === "United States" ? (
            <VatUSA
            onChangeHandleRadioBox={this.onChangeHandleRadioBox}
            SelectCheckBoxBasedOnStates_1={this.SelectCheckBoxBasedOnStates_1}
            EditHandler_for_us_1={this.EditHandler_for_us_1}
            SelectCheckBoxBasedOnCountry_1={this.SelectCheckBoxBasedOnCountry_1}
            EditHandler_for_us_4={this.EditHandler_for_us_4}
            EditHandler_country_us_4={this.EditHandler_country_us_4}
            SelectCheckBoxBasedOnStates_2={this.SelectCheckBoxBasedOnStates_2}
            SelectCheckBoxBasedOnCountry_2={this.SelectCheckBoxBasedOnCountry_2}
            SelectCheckBoxBasedOnStates_4={this.SelectCheckBoxBasedOnStates_4}
            EditHandler_for_us_2={this.EditHandler_for_us_2}
            EditHandler_country_us_1={this.EditHandler_country_us_1}
            charge_vat_number_usa={this.state.charge_vat_number_usa}
            charge_vat_states_usa={this.state.charge_vat_states_usa}
            charge_no_vat_number_usa_2={this.state.charge_no_vat_number_usa_2}
            charge_no_vat_states_usa_2={this.state.charge_no_vat_states_usa_2}
            charage_no_vat_usa={this.state.charage_no_vat_usa}
            charge_no_vat_states_usa={this.state.charge_no_vat_states_usa}
            state_list={this.props.state_list &&
              this.props.state_list.results}
              vatUsaStatesApiData_2={vatUsaStatesApiData_2}
            vatUsaStatesApiData_1={vatUsaStatesApiData_1}
            vatUsaCountryApiData_1={vatUsaCountryApiData_1}
            vatforus={this.state.vatforus}
            vatUsaCountryApiData_2={vatUsaCountryApiData_2}
            vatUsaStatesApiData_3={vatUsaStatesApiData_3}
            vatUsaCountryApiData_3={vatUsaCountryApiData_3}
            EditHandler_country_us_2={this.EditHandler_country_us_2}
            SelectCheckBoxBasedOnCountry_4={this.SelectCheckBoxBasedOnCountry_4}

        />
          ) : this.state.tab_1 !== "United States" &&
              this.state.tab_1 !== "Canada" ? (
                <VatSelfCountryName
                  onChangeHandleRadioBox={this.onChangeHandleRadioBox}
                  apc_including_vat={this.state.apc_including_vat}
                  charge_vat_number={this.state.charge_vat_number}
                  selfCountryVat_1={selfCountryVat_1}
                  origin={this.state.origin}
                  EditHandler={this.EditHandler}
                  charge_no_vat_number_1={this.state.charge_no_vat_number_1}
                  selfCountryVat_2={selfCountryVat_2}
                  EditHandler_2={this.EditHandler_2}
                  headingforeu={headingforeu}
                  not_charge_vat_number_2={this.state.not_charge_vat_number_2}
                />
         
              ) : this.state.tab_1 === "Canada" ? (
              
                <VatCanada
                  onChangeHandleRadioBox={this.onChangeHandleRadioBox}
                  charge_vat_number_canada={this.state.charge_vat_number_canada}
                  proviencebasedoncharge={this.state.proviencebasedoncharge}
                  canadaState={canadaState}
                  SelectCheckBoxBasedOnProvince_1={this.SelectCheckBoxBasedOnProvince_1}


                  vatCanadaProviceApiData_1={vatCanadaProviceApiData_1}
                  EditHandler_for_ca_1={this.EditHandler_for_ca_1}
                  EditHandler_for_ca_2={this.EditHandler_for_ca_2}

                  
                  vatforca={this.state.vatforca}
                  SelectCheckBoxBasedCanadaCountry_1={this.SelectCheckBoxBasedCanadaCountry_1}

                  vatCanadaCountryApiData_1={vatCanadaCountryApiData_1}
                  EditHandler_country_ca_1={this.EditHandler_country_ca_1}
                  charge_no_vat_canada_1={this.state.charge_no_vat_canada_1}
                  provience_basedon_vat_no_charge_1={this.state.provience_basedon_vat_no_charge_1}
                  SelectCheckBoxBasedOnProvince_2={this.SelectCheckBoxBasedOnProvince_2}
                  vatCanadaProviceApiData_2={vatCanadaProviceApiData_2}
                  SelectCheckBoxBasedCanadaCountry_2={this.SelectCheckBoxBasedCanadaCountry_2}
                  EditHandler_country_ca_2={this.EditHandler_country_ca_2}
                  vatCanadaCountryApiData_2={vatCanadaCountryApiData_2}
                  charge_no_vat_canada_2={this.state.charge_no_vat_canada_2}
                  provience_basedon_vat_no_charge_2={this.state.provience_basedon_vat_no_charge_2}
                  SelectCheckBoxBasedOnProvince_3={this.SelectCheckBoxBasedOnProvince_3}
                  vatCanadaProviceApiData_3={vatCanadaProviceApiData_3}
                  EditHandler_for_ca_3={this.EditHandler_for_ca_3}
                  SelectCheckBoxBasedCanadaCountry_3={this.SelectCheckBoxBasedCanadaCountry_3}
                  EditHandler_country_ca_3={this.EditHandler_country_ca_3}
                  vatCanadaCountryApiData_3={vatCanadaCountryApiData_3}
                  charge_no_vat_canada_3={this.state.charge_no_vat_canada_3}
                  provience_basedon_vat_no_charge_3={this.state.provience_basedon_vat_no_charge_3}
                  SelectCheckBoxBasedOnProvince_4={this.SelectCheckBoxBasedOnProvince_4}
                  vatCanadaProviceApiData_4={vatCanadaProviceApiData_4}
                  EditHandler_for_ca_4={this.EditHandler_for_ca_4}
                  SelectCheckBoxBasedCanadaCountry_4={this.SelectCheckBoxBasedCanadaCountry_4}
                  vatCanadaCountryApiData_4={vatCanadaCountryApiData_4}
                  EditHandler_country_ca_4={this.EditHandler_country_ca_4}
                />

              ) : null,
      },
      {
        title: this.state.tab_2 || "EU",
        content:
          this.state.tab_2 === "EU" ? (
            <VatEu
              onChangeHandleRadioBox={this.onChangeHandleRadioBox}
              charge_vat_number_eu={this.state.charge_vat_number_eu}
              charge_vat_billing_address_eu_1={this.state.charge_vat_billing_address_eu_1}
              vatforeu={this.state.vatforeu}
              selectCountryBillingAddressforeu={this.selectCountryBillingAddressforeu}
              vatEuBillingApiData_1={vatEuBillingApiData_1}
              EditHandlerforeu_1={this.EditHandlerforeu_1}
              selfCountryVat_3={selfCountryVat_3}
              EditHandler_3={this.EditHandler_3}
              charge_no_vat_number_eu_1={this.state.charge_no_vat_number_eu_1}
              charge_no_vat_billing_address_eu_2={this.state.charge_no_vat_billing_address_eu_2}
              selectCountryBillingAddressforeu2={this.selectCountryBillingAddressforeu2}
              vatEuBillingApiData_2={vatEuBillingApiData_2}
              EditHandlerforeu_2={this.EditHandlerforeu_2}
              selfCountryVat_4={selfCountryVat_4}
              EditHandler_4={this.EditHandler_4}
              charge_no_vat_number_eu_2={this.state.charge_no_vat_number_eu_2}
              charge_no_vat_billing_address_eu_3={this.state.charge_no_vat_billing_address_eu_3}
              selectCountryBillingAddressforeu3={this.selectCountryBillingAddressforeu3}
              vatEuBillingApiData_3={vatEuBillingApiData_3}
              EditHandlerforeu_3={this.EditHandlerforeu_3}

          />
          ) :  null,
      },

      {
        title: this.state.tab_3 || "United States",
        content:
          this.state.tab_3 === "United States" ? (
           
            <VatUSA
            onChangeHandleRadioBox={this.onChangeHandleRadioBox}
            SelectCheckBoxBasedOnStates_1={this.SelectCheckBoxBasedOnStates_1}
            EditHandler_for_us_1={this.EditHandler_for_us_1}
            SelectCheckBoxBasedOnCountry_1={this.SelectCheckBoxBasedOnCountry_1}
            EditHandler_for_us_4={this.EditHandler_for_us_4}
            EditHandler_country_us_4={this.EditHandler_country_us_4}
            SelectCheckBoxBasedOnStates_2={this.SelectCheckBoxBasedOnStates_2}
            SelectCheckBoxBasedOnCountry_2={this.SelectCheckBoxBasedOnCountry_2}
            SelectCheckBoxBasedOnStates_4={this.SelectCheckBoxBasedOnStates_4}
            EditHandler_for_us_2={this.EditHandler_for_us_2}
            EditHandler_country_us_1={this.EditHandler_country_us_1}
            charge_vat_number_usa={this.state.charge_vat_number_usa}
            charge_vat_states_usa={this.state.charge_vat_states_usa}
            charge_no_vat_number_usa_2={this.state.charge_no_vat_number_usa_2}
            charge_no_vat_states_usa_2={this.state.charge_no_vat_states_usa_2}
            charage_no_vat_usa={this.state.charage_no_vat_usa}
            charge_no_vat_states_usa={this.state.charge_no_vat_states_usa}
            state_list={this.props.state_list &&
              this.props.state_list.results}
              vatUsaStatesApiData_2={vatUsaStatesApiData_2}
            vatUsaStatesApiData_1={vatUsaStatesApiData_1}
            vatUsaCountryApiData_1={vatUsaCountryApiData_1}
            vatforus={this.state.vatforus}
            vatUsaCountryApiData_2={vatUsaCountryApiData_2}
            vatUsaStatesApiData_3={vatUsaStatesApiData_3}
            vatUsaCountryApiData_3={vatUsaCountryApiData_3}
            EditHandler_country_us_2={this.EditHandler_country_us_2}
            SelectCheckBoxBasedOnCountry_4={this.SelectCheckBoxBasedOnCountry_4}

        />
          ) : this.state.tab_3 === "EU" ? (
              <VatEu
                onChangeHandleRadioBox={this.onChangeHandleRadioBox}
                charge_vat_number_eu={this.state.charge_vat_number_eu}
                charge_vat_billing_address_eu_1={this.state.charge_vat_billing_address_eu_1}
                vatforeu={this.state.vatforeu}
                selectCountryBillingAddressforeu={this.selectCountryBillingAddressforeu}
                vatEuBillingApiData_1={vatEuBillingApiData_1}
                EditHandlerforeu_1={this.EditHandlerforeu_1}
                selfCountryVat_3={selfCountryVat_3}
                EditHandler_3={this.EditHandler_3}
                charge_no_vat_number_eu_1={this.state.charge_no_vat_number_eu_1}
                charge_no_vat_billing_address_eu_2={this.state.charge_no_vat_billing_address_eu_2}
                selectCountryBillingAddressforeu2={this.selectCountryBillingAddressforeu2}
                vatEuBillingApiData_2={vatEuBillingApiData_2}
                EditHandlerforeu_2={this.EditHandlerforeu_2}
                selfCountryVat_4={selfCountryVat_4}
                EditHandler_4={this.EditHandler_4}
                charge_no_vat_number_eu_2={this.state.charge_no_vat_number_eu_2}
                charge_no_vat_billing_address_eu_3={this.state.charge_no_vat_billing_address_eu_3}
                selectCountryBillingAddressforeu3={this.selectCountryBillingAddressforeu3}
                vatEuBillingApiData_3={vatEuBillingApiData_3}
                EditHandlerforeu_3={this.EditHandlerforeu_3}

              />
          ) :  <VatCanada
          onChangeHandleRadioBox={this.onChangeHandleRadioBox}
          charge_vat_number_canada={this.state.charge_vat_number_canada}
          proviencebasedoncharge={this.state.proviencebasedoncharge}
          canadaState={canadaState}
            SelectCheckBoxBasedOnProvince_1={this.SelectCheckBoxBasedOnProvince_1}

          vatCanadaProviceApiData_1={vatCanadaProviceApiData_1}
            EditHandler_for_ca_1={this.EditHandler_for_ca_1}
            EditHandler_for_ca_2={this.EditHandler_for_ca_2}

          vatforca={this.state.vatforca}
            SelectCheckBoxBasedCanadaCountry_1={this.SelectCheckBoxBasedCanadaCountry_1}
            
          vatCanadaCountryApiData_1={vatCanadaCountryApiData_1}
          EditHandler_country_ca_1={this.EditHandler_country_ca_1}
          charge_no_vat_canada_1={this.state.charge_no_vat_canada_1}
          provience_basedon_vat_no_charge_1={this.state.provience_basedon_vat_no_charge_1}
          SelectCheckBoxBasedOnProvince_2={this.SelectCheckBoxBasedOnProvince_2}
          vatCanadaProviceApiData_2={vatCanadaProviceApiData_2}
          SelectCheckBoxBasedCanadaCountry_2={this.SelectCheckBoxBasedCanadaCountry_2}
          EditHandler_country_ca_2={this.EditHandler_country_ca_2}
          vatCanadaCountryApiData_2={vatCanadaCountryApiData_2}
          charge_no_vat_canada_2={this.state.charge_no_vat_canada_2}
          provience_basedon_vat_no_charge_2={this.state.provience_basedon_vat_no_charge_2}
          SelectCheckBoxBasedOnProvince_3={this.SelectCheckBoxBasedOnProvince_3}
          vatCanadaProviceApiData_3={vatCanadaProviceApiData_3}
          EditHandler_for_ca_3={this.EditHandler_for_ca_3}
          SelectCheckBoxBasedCanadaCountry_3={this.SelectCheckBoxBasedCanadaCountry_3}
          EditHandler_country_ca_3={this.EditHandler_country_ca_3}
          vatCanadaCountryApiData_3={vatCanadaCountryApiData_3}
          charge_no_vat_canada_3={this.state.charge_no_vat_canada_3}
          provience_basedon_vat_no_charge_3={this.state.provience_basedon_vat_no_charge_3}
          SelectCheckBoxBasedOnProvince_4={this.SelectCheckBoxBasedOnProvince_4}
          vatCanadaProviceApiData_4={vatCanadaProviceApiData_4}
          EditHandler_for_ca_4={this.EditHandler_for_ca_4}
          SelectCheckBoxBasedCanadaCountry_4={this.SelectCheckBoxBasedCanadaCountry_4}
          vatCanadaCountryApiData_4={vatCanadaCountryApiData_4}
          EditHandler_country_ca_4={this.EditHandler_country_ca_4}
        />,
      },
      {
        title: this.state.tab_4 || "Canada",
        content:
          this.state.tab_4 === "Canada" ? (
                         
            <VatCanada
            onChangeHandleRadioBox={this.onChangeHandleRadioBox}
            charge_vat_number_canada={this.state.charge_vat_number_canada}
            proviencebasedoncharge={this.state.proviencebasedoncharge}
            canadaState={canadaState}
              SelectCheckBoxBasedOnProvince_1={this.SelectCheckBoxBasedOnProvince_1}

            vatCanadaProviceApiData_1={vatCanadaProviceApiData_1}
              EditHandler_for_ca_1={this.EditHandler_for_ca_1}
              EditHandler_for_ca_2={this.EditHandler_for_ca_2}

            vatforca={this.state.vatforca}
              SelectCheckBoxBasedCanadaCountry_1={this.SelectCheckBoxBasedCanadaCountry_1}
              
            vatCanadaCountryApiData_1={vatCanadaCountryApiData_1}
            EditHandler_country_ca_1={this.EditHandler_country_ca_1}
            charge_no_vat_canada_1={this.state.charge_no_vat_canada_1}
            provience_basedon_vat_no_charge_1={this.state.provience_basedon_vat_no_charge_1}
            SelectCheckBoxBasedOnProvince_2={this.SelectCheckBoxBasedOnProvince_2}
            vatCanadaProviceApiData_2={vatCanadaProviceApiData_2}
            SelectCheckBoxBasedCanadaCountry_2={this.SelectCheckBoxBasedCanadaCountry_2}
            EditHandler_country_ca_2={this.EditHandler_country_ca_2}
            vatCanadaCountryApiData_2={vatCanadaCountryApiData_2}
            charge_no_vat_canada_2={this.state.charge_no_vat_canada_2}
            provience_basedon_vat_no_charge_2={this.state.provience_basedon_vat_no_charge_2}
            SelectCheckBoxBasedOnProvince_3={this.SelectCheckBoxBasedOnProvince_3}
            vatCanadaProviceApiData_3={vatCanadaProviceApiData_3}
            EditHandler_for_ca_3={this.EditHandler_for_ca_3}
            SelectCheckBoxBasedCanadaCountry_3={this.SelectCheckBoxBasedCanadaCountry_3}
            EditHandler_country_ca_3={this.EditHandler_country_ca_3}
            vatCanadaCountryApiData_3={vatCanadaCountryApiData_3}
            charge_no_vat_canada_3={this.state.charge_no_vat_canada_3}
            provience_basedon_vat_no_charge_3={this.state.provience_basedon_vat_no_charge_3}
            SelectCheckBoxBasedOnProvince_4={this.SelectCheckBoxBasedOnProvince_4}
            vatCanadaProviceApiData_4={vatCanadaProviceApiData_4}
            EditHandler_for_ca_4={this.EditHandler_for_ca_4}
            SelectCheckBoxBasedCanadaCountry_4={this.SelectCheckBoxBasedCanadaCountry_4}
            vatCanadaCountryApiData_4={vatCanadaCountryApiData_4}
            EditHandler_country_ca_4={this.EditHandler_country_ca_4}
          />
          ) : this.state.tab_4 === "United States" ? (
                <VatUSA
onChangeHandleRadioBox={this.onChangeHandleRadioBox}
SelectCheckBoxBasedOnStates_1={this.SelectCheckBoxBasedOnStates_1}
EditHandler_for_us_1={this.EditHandler_for_us_1}
SelectCheckBoxBasedOnCountry_1={this.SelectCheckBoxBasedOnCountry_1}
EditHandler_for_us_4={this.EditHandler_for_us_4}
EditHandler_country_us_4={this.EditHandler_country_us_4}
SelectCheckBoxBasedOnStates_2={this.SelectCheckBoxBasedOnStates_2}
SelectCheckBoxBasedOnCountry_2={this.SelectCheckBoxBasedOnCountry_2}
SelectCheckBoxBasedOnStates_4={this.SelectCheckBoxBasedOnStates_4}
EditHandler_for_us_2={this.EditHandler_for_us_2}
EditHandler_country_us_1={this.EditHandler_country_us_1}
charge_vat_number_usa={this.state.charge_vat_number_usa}
charge_vat_states_usa={this.state.charge_vat_states_usa}
charge_no_vat_number_usa_2={this.state.charge_no_vat_number_usa_2}
charge_no_vat_states_usa_2={this.state.charge_no_vat_states_usa_2}
charage_no_vat_usa={this.state.charage_no_vat_usa}
charge_no_vat_states_usa={this.state.charge_no_vat_states_usa}
state_list={this.props.state_list &&
this.props.state_list.results}

vatUsaStatesApiData_1={vatUsaStatesApiData_1}
vatUsaStatesApiData_2={vatUsaStatesApiData_2}
vatUsaCountryApiData_1={vatUsaCountryApiData_1}
vatforus={this.state.vatforus}
vatUsaCountryApiData_2={vatUsaCountryApiData_2}
vatUsaStatesApiData_3={vatUsaStatesApiData_3}
  vatUsaCountryApiData_3={vatUsaCountryApiData_3}
                
EditHandler_country_us_2={this.EditHandler_country_us_2}
SelectCheckBoxBasedOnCountry_4={this.SelectCheckBoxBasedOnCountry_4}

              />
          ) :  <VatRestOfTheCountry
          onChangeHandleRadioBox={this.onChangeHandleRadioBox}
          waive_countries_charge_vat={this.state.waive_countries_charge_vat}
          other_countries={this.state.other_countries}
          selectCountrywaiveCountry={this.selectCountrywaiveCountry}
          waiverscountries={waiverscountries}
          editHandler_waiver_1={this.editHandler_waiver_1}
          other_countries_vat_charge_1={this.state.other_countries_vat_charge_1}
          vat_tax_other_countries_charge2={this.state.vat_tax_other_countries_charge2}
          selectCountryVATorTaxesCountry2={this.selectCountryVATorTaxesCountry2}
          editHandler_rest_2={this.editHandler_rest_2}
          restVatOrTaxesApiData_2={restVatOrTaxesApiData_2}
          charge_policy_other_coutries_2={this.state.charge_policy_other_coutries_2}
          selectCountryVATorTaxesCountry={this.selectCountryVATorTaxesCountry}
          restVatOrTaxesApiData_1={restVatOrTaxesApiData_1}
          editHandler_rest_1={this.editHandler_rest_1}
          charge_policy_other_coutries_1={this.state.charge_policy_other_coutries_1}
          selectCountrybillingaddressCountry={this.selectCountrybillingaddressCountry}
          VatRestBillingApiData_1={VatRestBillingApiData_1}
          editHandler_rest_3={this.editHandler_rest_3}
          editHandler_rest_4={this.editHandler_rest_4}
          VatRestBillingApiData_2={VatRestBillingApiData_2}
          selectCountrybillingaddressCountry2={this.selectCountrybillingaddressCountry2}
        />,
      },
      {
        title: this.state.tab_5 || "Rest of the World",
        content: (
          <VatRestOfTheCountry
            onChangeHandleRadioBox={this.onChangeHandleRadioBox}
            waive_countries_charge_vat={this.state.waive_countries_charge_vat}
            other_countries={this.state.other_countries}
            selectCountrywaiveCountry={this.selectCountrywaiveCountry}
            waiverscountries={waiverscountries}
            editHandler_waiver_1={this.editHandler_waiver_1}
            other_countries_vat_charge_1={this.state.other_countries_vat_charge_1}
            vat_tax_other_countries_charge2={this.state.vat_tax_other_countries_charge2}
            selectCountryVATorTaxesCountry2={this.selectCountryVATorTaxesCountry2}
            editHandler_rest_2={this.editHandler_rest_2}
            restVatOrTaxesApiData_2={restVatOrTaxesApiData_2}
            charge_policy_other_coutries_2={this.state.charge_policy_other_coutries_2}
            selectCountryVATorTaxesCountry={this.selectCountryVATorTaxesCountry}
            restVatOrTaxesApiData_1={restVatOrTaxesApiData_1}
            editHandler_rest_1={this.editHandler_rest_1}
            charge_policy_other_coutries_1={this.state.charge_policy_other_coutries_1}
            selectCountrybillingaddressCountry={this.selectCountrybillingaddressCountry}
            VatRestBillingApiData_1={VatRestBillingApiData_1}
            editHandler_rest_3={this.editHandler_rest_3}
            editHandler_rest_4={this.editHandler_rest_4}
            VatRestBillingApiData_2={VatRestBillingApiData_2}
            selectCountrybillingaddressCountry2={this.selectCountrybillingaddressCountry2}
          />
        ),
      },
    ];

    const { current } = this.state;
    return (
      <HubContent>
                {this.state.success  && (
            <PopupBox
              NoText="Close"
              msg="You have successfully created VAT."
              onCancel={this.redirectLink}
            />
          )}
        <div className="row">
          <div className="col-lg-12 vat ">
            <div className="card m-b-20 settings-card vat-card">
              <div className="card-body  pub-vat-page">
                <div className="vat-tax-button email d-flex justify-content-end">

                  <Buttons
                    type="danger"
                    name="danger"
                    text="X"
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <Steps current={current}>
                  {this.state.apc_including_vat === true
                    ? steps
                      && steps
                      .slice(0, 2)
                      && steps
                      .slice(0, 2)
                      .map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))        
                    :(this.state.tab_1 ==="Canada" || this.state.tab_1 ==="United States") ? steps && steps.slice(0,5) &&steps.slice(0,5).map((item) => (
                      <Step key={item.title} title={item.title} />
                    )):steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  
                  
                </Steps>
                <div id="steps-div" className="steps-content">
                  {steps[current]&&steps[current].content}
                </div>

                <div className="steps-action">
                  {this.state.apc_including_vat !== true
                    ? current > 0 && (
                      <Button
                        style={{ marginRight: 8 }}
                        onClick={this.prev}
                      >
                        Previous
                      </Button>
                    )
                    : null}
                  {this.state.apc_including_vat !== true ? (
                    this.state.tab_1 !== undefined && (this.state.tab_1 ==="Canada" || this.state.tab_1 ==="United States")  ?  
                      current < steps.length - 2 && (
                        <Button type="primary" onClick={this.next}>
                          Next
                        </Button>
                      )
                     :  this.state.tab_1 !== undefined && current < steps.length - 1 && (
                      <Button type="primary" onClick={this.next}>
                        Next
                      </Button>
                    )
                  ) : (
                      this.props.isLoading !== true ?
                        
                     <Button type="primary" onClick={this.onSubmit}>
                        Submit
                      </Button> : <Button    icon={<PoweroffOutlined />}
                    loading={true} type="secondary" onClick={this.onSubmit}>
                      Processing
                    </Button>
                       
                    )}

                  {(this.state.tab_1 ==="Canada" || this.state.tab_1 ==="United States") ? current === steps.length - 2 && (
                    this.props.isLoading !== true ?
                        
                    <Button type="primary" onClick={this.onSubmit}>
                        Submit
                    </Button> :
                    <Button icon={<PoweroffOutlined />}
                    loading={true} type="secondary" onClick={this.onSubmit}>
                    Processing
                  </Button>
                    
                  ):current === steps.length - 1 && nextBtn? (
                     this.props.isLoading !== true ?
                     <Button type="primary" onClick={this.onSubmit}>
                          Submit
                    </Button>
                    :
                    <Button icon={<PoweroffOutlined />} loading={true} type="secondary" onClick={this.onSubmit}>
                      Processing
                    </Button>
                    
                  ):null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.app.countries,
  state_list: state.vat.us_state_list,
  state_list_ca: state.vat.ca_state_list,
  country_list: state.vat.country_list,
  isLoading: state.vat.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  getAllCountry: () => dispatch(getAllCountry()),
  publisherVatCreateConfigure: (payload) =>
    dispatch(publisherVatCreateConfigure(payload)),
  getVatRatesPubVatConfig: (value) => dispatch(getVatRatesPubVatConfig(value)),
  getStatePubVatConfig: (value) => dispatch(getStatePubVatConfig(value)),
  getCAStatePubVatConfig: (value) => dispatch(getCAStatePubVatConfig(value)),
  publisherGetAllVatCreateConfigure: () => dispatch(publisherGetAllVatCreateConfigure()),
  createVatConfig: (payload) => dispatch(createVatConfig(payload)),
  createUpdateVatConfig: (payload) => dispatch(createUpdateVatConfig(payload)),
  getVatConfig: () => dispatch(getVatConfig()),
  getSingleVatConfig: (id) => dispatch(getSingleVatConfig(id)),
  DeleteVatConfig: (id) => dispatch(DeleteVatConfig(id)),
});

export const VATNumberForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(VatForm);