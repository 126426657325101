import React from "react";
import { connect } from "react-redux";
import axios from "axios"
import { AutoComplete, Spin, Popover } from "antd";
import { InfoCircleTwoTone, LoadingOutlined } from '@ant-design/icons';

import { Link, withRouter } from "react-router-dom";

import { requestSignUpUser, getPublicOrgSignup, getGenericAuthUrl } from "../../../actions";
import { PopupBox, Button } from "../../../../core/components/common";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const content = (
  <div>
    <a target="_blank" rel="noopener noreferrer" href="https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Registration.pdf">
      <span><i className="fa fa-angle-right"></i></span> Researchers Registration</a>
    <br />
    <a target="_blank" rel="noopener noreferrer" href="https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Login-with-Orcid.pdf"><span><i className="fa fa-angle-right"></i></span> Login with ORCID</a>
  </div>
);

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      visible: false,
      emailMessageVisable: false,
      orgsMessageVisable: false,
      orgsError: false
    }
    this.submitForm = this.submitForm.bind(this);
  }
  validateEmail(email) {
    // eslint-disable-next-line
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = pattern.test(String(email).toLowerCase());
    if (result === true) {
      this.setState({
        emailError: false,
        email: email
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }
  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });


    if (e.target.name === 'first_name') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          firstnameError: true
        })
      } else {
        this.setState({
          firstnameError: false,
          first_name: e.target.value
        })
      }
    }
    if (e.target.name === 'last_name') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          lastnameError: true
        })
      } else {
        this.setState({
          lastnameError: false,
          lastName: e.target.value
        })
      }
    }
    if (e.target.name === 'email') {
      this.validateEmail(e.target.value)
    }

    if (this.state.firstnameError === false && this.state.lastnameError === false &&
      this.state.emailError === false) {
      this.setState({
        isDisabled: false
      })
    }
  }

  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
      if (
        (!obj[key] && obj[key] !== 0) ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  };
  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  signWithORICID = async () => {
    let result = await this.props.getGenericAuthUrl({
      "context": "orcid"
    })
    axios.get("https://orcid.org/userStatus.json?logUserOut=true").then(res => {
      if (res.data.loggedIn === false) {
        window.location.href = `${result.data.auth_url}`;
        this.setState({
          url: result.data.auth_url
        })
      }
    })

  }

  submitForm = (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      email,
      orcid_id,
      username = email,
    } = this.state;

    if (this.isNumber(this.state.organisation)) {
      if (first_name && last_name && email) {
        const data = {
          first_name,
          last_name,
          email,
          orcid_id,
          username,
        };
        let res = this.removeEmpty(data)
        const regstData = {
          ...res,
          organisation: this.state.organisation,
        };

        this.props.requestSignUpUser(regstData);


      } else {
        this.setState({
          errorFields: true,
          first_name_error: `${first_name === undefined || first_name === "" ? "First name can not be empty" : ""}`,
          last_name_error: `${last_name === undefined || last_name === "" ? "Last name can not be empty" : ""}`,
          email_error: `${email === undefined || email === "" ? "Email can not be empty" : ""}`,
        });
      }


    } else {
      this.setState({
        errorMsg: "It appears you have not selected your correct affiliated institution name from organisation dropdown field, select your correct institution and resubmit. ",
      });
    }


  };


  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState({
        organisation: item.id,
        orgsError: false

      });
    }
  };
  onChange = (value) => {
    let data = this.props.orgs && this.props.orgs.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        organisation: data && data.id,
        orgsError: false,
      });
    } else {
      this.setState({
        orgsError: true,
        orgs_name:value

      });
    }
  };

  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getPublicOrgSignup(value);
      this.setState({
        dataList: this.props.orgs && this.props.orgs.map(item => ({
          id: item.id,
          value: item.name,
        }))

      })
    }, 200);
  };
  onCancel = () => {
    this.setState({
      errorMsg: false,
      orgsMessageVisable: false,
      emailMessageVisable: false,
      errorFields: false,
    });
  };
  handleClose = () => {
    this.setState({
      visible: !this.state.visible

    })
  }
  handleOrgMessage = () => {
    this.setState({
      orgsMessageVisable: !this.state.orgsMessageVisable,
      orgs_message: "Select the same institution or organisation name from dropdown that you have provided during submission  of your manuscript. Contact support@oametrix.co.uk for any help "

    })
  }
  handleEmailMessage = () => {
    this.setState({
      emailMessageVisable: !this.state.emailMessageVisable,
      emailMessage: "Enter the same email address that you have used during submission  of your manuscript.  "
    })
  }
  redirectLink = () => {
    this.props.history.push("/signin");
  };

  render() {
    return (
      <div className="container">
        {this.state.errorMsg && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.errorMsg}
            onCancel={this.onCancel}
          />
        )}
        {this.state.errorFields && (
          <PopupBox
            title="Required Information Missing"
            first_name={this.state.first_name_error}
            last_name={this.state.last_name_error}
            email={this.state.email_error}
            onCancel={this.onCancel}
          />
        )}
        {this.props.auth.status === 400 && (
          <div>
            {this.props.auth.error && <PopupBox msg={this.props.auth.error} />}
          </div>
        )}

        {this.props.auth.status === 201 && (
          <PopupBox
            NoText="Close"
            msg="Thank you for signing up, you have received an e-mail to change password . Please refresh the page if you continue see this popup message. "
            onCancel={this.redirectLink}
          />
        )}
        {this.state.emailMessageVisable && (
          <PopupBox
            msg={this.state.emailMessage}
            onCancel={this.onCancel}
          />
        )}
        {this.state.orgsMessageVisable && (
          <PopupBox
            msg={this.state.orgs_message}
            onCancel={this.onCancel}
          />
        )}
        <div className="row">

          <div className="col-md-8 offset-md-2">

            <div className="card card-login mx-auto mt-3">
              <div className="text-right">
                <Popover placement="leftTop" content={content} trigger="click">

                  <Button
                    type="primary"
                    name="primary"
                    text="Help"
                    className="help-menu"
                  > Help
                </Button>
                </Popover>




              </div>

              <h3 className="text-center m-10">
                <Link to="/" className="logo logo-admin">
                  <img
                    src="/assets/images/logo-sm.png"
                    style={{ height: "30px" }}
                    alt="logo"
                  />
                </Link>
              </h3>

              <div className="signup-header">
                <h3>User Registration </h3>
                <InfoCircleTwoTone style={{
                  fontSize: 18,
                  marginTop: 18,
                  marginLeft: 15
                }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                  onClick={this.handleClose}
                />

              </div>



              <div className="card-body">
                <div className="text-center">


                  {this.state.visible && <p className="contact-info">Enter your details to register with OaMetrix. Once you have submitted the form, you will recive an email to
                create your password. Click on the Help button to know more.</p>}

                  {this.state.visible && <p className="contact-info">Please do not proceed with registration if you are an admin user, <Link to="#" onClick={(e) => {
                    window.location = "mailto:biplob@oametrix.io"
                    e.preventDefault();
                  }} className="user-contact">contact us</Link> for your login details.</p>
                  }
                  <p className="signin-info">Click on <Link to="/signin" className="user-sign">Log in</Link> if you already have an account with OaMetrix</p>
                </div>
                <form className="signup-form">
                  <div className="text-right info-icon">
                    <InfoCircleTwoTone style={{
                      fontSize: 18,
                      marginTop: 10,
                      marginLeft: 13
                    }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                      onClick={this.handleOrgMessage}
                    />
                  </div>
                  <div className="form-group row auto-complete-search m-t-20">
                    <div className="col-md-2">
                      <label>Organisation<span style={{ color: "red", paddingLeft: "5px" }}>*</span></label>
                    </div>

                    <div className="col-md-10 search-compelete">
                      <div className="search-auto-complete">

                        <AutoComplete
                          defaultValue={this.state.organisation}
                          notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}
                          placeholder="Type your institution name and select from the drop down"

                          options={this.state.dataList}
                          style={{
                            width: "100%",
                          }}
                          onSelect={(val, option) => this.onSelect(val, option)}
                          onSearch={this.onSearch}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="search-auto-spin">
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>

                      {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
</span>}
                    </div>

                  </div>

                  <div className="row">

                    <div className="col-md-2">
                      <label htmlFor="first_name">First Name  <span style={{ color: "red", paddingLeft: "2px" }}>*</span></label>

                    </div>
                    <div className="col-md-4">
                      <div className="form-label-group">
                        <input type="text" id="first_name" name="first_name" className="form-control" placeholder="Enter your first name" onChange={(e) => { this.handleChange(e) }} />
                        {this.state.firstnameError ? <span style={{ color: "red" }}>Please enter your first name
</span> : ''}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <label htmlFor="last_name">Last Name   <span style={{ color: "red", paddingLeft: "px" }}>*</span></label>
                    </div>
                    <div className="col-md-4">
                      <div className="form-label-group">
                        <input type="text" id="last_name" name="last_name" className="form-control" placeholder="Enter your last name" onChange={(e) => { this.handleChange(e) }} />
                        {this.state.lastnameError ? <span style={{ color: "red" }}>Please enter your last name</span> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  </div>
                  <div className="text-right signup-email-icon">
                    <InfoCircleTwoTone style={{
                      fontSize: 18,
                      marginTop: 10,
                      marginLeft: 13
                    }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                      onClick={this.handleEmailMessage}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label htmlFor="email">Email  <span style={{ color: "red", paddingLeft: "2px" }}>*</span></label>
                    </div>

                    <div className="col-md-10">
                      <div className="form-group">
                        <div className="form-label-group">
                          <input type="text" id="email" name="email" className="form-control" placeholder="Type your institutional email" onChange={(e) => { this.handleChange(e) }} />
                          {this.state.emailError ? <span style={{ color: "red" }}>Please enter your valid institutional email</span> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label htmlFor="orcid">ORCID ID</label>
                    </div>

                    <div className="col-md-10">
                      <div className="form-group">
                        <div className="form-label-group">
                          <input type="text" id="orcid_id" name="orcid_id" className="form-control" placeholder="Enter your ORCID ID if available (optional)" onChange={(e) => { this.handleChange(e) }} />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="text-center">
                    <p className="privacy-info ">
                      <i className="mdi mdi-hand-pointing-right"></i> By
                          clicking Sign up, you agree to the OaMetrix user
                           agreement

                           <a

                        style={{ marginLeft: 5, color: "#375ba8", textDecoration: "underline" }}
                        href="https://www.oametrix.io/website-terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy
</a>
,
<a
                        style={{ marginLeft: 5, color: "#375ba8", textDecoration: "underline" }}
                        href="https://www.oametrix.io/website-terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        cookie policy
</a>
,
<a
                        style={{ marginLeft: 5, color: "#375ba8", textDecoration: "underline" }}
                        href="https://www.oametrix.io/website-terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms of use
</a></p>
                  </div>
                  <button className="btn btn-primary btn-block" onClick={this.submitForm}>Sign up</button>
                </form>

                <div className="text-center m-0">
                  <p className="orcid-info"> if you are an author, you can log in with your ORCID ID</p>
                </div>
                <div className="text-center" style={{ marginTop: 10 }}>
                  <a className="oricid" href={this.state.url} onClick={this.signWithORICID}>
                    <img src="https://orcid.org/assets/vectors/orcid.logo.icon.svg" alt="sign" />Log in with ORCID
                      </a>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  orgs: state.app.organizations,
  loading: state.orgs.loading,
});

const mapDispatchToProps = (dispatch) => ({
  requestSignUpUser: (payload) => dispatch(requestSignUpUser(payload)),
  getPublicOrgSignup: (value) => dispatch(getPublicOrgSignup(value)),
  getGenericAuthUrl: (payload) => dispatch(getGenericAuthUrl(payload)),

});

export const SignUp = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Auth)
);