import React, { Component } from "react";
import ViewVatTable from "../viewVatTable";

export default class CAVIEW extends Component {
  state = {};
  render() {
    const {
      charge_vat_number_canada,
      proviencebasedoncharge,
      charge_no_vat_canada_1,
      provience_basedon_vat_no_charge_1,
      charge_no_vat_canada_2,
      provience_basedon_vat_no_charge_2,
      charge_no_vat_canada_3,
      provience_basedon_vat_no_charge_3,
      // viewTable,
      canadadatacountrytable_1,
      canadaprovincedata_1,
      canadadataforcountry2,
      canadaprovincedata_2,
      canadadataforcountry3,
      canadaprovincedata_3,
      canadadataforcountry4,
      canadaprovincedata_4,
    } = this.props;
    return (
      <div className="vat-single-view">
  
        <div className="vat-detail-form">
          <div className="vat-question-panel">
            <p>
              You charge Tax/GST to CA customers if they provide a valid
              VAT/GST/Business Tax Number:{" "}
              {charge_vat_number_canada === true ? (
                <span className="vat_text">Yes</span>
              ) : (
                <span className="vat_text">No</span>
              )}
            </p>
          </div>
          {charge_vat_number_canada === true && (
            <div className="vat-question-panel">
              <p>
                You charge Tax/GST based on the Province:{" "}
                {proviencebasedoncharge === true ? (
                  <span className="vat_text">Yes</span>
                ) : (
                  <span className="vat_text">No</span>
                )}
              </p>
            </div>
          )}
          {charge_vat_number_canada !== false
            ? proviencebasedoncharge === true && (
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable data={canadaprovincedata_1} field="CA" />
                  </div>
                </div>
              )
            : null}
          {charge_vat_number_canada !== false
            ? proviencebasedoncharge === false && (
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable
                      data={canadadatacountrytable_1}
                      field={"CA"}
                    />
                  </div>
                </div>
              )
            : null}
          {charge_vat_number_canada !== false ? (
            proviencebasedoncharge === true ? (
              <div className="vat-question-panel">
                <p>
                  You charge Tax/GST to CA customers if they do not provide a
                  valid VAT/GST/Business Tax Number:{" "}
                  {charge_no_vat_canada_1 === true ? (
                    <span className="vat_text">Yes</span>
                  ) : (
                    <span className="vat_text">No</span>
                  )}
                </p>
              </div>
            ) : null
          ) : null}
          {charge_vat_number_canada !== false ? (
            proviencebasedoncharge !== false ? (
              // proviencebasedoncharge !== false ? (
              charge_no_vat_canada_1 === true ? (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST based on the Province:{" "}
                    {provience_basedon_vat_no_charge_1 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              ) : null
            ) : // ) : null
            null
          ) : null}
          {charge_vat_number_canada !== false
            ? proviencebasedoncharge !== false
              ? charge_no_vat_canada_1 !== false
                ? provience_basedon_vat_no_charge_1 === true && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable
                          data={canadaprovincedata_2}
                          field={"CA"}
                        />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {charge_vat_number_canada !== false
            ? proviencebasedoncharge !== false
              ? charge_no_vat_canada_1 !== false
                ? provience_basedon_vat_no_charge_1 === false && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable
                          data={canadadataforcountry2}
                          field={"CA"}
                        />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {}{" "}
          {charge_vat_number_canada !== false ? (
            proviencebasedoncharge !== true ? (
              proviencebasedoncharge === false ? (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST to CA customers if they do not provide a
                    valid VAT/GST/Business Tax Number:
                    {charge_no_vat_canada_2 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              ) : null
            ) : null
          ) : null}
          {charge_vat_number_canada !== false ? (
            proviencebasedoncharge !== true ? (
              // proviencebasedoncharge !== false ? (
              charge_no_vat_canada_2 === true ? (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST based on the Province:
                    {provience_basedon_vat_no_charge_2 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              ) : null
            ) : // ) : null
            null
          ) : null}
          {charge_vat_number_canada !== false
            ? proviencebasedoncharge !== true
              ? charge_no_vat_canada_2 !== false
                ? provience_basedon_vat_no_charge_2 === true && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable
                          data={canadaprovincedata_3}
                          field={"CA"}
                        />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {charge_vat_number_canada !== false
            ? proviencebasedoncharge !== true
              ? charge_no_vat_canada_2 !== false
                ? provience_basedon_vat_no_charge_2 === false && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable
                          data={canadadataforcountry3}
                          field={"CA"}
                        />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {charge_vat_number_canada === false && (
            <div className="vat-question-panel">
              <p>
                You charge Tax/GST to CA customers if they do not provide a
                valid VAT/GST/Business Tax Number:
                {charge_no_vat_canada_3 === true ? (
                  <span className="vat_text">Yes</span>
                ) : (
                  <span className="vat_text">No</span>
                )}
              </p>
            </div>
          )}
          {charge_vat_number_canada !== true
            ? charge_no_vat_canada_3 === true && (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST based on the Province:
                    {provience_basedon_vat_no_charge_3 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              )
            : null}
          {charge_vat_number_canada !== true ? (
            charge_no_vat_canada_3 === true &&
            provience_basedon_vat_no_charge_3 === true ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <ViewVatTable data={canadaprovincedata_4} field={"CA"} />
                </div>
              </div>
            ) : null
          ) : null}
          {charge_vat_number_canada !== true ? (
            charge_no_vat_canada_3 === true &&
            provience_basedon_vat_no_charge_3 === false ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <ViewVatTable data={canadadataforcountry4} field={"CA"} />
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    );
  }
}