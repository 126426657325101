import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { DataTable } from '../../../../../core/components';

class DB extends BaseComponent {
   
    render() {
        return (
            <HubContent title="Transaction history">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <DataTable
                                // heading={heading}
                                // data={this.props.users}
                                onEdit={this.onEditUser}
                                onDelete={this.onDeleteUser}
                                onView={this.onViewUser}
                            />
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}



export const PublisherReportDetails = (DB)