import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';

class AddPubAdmin extends BaseComponent {
    render() {
        return (
            <HubContent title="Add Publisher Admin">

                {/* Row Start */}
                <div className="row">
                    <div className="col-lg-12">
                        {/* Card Start */}
                        <div className="card m-b-20">
                            {/* Card Body Start */}
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Add publisher admin details</a>
                                    </li>
                                </ul>

                                {/* <!-- Tab panes --> */}
                                <div className="tab-content">
                                    <div className="tab-pane active p-3" id="home-1" role="tabpanel">

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">First name</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="first name" />
                                        </div>
                                        <label htmlFor="" className="col-sm-2 col-form-label">Last name</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="last name" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Ppublisher</label>
                                        <div className="col-sm-10">
                                            <select className="custom-select">
                                                <option selected>select publisher</option>
                                                <option defaultValue="Taylor & Francis">Taylor & Francis</option>
                                                <option defaultValue="Wiley">Wiley</option>
                                                <option defaultValue="Cambridge University Press">Cambridge University Press</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Position</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="position" />
                                        </div>
                                        <label htmlFor="" className="col-sm-2 col-form-label">Department</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="enter department" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Tel</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="Telephone" />
                                        </div>
                                        <label htmlFor="" className="col-sm-2 col-form-label">Email</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="enter your organsiatinonal email" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">User name</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="type your organisatinal email" />
                                        </div>

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Password</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="enter your password" />
                                        </div>
                                        <label htmlFor="" className="col-sm-2 col-form-label">Confirm password</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="re-enter your password" />
                                        </div>

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Note</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="Note" />
                                        </div>
                                    </div>
                                    <div className="text-center m-t-15">
                                        <button type="button" className="btn btn-primary waves-effect waves-light">Save</button>
                                    </div>
                                </div>
                                {/* <!-- End of Tab panes --> */}
                            </div>
                            {/* Card Body End */}
                        </div>
                        {/* Card Body End */}
                    </div>
                    {/* Col-lg-12 End */}
                </div>
            </HubContent>
        )
    }
}

export const AddPublisherAdmin = AddPubAdmin;
