import { 
    GET_LICENCE_PRICING_CONTROL_CONFIG_LIST, 
    GET_LICENCE_PRICING_CONTROL_CONFIG, 
    CREATE_UPDATE_LICENCE_PRICING_CONTROL_CONFIG, 
    DELETE_LICENCE_PRICING_CONTROL_CONFIG,
} from "../../config";

const LicencePricingControlConfigReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_LICENCE_PRICING_CONTROL_CONFIG_LIST: 
            return state = {
                ...state,
                licence_pricing_configs: action.payload
            }
        case GET_LICENCE_PRICING_CONTROL_CONFIG: 
            return state = {
                ...state,
                licence_pricing_config: action.payload
            }
        case CREATE_UPDATE_LICENCE_PRICING_CONTROL_CONFIG: 
            return state = {
                ...state,
                licence_pricing_config: action.payload
            }
        case DELETE_LICENCE_PRICING_CONTROL_CONFIG: 
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default LicencePricingControlConfigReducer;
