import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../core/components/BaseComponent";
import { stripeCodeSubmit, getStripeConnectStatus } from "../../../actions";
const queryString = require("query-string");

export class Auth extends BaseComponent {
  state = {};

  storeStripeCode = async () => {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    let payload = { auth_code: params.code };
    params.code && (await this.props.stripeCodeSubmit(payload));
    window.close();
  };

  render() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    params.error && window.close();
    params.code && this.storeStripeCode();
    return <div className="wrapper-page pt-5"></div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  stripeCodeSubmit: (payload) => dispatch(stripeCodeSubmit(payload)),
  getStripeConnectStatus: () => dispatch(getStripeConnectStatus()),
});

export const CallBackUri = connect(mapStateToProps, mapDispatchToProps)(Auth);
