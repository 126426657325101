import config, {
  UPDATE_DASHBOARD_DRAG_DROP,
  GET_DASHBOARD_LEVEL_LIST,
  GET_DASHBOARD_USER_PERMISSION_LIST,
  FAILED_DASHBOARD_DRAG_DROP_CONTROL_LIST,
  REQUEST_DASHBOARD_DRAG_DROP_CONTROL_LIST,
  SUCCESS_DASHBOARD_DRAG_DROP_CONTROL_LIST,
  DASHBOARD_DRAG_DROP_CONTROL,
  POST_DASHBOARD_USER_PERMISSION,
  SINGLE_DASHBOARD_USER_PERMISSION,
} from "../../config";
import { api } from "../../core/api";

const DashboardDataControlAction = (payload) => ({
  type: GET_DASHBOARD_USER_PERMISSION_LIST,
  payload,
});
const DashboardPostDataControlAction = (payload) => ({
  type: POST_DASHBOARD_USER_PERMISSION,
  payload,
});

const DashboardDataControlSingle = (payload) => ({
  type: SINGLE_DASHBOARD_USER_PERMISSION,
  payload,
});
const DashboardDragAndDropControlAction = (payload) => ({
  type: DASHBOARD_DRAG_DROP_CONTROL,
  payload,
});

const requestDashboardDragAndDropControlListAction = () => ({
  type: REQUEST_DASHBOARD_DRAG_DROP_CONTROL_LIST,
});

const successDashboardDragAndDropControlListAction = (payload) => ({
  type: SUCCESS_DASHBOARD_DRAG_DROP_CONTROL_LIST,
  payload,
});

const failedDashboardDragAndDropControlListAction = (payload) => ({
  type: FAILED_DASHBOARD_DRAG_DROP_CONTROL_LIST,
  payload,
});

const DashboardUpdateDataControlAction = (payload) => ({
  type: UPDATE_DASHBOARD_DRAG_DROP,
  payload,
});
const getDashboardLevelAction = (payload) => ({
  type: GET_DASHBOARD_LEVEL_LIST,
  payload,
});

export const getDashboardLevelList = (id) => {
  let url = `${config.endpoint.organisation_dashboard_component}${id}/`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((res) => {
          dispatch(getDashboardLevelAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getPreimumDataList = () => {
  let url = `${config.endpoint.quicksight_dashboard}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((res) => {
          dispatch(getDashboardLevelAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getDashboardDataControl = (payload) => {
  let url = `${config.endpoint.organisation_dashboard_control_config}?page=${
    payload && payload.pageNum
  }&page_size=${payload && payload.pageSize}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((res) => {
          dispatch(DashboardDataControlAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const createDashboardDataControl = (payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = config.endpoint.organisation_dashboard_control_config;
      api
        .post(url, payload)
        .then((res) => {
          dispatch(DashboardPostDataControlAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(DashboardPostDataControlAction(error));
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};
export const updateDashboardDataControl = (id, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = `${config.endpoint.organisation_dashboard_control_config}${id}/`;
      api
        .put(url, payload)
        .then((res) => {
          dispatch(DashboardUpdateDataControlAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(DashboardPostDataControlAction(error));
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getDashboardDataControlSingle = (id) => {
  let url = config.endpoint.organisation_dashboard_control_config + id;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((res) => {
          dispatch(DashboardDataControlSingle(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const dashboardDragAndDropControlList = (id) => {
  let url = config.endpoint.user_dashboard_config + `${id}/`;
  return (dispatch) => {
    dispatch(requestDashboardDragAndDropControlListAction());
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((res) => {
          dispatch(successDashboardDragAndDropControlListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(failedDashboardDragAndDropControlListAction(e));

          reject(e);
        });
    });
  };
};

export const dashboardDragAndDropControl = (id, payload) => {
  let url = config.endpoint.user_dashboard_config + `${id}/`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .put(`${url}`, payload)
        .then((res) => {
          dispatch(DashboardDragAndDropControlAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
