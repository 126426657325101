import React from "react";
export const WhitePublisherCard = (props) => {

  
  return (
    <div className={`${props.className ? props.className : "col-xl-12"}`}>
      <div className={`${props.component_class} mini-stat2 bg-white`}>
        <div
          className={`card-body mini-stat-img ${
            props.padding ? props.padding : ""
          }`}
        >
          <div className="" style={{ marginBottom: 10 }}>
       
            <h6 className="mb-4">{props.title}</h6>
        
            {typeof props.body === "object" ? (
              props.body.map((content) => {
                return (
                  <p className="mb-1" key={content.toString()}>
                    {content}
                  </p>
                );
              })
            ) : (
              <p className="mb-1">{props.body}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
