let VatStateData = {
  current: 0,
  enable: true,
  data: {},
  isShow: false,
  height: 0,
  offices: [],
  state: null,
  charge_based_on_states: null,
  other_country_chg_policies: [],
  charge_policy: "",
  other_waived_countries: [],
  value: null,
  arrayValue: [],
  countriesList: [],
  countryList: [],
  eudata: [],
  isDisabled: false,

  // SELF COUNTRY
  apc_including_vat: null,
  charge_vat: null,
  charge_no_vat: null,
  not_charge_vat_number: null,
  no_charge_vat_us: null,
};

export default VatStateData;
