import React from "react";
import { connect } from "react-redux";

import BaseComponent, { HubContent } from "../../../../core/components";
import {
  Loading,
  SelectBox,
  InputGroup,
  ExpandData,
  ExpandHeadingTable,
  PaginationForm,
} from "../../../../core/components/common";
import { PopupBox } from "../../../../core/components/common";
import {
  serialize,
  trxInvoiceHeading,
  column,
  filterMonths,
  filterDays,
  invoiceStatusList,
} from "../../../../core/lib";

import moment from "moment";

import {
  getArticleInvoice,
  getInvoiceDownload,
  downloadInvoice,
  paymentinvoiceTransactionDownload,
  getInstitutionsList,
  getAllDealType, 
} from "../../../actions";
class InvoiceHistory extends BaseComponent {
  state = {
    showPopup: false,
    acknowledge: false,
    filterParams: {page:1},
    page: 1,
    publisher_list: [],
    institution_list:[],
    tabItems: [],
  };

  oaDealTypeLabels = {
    'subscribe-to-open': 'OA-S2O',
    'pre-payment': 'OA-Prepayment',
    'read & publish': 'R&P Deal',
    'credit': 'OA-Credit',
  }
  
  componentDidMount() {
    this.getInvoiceList();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }
  isArrayCheck = value => {
    return Array.isArray(value)? value :[]
  }

  filterYears = () => { 
    let currentYear = (new Date()).getFullYear()
    let years = [{
      id: '',
      name: 'Year',
    }]

    for (let i = currentYear; i >= 2020; i--) {
      years.push({
        id: i,
        name: i,
      })
    }

    return years
  }

  getInvoiceList = async (pageNum = 1,) => {
    let value = {
      page: pageNum,

    };
    await this.props.getInstitutionsList();
    await this.props.getAllDealType();

    let tabItems = []
    if (this.props.deal_type) {
      for (let item of this.props.deal_type) {
        if (this.oaDealTypeLabels.hasOwnProperty(item.name)) {
          tabItems.push({ id: item.id, label: this.oaDealTypeLabels[item.name] })
        }
      }
    }

    this.setState({
      tabItems,
      institution_list: [ { id: "all", name: "All institutions" }].concat(this.isArrayCheck(this.props.institution_list)) 
    })

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
  
    });
  
    let params = serialize(this.state.params);
    let filter = serialize(this.state.filterParams);
  
    await this.props.getArticleInvoice({ params, filter });

    
  };


  pageChange = async (pageNumber) => {
    let value = {
        page: pageNumber,
      };
      await this.setState((prevState) => {
        prevState.filterParams = Object.assign(prevState.filterParams, value);
    
      });
    
      let params = serialize(this.state.params);
      let filter = serialize(this.state.filterParams);
    
      await this.props.getArticleInvoice({ params, filter });
  
      this.setState({
        page: pageNumber,
      })
      
};

  onActionHandler = async (action, id) => {
    try {
      switch (action.toLowerCase()) {
        case "invoice_paid":
          this.setState({
            showPopup: true,
            article_id: id,
            update_intent: "paid",
          });
          break;
        case "invoice_acknowledged":
          this.setState({
            showPopup: true,
            article_id: id,
            update_intent: "acknowledge_payment",
          });
          break;
        case "invoice_cancel":
          this.setState({
            showPopup: true,
            article_id: id,
            update_intent: "cancel_invoice",
          });
          break;
        default:
          break;
      }
    } catch (error) {
      this.log("error: ", error);
    }
  };

  oninvoiceHistoryDownloadClick = async () => {
    try {
      await this.props.paymentinvoiceTransactionDownload();
      const url = window.URL.createObjectURL(
        this.props.invoice_download_transction
      );
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `invoice_history_data_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };
  cancelLoader = async (id) => {
    this.setState({
      showPopup: false,
      acknowledge: false,
    });
    if (id) {
      this.getInvoiceList();
    }
  };

  onTabChangeHandler = async (invoiceType = '') => {
        
    let params = {
        invoice_type: invoiceType,
    };

    this.filterData(params);
  }

  filterData = async value => {
    value.page = 1;

    if (value.hasOwnProperty("search_text")) {
      this.setState({
        ...value,
      });
    }

    await this.setState((prevState) => {
      if (value.invoice_status === "all") {
        value.invoice_status = "";
      } else if (value.filter_institution === "all") {
        value.filter_institution = "";
      } else if (value.filter_publisher === "all") {
        value.filter_publisher = "";
      }

      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });

    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.getArticleInvoice({ params, filter });
  };

  render() {
    let errorHander = (
      <>
        {this.state.showPopup && (
          <PopupBox
            title="Payment Update"
            msg=""
            id={this.state.article_id}
            hideOk={true}
            acknowledge={this.state.acknowledge}
            NoText="Cancel"
            className="col-sm-8"
            onCancel={this.cancelLoader}
            markAsPaid={true}
            update_intent={this.state.update_intent}
          />
        )}
      </>
    );
    let payment_by_invoice_list = Array.isArray(this.props.payment_by_invoice_list) ? this.props.payment_by_invoice_list : [];
  
    let invoice_data = [];


    payment_by_invoice_list &&
      payment_by_invoice_list.map((data, index) =>( invoice_data.push({
          id: data && data.id,
          licence: data && data.licence_name,
          date: data && data.date_created,
          article_payment_id:
            data && data.article_data && data.article_data.article_id,
          article: data && data.article_data && data.article_data.article_title,
          publisher:
          data && data.article_data && data.article_data.publisher_name,
          article_data: data && data.article_data,
          author: data && data.article_data && data.article_data.author_name,
          institution: data && data.organisation_author_name,
          currency: data && data.currency_name,
          amount: data && data.amount,
          invoice_number: data && data.invoice_number,
          actions: data && data.actions,
          status: data && data.status,
          receiver_note: data.receiver_note,
          invoice_status: data && data.invoice_status,
          link: data && data.invoice_download && data.invoice_download.link,
          file_name: data && data.invoice_download && data.invoice_download.file_name,
          label: data && data.invoice_download && data.invoice_download.label,
          billing_address: data && data.billing_address_data && data.billing_address_data,
        expiry_date: data && data.expiry_date ,
        payer_note: data && data.payer_note 


        }))
      );

    return (
      <HubContent
        title="Invoice List"
        onArticleDownloadClickHandler={this.oninvoiceHistoryDownloadClick}
      >
        {errorHander}
        <div className="row filter">
          <SelectBox
            className="col-md-3 mb-2"
            label={false}
            onChange={this.filterData}
            field="filter_institution"
            defaultValue="all"
            data={
              
                  this.state.institution_list
                
            }
          />
              
          <SelectBox
            className="col-md-3 mb-2"
            data={invoiceStatusList}
            onChange={this.filterData}
            field="invoice_status"
            defaultValue="all"
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_days"
            defaultValue=""
            data={filterDays}
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_month"
            defaultValue=""
            data={filterMonths}
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_year"
            defaultValue=""
            data={this.filterYears()}
            onChange={this.filterData}
          />

          <InputGroup
            className="col-md-3 mb-2"
            onClick={this.filterData}
            field="search_text"
            placeholder="Search here... "
          />

          { this.state.tabItems && <div className="col-md-12 mb-2">
            <ul
                className="nav nav-pills article-stage-tabs"
                role="tablist"
            >
                <li
                    className={`nav-item waves-effect waves-light`}
                >
                    <a
                        className={`nav-link active`}
                        data-toggle="tab"
                        href="#invoice"
                        role="tab"
                        onClick={() => this.onTabChangeHandler()}
                    >
                        { `OA-Discount & No OA Deal Inv`}
                    </a>
                </li>

                {this.state.tabItems && this.state.tabItems.map(item => <li key={item.id} className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#invoice"
                      role="tab"
                      onClick={() => this.onTabChangeHandler(item.id)}
                    >
                      { item.label }
                    </a>
                </li>)}
            </ul>
          </div>}

          <div className="tab-content w-100">
            <div className="tab-pane active p-3" id="invoice" role="tabpanel">
              <div className="col-12">
                <div className="card m-b-20">
                  <ExpandHeadingTable heading={trxInvoiceHeading} column={column} />
                  {this.props.payment_by_invoice_list ? (
                    <div>
                      {invoice_data && invoice_data.length > 0 ? (
                        invoice_data.map((item, index) => (
                          <div id="accordion" key={index}>
                            <ExpandData
                              onActionHandler={this.onActionHandler}
                              invoiceUpdate={true}
                              key={index}
                              item={item}
                              column={column}
                              downloadName="invoice"
                            />
                          </div>
                        ))
                      ) : (
                          <div className="card-body" style={{ textAlign: "center" }}>
                            No Data Found
                          </div>
                        )}
                      {this.props.payment_by_invoice_list && this.props.payment_by_invoice_list.length > 0 ? (
                        <PaginationForm
                          pageSize={10}
                          current={this.state.page}
                          pageChange={this.pageChange}
                          count={this.props.payment_by_invoice_list_count}
                        />
                      ) : null}
                    </div>
                  ) : (
                      <Loading type="flat" />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: (state.auth && state.auth.group),
  deal_type: state.OaDeals.deal_type || false,

  payment_by_invoice_list:
    (state.payment &&
      state.payment.article_payment &&
      state.payment.article_payment.results) ,
  payment_by_invoice_list_count:
    (state.payment &&
      state.payment.article_payment &&
      state.payment.article_payment.count) ,
  invoice_download: (state.payment && state.payment.invoice_download) ,
  invoice_download_transction:
    state.payment && state.payment.invoice_download_transction,
  institution_list: (state.institutions &&  state.institutions.institution_list) || false,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDealType: () => dispatch(getAllDealType()),
  getArticleInvoice: (payload) => dispatch(getArticleInvoice(payload)),
  getInvoiceDownload: (payload = {}) => dispatch(getInvoiceDownload(payload)),
  downloadInvoice: (payload = {}) => dispatch(downloadInvoice(payload)),
  paymentinvoiceTransactionDownload: () =>
    dispatch(paymentinvoiceTransactionDownload()),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceHistory);
