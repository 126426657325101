import React from "react";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  SelectBox,
  RadioButtonGroup,
  Button,
  PopupBox,
  ModalAutoComplete,
  ModalSelectBox,
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import { serialize } from "../../../../../core/lib";
import {
  getPublicPublisher,
  getPublicatonTypeList,
  getPublicationList,
  getContentTypeList,
  getFunders,
  getAllCountry,
  createUpdateArticleControlConfig,
  getArticleControlConfig,
} from "../../../../actions";

class Edit extends BaseComponent {
  state = {
    articleControlConfig: {},
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.articleControlConfig.publication_types &&
      this.state.articleControlConfig.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.articleControlConfig.publication_types[0] !== "all"
          ? this.state.articleControlConfig.publication_types.join(",")
          : "";
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publication_list: this.props.publication_list.results,
      });
  };

  onChangeHandler = async (value) => {
    this.setState(
      (prevState) => {
        Object.assign(prevState.articleControlConfig, value);
      },
      () => {
        if (value.hasOwnProperty("publication_types")) {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  async componentDidMount() {
    if (this.props.group && this.props.group === "HUB") {
      this.props.getPublicPublisher();
    }

    this.props.getPublicatonTypeList();
    this.props.getContentType();
    this.props.getAllCountry();

    try {
      this.props.getFunders().then((res) => {
        this.setState({
          funderList: [{ id: "all", name: "All funders" }, ...res],
        });
      });
    } catch (error) {
      this.setState({
        funderList: [],
      });
    }

    let id = this.props.match.params.id;

    await this.props.getArticleControlConfig(id);

    let tempArticleControlConfig = this.props.article_control_config;
    let articleControlConfig = {
      organisation: tempArticleControlConfig.organisation,
      publication_types: tempArticleControlConfig.publication_types,
      publications: tempArticleControlConfig.publications,
      content_types: tempArticleControlConfig.content_types,
      institutions: tempArticleControlConfig.institutions,
      funders: tempArticleControlConfig.funders,
      countries: tempArticleControlConfig.countries,
      author_licence_required: tempArticleControlConfig.author_licence_required,
      author_terms_required: tempArticleControlConfig.author_terms_required,
      email_notif: tempArticleControlConfig.email_notif,
    };

    if (tempArticleControlConfig.success) {
      this.setState(
        {
          institutionList: tempArticleControlConfig.institution_details.map(
            (item) => ({ id: item.id, value: item.name })
          ),

          articleControlConfig,
          publication_types: tempArticleControlConfig.publication_types,
          publications: tempArticleControlConfig.publications,
          content_types: tempArticleControlConfig.content_types,
          institutions: tempArticleControlConfig.institutions,
          funders: tempArticleControlConfig.funders,
          countries: tempArticleControlConfig.countries,
          author_licence_required:
            tempArticleControlConfig.author_licence_required,
          author_terms_required: tempArticleControlConfig.author_terms_required,
          email_notif: tempArticleControlConfig.email_notif,
          ...tempArticleControlConfig.email_notif,
        },
        () => {
          this.getPublicationListOnParameterChange();
        }
      );
    }
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.articleControlConfig, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });
      }
    );
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "institutions":
        this.setState({
          isShowInstitutionModal: true,
        });
        break;
      case "publication_types":
        this.setState({
          isShowPublicationTypeModal: true,
        });
        break;
      case "funders":
        this.setState({
          isShowFunderModal: true,
        });
        break;
      case "countries":
        this.setState({
          isShowCountryModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowInstitutionModal: false,
      isShowFunderModal: false,
      isShowCountryModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
      isShowPublicationTypeModal: false,

    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "institutions":
        if (
          this.state.institutionList &&
          this.state.institutionList.length > 0
        ) {
          labelText =
            this.state.institutionList &&
              this.state.institutionList.length === 1
              ? `${this.state.institutionList &&
              this.state.institutionList.length
              } institution selected`
              : `${this.state.institutionList &&
              this.state.institutionList.length
              } institutions selected`;
        } else {
          labelText = `All institutions selected`;
        }
        break;
      case "publication_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Publication type selected`
              : `${fieldValues.length} Publication types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Publications type selected"
              : "Select publications type";
        }
        break;
      case "funders":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} funder selected`
              : `${fieldValues.length} funders selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Funders"
              : "Select Funder";
        }
        break;
      case "countries":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} country selected`
              : `${fieldValues.length} countries selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Countries"
              : "Select Country";
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} publication selected`
              : `${fieldValues.length} publications selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All publications selected"
              : "Select Publication";
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} content type selected`
              : `${fieldValues.length} content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Content Types"
              : "Select Content Type";
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  onChangeRadioButtonHandler = (value) => {
    this.setState((prevState) => {
      let email_notif = {};
      if (prevState.articleControlConfig.email_notif) {
        email_notif = prevState.articleControlConfig.email_notif;
      }

      Object.assign(email_notif, value);

      Object.assign(prevState.articleControlConfig, {
        email_notif: { ...email_notif },
      });
    });
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();
    let data = {};
    Object.assign(data, {
      ...this.state.articleControlConfig,
      institutions: this.state.institutions,
    });

    if (this.props.group !== "HUB") {
      data["organisation"] = this.props.organisation_id;
    }

    if (!data.organisation) {
      this.setState({
        isEmpty: (
          <div>{!data.organisation && <p>Organisation is required.</p>}</div>
        ),
      });
    } else {
      let id = this.props.match.params.id;

      await this.props.createUpdateArticleControlConfig(data, id);

      if (this.props.article_control_config.success) {
        this.props.history.push("/settings/author-notification-list");
      }
    }
  };
  onSaveSelectedInstItems = (value) => {
    this.setState({
      institutions: value && value.map((item) => item.id),
      institutionList: value,
    });
  };
  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    let {
      author_licence_required,
      author_terms_required,
      email_notif,
    } = this.state;

    let { send_email_to_author } = email_notif ? email_notif : {};

    return (
      <HubContent className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.push("/settings/author-notification-list")}
                />
              </div>
              <div className="card-body membership">
                <ul className="nav nav-pills nav-justified" role="tablist" style={{ marginBottom: 30 }}>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Update Author Notification & License Requirements Form

                    </a>
                  </li>
                </ul>

                <div className="tab-content">

                  <form action="">
                    {this.props.group && this.props.group === "HUB" && (
                      <div className="form-group row">
                        <SelectBox
                          label="Publisher"
                          onChange={this.onChangeHandler}
                          field="organisation"
                          data={
                            this.props.societies
                              ? this.props.societies.results
                              : []
                          }
                          isRequired={false}
                          placeholder="Select publisher"
                          defaultValue={this.state.society}
                        />
                      </div>
                    )}

                    <div className="form-group row">
                      <div className="col-sm-4 deal-model">
                        {this.showSelectItemsLabel("publication_types")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publication_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationTypeModal && (
                        <ModalSelectBox
                          modalClass="col-md-6"
                          label="Publications types"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          field="publication_types"
                          data={
                            (this.props.publication_type && [
                              { id: "all", name: "All publication types" },
                              ...this.props.publication_type,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.publication_types}
                          isRequired={false}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                      <div className="col-sm-4 deal-model">
                        {this.showSelectItemsLabel("publications")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publications")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationModal && (
                        <ModalSelectBox
                          label="Publications"
                          field="publications"
                          modalClass="col-md-6"
                          className="col-sm-9"
                          data={
                            (this.state.publication_list && [
                              { id: "all", name: "All publications" },
                              ...this.state.publication_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.publications}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-sm-4 deal-model">
                        {this.showSelectItemsLabel("content_types")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("content_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowContentTypeModal && (
                        <ModalSelectBox
                          label="Content types"
                          field="content_types"
                          modalClass="col-md-6"
                          className="col-sm-9"
                          data={
                            (this.props.content_type && [
                              { id: "all", name: "All content types" },
                              ...this.props.content_type,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.content_types}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-sm-4 deal-model">
                        {this.showSelectItemsLabel("institutions")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("institutions")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowInstitutionModal && (
                        <ModalAutoComplete
                          field="institutions"
                          modalClass="col-md-6"
                          className="col-sm-9"
                          value={this.state.institutions}
                          submitBtnTxt="Save"
                          placeholder="Type your full institution name and select from the drop down"
                          members={this.state.institutionList}
                          onSubmit={this.onSaveSelectedInstItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />

                      )}

                      <div className="col-sm-4 deal-model">
                        {this.showSelectItemsLabel("funders")}
                        <button
                          onClick={() => this.onModalSelectBoxShow("funders")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowFunderModal && (
                        <ModalSelectBox
                          label="Funders"
                          field="funders"
                          modalClass="col-md-6"
                          className="col-sm-9"
                          data={
                            (this.state.funderList && this.state.funderList) ||
                            []
                          }
                          defaultValue={this.state.funders}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-sm-4 deal-model">
                        {this.showSelectItemsLabel("countries")}
                        <button
                          onClick={() => this.onModalSelectBoxShow("countries")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowCountryModal && (
                        <ModalSelectBox
                          label="Countries"
                          field="countries"
                          modalClass="col-md-6"
                          className="col-sm-9"
                          data={
                            (this.props.countries && [
                              { id: "all", name: "All countries" },
                              ...this.props.countries,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.countries}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>
                    <div className=" row">

                      <div className="col-md-6">
                        <p className="notification-author">

                          Would you like to make licence required for author?
                      </p>
                      </div>
                      <div className="col-md-4">

                        {author_licence_required && (
                          <RadioButtonGroup
                            onChange={this.onChangeHandler}
                            field="author_licence_required"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={author_licence_required}
                          />
                        )}
                        {!author_licence_required && (
                          <RadioButtonGroup
                            onChange={this.onChangeHandler}
                            field="author_licence_required"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={false}
                          />
                        )}
                      </div>

                    </div>

                    <div className=" row">

                      <div className="col-md-6">
                        <p className="notification-author">
                          Would you like to make terms and condition required for
                          author?
                      </p>
                      </div>
                      <div className="col-md-4">

                        {author_terms_required && (
                          <RadioButtonGroup
                            onChange={this.onChangeHandler}
                            field="author_terms_required"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={author_terms_required}
                          />
                        )}
                        {!author_terms_required && (
                          <RadioButtonGroup
                            onChange={this.onChangeHandler}
                            field="author_terms_required"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={false}
                          />
                        )}
                      </div>

                    </div>
                    <div className=" row">

                      <div className="col-md-6">
                        <p className="notification-author">
                          Would you like to send email to author?
                      </p>
                      </div>
                      <div className="col-md-4">

                        {send_email_to_author && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="send_email_to_author"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={send_email_to_author}
                          />
                        )}

                        {!send_email_to_author && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="send_email_to_author"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={false}
                          />
                        )}
                      </div>

                    </div>
                    <div className="text-center m-t-15">
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id ,
  group: (state.auth && state.auth.group) ,
  organisation_id: state.auth.user.organisation_id ,
  publishers: state.orgs.publisher_list ,
  token: state.auth.token ,
  countries: state.app.countries,
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  article_control_config:
    state.articleControlConfig.article_control_config ,
});

const mapDispatchToProps = (dispatch) => ({
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getAllCountry: () => dispatch(getAllCountry()),
  getArticleControlConfig: (id) => dispatch(getArticleControlConfig(id)),
  createUpdateArticleControlConfig: (payload, id) =>
    dispatch(createUpdateArticleControlConfig(payload, id)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: () => dispatch(getContentTypeList()),
  getFunders: () => dispatch(getFunders()),
});

export const EditArticleControlConfig = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
