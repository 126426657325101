import React from "react";
import { withRouter } from "react-router-dom";
import { ActionButton } from "../../../../core/components/common";
import { history } from "../../../route";
import { Card } from "antd";
import { connect } from "react-redux";

import { PopupBox, Button } from "../../../../core/components/common";
import {
  downloadInvoice,
} from "../../../../openaccess/actions";

import Highlighter from "react-highlight-words";
import ArticleInfo from "./ArticleTab/ArticleInfo"
import AuthorInfo from "./ArticleTab/AuthorInfo"
import ReviewerInfo from "./ArticleTab/Reviewer"
import MetrixInfo from "./ArticleTab/MetrixInfo"
import FunderInfo from "./ArticleTab/Funderinfo"
import ArticlePriceInfo from "./ArticleTab/ArticlePrices"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class ArticleFull extends React.PureComponent {
  state = {
    view_co_author: false,
    error: null,
    errorInfo: null,
    extraField: {},
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  onClickHander = (e) => {
    e.preventDefault();
    history.push("/request-apc-fund/" + this.props.article.id);
  };

  onEdit = async (key, title, id) => {
    if (key === "license") {
      this.setState({
        showPopupLicense: true,
        key: key,
      });
    } else {
      this.setState({
        title: title,
        showPopup: true,
        key: key,
      });
    }
  };

  onPopupSuccess = (payload) => {
    this.setState(payload);
  };

  priceofFixed = (price) => {
    let dataPrice = Number(price).toFixed(2);

    return dataPrice;
  };

  isLicenceUpdateRequired = (article) => {
    if (
      article.status === "approved" &&
      (!article.selected_licence || !article.selected_licence.id)
    ) {
      if (this.props.group === "AUTHOR") {
        return (
          <Button
            size="sm"
            name="warning"
            onClick={(e) => {
              e.preventDefault();
              this.onEdit("license", article.id);
            }}
            type="button"
            text="Add licence"
          />
        );
      } else {
        return (
          <Button
            size="sm"
            name="warning"
            onClick={null}
            type="button"
            text="Licence missing"
          />
        );
      }
    }

    return "";
  };

  highlightSearchText = (text, searchText, fullText = false) => {
    if (!searchText || !text) {
      return text;
    }

    let lowerText = text.toLowerCase();
    let lowerSearchText = searchText.toLowerCase();

    if (fullText && lowerText !== lowerSearchText) {
      return text;
    }

    return (
      <Highlighter
        searchWords={[searchText]}
        autoEscape={true}
        textToHighlight={text}
      />
    );
  };
  contentToolTip = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
               <div className="failed-transfer-custom-btn"><button className="custom-btn" onClick={onClose}>X</button></div>

            <h3 style={{
              textAlign: "center",
              paddingBottom: 10,
              borderBottom: "1px solid #e4e4e4",
              fontSize:18
              }}>Reason for unsuccessful ingestion </h3>
            <div className="falied-info-tooltip">

              {this.props.article && this.props.article.unmatch_errors &&
                this.props.article.unmatch_errors.map(item =>
                  <p style={{ marginBottom: 10, textAlign: "center", fontSize: 16, color: "#1c225f" }}
                    key={item}>{item}.</p>)
      }
         <p style={{marginTop:10}}>  System did not find match for following information, please click on the update button to correct the information.

Most of the cases article does not successfully convert due to author affiliation mismatch.

OaMetrix support team
  continuously monitoring and improving institutional information.


Please contact <a href="mailto:support@oametrix.co.uk">support@oametrix.co.uk</a> for any help.</p>   
 
 
</div>
          </div>
        );
      }
    });
  }
  render() {
    let { article, url_article_id, search_text } = this.props;

    let showState =
      article && article.id.toString() === url_article_id ? true : false;
    let errorHander = (
      <>
        {this.state.status && (
          <PopupBox
            title="Addtional field"
            className="col-md-6 additional-field"
            extraField={true}
            NoText="Cancel"
            required_id={article.id}
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.showPopup && (
          <PopupBox
            title={this.state.title}
            keyField={true}
            className="col-sm-4 departments-fields"
            keyName={this.state.key}
            NoText="Cancel"
            id={article.id}
            onPopupSuccess={this.onPopupSuccess}
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.showPopupLicense && (
          <PopupBox
            title={"License"}
            LicenseForm={true}
            className="col-sm-6 departments-fields"
            keyName={this.state.key}
            NoText="Cancel"
            license_article_id={article.id}
            onPopupSuccess={this.onPopupSuccess}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    let {
      author,
      actions,
      selected_licence,
    } = article;


    let selected_license_anchor =
      article && selected_licence && selected_licence.license_link ? (
        <a
          href={article && selected_licence && selected_licence.license_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          { selected_licence && selected_licence.name}
        </a>
      ) : (
        article && selected_licence && selected_licence.name
      );

    let author_name =
      author &&
      `${author.salutaion || ""} ${author.first_name || ""} ${author.last_name || ""
      }`;
    let articleDetail = this.props.article;
    return this.state.error ? (
      <Card className="bg-danger text-white">Something is wrong...!</Card>
    ) : (
      <div className="card mb-1">
        {errorHander}

        <div className=" p-3" id="heading">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box  pt-0 pb-3">
                <h4 className="page-title">
                  <a
                    className="article_open_arrow ss"
                    href={`#collapse_${article.id}`}
                    data-toggle="collapse"
                    aria-expanded="true"
                    aria-controls={`collapse_${article.id}`}
                    style={{ color: "#5874ab", fontWeight: 500 }}
                  >
                    {this.highlightSearchText(article.title, search_text) ||
                      "Untitled"}


                  </a>
                </h4>
                  <div className="falied-tooltip" style={{ marginLeft: 15, float: "left" }}>
                  <button type="danger" onClick={this.contentToolTip}>Review</button>

             

                </div>


              </div>
              <a
                className="article_open_arrow ss"
                href={`#collapse_${article.id}`}
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls={`collapse_${article.id}`}
                style={{ color: "#5874ab", fontWeight: 500 }}
              >
                <i className="ion-arrow-down-b float-right" />
              </a>
            </div>
          </div>
          {/*End Of Title*/}

          {/* Author Info */}
          <div className="row">
            <div className="col-sm-5">
              <p className="m-0">
                <strong>Corresponding author : </strong>
                {this.highlightSearchText(author_name, search_text)}
              </p>
              <p className="m-0">
                <strong>Email : </strong> {author && author.email}
              </p>
              <p className="m-0">
                {article && selected_licence && selected_licence.license_link && <strong>License : </strong>}
                {selected_license_anchor}
                {article && selected_licence && selected_licence.license_link && (
                  <a
                    style={{ marginLeft: 20 }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={selected_licence.license_link}
                  >
                    {" "}
                    <i className="mdi mdi-download"></i>
                  </a>
                )}
                {article && article.is_licence_edit_icon === true
                  ? this.isLicenceUpdateRequired(article)
                  : null}
              </p>

            </div>
            <div className="col-sm-4">
              <div className="m-0">
                <strong>Institution :</strong>{" "}
                {author &&
                  this.highlightSearchText(author.affiliation, search_text)}
              </div>
              <div className="m-0">
                <strong>Department :</strong>{" "}
                {author && author.department && author.department.name}
                {this.state.department}

              </div>

            </div>
            <div className={`col-sm-3 `}>



              {actions && actions.map((data, key) => <ActionButton
                key={key}
                tooltip={this.props.user}
                text={data.label}
                color={data.color_key}
                action={data.action}
                onClickHander={() =>
                  this.props.onActionHandler &&
                  this.props.onActionHandler(data.action)
                }
              />
              )}

            </div>
          </div>
        </div>
        {
          article && article.id && (
            <div
              id={`collapse_${article.id}`}
              className={`collapse ${showState && "show"}`}
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="container-fluid">
                <div className="row info_border_top">
                  <div className="col-sm-12">
                    <ul
                      className="nav nav-tabs nav-tabs-custom nav-justified"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href={`#main-info_${article.id}`}
                          role="tab"
                        >
                          Article Info
                  </a>
                      </li>
                      <li className="nav-item">

                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href={`#author-info_${article.id}`}
                          role="tab"
                        >
                          Authors
                  </a>
                      </li>
                      {/* WARNING: IT'S ONLY FOR PUBLISHER AND HUB. NEVER TRY TO USE FOR UNIVERSITY , AUTHOR, USERS, CONSTORIA, FUNDERS AND OTHER DASHBOARD */}
                      {(this.props.group === "PUBLISHER" || this.props.group === "HUB") &&
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href={`#reviewer_${article.id}`}
                            role="tab"
                          >
                            Reviewers
                  </a>
                        </li>}
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href={`#funder_${article.id}`}
                          role="tab"
                        >
                          Funders
                  </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href={`#metrix_${article.id}`}
                          role="tab"
                        >
                          Metrics
                  </a>
                      </li>
                      {articleDetail && articleDetail.custom_questions_modified && articleDetail.custom_questions_modified.length > 0 &&

                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href={`#miscellinious_${article.id}`}
                            role="tab"
                          >
                            Miscellaneous
                  </a>
                        </li>}
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href={`#price-gbp_${article.id}`}
                          role="tab"
                        >
                          Prices
                  </a>
                      </li>

                    </ul>

                    <div className="tab-content">
                      <div
                        className="tab-pane active p-3"
                        id={`main-info_${article.id}`}
                        role="tabpanel"
                      >
                        <ArticleInfo search_text={search_text} articleDetail={articleDetail} highlightSearchText={(value, search) => this.highlightSearchText(value, search)} />

                      </div>
                      <div
                        className="tab-pane p-3"
                        id={`author-info_${article.id}`}
                        role="tabpanel"
                      >
                        <AuthorInfo author={articleDetail && articleDetail.author} co_authors={articleDetail && articleDetail.co_authors} />

                      </div>

                      {(this.props.group === "PUBLISHER" || this.props.group === "HUB") &&
                        <div
                          className="tab-pane p-3"
                          id={`reviewer_${article.id}`}
                          role="tabpanel"
                        >

                          <ReviewerInfo reviewers={articleDetail && articleDetail.reviewer_details} />
                        </div>}
                      <div
                        className="tab-pane p-3"
                        id={`metrix_${article.id}`}
                        role="tabpanel"
                      >
                        <MetrixInfo alt_metric_score={articleDetail && articleDetail.alt_metric_score} crossref_count={articleDetail && articleDetail.crossref_count} />
                      </div>

                      <div
                        className="tab-pane p-3"
                        id={`funder_${article.id}`}
                        role="tabpanel"
                      >

                        <FunderInfo funders={articleDetail && articleDetail.all_funders_json} funderdetail={articleDetail} />
                      </div>
                      <div
                        className="tab-pane p-3"
                        id={`miscellinious_${article.id}`}
                        role="tabpanel"
                      >
                        {
                          articleDetail && articleDetail.custom_questions_modified && articleDetail.custom_questions_modified.map((item, index) => (
                            <div className="row" key={index}>
                              <div className="col-sm-12">
                                <p className="m-1">{item.question_text}
                                  <strong> {item.answer_text}</strong>
                                </p>
                              </div>
                            </div>

                          ))

                        }

                      </div>

                      <div
                        className="tab-pane p-3"
                        id={`price-gbp_${article.id}`}
                        role="tabpanel"
                      >

                        <ArticlePriceInfo prices={articleDetail} priceofFixed={(value) => this.priceofFixed(value)} />

                      </div>

                    </div>

                  </div>

                </div>


              </div>

            </div>
          )
        }
      </div >
    );
  }
}

const mapStateToProps = (state, props) => ({
  group: (state.auth && state.auth.group),
  invoice_download: (state.payment && state.payment.invoice_download),
});

const mapDispatchToProps = (dispatch, props) => ({

  downloadInvoice: (payload = {}) => dispatch(downloadInvoice(payload)),
});

export const ArticleManualSingle = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticleFull)
);
