import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import BaseComponent, { HubContent } from "../../../../../core/components";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Button, Loading,PopupBox } from "../../../../../core/components/common";
import {
  getAllLicenceDataList,
  singleLicenceList,
  downloadInvoice,
  deleteLicenseMessage,
  getAllLicenseList,
} from "../../../../actions";
import { Popover } from "antd";


const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">License </a>
    <br />

  </div>
);
class GetLicenseList extends BaseComponent {
  state = {
    selectedTab: 0,
    viewOpen: false,
  };
  componentDidMount() {
    this.licenseDataList()
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  licenseDataList = async () => {
    let res = await this.props.getAllLicenceDataList()
    let licenseTag = [];
    let licensedata =
      res && res.results;
    licensedata &&
      licensedata.map((item) =>
        licenseTag.push({
          id: item.id,
          name: item.name,
        })
      );
    let index =
      (this.props.location.state && this.props.location.state.index) ||
      this.state.selectedTab;
    this.licenseTagList(licenseTag[index]);
      this.setState({
        selectedTab: index,
        tagList: [...licenseTag],
      });
    
  }
  licenseTagList = async (item, index = 0) => {
    this.setState({
      selectedTab: index,
    });
    let res = await this.props.getAllLicenseList(item && item.id)
    this.setState({
      licenceMessage: res && res.results,
      id: item && item.id,
    });
  };
 
  handleClose = () => {
    this.setState({
      viewOpen: false,
    });
  };

  addLicenseMessageId = (index) => {
    this.props.history.push({
      pathname: "/settings/create-license",
      state: { licenseId: this.state.id, index: index },
    });
  };
  viewLicenseMessage = (id, viewdata) => {
    this.setState({
      id: id,
      agreements: viewdata,
      viewOpen: !this.state.viewOpen,
    });
    this.props.singleLicenceList(id);
  };
  editLicenseMessage = (editId, index) => {
    this.props.history.push({
      pathname: `/settings/update-license/${editId}`,
      state: { id: this.state.id, index: index },
    });
  };

  licencePdfDownload = async (e, url, name) => {
    e.preventDefault();
    let payload = {
      url: url,
    };

    await this.props.licensepdfdownload(payload);

    const urlObj = window.URL.createObjectURL(this.props.licence_download);
    const link = document.createElement("a");
    link.href = urlObj;
    var d = new Date();

    link.setAttribute(
      "download",
      `${name}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  removeLicenseMessage = (deleteId) => {
    if (deleteId) {
      this.setState({
        deleteConfirm: true,
        deleteId,
      });
    }
  };
  onDeleteHandler = async () => {
    this.setState({
      waitingMsg: "Deleting...",
    });
    const deleteResponse = await this.props.deleteLicenseMessage(this.state.deleteId);
    this.setState({
      waitingMsg: null,
      deleteId: null,
      deleteConfirm: false,
      deleteStatus: deleteResponse.status,
    });

    const resData = await this.props.getAllLicenseList(this.state.id);
    this.setState({
      licenceMessage: resData && resData.results,
    });
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      deleteId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };


  render() {
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">

            {this.state.deleteConfirm && (
          <PopupBox
            title="Are you sure to delete this license?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteHandler}
            waitingMsg={this.state.waitingMsg}
          />
        )}


              <div className="vat-tax-button d-flex justify-content-end" style={{ marginTop: 20 }}>
                <Popover placement="leftTop" content={content} trigger="click">
                  <Button
                    className="btn btn-secondary float-right "
                    type=""
                    name=""
                    text="Help"
                  >Help</Button>
                </Popover>
                <div className="danger float-right"
                >
                  <button
                    type="danger"
                    className="btn btn-danger float-right "
                    onClick={() => this.props.history.push("/settings")}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    X

</button>                  </div>

              </div>

              <div className="card-body useremail">
                <div className="useremail-right">
                  <ul className="nav nav-pills nav-justified" role="tablist">
                    {this.props.isTagLoading !== true ? (
                      this.state.tagList &&
                      this.state.tagList.map((item, index) => (
                        <li
                          className="nav-item waves-effect waves-light"
                          key={item.name}
                        >
                          <a
                            className={`nav-link${this.state.selectedTab === index ? " active" : ""
                              }`}
                            data-toggle="tab"
                            href={`#${item.name}`}
                            onClick={() => this.licenseTagList(item, index)}
                            role="tab"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))
                    ) : (
                        <Loading type="flat" loading="loading" />
                      )}
                  </ul>
                </div>
                <div className="tab-content">
                  {this.state.tagList &&
                    this.state.tagList.map((item, index) => (
                      <div
                        className={`tab-pane p-3${this.state.selectedTab === index ? " active" : ""
                          }`}
                        id={item.name}
                        key={item.name}
                        role="tabpanel"
                      >
                        <div className="mb-3 m-t-15 float-right">
                          <Button
                            type="primary"
                            text="Add license"
                            onClick={() => this.addLicenseMessageId(index)}
                            name="primary"
                          />
                        </div>
                        <table
                          className="table table-striped"
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead className="text-white thead-dark">
                            <tr>
                              <th width="25%">Name</th>
                              <th width="60%">Description</th>
                              <th width="15%">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.props.isLoading !== true && (
                              this.state.licenceMessage &&
                              this.state.licenceMessage.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>{item.description}</td>

                                  <td>
                                    <span>
                                      <span
                                        className="cursor-pointer"
                                        onClick={() =>
                                          this.removeLicenseMessage(item.id)
                                        }
                                        id="deleteBtn"
                                        title="Delete"
                                      >
                                        <i className="mdi mdi-delete"></i>
                                      </span>
                                      |
                                      <span
                                        className="cursor-pointer"
                                        onClick={() =>
                                          this.editLicenseMessage(
                                            item.id,
                                            index
                                          )
                                        }
                                        title="Edit"
                                      >
                                        <i className="mdi mdi-pen"></i>
                                      </span>
                                      |
                                    </span>

                                    <span
                                      className="cursor-pointer"
                                      onClick={() =>
                                        this.viewLicenseMessage(item.id)
                                      }
                                      title="View"
                                    >
                                      <i className="mdi mdi-eye"></i>
                                    </span>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {this.props.isLoading === true && (
                          <Loading type="flat" loading="loading" />
                        )}
                        {this.state.licenceMessage&& this.state.licenceMessage.length<=0 &&
            <div className="card-body no-data-found">No Data Found
          </div>}
                      </div>
                    ))}

         
                  {this.state.viewOpen && (
                    <div
                      id="CoAuthorDialog"
                      className="addmultfield"
                      style={modal.overlay}
                    >
                      <div
                        className={`col-10 col-lg-8 bg-white licence licence-10 alert`}
                        style={modal.viewboxStyle}
                      >
                        <div className="col-md-12">
                          <div className="licence-view">
                            {this.props.isLoading !==true ?ReactHtmlParser(
                              this.props.single_lience &&
                              this.props.single_lience.agreements
                            ):<Loading type="flat" loading="loading" />}
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <button
                                onClick={this.handleClose}
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                              >
                                Close
                              </button>

                              <button
                              
                                style={{ marginLeft: 10 }}

                                onClick={(e) =>
                                  this.licencePdfDownload(
                                    e,
                                    `organisation-licence/${this.state.id}/download/`,
                                    "license"
                                  )
                                }
                                type="button"
                                className="btn btn-secondary waves-effect waves-light"
                              >
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}
let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}
const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0px, 0px)",
    height: boxHeight,
  },
  viewboxStyle: {
    transform: "translate(120px, 120px)",
    height: "600px",
  },
};
const mapStateToProps = (state) => ({
  licenseTagList: state.licence.licenseTagList || [],
  isLoading: state.licence.isLoading,
  isTagLoading: state.licence.isTagLoading,
  single_lience: state.licence.single_lience,
  licence_download: (state.payment && state.payment.invoice_download),
});

const mapDispatchToProps = (dispatch) => ({
  getAllLicenceDataList: () => dispatch(getAllLicenceDataList()),
  deleteLicenseMessage: (id) => dispatch(deleteLicenseMessage(id)),

  getAllLicenseList: (id) => dispatch(getAllLicenseList(id)),
  singleLicenceList: (id) => dispatch(singleLicenceList(id)),
  licensepdfdownload: (payload = {}) => dispatch(downloadInvoice(payload)),
});

export const LicenseList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GetLicenseList)
);
