import { 
    CREATE_GEOGRAPHICAL_DISCOUNT,
    FAILED_GEOGRAPHICAL_DISCOUNT,
    GEOGRAPHICAL_DISCOUNT_LIST,
    GEOGRAPHICAL_DISCOUNT_LIST_SINGLE,
    UPDATE_GEOGRAPHICAL_DISCOUNT
} from "../../config";

const DiscountReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_GEOGRAPHICAL_DISCOUNT: 
            return state = {
                ...state,
                create_discount: action.payload
            }
        case FAILED_GEOGRAPHICAL_DISCOUNT: 
            return state = {
                ...state,
                failed_discount: action.payload
            }
        case GEOGRAPHICAL_DISCOUNT_LIST: 
            return state = {
                ...state,
                discount_list: action.payload
            }
        case GEOGRAPHICAL_DISCOUNT_LIST_SINGLE: 
            return state = {
                ...state,
                discount_single: action.payload
            }
        case UPDATE_GEOGRAPHICAL_DISCOUNT: 
            return state = {
                ...state,
                discount_update: action.payload
            }
        default:
            return state;
    }
}
export default DiscountReducer;
