import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  HubContent,
  DataTable,
} from "../../../../../core/components";
import { GetDepositList, DepositDownloadlist } from "../../../../actions";
import { Loading } from "../../../../../core/components/common";
import moment from "moment";

class Fund extends BaseComponent {
  state = { deposit_list: null };

   componentDidMount() {
    this.apiDataList()
  }
  componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
  }
  apiDataList = async ()=>{
    await this.props.DepositList();
  }

  onArticleDownloadClick = async () => {
    try {
      await this.props.DepositDownloadlist();
      const url = window.URL.createObjectURL(this.props.deposit_download_list);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `deposit_list_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };
      // help menu function 
  HelpButtonClick = () => {
    const url = '/links';
    window.open(url, '_blank');
    
  }
  render() {
    let list = this.props.deposit_list;
    list &&
      list.map((item) => {
        return Object.assign(item, {
          date: moment(item.date_created).format("DD MMM YYYY"),
          publisher: item.counter_organisation_name,
          oa_deal_type: item.oa_deal_type,
          currency: item.currency_name,
          publish_fee: item.publish_fee
            ? Number(item.publish_fee).toFixed(2)
            : null,
          read_fee: item.read_fee ? Number(item.read_fee).toFixed(2) : null,
          total: item.amount
            ? Number(item.amount).toFixed(2)
            : (Number(item.publish_fee) + Number(item.read_fee)).toFixed(2),
          deposit_by: item.created_by_name,
        });
      });

    let heading = [
      "date",
      "publisher",
      "oa_deal_type",
      "currency",
      "publish_fee",
      "read_fee",
      "total",
      "deposit_by",
    ];
    return (
      <HubContent
        title="Deposit fund list"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
        onHelpHandler={ this.HelpButtonClick}
      >
        <div className="row filter">
          <div className="col-12">
            <div className="card m-b-20">
              {this.props.deposit_list ? (
                <DataTable heading={heading} data={list} noAction={true} />
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  deposit_list: state.deposit.deposit_list || null,
  deposit_download_list: state.deposit.deposit_download_list,
});

const mapDispatchToProps = (dispatch) => ({
  DepositList: () => dispatch(GetDepositList()),
  DepositDownloadlist: () => dispatch(DepositDownloadlist()),
});

export const DepositList = connect(mapStateToProps, mapDispatchToProps)(Fund);
