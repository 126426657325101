import React from "react";
import { connect } from "react-redux";
import { AutoComplete, Spin } from "antd";
import {LoadingOutlined } from '@ant-design/icons';

import BaseComponent from "../../../../core/components";
import {
  getGroupList,
  getOrgList,
  getAllCountry,
  getAllState,
  getAllCity,
  getOrgsGroupPermission
  ,hubgetOrgList
} from "../../../actions";
import { getRoleList } from "../../../actions";
import {
  SelectBox,
  InputBox,
} from "../../../../core/components/common";
import UserPermission from "../../views/publisher/user/userPermission/UserPermission";
import { serialize } from "../../../../core/lib";

import "../../../../style/userpermission.css";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.app.groups,
  roles: state.app.roles,
  orgs: state.app.organizations,
  countries: state.app.countries,
  geoLocation: state.app.geoLocation,
  Messages: {},
  user_status: state.user.new_user && state.user.new_user.status,
  group: (state.auth && state.auth.group) || false,
  userPermissionList: state.orgs.orgs_permission_objects,
});
const mapDispatchToProps = (dispatch) => ({
  hubgetOrgList:(params)=>dispatch(hubgetOrgList(params)),
  getRoleList: () => dispatch(getRoleList()),
  getGroupList: () => dispatch(getGroupList()),
  getOrgList: (payload) => dispatch(getOrgList(payload)),
  getAllCountry: () => dispatch(getAllCountry()),
  getAllState: (payload) => dispatch(getAllState(payload)),
  getAllCity: (payload) => dispatch(getAllCity(payload)),
  getOrgsGroupPermission:(id)=>dispatch(getOrgsGroupPermission(id))
});

class Form extends BaseComponent {
  state = {
    user: {},
    roleList: false,
    groupList: false,
    addressCounter: 0,
    addresses: [],
    user_meta: {},
    domain_meta: {},
    orgStatus: false,
    stateStatus: [],
    cityStatus: [],
    selectedOrganization: false,
    errMsg: false,
    additional_info: {},
    isOpen: false,
    isOpenUserPermission: false,
    isOpenExpand: false,
    userPermissionList:[]
  };

  async componentDidMount() {
    this.props.user &&
      this.setState(
        {
          user: this.props.user,
          addressCounter: this.props.user.addresses
            ? this.props.user.addresses.length
            : 0,
        },
        async () => {
          const org =
            this.state.user.group &&
            (await this.props.getOrgList({ group: this.state.user.group }));
          org &&
            org.status &&
            this.setState((prevState) => {
              prevState.orgStatus = true;
              return prevState;
            });
        }
      );
    this.props.getRoleList();
    this.props.getAllCountry();
    if (
      this.props.group !== "INSTITUTION" &&
      this.props.group !== "PUBLISHER"
    ) {
      this.props.getGroupList();
    }
    if(this.props.group ==="PUBLISHER"){
      await this.props.getOrgsGroupPermission(this.props.auth && this.props.auth.user && this.props.auth.user.organisation_id)
    
      this.setState((prevState) => {
        prevState.user = Object.assign(this.state.user, { organisation: this.props.auth && this.props.auth.user && this.props.auth.user.organisation_id });

        prevState.userPermissionList = this.props.userPermissionList &&
        this.props.userPermissionList.permissible_objects;
        return prevState;
      })
    
    } 
    
  }
  componentWillmount() {
    this.setState({ user: this.props.user });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  onChangeHandler = (value) => {
    this.setState((prevState) => {
      if (value.additional_info)
        prevState.additional_info = value.additional_info;
      return Object.assign(prevState.user, value);
    });

    this.onChangeEmailDomain();
  };

  // using this event handler
  onChangeGroupHandler = async (value) => {
    value.email_domain && this.onChangeEmailDomain(value.email_domain);
    this.setState((prevState) => {
      prevState.user = Object.assign(prevState.user, value);
      prevState.orgStatus = false;
      prevState.email_domain = value.email_domain;
      prevState.user.organisation = this.props.user.organisation || false;
      return prevState;
    });
    const org = value.group && (await this.props.getOrgList(value));
    org &&
      org.status &&
      this.setState((prevState) => {
        prevState.orgStatus = true;
        return prevState;
      });
  };

  onChangeAddressHandler = async (i, value) => {
    let adds = this.state.addresses[i] || {};
    let state, city;
    this.setState((prevState) => {
      prevState.addresses[i] = Object.assign(adds, value);
      return prevState;
    });
    let newValue = {
      ...value,
      index: i,
      page_size: 9999,
    };

    if (value.country) {
      this.setState((prevState) => {
        prevState.stateStatus[i] = false;
        return prevState;
      });
      state = await this.props.getAllState(newValue);
      state.status &&
        this.setState((prevState) => {
          prevState.stateStatus[i] = true;
          return prevState;
        });
    } else if (value.state) {
      this.setState((prevState) => {
        prevState.cityStatus[i] = false;
        return prevState;
      });
      city = await this.props.getAllCity(newValue);
      city.status &&
        this.setState((prevState) => {
          prevState.cityStatus[i] = true;
          return prevState;
        });
    }
  };

  onChangeOrcidHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.user_meta, value);
      return prevState;
    });
  };

  onChangeDomainHandler = (value) => {
    this.setState((prevState) => {
      prevState.domain_meta &&
        Object.assign(prevState.user, {
          domain_meta: {
            ...prevState.user.domain_meta,
            ...value,
          },
        });
      return prevState;
    });
  };

  onChangeEmailDomain = () => {
    this.setState((prevState) => {
      prevState.user.email &&
        prevState.user.email.includes(this.state.additional_info.email_domain);
    });
  };

  onSubmitHandler = async (data) => {
    await this.setState((prevState) => {
      prevState.addresses.filter((element) => {
        element.title === "" && delete element.title;
        element.address1 === "" && delete element.address1;
        element.address2 === "" && delete element.address2;
        element.city === "" && delete element.city;
        element.postal_code === "" && delete element.postal_code;
        if (Object.entries(element).length === 0) {
          return (prevState.addresses = []);
        } else {
          return element;
        }
      });
      Object.assign(prevState.user, {
        username: prevState.user.email,
        addresses: prevState.addresses,
        user_meta: prevState.user_meta ? prevState.user_meta : {},
        permissions: data.checkedList,
      });
      return prevState;
    });

    if (!this.state.user.domain_meta) {
      delete this.state.user.domain_meta;
    }
    await this.props.onSubmitHandler(this.state.user);
    if (this.props.user_status === 201) {
      this.setState({
        user: {},
        stateStatus: [],
        cityStatus: [],
        addressCounter: 1,
      });
    }
  };

  incrementAddress = () => {
    if (this.state.addressCounter >= 0) {
      this.setState({
        addressCounter: this.state.addressCounter + 1,
      });
    }
  };

  decrementAddress = () => {
    if (this.state.addressCounter > 0) {
      this.setState((prevState) => {
        prevState.addressCounter = this.state.addressCounter - 1;
        prevState.addresses.pop();
        return prevState;
      });
    }
  };

  handleOpenUserPermission = () => {
    this.setState({
      isOpenUserPermission: !this.state.isOpenUserPermission,
    });
  };
  handleCloseUserPermission = () => {
    this.setState({
      isOpenUserPermission: false,
    });
  };
  handleOpenExpand = () => {
    this.setState({
      isOpenExpand: !this.state.isOpenExpand,
    });
  };
  handleCloseExpand = () => {
    this.setState({
      isOpenExpand: false,
    });
  };

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  onSelect = async (value, item) => {
    if (this.isNumber(item.id)) {
     await this.props.getOrgsGroupPermission(item.id)
    
      this.setState((prevState) => {
        prevState.user = Object.assign(this.state.user, { organisation: item && item.id });
        prevState.userPermissionList = this.props.userPermissionList &&
        this.props.userPermissionList.permissible_objects;
        prevState.orgsError = false;
        return prevState;
      })
    
    } 
    
  };
  onChange = (value) => {
    let data = this.props.orgs && this.props.orgs.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState((prevState) => {
        prevState.user = Object.assign(this.state.user, { organisation: data && data.id});
        return prevState;
      });
     
    } else {
      this.setState((prevState) => {
        prevState.user = Object.assign(this.state.user, { organisation:null});
        return prevState;
      });
      if (value === "") {
        this.setState({
          orgsError: false,
          org_name: value

        })
      } else {
        this.setState({
          orgsError: true,
          org_name: value

        })
      }
    }
  };

  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      let filterParams = {
        domain: this.state.user.group ?  this.state.user.group: (this.props.org_details && this.props.org_details.domain),
        q: value,
      }
      let params = serialize(filterParams);
      await this.props.hubgetOrgList(params);
      this.setState({
        dataList: this.props.orgs && this.props.orgs.map(item => ({
          id: item.id,
          value: item.name,
        }))
      })
    }, 200);
  };
  render() {
    let userGroupKey;
    if (this.props.auth.group === "HUB") {
      let permissible_objects =this.state.userPermissionList
    

      userGroupKey = permissible_objects;
    } else if (this.props.auth.group === "PUBLISHER") {
      let permissible_objects =
        this.state.userPermissionList
      userGroupKey =
        permissible_objects &&
        permissible_objects.filter(
          (item) =>
            this.props.auth &&
            this.props.auth.permissions &&
            this.props.auth.permissions.includes(item.key)
        );

    }

    let addresses = [];
    let user = this.props.user || null;

    for (let i = 0; i <= this.state.addressCounter; i++) {
      addresses.push(
        <div className="addresses" key={i}>
          <div className="form-group row">
            <div className="col-md-4">
              <InputBox
                    labelInfo={true}
                    labelInfoTitle="Enter Address 1"
                onChange={(value) => this.onChangeAddressHandler(i, value)}
                field="address1"
                className="col-md-12"
                placeholder="Enter Address 1"
                defaultValue={
                  user
                    ? user.addresses
                      ? user.addresses.length > 0
                        ? user.addresses[i] && user.addresses[i].address1
                        : ""
                      : ""
                    : ""
                }
              />
            </div>
            <div className="col-md-4">
              <InputBox
                onChange={(value) => this.onChangeAddressHandler(i, value)}
                field="address2"
                labelInfo={true}
                labelInfoTitle="Enter Address 2"
                placeholder="Enter Address 2"
                className="col-md-12"
                defaultValue={
                  user
                    ? user.addresses
                      ? user.addresses.length > 0
                        ? user.addresses[i] && user.addresses[i].address2
                        : ""
                      : ""
                    : ""
                }
              />
            </div>
            <div className="col-md-4">
              <SelectBox
                onChange={(value) => this.onChangeAddressHandler(i, value)}
                field="country"
                className="col-md-12"

                placeholder="Select country"
                data={this.props.countries}
                defaultValue={
                  user
                    ? user.addresses
                      ? user.addresses.length > 0
                        ? user.addresses[i] && user.addresses[i].country
                        : ""
                      : ""
                    : ""
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <InputBox
                  labelInfo={true}
                  labelInfoTitle="Enter state name"
                className="col-md-12"
                onChange={(value) => this.onChangeAddressHandler(i, value)}
                field="state_custom"
                placeholder="Enter state name"
                defaultValue={
                  user
                    ? user.addresses
                      ? user.addresses.length > 0
                        ? user.addresses[i] && user.addresses[i].state_name
                        : ""
                      : ""
                    : ""
                }
              />
            </div>
            <div className="col-md-4">
              <InputBox
                     labelInfo={true}
                     labelInfoTitle="Enter city name"
                className="col-md-12"
                onChange={(value) => this.onChangeAddressHandler(i, value)}
                field="city_custom"
                placeholder="Enter city name"
                defaultValue={
                  user
                    ? user.addresses
                      ? user.addresses.length > 0
                        ? user.addresses[i] && user.addresses[i].city_name
                        : ""
                      : ""
                    : ""
                }
              />
            </div>

            <div className="col-md-4">

              <InputBox
                       labelInfo={true}
                       labelInfoTitle="Enter post code"
                className="col-md-12"
                onChange={(value) => this.onChangeAddressHandler(i, value)}
                field="postal_code"
                placeholder="Enter post code"
                defaultValue={
                  user
                    ? user.addresses
                      ? user.addresses.length > 0
                        ? user.addresses[i] && user.addresses[i].postal_code
                        : ""
                      : ""
                    : ""
                }
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="tab-content user-permission">
        <div className="form-group row">
          <InputBox
            label="Salutation"
            onChange={this.onChangeHandler}
            field="salute"
            labelInfo={true}
            labelInfoTitle="Enter salutation"
            className="col-sm-9 col-md-4 mb-sm-3 mb-md-0"
            labelClass="col-sm-3 col-md-2"
            placeholder="Enter salutation"
            defaultValue={user ? user.salute : ""}
          />
          <InputBox
            label="First name"
            labelInfo={true}
            labelInfoTitle="Enter first name"
            onChange={this.onChangeHandler}
            field="first_name"
            className="col-sm-9 col-md-4"
            labelClass="col-sm-3 col-md-2"
            placeholder="Enter first name"
            isRequired={true}
            defaultValue={user ? user.first_name : ""}
          />
        </div>
        <div className="form-group row">
          <InputBox
                        labelInfo={true}
                        labelInfoTitle="Enter last name"
            onChange={this.onChangeHandler}
            className="col-sm-9 col-md-4 mb-sm-3 mb-md-0"
            labelClass="col-sm-3 col-md-2"
            field="last_name"
            label="Last name"
            placeholder="Enter last name"
            isRequired={true}
            defaultValue={user ? user.last_name : ""}
          />
          <InputBox
            className="col-sm-9 col-md-4"
            labelClass="col-sm-3 col-md-2"
            label="Middle name"
            labelInfo={true}
            labelInfoTitle="Enter Middle name"
            onChange={this.onChangeHandler}
            field="middle_name"
            placeholder="Enter middle name"
            defaultValue={user ? user.middle_name : ""}
          />
        </div>
        <div className="form-group row">
          <InputBox
            onChange={this.onChangeHandler}
            field="email"
            label="Email"
            labelInfo={true}
            labelInfoTitle="Enter email"
            className="col-sm-9 col-md-4 mb-sm-3 mb-md-0"
            labelClass="col-sm-3 col-md-2"
            placeholder="Enter email"
            isRequired={true}
            Message={this.state.Messages && this.state.Messages.email}
            errMsg={this.state.errMsg}
            defaultValue={user ? user.email : ""}
            InputType="email"
          />

          <SelectBox
            label="Role"
            className="col-sm-9 col-md-4"
            labelClass="col-sm-3 col-md-2"
            onChange={this.onChangeHandler}
            field="role"
            labelInfo={true}
            labelInfoTitle="Enter  role"
            placeholder="Select role"
            data={this.props.roles}
            isRequired={true}
            defaultValue={user ? user.role : ""}
          />
        </div>
        {!this.props.notHub && (
          <div className="form-group row">
            <SelectBox
              label="Group"
              onChange={this.onChangeGroupHandler}
              field="group"
              placeholder="Select group"
              data={this.props.groups}
              isRequired={true}
              defaultValue={user ? user.group : ""}
            />
{
  this.state.user.group && <div className="col-md-6">
  <div className="row">
   <div className="col-md-4">                      <label style={{marginLeft:45}}>Organisation<span style={{ color: "red", paddingLeft: "5px",textAlign:'right' }}>*</span></label>
  </div>
   <div className="col-md-8">
   <AutoComplete
                    defaultValue={ this.state.organizations}
                    notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}
                    placeholder="Type your institution name and select from the drop down"
  
                    options={this.state.dataList}
                    style={{
                      width: "100%",
                    }}
                    onSelect={(val, option) => this.onSelect(val, option)}
                    onSearch={this.onSearch}
                    onChange={this.onChange}
                  />
                 
                  
                  
                      
                        <div className="search-auto-spin">
                          {this.props.loading && <Spin indicator={antIcon} />}
                        </div>
  
                        {this.state.orgsError
                          && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
                    </span>}
   </div>
  </div>
  </div>

}
  
             
{
  /*
 <SelectBox
              label="Organisation"
              onChange={this.onChangeHandler}
              field="organisation"
              placeholder="Select organisation name"
              data={this.props.orgs || ["Loading"]}
              isRequired={true}
              disabled={!this.props.user.group}
              onRestFields={this.onChangeEmailDomain}
              defaultValue={user ? user.organisation : ""}
              isData={true}
            />
*/
}

          </div>
        )}
        {this.state.isOpenExpand && (
          <>
            <div className="form-group row">
              <InputBox
                            labelInfo={true}
                            labelInfoTitle="Enter title"
                onChange={this.onChangeDomainHandler}
                field="title"
                label="Title"
                className="col-sm-9 col-md-4 mb-sm-3 mb-md-0"
                labelClass="col-sm-3 col-md-2"
                placeholder="Enter title"
                defaultValue={
                  user ? (user.domain_meta ? user.domain_meta.title : "") : ""
                }
              />

              <InputBox
                 labelInfo={true}
                 labelInfoTitle="Enter position"
                onChange={this.onChangeDomainHandler}
                field="position"
                label="Position"
                className="col-sm-9 col-md-4"
                labelClass="col-sm-3 col-md-2"
                placeholder="Enter position"
                defaultValue={
                  user
                    ? user.domain_meta
                      ? user.domain_meta.position
                      : ""
                    : ""
                }
                isRequired={false}
              />
            </div>
            <div className="form-group row">
              <InputBox
                         labelInfo={true}
                         labelInfoTitle="Enter department"
                onChange={this.onChangeDomainHandler}
                field="department"
                placeholder="Enter department"
                label="Department"
                className="col-sm-9 col-md-4 mb-sm-3 mb-md-0"
                labelClass="col-sm-3 col-md-2"
                defaultValue={
                  user
                    ? user.domain_meta
                      ? user.domain_meta.department
                      : ""
                    : ""
                }
                isRequired={false}
              />
              <InputBox
                       labelInfo={true}
                       labelInfoTitle="Enter phone"
                onChange={this.onChangeDomainHandler}
                field="alt_tel"
                className="col-sm-9 col-md-4"
                labelClass="col-sm-3 col-md-2"
                placeholder="Enter phone"
                label="Phone"
                defaultValue={
                  user ? (user.domain_meta ? user.domain_meta.alt_tel : "") : ""
                }
                isRequired={false}
              />
            </div>
            <div className="form-group row">
              <InputBox
                     labelInfo={true}
                     labelInfoTitle="Enter Orcid ID"
                onChange={this.onChangeOrcidHandler}
                field="orcid_id"
                placeholder="Enter Orcid ID"
                className="col-sm-9 col-md-4 mb-sm-3 mb-md-0"
                labelClass="col-sm-3 col-md-2"
                label="Orcid ID"
                defaultValue={
                  user ? (user.user_meta ? user.user_meta.orcid_id : "") : ""
                }
              />

              <InputBox
                onChange={this.onChangeOrcidHandler}
                field="pmc_id"
                placeholder="Enter PMC ID"
                label="PMC ID"
                labelInfo={true}
                labelInfoTitle="Enter PMC ID"
                className="col-sm-9 col-md-4"
                labelClass="col-sm-3 col-md-2"
                defaultValue={
                  user ? (user.user_meta ? user.user_meta.pmc_id : "") : ""
                }
              />
            </div>
            <div className="form-group row">
              <InputBox
                      labelInfo={true}
                      labelInfoTitle="Enter Mobile"
                onChange={this.onChangeOrcidHandler}
                field="phone"
                placeholder="Enter Mobile"
                label="Mobile"
                className="col-sm-9 col-md-4 mb-sm-3 mb-md-0"
                labelClass="col-sm-3 col-md-2"
                defaultValue={
                  user ? (user.user_meta ? user.user_meta.phone : "") : ""
                }
              />
              <InputBox
                labelInfo={true}
                labelInfoTitle="Enter Office Phone"
                onChange={this.onChangeHandler}
                field="alt_phone"
                label="Office Phone"
                className="col-sm-9 col-md-4"
                labelClass="col-sm-3 col-md-2"
                placeholder="Enter Office Phone"
                defaultValue={
                  user ? (user.user_meta ? user.user_meta.alt_phone : "") : ""
                }
              />
            </div>
          </>
        )}

        <div className="form-group row">
          <div className="col-sm-12 text-right">
            <button
              className="btn btn-primary mr-2"
              type="button"
              onClick={this.handleOpenExpand}
            >
              {this.state.isOpenExpand ? (
                <i className="mdi mdi-minus" style={{ fontSize: 20 }}></i>
              ) : (
                  <i className="mdi mdi-plus" style={{ fontSize: 20 }}></i>
                )}
            </button>
          </div>
        </div>

        {this.props.msg && (
          <div className="form-group row">
            <span className="alert alert-success col-sm-12">
              {this.props.msg}
            </span>
          </div>
        )}

        {this.props.auth.group === "INSTITUTION" ||  this.props.auth.group === "CONSORTIUM" ? (
          <div className="text-center m-t-15">
            <button
              className="btn btn-primary btn-md waves-effect waves-light"
              onClick={this.onSubmitHandler}
            >
             Update user
            </button>
          </div>
        ) : null}
        {  (this.props.auth.group === "PUBLISHER" || this.props.auth.group === "HUB") && userGroupKey && userGroupKey.length === 0 && (
          <div className="text-center m-t-15">
            <button
              className="btn btn-primary btn-md waves-effect waves-light"
              onClick={this.onSubmitHandler}
            >
              Add user
            </button>
          </div>)}
        
        {this.props.auth.group === "PUBLISHER" || this.props.auth.group === "HUB"  ? (
          this.props.user.id ? (
            userGroupKey && userGroupKey.length>0 && (
              <UserPermission
                auth={this.props.auth}
                userId={this.props.user.id}
                permissible_objects={userGroupKey}
                permissions={this.props.permissions}
                onSubmitHandler={this.onSubmitHandler}
              />
            ) 
          ) : userGroupKey &&userGroupKey.length>0&& (
            <UserPermission
              auth={this.props.auth}
              userId={this.props.user.id}
              permissible_objects={userGroupKey}
              permissions={this.props.permissions}
              onSubmitHandler={this.onSubmitHandler}
            />
          ) 
        ) : null}

        {/* </form> */}
      </div>
    );
  }
}

export const UserForm = connect(mapStateToProps, mapDispatchToProps)(Form);