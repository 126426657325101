import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { createUpdateQsDashboard } from '../../../../actions';
import { PopupBox, Loading, InputBox, } from '../../../../../core/components/common';

class Add extends BaseComponent {
    
    state = {
        dashboard: {},
    }

    componentDidMount() { 
     
        this.setState((prevState) => {
            return Object.assign(prevState.dashboard, this.state.params.dashboard );
        })
    }

 
    componentWillMount()
    {
        // when params sent via url
        if (this.props.history.location.state)
        {
            let params = this.props.history.location.state;
            this.setState({ params });
        }
    }

  

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.dashboard, value);
        })
    }

    onSubmitHandler = async (e) => {
        e.preventDefault();

        let data = this.state.dashboard;
        if (!data.name || !data.identity_pool_id || !data.dashboard_group || !data.dashboard_role || !data.dashboard_id) {
            this.setState({
                isEmpty: <div>
                    {!data.name && <p>Dashboard name must be filled</p>} 
                    {!data.identity_pool_id && <p>Pool ID must be filled</p>} 
                    {!data.dashboard_group && <p>Dashboard group must be filled</p>} 
                    {!data.dashboard_role && <p>Dashboard role must be filled</p>} 
                    {!data.dashboard_id && <p>Dashboard ID must be filled</p>} 
                </div>
            })
        } else { 
            this.setState({
                isRegistering: true,
            });
            let id = this.props.match.params.id;

            await this.props.createUpdateQsDashboard(data, id);

            this.cancelLoader();
            
            if (this.props.qs_dashboard.success) {
                this.props.history.push('/quicksight-dashboards/dashboard-list')
            }

        }
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            isEmpty: false
        })
    }

    render() {
        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <HubContent title="Edit quicksight dashboard">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                {/* LoadinG Handling */}
                {this.state.isRegistering && <Loading />}
                {/* LoadinG Handling */}
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card m-b-20">
                            <div className="card-body">

                                <form>
                                    <div className="form-group row">
                                        
                                        <InputBox
                                            label="Name"
                                            onChange={this.onChangeHandler}
                                            field='name'
                                            placeholder="Dashboard name"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                            isRequired={true}
                                            defaultValue={this.state.dashboard.name}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputBox
                                            label="Pool ID"
                                            onChange={this.onChangeHandler}
                                            field='identity_pool_id'
                                            placeholder="Identity pool ID"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                            isRequired={true}
                                            defaultValue={this.state.dashboard.identity_pool_id}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputBox
                                            label="Dashboard group"
                                            onChange={this.onChangeHandler}
                                            field='dashboard_group'
                                            placeholder="Quicksight dashboard group"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                            isRequired={true}
                                            defaultValue={this.state.dashboard.dashboard_group}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputBox
                                            label="Dashboard role"
                                            onChange={this.onChangeHandler}
                                            field='dashboard_role'
                                            placeholder="Quicksight dashboard role"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                            isRequired={true}
                                            defaultValue={this.state.dashboard.dashboard_role}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputBox
                                            label="Dashboard ID"
                                            onChange={this.onChangeHandler}
                                            field='dashboard_id'
                                            placeholder="Quicksight dashboard ID"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                            isRequired={true}
                                            defaultValue={this.state.dashboard.dashboard_id}
                                        />
                                    </div>

                                    <div className="text-center m-t-15">
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={this.onSubmitHandler}
                                        >Submit</button>
                                    </div>
                                </form>
           
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    qs_dashboard: state.QsDashboard.qs_dashboard || false,
})

const mapDispatchToProps = (dispatch) => ({
    createUpdateQsDashboard: (payload, id) => dispatch(createUpdateQsDashboard(payload, id)),
})

export const EditDashboard = connect(mapStateToProps, mapDispatchToProps)(Add);
