import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Pagination } from 'antd';

import BaseComponent, { HubContent } from "../../../../core/components";
import {
  DepositFundTrxn,
  depositFundDownload,
  getInstitutionsList,
  GetOaPubList
} from "../../../actions";
import {
  SelectBox,
  InputGroup,
  ExpandData,
  ExpandHeadingTable
  , Loading
} from "../../../../core/components/common";
import {
  serialize,
  trxDepositHeading,
  column,
  transactionTypes,
  filterDays
} from "../../../../core/lib";

class TransactionDeposit extends BaseComponent {

  state = {
    page: 1,
    filterParams: {
      page:1
    },
    activeItem: null,
  };

  onItemActive = item => {
    this.setState({ activeItem: item });
  };

  componentDidMount() {
    this.props.getInstitutionsList();
    this.getDepositFundList();
    this.getOaPubList()
  }
  getOaPubList = async () => {
    let publisher = await this.props.getOaPubList();
      this.setState(prevState => {
        prevState.publisher_list = publisher;
        return prevState;
      });
    
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  depositTransactionDownloadClick = async () => {
    try {
      await this.props.depositFundDownload();
      const url = window.URL.createObjectURL(this.props.deposit_download);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();
      link.setAttribute(
        "download",
        `${this.props.group.toLowerCase()}-deposit-transaction-${moment(
          d
        ).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  getDepositFundList = async (pageNum = 1,) => {
    let value = {
      page: pageNum,
  };
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
  
    });
  
    let params = serialize(this.state.params);
    let filter = serialize(this.state.filterParams);
  
    await this.props.DepositFundTrxn({ params, filter });

    this.setState({
      page: pageNum,
    })
    
  };

  pageChange = pageNumber => {
    this.getDepositFundList(pageNumber);
  };

  filterData = async value => {
    value.page = 1;

    if (value.hasOwnProperty("search_text")) {
      this.setState({
        ...value,
      });
    }

     this.setState(prevState => {
      if (value.filter_transaction_type === "all") {
        value.filter_transaction_type = "";
      } else if (value.filter_institution === "all") {
        value.filter_institution = "";
      } else if (value.filter_publisher === "all") {
        value.filter_publisher = "";
      }

      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });

    
    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.DepositFundTrxn({ params, filter });
  };

  render() {
    let { deposit_trxn } = this.props;
    let data = [];
    if (deposit_trxn.results) {

      if (this.props.group === "PUBLISHER") {
        deposit_trxn.results.map(trx => {
          let transaction_value;

          if (trx.transaction_type === "DEBIT") {
            transaction_value = "Approval";
          } else if (trx.amount < 0) {
            transaction_value = "Refund";
          } else {
            transaction_value = "Deposit";
          }
          return data.push({
            ...trx,
            date: moment(trx.date_created, "DD-MM-YYYY").format(),
            publisher: trx.publisher_name,
            institution: trx.institution_name,
            transaction_type: transaction_value,
            total: Math.abs(trx.amount),
            article_id: trx.article_info.article_id,
            article: trx.article_info.article_title,
            author: trx.article_info.author_name,
            currency: trx.currency_name,
            grant_number: trx.grant_number,
            funder: trx.breakdown.map(item => item.funder_name).join()
          });
        });
      } else {
        deposit_trxn.results.map(trx => {
          let transaction_value;

          if (trx.transaction_type === "DEBIT") {
            transaction_value = "Approval";
          } else if (trx.amount < 0) {
            transaction_value = "Refund";
          } else {
            transaction_value = "Deposit";
          }
          return data.push({
            ...trx,
            date: moment(trx.date_created, "DD-MM-YYYY").format(),
            publisher: trx.publisher_name,
            institution: trx.institution_name,
            transaction_type: transaction_value,
            total: Math.abs(trx.amount),
            article_id: trx.article_info.article_id,
            article: trx.article_info.article_title,
            author: trx.article_info.author_name,
            currency: trx.currency_name,
            grant_number: trx.grant_number,
            funder: trx.breakdown.map(item => item.funder_name).join()
          });
        });
      }
    }

    let inst = [
      {
        name: this.props.group ==="PUBLISHER" ? "All Institutions" :"All Publishers",
        id: "all",
      },
    ];

    return (
      <HubContent
        title="Transaction - deposit fund"
        onArticleDownloadClickHandler={this.depositTransactionDownloadClick}
      >
        <div className="row filter">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                {this.props.group === "PUBLISHER" ? (
                  <SelectBox
                    className="col-md-12"
                    label={false}
                    defaultValue="All"
                    onChange={this.filterData}
                    field="filter_institution"
                    data={
                      inst.concat(this.props.institution_list)
                    }
                  />
                ) : (
                    <SelectBox
                      className="col-md-12"
                      label={false}
                      onChange={this.filterData}
                      field="filter_publisher"
                      defaultValue="All"
                      data={
                        inst.concat(this.props.institution_list)
                      }
                    
                    />
                  )}
              </div>
              <div className="col-md-4">
                <SelectBox
                  className="col-md-12"
                  data={transactionTypes}
                  onChange={this.filterData}
                  field="filter_transaction_type"
                  defaultValue="all"
                />
              </div>
              <div className="col-md-4">
                <SelectBox
                  className="col-md-12"
                  field="filter_days"
                  defaultValue=""
                  data={filterDays}
                  onChange={this.filterData}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <  InputGroup
              className="col-md-12"
              onClick={this.filterData}
              field="search_text"
              placeholder="Search here... "
            />
          </div>{" "}
          <div className="col-12">
            <div className="card m-b-20">
              <ExpandHeadingTable heading={trxDepositHeading} />
              {
                this.props.isLoading !== true ? <div>
                  {
                    data && data.length > 0 ? data && data.map((item, index) => (
                      <div id="accordion" key={index}>
                        <ExpandData
                          onActionHandler={this.onActionHandler}
                          invoiceUpdate={true}
                          key={index}
                          item={item}

                          column={column}
                          downloadName="Invoice download"
                        />
                      </div>
                    )) :
                      <div className="card-body" style={{ textAlign: "center" }}>No Data Found</div>
                  }

                </div>

                  : <Loading type="flat" />

              }
              {data && data.length > 0 && <div style={{ textAlign: "center", marginTop: 20 }}> <Pagination
                
                showSizeChanger={ false} defaultCurrent={this.state.page} pageSize={10} onChange={this.pageChange} total={deposit_trxn.count} />
              </div>}

            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.trxn.isLoading,
  deposit_trxn: state.trxn.deposit_trxn,
  group: (state.auth && state.auth.group) || false,
  deposit_download: state.deposit.download_data,
  institution_list: (state.institutions && state.institutions.institution_list) || []
});

const mapDispatchToProps = dispatch => ({
  DepositFundTrxn: payload => dispatch(DepositFundTrxn(payload)),
  depositFundDownload: payload => dispatch(depositFundDownload(payload)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
  getOaPubList: () => dispatch(GetOaPubList())
});

export const TrxDeposit = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionDeposit);





