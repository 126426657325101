import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serialize } from ".././../../../../../core/lib";
import { Pagination } from "antd";
import { Loading } from "../../../../../../core/components/common"

import moment from "moment";
import { getAllBookList } from "../../../../../actions";
class BookSeriesTable extends React.Component {
  state = {
    filterParams: { page: 1 },
  };

  getBookData = async (pageNum = 1) => {
    await this.props.getAllBookList({ pageNum: pageNum });
    if (this._isMounted) {
      this.setState({
        data: this.props.articles.results,
        count: this.props.articles.count,
      });
    }
  };
  _isMounted = false
  componentDidMount() {
    this.getBookData();
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }
  onEditBook = (id) => {
    this.props.history.push({
      pathname: `/article/update-book/${id}`,
      state: { id: this.state.id, index: 2 },
    });
  };
  onViewUser = (id) => {
    this.props.history.push(`/article/singlebooks/${id}`);
  };

  pageChange = (pageNumber) => {
    this.getBookData(pageNumber);
  };

  filterData = (value) => {
    this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = serialize(this.state.filterParams);
    this.setState({
      filter_url: filter,
    });
  };

  onFilter = async () => {
    let filter = this.state.filter_url;
    await this.props.getAllBookList(filter);
  };
  render() {
    let TableData = [];

    this.state.data &&
      this.state.data.map((data) => {
        return TableData.push({
          id: data.id,
          title: data.title,
          book_Id: data.article_id,
          author:
            data.author &&
            `${data.author.first_name || ""} ${data.author.last_name || ""}`,
          acceptance_date: data.acceptance_date
            ? moment(data.acceptance_date).format("Do MMM YYYY")
            : "-",
          pISBN: data.pisbn,
          eisbn: data.eisbn,
        });
      });

    return (
      <div>

        <table
          className="table table-striped"
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            width: "100%",
          }}
        >
          <thead className="text-white thead-dark">
            <tr>
              <th width="30%">Title</th>
              <th width="20%">Book ID</th>
              <th width="20%">Author</th>
              <th width="10%">eISBN</th>
              <th width="30%"> Acceptance</th>
              <th width="10%">Action</th>
            </tr>
          </thead>
          {this.props.isLoading !== true && <tbody>
            {TableData &&
              TableData.map((item) => (
                <tr key={item.id}>
                  <td>{item.title}</td>
                  <td>{item.book_Id}</td>
                  <td>{item.author}</td>

                  <td>{item.eisbn}</td>

                  <td>{item.acceptance_date}</td>

                  <td>
                    <span>
                      <span
                        className="cursor-pointer"
                        onClick={() => this.onEditBook(item.id)}
                        title="Edit"
                      >
                        <i className="mdi mdi-pen"></i>
                      </span>
                      |
                    </span>

                    <span
                      className="cursor-pointer"
                      onClick={() => this.onViewUser(item.id)}
                      title="View"
                    >
                      <i className="mdi mdi-eye"></i>
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>}
        </table>
        {this.props.isLoading === true && <Loading type="flat" />}

        {this.state.count ? (
          <Pagination
           showSizeChanger={ false}
            style={{ marginTop: 10, marginBottom: 20, textAlign: "center" }}
            onChange={this.pageChange}
            pageSize={10}
            total={this.state.count || undefined}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  articles: state.articles.book_list || false,
  isLoading: state.articles.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  getAllBookList: (payload) => dispatch(getAllBookList(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookSeriesTable)
);
