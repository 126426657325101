import React from "react";
import { connect } from "react-redux";
import EditPublisherManualArticalForm from "./PublisherManualArticalForm";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getContentTypeList,
  articleFileUpload,
  trackArticleFileUploadStatus,
  getPublicatonTypeList,
  CreateArticle,
  EditArticle,
  getSingleArticle,
  trackArticleFileUploadStatusDownload,
  getJournalTypeList,
} from "../../../../actions";
import { PopupBox, Loading,Button } from "../../../../../core/components/common";

class Article extends BaseComponent {
  state = {
    article: false,
    isLoading: false,
    status: null,
    fileUploadStatus: null,
    fileUploadStatusMsg: null,
    pageSubtitle: "Enter article details",
  };

  async componentDidMount() {
    this.checkUrl();
    await this.props.getJournalTypeList();
    await this.props.getContentType();
    await this.props.getPublicatonType();
  }

  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  checkUrl = async () => {
    if (this.props.match.params.id) {
      let { article } = this.props.location.state;
      this.setState({
        pageTitle: "Edit Article Form",
        pageSubtitle: "Edit Article Details",
        article,
      });
    }
  };



  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
      if (
        !obj[key] ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  };



  onSubmitHandler = async (payload) => {
    this.setState({
      isLoading: true,
    });

    if (this.props.match.params.id) {
      Object.keys(payload).map((data) => {
        if ((payload && payload[data] === null) || (payload && payload[data] === "")) {
          delete payload[data];
        }
        return payload;
      });


      if (typeof payload && payload.author !== undefined) {
        this.removeEmpty(payload && payload.author)
      }
      payload && payload.co_authors && payload.co_authors.map((item) => this.removeEmpty(item))


      await this.props.EditArticle(payload);
      let failed_conversion_reason = this.props.article_single && this.props.article_single.failed_conversion_reason;

      if (failed_conversion_reason && failed_conversion_reason.message) {
        this.setState({
          msg: failed_conversion_reason.message,
        });
      } else {
        this.props.status === 200 &&
          this.props.history.push("/article/failed-transfers");
      }
    } else {
      await this.props.CreateArticle(payload);
      this.props.status === 201 &&
        this.props.history.push("/article/failed-transfers");
    }

    this.setState({
      isLoading: false,
    });
  };

  onArticleFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });

    await this.props.articleFileUpload(payload);
    if (this.props.article_file_upload.status) {
      let payload = {
        upload_status: this.props.article_file_upload.data.upload_status.slice(
          1
        ),
      };
      this.setState({
        upload_payload: payload,
      });
      await this.props.trackArticleFileUploadStatus(payload);
    }

    if (
      this.props.track_upload_status &&
      this.props.track_upload_status.reason
    ) {
      let upload_id = this.props.track_upload_status.id;

      let upload_download_status = `file-upload-error-response/${upload_id}/`;
      let payloadDownload = {
        upload_status: upload_download_status,
      };
      await this.props.trackArticleFileUploadStatusDownload(payloadDownload);
    }

    this.setState({
      fileUploadStatus: this.props.article_file_upload.status,
      fileUploadStatusMsg: this.props.article_file_upload.data.message,
      isLoading: false,
    });
  };

  cancelLoader = async () => {
    this.setState({
      status: null,
      msg: null,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
      isLoading: false,
    });
  };

  render() {
    let { article } = this.state;
    let errorHander = (
      <>
        {this.state.fileUploadStatus === false && (
          <PopupBox
            title="Bad Request"
            msg={
              this.state.fileUploadStatusMsg && this.state.fileUploadStatusMsg
            }
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.fileUploadStatus === true && (
          <PopupBox
            title="Success"
            msg={this.state.fileUploadStatusMsg}
            upload={true}
            upload_payload={this.state.upload_payload}
            onCancel={this.cancelLoader}
            className="col-sm-8"
          />
        )}

        {this.state.msg && (
          <PopupBox
            title="Failed"
            msg={this.state.msg}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return (
      <HubContent>
        {/* Error Handler */}
        {errorHander}
        {/* Error Handler */}

        {/* Loader */}
    < div className="row">
          <div className="col-lg-12">
            
            <div className="card m-b-20  article-update">
            <div className="vat-tax-button email">
                  <Button
                    type="danger"
                    name="danger"
                    text="x"
                    onClick={() => this.props.history.goBack()}
                  />
            </div>
            <div className="card-body">
              {this.props.match.params.id ? (
                article ? (
                    <EditPublisherManualArticalForm
                    isLoading={this.props.isUpdateLoading}

                    journal_type={this.props.journal_type}
                    onSubmitHandler={this.onSubmitHandler}
                    articleFileUploadHandler={this.onArticleFileUpload}
                    article={article}
                  />
                ) : (
                    <Loading type="flat" />
                  )
              ) : (
                  ""
                )}

              {!this.props.match.params.id && (
                  <EditPublisherManualArticalForm
                  isLoading={this.props.isUpdateLoading}
                  journal_type={this.props.journal_type}
                  onSubmitHandler={this.onSubmitHandler}
                  articleFileUploadHandler={this.onArticleFileUpload}
                />
              )}
            </div>
          </div>
        </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  journal_type: state.journals.journal_type,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  article_single: state.articles.article_status ? state.articles.article_status.data : null,
  article_file_upload: state.articles.article_file_upload ,
  track_upload_status: state.articles.track_upload_status,
  isUpdateLoading:state.articles.isUpdateLoading,
  track_upload_status_download:
    state.articles.track_upload_status_download ,
});

const mapDispatchToProps = (dispatch) => ({
  getContentType: () => dispatch(getContentTypeList()),
  getPublicatonType: () => dispatch(getPublicatonTypeList()),
  CreateArticle: (payload) => dispatch(CreateArticle(payload)),
  EditArticle: (payload) => dispatch(EditArticle(payload)),
  getSingleArticle: (id) => dispatch(getSingleArticle(id)),
  articleFileUpload: (payload) => dispatch(articleFileUpload(payload)),
  trackArticleFileUploadStatus: (payload) =>
    dispatch(trackArticleFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) =>
    dispatch(trackArticleFileUploadStatusDownload(payload)),
  getJournalTypeList: () => dispatch(getJournalTypeList()),
});

export const EditManualArticalForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Article);
