import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "../../../../../core/components/common";

import BaseComponent from "../../../../../core/components";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import {
  getAllLicenceDataList,
  getTermsAndConditionlist,
  singleLicenceList,
  singleTermsandCondition,
  downloadInvoice,
  deleteTermsConditionsMessage,
  deleteLicenseMessage,
  getLicenseList,
} from "../../../../actions";
class GetLicenseList extends BaseComponent {
  state = {
    status: false,
    msg: false,
    selectedTab: 0,
    viewTermsOpen: false,
    viewOpen: false,
  };

  componentDidMount() {
    this.licenceDataList()
  }
  licenceDataList = async () => {
    await this.props.getAllLicenceDataList();
    await this.props.getTermsAndConditionlist();

    let licenseTag = [];

    let licensedata = this.props.getlicencesTag.results;
    licensedata &&
      licensedata.map((item) =>
        licenseTag.push({
          id: item.id,
          name: item.name,
        })
      );
      this.setState({
        taglist: [...licenseTag],
        terms: this.props.get_terms_conditionTag && this.props.get_terms_conditionTag.results,

      });
    

  }
  
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  //CLOSE METHOD
  handleClose = () => {
    this.setState({
      viewOpen: false,
      viewTermsOpen: false,
    });
  };

  // array to object
  AddTermsConditions = () => {
    this.props.history.push("/affiliated-organisations/addlicense");
  };

  EditTermsMessage = (id) => {
    this.props.history.push(`/affiliated-organisations/editlicense/${id}`);
  };

  ViewTermsMessage = (id, viewdata, orgs) => {
    this.setState({
      id: id,
      termss: viewdata,
      viewTermsOpen: !this.state.viewTermsOpen,
      link: orgs && orgs.link,
      label: orgs.label,
    });
    this.props.singleTermsandCondition(id);
  };
  RemoveTermsMessage = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this license?",

      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteTermsConditionsMessage(id).then((res) => {
              if (res.status) {
                this.setState({
                  deletedMsg: "Email deleted successfully",
                });
                this.props.getTermsAndConditionlist().then((res) => {
                  this.setState({
                    terms: this.props.get_terms_conditionTag.results,
                  });
                });

                // let payload = {
                //   tag_id: this.props.email_tag.results[this.state.selectedTab].id,
                // };
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  getlistofTaglist = async (item, index = 0) => {
    const data = await this.props.getLicenseList(item.id);

    this.setState({
      licenceMessage: data && data.results,
      id: item && item.id,
    });
  };
  AddlicenseMessageId = () => {
    this.props.history.push({
      pathname: "/affiliated-organisations/addlicense",
      state: { id: this.state.id },
    });
  };
  ViewLicenseMessage = (id, viewdata) => {
    this.setState({
      id: id,
      agreements: viewdata,
      viewOpen: !this.state.viewOpen,
    });
    this.props.singleLicenceList(id);
  };
  EditLicenseMessage = (editId) => {
    this.props.history.push({
      pathname: `/affiliated-organisations/editlicense/${editId}`,
      state: { id: this.state.id },
    });
  };
  // Download
  licencepdfdownload = async (e, url, name) => {
    e.preventDefault();
    let payload = {
      url: url,
    };

    await this.props.licensepdfdownload(payload);

    const urlObj = window.URL.createObjectURL(this.props.licence_download);
    const link = document.createElement("a");
    link.href = urlObj;
    var d = new Date();

    link.setAttribute(
      "download",
      `${name}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  RemoveLicenseMessage = (id) => {
    confirmAlert({
      title: "",
      message: "Please confirm to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const resDeletedData = await this.props.deleteLicenseMessage(id);
            if (resDeletedData.status) {
              this.setState({
                deletedMsg: "Email deleted successfully",
              });
            }

            const resData = await this.props.getLicenseList(this.state.id);
            this.setState({
              licenceMessage: resData && resData.results,
            });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  render() {
    const termslist = this.state.terms && this.state.terms;
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card m-b-20">
            <div className="vat-tax-button email">
              <Button
                type="danger"
                name="danger"
                text="X"
                onClick={() => this.props.history.goBack()}
              />
            </div>

            <div className="card-body useremail">

              <div className="useremail-right">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link  active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Terms & Conditions
                    </a>
                  </li>

                  {this.state.taglist &&
                    this.state.taglist.map((item) => (
                      <li
                        className="nav-item waves-effect waves-light"
                        key={item.name}
                      >
                        <a
                          className={`nav-link`}
                          data-toggle="tab"
                          href={`#${item.name}`}
                          onClick={() => this.getlistofTaglist(item)}
                          role="tab"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane p-3  active"
                  id="home-1"
                  role="tabpanel"
                >
                  <div className="mb-3 m-t-15 float-right">
                    <button
                      type="button"
                      onClick={() => this.AddTermsConditions()}
                      data-toggle="modal"
                      data-target="#exampleModal"
                      className="btn btn-info waves-effect waves-light"
                    >
                      Add Terms & Conditions
                    </button>
                  </div>
                  <table
                    className="table table-striped"
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead className="text-white thead-dark">
                      <tr>
                        <th width="25%">Name</th>
                        <th width="60%">Description</th>
                        <th width="15%">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {termslist &&
                        termslist.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>
                              <span>
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    this.RemoveTermsMessage(item.id)
                                  }
                                  id="deleteBtn"
                                  title="Delete"
                                >
                                  <i className="mdi mdi-delete"></i>
                                </span>
                                |
                                <span
                                  className="cursor-pointer"
                                  onClick={() => this.EditTermsMessage(item.id)}
                                  title="Edit"
                                >
                                  <i className="mdi mdi-pen"></i>
                                </span>
                                |
                              </span>
                              <span
                                className="cursor-pointer"
                                onClick={() =>
                                  this.ViewTermsMessage(
                                    item.id,
                                    item.terms,
                                    item && item.organisation_terms_download
                                  )
                                }
                                title="View"
                              >
                                <i className="mdi mdi-eye"></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                {this.state.taglist &&
                  this.state.taglist.map((item, index) => (
                    <div
                      className="tab-pane p-3 "
                      id={item.name}
                      key={item.name}
                      role="tabpanel"
                    >
                      <div className="mb-3 m-t-15 float-right">
                        <button
                          type="button"
                          onClick={() => this.AddlicenseMessageId()}
                          data-toggle="modal"
                          data-target="#exampleModal"
                          className="btn btn-info waves-effect waves-light"
                        >
                          Add license
                        </button>
                      </div>
                      <table
                        className="table table-striped"
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead className="text-white thead-dark">
                          <tr>
                            <th width="25%">Name</th>
                            <th width="60%">Description</th>
                            <th width="15%">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.licenceMessage &&
                            this.state.licenceMessage.map((item) => (
                              <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.description}</td>

                                <td>
                                  <span>
                                    <span
                                      className="cursor-pointer"
                                      onClick={() =>
                                        this.RemoveLicenseMessage(item.id)
                                      }
                                      id="deleteBtn"
                                      title="Delete"
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </span>
                                    |
                                    <span
                                      className="cursor-pointer"
                                      onClick={() =>
                                        this.EditLicenseMessage(item.id)
                                      }
                                      title="Edit"
                                    >
                                      <i className="mdi mdi-pen"></i>
                                    </span>
                                    |
                                  </span>

                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      this.ViewLicenseMessage(item.id)
                                    }
                                    title="View"
                                  >
                                    <i className="mdi mdi-eye"></i>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ))}

                {this.state.viewTermsOpen && (
                  <div
                    id="CoAuthorDialog"
                    className="addmultfield"
                    style={modal.overlay}
                  >
                    <div
                      className={`col-10 col-lg-8 bg-white licence licence-10 alert`}
                      style={modal.viewboxStyle}
                    >
                      <div className="col-md-12">
                        <div className="licence-view">
                          {ReactHtmlParser(
                            this.props.single_terms_condition &&
                            this.props.single_terms_condition.terms
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <button
                              onClick={this.handleClose}
                              type="button"
                              className="btn btn-primary waves-effect waves-light licence-btn"
                            >
                              Close
                            </button>
                            <button
                              onClick={(e) =>
                                this.licencepdfdownload(
                                  e,
                                  this.state.link,
                                  "TermsandConditions"
                                )
                              }
                              type="button"
                              className="btn btn-primary waves-effect waves-light licence-btn"
                            >
                              {this.state.label}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.viewOpen && (
                  <div
                    id="CoAuthorDialog"
                    className="addmultfield"
                    style={modal.overlay}
                  >
                    <div
                      className={`col-10 col-lg-8 bg-white licence licence-10 alert`}
                      style={modal.viewboxStyle}
                    >
                      <div className="col-md-12">
                        <div className="licence-view">
                          {ReactHtmlParser(
                            this.props.single_lience &&
                            this.props.single_lience.agreements
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <button
                              onClick={this.handleClose}
                              type="button"
                              className="btn btn-primary waves-effect waves-light licence-btn"
                            >
                              Close
                            </button>

                            <button
                              onClick={(e) =>
                                this.licencepdfdownload(
                                  e,
                                  `organisation-licence/${this.state.id}/download/`,
                                  "license"
                                )
                              }
                              type="button"
                              className="btn btn-primary waves-effect waves-light licence-btn"
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}
const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0px, 0px)",
    height: boxHeight,
  },
  viewboxStyle: {
    transform: "translate(120px, 120px)",
    height: "600px",
  },
};
const mapStateToProps = (state) => ({
  getlicencesTag: state.licence.licenseTagList,
  get_terms_conditionTag: state.licence.get_terms_condition || false,
  get_all_license: state.licence.get_all_license || false,
  single_lience: state.licence.single_lience || false,
  update_terms_condition: state.licence.update_terms_condition || false,
  single_terms_condition: state.licence.single_terms_condition || false,

  licence_download: (state.payment && state.payment.invoice_download) || false,
});

const mapDispatchToProps = (dispatch) => ({
  getAllLicenceDataList: () => dispatch(getAllLicenceDataList()),
  deleteLicenseMessage: (id) => dispatch(deleteLicenseMessage(id)),
  deleteTermsConditionsMessage: (id) =>
    dispatch(deleteTermsConditionsMessage(id)),
  getTermsAndConditionlist: () => dispatch(getTermsAndConditionlist()),
  getLicenseList: (id) => dispatch(getLicenseList(id)),
  singleLicenceList: (id) => dispatch(singleLicenceList(id)),
  singleTermsandCondition: (id) => dispatch(singleTermsandCondition(id)),
  licensepdfdownload: (payload = {}) => dispatch(downloadInvoice(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GetLicenseList)
);
