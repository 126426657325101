import React from "react";
import axios from "axios";
import config from "../../config";
import { confirmAlert } from "react-confirm-alert";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const excludedRoutesFromPopup = [
  "/active-account",
  "/active-account/",
  "/forgot",
  "/forgot/",
];

let pathname = window.location.pathname;

let showPopup = !excludedRoutesFromPopup.includes(pathname);

class BaseAPI {
  constructor() {
    this.setBaseURL();
    this.requestHeaders = {
      // 'X-Requested-With': "XMLHttpRequest"
    };
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        let errorData = null,
          status = null;
        if (error.response) {
          status = error.response.status;
          let response =
            typeof error.response.data !== "undefined"
              ? error.response.data
              : null;

          switch (status) {
            case 404:
              errorData =
                typeof response === "object"
                  ? response
                  : { message: "Not found." };
              break;
            case 500:
              errorData =
                typeof response === "object"
                  ? response
                  : { message: "Internal server error." };
              break;
            case 400:
              errorData =
                typeof response === "object"
                  ? response
                  : { message: "Invalid request." };
              break;
            default:
              errorData =
                typeof response === "object"
                  ? response
                  : { message: "Unknown error." };
              break;
          }

          if (errorData) {
            let msg = false;

            if (errorData.message && errorData.message !== "") {
              if (errorData.message === "Invalid token.") {
                if (showPopup) {
                  msg = "Your session has expired, please refresh or re-login";
                }
              } else {
                msg = errorData.message;
              }
            } else if (errorData.detail && errorData.detail !== "") {
              if (errorData.detail === "Invalid token.") {
                if (showPopup) {
                  msg = "Your session has expired, please refresh or re-login";
                }
              } else {
                msg = errorData.detail;
              }
            } else if (
              error.response.statusText &&
              error.response.statusText !== ""
            ) {
              msg = error.response.statusText;
            }

            // Do something with response error
            msg &&
              confirmAlert({
                title: "",
                message: msg,
                buttons: [
                  {
                    label: "Close",
                    onClick: () => {},
                  },
                ],
              });
          }
        }

        return Promise.reject(error);
      }
    );
  }

  render() {
    return <></>;
  }

  setBaseURL = () => {
    this.baseURL = config.baseURL;
  };
  callAPI = (url, method = "get", payload = {}, headers = {}) => {
    let apiUrl = this.baseURL + url;
    if (method === "get") {
      let promise = new Promise((resolve, reject) => {
        axios
          .get(apiUrl, headers)
          .then((res) => resolve(res.data))
          .catch(reject);
      });
      return promise;
    } else if (method === "put") {
      let promise = new Promise((resolve, reject) => {
        axios
          .put(apiUrl, payload, headers)
          .then((res) => resolve(res))
          .catch(reject);
      });
      return promise;
    } else if (method === "delete") {
      let promise = new Promise((resolve, reject) => {
        axios
          .delete(apiUrl, headers)
          .then((res) => resolve(res))
          .catch(reject);
      });
      return promise;
    } else {
      let promise = new Promise((resolve, reject) => {
        axios
          .post(apiUrl, payload, headers)
          .then((res) => resolve(res))
          .catch((error) => {
            return reject(error);
          });
      });
      return promise;
    }
  };
}

export default BaseAPI;
