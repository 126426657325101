export const role = {
  public: "public",
  private: "private",
};

//Permission KEY
export const userKey = {
  manage_membership_discount: "manage_membership_discount",
  manage_society_fellows_list: "manage_society_fellows_list",
  manage_offset_list: "manage_offset_list",
  manage_meta_transfer: "manage_metadata_transfer",
  manage_config_dashboard: "manage_dashboard_config",
  manage_token_list: "manage_token_list",
  manage_books: "manage_books",
  manage_settings: "manage_settings",
  manage_users: "manage_users",
  manage_cancel_invoice: "manage_cancel_invoice",
  manage_update_payment: "manage_update_payment",
  manage_journals: "manage_journals",
  manage_token: "manage_token",
  manage_oa_deals: "manage_oa_deals",
  manage_invoice: "manage_invoice",
  manage_offset: "manage_offset",
  manage_terms: "manage_terms",
  manage_vat: "manage_vat",
  manage_apps: "manage_apps",
  manage_emails: "manage_emails",
  manage_discount: "manage_discount",
  manage_society_discount: "manage_society_discount",
  manage_waiver: "manage_waiver",
  manage_apc_waiver: "manage_apc_waiver",
  manage_licence_control: "manage_licence_control",
  manage_license: "manage_license",
  manage_affiliated_orgs: "manage_affiliated_orgs",
  manage_society_fellows: "manage_society_fellows",
  manage_transatction_invoice: "manage_transatction_invoice",
  manage_card_payment: "manage_card_payment",
  manage_transations_token: "manage_transations_token",
  manage_deposit_fund: "manage_deposit_fund",
  manage_non_oa_request: "manage_non_oa_request",
  manage_email_outbox: "manage_email_outbox",
  manage_email_inbox: "manage_email_inbox",
  manage_create_book_chapter: "manage_create_book_chapter",
  manage_create_book_series: "manage_create_book_series",
  manage_create_articles: "manage_create_articles",
  manage_failed_articles: "manage_failed_articles",
  manage_articles: "manage_articles",
  manage_institutions: "manage_institutions",
  manage_dashboard: "manage_dashboard",
  mangage_author_nofitication: "manage_metadata_author_notification",
  manage_articles_books: "manage_articles_books",
  manage_orgs_labels: "manage_orgs_labels",
  manage_btn_labels: "manage_btn_labels",
};

export let userKeyPermission = [
  "manage_articles_books",
  "manage_society_discount",
  "manage_membership_discount",
  "manage_society_fellows_list",
  "manage_metadata_author_notification",
  "manage_metadata_transfer",
  "manage_dashboard_config",
  "mangage_author_nofitication",
  "manage_token_list",
  "manage_invoice",
  "manage_vat",
  "manage_licence_control",
  "manage_emails",
  "manage_apc_waiver",
  "manage_waiver",
  "manage_terms",
  "manage_discount",
  "manage_apps",
  "manage_users",
  "manage_books",
  "manage_cancel_invoice",
  "manage_update_payment",
  "manage_journals",
  "manage_token",
  "manage_oa_deals",
  "manage_offset",
  "manage_affiliated_orgs",
  "manage_society_fellows",
  "manage_transatction_invoice",
  "manage_card_payment",
  "manage_transations_token",
  "manage_deposit_fund",
  "manage_email_inbox",
  "manage_email_outbox",
  "manage_create_book_chapter",
  "manage_create_book_series",
  "manage_create_articles",
  "manage_failed_articles",
  "manage_articles",
  "manage_dashboard",
  "manage_settings",
  "manage_orgs_labels",
  "manage_btn_labels",
];

export let dashboardcompontents = [
  {
    key: "estimated_income_summary_component",
    label: "Estimated Income & Pending Articles",
  },
  {
    key: "oa_income_component",
    label: "OA Income",
  },
  {
    key: "pub_deposit_component",
    label: "Deposited Fund",
  },
  {
    key: "pub_available_fund_component",
    label: "Available Funds",
  },
  {
    key: "publisher_payment_invoice_component",
    label: "Card & Invoice",
  },
  {
    key: "article_summary_component",
    label: "Articles",
  },
  {
    key: "read_and_publish_deal_component",
    label: "Read & Publish Deals",
  },
  {
    key: "oa_inst_membership_component",
    label: "OA Membership",
  },
  {
    key: "credit_pending_component",
    label: "Due",
  },
  {
    key: "credit_payment_component",
    label: "Payment",
  },
  {
    key: "book_summary_component",
    label: "Books",
  },
  {
    key: "publisher_institution_component",
    label: "Top Institutions",
  },
  {
    key: "publisher_funder_component",
    label: "Top Funders",
  },
  {
    key: "allocated_offset_component",
    label: "Allocated Offset",
  },
  {
    key: "spent_offset_component",
    label: "Offset fund Used",
  },
  {
    key: "available_offset_component",
    label: "Available Offset Fund",
  },
  {
    key: "publisher_content_type_component",
    label: "Article Types",
  },
  {
    key: "publisher_journal_component",
    label: "Journal Types",
  },
  {
    key: "apc_revenue_compare_component",
    label: "Approval Duration Comparison",
  },
  {
    key: "number_of_articles_compare_component",
    label: "Articles Comparison",
  },
  {
    key: "number_of_days_compare_component",
    label: "Approval Duration Comparison",
  },
  {
    key: "token_summary_component",
    label: "Tokens",
  },
  {
    key: "publisher_new_deals_component",
    label: "New OA Deal",
  },
];
