import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Pagination } from "antd"
import queryString from "query-string";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import CorrectionRequest from "./CorrectionRequest"
import ArticleFullSingle from "./ArticleSingle/ArticleSingle"
import {
    PopupBox,
    Loading,
    SelectBox,
    InputGroup,
    ModalCustom,
    Button

} from "../../../../../../core/components/common";
import SelectBoxName from "../../../../../../core/components/common/SelectBoxName.jsx";
import { serialize } from "../../../../../../core/lib";

import {
    getArticleOfferDetails,
    getPublisherArticleFullList,
    articleDownload,
    getInstitutionsList,
    CorrectionRequestAction,
    getPublicationFiltering,
    getOaDisciplineList,
    getOaSubjectList,
    getPublicationList,
    getArticleFullSingleList,
    sendFreeTextEmail,
    articleStatus,
    getApcArticleList,
    mutateArticleStatus,
    articleAuthorNotification
} from "../../../../../actions";
import {
    filterDays,
} from "./data";
import { AdHocEmailForm, ManualApproveForm, ArticleRevertForm } from "../../../../partials";
import { isArray } from "lodash";


class Article extends BaseComponent {
    state = {
        page: 1,
        pageSize: 10,
        msg: false,
        filterParams: { page: 1, article_stage: 'POST_ACCEPTANCE', },
        params: null,
        isCorrectionRequestOpen: false,
        article_id: null,
        oa_subject_list: [],
        publication_list:[],
        show: null
    };
    articleHandleClick = (index) => {
        if (this.state.show === index) {
          this.setState({
            show: null,
          });
        } else {

          this.setState({
            show: index,
          });
        }
      };
  
    componentDidMount() {
        if (this.props.location && this.props.location.filter && this.props.location.filter.page) {
            this.pageChange(this.props.location && this.props.location.filter && this.props.location.filter.page)
        }
        this.articleFullDataRequestList()
    };
    isArrayEmpty = (value) => {
        return Array.isArray(value) ? value : []
    }

    articleFullDataRequestList = async () => {

        let query_url = queryString.parse(this.props.location.search);
        let article_filter_id = query_url && query_url.id ? query_url.id : null;
        this.props.articleStatus().then(response => {
           
                if (response && response.success) {
                    this.setState({
                        filterStatus: response.data && response.data.length > 0 ? response.data : [{ id: "all", name: "All Status" }],
                    });
                }
            
        }).catch(error => {

        });

        let params = {
            institution_id: this.props.institute,
        };
        if (article_filter_id) {
            params["id"] = article_filter_id;
        }
            this.setState((prevState) => {
                if (this.props.institute) {
                    prevState.filterParams = Object.assign(prevState.filterParams, params);
                } else {
                    if (article_filter_id) {
                        prevState.filterParams = Object.assign(prevState.filterParams, {
                            id: article_filter_id,
                        });
                    }
                }
            });
        


        let filter = this.props.location && this.props.location.filter ? serialize(this.props.location && this.props.location.filter) : serialize(this.state.filterParams);

        if (this.props.location && this.props.location.apc_article_id) {
            await this.props.getApcArticleList(this.props.location && this.props.location.apc_article_id)
            await this.props.getArticleFullSingleList(this.props.location && this.props.location.apc_article_id)
            await this.props.getOaDisciplineList();
            await this.props.getPublicationFiltering();

        } else {

            await this.props.ArticleFull({ filter });
            let arrayChecking = Array.isArray(this.props.articles) ? this.props.articles : []
            let articles = arrayChecking && arrayChecking.map(item => item.id);
            if (articles && articles.length > 0) {
                this.props.getArticleOfferDetails(articles && articles.join()).then(response => {
                        this.setState({
                            articleOffer: response
                        })
                    
                }).catch(error => {
                });
            }

           await this.props.getOaDisciplineList();
           await this.props.getPublicationFiltering();
    
        }
  

        if (!this.props.institute) {
            try {
                this.props.getInstitutionsList();
            } catch (e) {

            }
        }


        this.setState({
                count: this.props.count,

                oa_disciplines: [
                    { id: "all", name: "All Disciplines" },
                ].concat(this.isArrayEmpty(this.props.oa_disciplines && this.props.oa_disciplines.results)),
                
                publication_types: [
                    { id: "all", name: "All Publication types" },
                ].concat(this.isArrayEmpty(this.props.publication_types))
                
            });
        
    }
    
    componentWillUnmount() {
            // fix Warning: Can't perform a React state update on an unmounted component
            this.setState = (state,callback)=>{
                return;
            };
        
    }

    filterData = async (value) => {
        value.page = 1;

        if (value.hasOwnProperty("q")) {
            this.setState({
                ...value,
            });
        }

        if (value.department === "All Departments") {
            value.department = "all";
        } else if (value.department === "No Department") {
            value.department = "no_dept";
        }
        if (value.publication_type === "All Publication types") {
            value.publication_type = "all";
        }

        if (value.hasOwnProperty("filter_discipline")) {
            let payload = {
                discipline:
                    value.filter_discipline !== "all" ? value.filter_discipline : "",
            };

            value.filter_subject = "all";
            value.filter_publication = "all";

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
                this.setState({
                    oa_subject_list: this.props.oa_subjects && this.props.oa_subjects.results,
                });
        }

        if (value.hasOwnProperty("filter_subject")) {
            value.filter_publication = "all";
        }

        await this.setState((prevState) => {
            if (value.institution_id === "all") {
                value.filter_publisher = "all";
            }
            prevState.filterParams = Object.assign(prevState.filterParams, value);
        });

        if (
            value.hasOwnProperty("publication_type") ||
            value.hasOwnProperty("filter_discipline") ||
            value.hasOwnProperty("filter_subject")
        ) {
            this.getPublicationListOnParameterChange();
        }

        if (this.state.filterParams.institution_id === "all") {
            delete this.state.filterParams.institution_id;
        }

        if (this.state.filterParams.filter_status === "all") {
            delete this.state.filterParams.filter_status;
        }

        let params = serialize(this.state.params);
        let filter = serialize(this.state.filterParams);
        let articles = await this.props.ArticleFull({ params, filter });

        let articlesOfferId = this.props.articles && this.props.articles.map(item => item.id);
        if (articlesOfferId && articlesOfferId.length > 0) {

            let articleOffer = await this.props.getArticleOfferDetails(articlesOfferId.join())
            this.setState({
                articleOffer
            })
        }
        if (value.q !== "") {
            this.setState({
                count: articles.count
            })
        }
    };
    onPaymentStatusActionHandler = async (action, id) => {
        try {
          switch (action.toLowerCase()) {
            case "invoice_paid":
              this.setState({
                showPopup: true,
                article_id: id,
                update_intent: "paid",
              });
              break;
            case "invoice_acknowledged":
              this.setState({
                showPopup: true,
                article_id: id,
                update_intent: "acknowledge_payment",
              });
              break;
            case "invoice_cancel":
              this.setState({
                showPopup: true,
                article_id: id,
                update_intent: "cancel_invoice",
              });
              break;
            default:
              break;
          }
        } catch (error) {
          this.log("error: ", error);
        }
      };
    onActionHandler = async (action, id) => { 
        try {
            const article = await this.props.article(id);
            switch (action && action.toLowerCase()) {
                case "update":
                    this.props.history.push({
                        pathname: `/article/update-article/${id}`,
                        filter: this.state.filterParams,
                        article: article
                    });
                    break;
                case "article_payment":
                    this.props.history.push(`/payment/addpayment/${id}`, {
                        name: action
                    });
                    break;
                case "article_invoice":
                    this.props.history.push(`/payment/addpayment/${id}`, {
                        name: action
                    });
                        break;
                 case "action_audit":

                    this.props.history.push({
                        pathname: `/audit/audit-trail/${id}`,
                        filter: this.state.filterParams,
                        name: action,
                        id:id
                    });
                    break;
                case "correction_request":
                    this.setState({
                        isCorrectionRequestOpen: true,
                        article_id: id,
                    });
                    break;
                case "unpublish":
                    this.setState({
                        showArticleRevertForm: true,
                        article_id: id,
                    });
                    break;
                case "manual_approve":
                    this.setState({
                        showManualApproveForm: true,
                        article_id: id,
                    });
                    break;
                  case "author_notification":
                        this.setState({
                            showAuthorNotificationForm: true,
                            article_author_id: id,
                        });
                        break;
                default:
                    break;
            }
        } catch (error) {
            this.log("error: ", error);
        }
    };

    pageChange = async (pageNumber) => {
        let value = {
            page: pageNumber,
        };

        await this.setState((prevState) => {
            prevState.filterParams = Object.assign(prevState.filterParams, value);
        });

        let params = serialize(this.state.params);
        let filter = serialize(this.state.filterParams);

        await this.props.ArticleFull({ params, filter });


        this.setState({
            page: pageNumber,
        });

        let articles = this.props.articles && this.props.articles.map(item => item.id);
        if (articles && articles.length > 0) {
            this.props.getArticleOfferDetails(articles.join()).then(response => {

                
                    this.setState({
                        articleOffer: response
                    })
                
            }).catch(error => {
            });
        }

    };

    onTabChangeHandler = async (articleStage = 'PRE_ACCEPTANCE', status = null) => {
        if (articleStage !== 'PRE_ACCEPTANCE') {
            articleStage = 'POST_ACCEPTANCE';
        }

        let params = {
            article_stage: articleStage,
        };

        if (status) {
            params.filter_status = status
        } else {
            params.filter_status = 'all'
        }

        this.filterData(params);
    }

    onCorrectionRequest = async (value) => {
        let data = {
            article: this.state.article_id.toString(),
            ...value,
        };
        try {
            await this.props.CorrectionRequestAction(data);
            this.setState({
                isCorrectionRequestOpen: false,
                article_id: null,
            });

            let filter = await serialize(this.state.filterParams);

            this.props.ArticleFull({ filter });
            let articles = this.props.articles && this.props.articles.map(item => item.id);
            if (articles && articles.length > 0) {
                let articleOffer = await this.props.getArticleOfferDetails(articles.join())
                this.setState({
                    articleOffer
                })
            }

        } catch (e) {

        }
    };

    onArticleDownloadClick = async () => {
        try {
            let filter = await serialize(this.state.filterParams);
            await this.props.articleDownload({ params: 999999, filter });
            const url = window.URL.createObjectURL(this.props.article_download_data);
            const link = document.createElement("a");
            link.href = url;
            var d = new Date();

            link.setAttribute(
                "download",
                `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
        } catch (error) {

        }
    };

    getPublicationListOnParameterChange = async () => {
        let payload = {};
        let filterParams = {
            page_size: 999999,
        };

        if (this.state.filterParams.publication_type) {
            filterParams.publication_type_name =
                this.state.filterParams.publication_type !== "All Publication types"
                    ? this.state.filterParams.publication_type
                    : "";
        }

        if (this.state.filterParams.filter_discipline) {
            filterParams.discipline_ids =
                this.state.filterParams.filter_discipline !== "all"
                    ? this.state.filterParams.filter_discipline
                    : "";
        }

        if (this.state.filterParams.filter_subject) {
            filterParams.subject_ids =
                this.state.filterParams.filter_subject !== "all"
                    ? this.state.filterParams.filter_subject
                    : "";
        }

        payload.filter = serialize(filterParams);
        await this.props.getPublicationList(payload);
            this.setState({
                publication_list: this.isArrayEmpty(this.props.publication_list && this.props.publication_list.results),
            });
    };


    onCancel = () => {
        this.setState({
            isCorrectionRequestOpen: false,
            isEmpty: false,
            successMsg: false,
        });
    };

    hasAccessToTabs = () => {
        let { group } = this.props;
        let groupList = ['HUB', 'PUBLISHER'];

        return groupList.includes(group);
    }

    onCustomEmailButtonClick = async (articleId) => {
        if (!articleId) {
            return null;
        }

        await this.props.getArticleFullSingleList(articleId);
        let articleData = this.props.article_single;
        let subject = '';
        let message_body = '';

        if (articleData && articleData.article_id) {
            subject = `Article ID: ${articleData && articleData.article_id},`;
            message_body = `Article ID: ${articleData && articleData.article_id}`;
        }

        if (articleData.title) {
            subject = `${subject} Article Title: ${articleData && articleData.title}`;
            message_body = `${message_body} <br/>Article Title: ${articleData && articleData.title}`;
        }

        if (articleData && articleData.publication && articleData.publication.name) {
            subject = `${subject}, Publication Name: ${articleData &&  articleData.publication && articleData.publication.name}`;
            message_body = `${message_body} <br/>Publication Name: ${articleData &&  articleData.publication &&  articleData.publication.name}<br/>`;
        }

        subject =subject && subject.trim();
        message_body =message_body&& message_body.trim();

        this.setState({
            showCustomEmailForm: true,
            article_id: articleData.id,
            message_body,
            subject,
        });

    }

    onCloseHandler = () => {
        this.setState({
            showCustomEmailForm: false,
            showManualApproveForm: false,
            article_id: '',
            showAuthorNotificationForm:false,
            showArticleRevertForm:false,
        });
    }

    onManualApproveSuccessHandler = () => {

        this.pageChange(this.state.page || 1)

        this.setState({
            showCustomEmailForm: false,
            showAuthorNotificationForm:false,
            showManualApproveForm: false,
            article_id: '',
        });
    }

    onSuccessHandler = () => {

        this.pageChange(this.state.page || 1)

        this.setState({
            showArticleRevertForm: false,
            article_id: '',
        });
    }

    onChangeHandler = (value) => {
        this.setState({
            ...value,
        });
    }

    onSubmitHandler = (e) => {

        let data = {
            article_id: this.state.article_id,
            organisation: this.state.organisation,
            subject: this.state.subject,
            recipients: this.state.recipients,
            message_body: this.state.message_body,
        };

        if (!data.article_id || !data.subject || !data.recipients || (isArray(data.recipients) && data.recipients.length === 0) || !data.message_body) {
            this.setState({
                isEmpty: <div>
                    {!data.article_id && <p>Article is required.</p>}
                    {(!data.recipients || (isArray(data.recipients) && data.recipients.length === 0)) && <p>Recipient is required.</p>}
                    {!data.subject && <p>Subject is required.</p>}
                    {!data.message_body && <p>Message is required.</p>}
                </div>
            })
        } else {
            this.setState({
                emailLoading: true,
            });

            this.props.sendFreeTextEmail(data).then(response => {
                if (response.data && response.data.success) {
                    this.setState({
                        emailLoading: false,
                        showCustomEmailForm: false,
                        successMsg: response.data.message,
                        article_id: '',
                        organisation: '',
                        subject: '',
                        recipients: '',
                        message_body: '',
                    });
                }
            }).catch(error => {
                this.setState({
                    emailLoading: false,
                });

                return error.response && error.response.data && error.response.data.success;
            });
        }
    };

    onViewArticle = async () => {
        await this.props.ArticleFull()
        let articles = this.props.articles && this.props.articles.map(item => item.id);
        if (articles && articles.length > 0) {
            let articleOffer = await this.props.getArticleOfferDetails(articles.join())
            this.setState({
                articleOffer
            })
        }
    }
    cancelLoader = async () => {
        this.setState({
          showPopup: false,
          acknowledge: false,
        });
  
      };
      sendAuthorNotification = async() =>{
         await this.props.articleAuthorNotification(this.state.article_author_id)

          this.setState({
            showAuthorNotificationForm:false,
            successMsg:this.props.articleSendNotification && this.props.articleSendNotification.message
          })
      }
    render() {
        let query_url = queryString.parse(this.props.location.search);
        let article_filter_id = query_url && query_url.id ? query_url.id : null;
        let { articles, auth, count } = this.props;
        let { showCustomEmailForm, filterStatus, showManualApproveForm, showArticleRevertForm, showAuthorNotificationForm } = this.state;
        let ErrorHandler = true;

        let articleTabs = (auth && auth.article_tabs) || [];

        let institutions = [
            {
                name: "All Institutions",
                id: "all",
            },
        ];
      

         institutions = this.props.institute_list && institutions.concat(this.isArrayEmpty(this.props.institute_list));
        if (articles) {
            if (articles.status === 404) {
                ErrorHandler = (
                    <PopupBox
                        title="Data Not Found"
                        msg="Something wrong, Please contact with admin"
                    />
                );
            } else if (articles.status) {
                ErrorHandler = (
                    <PopupBox
                        title="Unknown Error Found"
                        msg="Something wrong, Please contact with admin"
                    />
                );
            } else {
                ErrorHandler = false;
            }
        }


        let CorrectionRequestOpen = "";
        if (this.state.isCorrectionRequestOpen) {
            CorrectionRequestOpen = (
                <CorrectionRequest
                    title="Correction Request"
                    className="col-sm-7"
                    onCancel={this.onCancel}
                    onPressOK={this.onCorrectionRequest}
                    YesText="Send Request"
                    NoText="Cancel"
                    id={this.state.article_id}
                />
            );
        }

        let successErrMsgPop = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.onCancel}
            />}

            {this.state.successMsg && <PopupBox
                title="Success!"
                msg={this.state.successMsg}
                onCancel={this.onCancel}
            />}
        </>;
        let paymentStatus = (
            <>
            {this.state.showPopup && (
                <PopupBox
                title="Payment Update"
                msg=""
                id={this.state.article_id}
                hideOk={true}
                acknowledge={this.state.acknowledge}
                NoText="Cancel"
                className="col-sm-8"
                onCancel={this.cancelLoader}
                markAsPaid={true}
                update_intent={this.state.update_intent}
                />
            )}
            </>
        );
        let manage_article= this.props.auth &&  this.props.auth.permissions && this.props.auth.permissions.find(item=>item==="manage_articles_books") 

        return (
            <HubContent
                title={this.props.title || ""}
                className={this.props.className}
                onArticleDownloadClickHandler={this.onArticleDownloadClick}
            >
                {paymentStatus}
                {successErrMsgPop}

                {ErrorHandler}
                {CorrectionRequestOpen}

                {showCustomEmailForm && <ModalCustom
                    title="Send Ad Hoc Email"
                    onClose={this.onCloseHandler}
                    modalClass="col-sm-10 col-md-7 col-lg-8"
                    isShow={showCustomEmailForm}
                    submitBtnTxt="Send"
                    onSubmit={this.onSubmitHandler}
                >

                    <AdHocEmailForm
                        emailLoading={this.state.emailLoading}
                        article={this.props.article_single}
                        subject={this.state.subject}
                        recipients={this.state.recipients}

                        message_body={this.state.message_body}
                        article_id={this.state.article_id}
                        onChangeHandler={this.onChangeHandler}
                    />

                </ModalCustom>}
                {showAuthorNotificationForm && <ModalCustom
                    title="Author Notification"
                    onClose={this.onCloseHandler}
                    modalClass="col-sm-6 col-md-6 col-lg-4"
                    isShow={showAuthorNotificationForm}
                    onSubmit={this.onSubmitHandler}
                    hideFooter={true}
                >

                  <h4>You are about to trigger author notification email manually, please confirm.</h4>
<p>Please use this manual trigger only if original author notification was missing or you want to resend the author notification email. </p>
                  <div className="col-md-12 apc-btn-list" style={{ textAlign: "center" }}>

                                <Button type="danger" name="danger" text="No" className="author-notification-no" size={200} onClick={this.onCloseHandler} />
                                <Button type="secondary" name="secondary" text="Yes" className="author-notification-yes" size={200} onClick={()=>this.sendAuthorNotification()} />

                            </div>



                </ModalCustom>}


                {showManualApproveForm && <ModalCustom
                    title="Manual approval"
                    onClose={this.onCloseHandler}
                    modalClass="col-sm-6 col-md-6 col-lg-4"
                    isShow={showManualApproveForm}
                    onSubmit={this.onSubmitHandler}
                    hideFooter={true}
                >

                    <ManualApproveForm 
                        article_id={this.state.article_id}
                        mutateArticleStatus={this.props.mutateArticleStatus}
                        onManualApproveSuccessHandler={this.onManualApproveSuccessHandler}
                    />

                </ModalCustom>}


                {showArticleRevertForm && <ModalCustom
                    title="Revert article"
                    onClose={this.onCloseHandler}
                    modalClass="col-sm-6 col-md-6 col-lg-4"
                    isShow={showArticleRevertForm}
                    onSubmit={this.onSubmitHandler}
                    hideFooter={true}
                >

                    <ArticleRevertForm 
                        article_id={this.state.article_id}
                        mutateArticleStatus={this.props.mutateArticleStatus}
                        onSuccessHandler={this.onSuccessHandler}
                        onClose={this.onCloseHandler}
                    />

                </ModalCustom>}
                
                <div className="row mb-3">
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_status"
                        defaultValue="All Status"
                        data={filterStatus}
                        onChange={this.filterData}
                    />

                    <SelectBox
                        className="col-md-3 mb-2"
                        data={institutions}
                        onChange={this.filterData}
                        field="institution_id"
                        defaultValue="All Institutions"
                    />


         

                    <SelectBoxName
                        className="col-md-3 mb-2"
                        field="publication_type"

                        data={
                            this.state.publication_types 
                                
                        }
                        defaultValue="All Publication types"

                        onChange={this.filterData}
                    />
                   <SelectBox
                         className="col-md-3 mb-2"
                        field="filter_publication"
                        defaultValue={this.state.filterParams.filter_publication || "all"}
                        data={
                            this.state.publication_list
                                ? [
                                    { id: "all", name: "All Publications" },
                                    ...this.state.publication_list,
                                ]
                                : [{ id: "all", name: "All Publications" }]
                        }
                        onChange={this.filterData}
                    />
                  
                  <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_days"
                        defaultValue=""
                        data={filterDays}
                        onChange={this.filterData}
                    />
        
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_discipline"
                        defaultValue={this.state.filterParams.filter_discipline || "all"}
                        data={
                            this.state.oa_disciplines || []
                              
                        }
                        onChange={this.filterData}
                    />

                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_subject"
                        defaultValue={this.state.filterParams.filter_subject || "all"}
                        data={
                            this.state.oa_subject_list
                                ? [
                                    { id: "all", name: "All Subjects" },
                                    ...this.state.oa_subject_list,
                                ]
                                : [{ id: "all", name: "All Subjects" }]
                        }
                        onChange={this.filterData}
                    />
                    <InputGroup
                        onClick={this.filterData}
                        field="q"
                        placeholder="Search"
                        className="col-md-3 mb-2 free-text-search"
                    />

                    {(!this.props.location.apc_article_id && this.hasAccessToTabs()) && <div className="col-md-12">
                        <ul
                            className="nav nav-pills article-stage-tabs"
                            role="tablist"
                        >
                            <li
                                title={!articleTabs.includes('PRE_ACCEPTANCE') ? `You don't have access to this tab. Please contact with administrator.` : ''}
                                className={`nav-item waves-effect waves-light ${!articleTabs.includes('PRE_ACCEPTANCE') && 'disabled-tab'}`}
                            >
                                <a
                                    className={`nav-link`}
                                    data-toggle="tab"
                                    href="#pre-acceptance"
                                    role="tab"
                                    onClick={() => articleTabs.includes('PRE_ACCEPTANCE') ? this.onTabChangeHandler() : null}
                                    disabled={!articleTabs.includes('PRE_ACCEPTANCE')}
                                >
                                    Pre-acceptance
                                </a>
                            </li>
                            <li
                                title={!articleTabs.includes('REJECTED') ? `You don't have access to this tab. Please contact with administrator.` : ''}
                                className={`nav-item waves-effect waves-light ${!articleTabs.includes('REJECTED') && 'disabled-tab'}`}
                            >
                                <a
                                    className={`nav-link`}
                                    data-toggle="tab"
                                    href="#pre-acceptance"
                                    role="tab"
                                    onClick={() => articleTabs.includes('REJECTED') ? this.onTabChangeHandler('POST_ACCEPTANCE', 'rejected') : null}
                                    disabled={!articleTabs.includes('REJECTED')}
                                >
                                    Rejected
                                </a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                                <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#pre-acceptance"
                                    role="tab"
                                    onClick={() => this.onTabChangeHandler('POST_ACCEPTANCE')}
                                >
                                    {`Accepted & Post-acceptance`}
                                </a>
                            </li>
                        </ul>
                    </div>}
                    {
                        this.props.location.apc_article_id && (
                            <div className="col-md-12 apc-btn-list" style={{ textAlign: "right" }}>
                                <Button type="secondary" name="secondary" text="View All APC Requests" className="view-articles" size={200} onClick={() => this.props.history.push("/non-oa-request")} />

                                <Button type="secondary" name="primary" text="View All Articles" className="view-articles" size={200} onClick={this.onViewArticle} />
                            </div>
                        )
                    }


                </div>
            
                {
                    manage_article ? <div className="tab-content">
                        <div className="tab-pane active p-3" id="pre-acceptance" role="tabpanel">
                            <div className="row approved-btn">
                                <div className="col-lg-12">
                                    <div id="accordion" className="article-cards">
                                        {/*START OF LOOP*/}

                                        {!ErrorHandler && this.props.isArticleloading !== true ? (
                                            <>
                                                {articles && articles.map((article, i) => {
                                                    return (
                                                        <ArticleFullSingle
                                                            onPaymentStatusActionHandler={this.onPaymentStatusActionHandler}
                                                            offerLoading={this.props.article_offer_detail_loading}
                                                            articleOffer={this.state.articleOffer && this.state.articleOffer[article.id]}
                                                            key={article.id}
                                                            search_text={this.state.q}
                                                            article={article}
                                                            url_article_id={article_filter_id}
                                                            user={this.props.auth.user.username}
                                                            isAction={false}
                                                            index={article.id}
                                                            show={this.state.show}
                                                            location={this.props.location}
                                                            articleHandleClick={this.articleHandleClick}

                                                            onActionHandler={(action) =>
                                                                this.onActionHandler(action, article.id)
                                                            }
                                                            onCustomEmailButtonClick={this.onCustomEmailButtonClick}
                                                        />
                                                    );
                                                })}

                                                {
                                                    count > 0 ?
                                                        <Pagination
                                                            defaultCurrent={this.state.filterParams.page}
                                                            style={{
                                                                marginTop: 30,
                                                                marginBottom: 20,
                                                                textAlign: "center",
                                                            }}
                                                            showSizeChanger={false}
                                                            onChange={this.pageChange}
                                                            pageSize={this.state.pageSize || 10}
                                                            total={this.props.count || undefined}
                                                        /> : <div className="card">
                                                            <div className="card-body">
                                                                <h6>No Data Found</h6>
                                                            </div>
                                                        </div>
                                                }
                                            </>
                                        ) : (
                                                <Loading type="flat" />
                                            )}

                                        {/* END OF PAGINATION */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div className="card">
                                                            <div className="card-body">
                                                            <h6>No Permission available</h6>
                                                            </div>
                                                        </div>}
            </HubContent>
        );
    }
}

const mapStateToProps = (state, props) => ({
    loading: state.articles.loading,
    article_offer_detail_loading: state.articleOfferDetails.article_detail_loading,
    article_offer_detail: state.articleOfferDetails.article_offer_detail,
    articles:(state.articles&& state.articles.article_full )||[],
    count: state.articles.count,
    article: (id) => {
        let article = state.articles.article_full;
        if (article) {
            return article.find((article) => {
                return id.toString() === article.id.toString() && article;
            });
        } else {
            return false;
        }
    },
    institute_list:( state.institutions && state.institutions.institution_list )|| [],
    publication_types: state.institutions.publication_types,
    article_download_data: state.articles.download_data,
    group: (state.auth && state.auth.group) || false,
    auth: state.auth,
    oa_disciplines: state.OaDiscipline.oa_disciplines || false,
    oa_subjects: state.OaSubject.oa_subjects || false,
    publication_list: state.institutions.publication_list,
    article_single: state.articles.article_single,
    isArticleloading: state.articles.isArticleloading,
    articleSendNotification:state.articles.articleAuthorNotification

});

const mapDispatchToProps = (dispatch, props) => ({
    ArticleFull: (payload = {}) => dispatch(getPublisherArticleFullList(payload)),
    articleDownload: (payload = {}) => dispatch(articleDownload(payload)),
    getInstitutionsList: () => dispatch(getInstitutionsList()),
    getPublicationFiltering: () => dispatch(getPublicationFiltering()),
    CorrectionRequestAction: (payload) =>
        dispatch(CorrectionRequestAction(payload)),
    getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
    getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
    getPublicationList: (payload) => dispatch(getPublicationList(payload)),
    getArticleOfferDetails: (id) => dispatch(getArticleOfferDetails(id)),
    getArticleFullSingleList: (payload) => dispatch(getArticleFullSingleList(payload)),
    sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
    articleStatus: (payload = {}) => dispatch(articleStatus(payload)),
    getApcArticleList: (payload = {}) => dispatch(getApcArticleList(payload)),
    mutateArticleStatus: (ids, action, payload = {}) => dispatch(mutateArticleStatus(ids, action, payload)),
    articleAuthorNotification:(id)=>dispatch(articleAuthorNotification(id))

});

export const ArticleFull = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Article)
);