import React from "react";
import moment from "moment-timezone";
import { withRouter, Link } from "react-router-dom"
import { InfoCircleTwoTone,LoadingOutlined } from '@ant-design/icons';

import BaseComponent from "../../../../core/components";
import { Button, PopupBox } from "../../../../core/components/common";

import { AutoComplete,Spin } from "antd";

import { submitGenericAuthCode, getPublicOrgSignup, createGenericAuthUrl } from "../../../actions";
import { connect } from "react-redux";
import queryString from "query-string";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class OAuthForm extends BaseComponent {
  state = {
    errMsg: null,
    email: "",
    errorFields: false,
    reset_password: {},
    password_reseted: false,
    expired: true,
    visible: false,
    timezone: moment.tz.guess(),
  };
  onChange = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.reset_password, value);
      return prevState;
    });
  };

  componentDidMount = async () => {
    const values = queryString.parse(this.props.location.search);
    if (values.code) {
      let oaAuthCode = await this.props.submitGenericAuthCode({
        "context": "orcid",
        "auth_code": values.code,
        timezone: this.state.timezone,
      })
      this.setState({
        oaAuthCode: oaAuthCode && oaAuthCode.data && oaAuthCode.data.result,
        email: this.props.submit_generic_auth_code.email,
        name: this.props.submit_generic_auth_code.name,
        orcid_id: this.props.submit_generic_auth_code.orcid,
        affiliation: this.props.submit_generic_auth_code.affiliation
      })
    }

  };

  onSubmitHandler = () => {
    const { email, name, orcid_id, affiliation, timezone } = this.state;

    if (this.isNumber(affiliation)) {

      if (email && affiliation) {
        this.props.createGenericAuthUrl({
          email,
          name,
          orcid_id,
          affiliation,
          timezone,
        });
      } else {
        this.setState({
          errorFields: true,
        });
      }
    } else {
      this.setState({
        errorMsg: "It appears you have not selected your correct affiliated institution name from organisation dropdown field, select your correct institution and resubmit. ",
      });

    }
  }

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState({
        affiliation: item.id,
        orgsError: false

      });
    }

  };
  onChange = async (value) => {
    let data = this.props.orgs && this.props.orgs.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        affiliation: data && data.id,
        orgsError: false
      });
    } else {
      this.setState({
        orgsError: true,
        orgs_name:value,
        affiliation: data && data.id,
      });
    }
  };


  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getPublicOrgSignup(value);

      let dataList = [];
      this.props.orgs &&
        this.props.orgs.map((item) =>
          dataList.push({
            id: item.id,
            value: item.name,
          })
        );

      this.setState({
        dataList
      });
    }, 200);
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
    if (e.target.name === 'email') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          emailError: true
        })
      } else {
        this.setState({
          emailError: false,
          email: e.target.value
        })
      }
    }
  }

  onCancel = () => {
    this.setState({
      errorFields: false,
      errorMsg: false,
      orgsMessageVisable: false,
      emailMessageVisable: false,
      infoMessageVisable: false,
    });
  };
  handleOrgMessage = () => {
    this.setState({
      orgsMessageVisable: !this.state.orgsMessageVisable,
      orgs_message: "Select the same institution or organisation name from dropdown that you have provided during submission  of your manuscript. "

    })
  }
  handleEmailMessage = () => {
    this.setState({
      emailMessageVisable: !this.state.emailMessageVisable,
      emailMessage: "Enter the same email address that you have used during submission  of your manuscript.  "
    })
  }
  handleInformationData = () => {
    this.setState({
      infoMessageVisable: !this.state.infoMessageVisable,
      infoMessage: "You are attempting to sign in with your ORCID ID. We have found the following information. Please check this is correct."

    })
  }
  render() {
    const { email, name, orcid_id, affiliation, dataList } = this.state;
    return (
       orcid_id || name? 
        <div className="container">
        {this.state.errorMsg && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.errorMsg}
            onCancel={this.onCancel}
          />
        )}

        {this.state.errorFields && (
          <PopupBox
            title="Required Information Missing"
            email="Email can not be empty"
            onCancel={this.onCancel}
          />
        )}
        {this.state.orgsMessageVisable && (
          <PopupBox
            msg={this.state.orgs_message}
            onCancel={this.onCancel}
          />
        )}
        {this.state.emailMessageVisable && (
          <PopupBox
            msg={this.state.emailMessage}
            onCancel={this.onCancel}
          />
        )}
        {this.state.infoMessageVisable && (
          <PopupBox
            msg={this.state.infoMessage}
            onCancel={this.onCancel}
          />
        )}
        <div className="row">
          <div className="col-md-6 offset-md-4 generic-oricid">
            <div className="card">
              <div className="signup-info-button">
                <Button
                  type="danger"
                  name="danger"
                  text="x"
                  onClick={() => this.props.history.push("/signin")}
                />
              </div>
              <h3 className="signup-header text-center m-10">
                <Link to="/" className="logo logo-admin">
                  <img
                    src="/assets/images/logo-sm.png"
                    style={{ height: "30px" }}
                    alt="logo"
                  />


                </Link>
                <InfoCircleTwoTone style={{
                  fontSize: 18,
                  marginTop: 18,
                  marginLeft: 15
                }} twoToneColor="rgb(122, 111, 190)"
                  onClick={this.handleInformationData}
                />
              </h3>

              <div className="card-body">

                <div className="orcid" style={{ marginBottom: 5 }}>
                  <label htmlFor="username">Your ORCID ID: {orcid_id}</label>
                </div>
                <div className="username" style={{ marginBottom: 5 }}>
                  <label htmlFor="username">Your Name: {name}</label>
                </div>

                <div className="info-message-orcid">
                  <p className="email-name">In order to sign in, please provide <strong>your institutional email address and select your affiliated institution</strong> from the drop down. This information is only required once when registering for the first time.
                  </p>


                </div>

                <div className="form-group row">

                  <div className="col-md-10">
                    <label>Email:<span style={{ color: "red", paddingLeft: "5px" }}>*</span> </label>

                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      placeholder="Enter your valid email"
                      onChange={this.onEmailChange
                      }
                    />
                    {this.state.emailError ? <span style={{ color: "red" }}>Please enter your valid institutional email</span> : ''}

                  </div>
                  <div className="col-md-2">
                    <InfoCircleTwoTone style={{
                      fontSize: 18,
                      marginTop: 38,
                      marginLeft: 13
                    }} twoToneColor="rgb(122, 111, 190)"
                      onClick={this.handleEmailMessage}
                    />
                  </div>

                </div>

                <div className="form-group row auto-complete-search m-t-20">
                    <div className="col-md-12">
                      <label>Institution:<span style={{ color: "red", paddingLeft: "5px" }}>*</span></label>
                  </div>
                  

                    <div className="col-md-10 search-compelete">
                      <div className="search-auto-complete">

                        <AutoComplete
                          notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}
                          placeholder="Type your institution name and select from the drop down"
                          defaultValue={affiliation}

                          options={dataList}
                          style={{
                            width: "100%",
                          }}
                          onSelect={(val, option) => this.onSelect(val, option)}
                          onSearch={this.onSearch}
                        onChange={this.onChange}
                        
                        />
                      </div>
                      <div className="search-auto-spin">
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>

                      {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
</span>}
                  </div>


                  </div>

                <div className="form-group row m-t-20">

                  <div className="col-12 oricid-btn text-center">
                    <button
                      disabled={this.state.affiliation ? false: true}
                      onClick={this.onSubmitHandler}
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> :null

    );
  }
}

const mapStateToProps = (state) => ({
  submit_generic_auth_code: state.genericOAuth.submit_generic_auth_code || false,
  login_data: state.genericOAuth.login_data || false,
  orgs: state.app.organizations,
  loading: state.orgs.loading,

});

const mapDispatchToProps = (dispatch) => ({
  getPublicOrgSignup: (payload) => dispatch(getPublicOrgSignup(payload)),
  submitGenericAuthCode: (payload) => dispatch(submitGenericAuthCode(payload)),
  createGenericAuthUrl: (payload) => dispatch(createGenericAuthUrl(payload)),
});

export const GenericOAuthForm = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(OAuthForm))
