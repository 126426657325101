import {
  REQUEST_CREATE_ORG,
  CREATE_UPDATE_VAT,
  VAT_LIST,
  GET_VAT,
  CREATE_UPDATE_VAT_CONFIG,
  GET_VAT_CONFIG,
  GET_SINGLE_VAT_CONFIG,
  GET_VAT_HUB,
  UPDATE_VAT_HUB,
  GET_SINGLE_VAT_HUB,
  CREATE_VAT_HUB,
  CREATE_VAT_PUB,
  GET_COUNTRY_VAT_PUB,
  CA_STATE_VAT_PUB,
  US_STATE_VAT_PUB,
  CREATE_VAT_PUBLISHER_ACTION,
  REQUEST_VAT_PUBLISHER_ACTION,
  GET_ALL_VAT_PUBLISHER_ACTION,
  GET_SINGLE_VAT_PUBLISHER_ACTION,
  GET_DELETE_VAT_PUBLISHER_ACTION,
  UPDATE_VAT_PUBLISHER_ACTION,
  REQUEST_UPDATE_VAT_PUBLISHER_ACTION,
  GET_UPDATE_SINGLE_VAT_PUBLISHER_ACTION,
  REQUEST_CREATE_CONFIG_ACTION,
  REQUEST_VAT_CONFIGURE,
} from "../../config";

const VatReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_CREATE_ORG:
      return (state = {
        ...state,
        new_org: action.payload,
      });
    case VAT_LIST:
      return (state = {
        ...state,
        vat_list: action.payload,
      });
    case CREATE_UPDATE_VAT:
      return (state = {
        ...state,
        vat_info: action.payload,
      });
    case GET_VAT:
      return (state = {
        ...state,
        vat_info: action.payload,
      });
    case CREATE_UPDATE_VAT_CONFIG:
      return (state = {
        ...state,
        vat_config: action.payload,
      });
    case REQUEST_VAT_CONFIGURE:
      return (state = {
        ...state,
        isLoading: true,
      });
    case GET_VAT_CONFIG:
      return (state = {
        ...state,
        isLoading: false,
        vat_config_list: action.payload,
      });

    case GET_SINGLE_VAT_CONFIG:
      return (state = {
        ...state,
        single_vat_config: action.payload,
      });
    case GET_VAT_HUB:
      return (state = {
        ...state,
        vat_list_hub: action.payload,
      });
    case UPDATE_VAT_HUB:
      return (state = {
        ...state,
        update_vat_hub: action.payload,
      });
    case CREATE_VAT_HUB:
      return (state = {
        ...state,
        create_vat_hub: action.payload,
      });

    case GET_SINGLE_VAT_HUB:
      return (state = {
        ...state,
        single_vat_hub: action.payload,
      });
    case CREATE_VAT_PUB:
      return (state = {
        ...state,
        vat_pub: action.payload,
      });
    case GET_COUNTRY_VAT_PUB:
      return (state = {
        ...state,
        country_list: action.payload,
      });
    case US_STATE_VAT_PUB:
      return (state = {
        ...state,
        us_state_list: action.payload,
      });
    case CA_STATE_VAT_PUB:
      return (state = {
        ...state,
        ca_state_list: action.payload,
      });
    case CREATE_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        create_pub_vat: action.payload,
        isLoading: false,
      });
    case REQUEST_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        isLoading: true,
      });

    case GET_ALL_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        vat_publisher_list: action.payload,
        isLoading: false,
      });
    case REQUEST_CREATE_CONFIG_ACTION:
      return (state = {
        ...state,
        isLoading: true,
      });
    case GET_SINGLE_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        isVatLoading: true,
        single_publisher_vat: action.payload,
      });
    case GET_UPDATE_SINGLE_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        isVatLoading: false,
      });
    case GET_DELETE_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        vat_publisher_delete: action.payload,
      });
    case UPDATE_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        isLoading: false,
        update_publisher: action.payload,
      });
    case REQUEST_UPDATE_VAT_PUBLISHER_ACTION:
      return (state = {
        ...state,
        isLoading: true,
      });

    default:
      return state;
  }
};

export default VatReducer;
