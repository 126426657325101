import React from "react";
import { connect } from "react-redux";
import { Tabs } from 'antd';

import { Button, Loading } from "../../../../../core/components/common";

import EUVIEW from "./vatview/euview";
import UnitedStatesVIEW from "./vatview/usaview";
import CanadaVIEW from "./vatview/caview";
import RESTVIEW from "./vatview/restview";
import UKVIEW from "./vatview/ukview";

import { HubContent } from "../../../../../core/components";
import {
  getAllCountry,
  getVatRatesPubVatConfig,
  getStatePubVatConfig,
  getCAStatePubVatConfig,
  publisherVatCreateConfigure,
  publisherGetSingleVatCreateConfigure,
  publisherUpdateVatCreateConfigure,
  publisherDeleteVatCreateConfigure,
  publisherGetAllVatCreateConfigure,
  createVatConfig,
  createUpdateVatConfig,
  getSingleVatConfig,
  getVatConfig,
  DeleteVatConfig,
} from "../../../../actions";

const { TabPane } = Tabs;
class VatForm extends React.Component {
  state = {};
  componentDidMount() {
    this.apiDataList()
  }

  apiDataList = async () => {
    
    const datavatconfig = await this.props.getVatConfig();
    const vatData = Object.assign({},datavatconfig && datavatconfig.results);

      this.setState({
        vatid: vatData && vatData[0] && vatData[0].id,
        vat_number:
          vatData && vatData[0] && vatData[0].vat_number
            
      });
    

    const data = await this.props.publisherGetSingleVatCreateConfigure(
      this.props.match.params.id
    );
    //For SELF COUNTRY

    this.props
      .getVatRatesPubVatConfig({
        country: data.offices && data.offices[0].country,
      })
      .then((res) => {
        this.setState({
          selfcountry: res.results,
        });
      });
    //For CA COUNTRY

    this.props
      .getVatRatesPubVatConfig({
        eucontext: "EU",
        id: data.offices && data.offices[0].country,
      })
      .then((res) => {
        this.setState({
          vatforeu: res.results,
        });
      });

    this.props
      .getVatRatesPubVatConfig({ context: "OTHER_COUNTRY" })
      .then((res) => {
        this.setState({
          other_countries: res.results,
        });
      });
    this.props.getVatRatesPubVatConfig({ context: "US" }).then(async (res) => {
      const data = await this.props.getStatePubVatConfig(res.results[0].id);
      this.setState({
        stateList: data.results,
        vatforus: res.results,
      });
    });
    //For CA COUNTRY
    this.props.getVatRatesPubVatConfig({ context: "CA" }).then(async (res) => {
      let data = await this.props.getCAStatePubVatConfig(res.results[0].id);
      this.setState({
        vatforca: res.results,
        stateListCA: data.results,
      });
    });
    this.props.getAllCountry();

    //LETS SETUP FOR SELF COUNTRY
    let nochargevat;
    let nochargeselfvat;
    let vatrates;
    let vatchargeoffice =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0].rules[0].charge_vat;
    let vatnochargeOffice =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0] &&
      data.offices[0].rules[0].charge_no_vat;
    let rates_vat_self =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0] &&
      data.offices[0].rules[0].rates_vat;
    let rates_no_vat_self =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0].rules[0].rates_no_vat;

    if (vatchargeoffice === true) {
      nochargevat = vatnochargeOffice;
      vatrates = rates_vat_self;
      if (vatnochargeOffice === false) {
        nochargevat = vatnochargeOffice;
      } else {
        nochargeselfvat = rates_no_vat_self;
        nochargevat = vatnochargeOffice;
      }
    } else if (vatchargeoffice === false) {
      nochargevat = vatnochargeOffice;
      if (nochargevat) {
        nochargeselfvat = rates_no_vat_self;
      } else {
        nochargevat = vatnochargeOffice;
      }
    }

    //LET"S SETUP FOR USA
    let statePosition;
    let nochargevatforus;
    let nochargevatforus2;

    let ratesvatforstates;
    let ratesvatforstates2;

    let ratesvatforcountry2;
    let vatnochargeofficeforus;

    let vatchargeofficeforus;
    let vatchargeStatesOfficeforus;
    let rates_vat_forus;
    let rates_no_vat_forus;
    let vatnochargeStatesOfficeforus;
    if (
      data &&
      data.offices[0] &&
      data.offices[0].country_name === "United States"
    ) {
      vatnochargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_no_vat;

      vatchargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_vat;
      vatchargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_vat;
      vatnochargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_no_vat;
      rates_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_vat;
      rates_no_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_no_vat;
    } else {
      vatnochargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_no_vat;

      vatchargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_vat;
      vatchargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_vat;
      vatnochargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_no_vat;
      rates_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_vat;
      rates_no_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_no_vat;
    }

    let statePositioncharge_false;
    let notchargebasedstates2;
    let noratesvatforcountry;
    let noratesvatforstates;
    let charge_no_vat_usa_2;
    let noratesvatforcountry4;
    let noratesvatforstates4;
    let charge_no_vat_states_usa_3;
    let ratesvatforCountry;
    if (vatchargeofficeforus === true) {
      statePosition = vatchargeStatesOfficeforus;
      if (vatchargeStatesOfficeforus === true) {
        ratesvatforstates = rates_vat_forus;
        statePosition = vatchargeStatesOfficeforus;

        nochargevatforus = vatnochargeofficeforus;
        if (vatnochargeofficeforus === true) {
          //Do charge based on states if don't provide // comes from database
          notchargebasedstates2 = vatnochargeStatesOfficeforus;

          if (vatnochargeStatesOfficeforus === true) {
            //no state
            notchargebasedstates2 = vatnochargeStatesOfficeforus;

            noratesvatforstates = rates_no_vat_forus;
          } else {
            notchargebasedstates2 = vatnochargeStatesOfficeforus;

            //no country
            noratesvatforcountry = rates_no_vat_forus;
          }
        } else {
          nochargevatforus = vatnochargeofficeforus;
        }
      } else {
        ratesvatforCountry = rates_vat_forus;
        statePosition = vatchargeStatesOfficeforus;

        charge_no_vat_usa_2 = vatnochargeofficeforus;
        if (vatnochargeofficeforus === true) {
          //Do charge based on states if don't provide // comes from database
          charge_no_vat_states_usa_3 = vatnochargeStatesOfficeforus;
          if (vatnochargeStatesOfficeforus === true) {
            //no state
            noratesvatforstates4 = rates_no_vat_forus;
          } else {
            charge_no_vat_states_usa_3 = vatnochargeStatesOfficeforus;

            //no country
            noratesvatforcountry4 = rates_no_vat_forus;
          }
        }
      }
    } else if (vatchargeofficeforus === false) {
      nochargevatforus2 = vatnochargeofficeforus;
      if (vatnochargeofficeforus === true) {
        statePositioncharge_false = vatchargeStatesOfficeforus;
        if (vatchargeStatesOfficeforus === true) {
          statePositioncharge_false = vatchargeStatesOfficeforus;
          ratesvatforstates2 = rates_no_vat_forus;
        } else {
          statePositioncharge_false = vatchargeStatesOfficeforus;
          ratesvatforcountry2 = rates_no_vat_forus;
        }
      }
    }

    //LET"S SETUP FOR CA
    let statePositionca;
    let statePositionca2;
    let nochargevatforca;
    let ratesvatforstatesca;
    let ratesvatforstatesca2;
    let ratesvatforcountryca;
    let ratesvatforcountryca2;

    let vatnochargeofficeca;
    let vatchargeofficeforca;
    let vatchargeStatesOfficeforca;
    let rates_vat_forca;
    let rates_no_vat_forca;
    let vatnochargeStatesOfficeforca;
    let canada_no_rates_provience3;
    let canadanoratescountry_vat3;

    // Don't valid boolen Field
    let charge_no_vat_canada_1;
    let provience_no_charge_vat;
    let canadanoratescountry_vat;
    let canada_no_rates_provience;
    let charge_no_vat_canada_2;
    let provience_basedon_vat_no_charge_2;

    if (
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[2] &&
      data.offices[0].rules[2].context === "CA"
    ) {
      vatnochargeofficeca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_no_vat;
      vatchargeofficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_vat;
      vatchargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_vat;
      vatnochargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_no_vat;

      rates_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_vat;
      rates_no_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_no_vat;
    } else if (
      data.offices &&
      data.offices[0] &&
      data.offices[0].country_name === "Canada"
    ) {
      vatnochargeofficeca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_no_vat;
      vatchargeofficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_vat;
      vatchargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_vat;
      vatnochargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_no_vat;

      rates_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_vat;

      rates_no_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_no_vat;
    } else {
      vatnochargeofficeca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_no_vat;
      vatchargeofficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_vat;
      vatchargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_based_on_states_vat;
      vatnochargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_based_on_states_no_vat;

      rates_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].rates_vat;
      rates_no_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].rates_no_vat;
    }

    if (vatchargeofficeforca === true) {
      statePositionca = vatchargeStatesOfficeforca;
      if (vatchargeStatesOfficeforca === true) {
        statePositionca = vatchargeStatesOfficeforca;


        ratesvatforstatesca = rates_vat_forca;
        charge_no_vat_canada_1 = vatnochargeofficeca;

        if (vatnochargeofficeca === true) {
          charge_no_vat_canada_1 = vatnochargeofficeca;

          provience_no_charge_vat = vatnochargeStatesOfficeforca;

          if (vatnochargeStatesOfficeforca === true) {
            provience_no_charge_vat = vatnochargeStatesOfficeforca;

            canada_no_rates_provience = rates_no_vat_forca;
          } else {
            provience_no_charge_vat = vatnochargeStatesOfficeforca;
            canadanoratescountry_vat = rates_no_vat_forca;

          }
        } else {
          charge_no_vat_canada_1 = vatnochargeofficeca;
        }
      } else {
        //NEXT CHARGES
        ratesvatforcountryca = rates_vat_forca;
        statePositionca = vatchargeStatesOfficeforca;
        charge_no_vat_canada_2 = vatnochargeofficeca;

        //don't provide charge
        if (vatnochargeofficeca === true) {
          //DO prvoince
          charge_no_vat_canada_2 = vatnochargeofficeca;
          provience_basedon_vat_no_charge_2 = vatnochargeStatesOfficeforca;
          if (vatnochargeStatesOfficeforca === true) {
            provience_basedon_vat_no_charge_2 = vatnochargeStatesOfficeforca;

            canada_no_rates_provience3 = rates_no_vat_forca;

          } else {
            provience_basedon_vat_no_charge_2 = vatnochargeStatesOfficeforca;
            canadanoratescountry_vat3 = rates_no_vat_forca;

          }
        } else {
          charge_no_vat_canada_2 = vatnochargeofficeca;
        }
      }
    } else if (vatchargeofficeforca === false) {
      nochargevatforca = vatnochargeofficeca;
      if (vatnochargeofficeca === true) {
        statePositionca2 = vatchargeStatesOfficeforca;
        if (vatchargeStatesOfficeforca === true) {
          statePositionca2 = vatchargeStatesOfficeforca;

          ratesvatforstatesca2 = rates_no_vat_forca;
        } else {
          statePositionca2 = vatchargeStatesOfficeforca;
          ratesvatforcountryca2 = rates_no_vat_forca;
        }
      }
    }

    //LET"S SETUP FOR EU

    let blilling_charge_rates_address;
    let blilling_no_charge_rates_address;
    let blilling_no_charge_rates_address2;

    let charge_eu =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].charge_vat;
    let vatnochargeOfficeforeu =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].charge_no_vat;

    let vat_based_billing_address =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].vat_based_billing_address;

    let no_vat_based_billing_address =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].no_vat_based_billing_address;
    let vat_charge_billing_rates =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].rates_vat;
    let vat_charge_no_billing_rates =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].rates_no_vat;
    // boolen values for eu :
    let datanotvalidboolean;
    let charge_billing_charge_vat;
    let charge_billing_no_charge_vat;
    let charge_billing_no_charge_vat2;
    let datanotvalidboolean2;
    let novat_ratesforeu;
    let vat_ratesforeu;
    if (charge_eu === true) {
      //How Do you charge
      charge_billing_charge_vat = vat_based_billing_address; //;

      if (vat_based_billing_address === true) {
        charge_billing_charge_vat = vat_based_billing_address; //;
        blilling_charge_rates_address = vat_charge_billing_rates; //rates_vat

        // Do you charge VAT to EU customers if they do not provide you a valid VAT number?
        datanotvalidboolean = vatnochargeOfficeforeu;
        if (vatnochargeOfficeforeu === true) {
          datanotvalidboolean = vatnochargeOfficeforeu;

          charge_billing_no_charge_vat = no_vat_based_billing_address;

          if (no_vat_based_billing_address === true) {
            charge_billing_no_charge_vat = no_vat_based_billing_address;
            blilling_no_charge_rates_address = vat_charge_no_billing_rates; //rates_no_vat but billing address
          } else {
            blilling_no_charge_rates_address = vat_charge_no_billing_rates; //rates_no_vat
          }
        } else {
          datanotvalidboolean = vatnochargeOfficeforeu;
        }
      } else {
        vat_ratesforeu = vat_charge_billing_rates;
        datanotvalidboolean = vatnochargeOfficeforeu;

        if (vatnochargeOfficeforeu === true) {
          charge_billing_no_charge_vat = no_vat_based_billing_address;
          if (no_vat_based_billing_address === true) {
            blilling_no_charge_rates_address = vat_charge_no_billing_rates; // rates_no_vat
          } else {
            novat_ratesforeu = vat_charge_no_billing_rates;

            // blilling_no_charge_rates_address = vat_charge_no_billing_rates; //rates_no_vat
          }
        }
      }
    } else if (charge_eu === false) {
      datanotvalidboolean2 = vatnochargeOfficeforeu;
      if (vatnochargeOfficeforeu === true) {
        charge_billing_no_charge_vat2 = no_vat_based_billing_address;
        if (no_vat_based_billing_address === true) {
          charge_billing_no_charge_vat2 = no_vat_based_billing_address;
          blilling_no_charge_rates_address2 = vat_charge_no_billing_rates; // no_vates
        } else {
          novat_ratesforeu = vat_charge_no_billing_rates;
        }
      }
    }
    //LET"S SETUP FOR REST OF THE WORLD
    let wavierCharge;
    let waviercountry;
    let has_other_country_vat;
    let has_other_charge_policy_vat;
    let other_charge_policy;
    let other_charge_policy2;

    let other_country_vat;
    let other_country_vat2;
    let restbillingaddress1;

    let restbillingaddress2;
    let other_country;
    let other_country_vat_tax;
    let other_country_vat_tax1;

    let other_country_chg_policies;
    let other_waived_countries;

    wavierCharge = data.waive_other_country_vat;
    other_waived_countries = data.other_waived_countries;
    has_other_charge_policy_vat =
      data.offices &&
      data.offices[0] &&
      data.offices[0].has_other_country_charge_policy;
    has_other_country_vat =
      data.offices && data.offices[0] && data.offices[0].has_other_country_vat;
    other_country =
      data.offices && data.offices[0] && data.offices[0].other_country_vat;
    other_country_chg_policies =
      data.offices &&
      data.offices[0] &&
      data.offices[0].other_country_chg_policies;

    if (wavierCharge === true) {
      //waiver countries vat
      waviercountry = other_waived_countries;
      //boolen value
      other_country_vat = has_other_country_vat;

      if (other_country_vat === true) {
        //boolen value
        other_country_vat = has_other_country_vat;
        //vat rates
        other_country_vat_tax = other_country;

        other_charge_policy = has_other_charge_policy_vat;
        if (other_charge_policy === true) {
          other_charge_policy = has_other_charge_policy_vat;
          //vat rates
          restbillingaddress1 = other_country_chg_policies;
        } else {
          other_charge_policy = has_other_charge_policy_vat;
          // billingaddressforrest = null;
        }
      }
    } else if (wavierCharge === false) {
      other_country_vat2 = has_other_country_vat;

      if (other_country_vat2 === true) {
        other_country_vat_tax1 = other_country;
        other_country_vat2 = has_other_country_vat;
        other_charge_policy2 = has_other_charge_policy_vat;
        if (other_charge_policy2 === true) {
          other_charge_policy2 = has_other_charge_policy_vat;
          restbillingaddress2 = other_country_chg_policies;
        } else {
          other_charge_policy2 = has_other_charge_policy_vat;
        }
      } else {
      }
    }
    if (
      data &&
      data.offices[0] &&
      data.offices[0].country_name !== "United States" &&
      data &&
      data.offices[0] &&
      data.offices[0].country_name !== "Canada"
    ) {
      this.setState({
        tab_1: data && data.offices[0] && data.offices[0].country_name,
        tab_2: "EU",
        tab_3: "United States",
        tab_4: "Canada",
        tab_5: "REST OF THE WORLD",
        origin: data[0]&& data[0].name
      });
    } else if (
      data &&
      data.offices[0] &&
      data.offices[0].country_name === "United States"
    ) {
      this.setState({
        tab_1: "United States",
        tab_2: "UK",
        tab_3: "EU",
        tab_4: "Canada",
        tab_5: "REST OF THE WORLD",
      });
    } else {
      this.setState({
        tab_1: "Canada",
        tab_2: "UK",
        tab_3: "EU",
        tab_4: "United States",
        tab_5: "REST OF THE WORLD",
      });
    }

    this.setState({
      origin: data.offices && data.offices[0].country_name,
      data: data,
      publisher: data && data.publisher,
      id: data && data.id,
      country: data.offices && data.offices[0].country,
      apc_including_vat: data.offices && data.offices[0].apc_include_vat,
      charge_vat: vatchargeoffice,
      charge_no_vat: nochargevat,
      not_charge_vat_number: nochargevat,
      vatselfcountry: vatrates,
      selfCountrynoRates: nochargeselfvat,
      vatselfForEucountrynorates: novat_ratesforeu,
      vat_ratesforeu: vat_ratesforeu,
      charge_vat_number_eu: charge_eu,
      charge_vat_billing_address_eu_1: charge_billing_charge_vat,
      charge_no_vat_number_eu_1: datanotvalidboolean,
      charge_no_vat_billing_address_eu_2: charge_billing_no_charge_vat,

      charge_no_vat_number_eu_2: datanotvalidboolean2,
      charge_no_vat_billing_address_eu_3: charge_billing_no_charge_vat2,
      //RATES
      // vatselfcountry:
      basedoncharge_1: blilling_charge_rates_address,
      basedoncharge_2: blilling_no_charge_rates_address,
      basedoncharge_3: blilling_no_charge_rates_address2,

      //US
      charage_no_vat_usa_1: nochargevatforus,

      charge_vat_number_usa: vatchargeofficeforus,
      charge_vat_states_usa_1: statePosition,
      charge_no_vat_states_usa_3: charge_no_vat_states_usa_3, // value state don't assign

      notcharge_vat_usa2: nochargevatforus,
      charge_no_vat_states_usa_2: notchargebasedstates2,
      charge_no_vat_usa_2: charge_no_vat_usa_2,

      countrychargeStates3: noratesvatforstates,
      countrychargeusa3: noratesvatforcountry,
      charage_no_vat_usa_3: nochargevatforus2,

      charge_no_vat_states_usa_4: statePositioncharge_false,

      countrychargeusa: ratesvatforCountry,
      countrychargeStates: ratesvatforstates,
      countrychargeusa2: ratesvatforcountry2,
      countrychargeStates2: ratesvatforstates2,
      countrychargeStates4: noratesvatforstates4,
      countrychargeusa4: noratesvatforcountry4,

      //canada

      charge_vat_number_canada: vatchargeofficeforca,
      proviencebasedoncharge: statePositionca,

      charge_no_vat_canada_1: charge_no_vat_canada_1,
      provience_basedon_vat_no_charge_1: provience_no_charge_vat,
      charge_no_vat_canada_2: charge_no_vat_canada_2,
      provience_basedon_vat_no_charge_2: provience_basedon_vat_no_charge_2,

      charge_no_vat_canada_3: nochargevatforca,
      provience_basedon_vat_no_charge_3: statePositionca2,

      // VAT RATES
      selecteddatabasedonprovince_1: ratesvatforstatesca,
      selecteddatabasedonprovince_2: canada_no_rates_provience,
      selecteddatabasedonprovince_3: canada_no_rates_provience3,
      selecteddatabasedonprovince_4: ratesvatforstatesca2,
      selecteddatabasedoncanadacountry_1: ratesvatforcountryca,
      selecteddatabasedoncanadacountry_2: canadanoratescountry_vat,
      selecteddatabasedoncanadacountry_3: canadanoratescountry_vat3,
      selecteddatabasedoncanadacountry_4: ratesvatforcountryca2,

      //REST OF THE WORLD
      waive_countries_charge: wavierCharge,
      other_charge_policy: other_charge_policy,
      vat_tax_other_countries_charge: other_country_vat,
      vat_tax_other_countries_charge2: other_country_vat2,
      rest_billing_address2: other_charge_policy,
      rest_billing_address: other_charge_policy2,
      //rates
      countrychargewaive: waviercountry ? waviercountry : [],
      other_countries_vat_tax_2: other_country_vat_tax1,
      billingaddressCountry2: restbillingaddress2,
      other_countries_vat_tax_1: other_country_vat_tax,
      billingaddressCountry: restbillingaddress1,
    });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  render() {
    function fixedTwoDigit(x) {
      return Number.parseFloat(x).toFixed(2);
    }

    let vatselfcountry = [];
    this.state.vatselfcountry &&
      this.state.vatselfcountry.map((item) =>
        vatselfcountry.push({
          key:item.id,

          Country: item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );
    let selfcountry = [];
    this.state.selfcountry &&
      this.state.selfcountry.map((item) =>
        selfcountry.push({
          key:item.id,

          Country: item.name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );
    let selfCountrynoRates = [];
    this.state.selfCountrynoRates &&
      this.state.selfCountrynoRates.map((item) =>
        selfCountrynoRates.push({
          key:item.id,

          Country: item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );

    let vatselfForEucountrynorates = [];
    this.state.vatselfForEucountrynorates &&
      this.state.vatselfForEucountrynorates.map((item) =>
        vatselfForEucountrynorates.push({
          Country: item.country_name,
          key:item.id,

          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );

    let vat_ratesforeu = [];
    this.state.vat_ratesforeu &&
      this.state.vat_ratesforeu.map((item) =>
        vat_ratesforeu.push({
          key:item.id,

          Country: item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );

    let basedoncharge_1 = [];
    this.state.basedoncharge_1 &&
      this.state.basedoncharge_1.map((item) =>
        basedoncharge_1.push({
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );
    let basedoncharge_2 = [];
    this.state.basedoncharge_2 &&
      this.state.basedoncharge_2.map((item) =>
        basedoncharge_2.push({
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );
    let basedoncharge_3 = [];
    this.state.basedoncharge_3 &&
      this.state.basedoncharge_3.map((item) =>
        basedoncharge_3.push({
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );

    //US
    //USA SELECT MULTICHECKBOX DATA
    let countrychargeStates = [];
    this.state.countrychargeStates &&
      this.state.countrychargeStates.map((item) =>
        countrychargeStates.push({
          key:item.id,

          Country: item.country_name,
          "Federal Rate":  fixedTwoDigit( item.federal_vat_rate) ||0,
          States: item.name,
          "State Rate":fixedTwoDigit(item.state_provincial_vat_rate)||0,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );

    let countrychargeusa =
      this.state.countrychargeusa &&
      this.state.countrychargeusa.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Federal Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let countrychargeStates2 =
      this.state.countrychargeStates2 &&
      this.state.countrychargeStates2.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name,
          "Federal Rate":  fixedTwoDigit( item.federal_vat_rate) ||0,
          States: item.name,
          "State Rate":  fixedTwoDigit(item.state_provincial_vat_rate)||0,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let countrychargeusa2 =
      this.state.countrychargeusa2 &&
      this.state.countrychargeusa2.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Federal Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let countrychargeStates3 =
      this.state.countrychargeStates3 &&
      this.state.countrychargeStates3.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name,
          "Federal Rate": fixedTwoDigit( item.federal_vat_rate) ||0,
          States: item.name,
          "State Rate":  fixedTwoDigit(item.state_provincial_vat_rate)||0,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let countrychargeusa3 =
      this.state.countrychargeusa3 &&
      this.state.countrychargeusa3.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Federal Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let countrychargeStates4 =
      this.state.countrychargeStates4 &&
      this.state.countrychargeStates4.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name,
          "Federal Rate":fixedTwoDigit( item.federal_vat_rate) ||0,
          States: item.name,
          "State Rate": fixedTwoDigit(item.state_provincial_vat_rate)||0,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let countrychargeusa4 =
      this.state.countrychargeusa4 &&
      this.state.countrychargeusa4.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.name,
          "Federal Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    //CA
    //Table Data

    let canadaprovincedata_1 =
      this.state.selecteddatabasedonprovince_1 &&
      this.state.selecteddatabasedonprovince_1.map((item) => {
        return Object.assign(item, {
          key:item.id,
          Country: item.country_name,
          "PST Rate":fixedTwoDigit( item.federal_vat_rate) ||0,
          States: item.name,
          "State Rate": fixedTwoDigit(item.state_provincial_vat_rate)||0,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let canadaprovincedata_2 =
      this.state.selecteddatabasedonprovince_2 &&
      this.state.selecteddatabasedonprovince_2.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name,
          "PST Rate":fixedTwoDigit( item.federal_vat_rate) ||0,
          States: item.name,
          "State Rate": fixedTwoDigit(item.state_provincial_vat_rate)||0,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let canadaprovincedata_3 =
      this.state.selecteddatabasedonprovince_3 &&
      this.state.selecteddatabasedonprovince_3.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name,
          "PST Rate":fixedTwoDigit( item.federal_vat_rate) ||0,
          States: item.name,
          "State Rate": fixedTwoDigit(item.state_provincial_vat_rate)||0,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let canadaprovincedata_4 =
      this.state.selecteddatabasedonprovince_4 &&
      this.state.selecteddatabasedonprovince_4.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name,
          "PST Rate": item.federal_vat_rate,
          States: item.name,
          "State Rate": item.state_provincial_vat_rate,
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let canadadatacountrytable_1 =
      this.state.selecteddatabasedoncanadacountry_1 &&
      this.state.selecteddatabasedoncanadacountry_1.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name ? item.country_name : item.name,
          "PST Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let canadadataforcountry2 =
      this.state.selecteddatabasedoncanadacountry_2 &&
      this.state.selecteddatabasedoncanadacountry_2.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name ? item.country_name : item.name,
          "PST Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let canadadataforcountry3 =
      this.state.selecteddatabasedoncanadacountry_3 &&
      this.state.selecteddatabasedoncanadacountry_3.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.country_name ? item.country_name : item.name,
          "PST Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let canadadataforcountry4 = [];
    this.state.selecteddatabasedoncanadacountry_4 &&
      this.state.selecteddatabasedoncanadacountry_4.map((item) =>
        canadadataforcountry4.push({
          key:item.id,

          Country: item.country_name ? item.country_name : item.name,

          "PST Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        })
      );

    //REST
    let other_countries_vat_tax_1 =
      this.state.other_countries_vat_tax_1 &&
      this.state.other_countries_vat_tax_1.map((item) => {
        return Object.assign(item, {
          key:item.country,

          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.vat_rate,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let other_countries_vat_tax_2 =
      this.state.other_countries_vat_tax_2 &&
      this.state.other_countries_vat_tax_2.map((item) => {
        return Object.assign(item, {
          key:item.country,

          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });

    let billingaddressCountry =
      this.state.billingaddressCountry &&
      this.state.billingaddressCountry.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let billingaddressCountry2 =
      this.state.billingaddressCountry2 &&
      this.state.billingaddressCountry2.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: item.vat_rate ? fixedTwoDigit(item.vat_rate) : 0,
        });
      });
    let countrychargewaive =
      this.state.countrychargewaive &&
      this.state.countrychargewaive.map((item) => {
        return Object.assign(item, {
          key:item.id,

          Country: item.name ? item.name : item.country_name,
        });
      });
    const { data, vat_number, origin } = this.state;

    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12 ">
            <div className="card m-b-20 vat-card-tax">
              <div className="vat-card">
                <div className="vat-tax-button">
                  <Button
                    type="danger"
                    name="danger"
                    text="X"
                    onClick={() => this.props.history.push("/settings/vat-configuration")}
                  />
                </div>

                <div className="card-body">
                  <div className="vat-tax-heading">
                    {vat_number && (
                      <h3>
                        Your VAT & Taxes Number:
                        <span> {vat_number}</span>
                      </h3>
                    )}

                    {origin && (
                      <h3>
                        You have Selected your location:
                        <span> {origin}</span>
                      </h3>
                    )}
                  </div>

                  {data &&
                    data.offices[0] &&
                    data.offices[0].country_name !== "United States" &&
                    data &&
                    data.offices[0] &&
                    data.offices[0].country_name !== "Canada" ? (
                      this.state.apc_including_vat === true ? (
                        <UKVIEW
                          origin={this.state.origin}
                          apc_including_vat={this.state.apc_including_vat}
                          charge_vat={this.state.charge_vat}
                          charge_no_vat={this.state.charge_no_vat}
                          vatselfcountry={
                            vatselfcountry.length > 0
                              ? vatselfcountry
                              : selfcountry
                          }
                          selfCountrynoRates={
                            selfCountrynoRates.length > 0
                              ? selfCountrynoRates
                              : selfcountry
                          }
                          not_charge_vat_number={this.state.not_charge_vat_number}
                        />
                      ) : (
         

<Tabs defaultActiveKey="1" onChange={this.callback}>
<TabPane tab="UK" key="1">
                  
<UKVIEW
                          origin={this.state.origin}
                          apc_including_vat={this.state.apc_including_vat}
                              charge_vat={this.state.charge_vat}
                              charge_no_vat={this.state.charge_no_vat}
                              vatselfcountry={
                                vatselfcountry.length > 0
                                  ? vatselfcountry
                                  : selfcountry
                              }
                              selfCountrynoRates={
                                selfCountrynoRates.length > 0
                                  ? selfCountrynoRates
                                  : selfcountry
                              }
                              not_charge_vat_number={
                                this.state.not_charge_vat_number
                              }
                            />

</TabPane>
<TabPane tab="EU" key="2">
  
<EUVIEW
                              charge_vat_number_eu={this.state.charge_vat_number_eu}
                              charge_vat_billing_address_eu_1={
                                this.state.charge_vat_billing_address_eu_1
                              }
                              charge_no_vat_number_eu_1={
                                this.state.charge_no_vat_number_eu_1
                              }
                              charge_no_vat_billing_address_eu_2={
                                this.state.charge_no_vat_billing_address_eu_2
                              }
                              charge_no_vat_number_eu_2={
                                this.state.charge_no_vat_number_eu_2
                              }
                              charge_no_vat_billing_address_eu_3={
                                this.state.charge_no_vat_billing_address_eu_3
                              }
                              vat_ratesforeu={
                                vat_ratesforeu.length > 0
                                  ? vat_ratesforeu
                                  : vatselfcountry
                              }
                              vatselfForEucountrynorates={
                                vatselfForEucountrynorates.length > 0
                                  ? vatselfForEucountrynorates
                                  : vatselfcountry
                              }
                              viewTable="view"
                              vatselfcountry_1={vatselfcountry}
                              basedoncharge_1={basedoncharge_1}
                              basedoncharge_2={basedoncharge_2}
                              basedoncharge_3={basedoncharge_3}
                            />
</TabPane>
<TabPane tab="USA" key="3">
<UnitedStatesVIEW
                              charge_vat_number_usa={
                                this.state.charge_vat_number_usa
                              }
                              charge_vat_states_usa_1={
                                this.state.charge_vat_states_usa_1
                              }
                              charage_no_vat_usa_1={this.state.charage_no_vat_usa_1}
                              charge_no_vat_states_usa_2={
                                this.state.charge_no_vat_states_usa_2
                              }
                              charge_no_vat_usa_2={this.state.charge_no_vat_usa_2}
                              charge_no_vat_states_usa_3={
                                this.state.charge_no_vat_states_usa_3
                              }
                              charage_no_vat_usa_3={this.state.charage_no_vat_usa_3}
                              charge_no_vat_states_usa_4={
                                this.state.charge_no_vat_states_usa_4
                              }
                              viewTable="view"
                              countrychargeStates={countrychargeStates}
                              countrychargeusa={countrychargeusa}
                              countrychargeusa2={countrychargeusa2}
                              countrychargeStates2={countrychargeStates2}
                              countrychargeusa3={countrychargeusa3}
                              countrychargeStates3={countrychargeStates3}
                              countrychargeusa4={countrychargeusa4}
                              countrychargeStates4={countrychargeStates4}
                            />
                            </TabPane>
                            
                            <TabPane tab="CANADA" key="4">

                      
                              <CanadaVIEW
                              charge_vat_number_canada={
                                this.state.charge_vat_number_canada
                              }
                              proviencebasedoncharge={
                                this.state.proviencebasedoncharge
                              }
                              charge_no_vat_canada_1={
                                this.state.charge_no_vat_canada_1
                              }
                              provience_basedon_vat_no_charge_1={
                                this.state.provience_basedon_vat_no_charge_1
                              }
                              charge_no_vat_canada_2={
                                this.state.charge_no_vat_canada_2
                              }
                              provience_basedon_vat_no_charge_2={
                                this.state.provience_basedon_vat_no_charge_2
                              }
                              charge_no_vat_canada_3={
                                this.state.charge_no_vat_canada_3
                              }
                              provience_basedon_vat_no_charge_3={
                                this.state.provience_basedon_vat_no_charge_3
                              }
                              viewTable="view"
                              canadaprovincedata_1={canadaprovincedata_1}
                              canadadatacountrytable_1={canadadatacountrytable_1}
                              canadaprovincedata_2={canadaprovincedata_2}
                              canadadataforcountry2={canadadataforcountry2}
                              canadaprovincedata_3={canadaprovincedata_3}
                              canadadataforcountry3={canadadataforcountry3}
                              canadadataforcountry4={canadadataforcountry4}
                              canadaprovincedata_4={canadaprovincedata_4}
                            />
                              </TabPane>
                              <TabPane tab="REST OF THE COUNTRY" key="5">

                        
                              <RESTVIEW
                              waive_countries_charge={
                                this.state.waive_countries_charge
                              }
                              vat_tax_other_countries_charge={
                                this.state.vat_tax_other_countries_charge
                              }
                              vat_tax_other_countries_charge2={
                                this.state.vat_tax_other_countries_charge2
                              }
                              rest_billing_address={this.state.rest_billing_address}
                              rest_billing_address2={
                                this.state.rest_billing_address2
                              }
                              viewTable="view"
                              other_countries_vat_tax_1={other_countries_vat_tax_1}
                              billingaddressCountry2={billingaddressCountry2}
                              other_countries_vat_tax_2={other_countries_vat_tax_2}
                              billingaddressCountry={billingaddressCountry}
                              countrychargewaive={countrychargewaive}
                            />
                              </TabPane>


                            
</Tabs>

                     
       
                        )
                    ) : data &&
                      data.offices[0] &&
                      data.offices[0].country_name === "Canada" ? (
                     <Tabs defaultActiveKey="1" onChange={this.callback}>
<TabPane tab="CANADA" key="1">
                  
<CanadaVIEW
                            charge_vat_number_canada={
                              this.state.charge_vat_number_canada
                            }
                            proviencebasedoncharge={
                              this.state.proviencebasedoncharge
                            }
                            charge_no_vat_canada_1={
                              this.state.charge_no_vat_canada_1
                            }
                            provience_basedon_vat_no_charge_1={
                              this.state.provience_basedon_vat_no_charge_1
                            }
                            charge_no_vat_canada_2={
                              this.state.charge_no_vat_canada_2
                            }
                            provience_basedon_vat_no_charge_2={
                              this.state.provience_basedon_vat_no_charge_2
                            }
                            charge_no_vat_canada_3={
                              this.state.charge_no_vat_canada_3
                            }
                            provience_basedon_vat_no_charge_3={
                              this.state.provience_basedon_vat_no_charge_3
                            }
                            viewTable="view"
                            canadaprovincedata_1={canadaprovincedata_1}
                            canadadatacountrytable_1={canadadatacountrytable_1}
                            canadaprovincedata_2={canadaprovincedata_2}
                            canadadataforcountry2={canadadataforcountry2}
                            canadaprovincedata_3={canadaprovincedata_3}
                            canadadataforcountry3={canadadataforcountry3}
                            canadadataforcountry4={canadadataforcountry4}
                            canadaprovincedata_4={canadaprovincedata_4}
                          />

</TabPane>
<TabPane tab="EU" key="2">
<EUVIEW
                            charge_vat_number_eu={this.state.charge_vat_number_eu}
                            charge_vat_billing_address_eu_1={
                              this.state.charge_vat_billing_address_eu_1
                            }
                            charge_no_vat_number_eu_1={
                              this.state.charge_no_vat_number_eu_1
                            }
                            charge_no_vat_billing_address_eu_2={
                              this.state.charge_no_vat_billing_address_eu_2
                            }
                            charge_no_vat_number_eu_2={
                              this.state.charge_no_vat_number_eu_2
                            }
                            charge_no_vat_billing_address_eu_3={
                              this.state.charge_no_vat_billing_address_eu_3
                            }
                            vat_ratesforeu={vat_ratesforeu}
                            vatselfForEucountrynorates={vatselfForEucountrynorates}
                            viewTable="view"
                            vatselfcountry_1={vatselfcountry}
                            basedoncharge_1={basedoncharge_1}
                            basedoncharge_2={basedoncharge_2}
                            basedoncharge_3={basedoncharge_3}
                          />
</TabPane>
<TabPane tab="United States" key="3">

<UnitedStatesVIEW
                            charge_vat_number_usa={this.state.charge_vat_number_usa}
                            charge_vat_states_usa_1={
                              this.state.charge_vat_states_usa_1
                            }
                            charage_no_vat_usa_1={this.state.charage_no_vat_usa_1}
                            charge_no_vat_states_usa_2={
                              this.state.charge_no_vat_states_usa_2
                            }
                            charge_no_vat_usa_2={this.state.charge_no_vat_usa_2}
                            charge_no_vat_states_usa_3={
                              this.state.charge_no_vat_states_usa_3
                            }
                            charage_no_vat_usa_3={this.state.charage_no_vat_usa_3}
                            charge_no_vat_states_usa_4={
                              this.state.charge_no_vat_states_usa_4
                            }
                            viewTable="view"
                            countrychargeStates={countrychargeStates}
                            countrychargeusa={countrychargeusa}
                            countrychargeusa2={countrychargeusa2}
                            countrychargeStates2={countrychargeStates2}
                            countrychargeusa3={countrychargeusa3}
                            countrychargeStates3={countrychargeStates3}
                            countrychargeusa4={countrychargeusa4}
                            countrychargeStates4={countrychargeStates4}
                          />
</TabPane>

                       
<TabPane tab="REST OF THE WORLD" key="4">

                          <RESTVIEW
                            waive_countries_charge={
                              this.state.waive_countries_charge
                            }
                            vat_tax_other_countries_charge={
                              this.state.vat_tax_other_countries_charge
                            }
                            vat_tax_other_countries_charge2={
                              this.state.vat_tax_other_countries_charge2
                            }
                            rest_billing_address={this.state.rest_billing_address}
                            rest_billing_address2={this.state.rest_billing_address2}
                            viewTable="view"
                            other_countries_vat_tax_1={other_countries_vat_tax_1}
                            billingaddressCountry2={billingaddressCountry2}
                            other_countries_vat_tax_2={other_countries_vat_tax_2}
                            billingaddressCountry={billingaddressCountry}
                            countrychargewaive={countrychargewaive}
                          />
                          </TabPane>
                  </Tabs>
                      ) : data &&
                        data.offices[0] &&
                        data.offices[0].country_name === "United States" ? (
                            <Tabs defaultActiveKey="1" onChange={this.callback}>
<TabPane tab="United States" key="1">
<UnitedStatesVIEW
                              charge_vat_number_usa={this.state.charge_vat_number_usa}
                              charge_vat_states_usa_1={
                                this.state.charge_vat_states_usa_1
                              }
                              charage_no_vat_usa_1={this.state.charage_no_vat_usa_1}
                              charge_no_vat_states_usa_2={
                                this.state.charge_no_vat_states_usa_2
                              }
                              charge_no_vat_usa_2={this.state.charge_no_vat_usa_2}
                              charge_no_vat_states_usa_3={
                                this.state.charge_no_vat_states_usa_3
                              }
                              charage_no_vat_usa_3={this.state.charage_no_vat_usa_3}
                              charge_no_vat_states_usa_4={
                                this.state.charge_no_vat_states_usa_4
                              }
                              viewTable="view"
                              countrychargeStates={countrychargeStates}
                              countrychargeusa={countrychargeusa}
                              countrychargeusa2={countrychargeusa2}
                              countrychargeStates2={countrychargeStates2}
                              countrychargeusa3={countrychargeusa3}
                              countrychargeStates3={countrychargeStates3}
                              countrychargeusa4={countrychargeusa4}
                              countrychargeStates4={countrychargeStates4}
                            />


</TabPane>
<TabPane tab="EU" key="2">

<EUVIEW
                              charge_vat_number_eu={this.state.charge_vat_number_eu}
                              charge_vat_billing_address_eu_1={
                                this.state.charge_vat_billing_address_eu_1
                              }
                              vatselfForEucountrynorates={vatselfForEucountrynorates}
                              charge_no_vat_number_eu_1={
                                this.state.charge_no_vat_number_eu_1
                              }
                              charge_no_vat_billing_address_eu_2={
                                this.state.charge_no_vat_billing_address_eu_2
                              }
                              charge_no_vat_number_eu_2={
                                this.state.charge_no_vat_number_eu_2
                              }
                              charge_no_vat_billing_address_eu_3={
                                this.state.charge_no_vat_billing_address_eu_3
                              }
                              viewTable="view"
                              vatselfcountry_1={vatselfcountry}
                              basedoncharge_1={basedoncharge_1}
                              basedoncharge_2={basedoncharge_2}
                              basedoncharge_3={basedoncharge_3}
                            />
</TabPane>
<TabPane tab="CANADA" key="3">
  

<CanadaVIEW
                              charge_vat_number_canada={
                                this.state.charge_vat_number_canada
                              }
                              proviencebasedoncharge={
                                this.state.proviencebasedoncharge
                              }
                              charge_no_vat_canada_1={
                                this.state.charge_no_vat_canada_1
                              }
                              provience_basedon_vat_no_charge_1={
                                this.state.provience_basedon_vat_no_charge_1
                              }
                              charge_no_vat_canada_2={
                                this.state.charge_no_vat_canada_2
                              }
                              provience_basedon_vat_no_charge_2={
                                this.state.provience_basedon_vat_no_charge_2
                              }
                              charge_no_vat_canada_3={
                                this.state.charge_no_vat_canada_3
                              }
                              provience_basedon_vat_no_charge_3={
                                this.state.provience_basedon_vat_no_charge_3
                              }
                              viewTable="view"
                              canadaprovincedata_1={canadaprovincedata_1}
                              canadadatacountrytable_1={canadadatacountrytable_1}
                              canadaprovincedata_2={canadaprovincedata_2}
                              canadadataforcountry2={canadadataforcountry2}
                              canadaprovincedata_3={canadaprovincedata_3}
                              canadadataforcountry3={canadadataforcountry3}
                              canadadataforcountry4={canadadataforcountry4}
                              canadaprovincedata_4={canadaprovincedata_4}
                            />
                              </TabPane>
                              <TabPane tab="REST OF THE WORLD" key="4">
                              <RESTVIEW
                              waive_countries_charge={
                                this.state.waive_countries_charge
                              }
                              vat_tax_other_countries_charge={
                                this.state.vat_tax_other_countries_charge
                              }
                              vat_tax_other_countries_charge2={
                                this.state.vat_tax_other_countries_charge2
                              }
                              rest_billing_address={this.state.rest_billing_address}
                              rest_billing_address2={this.state.rest_billing_address2}
                              viewTable="view"
                              other_countries_vat_tax_1={other_countries_vat_tax_1}
                              billingaddressCountry2={billingaddressCountry2}
                              other_countries_vat_tax_2={other_countries_vat_tax_2}
                              billingaddressCountry={billingaddressCountry}
                              countrychargewaive={countrychargewaive}
                            />
                              </TabPane>
</Tabs>
                          
           
                        ) : (
                          <Loading type="flat" />
                        )}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.app.countries,
  state_list: state.vat.us_state_list,
  state_list_ca: state.vat.ca_state_list,
  country_list: state.vat.country_list,
  create_pub_vat: state.create_pub_vat,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountry: () => dispatch(getAllCountry()),
  publisherVatCreateConfigure: (payload) =>
    dispatch(publisherVatCreateConfigure(payload)),
  getVatRatesPubVatConfig: (value) => dispatch(getVatRatesPubVatConfig(value)),
  getStatePubVatConfig: (value) => dispatch(getStatePubVatConfig(value)),
  getCAStatePubVatConfig: (value) => dispatch(getCAStatePubVatConfig(value)),
  publisherGetSingleVatCreateConfigure: (id) =>
    dispatch(publisherGetSingleVatCreateConfigure(id)),
  publisherDeleteVatCreateConfigure: (id) =>
    dispatch(publisherDeleteVatCreateConfigure(id)),
  publisherGetAllVatCreateConfigure: () =>
    dispatch(publisherGetAllVatCreateConfigure()),
  publisherUpdateVatCreateConfigure: (payload, id) =>
    dispatch(publisherUpdateVatCreateConfigure(payload, id)),
  createVatConfig: (payload) => dispatch(createVatConfig(payload)),
  createUpdateVatConfig: (payload) => dispatch(createUpdateVatConfig(payload)),
  getVatConfig: () => dispatch(getVatConfig()),
  getSingleVatConfig: (id) => dispatch(getSingleVatConfig(id)),
  DeleteVatConfig: (id) => dispatch(DeleteVatConfig(id)),
});

export const VatViewForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(VatForm);