import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../core/components";
import validator from "validator";
import { Author } from "../book/Author";
import { CoAuthor } from "../book/CoAuthor";
import { Prices } from "../book/prices";
import moment from "moment";
import { getOaSubjectList } from "../../../actions";

import {
  Badge,
  InputBox,
  SelectBox,
  OaDatePicker,
  PopupBox,
} from "../../../../core/components/common";
import { confirmAlert } from "react-confirm-alert"; // Import

const BtnText = "Add Book";

class Form extends BaseComponent {
  state = {
    article: {},
    SubmitBtnText: BtnText,
    errMsg: false,
    isCoAuthor: false,
    isAuthor: false,
    edit_modal: false,
    co_authors: [],
    author: {},
    subjects: [],
    disciplines: [],
    editing: false,
    loaded: false,
  };

  async componentDidMount() {
    let dataSub =
      this.props.article.subjects &&
      this.props.article.subjects.map((item) => item.id).join();

    let payload = {
      discipline: dataSub,
    };

    await this.props.getOaSubjectList(payload);

    this.props.OaSubjectList &&
      this.setState({
        OaSubjectList: this.props.OaSubjectList.results,
      });

    if (this.props.article && !this.state.loaded) {
      let data =
        this.props.article.disciplines &&
        this.props.article.disciplines.map((item) => item.id).join();
      let convertString = data.toString();
      let num = convertString.split(",");
      let disciplines = num.map(Number);

      let dataSub =
        this.props.article.subjects &&
        this.props.article.subjects.map((item) => item.id).join();
      let convertStringSub = dataSub && dataSub.toString();

      let numSub = convertStringSub && convertStringSub.split(",");

      let sub = numSub && numSub.map(Number);

      let article = {
        title: this.props.article.title,
        article_id: this.props.article.article_id,
        content_type: this.props.article.content_type,
        doi: this.props.article.doi,
        book_acronym:
          this.props.article.publication &&
          this.props.article.publication.pub_acronym,
        sub_sys_acronym:
          this.props.article.publication &&
          this.props.article.publication.sub_sys_acronym,
        pisbn: this.props.article.pisbn,
        eisbn: this.props.article.eisbn,
        funder_name: this.props.article.funder_name,
        publisher: this.props.article.publisher.id,
        fund_acknowledgement: this.props.article.fund_acknowledgement,
        grant_number: this.props.article.grant_number,
        submission_date: this.props.article.submission_date,
        acceptance_date: this.props.article.acceptance_date,
        pub_date: this.props.article.pub_date,
        disciplines:
          this.props.article.disciplines.length > 0 ? disciplines : [],
        subjects: this.props.article.subjects.length > 0 ? sub : [],
        other_charge_eur: this.props.article.other_charge_eur,
        other_charge_gbp: this.props.article.other_charge_gbp,
        other_charge_usd: this.props.article.other_charge_usd,
        page_charge_eur: this.props.article.page_charge_eur,
        page_charge_gbp: this.props.article.page_charge_gbp,
        page_charge_usd: this.props.article.page_charge_usd,
        pub_fee_eur: this.props.article.pub_fee_eur,
        pub_fee_gbp: this.props.article.pub_fee_gbp,
        pub_fee_usd: this.props.article.pub_fee_usd,
        submission_fee_eur: this.props.article.submission_fee_eur,
        submission_fee_usd: this.props.article.submission_fee_usd,
        submission_fee_gbp: this.props.article.submission_fee_gbp,
        colour_charge_eur: this.props.article.colour_charge_eur,
        colour_charge_gbp: this.props.article.colour_charge_gbp,
        colour_charge_usd: this.props.article.colour_charge_usd,
        editor_manager: this.props.article.editor_manager,
        editor_support: this.props.article.editor_support,
        editor_co_support: this.props.article.editor_co_support,
      };

      let author = {};

      if (this.props.article.author.id) {
        author = {
          id: this.props.article.author.id,
          salutation: this.props.article.author.salutation,
          middle_name: this.props.article.author.middle_name,
          first_name: this.props.article.author.first_name,
          last_name: this.props.article.author.last_name,
          email: this.props.article.author.email,
          affiliation: this.props.article.author.affiliation,
          department: {
            name:
              this.props.article.author.department &&
              this.props.article.author.department.name,
            division: {
              name:
                this.props.article.author &&
                  this.props.article.author &&
                  this.props.article.author.department &&
                  this.props.article.author.department.division
                  ? this.props.article.author &&
                  this.props.article.author.department &&
                  this.props.article.author.department.division.name
                  : null,
            },
          },
          position: this.props.article.author.position,
          orcid_id: this.props.article.author.orcid_id,
          pmc_id: this.props.article.author.pmc_id,
        };
      }

      this.setState({
        article: article,
        co_authors: this.props.article.co_authors || [],
        author: author,
        is_author_affiliation_match: this.props.is_author_affiliation_match,
        is_eissn_match: this.props.article.is_eissn_match,
        is_pissn_match: this.props.article.is_pissn_match,
        is_publisher_name_match: this.props.article.is_publisher_name_match,
        loaded: true,
        SubmitBtnText: "Update",
      });
    }
  }

  componentWillReceiveProps() {
    if (this.props.article && !this.state.loaded) {
      let dataSub =
        this.props.article.subjects &&
        this.props.article.subjects.map((item) => item.id).join();
      let convertStringSub = dataSub && dataSub.toString();
      let numSub = convertStringSub && convertStringSub.split(",");

      let sub = numSub && numSub.map(Number);

      let data =
        this.props.article.disciplines &&
        this.props.article.disciplines.map((item) => item.id).join();
      let convertString = data.toString();
      let num = convertString.split(",");
      let disciplines = num.map(Number);

      let article = {
        title: this.props.article.title,
        article_id: this.props.article.article_id,
        content_type: this.props.article.content_type,
        doi: this.props.article.doi,
        book_acronym:
          this.props.article.publication &&
          this.props.article.publication.pub_acronym,
        sub_sys_acronym:
          this.props.article.publication &&
          this.props.article.publication.sub_sys_acronym,
        pisbn: this.props.article.pisbn,
        eisbn: this.props.article.eisbn,
        funder_name: this.props.article.funder_name,
        publisher: this.props.article.publisher.id,
        fund_acknowledgement: this.props.article.fund_acknowledgement,
        grant_number: this.props.article.grant_number,
        submission_date: this.props.article.submission_date,
        acceptance_date: this.props.article.acceptance_date,
        pub_date: this.props.article.pub_date,
        disciplines:
          this.props.article.disciplines.length > 0 ? disciplines : [],
        subjects: this.props.article.subjects.length > 0 ? sub : [],
        other_charge_eur: this.props.article.other_charge_eur,
        other_charge_gbp: this.props.article.other_charge_gbp,
        other_charge_usd: this.props.article.other_charge_usd,
        page_charge_eur: this.props.article.page_charge_eur,
        page_charge_gbp: this.props.article.page_charge_gbp,
        page_charge_usd: this.props.article.page_charge_usd,
        pub_fee_eur: this.props.article.pub_fee_eur,
        pub_fee_gbp: this.props.article.pub_fee_gbp,
        pub_fee_usd: this.props.article.pub_fee_usd,
        submission_fee_eur: this.props.article.submission_fee_eur,
        submission_fee_usd: this.props.article.submission_fee_usd,
        submission_fee_gbp: this.props.article.submission_fee_gbp,
        colour_charge_eur: this.props.article.colour_charge_eur,
        colour_charge_gbp: this.props.article.colour_charge_gbp,
        colour_charge_usd: this.props.article.colour_charge_usd,
        editor_manager: this.props.article.editor_manager,
        editor_support: this.props.article.editor_support,
        editor_co_support: this.props.article.editor_co_support,
      };

      let author = {};

      if (this.props.article.author.id) {
        author = {
          id: this.props.article.author.id,
          salutation: this.props.article.author.salutation,
          middle_name: this.props.article.author.middle_name,
          first_name: this.props.article.author.first_name,
          last_name: this.props.article.author.last_name,
          email: this.props.article.author.email,
          affiliation: this.props.article.author.affiliation,
          department: {
            name:
              this.props.article.author.department &&
                this.props.article.author.department.name
                ? this.props.article.author.department.name
                : null,
            division: {
              name:
                this.props.article.author &&
                  this.props.article.author &&
                  this.props.article.author.department &&
                  this.props.article.author.department.division
                  ? this.props.article.author &&
                  this.props.article.author.department &&
                  this.props.article.author.department.division.name
                  : null,
            },
          },
          position: this.props.article.author.position,
          orcid_id: this.props.article.author.orcid_id,
          pmc_id: this.props.article.author.pmc_id,
        };
      }

      this.setState({
        article: article,
        co_authors: this.props.article.co_authors || [],
        author: author,
        is_author_affiliation_match: this.props.is_author_affiliation_match,
        is_eissn_match: this.props.article.is_eissn_match,
        is_pissn_match: this.props.article.is_pissn_match,
        is_publisher_name_match: this.props.article.is_publisher_name_match,
        loaded: true,
      });
    }
  }
  onChangeHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.article, value);
    });
  };
  componentWillUnmount() {
    this.onMultiSelectChangeHandler();
  }

  onChangeDepartmentHandler = (value) => {
    this.setState((prevState) => {
      if (Object.keys(value).includes("department")) {
        prevState.author = {
          ...prevState.author,
          department: {
            name: value.department,
          },
        };
      } else if (Object.keys(value).includes("division")) {
        prevState.author = {
          ...prevState.author,
          department: {
            ...prevState.author.department,
            division: {
              name: value.division,
            },
          },
        };
      } else {
        prevState.author = {
          ...prevState.author,
          ...value,
        };
      }
      return prevState;
    });
  };
  onMultiSelectChangeHandler = (values) => {
    this.setState(
      {
        values,
        article: Object.assign(this.state.article, values),
      },
      async () => {
        if (values.hasOwnProperty("disciplines")) {
          if (values.disciplines.length > 0) {
            let payload = {
              discipline: values.disciplines.join(),
            };

            await this.props.getOaSubjectList(payload);

            this.props.OaSubjectList &&
              this.setState({
                OaSubjectList: this.props.OaSubjectList.results,
              });
          }
        }
      }
    );
  };

  onChangeAuthorHandler = (value) =>
    this.setState((prevState) => {
      Object.assign(prevState.author, value);
    });

    removeEmpty = (obj) => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
        if (
          !obj[key] ||
          (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
        ) {
          delete obj[key];
        }
      });
      return obj;
    };
  

  onSubmitHandler = async (e) => {
    e.preventDefault();

    let data = {
      ...this.state.article,
      visible_to_publisher: true,

      source_pub_type_name: "BOOK",

      colour_charge_eur:
        this.state.article && this.state.article.colour_charge_eur
          ? this.state.article.colour_charge_eur
          : 0,
      colour_charge_gbp:
        this.state.article && this.state.article.colour_charge_gbp
          ? this.state.article.colour_charge_gbp
          : 0,
      colour_charge_usd:
        this.state.article && this.state.article.colour_charge_usd
          ? this.state.article.colour_charge_usd
          : 0,
      other_charge_eur:
        this.state.article && this.state.article.other_charge_eur
          ? this.state.article.other_charge_eur
          : 0,
      other_charge_gbp:
        this.state.article && this.state.article.other_charge_gbp
          ? this.state.article.other_charge_gbp
          : 0,
      other_charge_usd:
        this.state.article && this.state.article.other_charge_usd
          ? this.state.article.other_charge_usd
          : 0,
      page_charge_eur:
        this.state.article && this.state.article.page_charge_eur
          ? this.state.article.page_charge_eur
          : 0,
      page_charge_gbp:
        this.state.article && this.state.article.page_charge_gbp
          ? this.state.article.page_charge_gbp
          : 0,
      page_charge_usd:
        this.state.article && this.state.article.page_charge_usd
          ? this.state.article.page_charge_usd
          : 0,
      pub_fee_usd:
        this.state.article && this.state.article.pub_fee_usd
          ? this.state.article.pub_fee_usd
          : 0,
      pub_fee_gbp:
        this.state.article && this.state.article.pub_fee_gbp
          ? this.state.article.pub_fee_gbp
          : 0,
      pub_fee_eur:
        this.state.article && this.state.article.pub_fee_eur
          ? this.state.article.pub_fee_eur
          : 0,
      submission_fee_eur:
        this.state.article && this.state.article.submission_fee_eur
          ? this.state.article.submission_fee_eur
          : 0,
      submission_fee_gbp:
        this.state.article && this.state.article.submission_fee_gbp
          ? this.state.article.submission_fee_gbp
          : 0,
      submission_fee_usd:
        this.state.article && this.state.article.submission_fee_usd
          ? this.state.article.submission_fee_usd
          : 0,
    };

    let author = this.state.author;
    if (typeof author !== undefined) {
      this.removeEmpty(author)
    }
    this.state.co_authors && this.state.co_authors.map((item) => this.removeEmpty(item))

    Object.assign(data, { author: author });
    
    if (data.acceptance_date) {
      Object.assign(data, {
        acceptance_date: moment(this.state.article.acceptance_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        acceptance_date: null,
      });
    }
    if (data.pub_date) {
      Object.assign(data, {
        pub_date: moment(this.state.article.pub_date).format("YYYY-MM-DD"),
      });
    } else {
      Object.assign(data, {
        pub_date: null,
      });
    }
    if (data.submission_date) {
      Object.assign(data, {
        submission_date: moment(this.state.article.submission_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        submission_date: moment().format("YYYY-MM-DD"),
      });
    }
    Object.assign(data, { co_authors: this.state.co_authors });

    if (
      !data.title ||
      !data.content_type ||
      !data.article_id ||
      !data.pisbn ||
      !data.eisbn
    ) {
      this.setState({
        errMsg: (
          <div>
            {!data.title && <p>Title cannot be empty!</p>}
            {!data.article_id && <p>Book id cannot be empty</p>}

            {!data.content_type && <p>Content name cannot be empty</p>}
            {!data.pisbn && <p>Pisbn cannot be empty</p>}
            {!data.eisbn && <p>Eisbn cannot be empty</p>}

            {data.doi && !validator.isURL(data.doi) && (
              <p>URL format is not correct</p>
            )}
            {data.author &&
              data.author.email &&
              !validator.isEmail(data.author.email) && (
                <p>Email format is not correct</p>
              )}
          </div>
        ),
      });
    } else {
      this.setState({
        errMsg: false,
      });


      this.props.onSubmitHandler(
        Object.assign(data, { id: this.props.article.id })
      );
    }
  };




  cancelLoader = async () => {
    this.setState({
      SubmitBtnText: BtnText,
      errMsg: false,
    });
  };

  AddNewAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isAuthor: true,
    });
  };

  AddNewCoAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isCoAuthor: true,
    });
  };

  onCloseAuthor = () => {
    this.setState({
      isCoAuthor: false,
      isAuthor: false,
      editing: false,
    });
  };

  onSaveCoAuthorHander = (author_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.co_authors[prevState.editing] = author_info;
        prevState.editing = false;
      } else {
        prevState.co_authors.push(author_info);
      }
      prevState.isCoAuthor = false;
      return prevState;
    });
  };

  onSaveAuthorHander = (author_info) => {
    this.setState((prevState) => {
      prevState.author = author_info;
      prevState.isAuthor = false;
      return prevState;
    });
  };

  onEditAuthor = () => {
    this.setState({
      isAuthor: true,
    });
  };

  onDeleteAuthor = () => {
    this.setState({
      author: null,
    });
  };

  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isCoAuthor: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.co_authors.splice(id, 1);
      return prevState;
    });
  };

  onFileDrop = (payload) => {
    confirmAlert({
      title: "Upload book",
      message: "Are you sure, you want to upload file?",
      buttons: [
        {
          label: "Upload",
          onClick: () => this.props.articleFileUploadHandler(payload),
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
    // this.props.articleFileUploadHandler(payload);
  };

  render() {
    let {
      article,
      is_author_affiliation_match,
      is_eissn_match,
      // is_pissn_match,
      // is_publisher_name_match,
    } = this.state;
    // let prices_id = this.getSelectedContentTypeId();
    const content_type = this.props.content_type && this.props.content_type;

    return (
      <div className="tab-content books">
        {/* Error Messages */}

        {this.state.errMsg && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.errMsg}
            onCancel={this.cancelLoader}
          />
        )}
        {/* End Error Messages */}

        <div className="tab-pane active p-3" id="home-1" role="tabpanel">
          {/* <h4 className="mt-0 header-title"></h4> */}

          <div className="form-group row">
            <div className="col-md-12">
              <label> Book title</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="title"
                className="col-sm-12"
                placeholder="Book title"
                isRequired={true}
                defaultValue={article.title}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <label> Book ID</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="article_id"
                className="col-sm-12"
                placeholder="Book ID"
                defaultValue={article.article_id}
              />
            </div>
            <div className="col-md-4">
              <label>pISBN</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="pisbn"
                className="col-sm-12"
                placeholder="pISBN"
                defaultValue={article.pisbn}
              // style={is_pissn_match === false ? styles.error : {}}
              />
            </div>
            <div className="col-md-4">
              <label>eISBN</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="eisbn"
                className="col-sm-12"
                placeholder="eISBN"
                defaultValue={article.eisbn}
                style={is_eissn_match === false ? styles.error : {}}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <label> Content type</label>
              <SelectBox
                onChange={this.onChangeHandler}
                field="content_type"
                data={content_type}
                className="col-sm-12"
                isRequired={true}
                defaultValue={article.content_type}
              />
            </div>
            <div className="col-md-4">
              <label> Discipline</label>
              <SelectBox
                onChange={this.onMultiSelectChangeHandler}
                field="disciplines"
                data={this.props.OaDisciplineList}
                isRequired={true}
                className="col-sm-12"
                multiple={true}
                defaultValue={article.disciplines}
              />
            </div>

            <div className="col-md-4">
              <label> Subject</label>

              <SelectBox
                onChange={this.onMultiSelectChangeHandler}
                field="subjects"
                data={this.state.OaSubjectList}
                className="col-sm-12"
                isRequired={true}
                defaultValue={article.subjects}
                multiple={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h3 className="profile-heading">Corresponding author </h3>
            </div>
          </div>
          <div className="form-group row">
            <Author
              fieldLabel="Author"
              onChangeHandler={this.onChangeDepartmentHandler}
              value={this.state.author}
              is_author_affiliation_match={is_author_affiliation_match}
            />
          </div>

          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                <h3 className="profile-heading">Co authors </h3>
              </div>
              <div className="float-right">
                <button
                  className="btn btn-light btn-o-light mb-2"
                  onClick={this.AddNewCoAuthor}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="mdi mdi-plus"></i> Add Co-authors
                </button>
              </div>
            </div>
          </div>

          {/* Co Author Form Modal */}
          {this.state.isCoAuthor && (
            <CoAuthor
              isShow={this.state.isCoAuthor} // if true modal will show up else it will be hidden
              onClose={this.onCloseAuthor} // Handle Close
              onSaveAuthor={this.onSaveCoAuthorHander}
              fieldLabel="Co author"
              value={this.state.co_authors[this.state.editing]}
            />
          )}
          <div className="form-group row">
            {this.state.co_authors.length > 0 &&
              this.state.co_authors.map((co_author, id) => {
                return (
                  <div                     key={id}
                  >
                      <Badge
                    label={
                      !co_author.first_name && !co_author.last_name
                        ? "No Name"
                        : (co_author.first_name || "") +
                        " " +
                        (co_author.last_name || "")
                    }
                    onEdit={() => this.onEditCoAuthor(id)}
                    onDelete={() => this.onDeleteCoAuthor(id)}
                  />

                </div>
                );
              })}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h3 className="profile-heading">Publication Info </h3>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <label>Publisher</label>
              <SelectBox
                onChange={this.onChangeHandler}
                field="publisher"
                data={this.props.publisherList}
                className="col-sm-12"
                isRequired={true}
                defaultValue={article.publisher}
              />
            </div>
            <div className="col-md-4">
              <label>Acronym</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="book_acronym"
                className="col-sm-12"
                placeholder="Acronym"
                defaultValue={article.book_acronym}
              />
            </div>
            <div className="col-md-4">
              <label>Site</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="sub_sys_acronym"
                className="col-sm-12"
                placeholder="Site"
                defaultValue={article.sub_sys_acronym}
              />
            </div>
          </div>
          <div className="form-group row">
            {article.submission_date && (
              <div className="col-md-4">
                <label>Submission date</label>
                <OaDatePicker
                  onChange={this.onChangeHandler}
                  field="submission_date"
                  placeholder="submission date"
                  className="col-md-12"
                  defaultValue={
                    article.submission_date
                      ? moment(article.submission_date)
                      : null
                  }
                />
              </div>
            )}
            {!article.submission_date && (
              <div className="col-md-4">
                <label>Submission date</label>
                <OaDatePicker
                  onChange={this.onChangeHandler}
                  field="submission_date"
                  className="col-md-12"
                  placeholder="submission date"
                  defaultValue={
                    article.submission_date
                      ? moment(article.submission_date)
                      : null
                  }
                />
              </div>
            )}

            {!article.acceptance_date && (
              <div className="col-md-4">
                <label>Acceptance date</label>

                <OaDatePicker
                  onChange={this.onChangeHandler}
                  field="acceptance_date"
                  className="col-md-12"
                  placeholder="submission date"
                  defaultValue={
                    article.acceptance_date
                      ? moment(article.acceptance_date)
                      : null
                  }
                />
              </div>
            )}
            {article.acceptance_date && (
              <div className="col-md-4">
                <label>Acceptance date</label>
                <OaDatePicker
                  onChange={this.onChangeHandler}
                  field="acceptance_date"
                  placeholder="submission date"
                  className="col-md-12"
                  defaultValue={
                    article.acceptance_date
                      ? moment(article.acceptance_date)
                      : null
                  }
                />
              </div>
            )}

            {article.pub_date && (
              <div className="col-md-4">
                <label>Publish date</label>
                <OaDatePicker
                  onChange={this.onChangeHandler}
                  field="pub_date"
                  className="col-md-12"
                  placeholder="Publish date"
                  defaultValue={
                    article.pub_date ? moment(article.pub_date) : null
                  }
                />{" "}
              </div>
            )}

            {!article.pub_date && (
              <div className="col-md-4">
                <label>Publish date</label>
                <OaDatePicker
                  onChange={this.onChangeHandler}
                  field="pub_date"
                  className="col-md-12"
                  placeholder="Publish date"
                  defaultValue={
                    article.pub_date ? moment(article.pub_date) : null
                  }
                />
              </div>
            )}
          </div>

          <div className="row">
            <Prices
              prices={this.state.article}
              // selected_array={prices_id}
              onChangeHandler={this.onChangeHandler}
            />
          </div>

          <div className="row">
            <div className="col-sm-12">
              <h3 className="profile-heading">Funders </h3>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <label>Funder</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="funder_name"
                className="col-sm-12"
                defaultValue={article.funder_name}
                placeholder="Funder"
                style={{}}
              />
            </div>
            <div className="col-md-4">
              <label>Grant Note</label>

              <InputBox
                onChange={this.onChangeHandler}
                className="col-sm-12"
                field="fund_acknowledgement"
                placeholder="Grant Note"
                defaultValue={article.fund_acknowledgement}
              />
            </div>
            <div className="col-md-4">
              <label>Grant number</label>
              <InputBox
                className="col-sm-12"
                onChange={this.onChangeHandler}
                field="grant_number"
                placeholder="grant number"
                defaultValue={article.grant_number}
              />
            </div>
          </div>

          {/* <div className="form-group row mt-4">
                        <div className="col-sm-12">
                            <div className="float-left">
                                <button className="btn btn-primary mb-2"
                                    onClick={this.addNewPrices}
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                ><i className="mdi mdi-plus"></i> Add price</button>
                            </div>
                        </div>
                        <s style={{ borderBottom: '1px solid #375ba8', width: '100%' }}></s>
                    </div> */}

          {/* Price Form Modal */}
          {/* {this.state.isPrices && <Prices
                        isShow={this.state.isPrices} // if true modal will show up else it will be hidden
                        onClose={this.onClosePrice} // Handle Close
                        onSavePrices={this.onSavePriceHandler}
                        value={this.state.prices[this.state.editing]}
                        selected_array={prices_id}
                    />} */}
          {/* Price Form Modal End */}

          <div className="form-group row ">
            <div className="col-md-12">
              <label>Book/Chapter DOI</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="doi"
                className="col-sm-12"
                placeholder="Book/Chapter doi"
                InputType="url"
                defaultValue={article.doi}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h3 className="profile-heading"> </h3>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-6">
              <label>Manager</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="editor_manager"
                className="col-sm-12"
                placeholder="Manager"
                defaultValue={article.editor_manager}
              />
            </div>
            <div className="col-md-6">
              <label>Support</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="editor_support"
                className="col-sm-12"
                placeholder="Support"
                defaultValue={article.editor_support}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-6">
              <label>Co-support</label>
              <InputBox
                onChange={this.onChangeHandler}
                field="editor_co_support"
                className="col-sm-12"
                placeholder="Co-support"
                defaultValue={article.editor_co_support}
              />
            </div>
          </div>

          {/* 
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <h6>Additional Info</h6>
                        </div>
                        <s style={{ borderBottom: '1px solid #375ba8', width: '100%' }}></s>
                    </div>
                    <div className="form-group row">
                        <TextBox
                            label="Note"
                            onChange={this.onChangeHandler}
                            field='note'
                            className="col-sm-10"
                            placeholder="Note"
                        />
                    </div> */}

          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary btn-lg w-25 waves-effect waves-light"
              onClick={this.onSubmitHandler}
            >
              Update
            </button>
          </div>
        </div>
        {/* <div className="tab-pane p-3" id="profile-1" role="tabpanel">
          <h4 className="mt-0 header-title">Publishier information</h4>
          <p className="text-muted m-b-30">
            Please drop your file. The sequense of columns will be Publisher
            name, address line 1, address line 2, City/Town, County/State, Post
            or zip code, Country, website, VAT, Note
          </p>

          <div className="m-b-30">
            <DragAndDrop onDropHandler={this.onFileDrop} />
          </div>

          <div className="text-center m-t-15">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
              onClick={this.onSubmitHandler}
            >
              Upload
            </button>
          </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  content_type: state.journals.content_type || null,
  publication_type: state.journals.publication_type || null,
  article_status: state.articles.article_status || null,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  oa_subjectsList: state.OaSubject.oa_subjects || false,
});

const mapDispatchToProps = (dispatch) => ({
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
});

const styles = {
  error: { border: "1px solid red" },
};

export const EditBookForm = connect(mapStateToProps, mapDispatchToProps)(Form);
