import {
    GET_REFUND_REQUEST_LIST,
    GET_REFUND_REQUEST,
    CREATE_UPDATE_REFUND_REQUEST,
    DELETE_REFUND_REQUEST,
    ACCEPT_REFUND_REQUEST,
    CANCEL_REFUND_REQUEST,
    REJECT_REFUND_REQUEST,
    GET_OADEAL_BALANCE_LIST,
    MUTATE_REFUND_REQUEST,
    GET_AUTHOR_REFUND_REQUEST_INFO, APPROVE_REFUND_REQUEST, GET_ALL_REFUND_INFO_LIST, REQUEST_ALL_REFUND_INFO_LIST
} from "../../config";

const RefundRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_REFUND_REQUEST_LIST:
            return state = {
                ...state,
                refund_request_list: action.payload
            }
        case CREATE_UPDATE_REFUND_REQUEST:
        case GET_REFUND_REQUEST:
            return state = {
                ...state,
                refund_request: action.payload
            }
        case APPROVE_REFUND_REQUEST:
            return state = {
                ...state,
                refund_request_action: action.payload
            }
        case DELETE_REFUND_REQUEST:
        case ACCEPT_REFUND_REQUEST:
        case CANCEL_REFUND_REQUEST:
        case REJECT_REFUND_REQUEST:
        case MUTATE_REFUND_REQUEST:
        case GET_AUTHOR_REFUND_REQUEST_INFO:

            return state = {
                ...state,
                author_refund_request_info: action.payload
            }
        case GET_OADEAL_BALANCE_LIST:
            return state = {
                ...state,
                oadeal_balance_list: action.payload
            }
        case REQUEST_ALL_REFUND_INFO_LIST:
            return state = {
                isLoading: true,
            }
        case GET_ALL_REFUND_INFO_LIST:
            return state = {
                ...state,
                isLoading: false,
                publisher_refund_request_list: action.payload
            }
        default:
            return state;
    }
}

export default RefundRequestReducer;
