import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { getGeographicalDiscount } from "../../../../actions";
import {Link} from "react-router-dom"
import moment from "moment";

const format = "Do MMM YYYY";

class UL extends BaseComponent {
  state = {};
  getGeographicalDiscountData = async (pageNum = 1, page = 10) => {
    await this.props.getGeographicalDiscount({
      pageNum: pageNum,
      pageSize: page,
    });
      this.setState({
        data: this.props.discount_list,
        count: this.props.discount_list_count,
      });
   
  };

  componentDidMount() {
    this.getGeographicalDiscountData()
  }
  componentWillUnmount() {
  // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
  };

  }
  pageChange = (pageNumber) => {
    this.setState({
      current:pageNumber
    })
    this.getGeographicalDiscountData(pageNumber);
  };

  onEditGeographicalDiscount = (id, data) => {
    this.props.history.push("/geographical-discount/discount-edit/" + id, {
      society_member: data,
    });
  };



  render() {
    const heading = [
      "reference_number",
      "coverage",
      "offer_type",
      "currency",
      "offer_amount",
      "offer_expiry",
    ];
    const data = [];
    this.state.data &&
      this.state.data.map((item) => {
        return data.push({
          ...item,
          offer_expiry: item.offer_expiry_formated
            ? moment(item.offer_expiry_formated).format(format)
            : "-",
          currency: item.currency_name,
          coverage:
            item.coverage.length > 1
              ? `${item.coverage.length} countries selected`
              : `${item.coverage.length} country selected`,
        });
      });
    return (
      <HubContent title="Geographical discount list">
  
        <div className="row">
        <div className="vat-tax-button email d-flex justify-content-end">
            <Link
              style={{ marginRight: 25 }}
              to="/settings/add-geographical-discount/"
              className="btn btn-primary float-right mb-2"
            >
              Add Geographical Discount
            </Link>
          </div> 
          


          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && (
                <DataTable
                  heading={heading}
                  data={data}
                  onEdit={this.onEditGeographicalDiscount}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  discount_list:
    (state.discount &&
      state.discount.discount_list &&
      state.discount.discount_list.results) ||
    false,
  discount_list_count:
    (state.discount &&
      state.discount.discount_list &&
      state.discount.discount_list.count) ||
    false,
});

const mapDispatchToProps = (dispatch) => ({
  getGeographicalDiscount: (payload) =>
    dispatch(getGeographicalDiscount(payload)),
});

export const DiscountList = connect(mapStateToProps, mapDispatchToProps)(UL);
