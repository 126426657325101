import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { getSingleBookForm } from "../../../../actions";
import {
  SkeletonForm,
  ExpandHeadingTable,
} from "../../../../../core/components/common";

import JournalPrice from "../../publisher/journals/JournalPrice";
import { journalPriceHeading, column } from "../../../../../core/lib";
class Add extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    user: {},
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    let data = await this.props.getSingleBookForm(id);

    this.setState({
      data,
    });
  }
  checkStringvalue = (value) => (value ? value : "- ");
  renderListItems = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <p className="oadeal-table-view"> {item.name}</p>
      ));
    }

    return "";
  };
  render() {
    return (
      <HubContent
        title={
          this.state.data ? this.state.data.title : <SkeletonForm rows={1} />
        }
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body profile">
                {this.state.data ? (
                  <>
                    <div className="row">
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Title </label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.title
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Book ID</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.article_id
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Book type </label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.content_type_name
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Publisher</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.publisher_name
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Acronym</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data &&
                              this.state.data.publication &&
                              this.state.data.publication.pub_acronym
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Site</label>
                        <p>
                          {this.state.data &&
                            this.state.data.publication &&
                            this.state.data.publication.sub_sys_acronym}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">pISBN</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data &&
                              this.state.data.pisbn
                          )}
                        </p>
                      </div>

                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">eISBN</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data &&
                              this.state.data.eisbn
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Disciplines</label>
                        {this.state.data &&
                        this.state.data.disciplines_list &&
                        this.state.data.disciplines_list.length > 0 ? (
                          this.renderListItems(this.state.data.disciplines_list)
                        ) : (
                          <p>All disciplines</p>
                        )}
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Subjects </label>
                        {this.state.data &&
                        this.state.data.subjects_list &&
                        this.state.data.subjects_list.length > 0 ? (
                          this.renderListItems(this.state.data.subjects_list)
                        ) : (
                          <p>All subjects</p>
                        )}
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Editor Manager</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.editor_manager
                          )}
                        </p>
                      </div>

                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Editor Support</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.editor_support
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Editor Co-Support</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.editor_co_support
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Funder name</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.funder_name
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Grant Number</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.grant_number
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Doi</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.doi
                          )}
                        </p>
                      </div>
                      <div className="col-md-4 profile-m-15">
                        <label className="oadeal-view">Status</label>
                        <p>
                          {this.checkStringvalue(
                            this.state.data && this.state.data.status
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card m-b-20">
                          <div
                            id="collapse1"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-sm-12">
                                  <ul
                                    className="nav nav-tabs nav-tabs-custom nav-justified"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#main-info-1"
                                        role="tab"
                                      >
                                        Prices
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#other-info-1"
                                        role="tab"
                                      >
                                        Other
                                      </a>
                                    </li>
                                  </ul>
                                  <div className="tab-content">
                                    <div
                                      className="tab-pane active p-3"
                                      id="main-info-1"
                                      role="tabpanel"
                                    >
                                      <ExpandHeadingTable
                                        heading={journalPriceHeading}
                                        column={column}
                                      />

                                      <div id="accordion">
                                        <JournalPrice
                                          item={this.state.data}
                                          column={column}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane p-3"
                                      id="other-info-1"
                                      role="tabpanel"
                                    >
                                      Other Info
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <SkeletonForm rows={5} />
                )}
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSingleBookForm: (id) => dispatch(getSingleBookForm(id)),
});

export const SingleBook = connect(mapStateToProps, mapDispatchToProps)(Add);
