import React from "react";
import BaseComponent from "../../../core/components/BaseComponent";

export class NotFound extends BaseComponent {
  render() {
    return (
      <div className="flex-container">
        <div className="notfound">
          <h1

          >
            404!    </h1>

          <h1

          >
            Oops! Page not found
              </h1>

          <button
            onClick={() => this.props.history.goBack()}
            className="bttn-1 bttn-ppl btn-primary btn"
          >
            Go Back
              </button>
          <button
            onClick={() => this.props.history.push("/")}
            className="ml-2 bttn-1 bttn-ppl btn-primary btn"
          >
            Home
              </button>
        </div>
      </div>
    );
  }
}

export default NotFound;
