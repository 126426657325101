import React from "react";
import AppSettings from "../../../../__base__/AppSettings";
import { HubContent } from "../../../../core/components";

class DB extends AppSettings {
  constructor(props) {
    super(props);
    this.state = {
      org: [
        {
          label: "Select",
        },
        {
          label: "Publisher",
          value: 1,
        },
        {
          label: "University",
          value: 2,
        },
        {
          label: "User",
          value: 3,
        },
      ],
      deal: [
        {
          label: "Select Deals",
        },
        {
          label: "OA Deal",
          value: 2,
        },
        {
          label: "Non OA Deal",
          value: 3,
        },
      ],
      ins: [
        {
          label: "Select Institute",
        },
        {
          label: "Publisher",
          value: 1,
        },
        {
          label: "University",
          value: 2,
        },
        {
          label: "User",
          value: 3,
        },
      ],
      year: [
        {
          label: "Select Years",
          value: 1,
        },
        {
          label: "2018",
          value: 2,
        },
        {
          label: "2019",
          value: 3,
        },
      ],
    };
  }
  render() {
    return (
      <HubContent title="Admin Dashboard">
        
      </HubContent>
    );
  }
}

export const HubDashboard = DB;
