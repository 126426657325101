import React from "react";
import { Popover } from "antd";

import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { UserForm } from "../../../partials";
import { CreateUser, ClearStatus } from "../../../../actions";
import { PopupBox, Loading, Button } from "../../../../../core/components/common";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">User</a>
    <br />
  </div>
);
class Add extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    user: {},
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
  };

  componentDidUpdate() {
    this.state.msg &&
      setTimeout(() => {
        this.setState({
          msg: false,
        });
      }, 2000);

    if (this.props.failed) {
      if (this.props.failed.status === 500 && this.state.status !== 500) {
        this.setState({
          status: 500,
        });
      } else if (
        this.props.failed.status === 400 &&
        this.state.status !== 400
      ) {
        this.setState({
          status: 400,
        });
      }
    }
  }

  onSubmitHandler = async (user) => {
    if (user.email && user.first_name && user.last_name && user.role) {
      this.setState({
        isRegistering: true,
        msg: false,
        user: user,
      });
      const data = Object.assign(user, {
        request_source: "hub",
        group: this.props.group,
        organisation: this.props.organisation,
      });
      const new_user = await this.props.CreateUser(data);
      if (new_user.status === 201) {
        this.props.history.push("/pub-user/user-list");

        this.setState({
          isRegistering: false,
          msg: "New user created.",
          user: {},
        });
      }
    } else {
      this.setState({
        isEmpty: (
          <div>
            {!user.email && <p>Email Cannot Be Empty!</p>}
            {!user.first_name && <p>Firstname Cannot Be Empty</p>}
            {!user.last_name && <p>Lastname Cannot Be Empty</p>}
            {!user.role && <p>Role Cannot Be Empty</p>}
          </div>
        ),
      });
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  render() {
    let errorHander = (
      <>
      

        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}

        {this.state.msg && (
          <PopupBox
            Title={this.state.msg}
            msg={this.state.msg}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent title=" Add User Details ">
        {errorHander}

        {this.state.isRegistering && !this.state.status && <Loading />}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <div className="vat-tax-button email d-flex justify-content-end">


                  <Popover placement="leftTop" content={content} trigger="click">
                    <Button
                      className="btn btn-secondary float-right "
                      type=""
                      name=""
                      text="Help"
                    >Help</Button>
                  </Popover>
                  <div className="danger float-right"
                  >
                    <button
                      type="danger"
                      className="btn btn-danger float-right "
                      onClick={() => this.props.history.goBack()}
                      style={{ marginLeft: 10 }}
                    >
                      X

</button>                  </div>
                </div>
                <UserForm
                  onSubmitHandler={this.onSubmitHandler}
                  msg={this.state.msg}
                  user={this.state.user}
                  notHub={true}
                />
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.user.failed || false,
  group: (state.auth && state.auth.user && state.auth.user.group_id) || false,
  organisation:
    (state.auth && state.auth.user && state.auth.user.organisation_id) || false,
});

const mapDispatchToProps = (dispatch) => ({
  CreateUser: (payload) => dispatch(CreateUser(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
});

export const PubAddUser = connect(mapStateToProps, mapDispatchToProps)(Add);
