import React from "react";
import BaseComponent, { HubContent, DataTable } from "../../../../../core/components";
import {

    Loading,
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import {
    emailLogHistory
} from "../../../../actions";


class EmailLog extends BaseComponent {

    state = {

    }
    getEmaiLogData = async (pageNum = 1, page = 10) => {
        await this.props.emailLogHistory({ pageNum: pageNum, pageSize: page });
        let data = this.props.email_history_log.results;
        let dataOrg = [];
        data.map((item) => {
            return dataOrg.push({
                "Sender": item.recipients,
                "subject": item.subject,
                "status": item.status,

                id: item.id
            });
        })
        this.setState({
            data: dataOrg,
            count: this.props.email_history_log.count
        })
    }
    async componentDidMount() {
        this.getEmaiLogData();
    }
    pageChange = (pageNumber) => {
        this.getEmaiLogData(pageNumber);
    }


    onViewCustomEmail = (id) => {
        this.props.history.push('/custom-email/custom-email-message/' + id)
    }
    // help menu function 
    HelpButtonClick = () => {
        const url = '/links';
        window.open(url, '_blank');

    }
    render() {
        const heading = [
            "Sender",
            "subject",
            "status"
        ];
        return (
            <HubContent
                title="Inbox List"
                onHelpHandler={this.HelpButtonClick}
            >


                <div className="row filter">

                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            {this.state.data ?

                                <DataTable
                                    heading={heading}
                                    data={this.state.data}

                                    onView={this.onViewCustomEmail}
                                    count={this.state.count}
                                    pageChange={this.pageChange}
                                    pageSize={10}
                                />
                                : <Loading />

                            }
                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = (state) => ({
    email_history_log: state.articles.email_history_log

});
const mapDispatchToProps = (dispatch) => ({
    emailLogHistory: (payload) => dispatch(emailLogHistory(payload)),

});

export const ArticleCustomEmail = connect(mapStateToProps, mapDispatchToProps)(EmailLog);











