import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { Button } from "../../../../../core/components/common";

import { getSocietyDiscount } from "../../../../actions";
import { Skeleton } from "antd";
import moment from "moment";

class ViewTable extends BaseComponent {
  state = {
    info: null,
  };

  async componentDidMount() {
    let id = this.props.match.params.id;

    try {
      await this.props.getSocietyDiscount(id);
      this.setState({
        info: this.props.society_discount,
      });
    } catch (e) {

    }
  }

  dateFormat = (date) => {
    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  };

  priceFormat = (price) => {
    let dataPrice = Number(price).toFixed(2);
    return dataPrice;
  };

  renderListItems = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => item.name).join(", ");
    }

    return "";
  };

  renderDetails = () => {
    let info = this.props.society_discount;
//    let optionsObj = this.props.society_discount.options || {};

    return (
      info && (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div className="card-body row society-discount">
                <div className="col-sm-12">
                  <h3 className="oadeal-view-heading">Society Discount </h3>
                  <table style={styles.table}>
                    {/******** User Basic Info *******/}
                    <tbody>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Society</td>
                        <td style={styles.td2}>
                          {this.props.society_discount.society_name}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Currency</td>
                        <td style={styles.td2}>
                          {this.props.society_discount.offer_currency_name}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Discount type</td>
                        <td style={styles.td2}>
                          {this.props.society_discount.offer_type}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Expiry</td>
                        <td style={styles.td2}>
                          {this.priceFormat(
                            this.props.society_discount.offer_amount != null
                              ? this.props.society_discount.offer_amount
                              : "-"
                          )}
                        </td>
                      </tr>

                      <tr style={styles.tr}>
                        <td style={styles.td}>Expiry</td>
                        <td style={styles.td2}>
                          {this.props.society_discount.offer_expiry !== null
                            ? this.dateFormat(
                              this.props.society_discount.offer_expiry
                            )
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row" style={{ padding: 15 }}>
                  <div className="col-md-4">
                    <label className="oadeal-view">Disciplines</label>
                    {this.props.society_discount.discipline_details.length > 0
                      ? this.renderListItems(
                        this.props.society_discount.discipline_details
                      )
                      : `All disciplines`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Subjects </label>
                    {this.props.society_discount.subject_details.length > 0
                      ? this.renderListItems(
                        this.props.society_discount.subject_details
                      )
                      : `All subjects`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Publication types </label>
                    {this.props.society_discount.publication_type_details
                      .length > 0
                      ? this.renderListItems(
                        this.props.society_discount.publication_type_details
                      )
                      : `All publication types`}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Publications </label>
                    {this.props.society_discount.publication_details.length > 0
                      ? this.renderListItems(
                        this.props.society_discount.publication_details
                      )
                      : `All publications`}{" "}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Content types</label>
                    {this.props.society_discount.content_type_details.length > 0
                      ? this.renderListItems(
                        this.props.society_discount.content_type_details
                      )
                      : `All content types`}
                  </div>
                </div>

                <div className="form-group pt-3">
                <div className="d-flex society">
                    <p className="col-lg-5 col-md-8">
                    Would you like to display society discount icon for authors in article?

                    </p>
                    <p className="col-md-4  text-color">
                      <strong>{info.show_badge ? "Yes" : "No"}</strong>
                    </p>
                  </div>
                  {/*
                  <div className="d-flex society">
                    <p className="col-md-11">
                      Would you apply a Society or Society member discount or a
                      special price if any of the authors claims to be a member
                      of the above society, but Oametrix could not cross check
                      this information?
                    </p>
                    <p className="col-md-1 text-right text-color">
                      <strong>{optionsObj.option_1 ? "Yes" : "No"}</strong>
                    </p>
                  </div>
                  <div className="d-flex society">
                    <p className="col-md-11">
                      Would you apply a Society or Society member discount or a
                      special price when an institutional administrator approves
                      an article where any of the authors claims to be a member
                      of the above society, and OaMetrix has successfully
                      cross-checked this information?
                    </p>
                    <p className="col-md-1 text-right text-color">
                      <strong>{optionsObj.option_2 ? "Yes" : "No"}</strong>
                    </p>
                  </div>
                  <div className="d-flex society">
                    <p className="col-md-11">
                      Would you apply a Society or Society member discount or a
                      special price when an institutional administrator approves
                      an article where any of the authors claims to be a member
                      of the above society, but OaMetrix could not cross check
                      this information?
                    </p>
                    <p className="col-md-1 text-right text-color">
                      <strong>{optionsObj.option_3 ? "Yes" : "No"}</strong>
                    </p>
                  </div>
                  <div className="d-flex society">
                    <p className="col-md-11">
                      Would you apply a Society or Society member discount or a
                      special price when an institutional administrator approves
                      an article under an OA deal where any of the authors
                      claims to be a member of the above society, and OaMetrix
                      successfully cross-checked this information?
                    </p>
                    <p className="col-md-1 text-right text-color">
                      <strong>{optionsObj.option_4 ? "Yes" : "No"}</strong>
                    </p>
                  </div>
                  <div className="d-flex society">
                    <p className="col-md-11">
                      Would you apply a Society or Society member discount or a
                      special price when an institutional admin approves an
                      article under an OA deal where any of the authors claims
                      to be a member of the above society, but OaMetrix could
                      not cross-check this information?
                    </p>
                    <p className="col-md-1 text-right text-color">
                      <strong>{optionsObj.option_5 ? "Yes" : "No"}</strong>
                    </p>
                  </div>
                   */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  render() {

    return (
      <HubContent title="Society discount details">
        {this.state.info ? (
          this.renderDetails()
        ) : (
            <Skeleton active paragraph={{ row: 10 }} />
          )}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  society_discount: state.societyDiscount.society_discount || false,
});

const mapDispatchToProps = (dispatch) => ({
  getSocietyDiscount: (id) => dispatch(getSocietyDiscount(id)),
});

export const SocietyDiscountView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTable);

const styles = {
  color: "red",
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tr: {
    marginTop: 20,
    verticalAlign: "baseline",
  },
  td: {
    paddingTop: 10,
    color: "rgb(0, 122, 206)",
  },
  td2: {
    paddingTop: 10,
    width: "75%",
  },
};
