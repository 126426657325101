import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import BaseComponent, { HubContent } from "../../../../core/components";
import { OaTokenTrxn, getTokenDownload } from "../../../actions";
import {
  Loading,
  ExpandData,
  ExpandHeadingTable,
  PaginationForm,
} from "../../../../core/components/common";
import { trxTokenHeading, column } from "../../../../core/lib";

class TransactionToken extends BaseComponent {
  state = {
    activeItem: null,
    current:1
  };

  onItemActive = (item) => {
    this.setState({ activeItem: item });
  };
  componentDidMount() {
    this.getTrxToken();
  }
  getTrxToken = async (pageNum = 1, page = 10) => {
    await this.props.OaTokenTrxn({ pageNum: pageNum, pageSize: page });
  };

  pageChange = (pageNumber) => {
    this.setState({
      current:pageNumber
    })
    this.getTrxToken(pageNumber);
  };
  transactionTokenDownloadClick = async () => {
    try {
      await this.props.getTokenDownload();
      const url = window.URL.createObjectURL(this.props.tokenDownload);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `${this.props.group.toLowerCase()}OA-tokens-transaction-${moment(
          d
        ).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  render() {
    let { token_trxn } = this.props;

    let data = [];
    if (token_trxn.results) {
      if (this.props.group === "PUBLISHER") {
        token_trxn.results.map((trx) => {
          return data.push({
            id: trx.token_info.id,

            date: moment(trx.date_created, "DD-MM-YYYY").format(),
            institution: trx.institution_name,
            publisher: trx.publisher_name,
            author: trx.article_info.author_name,
            reference: trx.token_info ? trx.token_info.name : "-",
            trx_token_name: trx.token_info ? trx.token_info.token : "-",
            currency: trx.currency
              ? trx.currency === "BRITISH POUND STERLING"
                ? "GBP"
                : trx.currency === "EURO"
                  ? "EUR"
                  : "USD"
              : null,
            amount: trx.amount,
            article_id:
              trx.article_info && trx.article_info.article_id
                ? trx.article_info && trx.article_info.article_id
                : "-",
            article: trx.article_title,
          });
        });
      } else {
        token_trxn.results.map((trx) => {
          return data.push({
            id: trx.token_info.id,
            date: moment(trx.date_created, "DD-MM-YYYY").format(),
            institution: trx.institution_name,
            publisher_name: trx.token_info.publisher_name,
            author: trx.article_info.author_name,

            reference: trx.token_info ? trx.token_info.name : "-",
            trx_token_name: trx.token_info ? trx.token_info.token : "-",
            currency: trx.currency
              ? trx.currency === "BRITISH POUND STERLING"
                ? "GBP"
                : trx.currency === "EURO"
                  ? "EUR"
                  : "USD"
              : null,
            amount: trx.amount,
            article_id:
              trx.article_info && trx.article_info.article_id
                ? trx.article_info && trx.article_info.article_id
                : "-",
            article: trx.article_title,
          });
        });
      }
    }

    return (
      <HubContent
        title="Transaction - OA tokens"
        onArticleDownloadClickHandler={this.transactionTokenDownloadClick}
      >
        <div className="row filter">
      
          <div className="col-12">
            <div className="card m-b-20">
              <ExpandHeadingTable heading={trxTokenHeading} />
              {token_trxn.results ? (
                <div>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <div id="accordion" key={index}>
                        <ExpandData
                          onActionHandler={this.onActionHandler}
                          invoiceUpdate={true}
                          key={index}
                          item={item}
                          column={column}
                          downloadName="Invoice download"
                        />
                      </div>
                    ))
                  ) : (
                      <div className="card-body" style={{ textAlign: "center" }}>
                        No Data Found
                      </div>
                    )}
                  {token_trxn.results.length > 0 ? (
                    <PaginationForm
                      pageSize={10}
                      current={this.state.current}
                      pageChange={this.pageChange}
                      count={token_trxn.count}
                    />
                  ) : null}
                </div>
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  token_trxn: state.trxn.token_trxn,
  tokenDownload: state.trxn.token_download,
  group: (state.auth && state.auth.group) || false,
});

const mapDispatchToProps = (dispatch) => ({
  OaTokenTrxn: (payload) => dispatch(OaTokenTrxn(payload)),
  getTokenDownload: (payload = {}) => dispatch(getTokenDownload(payload)),
});

export const TrxToken = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionToken);
