import React from "react";
import BaseComponent, {
  HubContent,
  DataTable,
} from "../../../../../core/components";
import { connect } from "react-redux";
import {
  GetPendingDepositList,
  DepositApproval,
} from "../../../../actions/Deposit";
import { Loading, ErrorHandler } from "../../../../../core/components/common";
import moment from "moment";
import { PopupBox } from "../../../../../core/components/common/PopupBox";

// import { DataFilter } from '../../../../../openaccess/components/common';
import { serialize } from "../../../../../core/lib";

class DB extends BaseComponent {
  state = {
    ids: [],
    err: false,
    approve: null,
    depositIds: [],
    checkedIds: [],
    filterParams: { page: 1 },
  };

  async componentDidMount() {
    await this.props.GetPendingDepositList();
  }

  onChecked = (id) => {
    let index = this.state.ids.indexOf(id);
    this.setState((prevState) => {
      index === -1 ? prevState.ids.push(id) : prevState.ids.splice(index, 1);
      return prevState;
    });
  };

  ids = [];
  onSelect = (id) => {
    let index = this.ids.indexOf(id);
    index !== -1 ? this.ids.splice(index, 1) : this.ids.push(id);
    this.setState({
      depositIds: this.ids.toString(),
      checkedIds: this.ids,
    });
  };

  onApproval = async () => {
    if (this.state.checkedIds.length > 0) {
      let ids = this.state.depositIds;
      let result = await this.props.DepositApproval(ids);
      if (result.success) {
        this.props.history.push("/oa-deal-activities/deposite-list");
      }
    } else {
      this.setState({ err: true });
    }
  };
  onArticleDownloadClick = async () => {
    // try {
    //   let params = await serialize(this.state.params);
    //   let filter = await serialize(this.state.filterParams);
    //   await this.props.DepositApprovalDownload({ params, filter });
    //   const url = window.URL.createObjectURL(this.props.deposit_approve_data);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   var d = new Date();
    //   link.setAttribute(
    //     "download",
    //     `deposit_approve_data_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (error) {
    
    // }
  };

  filterData = async (value) => {
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = await serialize(this.state.filterParams);
    this.setState({
      filter_url: filter,
    });
  };

  onFilter = async () => {
    let filter = this.state.filter_url;
    await this.props.getJournalList(filter);
  };

  render() {
    let heading = [
      "date",
      "institution",
      "oa_deal",
      "deal_year",
      "currency",
      "read_fee",
      "publish_fee",
      "total",
      "deposit_by",
    ];
    let status = this.props.deposit && this.props.deposit.status;

    this.props.deposit &&
      !status &&
      this.props.deposit.map((deposit) => {
        return Object.assign(deposit, {
          date: moment().format("DD MMM YYYY"),
          institution: deposit.organisation_name,
          oa_deal: deposit.oa_deal_type,
          deal_year: 2019,
          currency: deposit.currency_name,
          read_fee: deposit.read_fee,
          publish_fee: deposit.publish_fee,
          total: deposit.amount,
          deposit_by: deposit.created_by_name,
        });
      });

    // let filter_fields = [
    //   {
    //     field_name: 'filter_title',
    //     field_type:'text'
    //   },
    //   {
    //     field_name: 'filter_pissn',
    //     field_type: 'text'
    //   },
    //   {
    //     field_name: 'filter_eissn',
    //     field_type: 'text'
    //   },
    //   {
    //     field_name: 'filter_name',
    //     field_type: 'text'
    //   },
    //   {
    //     field_name: 'filter_date',
    //     field_type: 'date'
    //   },
    //   {
    //     data:[
    //       {name: "All Demo Two",id: "all"},
    //       {id:1,name:'demo_two_1'},
    //       {id:2,name:'demo_two_2'},
    //       {id:3,name:'demo_two_3'},
    //       {id:4,name:'demo_two_4'},
    //       {id:5,name:'demo_two_5'}
    //     ],
    //     field_name:'demo_two'
    //   }
    // ];

    return (
      <HubContent
        title="Deposit acknowledgement"
        subtitle="The following universities have deposited or topped up their OA funds"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >
        {this.state.err && (
          <PopupBox
            title="No Item Selected"
            msg="Please select few item"
            onCancel={() => this.setState({ err: false })}
          />
        )}
        {/* {ErrorHandler} */}
        <div className="row">
          <div className="col-12">
            {/* {  this.props.deposit &&  <DataFilter
              filterData = { this.filterData }
              filter_fields = { filter_fields }
              onClickFilter = { true }
              onFilter = { this.onFilter }
            /> 
          } */}
            <div className="card m-b-20">
              {this.props.deposit ? (
                !status ? (
                  <DataTable
                    heading={heading}
                    data={this.props.deposit}
                    onSelect={this.onSelect}
                    checkbox={true}
                    noAction={true}
                  />
                ) : (
                  <ErrorHandler status={status} />
                )
              ) : (
                <Loading type="flat" />
              )}

              <div className="form-group text-center my-3">
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={this.onApproval}
                  disabled={this.state.checkedIds.length <= 0}
                >
                  Acknowledge
                </button>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  deposit: state.deposit.pending,
  // deposit_approve_data:state.deposit.deposit_approve_data
});

const mapDispatchToProps = (dispatch) => ({
  GetPendingDepositList: () => dispatch(GetPendingDepositList()),
  DepositApproval: (ids) => dispatch(DepositApproval(ids)),
  // DepositApprovalDownload:(ids) => dispatch(DepositApprovalDownload()),
});

export const DipositApproval = connect(mapStateToProps, mapDispatchToProps)(DB);
