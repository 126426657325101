import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Button} from "antd"
import BaseComponent from "../../../../../../core/components";
import validator from "validator";
import CoAuthorArticle from "../CoAuthorArticle";
import Prices from "./Prices";

import moment from "moment";
import {
  Badge,
  InputNewBox,
  SelectBox,
  OaDatePicker,
  PopupBox,
  Button as Buttons,
  Loading
} from "../../../../../../core/components/common";
import {
  articleFullUpdate,
  articleFileUpload,
  trackArticleFileUploadStatus,
  getPublicatonTypeList,
  CreateArticle,
  EditArticle,
  getSingleArticle,
  trackArticleFileUploadStatusDownload,
  getJournalTypeList,
  getArticleFullSingleList,
  getPublicPublisher
} from "../../../../../actions";
import "react-confirm-alert/src/react-confirm-alert.css";
const BtnText = "Add Article";

class UpdateArticleForm extends BaseComponent {
  state = {
    article: {},
    SubmitBtnText: BtnText,
    errMsg: false,
    isCoAuthor: false,
    isAuthor: false,
    edit_modal: false,
    co_authors: [],
    author: null,
    editing: false,
    loaded: false,
  };
  componentDidMount() {
    this.apiDataList()
  }

  apiDataList = async () => {
    await this.props.getPublicPublisher()

    let articleData = await this.props.getArticleFullSingleList(this.props.id)
    let id = articleData && articleData.publisher && articleData.publisher.id
    let publisher = this.props.publishers && this.props.publishers.results && this.props.publishers.results.find(item=>item.id ===id)
    let author = {};

    if (
      articleData &&
      articleData.author &&
      articleData.author.id
    ) {
      author = {
        middle_name: articleData.author.middle_name,
        name: articleData.author.name,
        orcid_id: articleData.author.orcid_id,
        pmc_id: articleData.author.pmc_id,
        first_name: articleData.author.first_name,
        position: articleData.author.position,
        salutation: articleData.author.salutation,
        id: articleData.author.id,
        last_name: articleData.author.last_name,
        email: articleData.author.email,
        affiliation: articleData.author.affiliation,
        department: {
          name: articleData.author && articleData.author.department && articleData.author.department.name,

        }

      };
    }
    if (articleData) {

        this.setState({

          prices: {
            content_type: articleData.content_type,
            content_type_name: articleData.content_type_name,
            colour_charge_eur:
              articleData.colour_charge_eur
                ? articleData.colour_charge_eur
                : 0,
            colour_charge_gbp:
              articleData.colour_charge_gbp
                ? articleData.colour_charge_gbp
                : 0,
            colour_charge_usd:
              articleData.colour_charge_usd
                ? articleData.colour_charge_usd
                : 0,
            other_charge_eur:
              articleData.other_charge_eur
                ? articleData.other_charge_eur
                : 0,
            other_charge_gbp:
              articleData.other_charge_gbp
                ? articleData.other_charge_gbp
                : 0,
            other_charge_usd:
              articleData.other_charge_usd
                ? articleData.other_charge_usd
                : 0,
            page_charge_eur:
              articleData.page_charge_eur
                ? articleData.page_charge_eur
                : 0,
            page_charge_gbp:
              articleData.page_charge_gbp
                ? articleData.page_charge_gbp
                : 0,
            page_charge_usd:
              articleData.page_charge_usd
                ? articleData.page_charge_usd
                : 0,
            pub_fee_usd:
              articleData.pub_fee_usd
                ? articleData.pub_fee_usd
                : 0,
            pub_fee_gbp:
              articleData.pub_fee_gbp
                ? articleData.pub_fee_gbp
                : 0,
            pub_fee_eur:
              articleData.pub_fee_eur
                ? articleData.pub_fee_eur
                : 0,
            submission_fee_eur:
              articleData.submission_fee_eur
                ? articleData.submission_fee_eur
                : 0,
            submission_fee_gbp:
              articleData.submission_fee_gbp
                ? articleData.submission_fee_gbp
                : 0,
            submission_fee_usd:
              articleData.submission_fee_usd
                ? articleData.submission_fee_usd
                : 0,
          },
  
            acceptance_date:(articleData.acceptance_date &&  moment(articleData.acceptance_date) )|| undefined,
         submission_date:(articleData.submission_date && moment(articleData.submission_date) )|| undefined,
          article: {
            vol_name: articleData.vol_name,
            vol_number: articleData.vol_number,
            vol_pub_year: articleData.vol_pub_year,
            title: articleData.title,
            article_id: articleData.article_id,
            content_type: articleData.content_type,
            doi: articleData.doi,
            journal_name: articleData.publication.name,
            journal_acronym: articleData.publication.pub_acronym,
            sub_system_acronym: articleData.publication.sub_sys_acronym,
            pissn: articleData.publication.pissn,
            eissn: articleData.publication.eissn,
            publisher_name: articleData.publisher_name,
            funder_name: articleData.funder_name,
            fund_acknowledgement: articleData.fund_acknowledgement,
            grant_number: articleData.grant_number,
            meta_source_document_id:articleData.meta_source_document_id
              ,
            "publisher":this.props.group==="HUB" ? publisher.id : this.props.publisher_name.organisation_id ,
            "publication": articleData.publication.id,
          },
          co_authors: (articleData && articleData.co_authors.map(item => ({
            affiliated_organisation: item.affiliated_organisation,
            affiliation: item.affiliation,
            department: {
              name: item && item.department && item.department.name,
            },
            id: item.id,
            last_name: item.last_name,
            middle_name: item.middle_name,
            name: item.name,
            orcid_id: item.orcid_id,
            pmc_id: item.pmc_id,
            position: item.position,
            salutation: item.salutation,
            first_name: item.first_name,
            email: item.email,
          }))) || [],
          author: author,
          is_author_affiliation_match: this.props.is_author_affiliation_match,
   
          is_publisher_name_match: articleData.is_publisher_name_match,
          loaded: true,
          SubmitBtnText: "Update",
        });
   
  
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
        return;
    };

}

  onChangeHandler = (value) =>
    this.setState((prevState) => Object.assign(prevState.article, value));
  
  removeEmpty = (obj) => {
    Object.keys(obj ).forEach(key => {
      if (obj && obj[key] && typeof obj && obj[key] === 'object') this.removeEmpty(obj[key]);
      if (
        (!obj[key] && obj[key] !== 0) ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  };
  cancelLoader = async () => {
    this.setState({
      SubmitBtnText: BtnText,
      errMsg: false,
    });
  };


  addNewAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isAuthor: true,
    });
  };
  onCloseAuthor = () => {
    this.setState({
      isCoAuthor: false,
      isAuthor: false,
    });
  };
  onClosePrices = () => {
    this.setState({
      isPrices: false,
      editing: false,
    });
  };

  onSavePriceHandler = (price_info) => {
    this.setState((prevState) => {
      prevState.prices = price_info;
      prevState.isPrices = false;
      return prevState;
    });
  };

  onEditPrices = (id) => {
    this.setState({
      isPrices: true,
    });
  };

  onDeletePrice = () => {
    this.setState({
      prices: null,
    });
  };

  addNewCoAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isCoAuthor: true,
    });
  };


  onSaveCoAuthorHander = (author_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.co_authors[prevState.editing] = author_info;
        prevState.editing = false;
      } else {
        prevState.co_authors.push(author_info);
      }
      prevState.isCoAuthor = false;
      return prevState;
    });
  };

  onSaveAuthorHander = (author_info) => {
    this.setState((prevState) => {
      prevState.author = author_info;
      prevState.isAuthor = false;
      return prevState;
    });
  };

  onEditAuthor = () => {
    this.setState({
      isAuthor: true,
    });
  };

  onDeleteAuthor = () => {
    this.setState({
      author: null,
    });
  };

  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isCoAuthor: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.co_authors.splice(id, 1);
      return prevState;
    });
  };
  onSubmitHandler = async (e) => {
    let payload = {
      ...this.state.article,
      ...this.state.prices
    }

    let author = this.state.author
    if ((author && author.department === null )|| Object.keys(author && author.department) === 0||( author && author.department.name === undefined )|| (author && author.department && author.department.name === null )|| (author && author.department && author.department.name === "")) {
      delete author.department
    }
    let co_authors = this.state.co_authors && this.state.co_authors.map((item) => {
      if ((item && item.department === null )|| Object.keys(item && item.department) === 0||(item && item && item.department.name === undefined )||  (item && item.department && item.department.name === null )|| (item && item.department && item.department.name === "")) {
        delete item.department
      }
      return item
    })

    this.state.author && Object.assign(payload, { author });
    this.state.co_authors && Object.assign(payload, { co_authors });

    if (this.state.acceptance_date) {
      Object.assign(payload, {
        acceptance_date: moment(this.state.acceptance_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(payload, {
        acceptance_date:null,
      });
    }

    if (this.state.submission_date) {
      Object.assign(payload, {
        submission_date: moment(this.state.submission_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(payload, {
        submission_date: null,
      });
    }


    if (!payload.title || !payload.content_type) {
      this.setState({
        errMsg: (
          <div>
            {!payload.title && <p>Title cannot be empty!</p>}
            {!payload.content_type && <p>Content name cannot be empty</p>}
            {payload.doi && !validator.isURL(payload.doi) && (
              <p>URL format is not correct</p>
            )}
            {payload.author &&
              payload.author.email &&
              !validator.isEmail(payload.author.email) && (
                <p>Email format is not correct</p>
              )}
          </div>
        ),
      });
    } else {
      this.setState({
        errMsg: false,
      });


      let result = await this.props.articleFullUpdate(this.props.id, payload);
      if (result.status === 200) {
        this.setState({
          success: true,
          result
        });
      }

    }
  };

  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push(this.props.group ==="HUB" ? {pathname:"/articles/article-full",filter:this.props.filter }: {pathname:"/article/publisher-articles",filter:this.props.filter } )
  }
  render() {
    let {
      article,
      is_author_affiliation_match,

      is_publisher_name_match,
    } = this.state;
    return (
       <div className="row">
            {this.state.success && (
              <PopupBox
                NoText="Close"
                msg="You have successfully Update Article."
                onCancel={this.redirectLink}
              />
        )}
       
        <div className="col-lg-12">
          <div className="card m-b-20 article-update">
            
                <div className="vat-tax-button email">
                  <Buttons
                    type="danger"
                    name="danger"
                    text="x"
                    onClick={() => this.props.history.goBack()}
                  />
            </div>
            { this.props.isLoading !== true ?  

                <div className="card-body">

                  {this.state.errMsg && (
                    <PopupBox
                      Title="Following field cannot be empty"
                      msg={this.state.errMsg}
                      onCancel={this.cancelLoader}
                    />
                  )}

                  <div className="form-group row">
                    <InputNewBox
                      label="Article title"
                      onChange={this.onChangeHandler}
                      field="title"
                      className="col-sm-10"
                      placeholder="Article title"
                      isRequired={true}
                      defaultValue={article.title}
                    />
                  </div>

                  <div className="form-group row">
                    <InputNewBox
                      label="Article id"
                      onChange={this.onChangeHandler}
                      field="article_id"
                      className="col-sm-4"
                      placeholder="Article or document id"
                      defaultValue={article.article_id}
                    />

                    <SelectBox
                      label="Content type"
                      onChange={this.onChangeHandler}
                      field="content_type"
                      data={this.props.content_type}
                      isRequired={true}
                      defaultValue={article.content_type}
                    />
                </div>

                <div className="form-group row">
                    <InputNewBox
                      label="Document id"
                      onChange={this.onChangeHandler}
                      field="meta_source_document_id"
                    className="col-sm-4"
                    disabled={article &&article.meta_source_document_id ? true :false}
                      placeholder="Article or document id"
                      defaultValue={article &&article.meta_source_document_id}
                  />
                  
                 {this.props.group==="HUB" &&  <SelectBox
                 label="Publisher"
                 onChange={this.onChangeHandler}
                 field="publisher"
                 data={this.props.publishers && this.props.publishers.results}
                 isRequired={true}
                 defaultValue={article.publisher}
               />}

                  </div>
                  


                  <div className="form-group row mt-4">
                    <div className="col-sm-12">
                      <div className="float-left" style={{ marginRight: 20, marginLeft: 100 }}>
                        <h6>Add Author</h6>
                      </div>
                      <div className="float-left">
                        <button
                          className="btn btn-light btn-o-light"
                          onClick={this.addNewAuthor}
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="mdi mdi-plus"></i> Add Author
                    </button>
                      </div>
                    </div>
                  </div>

                  {this.state.isAuthor && (
                    <CoAuthorArticle
                      isShow={this.state.isAuthor} // if true modal will show up else it will be hidden
                      onClose={this.onCloseAuthor} // Handle Close
                      onSaveAuthor={this.onSaveAuthorHander}
                      fieldLabel="Author"
                      value={this.state.author}
                      is_author_affiliation_match={is_author_affiliation_match}
                    />
                  )}

                  <div className="form-group row" style={{ marginLeft: 100 }}>
                    {this.state.author && (
                      <Badge
                        label={
                          !this.state.author.first_name &&
                            !this.state.author.last_name
                            ? "No Name"
                            : (this.state.author.first_name || "") +
                            " " +
                            (this.state.author.last_name || "")
                        }
                        onEdit={this.onEditAuthor}
                        onDelete={this.onDeleteAuthor}
                        style={
                          is_author_affiliation_match === false ? styles.error : {}
                        }
                      />
                    )}
                  </div>
                  {/* Author Information End */}

                  <div className="form-group row">

                    <InputNewBox
                      label="Journal name"
                      onChange={this.onChangeHandler}
                      field="journal_name"
                      placeholder="Journal name"
                      defaultValue={article.journal_name}
                    />

                    <InputNewBox
                      label="Article DOI"
                      onChange={this.onChangeHandler}
                      field="doi"
                      placeholder="Doi"
                      InputType="url"
                      defaultValue={article.doi}
                    />
                  </div>
                  {article.is_book && <div className="form-group row">
                    <InputNewBox
                      onChange={this.onChangeHandler}
                      className="col-sm-10"
                      labelClass="col-sm-2"
                      field="vol_name"
                      label="Volume Name"
                      placeholder="Volume name"
                      defaultValue={article.vol_name}
                    />
                  </div>}

                  {article.is_book && <div className="form-group row">
                    <InputNewBox
                      onChange={this.onChangeHandler}
                      className="col-sm-2"
                      labelClass="col-sm-2"
                      field="vol_number"
                      label="Volume No/Year."
                      placeholder="Vol. #"
                      defaultValue={article.vol_number}
                    />
                    <InputNewBox
                      onChange={this.onChangeHandler}
                      field="vol_pub_year"
                      className="col-sm-2"
                      placeholder="Year"
                      defaultValue={article.vol_pub_year}
                    />
                  </div>}
                  <div className="form-group row">
                    <InputNewBox
                      label="Acronym"
                      onChange={this.onChangeHandler}
                      field="journal_acronym"
                      className="col-sm-4"
                      placeholder="Acronym"
                      defaultValue={article.journal_acronym}
                    />

                    <InputNewBox
                      label="Site"
                      onChange={this.onChangeHandler}
                      field="sub_system_acronym"
                      className="col-sm-4"
                      placeholder="Site"
                      defaultValue={article.sub_system_acronym}
                    />
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-form-label col-sm-2"
                    >
                      pISSN
            </label>
                    <p style={{marginTop:10}} className="col-sm-4"
                    > {article && article.pissn ? article && article.pissn  : "-"}</p>
                    <label
                      className="col-form-label col-sm-2"
                    >
                      eISSN
            </label>
                  <p style={{marginTop:10}}  className="col-sm-4"
                                        > {article && article.eissn ? article && article.eissn  : "-"}</p>

                
                  </div>

                  <div className="form-group row">
                    <InputNewBox
                      label="Publisher Name"
                      onChange={this.onChangeHandler}
                      field="publisher_name"
                      disabled={true}
                      className="col-sm-4"
                      placeholder="Publisher Name"
                      style={is_publisher_name_match === false ? styles.error : {}}
                      defaultValue={article.publisher_name}
                    />

                    <InputNewBox
                      label="Funder"
                      onChange={this.onChangeHandler}
                      field="funder_name"
                      className="col-sm-4"
                      defaultValue={article.funder_name}
                      placeholder="Funder"
                    />
                  </div>

                  <div className="form-group row">
                    <InputNewBox
                      label="Grant Note"
                      onChange={this.onChangeHandler}
                      field="fund_acknowledgement"
                      placeholder="Grant Note"
                      defaultValue={article.fund_acknowledgement}
                    />
                    <InputNewBox
                      label="Grant number"
                      onChange={this.onChangeHandler}
                      field="grant_number"
                      placeholder="Grant number"
                      defaultValue={article.grant_number}
                    />
                  </div>

                  <div className="form-group row">
                    {this.state.submission_date && (
                      <OaDatePicker
                        label="Submission date"
                        className="col-sm-3 col-lg-4"
                        labelClass="col-sm-3 col-lg-2"
                        onChange={this.onChangeHandler}
                        field="submission_date"
                        placeholder="Select submission date"
                        showTime={false}
                        defaultValue={this.state.submission_date}
                      />
                    )}
                    {!this.state.submission_date && (
                      <OaDatePicker
                        label="Submission date"
                        className="col-sm-3 col-lg-4"
                        labelClass="col-sm-3 col-lg-2"
                        onChange={this.onChangeHandler}
                        field="submission_date"
                        placeholder="Select submission date"
                      />
                    )}

                    {this.state.acceptance_date && (
                      <OaDatePicker

                        label="Acceptance date"
                        className="col-sm-3 col-lg-4"
                        labelClass="col-sm-3 col-lg-2"
                        onChange={this.onChangeHandler}
                        field="acceptance_date"
                        placeholder="Select acceptance date"
                        defaultValue={this.state.acceptance_date} />
                    )
                    }
                    {!this.state.acceptance_date && (
                      <OaDatePicker
                        label="Acceptance date"
                        className="col-sm-3 col-lg-4"
                        labelClass="col-sm-3 col-lg-2"
                        onChange={this.onChangeHandler}
                        field="acceptance_date"

                        placeholder="Select acceptance date"
                      />
                    )}
                  </div>

                
                  {/* Co Author Form Modal */}
                  {this.state.isPrices && (
                    <Prices
                      isShow={this.state.isPrices} // if true modal will show up else it will be hidden
                      onClose={this.onClosePrices} // Handle Close
                      onSavePrices={this.onSavePriceHandler}
                      value={this.state.prices}
                      content_type={this.props.content_type}

                    />
                  )}
                  {/* Co Author Form Modal End */}

                  <div className="form-group row" style={{ marginLeft: 100 }}>
                    {this.state.prices && (
                      <Badge
                        label={
                          (this.state.prices && this.state.prices.content_type_name) || "NO Price Name"
                        }
                        onEdit={this.onEditPrices}
                        onDelete={this.onDeletePrice}

                      />
                    )}

                  </div>
                  <div className="form-group row mt-4" style={{ marginLeft: 100 }}>
                    <div className="col-sm-12">
                      <div className="float-left" style={{ marginRight: 30 }}>
                        <h6>Add Co Author</h6>
                      </div>
                      <div className="float-left">
                        <button
                          className="btn btn-light btn-o-light"
                          onClick={this.addNewCoAuthor}
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="mdi mdi-plus"></i> Add Co-Author
                    </button>
                      </div>
                    </div>
                  </div>

                  {/* Co Author Form Modal */}
                  {this.state.isCoAuthor && (
                    <CoAuthorArticle
                      isShow={this.state.isCoAuthor}
                      onClose={this.onCloseAuthor}
                      onSaveAuthor={this.onSaveCoAuthorHander}
                      fieldLabel="Co author"
                      value={this.state.co_authors[this.state.editing]}
                    />
                  )}
                  {/* Co Author Form Modal End */}

                  <div className="form-group row" style={{ marginLeft: 100 }}>
                    {this.state.co_authors.length > 0 &&
                      this.state.co_authors.map((co_author, id) => {
                        return (
                          <div key={id}>
                            <Badge
                              label={
                                !co_author.first_name && !co_author.last_name
                                  ? "No Name"
                                  : (co_author.first_name || "") +
                                  " " +
                                  (co_author.last_name || "")
                              }
                              onEdit={() => this.onEditCoAuthor(id)}
                              onDelete={() => this.onDeleteCoAuthor(id)}
                  
                            />
                          </div>
                        );
                      })}
                  </div>

              

                  <div className="text-center m-t-15">

                    <Button type="secondary" loading={this.props.isUpdateLoading} onClick={this.onSubmitHandler}>
                      {this.props.isUpdateLoading !== true ? "Update Article" : "Processing"}
                    </Button>
                
                  </div>
              </div>
              : <div className="text-center" style={{textAlign:"center"}}><Loading type="flat" /> </div>   }
              </div>
            </div> 
      </div>  

    );
  }
}

const mapStateToProps = (state) => ({
  publisher_name: state.auth.user,
  content_type: state.journals.content_type ,
  publication_type: state.journals.publication_type ,
  article_status: state.articles.article_status,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  isLoading: state.articles.isLoading || false,
  isUpdateLoading: state.articles.isUpdateLoading,
  group: state.auth.user && state.auth.group,
  publishers: state.orgs.publisher_list ,

});

const mapDispatchToProps = (dispatch) => ({
  getPublicatonType: () => dispatch(getPublicatonTypeList()),
  CreateArticle: (payload) => dispatch(CreateArticle(payload)),
  EditArticle: (payload) => dispatch(EditArticle(payload)),
  getSingleArticle: (id) => dispatch(getSingleArticle(id)),
  articleFileUpload: (payload) => dispatch(articleFileUpload(payload)),
  trackArticleFileUploadStatus: (payload) =>
    dispatch(trackArticleFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) =>
    dispatch(trackArticleFileUploadStatusDownload(payload)),
  getJournalTypeList: () => dispatch(getJournalTypeList()),
  getArticleFullSingleList: (payload) => dispatch(getArticleFullSingleList(payload)),
  articleFullUpdate: (id, payload) => dispatch(articleFullUpdate(id, payload)),
  getPublicPublisher:  () => dispatch(getPublicPublisher()),
});

const styles = {
  error: { border: "1px solid red" },
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateArticleForm));
