const vatSelfCountryApiData = (dataList) => {
  let data = [];
  dataList &&
    dataList.map((item) =>
      data.push({
        id: item.id,
        key: item.id && item.id.toString(),
        country_name: item.name ? item.name : item.country_name,
        default_vat_rate: item.default_vat_rate ? item.default_vat_rate : 0,
        vat_rate: item.vat_rate || 0,
      })
    );
  return data;
};
const vatUsaStatesApiData = (dataList) => {
  let data = [];
  dataList &&
    dataList.map((item) =>
      data.push({
        id: item.id,
        state: item.state ? item.state : item.id,
        key:
          item.id && item.id.toString()
            ? item.id && item.id.toString()
            : item.key,
        country: item.parent ? item.parent : item.country,
        country_name: item.country_name ? item.country_name : item.name,
        federal_vat_rate: item.federal_vat_rate || 0,
        states_name: item.name ? item.name : "-",
        name: item.name ? item.name : "-",
        state_provincial_vat_rate: item.state_provincial_vat_rate || 0,
        total_rates: 0,
        vat_rate: item.vat_rate || 0,
      })
    );
  return data;
};

const vatUsaCountryApiData = (dataList) => {
  let data = [];
  dataList &&
    dataList.map((item) =>
      data.push({
        id: item.id,
        country: item.country ? item.country : item.id,
        key:
          item.id && item.id.toString()
            ? item.id && item.id.toString()
            : item.key,
        country_name: item.country_name ? item.country_name : item.name,
        federal_vat_rate: 0,
        states_name: "-",
        state_provincial_vat_rate: 0,
        total_rates: 0,
        vat_rate: item.vat_rate || 0,
      })
    );
  return data;
};

const vatCanadaProviceApiData = (dataList) => {
  let data = [];
  dataList &&
    dataList.map((item) =>
      data.push({
        id: item.id,
        state: item.state ? item.state : item.id,
        key:
          item.id && item.id.toString()
            ? item.id && item.id.toString()
            : item.key,
        country: item.parent ? item.parent : item.country,
        country_name: item.country_name ? item.country_name : item.name,
        federal_vat_rate: item.federal_vat_rate || 0,
        states_name: item.name ? item.name : "-",
        name: item.name ? item.name : "-",
        state_provincial_vat_rate: item.state_provincial_vat_rate || 0,
        total_rates: 0,
        vat_rate: item.vat_rate || 0,
      })
    );
  return data;
};

const vatCanadaCountryApiData = (dataList) => {
  let data = [];
  dataList &&
    dataList.map((item) =>
      data.push({
        id: item.id,
        country: item.country ? item.country : item.id,
        key:
          item.id && item.id.toString()
            ? item.id && item.id.toString()
            : item.key,
        country_name: item.country_name ? item.country_name : item.name,
        federal_vat_rate: 0,
        states_name: "-",
        state_provincial_vat_rate: 0,
        total_rates: 0,
        vat_rate: item.vat_rate || 0,
      })
    );

  return data;
};

const vatEuBillingApiData = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => {
      return Object.assign(item, {
        key: item.id && item.id.toString(),
        country: item.country ? item.country : item.id,
        country_name: item.country_name ? item.country_name : item.name,
        default_vat_rate: item.default_vat_rate || 0,
        vat_rate: item.vat_rate || 0,
      });
    });
  return data;
};

const restVatOrTaxesApiData = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => {
      return Object.assign(item, {
        key:
          item.id && item.id.toString()
            ? item.id && item.id.toString()
            : item.key,
        country: item.id ? item.id : item.country,
        country_name: item.name ? item.name : item.country_name,
        default_vat_rate: item.default_vat_rate || 0,
        vat_rate: item.vat_rate || 0,
      });
    });
  return data;
};
const VatRestBillingApiData = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => {
      return Object.assign(item, {
        id: item.id,
        key: item.id && item.id.toString(),
        country_name: item.name ? item.name : item.country_name,
        default_vat_rate: item.default_vat_rate || 0,
        vat_rate: item.vat_rate || 0,
      });
    });
  return data;
};
const VatWavierBillingApiData = (dataList) => {
  let data = [];
  dataList &&
    dataList.map((item) =>
      data.push({
        id: item.id,
        key: item.id && item.id.toString(),
        country_name: item.name ? item.name : item.country_name,
      })
    );

  return data;
};

//VAT Submitted Data Formated

const vatSubmitSelfCountry = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => ({
      country: item.id,
      state: null,
      federal_vat_rate: null,
      state_provincial_vat_rate: null,
      vat_rate: item.vat_rate,
    }));
  return data;
};

const vatStatesNProvince = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => ({
      country: item.parent ? item.parent : item.country,
      state: item.id,
      federal_vat_rate: item.federal_vat_rate,
      state_provincial_vat_rate: item.state_provincial_vat_rate,
      vat_rate: item.vat_rate,
    }));
  return data;
};

const vatDataCountry = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => ({
      country: item.id ? item.id : item.country,
      state: null,
      federal_vat_rate: null,
      state_provincial_vat_rate: null,
      vat_rate: item.vat_rate,
    }));
  return data;
};

const vatSubmittedBillingAddressData = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => ({
      country: item.id,
      vat_rate: item.vat_rate,
      state: null,
      federal_vat_rate: null,
      state_provincial_vat_rate: null,
    }));
  return data;
};

const VatSubmittedRestBillingApiData = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => ({
      country: item.id,
      state: null,
      vat_rate: item.vat_rate,

      charge_policy: "customer_billing_address",
    }));
  return data;
};

const vatSubmittedCountryVatOrTaxesData = (dataList) => {
  let data =
    dataList &&
    dataList.map((item) => ({
      country: item.id,
      vat_rate: item.vat_rate,
    }));
  return data;
};

const EUStateDataFormat = (
  charge_vat_number_eu,
  datanotvalidboolean,
  chargebilling_address,
  ratesbasedonAddress,
  ratesbasedonAddressnotvat
) => {
  return {
    charge_vat: charge_vat_number_eu,
    charge_no_vat: datanotvalidboolean,
    charge_based_on_states_vat: null,
    charge_based_on_states_no_vat: null,
    country: null,
    charge_policy: chargebilling_address ? chargebilling_address : null,
    rates_vat: ratesbasedonAddress ? ratesbasedonAddress : [],
    rates_no_vat: ratesbasedonAddressnotvat ? ratesbasedonAddressnotvat : [],
  };
};
const canadaStateDataFormat = (
  charge_vat_number_canada,
  charge_no_vat_canada,
  statePositionforca,
  no_charge_vat_province,
  vatprovincerate,
  vatprovincenorate
) => {
  return {
    context: "CA",
    charge_policy: null,
    charge_vat: charge_vat_number_canada,
    charge_no_vat: charge_no_vat_canada,
    charge_based_on_states_vat: statePositionforca,
    charge_based_on_states_no_vat: no_charge_vat_province,
    country: null,
    rates_vat: vatprovincerate ? vatprovincerate : [],
    rates_no_vat: vatprovincenorate ? vatprovincenorate : [],
  };
};
const USAStateDataFormat = (
  charge_vat_number_usa,
  notcharge,
  statePosition,
  charge_no_vat_states,
  vatState,
  vatnostate
) => {
  return {
    context: "US",
    charge_vat: charge_vat_number_usa,
    charge_no_vat: notcharge,
    charge_based_on_states_vat: statePosition,
    charge_based_on_states_no_vat: charge_no_vat_states
      ? charge_no_vat_states
      : null,
    country: null,
    charge_policy: null,
    rates_vat: vatState ? vatState : [],
    rates_no_vat: vatnostate ? vatnostate : [],
  };
};

export {
  vatUsaStatesApiData,
  vatUsaCountryApiData,
  vatSelfCountryApiData,
  vatEuBillingApiData,
  vatCanadaCountryApiData,
  vatCanadaProviceApiData,
  restVatOrTaxesApiData,
  VatRestBillingApiData,
  VatWavierBillingApiData,
  vatStatesNProvince,
  vatDataCountry,
  vatSubmitSelfCountry,
  vatSubmittedBillingAddressData,
  VatSubmittedRestBillingApiData,
  vatSubmittedCountryVatOrTaxesData,
  EUStateDataFormat,
  canadaStateDataFormat,
  USAStateDataFormat,
};
