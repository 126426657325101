import {
    SUCCESS_ARTICLE_CONTROL_CONFIG_LIST,
    FAIELD_ARTICLE_CONTROL_CONFIG_LIST,
    REQUEST_ARTICLE_CONTROL_CONFIG_LIST,
    GET_ARTICLE_CONTROL_CONFIG,
    CREATE_UPDATE_ARTICLE_CONTROL_CONFIG,
    DELETE_ARTICLE_CONTROL_CONFIG,
} from "../../config";

const ArticleControlConfigReducer = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_ARTICLE_CONTROL_CONFIG_LIST:
            return state = {
                ...state,
                isLoading: false,
                article_control_configs: action.payload
            }
        case REQUEST_ARTICLE_CONTROL_CONFIG_LIST:
            return state = {
                ...state,
                isLoading: true,
            }
        case FAIELD_ARTICLE_CONTROL_CONFIG_LIST:
            return state = {
                ...state,
                isLoading: false,
                article_control_configs: action.payload
            }
        case GET_ARTICLE_CONTROL_CONFIG:
            return state = {
                ...state,
                article_control_config: action.payload
            }
        case CREATE_UPDATE_ARTICLE_CONTROL_CONFIG:
            return state = {
                ...state,
                article_control_config: action.payload
            }
        case DELETE_ARTICLE_CONTROL_CONFIG:
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default ArticleControlConfigReducer;
