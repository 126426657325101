import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { getQuicksightLogList, } from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";

class UL extends BaseComponent {
  state = {
    data: [],
  };
  getQuicksightLogListData = async (pageNum = 1, page = 10) => {
    let qsLogs = await getQuicksightLogList({ pageNum: pageNum, pageSize: page });

    this.setState({
      data: qsLogs.results,
      count: qsLogs.count,
    });
  };



  componentDidMount() {
    this.getQuicksightLogListData();
  }

  pageChange = (pageNumber) => {
    this.getQuicksightLogListData(pageNumber);
  };

  onDeleteDashboard = (id) => {
    confirmAlert({
      title: "",
      message: "Please confirm to delete",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteQsDashboard(id).then((res) => {
              if (res.status) {
                this.props.history.push("/logs/quicksight-log-list");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const heading = ["date_created", "user_name", "organisation_name", "dashboard_name", "stacktrace", "execution_time"];

    return (
      <HubContent title="Quicksight log list">
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
});

export const QuicksightLogList = connect(mapStateToProps, mapDispatchToProps)(UL);
