import React from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import moment from "moment";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  
  getPublisherArticleFullList,
  articleDownload,
  getPublicationFiltering,
  getArticleOfferDetails,
  getArticleFullSingleList,
  sendFreeTextEmail,
  getApcArticleList,
  getInstitutionsList
} from "../../../../actions";
import {
  PopupBox,
  Loading,
  SelectBox,
  InputGroup,
  ModalCustom,
  Button,
} from "../../../../../core/components/common";
import SelectBoxName from "../../../../../core/components/common/SelectBoxName.jsx";
import { UnivRoot } from "../../../../data";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";
import { filterStatus, filterDays } from "../components/ArticleFull/FilterParameter";
import { ArticleFullSingle } from "../components/ArticleFull/index";
import { AdHocEmailForm } from "../../../partials";
import { isArray } from "lodash";

class Article extends BaseComponent {

  state = {
    page: 1,
    pageSize: 10,
    msg: false,
    author_apc_request: false,
    author_apc_no_oa_request: false,
    filterParams: { page: 1, article_stage: 'POST_ACCEPTANCE', },
    params: null,
    article_id: null,
    showLicenceUpdateNotification: false,
    publisher_list:[],
    show: 0
  };
  articleHandleClick = (index) => {
      if (this.state.show === index) {
        this.setState({
          show: null,
        });
      } else {
        this.setState({
          show: index,
        });
      }
    };

  componentDidMount() {
    if (this.props.location && this.props.location.filter && this.props.location.filter.page) {
      this.pageChange(this.props.location && this.props.location.filter && this.props.location.filter.page)
    }
    this.apiDataList()
  }
  isArrayEmpty = (value) => {
    return Array.isArray(value) ? value : []
}
  apiDataList = async () => {

     this.props.getPublicationFiltering();
   
    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;

    let params = {
      institution_id: this.props.institute,
    };
    if (article_filter_id) {
      params["id"] = article_filter_id;
    }
  
      this.setState((prevState) => {
        if (this.props.institute) {
          prevState.filterParams = Object.assign(prevState.filterParams, params);
        } else {
          if (article_filter_id) {
            prevState.filterParams = Object.assign(prevState.filterParams, {
              id: article_filter_id,
            });
          }
        }
      });
    

    let filter = this.props.location.filter ? serialize(this.props.location.filter) : serialize(this.state.filterParams);


    if (this.props.location.apc_article_id) {
      this.props.getApcArticleList(this.props.location.apc_article_id)
      this.props.getArticleFullSingleList(this.props.location.apc_article_id)
    } else {
      await this.props.ArticleFull({ filter });
    }
    await this.props.getInstitutionsList()
    this.setState({
      publisher_list: [{id: "all", name: "All Publishers"}].concat(this.isArrayEmpty(this.props.publisher_list)) 
    })
    let articles = this.props.articles && this.props.articles.map(item => item.id);
    if (articles && articles.length > 0) {
      let articleOffer = await this.props.getArticleOfferDetails(articles.join())
  
        this.setState({
          articleOffer
        })

    }
    if (this.props.articles) {
      this.licenceUpdateNotification();
    }
  
  }


  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  licenceUpdateNotification = () => {
    let articles = this.props.articles;

    if (articles) {
      for (let article of articles) {
        if (
          article.status === "approved" &&
          (!article.selected_licence || !article.selected_licence.id)
        ) {
          this.setState({
            showLicenceUpdateNotification: true,
          });
        }

        if (this.state.showLicenceUpdateNotification) {
          break;
        }
      }
    }
  };

  filterData = async (value) => {
    value.page = 1;

    if (value.hasOwnProperty("q")) {
      this.setState({
        ...value,
      });
    }


    if (value.publication_type === "All Publication Types") {
      value.publication_type = "all";
    }


    await this.setState((prevState) => {
      if (value.institution_id === "all") {
        value.filter_publisher = "all";
      }
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });


    if (this.state.filterParams.institution_id === "all") {
      delete this.state.filterParams.institution_id;
    }
    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.ArticleFull({ params, filter });
    let articles = this.props.articles && this.props.articles.map(item => item.id);
    if (articles && articles.length > 0) {
      let articleOffer = await this.props.getArticleOfferDetails(articles.join())
      this.setState({
        articleOffer
      })
    }
  };

  onActionHandler = async (action, id) => {
    try {
      const article = await this.props.article(id);
      switch (action.toLowerCase()) {
        case "reject":
          history.push(UnivRoot.article_decline + "/" + id);
          break;
          case "article_payment":
            this.props.history.push(`/payment/addpayment/${id}`, {
                name: action
            });
            break;
        case "article_invoice":
            this.props.history.push(`/payment/addpayment/${id}`, {
                name: action
            });
            break;
        case "author_apc_fund_request":
          this.setState({
            author_apc_request: true,
            author_apc_id: id,
            author_id: article && article.author && article.author.id,
          });
          break;
        case "action_audit":
          this.props.history.push({
            pathname: `/audit/audit-trail/${id}`,
            filter: this.state.filterParams,
            name: action,
            id:id,
          });
          break;
        case "author_non_oa_request":
          this.setState({
            author_apc_no_oa_request: true,
            author_apc_id: id,
            author_id: article && article.author && article.author.id,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      this.log("error: ", error);
    }
  };

  pageChange = async (pageNumber) => {
    let value = {
      page: pageNumber,
    };

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);

    await this.props.ArticleFull({ params, filter });
    let articles = this.props.articles && this.props.articles.map(item => item.id);
    if (articles && articles.length > 0) {
      let articleOffer = await this.props.getArticleOfferDetails(articles.join())
      this.setState({
        articleOffer
      })
    }
    if (this.props.group === "AUTHOR" && this.props.articles) {
      this.licenceUpdateNotification();
    }
  };

  onTabChangeHandler = async (articleStage = 'PRE_ACCEPTANCE') => {
    if (articleStage !== 'PRE_ACCEPTANCE') {
      articleStage = 'POST_ACCEPTANCE';
    }

    let params = {
      article_stage: articleStage,
    };

    this.filterData(params);
  }
  onArticleDownloadClick = async () => {
    try {
      let filter = await serialize(this.state.filterParams);
      await this.props.articleDownload({ params: 999999, filter });
      const url = window.URL.createObjectURL(this.props.article_download_data);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };


  popupSuccessCallback = async () => {
    this.onCancel();

    let filter = await serialize(this.state.filterParams);


    await this.props.ArticleFull({ filter });
    let articles = this.props.articles && this.props.articles.map(item => item.id);
    if (articles && articles.length > 0) {
      let articleOffer = await this.props.getArticleOfferDetails(articles.join())
      this.setState({
        articleOffer
      })
    }
  };

  onCancel = () => {
    this.setState({
      author_apc_request: false,
      author_apc_no_oa_request: false,
      // article_id: null,
      showLicenceUpdateNotification: false,
      isEmpty: false,
      successMsg: false,
    });
  };

  hasAccessToTabs = () => {
    let { group } = this.props;
    let groupList = ['HUB', 'PUBLISHER'];

    return groupList.includes(group);
  }

  onCustomEmailButtonClick = async (articleId) => {
    if (!articleId) {
      return null;
    }

    await this.props.getArticleFullSingleList(articleId);
    let articleData = this.props.article_single;
    let subject = '';
    let message_body = '';

    if (articleData.article_id) {
      subject = `Article ID: ${articleData.article_id},`;
      message_body = `Article ID: ${articleData.article_id}`;
    }

    if (articleData.title) {
      subject = `${subject} Article Title: ${articleData.title}`;
      message_body = `${message_body} <br/>Article Title: ${articleData.title}`;
    }

    if (articleData.publication && articleData.publication.name) {
      subject = `${subject}, Publication Name: ${articleData.publication.name}`;
      message_body = `${message_body} <br/>Publication Name: ${articleData.publication.name}<br/>`;
    }

    subject = subject.trim();
    message_body = message_body.trim();

    this.setState({
      showCustomEmailForm: true,
      article_id: articleData.id,
      message_body,
      subject,
    });

  }

  onCloseHandler = () => {
    this.setState({
      showCustomEmailForm: false,
      article_id: '',
    });
  }

  onChangeHandler = (value) => {
    this.setState({
      ...value,
    });
  }

  onSubmitHandler = (e) => {

    let data = {
      article_id: this.state.article_id,
      organisation: this.state.organisation,
      subject: this.state.subject,
      recipients: this.state.recipients,
      message_body: this.state.message_body,
    };

    if (!data.article_id || !data.subject || !data.recipients || (isArray(data.recipients) && data.recipients.length === 0) || !data.message_body) {
      this.setState({
        isEmpty: <div>
          {!data.article_id && <p>Article is required.</p>}
          {(!data.recipients || (isArray(data.recipients) && data.recipients.length === 0)) && <p>Recipient is required.</p>}
          {!data.subject && <p>Subject is required.</p>}
          {!data.message_body && <p>Message is required.</p>}
        </div>
      })
    } else {
      this.setState({
        emailLoading: true,
      });

      this.props.sendFreeTextEmail(data).then(response => {
        if (response.data && response.data.success) {
          this.setState({
            emailLoading: false,
            showCustomEmailForm: false,
            successMsg: response.data.message,
            article_id: '',
            organisation: '',
            subject: '',
            recipients: '',
            message_body: '',
          });
        }
      }).catch(error => {
        this.setState({
          emailLoading: false,
        });

        return error.response && error.response.data && error.response.data.success;
      });
    }
  };

  onViewArticle = async () => {
    await this.props.ArticleFull();

    let articles = this.props.articles && this.props.articles.map(item => item.id);
    if (articles && articles.length > 0) {
      let articleOffer = await this.props.getArticleOfferDetails(articles.join())
      this.setState({
        articleOffer
      })
    }
    if (this.props.articles) {
      this.licenceUpdateNotification();
    }
  }

  render() {

    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;
    let { articles, auth } = this.props;
    let { showCustomEmailForm } = this.state;
    let ErrorHandler = true;
    let AuthorApcRequest = true;
    let AuthorApcNoOARequest = true;

    let articleTabs = (auth && auth.article_tabs) || [];

    let publicationTypes = [
      {
        name: "All Publication Types",
        id: "all",
      },
    ];


    if (articles) {
      if (articles.status === 500) {
        ErrorHandler = (
          <PopupBox
            title="Network Error"
            msg="Please check your internet connection. If you think internet connection is ok then feel free to contact us"
          />
        );
      } else if (articles.status === 404) {
        ErrorHandler = (
          <PopupBox
            title="Data Not Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else if (articles.status) {
        ErrorHandler = (
          <PopupBox
            title="Unknown Error Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else {
        ErrorHandler = false;
      }
    }
    if (this.state.author_apc_request) {
      AuthorApcRequest = (
        <PopupBox
          helpmenu={true}
          title="Author APC Request"
          className="col-sm-8 col-md-9"
          apcForm={true}
          successCallback={this.popupSuccessCallback}
          onCancel={this.onCancel}
          NoText="Cancel"
          article_id={this.state.author_apc_id}
        />
      );
    }

    if (this.state.author_apc_no_oa_request) {
      AuthorApcNoOARequest = (
        <PopupBox
          title="Non OA Request"
          className="col-sm-8 col-md-9"
          noOAForm={true}
          successCallback={this.popupSuccessCallback}
          onCancel={this.onCancel}
          NoText="Cancel"
          article_id={this.state.author_apc_id}
        />
      );
    }

    let successErrMsgPop = <>
      {this.state.isEmpty && <PopupBox
        Title="Following field cannot be empty"
        msg={this.state.isEmpty}
        onCancel={this.onCancel}
      />}

      {this.state.successMsg && <PopupBox
        title="Success!"
        msg={this.state.successMsg}
        onCancel={this.onCancel}
      />}
    </>;
    return (
      <HubContent
        title={this.props.title || ""}
        className={this.props.className}
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
        group={this.props.group}
      >

        { successErrMsgPop}

        {ErrorHandler}
        {AuthorApcRequest}
        {AuthorApcNoOARequest}

        {this.state.msg && (
          <PopupBox
            title="No Oa deal found"
            msg={this.state.msg}
            onCancel={() => this.setState({ msg: false })}
          />
        )}

        {showCustomEmailForm && <ModalCustom
          title="Send Ad Hoc Email"
          onClose={this.onCloseHandler}
          modalClass="col-sm-12 col-md-12 col-lg-9"
          isShow={showCustomEmailForm}
          submitBtnTxt="Send"
          onSubmit={this.onSubmitHandler}
        >

          <AdHocEmailForm
            recipients={this.state.recipients}
            emailLoading={this.state.emailLoading}
            article={this.props.article_single}
            subject={this.state.subject}
            message_body={this.state.message_body}
            article_id={this.state.article_id}
            onChangeHandler={this.onChangeHandler}
          />

        </ModalCustom>}

        <div className="row mb-3">
          <SelectBox
            className="col-md-3 mb-2"
            field="filter_status"
            defaultValue="all"
            data={filterStatus}
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_days"
            defaultValue=""
            data={filterDays}
            onChange={this.filterData}
          />

          <SelectBoxName
            className="col-md-3 mb-2"
            field="publication_type"
            defaultValue="All Publication types"
            data={
              this.props.publication_types
                ? publicationTypes.concat(this.isArrayEmpty(this.props.publication_types))
                : []
              
              
            }
            onChange={this.filterData}
          />

        
                      <SelectBox
                        className="col-md-3 mb-2"
                        field="institution_id"
                        defaultValue="All Publishers"
                        data={
                            this.state.publisher_list
                            
                        }
                        onChange={this.filterData}
                    />


          <InputGroup
            onClick={this.filterData}
            field="q"
            placeholder="Search"
            className="col-md-3 mb-2 free-text-search"
          />
          <div className="col-md-9 mb-2">
            <p style={{ textAlign: "center", background: "#fff", padding: 10, color: "#007bff" }}> --&gt;  Click on the arrow to view APC fund request, generate Invoice, email to publisher or your institution etc.</p>
          </div>

          {
            this.props.location.apc_article_id && (
              <div className="col-md-12 apc-btn-list" style={{ textAlign: "right" }}>
                <Button type="secondary" name="secondary" text="View All APC Requests" className="view-articles" size={200} onClick={() => this.props.history.push("/author-request")} />

                <Button type="secondary" name="primary" text="View All Articles" className="view-articles" size={200} onClick={this.onViewArticle} />
              </div>
            )
          }

          {this.hasAccessToTabs() && <div className="col-md-12">
            <ul
              className="nav nav-pills article-stage-tabs"
              role="tablist"
            >
              <li
                title={!articleTabs.includes('PRE_ACCEPTANCE') ? `You don't have access to this tab. Please contact with administrator.` : ''}
                className={`nav-item waves-effect waves-light ${!articleTabs.includes('PRE_ACCEPTANCE') && 'disabled-tab'}`}
              >
                <a
                  className={`nav-link`}
                  data-toggle="tab"
                  href="#pre-acceptance"
                  role="tab"
                  onClick={() => articleTabs.includes('PRE_ACCEPTANCE') ? this.onTabChangeHandler() : null}
                  disabled={!articleTabs.includes('PRE_ACCEPTANCE')}
                >
                  Pre-acceptance
                </a>
              </li>
              <li className="nav-item waves-effect waves-light">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#pre-acceptance"
                  role="tab"
                  onClick={() => this.onTabChangeHandler('POST_ACCEPTANCE')}
                >
                  {`Accepted & Post-acceptance`}
                </a>
              </li>
            </ul>
          </div>}

        </div>

        <div className="tab-content">
          <div className="tab-pane active" id="pre-acceptance" role="tabpanel">
            <div className="row approved-btn">
              <div className="col-lg-12">
                <div id="accordion" className="article-cards">
                  {/*START OF LOOP*/}
                  {!ErrorHandler && this.props.isArticleloading !== true ? (

                    <>
                      { articles && articles.map((article, i) => {
                        return (
                            <ArticleFullSingle
                              location={this.props.location}
                              offerLoading={this.props.article_offer_detail_loading}
                              articleOffer={this.state.articleOffer && this.state.articleOffer[article.id]}
                              key={article.id}
                              search_text={this.state.q}
                              article={article}
                              articleHandleClick={this.articleHandleClick}
                              url_article_id={article_filter_id}
                              user={this.props.auth.user.username}
                              isAction={false}
                              index={article.id}
                              show={this.state.show}
                              onActionHandler={(action) =>
                                this.onActionHandler(action, article.id)
                              }
                              onCustomEmailButtonClick={this.onCustomEmailButtonClick}
                            />
                        );
                      })}
                      {this.props.count > 0 ?
                        <Pagination
                          defaultCurrent={this.state.filterParams.page}
                          style={{
                            marginTop: 10,
                            marginBottom: 20,
                            textAlign: "center",
                          }}
                          showSizeChanger={ false}
                          onChange={this.pageChange}
                          pageSize={this.state.pageSize || 10}
                          total={this.props.count || undefined}
                        /> : <div className="card">
                          <div className="card-body">
                            <h6>No Data Found</h6>
                          </div>
                        </div>
                      }

                    </>)
                    :
                    <Loading type="flat" />
                  }




                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modal for help menu  */}

      </HubContent>
    );
  }
}

const mapStateToProps = (state, props) => ({
  articles:(  state.articles && state.articles.article_full )|| [],
  count: state.articles.count,
  article: (id) => {
    let article = state.articles.article_full;
    if (article) {
      return article.find((article) => {
        return id.toString() === article.id.toString() && article;
      });
    } else {
      return false;
    }
  },
  publication_types: state.institutions.publication_types,
  article_download_data: state.articles.download_data,
  group: (state.auth && state.auth.group),
  auth: state.auth,

  publisher_list: (state.institutions && state.institutions.institution_list) || [],
  article_offer_detail_loading: state.articleOfferDetails.article_detail_loading,
  article_single: state.articles.article_single,
  isArticleloading: state.articles.isArticleloading
});

const mapDispatchToProps = (dispatch, props) => ({

  ArticleFull: (payload = {}) => dispatch(getPublisherArticleFullList(payload)),
  articleDownload: (payload = {}) => dispatch(articleDownload(payload)),
  getPublicationFiltering: () => dispatch(getPublicationFiltering()),
  getArticleOfferDetails: (id) => dispatch(getArticleOfferDetails(id)),
  getArticleFullSingleList: (payload) => dispatch(getArticleFullSingleList(payload)),
  sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
  getApcArticleList: (id) => dispatch(getApcArticleList(id)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
});

export const ArticleFull = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Article)
);