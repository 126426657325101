import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
  
} from "../../../../../core/components";
import {
  Button,
  Loading,PopupBox
} from "../../../../../core/components/common";
import {
  getArticleControlConfigList,
  deleteArticleControlConfig,
} from "../../../../actions";
import { Link } from "react-router-dom";
import { Popover } from "antd";


const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Article notification </a>
    <br />

  </div>
);
class ArticleControlConfigForm extends BaseComponent {
  state = {};
  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    this.getArticleControlConfigListData();
  }
  getArticleControlConfigListData = (pageNum = 1, page = 10) => {
    this.props.getArticleControlConfigList({
      pageNum: pageNum,
      pageSize: page,
    }).then(res => {
      if (this._isMounted) {
        this.setState({
          data: res && res.results,
          count: res && res.count,
        });
      }
    });

  };
  componentWillUnmount() {
    this._isMounted = false

  }


  pageChange = (pageNumber) => {
    this.getArticleControlConfigListData(pageNumber);
  };

  onEditArticleControlConfig = (id, data) => {
    this.props.history.push("/article-control-configs/update-author-notification/" + id);
  };

  onViewArticleControlConfig = (id, data) => {
    this.props.history.push("/article-control-configs/article-author-notification/" + id);
  };

  onDeleteHandler = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        id,
      });
    }
  };

  ondeleteArticleControlConfig = async () => {
    const id = this.state.id;
    this.setState({
      waitingMsg: "Deleting...",
    });
    const deleteArticle = await this.props.deleteArticleControlConfig(id);
    this.setState({
      waitingMsg: null,
      id: null,
      deleteConfirm: false,
      deleteStatus: deleteArticle.status,
    });
    this.getArticleControlConfigListData();
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      id: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

  processListData = (items) => {
    return (
      (items &&
        items
          .map((item) => {
            return item.name;
          })
          .join(", ")) ||
      false
    );
  };

  render() {
    let heading = [];

    if (this.props.group && this.props.group === "HUB") {
      heading = [
        "organisation",
        "institution",
        "publication_type",
        "publication",
        "content_type",
      ];
    } else {
      heading = [
        "institution",
        "publication_type",
        "publication",
        "content_type",
      ];
    }

    const data = [];
    this.state.data &&
      this.state.data.map((item) => {
        return data.push({
          ...item,
          institution:
            this.processListData(item.institution_details) ||
            "All institutions",
          publication_type:
            this.processListData(item.publication_type_details) ||
            "All publication types",
          publication:
            this.processListData(item.publication_details) ||
            "All publications",
          content_type:
            this.processListData(item.content_type_details) ||
            "All content types",
        });
      });

    return (
      <HubContent title="Author Notification & License Req Setup">

        <div className="vat-tax-button email d-flex justify-content-end">
          <Link
            to="/article-control-configs/create-author-notification"
            className="btn btn-primary float-right mb-2"
            style={{ marginRight: 10 }}
          >
            Add Author Notification
            </Link>

          <Popover placement="leftTop" content={content} trigger="click">
            <Button
              className="btn btn-secondary float-right mb-2"
              type=""
              name=""
              text="Help"
            >Help</Button>
          </Popover>
          <div className="danger float-right"
          >
            <button
              type="danger"
              className={`btn btn-danger btn- sm" waves-effect`}
              onClick={() => this.props.history.push("/settings")}
              style={{ marginLeft: 10 }}
            >
              X

            </button>

          </div>

        </div>
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            btnSize="200px"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.ondeleteArticleControlConfig}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        {this.props.isLoading !== true ? <div className="row">
          <div className="col-12">
            <div className="card m-b-20 pub-apc-waiver-config">
              {this.state.data && (
                <DataTable
                  heading={heading}
                  data={data}
                  onEdit={this.onEditArticleControlConfig}
                  onDelete={this.onDeleteHandler}
                  onView={this.onViewArticleControlConfig}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}

            </div>
          </div>
        </div> : <Loading type="flat" />}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: (state.auth && state.auth.group) || false,
  article_control_configs:
    state.articleControlConfig.article_control_configs || [],
  isLoading:
    state.articleControlConfig.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getArticleControlConfigList: (payload) =>
    dispatch(getArticleControlConfigList(payload)),
  deleteArticleControlConfig: (id) => dispatch(deleteArticleControlConfig(id)),
});

export const ArticleControlConfigList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleControlConfigForm);
