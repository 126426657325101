import React from "react";
import { withRouter } from "react-router-dom";
import { Tooltip } from 'antd';
import { FileExclamationFilled, FileDoneOutlined } from "@ant-design/icons";

class ActionButton extends React.Component {
    render() {

        let { text, color } = this.props;
        let name = "primary";
        let data = color ? color : text;
        switch (data && data.toLowerCase()) {
            case "highlight_action_approve": {
                name = "primary";
                break;
            }
            case "highlight_no_action_approved": {
                name = "green";
                break;
            }

            case "highlight_no_action_pending": {
                name = "primary";
                break;
            }
            case "highlight_action_pay": {
                name = "indigo";
                break;
            }
            case "highlight_action_raise_invoice": {
                name = "pink";
                break;
            }
            case "highlight_no_action_invoiced": {
                name = "orange";
                break;
            }
            case "highlight_action_published": {
                name = "green";
                break;
              }
                
            case "approve": {
                name = "primary";
                break;
            }
            case "approved": {
                name = "success";
                break;
            }
            case "pending": {
                name = "primary";
                break;
            }
            case "pay": {
                name = "default";
                break;
            }
            default: {
                name = "primary";
                break;
            }
        }

        return (
            <ul className={`action-show-button ${name}`}>
                <Tooltip placement="topLeft" title={ 
                    this.props.payment_text !== null
                    ? this.props.payment_text
                    : "Click on the arrow to see more actions." 
                } arrowPointAtCenter>
                    
                    
                    
                {this.props.payment_text !== null ? (
                    <div className="pad-unpaid">
                      <li
                    
                      >
                        {this.props.text}{" "}
                      </li>
        
                      <div className="icon-payment">
                        {
                          this.props.payment_text === "Unpaid" ?
                          <FileExclamationFilled
                            style={{ fontSize: "15px", color: "orange" }}
                          />: <FileDoneOutlined
                          style={{ fontSize: "15px", color: "green" }}
                        />
               }
        
                      </div>
                    </div>
                  ) : (
                    <li>{this.props.text} </li>  
                  )}
                    
                    
                     </Tooltip>
            </ul>


        );
    }
}

export default withRouter(ActionButton)

