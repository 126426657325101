import React from "react";
import { Link } from "react-router-dom";

class HubSetting extends React.Component {
  render() {
    return (
      <div className="row">
                <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/user/user-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-tooltip-edit"></i>
              </div>
              <div className="setting-info">
                <h4>Users</h4>
                <p>View and update your Users details</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to="/settings/dashboard-control-list">
              <div className="setting-icon">
                <i className="mdi mdi-table-edit"></i>
              </div>
              <div className="setting-info">
                <h4>Create Dashboard Control</h4>
                <p>View and update your Dashboard Control.</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/settings/add-article-config`}>
              <div className="setting-icon">
              <i className="mdi mdi-checkbox-marked-circle-outline"></i>
              </div>
              <div className="setting-info">
              <h4>Article Metadata Transfer </h4>
                              <p>
                                Configure to fetch article metadata from
                                submission system
                              </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/quicksight-dashboards/dashboard-list`}>
              <div className="setting-icon">
              <i className="mdi mdi-key-variant"></i>
              </div>
              <div className="setting-info">
              <h4>Quicksight dashboard</h4>
                              <p>
                                Configure to quicksight Dashboard
                              </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/article-tab-configs/list`}>
              <div className="setting-icon">
                <i className="mdi mdi-block-helper"></i>
              </div>
              <div className="setting-info">
                <h4>Article Pre & Post Acceptance</h4>
                <p>View and update Article Pre & Post Acceptance tab access.</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/settings/article-action`}>
              <div className="setting-icon">
              <i className="mdi mdi-settings-box"></i>
              </div>
              <div className="setting-info">
              <h4>Button label config</h4>
                                <p>
                                  View and update your button label
                                  configuartion
                                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/vat/vat-list`}>
              <div className="setting-icon">
              <i className="mdi mdi-open-in-app"></i>
              </div>
              <div className="setting-info">
                <h4>Vat Configuration</h4>
                <p>View and update your VAT Configuration</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/invoice-templates/list`}>
              <div className="setting-icon">
              <i className="mdi mdi-clipboard-text"></i>
              </div>
              <div className="setting-info">
                <h4>Setup Invoice</h4>
                <p>View and update your Invoice template</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/settings/apc-config-list`}>
              <div className="setting-icon">
              <i className="mdi mdi-water-percent"></i>
              </div>
              <div className="setting-info">
                <h4>Zero APC Configuration</h4>
                <p>View and update your Apc Waiver Config</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/disciplines/discipline-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-attachment"></i>
              </div>
              <div className="setting-info">
                <h4>OA Disciplines</h4>
                <p>View and update your OA Disciplines</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/society-members/society-member-list`}>
              <div className="setting-icon">
              <i className="mdi mdi-target"></i>
              </div>
              <div className="setting-info">
                <h4>Society Member </h4>
                <p>View and update your Society details.</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/subjects/subject-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-clipboard-check"></i>
              </div>
              <div className="setting-info">
                <h4>OA Subjects</h4>
                <p>View and your Subjects </p>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/email/addPublishers`}>
              <div className="setting-icon">
              <i className="mdi mdi-email"></i>
              </div>
              <div className="setting-info">
              <h4>Email Configuration</h4>
                                    <p>Create & manage emails</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/country/country-list`}>
              <div className="setting-icon">
                <i className="mdi  mdi-xbox-controller"></i>
              </div>
              <div className="setting-info">
                <h4>Setup Country </h4>
                <p>View and update your Country details</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/content-type/content-type-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-content-save-all"></i>
              </div>
              <div className="setting-info">
                <h4>Content Type</h4>
                <p>View and update your Content Type details</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/org-deal-features/list`}>
              <div className="setting-icon">
                <i className="mdi mdi-application"></i>
              </div>
              <div className="setting-info">
                <h4>Setup Deal Features</h4>
                <p>View and setup Deal Features for org</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/affiliation/faulty-author-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-grease-pencil"></i>
              </div>
              <div className="setting-info">
                <h4>Affiliation Missing</h4>
                <p>View and update your Faulty Author details</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/logs/quicksight-log-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-login"></i>
              </div>
              <div className="setting-info">
                <h4>Quicksight Logs</h4>
                <p>View your Logs details</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/oa-policy/oa-policy-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-application"></i>
              </div>
              <div className="setting-info">
                <h4>Oa Policy</h4>
                <p>View and update your Oa Policy details</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/program/program-list`}>
              <div className="setting-icon">
                <i className="mdi mdi-application"></i>
              </div>
              <div className="setting-info">
                <h4>Add Programs</h4>
                <p>View and update your Programs details</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-setting">
            <Link to={`/licence-pricing-configs/list`}>
              <div className="setting-icon">
                <i className="mdi mdi-application"></i>
              </div>
              <div className="setting-info">
                <h4>Licence Pricing Configuration</h4>
                <p>Add and update licence select option for publisher</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default HubSetting;
