import React, { Component } from "react";
import ViewVatTable from "../viewVatTable";

export default class EUView extends Component {
  state = {};

  render() {
    const {
      viewTable,
      charge_vat_number_eu,
      charge_vat_billing_address_eu_1,
      charge_no_vat_number_eu_1,
      charge_no_vat_billing_address_eu_2,
      charge_no_vat_number_eu_2,
      charge_no_vat_billing_address_eu_3,
      basedoncharge_1,

      basedoncharge_2,
      vat_ratesforeu,
      basedoncharge_3,
      vatselfForEucountrynorates,
    } = this.props;

    return (
      <div>
        <div className="vat_eu">
 
          <div className="vat-question-panel">
            <p>
              You charge VAT to EU customers if they provide you a valid VAT
              number :{" "}
              {charge_vat_number_eu === true ? (
                <span className="vat_text">Yes</span>
              ) : (
                <span className="vat_text">No</span>
              )}
            </p>
          </div>

          {charge_vat_number_eu === true && (
            <div className="vat-question-panel">
              <p>
                You charge VAT :
                {charge_vat_billing_address_eu_1 === true ? (
                  <span className="vat_text">
                    Based on customer's billing address
                  </span>
                ) : (
                  <span className="vat_text">
                    Based on your country of location
                  </span>
                )}{" "}
              </p>
            </div>
          )}
          {charge_vat_number_eu !== false ? (
            charge_vat_billing_address_eu_1 === true ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <ViewVatTable data={basedoncharge_1} viewTable={viewTable} />
                </div>
              </div>
            ) : null
          ) : null}
          {charge_vat_number_eu !== false ? (
            charge_vat_billing_address_eu_1 === false ? (
              <div className="vat-country">
                <div className="vat-question-panel">
                  <div className="col-12">
                    <div className="card m-b-20">
                      <ViewVatTable data={vat_ratesforeu} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}

          {charge_vat_number_eu !== false ? (
            charge_vat_billing_address_eu_1 === true ||
            charge_vat_billing_address_eu_1 === false ? (
              <div className="vat-question-panel">
                <p>
                  You charge VAT to EU customers if they do not provide you a
                  valid VAT number :
                  {charge_no_vat_number_eu_1 === true ? (
                    <span className="vat_text"> Yes </span>
                  ) : (
                    <span className="vat_text"> No </span>
                  )}{" "}
                </p>
              </div>
            ) : null
          ) : null}

          {charge_vat_number_eu === true ? (
            charge_no_vat_number_eu_1 === true ? (
              <div className="vat-question-panel">
                <p>
                  You charge VAT:
                  {charge_no_vat_billing_address_eu_2 === true ? (
                    <span className="vat_text">
                      {" "}
                      Based on customer's billing address
                    </span>
                  ) : (
                    <span className="vat_text">No</span>
                  )}{" "}
                </p>
              </div>
            ) : null
          ) : null}
          {charge_vat_number_eu !== false ? (
            charge_no_vat_number_eu_1 !== false ? (
              charge_no_vat_billing_address_eu_2 === true ? (
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable
                      data={basedoncharge_2}
                      viewTable={viewTable}
                    />
                  </div>
                </div>
              ) : null
            ) : null
          ) : null}

          {charge_vat_number_eu !== false ? (
            charge_no_vat_number_eu_1 !== false ? (
              charge_no_vat_billing_address_eu_2 === false ? (
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable data={vatselfForEucountrynorates} />
                  </div>
                </div>
              ) : null
            ) : null
          ) : null}

          {charge_vat_number_eu === false && (
            <div>
              <div className="vat-question-panel">
                <p>
                  You charge VAT to EU customers if they do not provide you a
                  valid VAT number :
                  {charge_no_vat_number_eu_2 === true ? (
                    <span className="vat_text">Yes</span>
                  ) : (
                    <span className="vat_text">No</span>
                  )}{" "}
                </p>
              </div>
              {charge_no_vat_number_eu_2 === true ? (
                <div className="vat-question-panel">
                  <p>
                    You charge VAT:
                    {charge_no_vat_billing_address_eu_3 === true ? (
                      <span className="vat_text">
                        {" "}
                        Based on customer's billing address
                      </span>
                    ) : (
                      <span className="vat_text">
                        {" "}
                        Based on your country of location
                      </span>
                    )}{" "}
                  </p>
                </div>
              ) : null}

              {charge_no_vat_number_eu_2 === true
                ? charge_no_vat_billing_address_eu_3 === true && (
                    <div className="vat-country">
                      <div className="vat-question-panel">
                        <div className="col-12">
                          <div className="card m-b-20">
                            <ViewVatTable
                              data={basedoncharge_3}
                              viewTable={viewTable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                : null}
              {charge_no_vat_number_eu_2 === true
                ? charge_no_vat_billing_address_eu_3 === false && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable data={vatselfForEucountrynorates} />
                      </div>
                    </div>
                  )
                : null}
            </div>
          )}
        </div>
      </div>
    );
  }
}
