import React from 'react'

const ArticleAuthorInfo = (props) => {
    let co_author = props && props.co_authors
    let author = props && props.author
    return (
        <div>
            <div className="row" style={{ marginTop: 5, marginLeft: 15 }}>
                <div className="author-icon"><i className="mdi mdi-account-circle m-r-5"></i></div>
                <div className="author-content">
                    <p><strong>Corresponding author:</strong>{`${author && author.name},`} {author && author.affiliation}</p>
                    <p>{`${author && author.email},`} <strong>ORCID ID:</strong> {author && author.orcid_id}</p>

                </div>
            </div>
            {co_author && co_author.map(item => (
                <div className="row" key={item.id} style={{ marginTop: 10, marginLeft: 15 }}>
                    <div className="author-icon"><i className="mdi mdi-account-circle m-r-5"></i></div>
                    <div className="author-content">
                        <p><strong>Co author:</strong>{`${item.name},`}  {item.affiliation}</p>
                        <p>{`${item.email},`} <strong>ORCID ID:</strong> {item.orcid_id}</p>

                    </div>
                </div>
            ))}
        </div>
    )
}

export const AuthorInfo = ArticleAuthorInfo
