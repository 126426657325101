import React from "react";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getPublicPublisher,
  createUpdateArticleTabControlConfig,
} from "../../../../actions";
import {
  PopupBox,
  SelectBox,
} from "../../../../../core/components/common";


const articleTabList = [
  {
    value: 'PRE_ACCEPTANCE',
    label: 'Pre Acceptance',
  }, 
  {
    value: 'REJECTED',
    label: 'Rejected',
  }, 
  {
    value: 'POST_ACCEPTANCE',
    label: 'Accepted & Post Acceptance',
    is_default: true,
  }
];

class Add extends BaseComponent {
  state = {
    article_tab_config: {
      article_tabs: [
        'POST_ACCEPTANCE'
      ]
    }
  };

  async componentDidMount() {
    this.props.getPublicPublisher();
  }

  onChangeHandler = value => {
    this.setState(prevState => {
      return Object.assign(prevState.article_tab_config, value);
    });
  };

  onCheckItem = (value) => (e) => { 
    let { article_tab_config } = this.state;
    let article_tabs = article_tab_config.article_tabs || [];

    Object.assign(article_tab_config, {
      article_tabs: article_tabs.includes(value)
        ? article_tabs.filter((x) => x !== value)
        : [...article_tabs, value],
    });

    this.setState({
      article_tab_config
    });
  };

  onSubmitHandler = async e => {
    e.preventDefault();

    let data = this.state.article_tab_config;
    
    await this.props.createUpdateArticleTabControlConfig(data);

    if (this.props.article_tab_config.success) {
      this.props.history.push("/article-tab-configs/list");
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
    });
  };

  render() {
    let { article_tabs } = this.state.article_tab_config;

    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add article tab configuration
                    </a>
                  </li>
                  
                </ul>
                <div className="tab-content books">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      <div className="form-group row">
                        <SelectBox
                          label="Publisher"
                          onChange={this.onChangeHandler}
                          field="organisation"
                          data={this.props.publishers.results || []}
                          className="col-sm-9"
                          labelClass="col-sm-3"
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="article_tabs" className=" col-form-label col-sm-3">Article tabs </label>
                        <div className=" col-sm-9">
                          {
                            articleTabList && articleTabList.map(item => (
                              <Checkbox
                                key={item.value}
                                onChange={this.onCheckItem(item.value)}
                                checked={
                                  (article_tabs && article_tabs.includes(item.value)) || !!item.is_default
                                }
                                disabled={!!item.is_default}
                              >
                                {item.label}
                              </Checkbox>
                            ))
                          }
                        </div>
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  publishers: state.orgs.publisher_list || false,
  article_tab_config: state.ArticleTabControlConfig.article_tab_config || false,
});

const mapDispatchToProps = dispatch => ({
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  createUpdateArticleTabControlConfig: payload => dispatch(createUpdateArticleTabControlConfig(payload)),
});

export const AddArticleTabControlConfig = connect(mapStateToProps, mapDispatchToProps)(Add);
