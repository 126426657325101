import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  ClearStatus,
  UpdateUser,
  getSingleUser,
  deleteUser,
  getAllUser,
  getUserPermissionList,
} from "../../../../actions";
import { Loading } from "../../../../../core/components/common";
import ViewPermission from "../../publisher/user/userPermission/EditPermission";
class User extends BaseComponent {
  state = {
    user: {},
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
    deleteConfirm: false,
    data: [],
    deleteStatus: null,
    count: null,
  };

  getUserData = async (pageNum = 1, page = 10) => {
    await this.props.getAllUser({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.users.results,
      count: this.props.users.count,
    });
  };

  async componentDidMount() {
    let user;
    const userId = this.props.match.params.id;
    let userPermissionList = await this.props.getUserPermissionList(userId);

    user = await this.props.getUser(userId);
    if (!user) {
      user = await this.props.getSingleUser(userId);
    }
    let userGroupKey =
      userPermissionList &&
      userPermissionList.permissible_objects &&
      userPermissionList.permissible_objects.filter(
        (item) => user.permissions && user.permissions.includes(item.key)
      );

    this.setState({
      user,

      permissions: user.permissions,
      userGroupKey: userGroupKey,
    });
    await this.getUserData();
  }
  render() {
    let user = this.state.user && this.state.user;

    let {
      salute,
      first_name,
      middle_name,
      last_name,
      email,
      user_meta,
      role_name,
    } = user;
    return (
      <HubContent title={`${first_name || ""} ${last_name || ""}`}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20 settings-card">
              <div className="card-body  profile">
              <button
                    type="danger"
                    className="btn btn-danger float-right "
                    onClick={() => this.props.history.goBack()}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    X

</button>            
                <div className="row">
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Salutation </label>
            
                    <p> {salute || "-"} </p>

                  </div>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">First name </label>
                    <p> {first_name || "-"} </p>
                  </div>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Middle Name</label>

                      <p> {middle_name || "-"} </p>

                  </div>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Last Name</label>
              
                   <p> {last_name || "-"} </p>

                  </div>

                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Email Address</label>

                    <p> {email || "-"} </p>

                    
                  </div>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Unique ID</label>

                      <p> {(user_meta && user_meta.unique_id)|| "-"} </p>

                  </div>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Orcid ID</label>
    
                    <p> {(user_meta && user_meta.orcid_id)  || "-"} </p>

                  </div>

                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Role</label>
       
                    <p> {role_name || "-"} </p>

                  </div>
                </div>

   
                <div className="row" style={{ marginBottom: 20 }}>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Address one </label>
                      <p> {( this.state.org &&
                        this.state.org.address &&
                        this.state.org.address.address1)|| "-"} </p>

                  </div>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">Address two </label>

                          <p> {( this.state.org &&
                        this.state.org.address &&
                        this.state.org.address.address2) || "-"} </p>
                  </div>
                  <div className="col-md-4 profile-m-15">
                    <label className="profile-label">City </label>

                           <p> {( this.state.org &&
                        this.state.org.address &&
                        this.state.org.address.city_name) || "-"} </p>
                  </div>
                  <div className="col-md-4">
                    <label className="profile-label">Post/Zip Code</label>
                        <p> {( this.state.org &&
                        this.state.org.address &&
                        this.state.org.address.postal_code) || "-"} </p>
                  </div>
                  <div className="col-md-4">
                    <label className="profile-label">State / County</label>
                              <p> {( this.state.org &&
                        this.state.org.address &&
                        this.state.org.address.state_name) || "-"} </p>
                  </div>
                  <div className="col-md-4">
                    <label className="profile-label">Country</label>

                      <p> {( this.state.org &&
                        this.state.org.address &&
                        this.state.org.address.country_name) || "-"} </p>
                  </div>
                </div>

                    {this.state.user && this.state.user.permissions ? (
                      <ViewPermission
                        disabled={true}
                        auth={this.props.auth}
                        userId={this.props.user.id}
                        permissible_objects={this.state.userGroupKey}
                        permissions={this.state.permissions}
                      />
                    ) : (
                        <Loading type="flat" />
                      )}
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth || {},
  user: state.auth.user || {},
  userPermissionList: state.userPermission.userPermissionList,
  getUser: (userId) => {
    return state.user.users
      ? state.user.users.results &&
      state.user.users.results.find((user) => {
        return user.id.toString() === userId.toString() && user;
      })
      : false;
  },
});

const mapDispatchToProps = (dispatch) => ({
  UpdateUser: (payload) => dispatch(UpdateUser(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
  getSingleUser: (id) => dispatch(getSingleUser(id)),
  deleteUser: (id) => dispatch(deleteUser(id)),
  getAllUser: (payload) => dispatch(getAllUser(payload)),
  getUserPermissionList: (id) => dispatch(getUserPermissionList(id)),
});

export const ProfileUser = connect(mapStateToProps, mapDispatchToProps)(User);
