import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

class ImageResize extends React.Component {
  render() {
    return (
      <>
        {this.props.isOpenImageModel ? (
          <div className="addmultfield" style={modal.overlay}>
            <div className={`col-md-12 bg-white alert`} style={modal.boxStyle}>
              <div>
                <div style={{ width: "100%" }}>
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    aspectRatio={16 / 9}
                    preview=".img-preview"
                    guides={false}
                    src={this.props.src}
                    ref={(cropper) => {
                      this.cropper = cropper;
                    }}
                  />
                </div>
                <div>
                  <div className="box" style={{ width: "50%", float: "right" }}>
                    <h1>
                      <span>Crop</span>
                      <button
                        onClick={this.props.cropImage}
                        style={{ float: "right" }}
                      >
                        Crop Image
                      </button>
                    </h1>
                    {/* <img
                      style={{ width: "100%" }}
                      src={this.props.cropResult}
                      alt={`picture`}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(90px, 90px)",
    height: boxHeight,
  },
};
export const ImageFileUploader = ImageResize;
