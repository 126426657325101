const url =
  process.env.REACT_APP_API_ENDPOINT || "https://api.dev.oametrix.io/";
const baseURL = url + "api/v1/";
export const config = {
  baseURL,
  endpoint: {
    login: "login/",
    proxy_login: "login-as/",
    sign_up: "registration/",
    logout: "logout/",
    role: "role/",
    group: "group/",
    group_top: "top-group/",
    article_download: "article-full/download/",
    org: "organisation/",
    social_program: "social-program/",
    organisation_program_plan: "organisation-program-plan/",
    program_coverage: "program-coverage/",
    discount_country: "discount-country/",
    organisation_file_upload: "organisation/upload/",
    child_organisation: "organisation-child/",
    create_organisation_token: "create-organisation-token/",
    apply_token: "apply-token/",
    clear_token: "clear-token/",
    org_auto_approval_config: "organisation-auto-approval-config/",
    oa_forgot_password: "oa-forgot-password/",
    org_auto_approval_config_trigger:
      "organisation-auto-approval-config-trigger/",
    journal: "journal/",
    journal_file_upload: "publication/upload/",
    public_org: "public-organisation-info/",
    public_registration: "public-registration/",
    publication: "publication/",
    publication_type: "publication-type/",
    journal_type: "journal-type/",
    content_type: "content-type/",
    country: "country/",
    state: "state/",
    state_vat: "state-vat/",
    city: "city/",
    active_account: "active-account/",
    change_pwd: "change-password/",
    validate_token: "validate-token/",
    validate_forgot_password: "validate-forgot-password/",
    set_name_password: "set-new-password/",
    user: "user/",
    article_basic_upload: "article-basic/upload/",
    book: "book/",
    book_upload: "book/upload/",
    article_basic: "article-basic/",
    article_manual: "article-manual/",
    article_required_field: "article-required-field/",
    article_full: "article-full/",
    article_reviewer: "article-reviewer/",
    currency: "currency/",
    refund_request: "refund-request/",
    deposit_fund: "deposit-request/",
    oa_deal_balance: "oa-deal-balance/",
    oa_deal: "oa-deal/",
    oa_deal_summary: "oa-deal-summary/",
    geographical_discount: "geographical-discount/",
    oa_deal_request: "oa-deal-request/",
    oa_deal_download: "oa-deal/download/",
    deal_inst: "deal-institution/",

    deposit_request_download: "deposit-request/download/",
    publisher: "publisher/",
    book_publisher: "book-publisher/",
    article_publisher: "article-publisher/",
    oa_publisher: "oa-publisher/",
    approveed_deposit_fund: "deposit-request/?approval_status=approve",
    pending_deposit_fund: "deposit-request/?approval_status=pending",
    reject_apc_fund_request: "author-apc-fund-request/",
    license: "licence/",
    non_oa_license: "no-oa-licence/",
    pub_license: "publisher-licence/",
    apc_fund_source: "apc-fund-source",
    apc_option: "apc-option/",
    article_apc_option: "article-apc-option/",
    article_approve: "article-approve/",
    article_reject: "article-reject/",
    vat: "geo-location-vat-info/",
    vat_config: "organisation-vat-config/",
    vat_hub: "vat/",
    vat_pub: "country-vat/",
    dashboard: "dashboard/",
    oa_token_trxn: "oa-token-transaction/",
    deposit_credit_trxn: "deposit-credit-transaction/",
    offset_fund_trxn: "offset-fund-transaction/",
    author_apc_fund_request: "author-apc-fund-request/",
    author_apc_no_oa: "author-apc-no-oa/",
    author_apc_fund_request_no_oa: "author-apc-fund-request-no-oa/",
    article_apc_request_organisation: "article-apc-request-organisation/",
    apc_funder: "funder/",
    apc_fund: "apc-fund/",
    institutions: "institutions/",
    institution: "institution/",
    pubdb: "publisher-dashboard/",
    unidb: "institution-dashboard/",
    correction_request: "correction-request/",
    consortium: "consortium/",
    consortium_member: "consortia-member/",
    department: "department/",
    article_payment_summary: "article-payment-summary/",
    article_invoice_summary: "article-invoice-summary/",
    article_payment: "article-payment/",
    invoice: "invoice/",
    opened_invoice: "opened-invoice/",
    update_invoice: "update-invoice/",
    publisher_invoice_config_single: "publisher-invoice-config/single/",
    publisher_invoice_config: "publisher-invoice-config/",
    publisher_invoice_preview: "publisher-invoice-preview/preview/",
    submit_for_user_credentials: "submit-for-user-credentials/",
    payment_transaction: "payment-transaction/",
    payment_billing_address: "payment-billing-address/",
    get_payment_authorize_url: "get-payment-authorize-url/",
    check_stripe_connect_status: "check-stripe-connect-status/",
    email_tag: "email-tag/",
    email_message: "email-message/",
    default_email_message: "default-email-message/",
    email_message_edit: "email-message/",
    article_approve_summary: "article-approve-summary/",
    oa_discipline: "oa-discipline/",
    oa_subject: "oa-subject/",
    oa_policy: "oa-policy/",
    oa_requirement: "oa-requirement/",
    oa_publisher_policy: "oa-publisher-policy/",
    organisation_requirement: "organisation-requirement/",
    params_by_tag_author: "params-by-tag/article_arrived_for_author/",
    offset_fund: "offset-fund/",
    publication_download: "publication/download/",
    invoice_download: "invoice/download/",
    orgs_token_download: "organisation-token/download/",
    orgs_offset_token_download: "organisation-offset-fund/download/",
    oa_token_download: "oa-token/download/",
    oa_token: "oa-token/",
    organisation_offset_fund_download: "organisation-offset-fund/download/",
    oa_deal_filter: "deal-filter-options",
    article_status: "article-status/",
    licence_terms: "organisation-licence-terms-config/",
    licence_update: "organisation-licence/",
    article_full_licence_update: "article-full-licence-update/",
    organisation_licence: "organisation-licence/",
    pricing_licence: "pricing-licence/",
    terms: "organisation-terms/",
    article_publisher_terms: "article-publisher-terms/",
    article_publisher_licence: "article-publisher-licence/",
    orgs_notification_config: "organisation-notification-config/",
    article_author: "article-author/",
    article_full_price: "article-full-price/",
    publish_vat_config: "publisher-vat-config/",
    society_membership: "society-member-ship/",
    membership_discount: "oa-inst-membership-discount-config/",
    society_list: "society/",
    society_member: "society-member/",
    publisher_membership_discount_config:
      "publisher-membership-discount-config/",
    publisher_deposit_payment: "publisher-deposit-payment/",
    user_permission_summery: "user-permission-summary/",
    submission_credentials: "oa-submission-credentials/",
    oa_sub_status: "oa-sub-status/",
    oa_deal_feature: "oa-deal-feature/",
    organisation_deal_feature: "organisation-deal-feature/",
    organisation_deal_feature_assignment:
      "organisation-deal-feature-assignment/",
    publication_apc_waiver_config: "pub-journal-price-waive-config/",
    organisation_article_control_config: "organisation-article-control-config/",
    organisation_article_control_decision:
      "organisation-article-control-decision/",
    organisation_dashboard_component: "organisation-dashboard-component/",
    organisation_dashboard_control_config:
      "organisation-dashboard-control-config/",
    user_dashboard_config: "user-dashboard-config/",
    apc_eligibility: "check-apc-eligibility/",
    trigger_article_transfer: "trigger-article-transfer/",
    article_transfer_info: "article-transfer-info/",
    invoice_template: "invoice-template/",
    email_log: "email-log/",
    create_and_apply_token: "create-and-apply-token/",
    organisation_author_suggestions: "organisation-author-suggestions/",
    article_action_config: "organisation-action-control-config/",
    article_action_summery: "org-action-cntrl-config-summary/",
    reset_password: "oa-forgot-password/",
    publisher_opened_invoice_email: "publisher-opened-invoice-email/",
    quicksight_dashboard: "quicksight-dashboard/",
    quicksight_log: "quicksight-log/",
    xero_authorize_url: "get-xero-authorize-url/",
    submit_xero_auth_code: "submit-xero-auth-code/",
    obtain_generic_oauth_login_url: "obtain-oauth-login-url/",
    submit_generic_oauth_auth_code: "obtain-oauth-token/",
    article_faulty_author: "article-faulty-author/",
    create_oauth_user: "create-oauth-user/",
    organisation_article_tab_control_config:
      "organisation-article-tab-control-config/",
    email_resend: "resend-article-email/",
    free_text_email: "free-text-email/",
    check_organisation_admin: "check-organisation-admin/",
    offers: "/offers",
    article_refund_request: "article-refund-request/",
    deal_type: "deal-type/",
    publisher_stripe_account: "publisher-stripe-account/",
    article_full_status: "article-full-status/",
    send_notification:"send-author-notification/",
    group_permission_summary:"group-permission-summary/",
    organisation_permission_summary:"organisation-permission-summary/",
    organisation_licence_pricing_control_config: "organisation-licence-pricing-control-config/",


  },
};
