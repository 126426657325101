import React from 'react';
import BaseComponent from '../../../core/components';
import { connect } from "react-redux";

import Ckditor from "ckeditor4-react";
import CreatableSelect from 'react-select';
import { LoadingOutlined } from "@ant-design/icons";
import { AutoComplete, Spin, } from "antd";
import {
  Loading,
  InputBox,
} from '../../../core/components/common';

import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

import {
  getPublicOrgSignup,
  checkOrganisationAdmin,
} from "../../actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const components = {
  DropdownIndicator: null,
};

const createOption = (label, value = null) => ({
  label,
  value: value || label,
});

class EmailForm extends BaseComponent {

  state = {
    inputValue: '',
    subject: this.props.subject,
    message_body: `<strong>${this.props.message_body}</strong> `,
    recipients: this.props.recipients || [],
  }

  contactTypes = {
    customer_service: 'Customer service',
    editorial: 'Editorial',
    finance: 'Finance',
    production: 'Production',
    sales: 'Sales',
  }

  componentDidMount() {
    this.setPublicationContacts();
 
  };

  setPublicationContacts = () => {
    let publication = this.props.article && this.props.article.publication
    if (publication && Array.isArray(publication.publication_contacts)) {
      let publicationContacts = publication.publication_contacts
      let recipients = [];
      if (publicationContacts && publicationContacts.length > 0) {
        if (Object.keys(publicationContacts[0]).length > 0) {
          recipients = publicationContacts.map(contact => { 
            let label = `${contact.contact_email}(${contact.contact_name}, ${this.contactTypes[contact.contact_department]})`

            return createOption(label, contact.contact_email)
          })
        }
      } 

      this.setState({
        recipients,
      }, () => {
        this.props.onChangeHandler({ recipients, });
      })
    }
  }

  handleChange = (recipients, actionMeta) => {
    this.setState({
      recipients
    }, () => {
      this.props.onChangeHandler({ recipients, });
    });
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    const { inputValue, recipients } = this.state;
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        let recipientsList = [...recipients, createOption(inputValue)];
        this.setState({
          inputValue: '',
          recipients: recipientsList,
        }, () => {
          this.props.onChangeHandler({ recipients: recipientsList, });
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  handleBlurEvent = (event) => {
    const { inputValue, recipients } = this.state;
    if (!inputValue) return;

    if (this.isValidEmail(inputValue)) {
      let recipientsList = [...recipients, createOption(inputValue)];
      this.setState({
        inputValue: '',
        recipients: recipientsList,
      }, () => {
        this.props.onChangeHandler({ recipients: recipientsList, });
      });
    }
  }

  isValidEmail = (value) => {
    // eslint-disable-next-line
    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return pattern.test(value && value.trim())
  }

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState({
        organisation: item.id,
        orgsError: false

      }, () => {
        this.props.onChangeHandler({ organisation: item.id, });
      });

      let article = this.props.article

      if (article &&  article.publisher && article.publisher.id === item.id) {
        this.setPublicationContacts()
      } else {
        this.props.checkOrganisationAdmin(item.id).then(response => {

          if (response && response.status) {
            let recipients = response.recipients && response.recipients.map(email => createOption(email));
            this.setState({
              has_organisation_admin: response.has_admin,
              recipients: [...recipients]
            }, () => {
              this.props.onChangeHandler({ recipients, });
            });
          }
        }).catch(error => {
          return error.response && error.response.data && error.response.data.success;
        });
      }
    }
  };

  onChange = async (value) => {
    let data = this.props.orgs && this.props.orgs.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        organisation: data && data.id,
        orgsError: false,

      }, () => {
        this.props.onChangeHandler({ organisation: data && data.id, });
      });
    } else {
      if (value === "") {
        this.setState({
          orgsError: false,
          org_name: value

        })
      } else {
        this.setState({
          orgsError: true,
          org_name: value

        })
      }
    }
  }
  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getPublicOrgSignup(value);
      this.setState({
        dataList: this.props.orgs && this.props.orgs.map(item => ({
          id: item.id,
          value: item.name,
        }))
      })
    }, 200);
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      this.props.onChangeHandler(value);
      return Object.assign(prevState, value);
    });
  };

  onEditorChange = evt => {
    let value = {
      message_body: evt.editor.getData()
    };

    this.onChangeHandler(value);
  };

  render() {

    return (
      <div>

        <form className="apc-form custom-email-form">
          {this.props.emailLoading && <Loading />}

          <div className="form-group row">

            <label className="col-form-label col-sm-2">
              To (Organisation)
              <Tooltip title="Type your institution name and select from the drop down">
                <InfoCircleTwoTone style={{
                  fontSize: 15,
                  marginLeft: 5
                }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                />
              </Tooltip>
            </label>

            <div className="col-sm-10">

              <div className="search-auto-complete">

                <AutoComplete
                  defaultValue={this.state.organisation}
                  notFoundContent={this.state.org_name && "The institution name you have typed has no match on OaMetrix."}
                  options={this.state.dataList}
                  style={{
                    width: "100%",
                  }}
                  onSelect={(val, option) => this.onSelect(val, option)}
                  onSearch={this.onSearch}
                  onChange={this.onChange}
                  placeholder="Type your organisation name and select from the drop down"
                />
              </div>

              <div className="search-auto-spin">
                {this.props.loading && <Spin indicator={antIcon} />}
              </div>

              {this.state.orgsError && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
                  </span>}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-sm-2">
              Publication
              <Tooltip title="Publication of the article">
                <InfoCircleTwoTone style={{
                  fontSize: 18,
                  marginLeft: 10
                }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                />
              </Tooltip>
            </label>
            <div className="col-sm-10">
              <label className="col-form-label">
                {
                  (this.props.article
                    && this.props.article
                    && this.props.article.publication
                    && this.props.article.publication.name) || this.props.publication_name || "-"
                }
              </label>

            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="request-to-email" className="col-form-label col-sm-2">
              Recipient
              <span className="text-danger"> * </span>

              <Tooltip title="Recipients of this email">
                <InfoCircleTwoTone style={{
                  fontSize: 18,
                  marginLeft: 10
                }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                />
              </Tooltip>
            </label>
            <div className="col-sm-10">
              <CreatableSelect
                components={components}
                inputValue={this.state.inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                onBlur={this.handleBlurEvent}
                placeholder="johndoe@test.com (press Tab or Space Bar for multiple recipients)"
                value={this.state.recipients || []}
              />
            </div>
          </div>

          <div className="form-group row">
            <InputBox
              labelInfo={true}
              labelInfoTitle="Enter your email subject"
              labelClass="col-sm-2 pr-0"
              label="Subject"
              onChange={this.onChangeHandler}
              className="col-sm-10"
              field="subject"
              placeholder="Enter your email subject"
              defaultValue={this.state.subject}
              isRequired={true}
            />
          </div>

          <div className="form-group row">

            <label htmlFor="message_body" className="col-form-label col-sm-2">
              Message
              <span className="text-danger"> * </span>

              <Tooltip title="Message of the email">
                <InfoCircleTwoTone style={{
                  fontSize: 18,
                  marginLeft: 10
                }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                />
              </Tooltip>
            </label>
            <div className="col-sm-10">
              <Ckditor
                onChange={this.onEditorChange}
                data={this.state.message_body}
                config={{
                  height: "200px",
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'Link', 'Unlink', 'Format']
                  ]
                }}
              />
            </div>

          </div>
        </form>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
  orgs: state.app.organizations,
  loading: state.orgs.loading,
});
const mapDispatchToProps = (dispatch) => ({
  getPublicOrgSignup: (value) => dispatch(getPublicOrgSignup(value)),
  checkOrganisationAdmin: (id) => dispatch(checkOrganisationAdmin(id)),
});

export const AdHocEmailForm = connect(mapStateToProps, mapDispatchToProps)(EmailForm);
