import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BaseComponent, { DataTable } from "../../../../../../core/components";
import {
  getJournalList,
  deleteJournal,
  getJournalDownload,
} from "../../../../../actions";
import {
  Loading,
  PopupBox,
  // SelectBox,
  // InputBox
} from "../../../../../../core/components/common";
import NotFound from "../../../NotFound";
import moment from "moment";
// import { DataFilter } from '../../../../../openaccess/components/common';
import { serialize } from "../../../../../../core/lib";

class Journal extends BaseComponent {
  state = {
    deleteConfirm: false,
    journalId: null,
    waitingMsg: null,
    deleteStatus: null,
    filterParams: { page: 1 },
  };
  getJournalData = async (pageNum = 1, page = 10) => {
    await this.props.getJournalList({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.journals.results,
      count: this.props.journals.count,
    });
  };
  async componentDidMount() {
    this.getJournalData();
  }
  pageChange = (pageNumber) => {
    this.getJournalData(pageNumber);
  };

  onEditjournal = (id) => {
    this.props.history.push("/article/edit-journal/" + id);
  };

  onViewjournal = (id) => {
    this.props.history.push("/article/single-journal/" + id);
  };

  onDeletejournal = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        journalId: id,
      });
    }
  };

  onDeletejournalHander = async () => {
    const journalId = this.state.journalId;
    this.setState({
      waitingMsg: "Deleting...",
    });
    const journal = await this.props.deleteJournal(journalId);
    let data = this.state.data;
    let newData = data.filter(function (element) {
      return element.id !== journalId;
    });
    this.setState({
      waitingMsg: null,
      journalId: null,
      deleteConfirm: false,
      deleteStatus: journal.status,
      data: newData,
    });
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      journalId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

  onJournalDownloadClick = async () => {
    try {
      await this.props.getJournalDownload();
      const url = window.URL.createObjectURL(this.props.journalDownload);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `journal_data${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
 
    }
  };

  filterData = async (value) => {
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = await serialize(this.state.filterParams);
    this.setState({
      filter_url: filter,
    });
  };

  onFilter = async () => {
    let filter = this.state.filter_url;
    await this.props.getJournalList(filter);
  };

  render() {
    const heading = ["name", "type", "subject", "pissn", "eissn"];
    const JournalDataList = [];
    this.state.data &&
      this.state.data.map((item) =>
        JournalDataList.push({
          id: item.id,
          name: item.name,
          type: item.journal_type_name,
          pissn: item.pissn,
          eissn: item.eissn,
          subject:
            item.subjects_list &&
            item.subjects_list
              .map((item) => item.name)
              .slice(0, 1)
              .join(),
        })
      );

    // let filter_fields = [
    //   {
    //     field_name: 'filter_title',
    //     field_type:'text'
    //   },
    //   {
    //     field_name: 'filter_pissn',
    //     field_type: 'text'
    //   },
    //   {
    //     field_name: 'filter_eissn',
    //     field_type: 'text'
    //   },
    //   {
    //     field_name: 'filter_name',
    //     field_type: 'text'
    //   },
    //   {
    //     field_name: 'filter_date',
    //     field_type: 'date'
    //   },
    //   {
    //     data:[
    //       {name: "All Demo Two",id: "all"},
    //       {id:1,name:'demo_two_1'},
    //       {id:2,name:'demo_two_2'},
    //       {id:3,name:'demo_two_3'},
    //       {id:4,name:'demo_two_4'},
    //       {id:5,name:'demo_two_5'}
    //     ],
    //     field_name:'demo_two'
    //   }
    // ];
    let errMsg = !this.props.journals;
    let journal = this.props.journals;
    let failedMsg = this.props.failed;
    let errorHander = (
      <>
        {failedMsg && failedMsg.status === 400 && !this.props.journals && (
          <PopupBox
            title="Invalid Request"
            msg="Your Request is not valid"
            onCancel={this.cancelLoader}
          />
        )}
        {failedMsg && failedMsg.status === 500 && !this.props.journals && (
          <PopupBox
            title="Network Error Found"
            msg="Please Check Internet Connection and Try Again"
            onCancel={this.cancelLoader}
          />
        )}
        {!journal && !failedMsg && failedMsg !== null && (
          <Loading type="flat" />
        )}
      </>
    );

    return this.state.status === 403 ? (
      <NotFound />
    ) : (
      <>
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeletejournalHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        {this.state.deleteStatus && (
          <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
        )}

        {this.state.deleteStatus === false && (
          <PopupBox
            title="Failed... Try Again"
            onCancel={this.onCancel}
            NoBtnType="danger"
          />
        )}
        {errorHander}
        <div className="row filter">
          {!errMsg && this.state.data && (
            <DataTable
              heading={heading}
              data={JournalDataList}
              onEdit={this.onEditjournal}
              onDelete={this.onDeletejournal}
              onView={this.onViewjournal}
              count={this.state.count}
              pageChange={this.pageChange}
              pageSize={10}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  journals: state.journals.journal_list || false,
  journalDownload: state.journals.journal_download,
});

const mapDispatchToProps = (dispatch) => ({
  getJournalList: (payload) => dispatch(getJournalList(payload)),
  deleteJournal: (id) => dispatch(deleteJournal(id)),
  getJournalDownload: (payload = {}) => dispatch(getJournalDownload(payload)),
});

export const JournalList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Journal)
);
