import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../../core/components";
import {
  InputBox,
  SelectBox,
  PopupBox
} from "../../../../../core/components/common";
import { getAllCountry, getAllState } from "../../../../actions";

class Form extends BaseComponent {
  state = {
    status: false,
    msg: false,
    isEmpty: false,
    federal_vat_rate: 0,
    state_provincial_vat_rate: 0
  };

  componentDidMount() {
    this.props.getAllCountry();

    if (this.props.data) {
      this.setState({
        ...this.props.data
      });

      let payload = {
        country: this.props.data.country,
        page_size: 9999
      };

      this.props.getAllState(payload).then(res => {
        if (res.status) {
          this.setState({
            stateProvince: [{ id: "", name: "All state" }, ...res.results]
          });
        }
      });
    }
  }

  onChangeHandler = value => {
    this.setState(
      {
        ...value
      },
      () => {
        let payload = {
          ...value,
          page_size: 9999
        };
        this.setState({
          res:
            Number(this.state.federal_vat_rate) +
            Number(this.state.state_provincial_vat_rate)
        });

        if (value.country) {
          this.props.getAllState(payload).then(res => {
            if (res.status) {
              this.setState({
                stateProvince: [{ id: "", name: "All state" }, ...res.results]
              });
            }
          });
        }
      }
    );
  };

  onSubmitHandler = async e => {
    e.preventDefault();
    let {
      country,
      state,
      federal_vat_rate,
      res,
      state_provincial_vat_rate
    } = this.state;

    if (!country || !res) {
      this.setState({
        isEmpty: (
          <div>
            {/* {!country && <p>Country must be selected</p>}
            {!vat_rate && <p>Vat percentage must be given.</p>}
            {!federal_vat_rate && <p>Federal Vat rate must be given.</p>}
            {!state_provincial_vat_rate && (
              <p>State Provincial Vat rate must be given.</p>
            )} */}
          </div>
        )
      });
    } else {
      let data = {
        country: country,
        federal_vat_rate: Number(federal_vat_rate),
        state: state || null,
        state_provincial_vat_rate: Number(state_provincial_vat_rate),
        vat_rate: Number(res)
      };

      this.props.onSubmitHandler(data);
    }
  };

  cancelLoader = async () => {
    this.setState({
      msg: false,
      status: false,
      isEmpty: false
    });
  };
  sum = () => {
    this.setState({
      res:
        Number(this.state.federal_vat_rate) +
        Number(this.state.state_provincial_vat_rate)
    });
  };

  render() {
 
    // const sum = Number(this.state.federal_vat_rate) + 12;
   
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <div className="row p-t-10">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        <div className="col-sm-12 mx-auto vat_config">
          <form>
            <div className="form-group row">
              <div className="col-md-6">
                <label>Country</label>
                <SelectBox
                  onChange={this.onChangeHandler}
                  field="country"
                  placeholder="Enter country"
                  // isRequired={true}
                  className="col-sm-12"
                  defaultValue={this.state.country}
                  data={this.props.countries}
                />
              </div>
              <div className="col-md-6">
                <label>State/province</label>
                <SelectBox
                  onChange={this.onChangeHandler}
                  field="state"
                  placeholder="Enter State/Province"
                  className="col-sm-12"
                  defaultValue={this.state.state}
                  data={this.state.stateProvince || []}
                />
              </div>
              <div className="col-md-6">
                <label>Country or Federal VAT Rate</label>
                <InputBox
                  onChange={this.onChangeHandler}
                  field="federal_vat_rate"
                  className="col-sm-12"
                  // isRequired={true}
                  maxLength={0}
                  minLength={0}
                  defaultValue={this.state.federal_vat_rate}
                  placeholder="Enter federal Vat Rate"
                  InputType="Number"
                />
              </div>

              <div className="col-md-6">
                <label>State/Province Rate</label>
                <InputBox
                  onChange={this.onChangeHandler}
                  field="state_provincial_vat_rate"
                  className="col-sm-12"
                  // isRequired={true}
                  defaultValue={this.state.state_provincial_vat_rate}
                  placeholder="Enter State/province Rate"
                  InputType="Number"
                  maxLength={0}
                  minLength={0}
                />
              </div>
              <div className="col-md-6">
                <label>Total VAT Rate</label>
                <InputBox
                  onChange={this.onChangeHandler}
                  field="res"
                  className="col-sm-12"
                  disabled={true}
                  // isRequired={true}
                  defaultValue={this.state.res}
                  placeholder="0"
                  InputType="Number"
                  maxLength={0}
                  minLength={0}
                />
              </div>
            </div>

            <div className="text-right m-t-15">
              <div className="text-center m-t-15">
                <button
                  onClick={this.onSubmitHandler}
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countries: state.app.countries
});

const mapDispatchToProps = dispatch => ({
  getAllCountry: () => dispatch(getAllCountry()),
  getAllState: payload => dispatch(getAllState(payload))
});

export const VATForm = connect(mapStateToProps, mapDispatchToProps)(Form);
