import React from "react";
import { connect } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  createUpdateSocietyMember,
  trackArticleFileUploadStatus,
  trackArticleFileUploadStatusDownload,
  SocietyFileUpload,
  getSocietyList
} from "../../../../actions";
import { Checkbox } from "antd";
import {
  PopupBox,
  InputBox,
  OaDatePicker,
  SelectBox,
  Button
} from "../../../../../core/components/common";
import DragAndDrop from "../../../partials/DragAndDrop";

import moment from "moment";

const format = "Do MMM YYYY";

class Add extends BaseComponent {
  state = {
    society_member: {},
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      if (value.membership_expiry) {
        value.membership_expiry = moment(value.membership_expiry).format(
          "YYYY-MM-DD"
        );
      }

      return Object.assign(prevState.society_member, value);
    });
  };

  onChangeChecked = (e) => {
    let value = {
      is_visible_to_other: e.target.checked,
    };

    this.setState((prevState) => {
      return Object.assign(prevState.society_member, value);
    });
  };

  componentDidMount() {
    this.props.getSocietyList();

  }
  onSubmitHandler = async (e) => {
    e.preventDefault();

    let data = this.state.society_member;
    if (!data.first_name || !data.last_name || !data.membership_id || !data.email  ) {
      this.setState({
        isEmpty: (
          <div>
            {!data.first_name && <p>First name must be filled</p>}
            {!data.last_name && <p>Last name must be filled</p>}
            {!data.email && <p>Email must be filled</p>}

            {!data.membership_id && <p>Membership ID must be filled</p>}
          </div>
        ),
      });
    } else {
      await this.props.createUpdateSocietyMember({ ...data, society: this.props.auth && this.props.auth.group === "HUB" ? this.state.society_member.society : this.props.auth && this.props.auth.user && this.props.auth.user.organisation_id });

      if (this.props.society_member.success) {
        this.props.history.push("/society-members/society-member-list");
      }
    }
  };

  cancelLoader = async () => {
    this.setState({
      isEmpty: false,
    });
  };
  onArticleFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });

    await this.props.SocietyFileUpload(payload);
    if (this.props.article_file_upload.status) {
      let payload = {
        upload_status: this.props.article_file_upload.data.upload_status.slice(
          1
        ),
      };
      this.setState({
        upload_payload: payload,
      });
      await this.props.trackArticleFileUploadStatus(payload);
      this.props.history.push("/society-members/society-member-list");

    }

    if (
      this.props.track_upload_status &&
      this.props.track_upload_status.reason
    ) {
      let upload_id = this.props.track_upload_status.id;

      let upload_download_status = `file-upload-error-response/${upload_id}/`;
      let payloadDownload = {
        upload_status: upload_download_status,
      };
      await this.props.trackArticleFileUploadStatusDownload(payloadDownload);
    }

    this.setState({
      fileUploadStatus: this.props.article_file_upload.status,
      fileUploadStatusMsg: this.props.article_file_upload.data.message,
      isLoading: false,
    });
  };
  onFileDrop = (payload) => {
    confirmAlert({
      title: "Upload society member",
      message: "Are you sure, you want to upload file?",
      buttons: [
        {
          label: "Upload",
          onClick: () => {
            this.onArticleFileUpload(payload);
          },
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
  };
  // onSelect = (value, item) => {
  //   this.setState({
  //     society: item.id,

  //   });
  // };
  // onChange = (value) => {
  //   this.setState({
  //     value: value,
  //   });
  // };
  // onSearch = (value) => {
  //   if (this.timeout) clearTimeout(this.timeout);
  //   this.timeout = setTimeout(async () => {
  //     this.props.getSocietyList({ q: value });
  //     this.setState({
  //       societiesList:
  //         []
  //     });
  //   }, 200);
  // };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent title="Add society member">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="x"
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div className="card-body">
                <ul
                  className="nav nav-pills nav-justified"
                  role="tablist"
                  style={{ marginBottom: 15 }}
                >
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add society Members
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload Society Members details
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      {this.props.auth.group === "HUB" && <div className="form-group row">
                        <SelectBox
                          label="Society"
                          onChange={this.onChangeHandler}
                          field="society"
                          data={
                            this.props.societies
                              ? this.props.societies.results
                              : []
                          }
                          isRequired={true}
                          placeholder="Select society"
                        />

                      </div>}


                      <div className="form-group row">
                        <InputBox
                          label="Salutation"
                          onChange={this.onChangeHandler}
                          field="salutation"
                          placeholder="Salutation"
                        />
                        <InputBox
                          label="First name"
                          onChange={this.onChangeHandler}
                          field="first_name"
                          placeholder="First name"
                          isRequired={true}
                        />
                      </div>
                      <div className="form-group row">
                        <InputBox
                          label="Middle name"
                          onChange={this.onChangeHandler}
                          field="middle_name"
                          placeholder="Middle name"
                        />
                        <InputBox
                          label="Last name"
                          onChange={this.onChangeHandler}
                          field="last_name"
                          placeholder="Last name"
                          isRequired={true}
                        />
                      </div>

                      <div className="form-group row">
                        <InputBox
                          label="Email"
                          onChange={this.onChangeHandler}
                          field="email"
                          placeholder="Email"
                          InputType="email"
                        />
                        <InputBox
                          label="Orcid ID"
                          onChange={this.onChangeHandler}
                          field="orcid_id"
                          placeholder="Orcid ID"
                        />
                      </div>
                      <div className="form-group row">
                        <InputBox
                          label="Membership ID"
                          onChange={this.onChangeHandler}
                          field="membership_id"
                          placeholder="Membership ID"
                          isRequired={true}
                        />
                        <OaDatePicker
                          className="col-sm-4"
                          label="Membership expiry"
                          labelClass="col-sm-2"
                          onChange={this.onChangeHandler}
                          field="membership_expiry"
                          placeholder="Membership expiry"
                          showTime={false}
                          format={format}
                        />
                      </div>
                      <div className="form-group row">
                        <InputBox
                          label="fellow Affiliation"
                          onChange={this.onChangeHandler}
                          field="fellow_affiliation"
                          placeholder="fellow affiliation"
                          />

                        <div className="col-md-6">
                          <Checkbox onChange={this.onChangeChecked}>
                            Click on checkbox to allow publishers to view
                            Society members.
                          </Checkbox>
                        </div>
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <div className="m-b-30">
                      <DragAndDrop onDropHandler={this.onFileDrop} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  society_member: state.SocietyMember.society_member || false,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  societies: state.society.societies || false,

  article_file_upload: state.articles.article_file_upload || false,
  track_upload_status: state.articles.track_upload_status || false,
  track_upload_status_download:
    state.articles.track_upload_status_download || false,
});

const mapDispatchToProps = (dispatch) => ({
  createUpdateSocietyMember: (payload) =>
    dispatch(createUpdateSocietyMember(payload)),
  SocietyFileUpload: (payload) => dispatch(SocietyFileUpload(payload)),
  trackArticleFileUploadStatus: (payload) =>
    dispatch(trackArticleFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) =>
    dispatch(trackArticleFileUploadStatusDownload(payload)),
  getSocietyList: (payload) =>
    dispatch(getSocietyList(payload)),
});

export const AddSocietyMember = connect(
  mapStateToProps,
  mapDispatchToProps
)(Add);
