import config, {
	CREATE_CONTENT_TYPE,
	DELETE_CONTENT_TYPE_DATA,
	GET_CONTENT_TYPE,
	UPDATE_CONTENT_TYPE_DATA,
	UPLOAD_CONTENT_TYPE,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Loaded into App Reducers
export const createContentTypeReducer = (payload) => ({
	type: CREATE_CONTENT_TYPE,
	payload,
});

export const contentTypeList = (payload) => ({
	type: GET_CONTENT_TYPE,
	payload,
});

export const deleteContentTypeData = (payload) => ({
	type: DELETE_CONTENT_TYPE_DATA,
	payload,
});

export const updateContentTypeData = (payload) => ({
	type: UPDATE_CONTENT_TYPE_DATA,
	payload,
});

// Content type file upload
export const fileUploadAction = (payload) => ({
	type: UPLOAD_CONTENT_TYPE,
	payload,
});

export const createContentType = (payload) => {
	return (dispatch) => {
		const url = config.endpoint.content_type;
		return new Promise((resolve, reject) => {
			api.post(url, payload).then(
				(res) => {
					dispatch(createContentTypeReducer(res.results));
					resolve(res.results);
				},
				(error) => {
					reject(error.response);
				}
			);
		});
	};
};

// Get Content Type List
export const getContentTypeListTable = (payload) => {
	return (dispatch) => {
		let url = config.endpoint.content_type;
		let params = {};

		if (payload.pageNum && payload.pageSize) {
			params["page"] = payload.pageNum;
			params["page_size"] = payload.pageSize;
		} else if (payload.pageNum) {
			params["page"] = payload.pageNum;
		} else if (payload.pageSize) {
			params["page_size"] = payload.pageSize;
		}

		if (payload.q) {
			params["q"] = payload.q;
		}

		if (!params.page_size) {
			params["page_size"] = 99999;
		}

		url += `?${serialize(params)}`;

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(contentTypeList(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};
// Delete
export const deleteContentType = (id) => {
	return (dispatch) => {
		const url = config.endpoint.content_type;
		return new Promise((resolve, reject) => {
			return api
				.delete(url + id + "/")
				.then(
					(res) => {
						dispatch(deleteContentTypeData(id));
						resolve({ status: true });
					},
					(error) => {
						resolve({ status: false });
					}
				)
				.catch(reject);
		});
	};
};

// Update

export const UpdateContentType = (id, payload) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			const url = config.endpoint.content_type;
			return api
				.put(url + id + "/", payload)
				.then(
					(res) => {
						dispatch(
							updateContentTypeData(
								Object.assign(res.data, { status: res.status })
							)
						);
						resolve(res.data);
					},
					(error) => {
						reject(error);
					}
				)
				.catch((error) => {
					reject(error);
				});
		});
	};
};

// Content type file upload action
export const uploadContentType = (payload) => {
	let url = `${config.endpoint.content_type}upload/`;
	return (dispatch, getState) => {
		const token = getState().auth.token;

		return new Promise((resolve, reject) => {
			let filename = payload.get("filename");
			let headers = {
				"Content-Disposition": `attachment; filename="${filename}"`,
			};

			payload.delete("filename");

			api.post(url, payload, token, headers)
				.then((res) => {
					dispatch(fileUploadAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {

					dispatch(fileUploadAction(error.response));
					reject(Object.assign(error.response, { status: false }));
				});
		});
	};
};
