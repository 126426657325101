import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment"
import BaseComponent from "../../../../../../core/components";
import ReactHtmlParser from "react-html-parser";
import { SelectBox, InputBox, Button, Loading } from "../../../../../../core/components/common";

import {
    getAllSocietyMemberList,
    CreateSocietyMemberShip,
    downloadInvoice,
    UpdateSocietyMemberShip,
    getArticleSocietyOfferDetails
} from "../../../../../actions";

class ArticleSocietyOffer extends BaseComponent {
    state = {
        isShow: false,
        isContentHide: true,
        membership: {},
        isEmpty: false,
        isHideBtn: true,
        isMemberSave: false,
        value: [],
        isSocietyMemberShow: false
    };
    componentDidMount() {
        this.apiDataList()

    }

    apiDataList = async () => {
        this.props.getArticleSocietyOfferDetails(this.props.article_id)
        let memberData = await this.props.getAllSocietyMemberList()
            this.setState({
                memberData: memberData && memberData.results && memberData.results.map(item => ({ id: item.id, name: item.name })),

            })
        
    }
    componentWillUnmount() {
            // fix Warning: Can't perform a React state update on an unmounted component
            this.setState = (state,callback)=>{
                return;
            };
        
    }

    onChangeHandler = value => {
        this.setState({
            ...value
        });
    };
    onCloseModal = e => {
        let overlay = document.getElementById("CoAuthorDialog");
        if (e.target === overlay) {
            this.props.onClose();
        }
    };

    onSubmit = async e => {
        let payload = {
            membership_id: this.state.membership_id,
            society: this.state.name,

        };
        this.props.CreateSocietyMemberShip(payload)
        this.props.getArticleSocietyOfferDetails(this.props.article_id, {
            post_check_membership_update: true

        })

        this.setState({
            isContentHide: false,
            isSocietyMemberShow: false,
            isMemberSave: !this.state.isMemberSave
        })
    };

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        });
        await this.props.ClearStatus();
    };
    handleSocietyMemberForm = () => {
        this.setState({
            isHideBtn: false,
            isMemberSave: false,
            isContentHide: !this.state.isContentHide,
            isSocietyMemberShow: !this.state.isSocietyMemberShow
        });
    }
    ApcFundRequest = () => {

        this.props.onClose();
        this.props.onActionHandler && this.props.onActionHandler("author_apc_fund_request")

    }

    onClickDownloadInvoice = async (url, item) => {
        let payload = {
            url: url,
        };

        await this.props.downloadInvoice(payload);

        const urlObj = window.URL.createObjectURL(this.props.invoice_download);
        const link = document.createElement("a");
        link.href = urlObj;
        var d = new Date();

        link.setAttribute(
            "download",
            `${item.replace(/\s+/g, "")}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
    };

    render() {
        let successcode = 1972
        let no_avaiable_code = 1975
        let submitted_code = 1971
        let success = this.props.offer_details && this.props.offer_details.code === successcode
        let no_avaiable = this.props.offer_details && this.props.offer_details.code === no_avaiable_code
        let society_submitted = this.props.offer_details && this.props.offer_details.code === submitted_code
        let invoice_raised = this.props.offer_details && this.props.offer_details.code === 1978

        let message = this.props.offer_details && this.props.offer_details.message
        let channel = this.props.offer_details && this.props.offer_details.channel
        let action = this.props.offer_details && this.props.offer_details.action
        let invoiceDwLink = this.props.offer_details && this.props.offer_details.data


        let SocietyMemberForm = (
            <div className="addresses">
                <div className="form-group row">
                    <div className="col-md-12">
                        <label className="orgs-label">Society Name</label>

                        <SelectBox
                            onChange={this.onChangeHandler}
                            field="name"
                            className="col-sm-12"
                            data={this.state.memberData}
                            placeholder="Select Society Name"
                            isData={true}
                            defaultValue={this.state.name}
                            isRequired={true}
                        />
                    </div>
                    <div className="col-md-12 orgs-margin-top">
                        <label className="orgs-label">Society Number</label>

                        <InputBox
                            onChange={this.onChangeHandler}
                            field="membership_id"
                            className="col-sm-12"
                            placeholder="Enter your society number"
                            defaultValue={this.state.membership_id}
                        />
                    </div>
                </div>
            </div>
        );

        return (

            <div
                onClick={this.onCloseModal}
                id="CoAuthorDialog"
                style={modal.overlay}

            >

                <div
                    className={`col-10 col-lg-8 memeber-author bg-white alert`}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        zIndex: 999999,
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <div className="article-offer">
                        <Button
                            type="danger"
                            name="danger"
                            text="X"
                            size="lg"
                            onClick={e => {
                                e.preventDefault();
                                this.props.onClose && this.props.onClose();
                            }} />
                    </div>
                    <h3 className="society-discount-heading">Society Membership Discount</h3>

                    {this.props.isloading !== true ?
                        <div>
                            {this.state.isContentHide && <div className="society-content-para">
                                {no_avaiable && <p className="society-offer-content">{message}</p>}

                                {no_avaiable !== true && (invoice_raised ? message : message && message.map((item, index) => <p className="society-offer-content" key={index}>{ReactHtmlParser(item)}</p>))}
                                {
                                    action && action.map((item, idx) => item === "add_membership_id" && <p className="membership-discount-id" key={idx}>It looks like OaMetrix could not find your membership details. Please click <strong style={{ textDecoration: "underline", cursor: "pointer", color: "#6610f2" }} onClick={this.handleSocietyMemberForm}>here</strong> to enter your society membership ID. OaMetrix will cross-check this information and will automatically apply a society discount if it recognises your membership ID. </p>)
                                }
                                {success && <p className="membership-discount-id">If you are not a society member, please click on Pay APC to pay your APC online or generate an invoice. Click on Request APC Fund to request your organisation to pay your APC.</p>
                                }

                                {invoice_raised &&
                                    <div className="society-content text-center" style={{ marginTop: 10 }} >
                                        <Button
                                            type="primary"
                                            size="200"
                                            className="invoice-download"
                                            name="primary"
                                            onClick={(e) =>
                                                this.onClickDownloadInvoice(invoiceDwLink.invoice_download_link, "invoice")
                                            }
                                            text="Download Invoice"
                                        />
                                    </div>
                                }
                                {society_submitted &&
                                    <p className="society-offer-content">If you are not a society member, please click on Pay APC to pay your APC online or generate an invoice. Click on Request APC Fund to request your organisation to pay your APC.</p>

                                }

                                {channel && channel.map((item, idx) => item === "PAY" &&
                                    (
                                        <div className="society-content text-center" key={idx}>
                                            <button
                                                style={{ marginBottom: 15 }}
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() => this.props.history.push(`/payment/addpayment/${this.props.article_id}`)}
                                            >
                                                PAY APC
                                            </button>
                                            <button
                                                style={{ marginBottom: 15, marginLeft: 10 }}
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={this.ApcFundRequest}>
                                                Request APC Fund
                                            </button>
                                        </div>
                                    )

                                )

                                }
                            </div>}


                            <div>
                                {this.state.isMemberSave && <div className="society-content-para">
                                    {no_avaiable && <p className="society-offer-content">Thank you very much for adding your membership ID.   </p>}

                                    {(no_avaiable !== true && success !== true) && <p className="society-offer-content">Thank you very much for adding your membership ID,  you are eligible for society membership discount.   </p>}

                                    {no_avaiable && <p className="society-offer-content">{message}</p>}


                                    {(no_avaiable !== true && society_submitted !== true) && (invoice_raised ? message : message && message.map((item, idx) => <p className="society-offer-content" key={idx}>{item}</p>))}

                                    <div className="society-content text-center">
                                        <button
                                            style={{ marginBottom: 30, marginTop: 20 }}
                                            type="button"
                                            className="btn btn-info"
                                            onClick={() => this.props.history.push(`/payment/addpayment/${this.props.article_id}`)}
                                        >
                                            PAY APC
</button>
                                    </div>
                                    <p className="pay-online">Please <Link to="/author-custom-email/author-custom-email">contact</Link> to publisher if you have any issue for society discount. </p>

                                </div>}
                            </div>

                        </div>

                        : <Loading type="flat" />}


                    {this.state.isSocietyMemberShow && SocietyMemberForm}
                    <div className="col-md-12 text-center">
                        <div className="modal-footer border-hide">

                            {this.state.isSocietyMemberShow &&
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.onSubmit}
                                >
                                    Save
              </button>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
    boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
    boxHeight = "-webkit-fit-content";
}

const modal = {
    overlay: {
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.64)",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 1,
        display: "flex",
        alignItem: "center",
        justifyContent: "center"
    },
    boxStyle: {
        height: boxHeight
    }
};

const mapStateToProps = state => ({
    user: state.auth.user || {},
    offer_details: state.society.offer_details,
    isloading: state.society.isloading,
    invoice_download: (state.payment && state.payment.invoice_download) || false,

});

const mapDispatchToProps = dispatch => ({
    getAllSocietyMemberList: () => dispatch(getAllSocietyMemberList()),
    getArticleSocietyOfferDetails: (id, payload) => dispatch(getArticleSocietyOfferDetails(id, payload)),
    CreateSocietyMemberShip: payload =>
        dispatch(CreateSocietyMemberShip(payload)),
    UpdateSocietyMemberShip: (id, payload) =>
        dispatch(UpdateSocietyMemberShip(id, payload)),
    downloadInvoice: (payload) => dispatch(downloadInvoice(payload)),

});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ArticleSocietyOffer)
);
