import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Icon from "@ant-design/icons";
import { InfoCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import Ckditor from "ckeditor4-react";
import CreatableSelect from 'react-select';

import { AutoComplete, Checkbox, Select, Tooltip, Spin, Popover } from "antd";
import { Button } from "../../../core/components/common";
import { Link } from "react-router-dom";
import {
    ClearStatus,
    getLicenseList,
    getLicenseListNonOa,
    createAuthorApcFund,
    getArticleRequired,
    UpdateArticleRequired,
    getArticleRequiredField,
    getArticleApcRequestOrganisation,
    trackArticleFileUploadStatus,
    getAllBookList,
    UpdateInvoice,
    getArticleFullSingleList,
    createAuthorApcNoOA,
    getArticleControlDecision,
    OrganisationLicenseAgreements,
    downloadInvoice,
    articleFullLicenceUpdate,
    getPublicOrgSignup,
    checkOrganisationAdmin,
} from "../../../openaccess/actions";
import { Progress } from "antd";
import BaseComponent, { DataTable } from "../../../core/components";
import { confirmAlert } from "react-confirm-alert";
import ReactHtmlParser from "react-html-parser";
import NewWindow from "react-new-window";
import moment from "moment";

import config from "../../../../src/config";

import {
    SelectBox,
    Loading,
    InputBox,
} from "../../../core/components/common";
const { Option } = Select;

var t;
const articlePublisherTerms = config.endpoint.article_publisher_terms;
const articlePublisherLicence = config.endpoint.article_publisher_licence;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const content = (
    <a target="_blank" rel="noopener noreferrer" href="https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/APC-Funding-REquest.pdf">
        <span><i className="fa fa-angle-right"></i></span> APC Funding Request</a>
);

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});

class Popup extends BaseComponent {
    state = {
        authorApcReq: {},
        extraField: {},
        loading: false,
        extraFieldForm: this.props.extraField || this.props.keyField,
        article: {},
        message: false,
        cancel: false,
        showLicence: false,
        licence_id: false,
        article_full_licence_update: {},
        article_control_decision: {},
        send_email_status: null,
        recipients: [],
    };

    contactTypes = {
        customer_service: 'Customer service',
        editorial: 'Editorial',
        finance: 'Finance',
        production: 'Production',
        sales: 'Sales',
    }

    setPublicationContacts = () => {
        let publication = this.props.article_single && this.props.article_single.publication

        if (publication && Array.isArray(publication.publication_contacts)) {
            let publicationContacts = publication.publication_contacts


            let recipients;
            if (Object.keys(publicationContacts && publicationContacts[0]).length !== 0) {
               recipients = publicationContacts.map(contact => {
                let label = `${contact.contact_email}(${contact.contact_name}, ${this.contactTypes[contact.contact_department]})`
                return createOption(label, contact.contact_email)
              })
            } else {
              recipients = []
            }
      
            this.setState({
                recipients,
            })
        }
    }

    handleChange = (recipients, actionMeta) => {
        this.setState({
            recipients
        }, () => {
            this.onChangeHandler({ recipients, });
        });
    };

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };

    handleKeyDown = (event) => {
        const { inputValue, recipients } = this.state;
        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
            case ' ':
                let recipientsList = [...recipients, createOption(inputValue)];
                this.setState({
                    inputValue: '',
                    recipients: recipientsList,
                }, () => {
                    this.onChangeHandler({ recipients: recipientsList, });
                });
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    handleBlurEvent = (event) => {
        const { inputValue, recipients } = this.state;
        if (!inputValue) return;

        if (this.isValidEmail(inputValue)) {
            let recipientsList = [...recipients, createOption(inputValue)];
            this.setState({
                inputValue: '',
                recipients: recipientsList,
            }, () => {
                this.onChangeHandler({ recipients: recipientsList, });
            });
        }
    }

    isValidEmail = (value) => {
        // eslint-disable-next-line
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return pattern.test(value && value.trim())
    }

    submit = (msg) => {
        confirmAlert({
            message: msg,
            buttons: [
                {
                    label: "Close",
                    onClick: () => { },
                },
            ],
        });
    };

    licencepdfdownload = async (e, url, name) => {
        e.preventDefault();
        if (!url) {
            this.setState({
                empty_licence_msg: true,
            });
            return;
        }
        let payload = {
            url: url,
        };
        await this.props.licensepdfdownload(payload);
        const urlObj = window.URL.createObjectURL(this.props.licence_download);
        const link = document.createElement("a");
        link.href = urlObj;
        var d = new Date();

        link.setAttribute(
            "download",
            `${name}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
    };

    onLicenceChange = (data) => {
        console.log(data)
        let parsedData = JSON.parse(data);
        let url = this.props.license_article_id
            ? `${articlePublisherLicence}${this.props.license_article_id}/download/?licence=${parsedData.id}`
            : `${articlePublisherLicence}${this.props.article_id}/download/?licence=${parsedData.id}`;
        this.setState({
            licence_id: parsedData.id,
            downloadlink: {
                link: url,
                name: "Licence",
            },
        });
        this.setState((prevState) => {
            return Object.assign(prevState.authorApcReq, { licence: parsedData.id });
        });
        this.setState((prevState) => {
            return Object.assign(prevState.article_full_licence_update, {
                licence: parsedData.id,
            });
        });
    };

    onChangeChecked = (e) => {
        this.setState({
            enable: e.target.checked,
        });
    };

    submitSuccess = (msg) => {
        confirmAlert({
            message: "Successfully created",
            buttons: [
                {
                    label: "Close",
                    onClick: () => { },
                },
            ],
        });
    };

    onChangeHandler = (value) => {
        if (this.state.extraFieldForm) {
            this.setState((prevState) => {
                return Object.assign(prevState.extraField, value);
            });
        } else {
            this.setState((prevState) => {
                return Object.assign(prevState.authorApcReq, value);
            });
        }
    };

    onSubmitHandler = async (e) => {
        e.preventDefault();
        this.setState({
            status: true,
        });
        let id = this.state.article.id || this.props.id;
        let payload = this.state.extraField;
        await this.props.UpdateArticleRequired(id, payload);
        this.props.onCancel ? this.props.onCancel() : this.props.ClearStatus();
        if (!this.props.article.success) {
            this.submit(this.props.article.message);
        } else {
            this.props.onPopupSuccess(payload);
            this.submitSuccess();
        }
    };

    submitAuthorApcFund = async (e) => {
        e.preventDefault();
        let data = this.state.authorApcReq;
        let request_id = this.props.request_to
            ? this.props.request_to.results[0].id
            : null;
        if (
            !this.state.authorApcReq.article ||
            !this.state.authorApcReq.request_to
        ) {
            let extraData = {
                article: this.state.authorApcReq.article
                    ? this.state.authorApcReq.article
                    : this.props.article_id,
                request_to: this.state.authorApcReq.request_to
                    ? this.state.authorApcReq.request_to
                    : request_id,
            };
            data = Object.assign(this.state.authorApcReq, extraData);
        } else {
            data = this.state.authorApcReq;
        }

        let articleControlDecision = this.state.article_control_decision;

        if (
            !this.state.authorApcReq.licence &&
            articleControlDecision.is_author_licence_required
        ) {
            confirmAlert({
                title: "Required field is missing ",
                message: "Licence is required field",
                buttons: [
                    {
                        label: "Close",
                        onClick: () => { },
                    },
                ],
            });
        } else {
            this.setState({
                loading: true,
            });

            data.is_author_licence_required =
                articleControlDecision.is_author_licence_required;

            if (!data.hasOwnProperty('recipients') && this.state.recipients) {
                data.recipients = this.state.recipients;
            }

            await this.props.createAuthorApcFund(data);

            this.setState({
                loading: false,
            });

            if (typeof this.props.author_apc_fund_success.success !== "undefined") {
                confirmAlert({
                    title: "Required field is missing ",
                    message: this.props.author_apc_fund_success.message,
                    buttons: [
                        {
                            label: "Close",
                            onClick: () => { },
                        },
                    ],
                });
            } else {
                this.props.successCallback();
            }
        }
    };

    submitAuthorApcNoOa = async (e) => {
        e.preventDefault();

        this.setState({
            loading: true,
        });

        let data = this.state.authorApcReq;
        let request_id = this.props.request_to
            ? this.props.request_to.results[0].id
            : null;
        if (
            !this.state.authorApcReq.article ||
            !this.state.authorApcReq.request_to
        ) {
            let extraData = {
                article: this.state.authorApcReq.article
                    ? this.state.authorApcReq.article
                    : this.props.article_id,
                request_to: this.state.authorApcReq.request_to
                    ? this.state.authorApcReq.request_to
                    : request_id,
            };
            data = Object.assign(this.state.authorApcReq, extraData);
        } else {
            data = this.state.authorApcReq;
        }

        let articleControlDecision = this.state.article_control_decision;

        if (
            !this.state.authorApcReq.licence &&
            articleControlDecision.is_author_licence_required
        ) {
            confirmAlert({
                title: "Error message!",
                message: "Licence is required field",
                buttons: [
                    {
                        label: "Close",
                        onClick: () => { },
                    },
                ],
            });
        } else {
            delete data.request_to;

            data.is_author_licence_required =
                articleControlDecision.is_author_licence_required;

            if (!data.hasOwnProperty('request_to') && this.state.request_to) {
                data.request_to = this.state.request_to;
            }

            if (!data.hasOwnProperty('recipients') && this.state.recipients) {
                data.recipients = this.state.recipients;
            }

            await this.props.createAuthorApcNoOA(data);

            this.setState({
                loading: false,
            });

            if (typeof this.props.author_apc_fund_success.success !== "undefined") {
                confirmAlert({
                    title: "Error message!",
                    message: this.props.author_apc_fund_success.message,
                    buttons: [
                        {
                            label: "Close",
                            onClick: () => { },
                        },
                    ],
                });
            } else {
                this.props.successCallback();
            }
        }
    };

    submitLiceceFormUpdate = async (e) => {
        // await this.props.createAuthorApcNoOA(data);
        e.preventDefault();
        let article_id = this.props.license_article_id;
        let licence_id = this.state.article_full_licence_update
            ? this.state.article_full_licence_update.licence
            : null;
        let term_checked = this.state.enable ? this.state.enable : null;
        this.setState({
            loading: true,
        });
        if (licence_id && term_checked) {
            let payload = {
                licence_id: licence_id,
            };
            await this.props.articleFullLicenceUpdate(article_id, payload);
            this.setState({
                loading: false,
            });
            window.location.reload();
        } else {
            this.setState({
                loading: false,
            });
            confirmAlert({
                title: "Required field is missing",
                message: "Licence and terms & condition selection is mandatory",
                buttons: [
                    {
                        label: "Close",
                        onClick: () => { },
                    },
                ],
            });
        }
    };

    ExtraFieldForm = (props) => {
        return (
            <div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field1"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field1"
                        placeholder="Extra field one"
                        labelClass="col-sm-3"
                        className="col-sm-9"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field1 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field2"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field2"
                        placeholder="Extra field two"
                        labelClass="col-sm-3"
                        className="col-sm-9"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field2 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field3"
                        lableLeft={true}
                        onChange={this.onChangeHandler}
                        field="extra_field3"
                        placeholder="Extra field three"
                        labelClass="col-sm-3"
                        className="col-sm-9"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field3 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field4"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field4"
                        placeholder="Extra field four"
                        labelClass="col-sm-3"
                        className="col-sm-9"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field4 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field5"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field5"
                        placeholder="Extra field five"
                        labelClass="col-sm-3"
                        className="col-sm-9"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field5 : ""
                        }
                    />
                </div>
                <div
                    className="text-center m-t-15 additional-field"
                    style={{
                        width: "45%",
                        float: "left",
                        marginTop: 16,
                    }}
                >
                    <ul className="nav nav-pills nav-justified" role="tablist">
                        <li className="nav-item waves-effect waves-light">
                            <a
                                className="nav-link active"
                                data-toggle="tab"
                                onClick={this.onSubmitHandler}
                                href="#home-1"
                                role="tab"
                            >
                                Update
              </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    licenceCurrency = async (props) => {
        return (
            <div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field1"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field1"
                        placeholder="Extra field one"
                        labelClass="col-sm-4"
                        className="col-sm-8"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field1 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field2"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field2"
                        placeholder="Extra field two"
                        labelClass="col-sm-4"
                        className="col-sm-8"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field2 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field3"
                        lableLeft={true}
                        onChange={this.onChangeHandler}
                        field="extra_field3"
                        placeholder="Extra field three"
                        labelClass="col-sm-4"
                        className="col-sm-8"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field3 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field4"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field4"
                        placeholder="Extra field four"
                        labelClass="col-sm-4"
                        className="col-sm-8"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field4 : ""
                        }
                    />
                </div>
                <div className="form-group row">
                    <InputBox
                        label="Extra field5"
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field="extra_field5"
                        placeholder="Extra field five"
                        labelClass="col-sm-4"
                        className="col-sm-8"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field5 : ""
                        }
                    />
                </div>
                <div
                    className="text-center m-t-15"
                    style={{
                        width: "45%",
                        float: "left",
                        marginTop: 16,
                    }}
                >
                    <ul className="nav nav-pills nav-justified" role="tablist">
                        <li className="nav-item waves-effect waves-light">
                            <a
                                className="nav-link active"
                                data-toggle="tab"
                                onClick={this.onSubmitHandler}
                                href="#home-1"
                                role="tab"
                            >
                                Update
              </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    percentage = () => {
        let partialValue =
            (this.props.upload_status && this.props.upload_status.uploaded_rows) || 0;
        let totalValue =
            (this.props.upload_status && this.props.upload_status.total_rows) || 0;
        let percentage = (100 * partialValue) / totalValue;

        if (!percentage || isNaN(percentage)) {
            percentage = 0;
        }

        return Number(percentage).toFixed(2);
    };

    UNSAFE_componentWillMount() {
        if (this.props.upload) {
            let payload = this.props.upload_payload;
            this.props.trackArticleFileUploadStatus(payload);
            let data =
                this.props.upload_status && this.props.upload_status.failed_reason
                    ? this.props.upload_status.failed_reason.columns
                    : [];
            this.setState({
                data: data,
            });
            let percentage = this.percentage();
            // call api until all row upload
            if (percentage <= 100 && !this.state.cancel) {
                t = setInterval(() => {
                    this.props.trackArticleFileUploadStatus(payload);
                }, 2000);
            }
        }
    }

    // take first four data
    formatUploadSlicedData = (data, isLowercase = false) => {
        let lowerCase = isLowercase;
        let length = data ? Object.entries(data).length : 0;
        let lastDataLowerCase =
            isLowercase && length > 0
                ? data[length - 1].replace(/\s+/g, "_").toLowerCase()
                : {};
        let lastDataNotLowerCase = length > 0 ? data[length - 1] : {};
        let lastData = isLowercase ? lastDataLowerCase : lastDataNotLowerCase;
        let result = data
            ? Object.keys(data)
                .slice(1, 4)
                .reduce((result, key) => {
                    result[key] = lowerCase
                        ? data[key].replace(/\s+/g, "_").toLowerCase()
                        : data[key];
                    return result;
                }, {})
            : {};
        return Object.assign(result, { reason: lastData });
    };

    beforeReloadMessage = (msg = "Are you sure you want to leave?") => {
        window.onbeforeunload = function () {
            return msg;
        };
    };

    uploadStatus = () => {
        this.beforeReloadMessage(
            "Your uploading is ongoing. If you reload you will be lost your upload status.  Are you sure you want to leave or reload?"
        );
        let upload_status = this.props.upload_status;
        // Table Header
        let uploadHeader =
            upload_status &&
            upload_status.table_header &&
            upload_status.table_header.columns;
        let slicedValueHeader = this.formatUploadSlicedData(uploadHeader, true);
        let heading = slicedValueHeader ? Object.values(slicedValueHeader) : null;
        // Table Data
        let UploadData =
            upload_status &&
                upload_status.table_header &&
                upload_status.table_header.data
                ? upload_status.table_header.data
                : [];
        let filterData = [];
        UploadData.filter((data) => {
            let filteredObject = this.formatUploadSlicedData(data);
            filterData.push(filteredObject);
            return data;
        });
        let TableData = [];
        if (filterData) {
            filterData.map((data) => {
                let errData = Object.values(data);
                // combine two arrays `heading` as key & `errData` as value
                let result = heading.reduce(
                    (o, k, i) => ({ ...o, [k]: errData[i] }),
                    {}
                );
                return TableData.push(result);
            });
        }
        // Table Data end
        // percentage calculation start
        let percentage = this.percentage();
        // percentage calculation end
        return (
            <div className="row">
                <div className="col-md-12" style={{ marginBottom: 40 }}>
                    <Progress percent={percentage} status="active" />
                </div>
                <div className="col-md-12" style={{ marginBottom: 10 }}>
                    {/* <button> Download error list</button> */}
                    {this.props.track_upload_status_download &&
                        this.props.track_upload_status_download.download_link && (
                            <a
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                                target="_blank"
                                href={this.props.track_upload_status_download.download_link}
                            >
                                <button className="m-3 col-sm-4 btn btn-lg btn-primary">
                                    Download error report
                </button>
                            </a>
                        )}
                </div>
                <div className="col-md-12" style={{ marginBottom: 40 }}>
                    {TableData.length > 0 && uploadHeader && (
                        <DataTable
                            heading={heading}
                            data={TableData}
                            onEdit={this.onEditUser}
                            onDelete={this.onDeleteUser}
                            onView={this.onViewUser}
                            count={this.state.count}
                            pageChange={this.pageChange}
                            nuButton={true}
                            pageSize={10}
                            noAction={true}
                        />
                    )}
                </div>
                {/* <!-- end col --> */}
            </div>
        );
    };

    toTitleCase = (str) => {
        return (
            str.replace(/_/g, " ").charAt(0).toUpperCase() +
            str.replace(/_/g, " ").substr(1).toLowerCase()
        );
    };

    KeyFieldForm = (props) => {
        return (
            <div>
                <div className="form-group row">
                    <InputBox
                        label={this.toTitleCase(props.keyName || "")}
                        onChange={this.onChangeHandler}
                        lableLeft={true}
                        field={props.keyName}
                        placeholder={this.toTitleCase(props.keyName || "")}
                        labelClass="col-sm-4"
                        className="col-sm-8"
                        defaultValue={
                            this.state.article ? this.state.article.extra_field1 : ""
                        }
                    />
                </div>
                <div
                    className="text-center m-t-15"
                    style={{
                        width: "45%",
                        float: "left",
                        marginTop: 16,
                    }}
                >
                    <ul className="nav nav-pills nav-justified" role="tablist">
                        <li className="nav-item waves-effect waves-light">
                            <button
                                className="btn btn-secondary department-btn"
                                onClick={this.onSubmitHandler}
                            >
                                Update
              </button>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    LicenceAgreement = () => {
        this.setState({
            showLicence: false,
        });
    };

    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    onSelect = (value, item) => {
        if (this.isNumber(item.id)) {
            this.setState({
                request_to: item.id,
                orgsError: false,

            });


            let article = this.props.article_single

            if (article.publisher && article.publisher.id === item.id) {
                this.setPublicationContacts()
            } else {
                this.props.checkOrganisationAdmin(item.id).then(response => {
                    if (response && response.status) {
                        let recipients = response.recipients && response.recipients.map(email => createOption(email));

                        this.setState({
                            has_organisation_admin: response.has_admin,
                            recipients,
                        });
                    }
                }).catch(error => {
                    return error.response && error.response.data && error.response.data.success;
                });
            }
        }
    };


    onChange = (value) => {
        let data = this.props.orgs && this.props.orgs.find(item => item.name === value)
        if (this.isNumber(data && data.id)) {
            this.setState({
                request_to: data && data.id,
                orgsError: false,
            });
        } else {
            this.setState({
                orgsError: true,
                has_organisation_admin: true,
                org_name: value
            });
        }


    };

    onSearch = (value) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            await this.props.getPublicOrgSignup(value);
            this.setState({
                dataList: this.props.orgs && this.props.orgs.map(item => ({
                    id: item.id,
                    value: item.name,
                }))
            })
        }, 200);
    };


    validateEmail = (value) => {
        // eslint-disable-next-line
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let res = pattern.test(value && value.trim())

        if (res === true) {
            this.setState({
                has_organisation_admin: true,
            })
        } else {
            this.setState({
                has_organisation_admin: false,
            })
        }
    }
    ApcForm = (props) => {
        let { article_single, licence_list } = this.props;
        delete licence_list[3];
        if (this.state.showLicence) {
            return (
                <>
                    {ReactHtmlParser(this.props.licence_agreements)}
                    <div className="text-center">
                        <button
                            onClick={this.LicenceAgreement}
                            type="button"
                            className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                        >
                            I agree
            </button>
                        {!props.deleting && (
                            <button
                                className={`m-3 col-sm-3 btn btn-lg btn-${props.NoBtnType || "secondary"
                                    }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    clearInterval(t);
                                    props.onCancel ? props.onCancel() : props.ClearStatus();
                                }}
                            >
                                {props.NoText || "OK"}
                            </button>
                        )}
                        {props.waitingMsg && <p>{props.waitingMsg}</p>}
                    </div>
                </>
            );
        } else {
            if (article_single && article_single.id) {
                let dataArticle = [];
                article_single &&
                    article_single.id &&
                    [article_single].map((item) => {
                        return dataArticle.push({
                            id: item.id,
                            name: item.title,
                        });
                    });
                if (article_single && article_single.id) {
                    return (this.props.isLoading !== true ? <form className="apc-form">
                        <div className="form-group row">
                            <SelectBox
                                label="Article"
                                onChange={this.onChangeHandler}
                                field="article"
                                className="col-sm-10"
                                data={dataArticle}
                                defaultValue={this.props.article_id}
                                isRequired={true}
                                labelInfo={true}
                                labelInfoTitle="Select your Article "
                            />
                        </div>



                        <div className="form-group row">

                            <div className=" col-form-label col-sm-2">
                                <label htmlFor="request-to" className="label-request">Request to</label>

                                <div className="request-icon">
                                    <Tooltip title="Select the institution or organisation name from the dropdown. Your APC request will not be submitted properly if you do not select the organisation name from dropdown.">

                                        <InfoCircleTwoTone style={{
                                            fontSize: 18,
                                            marginLeft: 5
                                        }} twoToneColor="rgb(122, 111, 190)" className="signup-email"

                                        />
                                    </Tooltip>

                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="search-auto-complete">

                                    <AutoComplete
                                        defaultValue={this.state.request_id || (this.props.request_to && this.props.request_to.results && this.props.request_to.results[0].name)}
                                        notFoundContent={this.state.org_name && "The institution name you have typed has no match on OaMetrix."}
                                        options={this.state.dataList}
                                        style={{
                                            width: "100%",
                                        }}
                                        onSelect={(val, option) => this.onSelect(val, option)}
                                        onSearch={this.onSearch}
                                        onChange={this.onChange}
                                        placeholder="Type your institution or publisher name and select from the drop down"
                                    />
                                </div>

                                <div className="search-auto-spin">
                                    {this.props.loading && <Spin indicator={antIcon} />}
                                </div>
                                {this.state.orgsError
                                    && <span style={{ color: "red" }}>Please make sure  you have selected institution or publisher name from dropdown.
                                            </span>}

                            </div>
                        </div>




                        <div className="form-group row">

                            <div className=" col-form-label col-sm-2">
                                <label htmlFor="request-to" className="label-request">Recipient</label>

                                <div className="request-icon">
                                    <Tooltip title="Enter recipient.">

                                        <InfoCircleTwoTone style={{
                                            fontSize: 18,
                                            marginLeft: 15
                                        }} twoToneColor="rgb(122, 111, 190)" className="signup-email"

                                        />
                                    </Tooltip>

                                </div>
                            </div>
                            <div className="col-sm-10">
                                <CreatableSelect
                                    components={components}
                                    inputValue={this.state.inputValue}
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    onChange={this.handleChange}
                                    onInputChange={this.handleInputChange}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleBlurEvent}
                                    placeholder="johndoe@test.com (press Tab or Space Bar for multiple recipients)"
                                    value={this.state.recipients || []}
                                />
                            </div>
                        </div>

                        {this.state.has_organisation_admin === false &&
                            <span style={{ color: "red", display: "block", marginLeft: 120, marginBottom: 30 }}>
                                Your organisation does not appear to have an admin account with OaMetrix so they will need to request an account from <a href="mailto:support@oametrix.co.uk">support@oametrix.co.uk</a> before they can proceed. Please enter the email of your organisational contact in the box provided. Multiple email addresses should be separated by a comma</span>}



                        {licence_list && (
                            <>
                                {this.state.article_control_decision
                                    .is_author_licence_required && (
                                        <div className="form-group row">
                                            <label className="col-form-label col-sm-2">
                                                Licence
                                                    <span className="text-danger"> * </span>
                                            </label>
                                            <div className="col-sm-6">
                                                <Select
                                                    showSearch
                                                    style={{ width: "100%" }}
                                                    placeholder="Select licence"
                                                    optionFilterProp="children"
                                                    onChange={this.onLicenceChange}
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {licence_list.map((item) => (
                                                        <Option
                                                            value={JSON.stringify(item)}
                                                            key={item.name}
                                                        >
                                                            {item.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="col-sm-4  invoice-licence-btn">
                                                <button
                                                    type="button"
                                                    style={{ width: 95 }}
                                                    onClick={(e) =>
                                                        this.licencepdfdownload(
                                                            e,
                                                            this.state.downloadlink &&
                                                            this.state.downloadlink.link,
                                                            "License"
                                                        )
                                                    }
                                                    className="btn btn-primary btn-md waves-effect float-right"
                                                >
                                                    View
                        </button>
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}

                        <div className="form-group row">

                            <label htmlFor="note" className="col-form-label col-sm-2">
                                Message
                                </label>
                            <div className="col-sm-10">
                                <Ckditor
                                    onChange={this.onEditorChange}
                                    data={this.state.note}
                                    config={{
                                        height: "200px",
                                        toolbar: [
                                            ['Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'Link', 'Unlink', 'Format']
                                        ]
                                    }}
                                />
                            </div>
                        </div>



                        <div className="text-center">
                            <button
                                onClick={this.submitAuthorApcFund}
                                type="button"
                                className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                            >
                                Submit
                </button>
                            {!props.deleting && (
                                <button
                                    className={`m-3 col-sm-3 btn btn-lg btn-${props.NoBtnType || "secondary"
                                        }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearInterval(t);
                                        props.onCancel ? props.onCancel() : props.ClearStatus();
                                    }}
                                >
                                    {props.NoText || "OK"}
                                </button>
                            )}
                            {props.waitingMsg && <p>{props.waitingMsg}</p>}
                        </div>
                    </form> : <Loading type="flat" />
                    );
                }
            }
        }
    };

    LicenseForm = (props) => {
        return (
            <div className="Payment-article-input">
                {this.state.loading && <Loading />}
                <div className="row">
                    <div className="col-md-8 margin-top">
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select licence"
                            optionFilterProp="children"
                            onChange={this.onLicenceChange}
                            defaultValue="Select licence"
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.props.licence_list &&
                                this.props.licence_list.map((item) => (
                                    <Option value={JSON.stringify(item)} key={item.name}>
                                        {item.name}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                    <div className="col-md-3 col-sm-6  margin-top ">
                        <Button
                            text="View"
                            type="primary"
                            name="primary"
                            onClick={(e) =>
                                this.licencepdfdownload(
                                    e,
                                    this.state.downloadlink && this.state.downloadlink.link,
                                    "License"
                                )
                            }
                        />
                    </div>
                    <div className="col-md-12 col-sm-12  margin-top">
                        <Checkbox
                            onChange={this.onChangeChecked}
                            checked={this.state.enable}
                        />
                        <p className="publisher_terms">I agree with </p>
                        <Link
                            to="#"
                            onClick={(e) =>
                                this.licencepdfdownload(
                                    e,

                                    `${articlePublisherTerms}${this.props.license_article_id}/download/`,
                                    "TermsandCondition"
                                )
                            }
                        >
                            Publisher’s Terms & Conditions
            </Link>
                    </div>
                    <div className="col-sm-10" style={{ marginTop: 100 }}>
                        <button
                            onClick={this.submitLiceceFormUpdate}
                            type="button"
                            className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                        >
                            Submit
            </button>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                clearInterval(t);
                                props.onCancel ? props.onCancel() : props.ClearStatus();
                            }}
                            type="button"
                            className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                        >
                            Cancel
            </button>
                    </div>
                </div>
            </div>
        );
    };

    NoOaForm = (props) => {
        let { article_single } = this.props;
        if (this.state.showLicence) {
            return (
                <>
                    {ReactHtmlParser(this.props.licence_agreements)}
                    <div className="text-center">
                        <button
                            onClick={this.LicenceAgreement}
                            type="button"
                            className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                        >
                            I agree
                        </button>
                        {!props.deleting && (
                            <button
                                className={`m-3 col-sm-3 btn btn-lg btn-${props.NoBtnType || "secondary"
                                    }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    clearInterval(t);
                                    props.onCancel ? props.onCancel() : props.ClearStatus();
                                }}
                            >
                                {props.NoText || "OK"}
                            </button>
                        )}
                        {props.waitingMsg && <p>{props.waitingMsg}</p>}
                    </div>
                </>
            );
        } else if (article_single && article_single.id) {
            let dataArticle = [];
            let dataPublisher = [];
            article_single &&
                article_single.id &&
                [article_single].map((item) => {
                    return dataArticle.push({
                        id: item.id,
                        name: item.title,
                    });
                });
            article_single &&
                article_single.id &&
                [article_single.publisher].map((item) => {
                    return dataPublisher.push({
                        id: item.id,
                        name: item.name,
                    });
                });
            if (article_single && article_single.id) {
                return (

                    this.props.isLoading !== true ? <form className="apc-form">

                        {article_single && (
                            <div className="form-group row">
                                <SelectBox
                                    labelInfo={true}
                                    labelInfoTitle="Select Article"
                                    label="Article"
                                    onChange={this.onChangeHandler}
                                    field="article"
                                    className="col-sm-10"
                                    data={dataArticle}
                                    defaultValue={this.props.article_id}
                                    isRequired={true}
                                />
                            </div>
                        )}

                        {this.props.licence_non_oa && (
                            <>
                                {this.state.article_control_decision
                                    .is_author_licence_required && (
                                        <div className="form-group row">
                                            <label className="col-form-label col-sm-2">
                                                Licence
                                                <span className="text-danger"> * </span>
                                            </label>
                                            <div className="col-sm-6">
                                                <Select
                                                    showSearch
                                                    style={{ width: "100%" }}
                                                    placeholder="Select licence"
                                                    optionFilterProp="children"
                                                    onChange={this.onLicenceChange}
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {this.props.licence_non_oa.results.map((item) => (
                                                        <Option value={JSON.stringify(item)} key={item.name}>
                                                            {item.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="col-sm-4  invoice-licence-btn">
                                                <button
                                                    type="button"
                                                    style={{ width: 95 }}
                                                    onClick={(e) =>
                                                        this.licencepdfdownload(
                                                            e,
                                                            this.state.downloadlink &&
                                                            this.state.downloadlink.link,
                                                            "License"
                                                        )
                                                    }
                                                    className="btn btn-primary btn-md waves-effect float-right"
                                                >
                                                    View
                      </button>
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                        <div className="form-group row">

                            {dataPublisher ? (
                                <SelectBox
                                    labelInfo={true}
                                    labelInfoTitle="Select Request to"
                                    label="Request to"
                                    onChange={this.onChangeHandler}
                                    field="request_to"

                                    className="col-sm-10"
                                    isRequired={true}
                                    data={dataPublisher}
                                    defaultValue={dataPublisher ? dataPublisher[0] && dataPublisher[0].id : null}
                                />
                            ) : <Loading type="flat" />}
                        </div>



                        <div className="form-group row">
                            <div className=" col-form-label col-sm-2">
                                <label htmlFor="request-to" className="label-request">Recipient</label>

                                <div className="request-icon">
                                    <Tooltip title="Enter Recipient ">

                                        <InfoCircleTwoTone style={{
                                            fontSize: 18,
                                            marginLeft: 15
                                        }} twoToneColor="rgb(122, 111, 190)" className="signup-email"

                                        />
                                    </Tooltip>

                                </div>
                            </div>
                            <div className="col-sm-10">
                                <CreatableSelect
                                    components={components}
                                    inputValue={this.state.inputValue}
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    onChange={this.handleChange}
                                    onInputChange={this.handleInputChange}
                                    onKeyDown={this.handleKeyDown}
                                    onBlur={this.handleBlurEvent}
                                    placeholder="johndoe@test.com (press Tab or Space Bar for multiple recipients)"
                                    value={this.state.recipients || []}
                                />
                            </div>
                        </div>

                        {this.state.has_organisation_admin === false &&
                            <span style={{ color: "red", display: "block", marginLeft: 120, marginBottom: 30 }}>
                                The organisation you have selected has no admin account in OaMetrix, type the recipient’s email address. Email address should be comma separated for multiple recipients.</span>}


                        <div className="form-group row">

                            <label htmlFor="note" className="col-form-label col-sm-2">
                                Message
                            </label>
                            <div className="col-sm-10">
                                <Ckditor
                                    onChange={this.onEditorChange}
                                    data={this.state.note}
                                    config={{
                                        height: "200px",
                                        toolbar: [
                                            ['Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'Link', 'Unlink', 'Format']
                                        ]
                                    }}
                                />
                            </div>
                        </div>



                        <div className="text-center">
                            <button
                                onClick={this.submitAuthorApcNoOa}
                                type="button"
                                className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                            >
                                Submit
              </button>
                            {!props.deleting && (
                                <button
                                    className={`m-3 col-sm-3 btn btn-lg btn-${props.NoBtnType || "secondary"
                                        }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearInterval(t);
                                        props.onCancel ? props.onCancel() : props.ClearStatus();
                                    }}
                                >
                                    {props.NoText || "OK"}
                                </button>
                            )}
                            {props.waitingMsg && <p>{props.waitingMsg}</p>}
                        </div>
                    </form> : <Loading type="flat" />
                );
            }
        }
    };

    errorMessage = (data, required_fields, required_field_messages) => {
        let error_field = [];
        required_fields.map((value) => {
            if (!(value in data)) {
                error_field.push(required_field_messages[value]);
            }
            return error_field;
        });
        let errorMessage = ReactHtmlParser(error_field.join("<br>"));
        let errorLength = error_field.length;
        if (errorLength > 0) {
            return confirmAlert({
                message: errorMessage,
                buttons: [
                    {
                        label: "Close",
                        onClick: () => { },
                    },
                ],
            });
        }
        return errorLength;
    };

    errorCount = (data, required_fields, required_field_messages) => {
        let error_field = [];
        required_fields.map((value) => {
            if (!(value in data)) {
                error_field.push(required_field_messages[value]);
            }
            return error_field;
        });
        return error_field.length;
    };

    UpdatePaidForm = (props) => {
        return (
            <form className="apc-form">
                {props.acknowledge && (
                    <div className="form-group row">
                        <InputBox
                            label="Receiver note"
                            onChange={this.onChangeHandler}
                            field="receiver_note"
                            className="col-sm-10"
                            placeholder="Note"
                            defaultValue={this.state.receiver_note}
                        />
                    </div>
                )}
                {!props.acknowledge && (
                    <div className="form-group row">
                        <InputBox
                            multi={true}
                            rows={5}
                            label="Payer note(Required)"
                            onChange={this.onChangeHandler}
                            field="payer_note"
                            className="col-sm-10"
                            placeholder="Note"
                            defaultValue={this.state.payer_note}
                        />
                    </div>
                )}
                {!props.acknowledge && (
                    <div className="form-group row">
                        <InputBox
                            label="Transacton ref.(Optional)"
                            onChange={this.onChangeHandler}
                            field="transaction_ref"
                            className="col-sm-10"
                            placeholder="Transaction reference"
                            defaultValue={this.state.transaction_ref}
                        />
                    </div>
                )}

                <div className="text-center">
                    <button
                        onClick={this.submitInvoicePaymentStatus}
                        type="button"
                        className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                    >
                        Submit
          </button>
                    {!props.deleting && (
                        <button
                            className={`m-3 col-sm-3 btn btn-lg btn-${props.NoBtnType || "secondary"
                                }`}
                            onClick={(e) => {
                                e.preventDefault();
                                clearInterval(t);
                                props.onCancel ? props.onCancel() : props.ClearStatus();
                            }}
                        >
                            {props.NoText || "OK"}
                        </button>
                    )}
                    {props.waitingMsg && <p>{props.waitingMsg}</p>}
                </div>
            </form>
        );
    };

    sendEmailBtnText = (status = null) => {
        if (status === 'sending') {
            return 'Sending...';
        } else if (status === 'sent') {
            return 'Email Sent';
        }
        return 'Send Email';
    }

    sendInvoiceEmailHandler = async () => {
        this.setState({
            send_email_status: 'sending'
        });

        let data = {};
        if (this.state.invoice_email_address) {
            data.invoice_email_address = this.state.invoice_email_address;
        } else if (this.props.invoice_email_address) {
            data.invoice_email_address = this.props.invoice_email_address;
        }

        let result = await this.props.sendInvoiceEmail(data);

        if (result) {
            this.setState({
                send_email_status: 'sent'
            });
        } else {
            this.setState({
                send_email_status: null
            });
        }
    }

    invoiceEmailForm = (props) => {
        return (
            <div className="form-group row">
                <div className="col-md-9 col-sm-6">
                    <InputBox
                        className="col-md-12"
                        onChange={(value) => this.onChangeHandler(value)}
                        field="invoice_email_address"
                        placeholder="Enter email address"
                        defaultValue={
                            this.state.invoice_email_address || props.invoice_email_address || null
                        }
                    />
                </div>
                <div className="col-md-3 col-sm-6">
                    <button
                        onClick={!this.state.send_email_status ? this.sendInvoiceEmailHandler : null}
                        type="button"
                        className="btn btn-md btn-primary"
                        disabled={!!this.state.send_email_status}
                    >
                        {
                            this.sendEmailBtnText(this.state.send_email_status)
                        }
                    </button>
                </div>
            </div>
        );
    };

    submitInvoicePaymentStatus = async (e) => {
        e.preventDefault();
        let id = this.props.id;
        let payload = Object.assign(this.state.authorApcReq, {
            status: this.props.acknowledge ? "acknowledged" : "paid",
        });
        let required_fields = this.props.acknowledge
            ? ["receiver_note"]
            : ["payer_note"];
        let required_field_messages = this.props.acknowledge
            ? {
                receiver_note: "Receiver note is required",
            }
            : {
                payer_note: "Payer note is required",
                transaction_ref: "Transaction reference is required",
            };
        let errorCount = this.errorCount(
            payload,
            required_fields,
            required_field_messages
        );
        if (errorCount > 0) {
            this.errorMessage(payload, required_fields, required_field_messages);
        } else {
            await this.props.UpdateInvoice(id, payload);
            this.props.onCancel(id);
        }
    };

    SubmitInvoiceAckStatus = async (e) => {
        e.preventDefault();
        let id = this.props.id;
        let payload = Object.assign(this.state.authorApcReq, {
            status: "acknowledged",
        });
        let required_fields = ["receiver_note"];
        let required_field_messages = {
            receiver_note: "Receiver note is required",
        };
        let errorCount = this.errorCount(
            payload,
            required_fields,
            required_field_messages
        );
        if (errorCount > 0) {
            this.errorMessage(payload, required_fields, required_field_messages);
        } else {
            await this.props.UpdateInvoice(id, payload);
            this.props.onCancel(id);
        }
    };

    SubmitInvoiceCancelStatus = async (e) => {
        e.preventDefault();
        let id = this.props.id;
        let payload = Object.assign(this.state.authorApcReq, {
            status: "cancelled",
        });
        let required_fields = ["receiver_note"];
        let required_field_messages = {
            receiver_note: "Receiver note is required",
        };
        let errorCount = this.errorCount(
            payload,
            required_fields,
            required_field_messages
        );
        if (errorCount > 0) {
            this.errorMessage(payload, required_fields, required_field_messages);
        } else {
            await this.props.UpdateInvoice(id, payload);
            this.props.onCancel(id);
        }
    };

    onChangeHandler = async (value) => {
        if (this.state.extraFieldForm) {
            this.setState((prevState) => {
                return Object.assign(prevState.extraField, value);
            });
        } else {
            this.setState((prevState) => {
                return Object.assign(prevState.authorApcReq, value);
            });
        }
        if (value && value.recipients) {
            // this.validateEmail(value.recipients)
        }
        if (value && value.hasOwnProperty('invoice_email_address')) {
            this.setState({
                send_email_status: null
            });
        }

    };

    onChangeHandler2 = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.authorApcReq, value);
        });
    };


    onEditorChange = evt => {
        let value = {
            note: evt.editor.getData()
        };

        this.onChangeHandler(value);
    };

    AckPaymentForm = (props) => {
        return (
            <form className="apc-form">
                {
                    <div className="form-group row">
                        <InputBox
                            multi={true}
                            rows={5}
                            required={true}
                            label="Payment Acknowledgement Note"
                            onChange={this.onChangeHandler2}
                            field="receiver_note"
                            className="col-sm-10"
                            placeholder="Note"
                            defaultValue={this.state.receiver_note}
                        />
                    </div>
                }

                <div className="text-center">
                    <button
                        onClick={this.SubmitInvoiceAckStatus}
                        type="button"
                        className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                    >
                        Submit
          </button>
                    {!props.deleting && (
                        <button
                            className={`m-3 col-sm-3 btn btn-lg btn-${props.NoBtnType || "secondary"
                                }`}
                            onClick={(e) => {
                                e.preventDefault();
                                clearInterval(t);
                                props.onCancel ? props.onCancel() : props.ClearStatus();
                            }}
                        >
                            {props.NoText || "OK"}
                        </button>
                    )}
                    {props.waitingMsg && <p>{props.waitingMsg}</p>}
                </div>
            </form>
        );
    };

    CancelInvoiceForm = (props) => {
        return (
            <form className="apc-form">
                {
                    <div className="form-group row">
                        <InputBox
                            multi={true}
                            rows={5}
                            label="Invoice Cancel Note"
                            onChange={this.onChangeHandler2}
                            field="receiver_note"
                            className="col-sm-10"
                            placeholder="Note"
                            defaultValue={this.state.receiver_note}
                        />
                    </div>
                }

                <div className="text-center">
                    <button
                        onClick={this.SubmitInvoiceCancelStatus}
                        type="button"
                        className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light"
                    >
                        Submit
          </button>
                    {!props.deleting && (
                        <button
                            className={`m-3 col-sm-3 btn btn-lg btn-${props.NoBtnType || "secondary"
                                }`}
                            onClick={(e) => {
                                e.preventDefault();
                                clearInterval(t);
                                props.onCancel ? props.onCancel() : props.ClearStatus();
                            }}
                        >
                            {props.NoText || "OK"}
                        </button>
                    )}
                    {props.waitingMsg && <p>{props.waitingMsg}</p>}
                </div>
            </form>
        );
    };

    async componentDidMount() {
        this.props.license_article_id &&
            this.props.LicenseForm &&
            (await this.props.getLicenseList());
        if (this.props.article_id) {
            this.props.apcForm && (await this.props.getLicenseList());
            this.props.noOAForm && (await this.props.getLicenseListNonOa());
            let article_id = this.props.article_id;
            await this.props.getArticleFullSingleList(article_id);
            await this.props.getArticleRequiredField(article_id);
            await this.props.getArticleApcRequestOrganisation(article_id);

            let articleControlDecision = await this.props.getArticleControlDecision(
                article_id
            );
            articleControlDecision &&
                this.setState({
                    article_control_decision: articleControlDecision,
                });

            if (this.props.article_single) {
                let { article_single, apcForm, noOAForm } = this.props;

                if (article_single.publisher && (apcForm || noOAForm)) {
                    let requestToId = null;

                    if (noOAForm) {
                        requestToId = article_single.publisher.id;

                        this.setState({
                            request_to: article_single.publisher.id,
                        });
                    } else {
                        if (this.props.request_to && this.props.request_to.results && this.props.request_to.results.length > 0) {
                            requestToId = this.props.request_to.results[0].id;
                        }
                    }

                    let note = '';

                    if (article_single.article_id) {
                        note = `Article ID: ${article_single.article_id} `;
                    }

                    if (article_single.title) {
                        note = `${note} <br/>Article Title: ${article_single.title}`;
                    }

                    if (article_single.publication && article_single.publication.name) {
                        note = `${note} <br/>Publication Name: ${article_single.publication.name}<br/>`;
                    }

                    note = note.trim();

                    this.setState({
                        note,
                    });

                    if (requestToId) {
                        if (article_single.publisher && article_single.publisher.id === requestToId) {
                            this.setPublicationContacts()
                        } else {
                            this.props.checkOrganisationAdmin(requestToId).then(response => {
                                if (response && response.status) {
                                    let recipients = response.recipients && response.recipients.map(email => createOption(email));

                                    this.setState({
                                        has_organisation_admin: response.has_admin,
                                        recipients,
                                    });
                                }
                            }).catch(error => {
                                return error.response && error.response.data && error.response.data.success;
                            });
                        }
                    }
                }
            }
        }
        if (this.props.required_id) {
            await this.props.getArticleRequired(this.props.required_id);
            this.setState({
                article: this.props.article,
            });
        }
        this.setState({
            request_name: this.props.request_to && this.props.request_to.results && this.props.request_to.results[0].id


        })
    }

    render() {
        let props = this.props;
        let className = this.props.className ? this.props.className : "col-10 col-sm-8 col-lg-4";
        let showPopMessage;
        if (this.props.showpop) {
            showPopMessage = (
                <div
                    className="showmessage"
                    style={{
                        position: "fixed",
                        zIndex: 1002,
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                        top: 0,
                        left: 0,
                    }}
                >
                    <div
                        className={`${className} showpop-message text-center alert`}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            zIndex: 999999,
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <Icon type="close-circle" onClick={(e) => this.props.onClose(e)} />
                        <p>{props.showtitle}</p>
                    </div>
                </div>
            );
        } else {
            showPopMessage = (
                <div
                    style={{
                        position: "fixed",
                        zIndex: 1002,
                        backgroundColor: "rgba(0, 0, 0, 0.64)",
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                        top: 0,
                        left: 0,
                    }}
                >
                    <div
                        className={`${className} bg-white alert apc-request`}
                        style={{
                            position: "absolute",
                            padding: 45,
                            top: "50%",
                            left: "50%",
                            zIndex: 999999,
                            transform: "translate(-50%, -50%)",
                            height: this.state.showLicence ? "100%" : undefined,
                            paddingBottom: this.state.showLicence ? 50 : 45,
                        }}
                    >
                        {this.props.helpmenu && <div className="text-right">
                            <Popover placement="leftTop" content={content} trigger="click">

                                <Button
                                    type="primary"
                                    name="primary"
                                    text="Help"
                                    className="help-menu"
                                > Help
                </Button>
                            </Popover>


                        </div>}

                        {props.title && <h4 className="apc-header" style={{ textAlign: "center" }}>{props.title}</h4>}

                        <div className="message" style={{ textAlign: "center" }}>

                            {!props.upload && props.msg}
                            {props.upload && this.uploadStatus(props)}
                        </div>
                        <div className="message" style={{ textAlign: "center" }}>

                            <p>{props.first_name ? props.first_name : null}</p>
                            <p>{props.last_name ? props.last_name : null}
                            </p>
                            {
                                props.link ? <p>Please<a href="mailto:biplob@oametrix.io"> contact us</a> if you do not find your institution from the dropdown.</p>
                                    : null
                            }
                            <p>{props.email ? props.email : null}
                            </p>
                        </div>

                        <div>{props.apcForm && this.ApcForm(props)}</div>
                        <div>{props.LicenseForm && this.LicenseForm(props)}</div>
                        <div>{props.noOAForm && this.NoOaForm(props)}</div>
                        <div>
                            {props.update_intent &&
                                props.update_intent === "paid" &&
                                this.UpdatePaidForm(props)}
                        </div>
                        <div>
                            {props.update_intent &&
                                props.update_intent === "acknowledge_payment" &&
                                this.AckPaymentForm(props)}
                        </div>
                        <div>
                            {props.update_intent &&
                                props.update_intent === "cancel_invoice" &&
                                this.CancelInvoiceForm(props)}
                        </div>
                        <div>{props.licenceCurrency && this.licenceCurrency(props)}</div>
                        <div>{props.extraField && this.ExtraFieldForm(props)}</div>
                        <div>{props.keyField && this.KeyFieldForm(props)}</div>
                        <div>{props.invoice_email_status && this.invoiceEmailForm(props)}</div>
                        {this.props.successMesage &&
                            this.props.msgone &&
                            this.props.msgtwo && (
                                <div className="successmessage" style={{ textAlign: "center" }}>
                                    <h3
                                        style={{
                                            color: "rgb(0, 122, 206)",
                                        }}
                                    >
                                        {this.props.successMesage}
                                    </h3>
                                    <p style={{ marginTop: 25 }}>{this.props.msgone}</p>
                                    <p style={{ marginBottom: 10 }}>{this.props.msgtwo}</p>
                                </div>
                            )}

                        <div>
                            {this.props.iframeUrl}
                            {this.props.iframe && this.props.iframeUrl && (
                                <NewWindow url={this.props.iframeUrl} />
                            )}
                        </div>
                        <div
                            style={
                                this.props.extraField || this.props.keyField
                                    ? {
                                        marginTop: -24,
                                        marginRight: -16,
                                    }
                                    : {}
                            }
                            className={
                                this.props.extraField || this.props.keyField
                                    ? ""
                                    : "text-center"
                            }
                        >
                            {!props.deleting && props.YesText && (
                                <button
                                    className={`btn btn-${props.type || "primary"}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.onPressOK();
                                    }}
                                >
                                    {props.YesText || "Yes"}
                                </button>
                            )}

                            {props.cancel !== "payment" ? (
                                !props.LicenseForm &&
                                !props.hideOk &&
                                !props.apcForm &&
                                !props.noOAForm &&
                                !props.deleting && (
                                    <button
                                        style={
                                            this.props.extraField || this.props.keyField
                                                ? {
                                                    float: "right",
                                                    width: "45%",
                                                    height: 37,
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                }
                                                : { marginLeft: 10 }
                                        }
                                        className={
                                            this.props.extraField || this.props.keyField
                                                ? "m-3 col-sm-6 btn btn-secondary"
                                                : `btn
                                                btn-${props.NoBtnType || "primary"}`
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            clearInterval(t);
                                            props.onCancel ? props.onCancel() : props.ClearStatus();
                                        }}
                                    >
                                        {props.NoText || "Close"}
                                    </button>
                                )
                            ) : (
                                <button
                                    className="m-3 col-sm-4 btn btn-primary view"
                                    onClick={() => this.props.view()}
                                >
                                    Download
                                </button>
                            )}

                            {props.cancel === "payment" ? (
                                <button
                                    className="m-3 col-sm-4 btn btn-lg btn-danger cancel"
                                    onClick={() => this.props.history.push(this.props.auth && this.props.auth.group ==="PUBLISHER" ? {pathname:"/article/publisher-articles",filter:this.props.filter} : this.props.auth && this.props.auth.group  ==="AUTHOR" ?  {pathname:"/my-article",filter:this.props.filter} :{pathname:"/articles",filter:this.props.filter} )}
                                >
                                    View later
                                </button>
                            ) : null}
                            {props.waitingMsg && <p>{props.waitingMsg}</p>}
                        </div>
                    </div>
                </div>
            );
        }
        return showPopMessage;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,

    article_data: (state.articles && state.articles.article_full_all) || false,
    article_single: (state.articles && state.articles.article_single) || false,
    licence_list:
        (state.articles &&
            state.articles.licence_list &&
            state.articles.licence_list.results) ||
        false,
    licence_agreements:
        (state.licence &&
            state.licence.organisation_licence_agreements &&
            state.licence.organisation_licence_agreements.results[0] &&
            state.licence.organisation_licence_agreements.results[0]
                .licence_agreements) ||
        false,
    licence_agreements_download_link:
        (state.licence &&
            state.licence.organisation_licence_agreements &&
            state.licence.organisation_licence_agreements.results[0] &&
            state.licence.organisation_licence_agreements.results[0]
                .organisation_licence_download &&
            state.licence.organisation_licence_agreements.results[0]
                .organisation_licence_download.link) ||
        false,
    author_apc_fund_failed: state.articles.author_apc_fund_failed || false,
    licence_non_oa: state.articles.licence_non_oa || false,
    author_apc_fund_success: state.articles.author_apc_fund_success || false,
    article: state.articles.article_single,
    request_to: state.correction_request
        ? state.correction_request.article_apc_request_organistion
        : false,
    article_required: state.correction_request
        ? state.correction_request.article_required_field
        : false,
    upload_status:
        state.articles && typeof state.articles.track_upload_status !== "undefined"
            ? state.articles.track_upload_status
            : null,
    track_upload_status_download:
        state.articles.track_upload_status_download || false,
    licence_download: (state.payment && state.payment.invoice_download) || false,
    orgs: state.app.organizations,
    loading: state.orgs.loading,
    isLoading: state.articles.isLoading
});
const mapDispatchToProps = (dispatch) => ({
    ClearStatus: () => dispatch(ClearStatus()),
    getLicenseList: () => dispatch(getLicenseList()),
    OrganisationLicenseAgreements: (payload) =>
        dispatch(OrganisationLicenseAgreements(payload)),
    getLicenseListNonOa: () => dispatch(getLicenseListNonOa()),
    getArticleFullSingleList: (id) => dispatch(getArticleFullSingleList(id)),
    createAuthorApcFund: (payload) => dispatch(createAuthorApcFund(payload)),
    createAuthorApcNoOA: (payload) => dispatch(createAuthorApcNoOA(payload)),
    getArticleRequired: (id) => dispatch(getArticleRequired(id)),
    UpdateArticleRequired: (id, payload) =>
        dispatch(UpdateArticleRequired(id, payload)),
    getArticleRequiredField: (payload) =>
        dispatch(getArticleRequiredField(payload)),
    getArticleApcRequestOrganisation: (payload) =>
        dispatch(getArticleApcRequestOrganisation(payload)),
    getAllBookList: (payload) => dispatch(getAllBookList(payload)),
    trackArticleFileUploadStatus: (payload) =>
        dispatch(trackArticleFileUploadStatus(payload)),
    licensepdfdownload: (payload = {}) => dispatch(downloadInvoice(payload)),
    UpdateInvoice: (id, payload) => dispatch(UpdateInvoice(id, payload)),
    articleFullLicenceUpdate: (id, payload) =>
        dispatch(articleFullLicenceUpdate(id, payload)),
    getArticleControlDecision: (articelId) =>
        dispatch(getArticleControlDecision(articelId)),
    getPublicOrgSignup: (value) => dispatch(getPublicOrgSignup(value)),
    checkOrganisationAdmin: (id) => dispatch(checkOrganisationAdmin(id)),
});

export const PopupBox = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Popup)
);