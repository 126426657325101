import React from 'react'
import moment from "moment";

const Info = (props) => {
    let articleDetail = props && props.articleDetail
    let search_text = props.search_text
    return (
        <div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Publisher Article number:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {props.highlightSearchText(
                                articleDetail && articleDetail.article_id,
                                search_text,
                                true
                            )}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Document ID: </strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.meta_source_document_id}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Content type:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {props.highlightSearchText(
                                articleDetail && articleDetail.content_type_name,
                                search_text,
                                true
                            )}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Journal/Book:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.publication &&
                                props.highlightSearchText(
                                    articleDetail &&
                                    articleDetail.publication &&
                                    articleDetail.publication.name,
                                    search_text
                                )}
                        </p>
                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Publisher:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.publisher &&
                                props.highlightSearchText(
                                    articleDetail &&
                                    articleDetail.publisher &&
                                    articleDetail.publisher.name,
                                    search_text
                                )}
                        </p>
                    </div>
                </div>


                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>pISSN/eISBN:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.publication &&
                                props.highlightSearchText(
                                    articleDetail &&
                                    articleDetail.publication &&
                                    articleDetail.publication.pissn,
                                    search_text,
                                    true
                                )}
                        </p>
                    </div>
                </div>

            </div>



            <div className="row">

                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>eISSN/eISBN:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.publication &&
                                props.highlightSearchText(
                                    articleDetail &&
                                    articleDetail.publication &&
                                    articleDetail.publication.eissn,
                                    search_text,
                                    true
                                )}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Site Name:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.publication &&
                                articleDetail.publication.sub_sys_acronym}
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">



                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Acronym:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.publication &&
                                articleDetail.publication.pub_acronym}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Submission date:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.submission_date !== null
                                ? moment(
                                    articleDetail && articleDetail.submission_date
                                ).format("Do MMMM YYYY")
                                : null}
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">


                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Acceptance date:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail &&
                                articleDetail.acceptance_date !== null
                                ? moment(
                                    articleDetail && articleDetail.acceptance_date
                                ).format("Do MMMM YYYY")
                                : null}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Pub date:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail && articleDetail.pub_date !== null
                                ? moment(
                                    articleDetail && articleDetail.pub_date
                                ).format("Do MMM YY")
                                : null}
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Volume name:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail && articleDetail.vol_name}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Volume number:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail && articleDetail.vol_number}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">


                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Volume year:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail && articleDetail.vol_pub_year}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Issue number:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail && articleDetail.issue_number}
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">


                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Pages:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail && articleDetail.page_no_from}
                        </p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>DOI:</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            {articleDetail && articleDetail.doi}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export const ArticleInfo = Info