import { PubDashboard } from "../components/views/publisher/PubDashboard";
import {
  CreateOaDeals,
  OaPolicies,
  PublisherEmailConfig,
  // DipositApproval - Temporary Disabled,
  PaymentGateWay,
  DipositList,
  CorrectionApprovalList,
  // JournalList,
  PubAddUser,
  UpdateArticleFullEditing,
  Institutions,
  ArticleFull,
  ViewInstitution,
  OaToken,
  OrgOffsetFundList,
  OrganisationOffsetFund,
  OaDealsList,
  EditManualArticalForm,
  // CreatePublisherArticle,
  AuditMessage,
  UpdateBookSeries,
  ArticleAuditTrailLogs,
  AddPublicationForm,
  AddBookSeriesForm,
  // OaDealRequestsList,
  AddJournal,
  OaTokenList,
  EditJournal,
  SingleJournal,
  UserList,
  UpdateOaDeal,
  OrgList,
  ProfileUser,
  SingleTableView,
  SinglePayment,
  Singleinvoice,
  Singletransition,
  PaymentHistory,
  License,
  WaiverForm,
  TermsForm,
  GeographicalDiscountForm,
  EditWaiverForm,
  AddSocietyMember,
  SocietyMemberList,
  EditSocietyMember,
  SocietyMemberView,
  DiscountList,
  SocietyDiscountList,
  AddSocietyDiscount,
  EditSocietyDiscount,
  SocietyDiscountView,
  MemberShipDiscountList,
  AddMemberShipDiscount,
  EditMemberShipDiscount,
  MemberDiscountView,
  ArticleControlConfigList,
  AddArticleControlConfig,
  EditArticleControlConfig,
  ArticleControlConfigView,
  // RefundRequestList,
  PublisherHelpMenu,
} from "../components/views/publisher";
import {
  TrxDeposit,
  TrxOffset,
  TrxToken,
} from "../components/views/_transaction";
import {
  Vat,
  RefundRequestView,
  ApcRequestList,
} from "../../openaccess/components/views/university";
import {
  ArticleCustomEmailMessage,
  ArticleCustomEmail,
  ArticleOutBoxEmail,
} from "../../openaccess/components/views/user";

import {
  // AddVAT,
  // AddArticle,
  // EditVAT,
  // VATList,
  PubProgramList,
  // AddPubProgram,
  // EditPubProgram,
  // ViewProgram,
  ArticleManualPage,
  PublicationApcWaiverConfigList,
  AddPublicationApcWaiverConfig,
  EditPublicationApcWaiverConfig,
} from "../../openaccess/components/views/hub";

import AddPayment from "../components/partials/payment/addPayment.jsx";
import InvoiceHistory from "../components/partials/payment/inovicehistory.jsx";

import {
  AddBook,
  // BookList,
  AddOaPolicy,
  OaPolicyList,
  ArticleConfigForm,
  SingleBook,
  EmailForm,
} from "../components/views/hub";
import {
  Settings,
  Profile,
  VATNumberForm,
  viewNumberForm,
  LicenseList,
  VatViewForm,
  TermsConditionsList,
  ArticleButtonCustomization,
  editvatform,
  VatPage,
  PubDashboardControl,
} from "../components/views";
import CustomInvoiceTemplate from "../components/views/publisher/organizations/CustomInvoiceTemplate";
import { role, userKey } from "../components/common/userPermission";
export const PubRoot = {
  dashboard: "/",
  oa_deal_activities: "/oa-deal-activities",
  institutions: "/pub-institutions",
  report: "/report",
  token: "/token",
  users: "/pub-user",
  journal: "/journal",
  oa_target: "/oa-target",
  affiliated_organisations: "/affiliated-organisations",
  article: "/article",
  book: "/book",
  action: "/action",
  email: "/email",
  transaction: "/transaction",
  programs: "/pub-program",
  settings: "/settings",
  refund_request: "/refund-request",
  society_members: "/society-members",
  geographical_discount: "/geographical-discount",
  member_discounts: "/membership-discounts",
  article_control_configs: "/article-control-configs",
  audit: "/audit",
  payment: "/payment",
  custom_email: "/custom-email",
  offset_fund: "/publisher-offset-fund",
  help_menu: "/publisher-help-menu",
  non_oa_request: "/non-oa-request",
};

export const PubSidebar = [
  {
    icon: "mdi-pencil-box",
    label: "Publications",
    to: PubRoot.article,
    exact: true,
    role: role.public,
    value:
      userKey.manage_create_articles ||
      userKey.manage_failed_articles ||
      userKey.manage_articles_books,
    content: [
      {
        label: "Articles & Books",
        to: "/publisher-articles",
        component: ArticleFull,
        role: role.public,
        /// value: userKey.manage_articles_books,
      },

      {
        label: "Update articleFull",
        to: "/update-article/:id",
        component: UpdateArticleFullEditing,
        role: "public",
        isHide: true,
      },

      {
        label: "Add & View Publications",
        to: "/add-publications",
        component: AddPublicationForm,
        role: role.private,
        value: userKey.manage_create_articles,
      },
      {
        label: "Create Journal",
        to: "/create-journal",
        component: AddJournal,
        isHide: true,
        value: userKey.manage_journals,
        role: role.private,
      },
      {
        label: "Journal View",
        to: "/single-journal/:id",
        component: SingleJournal,
        isHide: true,
        role: role.public,
      },
      {
        label: "Edit Journals",
        to: "/edit-journal/:id",
        component: EditJournal,
        isHide: true,
        role: role.public,
      },
      {
        label: "Add Book",
        to: "/create-book",
        component: AddBook,
        isHide: true,
        value: userKey.manage_books,
        role: role.private,
      },
      {
        label: "Add Book Series",
        to: "/create-bookseries",
        component: AddBookSeriesForm,
        role: "public",
        isHide: true,
      },
      {
        label: "Edit Book Series",
        to: "/update-bookseries/:id",
        component: UpdateBookSeries,
        role: "public",
        isHide: true,
      },

      {
        label: "Failed Transfers",
        to: "/failed-transfers",
        component: ArticleManualPage,
        role: role.private,
        value: userKey.manage_failed_articles,
      },
      {
        label: "Edit Article",
        to: "/article-manual/edit/:id",
        component: EditManualArticalForm,
        isHide: true,
        value: userKey.manage_failed_articles,
      },

      {
        label: "singlebook",
        to: "/singlebooks/:id",
        component: SingleBook,
        isHide: true,
        role: role.public,
      },

      {
        label: "Journal View",
        to: "/book-series/:id",
        component: SingleJournal,
        isHide: true,
        role: role.public,
      },

      {
        label: "Edit Book",
        to: "/update-book/:id",
        component: AddBook,
        isHide: true,
        role: role.public,
      },
    ],
  },

  {
    icon: "mdi-view-dashboard",
    label: "Dashboard",
    role: role.public,
    to: "/dashboard",
    value: userKey.manage_dashboard,
    exact: true,
    component: PubDashboard,
  },

  {
    icon: "mdi-settings",
    label: "Settings",
    to: PubRoot.settings,
    exact: true,
    component: Settings,
    role: role.public,
  },
  {
    to: PubRoot.oa_deal_activities,
    exact: true,
    component: CreateOaDeals,
    isHide: true,
    value: userKey.manage_oa_deals,
    role: role.private,
  },
  {
    icon: "mdi-buffer",
    label: "OA Deal Activities",
    to: PubRoot.oa_deal_activities,
    exact: true,
    value: userKey.manage_oa_deals,
    role: role.private,
    content: [
      {
        label: "Create OA Deals",
        to: "/create-oa-deal",
        component: CreateOaDeals,
        value: userKey.manage_oa_deals,
        role: role.private,
      },

      // {
      //     label: 'OA Deal request List',
      //     to: '/oa-deal-request-list',
      //     component: OaDealRequestsList,
      //     showBadge: true,
      //     value: userKey.manage_oa_deals,
      //     role: role.private   ,

      // },
      // {
      //     label: "View oadeal request",
      //     to: "/oadeal-request/:id",
      //     component: SingleTableView,
      //     isHide: true,
      //     value: userKey.manage_oa_deals,
      //     role: role.private   ,

      // },
      // {
      //   label: "Update OA Deal Request",
      //   to: "/edit-oadeal-request/:id",
      //   component: UpdateOaDeal,
      //   isHide: true,
      //   value: userKey.manage_oa_deals,
      //   role: role.private,
      // },
      {
        label: "Update OA deal",
        to: "/edit-oa-deal/:id",
        component: UpdateOaDeal,
        isHide: true,
        value: userKey.manage_oa_deals,
        role: role.private,
      },

      {
        label: "Deals & Payment Update",
        to: "/oa-deals-table",
        component: OaDealsList,
        value: userKey.manage_oa_deals,
        role: role.private,
      },

      // {
      //   label: "Deposit Approval",
      //   to: "/deposite-approval",
      //   component: DipositApproval,
      //   value: userKey.manage_oa_deals,
      //   role: role.private,
      // },
      {
        label: "Deposits",
        to: "/deposite-list",
        component: DipositList,
        value: userKey.manage_oa_deals,
        role: role.private,
      },
      {
        label: "Correction Single",
        to: "/oa-deal/:id",
        component: SingleTableView,
        isHide: true,
        value: userKey.manage_oa_deals,
        role: role.private,
      },

      {
        label: "Corrections",
        to: "/correction-approval-list/",
        component: CorrectionApprovalList,
        value: userKey.manage_oa_deals,
        role: role.private,
      },
      // {
      //   label: "Refund Requests",
      //   to: "/refund-request-list",
      //   component: RefundRequestList,
      //   value: userKey.manage_oa_deals,
      //   role: role.private,
      // },
      {
        label: "View Refund Request",
        to: "/view-refund-request/:id",
        component: RefundRequestView,
        isHide: true,
        value: userKey.manage_oa_deals,
        role: role.private,
      },
    ],
  },

  // {
  //   icon: " mdi-book-multiple",
  //   label: "Journals",
  //   value: userKey.manage_journals,
  //   to: PubRoot.journal,
  //   role: role.public,
  //   content: [
  //     {
  //       label: "Add Journal",
  //       to: "/add-journals",
  //       component: AddJournal,
  //       value: userKey.manage_journals,
  //       role: role.private,
  //     },
  //     {
  //       label: "Journals List",
  //       to: "/journals-list",
  //       component: JournalList,
  //       role: role.public,
  //     },
  //     {
  //       label: "Edit Journals",
  //       to: "/edit-journal/:id",
  //       component: EditJournal,
  //       isHide: true,
  //       value: userKey.manage_journals,
  //       role: role.private,
  //     },
  //     {
  //       label: "Journal View",
  //       to: "/single-journal/:id",
  //       component: SingleJournal,
  //       isHide: true,
  //       role: role.public,
  //     },
  //   ],
  // },

  {
    icon: " mdi-bank",
    label: "Institutions",
    to: PubRoot.institutions,
    exact: true,
    component: Institutions,
    role: role.private,
    value: userKey.manage_institutions,
  },
  {
    label: "Institutions",
    to: PubRoot.institutions + "/:id",
    component: ViewInstitution,
    isHide: true,
    role: role.private,
    value: userKey.manage_institutions,
  },
  {
    to: PubRoot.article,
    component: ArticleFull,
    isHide: true,
    role: "public",
  },

  {
    icon: "mdi-account-multiple",
    label: "Payment",
    exact: true,
    isHide: true,
    to: PubRoot.payment,
    content: [
      {
        isHide: true,
        to: "/addpayment/:id",
        component: AddPayment,
      },
    ],
  },
  // {
  //   icon: "mdi-book",
  //   label: "Books",
  //   to: PubRoot.book,
  //   role: role.public,
  //   value: userKey.manage_books,
  //   exact: true,
  //   content: [
  //     {
  //       label: "Add Book",
  //       to: "/addbook",
  //       component: AddBook,
  //       value: userKey.manage_books,
  //       role: role.private,
  //     },
  //     {
  //       label: "Edit Article",
  //       to: "/article-manual/edit/:id",
  //       component: AddBook,
  //       isHide: true,
  //       value: userKey.manage_books,
  //       role: role.private,
  //     },
  //     {
  //       label: "Books List",
  //       to: "/booklist",
  //       component: BookList,
  //       role: role.public,
  //     },
  //     {
  //       label: "singlebook",
  //       to: "/singlebooks/:id",
  //       component: SingleBook,
  //       isHide: true,
  //       value: userKey.manage_books,
  //       role: role.public,
  //     },
  //   ],
  // },
  {
    exact: true,
    to: PubRoot.transaction,
    role: "public",
    component: TrxDeposit,
    isHide: true,
  },
  {
    icon: "mdi-white-balance-incandescent",
    label: "Transactions",
    exact: true,
    to: PubRoot.transaction,
    role: role.public,
    value:
      userKey.manage_deposit_fund ||
      userKey.manage_offset ||
      userKey.manage_transations_token ||
      userKey.manage_card_payment ||
      userKey.manage_transatction_invoice,
    content: [
      {
        label: "Deposit Fund",
        to: "/deposit-fund",
        component: TrxDeposit,
        role: role.private,
        value: userKey.manage_deposit_fund,
      },
      {
        label: "Offset Fund",
        to: "/offset-fund",
        component: TrxOffset,
        role: role.private,
        value: userKey.manage_offset,
      },

      {
        label: "Tokens",
        to: "/Token-list",
        component: TrxToken,
        role: role.private,
        value: userKey.manage_transations_token,
      },

      {
        label: "singletransition",
        to: "/viewtransition",
        component: Singletransition,
        isHide: true,
        role: "public",
      },
      {
        label: "Card Payment ",
        to: "/paymenthistory",
        component: PaymentHistory,
        role: role.private,
        value: userKey.manage_card_payment,
      },
      {
        label: "Invoices ",
        to: "/invoice",
        component: InvoiceHistory,
        value: userKey.manage_transatction_invoice,
        role: role.private,
      },
      {
        label: "single Invoice payment ",
        to: "/singleinvoice/:id",
        isHide: true,
        component: Singleinvoice,
        role: "public",
      },
      {
        to: "/singlepayment/:id",
        label: "singlePayment",
        isHide: true,
        component: SinglePayment,
        role: "public",
      },
    ],
  },
  {
    icon: "mdi-email",
    label: "Emails",
    exact: true,
    to: PubRoot.custom_email,
    role: role.public,
    value: userKey.manage_email_inbox || userKey.manage_email_outbox,
    content: [
      {
        label: "Inbox ",
        to: "/email-custom-log",
        component: ArticleCustomEmail,
        value: userKey.manage_email_inbox,
        role: role.private,
      },
      {
        label: "Outbox",
        to: "/email-outbox-list",
        component: ArticleOutBoxEmail,
        value: userKey.manage_email_outbox,
        role: role.private,
      },
      {
        isHide: true,
        to: "/custom-email-message/:id",
        component: ArticleCustomEmailMessage,
      },
    ],
  },
  {
    icon: "mdi-book-multiple",
    label: "Non OA request",
    to: PubRoot.non_oa_request,
    component: ApcRequestList,
    exact: true,
    value: userKey.manage_non_oa_request,
    role: role.private,
  },
  {
    to: PubRoot.token,
    role: role.public,
    value: userKey.manage_token,
    exact: true,
    isHide: true,
    component: OaToken,
  },
  {
    icon: "mdi-key-variant",
    label: "Tokens",
    to: PubRoot.token,
    role: role.public,
    value: userKey.manage_token || userKey.manage_token_list,
    exact: true,
    content: [
      {
        label: "Generate Tokens",
        to: "/oa-token",
        component: OaToken,
        exact: true,
        value: userKey.manage_token,
        role: role.private,
      },
      {
        label: "Generated Tokens List",
        to: "/token-list",
        component: OaTokenList,
        exact: true,
        role: role.private,
        value: userKey.manage_token_list,
      },
    ],
  },

  {
    icon: " mdi-bank",
    label: "Offset Funds",
    exact: true,
    value: userKey.manage_offset || userKey.manage_offset_list,
    role: role.private,
    to: PubRoot.offset_fund,
    content: [
      {
        label: "Allocate Offset Funds",
        to: "/allocate-offset-fund",
        component: OrganisationOffsetFund,
        value: userKey.manage_offset,
        role: role.private,
      },
      {
        label: "Allocated Offset Funds",
        to: "/offset-fund-list",
        component: OrgOffsetFundList,
        role: role.private,
        value: userKey.manage_offset_list,
      },
      /*  {
        to: "/offset-fund-list",
        component: singleOffsetView,
        role: role.public,
      },
      {
        to: "/update-offset-fund",
        component: UpdateOffsetFund,
        role: role.public,
      },

	  */
    ],
  },
  {
    icon: "mdi-book",
    label: "Audit",
    to: PubRoot.audit,
    role: role.public,
    exact: true,
    isHide: true,
    content: [
      {
        to: "/audit-trail/:id",
        component: ArticleAuditTrailLogs,
        role: role.public,
        isHide: true,
      },
      {
        to: "/audit-message",
        component: AuditMessage,
        role: "public",
        isHide: true,
      },
    ],
  },
  // // {
  // //     icon: "mdi-target",
  // //     label: "OA Target",
  // //     to: PubRoot.oa_target,
  // //     exact: true,
  // //     content: [
  // //         {
  // //             label: "Add OA target",
  // //             to: "/create-oa-target",
  // //             component: AddOaTarget,
  // //             exact: true
  // //         },
  // //         {
  // //             label: "OA target list",
  // //             to: "/oa-target-list",
  // //             component: OaTargetList,
  // //             exact: true
  // //         }
  // //     ]
  // // },

  {
    to: PubRoot.settings,
    isHide: true,
    role: role.public,
    content: [
      {
        label: "Discount List",
        to: "/discount-list",
        component: DiscountList,
        exact: true,
      },
      {
        label: "Add Apc Waiver Config",
        to: "/create-apc-waiver-config",
        component: AddPublicationApcWaiverConfig,
        exact: true,
        isHide: true,
      },
      {
        label: "Edit Apc Waiver Config",
        to: "/update-apc-waiver/:id",
        component: EditPublicationApcWaiverConfig,
        exact: true,
        isHide: true,
      },

      {
        label: "Apc Waiver Config List",
        to: "/apc-config-list",
        component: PublicationApcWaiverConfigList,
        exact: true,
        isHide: true,
      },
      {
        to: "/author-notification-list",
        component: ArticleControlConfigList,
        isHide: true,
      },
      {
        label: "Article action",
        to: "/article-action/:id",
        component: ArticleButtonCustomization,
        role: "public",
      },
      {
        label: "Article action",
        to: "/article-action",
        component: ArticleButtonCustomization,
        role: "public",
      },
      {
        to: "/list",
        component: Settings,
        isHide: true,
        role: role.public,
      },
      {
        to: "/publisher-article-transfer-config",
        component: ArticleConfigForm,
        isHide: true,
        role: role.public,
      },
      {
        to: "/publisher-dashboard-control",
        component: PubDashboardControl,
        isHide: true,
        role: role.public,
      },
      {
        to: "/create-vat-configuration",
        component: VATNumberForm,
        isHide: true,
      },
      {
        to: "/vat-configuration-view/:id",
        component: VatViewForm,
        isHide: true,
      },
      {
        to: "/vat-configuration",
        component: VatPage,
        isHide: true,
      },
      {
        to: "/update-vat/:id",
        component: editvatform,
        isHide: true,
      },

      {
        to: "/license-configuration",
        component: LicenseList,
        isHide: true,
      },
      {
        to: "/waiver-policy",
        component: PubProgramList,
        isHide: true,
      },
      {
        to: "/add-waiver-policy",
        component: WaiverForm,
        isHide: true,
      },
      {
        to: "/add-geographical-discount/",
        component: GeographicalDiscountForm,
        isHide: true,
      },
      {
        to: "/edit-waiver-policy/:id",
        component: EditWaiverForm,
        isHide: true,
      },
      {
        to: "/termsconditions-configuration",
        component: TermsConditionsList,
        isHide: true,
      },
      {
        to: "/invoice-configuration",
        component: CustomInvoiceTemplate,
        isHide: true,
      },
      {
        to: "/apps",
        component: PaymentGateWay,
        isHide: true,
      },
      {
        to: "/profile/:id",
        component: Profile,
        isHide: true,
        role: role.public,
      },
      {
        to: "/addvat",
        component: Vat,
      },
      {
        to: "/email-configuration",
        component: PublisherEmailConfig,
      },
      {
        to: "/create-license",
        component: License,
        isHide: true,
      },
      {
        to: "/create-terms-conditions",
        component: TermsForm,
        isHide: true,
      },
      {
        to: "/create-email",
        component: EmailForm,
        isHide: true,
      },
      {
        to: "/update-email/:id",
        component: EmailForm,
        isHide: true,
      },
      {
        to: "/edit-terms-conditions/:id",
        component: TermsForm,
        isHide: true,
      },
      {
        to: "/update-license/:id",
        component: License,
        isHide: true,
      },
      {
        to: "/society-discount-list",
        component: SocietyDiscountList,
        isHide: true,
      },
      {
        to: "/add-society-discount",
        component: AddSocietyDiscount,
        isHide: true,
      },
      {
        to: "/edit-society-discount/:id",
        component: EditSocietyDiscount,
        isHide: true,
      },
      {
        to: "/view-society-discount/:id",
        component: SocietyDiscountView,
        isHide: true,
      },
    ],
  },

  {
    icon: "mdi-application",
    label: "Article Control Config",
    to: PubRoot.article_control_configs,
    exact: true,
    isHide: true,
    content: [
      {
        to: "/create-author-notification",
        component: AddArticleControlConfig,
        isHide: true,
      },
      {
        to: "/update-author-notification/:id",
        component: EditArticleControlConfig,
        isHide: true,
      },
      {
        to: "/article-author-notification/:id",
        component: ArticleControlConfigView,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "MemberShip Discount",
    to: PubRoot.member_discounts,
    exact: true,
    isHide: true,

    content: [
      {
        to: "/member-discount-list",
        component: MemberShipDiscountList,
        isHide: true,
      },
      {
        to: "/membership-society-discount",
        component: AddMemberShipDiscount,
        isHide: true,
      },
      {
        to: "/edit-membership-discount/:id",
        component: EditMemberShipDiscount,
        isHide: true,
      },
      {
        to: "/view-membership-discount/:id",
        component: MemberDiscountView,
        isHide: true,
      },
    ],
  },
  {
    role: role.public,
    to: PubRoot.affiliated_organisations,
    component: OrgList,
    isHide: true,
  },
  {
    icon: "ion-university",
    label: "Affiliated organisations",
    to: PubRoot.affiliated_organisations,
    value: userKey.manage_affiliated_orgs,
    role: role.private,
    content: [
      {
        label: "Affiliated Organisations List",
        to: "/affiliated-organisation-list",
        component: OrgList,
        role: role.private,
        value: userKey.manage_affiliated_orgs,
      },
    ],
  },
  // // {
  // //     icon: "mdi-percent",
  // //     label: "VAT",
  // //     to: "/vat",
  // //     content: [
  // //         {
  // //             label: "Add VAT",
  // //             to: "/add-vat",
  // //             component: AddVAT
  // //         },
  // //         {
  // //             to: "/edit-vat/:id",
  // //             component: EditVAT,
  // //             isHide: true
  // //         },
  // //         {
  // //             label: "VAT list",
  // //             to: "/vat-list",
  // //             component: VATList
  // //         }
  // //     ]
  // // },
  {
    icon: "mdi-account-multiple",
    label: "Users",
    to: PubRoot.users,
    value: userKey.manage_users,
    role: role.public,
    isHide: true,
    content: [
      {
        label: "Add user",
        to: "/add-user",
        component: PubAddUser,
        value: userKey.manage_users,
        role: role.private,
      },
      {
        label: "User list",
        to: "/user-list",
        component: UserList,
        role: role.public,
      },
      {
        label: "View User",
        to: "/user/:id",
        component: ProfileUser,
        isHide: true,
        role: role.public,
      },
      {
        label: "View User",
        to: "/vat/list",
        component: viewNumberForm,
        isHide: true,
        value: userKey.manage_users,
        role: role.private,
      },
    ],
  },
  {
    to: PubRoot.society_members,
    exact: true,
    value: userKey.manage_discount,
    role: role.public,
    component: AddSocietyMember,
    isHide: true,
  },
  {
    icon: "mdi-application",
    label: "Society Fellows",
    to: PubRoot.society_members,
    exact: true,
    value:
      userKey.manage_society_fellows || userKey.manage_society_fellows_list,
    role: role.public,
    content: [
      {
        label: "Add Society Member",
        to: "/add-society-member",
        component: AddSocietyMember,
        value: userKey.manage_society_fellows,
        role: role.private,
        exact: true,
        isHide: true,
      },
      {
        label: "Society Fellow List",
        to: "/society-member-list",
        component: SocietyMemberList,
        exact: true,
        value: userKey.manage_society_fellows_list,
        role: role.private,
      },
      {
        label: "Edit Society Member",
        to: "/edit-society-member/:id",
        component: EditSocietyMember,
        exact: true,
        isHide: true,
        value: userKey.manage_society_fellows,
        role: role.public,
      },
      {
        label: "View Society Member",
        to: "/view-society-member/:id",
        component: SocietyMemberView,
        exact: true,
        value: userKey.manage_discount,
        role: role.public,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "Geographical Discount",
    to: PubRoot.geographical_discount,
    exact: true,
    isHide: true,
    content: [
      {
        label: "Geographical Discount",
        to: "/discount-edit/:id",
        component: GeographicalDiscountForm,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "OA Policy",
    to: "/oa-policy",
    isHide: true,
    // key:userPermissionKey.corrections,
    content: [
      {
        label: "Add OA Policy",
        to: "/add-oa-policy",
        component: AddOaPolicy,
      },
      {
        label: "OA Policy list",
        to: "/oa-policy-list",
        component: OaPolicyList,
      },
      {
        label: "OA policies",
        to: "/oa-policy",
        component: OaPolicies,
        isHide: true,
      },
      {
        label: "Correction Single",
        to: "/correctiontablelist/:id",
        component: SingleTableView,
        isHide: true,
        // value:userPermissionValue.can_edit
      },
    ],
  },
  {
    icon: "mdi-comment-question-outline",
    label: "Help",
    to: PubRoot.help_menu,
    exact: true,
    component: PublisherHelpMenu,
    role: role.public,
  },
];
