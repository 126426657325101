import React from "react";
import moment from "moment";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  InputBox,
  SelectBox,
  RadioButtonGroup,
  OaDatePicker,
  Button,
  PopupBox,
  ModalSelectBox,
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import { serialize } from "../../../../../core/lib";

import {
  getAllCurrencyList,
  getPublicatonTypeList,
  getPublicationList,
  getContentTypeList,
  getOaDisciplineList,
  getOaSubjectList,
  getSocietyList,
  createUpdateSocietyDiscount,
} from "../../../../actions";

const format = "Do MMM YYYY";

const offerTypes = [
  { id: "DISCOUNT", name: "Discount" },
  { id: "AMOUNT", name: "Amount" },
  { id: "SPECIAL_APC", name: "Special APC" },
];

class Society extends BaseComponent {
  state = {
    societyDiscount: {},
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.societyDiscount.publication_types &&
      this.state.societyDiscount.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.societyDiscount.publication_types[0] !== "all"
          ? this.state.societyDiscount.publication_types.join(",")
          : "";
    }

    if (this.state.disciplines && this.state.disciplines.length > 0) {
      filterParams.discipline_ids =
        this.state.disciplines[0] !== "all"
          ? this.state.disciplines.join(",")
          : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publication_list: this.props.publication_list.results,
      });
  };

  onChangeHandler = async (value) => {
    if (value.hasOwnProperty("offer_type")) {
      this.setState({
        ...value,
      });
    }

    this.setState(
      (prevState) => {
        if (value.offer_expiry) {
          value.offer_expiry = moment(value.offer_expiry).format("YYYY-MM-DD");
        }

        if (value.hasOwnProperty("offer_type")) {
          Object.assign(prevState.societyDiscount, { offer_currency: null });
        }

        Object.assign(prevState.societyDiscount, value);
      },
      () => {
        if (value.hasOwnProperty("publication_types")) {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  async componentDidMount() {
    this.props.getSocietyList();
    this.props.getOaDisciplineList();
    this.props.getAllCurrencyList();
    this.props.getPublicatonTypeList();
    this.props.getContentType();

    let payload = {};
    await this.props.getOaSubjectList(payload);

    this.props.oa_subjects &&
      this.setState({
        oa_subject_list: this.props.oa_subjects.results,
      });

    this.getPublicationListOnParameterChange();
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.societyDiscount, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });

        if (fieldName === "disciplines") {
          if (data.length > 0) {
            let payload = {
              discipline: data[0] !== "all" ? data.join(",") : "",
            };

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }

          this.getPublicationListOnParameterChange();
        } else if (fieldName === "subjects") {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "disciplines":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "subjects":
        this.setState({
          isShowSubjectModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "disciplines":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} discipline selected`
              : `${fieldValues.length} disciplines selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Disciplines"
              : "Select Discipline";
        }
        break;
      case "subjects":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} subject selected`
              : `${fieldValues.length} subjects selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Subjects"
              : "Select Subject";
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Publication type selected`
              : `${fieldValues.length} Publication types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Publications type selected"
              : "Select Publications type";
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} content type selected`
              : `${fieldValues.length} content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Content types"
              : "Select Content type";
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  onChangeRadioButtonHandler = (value) => {
    this.setState((prevState) => {
      let options = {};
      if (prevState.societyDiscount.options) {
        options = prevState.societyDiscount.options;
      }

      Object.assign(options, value);

      Object.assign(prevState.societyDiscount, { options: { ...options } });
    });
  };
  onChangeRadioBadageHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.societyDiscount, { ...value });
    });
  };


  onSubmitHandler = async (e) => {
    e.preventDefault();
    let data = {};
    Object.assign(data, this.state.societyDiscount);


    let mainData;
    if (this.state.checked === true) {
      mainData = {
        ...data.options,
        option_2: "na",
      };
    } else {
      mainData = data.options
    }
    if (
      !data.society ||
      !data.offer_type ||
      (this.state.societyDiscount.offer_type !== "DISCOUNT" &&
        !data.offer_currency) ||
      !data.offer_amount
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!data.society && <p>Society is required.</p>}
            {!data.offer_type && <p>Offer type is required.</p>}
            {!data.offer_currency && <p>Currency is required.</p>}
            {!data.offer_amount && <p>Amount is required.</p>}
          </div>
        ),
      });
    } else {


      if (mainData !== undefined && Object.keys(mainData).length > 0) {
        data.options = JSON.stringify(mainData);
      } else {
        data.options = null;
      }

      await this.props.createUpdateSocietyDiscount(data);

      if (this.props.society_discount.success) {
        this.props.history.push("/settings/society-discount-list");
      }
    }
  };

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });
  };



  render() {

    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.push("/settings/society-discount-list")}
                />
              </div>
              <div className="card-body membership">
                <div className="tab-content">
                  <form action="">
                    <div className="form-group row">
                      <SelectBox
                        label="Society"
                        className="col-sm-8 col-md-3 pb-sm-3 pb-md-0"
                        labelClass="col-sm-4 col-md-2"
                        onChange={this.onChangeHandler}
                        field="society"
                        data={
                          this.props.societies
                            ? this.props.societies.results
                            : []
                        }
                        labelInfo={true}
                        labelInfoTitle="Select Society"
                        isRequired={false}
                        placeholder="Select society"
                        defaultValue={this.state.society}
                      />
                      <SelectBox
                        labelInfo={true}
                        labelInfoTitle="Select Publication Types"
                        label="Publication types"
                        onChange={this.onChangeHandler}
                        field="publication_types"
                        className="col-sm-8 col-md-4"
                        labelClass="col-sm-4 col-md-3"
                        data={this.props.publication_type}
                        defaultValue={this.state.publication_types}
                        isRequired={false}
                        multiple={true}
                      />
                    </div>

                    <div className="form-group row align-items-center">
                      <label className=" col-form-label col-sm-2 py-0">
                        Disciplines
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("disciplines")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("disciplines")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowDisciplineModal && (
                        <ModalSelectBox
                          title="Select disciplines"
                          label="Disciplines"
                          field="disciplines"
                          modalClass="col-10 col-md-8 col-lg-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            this.props.oa_disciplines && [
                              { id: "all", name: "All disciplines" },
                              ...this.props.oa_disciplines.results,
                            ]
                          }
                          defaultValue={this.state.disciplines}
                          isRequired={false}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <label className=" col-form-label col-sm-2 py-0">
                        Subjects{" "}
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("subjects")}
                        <button
                          onClick={() => this.onModalSelectBoxShow("subjects")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowSubjectModal && (
                        <ModalSelectBox
                          title="Select subjects"
                          label="Subjects"
                          field="subjects"
                          modalClass="col-10 col-md-8 col-lg-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            (this.state.oa_subject_list && [
                              { id: "all", name: "All subjects" },
                              ...this.state.oa_subject_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.subjects}
                          isRequired={true}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>
                    <div className="form-group row  align-items-center">
                      <label className=" col-form-label col-sm-2 py-0">
                        Content types
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("content_types")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("content_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowContentTypeModal && (
                        <ModalSelectBox
                          title="Select content types"
                          label="Content types"
                          field="content_types"
                          modalClass="col-10 col-md-8 col-lg-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            (this.props.content_type && [
                              { id: "all", name: "All content types" },
                              ...this.props.content_type,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.content_types}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                      <label className=" col-form-label col-sm-2 py-0">
                        Publications
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("publications")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publications")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationModal && (
                        <ModalSelectBox
                          title="Select publications"
                          label="Publications"
                          field="publications"
                          modalClass="col-10 col-md-8 col-lg-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            (this.state.publication_list && [
                              { id: "all", name: "All publications" },
                              ...this.state.publication_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.publications}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>
                    <div className="form-group row">
                      <SelectBox
                        label="Offer"
                        labelInfo={true}
                        labelInfoTitle="Select Offer Type"
                        onChange={this.onChangeHandler}
                        field="offer_type"
                        data={offerTypes}
                        isRequired={false}
                        defaultValue={this.state.offer_type}
                      />
                      {this.state.offer_type === "DISCOUNT" ? (
                        <label className="col-form-label col-sm-2">%</label>
                      ) : (
                          <SelectBox
                            labelInfo={true}
                            labelInfoTitle="Select Currency"
                            label="Currency"
                            placeholder="Select your Currency"
                            onChange={this.onChangeHandler}
                            field="offer_currency"
                            data={this.props.currency_list}
                            isRequired={false}
                            defaultValue={this.state.offer_currency}
                          />
                        )}
                    </div>
                    <div>
                      <div className="form-group row">
                        <InputBox
                          label="Amount/Dis"
                          onChange={this.onChangeHandler}
                          field="offer_amount"
                          labelInfo={true}

                          labelInfoTitle="Enter  Amount/Dis (No percent sign or decimal)"
                          placeholder="Amount/Dis (No percent sign or decimal) "
                          defaultValue={this.state.offer_amount}
                          InputType="number"
                        />

                        <OaDatePicker
                          className="col-sm-4"
                          label="Offer expiry"
                          field="offer_expiry"
                          labelInfo={true}
                          labelInfoTitle="Select Offer expiry"
                          onChange={this.onChangeHandler}
                          placeholder="Select offer expiry"
                          showTime={false}
                          format={format}
                          
                        />
                      </div>
                    </div>

                    <div className="form-group row vat-question-panel society d-flex justify-content-between">
                      <div className="w-75">
                        <p>
                          Would you like to display society discount icon for authors in article?
                        </p>
                      </div>
                      <div className="w-25">
                        <RadioButtonGroup
                          onChange={this.onChangeRadioBadageHandler}
                          field="show_badge"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.show_badge}
                        />
                      </div>
                    </div>
                    {/*
                    <div className="form-group row vat-question-panel society d-flex justify-content-between">
                      <div className="w-75">


                        <p>
                          Would you apply a Society or Society member discount
                          or a special price if corresponding author claims to
                          be a member of the above society, but Oametrix could
                          not cross check this information?
                        </p>
                      </div>
                      <div className="w-25">
                        <RadioButtonGroup
                          onChange={this.onChangeRadioButtonHandler}
                          field="option_1"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.option_1}
                        />
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society d-flex justify-content-between">
                      <div className="w-75">
                        <p>
                          In the event of a corresponding author not being a
                          society member, enter a minimum percentage of the
                          total number of co-authors required to be society
                          members to be eligible for a society membership
                          discount.
                        </p>
                      </div>
                      <div className="w-25">
                        <div className="society-discount-block d-flex">
                          {this.state.checked !== true && (
                            <div className="discount-number">
                              <InputBox
                                defaultValue={this.state.societyDiscount && this.state.societyDiscount.options && this.state.societyDiscount.options.option_2}
                                min={0}
                                field="option_2"

                                max={100}
                                className="col-md-12"
                                onChange={this.onChangeRadioButtonHandler}
                              />
                            </div>
                          )}
                          {this.state.checked !== true && (
                            <div className="discount-percentage ">%</div>
                          )}
                          <div className="discount-checkbox">
                            <Checkbox
                              checked={this.state.checked}
                              onChange={this.onChange}
                            >
                              Not Applicable
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society d-flex justify-content-between">
                      <div className="w-75">
                        <p>
                          Would you apply a Society or Society member discount
                          or a special price when an institutional administrator
                          approves an article where any of the authors claims to
                          be a member of the above society, and OaMetrix has
                          successfully cross-checked this information?
                        </p>
                      </div>

                      <div className="w-25">
                        <RadioButtonGroup
                          onChange={this.onChangeRadioButtonHandler}
                          field="option_3"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.option_3}
                        />
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society d-flex justify-content-between">
                      <div className="w-75">
                        <p>
                          Would you apply a Society or Society member discount
                          or a special price when an institutional administrator
                          approves an article where any of the authors claims to
                          be a member of the above society, but OaMetrix could
                          not cross check this information?
                        </p>
                      </div>
                      <div className="w-25">
                        <RadioButtonGroup
                          onChange={this.onChangeRadioButtonHandler}
                          field="option_4"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.option_4}
                        />
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society d-flex justify-content-between">
                      <div className="w-75">
                        <p>
                          Would you apply a Society or Society member discount
                          or a special price when an institutional administrator
                          approves an article under an OA deal where any of the
                          authors claims to be a member of the above society,
                          and OaMetrix successfully cross-checked this
                          information?
                        </p>
                      </div>
                      <div className="w-25">
                        <RadioButtonGroup
                          onChange={this.onChangeRadioButtonHandler}
                          field="option_5"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.option_5}
                        />
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society d-flex justify-content-between">
                      <div className="w-75">
                        <p>
                          Would you apply a Society or Society member discount
                          or a special price when an institutional admin
                          approves an article under an OA deal where any of the
                          authors claims to be a member of the above society,
                          but OaMetrix could not cross-check this information?
                        </p>
                      </div>
                      <div className="w-25">
                        <RadioButtonGroup
                          onChange={this.onChangeRadioButtonHandler}
                          field="option_6"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.option_6}
                        />
                      </div>
                    </div>
                    */}

                    <div className="text-center m-t-15">
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                    </div>
                  </form>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  organisation_id: state.auth.user.organisation_id,
  token: state.auth.token,
  currency_list: state.OaDeals.oadeal_currency,
  societies: state.society.societies,
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  oa_disciplines: state.OaDiscipline.oa_disciplines,
  oa_subjects: state.OaSubject.oa_subjects,
  society_discount: state.societyDiscount.society_discount,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCurrencyList: () => dispatch(getAllCurrencyList()),
  createUpdateSocietyDiscount: (payload) =>
    dispatch(createUpdateSocietyDiscount(payload)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: () => dispatch(getContentTypeList()),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getSocietyList: (payload) => dispatch(getSocietyList(payload)),
});

export const AddSocietyDiscount = connect(
  mapStateToProps,
  mapDispatchToProps
)(Society);
