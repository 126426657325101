import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../../core/components";
import EmailCommon from "./EmailCommon";


class AddHub extends BaseComponent {
  render() {

    return (
      <EmailCommon group="hub" />
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({

})

export const Hub = connect(mapStateToProps, mapDispatchToProps)(AddHub);