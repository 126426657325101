import config, {
  GET_DASHBOARD_DATA,
  GET_PREMIUM_DASHBOARD_DATA,
} from "../../config";
import { api } from "../../core/api";

const DashboardData = (payload) => ({
  type: GET_DASHBOARD_DATA,
  payload,
});

const DashboardPremiumData = (payload) => ({
  type: GET_PREMIUM_DASHBOARD_DATA,
  payload,
});

export const getDashboardData = (payload) => {
  return (dispatch) => {
    const params = payload ? payload.params || "" : "";
    const filter = payload ? payload.filter || "" : "";
    const url =
      config.endpoint.dashboard + `?${params && params + "&"}${filter}`;
    api.get(url).then(
      (res) => {
        dispatch(DashboardData(res.data));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(DashboardData({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(DashboardData({ success: null })); // Unknown Error
        } else {
          dispatch(DashboardData({ success: false })); // No Internet Connection
        }
      }
    );
  };
};
export const getPublisherDefaultDashboardData = (payload) => {
  return (dispatch) => {
    const params = payload ? payload.params || "" : "";
    const filter = payload ? payload.filter || "" : "";
    const url =
      config.endpoint.dashboard + `?${params && params + "&"}${filter}`;
    api.get(url).then(
      (res) => {
        dispatch(DashboardData(res.data));
      },
      (error) => {
        if (error.code === "ECONNABORTED") {
          dispatch(DashboardData({ success: undefined })); // Timeout
        } else if (error.response) {
          dispatch(DashboardData({ success: null })); // Unknown Error
        } else {
          dispatch(DashboardData({ success: false })); // No Internet Connection
        }
      }
    );
  };
};

export const getPremiumDashboardData = (payload) => {
  const url = config.endpoint.dashboard + `?_version=${payload}&_test=false`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((res) => {
          payload === "BASIC" ? dispatch(DashboardData(res.data)) : dispatch(DashboardPremiumData(res))
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
