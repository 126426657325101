import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { DataTable } from '../../../../../core/components';
import { ViewRow} from '../../../common';
import { ClearStatus, UpdateUser, singleOrg, getInstitutionsLists, getPublicPublisher, getOrgList, updateChildOrganisation } from '../../../../actions';
import { PopupBox, Loading, } from '../../../../../core/components/common';
import { api } from '../../../../../core/api';
import { AddChildOrganisation } from './AddChildOrganisation';


class Add extends BaseComponent {
    constructor(props) {
        super(props)
        this.error = props.failed;
        this.status = 100;
    }
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        showAddForm: false,
        isEmpty: false,
        childOrganizations: [],
        organizations: [],
        members: [],
        data: false,
        member_ids: [],
    }

    getSingle = (id) => {
        this.setState({
            loader:true
        })
        api.get('organisation/'+id+'/').then((res) => {
           
            this.setState({
                org:res
            }, async function() { 
                this.setState({
                    loader: false,
                });
                
                const id = parseInt(this.props.match.params.id);
                let organizations = false;

                if (this.state.org.top_domain_name === 'PUBLISHER') {                    
                    await this.props.getPublicPublisher();
                    organizations = this.props.publishers.results;
                } else if (this.state.org.top_domain_name === 'INSTITUTION') {
                    await this.props.getInstitutionsLists();
                    organizations = this.props.institutions;
                } else {
                    return;
                }

                organizations && this.setState({
                    organizations: organizations.filter((x) => x.id !== id),
                });

                let selectedMembers = this.state.org.consortium_members;
                let childOrganizations = organizations.filter((x) => selectedMembers.includes(x.id));

                let dataOrg = [];
                childOrganizations.map((item) => {
                    return dataOrg.push({
                        organization_name: item.name,
                        email_domain: item.email_domain,
                        grid_number: item.meta ? item.meta.grid_number : '',
                        country: item.address ? item.address.country_name : '',
                        id: item.id
                    });
                })
                
                this.setState({
                    childOrganizations: childOrganizations,
                    data: dataOrg,
                })
            })
         }, error => {
            this.setState({
                loader:false 
            })
         }).catch()
    }

    async componentDidMount(){
        const id = this.props.match.params.id;
        if(id){
            this.getSingle(id);
        }  
    }

    componentDidUpdate() {
        this.state.msg && setTimeout(() => {
            this.setState({
                msg: false
            })
        }, 2000)
        
        // this.props.getSingleUser(userId)

        if (this.props.failed) {
            if (this.props.failed.status === 500 && this.state.status !== 500) {
                this.setState({
                    status: 500
                })
            } else if (this.props.failed.status === 400 && this.state.status !== 400) {
                this.setState({
                    status: 400
                })
            }
        }
    }

    ids = [];
    
    onSelect = (id) => {
        let index = this.ids.indexOf(id);
        (index !== -1) ? this.ids.splice(index, 1) : this.ids.push(id);
        this.setState({
            member_ids: this.ids
        })
    }

    onSubmitHandler = async () => {
        let params = {
            mode: 'remove',
            members: this.state.member_ids,
        }

        if (this.state.member_ids.length < 1) {
            this.setState({
                isEmpty: <div>
                    <p>Please select child organisation</p>
                </div>
            })
        } else {
            const id = this.props.match.params.id;
            if (id) {
                await this.props.updateChildOrganisation(id, params);
                this.getSingle(id);
            } 
        }
    }

    onClick = async (e) => { 
        this.state.organizations && this.setState({
            showAddForm: true,
            members: this.state.org.consortium_members,
        });
    }

    getChildOranisation = async (pageNum = 1, page = 10) => {
        await this.props.getChildOranisations({ pageNum: pageNum, pageSize: page });
        this.setState({
            data: this.props.child_organisation.results,
            count: this.props.child_organisation.count
        })
    }

    pageChange = (pageNumber) => {
        this.getChildOranisation(pageNumber);
    }

    onChildOranisationFormSubmit = async (params) => { 
        const id = this.props.match.params.id;
        if (id) {
            await this.props.updateChildOrganisation(id, params);

            this.getSingle(id);

            this.setState({
                showAddForm: false,
            });
        } 
        
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false,
            showAddForm: false,
        })
        await this.props.ClearStatus()
    }


    render() {
        let { showAddForm, organizations, members } = this.state;
        const heading = ['organization_name', 'email_domain', 'grid_number', 'country'];
        const altNamesHeading = ['name', 'email_domain'];
        let isAuthorized = ['PUBLISHER', 'INSTITUTION'].includes(this.state.org && this.state.org.top_domain_name) || false;

        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Invalid form"
                msg="You submitted the form is not valid. Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <HubContent title={this.state.org ? this.state.org.name : ''}>
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                {/* LoadinG Handling */}
                {!this.state.org && <Loading />}
                {/* LoadinG Handling */}

                {/* Add child organisation modal */} 

                {showAddForm ? <AddChildOrganisation 
                                    organizations={organizations} 
                                    members={members}
                                    onSubmitHandler={this.onChildOranisationFormSubmit}
                                    className="col-sm-8"
                                    YesText="Submit"
                                    NoText="Cancel"
                                    btnSize="md"
                                    btnWidth="3"
                                    onCancel={this.cancelLoader}
                                /> : '' }
                {/* Add child organisation modal end */}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ViewRow 
                                    label="Group"
                                    value={this.state.org ? this.state.org.dommain_name : ''}
                                />
                                <ViewRow 
                                    label="Organization Name"
                                    value={this.state.org ? this.state.org.name : ''}
                                />
                                <ViewRow 
                                    label="Parent Organization"
                                    value={this.state.org ? this.state.org.parent_name : ''}
                                />
                                 <ViewRow 
                                    label="Building/street"
                                    value={this.state.org ? this.state.org.address && this.state.org.address.address1 : ''}
                                />
                                <ViewRow 
                                    label="Address line"
                                    value={this.state.org ? this.state.org.address && this.state.org.address.address2 : ''}
                                />
                                <ViewRow 
                                    label="City"
                                    value={this.state.org ? this.state.org.address && this.state.org.address.city_name : ''}
                                />

                                <ViewRow 
                                    label="Post/Zip Code"
                                    value={this.state.org ? this.state.org.address && this.state.org.address.postal_code : ''}
                                />
                               <ViewRow 
                                    label="State"
                                    value={this.state.org ? this.state.org.address && this.state.org.address.state : ''}
                                />
                                <ViewRow 
                                    label="Country"
                                    value={this.state.org ? this.state.org.address &&  this.state.org.address.country_name : ''}
                                />
                                <ViewRow 
                                    label="Email Domain"
                                    value={this.state.org ? this.state.org.email_domain : ''}
                                />
                                <ViewRow 
                                    label="Website"
                                    value={this.state.org ? this.state.org.website : ''}
                                />
                                <ViewRow 
                                    label="VAT"
                                    value={this.state.org ? this.state.org.vat_number : ''}
                                />
                                <ViewRow 
                                    label="GRID NO."
                                    value={this.state.org ? this.state.org.meta && this.state.org.meta.grid_number : ''}
                                />
                                <ViewRow 
                                    label="Ringgold No."
                                    value={this.state.org ? this.state.org.meta && this.state.org.meta.ring_gold_number : ''}
                                />
                                <ViewRow 
                                    label="ISNI No."
                                    value={this.state.org ? this.state.org.meta && this.state.org.meta.isni_number : ''}
                                />
                                <ViewRow 
                                    label="Note"
                                    value={this.state.org ? this.state.org.note : ''}
                                />
                                <ViewRow 
                                    label="Alternative names"
                                    value=""
                                />
                                
                                <DataTable 
                                    tableId="alt-names"
                                    heading={altNamesHeading}
                                    data={(this.state.org && this.state.org.alt_names) || []}
                                    noAction={true}
                                    pageSize={100}
                                />
                                {isAuthorized && <>
                                    <div className="row mt-4">
                                        <div className="col-sm-12">
                                            <div className="float-right">
                                                <button className="btn btn-primary mb-2"
                                                    onClick={this.onClick}
                                                ><i className="mdi mdi-plus"></i> Add child</button>
                                            </div>
                                        </div>
                                        <s style={{ borderBottom: '1px solid #375ba8', width: '100%' }}></s>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mt-4">
                                            <ul className="nav nav-pills nav-justified" role="tablist">
                                                <li className="nav-item waves-effect waves-light">
                                                    <a className="nav-link active" data-toggle="tab" href="#child-organisation" role="tab">Child organisation</a>
                                                </li>
                                                <li className="nav-item waves-effect waves-light">
                                                    <a className="nav-link" data-toggle="tab" href="#second-tab" role="tab">Other</a>
                                                </li>
                                            </ul>

                                            <div className="tab-content">
                                                <div className="tab-pane active p-3" id="child-organisation" role="tabpanel">
                                                    {this.state.data ?
                                                        <DataTable
                                                            heading={heading}
                                                            data={this.state.data || []}
                                                            noAction={true}
                                                            onSelect={this.onSelect}
                                                            checkbox={true}
                                                            count={this.state.count && this.state.count}
                                                            pageChange={this.pageChange}
                                                            pageSize={10}
                                                        /> : <Loading type="flat" />
                                                    }
                                                    <div className="text-center m-t-15" style={{ marginBottom: 20 }}>
                                                        <button onClick={this.onSubmitHandler} type="button" className="btn btn-primary waves-effect waves-light">Remove child</button>
                                                    </div>
                                                </div>
                                                <div className="tab-pane p-3" id="second-tab" role="tabpanel">
                                                    Tab content 2
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    failed: state.user.failed || false,
    getUser: (userId) => {
        return state.user.users ? state.user.users.find((user) => {
            return user.id.toString() === userId.toString() && user;
        }) : false;
    },
    demo_state:'demo',
    org: state.orgs.org_single || false,
    organizations: state.app.organizations || false,
    publishers: state.orgs.publisher_list || false,
    institutions:(state.institutions && state.institutions.institution_list )|| [],
})

const mapDispatchToProps = (dispatch) => ({
    UpdateUser: (payload) => dispatch(UpdateUser(payload)), 
    getOrgList: (payload) => dispatch(getOrgList(payload)),
    getInstitutionsLists: (payload) => dispatch(getInstitutionsLists(payload)),
    getPublicPublisher: () => dispatch(getPublicPublisher()),
    updateChildOrganisation: (id, payload) => dispatch(updateChildOrganisation(id, payload)),
    ClearStatus: () => dispatch(ClearStatus()),
    singleOrg: (id) => dispatch(singleOrg(id)),
    
})

export const SingleOrg = connect(mapStateToProps, mapDispatchToProps)(Add);