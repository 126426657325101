import React from "react";
import BaseComponent from "../../../../../core/components";
import { Card } from "../../../../../core/components/common";

const styles = {
  th: {
    padding: "10px 10px",
    fontWeight: 900,
    textAlign: "right",
  },
  td: {
    padding: "10px 10px",
    textAlign: "right",
  },
  td_first: {
    padding: "10px 10px",
    paddingLeft: 32,
  },
};

class ReadPublishList extends BaseComponent {
  state = {
    error: null,
    errorInfo: null,
    showpop: false,
    onClose: false,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  renderTableBody = (usd, eur, gbp) => (
    <tbody>
      {Object.keys(usd).map((key) => {
        // let fp = key === 'total_articles' ? 0 : 2; // Floating Point
        return (
          <tr key={key}>
            <td style={styles.td_first}>
              {this.capitalize(
                String(key.replace(/_/g, " ").replace(/apc/g, "APC"))
              )}
            </td>
            <td style={styles.td}>
              {gbp[key]
                ? this.numberWithCommas(Number(parseFloat(gbp[key]).toFixed(2)))
                : 0}
            </td>
            <td style={styles.td}>
              {eur[key]
                ? this.numberWithCommas(
                    Number(parseFloat(eur[key]).toFixed(2).toString())
                  )
                : 0}
            </td>
            <td style={styles.td}>
              {usd[key]
                ? this.numberWithCommas(
                    Number(parseFloat(usd[key]).toFixed(2).toString())
                  )
                : 0}
            </td>
          </tr>
        );
      })}
    </tbody>
  );

  render() {
    let { data } = this.props;
    let usd = data ? data.usd : [];
    let eur = data ? data.eur : [];
    let gbp = data ? data.gbp : [];

    if (this.state.errorInfo) {
      return (
        <Card
          title="Something Error"
          className={this.props.className || "col-md-4"}
          height={358}
        />
      );
    } else {
      return !data ? (
        <Card
          title="Fetching..."
          className={this.props.className || "col-md-4"}
        />
      ) : (
        <Card
          titleIcon={this.props.titleIcon}
          title="Read & Publish deal"
      
          showtitle={this.props.showtitle}
          className={this.props.className || "col-md-4 p-0"}
          height={320}
        >
          <div className="morris-chart-height">
            <table className="table table-hover mb-0 mb-0">
              <thead>
                <tr>
                  <th style={styles.th}></th>
                  <th style={styles.th}>£</th>
                  <th style={styles.th}>€</th>
                  <th style={styles.th}>$</th>
                </tr>
              </thead>
              {this.renderTableBody(usd, eur, gbp)}
            </table>
          </div>
        </Card>
      );
    }
  }
}

export default ReadPublishList;
