import React from "react";
class WhitePublisherCardUI extends React.Component {
  state = {
    filterParams: {},

  };


  render() {
    const props = this.props;

    let icon = "";
    if (props.icon) {
      icon = (
        <div className="mini-stat-icon2">
          <i
            className={`${props.icon} float-right`}
            style={{
              fontSize: "18px",
              width: "45px",
              height: "45px",
              lineHeight: "45px",
              textAlign: "center",
              color: "#375ba8",
              borderRadius: "50%",
              background: "rgba(88, 39, 140, 0.1)",
              display: "inline-block",
            }}
          ></i>
        </div>
      );
    }
    return (
      <div className="db_test">
        <div className="card mini-stat2 bg-white">
          <div
            className={`card-body mini-stat-img ${props.padding ? props.padding : ""
              }`}
          >
            {icon}
            <div className="">


              <h6 className="mb-4">{props.title}</h6>


              {typeof props.body === "object" ? (
                props.body.map((content) => {
                  return (
                    <p className="mb-1" key={content.toString()}>
                      {content}
                    </p>
                  );
                })
              ) : (
                  <p className="mb-1">{props.body}</p>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export const WhitePublisherCard = WhitePublisherCardUI;
