import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { getDashboardDataControl } from "../../../../actions";

class DashboardControlListUI extends BaseComponent {
  state = {
    deleteConfirm: false,
    contentTypeId: null,
    waitingMsg: null,
    deleteStatus: null,
    data: [],
  };

  async componentDidMount() {
    await this.props.getDashboardDataControl({ pageNum: 1, pageSize: 10 });
    this.setState({
      count:this.props.get_dashboard_user &&
        this.props.get_dashboard_user.count,
      orgs_list: this.props.get_dashboard_user &&
      this.props.get_dashboard_user.results
    })
  }
  getDashboardDataControlList = async (pageNum = 1, page = 10) => {
    await this.props.getDashboardDataControl({ pageNum: pageNum, pageSize: page });

    this.setState({
      count:this.props.get_dashboard_user &&
        this.props.get_dashboard_user.count,
      orgs_list: this.props.get_dashboard_user &&
      this.props.get_dashboard_user.results
    })

  };
  onEditUser = (id) => {
    this.props.history.push(`/settings/edit-dashboard-control/${id}`);
  };
  onViewUser = (id) => {
    this.props.history.push(`/settings/dashboard-control-view/${id}`);
  };
  pageChange = (pageNumber) => {
    this.getDashboardDataControlList(pageNumber);
  };

  render() {
    let heading = ["organisation_name"];
    const orgs_list = [];
    this.state.orgs_list && this.state.orgs_list.map((item) => {
      return orgs_list.push({
        ...item,
        id: item.id,
        organisation_name: item.organisation_name,
       
      });
    });

    return (
      <HubContent title="Dashboard Control List">
        <div className="row">
          <div className="col-12">
            <Link
              to="/settings/add-dashboard-control"
              className="btn btn-primary float-right mb-2"
            >
              Add DashboardControl
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.orgs_list  &&
                <DataTable
                  heading={heading}
                  onEdit={this.onEditUser}
                  onView={this.onViewUser}
                  count={
                 this.state.count
                  }
                  pageChange={this.pageChange}
                  pageSize={10}
                  data={
                   orgs_list
                  }
                />}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  get_dashboard_user: state.DashboardControl.get_dashboard_user,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardDataControl: (payload) => dispatch(getDashboardDataControl(payload)),
});

export const DashboardControlList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardControlListUI);
