import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { OrgForm } from "../../../partials";
import { ClearStatus, UpdateOrg, singleOrg } from "../../../../actions";
import validator from "validator";
import { PopupBox, Loading ,Button} from "../../../../../core/components/common";

class Add extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    user: {},
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
  };

 
  componentDidUpdate() {

    if (this.props.failed) {
      if (this.props.failed.status === 500 && this.state.status !== 500) {
        this.setState({
          status: 500,
        });
      } else if (
        this.props.failed.status === 400 &&
        this.state.status !== 400
      ) {
        this.setState({
          status: 400,
        });
      }
    }
  }

  onSubmitHandler = async (data) => {
    await this.setState({
      ...data,
      org: data,
    });

    const orgId = this.props.match.params.id;
    const orgData = Object.assign({ id: orgId }, data);

    let isEmail = this.state.email_domain;
    let isValidWebsite = validator.isURL(this.state.website || "");
    let isValidWebsiteGrid = validator.isURL(this.state.grid_number || "");
    if (
      !this.state.name ||
      !this.state.email_domain || !this.state.address ||
      !this.state.address.country
    ) {
      this.setState({
        isEmpty: (
          <div className="validation-error-messages">
            {!this.state.name && <p>Organisation Name Cannot Be Empty</p>}
            {!this.state.email_domain && <p>Email Domain Cannot be Empty</p>}
            {!this.state.address || !this.state.address.country ? (
              <p>Country cannot be empty</p>
            ) : (
              ""
            )}
          </div>
        ),
      });
    } else if (!isEmail) {
      this.setState({
        isEmpty: <div>{!isEmail && <p>Email format is not correct!</p>}</div>,
      });
    } else if (this.state.website && !isValidWebsite) {
      this.setState({
        isEmpty: (
          <div>{!isValidWebsite && <p>Website url is not correct</p>}</div>
        ),
      });
    } else if (this.state.grid_number && !isValidWebsiteGrid) {
      this.setState({
        isEmpty: (
          <div>{!isValidWebsiteGrid && <p>GRID No. url is not correct</p>}</div>
        ),
      });
    } else {
      Object.keys(orgData).map((key, value) => {
        if ((key === "address" || key === "meta") && orgData[key] == null) {
          orgData[key] = {};
        }
        return orgData;
      });

      try {
        this.setState({
          isRegistering: true,
          msg: false,
        });
        await this.props.UpdateOrg(orgData);
        this.props.history.push("/organizations/organization-list");
      } catch (err) {
        this.setState({
          isEmpty: null,
          status: 400,
          errorObject: this.props.failed,
        });
      }
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  OnChangeTextBox(value, name) {
    const state = {};
    state[name] = value;
    this.setState(state);
    
  }

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent
      >
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        {/* LoadinG Handling */}
        {this.state.isRegistering && !this.state.status && <Loading />}
        {/* LoadinG Handling */}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
              <div className="col-md-12">
                    <div className="vat-tax-button email d-flex justify-content-end">

                      <Button
                        type="danger"
                        name="danger"
                        text="X"
                        onClick={() => this.props.history.push("/")}
                      />
                    </div>
                  </div>
         
                <OrgForm
                  onSubmitHandler={this.onSubmitHandler}
                  OnChangeTextBox={this.OnChangeTextBox.bind(this)}
                  msg={this.state.msg}
                  org_id={this.props.match.params.id}
                />
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.user.failed || false,
  org: state.orgs.org_single || false,
  getUser: (userId) => {
    return state.user.users
      ? state.user.users.find((user) => {
          return user.id.toString() === userId.toString() && user;
        })
      : false;
  },
});

const mapDispatchToProps = (dispatch) => ({
  UpdateOrg: (payload) => dispatch(UpdateOrg(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
  singleOrg: (id) => dispatch(singleOrg(id)),

});

export const EditOrg = connect(mapStateToProps, mapDispatchToProps)(Add);
