import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination } from "antd"
import BaseComponent, { HubContent } from "../../../../../core/components";
import { ArticleFullSingle } from "../../../partials";
import {
  getArticleOfferDetails,
  getArticleFullList,
  articleDownload,
  sendFreeTextEmail,
  getInstitutionsList ,
  articleStatus,
  getArticlePublisher,
  getPublicationFiltering,
  getOaDisciplineList,
  getOaSubjectList,
  getArticleFullSingleList,
  getApcArticleList,
  getPublicationList,
} from "../../../../actions";
import moment from "moment";
import {
  PopupBox,
  Loading,
  SelectBox,
  InputGroup,
  Button
} from "../../../../../core/components/common";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";
import { filterDays } from "../../hub/articles/data";
import queryString from "query-string";


class Article extends BaseComponent {
  state = {
    page: 1,
    pageSize: 10,
    msg: false,
    author_apc_request: false,
    author_apc_no_oa_request: false,
    filterParams: { page: 1, article_stage: 'POST_ACCEPTANCE', },
    params: null,
    isCorrectionRequestOpen: false,
    article_id: null,
    showLicenceUpdateNotification: false,
    oa_subject_list: [],
    publication_list:[]
  };

   componentDidMount() {
    this.apiDataList()
  }
  apiDataList = async() => {
    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;

  
    this.props.articleStatus().then(response => {
      if (response && response.success) {
        this.setState({
          filterStatus: [{id:"all", name:"All Status"}].concat(response && response.data),
        });
      }

    }).catch(error => {

    });
    let params = {
      institution_id: this.props.institute,
    };
    if (article_filter_id) {
      params["id"] = article_filter_id;
    }


    await this.setState((prevState) => {
      if (this.props.institute) {
        prevState.filterParams = Object.assign(prevState.filterParams, params);
      } else {
        if (article_filter_id) {
          prevState.filterParams = Object.assign(prevState.filterParams, {
            id: article_filter_id,
          });
        }
      }
    });

    let filter = await serialize(this.state.filterParams);

    if (this.props.location.apc_article_id) {
      await this.props.getApcArticleList(this.props.location.apc_article_id)
      await this.props.getArticleFullSingleList(this.props.location.apc_article_id)
      await this.props.getOaDisciplineList();
      await this.props.getPublicationFiltering();
    } else {
      await this.props.ArticleFull({ filter });
      await this.props.getOaDisciplineList();
      await this.props.getPublicationFiltering();
    }

    if (!this.props.institute) {
      try {
        await this.props.getInstitutionsList();
      } catch (e) {
      }
    }

    this.setState({
      oa_disciplines: [
        { id: "all", name: "All Disciplines" },
        
      ].concat(this.props.oa_disciplines && this.props.oa_disciplines.results),

      publication_types: [
        {
          name: "All Publications",
          id: "all",
        },
      ].concat(this.props.publication_types),

    });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  filterData = async (value) => {
    value.page = 1;

    if (value.hasOwnProperty("q")) {
      this.setState({
        ...value,
      });
    }

    if (value.department === "All Departments") {
      value.department = "all";
    } else if (value.department === "No Department") {
      value.department = "no_dept";
    }
    if (value.publication_type === "All Publications") {
      value.publication_type = "all";
    }

    if (value.hasOwnProperty("filter_discipline")) {
      let payload = {
        discipline:
          value.filter_discipline !== "all" ? value.filter_discipline : "",
      };

      value.filter_subject = "all";
      value.filter_publication = "all";

      await this.props.getOaSubjectList(payload);

      this.props.oa_subjects &&
        this.setState({
          oa_subject_list: this.props.oa_subjects && this.props.oa_subjects.results,
        });
    }

    if (value.hasOwnProperty("filter_subject")) {
      value.filter_publication = "all";
    }

    await this.setState((prevState) => {
      if (value.institution_id === "all") {
        value.filter_publisher = "all";
      }
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    if (
      value.hasOwnProperty("publication_type") ||
      value.hasOwnProperty("filter_discipline") ||
      value.hasOwnProperty("filter_subject")
    ) {
      this.getPublicationListOnParameterChange();
    }

    if (this.state.filterParams.institution_id === "all") {
      delete this.state.filterParams.institution_id;
    }
    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.ArticleFull({ params, filter });
  };

  onActionHandler = async (action, id) => {
    try {
      const article = await this.props.article(id);
      switch (action && action.toLowerCase()) {
        case "update":
          history.push(`/article/update-article/${id}`, {
            article,
          });
          break;
          case "article_payment":
            this.props.history.push(`/payment/addpayment/${id}`, {
                name: action
            });
            break;
        case "article_invoice":
            this.props.history.push(`/payment/addpayment/${id}`, {
                name: action
            });
            break;
        case "action_audit":
          this.props.history.push({
            pathname: `/audit/audit-trail/${id}`,
            filter: this.state.filterParams,
            name: action
          });
          break;
        default:
          break;
      }
    } catch (error) {
      this.log("error: ", error);
    }
  };

  pageChange = async (pageNumber) => {
    let value = {
      page: pageNumber,
    };

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);

    await this.props.ArticleFull({ params, filter });

    this.setState({
      page: pageNumber,
    });
  };

  onTabChangeHandler = async (articleStage = 'PRE_ACCEPTANCE') => {
    if (articleStage !== 'PRE_ACCEPTANCE') {
      articleStage = 'POST_ACCEPTANCE';
    }

    let params = {
      article_stage: articleStage,
    };

    this.filterData(params);
  }


  onArticleDownloadClick = async () => {
    try {
      let filter = await serialize(this.state.filterParams);
      await this.props.articleDownload({ params: 999999, filter });
      const url = window.URL.createObjectURL(this.props.article_download_data);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };

    if (this.state.filterParams.publication_type) {
      filterParams.publication_type_name =
        this.state.filterParams.publication_type !== "all"
          ? this.state.filterParams.publication_type
          : "";
    }

    if (this.state.filterParams.filter_discipline) {
      filterParams.discipline_ids =
        this.state.filterParams.filter_discipline !== "all"
          ? this.state.filterParams.filter_discipline
          : "";
    }

    if (this.state.filterParams.filter_subject) {
      filterParams.subject_ids =
        this.state.filterParams.filter_subject !== "all"
          ? this.state.filterParams.filter_subject
          : "";
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publication_list: this.props.publication_list.results,
      });
  };



  onCancel = () => {
    this.setState({
      author_apc_request: false,
      isCorrectionRequestOpen: false,
      author_apc_no_oa_request: false,
      article_id: null,
      showLicenceUpdateNotification: false,
    });
  };

  hasAccessToTabs = () => {
    let { group } = this.props;
    let groupList = ['PUBLISHER'];

    return groupList.includes(group);
  }
  onViewArticle =async () => {
    await this.props.ArticleFull()
  }

  render() {

    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;
    let { articles, auth, count } = this.props;
    let { page, } = this.state;
    let ErrorHandler = true;


    let articleTabs = (auth && auth.article_tabs) || [];




    if (articles) {
      if (articles.status === 404) {
        ErrorHandler = (
          <PopupBox
            title="Data Not Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else if (articles.status) {
        ErrorHandler = (
          <PopupBox
            title="Unknown Error Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else {
        ErrorHandler = false;
      }
    }

    return (
      <HubContent
        title={this.props.title || ""}
        className={this.props.className}
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >
        {/* {NotificationHandler} */}
        {ErrorHandler}

        <div className="row mb-3">
          <SelectBox
            className="col-md-3 mb-2"
            field="filter_status"
            defaultValue="all"
            data={this.state.filterStatus}
            onChange={this.filterData}
          />


          <SelectBox
            className="col-md-3 mb-2"
            field="filter_days"
            defaultValue=""
            data={filterDays}
            onChange={this.filterData}
          />
    
          <SelectBox
            className="col-md-3 mb-2"
            field="publication_type"
            defaultValue="All Publication types"
            data={
              this.state.publication_types || []
               
            }
            onChange={this.filterData}
          />
       <SelectBox
            className="col-md-3 mb-2"
            field="filter_publication"
            defaultValue={this.state.filterParams.filter_publication || "all"}
            data={
              this.state.publication_list
                ? [
                  { id: "all", name: "All Publications" },
                  ...this.state.publication_list,
                ]
                : [{ id: "all", name: "All Publications" }]
            }
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_discipline"
            defaultValue={this.state.filterParams.filter_discipline || "all"}
            data={
              this.state.oa_disciplines ||[]
               
            }
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_subject"
            defaultValue={this.state.filterParams.filter_subject || "all"}
            data={
              this.state.oa_subject_list
                ? [
                  { id: "all", name: "All Subjects" },
                  ...this.state.oa_subject_list,
                ]
                : [{ id: "all", name: "All Subjects" }]
            }
            onChange={this.filterData}
          />

   
          <InputGroup
            onClick={this.filterData}
            field="q"
            placeholder="Search"
            className="col-md-3 mb-2 free-text-search"
          />
          {
            this.props.location.apc_article_id && (
              <div className="col-md-12 apc-btn-list" style={{ textAlign: "right" }}>
                <Button type="secondary" name="secondary" text="View All APC Requests" className="view-articles" size={200} onClick={() => this.props.history.push("/author-request/apc-request-list")} />

                <Button type="secondary" name="primary" text="View All Articles" className="view-articles" size={200} onClick={this.onViewArticle} />
              </div>
            )
          }
          {this.hasAccessToTabs() && <div className="col-md-12">
            <ul
              className="nav nav-pills article-stage-tabs"
              role="tablist"
            >
              <li
                title={!articleTabs.includes('PRE_ACCEPTANCE') ? `You don't have access to this tab. Please contact with administrator.` : ''}
                className={`nav-item waves-effect waves-light ${!articleTabs.includes('PRE_ACCEPTANCE') && 'disabled-tab'}`}
              >
                <a
                  className={`nav-link`}
                  data-toggle="tab"
                  href="#pre-acceptance"
                  role="tab"
                  onClick={() => articleTabs.includes('PRE_ACCEPTANCE') ? this.onTabChangeHandler() : null}
                  disabled={!articleTabs.includes('PRE_ACCEPTANCE')}
                >
                  Pre-acceptance
                </a>
              </li>
              <li className="nav-item waves-effect waves-light">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#pre-acceptance"
                  role="tab"
                  onClick={() => this.onTabChangeHandler('POST_ACCEPTANCE')}
                >
                  {`Accepted & Post-acceptance`}
                </a>
              </li>
            </ul>
          </div>}

        </div>

        <div className="tab-content">
          <div className="tab-pane active " id="pre-acceptance" role="tabpanel">
            <div className="row approved-btn">
              <div className="col-lg-12">
                <div id="accordion" className="article-cards">
                  {/*START OF LOOP*/}
                  {!ErrorHandler ? (
                    articles && articles.map((article, i) => {
                      return (
                        <ArticleFullSingle
                          key={article.id}
                          search_text={this.state.q}
                          article={article}
                          url_article_id={article_filter_id}
                          user={this.props.auth.user.username}
                          isAction={false}
                          index={i}
                          onActionHandler={(action) =>
                            this.onActionHandler(action, article.id)
                          }
                        />
                      );
                    })
                  ) : (
                    <Loading type="flat" />
                  )}
                  {/*END OF LOOP*/}
                  {articles && articles.length === 0 && (
                    <div className="card">
                      <div className="card-body">
                        <h6>No Data Found</h6>
                      </div>
                    </div>
                  )}
                  {/* START OF PAGINATION */}
                  {count && (
                    <Pagination
                      style={{
                        marginTop: 10,
                        marginBottom: 20,
                        textAlign: "center",
                      }}
                      showSizeChanger={ false}
                      onChange={this.pageChange}
                      pageSize={this.state.pageSize || 10}
                      total={count}
                      current={page}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state, props) => ({
  articles: state.articles.article_full,
  count: state.articles.count,
  article: (id) => {
    let article = state.articles.article_full;
    if (article) {
      return article.find((article) => {
        return id.toString() === article.id.toString() && article;
      });
    } else {
      return false;
    }
  },
  publication_types: state.institutions.publication_types,
  department_types: state.institutions.department_types,
  article_download_data: state.articles.download_data,
  group: (state.auth && state.auth.group) || false,
  auth: state.auth,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  oa_subjects: state.OaSubject.oa_subjects || false,
  publication_list: state.institutions.publication_list,
});

const mapDispatchToProps = (dispatch, props) => ({
  ArticleFull: (payload = {}) => dispatch(getArticleFullList(payload)),
  articleDownload: (payload = {}) => dispatch(articleDownload(payload)),
  getArticleOfferDetails: (id) => dispatch(getArticleOfferDetails(id)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
  getPublicationFiltering: () => dispatch(getPublicationFiltering()),
  getArticlePublisher: () => dispatch(getArticlePublisher()),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getArticleFullSingleList: (id) => dispatch(getArticleFullSingleList(id)),
  getApcArticleList: (id) => dispatch(getApcArticleList(id)),
  sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
  articleStatus: (payload = {}) => dispatch(articleStatus(payload)),

});


export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Article)
);
