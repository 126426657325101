import React from "react";
import BaseComponent from "..";

class Btn extends BaseComponent {
  render() {
    let {
      name,
      size,
      className,
      onClick,
      type,
      text,
      disabled,
      btn_size,
      icon,
      iconName,
      downloadIcon,
      cursor
    } = this.props;
    return (
      <button
        style={{ width: btn_size, whiteSpace: "nowrap", cursor: cursor ==="auto" ? "auto" : "pointer"}}
        type={type}
        disabled={disabled}
        className={`btn btn-${name} btn-${size || "sm"} waves-effect ${className || ""
          }`}
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick(e);
        }}
      >
        {text}

        {icon ? <i
          className={iconName ? "fa fa-chevron-up" : "fa fa-chevron-down"}
        /> : null}

        {downloadIcon &&
          <i className="fas fa-download" style={{marginLeft:10}}></i>}
      </button>
    );
  }
}

export const Button = Btn;
