import React from 'react'

const ArticleFunderInfo = ({ funders, funderdetail }) => {

    return (
        <div>
            <div className="row" style={{ borderBottom: "1px solid #ddd", paddingBottom: 5, marginBottom: 5 }}>
                <div className="col-sm-4">
                    <div className="funder-label">
                        <p className="m-1">
                            <strong>Funder </strong>
                        </p>
                    </div>

                </div>
                <div className="col-sm-5">
                    <div className="funder-label">
                        <p className="m-1">
                            <strong>FunderRef </strong>
                        </p>
                    </div>

                </div>
                <div className="col-sm-2">
                    <div className="funder-label">
                        <p className="m-1">
                            <strong>Grant Number </strong>
                        </p>
                    </div>

                </div>

            </div>


            {
                (funders && funders.length) > 0 ? funders && !!funders.length && funders.map(item => (
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="funder-label">
                                <p className="m-1">
                                    {item.name}            </p>
                            </div>

                        </div>
                        <div className="col-sm-5">
                            <div className="funder-label">
                                <p className="m-1">
                                    {item && item.fundRefInfo && item.fundRefInfo.identifier}                      </p>
                            </div>

                        </div>
                        <div className="col-sm-2">
                            <div className="funder-label">
                                <p className="m-1">
                                    {item && item.grants.number}

                                </p>
                            </div>

                        </div>


                    </div>
                )) : <div className="row">
                        <div className="col-sm-4">
                            <div className="funder-label">
                                <p className="m-1">
                                    {funderdetail && funderdetail.funder_name ? funderdetail && funderdetail.funder_name : "No funding information provided by author."}
                                </p>
                            </div>

                        </div>
                        <div className="col-sm-5">
                            <div className="funder-label">
                                <p className="m-1">
                                     </p>
                            </div>

                        </div>
                        <div className="col-sm-2">
                            <div className="funder-label">
                                <p className="m-1">
                                    {funderdetail && funderdetail.grant_number ? funderdetail && funderdetail.grant_number : ""}

                                </p>
                            </div>

                        </div>


                    </div>

            }


        </div>
    )
}

export default ArticleFunderInfo



