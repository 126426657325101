import config, {
  FAIELD_PUBLICATION_APC_WAIVER_CONFIG_LIST,
  REQUEST_PUBLICATION_APC_WAIVER_CONFIG_LIST,
  SUCCESS_PUBLICATION_APC_WAIVER_CONFIG_LIST,
  GET_PUBLICATION_APC_WAIVER_CONFIG,
  UPLOAD_PUBLICATION_APC_WAIVER_CONFIG,
  CREATE_UPDATE_PUBLICATION_APC_WAIVER_CONFIG,
  DELETE_PUBLICATION_APC_WAIVER_CONFIG,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get publication apc waiver config list
export const successGetPublicationApcWaiverConfigListAction = (payload) => ({
  type: SUCCESS_PUBLICATION_APC_WAIVER_CONFIG_LIST,
  payload,
});
export const requestGetPublicationApcWaiverConfigListAction = () => ({
  type: REQUEST_PUBLICATION_APC_WAIVER_CONFIG_LIST,
});
export const failedGetPublicationApcWaiverConfigListAction = (payload) => ({
  type: FAIELD_PUBLICATION_APC_WAIVER_CONFIG_LIST,
  payload,
});

// Get publication apc waiver config
export const getPublicationApcWaiverConfigAction = (payload) => ({
  type: GET_PUBLICATION_APC_WAIVER_CONFIG,
  payload,
});

// Create-Update publication apc waiver config
export const createUpdatePublicationApcWaiverConfigAction = (payload) => ({
  type: CREATE_UPDATE_PUBLICATION_APC_WAIVER_CONFIG,
  payload,
});

// Delete publication apc waiver config
export const deletePublicationApcWaiverConfigAction = (payload) => ({
  type: DELETE_PUBLICATION_APC_WAIVER_CONFIG,
  payload,
});

// PublicationApcWaiverConfig file upload
export const fileUploadAction = (payload) => ({
  type: UPLOAD_PUBLICATION_APC_WAIVER_CONFIG,
  payload,
});

export const getPublicationApcWaiverConfigList = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.publication_apc_waiver_config;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (payload.organisation) {
      params["organisation_id"] = payload.organisation;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;
    dispatch(requestGetPublicationApcWaiverConfigListAction());
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(successGetPublicationApcWaiverConfigListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA single PublicationApcWaiverConfig
export const getPublicationApcWaiverConfig = (id) => {
  return (dispatch) => {
    let url = config.endpoint.publication_apc_waiver_config + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getPublicationApcWaiverConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(failedGetPublicationApcWaiverConfigListAction(e));
          reject(e);
        });
    });
  };
};

// Create-Update publication apc waiver config

export const createUpdatePublicationApcWaiverConfig = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.publication_apc_waiver_config;
    let apiRequest = "";
    return new Promise((resolve, reject) => {
      if (id) {
        url += `${id}/`;
        apiRequest = api.put(url, payload);
      } else {
        apiRequest = api.post(url, payload);
      }

      apiRequest
        .then((res) => {
          dispatch(createUpdatePublicationApcWaiverConfigAction(res.data));
          resolve(Object.assign(res.data, { status: true }));
        })
        .catch((e) => {});
    });
  };
};

// Delete publication apc waiver config
export const deletePublicationApcWaiverConfig = (id) => {
  return (dispatch) => {
    let url = config.endpoint.publication_apc_waiver_config + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          dispatch(deletePublicationApcWaiverConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// PublicationApcWaiverConfig file upload action
export const uploadPublicationApcWaiverConfig = (payload) => {
  let url = `${config.endpoint.publication_apc_waiver_config}upload/`;
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return new Promise((resolve, reject) => {
      let filename = payload.get("filename");
      let headers = {
        "Content-Disposition": `attachment; filename="${filename}"`,
      };

      payload.delete("filename");

      api
        .post(url, payload, token, headers)
        .then((res) => {
          dispatch(fileUploadAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(fileUploadAction(error.response));
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};
