import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { connect } from 'react-redux';
class DB extends BaseComponent {
    async componentDidMount(){
       
    }

    render() {
        return (
            <HubContent title="Correction Request">


            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({

})
const mapDispatchToProps = (dispatch) => ({

})

export const CorrectionRequest = connect(mapStateToProps, mapDispatchToProps)(DB);