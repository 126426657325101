import config, {
  GET_OA_DISCIPLINE_LIST,
  GET_OA_DISCIPLINE,
  CREATE_UPDATE_OA_DISCIPLINE,
  DELETE_OA_DISCIPLINE,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get OA Discipline list
export const getOaDisciplineListAction = (payload) => ({
  type: GET_OA_DISCIPLINE_LIST,
  payload,
});

// Get OA Discipline
export const getOaDisciplineAction = (payload) => ({
  type: GET_OA_DISCIPLINE,
  payload,
});

// Create-Update OA Discipline
export const createUpdateOaDisciplineAction = (payload) => ({
  type: CREATE_UPDATE_OA_DISCIPLINE,
  payload,
});

// Delete OA Discipline
export const deleteOaDisciplineAction = (payload) => ({
  type: DELETE_OA_DISCIPLINE,
  payload,
});

export const getOaDisciplineList = (payload = {}) => {
  return (dispatch) => {
    let url = `${config.endpoint.oa_discipline}?fields=id,name`;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `&${serialize(params)}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOaDisciplineListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA single discipline
export const getOaDiscipline = (id) => {
  return (dispatch) => {
    let url = config.endpoint.oa_discipline + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOaDisciplineAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create-Update OA discipline

export const createUpdateOaDiscipline = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.oa_discipline;
    let apiRequest = "";
    return new Promise((resolve, reject) => {
      if (id) {
        url += `${id}/`;
        apiRequest = api.put(url, payload);
      } else {
        apiRequest = api.post(url, payload);
      }

      apiRequest
        .then((res) => {
          dispatch(createUpdateOaDisciplineAction(res.data));
          resolve(Object.assign(res.data, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Delete OA discipline
export const deleteOaDiscipline = (id) => {
  return (dispatch) => {
    let url = config.endpoint.oa_discipline + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          dispatch(deleteOaDisciplineAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
