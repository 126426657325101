import { DEPOSIT_FUND_LIST,DEPOSIT_SEARCH_LIST, PUBLISHER_DEPOSIT_PAYMENT, DEPOSIT_FUND, DEPOSIT_DOWNLOAD_LIST,CLEAR_FAILED_STATUS, APPROVED_DEPOSIT_LIST,DEPOSIT_APPROVAL_DOWNLOAD, PENDING_DEPOSIT_LIST,DEPOSIT_DOWNLOAD } from "../../config";

export default (state = {}, action) => {
    switch (action.type) {
        case DEPOSIT_FUND_LIST: {
            return state = {
                ...state,
                deposit_list: action.payload
            }
        }
        case DEPOSIT_FUND: {
            return state = {
                ...state,
                deposit_request: action.payload
            }
        }
        case PUBLISHER_DEPOSIT_PAYMENT: {
            return state = {
                ...state,
                deposit_payment: action.payload
            }
        }
        case APPROVED_DEPOSIT_LIST: {
            return state = {
                ...state,
                approved: action.payload
            }
        }
        case PENDING_DEPOSIT_LIST: {
            return state = {
                ...state,
                pending: action.payload
            }
        }
        case DEPOSIT_DOWNLOAD: {
            return state = {
                ...state,
                download_data: action.payload
            }
        }
        case DEPOSIT_APPROVAL_DOWNLOAD: {
            return state = {
                ...state,
                deposit_approve_data: action.payload
            }
        }
        case DEPOSIT_DOWNLOAD_LIST: {
            return state = {
                ...state,
                deposit_download_list: action.payload
            }
        }
        case DEPOSIT_SEARCH_LIST: {
            return state = {
                ...state,
                deposit_download_list: action.payload
            }
        }


        case CLEAR_FAILED_STATUS: {
            return state = {}
        }
        default:
            return state;
    }
}
