import {
  OA_TOKEN_TRXN,// OFFSET_FUND_TRXN,
  SUCCESS_DEPOSIT_FUND_TRXN,
  FAILED_DEPOSIT_FUND_TRXN,
  REQUEST_DEPOSIT_FUND_TRXN,
  DEPOSIT_FUND_PUB,
  DEPOSIT_OFFSET_PUB,
  PAYMENT_TRANSACTION_PUB,
  GET_ORGS_TOKEN_DOWNLOAD,
  DEPOSIT_FUND_TRANX_SEARCH,
  OFFSET_FUND_DOWNLOAD,
  OFFSET_TRANSACTION_SEARCH,
  // TOKEN_FUND_TRANX_SEARCH
} from "../../config";
import { TokenTrxnOffsetSearchList } from "../../openaccess/actions/Transaction";

export default (state = {
  token_trxn: {},
  offset_trxn: {},
  deposit_trxn: {},
  deposit_pub: {},
  isLoading: false,
}, action) => {
  switch (action.type) {
    case OA_TOKEN_TRXN:
      return (state = {
        ...state,
        token_trxn: action.payload,
      })
    // case OFFSET_FUND_TRXN:
    //   return (state = {
    //     ...state,
    //     offset_trxn: action.payload,
    //   })
    case FAILED_DEPOSIT_FUND_TRXN:
      return (state = {
        ...state,
        deposit_trxn: action.payload,
      })
    case REQUEST_DEPOSIT_FUND_TRXN:
      return (state = {
        ...state,
        isLoading: true
      })
    case SUCCESS_DEPOSIT_FUND_TRXN:
      return (state = {
        ...state,
        deposit_trxn: action.payload,
        isLoading: false
      })
    case DEPOSIT_FUND_PUB:
      return (state = {
        ...state,
        deposit_pub: action.payload,
      })
    case DEPOSIT_OFFSET_PUB:
      return (state = {
        ...state,
        offset_pub: action.payload,
      })
    case PAYMENT_TRANSACTION_PUB:
      return (state = {
        ...state,
        payment_transciton: action.payload,
      })
    case GET_ORGS_TOKEN_DOWNLOAD:
      return state = {
        ...state,
        token_download: action.payload
      }
    case OFFSET_FUND_DOWNLOAD:
      return state = {
        ...state,
        offset_fund_download: action.payload
      }
    case OFFSET_TRANSACTION_SEARCH:
      return (state = {
        ...state,
        offset_searchList: action.payload,
      })
    case DEPOSIT_FUND_TRANX_SEARCH:
      return state = {
        ...state,
        deposit_SearchList: action.payload
      }
    case TokenTrxnOffsetSearchList:
      return state = {
        ...state,
        tokenSearchList: action.payload
      }
    default:
      return state
  }
}