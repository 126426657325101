import config, { 
    GET_LICENCE_PRICING_CONTROL_CONFIG_LIST, 
    GET_LICENCE_PRICING_CONTROL_CONFIG, 
    CREATE_UPDATE_LICENCE_PRICING_CONTROL_CONFIG, 
    DELETE_LICENCE_PRICING_CONTROL_CONFIG,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get licence pricing control config list
export const getLicencePricingControlConfigListAction = (payload) => ({
    type: GET_LICENCE_PRICING_CONTROL_CONFIG_LIST,
    payload
});

// Get licence pricing control config
export const getLicencePricingControlConfigAction = (payload) => ({
    type: GET_LICENCE_PRICING_CONTROL_CONFIG,
    payload
});

// Create-Update licence pricing control config
export const createUpdateLicencePricingControlConfigAction = (payload) => ({
    type: CREATE_UPDATE_LICENCE_PRICING_CONTROL_CONFIG,
    payload
});

// Delete licence pricing control config
export const deleteLicencePricingControlConfigAction = (payload) => ({
    type: DELETE_LICENCE_PRICING_CONTROL_CONFIG,
    payload
});


export const getLicencePricingControlConfigList = (payload) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_licence_pricing_control_config;
        let params = {};

        if (payload.pageNum && payload.pageSize) {
            params['page'] = payload.pageNum;
            params['page_size'] = payload.pageSize;
        } else if (payload.pageNum) {
            params['page'] = payload.pageNum;
        } else if (payload.pageSize) {
            params['page_size'] = payload.pageSize;
        }
        
        if (payload.organisation) {
            params['organisation_id'] = payload.organisation;      
        }

        if (!params.page_size) {
            params['page_size'] = 99999;
        }

        url += `?${serialize(params)}`;

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getLicencePricingControlConfigListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}


// Get licence pricing control config
export const getLicencePricingControlConfig = (id) => { 
    return (dispatch) => {
        let url = config.endpoint.organisation_licence_pricing_control_config + `${id}/`;
        
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getLicencePricingControlConfigAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Create-Update licence pricing control config

export const createUpdateLicencePricingControlConfig = (payload, id) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_licence_pricing_control_config;
        let apiRequest = '';
        return new Promise((resolve, reject) => {
            if (id) {
                url += `${id}/`;
                apiRequest = api.put(url, payload);
            } else {
                apiRequest = api.post(url, payload);
            }

            apiRequest.then((res) => {
                dispatch(createUpdateLicencePricingControlConfigAction(res.data));
                resolve(Object.assign(res.data, { status: true }));
            }).catch(e => {
                reject(e)
            });
        })
    }
}

// Delete licence pricing control config
export const deleteLicencePricingControlConfig = (id) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_licence_pricing_control_config + `${id}/`;
        
        return new Promise((resolve, reject) => {
            api.delete(url).then((res) => {
                dispatch(deleteLicencePricingControlConfigAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

