import React from "react";
import { TableContext } from "../context";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import uuidv1 from "uuid/v1";
import BaseComponent from "./BaseComponent";

class Btn extends BaseComponent {
  render() {
    let { action, text, notAllowed } = this.props;
    let name = "primary";
    switch (text.toLowerCase()) {
      case "approve": {
        name = "primary";
        break;
      }
      case "view": {
        name = "primary";
        break;
      }
      case "accept": {
        name = "primary";
        break;
      }
      case "rejected": {
        name = "danger";
        break;
      }
      case "approved": {
        name = "success";
        break;
      }
      case "reject": {
        name = "warning";
        break;
      }
      case "correction_request": {
        name = "secondary";
        break;
      }
      case "correction_requested": {
        name = "secondary";
        break;
      }
      case "correction_request_approve": {
        name = "secondary";
        break;
      }
      case "correction request": {
        name = "secondary";
        break;
      }
      case "apc fund requested": {
        name = "info";
        break;
      }
      case "pending": {
        name = "primary";
        break;
      }
      case "request apc fund": {
        name = "secondary";
        break;
      }
      case "delete": {
        name = "danger";
        break;
      }
      case "create": {
        name = "primary";
        break;
      }
      case "update": {
        name = "warning";
        break;
      }
      case "upload": {
        name = "primary";
        break;
      }
      case "download": {
        name = "primary";
        break;
      }
      case "pay": {
        name = "default";
        break;
      }
      case "update payment": {
        name = "primary";
        break;
      }
      case "invoice_paid": {
        name = "primary";
        break;
      }
      case "acknowledge payment": {
        name = "primary";
        break;
      }
      case "cancel invoice": {
        name = "danger";
        break;
      }
      case "cancel request": {
        name = "danger";
        break;
      }
      case "update refund payment": {
        name = "primary";
        break;
      }
      case "paid": {
        name = "success";
        break;
      }
      default: {
        name = action;
        break;
      }
    }

    return (
      <div>
        {notAllowed ? (
          !notAllowed.includes(action) && (
            <p className="m-1">
              <button
                type={this.props.type}
                className={`btn btn-${name} btn-sm ${action !== "no_action" && "waves-effect"
                  }`}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onClickHander && this.props.onClickHander(e);
                }}
                style={{ width: "155px", cursor: "default" }}
              >
                {this.props.text}
              </button>
            </p>
          )
        ) : (
            <p className="m-1">
              <button
                type={this.props.type}
                className={`btn btn-${name} btn-sm ${action !== "no_action" && "waves-effect"
                  }`}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onClickHander && this.props.onClickHander(e);
                }}
                style={{ width: "155px", cursor: "default" }}
              >
                {this.props.text}
              </button>
            </p>
          )}
      </div>
    );
  }
}

export const ActionButton = Btn;

class Table extends TableContext {
  constructor(props) {
    super(props);
    this.data = props.data;
  }
  state = {
    rnd: false,
    isGoing: true,
    checkObj: {},
  };

  componentDidMount() { 
    let tableId = this.props.tableId || "datatable-buttons"

    let table = window.$(`#${tableId}`).DataTable({
      pageLength: this.props.pageSize || 10,
      scrollX: true,
      paging: false,
      bInfo: false,
      "ordering": false,
      searching: false,

      
    });
    table
      .buttons()
      .container()
      .appendTo("#datatable-buttons_wrapper .col-md-6:eq(0)");
  }

  toTitleCase = (str) => {
    return (
      str.replace(/_/g, " ").charAt(0).toUpperCase() +
      str.replace(/_/g, " ").substr(1).toLowerCase()
    );
  };
  onChange = (e) => {
    this.setState((prevState) => {
      Object.assign(prevState.checkObj, { [e.target.name]: e.target.checked });
    });
    this.setState({
      checkObj: this.state.checkObj,
    });
  };



  onChangePagination = (pageNumber) => {
    this.props.pageChange(pageNumber);
  };

  render() {
    function numberWithCommas(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
    let { wrap, userId } = this.props;

    let tableId = this.props.tableId || "datatable-buttons"

    return (
      this.props.heading ? (
        <div>
          <table
            id={tableId}
            className={this.props.group ==="HUB" ? "table table-striped" : "table table-striped" + (!wrap ? " nowrap" : "")}
            style={{
              borderCollapse: "collapse",
              borderSpacing: 0,
              width: "100%",
              marginTop: -7,
            }}
          >
            <thead className="text-white thead-dark">
              <tr>
                {this.props.heading.map((head, key) => {
                  if (head === "action") {
                    return (
                      <th
                        className="action"
                        key={`action-` + key}
                        style={{ textAlign: "center" }}
                      >
                        {this.toTitleCase(head.replace("_", " "))}
                      </th>
                    );
                  } else if (head === "offer_amount") {
                    return <th key={`action-` + key}>Offer amount(%)</th>;
                  } else {
                    return (
                      <th
                        key={key}
                        style={{
                          width:
                            wrap &&
                              (head === "title" ||
                                head === "article_title" ||
                                head === "article")
                              ? "35%"
                              : "auto",
                        }}
                      >
                        {this.toTitleCase(head.replace("_", " "))
                          .replace(/Vat|Oa/gi, function (x) {
                            return x.toUpperCase();
                          })
                          .replace(/Deposit/gi, "Deposited")
                          .replace(/Token id/gi, "Token ID")
                          .replace(/Article id/gi, "Article ID")
                          .replace(/Currency name/gi, "Currency")}
                      </th>
                    );
                  }
                })}
                {!this.props.noAction && <th>Action</th>}
                {this.props.checkbox && <th>Select</th>}
              </tr>
            </thead>

            <tbody>
              {this.props.data &&
                this.props.data.map((data, i) => {
                  return (
                    <tr
                      key={uuidv1(data.id)}
                      style={{
                        opacity:
                          this.props.isOaDealRequest &&
                            data.approval_status === "pending"
                            ? 0.5
                            : 1,
                      }}
                    >
                      {this.props.heading.map((head, key) => {
                        if (typeof data[head] !== "object") {
                          if (
                            head === "valid_from" ||
                            head === "valid_to" ||
                            head === "date"
                          ) {
                            return (
                              <td key={head}>
                                {data[head]
                                  ? moment(data[head]).format("Do MMM YYYY")
                                  : "-"}
                              </td>
                            );
                          } else if (head === "link") {
                            return (
                              <td key={head}>
                                <a
                                  href="/transactions/invoice"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onClickDownloadInvoice(
                                      data[head]
                                    );
                                  }}
                                >
                                  {data["label"]}
                                </a>
                              </td>
                            );
                          } else if (head === "action") {
                            return (
                              <td className="action">
                                <div key={data["id"]}>
                                  {data["actions"] &&
                                    data["actions"].map((invoice, key) => {
                                      if (invoice.label === "Paid") {
                                        return (
                                          <ActionButton
                                            notAllowed={["update", "delete"]}
                                            key={key}
                                            text={invoice.label}
                                            action={invoice.action}
                                            onClickHander={() =>
                                              this.props.onActionHandler(
                                                invoice.action,
                                                data["id"]
                                              )
                                            }
                                          />
                                        );
                                      } else {
                                        return (
                                          <ActionButton
                                            notAllowed={["update", "delete"]}
                                            key={key}
                                            text={invoice.label}
                                            action={invoice.action}
                                            onClickHander={() =>
                                              this.props.onActionHandler(
                                                invoice.action,
                                                data["id"]
                                              )
                                            }
                                          />
                                        );
                                      }
                                    })}
                                </div>
                              </td>
                            );
                          } else if (
                            head === "amount" ||
                            head === "publish_fee" ||
                            head === "read_fee" ||
                            head === "total"
                          ) {
                            return (
                              <td key={head}>
                                {data[head]
                                  ? numberWithCommas(
                                    parseFloat(data[head]).toFixed(2)
                                  )
                                  : "-"}
                              </td>
                            );
                          } else if (
                            head === "deal_type" ||
                            head === "oa_deal" ||
                            head === "oa_deal_type" ||
                            head === "deal_name"
                          ) {
                            if (data[head] === "pre-payment") {
                              return (
                                <td key={head}>
                                  {data[head] ? "Pre-payment" : "-"}
                                </td>
                              );
                            } else if (data[head] === "read & publish") {
                              return (
                                <td key={head}>
                                  {data[head] ? "Read & Publish" : "-"}
                                </td>
                              );
                            } else if (data[head] === "credit") {
                              return (
                                <td key={head}>
                                  {data[head] ? "Credit" : "-"}
                                </td>
                              );
                            } else {
                              return (
                                <td key={head}>
                                  {data[head] ? data[head] : "-"}
                                </td>
                              );
                            }
                          } else if (head === "action") {
                            return data["status"] &&
                              data["status"] === "opened" ? (
                                "action_btn"
                              ) : (
                                <div className="col-sm-2">
                                  <div></div>
                                  <div></div>
                                  <div>
                                    <p className="m-1">
                                      <button
                                        className="btn btn-default btn-sm "
                                        style={{ width: 130, cursor: "pointer" }}
                                      >
                                        {this.toTitleCase(data["status"])}
                                      </button>
                                    </p>
                                  </div>
                                </div>
                              );
                          } else if (head === "invoice_status") {
                            if (data[head] === "Opened") {
                              return (
                                <td key={head}>
                                  <label
                                    style={{
                                      marginTop: 3,
                                      color: "rgb(130, 185, 112)",
                                      width: 130,
                                      cursor: "default",
                                    }}
                                  >
                                    {data[head]}
                                  </label>
                                </td>
                              );
                            } else if (data[head] === "Payment Updated") {
                              return (
                                <td key={head}>
                                  <label
                                    style={{
                                      marginTop: 3,
                                      color: "#99d854",
                                      width: 130,
                                      cursor: "default",
                                    }}
                                  >
                                    {data[head]}
                                  </label>
                                </td>
                              );
                            } else if (data[head] === "Paid") {
                              return (
                                <td key={head}>
                                  <label
                                    style={{
                                      marginTop: 3,
                                      color: "green",
                                      width: 130,
                                      cursor: "default",
                                    }}
                                  >
                                    {data[head]}
                                  </label>
                                </td>
                              );
                            } else if (
                              data[head] === "Canceled" ||
                              data[head] === "Cancelled"
                            ) {
                              return (
                                <td key={head}>
                                  <label
                                    style={{
                                      marginTop: 3,
                                      color: "red",
                                      width: 130,
                                      cursor: "default",
                                    }}
                                  >
                                    {data[head]}
                                  </label>
                                </td>
                              );
                            } else {
                              return (
                                <td key={head}>
                                  {data[head] ? data[head] : "-"}
                                </td>
                              );
                            }
                          } else {
                            return (
                              <td className="" style={{ cursor: 'pointer', }} key={head}>
                             {this.props.group ==="HUB" ? <p style={{width:100,display:"block"}}>  {data[head] ? data[head] : "-"}</p> : data[head] ? data[head] : "-"}
                              </td> 
                            );
                          }
                        } else if (
                          head === "action" &&
                          Array.isArray(data[head])
                        ) {
                          if (this.props.isRefundRequest) {
                            return (
                              <td key={head}>
                                <div key={data["id"]}>
                                  {data["action"] &&
                                    data["action"].map(
                                      (refund_request, key) => {
                                        return (
                                          <ActionButton
                                            key={key}
                                            text={refund_request.label}
                                            action={refund_request.action}
                                            onClickHander={() =>
                                              this.props.actionHandler(
                                                refund_request.action,
                                                data
                                              )
                                            }
                                          />
                                        );
                                      }
                                    )}
                                  <ActionButton
                                    key={`view-${data["id"]}`}
                                    text={`View`}
                                    action={`view`}
                                    onClickHander={() =>
                                      this.props.actionHandler(`view`, data)
                                    }
                                  />
                                </div>
                              </td>
                            );
                          } else {
                            return "";
                          }
                        } else {
                          return (
                            <td key={head}>
                              {data[head] ? JSON.stringify(data[head]) : "-"}
                            </td>
                          );
                        }
                      })}
                      {!this.props.noAction && (
                        <td>
                          {data.approval_status !== "approved" &&
                            !this.props.isChildOrgList && (
                              <span>
                                
                                {this.props.onDelete &&
                                  data.deleteAction !== false ? (
                                    <span>
                                      <span
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.props.onDelete &&
                                            this.props.onDelete(data.id);
                                        }}
                                        id="deleteBtn"
                                        title="Delete"
                                      >
                                        <i className="mdi mdi-delete"></i>
                                      </span>
                                      {this.props.onDelete &&
                                        this.props.onStatusChange &&
                                        "|"}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  { userId !== data.id && this.props.onStatusChange ? (
                                    <span>
                                      <span
                                        className="cursor-pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.props.onStatusChange &&
                                            this.props.onStatusChange(data.id, data.is_active);
                                        }}
                                        id="deleteBtn"
                                        title={data.is_active ? `Click to deactivate the user` : `Click to activate the user`}
                                      >
                                        <i className={`mdi ${data.is_active ? 'mdi-lock-open' : 'mdi-lock'}`}></i>
                                      </span>
                                      {this.props.onStatusChange &&
                                        this.props.onEdit &&
                                        "|"}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                {this.props.onEdit && (
                                  <span
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.onEdit &&
                                        this.props.onEdit(data.id, data);
                                    }}
                                    title="Edit"
                                  >
                                    <i className="mdi mdi-pen"></i>
                                  </span>
                                )}
                                {this.props.onEdit && this.props.onView && "|"}
                              </span>
                            )}
                          {this.props.onView && (
                            <span
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.onView &&
                                  this.props.onView(data.id, data);
                              }}
                              title="View"
                            >
                              <i className="mdi mdi-eye"></i>
                              { this.props.onProxyLogin && "|"}
                            </span>
                          )}

                          {this.props.onProxyLogin && (
                            <span
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.onProxyLogin &&
                                  this.props.onProxyLogin(data.id, data);
                              }}
                              title={`Login as ${data.fullname}`}
                            >
                              <b>{`Login as`}</b>
                            </span>
                          )}
                        </td>
                      )}

                      {this.props.checkbox && (
                        <td>
                          <p style={{ textAlign: "center" }}>
                            <Checkbox
                              checked={
                                this.state.checkObj
                                  ? this.state.checkObj["selected" + data.id]
                                  : false
                              }
                              disabled={this.state.disabled}
                              name={"selected" + data.id}
                              onChange={this.onChange}
                              ref={"ref" + data.id}
                              value={data.id}
                              onClick={() => {
                                this.props.onSelect &&
                                  this.props.onSelect(data.id);
                              }}
                            ></Checkbox>
                          </p>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {
            this.props.count>0 ?   <Pagination
              showSizeChanger={false}
              /*current={this.props.current}*/
            style={{ marginTop: 10, marginBottom: 20, textAlign: "center" }}
            onChange={this.onChangePagination}
            pageSize={this.props.pageSize || 10}
            total={this.props.count || undefined}
          />:  <div className="">
          <div className="card-body text-center">No Data Found</div>
        </div>
        
          }
          
        
        </div>
      ) : (
          <div className="card">
            <div className="card-body text-center">No Data Found</div>
          </div>
        )
    );
  }
}

Table.propTypes = {
  heading: PropTypes.array,
};

export const DataTable = Table;
