import config, {
  GET_ORG_LIST,
  UPDATE_CHILD_ORGANISATION,
  GET_ORG_AUTO_APPROVAL_CONFIG,
  TRIGGER_ORG_AUTO_APPROVAL_CONFIG,
  CREATE_UPDATE_ORG_AUTO_APPROVAL_STATUS,
  REQUEST_CREATE_ORG,
  FAILED_CREATE_ORG,
  GET_ORG_LIST_ALL,
  REQUEST_UPDATE_ORG,
  DELETE_ORG_DATA,
  REQUEST_FAILED_DELETE_USER,
  SINGLE_ORG_DATA,
  FAILED_SINGLE_ORG_DATA,
  GET_ORG_FINANCIAL_REPORT,
  ORG_LIST_COUNT,
  GET_PUBLISHER,
  ORGANISATION_FILE_UPLOAD,
  TRACK_ORGANISATION_FILE_UPLOAD_STATUS,
  GET_APC_ELG_INST_LIST,
  GET_APC_ELG_JNL_LIST,
  GET_APC_ELG_FUNDER_LIST,
  GET_APC_ELG_COUNTRY_LIST,
  GET_APC_ELG_CONTENT_TYPE_LIST,
  GET_APC_ELG_SOCIETY_LIST,
  GET_APC_ELG_SUBMIT_RESPONSE,
  REQUEST_ORG_SEARCH,
  GET_GROUP_PERMISSION,
  GET_PERMISSION_ORGS,
  REQUEST_ORG_SINGLE_DATA
} from "../../config";
import axios from "axios";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Loaded into App Reducers
export const singleRequestOrgData = (payload) => ({
  type: REQUEST_ORG_SINGLE_DATA,
});
export const requestOrgAction = (payload) => ({
  type: REQUEST_ORG_SEARCH,
});
export const getOrgs = (payload) => ({
  type: GET_ORG_LIST,
  payload,
});
export const getgroupPermissionReducer = (payload) => ({
  type: GET_GROUP_PERMISSION,
  payload,
});
export const getorgsPermissionReducer = (payload) => ({
  type: GET_PERMISSION_ORGS,
  payload,
});

// Loaded into App Reducers
export const getAllOrgs = (payload) => ({
  type: GET_ORG_LIST_ALL,
  payload,
});

// Sign Up Action Creator
export const recievedOrgData = (payload) => ({
  type: REQUEST_CREATE_ORG,
  payload,
});

// Failed Response
export const failedCreatingOrg = (payload) => ({
  type: FAILED_CREATE_ORG,
  payload,
});

export const failedUpdateOrg = (payload) => ({
  type: REQUEST_UPDATE_ORG,
  payload,
});

export const updateOrgData = (payload) => ({
  type: REQUEST_UPDATE_ORG,
  payload,
});

export const updateChildOrg = (payload) => ({
  type: UPDATE_CHILD_ORGANISATION,
  payload,
});
export const failedDeleteUser = (payload) => ({
  type: REQUEST_FAILED_DELETE_USER,
  payload,
});

// Clearing failer status
export const deleteOrgData = (id) => ({
  type: DELETE_ORG_DATA,
  id,
});

export const singleOrgData = (payload) => ({
  type: SINGLE_ORG_DATA,
  payload,
});

export const failedSingleOrgData = (payload) => ({
  type: FAILED_SINGLE_ORG_DATA,
  payload,
});

export const orgFinancialReport = (payload) => ({
  type: GET_ORG_FINANCIAL_REPORT,
  payload,
});

export const orgListCount = (payload) => ({
  type: ORG_LIST_COUNT,
  payload,
});

// Get organisation approval config
export const getOrgAutoApprovalConfig = (payload) => ({
  type: GET_ORG_AUTO_APPROVAL_CONFIG,
  payload,
});

// Create & Update organisation approval status
export const createUpdateOrgAutoApprovalStatus = (payload) => ({
  type: CREATE_UPDATE_ORG_AUTO_APPROVAL_STATUS,
  payload,
});

// Get organisation approval config
export const triggerOrgAutoApprovalConfig = (payload) => ({
  type: TRIGGER_ORG_AUTO_APPROVAL_CONFIG,
  payload,
});

// GET Publisher
export const getPublisherReducer = (payload) => ({
  type: GET_PUBLISHER,
  payload,
});

// Organisation file upload reducer
export const organisationFileUploadReducer = (payload) => ({
  type: ORGANISATION_FILE_UPLOAD,
  payload,
});

// Organisation file upload tracking reducer
export const trackOrganisationFileUploadStatusReducer = (payload) => ({
  type: TRACK_ORGANISATION_FILE_UPLOAD_STATUS,
  payload,
});

// APC Eligibility
export const getAPCElgData = (action_type, payload) => ({
  type: action_type,
  payload,
});
// End APC Eligibility

// Get Organization List
export const getOrgList = (payload) => {
  return (dispatch) => {
    let url = payload
      ? config.endpoint.org +
        "?domain=" +
        payload.group +
        "&fields=id,name&page_size=999999"
      : `${config.endpoint.org}?fields=id,name&page_size=999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgs(res.results));
          dispatch(orgListCount(res.count));
          resolve(res.results);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const hubgetOrgList = (payload) => {
  return (dispatch) => {
    let url = payload
      ? `${config.endpoint.org}?${payload}&field=id,name&page_size=50`
      : `${config.endpoint.org}?fields=id,name&page_size=50`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgs(res.results));
          dispatch(orgListCount(res.count));
          resolve(res.results);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Organization List
export const getOrgListAllCheck = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.org}?page_size=999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgs(res.results));
          dispatch(orgListCount(res.count));
          resolve(res.results);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Organization List
export const getOrgListAll = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.org}?page_size=999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgs(res.results));
          dispatch(orgListCount(res.count));
          resolve(res.results);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Organization Author suggestions List
export const getOrganisationAuthorSuggestions = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.organisation_author_suggestions}?q=${payload.q}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Organization List
export const getOrganisationList = (payload = {}) => {
  return (dispatch) => {
    let url = config.endpoint.org;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (payload.q) {
      params["q"] = payload.q;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgs(res.results));
          dispatch(orgListCount(res.count));
          resolve(res.results);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Organization List
export const getChildOrganisationList = (payload) => {
  return (dispatch) => {
    let url = payload
      ? config.endpoint.child_organisation + "?page=" + payload.pageNum
      : config.endpoint.org;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgs(res.results));
          dispatch(orgListCount(res.count));
          resolve(res.results);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Organization List
export const getOrgInfo = (id) => {
  return (dispatch) => {
    let url = config.endpoint.org + id + "/";
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Request from hub
export const CreateOrg = (orgData = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;
      return api
        .createOrgHandler(orgData, token)
        .then(
          (res) => {
            dispatch(
              recievedOrgData(Object.assign(res.data, { status: res.status }))
            );
            resolve(res.data);
          },
          (error) => {
            dispatch(
              failedCreatingOrg(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            reject(error);
          }
        )
        .catch((error) => {
          dispatch(failedCreatingOrg(Object.assign({ status: 500 })));
          reject(error);
        });
    });
  };
};

export const UpdateOrg = (orgData = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;
      return api
        .updateOrgHandler(orgData, token)
        .then(
          (res) => {
            dispatch(
              updateOrgData(Object.assign(res.data, { status: res.status }))
            );
            resolve(res.data);
          },
          (error) => {
            dispatch(
              failedUpdateOrg(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            reject(error);
          }
        )
        .catch((error) => {
          dispatch(failedUpdateOrg(Object.assign({ status: 500 })));
          reject(error);
        });
    });
  };
};

export const deleteOrg = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;
      return api
        .deleteOrgHander(id, token)
        .then(
          (res) => {
            dispatch(deleteOrgData(id));
            resolve({ status: true });
          },
          (error) => {
            dispatch(
              failedDeleteUser(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

export const singleOrg = (id) => {
  return (dispatch, getState) => {
    dispatch(singleRequestOrgData());
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;
      return api
        .singleOrgHander(id, token)
        .then(
          (res) => {
            dispatch(singleOrgData(res));
            resolve({ status: true });
          },
          (error) => {
            dispatch(
              failedSingleOrgData(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

export const getPublicOrg = () => {
  const url = config.endpoint.public_org;
  return (dispatch) => {
    api.get(url, "").then(
      (res) => {
        dispatch(getOrgs(res.results));
      },
      (error) => {}
    );
  };
};

export const getArticlePublisher = () => {
  return (dispatch) => {
    let url = config.endpoint.article_publisher;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getPublisherReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getGroupPermission = (id) => {
  return (dispatch) => {
    let url = `${config.endpoint.group_permission_summary}${id}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getgroupPermissionReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getOrgsGroupPermission  = (id) => {
  return (dispatch) => {
    let url = `${config.endpoint.organisation_permission_summary}${id}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getorgsPermissionReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getPublicPublisher = () => {
  return (dispatch) => {
    let url = config.endpoint.publisher + "?page=1&page_size=1000";
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getPublisherReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

let cancelToken;
export const getPublicOrgSignup = (value) => {
  return async (dispatch, getState) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    try {
      let url = `${config.baseURL}organisation/?q=${value}&fields=id,name&exclude_hub=true&page_size=50`;
      dispatch(requestOrgAction());
      const res = await axios.get(
        url,
        { cancelToken: cancelToken.token } //Pass the cancel token to the current request
      );
      dispatch(getOrgs(res.data.results));
    } catch (error) {}
  };
};

export const getPublicOrganisations = (value) => {
  return new Promise((resolve, reject) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    let url = `${config.baseURL}organisation/?q=${value}&fields=id,name&exclude_hub=true&page_size=50`;

    axios
      .get(url, {
        cancelToken: cancelToken.token, // Pass the cancel token to the current request
      })
      .then((res) => {
        resolve(Object.assign(res.data, { status: true }));
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAPCElgInstitutions = () => {
  const url =
    config.endpoint.institution +
    "?exclude-funder=true&fields=id,name&page_size=9999999";
  return (dispatch) => {
    api.get(url, "").then(
      (res) => {
        dispatch(getAPCElgData(GET_APC_ELG_INST_LIST, res.results));
      },
      (error) => {}
    );
  };
};

export const getAPCElgFunders = (payload) => {
  const url =
    config.endpoint.institution +
    "?exclude-inst=true&fields=id,name&page_size=9999999";

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getAPCElgData(GET_APC_ELG_FUNDER_LIST, res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getAPCElgJournals = () => {
  const url = config.endpoint.publication + "?fields=id,name&page_size=9999999";
  return (dispatch) => {
    api.get(url, "").then(
      (res) => {
        dispatch(getAPCElgData(GET_APC_ELG_JNL_LIST, res.results));
      },
      (error) => {}
    );
  };
};

export const getAPCElgCountries = (payload) => {
  const url = config.endpoint.country + "?fields=id,name&page_size=9999999";

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getAPCElgData(GET_APC_ELG_COUNTRY_LIST, res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getAPCElgContentTypes = (id) => {
  const url = id
    ? `${config.endpoint.content_type}?publication=${id}`
    : config.endpoint.content_type + "?fields=id,name&page_size=9999999";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getAPCElgData(GET_APC_ELG_CONTENT_TYPE_LIST, res.results));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {}
      );
    });
  };
};

export const getAPCElgSocieties = (payload) => {
  const url =
    config.endpoint.society_list + "?fields=id,name&page_size=9999999";

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getAPCElgData(GET_APC_ELG_SOCIETY_LIST, res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const submitAPCEligibility = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.apc_eligibility;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(getAPCElgData(GET_APC_ELG_SUBMIT_RESPONSE, res.data));
          resolve(Object.assign(res.data, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// End For APC Eligibility

// Get Currency  List
export const getOrgFinancialReport = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("organization-finance-report/" + id)
        .then((res) => {
          dispatch(orgFinancialReport(res.data));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get organisation auto approval config
export const getOrganisationAutoApprovalConfig = () => {
  return (dispatch) => {
    let url = config.endpoint.org_auto_approval_config;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgAutoApprovalConfig(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Update organisation auto approval status
export const updateOrganisationAutoApprovalStatus = (id, payload) => {
  return (dispatch) => {
    let url = config.endpoint.org_auto_approval_config + id + "/";
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(createUpdateOrgAutoApprovalStatus(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Create organisation auto approval status
export const createOrganisationAutoApprovalStatus = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.org_auto_approval_config;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createUpdateOrgAutoApprovalStatus(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Trigger organisation auto approval config
export const triggerOrganisationAutoApprovalConfig = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.org_auto_approval_config_trigger + "?" + payload;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(triggerOrgAutoApprovalConfig(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Update child organisation
export const updateChildOrganisation = (id, payload) => {
  return (dispatch) => {
    let url = config.endpoint.child_organisation + id + "/";
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(updateChildOrg(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Organisation file upload action
export const organisationFileUpload = (payload) => {
  let url = config.endpoint.organisation_file_upload;
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return new Promise((resolve, reject) => {
      let filename = payload.get("filename");
      let headers = {
        "Content-Disposition": `attachment; filename="${filename}"`,
      };

      payload.delete("filename");

      api
        .post(url, payload, token, headers)
        .then((res) => {
          dispatch(organisationFileUploadReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(organisationFileUploadReducer(error.response));
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Track file upload status
export const trackOrganisationFileUploadStatus = (payload) => {
  let url = payload.upload_status;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(trackOrganisationFileUploadStatusReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(trackOrganisationFileUploadStatusReducer(error.response));
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};
