import React from "react";
import { withRouter } from "react-router-dom";
import { Radio } from "antd";

import { Button } from "../../../../../core/components/common";
import "react-color-picker/index.css";
class ReminderUI extends React.Component {
  render() {
    const {
      reminder_1,
      reminder_2,
      reminder_3,
      onChangeRemdiner,
      UpdateInvoiceNotification,
      onChangeValue,
      send_email_on_trigger,
    } = this.props;

    return this.props.paymentReminder ? (
      <div className="addmultfield" style={modal.overlay}>
        <div className={`col-sm-5 bg-white alert`} style={modal.boxStyle}>
          <div className="vat-tax-button email">
            <Button
              type="danger"
              name="danger"
              text="X"
              onClick={() => this.props.history.push("/settings/invoice-configuration")}
            />
          </div>
          <div className="invoice-reminder">
            <div className="reminder">
              <h3>Please enter your 1st payment reminder</h3>
              <input
                min="0"
                id="number"
                name="reminder_1"
                type="number"
                value={reminder_1}
                onChange={onChangeRemdiner}
              />
            </div>
            <div className="reminder">
              <h3>Please enter your 2nd payment reminder</h3>
              <input
                
                min="0"

                id="number"
                name="reminder_2"
                type="number"
                value={reminder_2}
                onChange={onChangeRemdiner}
              />
            </div>
            <div className="reminder">
              <h3>Please enter your 3rd payment reminder</h3>

              <input
                                min="0"

                id="number"
                name="reminder_3"
                type="number"
                value={reminder_3}
                onChange={onChangeRemdiner}
              />
            </div>
            <div className="reminder reminder-radiobox">
              <h3>
                Would you like to receive a copy of payment reminder email?
              </h3>
              <Radio.Group
                onChange={onChangeValue}
                value={send_email_on_trigger ? 1 : 0}
              >
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </div>
            <div className="row reminder-submit">
              <div className="col-md-12 invoice-notification">
                <Button
                  onClick={UpdateInvoiceNotification}
                  type="button"
                  name="primary"
                  text="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
        ""
      );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(25px, 100px)",
    height: boxHeight,
  },
};


export default withRouter(ReminderUI);
