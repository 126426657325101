import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Loading, PopupBox, ModalCustom } from "../../../../../../core/components/common";
import { HubContent } from "../../../../../../core/components";
import { AdHocEmailForm } from "../../../../partials";
import { isArray } from "lodash";
import { Alert } from "antd"
import {
  ArticalEmailHistoryLog,
  downloadArticleInvoice,
  auditEmailResend,
  sendFreeTextEmail,
} from "../../../../../actions";


class AuditHistoryLog extends React.PureComponent {
  state = {

  };

  processingTimeHiddenFor = ['AUTHOR', 'FUNDER', 'INSTITUTION']

  async componentDidMount() {
    await this.props.ArticalEmailHistoryLog(this.props.match.params.id)

    let result
    if (typeof Object.keys(((this.props.auditTrailMessage && this.props.auditTrailMessage.timeline_info) || {})) !== 'undefined' && Object.keys((this.props.auditTrailMessage && this.props.auditTrailMessage.timeline_info) || {}).length > 0) {
      result = Object.entries(this.props.auditTrailMessage && this.props.auditTrailMessage.timeline_info).map(([date, item]) => ({
        date,
        item,
      }));

    }

    this.setState({
      email_history_list: result,
      article_info: this.props.auditTrailMessage && this.props.auditTrailMessage.article_info,
    });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  onClickDownloadInvoice = async (url, fileName) => {
    let payload = {
      url: url,
    };

    await this.props.downloadArticleInvoice(payload);

    const urlObj = window.URL.createObjectURL(this.props.invoice_download);
    const link = document.createElement("a");
    link.href = urlObj;

    link.setAttribute(
      "download",
      `${fileName}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  resendEmail = async (context, context_data) => {
    let EmailData = await this.props.auditEmailResend({
      context: context,
      context_data: context_data
    })
    if (EmailData.status) {
      this.setState({
        success: true
      })
    }
  }

  replyEmail = (contextData) => {
    let articleData = contextData.article_info;
    let subject = contextData.subject;

    if (!subject.startsWith('Reply:')) {
      subject = `Reply: ${subject}`;
    } else if (subject) {
      subject = subject.replace('Reply: Reply: ', 'Reply: ');
    }

    let recipients = [];

    if (contextData && contextData.from_user_email) {
      recipients = [{
        label: contextData.from_user_email,
        value: contextData.from_user_email,
      }];
    }

    subject = subject.trim();

    this.setState({
      showCustomEmailForm: true,
      article_id: articleData.id,
      message_body: subject,
      subject,
      recipients,
      article_single: contextData.article_info,
    });
  }


  onCancel = () => {
    this.setState({
      isEmpty: false,
      successMsg: false,
    });
  };

  onClose = () => {
    this.setState({
      success: false
    })
  }

  onCloseHandler = () => {
    this.setState({
      showCustomEmailForm: false,
      article_id: '',
    });
  }

  onChangeHandler = (value) => {
    this.setState({
      ...value,
    });
  }

  onSubmitHandler = (e) => {

    let data = {
      article_id: this.state.article_id,
      organisation: this.state.organisation,
      subject: this.state.subject,
      recipients: this.state.recipients,
      message_body: this.state.message_body,
    };

    if (!data.article_id || !data.subject || !data.recipients || (isArray(data.recipients) && data.recipients.length === 0) || !data.message_body) {
      this.setState({
        isEmpty: <div>
          {!data.article_id && <p>Article is required.</p>}
          {(!data.recipients || (isArray(data.recipients) && data.recipients.length === 0)) && <p>Recipient is required.</p>}
          {!data.subject && <p>Subject is required.</p>}
          {!data.message_body && <p>Message is required.</p>}
        </div>
      })
    } else {
      this.setState({
        emailLoading: true,
      });

      this.props.sendFreeTextEmail(data).then(response => {
        if (response.data && response.data.success) {
          this.setState({
            emailLoading: false,
            showCustomEmailForm: false,
            successMsg: response.data.message,
            article_id: '',
            organisation: '',
            subject: '',
            recipients: '',
            message_body: '',
          });
        }
      }).catch(error => {
        this.setState({
          emailLoading: false,
        });

        return error.response && error.response.data && error.response.data.success;
      });
    }
  };

  showMoreInfoWithLabel = (item) => {
    if (!item.has_more_detail || !item.context_data) {
      return null;
    }

    if (item.context === "ARTICLE_REVIEW") {
      let reviewerData = item.context_data && item.context_data.reviewer;

      if (!reviewerData) {
        return null;
      }

      let reviewerName = [];

      if (reviewerData.salutation) {
        reviewerName.push(reviewerData.salutation);
      }

      if (reviewerData.first_name) {
        reviewerName.push(reviewerData.first_name);
      }

      if (reviewerData.middle_name) {
        reviewerName.push(reviewerData.middle_name);
      }

      if (reviewerData.last_name) {
        reviewerName.push(reviewerData.last_name);
      }

      let extraInfo = '';

      if (reviewerData.email) {
        extraInfo += `, ${reviewerData.email}`;
      }

      if (reviewerData.affiliation) {
        extraInfo += `, ${reviewerData.affiliation}`;
      }

      if (reviewerData.affiliation_country) {
        extraInfo += `, ${reviewerData.affiliation_country}`;
      }
      return (
        `(${reviewerName.join(' ')}${extraInfo})`
      )
    } else if (item.context === "APC_REQUEST" || item.context === "APC_REQUEST_AND_EMAIL") {
      let authorName = item.context_data.author && item.context_data.author.full_name;
      let requestedToOrganisation = item.context_data.apc_request && item.context_data.apc_request.counter_organisation;

      return (
        ` (Author: ${authorName}, Requested to: ${requestedToOrganisation})`
      );

    } else if (item.context === "APC_REQUEST_MUTATION" || item.context === "APC_REQUEST_REJECTED_AND_EMAIL") {
      let organisationName = item.context_data.user && item.context_data.user.organisation_name;
      
      return (
        ` by ${organisationName}`
      );

    } else if (item.context === "INVOICE" || item.context === "APC_PAYMENT" || (item.context_data && item.context_data.email_type === "AUTHOR_NOTIFICATION_EMAIL")) {
      let info = [];

      if (item.context_data && item.context_data.email_type === "AUTHOR_NOTIFICATION_EMAIL" && item.context_data.author_name) {
        info.push(item.context_data.author_name)
      }

      if (item.context_data && item.context_data.user_group === 'AUTHOR') {
        info.push(item.context_data.author_type)
      }

      if (item.context_data && item.context_data.organisation_name) {
        info.push(item.context_data.organisation_name)
      }

      if (info.length > 0) {
        return (
          ` (${info.join(', ')})`
        );
      }
    } else if (item.context === "AUTO_APPROVAL") {
      let dealTypeName = item.context_data.deal_type_name;

      return (
        ` (Deal name: ${dealTypeName})`
      );

    }

    return null;
  }

  showMoreInfo = (item) => {
    if (!item.has_more_detail) {
      return null;
    }

    if (item.context === "INVOICE") {
      return (
        <div className="col-md-4">
          <Button
            type="primary"
            text="Download Inv"
            name="primary"
            className="article_invoice_download"
            onClick={(e) =>
              this.onClickDownloadInvoice(
                item.context_data.download_url,
                (item.context_data && item.context_data.file_name) || 'Invoice'
              )
            }
          />
        </div>
      )
    } else if (item.context === "APC_PAYMENT") {
      return (
        <div className="col-md-4">
          <Button
            type="primary"
            text="Download Receipt"
            name="primary"
            className="article_receipt_download"
            onClick={(e) =>
              this.onClickDownloadInvoice(
                item.context_data.download_url,
                (item.context_data && item.context_data.file_name) || 'Receipt'
              )
            }
          />
        </div>
      )
    } else if (item.context === "EMAIL" || item.context === "APC_REQUEST_AND_EMAIL" || item.context === "APC_REQUEST_REJECTED_AND_EMAIL") {
      return (
        <div className="col-md-4">
          <Button
            type="primary"
            text="View Email"
            btn_size="100px"
            name="primary"
            onClick={() =>
              this.props.history.push({
                pathname: "/audit/audit-message",
                article_id:this.props.match.params.id,
                state: item.context_data,
                filter: this.props.location && this.props.location.filter,
                id: this.props.location && this.props.location.id

              })
            }
          />
          <div style={{ marginTop: 10 }}>
            {item.action_type === 'RESEND' &&
              <Button
                type="info"
                btn_size="100px"

                text="Resend email"
                name="info"
                onClick={() => this.resendEmail(item.context, item.context_data)}
              />}

            {item.action_type === 'REPLY' &&
              <Button
                type="info"
                btn_size="100px"

                text="Reply email"
                name="info"
                onClick={() => this.replyEmail(item.context_data)}
              />
            }
          </div>
        </div>
      )
    }

    return null;
  }

  humanize = (str) => {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }

    return frags.join(' ');
  }

  showProcessingTime = (item) => { 
    
    if (!item.has_more_detail) {
      return null;
    }

    if (item.context_data && item.context_data.processing_time) {
      return (
        <div className="col-md-12" >
          {
            item.context_data.processing_time.map(innerItem => {
              return (
                <div key={innerItem.from}>
                  <b>{this.humanize(innerItem.from)} to {this.humanize(innerItem.to)}</b> : {innerItem.number_of_days} days
                </div>
              )
            })
          }
        </div>
      )
    }

    return null;
  }


  render() {

    const { email_history_list, success, showCustomEmailForm } = this.state;
    const { group } = this.props

    let successErrMsgPop = <>
      {this.state.isEmpty && <PopupBox
        Title="Following field cannot be empty"
        msg={this.state.isEmpty}
        onCancel={this.onCancel}
      />}

      {this.state.successMsg && <PopupBox
        title="Success!"
        msg={this.state.successMsg}
        onCancel={this.onCancel}
      />}
    </>;
    return (
      <HubContent>
        { successErrMsgPop}

        {
          success === true && <PopupBox
            title="Email Resent "
            onCancel={this.onClose}
            msg="You have successfully resend the email."
          />
        }

        {showCustomEmailForm && <ModalCustom
          title="Reply Email"
          onClose={this.onCloseHandler}
          modalClass="col-sm-10 col-md-8"
          isShow={showCustomEmailForm}
          submitBtnTxt="Send"
          onSubmit={this.onSubmitHandler}
        >

          <AdHocEmailForm
            emailLoading={this.state.emailLoading}
            publication_name={this.state.article_info && this.state.article_info.publication_name}
            article={this.state.article_single}
            subject={this.state.subject}
            message_body={this.state.message_body}
            recipients={this.state.recipients}
            article_id={this.state.article_id}
            onChangeHandler={this.onChangeHandler}
          />

        </ModalCustom>}

        <div className="col-md-12 email-log">

          <div className="audit-trail">
            <h3>Audit Trail</h3>
            <div className="artical-email-history-btn">
              <button
                type="button"
                className="btn btn-danger "
                data-dismiss="modal"
                onClick={() => this.props.history.push(this.props.group === "PUBLISHER" ? {
                  pathname: "/article/publisher-articles",
                  filter: this.props.location && this.props.location.filter,
                  id: this.props.location && this.props.location.id
                } : this.props.group === "AUTHOR" ? {
                    pathname: "/my-article", filter: this.props.location && this.props.location.filter,
                    id: this.props.location && this.props.location.id

                  }:  this.props.group==="HUB" ?  {
                      pathname: "/articles/article-full", filter: this.props.location && this.props.location.filter,
                      id: this.props.location && this.props.location.id

                }
                    : {
                      pathname: "/articles", filter: this.props.location && this.props.location.filter,
                      id: this.props.location && this.props.location.id

                    })}  >
                X
              </button>
            </div>
            
          </div>
          {this.state.article_info && this.state.article_info.title && (
            <div className="audit-article">
              <h3>
                Title -
                {this.state.article_info && this.state.article_info.title}
              </h3>
   
            </div>
          )}
          {this.props.auditTrailLoading !== true ? (
            <div className="timeline">
              <ul>
                {
                  email_history_list &&
                  email_history_list.map((data, index) => (
                    <li key={index}>
                      <div className="content email-message-log">
                        {data &&
                          data.item.map((item) => (
                            <div key={item.id}>
                              <div className="row-content">
                                <div className={item.context === "ARTICLE_REVIEW" ? 'col-md-12' : 'col-md-8'}>{item.label} {this.showMoreInfoWithLabel(item)}</div>

                                {this.showMoreInfo(item)}

                              </div>
                              
                              { !this.processingTimeHiddenFor.includes(group) && item.context_data && item.context_data.processing_time && <div className="row-content">
                                {this.showProcessingTime(item)}
                              </div>}
                            </div>
                          ))}
                      </div>
                      <div className="point"></div>
                      <div className="date">
                        <h4>{data && data.date}</h4>
                      </div>
                    </li>
                  ))}

                {
                  this.props.auditTrailMessage && this.props.auditTrailMessage.timeline_info && Object.keys(this.props.auditTrailMessage && this.props.auditTrailMessage.timeline_info).length === 0 &&
                  <Alert
                    message="No Audit Trail"
                    description="It seems that There is no audit trial for this article."
                    type="info"
                  />
                }
              </ul>
            </div>
          ) : (
            <Loading type="flat" />

          )}
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state, props) => ({
  group: (state.auth && state.auth.group),
  invoice_download: (state.payment && state.payment.invoice_download),
  auditTrailMessage: state.articleAuditEmail.articleAuditLog,
  auditTrailLoading: state.articleAuditEmail.loading

});

const mapDispatchToProps = (dispatch, props) => ({
  ArticalEmailHistoryLog: (payload) =>
    dispatch(ArticalEmailHistoryLog(payload)),
  downloadArticleInvoice: (payload = {}) =>
    dispatch(downloadArticleInvoice(payload)),
  auditEmailResend: (payload) =>
    dispatch(auditEmailResend(payload)),
  sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
});

export const ArticleAuditTrailLogs = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditHistoryLog)
);