import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PublisherArticalForm from "./PublisherArticalForm";
import BaseComponent from "../../../../../core/components";
import { PopupBox } from "../../../../../core/components/common";

import {
  getContentTypeList,
  articleFileUpload,
  trackArticleFileUploadStatus,
  getPublicatonTypeList,
  CreateArticle,
  EditArticle,
  getSingleArticle,
  trackArticleFileUploadStatusDownload,
  getJournalTypeList,
  getAllCounterOrgList,
} from "../../../../actions";

class Article extends BaseComponent {
  state = {
    article: false,
    isLoading: false,
    status: null,
    success: false,
    fileUploadStatus: null,
    fileUploadStatusMsg: null,
    pageSubtitle: "Enter article details",
  };

  async componentDidMount() {
    this.props.getAllCounterOrgList();
    await this.props.getJournalTypeList();
    await this.props.getContentType();
    await this.props.getPublicatonType();
  }

  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
      if (
        !obj[key] ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  };

  onSubmitHandler = async (payload) => {

    if (typeof payload && payload.author !== undefined) {
      this.removeEmpty(payload && payload.author)
    }
    payload.co_authors && payload.co_authors.map((item) => this.removeEmpty(item))

    await this.props.CreateArticle(payload);

    if (this.props.status === 201) {
      this.setState({
        success: true,
      });
    }
  };

  onArticleFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });

    await this.props.articleFileUpload(payload);
    if (this.props.article_file_upload.status) {
      let payload = {
        upload_status: this.props.article_file_upload.data.upload_status.slice(
          1
        ),
      };
      this.setState({
        upload_payload: payload,
      });
      await this.props.trackArticleFileUploadStatus(payload);
    }

    if (
      this.props.track_upload_status &&
      this.props.track_upload_status.reason
    ) {
      let upload_id = this.props.track_upload_status.id;

      let upload_download_status = `file-upload-error-response/${upload_id}/`;
      let payloadDownload = {
        upload_status: upload_download_status,
      };
      await this.props.trackArticleFileUploadStatusDownload(payloadDownload);
    }

    this.setState({
      fileUploadStatus: this.props.article_file_upload.status,
      fileUploadStatusMsg: this.props.article_file_upload.data.message,
      isLoading: false,
    });
  };

  cancelLoader = async () => {
    this.setState({
      status: null,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };
  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push("/article/publisher-articles");
  };

  render() {
    let { article } = this.state;
    let successMessage = (
      <>
        {this.state.success && (
          <PopupBox
            NoText="Close"
            msg="You have successfully entered an article. Please check the Failed Transfer or Articles & Books to rectify it."
            onCancel={this.redirectLink}
          />
        )}
      </>
    );
    return (
      <>
        {successMessage}
        <PublisherArticalForm
          counter_org={this.props.counter_org}
          journal_type={this.props.journal_type}
          onSubmitHandler={this.onSubmitHandler}
          articleFileUploadHandler={this.onArticleFileUpload}
          article={article}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  journal_type: state.journals.journal_type,
  counter_org: state.OaDeals.counter_org ,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  article_file_upload: state.articles.article_file_upload ,
  track_upload_status: state.articles.track_upload_status ,
  track_upload_status_download:
    state.articles.track_upload_status_download ,
});

const mapDispatchToProps = (dispatch) => ({
  getContentType: () => dispatch(getContentTypeList()),
  getPublicatonType: () => dispatch(getPublicatonTypeList()),
  CreateArticle: (payload) => dispatch(CreateArticle(payload)),
  EditArticle: (payload) => dispatch(EditArticle(payload)),
  getSingleArticle: (id) => dispatch(getSingleArticle(id)),
  articleFileUpload: (payload) => dispatch(articleFileUpload(payload)),
  trackArticleFileUploadStatus: (payload) =>
    dispatch(trackArticleFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) =>
    dispatch(trackArticleFileUploadStatusDownload(payload)),
  getJournalTypeList: () => dispatch(getJournalTypeList()),
  getAllCounterOrgList: () => dispatch(getAllCounterOrgList()),
});

export const CreatePublisherArticle = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Article)
);
