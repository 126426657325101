import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  SelectBox,
  InputBox,
  Button,
  RadioButtonGroup,
  PopupBox,
  // ErrorHandler,
  ModalCustom,
  Loading
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import moment from "moment";

import {
  getLicense,
  getPublisherLicense,
  getApcOption,
  getArticleApcOption,
  getApcFundSrc,
  downloadInvoice,
  articleApproveSummary,
  applyTokenAction,
  clearToken,
  getOaTokenisActive,
  ArticleApprove,
  getOrganisationToken
} from "../../../../actions";
import { history } from "../../../../route";
import { PaymentHome } from "../../../partials/payment";
import { Checkbox } from "antd";
import config from "../../../../../config";

const articlePublisherTerms = config.endpoint.article_publisher_terms;
const articlePublisherLicence = config.endpoint.article_publisher_licence;

class ArticleApprovalForm extends BaseComponent {
  state = {

    article: false,
    msg: false,
    isValidToken: true,
    currency: "",

    approval: {
      article: Number(this.props.match.params.id),
      licence: null,
      apc_option: null,
      approval_source: null,
      breakdown: []
    },
    termsandcondition: true,
    license: [],
    apc_options: [],
    apc_fund_src: [],
    oa_tokens: [],
    article_approve_summary: false,
    isToken: false,
    OaDeal: false,
    totalAmount: 0,
    counter: 0,
    fundSources: [],
    fees: {
      sub_fee: 0,
      color_fee: 0,
      page_other_fee: 0,
      pub_fee: 0,
      currency: ""
    },
    downloadlink: ""
  };

  chargeDescriptionOptions = [
    { id: "APC", name: "APC" },
    { id: "All charges", name: "All charges" },
    { id: "BPC", name: "BPC" },

    { id: "Colour charge", name: "Colour charge" },
    { id: "Submission fee", name: "Submission fee" },
    { id: "Page & other charges", name: "Page & other charges" },
  ];

  async componentDidMount() {
    this.props.clearToken({});

    const apc_options = await this.props.getArticleApcOption({
      articleId: Number(this.props.match.params.id)
    });

    const apc_fund_src = await this.props.getApcFundSrc();
    await this.props.getOaTokenisActive(); // storing in redux
    let payload = {
      id: Number(this.props.match.params.id)
    };
    await this.props.articleApproveSummary(payload);
    let pubLicense = false;

    pubLicense = await this.props.getLicense();
    const oa_tokens = this.props.oa_tokens;
    const article_approve_summary = this.props.article_approve_summary;
    const apc_options_list =
      apc_options &&
      apc_options.map(apc_option => {
        return {
          id: apc_option.id,
          name: apc_option.apc_option_label || apc_option.name
        };
      });

    this.setState(prevState => {
      if (pubLicense) prevState.pub_license = pubLicense;
      if (apc_options_list) prevState.apc_options = apc_options_list;
      if (apc_fund_src) prevState.apc_fund_src = apc_fund_src;
      if (oa_tokens) prevState.oa_tokens = oa_tokens;
      if (article_approve_summary)
        prevState.article_approve_summary = article_approve_summary;
      return prevState;
    });
  }

  componentWillMount() {
    if(this.props.location && this.props.location.state){
      this.checkInitData();

    }
    this.props.getOrganisationToken({ pageNum: 1, pageSize: 999999 });
  }


  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    this.clearPromoToken();

  }


  clearPromoToken = async () => {
    if (!this.state.status && this.props.apply_token && this.props.apply_token.success) {
      let payload = {};
      await this.props.clearToken(payload);
    }
  }

  checkInitData = () => {

    
    let { article, OaDeal } = this.props.location && this.props.location.state;
    let sub_fee,
      color_fee,
      page_other_fee,
      pub_fee,
      currency = "gbp";

    switch (OaDeal&& OaDeal.currency_name && OaDeal.currency_name.toLowerCase()) {
      case "usd":
        sub_fee = Number(article && article.submission_fee_usd);
        color_fee = Number(article && article.colour_charge_usd);
        page_other_fee = Number(article&& article.page_charge_usd);
        pub_fee = Number(article&& article.pub_fee_usd);
        currency =OaDeal&& OaDeal.currency_name &&OaDeal.currency_name.toUpperCase();
        break;
      case "eur":
        sub_fee = Number(article && article.submission_fee_eur);
        color_fee = Number(article&& article.colour_charge_eur);
        page_other_fee = Number(article&& article.page_charge_eur);
        pub_fee = Number(article&& article.pub_fee_eur);
        currency =OaDeal&& OaDeal.currency_name && OaDeal.currency_name.toUpperCase();
        break;
      default:
        sub_fee = Number(article&& article.submission_fee_gbp);
        color_fee = Number(article&& article.colour_charge_gbp);
        page_other_fee = Number(article&& article.page_charge_gbp);
        pub_fee = Number(article&& article.pub_fee_gbp);
        currency = currency && currency.toUpperCase();
        break;
    }

    this.setState({
      fees: {
        sub_fee,
        color_fee,
        page_other_fee,
        pub_fee,
        currency
      },
      approval: {
        ...this.state.approval,
        currency
      }
    });
  };

  onSubmitHander = async () => {
    let approval;
    let status = false,
      errorMsg = false;
    try {
      let data = this.state.approval;
      let articleApproveSummary = this.state.article_approve_summary;

      data.is_author_terms_required = articleApproveSummary && articleApproveSummary.is_author_terms_required;

      approval = await this.props.ArticleApprove(data);
      status = approval.status;
    } catch (err) {
      if (err.data && err.data.message) {
        errorMsg = err.data.message;
      } else {
        status = err.status;
      }
    }

    if (errorMsg) {
      this.setState({
        errorMsg
      });
    } else {
      this.setState({
        status
      });
    }
  };

  onCancel = () => history.goBack();
  onChange = value =>
    this.setState(prevState => Object.assign(prevState.approval, value));

  onChangeApcOption = value => {
    let flag = false;
    if (value.additional_info) {
      flag = value && value.additional_info && value.additional_info.name&& value.additional_info.name.toLowerCase();
    }

    this.setState(
      prevState => {
        Object.assign(prevState.approval, {
          apc_option: value.apc_option
        });

        prevState.approval.breakdown = [];
        prevState.isToken = flag;
        return prevState;
      },
      () => {
        this.getArticleApproveSummary();
      }
    );
  };

  onChangeDeposit = (value, index) => {
    this.setState(prevState => {
      prevState.approval.breakdown[index] = {
        article: this.state.approval.article,
        apc_option: this.state.approval.apc_option,
        ...prevState.approval.breakdown[index],
        ...value
      };
      if (value.token) {
        Object.assign(prevState.approval, { token_id: value.token });
      }
      return prevState;
    });
  };

  onChangeHandler = value => {
    this.setState({
      ...value
    }, () => {
      if (value.action_type && value.action_type === 2) {
        this.clearPromoToken();
      }
    });
  };

  applyTokenHandler = async () => {
    if (!this.state.token) {
      this.setState({
        isValidToken: false
      });
    } else {
      let payload = {
        token: this.state.token,
        article: Number(this.props.match.params.id),
        ref_context: 'APPROVE',
      };

      try {
        await this.props.applyToken(payload);

        if (this.props.apply_token.success) {
          await this.getArticleApproveSummary();
        } else {
          this.setState({
            isValidToken: false
          });
        }
      } catch (error) {
        this.log(error);
      }
    }
  };

  getArticleApproveSummary = async () => {
    let payload = {
      id: Number(this.props.match.params.id),
      apc_option: this.state.approval.apc_option
    };

    await this.props.articleApproveSummary(payload);

    this.setState({
      isValidToken: true,
      article_approve_summary: this.props.article_approve_summary
    });
  };

  _renderSummery = data => {
    let summaryElements = [];

    data &&
      data.map((summary, index) => {
        if (summary.description !== "DRAW_BORDER") {
          return summaryElements.push(
            <div key={`summary-${index}`} className="row">
              <label
                className={`col-sm-8 text-right ${summary.bold &&
                  "font-weight-bold"}`}
              >
                {summary.description}
              </label>
              <label
                className={`col-sm-4 text-right ${summary.bold &&
                  "font-weight-bold"}`}
              >
                {summary.value !== "" ? summary.value : 0}
              </label>
            </div>
          );
        } else {
          return summaryElements.push(
            <div key={`summary-${index}`} className="col-sm-12 px-0">
              <hr />
            </div>
          );
        }
      });

    return summaryElements;
  };

  onPaymentModalClose = () => {
    this.clearPromoToken();

    this.setState(
      {
        action_type: undefined
      },
      () => {
        this.log("closed", this.state.action_type);
      }
    );
  };

  onChangeChecked = e => {
    this.setState({
      termsandcondition: !e.target.checked
    });
  };

  licencepdfdownload = async (e, url, name) => {
    e.preventDefault();
    let payload = {
      url: url
    };

    await this.props.licensepdfdownload(payload);

    const urlObj = window.URL.createObjectURL(this.props.licence_download);
    const link = document.createElement("a");
    link.href = urlObj;
    var d = new Date();

    link.setAttribute(
      "download",
      `${name}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  onLicenceChange = data => {
    let parsedData = JSON.parse(data);
    let { approval } = this.state;
    Object.assign(approval, {
      licence: parsedData.id
    });
    let url = `${articlePublisherLicence}${history.location.state.id}/download/?licence=${parsedData.id}`;

    this.setState({
      approval: approval,
      downloadlink: {
        link: url,
        name: "Licence"
      }
    });
  };

  OnClickterms = terms => {
    this.setState({
      terms
    });
  };


  render() {
    const articlePkId =
      this.props.location && this.props.location.state && this.props.location.state.article && this.props.location.state.article.id ;
    let {
      isToken,
      approval,
      token,
      action_type,
      article_approve_summary
    } = this.state;
    let article  = this.props.location && this.props.location.state&& this.props.location.state.article ;

    let DepositFundOption = [];

    let author_name =
    article&&  article.author &&
      `${(article&& article.author && article.author.salutaion )|| ""} ${(article && article.author&& article.author.first_name) ||
      ""} ${(article && article.author&& article.author.last_name) || ""}`;

    function calculateTotal() {
      let total =
        (article_approve_summary && article_approve_summary.net_payable) || 0;

      return total.toFixed(2);
    }

    function getBalance() {
      let balance = Number(calculateTotal());
      let tmpAmount = 0;
      if (approval.breakdown.length > 0) {
        approval.breakdown.map(({ amount }) => {
          return (tmpAmount += Number(amount || 0));
        });
      }

      balance = balance - tmpAmount;

      return balance.toFixed(2);
    }

    if (isToken && isToken !== "oatoken") {
      DepositFundOption.push(
        <div className="thead bg-primary text-white py-1" key={"header"}>
          <div className="row px-3">
            {/*<div className="col-md-2">Fund type</div>*/}
            <div className="col-md-3">Fund Source</div>
            <div className="col-md-3">Funder Name</div>
            <div className="col-md-4">Charge Description</div>
            <div className="col-md-2 text-right">Amount</div>
          </div>
        </div>
      );
      if (isToken === "depositcredit") {
        for (var i = 0; i <= this.state.counter; i++) {
          let index = i;
          DepositFundOption.push(
            <div className="Token" key={index} id={index}>
              <div className="tbody">
                <div className="row py-2">
                  <SelectBox
                    className="col-md-3"
                    data={this.state.apc_fund_src}
                    field="fund_source"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />
                  <InputBox
                    className="col-md-3"
                    field="funder_name"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />

                  <SelectBox
                    label={false}
                    className="col-md-4"
                    data={this.chargeDescriptionOptions}
                    field="charge_description"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />
                  <InputBox
                    className="col-md-2"
                    field="amount"
                    InputType="number"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />
                </div>
              </div>
            </div>
          );
        }
      } else {
        let index = 0;
        DepositFundOption.push(
          <div className="Token" key={index} id={index}>
            <div className="tbody">
              <div className="row py-2">
                <SelectBox
                  className="col-md-3"
                  data={this.state.apc_fund_src}
                  field="fund_source"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                <InputBox
                  className="col-md-3"
                  field="funder_name"
                  onChange={value => this.onChangeDeposit(value, index)}
                />

                <SelectBox
                  label={false}
                  className="col-md-3"
                  data={this.chargeDescriptionOptions}
                  field="charge_description"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                <InputBox
                  className="col-md-2"
                  field="amount"
                  InputType="number"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
              </div>
            </div>
          </div>
        );
      }
      isToken === "depositcredit" &&
        DepositFundOption.push(
          <div className="text-right " key={"footer"}>
            <Button
              text="-"
              size="md"
              name="primary"
              className="mr-1"
              onClick={() =>
                this.setState({
                  counter: this.state.counter > 0 && this.state.counter - 1
                })
              }
            />
            <Button
              text="+"
              size="md"
              name="primary"
              onClick={() => this.setState({ counter: this.state.counter + 1 })}
            />
          </div>
        );
    } else {
      let index = 0;
      let oaTokenList = [];
      this.props.oa_tokens &&
        this.props.oa_tokens.results.map(token => {
          return oaTokenList.push({
            name: token.token_info,
            ...token
          });
        });
      isToken === "oatoken" &&
        DepositFundOption.push(
          <div className="table deposit" key={index}>
            <div className="thead bg-primary text-white py-1">
              <div className="row px-3">
                <div className="col-md-3">Token</div>
                <div className="col-md-6">Charge Description</div>
                <div className="col-md-3 text-right">Amount</div>
              </div>
            </div>
            <div className="tbody">
              <div className="row py-2">
                <SelectBox
                  className="col-md-3"
                  InputType="number"
                  field="token"
                  data={oaTokenList}
                  onChange={value => this.onChangeDeposit(value, index)}
                />

                <SelectBox
                  label={false}
                  className="col-md-6"
                  data={this.chargeDescriptionOptions}
                  field="description"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                <InputBox
                  className="col-md-3"
                  field="amount"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
              </div>
            </div>
          </div>
        );
    }
    let addresses = [];

    for (let i = 0; i <= this.state.addressCounter; i++) {
      // this.props.user && this.props.user.addresses && this.props.user.addresses.length>0 && this.onChangeAddressHandler(i, this.props.user.addresses[i])
      addresses.push();
    }
    return (
      <HubContent title={"Article approval"}>
        {/* <!-- end row --> */}

        {this.state.status >= 200 && this.state.status <= 204 && (
          <PopupBox
            title="Succesfully Approved"
            msg="You have approved the APC for this article"
            onCancel={this.onCancel}
          />
        )}

        {/* <ErrorHandler
          status={this.state.status}
          onReset={status => this.setState({ status })}
        /> */}

        {action_type === 2 ? (
          <ModalCustom
            onClose={this.onPaymentModalClose}
            modalClass="col-sm-10"
            isShow={action_type}
          >
            <PaymentHome group={this.props.group} match={this.props.match} />
          </ModalCustom>
        ) : (
            ""
          )}

        <div className="row">
          {!this.state.msg ? (
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div className="vat-tax-button email">
                    <Button
                      type="danger"
                      name="danger"
                      text="X"
                      onClick={() => this.props.history.goBack()}
                    />
                  </div>

                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    <div
                      className="tab-pane active p-3"
                      id="home-1"
                      role="tabpanel"
                    />

                    <div className="row ">
                      <div className="col-sm-12">
                        <h5>{article && article.title}</h5>
                      </div>
                    </div>
                    <div className="row apc-row">
                      <div className="col-sm-3">
                        <p className="m-0">
                          <strong>Corresponding author</strong>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="m-0">
                          <strong className="info_seperator"> : </strong>
                          {article &&  article.author && author_name}
                        </p>
                      </div>
                    </div>

                    <div className="row apc-row">
                      <div className="col-sm-3">
                        <p className="m-0">
                          <strong>Affiliation</strong>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="m-0">
                          <strong className="info_seperator"> : </strong>
                          {article&& article.author && article.author.affiliation}
                        </p>
                      </div>
                    </div>

                    <div className=" row apc-row">
                      <div className="col-sm-3">
                        <p className="m-0">
                          <strong>Publisher</strong>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="m-0">
                          <strong className="info_seperator"> : </strong>
                          {article&& article.publisher && article.publisher.name}
                        </p>
                      </div>
                    </div>

              
                  
                   
                    <div className="form-group row apc-row"   style={{
                              marginTop: 40,
                            }}>
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-sm-6">
                                <h5>I want to</h5>
                              </div>

                              <div className="col-sm-6">
                                <div className="form-group">
                                  <RadioButtonGroup
                                    field="action_type"
                                    options={[
                                      { id: 1, name: "Approve" },
                                      { id: 2, name: "Pay/Invoice" }
                                    ]}
                                    radioStyle={{
                                      display: "block",
                                      height: "30px",
                                      lineHeight: "30px"
                                    }}
                                    defaultValue={action_type || false}
                                    disabled={false}
                                    value={action_type || false}
                                    onChange={this.onChangeHandler}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group row">
                             <SelectBox
                                label={false}
                                data={this.state.apc_options}
                                onChange={this.onChangeApcOption}
                                field="apc_option"
                                isData={true}
                                className="col-sm-12"
                                placeholder="APC option"
                                disabled={action_type !== 1 ? true : false}
                              /> 
                            </div>
                            <div className="form-group row">
                              <InputBox
                                label={false}
                                field="token"
                                placeholder="Enter token"
                                className="col-sm-8"
                                value={token}
                                onChange={this.onChangeHandler}
                              />

                              <div className="col-sm-4 pl-0">
                                <Button
                                  text="Apply"
                                  name="primary"
                                  className="btn-block apply-btn"
                                  size={"md"}
                                  onClick={this.applyTokenHandler}
                                />
                              </div>
                            </div>

                          </div>
                        </div>

                        {article_approve_summary && article_approve_summary.is_author_terms_required && <div className="row">
                          <div className="col-md-9 terms-condition">
                            <Checkbox onChange={this.onChangeChecked}>
                              I agree with Publisher’s Terms & Conditions{" "}
                            </Checkbox>
                          </div>
                          <div className="col-md-3 terms-condition-view">
                            <button
                              type="button"
                              onClick={e =>
                                this.licencepdfdownload(
                                  e,
                                  `${articlePublisherTerms}${articlePkId}/download/`,
                                  "TermsandCondition"
                                )
                              }
                              className="btn btn-primary btn-md waves-effect float-right"
                            >
                              View
                            </button>
                          </div>
                        </div>}
                      </div>
                      <div className="col-sm-6">
                        {article_approve_summary ?
                          this._renderSummery(article_approve_summary.summary):<Loading type="flat"/>} 
                      </div>
                    </div>

                    {action_type === 1 && approval.apc_option && (
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <h6
                            style={{
                              margin: "0",
                              padding: "0",
                              color: "#7d7db7"
                            }}
                          >
                            Approval
                          </h6>
                        </div>
                        <s
                          style={{
                            borderBottom: "1px solid #375ba8",
                            margin: "0 15px",
                            width: "100%"
                          }}
                        ></s>
                      </div>
                    )}

                    {action_type === 1 &&
                      approval.apc_option &&
                      DepositFundOption}

                    <div className="form-group row mt-1">
                      <s
                        style={{
                          borderBottom: "1px solid #375ba8",
                          margin: "0 15px",
                          width: "100%"
                        }}
                      ></s>
                    </div>

                    {action_type === 1 && approval.apc_option && (
                      <div className="form-group row " align="right">
                        <div className="col-sm-8 d-flex align-items-center justify-content-end">
                          {getBalance() < 0 && (
                            <div className="text-danger">
                              Out of range - Balance should Zero (0)
                            </div>
                          )}
                        </div>
                        <InputBox
                          label="Balance"
                          placeholder={getBalance()}
                          disabled
                          className="col-sm-2"
                        />
                      </div>
                    )}

                    <div className="text-right m-t-15 ">
                      {getBalance() === Number(0).toFixed(2) ? (
                        <Button
                          text="Approve"
                          name="primary"
                          size={"md"}
                          onClick={this.onSubmitHander}
                          disabled={false}
                        />
                      ) : (
                          <Button
                            text="Approve"
                            name="primary"
                            size={"md"}
                            onClick={this.onSubmitHander}
                            disabled={true}
                            className="disabled"
                          />
                        )}

                      <Button
                        text="Cancel"
                        name="danger"
                        size={"md"}
                        className={"ml-1"}
                        onClick={this.onCancel}
                      />
                    </div>
                  </div>
                  {/* <!-- end Tab panes --> */}
                </div>
              </div>
            </div>
          ) : (
              <div className="col-md-12">
                <h1>Nothing Found</h1>
              </div>
            )}
          {/* <!-- end col --> */}
        </div>
        {/* <!-- end row -->      */}
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  oa_tokens: state.oaToken.organisation_token_list,
  apply_token: state.oaToken.apply_token && state.oaToken.apply_token.data,
  article_approve_summary: state.articles.article_approve_summary || false,
  licence_download: (state.payment && state.payment.invoice_download) || false,
  licence_list: state.articles.licence_list,
  group:state.auth.group
});

const mapDispatchToProps = dispatch => ({
  getLicense: () => dispatch(getLicense()),
  getPublisherLicense: payload => dispatch(getPublisherLicense(payload)),
  getApcOption: () => dispatch(getApcOption()),
  getArticleApcOption: payload => dispatch(getArticleApcOption(payload)),
  getApcFundSrc: () => dispatch(getApcFundSrc()),
  getOaTokenisActive: () => dispatch(getOaTokenisActive()),
  ArticleApprove: payload => dispatch(ArticleApprove(payload)),
  applyToken: payload => dispatch(applyTokenAction(payload)),
  clearToken: payload => dispatch(clearToken(payload)),
  articleApproveSummary: payload => dispatch(articleApproveSummary(payload)),
  getOrganisationToken: payload => dispatch(getOrganisationToken(payload)),
  licensepdfdownload: (payload = {}) => dispatch(downloadInvoice(payload))
});

export const ArticalAprForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleApprovalForm);
