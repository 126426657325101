import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import NotFound from '../../NotFound';

class OaTarget extends BaseComponent {
  state = {

  }
  onArticleDownloadClick = async () => {
    // try {
    //   let params = await serialize(this.state.params);
    //   let filter = await serialize(this.state.filterParams);
    //   await this.props.articleDownload({ params, filter });
    //   const url = window.URL.createObjectURL(this.props.article_download_data);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   var d = new Date();

    //   link.setAttribute(
    //     "download",
    //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (error) {
    
    // }
  };
  render() {
    
    return (
      this.state.status === 403 ? <NotFound /> :
        <HubContent title="OA target list" onArticleDownloadClickHandler={this.onArticleDownloadClick}>
          
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <p>OA target list</p>
              </div>
            </div>
          </div>
        </HubContent>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = (dispatch) => ({
  
})

export const OaTargetList = connect(mapStateToProps, mapDispatchToProps)(OaTarget);

