import React from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { ArticleFullSingle, CorrectionRequest } from "../../../partials";
import {
    getArticleOfferDetails,
    getPublisherArticleFullList,
    articleDownload,
    oADealListApproved,
    getInstitutionsList,
    CorrectionRequestAction,
    getPublicationFiltering,
    getOaDisciplineList,
    getOaSubjectList,
    getArticleFullSingleList,
    getApcArticleList,
    getPublicationList,
    sendFreeTextEmail,
    getDepartByFiltering,
    mutateApcRequestStatus
} from "../../../../actions";
import moment from "moment";
import {
    PopupBox,
    Loading,
    SelectBox,
    InputGroup,
    Button,
    ModalCustom,
    TextBox
} from "../../../../../core/components/common";
import SelectBoxName from "../../../../../core/components/common/SelectBoxName.jsx";
import { UnivRoot } from "../../../../data";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";
import { filterStatus, filterDays } from "./data";
import queryString from "query-string";
import { AdHocEmailForm } from "../../../partials";
import { isArray } from "lodash";

class Article extends BaseComponent {
    state = {
        page: 1,
        pageSize: 10,
        msg: false,
        author_apc_request: false,
        author_apc_no_oa_request: false,
        filterParams: { page: 1, article_stage: 'POST_ACCEPTANCE', },
        params: null,
        isCorrectionRequestOpen: false,
        article_id: null,
        showLicenceUpdateNotification: false,
        oa_subject_list: [],
        show: null
    };
    articleHandleClick = (index) => {
        if (this.state.show === index) {
          this.setState({
            show: null,
          });
        } else {
          this.setState({
            show: index,
          });
        }
      };
    componentDidMount() {
        if (this.props.location && this.props.location.filter && this.props.location.filter.page) {
            this.pageChange(this.props.location && this.props.location.filter && this.props.location.filter.page)
          }
        this.apiDataList()

    }
    apiDataList = async () => {
        let query_url = queryString.parse(this.props.location.search);
        let article_filter_id = query_url && query_url.id ? query_url.id : null;

        this.props.getOaDisciplineList();
        this.props.getPublicationFiltering();
        this.props.getDepartByFiltering();

        let params = {
            institution_id: this.props.institute,
        };
        if (article_filter_id) {
            params["id"] = article_filter_id;
        }



        this.setState((prevState) => {
            if (this.props.institute) {
                prevState.filterParams = Object.assign(prevState.filterParams, params);
            } else {
                if (article_filter_id) {
                    prevState.filterParams = Object.assign(prevState.filterParams, {
                        id: article_filter_id,
                    });
                }
            }
        });

        let filter = serialize(this.state.filterParams);


        if (this.props.location.apc_article_id) {
            this.props.getApcArticleList(this.props.location.apc_article_id)
            this.props.getArticleFullSingleList(this.props.location.apc_article_id)

        } else {

            await this.props.ArticleFull({ filter });
            let arrayChecking = Array.isArray(this.props.articles) ? this.props.articles : []
            let articles = arrayChecking && arrayChecking.map(item => item.id);
            if (articles && articles.length > 0) {
                let articleOffer = await this.props.getArticleOfferDetails(articles.join())
                this.setState({
                    articleOffer
                })
            } 
        }
        if (!this.props.institute) {
                await this.props.getInstitutionsList();
       
        }


    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
    }

    filterData = async (value) => {
        value.page = 1;

        if (value.hasOwnProperty("q")) {
            this.setState({
                ...value,
            });
        }

        if (value.department === "All Departments") {
            value.department = "all";
        } else if (value.department === "No Department") {
            value.department = "no_dept";
        }
        if (value.publication_type === "All Publications") {
            value.publication_type = "all";
        }

        if (value.hasOwnProperty("filter_discipline")) {
            let payload = {
                discipline:
                    value.filter_discipline !== "all" ? value.filter_discipline : "",
            };

            value.filter_subject = "all";
            value.filter_publication = "all";

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
                this.setState({
                    oa_subject_list: this.props.oa_subjects && this.props.oa_subjects.results,
                });
        }

        if (value.hasOwnProperty("filter_subject")) {
            value.filter_publication = "all";
        }

        await this.setState((prevState) => {
            if (value.institution_id === "all") {
                value.filter_publisher = "all";
            }
            prevState.filterParams = Object.assign(prevState.filterParams, value);
        });

        if (
            value.hasOwnProperty("publication_type") ||
            value.hasOwnProperty("filter_discipline") ||
            value.hasOwnProperty("filter_subject")
        ) {
            this.getPublicationListOnParameterChange();
        }

        if (this.state.filterParams.institution_id === "all") {
            delete this.state.filterParams.institution_id;
        }
        let params = await serialize(this.state.params);
        let filter = await serialize(this.state.filterParams);
        await this.props.ArticleFull({ params, filter });
       
        let articles = this.props.articles && this.props.articles.map(item => item.id);
        if (articles && articles.length > 0) {
            let articleOffer = await this.props.getArticleOfferDetails(articles.join())
            this.setState({
                articleOffer
            })
        }
        
    };

    onActionHandler = async (action, id,request_apc) => {

        try {
            const article = await this.props.article(id);
            switch (action.toLowerCase()) {
                case "approve":
                    await this.props.getOaDeals();
                    let OaDeal = false;
                    try {
                        if (article.publisher) {
                            OaDeal = await this.props.OaDealCurrency(article.publisher.id);
                        }
                    } catch (error) {
                        this.log("OaDeal error: ", error);
                    }

                    if (!OaDeal) {
                        this.setState({
                            msg: "You don't have any OaDeal. Please Create One",
                        });
                    } else {
                        history.push(UnivRoot.article_approval + "/" + id, {
                            article,
                            OaDeal,
                            id: id,
                        });
                    }
                    break;
                
                case "decline_author_apc_fund_request":
                    this.setState({
                        showConfirmationModal: true,
                        modalTitle: 'Decline APC Request',
                        mutate_action: 'rejected',
                        apc_request: request_apc.apc_request.id,
                    })
                break;
                
                case "reject":
                    history.push(UnivRoot.article_decline + "/" + id);
                    break;

                    case "article_payment":
                        this.props.history.push(`/payment/addpayment/${id}`, {
                            name: action
                        });
                        break;
                    case "article_invoice":
                        this.props.history.push(`/payment/addpayment/${id}`, {
                            name: action
                        });
                        break;
      
                case "action_audit":
                    this.props.history.push({
                        pathname: `/audit/audit-trail/${id}`,
                        filter: this.state.filterParams,
                        name: action,
                        id:id,
                    });
                    break;
                case "author_apc_fund_request":
                    this.setState({
                        author_apc_request: true,
                        author_apc_id: id,
                        author_id: article && article.author && article.author.id,
                    });
                    break;
                case "author_non_oa_request":
                    this.setState({
                        author_apc_no_oa_request: true,
                        author_apc_id: id,
                        author_id: article && article.author && article.author.id,
                    });
                    break;
                case "correction_request":
                    this.setState({
                        isCorrectionRequestOpen: true,
                        article_id: id,
                    });
                    break;
                default:
                    break;
            }
        } catch (error) {
            this.log("error: ", error);
        }
    };

    pageChange = async (pageNumber) => {
        let value = {
            page: pageNumber,
        };

        await this.setState((prevState) => {
            prevState.filterParams = Object.assign(prevState.filterParams, value);
        });

        let params = await serialize(this.state.params);
        let filter = await serialize(this.state.filterParams);

        await this.props.ArticleFull({ params, filter });

        this.setState({
            page: pageNumber,
        });

       let articles = this.props.articles && this.props.articles.map(item => item.id);
        if (articles && articles.length > 0) {
            let articleOffer = await this.props.getArticleOfferDetails(articles.join())
            this.setState({
                articleOffer
            })
        }
    };


    onCorrectionRequest = async (value) => {
        let data = {
            article: this.state.article_id.toString(),
            ...value,
        };
        try {
            await this.props.CorrectionRequestAction(data);
            this.setState({
                isCorrectionRequestOpen: false,
                article_id: null,
            });

            let filter = await serialize(this.state.filterParams);

            await this.props.ArticleFull({ filter });
            
            let articles = this.props.articles && this.props.articles.map(item => item.id);
            if (articles && articles.length > 0) {
                let articleOffer = await this.props.getArticleOfferDetails(articles.join())
                this.setState({
                    articleOffer
                })
            }
               
        } catch (e) {
        }
     
    };

    onArticleDownloadClick = async () => {
        try {
            let filter = await serialize(this.state.filterParams);
            await this.props.articleDownload({ params: 999999, filter });
            const url = window.URL.createObjectURL(this.props.article_download_data);
            const link = document.createElement("a");
            link.href = url;
            var d = new Date();

            link.setAttribute(
                "download",
                `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
        } catch (error) {
        }
    };

    getPublicationListOnParameterChange = async () => {
        let payload = {};
        let filterParams = {
            page_size: 999999,
        };

        if (this.state.filterParams.publication_type) {
            filterParams.publication_type_name =
                this.state.filterParams.publication_type !== "all"
                    ? this.state.filterParams.publication_type
                    : "";
        }

        if (this.state.filterParams.filter_discipline) {
            filterParams.discipline_ids =
                this.state.filterParams.filter_discipline !== "all"
                    ? this.state.filterParams.filter_discipline
                    : "";
        }

        if (this.state.filterParams.filter_subject) {
            filterParams.subject_ids =
                this.state.filterParams.filter_subject !== "all"
                    ? this.state.filterParams.filter_subject
                    : "";
        }

        payload.filter = serialize(filterParams);
        await this.props.getPublicationList(payload);

        this.props.publication_list &&
            this.setState({
                publication_list:this.props.publication_list && this.props.publication_list.results,
            });
    };

    popupSuccessCallback = async () => {
        this.onCancel();

        let filter = await serialize(this.state.filterParams);

        await this.props.ArticleFull({ filter });
        let articles = this.props.articles && this.props.articles.map(item => item.id);
        if (articles && articles.length > 0) {
            let articleOffer = await this.props.getArticleOfferDetails(articles.join())
            this.setState({
                articleOffer
            })
        }
    };

    onCancel = () => {
        this.setState({
            author_apc_request: false,
            isCorrectionRequestOpen: false,
            author_apc_no_oa_request: false,
            // article_id: null,
            showLicenceUpdateNotification: false,
            isEmpty: false,
            successMsg: false,
        });
    };

    onCustomEmailButtonClick = async (articleId) => {
        if (!articleId) {
            return null;
        }

        await this.props.getArticleFullSingleList(articleId);
        let articleData = this.props.article_single;
        let subject = '';
        let message_body = '';

        if (articleData && articleData.article_id) {
            subject = `Article ID: ${articleData && articleData.article_id},`;
            message_body = `Article ID: ${articleData && articleData.article_id}`;
        }

        if (articleData && articleData.title) {
            subject = `${subject} Article Title: ${articleData && articleData.title}`;
            message_body = `${message_body} <br/>Article Title: ${articleData && articleData.title}`;
        }

        if (articleData && articleData.publication && articleData.publication.name) {
            subject = `${subject}, Publication Name: ${articleData && articleData.publication && articleData.publication.name}`;
            message_body = `${message_body} <br/>Publication Name: ${articleData && articleData.publication && articleData.publication.name}<br/>`;
        }

        subject =subject&& subject.trim();
        message_body = message_body && message_body.trim();

        this.setState({
            showCustomEmailForm: true,
            article_id: articleData.id,
            message_body,
            subject,
        });

    }

    onCloseHandler = () => {
        this.setState({
            showCustomEmailForm: false,
            article_id: '',
        });
    }

    onChangeHandler = (value) => {
        this.setState({
            ...value,
        });
    }
    onSubmitDeclineApc = async () => {
        
        let {
            apc_request,
            mutate_action,
            note,
        } = this.state;

        if (!note) {
            this.setState({
                isEmpty: 'Note field is required.'
            })
        } else {

            if (apc_request && mutate_action) {
                let data = {
                    note,
                };
    
                this.props.mutateApcRequestStatus(apc_request, mutate_action, data).then(response => { 
                    this.onClose()
                    
                    confirmAlert({
                        title: "",
                        message: "APC request declined.",
                        buttons: [
                          {
                            label: "Close",
                                onClick: async () => { 
                                    if (this.props.location.apc_article_id) {
                                        this.props.getApcArticleList(this.props.location.apc_article_id)
                                        this.props.getArticleFullSingleList(this.props.location.apc_article_id)
                            
                                    } else {
                                        let params = await serialize(this.state.params);
                                        let filter = await serialize(this.state.filterParams);
                                        await this.props.ArticleFull({ params, filter });
                                        
                                }
         
    
                            
                            },
                          },
                        ],
                    });
                })
    
            }
        }
    };

    onSubmitHandler = (e) => {

        let data = {
            article_id: this.state.article_id,
            organisation: this.state.organisation,
            subject: this.state.subject,
            recipients: this.state.recipients,
            message_body: this.state.message_body,
        };

        if (!data.article_id || !data.subject || !data.recipients || (isArray(data.recipients) && data.recipients.length === 0) || !data.message_body) {
            this.setState({
                isEmpty: <div>
                    {!data.article_id && <p>Article is required.</p>}
                    {(!data.recipients || (isArray(data.recipients) && data.recipients.length === 0)) && <p>Recipient is required.</p>}
                    {!data.subject && <p>Subject is required.</p>}
                    {!data.message_body && <p>Message is required.</p>}
                </div>
            })
        } else {
            this.setState({
                emailLoading: true,
            });

            this.props.sendFreeTextEmail(data).then(response => {
                if (response.data && response.data.success) {
                    this.setState({
                        emailLoading: false,
                        showCustomEmailForm: false,
                        successMsg: response.data.message,
                        article_id: '',
                        organisation: '',
                        subject: '',
                        recipients: '',
                        message_body: '',
                    });
                }
            }).catch(error => {
                this.setState({
                    emailLoading: false,
                });

                return error.response && error.response.data && error.response.data.success;
            });
        }
    };

    onViewArticle = async () => {
        await this.props.ArticleFull()
        let articles = this.props.articles && this.props.articles.map(item => item.id);
        if (articles && articles.length > 0) {
            let articleOffer = await this.props.getArticleOfferDetails(articles.join())
            this.setState({
                articleOffer
            })
        }
    }
    isArrayEmpty = (value) => {
        return Array.isArray(value) ? value : []
    }

    onClose = () => {
        this.setState({
            showConfirmationModal: false,
            modalTitle: "",
            note: "",
            mutate_action: false,
            id: false,
        });
    };
    modalForm = () => {
        return (
        <form>
            <div className="form-group row">
                <TextBox
                    label="Note"
                    labelClass="col-sm-4"
                    onChange={this.onChangeHandler}
                    field="note"
                    placeholder="Note"
                    rows={3}
                    className="col-sm-8"
                />
            </div>
        </form>
        );
    };
    render() {
        
        let query_url = queryString.parse(this.props.location.search);
        let article_filter_id = query_url && query_url.id ? query_url.id : null;
        let { articles, type, count } = this.props;

        let { page, showCustomEmailForm, } = this.state;
        let ErrorHandler = true;
        let AuthorApcRequest = true;
        let AuthorApcNoOARequest = true;



        let institutions = [
            {
                name:
                    this.props.type === "publisher"
                        ? "All Institutions"
                        : this.props.type === "university"
                            ? "All Publishers"
                            : "All",
                id: "all",
            },
        ];

        let publications = [
            {
                name: "All Publications",
                id: "all",
            },
        ];

        if (articles) {
            if (articles.status === 500) {
                ErrorHandler = (
                    <PopupBox
                        title="Network Error"
                        msg="Please check your internet connection. If you think internet connection is ok then feel free to contact us"
                    />
                );
            } else if (articles.status === 404) {
                ErrorHandler = (
                    <PopupBox
                        title="Data Not Found"
                        msg="Something Error. Please contact with admin"
                    />
                );
            } else if (articles.status) {
                ErrorHandler = (
                    <PopupBox
                        title="Unknown Error Found"
                        msg="Something Error. Please contact with admin"
                    />
                );
            } else {
                ErrorHandler = false;
            }
        }



        if (this.state.author_apc_request) {
            AuthorApcRequest = (
                <PopupBox
                    title="Author APC Request"
                    className="col-sm-8"
                    apcForm={true}
                    successCallback={this.popupSuccessCallback}
                    onCancel={this.onCancel}
                    NoText="Cancel"
                    article_id={this.state.author_apc_id}
                />
            );
        }

        if (this.state.author_apc_no_oa_request) {
            AuthorApcNoOARequest = (
                <PopupBox
                    title="Non OA Request"
                    className="col-sm-8"
                    noOAForm={true}
                    onCancel={this.onCancel}
                    NoText="Cancel"
                    article_id={this.state.author_apc_id}
                />
            );
        }

        let CorrectionRequestOpen = "";
        if (this.state.isCorrectionRequestOpen) {
            CorrectionRequestOpen = (
                <CorrectionRequest
                    title="Correction Request"
                    className="col-sm-7"
                    onCancel={this.onCancel}
                    onPressOK={this.onCorrectionRequest}
                    YesText="Send Request"
                    NoText="Cancel"
                    id={this.state.article_id}
                />
            );
        }


        let successErrMsgPop = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.onCancel}
            />}

            {this.state.successMsg && <PopupBox
                title="Success!"
                msg={this.state.successMsg}
                onCancel={this.onCancel}
            />}
        </>;
      let department = [
        {
            name: "All Departments",
            id: "all",
        },
        {
            name: "No Department",
            id: "no_dept",
        },
      ];
      let manage_article= this.props.auth &&  this.props.auth.permissions && this.props.auth.permissions.find(item=>item==="manage_articles_books") 

        return (
            <HubContent
                title={this.props.title || ""}
                className={this.props.className}
                onArticleDownloadClickHandler={this.onArticleDownloadClick}
            >
                {/* {NotificationHandler} */}
                {this.state.showConfirmationModal && (
                    <ModalCustom
                        title={this.state.modalTitle}
                        submitBtnTxt="Submit"
                        onClose={this.onClose}
                        onSubmit={this.onSubmitDeclineApc}
                        modalClass="col-sm-6"
                        isShow={this.state.showConfirmationModal}
                    >
                        {this.modalForm()}
                    </ModalCustom>
                )}
                {successErrMsgPop}

                {ErrorHandler}
                {AuthorApcRequest}
                {CorrectionRequestOpen}
                {AuthorApcNoOARequest}

                {this.state.msg && (
                    <PopupBox
                        title="No Oa deal found"
                        msg={this.state.msg}
                        onCancel={() => this.setState({ msg: false })}
                    />
                )}


                {showCustomEmailForm && <ModalCustom
                    title="Send Ad Hoc Email"
                    onClose={this.onCloseHandler}
                    modalClass="col-sm-10 col-md-6 col-lg-8"
                    isShow={showCustomEmailForm}
                    submitBtnTxt="Send"
                    onSubmit={this.onSubmitHandler}
                >

                    <AdHocEmailForm
                      
                        recipients={this.state.recipients}
                
                        emailLoading={this.state.emailLoading}
                        article={this.props.article_single}
                        subject={this.state.subject}
                        message_body={this.state.message_body}
                        article_id={this.state.article_id}
                        onChangeHandler={this.onChangeHandler}
                    />

                </ModalCustom>}

                <div className="row mb-3">
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_status"
                        defaultValue="All"
                        data={filterStatus}
                        onChange={this.filterData}
                    />
                    {!this.props.institute && type !== "author" && institutions && (
                        <SelectBox
                            className="col-md-3 mb-2"
                            data={
                                 institutions.concat(this.isArrayEmpty(this.props.institute_list))
                                
                            }
                            onChange={this.filterData}
                            field="institution_id"
                            defaultValue={institutions[0].name}
                        />
                    )}

               

        
                    <SelectBoxName
                        className="col-md-3 mb-2"
                        field="publication_type"
                        defaultValue="All Publication types"
                        data={
                            publications.concat(this.isArrayEmpty (this.props.publication_types))
                                
                        }
                        onChange={this.filterData}
                    />
  <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_publication"
                        defaultValue={this.state.filterParams.filter_publication || "all"}
                        data={
                            this.state.publication_list
                                ? [
                                    { id: "all", name: "All Publications" },
                                    ...this.state.publication_list,
                                ]
                                : [{ id: "all", name: "All Publications" }]
                        }
                        onChange={this.filterData}
                    />

                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_discipline"
                        defaultValue={this.state.filterParams.filter_discipline || "all"}
                        data={
                             [
                                    { id: "all", name: "All Disciplines" },
                                ].concat(this.isArrayEmpty(this.props.oa_disciplines && this.props.oa_disciplines.results))
                                
                        }
                        onChange={this.filterData}
                    />

                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_subject"
                        defaultValue={this.state.filterParams.filter_subject || "all"}
                        data={
                            this.state.oa_subject_list
                                ? [
                                    { id: "all", name: "All Subjects" },
                                    ...this.state.oa_subject_list,
                                ]
                                : [{ id: "all", name: "All Subjects" }]
                        }
                        onChange={this.filterData}
                    />
     
                    
                  
                  {this.props.group !== "AUTHOR" && (
                        <SelectBoxName
                            className="col-md-3 mb-2"
                            field="department"
                            defaultValue="All Departments"
                            data={
                                department.concat(this.isArrayEmpty(this.props.department_types))
                            }
                            onChange={this.filterData}
                        />
                    )}
   
       <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_days"
                        defaultValue=""
                        data={filterDays}
                        onChange={this.filterData}
                    />
                 
                    <InputGroup
                        onClick={this.filterData}
                        field="q"
                        placeholder="Search"
                        className="col-md-3 mb-2 free-text-search"
                    />
                    {
                        this.props.location.apc_article_id && (
                            <div className="col-md-12 apc-btn-list" style={{ textAlign: "right" }}>
                                <Button type="secondary" name="secondary" text="View All APC Requests" className="view-articles" size={200} onClick={() => this.props.history.push("/author-request")} />

                                <Button type="secondary" name="primary" text="View All Articles" className="view-articles" size={200} onClick={this.onViewArticle} />
                            </div>
                        )
                    }


                </div>

                {manage_article ? <div className="tab-content">
                    <div className="tab-pane active " id="pre-acceptance" role="tabpanel">
                        <div className="row approved-btn">
                            <div className="col-lg-12">
                                <div id="accordion" className="article-cards">
                                    {/*START OF LOOP*/}
                                    {!ErrorHandler && this.props.isArticleloading !== true ? (
                                        articles && articles.map((article, i) => {
                                            return (
                                                <ArticleFullSingle
                                                    location={this.props.location}
                                                    offerLoading={this.props.article_offer_detail_loading}
                                                    articleOffer={this.state.articleOffer && this.state.articleOffer[article.id]}
                                                    show={this.state.show}
                                                    articleHandleClick={this.articleHandleClick}

                                                    key={article.id}
                                                    search_text={this.state.q}
                                                    article={article}
                                                    url_article_id={article_filter_id}
                                                    user={this.props.auth.user.username}
                                                    isAction={false}
                                                    index={i}
                                                    onActionHandler={(action,id) =>
                                                        this.onActionHandler(action, article.id, id)
                                                    }
                                                    onCustomEmailButtonClick={this.onCustomEmailButtonClick}
                                                />
                                            );
                                        })
                                    ) : (
                                            <Loading type="flat" />
                                        )}
                                    {/*END OF LOOP*/}
                                    {articles && articles.length === 0 && (
                                        <div className="card">
                                            <div className="card-body">
                                                <h6>No Data Found</h6>
                                            </div>
                                        </div>
                                    )}

                                    {/* START OF PAGINATION */}
                                    {count && (
                                        <Pagination
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 20,
                                                textAlign: "center",
                                            }}
                                            showSizeChanger={false}
                                            onChange={this.pageChange}
                                            pageSize={this.state.pageSize || 10}
                                            total={count}
                                            current={page}
                                        />
                                    )}
                                    {/* END OF PAGINATION */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :<div className="card">
                                                            <div className="card-body">
                                                            <h6>No Permission available</h6>
                                                            </div>
                                                        </div>}
            </HubContent>
        );
    }
}

const mapStateToProps = (state, props) => ({
    loading: state.articles.loading,
    article_offer_detail_loading: state.articleOfferDetails.article_detail_loading,
    articles:state.articles.article_full,
    count: state.articles.count || false,
    article: (id) => {
        let article = state.articles.article_full;
        if (article) {
            return article.find((article) => {
                return id.toString() === article.id.toString() && article;
            });
        } else {
            return false;
        }
    },
    OaDealCurrency: (publisher) =>
        state.OaDeals.oadeal_approved_list
            ? state.OaDeals.oadeal_approved_list.find((OaDeal) => {
                if (publisher.toString() === OaDeal.organisation.toString()) {
                    return OaDeal;
                } else {
                    return false;
                }
            })
            : null,
    institute_list: (state.institutions && state.institutions.institution_list )|| [],

    publication_types: state.institutions.publication_types,
    article_download_data: state.articles.download_data,
    group: (state.auth && state.auth.group),
    auth: state.auth,
    oa_disciplines: state.OaDiscipline.oa_disciplines,
    oa_subjects: state.OaSubject.oa_subjects,
    publication_list: state.institutions.publication_list,
    article_single: state.articles.article_single,
    isArticleloading: state.articles.isArticleloading,
    department_types: state.institutions.department_types,


});

const mapDispatchToProps = (dispatch, props) => ({
    ArticleFull: (payload = {}) => dispatch(getPublisherArticleFullList(payload)),
    articleDownload: (payload = {}) => dispatch(articleDownload(payload)),
    getOaDeals: () => dispatch(oADealListApproved()),
    getInstitutionsList: () => dispatch(getInstitutionsList()),
    getPublicationFiltering: () => dispatch(getPublicationFiltering()),
    CorrectionRequestAction: (payload) =>
        dispatch(CorrectionRequestAction(payload)),
    getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
    getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
    getPublicationList: (payload) => dispatch(getPublicationList(payload)),
    getArticleFullSingleList: (id) => dispatch(getArticleFullSingleList(id)),
    getApcArticleList: (id) => dispatch(getApcArticleList(id)),
    getArticleOfferDetails: (id) => dispatch(getArticleOfferDetails(id)),
    sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
    getDepartByFiltering: () => dispatch(getDepartByFiltering()),
    mutateApcRequestStatus: (ids, action, payload = {}) => dispatch(mutateApcRequestStatus(ids, action, payload)),


});

export const ArticleFull = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Article)
);