import {
    EMAIL_HISTORY_ERRROR,
    EMAIL_HISTORY_SUCCESS,
    EMAIL_HISTORY_REQUEST,
    EMAIL_RESEND_ACTION
} from "../../config";

const ApcFundReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case EMAIL_HISTORY_REQUEST:
            return state = {
                ...state,
            }
        case EMAIL_HISTORY_SUCCESS:
            return state = {
                ...state,
                loading: false,
                articleAuditLog: action.payload
            }
        case EMAIL_HISTORY_ERRROR:
            return state = {
                ...state,
                loading: false,
                articleAuditError: action.payload,
            }
        case EMAIL_RESEND_ACTION:
            return state = {
                ...state,
                loading: false,
                emailResend: action.payload,
            }
        default:
            return state;
    }
}
export default ApcFundReducer;