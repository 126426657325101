import {
    REQUEST_CREATE_USER, FAILED_CREATE_USER, GET_ALL_USER, CLEAR_FAILED_STATUS, REQUEST_UPDATE_USER, FAILED_UPDATE_USER, DELETE_USER_DATA
    , FETCH_USER_DATA, SUCCESS_USER_DATA, FAILED_USER_DATA

} from "../../config";

const UsersReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_USER_DATA:
            return state = {
                ...state,
                loading: true
            }
        case SUCCESS_USER_DATA:
            return state = {
                ...state,
                loading: false,
                userData: action.payload
            }
        case FAILED_USER_DATA:
            return state = {
                ...state,
                userData: action.payload
                , loading: false
            }
        case REQUEST_CREATE_USER:
            return state = {
                ...state,
                new_user: action.payload
            }
        case FAILED_CREATE_USER:
            return state = {
                ...state,
                failed: action.payload
            }
        case GET_ALL_USER:
            return state = {
                ...state,
                users: action.payload
            }
        case CLEAR_FAILED_STATUS:
            return state = {
                ...state,
                failed: null
            }
        case REQUEST_UPDATE_USER:
            return state = {
                ...state,
                update_user: action.payload
            }
        case FAILED_UPDATE_USER:
            return state = {
                ...state,
                failed: action.payload
            }
        case DELETE_USER_DATA:
            return state = {
                ...state,
                delete_user: action.payload
            }
        default:
            return state;
    }
}
export default UsersReducer;