import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import {
  getJournalListHub,
  deleteJournal,
  getJournalDownload,
} from "../../../../actions";
import {
  Loading,
  PopupBox,
  InputGroup,
  // SelectBox,
  // InputBox
} from "../../../../../core/components/common";
import NotFound from "../../NotFound";
import { serialize } from "../../../../../core/lib";
import moment from "moment";

//journal
class Journal extends BaseComponent {
  state = {
    deleteConfirm: false,
    journalId: null,
    waitingMsg: null,
    deleteStatus: null,
  };
  getJournalData = async (payload = {}) => {
    payload.pageNum = payload.page || 1;

    if (!payload.pageSize) {
      payload.pageSize = 10;
    }

    await this.props.getJournalListHub(payload);
    this.setState({
      data: this.props.journals.results,
      count: this.props.journals.count,
    });
  };
  async componentDidMount() {
    this.getJournalData();
  }
  pageChange = (pageNumber) => {
    let payload = {
      page: pageNumber,
    };

    if (this.state.q) {
      payload["q"] = this.state.q;
    }

    this.getJournalData(payload);
  };

  filterData = async (value) => {
    value.page = 1;

    this.setState({
      ...value,
    });

    this.getJournalData(value);
  };

  onEditjournal = (id) => {
    this.props.history.push("/article/edit-journal/" + id);
  };

  onViewjournal = (id) => {
    this.props.history.push("/article/single-journal/" + id);
  };

  onDeletejournal = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        journalId: id,
      });
    }
  };

  onDeletejournalHander = async () => {
    const journalId = this.state.journalId;
  
    this.setState({
      waitingMsg: "Deleting...",
    });
    const journal = await this.props.deleteJournal(journalId);
    let data = this.state.data;
    let newData = data.filter(function (element) {
      return element.id !== journalId;
    });
    this.setState({
      waitingMsg: null,
      journalId: null,
      deleteConfirm: false,
      deleteStatus: journal.status,
      data: newData,
    });
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      journalId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

  onArticleDownloadClick = async () => {
    try {
      let params = await serialize(this.state.params);
      let filter = await serialize(this.state.filterParams);
      await this.props.getJournalDownload({ params, filter });
      const url = window.URL.createObjectURL(this.props.journalDownload);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `journal_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    
    }
  };

  render() {
    const heading = [
      "name",
      "publisher_name",
      "publication_type_name",
      "pissn",
      "eissn",
      "note",
    ];
    let errMsg = !this.props.journals;
    let journal = this.props.journals;
    let failedMsg = this.props.failed;
    let errorHander = (
      <>
        {failedMsg && failedMsg.status === 400 && !this.props.journals && (
          <PopupBox
            title="Invalid Request"
            msg="Your Request is not valid"
            onCancel={this.cancelLoader}
          />
        )}
        {failedMsg && failedMsg.status === 500 && !this.props.journals && (
          <PopupBox
            title="Network Error Found"
            msg="Please Check Internet Connection and Try Again"
            onCancel={this.cancelLoader}
          />
        )}
        {!journal && !failedMsg && failedMsg !== null && <Loading />}
       
      </>
    );

    return this.state.status === 403 ? (
      <NotFound />
    ) : (
      <HubContent
        title="Journal list"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeletejournalHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        {this.state.deleteStatus && (
          <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
        )}

        {this.state.deleteStatus === false && (
          <PopupBox
            title="Failed... Try Again"
            onCancel={this.onCancel}
            NoBtnType="danger"
          />
        )}
        {errorHander}
        <div className="row filter">
          <div className="col-md-8">
            {/* <div className="row">
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
            </div>*/}
          </div>
          <div className="col-md-4">
            <InputGroup
              onClick={this.filterData}
              field="q"
              placeholder="Search"
              className="col-md-12 mb-2 free-text-search"
            />
          </div>{" "}
          <div className="col-12">
            <div className="card m-b-20">
              {!errMsg && this.state.data && (
                <DataTable
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditjournal}
                  onDelete={this.onDeletejournal}
                  onView={this.onViewjournal}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: state.auth.group,
  journals: state.journals.journal_list || false,
  journalDownload: state.journals.journal_download,
});

const mapDispatchToProps = (dispatch) => ({
  getJournalListHub: (payload) => dispatch(getJournalListHub(payload)),
  deleteJournal: (id) => dispatch(deleteJournal(id)),
  getJournalDownload: (payload = {}) => dispatch(getJournalDownload(payload)),
});

export const JournalList = connect(
  mapStateToProps,
  mapDispatchToProps
)(Journal);
