import config, {
    GET_XERO_AUTHORIZE_URL,
    SUBMIT_XERO_AUTH_CODE
} from '../../config'
import { api } from '../../core/api';

export const getXeroAuthUrlReducer = (payload) => ({
    type: GET_XERO_AUTHORIZE_URL,
    payload
})

export const submitXeroAuthCodeReducer = (payload) => ({
    type: SUBMIT_XERO_AUTH_CODE,
    payload
})

export const getXEROAuthUrl = () => {
    let url = config.endpoint.xero_authorize_url;

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const token = getState().auth.token;
            api.get(url, token).then((res) => {
                dispatch(getXeroAuthUrlReducer(res))
                resolve(res);
            }).catch(function (error) {
                dispatch(getXeroAuthUrlReducer(error.response))
                reject(error);
            })
        })
    }
}

export const submitXEROAuthCode = (payload) => {
    let url = config.endpoint.submit_xero_auth_code;

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            api.post(url, payload).then((res) => {
                resolve(res);
            }).catch(function (error) {
                reject(error);
            })
        })
    }
}