import React from "react";
import { withRouter } from "react-router-dom";
// import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { serialize } from ".././../../../../../core/lib";
import { Pagination } from "antd";
import { Loading } from "../../../../../../core/components/common"

import { getBookSeriesTableList } from "../../../../../actions";
class BookSeriesTable extends React.Component {
  state = {
    filteredInfo: null,
    filterParams: { page: 1 },
  };

  getBookData = async (pageNum = 1) => {
    await this.props.getBookSeriesTableList({
      pageNum: pageNum,
    });
    if (this._isMounted) {
      this.setState({
        data:
          this.props.book_series_table && this.props.book_series_table.results,
        count: this.props.book_series_table && this.props.book_series_table.count,
      });
    }

  };

  pageChange = (pageNumber) => {
    this.getBookData(pageNumber);
  };

  filterData = async (value) => {
    this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = serialize(this.state.filterParams);
    this.setState({
      filter_url: filter,
    });
  };

  onFilter = async () => {
    let filter = this.state.filter_url;
    await this.props.getBookSeriesTableList(filter);
  };
  _isMounted = false
  componentDidMount() {
    this._isMounted = true
    this.getBookData();
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  onEditJournal = (editId) => {
    this.props.history.push({
      pathname: `/article/edit-journal/${editId}`,
      state: { id: this.state.id, index: 0 },
    });
  };
  onViewJournal = (id) => {
    this.props.history.push(`/article/single-journal/${id}`);
  };

  render() {
    let book_series_table = [];
    this.state.data &&
      this.state.data.map((item) =>
        book_series_table.push({
          journal_type_name: item.journal_type_name,
          name: item.name,
          id: item.id,
          pissn: item.pissn,
          eissn: item.eissn,
          subject:
            item.subjects_list &&
            item.subjects_list
              .map((item) => item.name)
              .slice(0, 1)
              .join(),
        })
      );

    return (
      <div>
        <table
          className="table table-striped"
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            width: "100%",
          }}
        >
          <thead className="text-white thead-dark">
            <tr>
              <th width="40%">Name</th>
              <th width="8%">Type</th>
              <th width="20%">Subject</th>
              <th width="20%">Pissn</th>
              <th width="20%">Eissn</th>
              <th width="10%">Action</th>
            </tr>
          </thead>
          {this.props.isLoading !== true &&
            <tbody>
              {book_series_table &&
                book_series_table.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.journal_type_name}</td>

                    <td>{item.subject}</td>
                    <td>{item.pissn}</td>
                    <td>{item.eissn}</td>

                    <td>
                      <span>
                        <span
                          className="cursor-pointer"
                          onClick={() => this.onEditJournal(item.id)}
                          title="Edit"
                        >
                          <i className="mdi mdi-pen"></i>
                        </span>
                      |
                    </span>

                      <span
                        className="cursor-pointer"
                        onClick={() => this.onViewJournal(item.id)}
                        title="View"
                      >
                        <i className="mdi mdi-eye"></i>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>}
        </table>
        {this.props.isLoading === true && <Loading type="flat" />}
        {this.state.count ? <Pagination
           showSizeChanger={ false}
          style={{ marginTop: 10, marginBottom: 20, textAlign: "center" }}
          onChange={this.pageChange}
          pageSize={10}
          total={this.state.count || undefined}
        />:null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  book_series_table: state.journals.book_series_table,
  isLoading: state.journals.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  getBookSeriesTableList: (payload) =>
    dispatch(getBookSeriesTableList(payload, "Journal")),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookSeriesTable)
);
