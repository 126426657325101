import config, {
    SUCCESS_ARTICLE_CONTROL_CONFIG_LIST,
    REQUEST_ARTICLE_CONTROL_CONFIG_LIST,
    FAIELD_ARTICLE_CONTROL_CONFIG_LIST,
    GET_ARTICLE_CONTROL_CONFIG,
    CREATE_UPDATE_ARTICLE_CONTROL_CONFIG,
    DELETE_ARTICLE_CONTROL_CONFIG,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get article control Member list
export const successArticleControlConfigListAction = (payload) => ({
    type: SUCCESS_ARTICLE_CONTROL_CONFIG_LIST,
    payload
});
export const requestArticleControlConfigListAction = () => ({
    type: REQUEST_ARTICLE_CONTROL_CONFIG_LIST,

});
export const faliedArticleControlConfigListAction = (payload) => ({
    type: FAIELD_ARTICLE_CONTROL_CONFIG_LIST,
    payload
});



// Get article control Member
export const getArticleControlConfigAction = (payload) => ({
    type: GET_ARTICLE_CONTROL_CONFIG,
    payload
});

// Create-Update article control Member
export const createUpdateArticleControlConfigAction = (payload) => ({
    type: CREATE_UPDATE_ARTICLE_CONTROL_CONFIG,
    payload
});

// Delete article control Member
export const deleteArticleControlConfigAction = (payload) => ({
    type: DELETE_ARTICLE_CONTROL_CONFIG,
    payload
});

export const getArticleControlConfigList = (payload = {}) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_control_config;
        let params = {};

        if (payload.pageNum && payload.pageSize) {
            params['page'] = payload.pageNum;
            params['page_size'] = payload.pageSize;
        } else if (payload.pageNum) {
            params['page'] = payload.pageNum;
        } else if (payload.pageSize) {
            params['page_size'] = payload.pageSize;
        }

        if (!params.page_size) {
            params['page_size'] = 99999;
        }

        url += `?${serialize(params)}`;
        dispatch(requestArticleControlConfigListAction())

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(successArticleControlConfigListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                dispatch(faliedArticleControlConfigListAction(e))
                reject(e)
            })
        })
    }
}

// Get article control config
export const getArticleControlConfig = (id) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_control_config + `${id}/`;

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getArticleControlConfigAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Create-Update article control config

export const createUpdateArticleControlConfig = (payload, id) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_control_config;
        let apiRequest = '';
        return new Promise((resolve, reject) => {
            if (id) {
                url += `${id}/`;
                apiRequest = api.put(url, payload);
            } else {
                apiRequest = api.post(url, payload);
            }

            apiRequest.then((res) => {
                dispatch(createUpdateArticleControlConfigAction(res.data));
                resolve(Object.assign(res.data, { status: true }));
            }).catch(e => {
                reject(e)
            });
        })
    }
}

// Delete discount config
export const deleteArticleControlConfig = (id) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_control_config + `${id}/`;

        return new Promise((resolve, reject) => {
            api.delete(url).then((res) => {
                dispatch(deleteArticleControlConfigAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}


// Get article control decision
export const getArticleControlDecision = (articleId) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_control_decision + `${articleId}/`;

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

