import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { DataTable, HubContent } from '../../../../../core/components';
import { getJournalList, deleteJournal, getOrganisationOffsetFund } from '../../../../actions';
import { Loading, PopupBox } from '../../../../../core/components/common';
import NotFound from '../../NotFound';

class OrgOffsetList extends BaseComponent {
    state = {
        deleteConfirm: false,
        journalId: null,
        waitingMsg: null,
        deleteStatus: null
    }
    getOrgOffsetFundTokenData = async (pageNum = 1, page = 10) => {
        await this.props.getOrganisationOffsetFund({ pageNum: pageNum, pageSize: page });
        let OffsetFundList = this.props.offset_fund_list.results;
        let dataOrg = [];
        OffsetFundList && OffsetFundList.map((item) => {
            return dataOrg.push({
                organization_name: item.organisation_name,
                publisher_name: item.offset_fund.publisher_name,
                currency: item.offset_fund.currency_name,
                amount: item.offset_fund.amount,
                valid_from: item.offset_fund.valid_from,
                valid_to: item.offset_fund.valid_to,
                id: item.id
            });
        })
        this.setState({
            data: dataOrg,
            count: this.props.offset_fund_list.count
        })
    }
    async componentDidMount() {
        this.getOrgOffsetFundTokenData();
    }
    pageChange = (pageNumber) => {
        this.getOrgOffsetFundTokenData(pageNumber);
    }

    onEditjournal = (id) => {
        this.props.history.push('/journals/edit-journal/' + id)
    }

    onViewjournal = (id) => {
        this.props.history.push('/journals/single-journal/' + id)
    }

    onDeletejournal = (id) => {
        if (id) {
            this.setState({
                deleteConfirm: true,
                journalId: id
            })
        }
    }

    onDeletejournalHander = async () => {
        const journalId = this.state.journalId;
        this.setState({
            waitingMsg: "Deleting..."
        })
        const journal = await this.props.deleteJournal(journalId);
        let data = this.state.data;
        let newData = data
            .filter(function (element) {
                return element.id !== journalId;
            });
        this.setState({
            waitingMsg: null,
            journalId: null,
            deleteConfirm: false,
            deleteStatus: journal.status,
            data: newData
        })
    }

    onCancel = () => {
        this.setState({
            deleteConfirm: false,
            journalId: null,
            waitingMsg: null,
            deleteStatus: null
        })
    }
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();

        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        // }
    };
    render() {
        const heading = ['organization_name', 'publisher_name', 'currency', 'amount', 'valid_from', 'valid_to']
        return (
            this.state.status === 403 ? <NotFound /> :
                <HubContent title="Organisation offset fund list" onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                    {this.state.deleteConfirm && <PopupBox
                        title="Do you want to delete?"
                        YesText="Yes"
                        yesBtnType="danger"
                        type="danger"
                        NoBtnType="success"
                        onCancel={this.onCancel}
                        onPressOK={this.onDeletejournalHander}
                        waitingMsg={this.state.waitingMsg}
                    />}
                    {this.state.deleteStatus && <PopupBox
                        title="Successfully deleted"
                        onCancel={this.onCancel}
                    />}

                    {this.state.deleteStatus === false && <PopupBox
                        title="Failed... Try Again"
                        onCancel={this.onCancel}
                        NoBtnType="danger"
                    />}
                    {/* {errorHander} */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                {this.state.data ?
                                    <DataTable
                                        heading={heading}
                                        data={this.state.data}
                                        onEdit={this.onEditjournal}
                                        onDelete={this.onDeletejournal}
                                        onView={this.onViewjournal}
                                        count={this.state.count}
                                        pageChange={this.pageChange}
                                        pageSize={10}
                                    />
                                    :
                                    <Loading />
                                }
                            </div>
                        </div>
                    </div>
                </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    offset_fund_list: state.oaToken.offset_fund_list || false,
})

const mapDispatchToProps = (dispatch) => ({
    getJournalList: () => dispatch(getJournalList()),
    deleteJournal: (id) => dispatch(deleteJournal(id)),
    getOrganisationOffsetFund: (payload) => dispatch(getOrganisationOffsetFund(payload))
})

export const OrgOffsetFundList = connect(mapStateToProps, mapDispatchToProps)(OrgOffsetList);

