import React from 'react';
import { connect } from 'react-redux';
import { RoleContext } from '../../../../../core/context/hub/RoleContext';
import { HubContent } from '../../../../../core/components';
import { RoleForm } from '../../../partials';
import { createRole } from '../../../../actions';
import { Loading } from '../../../../../core/components/common';

class Add extends RoleContext {
    constructor(props) {
        super(props)
        this.state = {
            status: false,
            msg: false
        }
    }

    onSubmitHandler = async (data) => {
        if (data.name) {
            this.setState({
                status: true,
                msg: false
            })
            Object.assign(data, { is_active: true })
            const role = await this.props.createRole(data);
            if (role.status === 201) {
                document.querySelector("form").reset();
                this.setState({
                    status: false,
                    msg: 'New Role Created'
                })
            }
        } else {
            alert('Field cannot be empty')
        }
    }

    render() {
        return (
            <HubContent title="Add role form">
                {this.state.status && <Loading />}
                {/* Row Start */}
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        {/* Card Start */}
                        <div className="card m-b-20">
                            {/* Card Body Start */}
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Add role details</a>
                                    </li>
                                </ul>
                                <RoleForm
                                    onSubmitHandler={this.onSubmitHandler}
                                    msg={this.state.msg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    createRole: (payload) => dispatch(createRole(payload))
})
export const AddRole = connect(mapStateToProps, mapDispatchToProps)(Add);
