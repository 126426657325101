import React from "react";
import { AutoComplete } from "antd";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  PopupBox,
  Loading,
  SelectBox,
  InputBox,
  TextBox,
  ModalCustom,
  InputGroup
} from "../../../../../core/components/common";
import { DataTable } from "../../../../../core/components";
import { connect } from "react-redux";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";

import moment from "moment";

import {
  getAllOaDealList,
  deleteOaDeal,
  oaDealDownload,
  filterOaDeal,
  getSingleOaDeal,
  dealInstituationList,
  createUpdateDepositPayment,
} from "../../../../actions";

const format = "Do MMM YYYY";

class DB extends BaseComponent {

  state = {
    data: null,
    oadeal_list: [],
    filterParams: { page: 1 },
    showUpdateDepositPayment: false,
  };
  getOaDealData = async (pageNum = 1) => {
    let value = {
      page: pageNum,
  };

  await this.setState((prevState) => {
    prevState.filterParams = Object.assign(prevState.filterParams, value);

  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getAllOaDealList({ params, filter });

      this.setState({
        oaDealList: this.props.oaDealList &&
          this.props.oaDealList.results
        ,
        count: this.props.oaDealList &&
          this.props.oaDealList.count
      })
    

  };
  componentDidMount() {
    this.getOaDealData();
    this.props.dealInstituationList();
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  pageChange =async (pageNumber) => {
    let value = {
      page: pageNumber,
  };

  await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getAllOaDealList({ params, filter });
  this.setState({
    oaDealList: this.props.oaDealList &&
      this.props.oaDealList.results
    ,
    count: this.props.oaDealList &&
      this.props.oaDealList.count
  })
  };

  onEditOaDeal = (id) => {
    this.props.history.push("/oa-deal-activities/edit-oa-deal/" + id);
  };


  onViewOaDeal = (id, data) => {
    history.push("/oa-deal-activities/oa-deal/" + id, {
      ...data,
    });
  };
  onDeleteOaDeal = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        oAdealId: id,
      });
    }
  };


  onDeleteOaDealHander = async () => {
    const oAdealId = this.state.oAdealId;
    this.setState({
      waitingMsg: "Deleting...",
    });
    const oADeal = await this.props.deleteOaDeal(oAdealId);
    this.setState({
      waitingMsg: null,
      oAdealId: null,
      deleteConfirm: false,
      deleteStatus: oADeal.status,
    });
    this.getOaDealData();
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      oAdealId: null,
      waitingMsg: null,
      deleteStatus: null,
      isEmpty: false,
      showUpdateDepositPayment: false,
    });
  };

  onArticleDownloadClick = async () => {
    try {
      await this.props.oaDealDownload();
      const url = window.URL.createObjectURL(this.props.oadeal_download_data);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `oa-deals_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) { }
  };


  onSearchfilterData = async (value) => {
    value.page = 1;
  

    if (value.hasOwnProperty("q")) {
      this.setState({
        ...value,
      });
    }

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = await serialize(this.state.filterParams);
    let params = await serialize(this.state.params);
    await this.props.getAllOaDealList({ params, filter });
    this.setState({
      oaDealList: this.props.oaDealList &&
        this.props.oaDealList.results
      ,
      count: this.props.oaDealList &&
        this.props.oaDealList.count
    })
};


  onChangeHandler = async (value) => {
    if (value.oa_deal) {
      let oaDealObj = await this.props.getSingleOaDeal(value.oa_deal);
      this.setState({
        deal_type: oaDealObj.deal_type,
        currency_name: oaDealObj.currency_name,
        deal_amount: oaDealObj.constraint && oaDealObj.constraint.deal_amount,
        oa_deal: value.oa_deal,
      });
    } else {
      this.setState({
        ...value,
      });
    }
  };

  onSelect = async (value, item) => {
    let payload = {
      pageNum: 1,
      pageSize: 99999,
      counter_organisation: item.id,
    };

    let oaDealList = await this.props.filterOaDeal(payload);

    this.setState({
      counter_organisation: item.id,
      oadeal_list:
        (oaDealList &&
          oaDealList.results.map((item) => {
            return { id: item.id, name: item.deal_name };
          })) ||
        [],
      is_manager_organisation:
        item.is_manager_organisation === "true" ? true : false,
    });
  };
  onChange = (value) => {
    this.setState({
      counter_organisation_name: value,
    });
  };
  onSearch = async (value) => {
    await this.props.dealInstituationList(value);
    this.setState({
      odealInstList: this.props.oaDeal_inst,
    });
  };
  modalForm = (value) => {
    return (
      <form>
        <div className="form-group row auto-complete-search ">
          <div className="col-form-label  col-md-3">
            <label>Institution/Consortia</label>
          </div>
          <div className="col-md-9">
            <AutoComplete
              value={this.state.counter_organisation_name}
              options={value}
              style={{
                width: "100%",
              }}
              onSelect={(val, option) => this.onSelect(val, option)}
              onSearch={this.onSearch}
              onChange={this.onChange}
              placeholder="Type your full Institution/Consortia name and select from the drop down"
            />
          </div>
        </div>
        <div className="form-group row">
          <SelectBox
            label="OA Deal"
            labelClass="col-sm-3"
            className="col-sm-9"
            onChange={this.onChangeHandler}
            field="oa_deal"
            placeholder="Select OA Deal"
            data={this.state.oadeal_list}
            isRequired={true}
            defaultValue={this.state.oa_deal}
          />
        </div>
        {this.state.deal_amount && (
          <div className="form-group row">
            <label className="col-form-label col-sm-3">Deal amount</label>
            <div className="col-sm-9" style={{ position: "relative" }}>
              {this.state.currency_name} {this.state.deal_amount}
            </div>
          </div>
        )}

        {this.state.deal_type === 2 && (
          <div>
            <div className="form-group row">
              <InputBox
                label="Publish fee"
                labelClass="col-sm-3"
                onChange={this.onChangeHandler}
                field="publish_fee"
                placeholder="Publish fee"
                className="col-sm-9"
              />
            </div>
            <div className="form-group row">
              <InputBox
                label="Read fee"
                labelClass="col-sm-3"
                onChange={this.onChangeHandler}
                field="read_fee"
                placeholder="Read fee"
                className="col-sm-9"
              />
            </div>
          </div>
        )}

        <div className="form-group row">
          <InputBox
            label={
              this.state.deal_type === 2 ? `Total amount` : `Deposit amount`
            }
            labelClass="col-sm-3"
            onChange={this.onChangeHandler}
            field="amount"
            placeholder="Amount"
            className="col-sm-9"
            InputType="number"
            defaultValue={
              this.state.deal_type === 2 ? this.getPubReadFeeTotal() : ""
            }
          />
        </div>

        <div className="form-group row">
          <TextBox
            label="Note"
            labelClass="col-sm-3"
            onChange={this.onChangeHandler}
            field="approval_note"
            placeholder="Note"
            rows={3}
            className="col-sm-9"
          />
        </div>
      </form>
    );
  };

  getPubReadFeeTotal = () => {
    let depositPublishFee = Number(this.state.publish_fee) || 0;
    let depositReadFee = Number(this.state.read_fee) || 0;
    let total = depositPublishFee + depositReadFee;

    return total;
  };

  onUpdatePaymentSubmit = async () => {
    let {
      oa_deal,
      amount,
      publish_fee,
      read_fee,
      approval_note,
      deal_type,
    } = this.state;

    if (!oa_deal) {
      this.setState({
        isEmpty: <div>{!oa_deal && <p>Please select OA Deal</p>}</div>,
      });
    } else {
      let data = {
        oa_deal,
        amount: deal_type === 2 ? this.getPubReadFeeTotal() : Number(amount),
        publish_fee: Number(publish_fee),
        read_fee: Number(read_fee),
        approval_note,
      };

      let depositPayment = await this.props.createUpdateDepositPayment(data);

      if (depositPayment && depositPayment.status) {
        this.setState({
          showUpdateDepositPayment: false,
          counter_organisation: undefined,
          oa_deal: undefined,
          deal_type: undefined,
          currency_name: undefined,
          deal_amount: undefined,
          isEmpty: "Payment update successful.",
        });
      }
    }
  };

  onUpdatePaymentClick = () => {
    this.setState({
      modalTitle: "Update payment",
      showUpdateDepositPayment: true,
    });
  };


  render() {
    const { odealInstList } = this.state;
    const data = [];
    this.state.oaDealList && this.state.oaDealList.map((item) => {
      return data.push({
        ...item,
        id: item.id,
        Organisation: item.counter_organisation_name,
        deal_type: item.deal_name,
        currency: item.currency_name,
        discount: item.discount
          ? parseFloat(item.discount).toFixed(0) + "%"
          : "-",
        expiry: item.expire_date
          ? moment(item.expire_date).format(format)
          : "-",
      });
    });

    const heading = [
      "Organisation",
      "deal_type",
      "currency",
      "discount",
      "expiry",
    ];


    let errorHandler = (
      <>
        {this.state.isEmpty && (
          <PopupBox msg={this.state.isEmpty} onCancel={this.onCancel} />
        )}
      </>
    );

    return (
      <HubContent
      group={this.props.group}
        title="List of OA deals"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
        onUpdatePaymentClickHandler={this.onUpdatePaymentClick}
      >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            btnSize="200px"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteOaDealHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}

        {this.state.showUpdateDepositPayment && (
          <ModalCustom
            title={this.state.modalTitle}
            submitBtnTxt="Submit"
            onClose={this.onCancel}
            onSubmit={this.onUpdatePaymentSubmit}
            modalClass="col-sm-8"
            isShow={this.state.showUpdateDepositPayment}
          >
            {this.modalForm(odealInstList)}
          </ModalCustom>
        )}
        {errorHandler}

        <div className="row filter">
          <div className="col-lg-12">
          <InputGroup
                        onClick={this.onSearchfilterData}
                        field="q"
                        placeholder="Search"
                        className="col-md-3 mb-2 free-text-search"
                    />
        </div>
          <div className="col-lg-12">
            <div className="card m-b-20">
              {this.state.oaDealList ?
                < DataTable
                  heading={heading}
                  data={data}
                  onEdit={this.onEditOaDeal}
                  onDelete={this.onDeleteOaDeal}
                  onView={this.onViewOaDeal}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                /> : (
                  <Loading type="flat" />
                )}
              

            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id || false,
  group:state.auth.group,
  oaDeal_inst: state.OaDeals.oaDeal_inst || false,
  oaDealList: state.OaDeals.oadeal_list || false,
  oadeal_download_data: state.OaDeals.download_data,
  deal_type: state.OaDeals.deal_type || false,
});
const mapDispatchToProps = (dispatch) => ({
  getAllOaDealList: (payload) => dispatch(getAllOaDealList(payload)),
  deleteOaDeal: (id) => dispatch(deleteOaDeal(id)),
  oaDealDownload: (payload = {}) => dispatch(oaDealDownload(payload)),
  dealInstituationList: (value) => dispatch(dealInstituationList(value)),
  getSingleOaDeal: (id) => dispatch(getSingleOaDeal(id)),
  filterOaDeal: (payload) => dispatch(filterOaDeal(payload)),
  createUpdateDepositPayment: (payload, id) =>
    dispatch(createUpdateDepositPayment(payload, id)),
});

export const OaDealsList = connect(mapStateToProps, mapDispatchToProps)(DB);
