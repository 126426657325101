import config, { 
    CREATE_GEOGRAPHICAL_DISCOUNT,
    FAILED_GEOGRAPHICAL_DISCOUNT,
    GEOGRAPHICAL_DISCOUNT_LIST,
    GEOGRAPHICAL_DISCOUNT_LIST_SINGLE,
    UPDATE_GEOGRAPHICAL_DISCOUNT,
} from "../../config";
import { api } from "../../core/api";

// Loaded into App Reducers
export const createGeographicalDiscountReducer = (payload) => ({
    type: CREATE_GEOGRAPHICAL_DISCOUNT,
    payload
})

export const failedGeographicalDiscount = (payload) => ({
    type: FAILED_GEOGRAPHICAL_DISCOUNT,
    payload
})

export const GeographicalDiscountList = (payload) => ({
    type: GEOGRAPHICAL_DISCOUNT_LIST,
    payload
})

export const GeographicalDiscountListSingle = (payload) => ({
    type: GEOGRAPHICAL_DISCOUNT_LIST_SINGLE,
    payload
})

export const GeographicalDiscounUpdate = (payload) => ({
    type: UPDATE_GEOGRAPHICAL_DISCOUNT,
    payload
})



// Geographical Discount create
export const createGeographicalDiscount = (payload) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let url = config.endpoint.geographical_discount;
            api.post(url, payload).then((res) => {
                dispatch(createGeographicalDiscountReducer(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(function (error) {
                dispatch(createGeographicalDiscountReducer(error))
                resolve(Object.assign(error, { status: false }));
            })
        })
    }
}


// Geographical Discount list
export const getGeographicalDiscount = (payload) => { 
    let url = config.endpoint.geographical_discount;
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            api
              .get(`${url}?page=${payload.pageNum}&page_size=${payload.pageSize}`)
              .then(res => {
                dispatch(GeographicalDiscountList(res))
                resolve(Object.assign(res, { status: true }))
              })
              .catch(e => {
                reject(e)
              })
        })
    }
}


// Geographical Discount single
export const getGeographicalDiscountSingle = (id) => { 
    let url = config.endpoint.geographical_discount + id;

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            api
              .get(`${url}?page=1&page_size=9999999`)
              .then(res => {
                dispatch(GeographicalDiscountListSingle(res))
                resolve(Object.assign(res, { status: true }))
              })
              .catch(e => {
                reject(e)
              })
        })
    }
}

// Geographical Discount Update
export const updateGeographicalDiscount = (id,payload) => { 
    let url = config.endpoint.geographical_discount + id +'/';

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            api
              .put(url,payload)
              .then(res => {
                dispatch(GeographicalDiscounUpdate(res))
                resolve(Object.assign(res, { status: true }))
              })
              .catch(e => {
                dispatch(GeographicalDiscounUpdate(e.response.data))
                reject(e)
              })
        })
    }
}


