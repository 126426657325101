import { api } from "../../core/api";
import config, { GET_USER_PERMISSION_LIST } from "../../config";

const userPermissionAction = (payload) => ({
  type: GET_USER_PERMISSION_LIST,
  payload,
});

export const getUserPermissionList = (id) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.user_permission_summery}${id}/`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then(
          (res) => {
            resolve(res);
            dispatch(userPermissionAction(res));
            resolve(res);
          },
          (error) => {
            resolve(error);
          }
        )
        .catch((error) => {
          resolve(error);
        });
    });
  };
};

const userforgetpasswordAction = (payload) => ({
  type: GET_USER_PERMISSION_LIST,
  payload,
});

export const createUserForgetPassword = (payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.reset_password}`;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then(
          (res) => {
            resolve(res);
            dispatch(userforgetpasswordAction(res));
            resolve(res);
          },
          (error) => {
            resolve(error);
          }
        )
        .catch((error) => {
          resolve(error);
        });
    });
  };
};
