import React from "react";
import { AutoComplete, Spin, Tooltip } from "antd";
import { InfoCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { InputNewBox } from "../../../../../core/components/common";
import { getPublicOrganisations } from "../../../../actions"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class CoAuthorArticle extends React.PureComponent {
  state = {
    department: this.props.value && this.props.value.department && this.props.value.department.name,
    co_authors: {
      affiliated_organisation: this.props.value && this.props.value.affiliated_organisation,
      affiliation: this.props.value && this.props.value.affiliation,
      last_name: this.props.value && this.props.value.last_name,
      middle_name: this.props.value && this.props.value.middle_name,
      name: this.props.value && this.props.value.name,
      orcid_id: this.props.value && this.props.value.orcid_id,
      pmc_id: this.props.value && this.props.value.pmc_id,
      position: this.props.value && this.props.value.position,
      salutation: this.props.value && this.props.value.salutation,
      first_name: this.props.value && this.props.value.first_name,
      email: this.props.value && this.props.value.email,
    },
  };

  componentDidMount() { 
    
    let item = this.props.value
    item &&
      this.setState({
        department: item && item.department && item.department.name,

        co_authors: {
          affiliated_organisation: item.affiliated_organisation,
          affiliation: item.affiliation,
          last_name: item.last_name,
          middle_name: item.middle_name,
          name: item.name,
          orcid_id: item.orcid_id,
          pmc_id: item.pmc_id,
          position: item.position,
          salutation: item.salutation,
          first_name: item.first_name,
          email: item.email,
        },
      });

  }

  onChangeHandler = (value) => {
    this.setState((prevState) => Object.assign(prevState.co_authors, value));
  };

  onCloseModal = (e) => {
    let overlay = document.getElementById("CoAuthorDialog");
    if (e.target === overlay) {
      this.props.onClose();
    }
  };

  onSaveCoAuthor = (e) => {
    e.preventDefault();

    let { co_authors } = this.state;
    Object.assign(co_authors, {
      department: {
        name: this.state.department,

      },
    })

    if (Object.entries(co_authors).length === 0) {
      alert("fields are empty");
    } else {
      this.props.onSaveAuthor(co_authors);
    }
  };

  onSelect = (value, item) => {
    this.setState((prevState) => Object.assign(prevState.co_authors, {
      affiliation: value
    }));
  };

  onChange = (value) => {
    this.onChangeHandler({
      affiliation: value
    });
  };

  onSearch = (value) => { 
    this.setState({
      loadingOrgList: true,
    });

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {

      getPublicOrganisations(value).then(res => { 
          let orgs = res.results

          let dataList = orgs && orgs.map(item => ({
                                        id: item.id,
                                        value: item.name,
                                      }))

          this.setState({
            loadingOrgList: false,
            dataList,
            no_affiliation_found: orgs.length === 0,
    
          })
        }).catch(error => {
          this.setState({
            loadingOrgList: false,
          });
        });
      
    }, 200);
  };


  render() {
    let { co_authors } = this.state;
    let { fieldLabel } = this.props;
    let CoAuthorInfo = (
      <div>
        <div className="form-group row">
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter Salutation"
            label="Salutation"
            onChange={this.onChangeHandler}
            field="salutation"
            className="col-md-4"
            placeholder="Enter Salutation"
            labelClass="col-md-2"
            defaultValue={co_authors.salutation}
          />

          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter First name"
            label="First name"
            onChange={this.onChangeHandler}
            field="first_name"
            className="col-md-4"
            placeholder="Enter First name"
            labelClass="col-md-2"
            defaultValue={co_authors.first_name}
          />
        </div>

        <div className="form-group row">
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter Middle name"
            label="Middle name"
            onChange={this.onChangeHandler}
            field="middle_name"
            className="col-md-4"
            placeholder="Middle name"
            labelClass="col-md-2"
            defaultValue={co_authors.middle_name}
          />

          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter Last name"
            label="Last name"
            onChange={this.onChangeHandler}
            field="last_name"
            className="col-md-4"
            placeholder="Enter Last name"
            labelClass="col-md-2"
            defaultValue={co_authors.last_name}
          />
        </div>

        <div className="form-group row">
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter Corresponding author email"
            label={`${fieldLabel} email`}
            onChange={this.onChangeHandler}
            field="email"
            className="col-md-4"
            placeholder="Enter Corresponding author email"
            labelClass="col-md-2"
            InputType="email"
            defaultValue={co_authors.email}
          />

          <div className="col-md-6 auto-complete-search author-affiliation">
            <div className="row">
              <div className="col-md-4 col-form-label">
                <label>
                  Affiliation 
                  <Tooltip title="Enter Affiliation">
                    <InfoCircleTwoTone 
                      style={{
                        fontSize: 18,
                        marginTop: 10,
                        marginLeft: 13
                      }} 
                      twoToneColor="rgb(122, 111, 190)" className="signup-email"
                    />
                  </Tooltip>
                </label>
              </div>

              <div className="col-md-8 search-compelete pt-2">
                <div className="search-auto-complete">

                  <AutoComplete 
                    className={this.props.is_author_affiliation_match === false && 'affiliation-mismatch'}
                    defaultValue={co_authors.affiliation}
                    notFoundContent={this.state.no_affiliation_found && "The affiliation name you have typed has no match on OaMetrix."}
                    placeholder="Type your affiliation name"
                    options={this.state.dataList}
                    style={{
                      width: "100%",
                    }}
                    onSelect={(val, option) => this.onSelect(val, option)}
                    onSearch={this.onSearch}
                    onChange={this.onChange}
                  />

                </div>
                <div className="search-auto-spin">
                  {this.state.loadingOrgList && <Spin indicator={antIcon} />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <InputNewBox
            labelInfo={true}
            labelInfoTitle={fieldLabel ? `Enter ${fieldLabel} department` : "Enter Author department"}
            label={`${fieldLabel} dept`}
            onChange={this.onChangeHandler}
            field="department"
            className="col-md-4"
            placeholder={
              fieldLabel ? `Enter ${fieldLabel} department` : "Enter Author department"
            }
            labelClass="col-md-2"
            defaultValue={this.state.department}
          />
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter Orcid ID"
            label="Orcid ID"
            onChange={this.onChangeHandler}
            field="orcid_id"
            placeholder="Enter Orcid ID"
            defaultValue={co_authors.orcid_id}
          />
        </div>

        <div className="form-group row">

          <InputNewBox
               labelInfo={true}
               labelInfoTitle="Enter PMC id"
            label="PMC id"
            onChange={this.onChangeHandler}
            field="pmc_id"
            placeholder="Enter PMC id"
            defaultValue={co_authors.pmc_id}
          />
          <InputNewBox
            label="Position"
            onChange={this.onChangeHandler}
            field="position"
            
            labelInfo={true}
            labelInfoTitle="Enter Position"
            placeholder="Enter Position"
            defaultValue={co_authors.position}
          />
        </div>


      </div>
    );

    return (
      <div
        onClick={this.onCloseModal}
        id="CoAuthorDialog"
        style={modal.overlay}
      >
        <div className={`col-10 col-lg-8 bg-white alert`} style={modal.boxStyle}>
          { CoAuthorInfo }
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.props.onClose && this.props.onClose();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSaveCoAuthor}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0, 25px)",
    height: boxHeight,
  },
  error: { border: "1px solid red" },
};

export default CoAuthorArticle;
