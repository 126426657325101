import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import { getProgramList } from "../../../../actions";
import {Link} from "react-router-dom"
class UL extends BaseComponent {
  state = {
    deleteConfirm: false,
    userId: null,
    waitingMsg: null,
    deleteStatus: null,
    data: []
  };
  ProgramData = async (pageNum = 1, page = 10) => {
    await this.props.getProgramList({ pageNum: pageNum, pageSize: page });
  };
  async componentDidMount() {
    this.ProgramData();
  }
  pageChange = pageNumber => {
    this.ProgramData(pageNumber);
  };

  onEdit = (id, data) => {
   
    this.props.history.push("/program/edit-program/" + id, { program: data });
  };

  onView = (id, data) => {
    this.props.history.push("/program/" + id, { user: data });
  };

  onDelete = id => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        userId: id
      });
    }
  };

  onDeleteUserHander = async () => {
    const userId = this.state.userId;
    this.setState({
      waitingMsg: "Deleting..."
    });
    await this.props.deleteUser(userId);
    let data = this.state.data;
    let newData = data.filter(function(element) {
      return element.id !== userId;
    });
    this.setState({
      waitingMsg: null,
      userId: null,
      deleteConfirm: false,
      data: newData
    });
    // const userId = this.state.userId;
    // this.setState({
    //     waitingMsg: "Deleting..."
    // })
    // const user = await this.props.deleteUser(userId);
    // this.setState({
    //     waitingMsg: null,
    //     userId: null,
    //     deleteConfirm: false,
    //     deleteStatus: user.status
    // })
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      waitingMsg: null,
      deleteStatus: null
    });
  };

  render() {
    const heading = ["date", "name"];

    let program = [];
    this.props.programs &&
      this.props.programs.map(data => {
        program.push({
          id: data.id,
          date: data.date_created,
          name: data.name
        });
        return program;
      });

    return (
      <HubContent title="Program List">
        <div className="row">
        <div className="col-md-12">
            <Link
             to="/program/add-program"
              className="btn btn-primary float-right mb-2"
            >
              Add Program 
            </Link>
          </div>

          <div className="col-12">
            <div className="card m-b-20">
              {this.props.programs && program && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={program}
                  onEdit={this.onEdit}
                  count={this.props.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  programs:
    (state.program &&
      state.program.program_list &&
      state.program.program_list.results) ||
    false,
  count:
    (state.program &&
      state.program.program_list &&
      state.program.program_list.count) ||
    false
});

const mapDispatchToProps = dispatch => ({
  getProgramList: payload => dispatch(getProgramList(payload))
});

export const ProgramList = connect(mapStateToProps, mapDispatchToProps)(UL);
