import React from "react";
import { withRouter } from "react-router-dom";
import { Switch } from "antd";
import { connect } from "react-redux";
import {
  SelectBox,
  ModalSelectBox,
  Loading,
  Button,
} from "../../../../../core/components/common";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { Steps } from "antd";
import {
  getProgramListAll,
  getProgramCoverageAll,
  getAllCountry,
  getPubProgramSingle,
  getSingleProgram,
  createPubProgram,
  getPubProgramList,
} from "../../../../actions";
const { Step } = Steps;
var _ = require("lodash");

class LicenseForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      program_data: {
        coverage: [],
        exclude_countries:
          (this.props.single_program &&
            this.props.single_program.exclude_countries) ||
          [],
        program:
          (this.props.single_program && this.props.single_program.program) ||
          false,
        send_mail_to_author:
          (this.props.single_program &&
            this.props.single_program.send_mail_to_author) ||
          false,
      },
      program_id: false,
      btn_disabled: true,
    };
  }

  async componentDidMount() {
    await this.props.getAllCountry();
    await this.props.getProgramListAll();
    await this.props.getPubProgramList({ pageNum: 1, pageSize: 9999999 });
  }
  detectStatus = (current) => {
    const { program_data } = this.state;
    let stepBtnStatus = false;
    if (current === 0) {
      stepBtnStatus = program_data && program_data.program ? false : true;
    } else if (current === 1) {
      stepBtnStatus =
        program_data &&
          program_data.coverage &&
          program_data.coverage.length > 0
          ? false
          : true;
    }
    return stepBtnStatus;
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
    let status = this.detectStatus(current);
    this.setState({
      btn_disabled: status,
    });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
    let status = this.detectStatus(current);
    this.setState({
      btn_disabled: status,
    });
  }
  //Submit form
  onSubmit = async (e) => {
    e.preventDefault();
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();
    await this.props.createPubProgram(this.state.program_data);
    this.props.history.push("/settings/waiver-policy/");
  };

  onChange = (checked) => {
    checked &&
      this.setState((prevState) => {
        return Object.assign(prevState.program_data, {
          send_mail_to_author: checked,
        });
      });
  };
  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "coverage":
        this.setState({
          isShowCoverageModal: true,
        });
        break;
      case "exclude_countries":
        this.setState({
          isShowExcludeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowCoverageModal: false,
      isShowExcludeModal: false,
    });
  };

  detectLabel = (length) => {
    let country_label = length === 1 ? "country" : "countries";
    return country_label;
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "coverage":
        if (fieldValues && fieldValues.length > 0) {
          let country_label = this.detectLabel(fieldValues.length);
          let program_id = this.state.program_data.program;
          let program =
            this.props.program &&
            _.find(this.props.program, { id: program_id });
          let program_name = program && program.name;
          labelText =
            program &&
            `
            There are currently ${fieldValues.length} ${country_label} countries included in the '${program_name}' waiver
       `;
        } else {
          labelText = `No countries selected`;
        }
        break;
      case "exclude_countries":
        if (fieldValues && fieldValues.length > 0) {
          let country_label = this.detectLabel(fieldValues.length);
          labelText = `${fieldValues.length} exclude ${country_label} selected`;
        } else {
          labelText = `No exclude countries selected`;
        }
        break;
      default:
        break;
    }
    return labelText;
  };
  onChangeHandler = async (value) => {
    if (value.program !== "") {
      let keys = Object.keys(value);
      let values = Object.values(value);
      if (keys[0] === "program") {
        this.setState({
          program_id: values[0],
          btn_disabled: values[0] ? false : true,
        });
        await this.props.getProgramCoverageAll({ id: values[0] });
        await this.props.getSingleProgram({ id: values[0] });
        this.props.coverage &&
          this.props.single_program &&
          this.setState((prevState) => {
            return Object.assign(prevState.program_data, {
              coverage: _.map(this.props.coverage, "id"),
              exclude_countries: this.props.single_program.exclude_countries,
            });
          });
      }
      this.setState((prevState) => {
        return Object.assign(prevState.program_data, value);
      });
    }
  };
  onSaveSelectedItems = (fieldName, data) => {
    if (fieldName === "coverage") {
      let btn_status = data.length > 0 ? false : true;
      this.setState({
        btn_disabled: btn_status,
      });
    }
    this.setState((prevState) => {
      return Object.assign(prevState.program_data, { [fieldName]: data });
    });
  };

  render() {
    const { program_data } = this.state;
    let programs = [
      {
        name: "Select apc waiver programme",
        id: "",
      },
    ];
    programs = this.props.program && programs.concat(this.props.program);
    let taken_programs = this.props.pub_program_list
      ? _.map(this.props.pub_program_list, "program")
      : [];
    let program_list = _.filter(programs, function (o) {
      return !taken_programs.includes(o.id);
    });
    const steps = [
      {
        title: "APC Programme",
        content: (
          <div
            className="license-body license-body-criterias masud-1"
            style={{ minHeight: 120 }}
          >
            {program_list ? (
              <div className="d-flex flex-column flex-md-row pb-4">
                <div className="float-left"
                  style={{
                    color: "#007ED5",
                    fontSize: 16,
                  }}
                >
                  Select waiver programme from the drop-down list.
                </div>
                <SelectBox
                  className="col-12 col-md-6 col-sm-7 ml-md-3 pl-0"
                  placeholder="Select APC Program to Select Waiver Programme"
                  onChange={this.onChangeHandler}
                  field="program"
                  data={program_list}
                // defaultValue={program_data.program}
                />
              </div>
            ) : (
                <Loading type="flat" />
              )}
          </div>
        ),
      },
      {
        title: "Select Countries",
        content: (
          <div
            className="license-body license-body-criterias masud-2"
            style={{ minHeight: 80 }}
          >
            <div className="form-group row">
              <div
                style={{
                  paddingTop: 40,
                  paddingBottom: 50,
                  color: "#007ED5",
                  fontSize: 15,
                }}
              >
                Click on the + to view, add, edit or remove the countries to be
                included in the waiver program.
              </div>
              {this.props.countries ? (
                <div className="row">
                  <div className="col-sm-10" style={{ position: "relative" }}>
                    <span style={{ paddingTop: 5, display: "inline-block" }}>
                      {this.showSelectItemsLabel("coverage")}
                    </span>
                  </div>
                  <div className="col-sm-2" style={{ marginTop: 10 }}>
                    <button
                      onClick={() => this.onModalSelectBoxShow("coverage")}
                      className="btn btn-info waves-effect waves-light float-right"
                      type="button"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              ) : (
                  <Loading type="flat" />
                )}
              {this.state.isShowCoverageModal && program_data.program && (
                <ModalSelectBox
                  title="Add or remove countries that will be included under this waiver programme"
                  label="Country"
                  placeholder="Countries"
                  header_text="Countries will be included"
                  field="coverage"
                  modalClass="col-md-4"
                  className="col-sm-9"
                  data={this.props.countries}
                  defaultValue={program_data.coverage}
                  isRequired={false}
                  submitBtnTxt="Save"
                  onSubmit={this.onSaveSelectedItems}
                  onClose={() => this.onModalSelectBoxClose()}
                />
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Mail to author",
        content: (
          <div
            className="license-body license-editor"
            style={{ minHeight: 90 }}
          >
            <div
              style={{
                paddingBottom: 40,
                color: "#007ED5",
                fontSize: 15,
                marginTop: 40,
              }}
            >
              System will automatically notify to corresponding authors from
              emerging countries for potential discount eligibility under APC
              Waiver programme. Would you like OaMetrix to automatically notify
              the corresponding authors of their potential eligibility for a
              discount under this APC Waiver programme?
            </div>
            <div
              className="col-sm-6"
              style={{ paddingBottom: 15, paddingTop: 15 }}
            >
              <span style={{ marginRight: 10 }}>
                Would you like to send mail to author ?
              </span>
              <Switch
                defaultChecked={program_data.send_mail_to_author}
                onChange={this.onChange}
              />
            </div>
          </div>
        ),
      },
    ];
    const { current, btn_disabled } = this.state;
    return (
      <HubContent>
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="vat-tax-button">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() =>
                    this.props.history.push("/settings/waiver-policy")
                  }
                />
              </div>
              <div className="waiver-step-form">
                <Steps current={current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                {current > 0 && (
                    <Button
                      type="primary"
                      text="Previous"
                      name="primary"
                      onClick={() => this.prev()}
                    />
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      type="primary"
                      text="Create waiver policy"
                      name="primary"
                      onClick={this.onSubmitHandler}
                    />
                  )}
                  
                  {current < steps.length - 1 && (
                    <Button
                      disabled={btn_disabled}
                      type="primary"
                      text="Next"
                      name="primary"
                      onClick={() => this.next()}
                    />
                  )}
            
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  countries: state.app.countries || false,
  coverage:
    (state.app && state.app.coverage && state.app.coverage.results) || false,
  program:
    (state.program &&
      state.program.program_list &&
      state.program.program_list.results) ||
    false,
  single_program: (state.program && state.program.single_program) || false,
  pub_program_list:
    (state.program &&
      state.program.pub_program_list &&
      state.program.pub_program_list.results) ||
    false,
});

const mapDispatchToProps = (dispatch) => ({
  getProgramListAll: () => dispatch(getProgramListAll()),
  getAllCountry: () => dispatch(getAllCountry()),
  getProgramCoverageAll: (payload) => dispatch(getProgramCoverageAll(payload)),
  getPubProgramSingle: (payload) => dispatch(getPubProgramSingle(payload)),
  getSingleProgram: (payload) => dispatch(getSingleProgram(payload)),
  createPubProgram: (payload) => dispatch(createPubProgram(payload)),
  getPubProgramList: (payload) => dispatch(getPubProgramList(payload)),
});

export const WaiverForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LicenseForm)
);
