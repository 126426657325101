import React from "react";

class ExpandJournalTable extends React.Component {
  state = {};

  setActive = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  render() {
    const { item, column } = this.props;
    return (
      <div className="data-table">
        <div className="row-table">
          <div className="row table-data">
            <div className={column[2]}>
              {item.content_type_name ? item.content_type_name : "-"}
            </div>
            <div className={column[1]}>
              {item.pub_fee_gbp ? item.pub_fee_gbp : "-"}
            </div>

            <div className={column[1]}>
              {item.pub_fee_eur ? item.pub_fee_eur : "-"}
            </div>

            <div className={column[1]}>{item.pub_fee_usd}</div>

            <div className={column[1]}>
              {" "}
              <a
                className="article_open_arrow ss"
                href={`#collapse_${item.id}`}
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls={`collapse_${item.id}`}
                style={{ color: "#5874ab", fontWeight: 500 }}
              >
                <i
                  onClick={this.setActive}
                  className={
                    this.state.active
                      ? "fa fa-chevron-up"
                      : "fa fa-chevron-down"
                  }
                />
              </a>
              {/* </div>
              </div> */}
            </div>
          </div>

          <div
            id={`collapse_${item.id}`}
            className={`collapse ${false && "show"}`}
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div
              className="row table-expand"
              style={{ paddingTop: 10, paddingBottom: 20 }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    {item.pub_fee_gbp ? <label>APC fee gbp:</label> : null}
                    {item.pub_fee_gbp ? <p>{item.pub_fee_gbp}</p> : null}

                    {item.submission_fee_gbp ? (
                      <label>Submission fee gbp:</label>
                    ) : null}
                    {item.submission_fee_gbp ? (
                      <p>{item.submission_fee_gbp}</p>
                    ) : null}

                    {item.colour_charge_gbp ? (
                      <label>Colour charge gbp:</label>
                    ) : null}
                    {item.colour_charge_gbp ? (
                      <p>{item.colour_charge_gbp}</p>
                    ) : null}

                    {item.page_charge_gbp ? (
                      <label>Page charge gbp:</label>
                    ) : null}
                    {item.page_charge_gbp ? (
                      <p>{item.page_charge_gbp}</p>
                    ) : null}
                    {item.other_charge_gbp ? (
                      <label>Other charge gbp:</label>
                    ) : null}
                    {item.other_charge_gbp ? (
                      <p>{item.other_charge_gbp}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    {item.pub_fee_eur ? <label>APC fee eur:</label> : null}
                    {item.pub_fee_eur ? <p>{item.pub_fee_eur}</p> : null}
                    {item.submission_fee_eur ? (
                      <label>Submission fee eur:</label>
                    ) : null}
                    {item.submission_fee_eur ? (
                      <p>{item.submission_fee_eur}</p>
                    ) : null}
                    {item.colour_charge_eur ? (
                      <label>Colour charge eur:</label>
                    ) : null}
                    {item.colour_charge_eur ? (
                      <p>{item.colour_charge_eur}</p>
                    ) : null}
                    {item.page_charge_eur ? (
                      <label>Page charge eur:</label>
                    ) : null}
                    {item.page_charge_eur ? (
                      <p>{item.page_charge_eur}</p>
                    ) : null}
                    {item.other_charge_eur ? (
                      <label>Other charge eur:</label>
                    ) : null}
                    {item.other_charge_eur ? (
                      <p>{item.other_charge_eur}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    {item.pub_fee_usd ? <label>APC fee usd:</label> : null}
                    {item.pub_fee_usd ? <p>{item.pub_fee_usd}</p> : null}
                    {item.submission_fee_usd ? (
                      <label>Submission fee usd:</label>
                    ) : null}
                    {item.submission_fee_usd ? (
                      <p>{item.submission_fee_usd}</p>
                    ) : null}
                    {item.colour_charge_usd ? (
                      <label>Colour charge usd:</label>
                    ) : null}
                    {item.colour_charge_usd ? (
                      <p>{item.colour_charge_usd}</p>
                    ) : null}
                    {item.page_charge_usd ? (
                      <label>Page charge usd:</label>
                    ) : null}
                    {item.page_charge_usd ? (
                      <p>{item.page_charge_usd}</p>
                    ) : null}
                    {item.other_charge_usd ? (
                      <label>Other charge usd:</label>
                    ) : null}
                    {item.other_charge_usd ? (
                      <p>{item.other_charge_usd}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpandJournalTable;
