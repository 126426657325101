import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { HubContent } from "../../../../../core/components";
import { BaseComponent } from "../../../../../core/components/BaseComponent";
import { DataTable } from "../../../../../core/components";
import {
  Loading,
  // SelectBox,
  // InputBox
} from "../../../../../core/components/common";
import { getApcFundList, getApcdownloadFundList } from "../../../../actions";

class ApcFund extends BaseComponent {
  state = {
    data: null
  };

  getApcFundData = async (pageNum = 1, page = 10) => {
    await this.props.getApcFundList({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.apc_fund_list.results,
      count: this.props.apc_fund_list.count
    });
  };

  componentDidMount() {
    this.getApcFundData();
  }

  pageChange = pageNumber => {
    this.getApcFundData(pageNumber);
  };
  onApcDownloadClick = async () => {
    try {
      await this.props.getApcdownloadFundList();
      const url = window.URL.createObjectURL(this.props.apc_fund_download);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `${this.props.group}-apc-fund-list-${moment(d).format(
          "YYYYMMDD_HHmmss"
        )}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  render() {
    const data = [];
    this.state.data &&
      this.state.data.map(item => {
        return data.push({
          id: item.id,
          "Fund source": item.source1_name || item.source2_name,
          Currency: item.currency_name,
          Name: item.name,
          Amount: item.amount,
          Date: item.date
        });
      });

    const heading = ["Fund source", "Name", "Currency", "Amount", "Date"];

    return (
      <HubContent
        title="Apc fund list"
        onArticleDownloadClickHandler={this.onApcDownloadClick}
      >
        <div className="row filter">
          <div className="col-md-8">
            <div className="row">
              {/* <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div> */}
            </div>
          </div>
          {/* <div className="col-md-4">
            <InputBox className="col-md-12" placeholder="Search here... " />
          </div>{" "} */}
          <div className="col-lg-12">
            <div className="card m-b-20">
              {this.state.data ? (
                <DataTable
                  heading={heading}
                  data={data}
                  noAction={true}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  apc_fund_list: state.apcFund.apc_fund_list || false,
  apc_fund_download: state.apcFund.apc_fund_download || false,
  group: (state.auth && state.auth.group) || false
});

const mapDispatchToProps = dispatch => ({
  getApcFundList: payload => dispatch(getApcFundList(payload)),
  getApcdownloadFundList: payload => dispatch(getApcdownloadFundList(payload))
});

export const ApcFundList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApcFund);
