import config, {
  GET_COUNTER_ORG_LIST,
  GET_OADEAL_LIST,
  GET_OADEAL,
  GET_OADEAL_REQUEST_LIST,
  GET_DEAL_TYPE,
  GET_CURRENCY,
  CREATE_OA_DEAL,
  UPDATE_OA_DEAL,
  DELETE_OA_DEAL,
  GET_OA_DEAL_FILTERED_LIST,
  GET_OA_DEAL_APPROVED_LIST,
  GET_OA_DEAL_APPROVAL,
  GET_OA_DEAL_REJECT,
  FAILED_CREATE_OA_DEAL,
  GET_DEAL_INSTITUATION,
  GET_OA_DEAL_CANCEL,
  OA_DEAL_DOWNLOAD,
  GET_OA_DEAL_DOWNLOAD,
  GET_PENDING_OADEAL_REQUEST_COUNT,
  GET_OA_REQUEST_DEAL_SEARCH,
  GET_ALL_OA_DEAL_SEARCH,
  GET_OA_DEAL_SUMMARY,
  SET_LOADING_OA_DEAL,
  REQUEST_OA_DEAL,
  REQUEST_OA_DEAL_SEARCH_LIST,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";
import axios from "axios";
// Loaded into App Reducers
export const getAllCounterOrg = (payload) => ({
  type: GET_COUNTER_ORG_LIST,
  payload,
});

export const getDealType = (payload) => ({
  type: GET_DEAL_TYPE,
  payload,
});

export const oaDealDownloadAction = (payload) => ({
  type: OA_DEAL_DOWNLOAD,
  payload,
});

export const getOaDealList = (payload) => ({
  type: GET_OADEAL_LIST,
  payload,
});

export const getOaDealRequestList = (payload) => ({
  type: GET_OADEAL_REQUEST_LIST,
  payload,
});

export const getOaDeal = (payload) => ({
  type: GET_OADEAL,
  payload,
});

export const getCurrencyList = (payload) => ({
  type: GET_CURRENCY,
  payload,
});

export const createOaDealReducer = (payload) => ({
  type: CREATE_OA_DEAL,
  payload,
});

export const updateOaDealReducer = (payload) => ({
  type: UPDATE_OA_DEAL,
  payload,
});
export const requestUpdateOaDealAction = (payload) => ({
  type: REQUEST_OA_DEAL,
  payload,
});
export const requestOaDealSearchList = () => ({
  type: REQUEST_OA_DEAL_SEARCH_LIST,
});

export const failedCreateOaDealReducer = (payload) => ({
  type: FAILED_CREATE_OA_DEAL,
  payload,
});

export const deleteOaDealReducer = (payload) => ({
  type: DELETE_OA_DEAL,
  payload,
});

export const getPendingOaDealRequestCountReducer = (payload) => ({
  type: GET_PENDING_OADEAL_REQUEST_COUNT,
  payload,
});

export const getOaDealFilterListReducer = (payload) => ({
  type: GET_OA_DEAL_FILTERED_LIST,
  payload,
});

export const getOaDealApprovedListReducer = (payload) => ({
  type: GET_OA_DEAL_APPROVED_LIST,
  payload,
});

export const getOaDealApprovalReducer = (payload) => ({
  type: GET_OA_DEAL_APPROVAL,
  payload,
});

export const getOaDealRejectReducer = (payload) => ({
  type: GET_OA_DEAL_REJECT,
  payload,
});

export const getOaDealCancelReducer = (payload) => ({
  type: GET_OA_DEAL_CANCEL,
  payload,
});

export const oadealDownloadaction = (payload) => ({
  type: GET_OA_DEAL_DOWNLOAD,
  payload,
});
export const getOaDealRequestSearch = (payload) => ({
  type: GET_OA_REQUEST_DEAL_SEARCH,
  payload,
});

export const getAllSearchOaDeal = (payload) => ({
  type: GET_ALL_OA_DEAL_SEARCH,
  payload,
});

// Get OA Deal Summary list
export const getOaDealSummaryAction = (payload) => ({
  type: GET_OA_DEAL_SUMMARY,
  payload,
});
// Get OA Deal Summary list
export const getdealInstituationAction = (payload) => ({
  type: GET_DEAL_INSTITUATION,
  payload,
});

export const SetOaDealLoading = (payload) => ({
  type: SET_LOADING_OA_DEAL,
  payload,
});

// Create OA Deal
export const createOaDeal = (payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .post("oa-deal/", payload)
        .then((res) => {
          dispatch(createOaDealReducer(res.results));
          dispatch(failedCreateOaDealReducer({ success: true }));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};
// Update OA Deal
export const updateOaDeal = (id, payload) => {
  return (dispatch, getState) => {
    dispatch(requestUpdateOaDealAction());

    return new Promise((resolve, reject) => {
      api
        .put(config.endpoint.oa_deal + id + "/", payload)
        .then((res) => {
          dispatch(updateOaDealReducer(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(failedCreateOaDealReducer(error.response.data));
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

// Update OA Deal Request
export const updateOaDealRequest = (id, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .put(config.endpoint.oa_deal_request + id + "/", payload)
        .then((res) => {
          dispatch(updateOaDealReducer(res.results));
          dispatch(failedCreateOaDealReducer({ success: true }));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(failedCreateOaDealReducer(error.response.data));
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

// Get Counter Organisation List
export const getAllCounterOrgList = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get("institution/?fields=id,name&page_size=9999999")
        .then((res) => {
          dispatch(getAllCounterOrg(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {});
    });
  };
};

let cancelToken;
export const dealInstituationList = (searchTerm) => {
  return async (dispatch, getState) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    dispatch(requestOaDealSearchList());

    try {
      const results = await axios.get(
        `${config.baseURL}${config.endpoint.deal_inst}?fields=id,value,is_manager_organisation&q=${searchTerm}&page_size=50`,
        { cancelToken: cancelToken.token } //Pass the cancel token to the current request
      );
      dispatch(getdealInstituationAction(results.data.results));
    } catch (error) {}
  };
};

export const getAllDealType = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(config.endpoint.deal_type)
        .then((res) => {
          dispatch(getDealType(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getAllOaDealList = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `oa-deal/?fields=id,counter_organisation_name,deal_name,discount,expire_date,currency_name&${
            params && params + "&"
          }${filter}`
        )
        .then((res) => {
          dispatch(getOaDealList(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA Deal Request List
export const getAllOaDealRequestList = (payload) => {
  let url = config.endpoint.oa_deal_request;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}?${payload}`)
        .then((res) => {
          dispatch(getOaDealRequestList(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA Deal
export const getSingleOaDeal = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(SetOaDealLoading());
      api
        .get(config.endpoint.oa_deal + id + "/")
        .then((res) => {
          dispatch(getOaDeal(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA Deal Request
export const getSingleOaDealRequest = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(config.endpoint.oa_deal_request + id + "/")
        .then((res) => {
          dispatch(getOaDeal(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getAllOaDealRequestSearchList = (payload) => {
  let url = config.endpoint.oa_deal_request;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}?page=1&page_size=9999999`)
        .then((res) => {
          dispatch(getOaDealRequestSearch(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Currency  List
export const getAllCurrencyList = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("currency/?page_size=9999")
        .then((res) => {
          dispatch(getCurrencyList(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
// Delete OA DEAL
export const deleteOaDeal = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .put(`oa-deal/${id}/mutate/delete/`)
        .then(
          (res) => {
            dispatch(deleteOaDealReducer(res));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Delete OA DEAL
export const deleteOaDealRequest = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .delete(config.endpoint.oa_deal_request + id)
        .then(
          (res) => {
            dispatch(deleteOaDealReducer(res));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Get Pending OA DEAL request count
export const getPendingOaDealRequestCount = (payload = {}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .get(
          config.endpoint.oa_deal_request + "?approval_status=pending&fields=id"
        )
        .then(
          (res) => {
            dispatch(getPendingOaDealRequestCountReducer(res.count));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Get Filterd OA DEAL
export const oADealListPending = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .get("oa-deal/?approval_status=pending")
        .then(
          (res) => {
            dispatch(getOaDealFilterListReducer(res.results));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Get Filterd OA DEAL
export const oADealListApproved = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .get(
          `oa-deal/?approval_status=approved&${params && params + "&"}${filter}`
        )
        .then(
          (res) => {
            dispatch(getOaDealApprovedListReducer(res.results));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Download OaDeals
export const oaDealDownload = (payload) => {
  const url = `${config.endpoint.oa_deal_download}?fields=publisher_name,institution_name,deal_type_name,currency_name,discount,start_date,expire_date,consortia_members&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(oaDealDownloadAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Download OaDeals
export const oaDealRequestDownload = (payload) => {
  const url = `${config.endpoint.oa_deal_request}download?fields=publisher_name,institution_name,deal_type_name,currency_name,discount,start_date,expire_date,consortia_members&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(oaDealDownloadAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Approved OaDeal List
export const oADealListApproval = (ids) => {
  let url = config.endpoint.oa_deal_request;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .put(url + ids + "/approve/")
        .then(
          (res) => {
            dispatch(getOaDealApprovalReducer(res.results));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Reject OaDeal List
export const oADealListReject = (ids) => {
  let url = config.endpoint.oa_deal_request;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .put(url + ids + "/reject/")
        .then(
          (res) => {
            dispatch(getOaDealRejectReducer(res.results));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Cancel OaDeal List
export const oADealListCancel = (ids) => {
  let url = config.endpoint.oa_deal_request;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .put(url + ids + "/cancel-request/")
        .then(
          (res) => {
            dispatch(getOaDealCancelReducer(res.results));
            resolve({ status: true });
          },
          (error) => {
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

export const oadealDownloadlist = () => {
  const url = `${config.endpoint.oa_deal_download}?fields=institution_name,publisher_name,deal_type_name,currency_name,discount,start_date,expire_date&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(oadealDownloadaction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Get OA Deal Summary
export const getOaDealSummary = (orgId, payload = {}) => {
  return (dispatch) => {
    let url = config.endpoint.oa_deal_summary + orgId;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (payload.deal_type) {
      params["deal_type"] = payload.deal_type;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOaDealSummaryAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA Deal
export const filterOaDeal = (payload = {}) => {
  return (dispatch) => {
    let url = config.endpoint.oa_deal;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (payload.counter_organisation) {
      params["counter_organisation"] = payload.counter_organisation;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
