import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { Link } from "react-router-dom";
import {
  getLicencePricingControlConfigList,
  deleteLicencePricingControlConfig,
} from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";

class UL extends BaseComponent {
  state = {
    data: [],
  };

  getLicencePricingControlConfigListData = async (pageNum = 1, page = 10) => {
    await this.props.getLicencePricingControlConfigList({
      pageNum: pageNum,
      pageSize: page /* has_df_only: true, */,
    });

    this.setState({
      data: this.props.licence_pricing_configs.results,
      count: this.props.licence_pricing_configs.count,
    });
  };

  async componentDidMount() {
    this.getLicencePricingControlConfigListData();
  }

  pageChange = (pageNumber) => {
    this.getLicencePricingControlConfigListData(pageNumber);
  };

  onEditLicencePricingControlConfig = (id) => {
    this.props.history.push("/licence-pricing-configs/edit/" + id);
  };

  onDeleteLicencePricingControlConfig = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteLicencePricingControlConfig(id).then((res) => {
              if (res.status) {
                this.props.history.push("/licence-pricing-configs/list");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const heading = ["organisation_name", ];
    let { data, count } = this.state
    console.log(data, count)

    return (
      <HubContent title="Licence pricing configuration list">
        <div className="row">
          <div className="col-12">
            <Link
              to="/licence-pricing-configs/add"
              className="btn btn-primary float-right mb-2"
            >
              Add licence pricing configuration
            </Link>
          </div>
        </div>
        <div className="row filter-md-4">
          <div className="col-12">
            <div className="card m-b-20">
              
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={data || []}
                  onEdit={this.onEditLicencePricingControlConfig}
                  onDelete={this.onDeleteLicencePricingControlConfig}
                  count={count || 0}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  licence_pricing_configs: state.LicencePricingControlConfig.licence_pricing_configs || false,
});

const mapDispatchToProps = (dispatch) => ({
  getLicencePricingControlConfigList: (payload) => dispatch(getLicencePricingControlConfigList(payload)),
  deleteLicencePricingControlConfig: (id) => dispatch(deleteLicencePricingControlConfig(id)),
});

export const LicencePricingControlConfigList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UL);
