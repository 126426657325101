import {
  GET_COUNTER_ORG_LIST,
  GET_DEAL_TYPE,
  GET_OADEAL_LIST,
  GET_OADEAL,
  GET_CURRENCY,
  CREATE_OA_DEAL,
  UPDATE_OA_DEAL,
  SET_LOADING_OA_DEAL,
  GET_OA_DEAL_FILTERED_LIST,
  GET_OA_DEAL_APPROVED_LIST,
  GET_OA_DEAL_APPROVAL,
  GET_OA_DEAL_REJECT,
  GET_OA_DEAL_CANCEL,
  FAILED_CREATE_OA_DEAL,
  GET_CURRENCY_LIST,
  OA_DEAL_DOWNLOAD,
  GET_OA_DEAL_DOWNLOAD,
  GET_OADEAL_REQUEST_LIST,
  GET_PENDING_OADEAL_REQUEST_COUNT,
  GET_OA_REQUEST_DEAL_SEARCH,
  GET_ALL_OA_DEAL_SEARCH,
  GET_OA_DEAL_SUMMARY,
  GET_DEAL_INSTITUATION,
  REQUEST_OA_DEAL_SEARCH_LIST,
  REQUEST_OA_DEAL,
} from "../../config";

const OaDealsReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case GET_COUNTER_ORG_LIST:
      return (state = {
        ...state,
        counter_org: action.payload,
      });
    case GET_DEAL_TYPE:
      return (state = {
        ...state,
        deal_type: action.payload,
      });
    case GET_OADEAL_LIST:
      return (state = {
        ...state,
        oadeal_list: action.payload,
      });
    case GET_OADEAL_REQUEST_LIST:
      return (state = {
        ...state,
        oadeal_request_list: action.payload,
      });
    case GET_PENDING_OADEAL_REQUEST_COUNT:
      return (state = {
        ...state,
        pending_oadeal_request_count: action.payload,
      });
    case SET_LOADING_OA_DEAL:
      return (state = {
        ...state,
        loading: true,
      });

    case GET_OADEAL:
      return (state = {
        ...state,
        loading: false,
        oadeal: action.payload,
      });
    case GET_CURRENCY:
      return (state = {
        ...state,
        oadeal_currency: action.payload,
      });
    case CREATE_OA_DEAL:
      return (state = {
        ...state,
        create_oadeal: action.payload,
      });

    case UPDATE_OA_DEAL:
      return (state = {
        ...state,
        isLoading: false,
        update_oadeal: action.payload,
      });
    case REQUEST_OA_DEAL:
      return (state = {
        ...state,
        isLoading: true,
      });

    case FAILED_CREATE_OA_DEAL:
      return (state = {
        ...state,
        failed_create_oadeal: action.payload,
      });
    case GET_OA_DEAL_FILTERED_LIST:
      return (state = {
        ...state,
        oadeal_pending_list: action.payload,
      });
    case GET_OA_DEAL_APPROVED_LIST:
      return (state = {
        ...state,
        oadeal_approved_list: action.payload,
      });
    case GET_OA_DEAL_APPROVAL:
      return (state = {
        ...state,
        oadeal_approval_success: action.payload,
      });
    case GET_OA_DEAL_REJECT:
      return (state = {
        ...state,
        oadeal_reject_success: action.payload,
      });
    case GET_OA_DEAL_CANCEL:
      return (state = {
        ...state,
        oadeal_cancel: action.payload,
      });
    case GET_CURRENCY_LIST:
      return (state = {
        ...state,
        currency_list: action.payload,
      });
    case OA_DEAL_DOWNLOAD:
      return (state = {
        ...state,
        download_data: action.payload,
      });
    case GET_OA_REQUEST_DEAL_SEARCH:
      return (state = {
        ...state,
        dealrequestSearchList: action.payload,
      });
    case GET_ALL_OA_DEAL_SEARCH:
      return (state = {
        ...state,
        OadealAllSearchList: action.payload,
      });
    case GET_OA_DEAL_DOWNLOAD:
      return (state = {
        ...state,
        oadeal_data_download: action.payload,
      });
    case GET_OA_DEAL_SUMMARY:
      return (state = {
        ...state,
        oadeal_summary: action.payload,
      });
    case REQUEST_OA_DEAL_SEARCH_LIST:
      return (state = {
        ...state,
        isLoading: true,
      });
    case GET_DEAL_INSTITUATION:
      return (state = {
        ...state,
        oaDeal_inst: action.payload,
        isLoading: false,
      });
    default:
      return state;
  }
};
export default OaDealsReducer;
