import React, { Component } from 'react'
import {
 
    RadioButtonGroup,
} from "../../../../../../core/components/common";
import VatTable from "../vatTable";

import MultiSelectCheckBox from "../../../../../../core/components/common/MultiSelectCheckBox";




 class USA extends Component {


    render() {
        return (
            <div>
             <div className="vat-detail-form">
              <p className="eu_vat_para">
                Let's setup VAT/Tax for customers based in the USA.
              </p>

              <div className="vat-question-panel">
                <p>
                  Do you charge Tax/GST to USA customers if they provide a valid
                  VAT/GST/Business Tax Number?
                </p>
                <RadioButtonGroup
                  onChange={this.props.onChangeHandleRadioBox}
                  field="charge_vat_number_usa"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.props.charge_vat_number_usa}
                />
              </div>

              {this.props.charge_vat_number_usa === true && (
                <div className="vat-question-panel">
                  <p>Do you charge Tax/GST based on the States?</p>

                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="charge_vat_states_usa"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.charge_vat_states_usa}
                  />
                </div>
              )}
              {this.props.charge_vat_number_usa !== false
                ? this.props.charge_vat_states_usa === true && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={
                            this.props.state_list 
                          }
                          selectCountry={(props) =>
                            this.props.SelectCheckBoxBasedOnStates_1(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={this.props.vatUsaStatesApiData_1}
                        editHandler={(value) =>
                          this.props.EditHandler_for_us_1(value)
                        }
                        countryType="USA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.props.charge_vat_number_usa !== false
                ? this.props.charge_vat_states_usa === false && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.props.vatforus}
                          selectCountry={(props) =>
                            this.props.SelectCheckBoxBasedOnCountry_1(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={this.props.vatUsaCountryApiData_1}
                        editHandler={(value) =>
                          this.props.EditHandler_country_us_1(value)
                        }
                        countryType="USA"
                      />
                    </div>
                  </div>
                )
                : null}

              {this.props.charge_vat_number_usa !== false ? (
                this.props.charge_vat_states_usa === true ? (
                  <div className="vat-question-panel">
                    <p>
                      Do you charge Tax/GST if USA based customers have no valid
                      Tax/GST number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charage_no_vat_usa"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charage_no_vat_usa}
                    />
                  </div>
                ) : null
              ) : null}
              {this.props.charge_vat_number_usa !== false ? (
                this.props.charge_vat_states_usa === false ? (
                  <div className="vat-question-panel">
                    <p>
                      Do you charge Tax/GST if USA based customers have no valid
                      Tax/GST number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charage_no_vat_usa"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charage_no_vat_usa}
                    />
                  </div>
                ) : null
              ) : null}
              {this.props.charge_vat_number_usa !== false ? (
                this.props.charage_no_vat_usa === true ? (
                  <div className="vat-question-panel">
                    <p>Do you charge Tax/GST based on the States?</p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charge_no_vat_states_usa"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charge_no_vat_states_usa}
                    />
                  </div>
                ) : null
              ) : 
                null}

              {this.props.charge_vat_number_usa !== false
                ? this.props.charage_no_vat_usa !== false
                  ? this.props.charge_no_vat_states_usa === true && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix.
                            You can override default GST/VAT/Tax rate to apply
                            correct one.
                            </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={
                              this.props.state_list 
                            }
                            selectCountry={(props) =>
                              this.props.SelectCheckBoxBasedOnStates_2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.vatUsaStatesApiData_2}
                          editHandler={(value) =>
                            this.props.EditHandler_for_us_4(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  )
                  : null
                : null}
              {this.props.charge_vat_number_usa !== false
                ? this.props.charage_no_vat_usa !== false
                  ? this.props.charge_no_vat_states_usa === false && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix.
                            You can override default GST/VAT/Tax rate to apply
                            correct one.
                            </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.props.vatforus}
                            selectCountry={(props) =>
                              this.props.SelectCheckBoxBasedOnCountry_2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.vatUsaCountryApiData_2}
                          editHandler={(value) =>
                            this.props.EditHandler_country_us_4(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  )
                  : null
                : null}

              {this.props.charge_vat_number_usa === false && (
                <div className="vat-question-panel">
                  <p>
                    Do you charge Tax/GST if USA based customers have no valid
                    Tax/GST number?
                  </p>
                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="charge_no_vat_number_usa_2"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.charge_no_vat_number_usa_2}
                  />
                </div>
              )}
              {this.props.charge_vat_number_usa !== true
                ? this.props.charge_no_vat_number_usa_2 === true && (
                  <div className="vat-question-panel">
                    <p>Do you charge Tax/GST based on the States?</p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charge_no_vat_states_usa_2"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charge_no_vat_states_usa_2}
                    />
                  </div>
                )
                : null}
              {this.props.charge_vat_number_usa !== true ? (
                this.props.charge_no_vat_number_usa_2 === true &&
                  this.props.charge_no_vat_states_usa_2 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={
                              this.props.state_list 
                            }
                            selectCountry={(props) =>
                              this.props.SelectCheckBoxBasedOnStates_4(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.vatUsaStatesApiData_3}
                          editHandler={(value) =>
                            this.props.EditHandler_for_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
              {this.props.charge_vat_number_usa !== true ? (
                this.props.charge_no_vat_number_usa_2 === true &&
                  this.props.charge_no_vat_states_usa_2 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.props.vatforus}
                            selectCountry={(props) =>
                              this.props.SelectCheckBoxBasedOnCountry_4(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.vatUsaCountryApiData_3}
                          editHandler={(value) =>
                            this.props.EditHandler_country_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
            </div>
            </div>
        )
    }
}

export const VatUSA= USA