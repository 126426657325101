import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { ClearStatus, getCountryList,postCountryList,getSingleCountryList } from '../../../../actions';
import { PopupBox, Loading, InputBox, SelectBox } from '../../../../../core/components/common';

class Country extends BaseComponent {

    state = {
        country: {},
    }

    async componentDidMount() {
        await this.props.getCountryList();
        await this.props.getSingleCountryList(this.props.match.params.id)
        this.setState({
            country:this.props.countryData
        })

    }

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.country, value);
        })
    }

    onSubmitHandler = async (e) => {
        e.preventDefault();
        let data = this.state.country;

            await this.props.postCountryList(data);
            this.props.history.push('/country/country-list')
        
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        })
        await this.props.ClearStatus()
    }


    render() {
        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Bad Request"
                msg="Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <HubContent title="Add Country form">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                {/* LoadinG Handling */}
                {this.state.isRegistering && !this.state.status && <Loading />}
                {/* LoadinG Handling */}
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card m-b-20">
                            <div className="card-body">
                        
                                <form>
                                    <div className="row">
                                        <SelectBox
                                        label="Parent"
                                        onChange={this.onChangeHandler}
                                        field='parent'
                                        className="col-sm-4"
                                        labelClass="col-sm-2"
                                        data={this.props.countries} 
                                            isRequired={true}   
                                            defaultValue={this.state.country.name}

                                    />
                                      
                                        <InputBox
                                            label="name"
                                            onChange={this.onChangeHandler}
                                            field='name'
                                            placeholder="name"
                                            defaultValue={this.state.country.name}
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                        />                                        </div>

                                    <div className="form-group row" style={{marginTop:20}} >
                                        <InputBox
                                            label="short name"
                                            onChange={this.onChangeHandler}
                                            field='short_name'
                                            placeholder="short name"
                                            defaultValue={this.state.country.short_name}
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                        />
                      
                                        <InputBox
                                            label="phone code"
                                            onChange={this.onChangeHandler}
                                            field='phone_code'
                                            placeholder="phone code"
                                            className="col-sm-4"
                                        labelClass="col-sm-2"
                                            defaultValue={this.state.country.phone_code}

                                        />
                                    </div>
                                    <div className="form-group row" style={{marginTop:20}} >
                                        <InputBox
                                            label="continent"
                                            onChange={this.onChangeHandler}
                                            field='continent'
                                            placeholder="continent"
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                            defaultValue={this.state.country.continent}

                                        />
                             
                                        <InputBox
                                            label="Region one"
                                            onChange={this.onChangeHandler}
                                            field='region_one'
                                            placeholder="Region one"
                                            defaultValue={this.state.country.region_one}
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                        />
                                    </div>
                                    <div className="form-group row" style={{marginTop:20}} >
                                        <InputBox
                                            label="Region two"
                                            onChange={this.onChangeHandler}
                                            field='region_two'
                                            placeholder="Region two"
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                            defaultValue={this.state.country.region_two}

                                        />
                     
                                        <InputBox
                                            label="Region three"
                                            onChange={this.onChangeHandler}
                                            field='region_three'
                                            placeholder="Region three"
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                            defaultValue={this.state.country.region_three}

                                        />
                                    </div>
                                    <div className="form-group row" style={{marginTop:20}} >
                                        <InputBox
                                            label="Region four"
                                            onChange={this.onChangeHandler}
                                            field='region_four'
                                            placeholder="Region four"
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                            defaultValue={this.state.country.region_four}

                                        />
                                    </div>
                               
                                    {this.props.msg &&
                                        <div className="form-group row">
                                            <span className="alert alert-success col-sm-12">{this.props.msg}</span>
                                        </div>
                                    }

                                    <div className="text-center m-t-15">
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={this.onSubmitHandler}
                                        >Submit</button>
                                    </div>
                                </form>
           
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    failed: state.journals.content_type || false,
    group_top: state.group.parent_group_list || false,
    countries:state.journals.countrylist && state.journals.countrylist.results ,
    countryData:state.journals.countryData,

})

const mapDispatchToProps = (dispatch) => ({
    getSingleCountryList: (payload) => dispatch(getSingleCountryList(payload)),
    getCountryList: () => dispatch(getCountryList()),
    ClearStatus: () => dispatch(ClearStatus()),

    postCountryList: (payload) => dispatch(postCountryList(payload))
})


export const addCountry = connect(mapStateToProps, mapDispatchToProps)(Country);
