import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import ReactHtmlParser from "react-html-parser";
import { Link, withRouter } from "react-router-dom";
import { Alert, Steps, Select, Checkbox, Popover, Tooltip, Button as SubmittedBtn } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

import Autosuggest from "react-autosuggest";
import {
  articlePaymentSummary,
  getLicenseList,
  getCurrency,
  applyTokenAction,
  clearToken,
  articlePayment,
  getLicense,
  articleInvoice,
  articleInvoiceSummary,
  paymentBillingAddress,
  downloadInvoice,
  getAllCountry,
  getPublisherLicense,
  getAllState,
  createAndApplyToken,
  getOrganisationAuthorSuggestions,
  sendInvoiceEmail,
  getPricingLicenceList, 
} from "../../../../actions";
import config from "../../../../../config";
import {
  PopupBox,
  SelectBox,
  Button,
  Loading,
  ModalCustom,
} from "../../../../../core/components/common";
import { InputBox } from "../../../../../core/components/common";
import BaseComponent from "../../../../../core/components";
import PaymentArticle from "./PaymentArticle";

const { Option } = Select;

const { Step } = Steps;
const articlePublisherTerms = config.endpoint.article_publisher_terms;
const articlePublisherLicence = config.endpoint.article_publisher_licence;

const publishable_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
var stripe = require("stripe-client")(publishable_key);
class PaymentFormUI extends BaseComponent {
  state = {
    current: 0,
    cardData: {},
    tokenData: {},
    billing_address: {},
    payment_data: {},
    cardToken: {},
    radio_value: "new_address",
    pay_value: 0,
    apply_token_status: false,
    article_payment_status: false,
    invoice_email_status: false,
    termsandcondition: false,
    stateList: [],
    isOpen: true,
    address_state: null,
    country: null,
    article_payment_summary: false,
    org_vat_number_focus: false,
    existing_address: [
      {
        id: 1,
        street_address1: "House #14/1, Road: Hazi chinu mia road",
        street_address2: "Mohammadpur, Dhaka",
        city: "Dhaka",
        state: "Dhaka",
        postal_code: "1207",
        country: "Bangladesh",
      },
    ],
    token_details: {},
    organisation: "",
    suggestions: [],
  };

  discountCategories = [
    { id: "author_discount", name: "Author discount" },
    { id: "geo_discount", name: "Geographical discount" },
    { id: "institutional_discount", name: "Institutional discount" },
    { id: "society_discount", name: "Society discount" },
    { id: "apc_waiver", name: "APC waiver" },
  ];

  chargeDescriptionOptions = [
    { id: "article_total", name: "All charges" },
    { id: "article_pub_fee", name: "APC" },
    { id: "article_color_charge", name: "Colour charge" },
    { id: "article_submission_fee", name: "Submission fee" },
    { id: "article_page_charge", name: "Page charges" },
    { id: "article_other_charge", name: "Other charges" },
  ];

  discountTypes = [
    { id: "DISCOUNT", name: "Discount (%)" },
    { id: "AMOUNT", name: "Amount" },
  ];

  // data: request data for submit
  // required_fields: array of required field Ex: required_field = ['name', 'number', 'exp_month'];
  // required_field_messages: Object of required field messages Ex: error_message = { name: 'Card holder name is required', number: 'Card number is required', exp_month: 'Expiry month is required'}
  errorMessage = (data, required_fields, required_field_messages) => {
    let error_field = [];
    required_fields.map((value) => {
      if (!(value in data)) {
        error_field.push(required_field_messages[value]);
      }
      return error_field;
    });
    let errorMessage = ReactHtmlParser(error_field.join("<br>"));
    let errorLength = error_field.length;
    if (errorLength > 0) {
      return confirmAlert({
        message: errorMessage,
        buttons: [
          {
            label: "Close",
            onClick: () => { },
          },
        ],
      });
    }
    return errorLength;
  };

  formatCurrency = () => {
    let currencyData = [];
    this.props.currency &&
      this.props.currency.map((value, index) => {
        return currencyData.push({
          id: value.id,
          name: value.short_name,
        });
      });
    return currencyData;
  };

  onChangeCard = async (value) => {
    let keys = Object.keys(value);
    let values = Object.values(value);
    if (keys[0] === "currency") {
      let data = this.formatCurrency();
      let index = data && data.findIndex((x) => x.id === values[0]);
      let currency_code = data[index].name;
      this.setState({
        currency_code: currency_code && currency_code.toLowerCase(),
      });
      this.onArticlePaymentSummary({
        isParam: true,
        currency_code: currency_code && currency_code.toLowerCase(),
      });

    } else if (keys[0] === "price_licence") { 
      this.setState({
        price_licence: value.price_licence,
      });

      this.onArticlePaymentSummary({
        isParam: true,
        currency_code: this.state.currency_code,
        price_licence: value.price_licence,
      });
    }

    let billing_address = [
      "address_line1",
      "address_line2",
      "address_zip",
      "address_city",
      "address_state",
      "address_country",
    ];
    
    let payment_data = ["order_reference_number", "org_vat_number", "licence", "currency",  "price_licence", "remarks"];
    
    if (billing_address.includes(keys[0])) {
      let newVal;
      if (keys[0] === "address_line1") {
        newVal = { street_address1: values[0] };
      } else if (keys[0] === "address_line2") {
        newVal = { street_address2: values[0] };
      } else if (keys[0] === "address_city") {
        newVal = { city: values[0] };
      } else if (keys[0] === "address_state") {
        newVal = { state: values[0] };
      } else if (keys[0] === "address_country") {
        newVal = { country: values[0] };
      } else if (keys[0] === "address_zip") {
        newVal = { postal_code: values[0] };
      }
      this.setState((prevState) => {
        Object.assign(prevState.billing_address, newVal);
        return prevState;
      });
    } else if (payment_data.includes(keys[0])) {
      this.setState((prevState) => {
        Object.assign(prevState.payment_data, value);
        return prevState;
      });
    }

    if (!payment_data.includes(keys[0])) {
      this.setState((prevState) => {
        Object.assign(prevState.cardToken, value);
        return prevState;
      });
    }

    this.setState((prevState) => {
      Object.assign(prevState.cardData, value);
      return prevState;
    });
  };

  errorCount = (data, required_fields, required_field_messages) => {
    let error_field = [];
    required_fields.map((value) => {
      if (!(value in data)) {
        error_field.push(required_field_messages[value]);
      }
      return error_field;
    });
    return error_field.length;
  };


  onGenerateInvoice = async () => {
    let articleId = { article: this.props.match.params.id };
    let data = this.state.cardData;

    let newbillingAddress = {};

    let {
      organisation,
      organisation_id,
      group,
      address_line1,
      address_line2,
      address_city,
      address_zip,
      address_country,
      address_state,
      org_vat_number,
    } = this.state.cardData;

    // if (
    //   organisation !== undefined ||
    //   address_line1 !== undefined ||
    //   address_line2 !== undefined ||
    //   address_city !== undefined ||
    //   address_zip !== undefined ||
    //   address_country !== undefined ||
    //   address_state !== undefined
    // ) {
    newbillingAddress = {
      title: organisation ? organisation : null,
      organisation: organisation_id
        ? organisation_id
        : null,
      title_type: group ? group : null,
      org_vat_number: org_vat_number ? org_vat_number : null,
      street_address1:
        address_line1 !== undefined
          ? address_line1
          : null,
      street_address2:
        address_line2 !== undefined
          ? address_line2
          : null,
      city: address_city !== undefined ? address_city : null,
      postal_code:
        address_zip !== undefined ? address_zip : null,
    };

    if (address_country !== undefined && address_country !== "") {
      newbillingAddress["country"] = address_country;
      if (
        address_state !== undefined &&
        address_state !== "" &&
        address_state
      ) {
        newbillingAddress["state"] = address_state;
      } else {
        newbillingAddress["state"] = null;
      }
    }
    // }

    const addressData = { new_billing_address: newbillingAddress };

    let invoice_data = {
      currency: data.currency,
      order_reference_number: data.order_reference_number,
      licence: data.licence,
      ...articleId,
      ...addressData,
    };

    if (data.price_licence) {
      invoice_data["price_licence"] = data.price_licence;
    }

    if (organisation) {
      invoice_data["organisation"] = organisation;
    }

    if (group) {
      invoice_data["group"] = group;
    }

    if (org_vat_number) {
      invoice_data["org_vat_number"] = org_vat_number;
    }

    let required_fields = ["licence", "currency"];
    let required_field_messages = {
      licence: "Licence is required",
      currency: "Currency is required",
    };
    let errorCount = this.errorCount(
      invoice_data,
      required_fields,
      required_field_messages
    );
    if (errorCount > 0) {
      this.errorMessage(invoice_data, required_fields, required_field_messages);
    } else {
      delete invoice_data.token;

      let articlePaymentSummary = this.state.article_payment_summary;

      invoice_data.is_author_terms_required =
        articlePaymentSummary && articlePaymentSummary.is_author_terms_required;
      invoice_data.is_author_licence_required =
        articlePaymentSummary &&
        articlePaymentSummary.is_author_licence_required;
      
      const transctiondownload = await this.props.articleInvoice(invoice_data);

      if (transctiondownload && transctiondownload.data && transctiondownload.data.id) {
        this.clearPromoTokenHandler()
      }
  
      if (transctiondownload && transctiondownload.data.id) {
        let data = {
          article_payment_status: true,
          success_payment: true,
          invoice_id: transctiondownload.data.id,
          title:
            "Your invoice has been successfully generated",
          msg: "Download your invoice.",
          transctiondownload:
            transctiondownload &&
            transctiondownload.data &&
            transctiondownload.data.invoice_download &&
            transctiondownload.data.invoice_download.link,
          
          file_name:
            transctiondownload &&
            transctiondownload.data &&
            transctiondownload.data.invoice_download &&
            transctiondownload.data.invoice_download.file_name,
        };

        if (this.props.group === 'PUBLISHER') {
          data.invoice_email_status = true;
        }

        this.setState({
          ...data,
        });
      }
    }
  };

  onPayment = async () => {
    let articleId = this.props.match.params.id;
    let data = this.state.cardData;
    let required_fields = [
      "name",
      "number",
      "exp_month",
      "exp_year",
      "currency",
      "cvc",
    ];
    let required_field_messages = {
      name: "Card holder name is required",
      number: "Card number is required",
      exp_month: "Expiry month is required",
      exp_year: "Expiry year name is required",
      cvc: "CVC is required",
    };
    let errorCount = this.errorCount(
      data,
      required_fields,
      required_field_messages
    );
    let newbillingAddress = {};

    let {
      organisation,
      address_line1,
      address_line2,
      address_city,
      address_zip,
      address_country,
      address_state,
    } = this.state.cardData;

    // if (
    //   organisation !== undefined ||
    //   address_line1 !== undefined ||
    //   address_line2 !== undefined ||
    //   address_city !== undefined ||
    //   address_zip !== undefined ||
    //   address_country !== undefined ||
    //   address_state !== undefined
    // ) {

    newbillingAddress = {
      organisation:
        organisation !== undefined ? organisation : null,
      street_address1:
        address_line1 !== undefined
          ? address_line1
          : null,
      street_address2:
        address_line2 !== undefined
          ? address_line2
          : null,
      city: address_city !== undefined ? address_city : null,
      postal_code:
        address_zip !== undefined ? address_zip : null,
    };

    if (address_country !== undefined && address_country !== "") {
      newbillingAddress["country"] = address_country;
      if (
        address_state !== undefined &&
        address_state !== "" &&
        address_state
      ) {
        newbillingAddress["state"] = address_state;
      } else {
        newbillingAddress["state"] = null;
      }
    }
    // }

    const addressData = { new_billing_address: newbillingAddress };

    const mainData = {
      // order_reference_number: data.order_reference_number,
      currency: this.state.payment_data.currency,
      licence: this.state.payment_data.licence,
      ...addressData,
    };

    if (data.price_licence) {
      mainData["price_licence"] = data.price_licence;
    }

    if (errorCount > 0) {
      this.errorMessage(data, required_fields, required_field_messages);
    } else {
      delete this.state.cardToken.token;
      let cardInfo = {
        card: this.state.cardToken,
      };

      await stripe
        .createToken(cardInfo)
        .then((res) => res.json())
        .then(async (res) => {
          if (res.error) {
            confirmAlert({
              message: res.error.message,
              buttons: [
                {
                  label: "Close",
                  onClick: () => { },
                },
              ],
            });
          } else {
            let articlePaymentSummary = this.state.article_payment_summary;
            let token_id = res.id;
            let tokenObj = { article: articleId, stripe_card_token: token_id };
            let submitData = Object.assign(mainData, tokenObj);
            submitData.is_author_terms_required =
              articlePaymentSummary &&
              articlePaymentSummary.is_author_terms_required;
            submitData.is_author_licence_required =
              articlePaymentSummary &&
              articlePaymentSummary.is_author_licence_required;

            let transctiondownload = await this.props.articlePayment(
              submitData
            );

            if (transctiondownload && transctiondownload.data.id) {
              this.setState({
                article_payment_status: true,
                success_payment: true,
                title: "Thank you very much, payment has been successfull.",
                msg: "Click on view to download or view your receipt.",
                transctiondownload:
                  transctiondownload &&
                  transctiondownload.data.receipt_download_info &&
                  transctiondownload.data.receipt_download_info.link,
          
                file_name:
                  transctiondownload &&
                  transctiondownload.data &&
                  transctiondownload.data.receipt_download_info &&
                  transctiondownload.data.receipt_download_info.file_name,
                paymentstatus: true,
              });
            }
          }
        });
    }
  };

  onManualApprovalPayment = async () => {
    let articleId = this.props.match.params.id;
    
    let newbillingAddress = {};

    let {
      organisation,
      address_line1,
      address_line2,
      address_city,
      address_zip,
      address_country,
      address_state,
      price_licence,
    } = this.state.cardData;

    newbillingAddress = {
      organisation:
        organisation !== undefined ? organisation : null,
      street_address1:
        address_line1 !== undefined
          ? address_line1
          : null,
      street_address2:
        address_line2 !== undefined
          ? address_line2
          : null,
      city: address_city !== undefined ? address_city : null,
      postal_code:
        address_zip !== undefined ? address_zip : null,
    };

    if (address_country !== undefined && address_country !== "") {
      newbillingAddress["country"] = address_country;
      if (
        address_state !== undefined &&
        address_state !== "" &&
        address_state
      ) {
        newbillingAddress["state"] = address_state;
      } else {
        newbillingAddress["state"] = null;
      }
    }

    const addressData = { new_billing_address: newbillingAddress };

    const mainData = {
      currency: this.state.payment_data.currency,
      licence: this.state.payment_data.licence,
      ...addressData,
    };
    
    if (price_licence) {
      mainData["price_licence"] = price_licence;
    }

    let articlePaymentSummary = this.state.article_payment_summary;
    let tokenObj = { article: articleId, stripe_card_token: null };
    let submitData = Object.assign(mainData, tokenObj);
    submitData.is_author_terms_required =
      articlePaymentSummary &&
      articlePaymentSummary.is_author_terms_required;
    submitData.is_author_licence_required =
      articlePaymentSummary &&
      articlePaymentSummary.is_author_licence_required;

    let transctiondownload = await this.props.articlePayment(
      submitData
    );

    if (transctiondownload && transctiondownload.data && transctiondownload.data.id) {
      this.clearPromoTokenHandler()
    }
    if (transctiondownload && transctiondownload.data.id) {
      this.setState({
        article_payment_status: true,
        success_payment: true,
        title: "Thank you very much, payment has been successfull.",
        msg: "Click on view to download or view your receipt.",
        transctiondownload:
          transctiondownload &&
          transctiondownload.data.receipt_download_info &&
          transctiondownload.data.receipt_download_info.link,
  
        file_name:
          transctiondownload &&
          transctiondownload.data &&
          transctiondownload.data.receipt_download_info &&
          transctiondownload.data.receipt_download_info.file_name,
        paymentstatus: true,
      });
    }
  };

  async onApplyToken() {
    if (this.state.cardData.token) {
      let payload = {
        token: this.state.cardData.token,
        article: Number(this.props.match.params.id),
        ref_context: "PAYMENT",
      };

      await this.props.applyTokenAction(payload);
      this.state.currency_code
        ? this.onArticlePaymentSummary({
          isParam: true,
          currency_code: this.state.currency_code,
        })
        : this.onArticlePaymentSummary();
      if (this.props.apply_token_status) {
        this.setState({
          apply_token_status: true,
        });
      }
    }
  }

  onChange = (e) => {
    this.setState({
      radio_value: e.target.value,
    });
  };

  onChangeToken = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.tokenData, value);
      return prevState;
    });
  };
  //  payload Ex. {id:id,currency_code:'USD',licence:2,isParam:true}
  onArticlePaymentSummary = async (payload = false) => {
    let articleId = this.props.match.params.id;
    
    if (payload) { 
      if (!payload.hasOwnProperty('price_licence') && this.state.price_licence) {
        Object.assign(payload, { price_licence: this.state.price_licence })
      }
    
      await this.props.articlePaymentSummary(
        Object.assign({ id: articleId }, payload)
      );
      this.setState({
        article_payment_summary: this.props.article_payment_summary
      })

    } else {

      await this.props.articlePaymentSummary({ id: articleId });
    }

    let articlePaymentSummary = this.props.article_payment_summary

    if (articlePaymentSummary && this.state.current === 0) {
      if (articlePaymentSummary.default_currency) {
        let value = {
          currency: articlePaymentSummary.default_currency.id,
        }

        this.setState(prevState => {
          Object.assign(prevState.payment_data, value)
          // Object.assign(prevState.cardToken, value)
          Object.assign(prevState.cardData, value)

          return prevState
        })
      }

      this.setState({
        article_payment_summary: articlePaymentSummary,
        currency_code: articlePaymentSummary.default_currency && articlePaymentSummary.default_currency.currency_code
      });
    } else if (articlePaymentSummary) {
      this.setState({
        article_payment_summary: articlePaymentSummary,
      });
    }

    return articlePaymentSummary
  };


  componentDidMount() {
    this.apiDataList()
  }
  apiDataList = async () => {
    let articleId = this.props.match.params.id;

    this.props.clearToken({});
    this.onArticlePaymentSummary();

    await this.props.paymentBillingAddress();
    await this.props.getLicenseList();
    await this.props.getCurrency();
    await this.props.getAllCountry();
    this.props.getPricingLicenceList({article_id: articleId});
  
      if (this.props.data) {
        this.setState({
          ...this.props.data,
        });
      }
    

    let pubLicense = false;

    pubLicense = await this.props.getLicense();
      this.setState((prevState) => {
        if (pubLicense) prevState.pub_license = pubLicense;

        return prevState;
      });
    }
  
  
  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
        return;
    };
    this.clearPromoToken();
  }

  clearPromoToken = async () => {
    if (
      !this.state.success_payment &&
      this.props.apply_token &&
      this.props.apply_token.success
    ) {
      let payload = {};
      await this.props.clearToken(payload);
    }
  };

  clearPromoTokenHandler = async () => {
   await this.clearPromoToken();

    let { cardData } = this.state;

    Object.assign(cardData, {
      token: '',
    })

    this.setState({
      token_details: {},
      cardData
    });
    let params = {
      currency_code: this.state.currency_code,
    };

    this.onArticlePaymentSummary(params);
    this.setState({
      token_details: {},
      cardData:{token:""}
    });
  };

  onChangeCountryAndState = (value) => {
    this.setState(
      (prevState) => {
        Object.assign(prevState, { showApproveBtn: false });
        Object.assign(prevState.cardData, value);
        return prevState;
      },
      () => {
        let payload = {
          country: value.address_country,
          page_size: 9999,
        };

        if (value.address_country) {
          this.props.getAllState(payload).then((res) => {
            if (res.status) {
              this.setState({
                stateProvince: [{ id: "", name: "All states" }, ...res.results],
              });
            }
          });
        }
      }
    );
  };

  cancelLoader = async () => {
    this.setState({
      apply_token_status: false,
      article_payment_status: false,
      empty_licence_msg: false,
      invoice_email_status: false,
      isEmpty: false,

    });
    if (this.state.success_payment) {
      this.props.history.push(this.props.group === "PUBLISHER" ?  "/article/publisher-articles" : this.props.group === "AUTHOR" ? "/my-article" :  "/articles")

    }
  };
  onClickDownloadInvoice = async (url) => {
    let payload = {
      url: url,
    };

    await this.props.downloadInvoice(payload);

    const urlObj = window.URL.createObjectURL(this.props.invoice_download);
    const link = document.createElement("a");
    link.href = urlObj;
    let fileName = this.state.file_name

    link.setAttribute(
      "download",
      `${fileName}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  onClickDownloadInvoiceforpayment = async (url) => {
    let payload = {
      url: url,
    };

    await this.props.downloadInvoice(payload);

    const urlObj = window.URL.createObjectURL(this.props.invoice_download);
    const link = document.createElement("a");
    link.href = urlObj;
    let fileName = this.state.file_name

    link.setAttribute(
      "download",
      `${fileName}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  licencepdfdownload = async (e, url, name) => {
    e.preventDefault();
    if (!url) {
      this.setState({
        empty_licence_msg: true,
      });
      return;
    }

    let payload = {
      url: url,
    };

    await this.props.licensepdfdownload(payload);

    const urlObj = window.URL.createObjectURL(this.props.licence_download);
    const link = document.createElement("a");
    link.href = urlObj;
    var d = new Date();

    link.setAttribute(
      "download",
      `${name}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  onLicenceChange = (data) => {
    let parsedData = JSON.parse(data);
    let { payment_data } = this.state;

    let url = `${articlePublisherLicence}${this.props.match.params.id}/download/?licence=${parsedData.id}`;

    Object.assign(payment_data, {
      licence: parsedData.id,
    });
    if (parsedData.name) {
      Object.assign(payment_data, {
        licence_name: parsedData.name,
      });
    }

    this.setState({
      cardData: payment_data,
      downloadlink: {
        link: url,
        name: "Licence",
      },
    });
  };

  onChangeChecked = (e) => {
    this.setState({
      enable: e.target.checked,
    });
  };

  async next(maxStepCount = 0) {
    let articlePaymentSummary = null,
      hasEnteredVatNumber = false,
      payload = {};

    if (this.state.current === 1) {
      let { address_country, address_state } = this.state.cardData;
      let country_code = "",
        country_index = "",
        state_index = "",
        state_code = "";

      country_index =
        this.props.countries &&
        this.props.countries.findIndex((x) => x.id === address_country);

      if (country_index >= 0) {
        country_code = this.props.countries[country_index].short_name;
      }

      payload = {
        isParam: true,
        currency_code: this.state.currency_code,
        biladdr_ccode: country_code,
      };

      state_index =
        this.state.stateProvince &&
        this.state.stateProvince.findIndex((x) => x.id === address_state);

      if (state_index >= 0) {
        state_code = this.state.stateProvince[state_index].name;
        payload["biladdr_scode"] = state_code;
      }

      let cardData = this.state.cardData;
      if (cardData && (cardData.organisation || cardData.org_vat_number)) {
        if (cardData.organisation) {
          payload["organisation"] = cardData.organisation;
        }

        if (cardData.organisation_id) {
          payload["org_author_id"] = cardData.organisation_id;
        }

        if (cardData.group) {
          payload["group"] = cardData.group;
        }

        if (cardData.org_vat_number) {
          payload["org_vat_number"] = cardData.org_vat_number;
          hasEnteredVatNumber = true;
        }
      }

      if (!hasEnteredVatNumber) {
        payload["skip_vat"] = true;
      }
      articlePaymentSummary = await this.onArticlePaymentSummary(payload)

      if (articlePaymentSummary) {
        let { cardData } = this.state;

        if (articlePaymentSummary.vat_num_bill_org) {
          Object.assign(cardData, {
            org_vat_number: articlePaymentSummary.vat_num_bill_org,
          });
        }

        this.setState({
          cardData,
          invoice_email_address: articlePaymentSummary.org_author_email,
        });
      }
    }

    let vat_was_skipped = false

    if (payload.skip_vat) { 
      vat_was_skipped = true
      delete payload.skip_vat;
    }

    if (
      articlePaymentSummary &&
      !articlePaymentSummary.has_vat_bill_org &&
      !hasEnteredVatNumber
    ) {

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body vat-number-confirmation">
              <h4 className="vat-invoice-heading">
                 VAT & Tax Information
              </h4>
              <p className="question">
                {this.props.group !=="PUBLISHER" ? "OaMetrix did not find any VAT or Tax number for your account.": "No VAT number available for this customer, would you like to enter it?"}
              </p>
              {
                this.props.group !== "PUBLISHER" ? <>
                  <p className="instruction">
                
                    If you do not have a VAT or Tax number or it does not apply for you, click on "No/Not Applicable" to continue.
              </p>
              <p className="instruction">
                Click on "Yes" to enter your VAT or Tax number.
              </p>
                  </>:
                <p className="instruction">
                
                Please click on “Yes” to add VAT number or “No” to continue.
              </p>
}

           
              <div className="react-confirm-alert-button-group">
                <button
                  className="no-btn"
                  onClick={() => {
                    this.onArticlePaymentSummary(payload);
                    if (this.state.current < maxStepCount - 1) {
                      this.setState({ current: this.state.current + 1 });
                    }
                    
                    if (this.state.current === 1) {
                      this.setState({ showApproveBtn: true });
                    }
                    
                    onClose();
                  }}
                >
                  No/Not Applicable
                </button>
                <button
                  className="yes-btn"
                  onClick={() => {
                    this.setState({
                      org_vat_number_focus: true,
                    });

                    this.setState({ showApproveBtn: false });
                    
                    onClose();
                  }}
                >
                  Yes
                </button>
              </div>
              {  this.props.group !== "PUBLISHER" && <div style={{display:"block",overflow:"hidden"}}>
                      <InfoCircleTwoTone style={{
                          fontSize: 18,
                          marginTop: 30,
                          float: "left",
                          marginLeft: 2,
                          width: "10%",
                      }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                />
                <p style={{
              marginTop: 30,
              fontSize: 11,
              width: "89%",
              float: "right",
              
    textAlign: "left"}}>VAT & Tax applies based on publisher` configuration.Contact the publisher if you have any questions.</p>
                    </div>}

            </div>
          );
        },
      });
    } else {
      Object.assign(payload, {
        isParam: true,
        currency_code: this.state.currency_code,
      })

      let locatioName = (this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.name) || "ARTICLE_PAYMENT"
      if (locatioName === "ARTICLE_PAYMENT") {
        let { address_country } = this.state.cardData;
        let country_code = "",
          country_index = "";
        country_index =
          this.props.countries &&
          this.props.countries.findIndex((x) => x.id === address_country);

        if (country_index >= 0) {
          country_code = this.props.countries[country_index].short_name;
        }

        payload["biladdr_ccode"] = country_code;

      }

      /* This tempory delete because vat Article summery endpoint call two times*/
      // this.onArticlePaymentSummary(payload);

      if (vat_was_skipped) {
        this.onArticlePaymentSummary(payload)
      }
      
      if (this.state.current === 1) {
        this.setState({ showApproveBtn: true });
      }

      if (this.state.current < maxStepCount - 1) {
        this.setState({ current: this.state.current + 1 });
      }
    }
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ 
      current,
      showApproveBtn: false, 
    });
  }


  onChangeStateHandler = async (value) => {
    const parseData = JSON.parse(value);

    const states = {
      address_state: parseData.id,
    };
    this.setState((prevState) => {
      Object.assign(prevState.cardData, states);

      return prevState;
    });
  };
  onChangeCountryHandler = async (value) => {
    const parseData = JSON.parse(value);
    const state = await this.props.getAllState(parseData.id);
    const countries = {
      address_country: parseData.id,
    };
    this.setState((prevState) => {
      Object.assign(prevState.cardData, countries);
      Object.assign(prevState.stateList, state.results);

      return prevState;
    });
  };
  editAddress = () => {
    const existing_address = this.props.existing_billing_address_list;

    let { cardData } = this.state;
    Object.assign(cardData, {
      organisation: existing_address.title,
      organisation_id:
        existing_address.title_type === "AUTHOR"
          ? existing_address.author
          : existing_address.organisation,
      group: existing_address.title_type,
      address_line1: existing_address.street_address1,
      address_city: existing_address.city,
      address_country: existing_address && existing_address.country,
      address_line2: existing_address.street_address2,
      address_state: existing_address.state,
      address_zip: existing_address.postal_code,
      org_vat_number: existing_address.org_vat_number,
    });

    this.setState({
      organisation: existing_address.title,
      cardData: cardData,
      isOpen: !this.state.isOpen,
    });
  };
  onview = async () => {
    await this.onClickDownloadInvoice(`${this.state.transctiondownload}`);
  };
  onviewforpayment = async () => {
    this.onClickDownloadInvoiceforpayment(`${this.state.transctiondownload}`);
  };

  onTokenFormSubmitHandler = async (e) => {
    let articleId = this.props.match.params.id;
    let payload = this.state.token_details;

    payload.currency = this.state.currency_code;
    payload.article_id = articleId;

    if (this.props.apply_token_status) {

      if (!payload.internal_context || !payload.description || !payload.offer_type || !payload.amount) {
        this.setState({
          errMsg: (
            <div>
              {!payload.description && (
                <p>Description cannot be empty</p>
              )}
  
              {!payload.amount && <p>Amount cannot be empty</p>}
            </div>
          ),
        });

      } else {
        await this.props.createAndApplyToken(
          payload,
          this.props.apply_token && this.props.apply_token.id
        );
      }
     
    } else {
      if (!payload.internal_context || !payload.description|| !payload.offer_type || !payload.amount) {
        this.setState({
          errMsg: (
            <div>
              {!payload.description && (
                <p>Description cannot be empty!</p>
              )}
  
              {!payload.amount && <p>Amount cannot be empty</p>}
            </div>
          ),
        });

      } else {

        await this.props.createAndApplyToken(payload);
      }
     
    }

    let { cardData } = this.state;
    Object.assign(cardData, {
      token: this.props.apply_token && this.props.apply_token.token,
    })

    this.setState({
      cardData,
    });

    let params = {
      currency_code: this.state.currency_code,
    };

    this.onArticlePaymentSummary(params);

    this.cancelCreateTokenModalHandler();
  };

  onTokenFormChangeHandler = (value) => {
    let token_details = this.state.token_details;

    Object.assign(token_details, value);

    this.setState({
      token_details,
    });
  };

  createTokenForm = () => {
    let { internal_context, description, offer_type, amount } =
      this.state.token_details || {};

    return (
      <form>
        <div className="form-group row">
          <SelectBox
            label="Category"
            labelClass="col-sm-4"
            className="col-sm-8"
            onChange={this.onTokenFormChangeHandler}
            field="internal_context"
            data={this.discountCategories}
            isRequired={true}
            placeholder="Select category"
            defaultValue={internal_context}
          />
        </div>
        <div className="form-group row">
          <SelectBox
            label="Description"
            labelClass="col-sm-4"
            className="col-sm-8"
            onChange={this.onTokenFormChangeHandler}
            field="description"
            data={this.chargeDescriptionOptions}
            isRequired={true}
            placeholder="Select description"
            defaultValue={description}
          />
        </div>
        <div className="form-group row">
          <SelectBox
            label="Discount/Amount"
            labelClass="col-sm-4"
            className="col-sm-8"
            onChange={this.onTokenFormChangeHandler}
            field="offer_type"
            placeholder="Discount (%) or amount"
            data={this.discountTypes}
            isRequired={true}
            defaultValue={offer_type}
          />
        </div>

        <div className="form-group row">
          <InputBox
            label={this.state.token_details.offer_type === "DISCOUNT"
            ? "Discount"
            : "Amount"}
            labelClass="col-sm-4"
            onChange={this.onTokenFormChangeHandler}
            field="amount"
            placeholder={
              this.state.token_details.offer_type === "DISCOUNT"
                ? "Discount"
                : "Amount"
            }
            isRequired={true}
            className="col-sm-8"
            defaultValue={amount}
          />
        </div>
      </form>
    );
  };

  showCreateTokenModalHandler = (e) => {
    this.setState({
      showCreateTokenModal: true,
    });
  };

  cancelCreateTokenModalHandler = () => {
    this.setState({
      showCreateTokenModal: false,
    });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => {
    let billing_address = this.state.billing_address;

    Object.assign(billing_address, {
      organisation: suggestion.name,
      organisation_id: suggestion.id,
      group: suggestion.group,
    });

    let cardData = this.state.cardData;

    Object.assign(cardData, {
      organisation: suggestion.name,
      organisation_id: suggestion.id,
      group: suggestion.group,
    });

    this.setState({
      billing_address: billing_address,
      cardData: cardData,
    });

    return suggestion.name;
  };

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => <div>{suggestion.label || ""}</div>;

  onOrganisationAuthorChangeHandler = (event, { newValue }) => {
    this.setState({
      organisation: newValue || "",
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }) => {
    if (this.props.group === "PUBLISHER") {
      let payload = {
        q: value,
      };

      let response = await this.props.getOrganisationAuthorSuggestions(payload);

      this.setState({
        suggestions: (response && response.data) || [],
      });
    }

    let billing_address = this.state.billing_address;
    Object.assign(billing_address, {
      organisation: value || "",
      organisation_id: null,
      group: null,
    });

    let cardData = this.state.cardData;
    Object.assign(cardData, {
      organisation: value || "",
      organisation_id: null,
      group: null,
    });

    this.setState({
      billing_address: billing_address,
      cardData: cardData,
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  sendInvoiceEmail = (data = {}) => {
    if (this.state.invoice_id) {
      data.invoice_id = this.state.invoice_id;
      return this.props.sendInvoiceEmail(data).then(response => {
        return response.data && response.data.success;
      }).catch(error => {
        return error.response && error.response.data && error.response.data.success;
      });
    }

  }

  render() {
    const {
      current,
      currency_code,
      enable,
      organisation,
      suggestions,
      cardData,
      showApproveBtn,
    } = this.state;
    let PaymentArticleSummary = this.state.article_payment_summary;
    let locationName = (this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.name) || "ARTICLE_INVOICE"
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Type your org or author [Required]",
      value: organisation || "",
      onChange: this.onOrganisationAuthorChangeHandler,
      className: "col-sm-12",
    };

    let disabledButton = true;

    if (current === 0) {
      disabledButton =
        currency_code &&
        currency_code.length > 0 &&
        (enable === true ||
          this.props.group === "PUBLISHER" ||
          (PaymentArticleSummary &&
            !PaymentArticleSummary.is_author_terms_required));
    } else if (current === 1) {
      disabledButton = cardData.address_line1 && cardData.address_country
    }

    let errorHander = (
      <>
        {this.state.apply_token_status && (
          <PopupBox
            title="Success"
            msg="Token has Applied successfully"
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.article_payment_status && (
          <PopupBox
            className="payment-alert"
            title={this.state.title}
            msg={this.state.msg}
            invoice_email_status={this.state.invoice_email_status}
            invoice_email_address={this.state.invoice_email_address}
            sendInvoiceEmail={this.sendInvoiceEmail}
            view={
              this.state.paymentstatus ? this.onviewforpayment : this.onview
            }
            cancel="payment"
          />
        )}

        {this.state.empty_licence_msg && (
          <PopupBox
            msg="Please select a licence."
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    const steps = [
      {
        title: "Payment",
        content: (
          <div className="Payment-article-input">
            {PaymentArticleSummary && PaymentArticleSummary.currency_selection_info && 
              <div className="row">
                <div className="col-md-10 col-sm-6">
                  {PaymentArticleSummary.currency_selection_info.show_currency_instruction && <Alert
                    message={PaymentArticleSummary.currency_selection_info.currency_instruction}
                    type="warning"
                    showIcon
                  />}
                  {PaymentArticleSummary.currency_selection_info.disclaimer_note && <Alert
                    message={PaymentArticleSummary.currency_selection_info.disclaimer_note}
                    type="warning"
                    showIcon
                  />}
                </div>
              </div>}

            <div className="row">

              <div className="col-md-8 col-sm-6 m-20">
                {currency_code && <SelectBox
                  className="col-md-12"
                  onChange={(value) => this.onChangeCard(value)}
                  field="currency"
                  data={this.formatCurrency()}
                  defaultValue={
                    currency_code.toUpperCase()
                  }
                />}

                {!currency_code && <SelectBox
                  className="col-md-12"
                  onChange={(value) => this.onChangeCard(value)}
                  field="currency"
                  data={this.formatCurrency()}
                  defaultValue="Select currency"
                />}
              </div>

              { PaymentArticleSummary && PaymentArticleSummary.licence_pricing_config && PaymentArticleSummary.licence_pricing_config.show_licence_option && <div className="col-md-8 col-sm-6 m-20">
                <div className="row">
                  <div className="col-md-2">
                    <Tooltip title="The price might be changed based on the selected licence.">
                      <InfoCircleTwoTone style={{
                        fontSize: 18,
                        marginTop: 12,
                      }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                      />
                    </Tooltip>

                  </div>

                  <div className="col-md-10">
                    <SelectBox
                      className="col-md-12"
                      onChange={(value) => this.onChangeCard(value)}
                      field="price_licence"
                      data={(this.props.pricing_licence && [{id: '', name: 'Select licence'}, ...this.props.pricing_licence]) || []}
                      defaultValue={this.state.price_licence || ''}
                    />
                  </div>
                </div>
              </div> }

              <div className="col-md-8 col-sm-6 mt-4">
                <div className="row">
                  <div className="col-md-2">
                    <Tooltip title="Enter an order reference number for your invoice. Order reference can be any, perhaps you can use the manuscript number and your surname as a reference.">
                      <InfoCircleTwoTone style={{
                        fontSize: 18,
                        marginTop: 12,
                      }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                      />
                    </Tooltip>

                  </div>

                  <div className="col-md-10">


                    <InputBox
                      className="col-md-12"
                      onChange={(value) => this.onChangeCard(value)}
                      field="order_reference_number"
                      placeholder="Enter order reference number"
                      defaultValue={
                        this.state.order_reference_number
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-sm-6 margin-top">
                <div className="row">
                  <div className="col-md-2">
                    <Tooltip title={`If you have been provided a token by the ${PaymentArticleSummary && PaymentArticleSummary.publisher_name}, please enter it here. If you do not have a token, please leave this section blank`}>

                      <InfoCircleTwoTone style={{
                        fontSize: 18,
                        marginTop: 12,
                      }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                      />
                    </Tooltip>

                  </div>

                  <div className="col-md-10">


                    <InputBox
                      className="col-md-12"
                      onChange={this.onChangeCard}
                      field="token"
                      placeholder="Enter token"
                     defaultValue={ this.state.cardData.token || (this.props.apply_token && this.props.apply_token.token) }
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-3  col-sm-6 margin-top ">
                <Button
                  text="Apply"
                  type="button"
                  name="primary"
                  disabled={this.props.apply_token_status}
                  onClick={() => {
                    return this.onApplyToken();
                  }}
                />
              </div>

              {this.props.group === "PUBLISHER" && (
                <>
                  <div className="col-md-8 col-sm-12  mt-2 text-center">OR</div>
                  <div className="col-md-8 col-sm-12  mt-2 text-center" style={{marginBottom:15}}>
                    {!this.props.apply_token_status && (
                      <Button
                        text="Create token"
                        type="button"
                        name="primary"
                        className="open-token-form"
                        disabled={
                          !this.state.currency_code ||
                          this.props.apply_token_status
                        }
                        onClick={this.showCreateTokenModalHandler}
                      />
                    )}
                    {this.props.apply_token_status && (
                      <>
                        <Button
                          text="Update token"
                          type="button"
                          name="primary"
                          className="open-token-form mr-1"
                          onClick={this.showCreateTokenModalHandler}
                        />
                        <Button
                          text="Clear token"
                          type="button"
                          name="primary"
                          className="clear-token"
                          onClick={this.clearPromoTokenHandler}
                        />
                      </>
                    )}
                  </div>
                </>
              )}

              {this.props.group &&
                this.props.group === "AUTHOR" &&
                PaymentArticleSummary &&
                PaymentArticleSummary.is_author_licence_required && (
                  <div className="col-md-8 margin-top">
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select licence"
                      optionFilterProp="children"
                      onChange={this.onLicenceChange}
                      defaultValue={
                        this.state.payment_data.licence_name || "Select licence"
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.pub_license &&
                        this.state.pub_license.map((item) => (
                          <Option value={JSON.stringify(item)} key={item.name}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                )}


              {this.props.group &&
                this.props.group === "AUTHOR" &&
                PaymentArticleSummary &&
                PaymentArticleSummary.is_author_licence_required && (
                  <div className="col-md-3 col-sm-6  margin-top ">
                    <Button
                      text="View"
                      type="primary"
                      name="primary"
                      onClick={(e) =>
                        this.licencepdfdownload(
                          e,
                          this.state.downloadlink &&
                          this.state.downloadlink.link,
                          "License"
                        )
                      }
                    />
                  </div>
                )}

              {this.props.group !== "PUBLISHER" &&
                PaymentArticleSummary &&
                PaymentArticleSummary.is_author_terms_required && (
                  <div className="col-md-12 col-sm-12  margin-top" style={{ marginBottom: 10 }}>
                    <Checkbox
                      onChange={this.onChangeChecked}
                      checked={this.state.enable}
                    />
                    <p className="publisher_terms">I agree with </p>
                    <Link
                      to="#"
                      onClick={(e) =>
                        this.licencepdfdownload(
                          e,

                          `${articlePublisherTerms}${this.props.match.params.id}/download/`,
                          "TermsandCondition"
                        )
                      }
                    >
                      Publisher’s Terms & Conditions
                    </Link>
                  </div>
                )}
            </div>
          </div >
        ),
      },
      {
        title: "Billing Address",
        content: (
          <div className="Payment-article-address">
            <div className="row">

              <div className="payment">
                <div className="col-sm-12 margin-bottom custom-dropdown-container">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    theme={{
                      suggestionsContainer: "custom-options",
                    }}
                  />
                </div>
                <div className="col-md-12 margin-bottom">
                  <InputBox
                    className="col-sm-12 "
                    onChange={(value) => this.onChangeCard(value)}
                    field="address_line1"
                    placeholder="Enter your Address line 1 [Required] "
                    defaultValue={this.state.cardData.address_line1}
                  />
                </div>

                <div className="col-md-12 margin-bottom">
                  <label style={{ display: "none" }}></label>
                  <InputBox
                    className="col-sm-12"
                    onChange={(value) => this.onChangeCard(value)}
                    field="address_line2"
                    placeholder="Enter your Address line 2 [Optional] "
                    defaultValue={this.state.cardData.address_line2}
                  />
                </div>
                <div className="col-md-6 margin-bottom">
                  <InputBox
                    className="col-md-12"
                    onChange={(value) => this.onChangeCard(value)}
                    field="address_city"
                    placeholder="Enter your city [Optional] "
                    defaultValue={this.state.cardData.address_city}
                  />
                </div>
                <div className="col-md-6 margin-bottom">
                  <InputBox
                    className="col-md-12"
                    onChange={(value) => this.onChangeCard(value)}
                    field="address_zip"
                    placeholder="Enter your post code [Optional]"
                    defaultValue={this.state.cardData.address_zip}
                  />
                </div>
                <div className="col-md-6 margin-bottom">
                  <SelectBox
                    onChange={this.onChangeCountryAndState}
                    field="address_country"
                    placeholder="Enter your country [Required]"
                    isRequired={true}
                    className="col-md-12"
                    defaultValue={
                      this.state.cardData &&
                      this.state.cardData.address_country
                    }
                    data={this.props.countries}
                  />
                </div>
                <div className="col-md-6 margin-bottom">
                  <SelectBox
                    onChange={this.onChangeCountryAndState}
                    field="address_state"
                    placeholder="Enter your State/province [Optional]"
                    className="col-md-12"
                    data={this.state.stateProvince || []}
                  />
                </div>
                <div
                  className="col-md-12 margin-bottom"
                  style={{ clear: "both" }}
                >
                  <InputBox
                    className={`col-sm-12`}
                    inputClass={`${this.state.org_vat_number_focus
                      ? "org_vat_number_focus"
                      : ""
                      }`}
                    onChange={(value) => this.onChangeCard(value)}
                    field="org_vat_number"
                    placeholder="Enter your VAT number"
                    autoFocus={this.state.org_vat_number_focus || false}
                    onBlur={() =>
                      this.setState({ org_vat_number_focus: false })
                    }
                    defaultValue={this.state.cardData.org_vat_number}
                  />
                </div>
              </div>

              {
                locationName === 'MANUAL_APPROVAL_PAYMENT' && (
                  <div className="invoice-btn" style={{marginLeft:100}}>
                  
                    {current > 0 && (
                      <Button
                        text="Previous "
                        type="secondary"
                        name="secondary"
                        onClick={() => this.prev()}
                      />
                    )}

                    { !showApproveBtn && <Button
                      text="Next"
                      className={`btn btn-primary btn-md waves-effect waves-light text-center${!disabledButton || (false && "disabled")
                        }`}
                      disabled={!disabledButton}
                      type="primary"
                      name="primary"
                      onClick={() => this.next(steps.length)}
                    />}

                    {showApproveBtn && <SubmittedBtn 
                      type="primary" 
                      loading={this.props.isPaymentRequestLoading} 
                      onClick={() => this.onManualApprovalPayment()}
                      className={`btn btn-primary btn-md waves-effect waves-light text-center${!disabledButton || (false && "disabled")
                        }`}
                      disabled={!disabledButton}
                    >
                      {this.props.isPaymentRequestLoading !== true ? "Approve Manually" : "Processing"}
                    </SubmittedBtn>}
                  </div>
                )
              }

            </div>
          </div>
        ),
      },
    ];

    if (locationName !== 'MANUAL_APPROVAL_PAYMENT') {
      steps.push({
        title: locationName === "ARTICLE_INVOICE" ? "Invoice" : "Pay by Card",
        content: (
          <div className="Payment-article-address pay-card">
            {locationName === "ARTICLE_INVOICE" ?
              <div className="invoice-btn" style={{marginTop:140}}>

                {current > 0 && (
                  <Button
                    text="Previous "
                    type="secondary"
                    name="secondary"
                    onClick={() => this.prev()}
                  />
                )}
                <SubmittedBtn type="primary" loading={this.props.isPaymentRequestLoading}  onClick={() => this.onGenerateInvoice()}>
                  {this.props.isPaymentRequestLoading !== true ? "Generate Invoice " : "Processing"}
                </SubmittedBtn>
              </div>
              :
              <div className="row">
                <ul className="resp-tabs-list">
                  <li
                    className="resp-tab-item"
                    aria-controls="tab_item-1"
                    role="tab"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/visa.png"
                      alt="text"
                    />
                  </li>
                  <li
                    className="resp-tab-item"
                    aria-controls="tab_item-1"
                    role="tab"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/mastercard-logo.png"
                      alt="text"
                    />
                  </li>
                </ul>
                <div className="col-md-12 margin-bottom">
                  <InputBox
                    className="col-sm-12 "
                    onChange={(value) => this.onChangeCard(value)}
                    field="number"
                    placeholder="Card number"
                    defaultValue={this.state.cardData.number}
                  />
                </div>
                <div className="col-md-12 margin-bottom">
                  <InputBox
                    className="col-sm-12"
                    onChange={(value) => this.onChangeCard(value)}
                    field="name"
                    placeholder="Card holder name"
                    defaultValue={this.state.cardData.name}
                  />
                </div>
                <div className="col-md-4 margin-bottom">
                  <InputBox
                    className="col-md-12"
                    onChange={(value) => this.onChangeCard(value)}
                    field="exp_month"
                    placeholder="Month "
                    defaultValue={this.state.cardData.exp_month}
                  />
                </div>
                <div className="col-md-4 margin-bottom">
                  <InputBox
                    className="col-md-12"
                    onChange={(value) => this.onChangeCard(value)}
                    field="exp_year"
                    placeholder="Year"
                    defaultValue={this.state.cardData.exp_year}
                  />
                </div>
                <div className="col-md-4 margin-bottom">
                  <InputBox
                    className="col-md-12"
                    onChange={(value) => this.onChangeCard(value)}
                    field="cvc"
                    placeholder="CVC "
                    defaultValue={this.state.cardData.cvc}
                  />
                </div>{" "}
                <div className="invoice-btn" style={{marginLeft:100}}>
                
                  {current > 0 && (
                    <Button
                      text="Previous "
                      type="secondary"
                      name="secondary"
                      onClick={() => this.prev()}
                    />
                  )}

                  <SubmittedBtn type="primary" loading={this.props.isPaymentRequestLoading}  onClick={() => this.onPayment()}>
                    {this.props.isPaymentRequestLoading !== true ? "Pay" : "Processing"}
                  </SubmittedBtn>
                </div>

              </div>}
          </div>
        )
      });
    }

    const content = (
      <div>
        <a target="_blank" rel="noopener noreferrer" href="https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Generate-Self-Service-Invoice.pdf">
          <span><i className="fa fa-angle-right"></i></span> Generate Self-Service Invoice</a>
        <br />
        <a target="_blank" rel="noopener noreferrer" href="https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Pay-APC-Online.pdf"><span><i className="fa fa-angle-right"></i></span> Pay APC Online </a>
      </div>
    );
    return (
      <div className="row">
        <div className="col-lg-12 ">
    
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}

          {this.state.showCreateTokenModal && (
            <ModalCustom
              title="Create token"
              submitBtnTxt="Submit"
              onClose={this.cancelCreateTokenModalHandler}
              onSubmit={this.onTokenFormSubmitHandler}
              modalClass="col-sm-6"
              isShow={this.state.showCreateTokenModal}
            >
              {this.createTokenForm()}
            </ModalCustom>
          )}
          {/* Error Handler */}
          {errorHander}
          {/* Error Handler */}
          <div className="card m-b-20 settings-card vat-card payment-home">
            {/* <div className="card m-b-20"> */}
            <div className="card-body  profile">

              <div className="vat-tax-button">
                <div className="help-menu-btn">
                 {
                   this.props.group !=="INSTITUTION" ?  <Button
                   type="danger"
                   name="danger"
                   text="X"
                   onClick={() => this.props.group === "INSTITUTION"? this.props.history.goBack() : this.props.history.push(this.props.group === "PUBLISHER" ?  "/article/publisher-articles" : this.props.group === "AUTHOR" ? "/my-article" :  "/articles")}
                   />:null
                 }
                  <Popover placement="leftTop" content={content} trigger="click">

                    <Button
                      type="primary"
                      name="primary"
                      text="Help"
                      className="help-menu"

                    > Help
                </Button>
                  </Popover>




                </div>

              </div>
              <div className="row step-row">
                <div className="col-md-7">
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  
                </div>
                <div className="col-md-5">
              
                  <h3 className="title-heading">

                    {PaymentArticleSummary && `Article Info`}{" "}
                  </h3>
               
                </div>
                
              </div>
              <div className="steps-content payment-home">
                <div className="row">
                  <div className="col-md-6 payment-left">
                    {steps[current] && steps[current].content}
                    <div className="steps-button" >
                  <div className="payment-button">

                    {current !== 0 ? current < steps.length - 1 && (
                      <Button
                        text="Previous "
                        type="secondary"
                        name="secondary"
                        onClick={() => this.prev()}
                      />
                    ):null}
                    {
                          current < steps.length - 1 && (
                        <Button
                          text="Next"
                          className={`btn btn-primary btn-md waves-effect waves-light text-center${!disabledButton || (false && "disabled")
                            }`}
                          disabled={!disabledButton}
                          type="primary"
                          name="primary"
                          onClick={() => this.next(steps.length)}
                        />
                      )
                    }

                </div>
              </div>
                  </div>

                  {this.props.isPaymentLoading !== true ? (
                    <PaymentArticle data={PaymentArticleSummary} id={this.props.match.params.id} />
                  ) : (
                    <div style={{ paddingLeft: "170px" }}>
                      <Loading type="flat" />
                    </div>
                  )}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  group: (state.auth && state.auth.group),
  isPaymentLoading: (state.payment && state.payment.isPaymentLoading),

  currency:
    (state.OaDeals &&
      state.OaDeals.currency_list &&
      state.OaDeals.currency_list.results)
  ,
  licence:
    (state.articles &&
      state.articles.licence_list &&
      state.articles.licence_list.results),
  pricing_licence:
    (state.licence &&
      state.licence.pricing_licence &&
      state.licence.pricing_licence.results),
  article_payment_summary:
    (state.payment && state.payment.article_payment_summary),
  existing_billing_address:
    (state.payment &&
      state.payment.article_payment_summary &&
      state.payment.article_payment_summary.last_billing_address &&
      state.payment.article_payment_summary.last_billing_address
        .address_as_narration),
  existing_billing_address_list:
    (state.payment &&
      state.payment.article_payment_summary &&
      state.payment.article_payment_summary.last_billing_address),
  invoice_billing_address:
    (state.payment &&
      state.payment.article_invoice_summary &&
      state.payment.article_invoice_summary.last_billing_address)
  ,
  isPaymentRequestLoading: state.payment.isPaymentRequestLoading,
  article_payment:
    (state.payment &&
      state.payment.article_payment &&
      state.payment.article_payment.data),
  article_payment_status:
    (state.payment &&
      state.payment.article_payment &&
      state.payment.article_payment.data &&
      state.payment.article_payment.data.success)
  ,

  billing_address:
    (state.payment &&
      state.payment.payment_billing_address &&
      state.payment.payment_billing_address.results),
  apply_token:
    (state.oaToken &&
      state.oaToken.apply_token &&
      state.oaToken.apply_token.data),
  apply_token_status:
    (state.oaToken &&
      state.oaToken.apply_token &&
      state.oaToken.apply_token.status)
  ,
  countries: state.app.countries,
  licence_download: (state.payment && state.payment.invoice_download),
  invoice_download: (state.payment && state.payment.invoice_download),
});
const mapDispatchToProps = (dispatch) => ({
  articleInvoice: (payload) => dispatch(articleInvoice(payload)),
  articleInvoiceSummary: (payload) => dispatch(articleInvoiceSummary(payload)),
  articlePaymentSummary: (payload) => dispatch(articlePaymentSummary(payload)),
  articlePayment: (payload) => dispatch(articlePayment(payload)),
  getPublisherLicense: (payload) => dispatch(getPublisherLicense(payload)),
  getLicenseList: () => dispatch(getLicenseList()),
  getPricingLicenceList: (payload) => dispatch(getPricingLicenceList(payload)),
  getCurrency: () => dispatch(getCurrency()),
  paymentBillingAddress: () => dispatch(paymentBillingAddress()),
  applyTokenAction: (payload) => dispatch(applyTokenAction(payload)),
  createAndApplyToken: (payload, id) =>
    dispatch(createAndApplyToken(payload, id)),
  getOrganisationAuthorSuggestions: (payload) =>
    dispatch(getOrganisationAuthorSuggestions(payload)),
  clearToken: (payload) => dispatch(clearToken(payload)),
  licensepdfdownload: (payload = {}) => dispatch(downloadInvoice(payload)),
  downloadInvoice: (payload = {}) => dispatch(downloadInvoice(payload)),
  sendInvoiceEmail: (payload = {}) => dispatch(sendInvoiceEmail(payload)),
  getAllCountry: () => dispatch(getAllCountry()),
  getLicense: () => dispatch(getLicense()),
  getAllState: (payload) => dispatch(getAllState(payload)),
});

export const PaymentHome = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentFormUI)
);