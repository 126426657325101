import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { ClearStatus, UpdateUser, getSingleUser, deleteUser, getAllUser } from '../../../../actions';

class User extends BaseComponent {
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        isEmpty: false,
        deleteConfirm: false,
        data: [],
        deleteStatus: null,
        count: null
    }

    getUserData = async (pageNum = 1, page = 10) => {
        await this.props.getAllUser({ pageNum: pageNum, pageSize: page });
        this.setState({
            data: this.props.users.results,
            count: this.props.users.count
        })
    }

    async componentDidMount() {
        let user;
        const userId = this.props.match.params.id;
        user = await this.props.getUser(userId);
        if (!user) {
            user = await this.props.getSingleUser(userId);

        }

        this.setState({
            user
        })
        await this.getUserData();

    }
    render() {
        let user = this.state.user && this.state.user;

        let {
            id,
            salute,
            first_name,
            middle_name,
            last_name,
            email,
            username,
            user_meta,
            addresses,
            role_name,
            unique_id,


        } = user
        return (
            <HubContent title={`${first_name} ${last_name}`}>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body row viewuser">
                                <div className="col-sm-6">
                                    <table style={styles.table} >{/******** User Info *******/}
                                        <tbody>
                                            <tr>
                                                <th className="w-100 mb-5"><h6>User Information</h6></th>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Salutation</td>
                                                <td style={{ textAlign: 'left' }}>{salute}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>First Name</td>
                                                <td style={{ textAlign: 'left' }}>{first_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Middle Name</td>
                                                <td style={{ textAlign: 'left' }}>{middle_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Last Name</td>
                                                <td style={{ textAlign: 'left' }}>{last_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Username</td>
                                                <td style={{ textAlign: 'left' }}>{username}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Email</td>
                                                <td style={{ textAlign: 'left' }}>{email}</td>
                                            </tr>


                                            <tr>
                                                <td style={{ width: '30%' }}>Role Name</td>
                                                <td style={{ textAlign: 'left' }}>{role_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Unique Id</td>
                                                <td style={{ textAlign: 'left' }}>{unique_id}</td>
                                            </tr>


                                            <tr>
                                                <td style={{ width: '30%' }}>Orcid Id</td>
                                                <td style={{ textAlign: 'left' }}>{user_meta && user_meta.orcid_id}</td>
                                            </tr>

                                            <tr>
                                                <td style={{ width: '30%' }}>Phone</td>
                                                <td style={{ textAlign: 'left' }}>{user_meta && user_meta.phone}</td>
                                            </tr>



                                        </tbody>
                                    </table>
                                </div>



                                <div className="col-sm-6"> {/******** User Address Information *******/}
                                    {addresses && addresses.map((value, key) => {
                                        return (
                                            <table style={styles.table}>
                                                <tbody key={addresses.id}>
                                                    <tr>
                                                        <td ><h6>Address # {key + 1}</h6></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Title</td>
                                                        <td style={{ textAlign: 'left' }}>{value.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Address1</td>
                                                        <td style={{ textAlign: 'left' }}>{value.address1}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Address2</td>
                                                        <td style={{ textAlign: 'left' }}>{value.address2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Country</td>
                                                        <td style={{ textAlign: 'left' }}>{value.country_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>City</td>
                                                        <td style={{ textAlign: 'left' }}>{value.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>State</td>
                                                        <td style={{ textAlign: 'left' }}>{value.state}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Postal Code</td>
                                                        <td style={{ textAlign: 'left' }}>{value.postal_code}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    })}
                                </div>

                                <div className="col-sm-12 text-right">
                                    <ul className="userProfile">
                                        <li className="color1"><Link to={`/user/edit-user/${id}`}>Edit</Link></li>
                                        <li className="color2"> <Link to="#" onClick={this.onDeleteUser}>Delete</Link></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const styles = {
    table: {
        borderCollapse: "collapse",
        width: '100%'
    }
};



const mapStateToProps = (state) => ({
    user: state.auth.user || {},
    failed: state.user.failed || false,
    getUser: (userId) => {
        return state.user.users ? state.user.users.results && state.user.users.results.find((user) => {
            return user.id.toString() === userId.toString() && user;
        }) : false;
    }
})

const mapDispatchToProps = (dispatch) => ({
    UpdateUser: (payload) => dispatch(UpdateUser(payload)),
    ClearStatus: () => dispatch(ClearStatus()),
    getSingleUser: (id) => dispatch(getSingleUser(id)),
    deleteUser: (id) => dispatch(deleteUser(id)),
    getAllUser: (payload) => dispatch(getAllUser(payload))
})


export const ProfileUser = connect(mapStateToProps, mapDispatchToProps)(User);