import { combineReducers } from "redux";
import AppReducer from "./AppReducer";
import AuthReducer from "./AuthReducer";
import UsersReducer from "./UsersReducer";
import OrgsReducer from "./OrgsReducer";
import JournalReducer from "./JournalReducer";
import ArticleReducer from "./ArticleReducer";
import AuthorRequestReducer from "./AuthorRequestReducer";
import InstitutionReducer from "./InstitutionReducer";
import OaDealsReducer from "./OaDealsReducer";
import OaDisciplineReducer from "./OaDisciplineReducer";
import OaSubjectReducer from "./OaSubjectReducer";
import OrganisationDealFeatureReducer from "./OrganisationDealFeatureReducer";
import InvoiceTemplateReducer from "./InvoiceTemplateReducer";
import OaTokenReducer from "./OaTokenReducer";
import DepositReducer from "./DepositReducer";
import ContentReducer from "./ContentReducer";
import GroupReducer from "./GroupReducer";
import VatReducer from "./VatReducer";
import DashReducer from "./DashReducer";
import TrxnReducer from "./TrxnReducer";
import ConsortiaReducer from "./ConsortiaReducer";
import ApcFundReducer from "./ApcFundReducer";
import CommonReducer from "./CommonReducer";
import ErrorReducer from "./ErrorReducer";
import CorrectionRequestReducer from "./CorrectionRequestReducer";
import EmailMessageReducer from "./EmailMessageReducer";
import PaymentReducer from "./PaymentReducer";
import OaPolicyReducer from "./OaPolicyReducer";
import LicenenceReducer from "./LicenenceReducer";
import PlanReducer from "./PlanReducer";
import RefundRequestReducer from "./RefundRequestReducer";
import SocietyReducer from "./SocietyReducer";
import SocietyMemberReducer from "./SocietyMemberReducer";
import DiscountReducer from "./DiscountReducer";
import SocietyDiscountReducer from "./SocietyDiscountReducer";
import MemberShipDiscountReducer from "./MemberDiscountReducer";
import UserPermissionReducer from "./UserPermissionReducer";
import SubmissionCreds from "./SubmissionCreds";
import PublicationApcWaiverConfigReducer from "./PublicationApcWaiverConfigReducer";
import ArticleControlConfigReducer from "./ArticleControlConfigReducer";
import DashboardControlReducer from "./DashboardControlReducer";
import APCEligibilityReducer from "./APCEligibilityReducer";
import ArticleTransferReducer from "./ArticleControlConfigReducer";
import QsDashboardReducer from "./QsDashboardReducer";
import XeroReducer from "./XeroReducer";
import GenericOAuthReducer from "./GenericOAuthReducer";
import ArticleFaultyAuthor from "./ArticleFaultyAuthorReducer";
import ArticleTabControlConfigReducer from "./ArticleTabControlConfigReducer";
import LicencePricingControlConfigReducer from "./LicencePricingControlConfigReducer";
import ArticleAuditEmail from "./ArticleAduitEmail";
import ArticleOfferDetails from "./ArticleOfferDetails";

export default combineReducers({

  app: AppReducer,
  apcFund: ApcFundReducer,
  auth: AuthReducer,
  genericOAuth: GenericOAuthReducer,
  user: UsersReducer,
  orgs: OrgsReducer,
  journals: JournalReducer,
  articles: ArticleReducer,
  articlefaultyAuthor: ArticleFaultyAuthor,
  authorRequests: AuthorRequestReducer,
  institutions: InstitutionReducer,
  OaDeals: OaDealsReducer,
  OaDiscipline: OaDisciplineReducer,
  OaSubject: OaSubjectReducer,
  OrganisationDealFeature: OrganisationDealFeatureReducer,
  InvoiceTemplate: InvoiceTemplateReducer,
  oaToken: OaTokenReducer,
  deposit: DepositReducer,
  content: ContentReducer,
  group: GroupReducer,
  vat: VatReducer,
  dashboard: DashReducer,
  trxn: TrxnReducer,
  consortia: ConsortiaReducer,
  common: CommonReducer,
  error: ErrorReducer,
  correction_request: CorrectionRequestReducer,
  emailMessage: EmailMessageReducer,
  payment: PaymentReducer,
  oa_policy: OaPolicyReducer,
  licence: LicenenceReducer,
  program: PlanReducer,
  RefundRequest: RefundRequestReducer,
  society: SocietyReducer,
  SocietyMember: SocietyMemberReducer,
  discount: DiscountReducer,
  societyDiscount: SocietyDiscountReducer,
  membershipDiscount: MemberShipDiscountReducer,
  userPermission: UserPermissionReducer,
  submissionCreds: SubmissionCreds,
  PublicationApcWaiverConfig: PublicationApcWaiverConfigReducer,
  articleControlConfig: ArticleControlConfigReducer,
  DashboardControl: DashboardControlReducer,
  apcelg: APCEligibilityReducer,
  articleTransfer: ArticleTransferReducer,
  QsDashboard: QsDashboardReducer,
  xero: XeroReducer,
  ArticleTabControlConfig: ArticleTabControlConfigReducer,
  LicencePricingControlConfig: LicencePricingControlConfigReducer,
  articleAuditEmail: ArticleAuditEmail,
  articleOfferDetails: ArticleOfferDetails,
});
