import {
  UniPubs,
  PubSingle,
  // DepositFund,
  // oaAccountApproval,
  oaDealApproveList,
  ArticleDecForm,
  ArticalAprForm,
  OaTokenList,
  tokenView,
  // DepositList,
  // AddApcFund,
  // ApcFundList,
  ApcRequestList,
  UniAddUser,
  WeeklyReport,
  DetailReport,
  // CorrectionList,
  // CorrectionView,
  OrgList,
  SingleOrg,
  // CorrectionRequest,
  EditUser,
  ProfileUser,
  // CreateRefundRequest,
  // UpdateRefundRequest,
  // RefundRequestList,
  // RefundRequestView,
  UniversityHelpMenu,
  Vat,
  ArticleFull,
  UserList,
} from "../components/views/university";
import {
  PaymentHistory,
  InvoiceHistory,
} from "../../openaccess/components/views/user";
import {
  ArticleCustomEmailMessage,
  ArticleCustomEmail,
  ArticleOutBoxEmail,
  ArticleCustomSendEmailMessage,
} from "../../openaccess/components/views/user";

import {
  OrgOffsetFundList,
  SingleTableView,
} from "../components/views/publisher";
import { Settings } from "../components/views";
import { userKey } from "../components/common/userPermission";

import {
  TrxDeposit,
  TrxOffset,
  TrxToken,
  Singletransition,
} from "../components/views/_transaction";
//import { Dashboard } from "../components/views";
import { UnivDashboard } from "../components/views/university/UnivDashboard";

import AddPayment from "../components/partials/payment/addPayment.jsx";
import SinglePayment from "../components/partials/payment/singlepaymentinfo.jsx";
import SingleInvoiceHistory from "../components/partials/payment/singleInvoice.jsx";
import Invoiceinfo from "../components/partials/payment/invoiceinfo.jsx";
export const UnivRoot = {
  dashboard: "/university-dashboard",
  publishers: "/publishers",
  affiliated_organisations: "/affiliated-organisations",
  users: "/users",
  articles: "/articles",
  apc_fund: "/apc-fund",
  author_request: "/author-request",
  refund_request: "/refund-request",
  pub_fund: "/publisher-fund",
  oa_account: "/oa-account",
  article_approval: "/article-approval",
  article_decline: "/article-decline",
  token: "/institute",
  transaction: "/transactions",
  reports: "/reports",
  action: "/action",
  settings: "/settings",
  vat: "/vat",
  payment: "/payment",
  help_menu: "/university-help-menu",
  custom_email: "/custom-email",
};

export const UnivSidebar = [
  {
    icon: " mdi-pencil-box",
    label: "Articles",
    role: "public",
    to: UnivRoot.articles,
    exact: true,
    component: ArticleFull,
  },
  {
    icon: "mdi-view-dashboard",
    label: "Dashboard",
    to: UnivRoot.dashboard,
    exact: true,
    component: UnivDashboard,
    role: "public",
  },
  {
    icon: "mdi-application",
    label: "Settings",
    to: UnivRoot.settings,
    exact: true,
    component: Settings,
    role: "public",
  },
  {
    icon: "ion-gear-b",
    label: "OA Account",
    exact: true,
    to: UnivRoot.action,
    role: "public",
    value: "manage_oa_account",
    content: [
      // {
      // 	label: "OA account requests",
      // 	to: "/oa-account-request",
      // 	component: oaAccountApproval,
      // 	showBadge: true,
      // },
      // {
      // 	label: "Correction request",
      // 	to: "/add-apc-fund",
      // 	component: CorrectionRequest,
      // },
      {
        label: "OA account list",
        to: "/oa-account-list",
        component: oaDealApproveList,
        role: "public",
        value: "manage_oa_account",
      },
      // {
      // 	label: "Correction list",
      // 	to: "/correction-list",
      // 	component: CorrectionList,
      // },
      // {
      // 	label: "Correction Single",
      // 	to: "/correction-list/:id",
      // 	component: CorrectionView,
      // 	isHide: true,
      // },
      {
        label: "Correction Single",
        to: "/oa-account-list/:id",
        component: SingleTableView,
        isHide: true,
      },
    ],
  },
  {
    icon: " mdi-bank",
    label: "Publishers",
    to: UnivRoot.publishers,
    exact: true,
    isHide: true,
    component: UniPubs,
    role: "private",
  },
  {
    to: UnivRoot.publishers + "/:id",
    exact: true,
    component: PubSingle,
    isHide: true,
    role: "private",
  },

  {
    icon: " mdi-pencil-box",
    label: "ArticleApprovalForm",
    to: UnivRoot.article_approval + "/:id",
    exact: true,
    component: ArticalAprForm,
    isHide: true,
  },
  {
    icon: " mdi-pencil-box",
    label: "Article Decline Form",
    to: UnivRoot.article_decline + "/:id",
    exact: true,
    component: ArticleDecForm,
    isHide: true,
  },

  {
    icon: "mdi-book-multiple",
    label: "APC Request List",
    exact: true,
    to: UnivRoot.author_request,
    component: ApcRequestList,
    showBadge: true,
    role: "private",
    value: "manage_apc_request",
  },
  {
    icon: "mdi-message-draw",
    label: "Emails",
    exact: true,
    to: UnivRoot.custom_email,
    role: "public",
    content: [
      {
        label: "Inbox",
        to: "/email-custom-log",
        component: ArticleCustomEmail,
        role: "private",
        value: userKey.manage_email_inbox,
      },
      {
        label: "Outbox",
        to: "/email-outbox-log",
        component: ArticleOutBoxEmail,
        role: "private",
        value: userKey.manage_email_outbox,
      },
      {
        isHide: true,
        to: "/custom-email-message/:id",
        component: ArticleCustomEmailMessage,
      },
      {
        isHide: true,
        to: "/author-custom-send-message/:id",
        component: ArticleCustomSendEmailMessage,
      },
    ],
  },
  // {
  // 	icon: "mdi mdi-cash-usd",
  // 	label: "APC fund",
  // 	exact: true,
  // 	to: UnivRoot.apc_fund,
  // 	content: [
  // 		{
  // 			label: "Add fund",
  // 			to: "/add-apc-fund",
  // 			component: AddApcFund,
  // 			exact: true,
  // 		},
  // 		{
  // 			label: "APC fund list",
  // 			to: "/apc-fund-list",
  // 			component: ApcFundList,
  // 			exact: true,
  // 		},
  // 	],
  // },
  // {
  // 	icon: "mdi-cash-multiple",
  // 	label: "Publisher fund",
  // 	exact: true,
  // 	to: UnivRoot.pub_fund,
  // 	content: [
  // 		{
  // 			label: "Deposit & payment",
  // 			to: "/deposit-fund",
  // 			component: DepositFund,
  // 			exact: true,
  // 		},
  // 		{
  // 			label: "Deposit fund list",
  // 			to: "/deposit-list",
  // 			component: DepositList,
  // 			exact: true,
  // 		},
  // 	],
  // },
  {
    icon: "mdi-key-variant",
    label: "Tokens & Offsets",
    to: UnivRoot.token,
    exact: true,
    role: "public",
    content: [
      {
        label: "Token List",
        to: "/token-list",
        component: OaTokenList,
        exact: true,
        role: "private",
        value: userKey.manage_token_list,
      },
      {
        label: "Tokens",
        to: "/token-view/:id",
        component: tokenView,
        isHide: true,
      },
      {
        label: "Offset funds",
        to: "/org-offset-fund-list",
        component: OrgOffsetFundList,
        role: "private",
        value: "manage_offset_funds",
      },
    ],
  },

  {
    icon: "mdi-account-multiple",
    label: "Payment",
    exact: true,
    isHide: true,
    to: UnivRoot.payment,
    content: [
      {
        isHide: true,
        to: "/addpayment/:id",
        component: AddPayment,
      },
      {
        label: "singlePayment",
        to: "/InvoiceInfo",
        isHide: true,
        component: Invoiceinfo,
      },
      {
        label: "PaymentHistory",
        to: "/payment-history",
        component: PaymentHistory,
        role: "private",
      },
    ],
  },

  {
    icon: "mdi-white-balance-incandescent",
    label: "Transaction",
    exact: true,
    to: UnivRoot.transaction,
    role: "public",
    content: [
      {
        label: "Deposit fund",
        to: "/deposit-fund",
        component: TrxDeposit,
        role: "private",
        value: userKey.manage_deposit_fund,
      },
      {
        label: "Offset fund",
        to: "/offset-fund",
        component: TrxOffset,
        role: "private",
        value: "manage_offset_fund",
      },
      {
        label: "singletransition",
        to: "/viewtransition",
        component: Singletransition,
        isHide: true,
      },
      {
        label: "Card payment ",
        to: "/paymenthistory",
        component: PaymentHistory,
        role: "private",
        value: userKey.manage_card_payment,
      },
      {
        label: "single Invoice payment ",
        to: "/singleinvoice/:id",
        isHide: true,
        component: SingleInvoiceHistory,
      },
      {
        label: "Invoice ",
        to: "/invoice",
        component: InvoiceHistory,
        role: "private",
        value: userKey.manage_transatction_invoice,
      },
      {
        to: "/singlepayment/:id",
        label: "singlePayment",
        isHide: true,
        component: SinglePayment,
      },

      {
        label: "Token",
        to: "/token",
        component: TrxToken,
        role: "private",
        value: userKey.manage_transations_token,
      },
    ],
  },
  // {
  // 	icon: "mdi-cart-outline",
  // 	label: "Refund request",
  // 	exact: true,
  // 	to: UnivRoot.refund_request,
  // 	content: [
  // 		{
  // 			label: "Create refund request",
  // 			to: "/create-refund-request",
  // 			component: CreateRefundRequest,
  // 		},
  // 		{
  // 			label: "Update refund request",
  // 			to: "/update-refund-request/:id",
  // 			component: UpdateRefundRequest,
  // 			isHide: true,
  // 		},
  // 		{
  // 			label: "View refund request",
  // 			to: "/view-refund-request/:id",
  // 			component: RefundRequestView,
  // 			isHide: true,
  // 		},
  // 		{
  // 			label: "Refund requests",
  // 			to: "/refund-request-list",
  // 			component: RefundRequestList,
  // 		},
  // 	],
  // },
  {
    icon: "mdi-chart-bubble",
    label: "Report",
    exact: true,
    to: UnivRoot.reports,
    isHide: true,
    content: [
      {
        label: "Weekly report",
        to: "/weekly-report",
        exact: true,
        component: WeeklyReport,
      },
      {
        label: "Detail report",
        to: "/detail-report",
        exact: true,
        component: DetailReport,
      },
    ],
  },
  {
    icon: "ion-university",
    label: "Affiliated organisations",
    to: UnivRoot.affiliated_organisations,
    role: "private",
    value: userKey.manage_affiliated_orgs,
    content: [
      {
        label: "Affiliated Organisations List",
        to: "/uni-affiliated-organisation-list",
        component: OrgList,
        role: "private",
        value: userKey.manage_affiliated_orgs,
      },
      {
        label: "Organisation View",
        to: "/single-org/:id",
        component: SingleOrg,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-account-multiple",
    label: "Users",
    exact: true,
    to: UnivRoot.users,
    isHide: true,
    content: [
      {
        label: "Add user",
        to: "/add-user",
        exact: true,
        component: UniAddUser,
      },
      {
        label: "User list",
        to: "/user-list",
        exact: true,
        component: UserList,
      },
      {
        label: "Edit User",
        to: "/edit-user/:id",
        component: EditUser,
        isHide: true,
      },
      {
        label: "View User",
        to: "/user/:id",
        component: ProfileUser,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "Vat",
    to: "/vat",
    isHide: true,
    content: [
      {
        label: "Add Vat",
        to: "/addvat",
        component: Vat,
      },
    ],
  },

  // {
  // 	icon: "mdi-application",
  // 	label: "OA Policy",
  // 	to: "/oa-policy",
  // 	isHide: true,
  // 	content: [
  // 		{
  // 			label: "Add OA Policy",
  // 			to: "/add-oa-policy",
  // 			component: AddOaPolicy,
  // 		},
  // 		{
  // 			label: "OA Policy list",
  // 			to: "/oa-policy-list",
  // 			component: OaPolicyList,
  // 		},
  // 	],
  // },

  {
    icon: "mdi-comment-question-outline",
    label: "Help",
    role: "public",
    to: UnivRoot.help_menu,
    exact: true,
    component: UniversityHelpMenu,
  },
];
