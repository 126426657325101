import React from 'react'
const PriceInfo = (props) => {
    let articleDetail = props && props.prices
    let priceofFixed = props.priceofFixed
    let page_charge = (articleDetail && articleDetail.page_charge_gbp !== "0.00") || (articleDetail && articleDetail.page_charge_eur !== "0.00") || (articleDetail && articleDetail.page_charge_usd !== "0.00")
    let submission_charge = (articleDetail && articleDetail.submission_fee_gbp !== "0.00") || (articleDetail && articleDetail.submission_fee_eur !== "0.00") || (articleDetail && articleDetail.submission_fee_usd !== "0.00")
    let colour_charge = (articleDetail && articleDetail.colour_charge_gbp !== "0.00") || (articleDetail && articleDetail.colour_charge_eur !== "0.00") || (articleDetail && articleDetail.colour_charge_usd !== "0.00")
    let pub_charge = (articleDetail && articleDetail.pub_fee_gbp !== "0.00") || (articleDetail && articleDetail.pub_fee_eur !== "0.00") || (articleDetail && articleDetail.pub_fee_usd !== "0.00")
    let discountSummary = (articleDetail && articleDetail.discount_summary)
    
    return (
        <div>
            <div className="row">
                {(pub_charge || colour_charge || submission_charge || page_charge) &&
                    <div className="col-sm-3">
                        <p>
                            <strong className="info_seperator"> Currency </strong>

                        </p>
                    </div>}
                {
                    ((articleDetail && articleDetail.pub_fee_gbp !== "0.00") || (articleDetail && articleDetail.page_charge_gbp !== "0.00") || (articleDetail && articleDetail.submission_fee_gbp !== "0.00") || (articleDetail && articleDetail.colour_charge_gbp !== "0.00")) && <div className="col-sm-3">
                        <p className="article-price-currency">

                            <strong className="info_seperator"> GBP </strong>

                        </p>

                    </div>
                }
                {
                    ((articleDetail && articleDetail.pub_fee_eur !== "0.00") || (articleDetail && articleDetail.page_charge_eur !== "0.00") || (articleDetail && articleDetail.submission_fee_eur !== "0.00") || (articleDetail && articleDetail.colour_charge_eur !== "0.00")) &&

                    <div className="col-sm-3">

                        <p className="article-price-currency">
                            <strong className="info_seperator"> EUR </strong>

                        </p>
                    </div>}
                {((articleDetail && articleDetail.pub_fee_usd !== "0.00") || (articleDetail && articleDetail.page_charge_usd !== "0.00") || (articleDetail && articleDetail.submission_fee_usd !== "0.00") || (articleDetail && articleDetail.colour_charge_usd !== "0.00")) &&

                    <div className="col-sm-3">

                        <p className="article-price-currency">
                            <strong className="info_seperator"> USD </strong>

                        </p>
                    </div>}
            </div>
            <div className="row">
                {pub_charge && <div className="col-sm-3">
                    <p className="m-1">
                        <strong>APC </strong>
                    </p>
                </div>}

                {articleDetail && articleDetail.pub_fee_gbp !== "0.00" ?
                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  £ </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.pub_fee_gbp
                            )}

                        </p>
                    </div> : null}
                {articleDetail && articleDetail.pub_fee_eur !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">   €    </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.pub_fee_eur
                            )}

                        </p>
                    </div> : null}
                {articleDetail && articleDetail.pub_fee_usd !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  $ </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.pub_fee_usd
                            )}

                        </p>
                    </div>
                    : null}
            </div>
            <div className="row">
                {submission_charge && <div className="col-sm-3">
                    <p className="m-1">
                        <strong>Submission fee </strong>
                    </p>
                </div>}
                {articleDetail && articleDetail.submission_fee_gbp !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  £ </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.submission_fee_gbp
                            )}

                        </p>
                    </div>
                    : null}

                {articleDetail && articleDetail.submission_fee_eur !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  €  </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.submission_fee_eur
                            )}

                        </p>
                    </div>
                    : null}
                {articleDetail && articleDetail.submission_fee_usd !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator"> $ </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.submission_fee_usd
                            )}

                        </p>
                    </div>
                    : null}

            </div>



            <div className="row">
                {
                    colour_charge && <div className="col-sm-3">
                        <p className="m-1">
                            <strong>Colour charge</strong>
                        </p>
                    </div>
                }
                {articleDetail && articleDetail.colour_charge_gbp !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  £ </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.colour_charge_gbp
                            )}

                        </p>
                    </div>
                    : null}

                {articleDetail && articleDetail.colour_charge_eur !== "0.00" ?

                    <div className="col-sm-3">

                        <p className="m-1">
                            <strong className="info_seperator">  €  </strong>

                            {priceofFixed(
                                articleDetail && articleDetail.colour_charge_eur
                            )}

                        </p>
                    </div> : null}
                {articleDetail && articleDetail.colour_charge_usd !== "0.00" ?

                    <div className="col-sm-3">

                        <p className="m-1">
                            <strong className="info_seperator"> $ </strong>

                            {priceofFixed(
                                articleDetail && articleDetail.colour_charge_usd
                            )}

                        </p>
                    </div> : null}

            </div>
            <div className="row">
                {
                    page_charge &&
                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong>Page and other charge</strong>
                        </p>
                    </div>}
                {articleDetail && articleDetail.page_charge_gbp !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  £ </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.page_charge_gbp
                            )}

                        </p>
                    </div> : null}
                {articleDetail && articleDetail.page_charge_eur !== "0.00" ?

                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  €  </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.page_charge_eur
                            )}

                        </p>
                    </div> : null}

                {articleDetail && articleDetail.page_charge_usd !== "0.00" ?
                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator"> $ </strong>
                            {priceofFixed(
                                articleDetail && articleDetail.page_charge_usd
                            )}

                        </p>
                    </div> : null}
            </div>

            { discountSummary 
                && (discountSummary.gbp || discountSummary.usd || discountSummary.eur) &&

                <div className="row">
                    
                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong>{ (discountSummary.gbp && discountSummary.gbp.discount_description) || (discountSummary.usd && discountSummary.usd.discount_description) || (discountSummary.eur && discountSummary.eur.discount_description) }</strong>
                        </p>
                    </div>
                    
                    { discountSummary.gbp && <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  £ </strong>
                            
                            {
                                discountSummary.gbp.discount_value && discountSummary.gbp.discount_value.replace('£', '')
                            }
                        </p>
                    </div>}

                    { discountSummary.eur && <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  € </strong>
                            
                            {
                                discountSummary.eur.discount_value && discountSummary.eur.discount_value.replace('€', '')
                            }
                        </p>
                    </div>}
                    
                    { discountSummary.usd && <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator">  $ </strong>
                            
                            {
                                discountSummary.usd.discount_value && discountSummary.usd.discount_value.replace('$', '')
                            }
                        </p>
                    </div>}
                </div>
            }

            { discountSummary 
                && (discountSummary.gbp || discountSummary.usd || discountSummary.eur) &&

                <div className="row">
                    
                    <div className="col-sm-3">
                        <p className="m-1">
                            <strong>Price after discount</strong>
                        </p>
                    </div>
                    
                    { discountSummary.gbp && <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator"> £ </strong>

                            {
                                discountSummary.gbp.net_payable && priceofFixed(discountSummary.gbp.net_payable)
                            }
                        </p>
                    </div>}

                    { discountSummary.eur && <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator"> € </strong>
                            
                            {
                                discountSummary.eur.net_payable && priceofFixed(discountSummary.eur.net_payable)
                            }
                        </p>
                    </div>}
                    
                    { discountSummary.usd && <div className="col-sm-3">
                        <p className="m-1">
                            <strong className="info_seperator"> $ </strong>
                            
                            {
                                discountSummary.usd.net_payable && priceofFixed(discountSummary.usd.net_payable)
                            }
                        </p>
                    </div>}
                </div>
            }

        </div>
    )
}

export default PriceInfo
