import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getPublisherOaToken,
  deleteOaToken,
  getOaTokenisActiveDownload,
} from "../../../../actions";
import {
  Loading,
  PopupBox,
  PaginationForm,
  InputGroup
} from "../../../../../core/components/common";
import ExpandData from "./TokenDataTable";
import { column } from "../../../../../core/lib";
import NotFound from "../../NotFound";
import moment from "moment";
import { serialize } from "../../../../../core/lib";

class OrgToken extends BaseComponent {
  state = {    filterParams: { page: 1 },

    deleteConfirm: false,
    journalId: null,
    waitingMsg: null,
    deleteStatus: null,
    state: [],
    pageNumber: 1,
    
  };
  getOaTokenData = async (pageNum = 1) => {
    let value = {
      page: pageNum,
  };

  await this.setState((prevState) => {
    prevState.filterParams = Object.assign(prevState.filterParams, value);

  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getOaToken({ params, filter });
  };
  async componentDidMount() {
    this.getOaTokenData();
  }
  filterData = async (value) => {
    value.page = 1;

    if (value.hasOwnProperty("q")) {
      this.setState({
        ...value,
      });
    }

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
  
    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.getOaToken({ params, filter });
   
  };


  pageChange = async(pageNumber) => {
    let value = {
      page: pageNumber,
    };

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
     await this.props.getOaToken({ params, filter });

  };

  onDeleteOaToken = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        oaTokenId: id,
      });
    }
  };

  onDeleteOaTokenHander = async () => {
    const oaTokenId = this.state.oaTokenId;
    this.setState({
      waitingMsg: "Deleting...",
    });
    await this.props.deleteOaToken(oaTokenId);
    let data = this.state.data;
    let newData = data && data.filter(function (element) {
      return element.id !== oaTokenId;
    });
    this.setState({
      waitingMsg: null,
      oaTokenId: null,
      deleteConfirm: false,
      data: newData,
    });
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      oaTokenId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };
  OatokenDownloadClick = async () => {
    try {
      await this.props.getOaTokenisActiveDownload();
      const url = window.URL.createObjectURL(this.props.token_download);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `Oa_token_data_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  render() {
    let OffsetFundList = this.props.OaTokenList && this.props.OaTokenList.results;
    let dataTokenOa = [];
    OffsetFundList &&
      OffsetFundList.map((item) => {
        return dataTokenOa.push({
          reference: item.name,
          assigned_organisation_name: item.assigned_organisation_name,

          publisher: item.publisher_name,
          number_of_time: item.number_of_time,
          number_of_time_used: item.number_of_time_used,

          token_expire_type: item.token_expire_type,
          token: item.token,
          currency: item.reward_currency ? item.reward_currency : "-",
          amount: item.reward && item.reward.amount,
          valid_from: item.valid_from
            ? moment(item.valid_from).format("Do MMM YYYY")
            : "-",
          valid_to: item.valid_to
            ? moment(item.valid_to).format("Do MMM YYYY")
            : "-",
          assigned_status: item.is_assigned
          ? "Already assigned"
          : "Not assigned",
          used_status: item.status_label,
          id: item.id,
          note :item.note
        });
      });
    return this.state.status === 403 ? (
      <NotFound />
    ) : (
      <HubContent
        title="OA tokens"
          onArticleDownloadClickHandler={this.OatokenDownloadClick}
      >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            NoText="No"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteOaTokenHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        {this.state.deleteStatus && (
          <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
        )}

        {this.state.deleteStatus === false && (
          <PopupBox
            title="Failed... Try Again"
            onCancel={this.onCancel}
            NoBtnType="danger"
          />
        )}
        {/* {errorHander} */}
        <div className="row filter">
            <div className="col-md-4 m-b-20">
              
              
                   <InputGroup
            onClick={this.filterData}
            field="q"
            placeholder="Searching here..."
            className="col-md-12"
            />
                
             
       
       
          </div>{" "}
          <div className="col-12">
              <div className="card m-b-20">
                <div className="row heading" style={{ textAlign: "center" }}>
                  
                <div className="col-md-2" >
                    <p>Inst/Author/</p>
                    <p>Sub/Journal</p>
                  </div>
                              
                <div className="col-md-2" >
            <p>Token ID</p>
                  </div>
                              
                <div className="col-md-1" style={{textAlign:"center"}}>
                    <p>Cur/</p>
                    <p>Percent</p>
          </div>
                      
          <div className="col-md-1"style={{textAlign:"center"}} >
            <p>Amt/Dis</p>
          </div>            
                <div className="col-md-2"style={{textAlign:"center"}} >
                    <p>Token usage</p>
                    <p>(#of times)</p>
          </div>
                      
          <div className="col-md-1" >
            <p>Token Used</p>
                  </div>
                  <div className="col-md-1" >
            <p>status</p>
                  </div>
                  <div className="col-md-1" >
                    <p>Expire</p>
                    <p>Date</p>
                  </div>
                  <div className="col-md-1" >
            <p>Details</p>
          </div>
                  </div>
       
        
    
            
               
                {
                this.props.isLoading !==true?  <div>
             { dataTokenOa &&
                    dataTokenOa.map((item, index) => (
                      <div id="accordion" key={index}>
                        <ExpandData
                          onActionHandler={this.onActionHandler}
                          invoiceUpdate={true}
                          key={index}
                          item={item}
                          column={column}
                          downloadName="Payment Card"
                        />
                      </div>
                    ))
                 
               
                }

                  {this.props.OaTokenList &&this.props.OaTokenList.count >0  ?  <PaginationForm
                          pageSize={10}
                          current={this.state.filterParams.page}
                      pageChange={this.pageChange}
                      count={this.props.OaTokenList &&this.props.OaTokenList.count}
                    />: 
                    <div className="card-body text-center">No Data Found</div>
                  }
                 </div> :<Loading type="flat"/>

                }
              
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  OaTokenList: state.oaToken.oa_token_list || false,
  isLoading: state.oaToken.isLoading || false,

  token_download: state.oaToken.token_download || false,
});

const mapDispatchToProps = (dispatch) => ({
  getOaToken: (payload) => dispatch(getPublisherOaToken(payload)),
  deleteOaToken: (id) => dispatch(deleteOaToken(id)),
  getOaTokenisActiveDownload: () => dispatch(getOaTokenisActiveDownload()),
});

export const OaTokenList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgToken);
