import {
  GET_PUBLICATION_TYPE,
  GET_COUNTRY,
  GET_BOOK_SERIES_ACTION,
  GET_JOURNAL_SEARCHLIST,
  JOURNAL_FILE_UPLOAD,
  TRACK_JOURNAL_FILE_UPLOAD_STATUS,
  GET_JOURNAL_TYPE,
  GET_CONTENT_TYPE,
  REQUEST_CREATE_PUBLICATION,
  GET_JOURNAL_LIST,
  SINGLE_JOURNAL_DATA,
  COUNT_JOURNAL_LIST,
  GET_JOURNAL_DOWNLOAD,
  GET_JOURNAL_ACTION,
  REQUEST_BOOK_SERIES,
  SUCESS_BOOK_SERIES,
  GET_BOOK_PUBLICATION_TYPE,
  FAILURE_BOOK_SERIES,
  SUCCESS_GET_PUBLICATION,
  FAIELD_GET_PUBLICATION,
  REQUEST_GET_PUBLICATION,
  GET_SINGLE_COUNTRY,
} from "../../config";

const OrgsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SINGLE_COUNTRY:
      return (state = {
        ...state,
        countryData: action.payload,
      });
    case SUCCESS_GET_PUBLICATION:
      return (state = {
        ...state,
        isLoading: false,
        publicationData: action.payload,
      });
    case REQUEST_GET_PUBLICATION:
      return (state = {
        ...state,
        isLoading: true,
      });

    case FAIELD_GET_PUBLICATION:
      return (state = {
        ...state,
        publicationData: action.payload,

        isLoading: false,
      });

    case GET_PUBLICATION_TYPE:
      return (state = {
        ...state,
        publication_type: action.payload,
      });
    case GET_BOOK_PUBLICATION_TYPE:
      return (state = {
        ...state,
        publisherList: action.payload,
      });

    case GET_JOURNAL_TYPE:
      return (state = {
        ...state,
        journal_type: action.payload,
      });
    case GET_CONTENT_TYPE:
      return (state = {
        ...state,
        content_type: action.payload,
      });
    case REQUEST_CREATE_PUBLICATION:
      return (state = {
        ...state,
        publication_created: action.payload,
      });
    case GET_JOURNAL_LIST:
      return (state = {
        ...state,
        journal_list: action.payload,
      });
    case SINGLE_JOURNAL_DATA:
      return (state = {
        ...state,
        single_journal: action.payload,
      });
    case COUNT_JOURNAL_LIST:
      return (state = {
        ...state,
        count_journal_list: action.payload,
      });
    case JOURNAL_FILE_UPLOAD:
      return (state = {
        ...state,
        journal_file_upload: action.payload,
      });
    case TRACK_JOURNAL_FILE_UPLOAD_STATUS:
      return (state = {
        ...state,
        track_upload_status: action.payload,
      });
    case GET_JOURNAL_DOWNLOAD:
      return (state = {
        ...state,
        journal_download: action.payload,
      });
    case GET_COUNTRY:
      return (state = {
        ...state,
        countrylist: action.payload,
      });
    case GET_JOURNAL_SEARCHLIST:
      return (state = {
        ...state,
        journalSearchList: action.payload,
      });
    case GET_BOOK_SERIES_ACTION:
      return (state = {
        ...state,
        book_series_type: action.payload,
      });

    case GET_JOURNAL_ACTION:
      return (state = {
        ...state,
        specific_journal_type: action.payload,
      });
    case SUCESS_BOOK_SERIES:
      return (state = {
        ...state,
        isLoading: false,
        book_series_table: action.payload,
      });
    case REQUEST_BOOK_SERIES:
      return (state = {
        ...state,
        isLoading: true,
      });
    case FAILURE_BOOK_SERIES:
      return (state = {
        ...state,
        isLoading: false,
        book_series_table: action.payload,
      });
    default:
      return state;
  }
};
export default OrgsReducer;
