import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getPublicPublisher,
  createUpdatePublicationApcWaiverConfig,
  getPublicationList,
  getJournalList,
  getContentTypeList,
} from "../../../../actions";
import moment from "moment";
import {
  PopupBox,
  SelectBox,
  OaDatePicker,
  Button,
  ModalSelectBox,
} from "../../../../../core/components/common";
import { serialize } from "../../../../../core/lib";

const format = "Do MMM YYYY";

class Add extends BaseComponent {
  state = {
    publication_apc_waiver_config: {},
  };

  async componentDidMount() {
    if (this.props.group === "HUB") {
      this.props.getPublicPublisher();
    }


    this.props.getContentType();

    this.getPublicationListOnParameterChange();
  }

  onChangeHandler = (value) => {
    if (value.expiry_date) {
      this.setState((prevState) => {
        return Object.assign(prevState.publication_apc_waiver_config, {
          expiry_date: moment(value.expiry_date, format),
        });
      });
    } else {
      this.setState(
        (prevState) => {
          return Object.assign(prevState.publication_apc_waiver_config, value);
        },
        () => {
          if (this.props.group === "HUB" && value.publisher) {
            this.getPublicationListOnParameterChange();
          }
        }
      );
    }
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();

    let data = this.state.publication_apc_waiver_config;

    if (!data.publication) {
      this.setState({
        isEmpty: (
          <div>{!data.publication && <p>Publication must be selected</p>}</div>
        ),
      });
    } else {
      if (!data.publisher) {
        data.publisher = this.props.organisation_id;
      }

      if (data.expiry_date) {
        data.expiry_date = moment(data.expiry_date).format("YYYY-MM-DD");
      }

      data.is_price_waived = true;

      await this.props.createUpdatePublicationApcWaiverConfig(data);

      if (this.props.publication_apc_waiver_config.success) {
        this.props.history.push("/settings/apc-config-list");
      }
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };

    if (this.state.publisher) {
      filterParams.publisher = this.state.publisher;
    } else if (this.props.organisation_id) {
      // filterParams.publisher = this.props.organisation_id;
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload.filter);

    this.props.publication_list &&
      this.setState({
        publicationList: this.props.publication_list.results,
      });
  };

  onChangeChecked = (e) => {
    let value = {
      is_price_waived: e.target.checked,
    };

    this.setState((prevState) => {
      return Object.assign(prevState.publication_apc_waiver_config, value);
    });
  };

  onCancelHandler = () => {
    this.props.history.push("/settings/apc-config-list");
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.publication_apc_waiver_config, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });
      }
    );
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowContentTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} content type selected`
              : `${fieldValues.length} content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Content types"
              : "Select Content type";
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent title="Zero APC Journal Form">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card m-b-20">

              <div className="card-body">
                <div className="vat-tax-button email d-flex justify-content-end">
                  <Button
                    type="danger"
                    name="danger"
                    text="X"
                    onClick={() => this.props.history.push("/settings/apc-config-list")}
                  />
                </div>

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      {this.props.group === "HUB" && (
                        <div className="form-group row">
                          <SelectBox
                            label="Publisher"
                            onChange={this.onChangeHandler}
                            field="publisher"
                            labelInfo={true}
                            labelInfoTitle="Select Publication"
                            placeholder="Select Publication"
                            data={this.props.publishers.results || []}
                            isRequired={true}
                            className="col-sm-6"
                            labelClass="col-sm-3"
                          />
                        </div>
                      )}
                      <div className="form-group row">
                        <SelectBox
                            labelInfo={true}
                            labelInfoTitle="Select Publication"
                          label="Publication"
                          placeholder="Select Publication"
                          onChange={this.onChangeHandler}
                          field="publication"
                          data={this.state.publicationList || []}
                          isRequired={true}
                          className="col-sm-6"
                          labelClass="col-sm-3"
                        />
                      </div>

                      <div className="form-group row mt-4">
                        <label className=" col-form-label col-sm-3">
                          Content types
                        </label>
                        <div
                          className="col-sm-3"
                          style={{ position: "relative",marginTop:10 }}
                        >
                          {this.showSelectItemsLabel("content_types")}
                          <button
                            onClick={() =>
                              this.onModalSelectBoxShow("content_types")
                            }
                            className="btn btn-info waves-effect waves-light float-right"
                            type="button"
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>

                        {this.state.isShowContentTypeModal && (
                          <ModalSelectBox
                            title="Select content types"
                            label="Content types"
                            field="content_types"
                            modalClass="col-10 col-md-8 col-lg-6"
                            labelClass="col-md-4"
                            className="col-sm-6"
                            data={
                              (this.props.content_type && [
                                { id: "all", name: "All content types" },
                                ...this.props.content_type,
                              ]) ||
                              []
                            }
                            defaultValue={this.state.content_types}
                            submitBtnTxt="Save"
                            onSubmit={this.onSaveSelectedItems}
                            onClose={() => this.onModalSelectBoxClose()}
                          />
                        )}
                      </div>
                      <div className="form-group row">
                        <OaDatePicker
                          className="col-sm-6"
                          label="Expiry date"
                          labelClass="col-sm-3"
                          field="expiry_date"
                          labelInfo={true}
                          labelInfoTitle="Select expiration date"
                          onChange={this.onChangeHandler}
                          placeholder="Select expiration date"
                          showTime={false}
                          format={format}
                          defaultValue={
                            this.state.expiry_date 
                          }
                        />
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-danger waves-effect waves-light mx-1"
                          onClick={this.onCancelHandler}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: (state.auth && state.auth.group) ,
  organisation_id: state.auth.user.organisation_id ,
  publishers: state.orgs.publisher_list ,
  publication_apc_waiver_config:
    state.PublicationApcWaiverConfig.publication_apc_waiver_config,
  publication_list: state.institutions.publication_list,
  journals: state.journals.journal_list ,
  content_type: state.journals.content_type,
});

const mapDispatchToProps = (dispatch) => ({
  getJournalList: (payload) => dispatch(getJournalList(payload)),
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: (payload) => dispatch(getContentTypeList(payload)),
  createUpdatePublicationApcWaiverConfig: (payload, id = undefined) =>
    dispatch(createUpdatePublicationApcWaiverConfig(payload, id)),
});

export const AddPublicationApcWaiverConfig = connect(
  mapStateToProps,
  mapDispatchToProps
)(Add);