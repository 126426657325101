import { GET_GENERIC_OAUTH_LOGIN_URL, SUBMIT_GENERIC_OAUTH_AUTH_CODE,RECIEVED_LOGIN_DATA } from "../../config";

const GenericOauthReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_GENERIC_OAUTH_LOGIN_URL: {
            return state = {
                ...state,
                generic_oauth_auth_url: action.payload
            }
        }
        case SUBMIT_GENERIC_OAUTH_AUTH_CODE: {
            return state = {
                ...state,
                submit_generic_auth_code: action.payload
            }
        }
        case RECIEVED_LOGIN_DATA:
            return action.payload

        default:
            return state;
    }
}

export default GenericOauthReducer;