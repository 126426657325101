import { GET_USER_PERMISSION_LIST
 } from "../../config";
 
 export default (state = {
   userPermissionList: {},
   
 }, action) => {
   switch (action.type) {
     case GET_USER_PERMISSION_LIST:
       return (state = {
         ...state,
         userPermissionList: action.payload,
       })
    
     default:
       return state
   }
 }