import React from 'react';
import BaseComponent from '..';
import { InputBox } from './InputBox';
import { ModalCustom } from './ModalCustom';

class Form extends BaseComponent { 

    state = {
        temp_alt_names: this.props.alt_names ? [...this.props.alt_names] : [],
    }

    addAltName = () => {
        let altNames = this.state.temp_alt_names

        altNames.push({
            key: Date.now(),
            name: '',
            email_domain: '',
        })

        this.setState({
            temp_alt_names: altNames,
        })
    }

    removeAltName = (index) => { 
        let altNames = this.state.temp_alt_names

        altNames.splice(index, 1)

        this.setState({
            temp_alt_names: altNames,
        })
    }

    onAltNameChangeHandler = (value, index) => { 
        let altNames = this.state.temp_alt_names || []

        if (!altNames[index]) {
            altNames[index] = {}
        }

        Object.assign(altNames[index], value)

        this.setState({
            temp_alt_names: altNames,
        })
    }

    renderAltNameForm = () => { 
        let altNames = this.state.temp_alt_names

        return altNames.map((item, index) => { 
            return (
            <div className="form-group row" key={`${index}-${item.id || item.key}`}>
                <InputBox
                    label="Name"
                    onChange={(value) => this.onAltNameChangeHandler(value, index)}
                    className="col-sm-4"
                    field="name"
                    placeholder="Enter name"
                    defaultValue={item.name}
                    isRequired={true}
                />

                <InputBox
                    label="Email domain"
                    onChange={(value) => this.onAltNameChangeHandler(value, index)}
                    className="col-sm-3"
                    field="email_domain"
                    placeholder="Enter email domain"
                    defaultValue={item.email_domain}
                    isRequired={false}
                />

                <div className="col-sm-1">
                    <button 
                        type="button"
                        className="btn btn-danger mb-2 float-right"
                        onClick={() => this.removeAltName(index)}
                    >
                        <i className="mdi mdi-close"></i>
                    </button>
                </div>
            </div>
            )
        })
    }

    onSaveAltNameHandler = () => {
        this.props.onSaveAltName(this.state.temp_alt_names)
    }
    
    render() {
        let { showAltNameModal, } = this.props;

        return(
            <ModalCustom
                title="Add alternative names"
                onClose={this.props.onModalClose}
                modalClass="col-sm-10 col-md-6 col-lg-6"
                isShow={showAltNameModal}
                submitBtnTxt="Save"
                onSubmit={this.onSaveAltNameHandler}
            >
                <div className="form-group row">
                    <div className="col-sm-12">
                        <button 
                            type="button"
                            className="btn btn-info waves-effect waves-light float-right"
                            onClick={this.addAltName}
                        >
                            <i className="fa fa-plus"></i>
                        </button>
                    </div>
                </div>

                {
                    this.renderAltNameForm()
                }
    
            </ModalCustom>
        )
    }
}

export const AlternativeNameForm = Form;