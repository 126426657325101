import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../../../../../core/components';
import { InputBox, PopupBox } from '../../../../../core/components/common';
import { createUpdateVatConfig } from '../../../../actions';


class Form extends BaseComponent { 
  state = {
    status: false,
    msg: false,
    isEmpty: false,
    showForm: false,
  }

  componentDidMount() {     
    this.props.data && this.setState({
      vat_number: this.props.data.vat_number,
      id: this.props.data.id,
    });
  }

  onChangeHandler = (value) => { 
    this.setState({
      ...value
    });
  }

  onSubmitHandler = async (e) => {
    e.preventDefault();
    let {
      id,
      vat_number
    } = this.state;

    if (
      !vat_number
    ) {
      this.setState({
        isEmpty: (
          <div>
            
            {!vat_number && (
              <p>VAT number must be given.</p>
            )}
          </div>
        )
      });
    } else {
      let data = {
        vat_number: vat_number,
      };

      if (id) {
        await this.props.createUpdateVatConfig(data, id);
      } else {
        await this.props.createUpdateVatConfig(data);
      }

      this.props.vat_config && this.setState({
        msg: 'VAT number saved successfully.',
        showForm: false,
      });      
    }    
  }

  cancelLoader = async () => {
    this.setState({
      msg: false,
      status: false,
      isEmpty: false
    });
  };
  
  render() { 
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    errorHander = (
      <>
        {this.state.msg && (
          <PopupBox
            msg={this.state.msg}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <div className="row p-t-10">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        <div className="col-sm-10 mx-auto">
          
          <form>
            { this.state.showForm ?
            <div className="form-group row">
              
              <InputBox 
                label="VAT number"
                labelClass="col-sm-3"
                onChange={this.onChangeHandler}
                field="vat_number"
                className="col-sm-5"
                isRequired={true}
                defaultValue={this.state.vat_number}
                placeholder="Enter VAT number"
              />
              <button
                onClick={this.onSubmitHandler}
                type="button"
                className="btn btn-primary waves-effect waves-light"
              >
                Submit
                </button>
            </div>
            : 
              <div className="form-group row">
                <label className="col-form-label col-sm-3">VAT number : </label>
                <div className="col-sm-5 py-2" style={{position: 'relative'}}>
                  {this.state.vat_number || ''}
                </div>
                <button
                  onClick={() => this.setState({ showForm: true })}
                  type="button" 
                  className="btn btn-primary waves-effect waves-light">
                  {this.state.vat_number ? 'Edit' : 'Add'}
                </button>
              </div>
            }
            
          </form>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  vat_config: state.vat.vat_config || false,
})

const mapDispatchToProps = (dispatch) => ({
  createUpdateVatConfig: (payload, id) => dispatch(createUpdateVatConfig(payload, id)),
})

export const VATNumberForm = connect(mapStateToProps, mapDispatchToProps)(Form);