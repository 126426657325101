import { 
    GET_OA_DISCIPLINE_LIST, 
    GET_OA_DISCIPLINE,
    CREATE_UPDATE_OA_DISCIPLINE,
    DELETE_OA_DISCIPLINE,
} from "../../config";

const OaDisciplineReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_OA_DISCIPLINE_LIST: 
            return state = {
                ...state,
                oa_disciplines: action.payload
            }
        case GET_OA_DISCIPLINE: 
            return state = {
                ...state,
                oa_discipline: action.payload
            }
        case CREATE_UPDATE_OA_DISCIPLINE: 
            return state = {
                ...state,
                oa_discipline: action.payload
            }
        case DELETE_OA_DISCIPLINE: 
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default OaDisciplineReducer;
