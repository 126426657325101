import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { PopupBox }  from '../../../../../core/components/common';
import { DataTable } from '../../../../../core/components';
import { connect } from 'react-redux';
import { oADealListPending,deleteOaDeal,oADealListApproval,oADealListReject } from '../../../../actions';


class DB extends BaseComponent {
    async componentDidMount(){
        await this.props.oADealListPending();
    }
    state = {
        oaDealIds:[]
    }
    ids = [];
    onSelect=(id)=>{
        let index = this.ids.indexOf(id);
        (index !== -1) ? this.ids.splice(index, 1) : this.ids.push(id);
        this.setState({
            oaDealIds:this.ids.toString()
        })
    }
    onDeleteOaDealHander = async () => {
        const oAdealId = this.state.oAdealId;
        this.setState({
            waitingMsg: "Deleting..."
        })
        const journal = await this.props.deleteOaDeal(oAdealId);
        let data = this.state.data;
        let newData = data
        .filter(function(element) {
            return element.id !== oAdealId;
        });
        this.setState({
            waitingMsg: null,
            oAdealId: null,
            deleteConfirm: false,
            deleteStatus: journal.status,
            data:newData
        })
    }
    onApproval=async ()=>{
        let ids = this.state.oaDealIds;
        await this.props.oADealListApproval(ids);
        this.props.history.push('/action/oa-account-list')

    }
    onReject=async ()=>{
        let ids = this.state.oaDealIds;
        await this.props.oADealListReject(ids);
    }
    onCancel = () => {
        this.setState({
            deleteConfirm: false,
            oAdealId: null,
            waitingMsg: null,
            deleteStatus: null
        })
    }
    render() {
        const heading = ['organisation_name', 'deal_name', 'currency_name', 'vat_country_name']
        return (
            <HubContent title="OA account approval">
                {this.state.deleteConfirm && <PopupBox
                    title="Do you want to delete?"
                    YesText="Yes"
                    yesBtnType="danger"
                    type="danger"
                    NoBtnType="success"
                    onCancel={this.onCancel}
                    onPressOK={this.onDeleteOaDealHander}
                    waitingMsg={this.state.waitingMsg}
                />}
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                        {this.props.oaDealList &&
                            <DataTable
                                heading={heading}
                                data={this.props.oaDealList}
                                onSelect={this.onSelect}
                                checkbox={true}
                                noAction={true}
                            />
                        }
                        {this.props.oaDealList &&
                            <div style={{textAlign:'center',marginTop:20,marginBottom:20}}>
                                <button     
                                    type="submit" 
                                    className="btn btn-primary waves-effect waves-light" 
                                    style={{marginRight:20}}
                                    onClick={this.onApproval}
                                >
                                    Accept
                                </button>
                                <button 
                                    type="submit" 
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={this.onReject}
                                >
                                    Reject
                                </button>
                            </div>
                        }


                        </div>
                    </div>
                </div>

            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    userId: state.auth.user.id || false,
    oaDealList: state.OaDeals.oadeal_pending_list || false,
    orgId: state.auth.user.organisation_id || false
})
const mapDispatchToProps = (dispatch) => ({
    oADealListPending: () => dispatch(oADealListPending()),
    deleteOaDeal: (id) => dispatch(deleteOaDeal(id)),
    oADealListApproval: (ids) => dispatch(oADealListApproval(ids)),
    oADealListReject: (ids) => dispatch(oADealListReject(ids)),
})

export const oaAccountApproval = connect(mapStateToProps, mapDispatchToProps)(DB);