import config, {
    GET_PROGRAM_LIST, 
    REQUEST_CREATE_PROGRAM,
    SINGLE_PROGRAM,
    GET_PUB_PROGRAM_LIST
} from "../../config";
import { api } from "../../core/api";



// Loaded into App Reducers
export const getPrograms = (payload) => ({
    type: GET_PROGRAM_LIST,
    payload
})

// Loaded into App Reducers
export const getPubPrograms = (payload) => ({
    type: GET_PUB_PROGRAM_LIST,
    payload
})

// Loaded into App Reducers
export const getProgram = (payload) => ({
    type: SINGLE_PROGRAM,
    payload
})

// Loaded into App Reducers
export const createProgramReducer = (payload) => ({
    type: REQUEST_CREATE_PROGRAM,
    payload
})




// Get Social Program List
export const getProgramList = (payload) => {
    return (dispatch, getState) => {
        let url =  `${config.endpoint.social_program}?page=${payload.pageNum}`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getPrograms(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Get Single Social Program
export const getSingleProgram = (payload) => {
    return (dispatch, getState) => {
        let url =  `${config.endpoint.social_program}${payload.id}/`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getProgram(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Get Social Program List
export const getProgramListAll = (payload) => {
    return (dispatch, getState) => {
        let url =  `${config.endpoint.social_program}?page_size=999999999`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getPrograms(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Get Journalanization List
export const getPubProgramList = (payload) => {
    return (dispatch, getState) => {
        let url =  `${config.endpoint.organisation_program_plan}?page=${payload.pageNum}`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getPubPrograms(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}


// Get Publication Program Single
export const getPubProgramSingle = (payload) => {
    return (dispatch, getState) => {
        let url = `${config.endpoint.organisation_program_plan}${payload.id}/`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getProgram(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}




// Create Consortia Member
export const createProgram = (payload) => {
    return (dispatch) => {
        const url = config.endpoint.social_program;
        return new Promise((resolve, reject) => {
            api.post(url,payload).then((res) => {
                dispatch(createProgramReducer(res.results))
                resolve(res.results)
            }, error => {
                reject(error.response);
            })
        })
    }
}

// Create Consortia Member
export const updateProgram = (id,payload) => {
    return (dispatch) => {
        const url = config.endpoint.social_program+`${id}/`;
        return new Promise((resolve, reject) => {
            api.put(url,payload).then((res) => {
                dispatch(createProgramReducer(res.results))
                resolve(res.results)
            }, error => {
                reject(error.response);
            })
        })
    }
}

// Create Consortia Member
export const updatePubProgram = (id,payload) => {
    return (dispatch) => {
        const url = config.endpoint.organisation_program_plan+id+'/';
        return new Promise((resolve, reject) => {
            api.put(url,payload).then((res) => {
                dispatch(createProgramReducer(res.results))
                resolve(res.results)
            }, error => {
                reject(error.response);
            })
        })
    }
}
// Create Consortia Member
export const createPubProgram = (payload) => {
    return (dispatch) => {
        const url = config.endpoint.organisation_program_plan;
        return new Promise((resolve, reject) => {
            api.post(url,payload).then((res) => {
                dispatch(createProgramReducer(res.results))
                resolve(res.results)
            }, error => {
                reject(error.response);
            })
        })
    }
}


