import React from "react";

class ExpandTable extends React.Component {
  state = {
   
  };

    render() {
        const { item, column } = this.props;
    return (
      <div className="data-table">
        <div className="row-table">
          <div className="row table-data">
            <div className={column[1]}>
             {item.assigned_organisation_name ? item.assigned_organisation_name:"-"  }
            </div>

            <div className={column[1]}>
      {item.token ? item.token :"-"}
            </div>

                    <div className={column[0]}style={{textAlign:"center"}}>
                    {item.currency ==="Discount %"? "Dis %" : item.currency}
            </div>
            <div className={column[0]} style={{textAlign:"center"}}>
            {item.currency === "Discount %"
                ? `${Math.round(item.amount)}%`
                : item.amount}
            </div>
                    <div className={column[1]} style={{textAlign:"center"}}>
                        {item.token_expire_type ==="unlimited" ? item.token_expire_type :item.number_of_time}
            </div>

            <div className={column[0]} style={{textAlign:"center"}}>
            
             {item.number_of_time_used ? item.number_of_time_used : 0}

                    </div>
            <div className={column[0]}>
            
            {item.used_status ? item.used_status :"-"}

                    </div>
                    <div className={column[0]}>
            
            {item.valid_to ? item.valid_to :"-"}

           </div>
  
  
           <div className={column[0]}>
                    <a
                className="article_open_arrow ss"
                href={`#collapse_${item.id}`}
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls={`collapse_${item.id}`}
                style={{ color: "#5874ab", fontWeight: 500 }}
              >
                <i
                  onClick={this.setActive}
                  className={
                    this.state.active
                      ? "fa fa-chevron-up"
                      : "fa fa-chevron-down"
                  }
                />
              </a>
        </div>
          </div>

          <div
            id={`collapse_${item.id}`}
            className={`collapse ${false && "show"}`}
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div
              className="row table-expand"
              style={{ paddingTop: 10, paddingBottom: 20 }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-5">
    

                    {item.institution ? <label>Organisation</label> : null}
                    {item.institution ? (
                      <p>{item.institution ? item.institution : "-"}</p>
                    ) : null}

                    {item.assigned_organisation_name ? (
                      <label>Inst/Author/Sub/Journal</label>
                    ) : null}
                    {item.assigned_organisation_name ? (
                      <p>
                        {item.assigned_organisation_name
                          ? item.assigned_organisation_name
                          : "-"}
                      </p>
                    ) : null}

                    { item.publisher && <label>Publisher:</label>}
                    <p>{item ? item.publisher : null}</p>

                    {item.valid_from ? <label>Valid from</label> : null}
                    {item.valid_from ? <p>{item.valid_from}</p> : null}
                    {item.valid_to ? <label>Valid to</label> : null}
                    {item.valid_to ? <p>{item.valid_to}</p> : null}
                     <label>Note</label>

                    {item.note ? (
                      <p>{item.note}</p>
                    ) : <p>-</p>}
          
                                 
                  </div>
                  <div className="col-md-4">

                    <label>
                      {item.currency === "Discount %" ? "Discount" : "Currency"}
                    </label>
                    <p> {item.currency ? item.currency : "-"}</p>
                    <label>
                      Reference
                        
                    </label>
                    <p>
                      {item.reference
                            ? item.reference
                            : "-"}
                    </p>
                    {
                      item.token ? (
                        <label>
                            Token
                                                
                        </label>
                      ) : null}

                    {item.grant_number ? (
                      <p> {item.grant_number}</p>
                    ) : item.token ? (
                      <p>{item.token}</p>
                    ) : item.trx_token_name ? (
                      <p> {item.trx_token_name}</p>
                    ) : null}
                                                        <label>
Token usage

(#of times)</label> 
                    {item.token_expire_type ? <p>{item.number_of_time ? item.number_of_time : item.token_expire_type}</p> : <p>-</p>}

        

                  </div>
                  <div className="col-md-3">
        
         
                    <label>Amount</label>
                    <p>
                      {item.currency === "Discount %"
                        ? `${Math.round(item.amount)}%`
                        : item.amount}
                    </p>
              
                    {item.used_status ? <label>Used Status</label> : null}
                    {item.used_status ? <p>{item.used_status}</p> : null}
                    {item.assigned_status ? (
                      <label>Assigned Status</label>
                    ) : null}
                    {item.assigned_status ? (
                      <p>{item.assigned_status}</p>
                    ) : null}
          

           
          { <label>
Remaining Token</label>}
                {item.token_expire_type !=="unlimited" ? <p>{ item.number_of_time - item.number_of_time_used}</p> :<p>unlimited</p>}

                  </div>



                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default ExpandTable;
