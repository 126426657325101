import React from 'react';
import { connect } from 'react-redux';
import {AutoComplete,Spin} from "antd"
import {  PopupBox, Badge } from '../../../../../core/components/common';
import { HubContent } from '../../../../../core/components';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { getInstitutionDataLists, createConsortiaMember, getConsortiaMembersList } from '../../../../actions';
import {LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class AddConsortium extends BaseComponent {
    state = {
        expire_date: false,
        members: [],
        data: [],
        member_ids: []
    }
  
   

    onDeleteMember = (id) => {
        this.setState((prevState) => {
            prevState.data.push(this.state.members[id])
            prevState.members.splice(id, 1)
            prevState.member_ids.splice(id, 1);
            return prevState
        })
    }


    onSubmitHandler = async (e) => {
        e.preventDefault();
        let params = {
            id: this.props.organisation_id,
            member: {
                mode: 'add',
                members: [...new Set( this.state.member_ids)]
            }
        }
        if (this.state.member_ids.length < 1) {
            this.setState({
                isEmpty: <div>
                    <p>Please select institution</p>
                </div>
            })
        } else {
           await this.props.createConsortiaMember(params);
        this.props.history.push('/consortium/consortium-member-list')
        }
    }

    cancelLoader = async () => {
        this.setState({
            isEmpty: false
        })
    }

    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
      onSelect = (value, item) => {
          if (this.isNumber(item.id)) {
              this.setState((prevState) => {
                let memberData =this.state.data && this.state.data.find(x => x.value === item.value)
                prevState.members.push(memberData);
                prevState.member_ids.push(item.id);
                prevState.orgsError = false;
                return prevState;
            }, function () {
                let institutions = this.state.data && this.state.data.filter(x => x.id !== item.value);
                this.setState({
                    data: institutions,
                    orgsError: false

                })
            })
        }
      };
    
    onChange = (value) => {
        
        let data = this.props.institution_list && this.props.institution_list.find(item => item.name === value)
        if (this.isNumber(data && data.id)) {
          this.setState({
            value: value,
            orgsError: false,
          });
        } else {
          if (value === "") {
            this.setState({
              orgsError: false,
              orgs_name: value
    
            })
          } else {
            this.setState({
              orgsError: true,
              orgs_name: value
    
            })
          }
        }
    
      };
      onSearch = (value) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            await this.props.getInstitutionDataLists(value);
            await this.props.getConsortiaMembersList({ pageNum: 1, pageSize: 9999 });
            let selectedMembers = this.props.consortia_list ?  this.props.consortia_list && this.props.consortia_list.results : [];
    
            if (selectedMembers) {
                let SelectedArray = [];
                selectedMembers.map((item) => {
                    SelectedArray.push(item.id)
                    return item;
                })
                let institutions = this.props.institution_list &&  this.props.institution_list.filter((x) => !SelectedArray.includes(x.id));
                this.setState({
                    data:
                    institutions &&
                    institutions.map((item) => ({
                      id: item.id,
                      value: item.name,
                    }))
                })
            } else {
                this.setState({
                    data:
                    this.props.institution_list &&
                    this.props.institution_list.map((item) => ({
                      id: item.id,
                      value: item.name,
                    }))
                })
            }

        }, 200);
      };
    
    
    
    render() {
      let members =  [...new Set( this.state.members)]
        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>
        return (
            <HubContent title="Consortium member">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Add consortium member</a>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    <div className="tab-pane active p-3" id="" role="tabpanel">
                                    </div>
                                    <form action="">
                                        <div className="form-group row">


                                        <div className="col-md-12">
              
              <div className="row auto-complete-search">
               
                <label className="deal-label-name">
                Enter Institution Name </label>
                <div className="col-md-12">
                  <AutoComplete
                    defaultValue={this.state.value}
                    notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}

                    options={this.state.data}
                    style={{
                      width: "100%",
                    }}
                    onSelect={(val, option) =>
                      this.onSelect(val, option)
                    }
                    onSearch={this.onSearch}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              <div className="search-auto-spin" style={{marginTop:30}}>
                {this.props.loading && <Spin indicator={antIcon} />}
              </div>

              {this.state.orgsError
                && <span style={{ color: "red" }}>Please make sure you have selected Institution name from dropdown.
</span>}
            </div>

                                        
                                        </div>
                                        <div className="form-group row" style={{ marginLeft: 3 }}>
                                            {  members && members.length > 0 && members && members.map((member, id) => {
                                                return <div                                                     key={id}
                                                >
                                                     <Badge
                                                    label={member.value}
                                                    onDelete={() => this.onDeleteMember(id)}
                                                />
                                                    </div>
                                            }) }
                                        </div>
                                        <div className="text-center m-t-15">
                                            <button onClick={this.onSubmitHandler} type="button" className="btn btn-primary waves-effect waves-light">Add members</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    organisation_id: state.auth.user.organisation_id || false,
    institution_list: (state.institutions && state.institutions.institution_list) || [],
    user_id: state.auth ? state.auth.user.id : false,
    consortia_list: state.consortia.consortia_list || false
})

const mapDispatchToProps = (dispatch) => ({
    getInstitutionDataLists: (value) => dispatch(getInstitutionDataLists(value)),
    getConsortiaMembersList: (payload) => dispatch(getConsortiaMembersList(payload)),
    createConsortiaMember: (payload) => dispatch(createConsortiaMember(payload)),
})

export const AddConsortiumMember = connect(mapStateToProps, mapDispatchToProps)(AddConsortium)