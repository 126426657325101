import React from "react";
import Ckditor from "ckeditor4-react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {
  InputNewBox,
  ModalSelectBox,
  Button,
  ModalAutoComplete,
  Loading
} from "../../../../../core/components/common";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { serialize } from "../../../../../core/lib";

import { Steps } from "antd";
import {
  getFunders,
  getPublicatonTypeList,
  getPublicationList,
  CreateLicense,
  getCountryList,
  UpdateLicense,
  singleLicenceList,
} from "../../../../actions";

const { Step } = Steps;

class LicenseForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      value: {},
      agreements: "",
      publicationTypeList: [],
      institutionList: [],
      funderList: [],
      publicationList: [],
      countrylist: [],
    };
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      const EditLicense = await this.props.singleLicenceList(
        this.props.match.params.id
      );
      let publicaionsID = Number(
        EditLicense.publication_types.map((item) => item).toString()
      );

      if (publicaionsID === 0) {
        let payload = {};
        let filterParams = {
          page_size: 999999,
        };
        payload.filter = serialize(filterParams);

        await this.props.getPublicationList(payload);
      } else {
        let payload = {};
        let filterParams = {
          page_size: 999999,
        };
        filterParams.publication_type = publicaionsID;
        payload.filter = serialize(filterParams);
        await this.props.getPublicationList(payload);
      }

      if (this.props.publication_list) {
        let res = this.props.publication_list;
        this.setState({
          publicationList: [
            { id: "all", name: "All publications" },
            ...res.results,
          ],
        });
      }
      this.setState({
        licence:EditLicense.licence,
        name: EditLicense && EditLicense.name,
        description: EditLicense.description,
        publications_types:
          (EditLicense && EditLicense.publication_types.map((item) => item)) ||
          [],
        institutionList: EditLicense.institution_details
          ? EditLicense &&
          EditLicense.institution_details.map((item) => ({
            id: item.id,
            value: item.name,
          }))
          : [],
        funders: (EditLicense && EditLicense.funders.map((item) => item)) || [],
        publications:
          (EditLicense && EditLicense.publications.map((item) => item)) || [],
        country:
          (EditLicense && EditLicense.countries.map((item) => item)) || [],
        agreements: EditLicense && EditLicense.agreements,
      });
    }

    this.setState({
      user: this.props.user,
    });
    let payload = {};

    try {
      payload.filter = serialize({ page_size: 999999 });

      this.props.getCountryList(payload).then((res) => {
        this.setState({
          countrylist: [{ id: "all", name: "All Countries" }, ...res.results],
        });
      });
    } catch (error) {
      this.setState({
        countrylist: [],
      });
    }

    try {
      payload.filter = serialize({ page_size: 999999 });
      this.props.getPublicationList(payload).then((res) => {
        // this.setState({
        //   publicationList: [{ id: 'all', name: 'All publications' }, ...res.results],
        // });
      });
    } catch (error) {
      this.setState({
        publicationTypeList: [],
      });
    }

    try {
      this.props.getPublicatonTypeList().then((res) => {
        this.setState({
          publicationTypeList: [
            { id: "all", name: "All publication types" },
            ...res.results,
          ],
        });
      });
    } catch (error) {
      this.setState({
        publicationTypeList: [],
      });
    }

    try {
      this.props.getFunders().then((res) => {
        this.setState({
          funderList: [{ id: "all", name: "All funders" }, ...res],
        });
      });
    } catch (error) {
      this.setState({
        funderList: [],
      });
    }
  }


  onEditorChange = (evt) => {
    this.setState({
      agreements: evt.editor.getData(),
    });
  };

  //Step Method
  onTextFieldChange = (value) => {
    this.setState({ ...value });
  };
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  //Submit form
  onSubmit = async (e) => {
    e.preventDefault();

    let funderList =
      this.state.funders && this.state.funders[0] === "all"
        ? []
        : this.state.funders;
    let publication_types =
      this.state.publications_types &&
        this.state.publications_types[0] === "all"
        ? []
        : this.state.publications_types;
    let author_affiliations = this.state.institutions
      ? this.state.institutions
      : [];

    let publications =
      this.state.publications && this.state.publications[0] === "all"
        ? []
        : this.state.publications;
    let countries =
      this.state.country && this.state.country[0] === "all"
        ? []
        : this.state.country;

    const { name, description } = this.state;

    const payload = {
      licence:(this.props.location.state && this.props.location.state.licenseId) || this.state.licence,
      name: name,
      description: description,
      institutions: author_affiliations,
      funders: funderList,
      countries: countries,
      publication_types: publication_types,
      publications: publications,
      agreements: this.state.agreements,
    };
    if (this.props.match.params.id) {
      await this.props.UpdateLicense(this.props.match.params.id, payload);
      this.props.history.push(`/settings/license-configuration`, {
        index: this.props.location.state.index || 0,
      });
    } else {
      await this.props.CreateLicense(payload);
      this.props.history.push(`/settings/license-configuration`, {
        index: this.props.location.state.index || 0,
      });
    }
 };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "publications_types":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationTypeModal: true,
        });
        break;
      case "funders":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      case "institutions":
        this.setState({
          isShowInstitutionsTypeModal: true,
        });
        break;
      case "country":
        this.setState({
          isShowInstitutionsDealTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowPublicationModal: false,
      isShowPublicationTypeModal: false,
      isShowContentTypeModal: false,
      isShowInstitutionsTypeModal: false,
      isShowInstitutionsDealTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];

    let labelText = "";
    switch (fieldName) {
      case "publications_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText = `${fieldValues.length} Publication type selected`;
        } else {
          labelText = `All Publications type`;
        }
        break;

      case "institutions":
        if (
          this.state.institutionList &&
          this.state.institutionList.length > 0
        ) {
          labelText =
            this.state.institutionList &&
              this.state.institutionList.length === 1
              ? `${this.state.institutionList &&
              this.state.institutionList.length
              } institution selected`
              : `${this.state.institutionList &&
              this.state.institutionList.length
              } institutions selected`;
        } else {
          labelText = `All institutions selected`;
        }
        break;

      case "funders":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length > 1
              ? `${fieldValues.length} funders selected`
              : `${fieldValues.length} funder selected`;
        } else {
          labelText = `All funders selected`;
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length > 1
              ? `${fieldValues.length} publications selected`
              : `${fieldValues.length} publication  selected`;
        } else {
          labelText = `All publications selected`;
        }
        break;

      case "country":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length > 1
              ? `${fieldValues.length} Countries selected`
              : `${fieldValues.length} Country  selected`;
        } else {
          labelText = `All Countries selected`;
        }
        break;
      default:
        break;
    }

    return labelText;
  };
  onSaveSelectedInstItems = (value) => {
    this.setState({
      institutions: value && value.map((item) => item.id),
      institutionList: value,
    });
  };
  onSaveSelectedItems = async (fieldName, data) => {
    this.setState({
      [fieldName]: data,
    });
  };
  handePublishersBasedonParent = async (fieldName, data) => {
    this.setState({
      [fieldName]: data,
    });

    if (data.length === 0) {
      let payload = {};
      let filterParams = {
        page_size: 999999,
      };

      payload.filter = serialize(filterParams);

      await this.props.getPublicationList(payload);
    } else {
      let payload = {};
      let filterParams = {
        page_size: 999999,
      };
      filterParams.publication_type = data;
      payload.filter = serialize(filterParams);

      await this.props.getPublicationList(payload);
    }

    if (this.props.publication_list) {
      let res = this.props.publication_list;
      this.setState({
        publicationList: [
          { id: "all", name: "All publications" },
          ...res.results,
        ],
      });
    }
  };
  onSaveSelectedInstItems = (value) => {
    this.setState({
      institutions: value && value.map((item) => item.id),
      institutionList: value,
    });
  };

  render() {

    const {
      institutionList,

      publicationTypeList,

      countrylist,
      publicationList,
      funderList,
    } = this.state;
    const steps = [
      {
        title: "Title and Description",
        content: (
          <div className="card-body">
            <div className="tab-content">
              <form action="">
                <div className="form-group row">
                  <InputNewBox
                    className="col-md-10"
                    label="Name"
                    labelInfo={true}
                    labelInfoTitle="Enter Name"
                    onChange={this.onTextFieldChange}
                    field="name"
                    placeholder="Name"
                    defaultValue={this.state.name}
                  />
                </div>
                <div className="form-group row">
                  <InputNewBox
                    className="col-md-10"
                    label="Description"
                    labelInfo={true}
                    labelInfoTitle="Enter Description"
                    onChange={this.onTextFieldChange}
                    field="description"
                    placeholder="Description"
                    defaultValue={this.state.description}
                  />
                </div>

                <div className="form-group row">
                  <div className="col-sm-4 model-class">
                    {this.showSelectItemsLabel("publications_types")}
                    <button
                      onClick={() =>
                        this.onModalSelectBoxShow("publications_types")
                      }
                      className="btn btn-info waves-effect waves-light float-right"
                      type="button"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>

                  {this.state.isShowDisciplineModal && (
                    <ModalSelectBox
                      label="Publications types"
                      field="publications_types"
                      singleField="singleField"
                      modalClass="col-sm-6"
                      labelClass="col-sm-4"
                      className="col-sm-8"
                      data={
                        (publicationTypeList && [...publicationTypeList]) || []
                      }
                      defaultValue={this.state.publications_types}
                      isRequired={false}
                      submitBtnTxt="Save"
                      onSubmit={this.handePublishersBasedonParent}
                      onClose={() => this.onModalSelectBoxClose()}
                    />
                  )}

                  <div className="col-sm-4 model-class">
                    {this.showSelectItemsLabel("publications")}
                    <button
                      onClick={() => this.onModalSelectBoxShow("publications")}
                      className="btn btn-info waves-effect waves-light float-right"
                      type="button"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>

                  {this.state.isShowPublicationTypeModal && (
                    <ModalSelectBox
                      label="publications"
                      field="publications"
                      modalClass="col-sm-6"
                      labelClass="col-sm-3"
                      className="col-sm-9"
                      data={publicationList || []}
                      defaultValue={this.state.publications}
                      isRequired={true}
                      submitBtnTxt="Save"
                      onSubmit={this.onSaveSelectedItems}
                      onClose={() => this.onModalSelectBoxClose()}
                    />
                  )}

                  <div className="col-sm-4 model-class">
                    {this.showSelectItemsLabel("institutions")}
                    <button
                      onClick={() => this.onModalSelectBoxShow("institutions")}
                      className="btn btn-info waves-effect waves-light float-right"
                      type="button"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>

                  {this.state.isShowInstitutionsTypeModal && (
                    <ModalAutoComplete
                      modalClass="col-md-6"
                      placeholder="Type your full institution name and select from the drop down"
                      className="col-sm-12"
                      defaultValue={this.state.institutions}
                      submitBtnTxt="Save"
                      members={institutionList}
                      onSubmit={this.onSaveSelectedInstItems}
                      onClose={() => this.onModalSelectBoxClose()}
                    />
                  )}
                  <div className="col-sm-4 model-class">
                    {this.showSelectItemsLabel("funders")}
                    <button
                      onClick={() => this.onModalSelectBoxShow("funders")}
                      className="btn btn-info waves-effect waves-light float-right"
                      type="button"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>

                  {this.state.isShowPublicationModal && (
                    <ModalSelectBox
                      label="Funders"
                      field="funders"
                      modalClass="col-sm-4"
                      data={funderList || []}
                      defaultValue={this.state.funders}
                      submitBtnTxt="Save"
                      onSubmit={this.onSaveSelectedItems}
                      onClose={() => this.onModalSelectBoxClose()}
                    />
                  )}

                  <div className="col-sm-4 model-class">
                    {this.showSelectItemsLabel("country")}
                    <button
                      onClick={() => this.onModalSelectBoxShow("country")}
                      className="btn btn-info waves-effect waves-light float-right"
                      type="button"
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>

                  {this.state.isShowInstitutionsDealTypeModal && (
                    <ModalSelectBox
                      label="Country"
                      field="country"
                      modalClass="col-sm-4"
                      data={countrylist || []}
                      defaultValue={this.state.country}
                      submitBtnTxt="Save"
                      onSubmit={this.onSaveSelectedItems}
                      onClose={() => this.onModalSelectBoxClose()}
                    />
                  )}
                </div>

                <div></div>
              </form>
            </div>
          </div>
        ),
      },

      {
        title: "Content",
        content: (
          <div className="license-body license-editor">
              <div className={`col-12 bg-white licence alert`}>
                <div className="licence-editor">
           
                      <Ckditor
                        onChange={this.onEditorChange}
                        data={this.state.agreements}
                        config={{ height: "400px" }}
                      />
                  
                </div>
            </div>
          </div>
        ),
      },
    ];
    const { current } = this.state;

    return (
      <HubContent>
        {
          this.props.isLoading !== true ?
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="vat-tax-button email">
                    <Button
                      type="danger"
                      name="danger"
                      text="X"
                      onClick={() => this.props.history.push("/settings/license-configuration")}
                    />
                  </div>

                  <div className="license-step-form">
                    <Steps current={current}>
                      {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>
                    <div className="steps-content">{steps[current].content}</div>

                    <div className="steps-action">
                      {current > 0 && (
                        <Button
                          onClick={() => this.prev()}
                          type="secondary"
                          name="secondary"
                          text="Previous"
                        />
                      )}
                      {current < steps.length - 1 && (
                        <Button
                          type="secondary"
                          name="secondary"
                          text="Next"
                          onClick={() => this.next()}
                        />
                      )}

                      {current === steps.length - 1 && (
                        <Button
                          type="primary"
                          name="primary"
                          text="Submit"
                          onClick={this.onSubmit}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> : <Loading type="flat" />}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  publisher_list: state.orgs && (state.orgs.publisher_list || []),
  publication_types: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  isLoading: state.licence.isLoading,

  countrylist: state.journals.countrylist,
  user: (state.auth && state.auth.user) || false,
});

const mapDispatchToProps = (dispatch) => ({
  CreateLicense: (payload) => dispatch(CreateLicense(payload)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getFunders: () => dispatch(getFunders()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getCountryList: (payload) => dispatch(getCountryList(payload)),
  singleLicenceList: (id) => dispatch(singleLicenceList(id)),
  UpdateLicense: (id, data) => dispatch(UpdateLicense(id, data)),
});

export const License = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LicenseForm)
);