import React from "react";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getPublicPublisher,
  createUpdateLicencePricingControlConfig,
} from "../../../../actions";
import {
  PopupBox,
  SelectBox,
} from "../../../../../core/components/common";


const configOptions = [
  {
    value: 'show_licence_option',
    label: 'Show licence option on payment page',
  },
];

class Add extends BaseComponent {
  state = {
    licence_pricing_config: {
      
    }
  };

  async componentDidMount() {
    this.props.getPublicPublisher();
  }

  onChangeHandler = value => {
    this.setState(prevState => {
      return Object.assign(prevState.licence_pricing_config, value);
    });
  };

  onCheckItem = (e) => { 
    let { licence_pricing_config } = this.state;
    let config_options = licence_pricing_config.config_options || {};
    
    config_options[e.target.field] = e.target.checked

    licence_pricing_config.config_options = config_options

    this.setState({
      licence_pricing_config
    });
  };

  onSubmitHandler = async e => {
    e.preventDefault();

    let data = this.state.licence_pricing_config;

    if (!data.organisation) {
      this.setState({
        isEmpty: `Publisher is required.`
      })
    } else { 
      await this.props.createUpdateLicencePricingControlConfig(data);

      if (this.props.licence_pricing_config.success) {
        this.props.history.push("/licence-pricing-configs/list");
      }
    }
    
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
    });
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add licence pricing configuration
                    </a>
                  </li>
                  
                </ul>
                <div className="tab-content books">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      <div className="form-group row">
                        <SelectBox
                          label="Publisher"
                          onChange={this.onChangeHandler}
                          field="organisation"
                          data={this.props.publishers.results || []}
                          className="col-sm-9"
                          labelClass="col-sm-3"
                          isRequired={true}
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="licence_pricings" className=" col-form-label col-sm-3">{` `}</label>
                        <div className=" col-sm-9">
                          {
                            configOptions && configOptions.map(item => (
                              <Checkbox
                                key={item.value}
                                field={item.value}
                                onChange={this.onCheckItem}
                              >
                                {item.label}
                              </Checkbox>
                            ))
                          }
                        </div>
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  publishers: state.orgs.publisher_list || false,
  licence_pricing_config: state.LicencePricingControlConfig.licence_pricing_config || false,
});

const mapDispatchToProps = dispatch => ({
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  createUpdateLicencePricingControlConfig: payload => dispatch(createUpdateLicencePricingControlConfig(payload)),
});

export const AddLicencePricingControlConfig = connect(mapStateToProps, mapDispatchToProps)(Add);
