import React from "react";
import { AutoComplete } from "antd";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  SelectBox,
  InputBox,
  OaDatePicker,
  PopupBox,
  ModalSelectBox,
  Badge,
  ModalAutoComplete,
  RadioButtonGroup,
  Button,
  Loading
} from "../../../../../core/components/common";
import moment from "moment";
import { connect } from "react-redux";

import {
  updateOaDeal,
  getSingleOaDeal,
  dealInstituationList,
  getAllDealType,
  getAllCurrencyList,
  createOaDeal,
  getPublicatonTypeList,
  getPublicationList,
  getContentTypeList,
  getOaDisciplineList,
  getOaSubjectList,
  getOaDealSummary,
} from "../../../../actions";
import UpdateConstinvidiualMemberOAdeals from "./UpdateConstinvidiualMemberOAdeals";

import { serialize } from "../../../../../core/lib";

const format = "Do MMM YYYY";

class DB extends BaseComponent {
  state = {
    oaDeal: {},
    added_individual_member_deal: null,
    isIndividualMember: false,
    individualMembers: [],
    expire_date: false,
    showConstraint: false,
    all_deal_features: [],
    invoice_config_options: {},
    counter_organisation_name: "",
    config_options: [
      {
        label:
          "Would you like to allow system generates an invoice for an article when processed automatically under this Read & Publish deal?",
        name: "raise_invoice",
      },
      {
        label: "Would you like to add a custom watermark in the invoice?",
        name: "has_custom_watermark",
        depends_on: "raise_invoice",
      },
      {
        label: "Would you like to show net payable Zero in the invoice?",
        name: "is_void_invoice",
        depends_on: "raise_invoice",
      },
    ],
    constrainData: [
      { id: "no_constraint", name: "No cap" },
      { id: "by_total_read", name: "By total article" },
      { id: "by_total_price", name: "By total price" },
    ],
  };

  getPublicationListOnParameterChange = async () => {
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.oaDeal.publication_types &&
      this.state.oaDeal.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.oaDeal.publication_types[0] !== "all"
          ? this.state.oaDeal.publication_types.join(",")
          : "";
    }

    if (this.state.disciplines && this.state.disciplines.length > 0) {
      filterParams.discipline_ids =
        this.state.disciplines[0] !== "all"
          ? this.state.disciplines.join(",")
          : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }
  };

  onChangeHandler = async (value) => {


    if (
      value.deposit_publish_fee !== undefined ||
      value.deposit_read_fee !== undefined
    ) {
      this.setState(
        {
          ...value,
        },
        () => {
          this.setState((prevState) => {
            Object.assign(prevState.oaDeal, {
              amount:
                Number(prevState.deposit_publish_fee || 0) +
                Number(prevState.deposit_read_fee || 0),
            });
          });
        }
      );
    } else {
      this.setState((prevState) => {
        Object.assign(prevState.oaDeal, value);
      });
    }



  /*  this.setState((prevState) => {
      Object.assign(prevState.oaDeal, value);
    });
*/
    if (value.hasOwnProperty("publication_types")) {
      this.getPublicationListOnParameterChange();
    }

    if (value.hasOwnProperty("constraint_by")) {
      this.setState({
        constraint_by: value.constraint_by,
      });
    }


  };

  handePublishersBasedonParent = async (fieldName, data) => {
    this.setState({
      [fieldName]: data,
    });
    this.setState((prevState) => {
      Object.assign(prevState.oaDeal, { [fieldName]: data });
    });

    let payload = {};
    let filterParams = {
      page_size: 999999,
    };

    if (data && data.length > 0) {
      filterParams.publication_type_ids = data !== "all" ? data.join(",") : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }
    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);
    let arrayChecking = Array.isArray(this.props.publication_list.results) ? this.props.publication_list.results: []

    console.log(arrayChecking)
    this.props.publication_list &&
      this.setState({
        publicationList: [
          { id: "all", name: "All publications" },
          ...arrayChecking,
        ],
      });
  };

  onIndiviualSubmit = async () => {
    // ALL MEMBERS DEAL WITH ONE INSTITUTION
    let oaDealDataFormat;
    let oaDealDataForReadNPublish = {
      organisation: this.props.organisation_id,
      id: this.state.id,



    
      member_deals: this.state.individualMembers.map((item) => ({

        ...item,
        expire_date: item.expire_date !== "Invalid date" ? moment(item.expire_date).format("YYYY-MM-DD") : null,
        acceptance_date: item.acceptance_date !== "Invalid date" ? moment(item.acceptance_date).format("YYYY-MM-DD") : null,
        submission_date: item.submission_date !== "Invalid date" ? moment(item.submission_date).format("YYYY-MM-DD") : null,
        start_date: item.start_date !== "Invalid date" ? moment(item.start_date).format("YYYY-MM-DD") : null,
        deal_type: this.state.oaDeal.deal_type || this.state.oaDeal.deal_type,
      })),
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.oaDeal.deal_type,
      added_individual_member_deal: this.state.added_individual_member_deal,
    };
    oaDealDataForReadNPublish.member_deals.map(item=>item.constraint ===null && delete item.constraint)


    let oaDealOtherDataFormat = {
      organisation: this.props.organisation_id,
      id: this.state.id,
      member_deals: this.state.individualMembers.map((item) => ({
        id: item.id,
        invoice_config_options: item.invoice_config_options,
        organisation: this.props.organisation_id,
        counter_organisation_name: item.counter_organisation_name,
        note: item.note,
        counter_organisation: item.counter_organisation,
        deal_type: item.deal_type || this.state.oaDeal.deal_type,
        currency: item.currency,
        discount: item.discount,
        expire_date: item.expire_date !== "Invalid date" ? moment(item.expire_date).format("YYYY-MM-DD") : null,
        acceptance_date: item.acceptance_date !== "Invalid date" ? moment(item.acceptance_date).format("YYYY-MM-DD") : null,
        submission_date: item.submission_date !== "Invalid date" ? moment(item.submission_date).format("YYYY-MM-DD") : null,
        start_date: item.start_date !== "Invalid date" ? moment(item.start_date).format("YYYY-MM-DD") : null,

        content_types:
          item.content_types && item.content_types.length > 0
            ? item.content_types
            : [],
        disciplines:
          item.disciplines && item.disciplines.length > 0
            ? item.disciplines
            : [],

        publication_types:
          item.publication_types && item.publication_types.length > 0
            ? item.publication_types && item.publication_types
            : [],

        publications:
          item.publications && item.publications.length > 0
            ? item.publications && item.publications
            : [],

        subjects:
          item.subjects && item.subjects.length > 0
            ? item.subjects && item.subjects
            : [],
      })),
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.oaDeal.deal_type,
      added_individual_member_deal: this.state.added_individual_member_deal,
    };

    if (this.state.deal_name === "oa-discount") {
      // oaDealOtherDataFormat.member_deals.map((item) => delete item.expire_date);
      oaDealOtherDataFormat.member_deals.map((item) => delete item.currency);
    }
    oaDealDataFormat =
      this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
        ? oaDealDataForReadNPublish
        : oaDealOtherDataFormat;

    //INDIVIDUAL MEMBERS DEAL WITH ONE INSTITUTION

    let constraint;
    if (this.state.constraint_by === "by_total_read") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
        max_total_read: Number(this.state.oaDeal.max_total_read) || 0,
      };
    } else if (this.state.constraint_by === "no_constraint") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
      };
    } else if (this.state.constraint_by === "by_total_price") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,

        max_total_price: Number(this.state.oaDeal.max_total_price) || 0,
      };
    }
    let commonObject = {
      note: this.state.oaDeal.note,
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.oaDeal.deal_type,
      currency: this.state.oaDeal.currency,
      discount: this.state.oaDeal.discount,
      acceptance_date:this.state.oaDeal.acceptance_date ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD"):null,
      submission_date:this.state.oaDeal.submission_date ?  moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD"):null,
      start_date:this.state.oaDeal.start_date ?  moment(this.state.oaDeal.start_date).format("YYYY-MM-DD"):null,
      expire_date: this.state.oaDeal.expire_date ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD"):null,
      deal_features:
        this.state.all_deal_features &&
        this.state.all_deal_features.map((feature) => ({
          id: feature.id,
          selected: feature.selected,
        })),
      
      member_organisations:
        this.state.member_organisations &&
          this.state.member_organisations.length > 0
          ? this.state.member_organisations
          : [],

      content_types:
        this.state.content_types && this.state.content_types.length > 0
          ? this.state.content_types
          : [],
      disciplines:
        this.state.disciplines && this.state.disciplines.length > 0
          ? this.state.disciplines
          : [],

      publication_types:
        this.state.publication_types && this.state.publication_types.length > 0
          ? this.state.publication_types
          : [],

      publications:
        this.state.publications && this.state.publications.length > 0
          ? this.state.publications
          : [],

      subjects:
        this.state.subjects && this.state.subjects.length > 0
          ? this.state.subjects
          : [],
      added_individual_member_deal: this.state.added_individual_member_deal,
    };


    let memberInfoReadAndPublish = {
      ...commonObject,
      invoice_config_options: this.state.invoice_config_options,
      constraint: constraint,
      publisher_deposit_payment: {
        publish_fee:this.state.deposit_publish_fee|| 0,
        read_fee: this.state.deposit_read_fee|| 0,
        amount: this.getPubReadFeeTotal() || 0,
      },

    };
    let memberInfoCreditDeal = {
      ...commonObject,
    };
    let memberInfoPrePayment = {
      ...commonObject,
    };
    let oaDealDataFormatForAllMembers =
      this.state.deal_name === "pre-payment"
        ? memberInfoPrePayment
        : this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
          ? memberInfoReadAndPublish
          : memberInfoCreditDeal;

    let finalOutput =
      this.state.added_individual_member_deal === true
        ? oaDealDataFormat
        : oaDealDataFormatForAllMembers;
    if (finalOutput.expire_date === "Invalid date") {
      delete finalOutput.expire_date
    }
    if (finalOutput.acceptance_date === "Invalid date") {
      delete finalOutput.acceptance_date
    }
    if (finalOutput.submission_date === "Invalid date") {
      delete finalOutput.submission_date
    }
    if (finalOutput.start_date === "Invalid date") {
      delete finalOutput.start_date
    }
  
    let result = await this.props.updateOaDeal(
      this.props.match.params.id,
      finalOutput
    );
    if (result.status === true) {
     this.props.history.push("/oa-deal-activities/oa-deals-table");
    }
  };

  onShowConstraint = () => {
    this.setState({
      showConstraint: true,
    });
  };
  componentDidMount() {
    this.apiDataList()
  }
  apiDataList = async () => {
    const oaDealId = this.props.match.params.id;
    await this.props.getSingleOaDeal(oaDealId);
    this.props.dealInstituationList();
    this.props.getAllDealType();
    this.props.getAllCurrencyList();
    this.props.getContentType();
    this.props.getOaDisciplineList();


    let { oadeal } = this.props;
    let featureCodes = {};

    let all_deal_features =
      oadeal.features &&
      JSON.parse(oadeal.features).map((item) => {
        featureCodes[item.deal_feature_code] = item.selected;

        return {
          id: item.organisation_deal_feature_id,
          deal_feature_name: item.deal_feature_name,
          deal_feature_code: item.deal_feature_code,
          selected: item.selected,
        };
      });

    let publicaionsID = Number(
      oadeal.publication_types &&
      oadeal.publication_types.map((item) => item).toString()
    );
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };
    let data = await this.props.getPublicatonTypeList();
    if (publicaionsID && publicaionsID.length > 0) {
      filterParams.publication_type_ids =
        publicaionsID !== "all" ? publicaionsID.join(",") : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }
    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);
    let oaDealData = {
      deal_type: oadeal.deal_type,
      discount: Math.round(oadeal.discount),
      vat_country: oadeal.vat_country,
      vat_percentage: oadeal.vat_percentage,
      currency: oadeal.currency,
      vat_number: oadeal.vat_number,
      disciplines: oadeal.disciplines,
      subjects: oadeal.subjects,
      publications: oadeal.publications,
      content_types: oadeal.content_types,
      publication_types: oadeal.publication_types,
      note: oadeal.note,
      start_date: oadeal.start_date ? moment(oadeal.start_date) : undefined,
      acceptance_date: oadeal.acceptance_date
        ? moment(oadeal.acceptance_date)
        : undefined,
      submission_date: oadeal.submission_date
        ? moment(oadeal.submission_date)
        : undefined,

      expire_date: oadeal.expire_date ? moment(oadeal.expire_date) : undefined,
    };

    if (oadeal.constraint) {
      Object.assign(oaDealData, {
        constraint_by: oadeal.constraint.constraint_by,
        max_total_price: oadeal.constraint.max_total_price || 0,
        max_total_read: oadeal.constraint.max_total_read || 0,
        expire_date: oadeal.expire_date ? moment(oadeal.expire_date) : undefined,
      });
    }
    let DealType = this.props.deal_type && this.props.deal_type.map((item) => ({
      id: item.id,
      name: item.display_name,
      display_name: item.name,
    }));
    this.dealTypeCheckHandler(DealType &&DealType.find((item) => item.id === oaDealData.deal_type).display_name)
    
    let arrayChecking = Array.isArray(this.props.publication_list && this.props.publication_list.results) ?  this.props.publication_list && this.props.publication_list.results: []

    this.setState({
        publicationList: [
          { id: "all", name: "All publications" },
          ...arrayChecking,
        ],
        DealType: DealType,
        deal_name: DealType &&DealType.find((item) => item.id === oaDealData.deal_type)
          .display_name,
        id: oadeal.id,
        deal_type: oadeal.deal_type,
        deposit_publish_fee:oadeal && oadeal.deposit_payment && oadeal.deposit_payment.publish_fee, 
        deposit_read_fee:oadeal && oadeal.deposit_payment && oadeal.deposit_payment.read_fee, 
        publication_type: data && data.results,
        member_organisation_details:
          oadeal &&
          oadeal.member_organisation_details && oadeal.member_organisation_details.map((item) => ({
            id: item.id,
            value: item.name,
          })),
        individualMembers:
          oadeal.member_deals &&
          oadeal.member_deals.map((item) => ({
            deposit_publish_fee:item && item.deposit_payment && item.deposit_payment.publish_fee, 
            deposit_read_fee: item && item.deposit_payment && item.deposit_payment.read_fee, 
            deposit_amount: item && item.deposit_payment && item.deposit_payment.amount, 
            id: item.id,
            invoice_config_options: item.invoice_config_options,
            organisation: this.props.organisation_id,
            counter_organisation_name: item.counter_organisation_name,
            all_deal_features:
              item.features &&
              JSON.parse(item.features).map((item) => {
                return {
                  id: item.organisation_deal_feature_id,
                  deal_feature_name: item.deal_feature_name,
                  deal_feature_code: item.deal_feature_code,
                  selected: item.selected,
                };
              }),
            note: item.note,

            counter_organisation: item.counter_organisation,
            deal_type: item.deal_type,
            currency: item.currency,
            discount: item.discount,
            acceptance_date: item.acceptance_date
              ? moment(item.acceptance_date)
              : moment(new Date(), format),
            submission_date: item.submission_date
              ? moment(item.submission_date)
              : moment(new Date(), format),
            start_date: item.start_date ? moment(item.start_date) : moment(new Date(), format),
            expire_date: item.expire_date ? moment(item.expire_date) : moment(new Date(), format),
            constraint:item.constraint !== null?{
              constraint_by:
                (item && item.constraint && item.constraint.constraint_by),
              max_total_price:
                (item && item.constraint && item.constraint.max_total_price) || 0,
              max_total_read:
                (item && item.constraint && item.constraint.max_total_read) || 0,
            }:null,
            content_types:
              item.content_types && item.content_types.length > 0
                ? item.content_types
                : [],
            disciplines:
              item.disciplines && item.disciplines.length > 0
                ? item.disciplines
                : [],

            publication_types:
              item.publication_types && item.publication_types.length > 0
                ? item.publication_types && item.publication_types
                : [],

            publications:
              item.publications && item.publications.length > 0
                ? item.publications && item.publications
                : [],

            subjects:
              item.subjects && item.subjects.length > 0
                ? item.subjects && item.subjects
                : [],
          })),
        added_individual_member_deal: oadeal.added_individual_member_deal,
        oaDeal: oaDealData,
        counter_organisation_name: oadeal.counter_organisation_name,

        is_manager_organisation: oadeal.is_manager_counter_organisation,
        counter_organisation: oadeal.counter_organisation,
        member_organisations: oadeal.member_organisations,
        oadeal_currency: oadeal.currency,
        disciplines: oadeal.disciplines,
        subjects: oadeal.subjects,
        publications: oadeal.publications,
        content_types: oadeal.content_types,
        publication_types: oadeal.publication_types,
        showConstraint: oadeal.constraint ? true : false,
        loading: false,
        ...featureCodes,
        all_deal_features: all_deal_features || [],
        invoice_config_options: oadeal.invoice_config_options,
        ...oaDealData,
      })
    

    if (!all_deal_features || all_deal_features.length <= 0) {
      let payload = {
        deal_type: oadeal.deal_type,
      };
      this.getDealSummaryData(payload);
    }

    if (oadeal.disciplines && oadeal.disciplines.length > 0) {
      payload["discipline"] = oadeal.disciplines.join(",");
    }

    await this.props.getOaSubjectList(payload);
      this.props.oa_subjects &&
        this.setState({
          oa_subject_list: this.props.oa_subjects.results,
        });
    

    this.getPublicationListOnParameterChange();
  }
  componentWillUnmount() {
             // fix Warning: Can't perform a React state update on an unmounted component
             this.setState = (state,callback)=>{
              return;
          };
  }
  getDealSummaryData = async (payload = {}) => {
    await this.props.getOaDealSummary(this.props.organisation_id, payload);

    if (this.props.oadeal_summary && this.props.oadeal_summary.deal_features) {
      this.setState({
        all_deal_features: this.props.oadeal_summary.deal_features,
      });
    }
  };

  onChangeRadioButtonHandler = (value) => {
    let { all_deal_features } = this.state;
    let featureCodes = {};

    let itemKey = Object.keys(value).length && Object.keys(value)[0];
    all_deal_features = all_deal_features.map((item) => {
      if (itemKey === `feature_${item.id}`) {
        if (item.deal_feature) {
          featureCodes[item.deal_feature.feature_code] = value[itemKey];
        } else if (item.deal_feature_code) {
          featureCodes[item.deal_feature_code] = value[itemKey];
        }
        Object.assign(item, { selected: value[itemKey] });
      }

      return item;
    });

    if (!this.state["OADF-00001"] || !featureCodes["OADF-00001"]) {
      this.setState({
        invoice_config_options: null,
      });
    }

    this.setState({
      all_deal_features,
      ...featureCodes,
    });
  };

  renderDealFeatues = (items) => {
    return items.map((item) => {
      return (
        <div key={item.id} className="row">
          <div className="col-md-8">

            <p>
              {(item.deal_feature && item.deal_feature.name) ||
                item.deal_feature_name}
            </p>
          </div>
          <div className="col-md-2">

            <RadioButtonGroup
              onChange={this.onChangeRadioButtonHandler}
              field={`feature_${item.id}`}
              options={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
              value={item.selected}
            />
          </div>

        </div>
      );
    });
  };

  onChangeInvoiceConfigOptionHandler = (value) => {
    let { invoice_config_options } = this.state;

    if (!invoice_config_options) {
      invoice_config_options = {};
    }

    if (
      value.hasOwnProperty("has_custom_watermark") &&
      !value.has_custom_watermark
    ) {
      value.custom_watermark = null;
    }

    if (value.hasOwnProperty("raise_invoice") && !value.raise_invoice) {
      value.has_custom_watermark = false;
      value.is_void_invoice = false;
      value.custom_watermark = null;
    }

    Object.assign(invoice_config_options, value);

    this.setState({
      invoice_config_options,
    });
  };

  renderInvoiceConfigOptions = (items) => {
    let { invoice_config_options } = this.state;

    return items.map((item, index) => {
      if (
        item.hasOwnProperty("depends_on") &&
        (!invoice_config_options || !invoice_config_options[item.depends_on])
      ) {
        return null;
      } else if (!this.state["OADF-00001"]) {
        return null;
      }

      return (
        <div key={`${item.name}-${index}`} className="row">
          <div className="col-md-8">
            <p>{item.label}</p>
          </div>
          <div className="col-md-2">

            <RadioButtonGroup
              onChange={this.onChangeInvoiceConfigOptionHandler}
              field={item.name}
              options={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
              value={
                !!invoice_config_options && !!invoice_config_options[item.name]
              }
            />
          </div>

          {item.name === "has_custom_watermark" &&
            !!invoice_config_options &&
            !!invoice_config_options[item.name] && (
              <InputBox
                className="col-md-2"

                label={false}
                onChange={this.onChangeInvoiceConfigOptionHandler}
                field="custom_watermark"
                placeholder="Enter custom watermark"
                defaultValue={
                  !!invoice_config_options &&
                  invoice_config_options.custom_watermark
                }
              />
            )}
        </div>
      );
    });
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.oaDeal, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });

        if (fieldName === "disciplines") {
          if (data.length > 0) {
            let payload = {
              discipline: data[0] !== "all" ? data.join(",") : "",
            };

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }

          this.getPublicationListOnParameterChange();
        } else if (fieldName === "subjects") {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "disciplines":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "subjects":
        this.setState({
          isShowSubjectModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      case "publication_types":
        this.setState({
          isShowPublicationTypeModal: true,
        });
        break;
      case "member_organisations":
        this.setState({
          isShowMemberTypeModal: true,
        });
        break;
      default:
        break;
    }
  };
  onSaveSelectedMembersItems = (members) => {
    this.setState({
      member_organisation_details: members,
      member_organisations: members && members.map((item) => item.id),
    });
  };
  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
      isShowPublicationTypeModal: false,
      isShowMemberTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "publication_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Publication type selected`
              : `${fieldValues.length} Publication types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Publications type"
              : "Select publications type";
        }
        break;
      case "disciplines":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} discipline selected`
              : `${fieldValues.length} disciplines selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All disciplines"
              : "Select discipline";
        }

        break;
      case "subjects":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} subject selected`
              : `${fieldValues.length} subjects selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All subjects"
              : "Select subject";
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} publication selected`
              : `${fieldValues.length} publications selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All publications"
              : "Select publication";
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Content type selected`
              : `${fieldValues.length} Content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All content types"
              : "Select content type";
        }
        break;
      case "member_organisations":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Consortia Member Selected`
              : `${fieldValues.length} Consortia Members Selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Consortia Members Selected"
              : "Click on PLUS button to select Consortia members ";
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  addInvidualMember = (e) => {
    e.preventDefault();
    this.setState({
      isIndividualMember: true,
    });
  };

  onCloseInvidualMember = () => {
    this.setState({
      isIndividualMember: false,
      editing: false,
    });
  };

  onSaveInvidualMemberHander = (member_info) => {
    let constraint;
    if (member_info.constraint_by === "by_total_read") {
      constraint = {
        constraint_by: member_info.constraint_by,
        max_total_read: Number(member_info.max_total_read) || 0,
      };
    } else if (member_info.constraint_by === "no_constraint") {
      constraint = {
        constraint_by: member_info.constraint_by,
      };
    } else if (member_info.constraint_by === "by_total_price") {
      constraint = {
        constraint_by: member_info.constraint_by,
        max_total_price: Number(member_info.max_total_price),
      };
    }

    let commonObject = {
      counter_organisation_name: member_info.counter_organisation_name,

      counter_organisation: member_info.counter_organisation,
      organisation: this.props.organisation_id,
      id: member_info.id,
      note: member_info.note,
      all_deal_features: member_info.all_deal_features,
      member_organisations: member_info.member_organisations,
      deal_type: member_info.deal_type,
      currency: member_info.currency,
      discount: member_info.discount,
      expire_date: moment(member_info.expire_date).format("YYYY-MM-DD"),
      acceptance_date: moment(member_info.acceptance_date).format("YYYY-MM-DD"),
      submission_date: moment(member_info.submission_date).format("YYYY-MM-DD"),
      start_date: moment(member_info.start_date).format("YYYY-MM-DD"),

      deal_features:
        member_info.all_deal_features &&
        member_info.all_deal_features.map((feature) => ({
          id: feature.id,
          selected: feature.selected,
        })),
      content_types:
        member_info.content_types && member_info.content_types.length > 0
          ? member_info.content_types
          : [],
      disciplines:
        member_info.disciplines && member_info.disciplines.length > 0
          ? member_info.disciplines
          : [],

      publication_types:
        member_info.publication_types &&
          member_info.publication_types.length > 0
          ? member_info.publication_types && member_info.publication_types
          : [],

      publications:
        member_info.publications && member_info.publications.length > 0
          ? member_info.publications && member_info.publications
          : [],

      subjects:
        member_info.subjects && member_info.subjects.length > 0
          ? member_info.subjects && member_info.subjects
          : [],
    };

    if (this.state.deal_name === "oa-discount") {
      delete commonObject.currency;
    }
    let memberInfoData;
    let memberInfoReadAndPublish = {
      ...commonObject,
      invoice_config_options: member_info.invoice_config_options,
      constraint: constraint,
      publisher_deposit_payment: {
        publish_fee: member_info.publish_fee || 0,
        read_fee: member_info.read_fee || 0,
        amount: Number(member_info.deposit_publish_fee || 0) + Number(member_info.deposit_read_fee || 0) || member_info.totals

      },
      
    };
    let memberInfoCreditDeal = {
      ...commonObject,
    };
    let memberInfoPrePayment = {
      ...commonObject,
    };

    memberInfoData = this.state.deal_name === "pre-payment"
      ? memberInfoPrePayment
      : this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
        ? memberInfoReadAndPublish
        : memberInfoCreditDeal;


    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.individualMembers[prevState.editing] = memberInfoData;
        prevState.editing = false;
      } else {
        prevState.individualMembers.push(memberInfoData);
      }
      prevState.isIndividualMember = false;
      return prevState;
    });
  };

  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isIndividualMember: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.individualMembers.splice(id, 1);
      return prevState;
    });
  };
  onChangeMemberRadioHandler = (value) => {
    this.setState({
      added_individual_member_deal: value.added_individual_member_deal,
    });
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();
    let constraint;
    if (this.state.constraint_by === "by_total_read") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
        max_total_read: this.state.oaDeal.max_total_read || 0,
      };
    } else if (this.state.constraint_by === "no_constraint") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
      };
    } else if (this.state.constraint_by === "by_total_price") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
        max_total_price: this.state.oaDeal.max_total_price || 0,
      };
    }
    let commonObject = {
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.oaDeal.deal_type,
      currency: this.state.oaDeal.currency,
      discount: this.state.oaDeal.discount,
      deal_features:
        this.state.all_deal_features &&
        this.state.all_deal_features.map((feature) => ({
          id: feature.id,
          selected: feature.selected,
        })),
      content_types:
        this.state.content_types && this.state.content_types.length > 0
          ? this.state.content_types
          : [],
      disciplines:
        this.state.disciplines && this.state.disciplines.length > 0
          ? this.state.disciplines
          : [],

      publication_types:
        this.state.publication_types && this.state.publication_types.length > 0
          ? this.state.publication_types
          : [],

      publications:
        this.state.publications && this.state.publications.length > 0
          ? this.state.publications
          : [],

      subjects:
        this.state.subjects && this.state.subjects.length > 0
          ? this.state.subjects
          : [],
      added_individual_member_deal: null,
      member_deals: [],
      member_organisations: [],
    };
    if (this.state.deal_name === "oa-discount") {
      delete commonObject.currency;
    }
    let readAndPublishOaDeal = {
      ...commonObject,
      constraint: constraint,
      invoice_config_options: this.state.invoice_config_options,
      expire_date:this.state.oaDeal.expire_date && moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
      start_date:this.state.oaDeal.start_date&& moment(this.state.oaDeal.start_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.start_date).format("YYYY-MM-DD") : null,
      acceptance_date: this.state.oaDeal.acceptance_date &&moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.acceptance_date).format(
        "YYYY-MM-DD"
      ) : null,
      submission_date: this.state.oaDeal.submission_date  && moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.submission_date).format(
        "YYYY-MM-DD"
      ) : null,
      publisher_deposit_payment: {
        publish_fee: this.state.deposit_publish_fee,
        read_fee: this.state.deposit_read_fee,
        amount: this.getPubReadFeeTotal() || 0,
      },
    };


    if (this.state.isDealReadSubscribe !== true) {
      delete readAndPublishOaDeal.deal_features

    }

    let creditOaDeal = {
      ...commonObject,
      acceptance_date: this.state.oaDeal.acceptance_date &&moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.acceptance_date).format(
        "YYYY-MM-DD"
      ) : null,
      submission_date: this.state.oaDeal.submission_date  && moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.submission_date).format(
        "YYYY-MM-DD"
      ) : null,
      expire_date:this.state.oaDeal.expire_date && moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
    };
   

    let prePaymentOaDeal = {
      ...commonObject,
      acceptance_date: this.state.oaDeal.acceptance_date &&moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.acceptance_date).format(
        "YYYY-MM-DD"
      ) : null,
      submission_date: this.state.oaDeal.submission_date  && moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.submission_date).format(
        "YYYY-MM-DD"
      ) : null,
      expire_date: this.state.oaDeal.expire_date  && moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(this.state.oaDeal.expire_date).format(
        "YYYY-MM-DD"
      ) : null
    };
    let oaDealDataFormat =
      this.state.deal_name === "pre-payment"
        ? prePaymentOaDeal
        : this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
          ? readAndPublishOaDeal
          : creditOaDeal;
    if (oaDealDataFormat.expire_date === "Invalid date") {
      delete oaDealDataFormat.expire_date
    }
    if (oaDealDataFormat.acceptance_date === "Invalid date") {
      delete oaDealDataFormat.acceptance_date
    }
    if (oaDealDataFormat.submission_date === "Invalid date") {
      delete oaDealDataFormat.submission_date
    }
    if (oaDealDataFormat.start_date === "Invalid date") {
      delete oaDealDataFormat.start_date
    }
    let result = await this.props.updateOaDeal(
      this.props.match.params.id,
      oaDealDataFormat
    );
    if (result.status === true) {
     this.props.history.push("/oa-deal-activities/oa-deals-table");
    }
  };

  getPubReadFeeTotal = () => {
    let depositPublishFee = Number(this.state.deposit_publish_fee) || 0;
    let depositReadFee = Number(this.state.deposit_read_fee) || 0;
    let total = depositPublishFee + depositReadFee;

    return Number(total||0 ).toFixed(2);
  };

  onSelect = (value, item) => {
    this.setState({
      counter_organisation: item.id,
      is_manager_organisation:
        item.is_manager_organisation.toString() === "true" ? true : false,
    });
  };
  onChange = (value) => {
    this.setState({
      counter_organisation_name: value,
    });
  };
  onSearch = async (value) => {
    await this.props.dealInstituationList(value);
    this.setState({
      odealInstList:
        this.props.oaDeal_inst &&
        this.props.oaDeal_inst.map((item) => ({
          id: item.id,
          value: item.value,
          is_manager_organisation: item.is_manager_organisation.toString(),
        })),
    });
  };

  dealModelSetupHandler = () => (
    <div className="row" style={{ marginTop: 30 }}>

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("disciplines")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("disciplines")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowDisciplineModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Disciplines"
          field="disciplines"
          className="col-sm-9"
          data={
            this.props.oa_disciplines && [
              { id: "all", name: "All disciplines" },
              ...this.props.oa_disciplines.results,
            ]
          }
          defaultValue={this.state.disciplines}
          isRequired={false}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("subjects")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("subjects")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowSubjectModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Subjects"
          field="subjects"
          className="col-sm-9"
          data={
            (this.state.oa_subject_list && [
              { id: "all", name: "All subjects" },
              ...this.state.oa_subject_list,
            ]) ||
            []
          }
          defaultValue={this.state.subjects}
          isRequired={true}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("publication_types")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("publication_types")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowPublicationTypeModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Publications types"
          labelClass="col-md-4"
          className="col-sm-8"
          field="publication_types"
          data={
            (this.props.publication_type && [
              { id: "all", name: "All publication types" },
              ...this.props.publication_type,
            ]) ||
            []
          }
          defaultValue={this.state.publication_types}
          isRequired={false}
          submitBtnTxt="Save"
          onSubmit={this.handePublishersBasedonParent}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("publications")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("publications")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowPublicationModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Publications"
          field="publications"
          className="col-sm-9"
          data={this.state.publicationList || []}
          defaultValue={this.state.publications}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("content_types")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("content_types")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowContentTypeModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Content types"
          field="content_types"
          className="col-sm-9"
          data={
            (this.props.content_type && [
              { id: "all", name: "All content types" },
              ...this.props.content_type,
            ]) ||
            []
          }
          defaultValue={this.state.content_types}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

    </div>
  )
  individualMembersRadioBox = () => (
    <div className="row" style={{ marginTop: 30 }}>
      <div className="col-md-6">
        <p className="mr-3">
          Do you want to create OA deal separately for each
          member?
          </p>
      </div>

      <div className="col-md-3">
        <RadioButtonGroup
          onChange={this.onChangeMemberRadioHandler}
          field="added_individual_member_deal"
          options={[
            { id: true, name: "Yes" },
            { id: false, name: "No" },

          ]}
          value={this.state.added_individual_member_deal}
        />
      </div>
    </div>
  )
  readAndPublisherDeal = () => (
    <div>
      <div className="row">
        <div className="col-md-3">
          <label className="deal-label-name">Select Currency</label>

          <SelectBox
            className="deal-currency"
            disabled={this.state.oadeal_currency !== null ? true:false}
            onChange={this.onChangeHandler}
            field="currency"
            data={this.props.oadeal_currency}
            isRequired={false}
            defaultValue={this.state.oadeal_currency}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Enter your discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"

            defaultValue={this.state.discount}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Deal Start Date</label>
          <OaDatePicker
            className="deal-start-date"
            field="start_date"
            placeholder="Select Start Date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              this.state.start_date || undefined
            }
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Deal Expiry Date</label>
          <OaDatePicker
            className="deal-expire-date"
            field="expire_date"
            placeholder="Select Expiry Date"

            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              this.state.expire_date || undefined
            }
          />

        </div>
      </div>
      <div className="row">

        <div className="col-md-3">
          <label className="deal-label-name">Select Submission Date</label>

          <OaDatePicker
                        placeholder="Select Submission Date"

            className="deal-submission-date"
            field="submission_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              this.state.submission_date || undefined
            }
          />

        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Acceptance Date </label>

          <OaDatePicker
                                    placeholder="Select Acceptance Date"

            className="deal-acceptance-date"
            field="acceptance_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              this.state.acceptance_date || undefined
            }
          />


        </div>
        <div className="col-md-3">
          <label className="deal-label-name">{this.state.deal_name === "read & publish"
            ? `Enter Publish Fee`
            : `Enter Subscription Fee`}</label>
          <InputBox

            onChange={this.onChangeHandler}
            field="deposit_publish_fee"
            defaultValue={Number(this.state.deposit_publish_fee && this.state.deposit_publish_fee).toFixed(2)}

            className="deal-publish-fee"
            InputType="number"
          />
        </div>

        <div className="col-md-3">
          <label className="deal-label-name">{this.state.deal_name === "read & publish"
            ? `Enter Read Fee`
            : `Enter Other Fee`}</label>
          <InputBox

            onChange={this.onChangeHandler}
            field="deposit_read_fee"
            defaultValue={Number(this.state.deposit_read_fee).toFixed(2)}

            className="deal-read-fee"
            InputType="number"
          />
        </div>


        <div className="col-md-3">
          <label className="deal-label-name">{this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
            ? ` Total Amount`
            : `Enter deposit amount`}</label>

          {this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
            ? <p className="total-amount">{this.getPubReadFeeTotal()}</p> : <InputBox
              onChange={this.onChangeHandler}
              field="deposit_amount"
              className="deal-deposit-amount"
              InputType="number"

            />}
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Cap or No Cap</label>

          <SelectBox
            className="deal-set-cap"
            onChange={this.onChangeHandler}
            placeholder="Select"
            field="constraint_by"
            data={this.state.constrainData}
            isRequired={false}
            defaultValue={this.state.constraint_by}
          />
        </div>
        {this.state.constraint_by ===
          "by_total_read" && (
            <div className="col-md-3">
              <label className="deal-label-name">Enter No. of Articles</label>

              <InputBox
                className="deal-articles"
                onChange={this.onChangeHandler}
                field="max_total_read"
                defaultValue={this.state.max_total_read}
                InputType="number"
              />
            </div>


          )}

        {this.state.constraint_by ===
          "by_total_price" && (
            <div className="col-md-3">
              <label className="deal-label-name">Enter Cap Amount</label>

              <InputBox
                className="deal-price"
                onChange={this.onChangeHandler}
                field="max_total_price"
                defaultValue={this.state.max_total_price}
              />

            </div>
          )}


      </div>

      <div>

      </div>

      {this.dealModelSetupHandler()}
      <div className="all-deal-feature">

        {this.state.all_deal_features &&
          this.state.all_deal_features.length > 0 &&
          this.renderDealFeatues(
            this.state.all_deal_features
          )}

        {this.state.config_options &&
          this.state.config_options.length > 0 &&
          this.renderInvoiceConfigOptions(
            this.state.config_options
          )}

      </div>

    </div>
  )
  currencyAndDiscount = () => (
    <div>
      <div className="row">
        <div className="col-md-3">
          <label className="deal-label-name">Select Currency</label>
          <SelectBox
            onChange={this.onChangeHandler}
            field="currency"
            disabled={this.state.oadeal_currency !== null ? true:false}

            className="deal-currency"
            data={this.props.oadeal_currency}
            isRequired={false}
            defaultValue={this.state.oadeal_currency}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Enter Discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"
            defaultValue={this.state.discount}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Submission Date</label>

          <OaDatePicker
                        placeholder="Select Submission Date"

            className="deal-submission-date"
            field="submission_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              this.state.submission_date || undefined
            }
          />

        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Acceptance Date </label>

          <OaDatePicker
                                    placeholder="Select Acceptance Date"

            className="deal-acceptance-date"
            field="acceptance_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              this.state.acceptance_date || undefined
            }
          />


        </div>

      </div>

    </div>
  )
  prePaymentDeal = () => (
    <div>
      <div className="row">
        <div className="col-md-3">
          <label className="deal-label-name">Select Currency</label>
          <SelectBox
            onChange={this.onChangeHandler}
            field="currency"
            disabled={this.state.oadeal_currency !== null ? true:false}

            className="deal-currency"
            data={this.props.oadeal_currency}
            isRequired={false}
            defaultValue={this.state.oadeal_currency}
          />
        </div>

        <div className="col-md-3">
          <label className="deal-label-name">Enter Discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"
            defaultValue={this.state.discount}
          />
        </div>

        {
          this.state.deal_name === "pre-payment" && 
          <div className="col-md-3">
                    <label className="deal-label-name">Select Submission Date</label>
          
                    <OaDatePicker
                                  placeholder="Select Submission Date"
          
                      className="deal-submission-date"
                      field="submission_date"
                      onChange={this.onChangeHandler}
                      showTime={false}
                      format={format}
                      defaultValue={
                        this.state.submission_date || undefined
                      }
                    />
          
                  </div>
        }
        {
          this.state.deal_name === "pre-payment" && 
          <div className="col-md-3">
            <label className="deal-label-name">Select Acceptance Date </label>
  
            <OaDatePicker
             placeholder="Select Acceptance Date"
  
              className="deal-acceptance-date"
              field="acceptance_date"
              onChange={this.onChangeHandler}
              showTime={false}
              format={format}
              defaultValue={
                this.state.acceptance_date || undefined
              }
            />
  
  
          </div>
        }
        {
          this.state.deal_name === "pre-payment" && 
          <div className="col-md-3">
          <label className="deal-label-name">Deal Expiry Date</label>

          <OaDatePicker
            className="deal-expire-date"
            field="expire_date"
            onChange={this.onChangeHandler}
            placeholder="Select Expiry Date"
            showTime={false}
            format={format}
            defaultValue={
              this.state.expire_date ||
             undefined
            }
          />
        </div>
        }
    
      </div>
      <div className="row">
        <div className="col-md-12" style={{ marginTop: 30 }}>
          <Button type="success" text="Customise OA Deal Setup" name="success"
            icon={true} iconName={this.state.isdealModelShowHandler} onClick={this.dealModelShowHandler} />
        </div>
      </div>


      {this.state.isdealModelShowHandler && this.dealModelSetupHandler()}

    </div>
  )
  oaDiscountDeal = () => (
    <div>
      <div className="row">
        {
          this.state.deal_name === "oa-discount" && <div className="col-md-3">
            <label className="deal-label-name">Enter Discount%</label>
            <InputBox
              onChange={this.onChangeHandler}
              field="discount"
              className="deal-discount"
              InputType="number"
              defaultValue={this.state.oaDeal.discount}
            />
          </div>
        }
       { this.state.deal_name === "oa-discount" && 

        <div className="col-md-3">
        <label className="deal-label-name">Deal Start Date</label>
        <OaDatePicker
          className="deal-start-date"
          field="start_date"
          placeholder="Select Start Date"
          onChange={this.onChangeHandler}
          showTime={false}
          format={format}
          defaultValue={
            this.state.start_date || undefined
          }
        />
        </div>}
        
        {
          this.state.deal_name === "oa-discount" && 
          <div className="col-md-3">
                    <label className="deal-label-name">Select Submission Date</label>
          
                    <OaDatePicker
                                  placeholder="Select Submission Date"
          
                      className="deal-submission-date"
                      field="submission_date"
                      onChange={this.onChangeHandler}
                      showTime={false}
                      format={format}
                      defaultValue={
                        this.state.submission_date || undefined
                      }
                    />
          
                  </div>
        }
        {
          this.state.deal_name === "oa-discount" && 
          <div className="col-md-3">
            <label className="deal-label-name">Select Acceptance Date </label>
  
            <OaDatePicker
             placeholder="Select Acceptance Date"
  
              className="deal-acceptance-date"
              field="acceptance_date"
              onChange={this.onChangeHandler}
              showTime={false}
              format={format}
              defaultValue={
                this.state.acceptance_date || undefined
              }
            />
  
  
          </div>
        }
        {
          this.state.deal_name === "oa-discount" && 
          <div className="col-md-3">
          <label className="deal-label-name">Select Deal Expiry Date</label>

          <OaDatePicker
            className="deal-expire-date"
            field="expire_date"
            onChange={this.onChangeHandler}
            placeholder="Select Expiry Date"
            showTime={false}
            format={format}
            defaultValue={
              this.state.expire_date ||
             undefined
            }
          />
        </div>
        }

      </div>
      {this.dealModelSetupHandler()}
    </div>
  )

  creditDeal = () => (
    <div>

      <div className="row">
        <div className="col-md-3">
          <label className="deal-label-name">Select Currency</label>
          <SelectBox
            onChange={this.onChangeHandler}
            field="currency"
            className="deal-currency"
            data={this.props.oadeal_currency}
            isRequired={false}
            defaultValue={this.state.oadeal_currency}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Enter Discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"
            defaultValue={this.state.discount}
          />
        </div>
        {
          this.state.deal_name === "credit" && 
          <div className="col-md-3">
                    <label className="deal-label-name">Select Submission Date</label>
          
                    <OaDatePicker
                                  placeholder="Select Submission Date"
          
                      className="deal-submission-date"
                      field="submission_date"
                      onChange={this.onChangeHandler}
                      showTime={false}
                      format={format}
                      defaultValue={
                        this.state.submission_date || undefined
                      }
                    />
          
                  </div>
        }
        {
          this.state.deal_name === "credit" && 
          <div className="col-md-3">
            <label className="deal-label-name">Select Acceptance Date </label>
  
            <OaDatePicker
                                      placeholder="Select Acceptance Date"
  
              className="deal-acceptance-date"
              field="acceptance_date"
              onChange={this.onChangeHandler}
              showTime={false}
              format={format}
              defaultValue={
                this.state.acceptance_date || undefined
              }
            />
  
  
          </div>
        }

        {this.state.deal_name === "credit" && (
          <div className="col-md-3">
            <label className="deal-label-name">Select Deal Expiry Date</label>

            <OaDatePicker
              className="deal-expire-date"
              field="expire_date"
              onChange={this.onChangeHandler}
              placeholder="Select Expiry Date"
              showTime={false}
              format={format}
              defaultValue={
                this.state.expire_date ||
               undefined
              }
            />
          </div>
        )}

      </div>
      {this.dealModelSetupHandler()}
    </div>
  )

  dealCompontent = () => (
    <div>
      <div className="row">
        {
          this.state.isDealReadSubscribe !== true ?

            this.state.deal_name !== this.dealTyeNameCheckerHandler().checkExpireDate[this.state.deal_name] &&

            <div className="col-md-3">
              <label className="deal-label-name">Select Deal Expiry Date</label>

              <OaDatePicker
                className="deal-expire-date"
                field="expire_date"
                onChange={this.onChangeHandler}
                placeholder="Select Expiry Date"
                showTime={false}
                format={format}
                defaultValue={
                  this.state.expire_date ||
                  undefined
                }
              />
            </div>

            : (this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div className="col-md-6" style={{ marginTop: 20 }}>
              <Button type="success" text="Customise OA Deal Setup" name="success"
                icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
            </div>
        }

        {
          this.state.isDealReadSubscribe !== true &&
          (this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div className="col-md-6" style={{ marginTop: 35, marginBottom: 50 }}>
            <Button type="success" text="Customise OA Deal Setup" name="success"
              icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
          </div>
        }
      </div>
      { this.state.isDealCredit && this.creditDeal()}
      { this.state.isDealPrePayment && this.prePaymentDeal()}
      { this.state.isDealOaDiscount && this.oaDiscountDeal()}
      { this.state.isDealReadSubscribe && this.readAndPublisherDeal()}
    </div >

  )
  dealOtherHandler = () => {
    this.setState({
      isDealReadSubscribe: !this.state.isDealReadSubscribe,
      isDealPrePayment: false,
      isDealCredit: false,
      isDealOaDiscount: false,

    })
  }
  dealModelShowHandler = () => {
    this.setState({
      isDealReadSubscribe: false,
      isdealModelShowHandler: !this.state.isdealModelShowHandler
    })
  }

  dealTypeCheckHandler = (value) => {
    switch (value) {
      case "pre-payment":
        this.setState({
          isDealPrePayment: !this.state.isDealPrePayment,
          isDealCredit: false,
          isDealOaDiscount: false,
          isDealReadSubscribe: false,
        })
        break;
      case "credit":
        this.setState({
          isDealCredit: !this.state.isDealCredit,
          isDealPrePayment: false,
          isDealOaDiscount: false,
          isDealReadSubscribe: false,

        })
        break;
      case "oa-discount":
        this.setState({
          isDealOaDiscount: !this.state.isDealOaDiscount,
          isDealCredit: false,
          isDealPrePayment: false,
          isDealReadSubscribe: false,

        })
        break;
      default:
        this.setState({
          isDealOaDiscount: false,
          isDealCredit: false,
          isDealPrePayment: false,
          isDealReadSubscribe: false,
        })
        break;
    }
  }

  dealTyeNameCheckerHandler = () => {

    let checkExpireDate = {
      "pre-payment": "pre-payment",
      "credit": "credit",
      "oa-discount": "oa-discount"

    }

    return {
      checkExpireDate
    }
  }
  selectDealForAllMembersComponent = (value) => (
    <div>
      { this.state.isDealOaDiscount && this.individualMembersRadioBox()}

      <div className="row">
        {
          this.state.isDealReadSubscribe !== true ? this.state.deal_name !== this.dealTyeNameCheckerHandler().checkExpireDate[this.state.deal_name] && (<div className="col-md-3">
            <label className="deal-label-name">Select Deal Expiry Date</label>
            <OaDatePicker
              className="deal-expire"
              field="expire_date"
              
              onChange={this.onChangeHandler}
              placeholder="Select Expiry Date"
              showTime={false}
              format={format}
              defaultValue={
                this.state.expire_date ||
                undefined
              }
            />
          </div>) : (this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div className="col-md-4" style={{
            marginTop: 30
          }}>
            <Button type="success" text="Customise OA Deal Setup" name="success"
              icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
          </div>
        }


        <div
          className="col-md-3"
          style={{
            fontSize: 14,
            marginTop: 30,
          }}
        >
          <label>
            {this.showSelectItemsLabel("member_organisations")}
          </label>
        </div>

        <div style={{
          marginTop: 30
        }}>
          <button
            onClick={() =>
              this.onModalSelectBoxShow("member_organisations")
            }
            className="btn btn-success waves-effect waves-light float-right"
            type="button"
          >
            <i className="fa fa-plus"></i>
          </button>

          {this.state.isShowMemberTypeModal && (
            <ModalAutoComplete
              field="member_organisations"
              modalClass="col-md-6"
              className="col-sm-9"
              placeholder="Please select your full Member name"
              defaultValue={value}
              submitBtnTxt="Save"
              members={this.state.member_organisation_details}
              onSubmit={this.onSaveSelectedMembersItems}
              onClose={() => this.onModalSelectBoxClose()}
            />
          )}
        </div>
      </div>

      <div className="row">

        {
          this.state.isDealReadSubscribe !== true &&

          <div className="col-md-12">
            {(this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div style={{
              marginTop: 30,

            }}>
              <Button type="success" text="Customise OA Deal Setup" name="success"
                icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
            </div>}
          </div>
        }
      </div>

      { this.state.isDealCredit && this.individualMembersRadioBox()}

      { this.state.isDealCredit && this.creditDeal()}

      { this.state.isDealPrePayment && this.individualMembersRadioBox()}

      { this.state.isDealPrePayment && this.prePaymentDeal()}

      { this.state.isDealOaDiscount && this.oaDiscountDeal()}
      { this.state.isDealReadSubscribe && this.individualMembersRadioBox()}
      { this.state.isDealReadSubscribe && this.readAndPublisherDeal()}
    </div >

  )

  onChangeHandlerDealType = (value) => {
    if (value.deal_type) {
      this.setState({
        deposit_publish_fee: 0,
        deposit_read_fee: 0,
        oaDeal: {
          deposit_amount: 0,
          constraint: {
            constraint_by: "no_constraint"
          },

          content_types: [],
          disciplines: [],
          publication_types: [],
          publications: [],
          subjects: [],
          added_individual_member_deal: null,
          member_deals: [],
          member_organisations: [],

        }
      })


    }
    this.getDealSummaryData({ deal_type: value.deal_type })
    let select_deal_type = this.props.deal_type && this.props.deal_type.find(
      (item) => item.id === value.deal_type
    );
    if (select_deal_type.name === "read & publish") {
      value.invoice_config_options = null;
    }
    this.dealTypeCheckHandler(select_deal_type.name)

    this.setState({
      deal_type: value.deal_type,
      deal_name: select_deal_type.name,
    });
  };

  render() {

    const { odealInstList, DealType } = this.state;
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent>

        <>
          {this.state.success && (
            <PopupBox
              NoText="Close"
              msg="You have successfully created OA Deal."
              onCancel={this.redirectLink}
            />
          )}
        </>
        {errorHander}
        {this.props.loading !== true ? <div className="row">

          <div className="col-lg-12">
            <div className="card m-b-20">

              <div className="card-body">
                <div className="row create-oa-deal">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <ul className="nav nav-pills nav-justified" role="tablist">
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#home-1"
                          role="tab"
                        >
                          Update OA Deal
                    </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <div className="vat-tax-button email">
                      <Button
                        type="danger"
                        name="danger"
                        text="X"
                        onClick={() => this.props.history.goBack()}
                      />
                    </div>
                  </div>
                </div>
                <div className="create-oa-deal-content ">
                  <div className="row institution-create-oadeal">

                    <div className="col-md-3">
                      <label className="deal-label-name">Select Deal Type</label>
                      <SelectBox
                        className="select-deal-type"
                        onChange={this.onChangeHandlerDealType}
                        field="deal_type"
                        data={DealType}
                        disabled={true}
                        isRequired={false}
                        defaultValue={this.state.deal_type}
                      />

                    </div>
                    <div className="col-md-9">
                      <div className="row auto-complete-search">
                        <label className="deal-label-name">
                         Enter Institution or Consortia Name
                        </label>
                        <div className="col-md-12">
                          <AutoComplete
                            disabled={true}
                            value={this.state.counter_organisation_name}
                            options={odealInstList}
                            style={{
                              width: "100%",
                            }}
                            onSelect={(val, option) =>
                              this.onSelect(val, option)
                            }
                            onSearch={this.onSearch}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>


                  </div>

                  {
                    this.state.is_manager_organisation !== true && (
                      this.dealCompontent()
                    )
                  }
                  {this.state.added_individual_member_deal === true &&
                    this.individualMembersRadioBox()
                  }

                  {this.state.is_manager_organisation === true &&
                    this.state.added_individual_member_deal === false && (
                      <div>
                        { this.state.isDealOaDiscount && this.individualMembersRadioBox()}

                        <div className="row">
                          {
                            this.state.isDealReadSubscribe !== true ? this.state.deal_name !== this.dealTyeNameCheckerHandler().checkExpireDate[this.state.deal_name] && (<div className="col-md-3">
                              <label className="deal-label-name">Select Deal Expiry Date</label>
                              <OaDatePicker
                              className="deal-expire"
                              placeholder="Select Expiry Date"

                                field="expire_date"
                                onChange={this.onChangeHandler}
                                showTime={false}
                                format={format}
                                defaultValue={
                                  this.state.expire_date || undefined
                                }
                              />
                            </div>) : (this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div className="col-md-4" style={{
                              marginTop: 30
                            }}>
                              <Button type="success" text="Customise OA Deal Setup" name="success"
                                icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
                            </div>
                          }


                          <div
                            className="col-md-3"
                            style={{
                              fontSize: 14,
                              marginTop: 30,
                            }}
                          >
                            <label>
                              {this.showSelectItemsLabel("member_organisations")}
                            </label>
                          </div>

                          <div style={{
                            marginTop: 30
                          }}>
                            <button
                              onClick={() =>
                                this.onModalSelectBoxShow("member_organisations")
                              }
                              className="btn btn-success waves-effect waves-light float-right"
                              type="button"
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                            {this.state.isShowMemberTypeModal && (
                              <ModalAutoComplete
                                field="member_organisations"
                                modalClass="col-md-6"
                                className="col-sm-9"
                                defaultValue={this.state.member_organisations}
                                submitBtnTxt="Save"
                                placeholder="Please select your Members"
                                members={this.state.member_organisation_details}
                                onSubmit={this.onSaveSelectedMembersItems}
                                onClose={() => this.onModalSelectBoxClose()}
                              />
                            )}


                          </div>
                        </div>
                        <div className="row">

                          {
                            this.state.isDealReadSubscribe !== true &&

                            <div className="col-md-12">
                              {(this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div style={{
                                marginTop: 30,

                              }}>
                                <Button type="success" text="Customise OA Deal Setup" name="success"
                                  icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
                              </div>}
                            </div>
                          }
                        </div>

                        { this.state.isDealCredit && this.individualMembersRadioBox()}

                        { this.state.isDealCredit && this.creditDeal()}

                        { this.state.isDealPrePayment && this.individualMembersRadioBox()}

                        { this.state.isDealPrePayment && this.prePaymentDeal()}

                        { this.state.isDealOaDiscount && this.oaDiscountDeal()}
                        { this.state.isDealReadSubscribe && this.individualMembersRadioBox()}
                        { this.state.isDealReadSubscribe && this.readAndPublisherDeal()}
                      </div >
                    )}


                  {this.state.is_manager_organisation === true && this.state.added_individual_member_deal === true && (
                    <div className="row mt-4">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-light btn-o-light mb-2"
                          onClick={this.addInvidualMember}
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="mdi mdi-plus"></i> Add Individual
      Members
    </button>
                      </div>
                    </div>
                  )}

                  {
                    this.state.is_manager_organisation === true && this.state.added_individual_member_deal === true && (
                      <div>
                        <div className="form-group row">
                          {this.state.isIndividualMember && (
                            <UpdateConstinvidiualMemberOAdeals
                              oadeal_currency={this.props.oadeal_currency}
                              constrainData={this.props.constrainData}
                              publication_types={this.state.publication_type}
                              publicationList={this.state.publicationList || []}
                              content_types={
                               this.props.content_type
                              }
                              deal_type={this.state.deal_type}
                              deal_name={this.state.deal_name}
                              onClose={this.onCloseInvidualMember}
                              onSaveAuthor={this.onSaveInvidualMemberHander}
                              fieldLabel="Co author"
                              value={
                                this.state.individualMembers[this.state.editing]
                              }
                            />
                          )}
                        </div>
                        <div className="form-group row">
                          {this.state.individualMembers.length > 0 &&
                            this.state.individualMembers.map((members, id) => {
                              return (
                                <div className="col-md-4" key={id}>
                                  <Badge
                                    label={members.counter_organisation_name}
                                    onEdit={() => this.onEditCoAuthor(id)}
                                    onDelete={() => this.onDeleteCoAuthor(id)}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )
                  }



                  <div className="text-center m-t-15">

                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={
                      this.state.is_manager_organisation === true
                        ? this.onIndiviualSubmit
                        : this.onSubmitHandler
                    }>Update OA deal</button>                 </div>

                </div>
              </div>
            </div>


          </div>

        </div> : <Loading type="flat" />}
      </HubContent >
    );
  }
}

const mapStateToProps = (state) => ({
  oadeal: state.OaDeals.oadeal || {},
  userId: state.auth.user.id || false,
  oaDeal_inst: state.OaDeals.oaDeal_inst || false,
  failed_oa_deal: state.OaDeals.failed_create_oadeal || false,
  organisation_id: state.auth.user.organisation_id || false,
  token: state.auth.token || false,
  deal_type: state.OaDeals.deal_type || false,
  oadeal_currency: state.OaDeals.oadeal_currency || false,
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  oa_subjects: state.OaSubject.oa_subjects || false,
  oadeal_summary: state.OaDeals.oadeal_summary || false,
  loading: state.OaDeals.loading,

});
const mapDispatchToProps = (dispatch) => ({
  dealInstituationList: (value) => dispatch(dealInstituationList(value)),
  getAllDealType: () => dispatch(getAllDealType()),
  getAllCurrencyList: () => dispatch(getAllCurrencyList()),
  createOaDeal: (payload) => dispatch(createOaDeal(payload)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: () => dispatch(getContentTypeList()),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getOaDealSummary: (orgId, payload) =>
    dispatch(getOaDealSummary(orgId, payload)),
  updateOaDeal: (id, payload) => dispatch(updateOaDeal(id, payload)),

  getSingleOaDeal: (id) => dispatch(getSingleOaDeal(id)),
});

export const UpdateOaDeal = connect(mapStateToProps, mapDispatchToProps)(DB);