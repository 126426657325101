import config, {
  GET_ARTICLE_FAULTY_AUTHOR,
  GET_ARTICLE_SINGLE_FAULTY_AUTHOR

} from "../../config";
import { api } from "../../core/api";

export const getFaultyAuthor = (payload) => ({
  type: GET_ARTICLE_FAULTY_AUTHOR,
  payload,
});
export const getSingleFaultyAuthorAction = (payload) => ({
  type: GET_ARTICLE_SINGLE_FAULTY_AUTHOR,
  payload,
});




export const getFaultyAuthorAction = (page_number) => {
  return (dispatch) => {
    let url = `${config.endpoint.article_faulty_author}?page=${page_number}`

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getFaultyAuthor(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getSingleFaultyAuthor = (id) => {
  return (dispatch) => {
    let url = `${config.endpoint.article_author}${id}/`
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getSingleFaultyAuthorAction(res));
          resolve(Object.assign(res, { status: true }));

        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};


export const updateFaultyAuthorAction = (id, payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.article_author}${id}/`
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(getFaultyAuthor(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

