import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import {
  getOrganisationOffsetFund,
  getOrganisationTokenDownload
} from "../../../../actions";
import {
  Loading,
  // InputBox,
  // SelectBox
} from "../../../../../core/components/common";
import NotFound from "../../NotFound";
import moment from "moment";

class OrgOffsetList extends BaseComponent {
  state = {
    deleteConfirm: false,
    journalId: null,
    waitingMsg: null,
    deleteStatus: null
  };
  getOrgOffsetFundTokenData = async (pageNum = 1, page = 10) => {
    await this.props.getOrganisationOffsetFund({
      pageNum: pageNum,
      pageSize: page
    });
    let OffsetFundList = this.props.offset_fund_list.results;
    let dataOrg = [];
    OffsetFundList && OffsetFundList.map(item => {
      return dataOrg.push({
        organisation: item.organisation_name,
        publisher: item.offset_fund.publisher_name,
        currency: item.offset_fund.currency_name,
        amount: item.offset_fund.amount,
        valid_from: item.offset_fund.valid_from,
        valid_to: item.offset_fund.valid_to,
        id: item.id
      });
    });
    this.setState({
      data: dataOrg,
      count:this.props.offset_fund_list && this.props.offset_fund_list.count
    });
  };
   componentDidMount() {
    this.getOrgOffsetFundTokenData();
   }
   componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}
  pageChange = pageNumber => {
    this.getOrgOffsetFundTokenData(pageNumber);
  };

  onEditoffsetFund = id => {
    this.props.history.push("/publisher-offset-fund/update-offset-fund/" + id);
  };

  onViewoffsetFund = id => {
    this.props.history.push("/publisher-offset-fund/single-offset-fund/" + id);
  };

  OrgoffSetDownloadClick = async () => {
    try {
      await this.props.getOrganisationTokenDownload();
      const url = window.URL.createObjectURL(this.props.orgs_token_download);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `orgs_token_download${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      
    }
  };
 
  render() {
    const heading = [
      "organisation",
      "publisher",
      "currency",
      "amount",
      "valid_from",
      "valid_to"
    ];
    return this.state.status === 403 ? (
      <NotFound />
    ) : (
      <HubContent
        title="Organisation offset fund list"
          onArticleDownloadClickHandler={this.OrgoffSetDownloadClick}
      >
    
          <div className="row filter">
          <div className="col-md-8">
            <div className="row">
              {/* <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div> */}
            </div>
          </div>
          {/* <div className="col-md-4">
            <InputBox className="col-md-12" placeholder="Search here... " />
          </div> */}
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data ? (
                <DataTable
                  heading={heading}
                  data={this.state.data}
                  /*
                    onEdit={this.onEditoffsetFund}
                  onView={this.onViewoffsetFund}
                */
                  count={this.state.count}
                  pageChange={this.pageChange}
                    pageSize={10}
                    noAction={true}
                />
              ) : (
                <Loading type="flat" />
                  )}
              
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  offset_fund_list: state.oaToken.offset_fund_list || false,
  orgs_token_download: state.oaToken.orgs_token_download || false
});

const mapDispatchToProps = dispatch => ({
  getOrganisationOffsetFund: payload =>
    dispatch(getOrganisationOffsetFund(payload)),
  getOrganisationTokenDownload: () => dispatch(getOrganisationTokenDownload())
});

export const OrgOffsetFundList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgOffsetList);
