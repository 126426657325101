import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
// Routes
import AuthRoute from "./AuthRoute";
import HubRoute from "./HubRoute";
import PrivateRoute from "./PrivateRoute";

import {
  Auth,
  Public,
  Hub,
  User,
  Publisher,
  University,
  Funder,
  Consortia,
  Private,
} from "./Router";

import BaseComponent from "../../core/components/BaseComponent";
import { NotFound } from "../components/views/NotFound";
import {
  HUB,
  AUTHOR,
  PUBLISHER,
  INSTITUTION,
  CONSORTIUM,
  FUNDER,
} from "../data/permission";

const createHistory = require("history").createBrowserHistory;

export const history = createHistory();


class Routes extends BaseComponent {
  render() {
    /*
    let res;
    
    let TempPubSidebar = Publisher&& Publisher.map((item) => {
      return Object.assign({}, item);
    });
    
    let permissionObj = Array.isArray(this.props.auth && this.props.auth.permissions)?this.props.auth && this.props.auth.permissions:[] ;
    console.log(permissionObj)
    res = TempPubSidebar.filter((x) => {
      if (
        x.role === "public" ||
        (x.role === "private" && permissionObj.includes(x.value))
      ) {
        if (x.content) {
          let tempX = x.content.filter(
            (y) =>
              y.role === "public" ||
              (y.role === "private" &&
                permissionObj &&
                permissionObj.includes(y.value))
          );

          x.content = tempX;

          return x;
        } else {
          return x;
        }
      }
      return null;
    });
    */
    const routerHander = [
      {
        results: Hub,
        permission: {
          group: HUB,
        },
      },
      {
        results: [...User, ...Private],
        permission: {
          group: AUTHOR,
        },
      },
      {
        results: Publisher,
        permission: {
          group: PUBLISHER,
        },
      },
      {
        results: University,
        permission: {
          group: INSTITUTION,
        },
      },
      {
        results: Funder,
        permission: {
          group: FUNDER,
        },
      },
      {
        results: Consortia,
        permission: {
          group: CONSORTIUM,
        },
      },
    ];


    let { group, kind } = this.props;
    return (
      <div>
        <Router history={history}>
          <div>
            <Switch>
              {Public.map((R, k) => {
                return (
                  <Route
                    key={k}
                    path={R.path}
                    component={R.component}
                    exact={R.exact}
                  />
                );
              })}

              {routerHander.map((Handler) => {
                if (Handler.permission.group === group) {
                  return Handler.results.map((R, k) => {
                    if (!R.kind || kind === R.kind) {
                      return (
                        <HubRoute
                          key={k}
                          path={R.path}
                          component={R.component}
                          exact={R.exact}
                          role={Handler.permission.role}
                          group={Handler.permission.group}
                        />
                      );
                    } else {
                      return null;
                    }
                  });
                } else {
                  return Handler.results.map((R, k) => {
                    if (!R.kind || kind === R.kind) {
                      return (
                        <PrivateRoute
                          key={k}
                          path={R.path}
                          component={R.component}
                          exact={R.exact}
                          group={Handler.permission.group}
                        />
                      );
                    } else {
                      return null;
                    }
                  });
                }
              })}

              {Auth.map((R, k) => {
                return (
                  <AuthRoute
                    key={k}
                    path={R.path}
                    component={R.component}
                    exact={R.exact}
                  />
                );
              })}
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth:state.auth,
  isAuthenticated: !!state.auth.token,
  role_id: state.auth.user && state.auth.user.role_id,
  group: state.auth.user && state.auth.group,
  kind: state.auth.user && state.auth.user.kind,
});

export const AppRouter = connect(mapStateToProps)(Routes);