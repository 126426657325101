import React from "react";
import { connect } from "react-redux";
import {Button as SendOrcIdBtn} from "antd";

import moment from "moment-timezone";
import { Link } from "react-router-dom";
import axios from "axios"
import { PopupBox } from "../../../../core/components/common";
import { fetchLoginUser, getGenericAuthUrl, submitGenericAuthCode, } from "../../../actions";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: moment.tz.guess(),
      sendAdhocBtnLoading:false
    }
  }
  validateEmail(username) {

    // eslint-disable-next-line
    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let res = pattern.test(username && username.trim())

    if (res === true) {
      this.setState({
        emailError: false,
        username: username
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });

    if (e.target.name === 'username') {
      this.validateEmail(e.target.value);
    }
    if (e.target.name === 'password') {
      if (e.target.value === '' || e.target.value === null) {
        this.setState({
          passwordError: true
        })
      } else {
        this.setState({
          passwordError: false,
          password: e.target.value
        })
      }
    }

  }
  onClickPopup = () => {
    this.setState({
      startMsg:"Contact support@oametrix.co.uk if you wish to set up an institutional admin account. Please continue if you are creating an author account."
    });
  };
  onEndPopup = () => {
    this.props.history.push("/signup")
  };
  onCancel = () => {
    this.setState({
      errorMsg: false,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    const { username, password, timezone } = this.state;
    if (username && password) {

      let data = { username, password, timezone };
      this.props.fetchLoginUser(data);

    } else {
      this.setState({
        errorMsg: "Email and Password cannot be empty",
      });
    }

  }


  signWithORICID = async () => {
    this.enterLoading()
    let result = await this.props.getGenericAuthUrl({
      "context": "orcid"
    })

    axios.get("https://orcid.org/userStatus.json?logUserOut=true").then(res => {
      if (res.data.loggedIn === false) {
        window.location.href = `${result.data.auth_url}`;
        this.setState({
          url: result.data.auth_url
        })
      }
    })
  }
  enterLoading = index => {
    this.setState(({ sendAdhocBtnLoading }) => {
   
      return {
        sendAdhocBtnLoading: true,
      };
    });
    setTimeout(() => {
      this.setState(({ sendAdhocBtnLoading }) => {
   
        return {
            sendAdhocBtnLoading: false,
        };
      });
    }, 4000);
  };
  render() {
    return (
      <div className="wrapper-page">
        {this.state.errorMsg && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.errorMsg}
            onCancel={this.onCancel}
          />
        )}
        {this.state.startMsg && (
          <PopupBox
            title="Info"
            NoText="Continue"
            msg={this.state.startMsg}
            onCancel={this.onEndPopup}
          />
        )}

        <div className="card">
          <div className="card-body">
            <h3 className="text-center m-10">
              <Link to="/" className="logo logo-admin">
                <img
                  src="/assets/images/logo-sm.png"
                  style={{ height: "30px" }}
                  alt="logo"
                />
              </Link>
            </h3>

            <div className="text-center m-20">
              <p className="register-info" >
                Please  <span onClick={this.onClickPopup} style={{textDecoration:"underline",color:"#1890ff"}}> Register</span> if you do not have an account
            </p>
            <p className="register-info">
            Contact <a href="mailto:support@oametrix.co.uk">support@oametrix.co.uk</a> if you wish to set up an institutional admin account. Please continue if you are creating an author account.</p>
            </div>

            <div className="p-3">

              {this.props.auth && this.props.auth.status === 400 && (
                <h5 className="text-danger font-18 mb-5 text-center">
                  {this.props.auth.message}
                </h5>
              )}
              {this.props.auth && this.props.auth.status === 500 && (
                <h5 className="text-danger font-18 mb-5 text-center">
                  Network Error! Try again after sometimes
                </h5>
              )}

              <form
                className="form-horizontal mt-30"
                action=""
                onSubmit={this.onSubmit}
              >
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input type="username" id="username" name="username" className="form-control" placeholder="Enter your email address" onChange={(e) => { this.handleChange(e) }} />
                  {this.state.emailError ? <span style={{ color: "red" }}>Please enter valid email address</span> : ''}

                </div>
                <div className="form-group">
                  <label htmlFor="userpassword">Password</label>
                  <input type="password" id="password" name="password" autoComplete="on" className="form-control" placeholder="Enter your password" onChange={(e) => { this.handleChange(e) }} />
                  {this.state.passwordError ? <span style={{ color: "red" }}>Please enter valid password</span> : ''}

                </div>


                <div className="text-center m-10" style={{ marginBottom: 15, marginTop: 30 }}>
                  <button className="btn btn-primary btn-block" onClick={this.submitForm}>Log in</button>
                </div>
              </form>

              <div className="text-center m-0">
                <p className="orcid-info"> if you are an author, you can log in with your ORCID ID</p>
              </div>
              <div className="text-center"style={{ marginBottom: 10, marginTop: 10,textAlign:"center" }}>
                                     
                <a className="oricid" href={this.state.url} onClick={this.signWithORICID}>
                {this.state.sendAdhocBtnLoading !== true && <img src="https://orcid.org/assets/vectors/orcid.logo.icon.svg" alt="sign" target="_blank" />}
                <SendOrcIdBtn type="" loading={this.state.sendAdhocBtnLoading}>
                Log in with ORCID</SendOrcIdBtn>
                  
                      </a>
              </div>
              <div className="text-center m-0">

                <p className="reset-info">  Click on <Link to="/forgot" className="reset-passowrd">Reset Password</Link> if you forgot your login details.
</p>              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  submitGenericAuthCode: (payload) => dispatch(submitGenericAuthCode(payload)),
  getGenericAuthUrl: (payload) => dispatch(getGenericAuthUrl(payload)),

  fetchLoginUser: (data) => dispatch(fetchLoginUser(data)),
});

export const SignIn = connect(mapStateToProps, mapDispatchToProps)(Auth);