import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { CreateUser, ClearStatus } from '../../../../actions';
import { InputBox, PopupBox, Loading } from '../../../../../core/components/common';

class Change extends BaseComponent {
    constructor(props) {
        super(props)
        this.error = props.failed;
        this.status = 100;
    }
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        isEmpty: false
    }

    componentDidUpdate() {
        this.state.msg && setTimeout(() => {
            this.setState({
                msg: false
            })
        }, 2000)

        if (this.props.failed) {
            if (this.props.failed.status === 500 && this.state.status !== 500) {
                this.setState({
                    status: 500
                })
            } else if (this.props.failed.status === 400 && this.state.status !== 400) {
                this.setState({
                    status: 400
                })
            }
        }
    }

    onSubmitHandler = async (user) => {
        if (user.email && user.first_name && user.last_name && user.role && user.organisation && user.group) {
            this.setState({
                isRegistering: true,
                msg: false
            })
            const data = (Object.assign(user, { request_source: "hub" }));
            const new_user = await this.props.CreateUser(data);
            if (new_user.status === 201) {
                document.querySelector("form").reset();
                this.setState({
                    isRegistering: false,
                    msg: 'New user created.'
                })
            }

        } else {
            this.setState({
                isEmpty: <div>
                    {!user.email && <p>Email Cannot Be Empty!</p>}
                    {!user.first_name && <p>Firstname Cannot Be Empty</p>}
                    {!user.last_name && <p>Lastname Cannot Be Empty</p>}
                    {!user.role && <p>Role Cannot Be Empty</p>}
                    {!user.group && <p>Group Cannot Be Empty</p>}
                    {!user.organisation && <p>Organization Cannot Be Empty</p>}
                </div>
            })
        }
    }

    onChangeHandler = (value) => {
      
        this.setState((prevState) => {
            return Object.assign(prevState.user, value);
        })
        
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        })
        await this.props.ClearStatus()
    }


    render() {
        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Bad Request"
                msg="Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <HubContent title="Change password">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}

                {/* LoadinG Handling */}
                {this.state.isRegistering && !this.state.status && <Loading />}
                {/* LoadinG Handling */}

                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Password reset form</a>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    <div className="tab-pane active p-3" id="home-1" role="tabpanel">
                                    </div>
                                    <div className="form-group row">
                                        <InputBox
                                            label="Old password"
                                            InputType="password"
                                            onChange={this.onChangeHandler}
                                            field='salute'
                                            defaultValue=""
                                            placeholder="Old password"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                        />
                                    </div>
                                    <div className="form-group row">
                                        
                                        <InputBox
                                            label="New password"
                                            InputType="password"
                                            onChange={this.onChangeHandler}
                                            field='salute'
                                            defaultValue=""
                                            placeholder="New password"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                        />
                                        
                                    </div>
                                    <div className="form-group row">
                                        
                                        <InputBox
                                            label="Confirm password"
                                            InputType="password"
                                            onChange={this.onChangeHandler}
                                            field='salute'
                                            defaultValue=""
                                            placeholder="Confirm password"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                        />
                                    </div>

                                    <div className="text-center mt-5">
                                        <button
                                            className="btn btn-primary btn-md waves-effect waves-light"
                                            onClick={this.onSubmitHandler}
                                        >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    failed: state.user.failed || false,
})

const mapDispatchToProps = (dispatch) => ({
    CreateUser: (payload) => dispatch(CreateUser(payload)),
    ClearStatus: () => dispatch(ClearStatus())
})


export const ChangePwd = connect(mapStateToProps, mapDispatchToProps)(Change);
