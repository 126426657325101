import React from "react";
import DashContext from "../../../../core/context/DashContext";
import { NavLink } from "react-router-dom";
import { history } from "../../../route/Settings";

class MenuItem extends DashContext {
  render() {
    const { context, baseUrl, data, kind, dataCount } = this.props;
    const content = context.content;
    return (
      <li
        className={
          history.location.pathname.includes(context.to) ? "active" : ""
        }
      >
        <NavLink
          exact={context.exact}
          activeClassName="active"
          to={context.to ? context.to : "#"}
          className="waves-effect"
        >
          <i className={`mdi ${context && context.icon}`}></i>
          <span>
            {context && context.label}
            {context.content && (
              <span className="float-right menu-arrow">
                <i className={"mdi mdi-chevron-right"}></i>
              </span>
            )}
            {
              context.showBadge && dataCount > 0 && (
                <span className="badge p-1 badge-secondary ml-2 pending-oadeal-request">
                  {dataCount}
                </span>
              )
            }
          </span>
        </NavLink>

        {/* Dropdown Menu */}
        {content && (
          <ul
            className={
              history.location.pathname.includes(context.to)
                ? "submenu collapse in"
                : "submenu collapse"
            }
          >
            {content.map((item, i) => {
              const submenu = !item.isHide &&
                (!item.kind || item.kind === kind) && (
                  <li
                    key={i}
                    className={
                      history.location.pathname.includes(item.to)
                        ? "active"
                        : ""
                    }
                  >
                    <NavLink
                      onClick={() => {
                        this.setState({ isActive: true });
                      }}
                      activeClassName="active"
                      to={baseUrl + item.to || "#"}
                    >
                      {item.label}



                      {item.showBadge &&
                        data &&
                        data.pendingOadealRequestCount && (
                          <span className="badge badge-pill badge-success ml-2 pending-oadeal-request">
                            {data.pendingOadealRequestCount}
                          </span>
                        )}
                    </NavLink>
                  </li>
                );
              return submenu;
            })}
          </ul>
        )}
      </li>
    );
  }
}

export default MenuItem;
