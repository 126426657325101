import { GET_INSTITUTION_LIST, GET_OADEAL_FILTER_LIST, GET_ORG_FINANCIAL_REPORT, GET_PUBLICATION_LIST_NEW, GET_PUBLICATION_LIST, GET_DEPARTMENT_LIST, GET_REQUEST_ACTION } from "../../config";

const InstitutionReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_REQUEST_ACTION:
            return state = {
                ...state,
                loading: true
            }
        case GET_INSTITUTION_LIST:
            return state = {
                ...state,
                institution_list: action.payload, 
                loading: false
            }
        case GET_ORG_FINANCIAL_REPORT:
            return state = {
                ...state,
                org_financial_report: action.payload
            }
        case GET_PUBLICATION_LIST:
            return state = {
                ...state,
                publication_types: action.payload
            }
        case GET_PUBLICATION_LIST_NEW:
            return state = {
                ...state,
                publication_list: action.payload
            }
        case GET_OADEAL_FILTER_LIST:
            return state = {
                ...state,
                oadeal_filter: action.payload
            }
        case GET_DEPARTMENT_LIST:
            return state = {
                ...state,
                department_types: action.payload
            }
        default:
            return state;
    }
}
export default InstitutionReducer;
