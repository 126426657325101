import React from 'react';
import { DragAndDrop } from '../../../partials';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { OrgForm } from '../../../partials';
import {ClearStatus, CreateOrg, organisationFileUpload, trackOrganisationFileUploadStatus, trackArticleFileUploadStatusDownload } from '../../../../actions';
import { connect } from 'react-redux';
import { PopupBox, Loading ,Button} from '../../../../../core/components/common';
import validator from 'validator';
import { confirmAlert } from 'react-confirm-alert'; // Import

class Org extends BaseComponent {
  state = {
    org: {},
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
    errorObject: false,
    validationObject: false,
    error: false,
    isLoading: false,
    fileUploadStatus: null,
    fileUploadStatusMsg: null,
  }


  onSubmitHandler = async (org) => {
    this.setState({
      ...org
    }, async function () {
      let isEmail = validator.isURL(this.state.email_domain ? this.state.email_domain : '');
      let isValidWebsite = validator.isURL(this.state.website ? this.state.website : '');

      if (
        !this.state.name || !this.state.email_domain
        || !this.state.address || !this.state.address.country || !this.state.address.address1
      ) {
        this.setState({
          isEmpty: <div className="validation-error-messages">
            {!this.state.name ? <p>Organisation name cannot Be empty</p> : ''}
            {!this.state.email_domain && <p>Email domain cannot be empty</p>}
            {!this.state.address || !this.state.address.country ? <p>Country cannot be empty</p> : ''}
            {!this.state.address || !this.state.address.address1 ? <p>Address 1 cannot be empty</p> : ''}
          </div>
        })
      } else if (!isEmail) {
        this.setState({
          isEmpty: <div>
            {!isEmail && <p>Email Domain format is not correct!</p>}
          </div>
        })
      } else if (this.state.website && !isValidWebsite) {
        this.setState({
          isEmpty: <div>
            {!isValidWebsite && <p>Website url is not correct</p>}
          </div>
        })
      }  else {
        try {
          this.setState({
            isRegistering: true,
            msg: false
          })
          Object.keys(org).map((key, value) => {
            if ((key === 'address' || key === 'meta') && org[key] == null) {
              org[key] = {}
            }
            return org;
          })
          org['parent'] = org['organisation'];
          org['domain'] = org['group'];
          await this.props.CreateOrg(org);
          this.props.history.push('/organizations/organization-list')
        } catch (err) {
          this.setState({
            isEmpty: null,
            status: 400,
            errorObject: this.props.failed
          })
        }
      }
    })

  }

  componentDidUpdate() {
    this.state.msg && setTimeout(() => {
      this.setState({
        msg: false
      })
    }, 2000)
  }

  cancelLoader = async () => {
    this.setState({
      status: null,
      isEmpty: null,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  }

  OnChangeTextBox(value, name) {
    const state = {}
    state[name] = value
    this.setState(state);

  }


  onFileDrop = (payload) => {

    confirmAlert({
      title: 'Upload book',
      message: 'Are you sure, you want to upload file?',
      buttons: [
        {
          label: 'Upload',
          onClick: () => { this.organisationFileUpload(payload); }
        },
        {
          label: 'Cancel',
          onClick: () => { }
        }
      ]
    });
  }

  organisationFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });

    await this.props.organisationFileUpload(payload);
    if (this.props.organisation_file_upload.status) {
      let payload = {
        upload_status: this.props.organisation_file_upload.data.upload_status.slice(1)
      };
      this.setState({
        upload_payload: payload
      })
      await this.props.trackOrganisationFileUploadStatus(payload);
    }
    if (this.props.track_upload_status) {
      let upload_id = this.props.track_upload_status.id;
    
      let upload_download_status = `file-upload-error-response/${upload_id}/`;
      let payloadDownload = {
        upload_status: upload_download_status
      };
      await this.props.trackArticleFileUploadStatusDownload(payloadDownload);
    }

    this.setState({
      fileUploadStatus: (this.props.organisation_file_upload.status || this.props.organisation_file_upload.status === 200) ? true : false,
      fileUploadStatusMsg: this.props.organisation_file_upload.data.message,
      isLoading: false,
    });
  }

  render() {
    let errorHander = <>
      {this.state.isEmpty && <PopupBox
        Title="Following field cannot be empty"
        msg={this.state.isEmpty}
        onCancel={this.cancelLoader}
      />}
      {this.state.fileUploadStatus === true && <PopupBox
        title="Success"
        msg={this.state.fileUploadStatusMsg}
        upload={true}
        upload_payload={this.state.upload_payload}
        onCancel={this.cancelLoader}
        className="col-sm-8"
      />}
    </>

    return (
      <HubContent title="Add Organization">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        {/* Loader */}
        {this.state.isLoading && <Loading />}
        {/* Loader */}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {/* ErroR handling */}
                {/* {errorHander} */}
                {/* ErroR Handling */}
                <div className="col-md-12">
                    <div className="vat-tax-button email d-flex justify-content-end">

                      <Button
                        type="danger"
                        name="danger"
                        text="X"
                        onClick={() => this.props.history.push("/")}
                      />
                    </div>
                  </div>
                {/* {<ValidationMsg msg="hello hello hello" errorObject={this.state.errorObject} validationObject={this.state.validationObject} />} */}
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Enter organisation details</a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a className="nav-link" data-toggle="tab" href="#profile-1" role="tab">Upload organisation details</a>
                  </li>
                </ul>

           
                <div className="tab-content">
                  <div className="tab-pane active p-3" id="home-1" role="tabpanel">
                    <OrgForm
                      onSubmitHandler={this.onSubmitHandler}
                      OnChangeTextBox={this.OnChangeTextBox.bind(this)}
                      msg={this.state.msg}
                    />
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">

                    <div className="m-b-30">
                      <DragAndDrop
                        onDropHandler={this.onFileDrop} />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    )
  }
}

const mapStateToProps = (state) => ({
  failed: state.orgs.failed || false,
  token: state.auth.token || false,
  organisation_file_upload: state.orgs.organisation_file_upload || false,

  track_upload_status: state.orgs.track_upload_status || false,
  track_upload_status_download: state.articles.track_upload_status_download || false,
})
const mapDispatchToProps = (dispatch) => ({
  CreateOrg: (payload) => dispatch(CreateOrg(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
  organisationFileUpload: (payload) => dispatch(organisationFileUpload(payload)),
  trackOrganisationFileUploadStatus: (payload) => dispatch(trackOrganisationFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) => dispatch(trackArticleFileUploadStatusDownload(payload)),
})
export const AddOrg = connect(mapStateToProps, mapDispatchToProps)(Org);