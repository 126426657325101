import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
    emailLogHistory
} from "../../../../actions";
import {
    ExpandHeadingTable,
    PaginationForm,
    Loading
} from "../../../../../core/components/common";
import {
    authorEmail,
    column,

} from "../../../../../core/lib";

class EmailLog extends BaseComponent {
    state = {

    }
    getEmaiLogData = async (pageNum = 1, page = 10) => {
        await this.props.emailLogHistory({ pageNum: pageNum, pageSize: page }, "inbox");
        let data = this.props.email_history_log.results;
        let dataOrg = [];
        data.map((item) => {
            return dataOrg.push({
                "recipients": item.recipients,
                "subject": item.subject,
                "status": item.status,

                id: item.id
            });
        })
        this.setState({
            data: dataOrg,
            count: this.props.email_history_log&&this.props.email_history_log.count
        })
    }
    async componentDidMount() {
        this.getEmaiLogData();
    }
    pageChange = (pageNumber) => {
        this.getEmaiLogData(pageNumber);
    }

    onViewCustomEmail = (id) => {

        this.props.history.push('/author-custom-email/author-custom-email-message/' + id)
    }
    render() {


        return (

            <HubContent
                title="Outbox List"
            >

                <div className="col-12">
                    <div className="card m-b-20">
                        <ExpandHeadingTable heading={authorEmail} />

                        {this.state.data ? (
                            <div>
                                {   this.state.data && this.state.data.length > 0 ? (
                                    this.state.data && this.state.data.map((item, index) => (
                                        <div id="accordion" key={item.id}>

                                            <div className="data-table">
                                                <div className="row-table">
                                                    <div className="row table-data">
                                                        <div className={column[2]}>
                                                            <p>{item.recipients}  </p>  </div>

                                                        <div className={column[5]}>
                                                            {item.subject}                                                     </div>

                                                        <div className={column[1]}>

                                                            {item.status}                                                      </div>


                                                        <div className={column[0]}>

                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={(e) => this.onViewCustomEmail(item.id)}
                                                                title="View"
                                                            >
                                                                <i className="mdi mdi-eye"></i>
                                                            </span>                                                </div>





                                                    </div>

                                                </div >
                                            </div >


                                        </div>
                                    ))
                                ) : (
                                        <div className="card-body" style={{ textAlign: "center" }}>
                                            No Data Found
                                        </div>
                                    )}
                                {this.state.data && this.state.data.length > 0 ? (
                                    <PaginationForm
                                        pageSize={10}
                                        pageChange={this.pageChange}
                                        count={this.state.count}
                                    />
                                ) : null}
                            </div>
                        ) : (
                                <Loading type="flat" />
                            )}



                    </div>
                </div>
            </HubContent >
        );
    }
}


const mapStateToProps = (state) => ({
    email_history_log: state.articles.email_history_log,
})

const mapDispatchToProps = (dispatch) => ({
    emailLogHistory: (payload, inbox) => dispatch(emailLogHistory(payload, inbox)),
})
export const ArticleOutboxEmail = connect(mapStateToProps, mapDispatchToProps)(EmailLog);
