import React from "react";
import { isArray } from "lodash";

import { HubContent } from "../../../../../core/components";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { emailLogDisplayHistory, sendFreeTextEmail } from "../../../../actions";
import { Button, Loading, ModalCustom } from "../../../../../core/components/common";
import { AdHocEmailForm } from "../../../partials";

class ViewEmailModal extends React.Component {
    state = {
    };


    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.emailLogDisplayHistory(this.props.match.params.id)
        }
    }

    onChangeHandler = (value) => {
        this.setState({
            ...value,
        });
    }
    replyEmail = (contextData) => {
        let articleData =contextData && contextData.article_info;
        let subject = contextData.subject;

        if (!subject.startsWith('Reply:')) {
            subject = `Reply: ${subject}`;
        } else if (subject) {
            subject = subject.replace('Reply: Reply: ', 'Reply: ');
        }

        let recipients = [];
        let senderEmail = contextData && contextData.recipients && contextData.recipients.split(",") 

        if (this.props.group === "PUBLISHER" || this.props.group === "AUTHOR" || this.props.group ==="INSTITUTION" ) {
            
                recipients = senderEmail && senderEmail.map(item => (
                    {
                        label: item,
                        value: item,
                    }
                )) 
            
    
    
        } else {
            if (contextData && contextData.from_user_email) {
                recipients = [{
                    label: contextData && contextData.from_user_email,
                    value: contextData && contextData.from_user_email,
                }];
            }
    
    
        }


        subject = subject && subject.trim();

        this.setState({
            showCustomEmailForm: true,
            article_id: articleData&& articleData.id,
            message_body: subject,
            subject,
            recipients,
            article_single: contextData && contextData.article_info,
        });
    }


    onCloseHandler = () => {
        this.setState({
            showCustomEmailForm: false,
            article_id: '',
        });
    }
    onSubmitHandler = (e) => {

        let data = {
            article_id: this.state.article_id,
            organisation: this.state.organisation,
            subject: this.state.subject,
            recipients: this.state.recipients,
            message_body: this.state.message_body,
        };

        if (!data.article_id || !data.subject || !data.recipients || (isArray(data.recipients) && data.recipients.length === 0) || !data.message_body) {
            this.setState({
                isEmpty: <div>
                    {!data.article_id && <p>Article is required.</p>}
                    {(!data.recipients || (isArray(data.recipients) && data.recipients.length === 0)) && <p>Recipient is required.</p>}
                    {!data.subject && <p>Subject is required.</p>}
                    {!data.message_body && <p>Message is required.</p>}
                </div>
            })
        } else {
            this.setState({
                emailLoading: true,
            });

            this.props.sendFreeTextEmail(data).then(response => {
                if (response.data && response.data.success) {
                    this.setState({
                        emailLoading: false,
                        showCustomEmailForm: false,
                        successMsg: response.data.message,
                        article_id: '',
                        organisation: '',
                        subject: '',
                        recipients: '',
                        message_body: '',
                    });
                }
            }).catch(error => {
                this.setState({
                    emailLoading: false,
                });

                return error.response && error.response.data && error.response.data.success;
            });
        }
    };

    render() {
        const { showCustomEmailForm } = this.state;
        let isNullCheck = this.props.article_email_log_view && this.props.article_email_log_view.from_email
        let fromEmail;
        if (isNullCheck) {
            fromEmail = this.props.article_email_log_view && this.props.article_email_log_view.from_email
        } else {
            fromEmail =""
        }
        return (
            <HubContent>
                {showCustomEmailForm && <ModalCustom
                    title="Reply Email"
                    onClose={this.onCloseHandler}
                    modalClass="col-sm-12 col-md-8 "
                    isShow={showCustomEmailForm}
                    submitBtnTxt="Send"
                    onSubmit={this.onSubmitHandler}
                >
                    <AdHocEmailForm
                        publication_name={this.props.article_email_log_view && this.props.article_email_log_view.article_info && this.props.article_email_log_view.article_info.publication_name}

                        emailLoading={this.state.emailLoading}
                        article={this.state.article_single}
                        subject={this.state.subject}
                        message_body={this.state.message_body}
                        recipients={this.state.recipients}
                        article_id={this.state.article_id}
                        onChangeHandler={this.onChangeHandler}
                    />
                </ModalCustom>}
                {this.props.isLoading !== true ?
                    <div className="col-sm-12 email-history-content">
                        <div className="vat-tax-button">
                            <div className="vat-tax-button email d-flex justify-content-end">
                                <Button
                                    className="btn btn-primary btn-sm waves-effect help-menu reply"
                                    type=""
                                    name=""
                                    text="Resend email"
                                    onClick={() => this.replyEmail(this.props.article_email_log_view)}

                                />

                                <Button
                                    type="danger"
                                    name="danger"
                                    text="X"
                                    onClick={() => this.props.history.goBack()}
                                />
                            </div>

                        </div>
                        <div className="row email-content">
                            <div className="col-md-12 email-subject ">
                                <p>Subject: {this.props.article_email_log_view && this.props.article_email_log_view.subject}</p>
                            </div>
                            <div className="col-md-6">
                                <div className="email-box">
                                    <div className="email-icon">
                                        <i className="mdi mdi-account"></i>
                                    </div>
                                    <div className="email-description">
                                        <p>
                                            <strong>From:</strong>  {`${fromEmail && fromEmail.split("<").slice(0, -1)}<${this.props.article_email_log_view && this.props.article_email_log_view.from_user_email }>`}
                                        </p>
                                        <p>
                                            <strong>To: </strong>
                                            {this.props.article_email_log_view && this.props.article_email_log_view.recipients}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="email-date">
                                    <p>{new Date(this.props.article_email_log_view && this.props.article_email_log_view.date_created).toLocaleString()}</p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="email-content-message"
                            dangerouslySetInnerHTML={{
                                __html: this.props.article_email_log_view && this.props.article_email_log_view.html_message,
                            }}
                        ></div>
                    </div> : <Loading type="flat" />}
            </HubContent>
        )


    }
}


const mapStateToProps = (state) => ({
    article_email_log_view: state.articles.article_email_log_view,
    isLoading: state.articles.loading,
    group:state.auth.group

});

const mapDispatchToProps = (dispatch) => ({
    emailLogDisplayHistory: (payload) => dispatch(emailLogDisplayHistory(payload)),
    sendFreeTextEmail: (payload) => dispatch(sendFreeTextEmail(payload))
});

export const ArticleCustomSendEmailMessage = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewEmailModal));
