import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../../../core/components";
import {
    SelectBox,
    InputBoxCustom,
    PopupBox,
} from "../../../../../../core/components/common";
import { mutateArticlePrice } from "../../../../../actions";

const priceTypes = [
    { id: "WC", name: "Word Count" },
    { id: "WR", name: "Word Range" },
    { id: "PC", name: "Page Count" },
    { id: "PR", name: "Page Range" },
];

class Form extends BaseComponent {
    state = {
        prices: {},
        isEmpty: false,
    };

    componentDidMount() {
        let article = this.props.article;

        let prices = {
            price_type: article.price_type,
            price_type_value: article.price_type_value,
            pub_fee_gbp: article.pub_fee_gbp,
            pub_fee_eur: article.pub_fee_eur,
            pub_fee_usd: article.pub_fee_usd,
            submission_fee_gbp: article.submission_fee_gbp,
            submission_fee_usd: article.submission_fee_usd,
            submission_fee_eur: article.submission_fee_eur,
            colour_charge_gbp: article.colour_charge_gbp,
            colour_charge_usd: article.colour_charge_usd,
            colour_charge_eur: article.colour_charge_eur,
            page_other_charge_gbp: article.other_charge_gbp,
            page_other_charge_usd: article.other_charge_usd,
            page_other_charge_eur: article.other_charge_eur,
        };

        this.setState((prevState) => {
            return Object.assign(prevState.prices, prices);
        });
    }


    onChangeHandler = (value) => {
        let { prices } = this.state;
        let hasErrorMsg = false;

        for (let key in value) {
            if (key !== "price_type" && key !== "price_type_value") {
                value[key] = Number(value[key]);
                if (value[key] < 0) {
                    hasErrorMsg = "Price is not valid.";
                    value[key] = Math.abs(value[key]).toFixed(2);
                }
            } else if (key === "price_type_value") {
                if (prices.price_type === "WC" || prices.price_type === "PC") {
                    value[key] = Number(value[key]);
                    if (value[key] < 0) {
                        hasErrorMsg = "Price type value is not valid.";
                        value[key] = Math.abs(value[key]);
                    }
                }
            }
        }

        Object.assign(prices, value);
        this.setState({
            prices,
        });

        if (hasErrorMsg) {
            this.setState({
                isEmpty: hasErrorMsg,
            });
        }
    };



    onSavePrices = async (e) => {
        let data = this.state.prices;
        let hasErrorMsg = false;

        for (let key in data) {
            if (key !== "price_type" && key !== "price_type_value") {
                data[key] = Number(data[key]);
                if (data[key] < 0) {
                    hasErrorMsg = "Price is not valid.";
                    break;
                }
            } else if (key === "price_type_value") {
                if ((data && data.price_type === "WC") ||( data && data.price_type === "PC")) {
                    data[key] = Number(data[key]);
                    if (data[key] < 0) {
                        hasErrorMsg = "Price type value is not valid.";
                        break;
                    }
                } else if ((data && data.price_type === "WR") ||(data && data.price_type === "PR")) {
                    data[key] = data && data[key] && data[key].replace(" ", "");
                    if (data &&data[key] &&  data[key].split("-") && data[key].split("-").length !== 2) {
                        hasErrorMsg = "The APC for this article is based on article type. You cannot change the price based on to word count or word range.";
                        break;
                    }
                }
            }
        }

        if (!hasErrorMsg) {
            const articleId = this.props.article && this.props.article.id;
            const mutateAction = "update-article-price";

            await this.props.mutateArticlePrice(articleId, mutateAction, data);

            if (this.props.article_price && this.props.article_price.success) {
                this.setState({
                    successMsg: "You have successfully updated your prices",
                });
            }
        } else {
            this.setState({
                isEmpty: hasErrorMsg,
            });
        }
    };

    showPriceTypeName = (priceType) => {
        if (!priceType || priceType === "") {
            return priceTypes.length > 0 ? priceTypes[0].name : "Page Count";
        }

        let obj = priceTypes.find((item) => item.id === priceType);

        return obj ? obj.name : "Page Count";
    };

    cancelLoader = async () => {
        this.setState({
            isEmpty: false,
        });
    };

    successConfirm = async () => {
        this.setState(
            {
                successMsg: false,
            },
            () => {
                this.props.onClose(this.props.article && this.props.article.id);
            }
        );
    };
    render() {
        let { prices } = this.state;

        let errorHandler = (
            <>
                {this.state.isEmpty && (
                    <PopupBox
                        Title="Following field cannot be empty"
                        msg={this.state.isEmpty}
                        onCancel={this.cancelLoader}
                    />
                )}
            </>
        );

        let successHandler = (
            <>
                {this.state.successMsg && (
                    <PopupBox
                        msg={this.state.successMsg}
                        onCancel={this.successConfirm}
                    />
                )}
            </>
        );

        return (
            <div
            // onClick={this.onCloseModal}
            // id="CoAuthorDialog"
            // style={modal.overlay}
            >
                {/* ErroR handling */}
                {errorHandler}
                {/* ErroR Handling */}

                {/* ErroR handling */}
                {successHandler}
                {/* ErroR handling */}



                <div className="row">

                    <div className="col-md-3">
                        <label className="price-label-name">Select your type</label>
                        <SelectBox
                            className="col-md-12"
                            field="price_type"
                            placeholder="Select your type"
                            data={priceTypes}
                            onChange={this.onChangeHandler}
                            defaultValue={prices.price_type}
                        />
                    </div>


                    <div className="col-md-3">
                        <label className="price-label-name">Enter GBP</label>

                        <InputBoxCustom
                            className="col-md-12"
                            onChange={this.onChangeHandler}
                            field="pub_fee_gbp"
                            placeholder="0.00"
                            value={prices.pub_fee_gbp}
                            InputType="number"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter EUR</label>

                        <InputBoxCustom
                            className="col-md-12"
                            onChange={this.onChangeHandler}
                            field="pub_fee_eur"
                            placeholder="0.00"
                            value={prices.pub_fee_eur}
                            InputType="number"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter USD</label>

                        <InputBoxCustom
                            className="col-md-12"
                            onChange={this.onChangeHandler}
                            field="pub_fee_usd"
                            placeholder="0.00"
                            value={prices.pub_fee_usd}
                            InputType="number"
                        />{" "}
                    </div>
                </div>
                <div className=" row ">
                    <div className="col-md-3">
                        <label className="price-label-name">Enter {this.showPriceTypeName(prices.price_type)} </label>

                        <InputBoxCustom
                            className="col-md-12"
                            onChange={this.onChangeHandler}
                            field="price_type_value"
                            value={prices.price_type_value}
                            InputType="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter GBP</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="submission_fee_gbp"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.submission_fee_gbp}
                            InputType="number"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter EUR</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="submission_fee_eur"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.submission_fee_eur}
                            InputType="number"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter USD</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="submission_fee_usd"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.submission_fee_usd}
                            InputType="number"
                        />
                    </div>
                </div>
                <div className=" row ">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter GBP</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="colour_charge_gbp"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.colour_charge_gbp}
                            InputType="number"
                        />
                    </div>

                    <div className="col-md-3 ">
                        <label className="price-label-name">Enter EUR</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="colour_charge_eur"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.colour_charge_eur}
                            InputType="number"
                        />
                    </div>
                    <div className="col-md-3 ">
                        <label className="price-label-name">Enter USD</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="colour_charge_usd"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.colour_charge_usd}
                            InputType="number"
                        />
                    </div>
                </div>
                <div className=" row ">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter GBP</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="page_other_charge_gbp"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.page_other_charge_gbp}
                            InputType="number"
                        />
                    </div>

                    <div className="col-md-3">
                        <label className="price-label-name">Enter EUR</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="page_other_charge_eur"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.page_other_charge_eur}
                            InputType="number"
                        />
                    </div>
                    <div className="col-md-3">
                        <label className="price-label-name">Enter USD</label>

                        <InputBoxCustom
                            onChange={this.onChangeHandler}
                            field="page_other_charge_usd"
                            className="col-sm-12"
                            placeholder="0.00"
                            value={prices.page_other_charge_usd}
                            InputType="number"
                        />
                    </div>
                </div>

                <div className="text-center" style={{ marginTop: 30 }}>

                    <button
                        style={{ marginRight: 10 }}
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.onClose(this.props.article && this.props.article.id);
                        }}
                    >
                        Close
            </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.onSavePrices}
                    >
                        Save
            </button>
                </div>
            </div>

        )

    }
}




const mapStateToProps = (state) => ({
    article_price: state.articles.article_price || false,
});

const mapDispatchToProps = (dispatch) => ({
    mutateArticlePrice: (ids, action, payload) =>
        dispatch(mutateArticlePrice(ids, action, payload)),
});

export const ArticlePrice = connect(mapStateToProps, mapDispatchToProps)(Form);
