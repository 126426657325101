import {
    SUCCESS_SOCIETY_MEMBER_LIST,
    REQUEST_SOCIETY_MEMBER_LIST,
    FALIED_SOCIETY_MEMBER_LIST,

    GET_SOCIETY_MEMBER,
    CREATE_UPDATE_SOCIETY_MEMBER,
    DELETE_SOCIETY_MEMBER,
} from "../../config";

const SocietyMemberReducer = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_SOCIETY_MEMBER_LIST:
            return state = {
                ...state,
                society_members: action.payload,
                isLoading: false,
            }
        case REQUEST_SOCIETY_MEMBER_LIST:
            return state = {
                ...state,
                isLoading: true,
            }
        case FALIED_SOCIETY_MEMBER_LIST:
            return state = {
                ...state,
                society_members: action.payload,
                isLoading: false,
            }
        case GET_SOCIETY_MEMBER:
            return state = {
                ...state,
                society_member: action.payload
            }
        case CREATE_UPDATE_SOCIETY_MEMBER:
            return state = {
                ...state,
                society_member: action.payload
            }
        case DELETE_SOCIETY_MEMBER:
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default SocietyMemberReducer;
