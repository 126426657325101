import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import { getGroupListTable, deleteGroup } from "../../../../actions";
import NotFound from "../../NotFound";
import { PopupBox, Loading } from "../../../../../core/components/common";

class UL extends BaseComponent {
  state = {
    deleteConfirm: false,
    contentTypeId: null,
    waitingMsg: null,
    deleteStatus: null,
    data: []
  };
  getContentTypeData = async (pageNum = 1, page = 10) => {
    await this.props.getGroupListTable({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.users.results,
      count: this.props.users.count
    });
  };
  async componentDidMount() {
    this.getContentTypeData();
  }
  pageChange = pageNumber => {
    this.getContentTypeData(pageNumber);
  };

  onEditGroup = (id, data) => {
    this.props.history.push("/group/edit-group/" + id, { group: data });
  };

  onViewUser = (id, data) => {
    this.props.history.push("/user/" + id, { user: data });
  };

  ondeleteContentType = id => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        contentTypeId: id
      });
    }
  };

  ondeleteContentTypeHander = async () => {
    const contentTypeId = this.state.contentTypeId;
    this.setState({
      waitingMsg: "Deleting..."
    });
    await this.props.deleteGroup(contentTypeId);
    let data = this.state.data;
    let newData = data.filter(function(element) {
      return element.id !== contentTypeId;
    });
    this.setState({
      waitingMsg: null,
      contentTypeId: null,
      deleteConfirm: false,
      data: newData
    });
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      contentTypeId: null,
      waitingMsg: null,
      deleteStatus: null
    });
  };
  onArticleDownloadClick = async () => {
    // try {
    //   let params = await serialize(this.state.params);
    //   let filter = await serialize(this.state.filterParams);
    //   await this.props.articleDownload({ params, filter });
    //   const url = window.URL.createObjectURL(this.props.article_download_data);
    //   const link = document.createElement("a");
    //   link.href = url;
    //   var d = new Date();
    //   link.setAttribute(
    //     "download",
    //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    // } catch (error) {
    
    // }
  };
  render() {
    const heading = ["name"];
    let user = this.props.users;
    let failedMsg = this.props.failed;

    let errorHander = (
      <>
        {failedMsg && failedMsg.status === 400 && !this.props.users && (
          <PopupBox
            title="Invalid Request"
            msg="Your Request is not valid"
            onCancel={this.cancelLoader}
          />
        )}

        {failedMsg && failedMsg.status === 500 && !this.props.users && (
          <PopupBox
            title="Network Error Found"
            msg="Please Check Internet Connection and Try Again"
            onCancel={this.cancelLoader}
          />
        )}

        {!user && !failedMsg && failedMsg !== null && <Loading />}
       
      </>
    );

    return this.state.status === 403 ? (
      <NotFound />
    ) : (
      <HubContent
        title="Group list"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.ondeleteContentTypeHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}

        {this.state.deleteStatus && (
          <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
        )}

        {this.state.deleteStatus === false && (
          <PopupBox
            title="Failed... Try Again"
            onCancel={this.onCancel}
            NoBtnType="danger"
          />
        )}
        {errorHander}

        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditGroup}
                  onDelete={this.ondeleteContentType}
                  onView={this.onViewUser}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  users: state.group.success_group_create || false,
  failed: state.user.failed || false
});

const mapDispatchToProps = dispatch => ({
  getGroupListTable: payload => dispatch(getGroupListTable(payload)),
  deleteGroup: id => dispatch(deleteGroup(id))
});

export const GroupList = connect(mapStateToProps, mapDispatchToProps)(UL);
