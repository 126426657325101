import React from "react";
import { withRouter } from "react-router-dom";
import { JournalForm } from "../../../../partials";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import { connect } from "react-redux";
import { ClearStatus, CreatePublication } from "../../../../../actions";
import { PopupBox, Button } from "../../../../../../core/components/common";

class Journal extends BaseComponent {
  state = {
    journal: {},
    isEmpty: false,
    success: false,
  };

  onSubmitHandler = async (journal) => {
    if (
      !journal.journal_type ||
      !journal.publication_type ||
      !journal.publisher ||
      !journal.name
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!journal.journal_type && <p>Journal Type must be selected</p>}
            {!journal.publication_type && (
              <p>Publication Type must be selected</p>
            )}
            {!journal.publisher && <p>Publisher must be selected</p>}
            {!journal.name && <p>Journal Name must be filled</p>}
          </div>
        ),
      });
    } else {
      let data = await this.props.CreatePublication(journal);
      if (data.status === 201) {
        this.setState({
          success: true,
        });
      }
    }
  };

  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push(`/article/add-publications`, {
      index: this.props.location.state.index || 0,
    });
  };

  OnChangeTextBox(value, name) {
    const state = {};
    state[name] = value;
    this.setState(state);
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    let successMessage = (
      <>
        {this.state.success && (
          <PopupBox
            NoText="Close"
            msg="You have succesfully created a journal"
            onCancel={this.redirectLink}
          />
        )}
      </>
    );
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={this.redirectLink}
                />
              </div>
              <div className="card-body">
                {errorHander}
                {successMessage}
                {/* Temporary Disabled , we bring it back
        <ul className="nav nav-pills nav-justified" role="tablist">
          <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#home-1"
              role="tab"
            >
              Enter journal details
            </a>
          </li>
          <li className="nav-item waves-effect waves-light">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#profile-1"
              role="tab"
            >
              Upload journal details
            </a>
          </li>
        </ul> */}

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <JournalForm
                      onSubmitHandler={this.onSubmitHandler}
                      OnChangeTextBox={this.OnChangeTextBox.bind(this)}
                      msg={this.state.msg}
                    />
                  </div>
                  {/* 
          //Temporary Disabled 
          <div className="tab-pane p-3" id="profile-1" role="tabpanel">
            <h4 className="mt-0 header-title">Publishier information</h4>
            <p className="text-muted m-b-30">
              Please drop your file. The sequense of columns will be Publisher
              name, address line 1, address line 2, City/Town, County/State,
              Post or zip code, Country, website, VAT, Note
            </p>

            <div className="m-b-30">
              <DragAndDrop />
            </div>

            <div className="text-center m-t-15">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
              >
                Upload
              </button>
            </div>
          </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.orgs.failed || false,
  token: state.auth.token || false,
});
const mapDispatchToProps = (dispatch) => ({
  ClearStatus: () => dispatch(ClearStatus()),
  CreatePublication: (payload) => dispatch(CreatePublication(payload)),
});
export const AddJournal = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Journal)
);
