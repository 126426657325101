import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { TextBox, Button, ErrorHandler, PopupBox } from '../../../../../core/components/common';
import { history } from '../../../../route';
// import { root } from '../../../../data/univ';
import { connect } from 'react-redux'
import { ArticleReject } from '../../../../actions';

// This Component is onfigured By Shahan
class ArticleDeclineForm extends BaseComponent {
    state = {
        note: '',
        article: this.props.match.params.id
    }
    onCancel = () => {
        history.goBack();
    }

    onChange = (value) => this.setState(() => Object.assign(this.state, value))

    onSubmitHander = async () => {
        let reject;
        let status;
        try {
            reject = await this.props.ArticleReject({
                note: this.state.note,
                article: this.state.article
            });
            status = reject.status;
        } catch (err) {
            status = err.status;
        }

        this.setState({
            status
        })
    }

    render() {
        return (
            <HubContent title='Article approval decision'>

                {(this.state.status >= 200 && this.state.status <= 204) && <PopupBox
                    title="Article Rejected"
                    msg="You have reject a article"
                    onCancel={this.onCancel}
                />}

                <ErrorHandler
                    status={this.state.status}
                    onReset={(status) => this.setState({ status })}
                />

                <div className="row">
                    <div className="col-lg-10">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">APC declining form</a>
                                    </li>
                                </ul>

                                {/* <!-- Tab panes --> */}
                                <div className="tab-content">
                                    <div className="tab-pane active p-3" id="home-1" role="tabpanel">
                                    </div>

                                    {/* <!-- Tab panes --> */}
                                    <div className="tab-content">
                                        <div className="form-group row">
                                            <TextBox
                                                label="Reason for declining"
                                                className="col-sm-10"
                                                type="number"
                                                field='note'
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="text-center m-t-15">
                                        <Button
                                            text="Decline"
                                            name="danger"
                                            size={'md'}
                                            onClick={this.onSubmitHander}
                                        />

                                        <Button
                                            text="Cancel"
                                            name="success"
                                            size={'md'}
                                            className={"ml-1"}
                                            onClick={this.onCancel}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    ArticleReject: (payload) => dispatch(ArticleReject(payload))
})

export const ArticleDecForm = connect(undefined, mapDispatchToProps)(ArticleDeclineForm);
