
import React from "react";
import moment from "moment";
import { Select, Spin,AutoComplete, Popover,Button as BtnSubmitted } from "antd";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  InputBox,
  OaDatePicker,
  PopupBox,
  RewardsRow,
  SelectBox,
  ToolTipField,
  Button
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import {  LoadingOutlined } from '@ant-design/icons';

import {
  createOrganisationTokenAction,
  getAllCurrencyList,
  getInstitutionDataLists,
  getAuthorArticleToken,
  getOaSubjectList,
  getPublicationList,
} from "../../../../actions";
import { serialize } from "../../../../../core/lib";
const { Option } = Select;
const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Token</a>
    <br />

  </div>
);
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Token extends BaseComponent {
  state = {
    token: {
      valid_from: moment().format("YYYY-MM-DD"),
      valid_to: moment().format("YYYY-MM-DD"),
      is_promotional_token: true,
      is_active: true,
      quantity: 1,
      token_expire_type:""
    },
    isEmpty: false,
    currencyList: undefined,
    author: "",
    selectItem: "",
    authorArticles: [
      { id: "author", name: "Author" },
      { id: "institution", name: "Institution" },
      { id: "publication", name: "Journal" },
      { id: "subject", name: "Subject" },
    ],
    tokenReward: {},
  };

  tokenQuantity = (n) => {
    let arr = [];
    for (let i = 1; i <= n; i++) {
      arr.push({
        id: i,
        name: i.toString(),
      });
    }

    return arr;
  };

  async componentDidMount() {
    let currencyList = await this.props.getAllCurrencyList();

    this.setState((prevState) => {
      prevState.currencyList = currencyList.results;
      return prevState;
    });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.token, value);
    });

    if (value.hasOwnProperty("valid_from")) {
      this.setState((prevState) => {
        Object.assign(prevState.token, {
          valid_from: moment(value.valid_from).format("YYYY-MM-DD"),
        });
      });
    }

    if (value.hasOwnProperty("valid_to")) {
      this.setState((prevState) => {
        Object.assign(prevState.token, {
          valid_to: moment(value.valid_to).format("YYYY-MM-DD"),
        });
      });
    }
  };
  

  onSubmitHandler = async () => {
    let { token } = this.state;

    if (this.isNumber(this.state.object_id)) {
      if (
        !this.state.token.name ||
        !this.state.token.valid_from ||
        !this.state.token.valid_to
      ) {
        this.setState({
          isEmpty: (
            <div>
              {!this.state.token.name && <p>Token batch number is missing.</p>}
              {!this.state.token.valid_from && <p>Valid from must be selected</p>}
              {!this.state.token.valid_to && <p>Valid to must be selected</p>}
            </div>
          ),
        });
      } else if (this.state.token.valid_from === this.state.token.valid_to) {
        this.setState({
          isEmpty: (
            <div>
              <p>Valid from & valid to cannot be same</p>
            </div>
          ),
        });
      } else {
        try {

          if (token.is_promotional_token) {
            Object.assign(token, { reward: this.state.tokenReward });
          } else {
            Object.assign(token, { reward: {} });
          }

       
          let tokenData = { ...token, object_id: this.state.object_id };
          await this.props.createOrganisationToken(tokenData);
          this.props.history.push("/token/token-list");
        } catch (e) {
          this.log("Error Found in OA Token Generate", e);
        }
      }
    } else {
      this.setState({
        errorMsg: "It appears you have not selected your correct affiliated institution name from organisation dropdown field, select your correct institution and resubmit. ",
      });
    }


  };

  onTokenRewardChange = (tokenReward) => {
    this.setState({
      tokenReward,
    });
  };

  onChangeSelectItem = async (value) => {
    let { token } = this.state;
    Object.assign(token, { token_for: value });

    this.setState({
      selectItem: value,
      token: token,
    });
    if (value === "author") {
      let authorArticles;
      authorArticles = await this.props.getAuthorArticleToken();
      authorArticles = authorArticles.results;
      const data = [];
      authorArticles &&
        authorArticles.map((item) =>
          data.push({ id: item.id, name: item.name })
        );
      this.setState((prevState) => {
        prevState.data = data;
        return prevState;
      });
    } else if (value === "institution") {
      this.props.getInstitutionDataLists();
      this.setState((prevState) => {
        prevState.data = [];

        return prevState;
      });
    } else if (value === "publication") {
      let payload = {};
      let filterParams = {
        page_size: 999999,
      };
      payload.filter = serialize(filterParams);
      await this.props.getPublicationList(payload);

      this.setState((prevState) => {
        prevState.data =
          this.props.publication_list && this.props.publication_list.results;

        return prevState;
      });
    } else if (value === "subject") {
      let payload = {
        pageSize: 999999,
      };
      await this.props.getOaSubjectList(payload);

      this.setState((prevState) => {
        prevState.data =
          this.props.oa_subjects && this.props.oa_subjects.results;

        return prevState;
      });
    }
  };
  cancelLoader = async () => {
    this.setState({
      isEmpty: false,
    });
  };
  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState({
        object_id: item.id,
        orgsError: false

      });
    }
  };
  onChange = (value) => {
    let data = this.props.institution_list && this.props.institution_list.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        object_id: data && data.id,
        orgsError: false,
      });
    } else {
      this.setState({
        orgsError: true,
        orgs_name:value

      });
    }

  
  };

  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getInstitutionDataLists(value);
      this.setState({
        institution_list:
        this.props.institution_list &&
        this.props.institution_list.map((item) => ({
          id: item.id,
          value: item.name,
        })),
      publication: [],
      author: [],
      subject: [],

      })
    }, 200);
  };


  render() {

    
    let {
      currencyList,
      data,
      authorArticles,
      institution_list,
      tokenReward,
    } = this.state;
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent title="Generate OA token" className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card m-b-20 ">

              <div className="card-body">
                <div className="vat-tax-button email d-flex justify-content-end">


                  <Popover placement="leftTop" content={content} trigger="click">
                    <Button
                      className="btn btn-secondary float-right "
                      type=""
                      name=""
                      text="Help"
                    >Help</Button>
                  </Popover>
                  <div className="danger float-right"
                  >
                    <button
                      type="danger"
                      className="btn btn-danger float-right "
                      onClick={() => this.props.history.goBack()}
                      style={{ marginLeft: 10 }}
                    >
                      X

</button>                  </div>

                </div>
                <div className="form-group row">
                  <InputBox
                    labelInfo={true}
                    labelInfoTitle="Enter Token batch number"
                    label="Token batch no."
                    onChange={this.onChangeHandler}
                    field="name"
                    className="col-sm-3 col-lg-4"
                    labelClass="col-sm-3 col-lg-2"
                    placeholder="Enter Token batch number"
                    defaultValue={this.state.token.name}
                  />

                  <SelectBox
                    labelInfo={true}
                    labelInfoTitle="Enter Quantity"
                    label="Quantity"
                    placeholder="Select Quantity"
                    onChange={this.onChangeHandler}
                    field="quantity"
                    className="col-sm-3 col-lg-4"
                    labelClass="col-sm-3 col-lg-2"
                    data={this.tokenQuantity(100)}
                    defaultValue={this.state.token.quantity}
                    isRequired={false}
                  />
                </div>
                <div className="form-group row">
                  <SelectBox
                    labelInfo={true}
                    labelInfoTitle="Token Usage "
                    label="Token Usage"
                    placeholder="Select Token Usage "
                    onChange={this.onChangeHandler}
                    field="token_expire_type"
                    className="col-sm-3 col-lg-4"
                    labelClass="col-sm-3 col-lg-2"
                    data={[{ id: "unlimited", name: "Unlimited" }, {id:"number_of_time",name:"Number of times"}]}
                    isRequired={false}
                  />
                  {
                    this.state.token.token_expire_type ==="number_of_time" &&    <InputBox
                    labelInfo={true}
                    labelInfoTitle="Enter number of Time"
                      label="Number of Time"

                    onChange={this.onChangeHandler}
                    field="number_of_time"
                    className="col-sm-3 col-lg-4"
                    labelClass="col-sm-3 col-lg-2"
                    placeholder="Enter number of Time"
                    defaultValue={this.state.token.number_of_time}
                  />

                 }
                </div>

                <div className="form-group row">
                  <OaDatePicker
                    labelInfo={true}
                    labelInfoTitle="Select Valid from"
                    className="col-sm-3 col-lg-4"
                    labelClass="col-sm-3 col-lg-2"
                    label="Valid from"
                    field="valid_from"
                    onChange={this.onChangeHandler}
                    placeholder="Select Valid from"
                    showTime={false}
                  />
                  <OaDatePicker
                    labelInfo={true}
                    labelInfoTitle="Select Valid to"
                    className="col-sm-3 col-lg-4"
                    labelClass="col-sm-3 col-lg-2"
                    label="Valid to"
                    field="valid_to"
                    onChange={this.onChangeHandler}
                    placeholder="Select Valid to"
                    showTime={false}
                  />
                </div>

                <div className="form-group row">
                  <div className="col-form-label col-sm-3 col-lg-2">
                    <label>Token for</label>
                    <ToolTipField labelInfoTitle="Select Token for"/>

                  </div>
                  <div className="col-sm-3 col-lg-4">
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      field="token_for"
                      placeholder="Select author or instituation or journal or subject"
                      optionFilterProp="children"
                      onChange={this.onChangeSelectItem}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {authorArticles && authorArticles.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {this.state.selectItem === "institution" ? (
                    <div className="col-md-6">

                      

                      <div className="row auto-complete-search">
                 
                        <label className="col-form-label col-md-4">Allocate to <span style={{ color: "red", paddingLeft: "5px" }}>*</span> </label>

                        <div className="col-md-8">
                          <AutoComplete
                          notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}

                            options={institution_list}
                            style={{
                              width: "100%",
                            }}
                            onSelect={(val, option) =>
                              this.onSelect(val, option)
                            }
                            onSearch={this.onSearch}
                            onChange={this.onChange}
                            placeholder={`Type the ${this.state.selectItem} Name `}
                            />
                           {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
</span>}
                        </div>
                        <div className="search-auto-spin">
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>
                      </div>
                    </div>
                  ) : (
                      <SelectBox
                        label="Allocate to"
                        placeholder={`Allocate to ${this.state.selectItem}`}
                        onChange={this.onChangeHandler}
                        field="object_id"
                        className="col-sm-3 col-lg-4"
                        labelClass="col-sm-3 col-lg-2"
                        data={data}
                        defaultValue={this.state.token.object_id}
                        isRequired={true}
                      />
                    )}
                </div>
                {this.state.token.is_promotional_token && currencyList ? (
     
                    <RewardsRow
                      data={tokenReward}
                      currencyList={currencyList}
                      onChangeHandler={this.onTokenRewardChange}
                    />
                
                ) : (
                    ""
                  )}
                <div className="form-group row">
                <InputBox
                    labelInfo={true}
                    labelInfoTitle="Enter Note"
                    label="Note"
                    onChange={this.onChangeHandler}
                    field="note"
                    className="col-sm-3 col-lg-4"
                    labelClass="col-sm-3 col-lg-2"
                    placeholder="Enter Note"
                    defaultValue={this.state.token.note}
                  />

                 </div>
        



                  <div className="text-center m-t-15">
                    <BtnSubmitted type="primary"  loading={this.props.isLoading} onClick={this.onSubmitHandler}>
                      {this.props.isLoading !== true ? "Generate OA token" : "Processing"}
                    </BtnSubmitted>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  orgList: state.OaDeals.counter_org ,
  publication_list: state.institutions.publication_list,
  oa_subjects: state.OaSubject.oa_subjects,
  
  institution_list: (state.institutions && state.institutions.institution_list)|| [],
  loading: state.institutions.loading,

  isLoading:state.oaToken.isLoading ,
});

const mapDispatchToProps = (dispatch) => ({
  createOrganisationToken: (payload) =>
    dispatch(createOrganisationTokenAction(payload)),
  getInstitutionDataLists: (payload) =>
    dispatch(getInstitutionDataLists(payload)),

  getAllCurrencyList: () => dispatch(getAllCurrencyList()),
  getAuthorArticleToken: () => dispatch(getAuthorArticleToken()),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
});

export const OaToken = connect(mapStateToProps, mapDispatchToProps)(Token);
