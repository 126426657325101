import React from "react";
import { InputNewBox } from "../../../../core/components/common";

class Form extends React.PureComponent {
  state = {
    co_authors: {},
  };
  componentDidMount() {
    let item = this.props.value
    item &&
      this.setState({
        division: (item && item.department && item.department.division && item.department.division.name) || "",
        department: (item && item.department && item.department.name) || "",
        co_authors: {
          affiliated_organisation: item.affiliated_organisation,
          affiliation: item.affiliation,
          last_name: item.last_name,
          middle_name: item.middle_name,
          name: item.name,
          orcid_id: item.orcid_id,
          pmc_id: item.pmc_id,
          position: item.position,
          salutation: item.salutation,
          first_name: item.first_name,
          email: item.email,
        },
      });

  }

  onChangeHandler = (value) => {
    this.setState((prevState) => Object.assign(prevState.co_authors, value));
  };
  onCloseModal = (e) => {
    let overlay = document.getElementById("CoAuthorDialog");
    if (e.target === overlay) {
      this.props.onClose();
    }
  };

  onSaveCoAuthor = (e) => {
    e.preventDefault();

    let { co_authors } = this.state;
    Object.assign(co_authors, {
      department: {
        name: this.state.department,
      },
    })
    if (Object.entries(co_authors).length === 0) {
      alert("fields are empty");
    } else {
      this.props.onSaveAuthor(co_authors);
    }
  };

  render() {
    let { co_authors } = this.state;
    let { fieldLabel } = this.props;
    let CoAuthorInfo = (
      <div>
        <div className="form-group row">
          <InputNewBox
            label="Salutation"
            onChange={this.onChangeHandler}
            field="salutation"
            className="col-md-4"
            placeholder="Salutation"
            labelClass="col-md-2"
            defaultValue={co_authors.salutation}
          />

          <InputNewBox
            label="First name"
            onChange={this.onChangeHandler}
            field="first_name"
            className="col-md-4"
            placeholder="First name"
            labelClass="col-md-2"
            defaultValue={co_authors.first_name}
          />
        </div>

        <div className="form-group row">
          <InputNewBox
            label="Middle name"
            onChange={this.onChangeHandler}
            field="middle_name"
            className="col-md-4"
            placeholder="Middle name"
            labelClass="col-md-2"
            defaultValue={co_authors.middle_name}
          />

          <InputNewBox
            label="Last name"
            onChange={this.onChangeHandler}
            field="last_name"
            className="col-md-4"
            placeholder="Last name"
            labelClass="col-md-2"
            defaultValue={co_authors.last_name}
          />
        </div>

        <div className="form-group row">
          <InputNewBox
            label={`${fieldLabel} email`}
            onChange={this.onChangeHandler}
            field="email"
            className="col-md-4"
            placeholder="Corresponding author email"
            labelClass="col-md-2"
            InputType="email"
            defaultValue={co_authors.email}
          />

          <InputNewBox
            onChange={this.onChangeHandler}
            field="affiliation"
            label="Affiliation"
            className="col-md-4"
            placeholder="Affiliation"
            defaultValue={co_authors.affiliation}
            style={
              this.props.is_author_affiliation_match === false
                ? modal.error
                : {}
            }
          />
        </div>

        <div className="form-group row">
          <InputNewBox
            label={`${fieldLabel} dept`}
            onChange={this.onChangeHandler}
            field="department"
            className="col-md-4"
            placeholder={
              fieldLabel ? `${fieldLabel} department` : "Author department"
            }
            labelClass="col-md-2"
            defaultValue={this.state.department}
          />
          <InputNewBox
            label="Orcid ID"
            className="col-md-4"

            onChange={this.onChangeHandler}
            field="orcid_id"
            placeholder="Orcid ID"
            defaultValue={co_authors.orcid_id}
          />

        </div>

        <div className="form-group row">

          <InputNewBox
            label="PMC id"
            className="col-md-4"

            onChange={this.onChangeHandler}
            field="pmc_id"
            placeholder="PMC id"
            defaultValue={co_authors.pmc_id}
          />
          <InputNewBox
            className="col-md-4"

            label="Position"
            onChange={this.onChangeHandler}
            field="position"
            placeholder="Position"
            defaultValue={co_authors.position}
          />
        </div>


      </div>
    );

    return (
      <div
        onClick={this.onCloseModal}
        id="CoAuthorDialog"
        style={modal.overlay}
      >
        <div className={`col-10 col-lg-8 bg-white alert`} style={modal.boxStyle}>
          {CoAuthorInfo}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.props.onClose && this.props.onClose();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSaveCoAuthor}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0, 25px)",
    height: boxHeight,
  },
  error: { border: "1px solid red" },
};
export const CoAuthor = Form;

