import  {
    FILTER_DATA,
  } from "../../config";
import { api } from "../../core/api";


export const filterData = (payload) => ({
    type: FILTER_DATA,
    payload
})

// Get Journalanization List for hub
export const getAllFilterData = (payload) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let url = `${payload.url}/?page_size=9999`
            api.get(url).then((res) => {
                dispatch(filterData(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}