import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  SelectBox,
  InputBox,
  Button,
  PopupBox,
  // ErrorHandler
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import {
  getLicense,
  getApcOption,
  getApcFundSrc,
  getOaTokenisActive,
  ArticleApprove,
  getOrganisationToken
} from "../../../../actions";
import { history } from "../../../../route";

class ArticleApprovalForm extends BaseComponent {
  state = {
    article: false,
    msg: false,
    currency: "",

    approval: {
      article: Number(this.props.match.params.id),
      licence: null,
      apc_option: null,
      approval_source: "string",
      breakdown: []
    },

    license: [],
    apc_options: [],
    apc_fund_src: [],
    oa_tokens: [],
    isToken: false,
    OaDeal: false,
    totalAmount: 0,
    counter: 0,
    fundSources: [],
    fees: {
      sub_fee: 0,
      color_fee: 0,
      page_other_fee: 0,
      pub_fee: 0,
      currency: ""
    }
  };

  chargeDescriptionOptions = [
    { id: "APC", name: "APC" },
    { id: "BPC", name: "BPC" },
    { id: "Submission fee", name: "Submission fee" },
    { id: "Colour charge", name: "Colour charge" },
    { id: "Page & other charges", name: "Page & other charges" },
    { id: "All charges", name: "All charges" }
  ];

  async componentDidMount() {
    const license = await this.props.getLicense();
    const apc_options = await this.props.getApcOption();
    const apc_fund_src = await this.props.getApcFundSrc();
    await this.props.getOaTokenisActive(); // storing in redux
    const oa_tokens = this.props.oa_tokens;
    this.setState(prevState => {
      if (license) prevState.license = license;
      if (apc_options) prevState.apc_options = apc_options;
      if (apc_fund_src) prevState.apc_fund_src = apc_fund_src;
      if (oa_tokens) prevState.oa_tokens = oa_tokens;
      return prevState;
    });
  }


    
  componentWillMount() {
    this.checkInitData();
    this.props.getOrganisationToken({ pageNum: 1, pageSize: 999999 });
  }

  
  checkInitData = () => {
    let { article, OaDeal } = this.props.location.state;
    let sub_fee,
      color_fee,
      page_other_fee,
      pub_fee,
      currency = "gbp";

    switch (OaDeal.currency_name.toLowerCase()) {
      case "usd":
        sub_fee = Number(article.submission_fee_usd);
        color_fee = Number(article.colour_charge_usd);
        page_other_fee = Number(article.page_charge_usd);
        pub_fee = Number(article.pub_fee_usd);
        currency = OaDeal.currency_name.toUpperCase();
        break;
      case "eur":
        sub_fee = Number(article.submission_fee_eur);
        color_fee = Number(article.colour_charge_eur);
        page_other_fee = Number(article.page_charge_eur);
        pub_fee = Number(article.pub_fee_eur);
        currency = OaDeal.currency_name.toUpperCase();
        break;
      default:
        sub_fee = Number(article.submission_fee_gbp);
        color_fee = Number(article.colour_charge_gbp);
        page_other_fee = Number(article.page_charge_gbp);
        pub_fee = Number(article.pub_fee_gbp);
        currency = currency.toUpperCase();
        break;
    }

    this.setState({
      fees: {
        sub_fee,
        color_fee,
        page_other_fee,
        pub_fee,
        currency
      },
      approval: {
        ...this.state.approval,
        currency
      }
    });
  };

  onSubmitHander = async () => {
    let approval;
    let status;
    try {
      approval = await this.props.ArticleApprove(this.state.approval);
      status = approval.status;
    } catch (err) {
      status = err.status;
    }

    this.setState({
      status
    });
  };

  onCancel = () => history.goBack();
  onChange = value =>
    this.setState(prevState => Object.assign(prevState.approval, value));

  onChangeApcOption = value => {
    let flag = false;
    if (value.additional_info) {
      flag = value.additional_info.name.toLowerCase();
    }

    this.setState(prevState => {
      Object.assign(prevState.approval, {
        apc_option: value.apc_option
      });
      prevState.approval.breakdown = [];
      prevState.isToken = flag;
      return prevState;
    });
  };

  onChangeDeposit = (value, index) => {
    this.setState(prevState => {
      prevState.approval.breakdown[index] = {
        article: this.state.approval.article,
        apc_option: this.state.approval.apc_option,
        ...prevState.approval.breakdown[index],
        ...value
      };
      if (value.token) {
        Object.assign(prevState.approval, { token_id: value.token });
      }
      return prevState;
    });
  };

  render() {
    let { isToken, approval } = this.state;
    let { article, OaDeal } = this.props.location && this.props.location.state; // Coming from ArticleFull.jsx -> /views/hub/articles
    let DepositFundOption = [];
    let {
      sub_fee,
      color_fee,
      page_other_fee,
      pub_fee,
      currency
    } = this.state.fees;
    let discount = Number(OaDeal.discount);
    let vat = Number(OaDeal.vat_percentage);

    function calculateTotal() {
      let total = 0;
      let discount = OaDeal.discount || 0;
      let vat = OaDeal.vat_percentage || 0;
      total =
        Number(pub_fee) +
        Number(sub_fee) +
        Number(color_fee) +
        Number(page_other_fee);
      total = total - (total * discount) / 100;
      total = total + (total * vat) / 100;

      return total.toFixed(2);
    }

    function getBalance() {
      let balance = Number(calculateTotal());
      let tmpAmount = 0;
      if (approval.breakdown.length > 0) {
        approval.breakdown.map(({ amount }) => {
          return (tmpAmount += Number(amount || 0));
        });
      }

      balance = balance - tmpAmount;

      return balance.toFixed(2);
    }

    if (isToken && isToken !== "oatoken") {
      DepositFundOption.push(
        <div className="thead bg-primary text-white py-1" key={"header"}>
          <div className="row px-3">
            {/*<div className="col-md-2">Fund type</div>*/}
            <div className="col-md-3">Fund Source</div>
            <div className="col-md-3">Funder Name</div>
            <div className="col-md-4">Charge Description</div>
            <div className="col-md-2 text-right">Amount</div>
          </div>
        </div>
      );
      if (isToken === "depositcredit") {
        for (var i = 0; i <= this.state.counter; i++) {
          let index = i;
          DepositFundOption.push(
            <div className="Token" key={index} id={index}>
              <div className="tbody">
                <div className="row py-2">
                  <SelectBox
                    className="col-md-3"
                    data={this.state.apc_fund_src}
                    field="fund_source"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />
                  <InputBox
                    className="col-md-3"
                    field="funder_name"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />
                  {/* <InputBox
                    className="col-md-4"
                    field="charge_description"
                    onChange={value => this.onChangeDeposit(value, index)}
                  /> */}
                  <SelectBox
                    label={false}
                    className="col-md-4"
                    data={this.chargeDescriptionOptions}
                    field="charge_description"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />
                  <InputBox
                    className="col-md-2"
                    field="amount"
                    InputType="number"
                    onChange={value => this.onChangeDeposit(value, index)}
                  />
                </div>
              </div>
            </div>
          );
        }
      } else {
        let index = 0;
        DepositFundOption.push(
          <div className="Token" key={index} id={index}>
            <div className="tbody">
              <div className="row py-2">
                <SelectBox
                  className="col-md-3"
                  data={this.state.apc_fund_src}
                  field="fund_source"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                <InputBox
                  className="col-md-3"
                  field="funder_name"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                {/* <InputBox
                  className="col-md-4"
                  field="charge_description"
                  onChange={value => this.onChangeDeposit(value, index)}
                /> */}
                <SelectBox
                  label={false}
                  className="col-md-3"
                  data={this.chargeDescriptionOptions}
                  field="charge_description"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                <InputBox
                  className="col-md-2"
                  field="amount"
                  InputType="number"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
              </div>
            </div>
          </div>
        );
      }
      isToken === "depositcredit" &&
        DepositFundOption.push(
          <div className="text-right " key={"footer"}>
            <Button
              text="-"
              size="md"
              name="primary"
              className="mr-1"
              onClick={() =>
                this.setState({
                  counter: this.state.counter > 0 && this.state.counter - 1
                })
              }
            />
            <Button
              text="+"
              size="md"
              name="primary"
              onClick={() => this.setState({ counter: this.state.counter + 1 })}
            />
          </div>
        );
    } else {
      let index = 0;
      let oaTokenList = [];
      this.props.oa_tokens &&
        this.props.oa_tokens.results.map(token => {
          return oaTokenList.push({
            name: token.token_info,
            ...token
          });
        });
        
      isToken === "oatoken" &&
        DepositFundOption.push(
          <div className="table deposit" key={index}>
            <div className="thead bg-primary text-white py-1">
              <div className="row px-3">
                <div className="col-md-3">Token</div>
                <div className="col-md-6">Charge Description</div>
                <div className="col-md-3 text-right">Amount</div>
              </div>
            </div>
            <div className="tbody">
              <div className="row py-2">
                <SelectBox
                  className="col-md-3"
                  InputType="number"
                  field="token"
                  data={oaTokenList}
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                
                <SelectBox
                  label={false}
                  className="col-md-6"
                  data={this.chargeDescriptionOptions}
                  field="description"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
                <InputBox
                  className="col-md-3"
                  field="amount"
                  onChange={value => this.onChangeDeposit(value, index)}
                />
              </div>
            </div>
          </div>
        );
    }

    return (
      <HubContent title={"Article approval - " + (article.title || "")}>
        {this.state.status >= 200 && this.state.status <= 204 && (
          <PopupBox
            title="Succesfully Approved"
            msg="You have approved the APC for this article"
            onCancel={this.onCancel}
          />
        )}


        <div className="row">
          {!this.state.msg ? (
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <ul className="nav nav-pills nav-justified" role="tablist">
                    <li className="nav-item waves-effect waves-light">
                      <a className="nav-link active" href="#s">
                        APC information
                      </a>
                    </li>
                  </ul>

                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    <div
                      className="tab-pane active p-3"
                      id="home-1"
                      role="tabpanel"
                    />
                    <div className="form-group row">
                      <InputBox
                        label={"APC - " + currency}
                        className="col-sm-2"
                        inputClass="text-right"
                        disabled
                        defaultValue={pub_fee.toFixed(2)}
                      />
                      <InputBox
                        label={"Submission fee - " + currency}
                        className="col-sm-2"
                        inputClass="text-right"
                        disabled
                        defaultValue={sub_fee.toFixed(2)}
                      />
                      <InputBox
                        label={"Colour charge - " + currency}
                        className="col-sm-2"
                        inputClass="text-right"
                        disabled
                        defaultValue={color_fee.toFixed(2)}
                      />
                    </div>

                    <div className="form-group row">
                      <InputBox
                        label={"Page & other charges - " + currency}
                        className="col-sm-2"
                        inputClass="text-right"
                        disabled
                        defaultValue={page_other_fee.toFixed(2)}
                      />
                      <InputBox
                        label="Discount%"
                        className="col-sm-2"
                        inputClass="text-right"
                        disabled
                        defaultValue={discount.toFixed(2)}
                      />
                      <InputBox
                        label="VAT%"
                        className="col-sm-2"
                        inputClass="text-right"
                        disabled
                        defaultValue={vat.toFixed(2)}
                      />
                    </div>
                    <div>
                      <hr />
                    </div>
                    <div className="form-group row">
                      <InputBox
                        label="University VAT #"
                        className="col-md-6"
                        placeholder="vat-no-34556778"
                      />

                      <InputBox
                        label="VAT Deduction"
                        className="col-sm-2"
                        disabled
                      />
                    </div>
                    <div>
                      <hr />
                    </div>
                    <div className="form-group row" align="right">
                      <div className="col-sm-8" />
                      <InputBox
                        label="Total"
                        className="col-sm-2"
                        inputClass="text-right"
                        disabled
                        defaultValue={calculateTotal()}
                      />
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <h6
                          style={{
                            margin: "0",
                            padding: "0",
                            color: "#7d7db7"
                          }}
                        >
                          Approval
                        </h6>
                      </div>
                    </div>

                    <div className="form-group row">
                      <SelectBox
                        label="Select license type"
                        data={this.state.license}
                        onChange={this.onChange}
                        field="licence"
                      />
                      <SelectBox
                        label="APC Option"
                        data={this.state.apc_options}
                        onChange={this.onChangeApcOption}
                        field="apc_option"
                        isData={true}
                      />
                    </div>

                    {DepositFundOption}

                    <div>
                      <hr />
                    </div>

                    <div className="form-group row " align="right">
                      <div className="col-sm-8 d-flex align-items-center justify-content-end">
                        {getBalance() < 0 && (
                          <div className="text-danger">
                            Out of range - Balance should Zero (0)
                          </div>
                        )}
                      </div>
                      <InputBox
                        label="Balance"
                        placeholder={getBalance()}
                        disabled
                        className="col-sm-2"
                      />
                    </div>

                    <div className="text-right m-t-15 ">
                      <Button
                        text="Approve"
                        name="primary"
                        size={"md"}
                        onClick={this.onSubmitHander}
                      />

                      <Button
                        text="Cancel"
                        name="danger"
                        size={"md"}
                        className={"ml-1"}
                        onClick={this.onCancel}
                      />
                    </div>
                  </div>
                  {/* <!-- end Tab panes --> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12">
              <h1>Nothing Found</h1>
            </div>
          )}
          {/* <!-- end col --> */}
        </div>
        {/* <!-- end row -->      */}
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  oa_tokens: state.oaToken.organisation_token_list
});

const mapDispatchToProps = dispatch => ({
  getLicense: () => dispatch(getLicense()),
  getApcOption: () => dispatch(getApcOption()),
  getApcFundSrc: () => dispatch(getApcFundSrc()),
  getOaTokenisActive: () => dispatch(getOaTokenisActive()),
  ArticleApprove: payload => dispatch(ArticleApprove(payload)),
  getOrganisationToken: payload => dispatch(getOrganisationToken(payload))
});

export const ArticalAprForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleApprovalForm);
