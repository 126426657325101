import React from 'react';
import Iframe from "react-iframe";

import { connect } from 'react-redux'
import AppSettings from '../../../../__base__/AppSettings';
import { HubContent } from '../../../../core/components';
import  WhitePublisherCard  from './components/WhitePublisherCard';
import { Card, DBChart, SelectBox,Loading} from '../../../../core/components/common';
import { getPublisherDefaultDashboardData, getInstitutionsList,getoaDealFilterList,getPremiumDashboardData, getChildOrganisationList, getPublicPublisher } from '../../../actions';
import { PrimaryPublisherCard } from '../publisher/components/PrimaryPublisherCard';
import NewOAdeals from '../publisher/components/NewOAdeals';
import ReadPublishList from '../publisher/components/ReadPublishList';
import { serialize } from '../../../../core/lib'

const apcAverageStyle = {
  // height: 130,
  width: 140,
  backgroundColor: 'white',
  marginTop:10,
  fontSize: 14,
  justifyContent: 'center',
};

class DB extends AppSettings {
    state = {
    filterParams: {},
    showPop:false,
    onClose:false
  }
    HandleClick = ()=>{
    this.setState({
      showPop:!this.state.showPop,
      onClose:!this.state.onClose
    })
  }
   HandleOnClose = ()=>{
    this.setState({
      showPop:false,
      onClose:false
    })
  }
  defaultCurrency(){
    return ['£ 0.00','$ 0.00','€ 0.00' ];
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  apcAverageCalculation = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [0.00, 0.00, 0.00];
    }

    let gbp = component.data.gbp ? parseFloat(component.data.gbp).toFixed(2) : 0.00;
    let usd = component.data.usd ? parseFloat(component.data.usd).toFixed(2) : 0.00;
    let eur = component.data.eur ? parseFloat(component.data.eur).toFixed(2) : 0.00;

    return [usd, eur, gbp];
  }

  currencyCalculation = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return ['£ 0.00', '$ 0.00', '€ 0.00'];
    }

    let gbp = '£ ' + this.numberWithCommas( component.data.gbp ? parseFloat(component.data.gbp).toFixed(2) : 0.00);
    let usd = '$ ' + this.numberWithCommas( component.data.usd ? parseFloat(component.data.usd).toFixed(2) : 0.00);
    let eur = '€ ' + this.numberWithCommas( component.data.eur ? parseFloat(component.data.eur).toFixed(2) : 0.00);
    return [gbp, usd, eur];
  }
  async componentDidMount() {
    await this.props.getoaDealFilterList()

    try {
      await this.props.getChildOrganisationList();
    } catch (error) {
    }

    try {
      await this.props.getPublicPublisher();
    } catch (error) {
    }
    
    // let params = '?components=token-summary-component,total-deposit-component&filter_publisher=12&filter_year=all&filter_deal=all';
 
    this.getDashboardData(
      (this.props.dashboardcontrol &&
        this.props.dashboardcontrol.dashboards &&
        this.props.dashboardcontrol.dashboards.DEFAULT) || 'BASIC'
    );

  }

  pieChartCalculation = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    return Object.values(component.data);
  }

  getInstitutionLabels = (data) => {
    let items = data.items || [];
    return items.map(item => this.capitalize(item.institution_name));
  }

  getPublisherLabels = (data) => {
    let items = data.items || [];
    return items.map(item => this.capitalize(item.publisher.name));
  }
  
  getLabels = (component) =>{ 
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let data = Object.keys(component.data);
    return data.map(item=> this.capitalize(item.replace(/_/g, ' ')));
  }

  common(arr1, arr2) {
    var newArr = [];
    newArr = arr1.filter(function(v){ return arr2.indexOf(v) >= 0;})
    newArr.concat(arr2.filter(function(v){ return newArr.indexOf(v) >= 0;}));
    return newArr;
  }

  chartCalculation(component) { 
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }
    
    let keys = ['funder', 'institution_name','journal','publisher'];
    let values = [];
    if('items' in component){
      let data = component.items ? component.items : [];
      data.map(item => {
        let ObjectKeys = Object.keys(item);
        let key =  this.common(ObjectKeys, keys).toString();
        if(key ==='funder' || key === 'institution_name'){
          values.push(item.total_funding)
        }else if(key ==='publisher'){
          values.push(item.total_publish)
        }else
        {
          values.push(item.total)
        }
        return item;
      });
      return values;
    }else{
      return 'data' in component ? Object.values(component.data) : [];
    }
  }

  filterData = async (value) => {
    await this.setState((prevState) => {
      return prevState.filterParams = Object.assign(prevState.filterParams, value);
    })
    let filter = serialize(this.state.filterParams);
    this.props.getPublisherDefaultDashboardData({ filter });
  }
  capitalize(s){
    return s[0].toUpperCase() + s.slice(1);
  }
  onChangeSwitch = async (checked) => {
    this.setState(
      {
        executed: checked,
        hideTab: checked !== true ? true : false,
      },
      async () => {
        let dashboardType = this.state.executed === true ? 'PREMIUM' : 'BASIC';

        this.getDashboardData(dashboardType);
      }
    );
  };
  getDashboardData = async (dashboardType) => {
    try {
      this.setState({
        executed: dashboardType === 'PREMIUM',
      });

      await this.props.getPremiumDashboardData(dashboardType);

      if (this.props.dashboardcontrol
        && this.props.dashboardcontrol.dashboards
        && this.props.dashboardcontrol.dashboards.DEFAULT !== dashboardType
        && this.props.dashboardcontrol.dashboards.OTHERS.length > 0
      ) {
        let authData = this.props.dashboardcontrol;
        let dashboards = Object.assign({}, authData.dashboards);
        let others = dashboards.OTHERS;

        others = others.filter(item => item !== dashboardType);

        others.push(dashboards.DEFAULT);

        Object.assign(authData.dashboards, {
          DEFAULT: dashboardType,
          OTHERS: others,
        });

        localStorage.setItem("auth", JSON.stringify(authData));
      }

      if (dashboardType === 'PREMIUM') {
          this.setState({
            dashBoardURL: this.props.dashbaordPremiumData.data,
            success: this.props.dashbaordPremiumData.success,
          });
        


      }

    } catch (error) {

    }
  }
  render() {
    let { publisher_list, child_orgs_list } = this.props
    
    let organisations = [{
      name: 'All affiliated organisations',
      id: 'all'
    }]

    let publishers = [{
      name: 'All publishers',
      id: 'all'
    }];

    let year = [{
      name: 'Years',
      id: 'all',
    }]

    function oaDeal(input) {
      var output = [];
    
      for (var index in input) {
        output.push({
          "id": index,
          "name": input[index]
        });
      }
    
      return output;
    }
  

    for(let i=2018;i<=2050;i++){
      year.push({
        name: i,
        id: i
      })
    }

    organisations = (child_orgs_list && organisations.concat(child_orgs_list)) || organisations;
    publishers = (publisher_list.results && publishers.concat(publisher_list.results)) || publishers;

    return (
      <HubContent 
        article_title="Pending articles"
        article_income_title="Estimated income"
        hideTab={this.state.hideTab}
        executed={this.state.executed}
        onChangeSwitch={this.onChangeSwitch}
        dashboardcontrol={true}
     
        handleSaveDesign={this.onDragAndDropSave}
        hideSaveBtn={this.state.hideSaveBtn}
      
      >
  {this.state.executed !== true ? (<div>
        <div className="row mb-4">
  
          <SelectBox
            className="col-md-3"
            data={oaDeal(this.props.oadeal_filter&&this.props.oadeal_filter.data)}
            onChange={this.filterData}
            field="filter_deal"
            defaultValue="all"
          />
          <SelectBox
            className="col-md-3"
            data={publishers}
            onChange={this.filterData}
            field="filter_publisher"
            defaultValue="all"
          />
          <SelectBox
            className="col-md-3"
            data={organisations}
            onChange={this.filterData}
            field="filter_child_organisation"
            defaultValue="all"
          />
          <SelectBox
            className="col-md-3"
            data={year}
            onChange={this.filterData}
            field="filter_year"
            defaultValue="all"
          />

          {/*
            End of Filtering Dashboard Components
          */}
        </div>
        <div className="row mb-4">
          <PrimaryPublisherCard
            icon="mdi mdi-cube-outline"
            title="AVAILABLE FUNDS"
            body={
              this.props.available_fund
                ? this.currencyCalculation(this.props.available_fund)
                : this.defaultCurrency()
            }
          />
          <PrimaryPublisherCard
            icon="mdi mdi-buffer"
            title="Total deposit"
            body={
              this.props.total_deposit
                ? this.currencyCalculation(this.props.total_deposit)
                : this.defaultCurrency()
            }
          />
          <PrimaryPublisherCard
            icon="mdi mdi-tag-text-outline"
            title="OA spent"
            body={
              this.props.oa_spent
                ? this.currencyCalculation(this.props.oa_spent)
                : this.defaultCurrency()
            }
          />
          <PrimaryPublisherCard
            icon="mdi mdi-cube-outline"
            title="Credit amount"
            body={
              this.props.credit_due_data
                ? this.currencyCalculation(this.props.credit_due_data)
                : this.defaultCurrency()
            }
          />
        </div>
        <div className="row mb-4">
          <div className="col-md-9">
            <div className="row">
            {this.props.article_summary && (
                <DBChart

                onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This doughnut chart represents all the articles are pending for approval, approved and
declined for APC."
                titleIcon="info-circle"
                  title="Articles"
                  type="doughnut"
                  width={220}
                  height={220}
                  containerHeight={320}
                  chartMarginTopClassName="m-t-40"
                  className="col-md-5"
                  // labels={['Pending', 'Approved', 'Rejected']}
                  labels={this.getLabels(this.props.article_summary)}
                  data={this.pieChartCalculation(this.props.article_summary)}
                />
              )}

              <ReadPublishList
                onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This table represents the total amount for Read &amp; Publish deal, the number of articles
approved and their average APC."
                titleIcon="info-circle"
                data={this.props.read_and_publish_deal && this.props.read_and_publish_deal.data}
                className="col-md-7 read_publish no_background color"
              />

            </div>
          </div>
          <div className="col-md-3">
            <div className="row">
              <WhitePublisherCard
                onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This represents that amount you have paid to publishers under credit OA deal."
                titleIcon="info-circle"
                padding="Credit_paid"
                icon="mdi mdi-buffer"
                title="Credit paid"
                body={
                  this.props.credit_paid_data
                    ? this.currencyCalculation(this.props.credit_paid_data)
                    : this.defaultCurrency()
                }
              />
            </div>

          </div>

        </div>
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="row">
            
                {this.props.book_summary && (
                    <DBChart
                      title="Books"
                        onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This doughnut chart represents all the books are pending for approval, approved and
declined for APC."
                titleIcon="info-circle"
                      type="doughnut"
                      width={220}
                      height={220}
                      containerHeight={320}
                      chartMarginTopClassName="m-t-40"
                      className="col-md-4"
                      labels={this.getLabels(this.props.book_summary)}
                      data={this.pieChartCalculation(this.props.book_summary)}
                    />
                  )}

                <DBChart
                  height={220}
                    onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This chart represents the top three affiliated organisations that books and articles have
been approved."
                titleIcon="info-circle"
                  containerHeight={320}
                  title="Institutions"
                  chartMarginTopClassName="m-t-40"
                  className="col-md-4"
                  type="doughnut"
                  labels={this.getInstitutionLabels(this.props.funder_institution ? this.props.funder_institution.data : {})}
                  data={this.chartCalculation(this.props.funder_institution ? this.props.funder_institution.data : {})}
                />

                <DBChart
                  onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This chart represents the top publishers that books and articles have been approved."
                titleIcon="info-circle"
                  chartMarginTopClassName="m-t-40"
                  height={220}
                  containerHeight={320}
                  title="Publishers"
                  className="col-md-4"
                  type="doughnut"
                  labels={this.getPublisherLabels(this.props.funder_publisher ? this.props.funder_publisher.data : {})}
                  data={this.chartCalculation(this.props.funder_publisher ? this.props.funder_publisher.data : {})}
                />
            </div>
          </div>
        </div>

        <div className="row mb-4">
            {this.props.open_access && (
                <DBChart
                  onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This represents total articles approved by journal type."
                titleIcon="info-circle"
                  title="Journals"
                  type="bar"
                  chartMarginTopClassName="m-t-40"
                  labels={this.getLabels(this.props.open_access)}
                  data={
                    this.props.open_access &&
                    Object.values(this.props.open_access.data)
                  }
                  height={220}
                  containerHeight={320}
                  className="col-md-4"
                  isShowLabel={false}
                  grid={false} // Should Implement
                  titleClass="mb-4"
                />
              )}

            {this.props.average_apc && (
                <Card
                  onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This represents average APC for all the articles that have approved against all the OA deals
(Pre-payment, Read &amp; Publish and Credit)."
                titleIcon="info-circle"
                  title="Average APC"
                  height={320}
                  icon="mdi mdi-buffer"
                  style={{backgroundColor: "#fff"}}
                  className="col-md-4 no_background"
                  color="color"
                >
                  <div className="row">
                    <div className="col mt-3">
                      <div className="d-flex align-items-center text-center mx-auto" style={apcAverageStyle}>
                        £ { parseFloat(this.props.average_apc.data.gbp).toFixed(2) }
                      </div>
                    </div>
                    <div className="col mt-3">
                      <div className="d-flex align-items-center text-center mx-auto" style={apcAverageStyle}>
                        $ { parseFloat(this.props.average_apc.data.usd).toFixed(2) }
                      </div>
                    </div>
                    <div className="col mt-3">
                      <div className="d-flex align-items-center text-center mx-auto" style={apcAverageStyle}>
                        € { parseFloat(this.props.average_apc.data.eur).toFixed(2) }
                      </div>
                    </div>
                  </div>


                </Card>
              )}

            {this.props.token_summary && (
                      <DBChart
                        onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This pie chart represents the total number of tokens given to the publisher(s), how many
tokens have been used and available tokens."
                titleIcon="info-circle"
                      chartMarginTopClassName="m-t-40"
                        className="col-md-4"
                        title="Tokens"
                        type="pie"
                        height={220}
                        labels={this.getLabels(this.props.token_summary)}
                        data={this.pieChartCalculation(this.props.token_summary)}
                      />
                    )}
              
        </div>

        <div className="row mb-4">
              <WhitePublisherCard
                onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This represents the total offset fund available given by publisher (s)."
                titleIcon="info-circle"
                className="col-md-4"
                icon="mdi mdi-briefcase-check"
                title="Offset"
                body={
                  this.props.offset_summary
                    ? this.currencyCalculation(this.props.offset_summary)
                    : this.defaultCurrency()
                }
              />
              <WhitePublisherCard
                onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This represents the total offset fund given to the publisher(s)."
                titleIcon="info-circle"
                title="Allocated offset"
                className="col-md-4"
                icon="mdi mdi-buffer"
                body={
                  this.props.allocated_offset_fund
                    ? this.currencyCalculation(this.props.allocated_offset_fund)
                    : this.defaultCurrency()
                }
              />
              <WhitePublisherCard
                onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This represents the total offset fund spent for approving articles(s)."
                titleIcon="info-circle"
                icon="mdi mdi-briefcase-check"
                title="Spent offset"
                className="col-md-4"
                body={
                  this.props.offset_summary
                    ? this.currencyCalculation(this.props.offset_summary)
                    : this.defaultCurrency()
                }
              />
              

      
        </div>
        <div className="row mb-4">
        <NewOAdeals
              onClick={(e)=>this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e)=>this.HandleOnClose(e)}
                  showtitle="This represents that amount paid by institutions under credit deal."
                titleIcon="info-circle"
              className="col-md-4 padding_reduce"
              data={this.props.new_deals}
              title="New deals"
              isStyle={true}
              university={true}
            />
        </div> </div>): this.state.success && this.state.dashBoardURL ? (
          <Iframe
            url={this.state.dashBoardURL}
            width="100%"
            height="800px"
            id="myId"
            frameBorder={0}
            className="myClassname"
            display="initial"
            position="relative"
            allow="fullscreen"
          />
        ) : (
              <Loading type="flat" />
            )}
      </HubContent>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth || false,
  data: state.dashboard || false,
  dashbaordData: state.dashboard || false,
  dashbaordPremiumData: state.dashboard.premium_dashboard || false,

  available_fund: state.dashboard.available_fund_component || false,
  total_deposit: state.dashboard.funder_total_deposit_component || false,
  oa_spent: state.dashboard.oa_spent_component || false,
  credit_due_data: (state.dashboard.credit_due_component || false),
  credit_paid_data: (state.dashboard.credit_paid_component || false),
  offset_summary: state.dashboard.offset_summary_component || false,
  article_summary: state.dashboard.article_summary_component || false,
  token_summary: state.dashboard.funder_token_summary_component || false,
  read_and_publish_deal: state.dashboard.read_and_publish_deal_component,
  average_apc: state.dashboard.average_apc_component || false,
  new_deals: state.dashboard.new_deals_component ? state.dashboard.new_deals_component.data : false,
  open_access: state.dashboard.oa_journal_component || false,
  funder_institution: state.dashboard.funder_institution_component || false,
  funder_publisher: state.dashboard.funder_publisher_component || false,
  book_summary: state.dashboard.book_summary_component || false,
  allocated_offset_fund: state.dashboard.allocated_offset_fund_component || false,
  institute_list: state.institutions.institution_list,
  child_orgs_list: state.app.organizations || false,
  oadeal_filter:state.institutions.oadeal_filter,
  publisher_list: state.orgs.publisher_list || false,
})

const mapDispatchToProps = dispatch => ({
  getPremiumDashboardData: (payload) =>
  dispatch(getPremiumDashboardData(payload)),
  getPublisherDefaultDashboardData: (payload) =>
  dispatch(getPublisherDefaultDashboardData(payload)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getoaDealFilterList:() => dispatch(getoaDealFilterList()),
  getChildOrganisationList: (payload) => dispatch(getChildOrganisationList(payload)),
})

export const FunderDashboard = connect(mapStateToProps, mapDispatchToProps)(DB)