import React from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { withRouter } from "react-router-dom";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { ArticleManualSingle } from "../../../partials";
import {
  getArticleManual,

} from "../../../../actions";

import {
  PopupBox,
  Loading,
  InputGroup,
} from "../../../../../core/components/common";
import { UnivRoot } from "../../../../data";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";
import queryString from "query-string";

class Article extends BaseComponent {

  state = {
    page: 1,
    pageSize: 10,
    msg: false,
    author_apc_request: false,
    author_apc_no_oa_request: false,
    filterParams: { page: 1 },
    params: null,
    license: null,
    funders: null,
    isCorrectionRequestOpen: false,
    article_id: null,
    showLicenceUpdateNotification: false,
  };
  componentDidMount() {
    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;
    let params = {
      institution_id: this.props.institute,
    };
    if (article_filter_id) {
      params["id"] = article_filter_id;
    }

    this.setState((prevState) => {
      if (this.props.institute) {
        prevState.filterParams = Object.assign(prevState.filterParams, params);
      } else {
        if (article_filter_id) {
          prevState.filterParams = Object.assign(prevState.filterParams, {
            id: article_filter_id,
          });
        }
      }
    });

    this.articleManualDataList()

  }

  articleManualDataList = async () => {
    let filter = serialize(this.state.filterParams);

     await this.props.ArticleManual({ filter })

  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  filterData = async (value) => {
    value.page = 1;

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
  });

    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.ArticleManual({ params, filter })
  };

  onActionHandler = async (action, id) => {
    try {
      const article = await this.props.article(id);
      switch (action && action.toLowerCase()) {
        case "reject":
          history.push(UnivRoot.article_decline + "/" + id);
          break;
        case "update":
          history.push(`./article-manual/edit/${id}`, {
            article,
          });
          break;
        case "article_payment":
          this.props.history.push(`/payment/addpayment/${id}`);
          break;
        default:
          break;
      }
    } catch (error) {
      this.log("error: ", error);
    }
  };

  pageChange = async (pageNumber) => {
    let value = {
      page: pageNumber,
    };

     await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    let params =  serialize(this.state.params);
    let filter =  serialize(this.state.filterParams);
    

     await this.props.ArticleManual({ params, filter })
    

  };




  render() {

    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;
    let { articles,  } = this.props;
    
    let articleDataList = Array.isArray(articles) ? articles : []
    
    let ErrorHandler = true;


    if (articles) {
      if (articles.status === 404) {
        ErrorHandler = (
          <PopupBox
            title="Data Not Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else if (articles.status) {
        ErrorHandler = (
          <PopupBox
            title="Unknown Error Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else {
        ErrorHandler = false;
      }
    }



    return (
      <HubContent
        title={this.props.title || ""}
        className={this.props.className}
     
      >
        {ErrorHandler}


        <div className="row mb-3">
   



          <InputGroup
            onClick={this.filterData}
            field="q"
            placeholder="Search"
            className="col-md-3 mb-2 free-text-search"
          />
        </div>

        <div className="row approved-btn">
          <div className="col-lg-12">
            <div id="accordion" className="article-cards">
              {/*START OF LOOP*/}
              { this.props.isRequestLoading !== true ? (
                <div>
                  {    articleDataList &&  articleDataList.map((article, i) => {
                  return (
                    <ArticleManualSingle
                      key={article.id}
                      search_text={this.state.q}
                      article={article}
                      url_article_id={article_filter_id}
                      user={this.props.auth.user.username}
                      isAction={false}
                      index={i}
                      onActionHandler={(action) =>
                        this.onActionHandler(action, article.id)
                      }
                    />
                  );
                  })}
                  {/*END OF LOOP*/}
              {articles && articles.length === 0 && (
                <div className="card">
                  <div className="card-body">
                    <h6>No Data Found</h6>
                  </div>
                </div>
              )}
{this.props.count  &&
               <Pagination
               style={{
                 marginTop: 10,
                 marginBottom: 20,
                 textAlign: "center",
                    }}
                current={this.state.filterParams.page}
               showSizeChanger={ false}
               onChange={this.pageChange}
               pageSize={this.state.pageSize || 10}
               total={this.props.count}
             /> }

              </div>
              ) : (
                <Loading type="flat" />
              )}
              
             
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isRequestLoading: state.articles.isRequestLoading,
  articles:state.articles.article_manual,
      
  count: state.articles.count || false,
  article: (id) => {
    let article = state.articles.article_manual
        
    if (article) {
      return article.find((article) => {
        return id.toString() === article.id.toString() && article;
      });
    } else {
      return false;
    }
  },

  group: (state.auth && state.auth.group),
  auth: state.auth,
});

const mapDispatchToProps = (dispatch, props) => ({
  ArticleManual: (payload = {}) => dispatch(getArticleManual(payload)),
 
});

export const ArticleManual = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Article)
);
