import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import { getHubCountryList,  } from "../../../../actions";
import NotFound from "../../NotFound";
import {  InputGroup} from "../../../../../core/components/common";
import { serialize } from "../../../../../core/lib";

class UL extends BaseComponent {
  state = {
    deleteConfirm: false,
    contentTypeId: null,
    waitingMsg: null,
    deleteStatus: null,
      data: [],
      filterParams:{}
  };
    


   componentDidMount() {
    this.getCountryDataList();
  }

  pageChange =async (pageNumber) => {
    let value = {
      page: pageNumber,
  };

  await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getCountryList({ params, filter });
  this.setState({
    data: this.props.countries &&this.props.countries.results ,
    count:  this.props.countries &&this.props.countries.count
  })
  };
  
    onEditcountry = (id, data) => {
      this.props.history.push(`/country/update-country/${id}`)
  };

 


  getCountryDataList = async (pageNum = 1) => {
    let value = {
      page: pageNum,
  };

  await this.setState((prevState) => {
    prevState.filterParams = Object.assign(prevState.filterParams, value);

  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getCountryList({ params, filter });

      this.setState({
        data: this.props.countries &&this.props.countries.results ,
        count:  this.props.countries &&this.props.countries.count
      })
    

  };
  onSearchfilterData = async (value) => {
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = await serialize(this.state.filterParams);
    let params = await serialize(this.state.params);
    await this.props.getCountryList({ params, filter });
    this.setState({
      data: this.props.countries &&this.props.countries.results ,
      count:  this.props.countries &&this.props.countries.count
    })
};

  render() {              
    const heading = ["name","short_name", "continent","region_one" ,"region_two" ,"region_three", "region_four",];

    return this.state.status === 403 ? (
      <NotFound />
    ) : (
      <HubContent
        title="Country list"
      >


          <div className="row">
          <div className="col-lg-12">
          <InputGroup
                        onClick={this.onSearchfilterData}
                        field="q"
                        placeholder="Search"
                        className="col-md-3 mb-2 free-text-search"
                    />
        </div>
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count && (
                <DataTable
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditcountry}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
    countries: state.journals.countrylist,

});

const mapDispatchToProps = dispatch => ({
  getCountryList: (id,pageNumber) => dispatch(getHubCountryList(id,pageNumber))
});


export const CountryList = connect(mapStateToProps, mapDispatchToProps)(UL);

