import React from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { withRouter } from "react-router-dom";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { ArticleFullSingle, CorrectionRequest } from "../../../partials";
import {
  getArticleFullList,
  ReviewArticle,
  articleDownload,
  oADealListApproved,
  getArticleManual,
  getLicense,
  getInstitutionsList,
  getFunders,
  CorrectionRequestAction,
  getArticlePublisher,
  getDepartByFiltering,
  getPublicationFiltering,
  getOaDisciplineList,
  getOaSubjectList,
  getArticleFullSingleList,
  getApcArticleList,
  getPublicationList,
} from "../../../../actions";
import moment from "moment";
import {
  PopupBox,
  Loading,
  SelectBox,
  InputGroup,
  Button
} from "../../../../../core/components/common";
import SelectBoxName from "../../../../../core/components/common/SelectBoxName.jsx";
import { UnivRoot } from "../../../../data";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";
import { filterStatus, filterDays, filterDeal } from "./data";
import queryString from "query-string";

class Article extends BaseComponent {
  state = {
    page: 1,
    pageSize: 10,
    msg: false,
    author_apc_request: false,
    author_apc_no_oa_request: false,
    filterParams: { page: 1, article_stage: 'POST_ACCEPTANCE', },
    params: null,
    license: null,
    funders: null,
    isCorrectionRequestOpen: false,
    article_id: null,
    showLicenceUpdateNotification: false,
    show:null,
  };
  articleHandleClick = (index) => {
    if (this.state.show === index) {
      this.setState({
        show: null,
      });
    } else {

      this.setState({
        show: index,
      });
    }
  };

  async componentDidMount() {
    if (this.props.location && this.props.location.filter && this.props.location.filter.page) {
      this.pageChange(this.props.location && this.props.location.filter && this.props.location.filter.page)
  }
    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;

    this.props.getOaDisciplineList();
    this.props.getPublicationFiltering();
    let params = {
      institution_id: this.props.institute,
    };
    if (article_filter_id) {
      params["id"] = article_filter_id;
    }


    await this.setState((prevState) => {
      if (this.props.institute) {
        prevState.filterParams = Object.assign(prevState.filterParams, params);
      } else {
        if (article_filter_id) {
          prevState.filterParams = Object.assign(prevState.filterParams, {
            id: article_filter_id,
          });
        }
      }
    });

    let filter = await serialize(this.state.filterParams);

    let license = "";
    let funders = "";

    if (this.props.location.apc_article_id) {
      this.props.getApcArticleList(this.props.location.apc_article_id)
      this.props.getArticleFullSingleList(this.props.location.apc_article_id)
    } else {

      this.props.ArticleType === "manual"
        ? await this.props.ArticleManual({ filter })
        : await this.props.ArticleFull({ filter });
    }




    if (!this.props.institute) {
      try {
        await this.props.getInstitutionsList();
      } catch (e) {
      }
    }
    if (this.props.group !== "AUTHOR") {
      try {
        license = await this.props.getLicense();
        await this.props.getArticlePublisher();
      } catch (e) {
      }
      this.props.getDepartByFiltering();

      this.getPublicationListOnParameterChange();
      await this.props.getOaSubjectList({});

      this.props.oa_subjects &&
        this.setState({
          oa_subject_list: this.props.oa_subjects.results,
        });
    } else {
      if (this.props.articles) {
        this.licenceUpdateNotification();
      }
    }

    try {
      funders = await this.props.getFunders();
    } catch (e) {
    }

    this.setState({
      license,
      funders,
    });
  }

  licenceUpdateNotification = () => {
    let articles = this.props.articles;

    if (articles) {
      for (let article of articles) {
        if (
          article.status === "approved" &&
          (!article.selected_licence || !article.selected_licence.id)
        ) {
          this.setState({
            showLicenceUpdateNotification: true,
          });
        }

        if (this.state.showLicenceUpdateNotification) {
          break;
        }
      }
    }
  };

  filterData = async (value) => {
    value.page = 1;

    if (value.hasOwnProperty("q")) {
      this.setState({
        ...value,
      });
    }

    if (value.department === "All Departments") {
      value.department = "all";
    } else if (value.department === "No Department") {
      value.department = "no_dept";
    }
    if (value.publication_type === "All Publications") {
      value.publication_type = "all";
    }

    if (value.hasOwnProperty("filter_discipline")) {
      let payload = {
        discipline:
          value.filter_discipline !== "all" ? value.filter_discipline : "",
      };

      value.filter_subject = "all";
      value.filter_publication = "all";

      await this.props.getOaSubjectList(payload);

      this.props.oa_subjects &&
        this.setState({
          oa_subject_list: this.props.oa_subjects.results,
        });
    }

    if (value.hasOwnProperty("filter_subject")) {
      value.filter_publication = "all";
    }

    await this.setState((prevState) => {
      if (value.institution_id === "all") {
        value.filter_publisher = "all";
      }
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    if (
      value.hasOwnProperty("publication_type") ||
      value.hasOwnProperty("filter_discipline") ||
      value.hasOwnProperty("filter_subject")
    ) {
      this.getPublicationListOnParameterChange();
    }

    if (this.state.filterParams.institution_id === "all") {
      delete this.state.filterParams.institution_id;
    }
    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    this.props.ArticleType === "manual"
      ? await this.props.ArticleManual({ params, filter })
      : await this.props.ArticleFull({ params, filter });
  };

  onActionHandler = async (action, id) => {
    try {
      const article = await this.props.article(id);
      switch (action.toLowerCase()) {
        case "approve":
          await this.props.getOaDeals();
          let OaDeal = false;
          try {
            if (article.publisher) {
              OaDeal = await this.props.OaDealCurrency(article.publisher.id);
            }
          } catch (error) {
            this.log("OaDeal error: ", error);
          }

          if (!OaDeal) {
            this.setState({
              msg: "You don't have any OaDeal. Please Create One",
            });
          } else {
            history.push(UnivRoot.article_approval + "/" + id, {
              article,
              OaDeal,
              id: id,
            });
          }
          break;
        case "reject":
          history.push(UnivRoot.article_decline + "/" + id);
          break;
        case "update":
          history.push(`/article/update-article/${id}`, {
            article,
          });
          break;
        case "article_payment":
          this.props.history.push(`/payment/addpayment/${id}`, {
            name: action
          });
          break;
        case "article_invoice":
          this.props.history.push(`/payment/addpayment/${id}`, {
            name: action
          });
          break;
        case "author_apc_fund_request":
          this.setState({
            author_apc_request: true,
            author_apc_id: id,
            author_id: article && article.author && article.author.id,
          });
          break;
          case "action_audit":

            this.props.history.push({
                pathname: `/audit/audit-trail/${id}`,
                filter: this.state.filterParams,
                name: action,
                id:id
            });
            break;
        case "author_non_oa_request":
          this.setState({
            author_apc_no_oa_request: true,
            author_apc_id: id,
            author_id: article && article.author && article.author.id,
          });
          break;
        case "correction_request":
          this.setState({
            isCorrectionRequestOpen: true,
            article_id: id,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      this.log("error: ", error);
    }
  };

  pageChange = async (pageNumber) => {
    let value = {
      page: pageNumber,
    };

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);

    this.props.ArticleType === "manual"
      ? await this.props.ArticleManual({ params, filter })
      : await this.props.ArticleFull({ params, filter });

    if (this.props.group === "AUTHOR" && this.props.articles) {
      this.licenceUpdateNotification();
    }

    this.setState({
      page: pageNumber,
    });
  };

  onTabChangeHandler = async (articleStage = 'PRE_ACCEPTANCE') => {
    if (articleStage !== 'PRE_ACCEPTANCE') {
      articleStage = 'POST_ACCEPTANCE';
    }

    let params = {
      article_stage: articleStage,
    };

    this.filterData(params);
  }

  onCorrectionRequest = async (value) => {
    let data = {
      article: this.state.article_id.toString(),
      ...value,
    };
    try {
      await this.props.CorrectionRequestAction(data);
      this.setState({
        isCorrectionRequestOpen: false,
        article_id: null,
      });

      let filter = await serialize(this.state.filterParams);

      this.props.ArticleType === "manual"
        ? this.props.ArticleManual({ filter })
        : this.props.ArticleFull({ filter });
    } catch (e) {
    }
  };

  onArticleDownloadClick = async () => {
    try {
      let filter = await serialize(this.state.filterParams);
      await this.props.articleDownload({ params: 999999, filter });
      const url = window.URL.createObjectURL(this.props.article_download_data);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };

    if (this.state.filterParams.publication_type) {
      filterParams.publication_type_name =
        this.state.filterParams.publication_type !== "all"
          ? this.state.filterParams.publication_type
          : "";
    }

    if (this.state.filterParams.filter_discipline) {
      filterParams.discipline_ids =
        this.state.filterParams.filter_discipline !== "all"
          ? this.state.filterParams.filter_discipline
          : "";
    }

    if (this.state.filterParams.filter_subject) {
      filterParams.subject_ids =
        this.state.filterParams.filter_subject !== "all"
          ? this.state.filterParams.filter_subject
          : "";
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publication_list: this.props.publication_list.results,
      });
  };

  popupSuccessCallback = async () => {
    this.onCancel();

    let filter = await serialize(this.state.filterParams);

    this.props.ArticleType === "manual"
      ? await this.props.ArticleManual({ filter })
      : await this.props.ArticleFull({ filter });
  };

  onCancel = () => {
    this.setState({
      author_apc_request: false,
      isCorrectionRequestOpen: false,
      author_apc_no_oa_request: false,
      article_id: null,
      showLicenceUpdateNotification: false,
    });
  };

  hasAccessToTabs = () => {
    let { group } = this.props;
    let groupList = ['HUB', 'PUBLISHER'];

    return groupList.includes(group);
  }
  onViewArticle = () => {
    this.props.ArticleFull()
  }
  // help menu function 
  HelpButtonClick = () => {
    const url = '/links';
    window.open(url, '_blank');

  }
  render() {
    let query_url = queryString.parse(this.props.location.search);
    let article_filter_id = query_url && query_url.id ? query_url.id : null;
    let { articles, type, auth, count } = this.props;
    let { page, } = this.state;
    let ErrorHandler = true;
    let AuthorApcRequest = true;
    let AuthorApcNoOARequest = true;

    let articleTabs = (auth && auth.article_tabs) || [];

    let licenses = [
      {
        name: "All Licences",
        id: "all",
      },
    ];
    let funders = [
      {
        name: "All Funders",
        id: "all",
      },
    ];

    let institutions = [
      {
        name:
          this.props.type === "publisher"
            ? "All Institutions"
            : this.props.type === "university"
              ? "All Publishers"
              : "All",
        id: "all",
      },
    ];
    let department = [
      {
        name: "All Departments",
        id: "all",
      },
      {
        name: "No Department",
        id: "no_dept",
      },
    ];

    let publications = [
      {
        name: "All Publications",
        id: "all",
      },
    ];
    licenses = this.state.license && licenses.concat(this.state.license);
    funders = this.state.funders && funders.concat(this.state.funders);

    if (articles) {
      if (articles.status === 500) {
        ErrorHandler = (
          <PopupBox
            title="Network Error"
            msg="Please check your internet connection. If you think internet connection is ok then feel free to contact us"
          />
        );
      } else if (articles.status === 404) {
        ErrorHandler = (
          <PopupBox
            title="Data Not Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else if (articles.status) {
        ErrorHandler = (
          <PopupBox
            title="Unknown Error Found"
            msg="Something Error. Please contact with admin"
          />
        );
      } else {
        ErrorHandler = false;
      }
    }



    if (this.state.author_apc_request) {
      AuthorApcRequest = (
        <PopupBox
          title="Author APC Request"
          className="col-sm-8"
          apcForm={true}
          successCallback={this.popupSuccessCallback}
          onCancel={this.onCancel}
          NoText="Cancel"
          article_id={this.state.author_apc_id}
        />
      );
    }

    if (this.state.author_apc_no_oa_request) {
      AuthorApcNoOARequest = (
        <PopupBox
          title="Non OA Request"
          className="col-sm-8"
          noOAForm={true}
          onCancel={this.onCancel}
          NoText="Cancel"
          article_id={this.state.author_apc_id}
        />
      );
    }

    let CorrectionRequestOpen = "";
    if (this.state.isCorrectionRequestOpen) {
      CorrectionRequestOpen = (
        <CorrectionRequest
          title="Correction Request"
          className="col-sm-7"
          onCancel={this.onCancel}
          onPressOK={this.onCorrectionRequest}
          YesText="Send Request"
          NoText="Cancel"
          id={this.state.article_id}
        />
      );
    }
    return (
      <HubContent
        title={this.props.title || ""}
        className={this.props.className}
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
        onHelpHandler={this.HelpButtonClick}
      >
        {/* {NotificationHandler} */}
        {ErrorHandler}
        {AuthorApcRequest}
        {CorrectionRequestOpen}
        {AuthorApcNoOARequest}

        {this.state.msg && (
          <PopupBox
            title="No Oa deal found"
            msg={this.state.msg}
            onCancel={() => this.setState({ msg: false })}
          />
        )}

        <div className="row mb-3">
          <SelectBox
            className="col-md-3 mb-2"
            field="filter_status"
            defaultValue="all"
            data={filterStatus}
            onChange={this.filterData}
          />
          {!this.props.institute && type !== "author" && institutions && (
            <SelectBox
              className="col-md-3 mb-2"
              data={
                this.props.institute_list
                  ? institutions.concat(this.props.institute_list)
                  : []
              }
              onChange={this.filterData}
              field="institution_id"
              defaultValue={institutions[0].name}
            />
          )}

          {type !== "author" && (
            <SelectBox
              className="col-md-3 mb-2"
              data={funders}
              onChange={this.filterData}
              field="filter_funder"
              defaultValue="all"
            />
          )}

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_days"
            defaultValue=""
            data={filterDays}
            onChange={this.filterData}
          />
          {this.props.group !== "AUTHOR" && (
            <SelectBoxName
              className="col-md-3 mb-2"
              field="department"
              defaultValue="All Departments"
              data={
                this.props.department_types
                  ? department.concat(this.props.department_types)
                  : []
              }
              onChange={this.filterData}
            />
          )}
          <SelectBoxName
            className="col-md-3 mb-2"
            field="publication_type"
            defaultValue="All Publication types"
            data={
              this.props.publication_types
                ? publications.concat(this.props.publication_types)
                : []
            }
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_discipline"
            defaultValue={this.state.filterParams.filter_discipline || "all"}
            data={
              this.props.oa_disciplines
                ? [
                  { id: "all", name: "All Disciplines" },
                  ...this.props.oa_disciplines.results,
                ]
                : [{ id: "all", name: "All disciplines" }]
            }
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_subject"
            defaultValue={this.state.filterParams.filter_subject || "all"}
            data={
              this.state.oa_subject_list
                ? [
                  { id: "all", name: "All Subjects" },
                  ...this.state.oa_subject_list,
                ]
                : [{ id: "all", name: "All Subjects" }]
            }
            onChange={this.filterData}
          />

          <SelectBox
            className="col-md-3 mb-2"
            field="filter_publication"
            defaultValue={this.state.filterParams.filter_publication || "all"}
            data={
              this.state.publication_list
                ? [
                  { id: "all", name: "All Publications" },
                  ...this.state.publication_list,
                ]
                : [{ id: "all", name: "All Publications" }]
            }
            onChange={this.filterData}
          />

          {type !== "author" && (
            <SelectBox
              className="col-md-3 mb-2"
              field="filter_deal"
              defaultValue="all"
              data={filterDeal}
              onChange={this.filterData}
            />
          )}
          <SelectBox
            className="col-md-3 mb-2"
            field="filter_licence"
            defaultValue="all"
            data={licenses}
            onChange={this.filterData}
          />

          <InputGroup
            onClick={this.filterData}
            field="q"
            placeholder="Search"
            className="col-md-3 mb-2 free-text-search"
          />
          {
            this.props.location.apc_article_id && (
              <div className="col-md-12 apc-btn-list" style={{ textAlign: "right" }}>
                <Button type="secondary" name="secondary" text="View All APC Requests" className="view-articles" size={200} onClick={() => this.props.history.push("/author-request/apc-request-list")} />

                <Button type="secondary" name="primary" text="View All Articles" className="view-articles" size={200} onClick={this.onViewArticle} />
              </div>
            )
          }
          {this.hasAccessToTabs() && <div className="col-md-12">
            <ul
              className="nav nav-pills article-stage-tabs"
              role="tablist"
            >
              <li
                title={!articleTabs.includes('PRE_ACCEPTANCE') ? `You don't have access to this tab. Please contact with administrator.` : ''}
                className={`nav-item waves-effect waves-light ${!articleTabs.includes('PRE_ACCEPTANCE') && 'disabled-tab'}`}
              >
                <a
                  className={`nav-link`}
                  data-toggle="tab"
                  href="#pre-acceptance"
                  role="tab"
                  onClick={() => articleTabs.includes('PRE_ACCEPTANCE') ? this.onTabChangeHandler() : null}
                  disabled={!articleTabs.includes('PRE_ACCEPTANCE')}
                >
                  Pre-acceptance
                </a>
              </li>
              <li className="nav-item waves-effect waves-light">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#pre-acceptance"
                  role="tab"
                  onClick={() => this.onTabChangeHandler('POST_ACCEPTANCE')}
                >
                  {`Accepted & Post-acceptance`}
                </a>
              </li>
            </ul>
          </div>}

        </div>

        <div className="tab-content">
          <div className="tab-pane active " id="pre-acceptance" role="tabpanel">
            <div className="row approved-btn">
              <div className="col-lg-12">
                <div id="accordion" className="article-cards">
                  {/*START OF LOOP*/}
                  {!ErrorHandler ? (
                    articles.map((article, i) => {
                      return (
                        <ArticleFullSingle
                          key={article.id}
                          search_text={this.state.q}
                          article={article}
                          url_article_id={article_filter_id}
                          user={this.props.auth.user.username}
                          isAction={false}
                          index={article.id}
                          show={this.state.show}
                          articleHandleClick={this.articleHandleClick}
                          onActionHandler={(action) =>
                            this.onActionHandler(action, article.id)
                          }
                        />
                      );
                    })
                  ) : (
                    <Loading type="flat" />
                  )}
                  {/*END OF LOOP*/}
                  {articles && articles.length === 0 && (
                    <div className="card">
                      <div className="card-body">
                        <h6>No Data Found</h6>
                      </div>
                    </div>
                  )}

                  {/* START OF PAGINATION */}
                    <Pagination
                      style={{
                        marginTop: 10,
                        marginBottom: 20,
                        textAlign: "center",
                    }}
                      showSizeChanger={ false}
                      onChange={this.pageChange}
                      pageSize={this.state.pageSize || 10}
                      total={count}
                      current={page}
                    />
               
                  {/* END OF PAGINATION */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: state.dashboard || false,
  available_fund: state.dashboard.available_fund_component,
  articles:
    props.ArticleType === "manual"
      ? state.articles.article_manual
      : state.articles.article_full,
  count: state.articles.count,
  article: (id) => {
    let article =
      props.ArticleType === "manual"
        ? state.articles.article_manual
        : state.articles.article_full;
    if (article) {
      return article.find((article) => {
        return id.toString() === article.id.toString() && article;
      });
    } else {
      return false;
    }
  },
  OaDealCurrency: (publisher) =>
    state.OaDeals.oadeal_approved_list
      ? state.OaDeals.oadeal_approved_list.find((OaDeal) => {
        if (publisher.toString() === OaDeal.organisation.toString()) {
          return OaDeal;
        } else {
          return false;
        }
      })
      : null,
  institute_list:( state.institutions && state.institutions.institution_list) || [],
  publishers_list: state.orgs && (state.orgs.publisher_list || false),
  publication_types: state.institutions.publication_types,
  department_types: state.institutions.department_types,
  article_download_data: state.articles.download_data,
  group: (state.auth && state.auth.group) || false,
  auth: state.auth,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  oa_subjects: state.OaSubject.oa_subjects || false,
  publication_list: state.institutions.publication_list,
});

const mapDispatchToProps = (dispatch, props) => ({
  ArticleFull: (payload = {}) => dispatch(getArticleFullList(payload)),
  articleDownload: (payload = {}) => dispatch(articleDownload(payload)),
  ArticleManual: (payload = {}) => dispatch(getArticleManual(payload)),
  getLicense: () => dispatch(getLicense()),
  ReviewArticle: () => dispatch(ReviewArticle()),
  getOaDeals: () => dispatch(oADealListApproved()),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
  getDepartByFiltering: () => dispatch(getDepartByFiltering()),
  getPublicationFiltering: () => dispatch(getPublicationFiltering()),
  getFunders: () => dispatch(getFunders()),
  getArticlePublisher: () => dispatch(getArticlePublisher()),
  CorrectionRequestAction: (payload) =>
    dispatch(CorrectionRequestAction(payload)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getArticleFullSingleList: (id) => dispatch(getArticleFullSingleList(id)),
  getApcArticleList: (id) => dispatch(getApcArticleList(id))
});

export const ArticleFull = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Article)
);
