import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import {
  InputBox,
  Button,
  PopupBox,
} from "../../../../../core/components/common";
import BaseComponent, { HubContent } from "../../../../../core/components";

import {
  createSubmission,
  updateSubmission,
  getSubmissionList,
  getOaSubStatus,
  deleteSubmission,
  getXEROAuthUrl,
  submitXEROAuthCode
} from "../../../../actions";

class AppPage extends BaseComponent {
  state = {
    isOpen: false,
    disabled: true,
  };
  async componentDidMount() {
    this.props.getOaSubStatus();
    this.props.getXEROAuthUrl();
    let queryParm = this.checkUrl(this.props.location.search);
    if (queryParm.code) {
      // let xero_response = await this.props.submitXEROAuthCode({ "auth_code": queryParm.code });
    }
  }

  checkUrl = (query) => {
    try {
      const q =
        query &&
        JSON.parse(
          '{"' +
          decodeURI(query.substring(1))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
        );
      return q;
    } catch (e) {
      return false;
    }
  };

  handleOpenModel = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  onChangeHandler = (value) => {
    this.setState(
      {
        ...value,
      },
      () =>
        this.state.username && this.state.api_key
          ? this.setState({
            disabled: false,
          })
          : this.setState({
            disabled: true,
          })
    );
  };

  onDeleteHandler = async (id) => {
    confirmAlert({
      title: "",
      message: "Do you want to disconnect?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let data = await this.props.deleteSubmission(id);
            if (data) {
              await this.props.getOaSubStatus();
            }
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  onSubmit = async () => {
    const submissionData = {
      name: "EM",
      organisation:
        this.props.user &&
        this.props.user.user &&
        this.props.user.user.organisation_id,
      credentials: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
        api_key: this.state.api_key,
      }),
    };
    let data = await this.props.createSubmission(submissionData);
    await this.props.getOaSubStatus();
    if (data && data.status === true) {
      this.setState({
        isOpen: false,
        status: data.status,
      });
    }
  };

  onSubmitXero = async () => {
    window.location.href = this.props.xero_auth_url.auth_url;
    // let queryParm = this.checkUrl(this.props.location.search);
  }

  render() {
    return (
      <HubContent className="setting-heading">
        <div className="row">
          <div className="col-lg-12">
            {this.state.status && (
              <PopupBox
                className="col-md-6 meta-data"
                NoText="Metadata Transfer"
                msgone="Please click now on the below button for article metadata transfer."
                successMesage="You have successfully provided your submission system Credentials."
                msgtwo="Once you click on the button, you will be redirected to article
                 metadata transfer page to activate it."
                onCancel={() =>
                  this.props.history.push(
                    "/settings/publisher-article-transfer-config"
                  )
                }
              />
            )}
            <div className="card m-b-20 settings-card app">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="x"
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div className="card-body">
                {this.state.isOpen && (
                  <div className="addmultfield" style={modal.overlay}>
                    <div
                      className={`col-sm-6 bg-white alert`}
                      style={modal.boxStyle}
                    >
                      <div className="form-group row sumbmission-row">
                        <div
                          className="col-md-12 text-center submission-title"
                          style={{ marginBottom: 15 }}
                        >
                          <label className="submission-system">
                            {" "}
                            Connect to Editorial Manager
                          </label>{" "}
                        </div>
                        <div className="col-md-12" style={{ marginBottom: 25 }}>
                          <div className="row">
                            <div
                              className="col-md-12 text-center"
                              style={{ marginBottom: 10 }}
                            >
                              <label className="submission-system">
                                Please enter your Editorial Manager username and
                                either password or API key.
                              </label>
                            </div>
                            <div className="col-md-3">
                              <label className="submission-system">
                                {" "}
                                Username
                              </label>
                            </div>
                            <div className="col-md-9">
                              <InputBox
                                onChange={this.onChangeHandler}
                                field="username"
                                className="col-md-12"
                                placeholder="Username"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12" style={{ marginBottom: 25 }}>
                          <div className="row">
                            <div className="col-md-3">
                              <label className="submission-system">
                                API Key
                              </label>
                            </div>
                            <div className="col-md-9">
                              <InputBox
                                onChange={this.onChangeHandler}
                                field="api_key"
                                className="col-md-12"
                                placeholder="API KEY"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12" style={{ marginBottom: 25 }}>
                          <div className="row">
                            <div className="col-md-3">
                              <label className="submission-system">
                                Password
                              </label>
                            </div>
                            <div className="col-md-9">
                              <InputBox
                                onChange={this.onChangeHandler}
                                field="password"
                                className="col-md-12"
                                placeholder="Password[Optional Field]"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-center sub-btn">
                          <button
                            className="btn btn-primary btn-md waves-effect "
                            onClick={this.onSubmit}
                            disabled={this.state.disabled}
                          >
                            Connect now
                          </button>
                          <button
                            className="btn btn-secondary btn-md waves-effect "
                            onClick={this.handleOpenModel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-12">
                    <h3 className="profile-heading">Payment Gateway </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 ">
                    <div className="single-setting">
                      <div className="setting-icon">
                        <img
                          src="https://img.icons8.com/color/48/000000/stripe.png"
                          alt=""
                          className="app-icon"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>Stripe</h4>

                        <p>Accept payments with Stripe. </p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          {" "}
                          <a
                            href=" https://dashboard.stripe.com/login"
                            target="_blank"
                          >
                            Connect
                          </a>
                          {/* <a href="https://dashboard.stripe.com/login" target="_blank">Connect</a> */}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        <img
                          src="https://img.icons8.com/color/48/000000/paypal.png"
                          alt=""
                          className="app-icon"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>Paypal</h4>

                        <p>Accept payments with Paypal.</p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          <a
                            href=" https://www.paypal.com/us/signin"
                            target="_blank"
                          >
                            Connect
                          </a>
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        {" "}
                        <img
                          src="/assets/images/app/saga-pay.png"
                          alt=""
                          className="app-icon edit1"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>SagePay</h4>

                        <p> Accept payment with SagePay. </p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          <a
                            href=" https://live.sagepay.com/mysagepay/login.msp"
                            target="_blank"
                          >
                            Connect
                          </a>
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="profile-heading  m-30">
                      Peer Review and Production System{" "}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        {" "}
                        <img
                          src="https://blog.growkudos.com/hs-fs/hubfs/Imported_Blog_Media/em_dot_only_camtasia-2-1.png?width=246&name=em_dot_only_camtasia-2-1.png"
                          alt=""
                          className="app-icon"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>Editorial Manager</h4>

                        <p>Connect to Editorial Manager.</p>

                        {this.props.sub_status &&
                          this.props.sub_status.EM.connected === true ? (
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light green"
                          >
                            Connected
                          </button>
                        ) : (
                          <button
                            onClick={this.handleOpenModel}
                            type="button"
                            className="btn btn-primary waves-effect waves-light "
                          >
                            Connect
                          </button>
                        )}
                        <button
                          disabled={
                            this.props.sub_status &&
                              this.props.sub_status.EM.connected === false
                              ? true
                              : false
                          }
                          type="button"
                          onClick={() =>
                            this.onDeleteHandler(
                              this.props.sub_status &&
                              this.props.sub_status.EM.object_id
                            )
                          }
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        {" "}
                        <img
                          src="https://img.icons8.com/color/48/000000/coming-soon.png"
                          alt=""
                          className="app-icon"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>OaSub System</h4>

                        <p>Connect to your OaSub system.</p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Connect
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        {" "}
                        <img
                          src="https://img.icons8.com/color/48/000000/coming-soon.png"
                          alt=""
                          className="app-icon"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>Submission System</h4>

                        <p>Connect to your submission system.</p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Connect
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="profile-heading  m-30">
                      {" "}
                      CRM and Accounting System{" "}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        {" "}
                        <img
                          src="https://c1.sfdcstatic.com/content/dam/web/en_us/www/images/home/logo-salesforce-m.svg"
                          alt=""
                          className="app-icon"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>Salesforce</h4>

                        <p>Connect to Salesforce.</p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          <a
                            href=" https://login.salesforce.com/"
                            target="_blank"
                          >
                            {" "}
                            Connect
                          </a>
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        {" "}
                        <img
                          src="https://www.sageone.co.za/wp-content/themes/arcturus/images/logo-sage.svg"
                          alt=""
                          className="app-icon edi3"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>SageOne</h4>

                        <p> Connect to SageOne.</p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          <a
                            href=" https://eu-signon1.sso.services.sage.com/signon/9723a067-903a-470a-a72f-2475bc06ba91?f=yxlvy2LooH7IQrJObGuERw7iqKEAPYI9dhRj%2bkj0l6YkIqQTKtYA4JcOdfI1KLqlY5y8dRp6A7tT%2f98UaOScJnR4buXvzrxUYlJHgHaRr5wIgVnXhEgIWaTwxAwdNmT%2f%2fHhPX8qe2%2fP0s8GEp4i%2fXZhsN2K50aTPNGmiOcF9eWw%3d"
                            target="_blank"
                          >
                            {" "}
                            Connect
                          </a>{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-setting">
                      <div className="setting-icon">
                        {" "}
                        <img
                          src="https://www.xero.com/etc/designs/xero-cms/clientlib/assets/img/logo/logo-xero-blue.svg"
                          alt=""
                          className="app-icon"
                        />
                      </div>
                      <div className="setting-info">
                        <h4>XERO</h4>
                        <p>Connect to XERO.</p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitXero}
                        >
                          Connect
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth || {},
  createSubmission: state.submissionCreds.createSubmission,
  update_submission: state.submissionCreds.update_submission,
  submission_creds_info: state.submissionCreds.submission_creds_info,
  sub_status: state.submissionCreds.sub_status,
  xero_auth_url: state.xero.xero_auth_url,
  xero_auth_code_submission: state.xero.submit_auth_code,
});

const mapDispatchToProps = (dispatch) => ({
  createSubmission: (payload) => dispatch(createSubmission(payload)),
  updateSubmission: (payload, id) => dispatch(updateSubmission(payload, id)),
  getSubmissionList: () => dispatch(getSubmissionList()),
  getOaSubStatus: () => dispatch(getOaSubStatus()),
  deleteSubmission: (id) => dispatch(deleteSubmission(id)),
  getXEROAuthUrl: () => dispatch(getXEROAuthUrl()),
  submitXEROAuthCode: (payload) => dispatch(submitXEROAuthCode(payload))
});

export const Apps = connect(mapStateToProps, mapDispatchToProps)(AppPage);

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(100px, 100px)",
    height: boxHeight,
  },
};
