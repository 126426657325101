import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Popover } from "antd";

import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import {
  Button, PopupBox,InputGroup
} from "../../../../../core/components/common";
import { getSocietyMemberList, deleteSocietyMember } from "../../../../actions";
import { serialize } from "../../../../../core/lib";
import moment from "moment";

const format = "Do MMM YYYY";
const content = (
  <div className="">
    <a href="https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/8+-+Society+Member+and+Society+Discount+Setup.pdf" target="_blank" rel="noopener noreferrer">Society Member and Society Discount Setup</a>
    <br />

  </div>
);




class SocietyMember extends BaseComponent {
  state = {
    data: null,
    filterParams: { page: 1 },
    page: 1,
    params: null,
  };



  filterData = async (value) => {
    value.page = 1;

    if (value.hasOwnProperty("q")) {
      this.setState({
        ...value,
      });
    }

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
  
    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.getSocietyMemberList({ params, filter });

  };


  componentDidMount() {
    this.getSocietyMemberListData();
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}


  getSocietyMemberListData = async (pageNum = 1) => {
    let value = {
      page: pageNum,
  };

  await this.setState((prevState) => {
    prevState.filterParams = Object.assign(prevState.filterParams, value);

  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getSocietyMemberList({ params, filter });

  };

  pageChange = async (pageNumber) => {
    let value = {
      page: pageNumber,
    };

    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });

    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
     await this.props.getSocietyMemberList({ params, filter });


    this.setState({
      page: pageNumber,
    });
  };

  onEditSocietyMember = (id, data) => {
    this.props.history.push("/society-members/edit-society-member/" + id, {
      society_member: data,
    });
  };

  onViewSocietyMember = (id, data) => {
    this.props.history.push("/society-members/view-society-member/" + id);
  };

  onDeleteHandler = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        id,
      });
    }
  };
  onDeleteSocietyMember = async () => {
    const id = this.state.id;
    this.setState({
      waitingMsg: "Deleting...",
    });
    const deleteArticle = await this.props.deleteSocietyMember(id);
    this.setState({
      waitingMsg: null,
      id: null,
      deleteConfirm: false,
      deleteStatus: deleteArticle.status,
    });
    this.getSocietyMemberListData();
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      id: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

  render() {
    let heading = [];
    if (!this.props.kind) {
      heading = [
        "first_name",
        "last_name",
        "email",
        "membership_id",
        "Membership expiry",
        "fellow_affiliation",


      ];
    } else {
      heading = [

        "first_name",
        "last_name",
        "email",
        "membership_id",
        "Membership expiry",
                "fellow_affiliation",

      ];
    }
    let dataList = this.props.society_members && this.props.society_members.results
    const data = [];
    dataList&&
       dataList.map((item) => {
        return data.push({
          ...item,
          "Membership expiry": item.membership_expiry
            ? moment(item.membership_expiry).format(format)
            : "-",
        });
      });
    return (
      <HubContent title="Society members list" >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            btnSize="200px"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteSocietyMember}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        <div className="vat-tax-button email d-flex justify-content-end">
          <div className="col-md-4">
          <InputGroup
            onClick={this.filterData}
            field="q"
            placeholder="Searching here..."
            className="col-md-12"
            />
       
          </div>{" "}
          {this.props.kind === "SOCIETY" || this.props.group === "HUB" ? <div className="row">
            <Link
              style={{ marginRight: 25 }}
              to="/society-members/add-society-member"
              className="btn btn-primary float-right mb-2"
            >
              Add SocietyMember
            </Link>
          </div> : null}
          <Popover placement="leftTop" content={content} trigger="click">
            <Button
              className="btn btn-secondary float-right mb-2"
              type=""
              name=""
              text="Help"
            >Help</Button>
          </Popover>


        </div>

        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {
              
                < DataTable
                  heading={heading}
                  data={data}
                  onEdit={this.props.kind && this.onEditSocietyMember}
                  onDelete={this.props.kind && this.onDeleteHandler}
                  onView={this.onViewSocietyMember}
                  count={this.props.society_members && this.props.society_members.count}
                  pageChange={this.pageChange}
          
                  pageSize={10}
                />
              }
     
                 
            </div>
          </div>
        </div>
        
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  society_members: state.SocietyMember.society_members || [],
  kind: state.auth.user && state.auth.user.kind,
  group: state.auth && state.auth.group,
  isLoading: state.SocietyMember.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  getSocietyMemberList: (payload) => dispatch(getSocietyMemberList(payload)),
  deleteSocietyMember: (id) => dispatch(deleteSocietyMember(id)),
});

export const SocietyMemberList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocietyMember);
