import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import { Checkbox } from "antd";
import {
  getOrgList,
  getOaDisciplineList,
  getOaSubjectList,
  getContentTypeList,
  getJournalTypeList,
  getBookSeriesType,
  UpdateJournal,
  singleJournal,
  getPublicatonTypeList,
} from "../../../../../actions";
import { Prices } from "../../../../partials";
import {
  SelectBox,
  InputNewBox,
  InputBox,
  Button,
  Badge,
  Loading,
  // PopupBox,
} from "../../../../../../core/components/common";

import { api } from "../../../../../../core/api";

class Form extends BaseComponent {
  state = {
    journal: {},
    prices: [],
    selected_content_type: [],
    content_type: [],
    success: false,
    publication_contacts: [
      {
        contact_department: undefined,
        contact_name: undefined,
        contact_email: undefined,
        is_email_signature: false,
        receive_payment_notification: false,
      }
    ],
    email_signature_index: null,
  };

  contactTypes = [
    {
      id: '',
      name: 'Select department',
    },
    {
      id: 'customer_service',
      name: 'Customer service',
    },
    {
      id: 'editorial',
      name: 'Editorial',
    },
    {
      id: 'finance',
      name: 'Finance',
    },
    {
      id: 'production',
      name: 'Production',
    },
    {
      id: 'sales',
      name: 'Sales',
    }
  ]


  resetForm = () => {
    this.setState(this.baseState);
  };

  getSingle = (id) => {
    this.resetForm();
    api
      .get("publication/" + id + "/")
      .then(
        async (res) => {

          let publicationContacts = res.publication_contacts || []
          let email_signature_index = publicationContacts.findIndex(item => item.is_email_signature === true)
          
          let payload = {
            discipline: res.disciplines.join(),
          };
          await this.props.getOaSubjectList(payload);

          this.setState({
            selectedSubjects:res && res.subjects,
            ...res,
            email_signature_index: email_signature_index >= 0 ? email_signature_index : null,
            oa_subject_list: this.props.oa_subjects && this.props.oa_subjects.results
          });
        },
        (error) => {}
      )
      .catch();
  };

  async componentDidMount() {
    this.props.getOaDisciplineList();
    this.props.getBookSeriesType();
    this.props.getOrgList();
    this.props.getPublicatonTypeList();
    this.props.getJournalTypeList();
    this.props.getContentTypeList();
    this.setState({
      content_type: this.props.content_type,
    });
    const id = this.props.match.params.id;

    this.setState({
      loading: true,
    });
    await this.props.singleJournal(id);

    this.setState({
      loading: false,
      journal: this.props.book_series,
    });
    if (id) {
      this.getSingle(id);
    }
  }

  formData() {
    let data = {
      name: this.state.name,
      publication_type: this.state.publication_type,
      publisher: this.state.publisher,
      journal_type: this.state.journal_type,
      pissn: this.state.pissn,
      eissn: this.state.eissn,
      impact_factor: this.state.impact_factor,
      hindex: this.state.hindex,
      indexed: this.state.indexed,
      sherpa_romeo_info: this.state.sherpa_romeo_info,
      note: this.state.note,
      status: this.state.status,
      pub_acronym: this.state.pub_acronym,
      sub_sys_acronym: this.state.sub_sys_acronym,
      owner: this.state.owner,
      editor_manager: this.state.editor_manager,
      editor_support: this.state.editor_support,
      editor_co_support: this.state.editor_co_support,
      disciplines: this.state.journal.disciplines,
      subjects: this.state.journal.subjects,
      publication_contacts: this.state.publication_contacts,

    };

    let Journal = Object.assign(data, { prices: this.state.prices });
    return Journal;
  }

  onSubmitHandler = async (e) => {
    e.preventDefault();
    let Journal = this.formData();
    let data = {
      ...Journal,
      visible_to_publisher: true,
      publication_contacts: this.state.publication_contacts,

      publication_type:
        this.props.book_series_type && this.props.book_series_type[0].id,
    };
    await this.props.updateBookSeries(data, this.props.match.params.id);
    this.props.history.push(`/article/add-publications`, {
      index: this.props.location.state.index || 0,
    });

    // if (result.status === 201) {
    //   this.setState({
    //     success: true,
    //   });
    // }
  };
  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push(`/article/add-publications`, {
      index: this.props.location.state.index || 0,
    });
  };

  AddNewPrices = (e) => {
    e.preventDefault();
    this.setState({
      isPrices: true,
    });
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.journal, value);
    });
  };

  onClosePrices = () => {
    this.setState({
      isPrices: false,
      editing: false,
    });
  };

  onSavePriceHandler = (price_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.prices[prevState.editing] = price_info;
      } else {
        prevState.prices.push(price_info);
      }
      prevState.editing = false;
      prevState.isPrices = false;
      return prevState;
    });
  };

  onEditPrices = (id) => {
    this.setState({
      editing: id.toString(),
      isPrices: true,
    });
  };

  onDeletePrice = (id) => {
    this.setState((prevState) => {
      prevState.prices.splice(id, 1);
      return prevState;
    });
  };

  getSelectedContentTypeId() {
    let selectedPrices = this.state.prices;
    let selected = [];
    selectedPrices.forEach(function (element) {
      selected.push(element.content_type);
    });
    return selected;
  }

  onMultiSelectChangeHandler = (values) => {
    this.setState(
      {
        values,
        journal: Object.assign(this.state.journal, values),
      },
      async () => {
        if (values.hasOwnProperty("disciplines")) {
          if (values.disciplines.length > 0) {
            let payload = {
              discipline: values.disciplines.join(),
            };
            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }
        }
      }
    );
  };
  addNewPubContact = () => {
    let publicationContacts = this.state.publication_contacts

    publicationContacts.push({
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
      is_email_signature: false,
      receive_payment_notification: false,
    })

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  removePubContact = (index) => { 
    let publicationContacts = this.state.publication_contacts

    publicationContacts.splice(index, 1)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  renderPublicationContact = (publicationContacts) => { 
    let initialObj = {
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
      is_email_signature: false,
      receive_payment_notification: false,
    }

    if (!publicationContacts || !Array.isArray(publicationContacts)) {
      publicationContacts = [
        initialObj
      ]
    } else if (publicationContacts.length === 0) {
      publicationContacts.push(
        initialObj
      )
    }
    
    return publicationContacts.map((contact, index) => {
      return (
        <div key={index}>
         <div className="form-group row" style={{float:"right",marginLeft:10}}>
          <div 
            className=""
          >
              { index > 0 && <button 
                  type="button"
                  className="btn btn-danger mb-2"
                  onClick={() => this.removePubContact(index)}
                >
                  <i className="mdi mdi-close"></i>
                </button> }
          </div>
          </div>
         <div className="form-group row">

          <SelectBox
            labelInfo={false}
            labelInfoTitle="Select department"
            placeholder="Select department"
            label="Department"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_department"
            className="col-sm-4"
            labelClass="col-sm-2"
            data={this.contactTypes || []}
            defaultValue={contact.contact_department}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter contact name"
            placeholder="Enter contact name"
            label="Name"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_name"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_name}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter contact email"
            placeholder="Enter contact email"
            label="Email"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_email"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_email}
          />

          <div className="col-sm-6">
            <div className="row" style={{marginTop:15}}>
              <div
                className="col-form-label journal-contact-form col-sm-4"
                >

                <label

              >
                <Checkbox
                  onChange={(evt) => this.onPubContactCheckedChangeHandler(evt, index)}
                  checked={!!contact.receive_payment_notification}
                  name="receive_payment_notification"
                >
              
                </Checkbox>
              </label>
            </div>
            <div 
              className="col-sm-8"
            >
            
            <p> Receive payment notification email</p>
              
            </div>
            <div
              className="col-form-label journal-contact-form col-sm-4"
            >

              <label>
                <Checkbox
                  onChange={(evt) => this.onPubContactCheckedChangeHandler(evt, index)}
                  checked={!!contact.receive_invoice_notification}
                  name="receive_invoice_notification"
                >
              
                </Checkbox>
              </label>
            </div>
            <div 
              className="col-sm-8"
            >
            
            <p> Receive invoice notification email</p>
              
            </div>
            <div
                className="col-form-label journal-contact-form col-sm-4"
                >

              <label

              >
                <Checkbox
                  onChange={(evt) => this.onPubContactSignatureCheckedChangeHandler(evt, index)}
                  checked={!!contact.is_email_signature}
                  name="is_email_signature"
                >
              
                </Checkbox>
                </label>
              </div>
              <div 
                className="col-sm-8"
              >
              
              <p>Include my name in the signature of author notification emails</p>
                
              </div>
            </div>
            </div>

       
          </div>

        </div>
      )
    })
  }

  onPubContactChangeHandler = (value, index) => { 
    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  onPubContactCheckedChangeHandler = (evt, index) => {
    let value = {}

    let checked = evt.target.checked
    let name = evt.target.name 

    value[name] = checked

    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  onPubContactSignatureCheckedChangeHandler = (evt, index) => {
    let value = {}

    let checked = evt.target.checked
    let name = evt.target.name 

    value[name] = checked

    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    let email_signature_index = this.state.email_signature_index

    if (email_signature_index !== null && email_signature_index < publicationContacts.length) {
      let uncheckedValue = {}
      uncheckedValue[name] = false 
      Object.assign(publicationContacts[email_signature_index], uncheckedValue)
    }

    this.setState({
      publication_contacts: publicationContacts,
      email_signature_index: checked ? index : null,
    })
  }
  
  render() {
    let prices_id = this.getSelectedContentTypeId();


    return (
      <HubContent>
        {this.state.loading && <Loading />}

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card m-b-20  ">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={this.redirectLink}
                />
              </div>
              <div className="card-body">
                {/* {successMessage} */}
                <form>
                  <div className="form-group row">
                    <InputNewBox
                      onChange={this.onChangeHandler}
                      field="name"
                      labelClass="col-sm-2"
                      label="Title"
                      className="col-sm-10"
                      placeholder="Book series, Monograph, Reference"
                      defaultValue={this.state.name}
                    />
                  </div>

                  <div className="form-group row">
                    <SelectBox
                      label="Book type"
                      labelClass="col-sm-2"
                      onChange={this.onChangeHandler}
                      field="journal_type"
                      className="col-sm-4"
                      data={this.props.journal_type}
                      defaultValue={this.state.journal_type}
                      isRequired={true}
                    />
                    <SelectBox
                      label="Discipline"
                      onChange={this.onMultiSelectChangeHandler}
                      field="disciplines"
                      className="col-sm-4"
                      labelClass="col-sm-2"
                      data={this.props.oa_disciplines && this.props.oa_disciplines.results}
                      defaultValue={this.state.disciplines}
                      multiple={true}
                    />
                  </div>
                  <div className="form-group row">
                    <SelectBox
                      label="Subject"
                      onChange={this.onMultiSelectChangeHandler}
                      field="subjects"
                      className="col-sm-10"
                      data={this.state.oa_subject_list || []}
                      defaultValue={this.state.subjects}
                      multiple={true}
                    />
                  </div>

                  <div className="form-group row">
                    <SelectBox
                      label="Publisher"
                      onChange={this.onChangeHandler}
                      field="publisher"
                      className="col-sm-4"
                      data={this.props.orgs}
                      defaultValue={this.state.publisher}
                      isRequired={true}
                    />

                    <SelectBox
                      label="Owner"
                      onChange={this.onChangeHandler}
                      field="owner"
                      className="col-sm-4"
                      data={this.props.orgs}
                      defaultValue={this.state.owner}
                    />
                  </div>

                  <div className="form-group row">
                    <InputNewBox
                      label="Acronym"
                      onChange={this.onChangeHandler}
                      field="pub_acronym"
                      className="col-sm-4"
                      placeholder="Acronym"
                      defaultValue={this.state.pub_acronym}
                    />
                    <InputNewBox
                      label="Site"
                      onChange={this.onChangeHandler}
                      field="sub_sys_acronym"
                      className="col-sm-4"
                      placeholder="Site"
                      defaultValue={this.state.sub_sys_acronym}
                    />
                  </div>
                  <div className="form-group row">
                    <InputNewBox
                      label="pISSN/pISBN"
                      onChange={this.onChangeHandler}
                      field="pissn"
                      className="col-sm-4"
                      placeholder="p-ISSN/p-ISBN"
                      defaultValue={this.state.pissn}
                    />
                    <InputNewBox
                      label="eISSN/eISBN"
                      onChange={this.onChangeHandler}
                      field="eissn"
                      className="col-sm-4"
                      placeholder="e-ISSN/e-ISBN"
                      defaultValue={this.state.eissn}
                    />
                  </div>

                  <div className="form-group row mt-4">
                    <div className="col-sm-12">
                      <div className="float-left">
                        <button
                          className="btn btn-primary mb-2"
                          onClick={this.AddNewPrices}
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="mdi mdi-plus"></i> Add price
                        </button>
                      </div>
                    </div>
                    <s
                      style={{
                        borderBottom: "1px solid #375ba8",
                        width: "100%",
                      }}
                    ></s>
                  </div>

                  {this.state.isPrices && (
                    <Prices
                      isShow={this.state.isPrices} // if true modal will show up else it will be hidden
                      onClose={this.onClosePrices} // Handle Close
                      onSavePrices={this.onSavePriceHandler}
                      value={this.state.prices[this.state.editing]}
                      selected_array={prices_id}
                    />
                  )}

                  <div className="form-group row">
                    {this.state.prices.length > 0 &&
                      this.state.prices.map((price, id) => {
                        return (
                          <div key={id}>
                              <Badge
                            label={
                              (price.additional_info &&
                                price.additional_info.name) ||
                              price.content_type_name
                            }
                            onEdit={() => this.onEditPrices(id)}
                            onDelete={() => this.onDeletePrice(id)}
                          />
                        </div>
                        );
                      })}
                  </div>
                  <div className="form-group row">
                    <InputNewBox
                      label="Editor-Manager"
                      onChange={this.onChangeHandler}
                      field="editor_manager"
                      className="col-sm-4"
                      placeholder="Editor-Manager"
                      defaultValue={this.state.editor_manager}
                    />

                    <InputNewBox
                      label="Editor-Support"
                      onChange={this.onChangeHandler}
                      field="editor_support"
                      className="col-sm-4"
                      placeholder="Editor support"
                      defaultValue={this.state.editor_support}
                    />
                  </div>
                  <div className="form-group row">
                    <InputNewBox
                      label="Editor Co-support"
                      onChange={this.onChangeHandler}
                      field="editor_co_support"
                      className="col-sm-4"
                      placeholder="Editor Co-support"
                      defaultValue={this.state.editor_co_support}
                    />
                    <s
                      className="mt-3"
                      style={{
                        borderBottom: "1px solid #375ba8",
                        width: "100%",
                      }}
                    ></s>
                  </div>
                  <div className="form-group row">
                    <InputNewBox
                      label="IF"
                      onChange={this.onChangeHandler}
                      field="impact_factor"
                      className="col-sm-2"
                      placeholder="Impact factor"
                      defaultValue={this.state.impact_factor}
                    />

                    <InputNewBox
                      label="H-index"
                      onChange={this.onChangeHandler}
                      field="hindex"
                      className="col-sm-2"
                      placeholder="H-index"
                      defaultValue={this.state.hindex}
                    />

                    <InputNewBox
                      label="Indexed"
                      onChange={this.onChangeHandler}
                      field="indexed"
                      className="col-sm-2"
                      placeholder="Indexed in"
                      defaultValue={this.state.indexed}
                    />
                  </div>
                  <div className="form-group row mt-2">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Publication contacts </label>
              </div>
              
            </div>
            <s style={{ borderBottom: "1px solid #375ba8", width: "100%" }}></s>

          </div>

          { this.renderPublicationContact(this.state.publication_contacts) }

          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Add Contacts </label>
              </div>
              
              <div style={{marginLeft:15,float:"left"}}>
                <button 
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={this.addNewPubContact}
                >
                  <i className="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
          </div>



          <div className="text-right m-t-15"></div>
          {this.props.msg && (
            <div className="form-group row">
              <span className="alert alert-success col-sm-12">
                {this.props.msg}
              </span>
            </div>
                  )}
                  

                  <div className="text-right m-t-15">
                    <div className="text-center m-t-15">
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Update bookseries
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  orgs: state.app.organizations,
  journal_type: state.journals.journal_type,
  book_series_type: state.journals.book_series_type,
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  oa_disciplines: state.OaDiscipline.oa_disciplines || [],
  oa_subjects: state.OaSubject.oa_subjects || [],
  book_series: state.journals.single_journal ,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgList: (payload) => dispatch(getOrgList(payload)),
  getBookSeriesType: () => dispatch(getBookSeriesType()),
  updateBookSeries: (payload, id) => dispatch(UpdateJournal(payload, id)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getJournalTypeList: (payload) => dispatch(getJournalTypeList(payload)),
  getPublicatonTypeList: (payload) => dispatch(getPublicatonTypeList(payload)),
  getContentTypeList: (payload) => dispatch(getContentTypeList(payload)),
  singleJournal: (payload) => dispatch(singleJournal(payload)),
});

export const UpdateBookSeries = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form)
);
