import React from "react";
import moment from "moment";
import { AutoComplete } from "antd";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  InputNewBox,
  SelectBox,
  OaDatePicker,
  Button,
  PopupBox,
  ModalSelectBox,
  Loading, ToolTipField
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import { serialize } from "../../../../../core/lib";
import {
  getAllCurrencyList,
  getPublicatonTypeList,
  getPublicationList,
  getContentTypeList,
  getOaDisciplineList,
  getOaSubjectList,
  getInstitutionDataLists,
  getMembershipDiscount,
  createUpdateMembershipDiscount,
} from "../../../../actions";

const format = "Do MMM YYYY";

const offerTypes = [
  { id: "DISCOUNT", name: "Discount" },
  { id: "AMOUNT", name: "Amount" },
  { id: "SPECIAL_APC", name: "Special APC" },
];

class MemberShipForm extends BaseComponent {
  state = {
    memberShipDiscount: {},
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.memberShipDiscount.publication_types &&
      this.state.memberShipDiscount.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.memberShipDiscount.publication_types[0] !== "all"
          ? this.state.memberShipDiscount.publication_types.join(",")
          : "";
    }

    if (this.state.disciplines && this.state.disciplines.length > 0) {
      filterParams.discipline_ids =
        this.state.disciplines[0] !== "all"
          ? this.state.disciplines.join(",")
          : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publication_list: this.props.publication_list.results,
      });
  };

  onChangeHandler = async (value) => {
    if (value.hasOwnProperty("offer_type")) {
      this.setState({
        ...value,
      });
    } else {
        this.setState({
        ...value,
      });
    }

    this.setState(
      (prevState) => {
        if (value.offer_expiry) {
          value.offer_expiry = moment(value.offer_expiry).format("YYYY-MM-DD");
        }

        // if (value.hasOwnProperty("offer_type")) {
        //   Object.assign(prevState.memberShipDiscount, { offer_currency: null });
        // }

        Object.assign(prevState.memberShipDiscount, value);
      },
      () => {
        if (value.hasOwnProperty("publication_types")) {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  async componentDidMount() {
    await this.props.getMembershipDiscount(this.props.match.params.id);

    this.props.getOaDisciplineList();
    this.props.getAllCurrencyList();
    this.props.getPublicatonTypeList();
    this.props.getContentType();

    let payload = {};
    await this.props.getOaSubjectList(payload);

    this.props.oa_subjects &&
      this.setState({
        oa_subject_list: this.props.oa_subjects.results,
      });

    this.getPublicationListOnParameterChange();

    if (this.props.match.params.id) {
      let single_membership = this.props.single_membership;
      if (
        single_membership.disciplines &&
        single_membership.disciplines.length > 0
      ) {
        payload["discipline"] = single_membership.disciplines.join(",");
      }
      let membershipDiscount = {
        subjects: single_membership.subjects,
        offer_expiry: single_membership.offer_expiry,
        disciplines: single_membership.disciplines,
        society: single_membership.society,
        membership_fees: single_membership.membership_fees,
        membership_fees_currency: single_membership.membership_fees_currency,
        offer_type: single_membership.offer_type,
        offer_currency: single_membership.offer_currency,
        offer_amount: single_membership.offer_amount,
        publications: single_membership.publications,
        content_types: single_membership.content_types,
        publication_types: single_membership.publication_types,
        society_name: single_membership.society_name,
      };

      this.setState({
        society_name: membershipDiscount.society_name,
        memberShipDiscount: membershipDiscount,
        society: membershipDiscount.society,
        membership_fees: membershipDiscount.membership_fees,
        membership_fees_currency: membershipDiscount.membership_fees_currency,
        offer_type: membershipDiscount.offer_type,
        offer_currency: membershipDiscount.offer_currency,
        offer_amount: membershipDiscount.offer_amount,
        offer_expiry:
          (membershipDiscount.offer_expiry &&
            moment(membershipDiscount.offer_expiry)) ||
          moment(new Date(), format),
        disciplines: membershipDiscount.disciplines || [],
        subjects: membershipDiscount.subjects || [],
        publications: membershipDiscount.publications || [],
        content_types: membershipDiscount.content_types || [],
        publication_types: membershipDiscount.publication_types || [],
        options: membershipDiscount.options,
      });

    }
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.memberShipDiscount, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });

        if (fieldName === "disciplines") {
          if (data.length > 0) {
            let payload = {
              discipline: data[0] !== "all" ? data.join(",") : "",
            };

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }

          this.getPublicationListOnParameterChange();
        } else if (fieldName === "subjects") {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "disciplines":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "subjects":
        this.setState({
          isShowSubjectModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "disciplines":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} discipline selected`
              : `${fieldValues.length} disciplines selected`;
        } else {
          labelText = `All disciplines selected`;
        }
        break;
      case "subjects":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} subject selected`
              : `${fieldValues.length} subjects selected`;
        } else {
          labelText = `All subjects selected`;
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} publication selected`
              : `${fieldValues.length} publications selected`;
        } else {
          labelText = `All publications selected`;
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} content selected`
              : `${fieldValues.length} contents selected`;
        } else {
          labelText = `All content types selected`;
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  onSelect = (value, item) => {
    this.setState({
      society: item.id,
    });
  };
  onChange = (value) => {
    this.setState({
      society_name: value,
    });
  };
  onSearch = (value) => {
    this.props.getInstitutionDataLists(value);
  };
  onSubmitHandler = async (e) => {
    e.preventDefault();
    let data = {};
    Object.assign(data, {
      ...this.state.memberShipDiscount,
      society: this.state.society,
    });


    if (
      !data.membership_fees_currency ||
      !data.membership_fees ||
      !data.offer_type ||
      !data.offer_amount
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!data.membership_fees_currency && <p>Currency must be selected</p>}
            {!data.membership_fees && (
              <p>Membership fee is required </p>
            )}
            {!data.offer_type && <p>Offer Type must be selected</p>}
            {!data.offer_amount && <p>Offer Amount is required</p>}
          </div>
        ),
      });
    } else {
      if (this.props.match.params.id) {
        await this.props.createUpdateMembershipDiscount(
          data,
          this.props.match.params.id
        );
      }
  
      this.props.history.push("/membership-discounts/member-discount-list/");
  
    }


  };

  render() {

    const currency = [];
    const currency_2 = [];

    this.props.currency_list &&
      this.props.currency_list.map((item) =>
        currency.push({
          id: item.id,

          name:
            item.name === "British Pound Sterling"
              ? "GBP"
              : item.name === "Euro"
                ? "EUR"
                : "USD",
        })
      );

    this.props.currency_list &&
      this.props.currency_list.map((item) =>
        currency_2.push({
          id: item.id,
          name:
            item.name === "British Pound Sterling"
              ? "GBP"
              : item.name === "Euro"
                ? "EUR"
                : "USD",
        })
      );
    let odealInstList = [];
    this.props.institute_list &&
      this.props.institute_list.map((item) =>
        odealInstList.push({
          id: item.id,
          value: item.name,
        })
      );
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        {this.props.isLoading !== true ? <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.push("/membership-discounts/member-discount-list/")}
                />
              </div>
              <div className="card-body membership">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Update Institution discount
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id=""
                    role="tabpanel"
                  ></div>

                  <form action="">
                    <div className="form-group row auto-complete-search">
                      <div className="col-form-label col-sm-3 col-lg-2">
                        <label>Institution<span className="label-icon-red">*</span></label>
                        <ToolTipField labelInfoTitle="Type your full institution name and select from the drop down" />

                      </div>


                      <div className="col-md-10">
                        <AutoComplete
                          value={this.state.society_name}
                          options={odealInstList}
                          style={{
                            width: "100%",
                          }}
                          onSelect={(val, option) => this.onSelect(val, option)}
                          onSearch={this.onSearch}
                          onChange={this.onChange}
                          placeholder="Type your full institution name and select from the drop down
"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <SelectBox


                        label="Membership types"
                        onChange={this.onChangeHandler}
                        field="membership_types"
                        className="col-sm-4"
                        data={[
                          { id: 1, name: "Standard" },
                          { id: 2, name: "Premium" },
                        ]}

                        defaultValue={this.state.membership_types}
                        isRequired={false}
                        multiple={false}
                      />
                      <SelectBox
                        label="Publication types"
                        onChange={this.onChangeHandler}
                        field="publication_types"
                        className="col-sm-4"
                        data={this.props.publication_type}
                        defaultValue={this.state.publication_types}
                        isRequired={false}
                        multiple={true}
                      />
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-4 model-class no-color">
                        {this.showSelectItemsLabel("disciplines")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("disciplines")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowDisciplineModal && (
                        <ModalSelectBox
                          title="Select disciplines"
                          label="Disciplines"
                          field="disciplines"
                          className="col-sm-9"
                          data={
                            this.props.oa_disciplines && [
                              { id: "all", name: "All disciplines" },
                              ...this.props.oa_disciplines.results,
                            ]
                          }
                          defaultValue={this.state.disciplines}
                          isRequired={false}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-sm-4 model-class no-color">
                        {this.showSelectItemsLabel("subjects")}
                        <button
                          onClick={() => this.onModalSelectBoxShow("subjects")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowSubjectModal && (
                        <ModalSelectBox
                          title="Select subjects"
                          label="Subjects"
                          field="subjects"
                          className="col-sm-9"
                          data={
                            (this.state.oa_subject_list && [
                              { id: "all", name: "All subjects" },
                              ...this.state.oa_subject_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.subjects}
                          isRequired={true}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-sm-4 model-class no-color">
                        {this.showSelectItemsLabel("content_types")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("content_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowContentTypeModal && (
                        <ModalSelectBox
                          title="Select content types"
                          label="Content types"
                          field="content_types"
                          className="col-sm-9"
                          data={
                            (this.props.content_type && [
                              { id: "all", name: "All content types" },
                              ...this.props.content_type,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.content_types}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-sm-4 model-class no-color">
                        {this.showSelectItemsLabel("publications")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publications")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationModal && (
                        <ModalSelectBox
                          title="Select publications"
                          label="Publications"
                          field="publications"
                          className="col-sm-9"
                          data={
                            (this.state.publication_list && [
                              { id: "all", name: "All publications" },
                              ...this.state.publication_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.publications}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>
                    <div className="form-group row">
                      <SelectBox
                        label="Currency"
                        onChange={this.onChangeHandler}
                        field="membership_fees_currency"
                        data={currency}
                        isRequired={false}
                        labelInfo={true}
                        labelInfoTitle="Select Currency "
                        defaultValue={this.state.membership_fees_currency}
                      />

                      <InputNewBox
                        className="col-sm-4"
                        labelInfo={true}
                        labelInfoTitle="Enter  membership fee"
                        label="Membership fee"
                        onChange={this.onChangeHandler}
                        field="membership_fees"
                        placeholder="Membership fee"
                        defaultValue={this.state.membership_fees}
                        InputType="number"
                      />
                    </div>
                    <div>
                      <div className="form-group row memberhsip">

                        {this.state.offer_type && (
                          <SelectBox
                            label="Offer"
                            onChange={this.onChangeHandler}
                            field="offer_type"
                            className="col-md-4"
                            data={offerTypes}
                            isRequired={false}
                            labelInfo={true}
                            labelInfoTitle="Select Offer Type "
                            defaultValue={this.state.offer_type}
                          />
                        )}
                        {this.state.offer_type === "SPECIAL_APC" ||
                          this.state.offer_type === "AMOUNT" ? (
                            <div className="col-md-2">
                              <SelectBox
                                onChange={this.onChangeHandler}
                                field="offer_currency"
                                className="col-md-12"
                                defaultValue={this.state.offer_currency}

                                
                                data={currency}
                                labelInfo={true}
                                labelInfoTitle="Select Offer Currency "
                                isRequired={false}
                              />
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <div className="row">
                                <InputNewBox
                                  label={
                                    this.state.offer_type === "DISCOUNT"
                                      ? "Discount"
                                      : "Amount"
                                  }
                                  labelInfo={true}
                                  labelInfoTitle={this.state.offer_type === "DISCOUNT"
                                    ? "Enter discount"
                                    : "Enter amount"}
                                  labelClass="col-md-4"
                                  className="col-md-8"
                                  onChange={this.onChangeHandler}
                                  field="offer_amount"
                                  placeholder={
                                    this.state.offer_type === "DISCOUNT"
                                      ? "Enter discount"
                                      : "Enter amount"
                                  }
                                  defaultValue={this.state.offer_amount}
                                  InputType="number"
                                />
                              </div>
                            </div>
                          )}

                        <div className="col-md-4">
                          {this.state.offer_type !== "AMOUNT" ? null : (
                            <InputNewBox
                              className="col-md-12"
                              onChange={this.onChangeHandler}
                              field="offer_amount"
                              placeholder={
                                this.state.offer_type === "DISCOUNT"
                                  ? "enter discount"
                                  : "enter amount"
                              }
                              labelInfo={true}
                              labelInfoTitle={this.state.offer_type === "DISCOUNT"
                                ? "Enter discount"
                                : "Enter amount"}
                              defaultValue={this.state.offer_amount}
                              InputType="number"
                            />
                          )}
                          {this.state.offer_type !== "SPECIAL_APC" ? null : (
                            <InputNewBox
                              labelInfo={true}
                              labelInfoTitle={this.state.offer_type === "DISCOUNT"
                                ? "Enter discount"
                                : "Enter amount"}
                              className="col-md-12"
                              onChange={this.onChangeHandler}
                              field="offer_amount"
                              placeholder={
                                this.state.offer_type === "DISCOUNT"
                                  ? "Enter discount"
                                  : "Enter amount"
                              }
                              defaultValue={this.state.offer_amount}
                              InputType="number"
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        {this.state.offer_expiry && (
                          <OaDatePicker
                            label="Offer expiry"
                            field="offer_expiry"
                            onChange={this.onChangeHandler}
                            placeholder="Select offer expiry"
                            showTime={false}
                            format={format}
                            labelInfo={true}
                            labelInfoTitle="Select offer expiry"
                            defaultValue={this.state.offer_expiry}
                          />
                        )}
                      </div>
                    </div>

                    <div className="text-center m-t-15">
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> : <Loading type="flat" />}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  single_membership: state.membershipDiscount.single_members,
  organisation_id: state.auth.user.organisation_id,
  token: state.auth.token,
  currency_list: state.OaDeals.oadeal_currency || [],
  institute_list: (state.institutions && state.institutions.institution_list )|| [],
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  oa_disciplines: state.OaDiscipline.oa_disciplines || [],
  oa_subjects: state.OaSubject.oa_subjects || [],
  isLoading: state.membershipDiscount.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCurrencyList: () => dispatch(getAllCurrencyList()),
  createUpdateMembershipDiscount: (payload, id) =>
    dispatch(createUpdateMembershipDiscount(payload, id)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: () => dispatch(getContentTypeList()),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getMembershipDiscount: (payload) => dispatch(getMembershipDiscount(payload)),
  getInstitutionDataLists: (payload) =>
    dispatch(getInstitutionDataLists(payload)),
});

export const EditMemberShipDiscount = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberShipForm);
