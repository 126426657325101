import { GET_XERO_AUTHORIZE_URL, SUBMIT_XERO_AUTH_CODE, CLEAR_FAILED_STATUS } from "../../config";

const XeroReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_XERO_AUTHORIZE_URL: {
            return state = {
                ...state,
                xero_auth_url: action.payload
            }
        }
        case SUBMIT_XERO_AUTH_CODE: {
            return state = {
                ...state,
                submit_auth_code: action.payload
            }
        }


        case CLEAR_FAILED_STATUS: {
            return state = {}
        }
        default:
            return state;
    }
}

export default XeroReducer;
