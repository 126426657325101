import {
    GET_AUTHOR_APC_REQUEST,
    GET_AUTHOR_APC_REQUEST_DOWNLOAD, GET_APC_REQUEST
} from "../../config";

const AuthorRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_AUTHOR_APC_REQUEST:
            return state = {
                ...state,
                isLoading: false,
                apc_requests: action.payload
            }
        case GET_APC_REQUEST: {
            return state = {
                ...state,
                isLoading: true,
            }
        }
        case GET_AUTHOR_APC_REQUEST_DOWNLOAD:
            return state = {
                ...state,
                apc_requests_download: action.payload
            }

        default:
            return state;
    }
}
export default AuthorRequestReducer;