import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";


import { Loading, InputBox, PopupBox, InputNewBox } from "../../../../../core/components/common";
import {
    UpdateUser,
    getSingleUser,
    getInstitutionDataLists,


} from "../../../../actions";

class User extends BaseComponent {
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        isEmpty: false,
        deleteConfirm: false,
        deleteStatus: null,
        isInputEnable: true,
        emailMessageVisable: false,
        isUpdateBtn: true
    };
    removeEmpty = (obj) => {
        Object.keys(obj).forEach(key => {
            if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
            if (
                (!obj[key] && obj[key] !== 0) ||
                (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
            ) {
                delete obj[key];
            }
        });
        return obj;
    };

    async componentDidMount() {
        let user;
        const userId = this.props.match.params.id;
        user = await this.props.getSingleUser(userId);


        this.setState({
            organisation_name: user.organisation_name,
            organisation: user.organisation,
            user,
            position: user && user.domain_meta && user.domain_meta.position,
            department: user && user.domain_meta && user.domain_meta.department,
            tableData: this.props.society_list
        });
    }

    onChangeDomainHandler = (value) => {
        this.setState((prevState) => {

            Object.assign(prevState.user, {
                domain_meta: {
                    ...prevState.user.domain_meta,
                    ...value,
                },
            });
            return prevState;
        });
    };
    onChangeAddressesler = (value) => {
      this.setState((prevState) => {

          Object.assign(prevState.user, {
              domain_meta: {
                  ...prevState.user.domain_meta,
                  ...value,
              },
          });
          return prevState;
      });
  };
    


    onSubmit = async () => {
        if (this.isNumber(this.state.organisation)) {
            let { salute,
                last_name,
                middle_name,
                first_name,
                department,
                email,
                position,
                username } = this.state.user
            const userData = {
                salute,
                ...this.state.user,
                last_name,
                middle_name,
                first_name,
                email,
                domain_meta: {
                    department: this.state.department || department,
                    position: this.state.position || position

                },
                username
            }
            let res = await this.props.UpdateUser({ ...userData, organisation: this.state.organisation });

            if (res.status === 200) {
                this.setState({
                    successMessage: true,
                    isUpdateBtn: !this.state.isUpdateBtn

                });
            }
        } else {
            this.setState({

                errorinstitutionMessage: true
            })
        }

    };

    onClose = () => {
        this.setState({
            isShow: false,
            isAlterNativeMail: false
        });
    };

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.user, value);
        });

    };



    updateBtn = () => {
        this.setState({
            isInputEnable: !this.state.isInputEnable,
            isUpdateBtn: !this.state.isUpdateBtn
        })
    }

    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }


    onCancel = () => {
        this.setState({
            societyMessageVisable: false,
            emailMessageVisable: false,
            errorinstitutionMessage: false,
            InstMessageVisable: false,

        });
    };
    redirectLink = () => {
        this.setState({
            successMessage: false,
        });
        this.props.history.push(`/users/user/${this.props.match.params.id}`)

    };
  


    onChangeOrcidHandler = (value) => {
          
        this.setState((prevState) => {
            Object.assign(prevState.user, {
                user_meta: {
                ...prevState.user.user_meta,
                ...value,
              },
            });
            return prevState;
          });

      };
    render() {

    
        return (
            <HubContent title={`${this.state.user.fullname || ""} `}>

                <div className="container">
                    {this.state.successMessage && (
                        <PopupBox
                            NoText="Close"
                            msg="You have successfully updated user information."
                            onCancel={this.redirectLink}
                        />
                    )}




                </div>
                <div className="row">
                    <div className="col-lg-12">




                        <div className="profile-content" style={{ paddingTop: 10 }}>


                            {this.props.loading !== true ? <div className="row">
                                <div className="col-md-12 profile-m-15" style={{ marginBottom: 15, paddingLeft: 15, paddingRight: 15 }}>
                                    {this.state.isUpdateBtn && <div className="col-md-12 update-btn">
                                        <button
                                            className="btn btn-info btn-md"
                                            onClick={this.updateBtn}
                                            style={{ marginTop: 10 }}
                                        >
                                            Edit</button>
                                    </div>}
                                    {
                                        (this.state.isInputEnable !== true && this.state.successMessage !== true) &&
                                        <div className="col-md-12 update-btn">
                                            <button
                                                className="btn btn-primary btn-md"
                                                onClick={this.onSubmit}
                                            >
                                                Save
            </button>
                                        </div>
                                    }

<label className="profile-label">Institution  </label>

                               <InputBox
                                        onChange={this.onChangeHandler}
                                        field="organisation_name"
                                        disabled={true}

                                        className="col-md-12"
                                        defaultValue={this.state.organisation_name}
                                    /> 

                                       

                                </div>
                                {this.state.isInputEnable && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Name </label>
                                    <InputBox
                                        onChange={this.onChangeHandler}
                                        className="col-md-12"
                                        disabled={true}
                                        defaultValue={this.state.user.fullname}
                                    />
                                </div>}
                                {this.state.isInputEnable !== true && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Salutation </label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        className="col-md-12"
                                        field="salute"
                                        disabled={this.state.isInputEnable}
                                        defaultValue={this.state.user.salute}
                                    />

                                </div>}

                                {this.state.isInputEnable !== true && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">First name <span style={{ color: "red", marginLeft: "5px" }}>*</span></label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="first_name"
                                        disabled={this.state.isInputEnable}

                                        className="col-md-12"
                                        defaultValue={this.state.user.first_name}
                                    />
                                </div>}
                                {this.state.isInputEnable !== true && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Middle Name</label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="middle_name"
                                        className="col-md-12"
                                        disabled={this.state.isInputEnable}
                                        defaultValue={this.state.user.middle_name}
                                    />
                                </div>}
                                {this.state.isInputEnable !== true &&
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Last Name <span style={{ color: "red", marginLeft: "5px" }}>*</span></label>
                                        <InputNewBox
                                            onChange={this.onChangeHandler}
                                            field="last_name"
                                            disabled={this.state.isInputEnable}

                                            className="col-md-12"
                                            defaultValue={this.state.user.last_name}
                                        />
                                    </div>}
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Position </label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="position"
                                        disabled={this.state.isInputEnable}
                                        defaultValue={this.state.position}
                                        className="col-md-12"

                                    />
                                </div>
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label"> Department</label>
                                    <InputNewBox
                                        disabled={this.state.isInputEnable}

                                        onChange={this.onChangeHandler}
                                        field="department"
                                        defaultValue={this.state.department}

                                        className="col-md-12"
                                    />
                                </div>

                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Email Address <span style={{ color: "red", marginLeft: "5px" }}>*</span></label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="email"
                                        disabled={this.state.isInputEnable}

                                        className="col-md-12"
                                        defaultValue={this.state.user.email}
                                    />
                                </div>
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Username</label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="username"
                                        disabled={true}

                                        className="col-md-12"
                                        defaultValue={this.state.user.username}
                                    />
                                </div>
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Oametrix ID</label>
                                    <InputBox
                                        disabled={true}

                                        id="name"
                                        onChange={this.onChangeHandler}
                                        field="unique_id"


                                        className="col-md-12"
                                        defaultValue={this.state.user.unique_id}
                                    />
                                </div>

                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Orcid ID</label>
                                    <InputBox
                                        id="name"
                                        onChange={this.onChangeHandler}
                                        field="orcid_id"
                                        disabled={true}

                                        className="col-md-12"
                                        defaultValue={
                                            this.state.user.user_meta && this.state.user.user_meta.orcid_id
                                        }
                                    />
                                </div>






                                <div className="col-md-4 profile-m-15">
                                <label className="profile-label">Phone</label>

                 <InputBox
                onChange={this.onChangeOrcidHandler}
                field="phone"
                placeholder="Enter phone Number"
                disabled={this.state.isInputEnable}

                className="col-md-12"
                defaultValue={
                    this.state.user ? (  this.state.user.user_meta ?   this.state.user.user_meta.phone : "") : ""
                }
              />
              
            </div>
       
      


                            </div>
                                : <Loading type="flat" />}

                        </div>

                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.user.userData,
    loading: state.user.loading,
    user: state.auth.user || {},
    institution_list: (state.institutions && state.institutions.institution_list )|| [],


});

const mapDispatchToProps = dispatch => ({
    getSingleUser: id => dispatch(getSingleUser(id)),
    UpdateUser: (payload) => dispatch(UpdateUser(payload)),
    getInstitutionDataLists: (value) => dispatch(getInstitutionDataLists(value)),


});

export const ProfileUser = connect(mapStateToProps, mapDispatchToProps)(User);
