import React from 'react';
import { connect } from 'react-redux'
import BaseComponent, { HubContent } from '../../../../../core/components';
import { Button } from "../../../../../core/components/common";

import { getSocietyMember } from '../../../../actions';
import { Card, Skeleton } from 'antd';

import moment from "moment";

// const format = "Do MMM YYYY";

class ViewTable extends BaseComponent {
  state = {
    info: null
  }

  async componentDidMount() {
    let id = this.props.match.params.id;

    await this.props.getSocietyMember(id);

    this.setState({
      info: this.props.society_member
    });
  }

  dateFormatted = (date) => {
    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  }

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  renderDetails = () => {
    let info = this.props.society_member;

    return (
      info &&
      <div className="row">
        <div className="col-lg-12">
          <table style={styles.table}>
            <tbody>
              <tr style={styles.tr}>
                <td style={styles.td}>Name</td>
                <td style={styles.td2}>{info.salutation || ''} {info.first_name || ''} {info.last_name || ''}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Society</td>
                <td style={styles.td2}>{info.society_name || '-'}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Membership ID</td>
                <td style={styles.td2}>{info.membership_id}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Orcid ID</td>
                <td style={styles.td2}>{info.orcid_id}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Email</td>
                <td style={styles.td2}>{info.email}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Membership expiry</td>
                <td style={styles.td2}>{info.membership_expiry ? this.dateFormatted(info.membership_expiry) : '-'}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Affiliation</td>
                <td style={styles.td2}>{info.affiliation}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render() {


    return (
      <HubContent title="Society member details">
        <Card>
          <div className="vat-tax-button email">
            <Button
              type="danger"
              name="danger"
              text="X"
              onClick={() => this.props.history.goBack()}
            />
          </div>
          {this.state.info ? this.renderDetails() :
            <Skeleton
              active
              paragraph={{ row: 10 }}
            />
          }
        </Card>
      </HubContent>
    )
  }
}

const mapStateToProps = (state) => ({
  society_member: state.SocietyMember.society_member || false,
})

const mapDispatchToProps = (dispatch) => ({
  getSocietyMember: (id) => dispatch(getSocietyMember(id))
})

export const SocietyMemberView = connect(mapStateToProps, mapDispatchToProps)(ViewTable)


const styles = {
  table: {
    borderCollapse: "collapse",
    width: '100%'
  },
  tr: {
    marginTop: 20,
    verticalAlign: 'baseline'
  },
  td: {
    paddingTop: 10
  },
  td2: {
    paddingTop: 10,
    width: '75%'
  }
};
