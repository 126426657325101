import React from "react";
import moment from "moment";

class ExpandConsortiaTable extends React.Component {
  state = {};

  setActive = () => {
    this.setState({
      active: !this.state.active,
    });
  };
  dateOfDeal = (date) => {
    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  };

  priceofOadeal = (price) => {
    let dataPrice = Number(price).toFixed(2);
    return dataPrice;
  };

  renderListItems = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <p className="oadeal-table-view"> {item.name}</p>
      ));
    }

    return "";
  };

  render() {
    const { item, column } = this.props;
    return (
      <div className="data-table">
        <div className="row-table">
          <div className="row table-data">
            <div className={column[2]}>
              {item.counter_organisation_name
                ? item.counter_organisation_name
                : "-"}
            </div>

            <div className={column[1]}>
              {item.deal_name ? item.deal_name : "-"}
            </div>
            <div className={column[1]}>
              {item.currency_name ? item.currency_name : "-"}
            </div>

            <div className={column[1]}>{item.discount}</div>

            <div className={column[1]}>
              {" "}
              <a
                className="article_open_arrow ss"
                href={`#collapse_${item.id}`}
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls={`collapse_${item.id}`}
                style={{ color: "#5874ab", fontWeight: 500 }}
              >
                <i
                  onClick={this.setActive}
                  className={
                    this.state.active
                      ? "fa fa-chevron-up"
                      : "fa fa-chevron-down"
                  }
                />
              </a>
              {/* </div>
              </div> */}
            </div>
          </div>

          <div
            id={`collapse_${item.id}`}
            className={`collapse ${false && "show"}`}
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div
              className="row table-expand"
              style={{ paddingTop: 10, paddingBottom: 20 }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    {item.counter_organisation_name ? (
                      <label>Organisation name:</label>
                    ) : null}
                    {item.counter_organisation_name ? (
                      <p>{item.counter_organisation_name}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    {item.deal_name ? <label>Deal name:</label> : null}
                    {item.deal_name ? <p>{item.deal_name}</p> : null}
                  </div>
                  <div className="col-md-4">
                    {item.discount ? <label>Discount:</label> : null}
                    {item.discount ? <p>{item.discount}</p> : null}
                  </div>

                  <div className="col-md-4">
                    <label className="oadeal-view">Disciplines</label>
                    {item.discipline_details.length > 0
                      ? this.renderListItems(item.discipline_details)
                      : `All disciplines`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Subjects </label>
                    {item.subject_details.length > 0
                      ? this.renderListItems(item.subject_details)
                      : `All subjects`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Publication types </label>
                    {item.publication_type_details.length > 0
                      ? this.renderListItems(item.publication_type_details)
                      : `All publication types`}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Publications </label>
                    {item.publication_details.length > 0
                      ? this.renderListItems(item.publication_details)
                      : `All publications`}{" "}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Content types</label>
                    {item.content_type_details.length > 0
                      ? this.renderListItems(item.content_type_details)
                      : `All content types`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpandConsortiaTable;
