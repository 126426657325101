import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EmailEditor from "react-email-editor";

import { HubContent } from "../../../../../core/components";
import {
  ModalSelectBox,
  Badge,
  Button,
  InputBox,
  ModalAutoComplete,
} from "../../../../../core/components/common";
import { serialize } from "../../../../../core/lib";
import {
  getPublicationList,
  createEmailMessage,
  updateEmailMessage,
} from "../../../../actions";

import { Radio } from "antd";


class EmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.isEditorLoaded = false;
    this.isComponentMounted = false;
    this.state = {
      ref_email: "",
      title: "",
      description: "",
      subject: "",
      only_for_waiver_countries: null,
      content_types: [],
      publication_types: [],
      authors: [],
      author_affiliations: [],
      author_affiliation_countries: [],
      institutions: [],
      funders: [],
      content_types_list:Array.isArray(this.props.location &&
        this.props.location.state &&
        this.props.location.state.content_type_list) ? this.props.location &&
        this.props.location.state &&
        this.props.location.state.content_type_list :[]
      ,
      funderList:
      Array.isArray(this.props.location &&
        this.props.location.state &&
        this.props.location.state.funderList)? this.props.location &&
        this.props.location.state &&
        this.props.location.state.funderList: [],
      publications: [],
      publishers: [],
      funder_deal: null,
      article_auto_approved: false,
      html_body: "",
      publicationList: [],
      defaultEmailMessage:
        (this.props.location &&
          this.props.location.state &&
          this.props.location.state.default_email_message) ||
        {},

      publicationTypeList: Array.isArray(this.props.location &&
        this.props.location.state &&
        this.props.location.state.publicationTypeList) ?this.props.location &&
        this.props.location.state &&
        this.props.location.state.publicationTypeList:[],
        
      institutions_deal: [],
      institution_deal_List: [
        { name: "All OA deals", id: "all" },
        { name: "OA Deal", id: "deal" },
        { name: "Non OA Deal", id: "non-deal" },
      ],
      selectedItems: [],
      value: false,
      html_default_json:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.default_email_message &&
        this.props.location.state.default_email_message.json_body,
      id:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.data &&
        this.props.location.state.data.id,
      html_json:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.data &&
        this.props.location.state.data.json_body,
    };
  }
 
  async componentDidMount() {
    this.isComponentMounted = true;
    this.loadTemplate();


    let getEditData = this.props.location && this.props.location.state && this.props.location.state.data;

    if (getEditData) {
      let publicaionsID = Number(
        getEditData.publication_types.map((item) => item).toString()
      );
      let instititutionDeal = this.state.institution_deal_List.find(
        (item) =>
          getEditData.institution_deal &&
          getEditData.institution_deal.includes(item.id)
      );
      if (publicaionsID === 0) {
        let payload = {};
        let filterParams = {
          page_size: 999999,
        };
        payload.filter = serialize(filterParams);

        await this.props.getPublicationList(payload);
      } else {
        let payload = {};
        let filterParams = {
          page_size: 999999,
        };
        filterParams.publication_type = publicaionsID;
        payload.filter = serialize(filterParams);
        await this.props.getPublicationList(payload);
      }

      if (this.props.publication_list) {
        let res = this.props.publication_list;
        this.setState({
          publicationList: [
            { id: "all", name: "All publications" },
            ...res.results,
          ],
        });
      }
      this.setState({
        getEditData: getEditData,
        ref_email: this.state.defaultEmailMessage.id,
        title: getEditData && getEditData.title,
        description: getEditData.description,
        subject: getEditData.subject,
        only_for_waiver_countries: getEditData.only_for_waiver_countries,
        data: getEditData && getEditData.template,
        publications_types:
        Array.isArray(getEditData.publication_types) ? getEditData.publication_types.map((item) => item) : [],
        authors: [],
        content_types:
        Array.isArray(getEditData.content_types) ? getEditData.content_types.map((item) => item) : [],

        institutionList:
        Array.isArray(getEditData.author_affiliation_details) ?getEditData.author_affiliation_details.map((item) => ({
          id: item.id,
          value: item.name,
        })) : [],


        author_affiliation_countries: [],
        funders: Array.isArray(getEditData.funders) ? getEditData.funders.map((item) => item) : [],
        publications:Array.isArray(getEditData.publications) ? getEditData.publications.map((item) => item) : [],
        publishers: [],
        institutions_deal:
          [instititutionDeal && instititutionDeal.id.toString()] || "",
        funder_deal: null,
        article_auto_approved: false,
        html_body: getEditData.html_body,
        json_body: getEditData.json_body,
      });
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  onChangeRadioButtonHandler = (e) => {
    this.setState({
      only_for_waiver_countries: e.target.value,
    });
  };

  onTextFieldChange = (value) => {
    this.setState({
      ...value,
    });
  };

  handleChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  handePublishersBasedonParent = async (fieldName, data) => {
    this.setState({
      [fieldName]: data,
    });

    if (data.length === 0) {
      let payload = {};
      let filterParams = {
        page_size: 999999,
      };
      payload.filter = serialize(filterParams);

      await this.props.getPublicationList(payload);
    } else {
      let payload = {};
      let filterParams = {
        page_size: 999999,
      };
      filterParams.publication_type = data;
      payload.filter = serialize(filterParams);
      await this.props.getPublicationList(payload);
    }

    if (this.props.publication_list) {
      let res = this.props.publication_list;
      this.setState({
        publicationList: [
          { id: "all", name: "All publications" },
          ...res.results,
        ],
      });
    }
  };
  getEmailContent = (content) => {
    this.setState({
      html_body: content,
    });
  };
  emailFormSubmit = (value) => {
    let funderList =
      this.state.funders && this.state.funders[0] === "all"
        ? []
        : this.state.funders;
    let contentList =
      this.state.content_types && this.state.content_types[0] === "all"
        ? []
        : this.state.content_types;

    let publication_types =
      this.state.publications_types &&
        this.state.publications_types[0] === "all"
        ? []
        : this.state.publications_types;
    let author_affiliations = this.state.institutions
      ? this.state.institutions
      : [];
    let institutions_deal =
      this.state.institutions_deal && this.state.institutions_deal[0] === "all"
        ? []
        : this.state.institutions_deal &&
        this.state.institutions_deal.toString();
    let publications =
      this.state.publications && this.state.publications[0] === "all"
        ? []
        : this.state.publications;
    let data = {
      ref_email: this.state.defaultEmailMessage.id,
      title: this.state.title,
      description: this.state.description,
      subject: this.state.subject,
      only_for_waiver_countries: this.state.only_for_waiver_countries,
      content_types:contentList,
      publication_types: publication_types ? publication_types : [],
      authors: [],
      author_affiliations: author_affiliations ? author_affiliations : [],
      author_affiliation_countries: [],
      funders: funderList ? funderList : [],
      publications: publications ? publications : [],
      publishers: [],
      institution_deal: institutions_deal || "",
      funder_deal: null,
      html_body: String(value.html),
      json_body: String(value.body),
    };
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.id
    ) {
      this.props
        .updateEmailMessage(this.props.location.state.id, data)
        .then((res) => {
          this.props.history.push({
            pathname: "/settings/email-configuration",
            state: res.data && res.data.tag,
          });
        });
    } else {
      this.props.createEmailMessage(data).then((res) => {
        this.props.history.push({
          pathname: "/settings/email-configuration",
          state: res.data && res.data.tag,
        });
      });
    }
  };
  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "publications_types":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationTypeModal: true,
        });
        break;
      case "funders":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      case "institutions":
        this.setState({
          isShowInstitutionsTypeModal: true,
        });
        break;
      case "institutions_deal":
        this.setState({
          isShowInstitutionsDealTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowPublicationModal: false,
      isShowPublicationTypeModal: false,
      isShowContentTypeModal: false,
      isShowInstitutionsTypeModal: false,
      isShowInstitutionsDealTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "publications_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText = `${fieldValues.length} Publication type selected`;
        } else {
          labelText = `All publications type`;
        }
        break;

      case "institutions":
        if (
          this.state.institutionList &&
          this.state.institutionList.length > 0
        ) {
          labelText =
            this.state.institutionList &&
              this.state.institutionList.length === 1
              ? `${this.state.institutionList &&
              this.state.institutionList.length
              } institution selected`
              : `${this.state.institutionList &&
              this.state.institutionList.length
              } institutions selected`;
        } else {
          labelText = `All institutions selected`;
        }
        break;

      case "funders":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} funder selected`
              : `${fieldValues.length} funders selected`;
        } else {
          labelText = `All funders selected`;
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText = `${fieldValues.length} content types selected`;
        } else {
          labelText = `All content types selected`;
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} publication selected`
              : `${fieldValues.length} publications  selected`;
        } else {
          labelText = `All publications selected`;
        }
        break;

      case "institutions_deal":
        if (fieldValues && fieldValues.length > 0) {
          let deals =
            this.state.institution_deal_List &&
            this.state.institution_deal_List.find((item) =>
              this.state[fieldName].includes(item.id)
            );
          labelText = `${deals ? deals.name : "institutions"} selected`;
        } else {
          labelText = `All institutions deal selected`;
        }
        break;
      default:
        break;
    }

    return labelText;
  };
  onSaveSelectedItems = async (fieldName, data) => {
    this.setState({
      [fieldName]: data,
    });
  };
  saveDesign = (data) => {
    alert(data);
    this.editor.saveDesign((design) => { });
  };
  exportHtml = () => {
    this.editor &&
      this.editor.exportHtml((data) => {
        this.emailFormSubmit({
          html: data.chunks.body,
          body: JSON.stringify(data.design),
        });
      });
  };

  onLoad = () => {
    this.isEditorLoaded = true;
    this.loadTemplate();
  };
  loadTemplate = () => {
    if (!this.isEditorLoaded || !this.isComponentMounted) return;

    if (this.state.id) {
      let data = JSON.parse(this.state.html_json);
      let editor_json = data&& data.body ? { body: data.body } : null;
      this.editor.loadDesign(editor_json);
    } else {
      let data;
      if (this.state.html_default_json !== undefined ) {
        try {
          let default_json = this.state.html_default_json
          data = JSON.parse(default_json);

          let editor_default_json = data && data.body ? { body: data.body } : null;
          this.editor.loadDesign(editor_default_json);
        } catch (error) {
        }
      }
    }
  };
  onDesignLoad = (value) => { };
  onSaveSelectedInstItems = (members) => {
    this.setState({
      institutions: members && members.map((item) => item.id),
      institutionList: members,
    });
  };
  render() {
    const {
      institutionList,
      publicationTypeList,
      defaultEmailMessage,
    } = this.state;

    
    return (
      <HubContent>
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.push("/settings/email-configuration")}
                />
              </div>
              <div className="card-body email-configure">
                <div className="tab-content">
                  <form action="">
                    <div className="form-group row">
                      <InputBox
                        className="col-sm-9 col-md-10"
                        labelClass="col-sm-3 col-md-2"
                        label="Title"
                        labelInfo={true}
                        labelInfoTitle="Enter Email Title"
                        onChange={this.onTextFieldChange}
                        field="title"
                        placeholder="Enter title"
                        defaultValue={this.state.title}
                      />
                    </div>
                    <div className="form-group row">
                      <InputBox
                        className="col-sm-9 col-md-10"
                        labelClass="col-sm-3 col-md-2"
                        label="Description"
                        labelInfo={true}
                        labelInfoTitle="Enter Email Description"
                        onChange={this.onTextFieldChange}
                        field="description"
                        placeholder="Enter description"
                        defaultValue={this.state.description}
                      />
                    </div>

                    <div className="form-group row">
                      
                      <div className="col-12 col-sm-6 col-md-4 py-3 model-class">
                        {this.showSelectItemsLabel("publications_types")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publications_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowDisciplineModal && (
                        <ModalSelectBox
                          label="Publications types"
                          field="publications_types"
                          modalClass="col-10 col-sm-10 col-md-8 col-lg-6"
                          labelClass="col-sm-4"
                          className="col-sm-8"
                          data={
                            (publicationTypeList && [
                              { id: "all", name: "All Publications types" },
                              ...publicationTypeList,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.publications_types}
                          isRequired={false}
                          singleField="singleField"
                          submitBtnTxt="Save"
                          onSubmit={this.handePublishersBasedonParent}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-12 col-sm-6 col-md-4 py-3 py-3 model-class">
                        {this.showSelectItemsLabel("publications")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publications")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationTypeModal && (
                        <ModalSelectBox
                          label="publications"
                          field="publications"
                          modalClass="col-10 col-sm-10 col-md-8 col-lg-6"
                          labelClass="col-sm-4"
                          className="col-sm-8"
                          data={this.state.publicationList || []}
                          defaultValue={this.state.publications}
                          isRequired={true}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-12 col-sm-6 col-md-4 py-3 model-class">
                        {this.showSelectItemsLabel("institutions")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("institutions")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowInstitutionsTypeModal && (
                        <ModalAutoComplete
                          modalClass="col-10 col-sm-10 col-md-8 col-lg-6"
                          labelClass="col-sm-4"
                          className="col-sm-8"
                          defaultValue={this.state.institutions}
                          isRequired={false}
                          submitBtnTxt="Save"

                          placeholder="Type your full institution name and select from the drop down"
                          members={institutionList}
                          onSubmit={this.onSaveSelectedInstItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />

                      )}
                      <div className="col-12 col-sm-6 col-md-4 py-3 model-class">
                        {this.showSelectItemsLabel("funders")}
                        <button
                          onClick={() => this.onModalSelectBoxShow("funders")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationModal && (
                        <ModalSelectBox
                          label="Funders"
                          field="funders"
                          modalClass="col-10 col-sm-10 col-md-8 col-lg-6"
                          labelClass="col-sm-4"
                          className="col-sm-8"
                          data={this.state.funderList}
                          defaultValue={this.state.funders}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <div className="col-12 col-sm-6 col-md-4 py-3 model-class">
                        {this.showSelectItemsLabel("institutions_deal")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("institutions_deal")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowInstitutionsDealTypeModal && (
                        <ModalSelectBox
                          title="Select Institutions Deal"
                          label="Institutions Deal"
                          field="institutions_deal"
                          modalClass="col-10 col-sm-10 col-md-8 col-lg-6"
                          labelClass="col-sm-4"
                          className="col-sm-8"
                          data={this.state.institution_deal_List || []}
                          defaultValue={this.state.institutions_deal}
                          submitBtnTxt="Save"
                          singleField="singleField"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    <div className="col-12 col-sm-6 col-md-4 py-3 model-class">
                        {this.showSelectItemsLabel("content_types")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("content_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowContentTypeModal && (
                        <ModalSelectBox
                          label="Content types"
                          field="content_types"
                          modalClass="col-10 col-sm-10 col-md-8 col-lg-6"
                          labelClass="col-sm-4"
                          className="col-sm-8"
                          data={
                          [
                              { id: "all", name: "All Content types" },
                              ...this.state.content_types_list,
                            ]
                          }
                          defaultValue={this.state.content_types}
                          isRequired={false}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                      <div className="col-12 col-sm-6 col-md-4 py-3 model-class d-flex align-items-center">
                        <label className="waiver_paragraph">
                          Only for waiver Countries
                        </label>
                        <Radio.Group
                          onChange={this.onChangeRadioButtonHandler}
                          value={this.state.only_for_waiver_countries}
                        >
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </div>
                    </div>

                    <div className="form-group row">
                      <InputBox
                        className="col-md-10"
                        label="Subject"
                        labelInfo={true}
                        labelInfoTitle="Enter Email Subject"
                        onChange={this.onTextFieldChange}
                        field="subject"
                        placeholder="Enter Email Subject"
                        defaultValue={this.state.subject}
                      />
                    </div>
                    <div className="row d-flex justify-content-center">
                      {defaultEmailMessage &&
                        defaultEmailMessage.parameters &&
                        defaultEmailMessage.parameters.length > 0 &&
                        defaultEmailMessage &&
                        defaultEmailMessage.parameters &&
                        defaultEmailMessage.parameters.map((parameter, index) => {
                          return (
                            <div key={index}>
                              <Badge
                                style={{ padding: "0.25rem 0.5rem" }}
                                label={parameter.name}
                              />
                            </div>
                          );
                        })}
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <EmailEditor
                          ref={(editor) => (this.editor = editor)}
                          onLoad={this.onLoad}
                          onDesignLoad={this.onDesignLoad}
                        />
                      </div>
                    </div>

                    <div></div>

                    <div className="text-center m-t-15">
                      <button
                        onClick={() => this.exportHtml()}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  publication_list: state.institutions.publication_list,
});

const mapDispatchToProps = (dispatch) => ({
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  createEmailMessage: (payload) => dispatch(createEmailMessage(payload)),
  updateEmailMessage: (payload, id) =>
    dispatch(updateEmailMessage(payload, id)),
});

export const EmailForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailModal));
