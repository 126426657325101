import config, {
  GET_INSTITUTION_LIST,
  GET_PUBLICATION_LIST,
  GET_OADEAL_FILTER_LIST,
  GET_PUBLICATION_LIST_NEW,
  GET_DEPARTMENT_LIST,
  GET_REQUEST_ACTION,
} from "../../config";
import { api } from "../../core/api";
import axios from "axios";
// Loaded into App Reducers
export const getInstitutions = (payload) => ({
  type: GET_INSTITUTION_LIST,
  payload,
});

export const getPublications = (payload) => ({
  type: GET_PUBLICATION_LIST,
  payload,
});

export const getPublicationListAction = (payload) => ({
  type: GET_PUBLICATION_LIST_NEW,
  payload,
});

export const getdepartments = (payload) => ({
  type: GET_DEPARTMENT_LIST,
  payload,
});
export const getoadeal = (payload) => ({
  type: GET_OADEAL_FILTER_LIST,
  payload,
});
export const requestInstAction = (payload) => ({
  type: GET_REQUEST_ACTION,
  payload,
});

// Get Organization List
export const getoaDealFilterList = (payload) => {
  return (dispatch) => {
    const url = config.endpoint.oa_deal_filter;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getoadeal(res));
          // dispatch(orgListCount(res.count))
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Journalanization List
export const getInstitutionsList = (payload) => {
  let url = `${config.endpoint.institutions}?fields=id,name&page_size=1000`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getInstitutions(res.results));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};

export const getInstitutionsLists = (payload) => {
  let url = config.endpoint.institution + `?fields=id,name&page_size=100`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getInstitutions(res.results));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};

let cancelToken;
export const getInstitutionDataLists = (searchTerm) => {
  return async (dispatch, getState) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    try {
      dispatch(requestInstAction());

      const results = await axios.get(
        `${config.baseURL}${config.endpoint.institution}?fields=id,name&q=${searchTerm}&page_size=10`,
        { cancelToken: cancelToken.token } //Pass the cancel token to the current request
      );
      dispatch(getInstitutions(results.data.results));
    } catch (error) {}
  };
};

export const getPubsByFiltering = (payload) => {
  const filter = payload ? payload.filter || "" : "";
  const url = config.endpoint.pubdb + `?${filter}`;
  return (dispatch, getState) => {
    dispatch(requestInstAction({}));

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getInstitutions(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};

export const getUnivByFiltering = (payload) => {
  const filter = payload ? payload.filter || "" : "";
  const url = config.endpoint.unidb + `?${filter}`;
  return (dispatch, getState) => {
    dispatch(requestInstAction({}));

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getInstitutions(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};
export const getPublicationFiltering = (payload) => {
  const filter = payload ? payload.filter || "" : "";
  const url = config.endpoint.publication_type + `?${filter}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getPublications(res.results));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};
export const getOaTokenPublicationList = (payload) => {
  let url = `${config.endpoint.publication}?fields=id,name&q=${payload}`;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getPublicationListAction(res.results));
          resolve(Object.assign(res.results, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};
export const getPublicationList = (payload) => {
  const filter = payload ? payload.filter || "" : "";
  // const url = config.endpoint.publication + `?${filter}`;
  let url = `${config.endpoint.publication}?fields=id,name&${filter}`;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getPublicationListAction(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};

export const getPublicationListAll = (payload) => {
  //   const filter = payload ? payload.filter || "" : "";
  const url = config.endpoint.publication + `?page_size=99999`;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getPublicationListAction(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};

export const getDepartByFiltering = () => {
  // const filter = payload ? payload.filter || "" : "";
  const url = config.endpoint.department + `?fields=id,name&page_size=999999`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getdepartments(res.results));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};

// check organisation admin
export const checkOrganisationAdmin = (id) => {
  return (dispatch) => {
    let url = `${config.endpoint.check_organisation_admin}${id}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
