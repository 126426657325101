import React from "react";
import {
    Button
} from "../../../../../core/components/common";
class AuthorMessage extends React.Component {
    state = {
    }

    onCloseModal = () => {
        this.props.onClose();
    };

    render() {
        return (
            <div style={modal.overlay}>
                <div className={`col-sm-5 bg-white alert`} style={modal.boxStyle}>
                    <div className="vat-tax-button email">
                        <Button
                            type="danger"
                            name="danger"
                            text="X"
                            onClick={() => this.onCloseModal()}
                        />
                    </div>
                    <p className="email-non-request">
                    The following message has been sent from <strong> {this.props.author}</strong>. You will find this message in Audit as an email format.
</p>
                    <div
                        dangerouslySetInnerHTML={{ __html: this.props.note }}
                    />
                </div>
            </div >
        );
    }
}
let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
    boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
    boxHeight = "-webkit-fit-content";
}

const modal = {
    overlay: {
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.64)",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 1,
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
    },
    boxStyle: {
        transform: "translate(20px, 100px)",
        height: boxHeight,
        padding: 30,
    },
    error: { border: "1px solid red" },
};




export default AuthorMessage;
