import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { UserForm } from '../../../partials';
import { ClearStatus, UpdateUser, getSingleUser } from '../../../../actions';
import { PopupBox, Loading } from '../../../../../core/components/common';

class Add extends BaseComponent {
    constructor(props) {
        super(props)
        this.error = props.failed;
        this.status = 100;
    }
    state = {
        user: false,
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        isEmpty: false
    }

    async componentDidMount(){
        let user;
        const userId = this.props.match.params.id;
        user = await this.props.getUser(userId);

        if (!user) {
            user = await this.props.getSingleUser(userId);
        }
        delete user.request_source
        delete user.organisation_name
        delete user.group_name
        delete user.role_name
        this.setState({
            user
        })
    }

    componentDidUpdate() {
        this.state.msg && setTimeout(() => {
            this.setState({
                msg: false
            })
        }, 2000)

        if (this.props.failed) {
            if (this.props.failed.status === 500 && this.state.status !== 500) {
                this.setState({
                    status: 500
                })
            } else if (this.props.failed.status === 400 && this.state.status !== 400) {
                this.setState({
                    status: 400
                })
            }
        }
    }

    onSubmitHandler = async (user) => {
        if (user.email && user.first_name && user.last_name && user.role && user.organisation && user.group) {
            this.setState({
                isRegistering: true,
                msg: false
            })
            const data = (Object.assign(user, { request_source: "hub" }));
            const new_user = await this.props.UpdateUser(data);
            if (new_user.status === 201 || new_user.status === 200) {
                this.props.history.push('/user/user-list')

                this.setState({
                    isRegistering: false,
                    msg: 'User Updated',
                    user: {}
                })
            } else {
                this.setState({
                    msg: "Timeout"
                })
            }
        } else {
            this.setState({
                isEmpty: <div>
                    {!user.email && <p>Email Cannot Be Empty!</p>}
                    {!user.first_name && <p>Firstname Cannot Be Empty</p>}
                    {!user.last_name && <p>Lastname Cannot Be Empty</p>}
                    {!user.role && <p>Role Cannot Be Empty</p>}
                    {!user.group && <p>Group Cannot Be Empty</p>}
                    {!user.organisation && <p>Organization Cannot Be Empty</p>}
                </div>
            })
        }
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        })
        await this.props.ClearStatus()
    }


    render() {
        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Invalid form"
                msg="You submitted the form is not valid. Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>

        let { user } = this.props.location.state;

        return (
            <HubContent title={`Edit User Info - #${this.props.match.params.id}`}>
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}

                {/* LoadinG Handling */}
                {this.state.isRegistering && !this.state.status && <Loading />}
                {/* LoadinG Handling */}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Edit User</a>
                                    </li>
                                </ul>

                                {user && <UserForm
                                    onSubmitHandler={this.onSubmitHandler}
                                    msg={this.state.msg}
                                    user={user}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    failed: state.user.failed || false,
    getUser: (userId) => {
        return state.user.users ? state.user.users.find((user) => {
            return user.id.toString() === userId.toString() && user;
        }) : false;
    }
})

const mapDispatchToProps = (dispatch) => ({
    UpdateUser: (payload) => dispatch(UpdateUser(payload)),
    ClearStatus: () => dispatch(ClearStatus()),
    getSingleUser: (id) => dispatch(getSingleUser(id))
})

export const EditUser = connect(mapStateToProps, mapDispatchToProps)(Add);