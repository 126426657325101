import React from 'react';
import { connect } from 'react-redux';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { OrgSingle } from '../../_multiaccess';

// This Component is onfigured By Shahan
class Institution extends BaseComponent {
  render() {
    return (
      <OrgSingle
        OrgID={this.props.match.params.id}
        type="publisher"
      />
    )
  }
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})


export const InstitutionSingle = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Institution)