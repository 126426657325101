import React, { useState, useEffect } from "react";
import { Table, Input, InputNumber, Popconfirm, Form ,Button} from "antd";
import { EditOutlined } from '@ant-design/icons';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber min={0}  /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
          children
        )}
    </td>
  );
};

const EditableTable = ({ editHandler, data, countryType,field }) => {
  const [form] = Form.useForm();
  const [fieldName] = useState(countryType ||field );
  const [tableData, setData] = useState(data);
  const [editingKey, setEditingKey] = useState("");

  useEffect(() => {
    setData(data);
  }, [data]);
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.key);
      editHandler(newData);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
        editHandler(newData);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
        editHandler(newData);
      }
    } catch (errInfo) {
    }
  };
  let column =
  fieldName === "USA"
      ? [
        {
          title: "Country",
          dataIndex: "country_name",
          width: 150,
        },
        {
          title: "Federal Rate",
          dataIndex: "federal_vat_rate",
          width: 150,
        },
        {
          title: "States",
          dataIndex: "states_name",
          width: 200,
        },
        {
          title: "State Rate",
          dataIndex: "state_provincial_vat_rate",
        },
        {
          title: "Default VAT %",
          dataIndex: "total_rates",
        },
        {
          title: "Set your own VAT rate %",
          dataIndex: "vat_rate",
          width: "25%",
          editable: true,
        },
        {
          title: "Actions",
          dataIndex: "Actions",
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>

                
                  <Button type="primary"onClick={() => save(record.key)}        style={{
                    marginRight: 8,
                  }}>Save</Button>

                
                <Popconfirm okText="Yes" cancelText="No" title="are you want to cancel update VAT" onConfirm={cancel}>
                  <Button type="danger">Close</Button>

                </Popconfirm>
              </span>
            ) : (
                       
                <Button disabled={editingKey !== ""}
                  
                  type="ghost" icon={<EditOutlined />} onClick={() => edit(record)}
              >Update</Button>

            
        
              );
          },
        },
      ]
      : fieldName === "CA"
        ? [
          {
            title: "Country",
            dataIndex: "country_name",
            width: 150,
          },
          {
            title: "PST Rate",
            dataIndex: "federal_vat_rate",
            width: 150,
          },
          {
            title: "Province",
            dataIndex: "states_name",
            width: 200,
          },
          {
            title: "Province Rate",
            dataIndex: "state_provincial_vat_rate",
          },
          {
            title: "Default VAT %",
            dataIndex: "total_rates",
          },
          {
            title: "Set your own VAT rate %",
            dataIndex: "vat_rate",
            editable: true,
          },

          {
            title: "Actions",
            dataIndex: "Actions",
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                
                <Button type="primary"onClick={() => save(record.key)}        style={{
                    marginRight: 8,
                  }}>Save</Button>

<Popconfirm okText="Yes" cancelText="No" title="are you want to cancel update VAT" onConfirm={cancel}>
                  <Button type="danger">Close</Button>

                </Popconfirm>
                </span>
              ) : (
                <Button disabled={editingKey !== ""}
                  
                  type="ghost" icon={<EditOutlined />} onClick={() => edit(record)}
              >Update</Button>

                );
            },
          },
        ]
        : fieldName === "REST"
          ? [
            {
              title: "Country",
              dataIndex: "country_name",
              width: 150,
            },
          ]
         : fieldName === "RESTOFTHEWORLD"
          ? [
            {
              title: "Country",
              dataIndex: "country_name",
              width: 150,
            },
            {
              title: "Default VAT %",
              dataIndex: "default_vat_rate",
              width: 150,
            },
          ]
          :  [
              {
                title: "Country",
                dataIndex: "country_name",
                width: 150,
              },
              {
                title: "Default VAT %",
                dataIndex: "default_vat_rate",
                width: 150,
              },
              {
                title: "Set your own VAT rate %",
                dataIndex: "vat_rate",
                editable: true,
              },
              {
                title: "Actions",
                dataIndex: "Actions",
                render: (_, record) => {
                  const editable = isEditing(record);
                  return editable ? (
                    <span>
                
                <Button type="primary"onClick={() => save(record.key)}        style={{
                    marginRight: 8,
                  }}>Save</Button>
           <Popconfirm okText="Yes" cancelText="No" title="are you want to cancel update VAT" onConfirm={cancel}>
                  <Button type="danger">Close</Button>

                </Popconfirm>
                    </span>
                  ) : (
                    <Button disabled={editingKey !== ""}
                  
                    type="ghost" icon={<EditOutlined />} onClick={() => edit(record)}
                >Update</Button>
  
                    );
                },
              },
            ];
  let mergedColumns = column.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "vat_rate" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        style={{
          border: "1px solid",
          padding:10
        }}
        bordered
        dataSource={tableData}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          showSizeChanger: false,
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default EditableTable;
