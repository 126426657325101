import React from "react";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";

class MultSelectCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      arrayValue: this.props.array ? this.props.array : [],
    };
    this.selectOption = this.selectOption.bind(this);
    this.selectMultipleOption = this.selectMultipleOption.bind(this);
  }

  selectOption(value) {
    this.setState({ value });
  }
  selectMultipleOption(value) {
    this.setState({ arrayValue: value });
    this.props.selectCountry(value);
  }

  render() {
    const { field } = this.props;
    return (
        <Picky
          value={this.state.arrayValue}
          options={this.props.data}
          onChange={this.selectMultipleOption}
          open={false}
          valueKey="id"
          labelKey={field === "STATES" ? "name" : "country_name"}
          multiple={true}
          includeSelectAll={true}
          includeFilter={true}
          placeholder={"No countries selected"}
          allSelectedPlaceholder={"All countries selected"}
          manySelectedPlaceholder={"You have selected %s countries"}
        dropdownHeight={150}
        filterPlaceholder="Please type your VAT Country Name"

        />
    );
  }
}

export default MultSelectCheckbox;
