import React from "react";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import { connect } from "react-redux";
import { GetAllArticles, ReviewArticle } from "../../../../actions";
class Article extends BaseComponent {
  state = {
    review: false
  };
 componentDidMount() {
    this.getArticleBasicData()
  }
  getArticleBasicData = async (pageNum = 1, page = 10) => {
    await this.props.GetArticles({ pageNum: pageNum, pageSize: page });
}

pageChange = (pageNumber) => {
    this.GetArticles(pageNumber);
}

  onEditUser = () => {
  
}
  render() {
    let heading = [
      "title",
      "doi",
      "article_id",
      "author_name",
      "PISSN",
      "EISSN"
    ];
    let TableData = [];
    if (this.props.articles) {
      this.props.articles.map(data => {
        return TableData.push({
          id: data.id,
          title: data.title,
          doi: data.doi,
          article_id: data.article_id,
          author_name:
            data.author &&
            `${data.author.first_name || ""} ${data.author.last_name || ""}`,
          // journal_name: data.journal_name,
          PISSN: data.pissn,
          EISSN: data.eissn
        });
      });
    }

    return (
      <HubContent title="Article Basic">


        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.props.articles && (
                <DataTable
                  heading={heading}
                  data={TableData}
                  onEdit={this.onEditUser}
                  onDelete={this.onDeleteUser}
                  onView={this.onViewUser}
                  count={this.props.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
          {/* <!-- end col --> */}
        </div>
        {/* <!-- end row --> */}
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  articles: state.articles.article_list
});

const mapDispatchToProps = dispatch => ({
  GetArticles: (payload) => dispatch(GetAllArticles(payload)),
  ReviewArticle: () => dispatch(ReviewArticle())
});

export const ArticleBasic = connect(
  mapStateToProps,
  mapDispatchToProps
)(Article);
