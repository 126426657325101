import React from 'react';
import BaseComponent from '..';
import moment from 'moment';
import { OaDatePicker, OaSwitch, SelectBox  } from './';

const format = 'Do MMM YYYY';

class TableRow extends BaseComponent { 

    state = {
        organisationList: this.props.organisationList ? this.props.organisationList : [],
        counter_organisation: this.props.item ? this.props.item.counter_organisation : false,
        enabled: this.props.item ? this.props.item.enabled : false,
        deal_type: this.props.item ? this.props.item.deal_type : false,
        publication_type: this.props.item ? this.props.item.publication_type : false,
        expiry_time: this.props.item && this.props.item.expiry_time ? moment(this.props.item.expiry_time).format() : moment(new Date()),
    }

    capitalize = (s) => { 
        if (s.trim() === '') return s;
        return s[0].toUpperCase() + s.slice(1);
    }

    onChange = (value) => { 
        this.setState(value, () => {
            if (value.hasOwnProperty('enabled')) {
                let id = this.props.item.id;
                let data = this.state;
                Object.assign(data, { 
                    id: id,
                    organisation: this.props.item.organisation,
                    deal_type_name: this.props.item.deal_type_name,
                });
                
                if (data.enabled && !data.expiry_time) { 
                    let msg = <p>Expiry date must be selected.</p>;

                    this.props.onErrorHandler(msg);
                } else { 
                    this.props.onSubmitHandler(data);
                }                
            } else {
                this.setState({
                    enabled: false,
                });
            }
        });
               
    }

    nameCapitalize = (items) => { 
        try {
            return items.map((item, index) => {
                item.name = this.capitalize(item.name);
                return item;
            });
        } catch (error) {
        }
        return [];
    }

    render() {
        let { deal_type, publication_type, counter_organisation, enabled, expiry_time, } = this.state;
        
        return(
                <tr>
                    <td>
                        {!this.props.isNew && this.props.item && this.capitalize(this.props.item.deal_type_name)}
                        
                        {this.props.isNew && <SelectBox
                            label={false}
                            className="col-md-12 pl-0"
                            onChange={this.onChange}
                            field='deal_type'
                            data={this.nameCapitalize(this.props.dealTypeList)}
                            defaultValue={deal_type}
                        />}
                    </td>
                    <td>
                        {!this.props.isNew && this.props.item && this.capitalize(this.props.item.publication_type_name)}
                        
                        {this.props.isNew && <SelectBox
                            label={false}
                            className="col-md-12 pl-0"
                            onChange={this.onChange}
                            field='publication_type'
                            data={this.props.publicationTypeList}
                            defaultValue={publication_type}
                        />}
                    </td>
                    <td>
                        <SelectBox
                            label={false}
                            className="col-md-12 pl-0"
                            onChange={this.onChange}
                            field='counter_organisation'
                            data={this.props.organisationList}
                            defaultValue={counter_organisation}
                        />
                    </td>
                    <td className="text-left">
                        <OaDatePicker
                            className="col-sm-12 pl-0"
                            label={false}
                            field="expiry_time"
                            onChange={this.onChange}
                            placeholder="Select expiry time"
                            showTime={false}
                            format={format}
                            defaultValue={expiry_time ? moment(expiry_time) : moment(new Date(), format)}
                        />
                    </td>
                    <td className="text-left">
                        <OaSwitch 
                            containerClassName="col-sm-12 pl-0"
                            field="enabled" 
                            defaultChecked={enabled || false}
                            disabled={!counter_organisation || !deal_type || !publication_type || !expiry_time}
                            checked={enabled || false}
                            onChange={this.onChange}
                        />
                    </td>
                </tr>
        )
    }
}

export const AutoApprovalTableRow = TableRow;