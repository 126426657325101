import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { Link } from "react-router-dom";
import {
  getArticleTabControlConfigList,
  deleteArticleTabControlConfig,
} from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";

class UL extends BaseComponent {
  state = {
    data: [],
  };

  getArticleTabControlConfigListData = async (pageNum = 1, page = 10) => {
    await this.props.getArticleTabControlConfigList({
      pageNum: pageNum,
      pageSize: page /* has_df_only: true, */,
    });

    this.setState({
      data: this.props.article_tab_configs.results,
      count: this.props.article_tab_configs.count,
    });
  };

  async componentDidMount() {
    this.getArticleTabControlConfigListData();
  }

  pageChange = (pageNumber) => {
    this.getArticleTabControlConfigListData(pageNumber);
  };

  onEditArticleTabControlConfig = (id) => {
    this.props.history.push("/article-tab-configs/edit/" + id);
  };

  onDeleteArticleTabControlConfig = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteArticleTabControlConfig(id).then((res) => {
              if (res.status) {
                this.props.history.push("/article-tab-configs/list");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const heading = ["organisation_name", ];

    return (
      <HubContent title="Article tab configuration list">
        <div className="row">
          <div className="col-12">
            <Link
              to="/article-tab-configs/add"
              className="btn btn-primary float-right mb-2"
            >
              Add article tab configuration
            </Link>
          </div>
        </div>
        <div className="row filter-md-4">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditArticleTabControlConfig}
                  onDelete={this.onDeleteArticleTabControlConfig}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  article_tab_configs: state.ArticleTabControlConfig.article_tab_configs || false,
});

const mapDispatchToProps = (dispatch) => ({
  getArticleTabControlConfigList: (payload) =>
    dispatch(getArticleTabControlConfigList(payload)),
  deleteArticleTabControlConfig: (id) => dispatch(deleteArticleTabControlConfig(id)),
});

export const ArticleTabControlConfigList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UL);
