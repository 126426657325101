import { 
    GET_ORGANISATION_DEAL_FEATURE_LIST, 
    GET_ORGANISATION_DEAL_FEATURE, 
    CREATE_UPDATE_ORGANISATION_DEAL_FEATURE, 
    DELETE_ORGANISATION_DEAL_FEATURE,
    UPLOAD_ORGANISATION_DEAL_FEATURE,
    GET_OA_DEAL_FEATURE_LIST,
} from "../../config";

const OrganisationDealFeatureReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ORGANISATION_DEAL_FEATURE_LIST: 
            return state = {
                ...state,
                organisation_deal_features: action.payload
            }
        case GET_OA_DEAL_FEATURE_LIST: 
            return state = {
                ...state,
                oa_deal_features: action.payload
            }
        case GET_ORGANISATION_DEAL_FEATURE: 
            return state = {
                ...state,
                organisation_deal_feature: action.payload
            }
        case CREATE_UPDATE_ORGANISATION_DEAL_FEATURE: 
            return state = {
                ...state,
                organisation_deal_feature: action.payload
            }
        case DELETE_ORGANISATION_DEAL_FEATURE: 
            return state = {
                ...state,
            }
        case UPLOAD_ORGANISATION_DEAL_FEATURE: 
            return state = {
                ...state,
                organisation_deal_feature_file_upload: action.payload,
            }
        default:
            return state;
    }
}

export default OrganisationDealFeatureReducer;
