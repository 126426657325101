import config, {
	GET_ALL_OA_POLICY,
	GET_ALL_OA_REQUIREMENT,
	POST_OA_POLICY,
	POST_OA_REQUIREMENT,
	DELETE_JOURNAL_DATA,
	SINGLE_OA_POLICY,
	SINGLE_OA_REQUIREMENT,
} from "../../config";
import { api } from "../../core/api";

// Loaded into App Reducers
export const getOaPolicy = (payload) => ({
	type: GET_ALL_OA_POLICY,
	payload,
});

// Clearing failer status
export const deleteJournalData = (id) => ({
	type: DELETE_JOURNAL_DATA,
	id,
});

// reducerCall oa requirement
export const getOaRequirement = (payload) => ({
	type: GET_ALL_OA_REQUIREMENT,
	payload,
});

// reducerCall post oa policy
export const postPolicy = (payload) => ({
	type: POST_OA_POLICY,
	payload,
});
// reducerCall post oa requirement
export const postRequirement = (payload) => ({
	type: POST_OA_REQUIREMENT,
	payload,
});
// reducerCall put oa policy
export const putPolicy = (payload) => ({
	type: POST_OA_POLICY,
	payload,
});
// reducerCall put oa requirement
export const putRequirement = (payload) => ({
	type: POST_OA_REQUIREMENT,
	payload,
});

// reducerCall single oa policy
export const singlePolicy = (payload) => ({
	type: SINGLE_OA_POLICY,
	payload,
});
// reducerCall single oa requirement
export const singleRequirement = (payload) => ({
	type: SINGLE_OA_REQUIREMENT,
	payload,
});

// Create oa policy publisher
export const createOaPublisherPolicy = (payload) => {
	return (dispatch) => {
		let url = config.endpoint.oa_publisher_policy;
		return new Promise((resolve, reject) => {
			api.post(url, payload)
				.then((res) => {
					dispatch(postPolicy(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((error) => {
					reject(Object.assign(error, { status: false }));
				});
		});
	};
};

// Create oa policy publisher
export const createOrganisationRequirement = (payload) => {
	return (dispatch) => {
		let url = config.endpoint.organisation_requirement;
		return new Promise((resolve, reject) => {
			api.post(url, payload)
				.then((res) => {
					dispatch(postRequirement(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((error) => {
					reject(Object.assign(error, { status: false }));
				});
		});
	};
};

// Get Organization List
export const getOaPolicyAll = () => {
	return (dispatch) => {
		let url = `${config.endpoint.oa_policy}?page_size=999999`;
		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getOaPolicy(res.results));
					resolve(res.results);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Get Oa Policy List
export const getOaPolicyList = (payload) => {
	return (dispatch) => {
		let url = payload
			? `${config.endpoint.oa_publisher_policy}?page=${payload.pageNum}&page_size=${payload.pageSize}`
			: config.endpoint.oa_publisher_policy;
		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getOaPolicy(res));
					resolve(res);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Get Oa Policy Single
export const getOaPolicySingle = (payload) => {
	return (dispatch) => {
		let url = payload
			? `${config.endpoint.oa_publisher_policy}${payload.id}`
			: config.endpoint.oa_publisher_policy;
		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(singlePolicy(res));
					resolve(res);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};
// Get Oa Requirement Single
export const getOaRequirementSingle = (payload) => {
	return (dispatch) => {
		let url = payload
			? `${config.endpoint.organisation_requirement}${payload.id}`
			: config.endpoint.oa_publisher_policy;
		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(singleRequirement(res));
					resolve(res);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};
// Delete Oa Policy List
export const deleteOaPolicy = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			let url = `${config.endpoint.oa_publisher_policy}${id}`;
			return api
				.delete(url)
				.then(
					(res) => {
						dispatch(deleteJournalData(id));
						resolve({ status: true });
					},
					(error) => {
						resolve({ status: false });
					}
				)
				.catch(reject);
		});
	};
};

// Delete Oa Policy List
export const deleteOaRequirement = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			let url = `${config.endpoint.organisation_requirement}${id}`;
			return api
				.delete(url)
				.then(
					(res) => {
						dispatch(deleteJournalData(id));
						resolve({ status: true });
					},
					(error) => {
						resolve({ status: false });
					}
				)
				.catch(reject);
		});
	};
};

export const UpdateOaPolicy = (id, payload) => {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			let url = `${config.endpoint.oa_publisher_policy}${id}`;
			return api
				.put(url, payload)
				.then(
					(res) => {
						dispatch(
							putPolicy(
								Object.assign(res.data, { status: res.status })
							)
						);
						resolve(res.data);
					},
					(error) => {
						resolve(error);
					}
				)
				.catch((error) => {
					resolve(error);
				});
		});
	};
};

export const UpdateOaRequirement = (id, payload) => {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			let url = `${config.endpoint.organisation_requirement}${id}`;
			return api
				.put(url, payload)
				.then(
					(res) => {
						dispatch(
							putRequirement(
								Object.assign(res.data, { status: res.status })
							)
						);
						resolve(res.data);
					},
					(error) => {
						resolve(error);
					}
				)
				.catch((error) => {
					resolve(error);
				});
		});
	};
};

// Get Organization requirement List
export const getOrganisationRequirementList = (payload) => {
	return (dispatch) => {
		let url = payload
			? `${config.endpoint.organisation_requirement}?page=${payload.pageNum}&page_size=${payload.pageSize}`
			: config.endpoint.organisation_requirement;
		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getOaPolicy(res));
					resolve(res);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Get Organization List
export const getOaRequirementAll = () => {
	return (dispatch) => {
		let url = `${config.endpoint.oa_requirement}?page_size=999999`;
		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getOaRequirement(res.results));
					resolve(res.results);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};
