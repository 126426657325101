import React from 'react';
import { connect } from 'react-redux'
import BaseComponent, { HubContent } from '../../../../../core/components';
import { getRefundRequest } from '../../../../actions';
import { Card, Skeleton } from 'antd';

import moment from "moment";

const format = "Do MMM YYYY";

class ViewTable extends BaseComponent {
  state = {
    info: null
  }

  async componentDidMount() {
    let id = this.props.match.params.id;

    await this.props.getRefundRequest(id);
    
    this.setState({
      info: this.props.refund_request
    });
  }

  dateOfDeal = (date) => {
    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  }

  priceofOadeal = (price) => {
    let dataPrice = Number(price).toFixed(2);
    return dataPrice
  }

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  renderDetails = () => {
    let info = this.props.refund_request;

    return (
      info &&
      <div className="row">
        <div className="col-lg-12">
          <table style={styles.table}>
            <tbody>
              <tr style={styles.tr}>
                <td style={styles.td}>Organisation</td>
                <td style={styles.td2}>{info.deal_organisation_name}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Deal Type</td>
                <td style={styles.td2}>{info.deal_name}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Currency</td>
                <td style={styles.td2}>{info.currency_name}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Requested amount</td>
                <td style={styles.td2}>{info.requested_amount}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Accepted amount</td>
                <td style={styles.td2}>{info.accepted_amount}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Approval status</td>
                <td style={styles.td2}>{info.approval_status && this.capitalize(info.approval_status.replace(/-/g, ' '))}</td>
              </tr>
              <tr style={styles.tr}>
                <td style={styles.td}>Request sent on</td>
                <td style={styles.td2}>{ info.date_created && moment(info.date_created).format(format)}</td>
              </tr>
              {info.approve_date && <tr style={styles.tr}>
                <td style={styles.td}>{info.approval_status && this.capitalize(info.approval_status.replace(/-/g, ' '))} on</td>
                <td style={styles.td2}>{ info.approve_date && moment(info.approve_date).format(format)}</td>
              </tr>}
              {info.payment_deposit_date && <tr style={styles.tr}>
                <td style={styles.td}>Payment deposited on</td>
                <td style={styles.td2}>{ info.payment_deposit_date && moment(info.payment_deposit_date).format(format)}</td>
              </tr>}
              {info.payment_receive_date && <tr style={styles.tr}>
                <td style={styles.td}>Payment received on</td>
                <td style={styles.td2}>{ info.payment_receive_date && moment(info.payment_receive_date).format(format)}</td>
              </tr>}
              <tr style={styles.tr}>
                <td style={styles.td}>Note</td>
                <td style={styles.td2}>{info.approval_note}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render() {


    return (
      <HubContent title="Refund request details">
        <Card>
          {this.state.info ? this.renderDetails() :
            <Skeleton
              active
              paragraph={{ row: 10 }}
            />
          }
        </Card>
      </HubContent>
    )
  }
}

const mapStateToProps = (state) => ({
  refund_request: state.RefundRequest.refund_request || false,
})

const mapDispatchToProps = (dispatch) => ({
  getRefundRequest: (id) => dispatch(getRefundRequest(id))
})

export const RefundRequestView = connect(mapStateToProps, mapDispatchToProps)(ViewTable)


const styles = {
  table: {
    borderCollapse: "collapse",
    width: '100%'
  },
  tr: {
    marginTop: 20,
    verticalAlign: 'baseline'
  },
  td: {
    paddingTop: 10
  },
  td2: {
    paddingTop: 10,
    width: '75%'
  }
};
