import React from "react";
import { withRouter } from "react-router-dom";

import BaseComponent from "../BaseComponent";

import { Select, Switch } from "antd";
const { Option } = Select;

class Hub extends BaseComponent {
  state = {};

  onChange = (value) => {
    if (value === "USD") {
      this.setState({
        net_income: this.props.article.data.total_usd_charge,
      });
    } else if (value === "EUR") {
      this.setState({
        net_income: this.props.article.data.total_eur_charge,
      });
    } else if (value === "GBP") {
      this.setState({
        net_income: this.props.article.data.total_gbp_charge,
      });
    } else {
      this.setState({
        net_income: this.props.article.data.total_gbp_charge,
      });
    }
  };

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  currencyCalculation(component) {
    let value = this.numberWithCommas(parseFloat(component).toFixed(2));
    return value;
  }
  onSearch = (val) => {
  };

  render() {
    const props = this.props;
    return (
      <div className={"content-page "}>
        <div className={"content mar-10  " + props.className}>
          <div className="container-fluid">
            <div
              className={`row ${props.dashboardcontrol && "dashboard-control-switch-background"
                }`}
            >
              <div className={props.article ? "col-sm-5" : "col-sm-8"}>
                <div className="page-title-box">
                  {props.title && <h4 className="page-title">{props.title}</h4>}

                  {props.dashboardcontrol && (
                    <div className="dashboard-control-switch-background">
                      <div className="dashboard-control-basic">
                        <h3>Dashboard: {this.props.manage_dashboard && this.props.executed !==true? "Basic" : "Premium" } </h3>
                      </div>
                      <div className="dashboard-control-switch">
                        <Switch
                          checked={this.props.executed}
                          onChange={this.props.onChangeSwitch}
                        />
                      </div>

                      {props.hideSaveBtn && (
                        <div className="dashboard-control-btn">
                          <button
                            type="primary"
                            className="btn btn-primary btn-sm waves-effect"
                            onClick={this.props.handleSaveDesign}
                          >
                            Save Design{" "}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {this.props.manage_dashboard&& props.hideTab && props.estimated_income_hide_summery ? (
                props.article ? (
                  <div className="col-md-7">
                    {props.article ? (
                      <div className="article-title-pending">
                        {
                          <h4>
                            {props.article_title}:
                            <span className="income">
                              {props.article.data.total_pending_article}
                            </span>
                          </h4>
                        }
                      </div>
                    ) : null}
                    {props.article ? (
                      <div className={`${props.className} article-income`}>
                        <h4>{props.article_income_title}:</h4>
                        <div className="dropdown-currency">
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="GBP"
                            optionFilterProp="children"
                            onChange={(props) => this.onChange(props)}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {props.article.data.currencies &&
                              props.article.data.currencies.map(
                                (item, index) => (
                                  <Option value={item} key={index}>
                                    {item}
                                  </Option>
                                )
                              )}
                          </Select>
                        </div>
                        <p className="income m-25">
                          {this.state.net_income
                            ? this.currencyCalculation(this.state.net_income)
                            : this.currencyCalculation(
                              this.props.article.data.total_gbp_charge
                            )}{" "}
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null
              ) : null}
              <div className="col-sm-4">
                {props.button === true ? (
                  <div className="vat-tax-button" style={{ marginTop: 5 }}>
                    <button
                      type="button"
                      className="btn btn-danger btn-md waves-effect float-right mx-1"
                      onClick={() => this.props.history.goBack()}
                    >
                      x
                    </button>
                  </div>
                ) : null}
                {this.props.onAddApcWaiverClickHandler ? (
                  <button
                    style={{ marginTop: 15 }}
                    type="button"
                    className="btn btn-primary btn-md waves-effect float-right mx-1"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.onAddApcWaiverClickHandler();
                    }}
                  >
                    Add APC Waiver
                  </button>
                ) : null}
                {this.props.group !== "INSTITUTION" ? this.props.onUpdatePaymentClickHandler &&  (
                  <button
                    style={{ marginTop: 15 }}
                    type="button"
                    className="btn btn-primary btn-md waves-effect float-right mx-1"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.onUpdatePaymentClickHandler();
                    }}
                  >
                    Update Payment
                  </button>
                ) :null}
                {this.props.onArticleDownloadClickHandler
                  ? this.props.onArticleDownloadClickHandler && (
                    <button
                      style={{ marginTop: 15, marginBottom: 10 }}
                      type="button"
                      className="btn btn-primary btn-md waves-effect float-right mx-1"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onArticleDownloadClickHandler();
                      }}
                    >
                      Download
                    </button>
                  )
                  : null}
                {/* {this.props.group !== "AUTHOR" ?
                  (this.props.onHelpHandler &&
                    <button
                      style={{ marginTop: 15, marginBottom: 10 }}
                      type="button"
                      className="btn btn-primary btn-md waves-effect float-right mx-1"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onHelpHandler();
                      }}
                    >
                      Help
                    </button>)
                  : null
                } */}
              </div>
              <div className="col-md-12">
                {props.subtitle && <p>{props.subtitle}</p>}
              </div>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    );
  }
}

export const HubContent = withRouter(Hub);
