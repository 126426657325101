import React from "react";
import { Pagination } from "antd";

const  PaginationTable =({pageChange,count,pageSize,current})=> {
  let onChangePagination = (pageNumber) => {
    pageChange(pageNumber);
  };
  return (
      
      <Pagination
        style={{
          marginTop: 10,
          marginBottom: 20,
          textAlign: "center",
        }}
        showSizeChanger={ false}
        total={count}
        onChange={onChangePagination}
        pageSize={pageSize}
        current={current}
      />
    );

}
export const PaginationForm = PaginationTable;
