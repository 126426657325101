import React from "react";
import { connect } from "react-redux";
import DragAndDrop from "../../../partials/DragAndDrop";
import validator from "validator";
import CoAuthor  from "./CoAuthorArticle";
import moment from "moment";
import {
  Badge,
  InputNewBox,
  SelectBox,
  OaDatePicker,
  PopupBox,
} from "../../../../../core/components/common";
import { confirmAlert } from "react-confirm-alert";
import {Button} from "antd"
import "react-confirm-alert/src/react-confirm-alert.css";
const BtnText = "Add Article";

class Form extends React.Component {
  state = {
    article: {
      author: {
        first_name: "",
        email: "",
        affiliation: "",

        department: {
          name: "",
          division: {
            name: "",
          },
        },

        last_name: "",
        middle_name: "",
        name: "",
        orcid_id: "",
        pmc_id: "",
        position: "",
        salutation: "",
      },
    },
    SubmitBtnText: BtnText,
    errMsg: false,
    isCoAuthor: false,
    isAuthor: false,
    edit_modal: false,
    co_authors: [],
    author: null,
    editing: false,
    loaded: false,
  };

  componentDidMount() {
    console.log(this.props.article)
    if (this.props.article && !this.state.loaded) {
      let article = {
        vol_name: this.props.article.vol_name,
        vol_number: this.props.article.vol_number,
        vol_pub_year: this.props.article.vol_pub_year,
        title: this.props.article.title,
        article_id: this.props.article.article_id,
        content_type: this.props.article.content_type,
        doi: this.props.article.doi,
        journal_name: this.props.article.journal_name,
        journal_acronym: this.props.article.journal_acronym,
        sub_system_acronym: this.props.article.sub_system_acronym,
        pissn: this.props.article.pissn,
        eissn: this.props.article.eissn,
        publisher_name: this.props.article.publisher_name,
        funder_name: this.props.article.funder_name,
        fund_acknowledgement: this.props.article.fund_acknowledgement,
        grant_number: this.props.article.grant_number,
        note: this.props.article.note,
        source_pub_type_name: this.props.article.source_pub_type_name,
        
      };

      let author = {};

      if (
        this.props.article &&
        this.props.article.author &&
        this.props.article.author.id
      ) {
        author = {
          affiliated_organisation: this.props.article.author.affiliated_organisation,

          first_name: this.props.article.author.first_name,
          email: this.props.article.author.email,
          affiliation: this.props.article.author.affiliation,

          department: {
            name:
              this.props.article.author &&
              this.props.article.author.department &&
              this.props.article.author.department.name,

          },

          last_name: this.props.article.author.last_name,
          middle_name: this.props.article.author.middle_name,
          name: this.props.article.author.name,
          orcid_id: this.props.article.author.orcid_id,
          pmc_id: this.props.article.author.pmc_id,
          position: this.props.article.author.position,
          salutation: this.props.article.author.salutation,
        };
      }

      this.setState({
        submission_date:
        (this.props.article.submission_date &&
          moment( this.props.article.submission_date)) ||
          undefined,
          acceptance_date:
          (this.props.article.acceptance_date &&
            moment( this.props.article.acceptance_date)) ||
            undefined,
        article: article,
        source_pub_type_name: article.source_pub_type_name,
        co_authors:
          (this.props.article &&
            this.props.article.co_authors.map((item) => ({
              ...item,
              affiliation: item.affiliation,
            }))) ||
          [],
        author: author,
        is_author_affiliation_match: this.props.is_author_affiliation_match,
        is_publication_match: this.props.article.is_publication_match,
        is_eissn_match: this.props.article.is_eissn_match,
        is_pissn_match: this.props.article.is_pissn_match,
        is_publisher_name_match: this.props.article.is_publisher_name_match,
        loaded: true,
        SubmitBtnText: "Update",
      });
    }
  }



  onChangeHandler = (value) =>
    this.setState((prevState) => Object.assign(prevState.article, value));
  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
      if (
        (!obj[key] && obj[key] !== 0) ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  };
  onSubmitHandler = async (e) => {
    e.preventDefault();
    let data = {
      ...this.state.article,
      source_pub_type_name: this.state.source_pub_type_name,
      visible_to_publisher: true,
    };

    let author = this.state.author
    if ((author && author.department === null )|| (author && author.department && author.department.name === null )|| (author && author.department && author.department.name === "")) {
      delete author.department
    }
    let co_author =  this.state.co_authors && this.state.co_authors.map((item) => this.removeEmpty(item))

    Object.assign(data, { author: this.state.author });
    Object.assign(data, { co_authors:co_author });
    if (this.state.acceptance_date) {
      Object.assign(data, {
        acceptance_date: moment(this.state.acceptance_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        acceptance_date:null,
      });
    }

    if (this.state.submission_date) {
      Object.assign(data, {
        submission_date: moment(this.state.submission_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        submission_date: null,
      });
    }


    if (!data.title || !data.content_type) {
      this.setState({
        errMsg: (
          <div>
            {!data.title && <p>Title cannot be empty!</p>}
            {!data.content_type && <p>Content name cannot be empty</p>}
            {data.doi && !validator.isURL(data.doi) && (
              <p>URL format is not correct</p>
            )}
            {data.author &&
              data.author.email &&
              !validator.isEmail(data.author.email) && (
                <p>Email format is not correct</p>
              )}
          </div>
        ),
      });
    } else {
      this.setState({
        errMsg: false,
      });
        Object.assign(data, {
          id: this.props.article.id,
        });
        this.props.onSubmitHandler(data);

    }
  };

  cancelLoader = async () => {
    this.setState({
      SubmitBtnText: BtnText,
      errMsg: false,
    });
  };

  AddNewAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isAuthor: true,
    });
  };

  AddNewCoAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isCoAuthor: true,
    });
  };

  onCloseAuthor = () => {
    this.setState({
      isCoAuthor: false,
      isAuthor: false,
      editing: false,
    });
  };

  onSaveCoAuthorHander = (author_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.co_authors[prevState.editing] = author_info;
        // prevState.editing = false;
      } else {
        prevState.co_authors.push(author_info);
      }
      prevState.isCoAuthor = false;
      return prevState;
    });
  };

  onSaveAuthorHander = (author_info) => {
    this.setState((prevState) => {
      prevState.author = author_info;
      prevState.isAuthor = false;
      return prevState;
    });
  };

  onEditAuthor = () => {
    this.setState({
      isAuthor: true,
    });
  };

  onDeleteAuthor = () => {
    this.setState({
      author: null,
    });
  };

  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isCoAuthor: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.co_authors.splice(id, 1);
      return prevState;
    });
  };

  onFileDrop = (payload) => {
    confirmAlert({
      title: "Upload book",
      message: "Are you sure, you want to upload file?",
      buttons: [
        {
          label: "Upload",
          onClick: () => {
            this.props.articleFileUploadHandler(payload);
          },
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
  };

  render() {
    let {
      article,
      is_author_affiliation_match,
      is_publication_match,
      is_eissn_match,
      is_pissn_match,
      is_publisher_name_match,
    } = this.state;

    return (
      <div className="tab-content">
        {this.state.errMsg && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.errMsg}
            onCancel={this.cancelLoader}
          />
        )}

        <div className="tab-pane active p-3" id="home-1" role="tabpanel">
          <div className="form-group row">
            <InputNewBox
              label="Article title"
              onChange={this.onChangeHandler}
              field="title"
              className="col-sm-9 col-lg-10"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Article title"
              isRequired={true}
              defaultValue={article.title}
            />
          </div>
          <div className="form-group row">
            <InputNewBox
              label="Article id"
              onChange={this.onChangeHandler}
              field="article_id"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Article or document id"
              defaultValue={article.article_id}
            />

            <SelectBox
              label="Content type"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="content_type"
              data={this.props.content_type}
              isRequired={true}
              defaultValue={article.content_type}
            />
          </div>
          <div className="form-group row">
            <InputNewBox
              label="Article DOI"
              onChange={this.onChangeHandler}
              field="doi"
              className="col-sm-9 col-lg-10"
              labelClass="col-sm-3 col-lg-2"
              placeholder="doi"
              InputType="url"
              defaultValue={article.doi}
            />
          </div>

          {/* Co Author Form Modal */}
          {this.state.isAuthor && (
            <CoAuthor
              isShow={this.state.isAuthor} // if true modal will show up else it will be hidden
              onClose={this.onCloseAuthor} // Handle Close
              onSaveAuthor={this.onSaveAuthorHander}
              fieldLabel="Author"
              value={this.state.author}
              is_author_affiliation_match={is_author_affiliation_match}
            />
          )}
          {/* Co Author Form Modal End */}
          <div className="form-group row" style={{ marginBottom: 10,marginLeft:100 }}>
            {this.state.author && (
    
                <Badge
                label={
                  !this.state.author.first_name && !this.state.author.last_name
                    ? "No Name"
                    : (this.state.author.first_name || "") +
                    " " +
                    (this.state.author.last_name || "")
                }
                onEdit={this.onEditAuthor}
                onDelete={this.onDeleteAuthor}
                style={
                  is_author_affiliation_match === false ? styles.error : {}
                }
              />
            )}
          </div>


          <div className="form-group row">
            <InputNewBox
              label="Name"
              onChange={this.onChangeHandler}
              field="journal_name"
              className="col-sm-9 col-lg-10"
              labelClass="col-sm-3 col-lg-2"
              placeholder="name"
              defaultValue={article.journal_name}
              style={is_publication_match === false ? styles.error : {}}
            />
          </div>
          {article.is_book && <div className="form-group row">
            <InputNewBox
              onChange={this.onChangeHandler}
              className="col-lg-10 col-sm-9"
              labelClass="col-lg-2 col-sm-3"
              field="vol_name"
              label="Volume Name"
              placeholder="Volume name"
              defaultValue={article.vol_name}
            />
          </div>}
          {article.is_book && <div className="form-group row">
            <InputNewBox
              onChange={this.onChangeHandler}
              className="col-sm-4"
              labelClass="col-sm-3"
              field="vol_number"
              label="Volume No/Year."
              placeholder="Vol. #"
              defaultValue={article.vol_number}
            />
            <InputNewBox
              onChange={this.onChangeHandler}
              field="vol_pub_year"
              className="col-sm-4"
              placeholder="Year"
              defaultValue={article.vol_pub_year}
            />
          </div>}
          <div className="form-group row">
            <InputNewBox
              label="Acronym"
              onChange={this.onChangeHandler}
              field="journal_acronym"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="acronym"
              defaultValue={article.journal_acronym}
            />

            <InputNewBox
              label="Site"
              onChange={this.onChangeHandler}
              field="sub_system_acronym"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="site"
              defaultValue={article.sub_system_acronym}
            />
          </div>
          <div className="form-group row">
            <InputNewBox
              label="pISSN"
              onChange={this.onChangeHandler}
              field="pissn"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="pISSN"
              defaultValue={article.pissn}
              style={is_pissn_match === false ? styles.error : {}}
            />
            <InputNewBox
              label="eISSN"
              onChange={this.onChangeHandler}
              field="eissn"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="eISSN"
              defaultValue={article.eissn}
              style={is_eissn_match === false ? styles.error : {}}
            />
          </div>
          <div className="form-group row">
            <InputNewBox
              label="Publisher Name"
              disabled={true}
              onChange={this.onChangeHandler}
              field="publisher_name"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Publisher Name"
              style={is_publisher_name_match === false ? styles.error : {}}
              defaultValue={article.publisher_name}
            />

            <InputNewBox
              label="Funder"
              onChange={this.onChangeHandler}
              field="funder_name"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              defaultValue={article.funder_name}
              placeholder="Funder"
              style={{}}
            />
          </div>
          <div className="form-group row">
            <InputNewBox
              label="Fund acknowledgement"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="fund_acknowledgement"
              placeholder="fund acknowledgement"
              defaultValue={article.fund_acknowledgement}
            />
            <InputNewBox
              label="Grant number"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="grant_number"
              placeholder="grant number"
              defaultValue={article.grant_number}
            />
          </div>
          <div className="form-group row">


            
           {this.state.submission_date && (
              <OaDatePicker
                label="Submission date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                onChange={this.onChangeHandler}
                field="submission_date"
                placeholder="Select Submission date"
                defaultValue={this.state.submission_date}

                />
            )}
            {!this.state.submission_date && (
              <OaDatePicker
                label="Submission date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                onChange={this.onChangeHandler}
                field="submission_date"
                placeholder="Select Submission date"
              />
            )}

            {this.state.acceptance_date && (
              <OaDatePicker
                label="Acceptance date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                onChange={this.onChangeHandler}
                field="acceptance_date"
                placeholder="Acceptance date"
                defaultValue={this.state.acceptance_date}/>
            )
            }
            {!this.state.acceptance_date && (
              <OaDatePicker
                label="Acceptance date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                onChange={this.onChangeHandler}
                field="acceptance_date"
                placeholder="Acceptance date"
              />
            )}
          </div>
          {/* Article Information End */}
          <div className="form-group row mt-4">
            <div className="col-sm-12" style={{ marginBottom: 10,marginLeft:100 }}>
              <div className="float-left" style={{marginRight: 20}}>
                <h6>Co Author Info: </h6>
              </div>

              <div className="float-left">
                <button
                  className="btn btn-light btn-o-light"
                  onClick={this.AddNewCoAuthor}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="mdi mdi-plus"></i> Add Co Author
                </button>
              </div>
            </div>
          </div>
          {/* Co Author Form Modal */}
          {this.state.isCoAuthor && (
            <CoAuthor
              isShow={this.state.isCoAuthor}
              onClose={this.onCloseAuthor}
              onSaveAuthor={this.onSaveCoAuthorHander}
              fieldLabel="Co author"
              value={this.state.co_authors[this.state.editing]}
            />
          )}
          {/* Co Author Form Modal End */}
          <div className="form-group row" style={{marginLeft:100 }}>
            {this.state.co_authors.length > 0 &&
              this.state.co_authors.map((co_author, id) => {
                return (
                
                  <div key={id}>

                    <Badge
                    label={
                      !co_author.first_name && !co_author.last_name
                        ? "No Name"
                        : (co_author.first_name || "") +
                        " " +
                        (co_author.last_name || "")
                    }
                    onEdit={() => this.onEditCoAuthor(id)}
                    onDelete={() => this.onDeleteCoAuthor(id)}
                  />
                  </div>
             
                );
              })}
          </div>
          <div className="text-center m-t-15">


              <Button type="secondary" loading={this.props.isLoading } onClick={this.onSubmitHandler}>
                {this.props.isLoading !== true ? "Update Article" : "Processing"}
              </Button>

          </div>
        </div>
        <div className="tab-pane p-3" id="profile-1" role="tabpanel">
          <div className="m-b-30">
            <DragAndDrop onDropHandler={this.onFileDrop} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  content_type: state.journals.content_type || null,
  publication_type: state.journals.publication_type || null,
  article_status: state.articles.article_status || null,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
});

const mapDispatchToProps = (dispatch) => ({});

const styles = {
  error: { border: "1px solid red" },
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
