import React from "react";
import { withRouter } from "react-router-dom";
import { Switch } from "antd";
import { connect } from "react-redux";
import {
  SelectBox,
  ModalSelectBox,
  Loading,
  Button,
} from "../../../../../core/components/common";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { Steps } from "antd";
import {
  getProgramListAll,
  getProgramCoverageAll,
  getAllCountry,
  getPubProgramSingle,
  getSingleProgram,
  updatePubProgram,
} from "../../../../actions";
const { Step } = Steps;
var _ = require("lodash");

class WaiverForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      program_data: {
        coverage: [],
        exclude_countries:
          (this.props.single_program &&
            this.props.single_program.exclude_countries) ||
          [],
        send_mail_to_author:
          (this.props.single_program &&
            this.props.single_program.send_mail_to_author) 
          ,
      },
      program_id: false,
      btn_disabled: true,
    };
  }

  async componentDidMount() {
    const programId = this.props.match.params.id;
    await this.props.getAllCountry();
    await this.props.getProgramListAll();

    await this.props.getPubProgramSingle({ id: programId });
      this.setState((prevState) => {
        let program_data = this.props.single_program;
        return Object.assign(prevState.program_data, program_data);
      });
    this.setState({
      btn_disabled: false,
    });
  }
  detectStatus = (current) => {
    const { program_data } = this.state;
    let stepBtnStatus = false;
    if (current === 0) {
      stepBtnStatus = program_data && program_data.program ? false : true;
    } else if (current === 1) {
      stepBtnStatus =
        program_data &&
          program_data.coverage &&
          program_data.coverage.length > 0
          ? false
          : true;
    }
    return stepBtnStatus;
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
    let status = this.detectStatus(current);
    this.setState({
      btn_disabled: status,
    });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
    let status = this.detectStatus(current);
    this.setState({
      btn_disabled: status,
    });
  }

  onSubmitHandler = async (e) => {
    e.preventDefault();
    const programId = this.props.match.params.id;
    let program = this.state.program_data;
    await this.props.updatePubProgram(programId, program);
    this.props.history.push("/settings/waiver-policy");
  };

  onChange = (checked) => {
    if (checked) {
      this.setState((prevState) => {
        return Object.assign(prevState.program_data, {
          send_mail_to_author: checked,
        });
      });
    } else {
      this.setState((prevState) => {
        return Object.assign(prevState.program_data, {
          send_mail_to_author: false,
        });
      });
    }
  };
  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "coverage":
        this.setState({
          isShowCoverageModal: true,
        });
        break;
      case "exclude_countries":
        this.setState({
          isShowExcludeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowCoverageModal: false,
      isShowExcludeModal: false,
    });
  };

  detectLabel = (length) => {
    let country_label = length === 1 ? "country" : "countries";
    return country_label;
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "coverage":
        if (fieldValues && fieldValues.length > 0) {
          let country_label = this.detectLabel(fieldValues.length);
          labelText = `${fieldValues.length} included ${country_label}  selected`;
        } else {
          labelText = `No countries selected`;
        }
        break;
      case "exclude_countries":
        if (fieldValues && fieldValues.length > 0) {
          let country_label = this.detectLabel(fieldValues.length);
          labelText = `${fieldValues.length} exclude ${country_label} selected`;
        } else {
          labelText = `No exclude countries selected`;
        }
        break;
      default:
        break;
    }
    return labelText;
  };
  onChangeHandler = async (value) => {
    let keys = Object.keys(value);
    let values = Object.values(value);
    if (keys[0] === "program") {
      this.setState({
        program_id: values[0],
        btn_disabled: values[0] ? false : true,
      });
      await this.props.getProgramCoverageAll({ id: values[0] });
      await this.props.getSingleProgram({ id: values[0] });
      this.props.coverage &&
        this.props.single_program &&
        this.setState((prevState) => {
          return Object.assign(prevState.program_data, {
            coverage: _.map(this.props.coverage, "id"),
            exclude_countries: this.props.single_program.exclude_countries,
          });
        });
    }
    this.setState((prevState) => {
      return Object.assign(prevState.program_data, value);
    });
  };
  onSaveSelectedItems = (fieldName, data) => {
    if (fieldName === "coverage") {
      let btn_status = data.length > 0 ? false : true;
      this.setState({
        btn_disabled: btn_status,
      });
    }
    this.setState((prevState) => {
      return Object.assign(prevState.program_data, { [fieldName]: data });
    });
  };

  render() {
    const { program_data } = this.state;
    let programs = [
      {
        name: "Select apc program",
        id: "",
      },
    ];
    programs = this.props.program && programs.concat(this.props.program);
    const steps = [
      {
        title: "APC Programme",
        content: (
          <div
            className="license-body license-body-criterias"
            style={{ minHeight: 120 }}
          >
            { program_data.program ? (
              <div style={{ paddingBottom: 10 }}>
                <div
                  style={{
                    paddingLeft: 15,
                    paddingBottom: 20,
                    color: "#007ED5",
                    paddingTop: 40,
                    fontSize: 16,
                  }}
                >
                  Select the waiver programme from the drop-down list.
                </div>
                <SelectBox
                  className="col-md-4"
                  placeholder="Select apc program"
                  onChange={this.onChangeHandler}
                  field="program"
                  data={programs}
                  disabled={true}
                  defaultValue={program_data.program}
                />
              </div>
            ) : (
                <Loading type="flat" />
              )}
          </div>
        ),
      },
      {
        title: "Select countries",
        content: (
          <div
            className="license-body license-body-criterias"
            style={{ minHeight: 80 }}
          >
            <div className="form-group row">
              <div
                style={{
                  marginLeft: 15,
                  paddingTop: 40,
                  paddingBottom: 50,
                  color: "#007ED5",
                  fontSize: 15,
                }}
              >
                Click on the + to view, add, edit or remove the countries to be
                included in the waiver program.
              </div>
              {this.props.countries ? (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      {this.showSelectItemsLabel("coverage")}
                    </div>
                    <div className="col-md-6">
                      <button
                        onClick={() => this.onModalSelectBoxShow("coverage")}
                        className="btn btn-info waves-effect waves-light float-left"
                        type="button"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                  <Loading type="flat" />
                )}
              {this.state.isShowCoverageModal && program_data.program && (
                <ModalSelectBox
                  title="Select coverage"
                  label="Coverage"
                  placeholder="Countries"
                  field="coverage"
                  modalClass="col-10 col-md-8 col-lg-6"
                  className="col-sm-9"
                  data={this.props.countries}
                  defaultValue={program_data.coverage}
                  isRequired={false}
                  submitBtnTxt="Save"
                  onSubmit={this.onSaveSelectedItems}
                  onClose={() => this.onModalSelectBoxClose()}
                />
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Mail to author",
        content: (
          <div
            className="license-body license-editor"
            style={{ minHeight: 90 }}
          >
            <div className="col-sm-12" style={{ paddingTop: 7 }}>
              <div
                style={{
                  paddingBottom: 40,
                  color: "#007ED5",
                  fontSize: 15,
                  marginTop: 40,
                }}
              >
                System will automatically notify to corresponding authors from
                emerging countries for potential discount eligibility under APC
                Waiver programme. Would you like system automatically notify
                authors from countries included in this waiver program of their
                potential eligibility for a waiver?
              </div>
              <Switch
                defaultChecked={program_data.send_mail_to_author}
                onChange={this.onChange}
              />
            </div>
          </div>
        ),
      },
    ];
    const { current, btn_disabled } = this.state;

    return (
      <HubContent>
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="vat-tax-button">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() =>
                    this.props.history.push("/settings/waiver-policy")
                  }
                />
              </div>
              <div className="waiver-step-form">
                <Steps current={current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                 
                {current > 0 && (
                    <Button
                      type="secondary"
                      text="Previous"
                      name="secondary"
                      btn_size={100}
                      onClick={() => this.prev()}
                    />
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      type="primary"
                      text="Update Waiver Policy"
                      name="primary"
                      btn_size="auto"

                      onClick={this.onSubmitHandler}
                    />
                  )}
                  {current < steps.length - 1 && (
                    <Button
                      disabled={btn_disabled}
                      type="primary"
                      text="Next"
                      btn_size={100}

                      name="primary"
                      onClick={() => this.next()}
                    />
                  )}
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  countries: state.app.countries ,
  coverage:
    (state.app && state.app.coverage && state.app.coverage.results) ,
  program:
    (state.program &&
      state.program.program_list &&
      state.program.program_list.results) 
    ,
  single_program: (state.program && state.program.single_program) ,
});

const mapDispatchToProps = (dispatch) => ({
  getProgramListAll: () => dispatch(getProgramListAll()),
  getAllCountry: () => dispatch(getAllCountry()),
  getProgramCoverageAll: (payload) => dispatch(getProgramCoverageAll(payload)),
  getPubProgramSingle: (payload) => dispatch(getPubProgramSingle(payload)),
  getSingleProgram: (payload) => dispatch(getSingleProgram(payload)),
  updatePubProgram: (id, payload) => dispatch(updatePubProgram(id, payload)),
});

export const EditWaiverForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WaiverForm)
);
