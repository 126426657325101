import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { downloadInvoice } from "../../../../../../openaccess/actions";
import { Button } from "../../../../../../core/components/common";

class Btn extends React.Component {
    render() {
        let { action, text, notAllowed } = this.props;
        let name = "primary";
        switch (text && text.toLowerCase()) {
            case "approve": {
                name = "primary";
                break;
            }
            case "view": {
                name = "primary";
                break;
            }
            case "accept": {
                name = "primary";
                break;
            }
            case "rejected": {
                name = "danger";
                break;
            }
            case "approved": {
                name = "success";
                break;
            }
            case "reject": {
                name = "warning";
                break;
            }
            case "correction_request": {
                name = "secondary";
                break;
            }
            case "correction_requested": {
                name = "secondary";
                break;
            }
            case "correction_request_approve": {
                name = "secondary";
                break;
            }
            case "correction request": {
                name = "secondary";
                break;
            }
            case "apc fund requested": {
                name = "info";
                break;
            }
            case "pending": {
                name = "primary";
                break;
            }
            case "request apc fund": {
                name = "secondary";
                break;
            }
            case "delete": {
                name = "danger";
                break;
            }
            case "create": {
                name = "primary";
                break;
            }
            case "update": {
                name = "warning";
                break;
            }
            case "upload": {
                name = "primary";
                break;
            }
            case "download": {
                name = "primary";
                break;
            }
            case "pay": {
                name = "default";
                break;
            }
            case "update payment": {
                name = "primary";
                break;
            }
            case "invoice_paid": {
                name = "primary";
                break;
            }
            case "acknowledge payment": {
                name = "primary";
                break;
            }
            case "cancel invoice": {
                name = "danger";
                break;
            }
            case "cancel request": {
                name = "danger";
                break;
            }
            case "update refund payment": {
                name = "primary";
                break;
            }
            case "paid": {
                name = "success";
                break;
            }
            default: {
                name = action;
                break;
            }
        }

        return (
            <div>
                {notAllowed ? (
                    !notAllowed.includes(action) && (
                        <p className="m-1">
                            <button
                                type={this.props.type}
                                className={`btn btn-${name} btn-sm ${action !== "no_action" && "waves-effect"
                                    }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onClickHander && this.props.onClickHander(e);
                                }}
                                style={{ width: "155px", cursor: "default" }}
                            >
                                {this.props.text}
                            </button>
                        </p>
                    )
                ) : (
                        <p className="m-1">
                            <button
                                type={this.props.type}
                                className={`btn btn-${name} btn-sm ${action !== "no_action" && "waves-effect"
                                    }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.onClickHander && this.props.onClickHander(e);
                                }}
                                style={{ width: "155px", cursor: "default" }}
                            >
                                {this.props.text}
                            </button>
                        </p>
                    )}
            </div>
        );
    }
}

export const ActionButton = Btn;
class ExpandTable extends React.Component {
    state = {
        manage_invoice_btn: Object.assign(
            {},
            this.props.item && this.props.item.actions
        ),
    };

    onClickDownloadInvoice = async (url, item) => {
        let payload = {
            url: url,
        };

        await this.props.downloadInvoice(payload);

        const urlObj = window.URL.createObjectURL(this.props.invoice_download);
        const link = document.createElement("a");
        link.href = urlObj;
        var d = new Date();

        link.setAttribute(
            "download",
            `${item.replace(/\s+/g, "")}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
    };

    setActive = () => {
        this.setState({
            active: !this.state.active,
        });
    };

    render() {
        const { item, column, downloadName } = this.props;
        return (
            <div className="data-table">
                <div className="row-table">
                    <div className="row table-data">
                        <div className={column[2]}>
                            {item.publisher ? item.publisher : "-"}
                        </div>

                        <div className={column[1]}>
                            {item.invoice_number
                                ? item.invoice_number
                                : item.transaction_type
                                    ? item.transaction_type
                                    : item.reference
                                        ? item.reference
                                        : item.author
                                            ? item.author
                                            : item.oa_deal
                                                ? item.oa_deal
                                                : "-"}
                        </div>

                        <div className={column[1]}>
                            {item.currency ? item.currency : "-"}
                        </div>
                        <div className={column[1]}>
                            {item.currency === "Discount %"
                                ? `${Math.round(item.amount)}%`
                                : item.amount}
                        </div>
                        <div className={column[1]}>
                            {item.grant_number ? (
                                item.grant_number
                            ) : item.token ? (
                                item.token
                            ) : item.article_id ? (
                                item.article_id
                            ) : item.deal_year ? (
                                <p>{item.deal_year}</p>
                            ) : item.invoice_status || item.payment_status ? (
                                <Button
                                    type={
                                        item && item.invoice_status === "Paid"
                                            ? "success"
                                            :(item && item.invoice_status === "Cancelled") ||( item && item.invoice_status=== "Expired")
                                                ? "danger"
                                                : "success"
                                    }
                                    name={
                                        item && item.invoice_status === "Paid"
                                            ? "success"
                                            :(item && item.invoice_status === "Cancelled") ||( item && item.invoice_status=== "Expired")
                                                ? "danger"
                                                : "success"
                                    }
                                    btn_size={85}
                                    size="85"
                                    className="btn-status"
                                    cursor="auto"
                                    text={
                                        item && item.invoice_status
                                            ? item && item.invoice_status === "Paid"
                                                ? "Paid"
                                                : (item && item.invoice_status === "Cancelled")
                                                    ? "Cancelled"
                                                    : item.invoice_status
                                            : item.payment_status
                                    }
                                />
                            ) : "-"}
                        </div>

                        <div className={column[0]}>
                            {" "}
                            <a
                                className="article_open_arrow ss"
                                href={`#collapse_${item.id}`}
                                data-toggle="collapse"
                                aria-expanded="true"
                                aria-controls={`collapse_${item.id}`}
                                style={{ color: "#5874ab", fontWeight: 500 }}
                            >
                                <i
                                    onClick={this.setActive}
                                    className={
                                        this.state.active
                                            ? "fa fa-chevron-up"
                                            : "fa fa-chevron-down"
                                    }
                                />
                            </a>

                        </div>
                    </div>

                    <div
                        id={`collapse_${item.id}`}
                        className={`collapse ${false && "show"}`}
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                    >
                        <div
                            className="row table-expand"
                            style={{ paddingTop: 10, paddingBottom: 20 }}
                        >
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-5">
                                        {item.article ? <label>Article Title:</label> : null}
                                        {item.article ? <p>{item.article}</p> : null}

                                        {item.institution ? <label>Organisation</label> : null}
                                        {item.institution ? (
                                            <p>{item.institution ? item.institution : "-"}</p>
                                        ) : null}

                                        {item.assigned_organisation_name ? (
                                            <label>Inst/Author/Sub/Journal</label>
                                        ) : null}
                                        {item.assigned_organisation_name ? (
                                            <p>
                                                {item.assigned_organisation_name
                                                    ? item.assigned_organisation_name
                                                    : "-"}
                                            </p>
                                        ) : null}

                                        <label>Publisher:</label>
                                        <p>{item ? item.publisher : null}</p>
                                        <label>Document ID:</label>
                                        <p>{ item && item.article_data ? item && item.article_data && item.article_data.document_id : "-"}</p>
                                        {item.valid_from ? <label>Valid from</label> : null}
                                        {item.valid_from ? <p>{item.valid_from}</p> : null}
                                        {item.valid_to ? <label>Valid to</label> : null}
                                        {item.valid_to ? <p>{item.valid_to}</p> : null}

                                        <label>{item.funder ? "Funder" : null}</label>
                                        {item.funder ? <p>{item.funder}</p> : null}
                                        {item.payer_note ? (
                                            <label>
                                                {item.payer_note ? "Payer Note " : null}
                                            </label>
                                        ) : null}
                                        <p>{item.payer_note}</p>

                                        {item.receiver_note ? (
                                            <label>
                                                {item.receiver_note ? "Payment Acknowledge " : null}
                                            </label>
                                        ) : null}
                                        <p>{item.receiver_note}</p>

                                        {item && item.actions && item.actions.length > 0 ? (
                                            item.actions.map(
                                                (invoice, key) =>
                                                    invoice.label === "Paid" ? (
                                                        <ActionButton
                                                            notAllowed={["update", "delete"]}
                                                            key={key}
                                                            text={invoice.label}
                                                            action={invoice.action}
                                                            onClickHander={() =>
                                                                this.props.onActionHandler(
                                                                    invoice.action,
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                            <ActionButton
                                                                notAllowed={["update", "delete"]}
                                                                key={key}
                                                                text={invoice.label}
                                                                action={invoice.action}
                                                                onClickHander={() =>
                                                                    this.props.onActionHandler(
                                                                        invoice.action,
                                                                        item.id
                                                                    )
                                                                }
                                                            />
                                                        )

                                            )
                                        ) : (
                                                <p>{item.receiver_note}</p>
                                            )}


                                    </div>
                                    <div className="col-md-4">
                                        {item.date ? <label>Invoice Date:</label> : null}
                                        {item.date ? (
                                            <p>
                                                {item.date
                                                    ? moment(item.date).format("Do MMM YYYY")
                                                    : "-"}
                                            </p>
                                        ) : null}

                                        <label>
                                            {item.currency === "Discount %" ? "Discount" : "Currency"}
                                        </label>
                                        <p> {item.currency ? item.currency : "-"}</p>
                                        <label>
                                            {item.invoice_number
                                                ? "Invoice Number"
                                                : item.reference
                                                    ? "Reference"
                                                    : item.licence_name
                                                        ? "License"
                                                        : item.transaction_type
                                                            ? "Transaction type"
                                                            : "Read fee"}
                                        </label>
                                        <p>
                                            {item.invoice_number
                                                ? item.invoice_number
                                                : item.licence_name
                                                    ? item.licence_name
                                                    : item.reference
                                                        ? item.reference
                                                        : item.transaction_type
                                                            ? item.transaction_type
                                                            : item.read_fee
                                                                ? item.read_fee
                                                                : "-"}
                                        </p>
                                        {item.grant_number ||
                                            item.invoice_status ||
                                            item.token ||
                                            item.trx_token_name ? (
                                                <label>
                                                    {item.grant_number
                                                        ? "Grant Number"
                                                        : item.invoice_status
                                                            ? "Invoice Status"
                                                            : item.token
                                                                ? "Token"
                                                                : item.trx_token_name
                                                                    ? "Token ID"
                                                                    : "Payment Status"}
                                                </label>
                                            ) : null}
                                        {item.grant_number ? (
                                            <p> {item.grant_number}</p>
                                        ) : item.token ? (
                                            <p>{item.token}</p>
                                        ) : item.trx_token_name ? (
                                            <p> {item.trx_token_name}</p>
                                        ) : ((item && item.invoice_status) || (item && item.payment_status)) ? (
                                            <div style={{ display: "Block" }}>
                                                <Button
                                                  cursor="auto"
                                                    type={
                                                        item && item.invoice_status === "Paid"
                                                            ? "success"
                                                            : (item && item.invoice_status === "Cancelled") ||( item && item.invoice_status=== "Expired")
                                                                ? "danger"
                                                                : "success"
                                                    }
                                                    name={
                                                        item && item.invoice_status === "Paid"
                                                            ? "success"
                                                            :  (item && item.invoice_status === "Cancelled") ||( item && item.invoice_status=== "Expired")
                                                                ? "danger"
                                                                : "success"
                                                    }
                                                    btn_size={85}
                                                    size="85"
                                                    className="btn-status"
                                                    text={
                                                        item && item.invoice_status
                                                            ? item && item.invoice_status === "Paid"
                                                                ? "Paid"
                                                                : item && item.invoice_status === "Cancelled"
                                                                    ? "Cancelled"
                                                                    : item.invoice_status
                                                            : item.payment_status
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-md-3">
                                    {item.date ? <label>Due Date:</label> : null}
                                        {item.date ? (
                                            <p>
                                                {item.date
                                                    ? moment(item.expiry_date).format("Do MMM YYYY")
                                                    : "-"}
                                            </p>
                                        ) : null}
                                        {item.author ? <label>Author</label> : null}
                                        {item.author ? <p> {item.author}</p> : null}

                                        <label>Amount</label>
                                        <p>
                                            {item.currency === "Discount %"
                                                ? `${Math.round(item.amount)}%`
                                                : item.amount}
                                        </p>
                                        {item.publish_fee ? <label>Publish fee</label> : null}
                                        {item.publish_fee ? <p>{item.publish_fee}</p> : null}

                                        {item.used_status ? <label>Used Status</label> : null}
                                        {item.used_status ? <p>{item.used_status}</p> : null}
                                        {item.assigned_status ? (
                                            <label>Assigned Status</label>
                                        ) : null}
                                        {item.assigned_status ? (
                                            <p>{item.assigned_status}</p>
                                        ) : null}
                                        {item.article_id ? <label>Article ID:</label> : null}
                                        {item.article_payment_id ? (
                                            <label>Article ID:</label>
                                        ) : null}
                                        {item.article_payment_id ? (
                                            <p>
                                                {item.article_payment_id
                                                    ? item.article_payment_id
                                                    : null}
                                            </p>
                                        ) : null}

                                        {item.article_id ? (
                                            <p>{item.article_id ? item.article_id : null}</p>
                                        ) : null}

                                        {item.link ? (
                                            <Button
                                            downloadIcon={true}

                                                type="primary"
                                                size="200"
                                                className="invoice-download"
                                                name="primary"
                                                onClick={(e) =>
                                                    this.onClickDownloadInvoice(item.link, downloadName)
                                                }
                                                text={`${downloadName}`}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.auth ,
    group: state.auth.group,
    invoice_download: (state.payment && state.payment.invoice_download) ,
});

const mapDispatchToProps = (dispatch) => ({
    downloadInvoice: (payload = {}) => dispatch(downloadInvoice(payload)),
});

export const InvoiceDataTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpandTable);
