import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Button, Loading,PopupBox } from "../../../../../core/components/common";
import {
  getTermsAndConditionlist,
  singleTermsandCondition,
  downloadInvoice,
  deleteTermsConditionsMessage,
} from "../../../../actions";

import { Popover } from "antd";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Terms and conditions</a>
    <br />
  </div>
);
class TermsConditions extends BaseComponent {
  state = {
    viewTermsOpen: false,
  };

  componentDidMount() {
    this.props.getTermsAndConditionlist().then(res => {
        this.setState({
          terms: res && res.results,
        });
      

    });

  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  

  handleClose = () => {
    this.setState({
      viewTermsOpen: false,
    });
  };

  addTermsConditions = () => {
    this.props.history.push("/settings/create-terms-conditions");
  };

  editTermsMessage = (id) => {
    this.props.history.push(`/settings/edit-terms-conditions/${id}`);
  };

  viewTermsMessage = (id, viewdata, orgs) => {
    this.setState({
      id: id,
      termss: viewdata,
      viewTermsOpen: !this.state.viewTermsOpen,
      link: orgs && orgs.link,
      label: orgs.label,
    });
    this.props.singleTermsandCondition(id);
  };
  onDeleteTermsConditions = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
         id,
      });
    }
  };
  onDeleteHander = async () => {
    this.setState({
      waitingMsg: "Deleting...",
    });
    const deleteResponse = await this.props.deleteTermsConditionsMessage(this.state.id);
    this.setState({
      waitingMsg: null,
      id: null,
      deleteConfirm: false,
      deleteStatus: deleteResponse.status,
    });
    this.props.getTermsAndConditionlist().then((res) => {
      this.setState({
        terms: this.props.get_terms_conditionTag.results,
      });
    });
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      id: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

  licencepdfdownload = async (e, url, name) => {
    e.preventDefault();
    let payload = {
      url: url,
    };

    await this.props.licensepdfdownload(payload);

    const urlObj = window.URL.createObjectURL(this.props.licence_download);
    const link = document.createElement("a");
    link.href = urlObj;
    var d = new Date();

    link.setAttribute(
      "download",
      `${name}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  };

  render() {
    const termslist = this.state.terms 
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">

              <div className="card-body">

              {this.state.deleteConfirm && (
          <PopupBox
            title="Are you sure to delete this Terms & Conditions?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}


                <div className="vat-tax-button email d-flex justify-content-end">


                  <Popover placement="leftTop" content={content} trigger="click">
                    <Button
                      className="btn btn-secondary float-right "
                      type=""
                      name=""
                      text="Help"
                    >Help</Button>
                  </Popover>
                  <div className="danger float-right"
                  >
                    <button
                      type="danger"
                      className="btn btn-danger float-right "
                      onClick={() => this.props.history.goBack()}
                      style={{ marginLeft: 10 }}
                    >
                      X

</button>                  </div>
                </div>
                <div className="useremail-right">
                  <ul className="nav nav-pills nav-justified" role="tablist">
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link  active"
                        data-toggle="tab"
                        href="#home-1"
                        role="tab"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane p-3  active"
                    id="home-1"
                    role="tabpanel"
                  >
                    <div className="mb-3 m-t-15 float-right">
                      <Button
                        type="primary"
                        text="  Add Terms & Conditions   "
                        onClick={() => this.addTermsConditions()}
                        name="primary"
                      />
                    </div>
                    <table
                      className="table table-striped"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead className="text-white thead-dark">
                        <tr>
                          <th width="25%">Name</th>
                          <th width="60%">Description</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>

                      {this.props.isLoading !== true && <tbody>
                        {termslist &&
                          termslist.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{item.description}</td>
                              <td>
                                <span>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      this.onDeleteTermsConditions(item.id)
                                    }
                                    id="deleteBtn"
                                    title="Delete"
                                  >
                                    <i className="mdi mdi-delete"></i>
                                  </span>
                                  |
                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      this.editTermsMessage(item.id)
                                    }
                                    title="Edit"
                                  >
                                    <i className="mdi mdi-pen"></i>
                                  </span>
                                  |
                                </span>
                                <span
                                  className="cursor-pointer"
                                  onClick={() =>
                                    this.viewTermsMessage(
                                      item.id,
                                      item.terms,
                                      item && item.organisation_terms_download
                                    )
                                  }
                                  title="View"
                                >
                                  <i className="mdi mdi-eye"></i>
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>}
                    </table>
                    {termslist&& termslist.length<=0 &&<div className="card">
            <div className="card-body no-data-found">No Data Found</div>
          </div>}
                    {this.props.isLoading === true && <Loading type="flat" />}
                  </div>

                  {this.state.viewTermsOpen && (
                    <div
                      id="CoAuthorDialog"
                      className="addmultfield"
                      style={modal.overlay}
                    >
                      <div
                        className={`col-10 col-lg-8 bg-white licence licence-10 alert`}
                        style={modal.viewboxStyle}
                      >
                        <div className="col-md-12">
                          <div className="licence-view">
                            {this.props.isLoading !==true ? ReactHtmlParser(
                              this.props.single_terms_condition &&
                              this.props.single_terms_condition.terms
                            ):<Loading type="flat" />}
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <button
                                onClick={this.handleClose}
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                              >
                                Close
                              </button>
                              <button
                                style={{ marginLeft: 10 }}
                                onClick={(e) =>
                                  this.licencepdfdownload(
                                    e,
                                    this.state.link,
                                    "TermsandConditions"
                                  )
                                }
                                type="button"
                                className="btn btn-secondary waves-effect waves-light"
                              >
                                {this.state.label}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}
let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}
const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0px, 0px)",
    height: boxHeight,
  },
  viewboxStyle: {
    transform: "translate(50px, 20px)",
    height: "600px",
  },
};
const mapStateToProps = (state) => ({
  get_terms_conditionTag: state.licence.get_terms_condition,
  isLoading: state.licence.isLoading,
  single_terms_condition: state.licence.single_terms_condition,
  licence_download: (state.payment && state.payment.invoice_download),
});

const mapDispatchToProps = (dispatch) => ({
  deleteTermsConditionsMessage: (id) =>
    dispatch(deleteTermsConditionsMessage(id)),
  getTermsAndConditionlist: () => dispatch(getTermsAndConditionlist()),
  singleTermsandCondition: (id) => dispatch(singleTermsandCondition(id)),
  licensepdfdownload: (payload = {}) => dispatch(downloadInvoice(payload)),
});

export const TermsConditionsList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TermsConditions)
);
