import React from "react";
import BaseComponent from "..";
import { SelectBox, InputBox } from ".";

class Rewards extends BaseComponent {
  state = {
    currencyList: this.props.currencyList || [],
    tokenReward: this.props.data || {},
  };

  chargeDescriptionOptions = [
    { id: "article_total", name: "All charges" },
    { id: "article_pub_fee", name: "APC" },
    { id: "article_color_charge", name: "Colour charge" },
    { id: "article_submission_fee", name: "Submission fee" },
    { id: "article_page_charge", name: "Page charges" },
    { id: "article_other_charge", name: "Other charges" },
  ];

  discountTypes = [
    { id: 1, name: "Discount (%)" },
    { id: 0, name: "Amount" },
  ];

  onChange = (value) => {
    let { tokenReward } = this.state;

    Object.assign(tokenReward, value);

    if (value.hasOwnProperty("by_percentage")) {
      if (value.by_percentage === 1) {
        delete tokenReward.currency;
      }
    }

    this.setState(
      {
        tokenReward,
      },
      () => {
        this.props.onChangeHandler(this.state.tokenReward);
      }
    );
  };

  render() {
    let { currencyList, tokenReward } = this.state;

    return (
      <div>
        <div className="form-group row">
          <SelectBox
                   labelInfo={true}
                   labelInfoTitle="Select Description"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            label="Description"
            onChange={this.onChange}
            field="name"
            data={this.chargeDescriptionOptions}
            isRequired={false}
            defaultValue={tokenReward.name}
            placeholder="Select Description"
          />

          <SelectBox
          labelInfo={true}
          labelInfoTitle="Select Discount/Amount"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            label="Discount/Amount"
            onChange={this.onChange}
            field="by_percentage"
            data={this.discountTypes}
            isRequired={false}
            defaultValue={tokenReward.by_percentage}
            placeholder="Discount (%) or amount"
          />
        </div>
        <div className="form-group row">
          {tokenReward.by_percentage !== 1 && (
            <SelectBox
            labelInfo={true}
            labelInfoTitle="Select Currency"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label="Currency"
              onChange={this.onChange}
              field="currency"
              data={currencyList || []}
              isRequired={false}
              defaultValue={tokenReward.currency}
              placeholder="Currency"
            />
          )}

          <InputBox
            label={tokenReward.by_percentage === 1 ? "Discount" : "Amount" }
            onChange={this.onChange}
            field="amount"
            labelInfo={true}
            labelInfoTitle="Enter Amount"
            InputType="number"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder={
              tokenReward.by_percentage === 1 ? "Enter Discount" : "Enter Amount"
            }
            defaultValue={tokenReward.amount}
          />
          {tokenReward.by_percentage === 1 && (
            <label className="col-sm-1">%</label>
          )}
        </div>
      </div>
    );
  }
}

export const RewardsRow = Rewards;
