import config, {
	APPROVED_DEPOSIT_LIST,
	DEPOSIT_SEARCH_LIST,
	PUBLISHER_DEPOSIT_PAYMENT,
	PENDING_DEPOSIT_LIST,
	DEPOSIT_DOWNLOAD,
	DEPOSIT_LIST_DOWNLOAD,
	DEPOSIT_APPROVAL_DOWNLOAD,
} from "../../config";
import { api } from "../../core/api";

export const ApprovedList = (payload) => ({
	type: APPROVED_DEPOSIT_LIST,
	payload,
});

export const PendingList = (payload) => ({
	type: PENDING_DEPOSIT_LIST,
	payload,
});

export const depositDownloadAction = (payload) => ({
	type: DEPOSIT_DOWNLOAD,
	payload,
});
export const depositApprovalDwAction = (payload) => ({
	type: DEPOSIT_APPROVAL_DOWNLOAD,
	payload,
});
export const depositlistDownloadAction = (payload) => ({
	type: DEPOSIT_LIST_DOWNLOAD,
	payload,
});
export const GetApprovedDepositSearch = (payload) => ({
	type: DEPOSIT_SEARCH_LIST,
	payload,
});

export const createUpdateDepositPaymentAction = (payload) => ({
	type: PUBLISHER_DEPOSIT_PAYMENT,
	payload,
});

// Create-Update Deposit Payment

export const createUpdateDepositPayment = (payload, id = false) => {
	return (dispatch) => {
		let url = config.endpoint.publisher_deposit_payment;
		let apiRequest = "";
		return new Promise((resolve, reject) => {
			if (id) {
				url += `${id}/`;
				apiRequest = api.put(url, payload);
			} else {
				apiRequest = api.post(url, payload);
			}

			apiRequest
				.then((res) => {
					// dispatch(createUpdateDepositPaymentAction(res.data));
					resolve(Object.assign(res.data, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Approved Deposit List
export const DepositApproval = (ids) => {
	return (dispatch) => {
		const url = config.endpoint.deposit_fund + ids + "/approve/";
		return new Promise((resolve, reject) => {
			return api
				.put(url)
				.then(
					(res) => {
						resolve(Object.assign(res.data, res.status));
					},
					(error) => {
						resolve({ status: false });
					}
				)
				.catch(reject);
		});
	};
};

export const DepositApprovalDownload = () => {
	const url = `${config.endpoint.deposit_fund}download?fields=date,publisher_name,institution_name,deal_type_name,deal_year,currency_name,read_fee,publish_fee,amount,deposited_by&page_size=999999`;
	return (dispatch, getState) => {
		const token = getState().auth.token;
		let options = {
			responseType: "blob",
		};
		return new Promise((resolve, reject) => {
			api.get(url, token, options)
				.then((res) => {
					dispatch(depositApprovalDwAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {
					reject(Object.assign(error.response, { status: false }));
				});
		});
	};
};

// Download depositFund
export const depositFundDownload = () => {
	const url = `${config.endpoint.deposit_credit_trxn}download?fields=date,publisher_name,institution_name,trans_type,currency_name,total,article_id,article_title,author,grant_number,funder&page_size=999999`;
	return (dispatch, getState) => {
		const token = getState().auth.token;
		let options = {
			responseType: "blob",
		};
		return new Promise((resolve, reject) => {
			api.get(url, token, options)
				.then((res) => {
					dispatch(depositDownloadAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {
					reject(Object.assign(error.response, { status: false }));
				});
		});
	};
};

export const GetPendingDepositList = () => {
	return (dispatch) => {
		const url = config.endpoint.pending_deposit_fund;
		return new Promise((resolve, reject) => {
			api.get(url).then(
				(res) => {
					dispatch(PendingList(res.results));
					resolve(res.results);
				},
				(error) => {
					if (error.response) {
						delete error.config;
						delete error.headers;
						delete error.request;
						delete error.response.request;
						delete error.response.headers;
						delete error.response.config;
						dispatch(PendingList(error.response));
					} else {
						dispatch(
							PendingList({
								status: 500,
							})
						);
					}
				}
			);
		});
	};
};

export const GetApprovedDepositList = (payload) => {
	return (dispatch) => {
		const url =
			config.endpoint.approveed_deposit_fund + "&page=" + payload.pageNum;
		return new Promise((resolve, reject) => {
			api.get(url).then(
				(res) => {
					dispatch(ApprovedList(res));
					resolve(Object.assign(res, { status: true }));
				},
				(error) => {
					if (error.response) {
						delete error.config;
						delete error.headers;
						delete error.request;
						delete error.response.request;
						delete error.response.headers;
						delete error.response.config;
						dispatch(ApprovedList(error.response));
					} else {
						dispatch(
							ApprovedList({
								status: 500,
							})
						);
					}
				}
			);
		});
	};
};

export const GetApprovedDepositSearchList = (payload) => {
	return (dispatch) => {
		const url =
			config.endpoint.approveed_deposit_fund + "&page=" + payload.pageNum;
		return new Promise((resolve, reject) => {
			api.get(url).then(
				(res) => {
					dispatch(GetApprovedDepositSearch(res));
					resolve(Object.assign(res, { status: true }));
				},
				(error) => {
					if (error.response) {
						delete error.config;
						delete error.headers;
						delete error.request;
						delete error.response.request;
						delete error.response.headers;
						delete error.response.config;
						dispatch(GetApprovedDepositSearch(error.response));
					} else {
						dispatch(
							ApprovedList({
								status: 500,
							})
						);
					}
				}
			);
		});
	};
};
