import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { Link } from "react-router-dom";
import { getAllUser, deleteUser, mutateUserStatus } from "../../../../actions";
import NotFound from "../../NotFound";
import {
  PopupBox,
  Loading,
  Button
} from "../../../../../core/components/common";
import { Popover } from "antd";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">UserList</a>
    <br />
  </div>
);
class UL extends BaseComponent {
  state = {
    deleteConfirm: false,
    userId: null,
    waitingMsg: null,
    deleteStatus: null,
    data: [],
  };
  getUserData = (pageNum = 1, page = 10) => {
    this.props.getAllUser({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.users.results,
      count: this.props.users.count,
    });
  };
  componentDidMount() {
    this.getUserData();
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  pageChange = (pageNumber) => {
    this.getUserData(pageNumber);
  };

  onEditUser = (id, data) => {
    this.props.history.push("/user/edit-user/" + id, { user: data });
  };

  onViewUser = (id, data) => {
    this.props.history.push("/pub-user/user/" + id, { user: data });
  };

  onMutateUserStatus = (id, status = true) => {
    let action = ''
    let popupTitle = ''
    let popupSuccessMsg = ''

    if (status) {
      action = 'deactivate-user'
      popupTitle = 'Are you sure to deactivate the user?'
      popupSuccessMsg = 'The user has been deactivated successfully.'
    } else {
      action = 'activate-user'
      popupTitle = 'Are you sure to activate the user?'
      popupSuccessMsg = 'The user has been activated successfully.'
    }

    if (id) {
      this.setState({
        changeStatusConfirm: true,
        mutateAction: action,
        userId: id,
        popupTitle,
        popupSuccessMsg,
      });
    }
  };

  onMutateUserStatusHandler = async () => {
    const userId = this.state.userId;
    const mutateAction = this.state.mutateAction;
    this.setState({
      waitingMsg: "Proceesing...",
    });

    this.props.mutateUserStatus(userId, mutateAction)
      .then((response) => {
        this.getUserData();

        this.setState({
          waitingMsg: null,
          userId: null,
          mutateAction: null,
          changeStatusConfirm: false,
          popupTitle: null,
          changeStatus: response.status,
        });
      }).catch(error => {
      });
    
  };

  onDeleteUser = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        userId: id,
      });
    }
  };

  onDeleteUserHander = async () => {
    const userId = this.state.userId;
    this.setState({
      waitingMsg: "Deleting...",
    });
    const user = await this.props.deleteUser(userId);
    this.getUserData();

    this.setState({
      waitingMsg: null,
      userId: null,
      deleteConfirm: false,
      deleteStatus: user.status,
    });
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      userId: null,
      waitingMsg: null,
      deleteStatus: null,
      mutateAction: null,
      changeStatusConfirm: false,
      changeStatus: false,
      popupTitle: null,
      popupSuccessMsg: null,
    });
  };
  
  render() {
    const heading = [
      "fullname",
      "email",
      "group_name",
      "organisation_name",
      "role_name",
      "active_status_label",
    ];

    let user = this.props.users;

    let failedMsg = this.props.failed;

    let errorHander = (
      <>
        {failedMsg && failedMsg.status === 400 && !this.props.users && (
          <PopupBox
            title="Invalid Request"
            msg="Your Request is not valid"
            onCancel={this.cancelLoader}
          />
        )}


        {!user && !failedMsg && failedMsg !== null && <Loading />}

      </>
    );

    return this.state.status === 403 ? (
      <NotFound />
    ) : (
        <HubContent
          title="User List"
        >
          {this.state.deleteConfirm && (
            <PopupBox
              title="Do you want to delete?"
              YesText="Yes"
              yesBtnType="danger"
              type="danger"
              NoBtnType="success"
              onCancel={this.onCancel}
              onPressOK={this.onDeleteUserHander}
              waitingMsg={this.state.waitingMsg}
            />
          )}

          {this.state.changeStatusConfirm && (
            <PopupBox
              title={this.state.popupTitle}
              YesText="Yes"
              NoText="No"
              yesBtnType="danger"
              type="danger"
              NoBtnType="primary"
              onCancel={this.onCancel}
              onPressOK={this.onMutateUserStatusHandler}
              waitingMsg={this.state.waitingMsg}
            />
          )}

          {this.state.changeStatus && (
            <PopupBox title={this.state.popupSuccessMsg} onCancel={this.onCancel} />
          )}

          {this.state.deleteStatus && (
            <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
          )}

          {this.state.deleteStatus === false && (
            <PopupBox
              title="Failed... Try Again"
              onCancel={this.onCancel}
              NoBtnType="danger"
            />
          )}
          {errorHander}

          <div className="row">


            <div className="col-12">
              <div className="vat-tax-button email d-flex justify-content-end">
                <Link
                  to="/pub-user/add-user"
                  style={{ marginRight: 10 }}
                  className="btn btn-primary float-right mb-2"
                >
                  Add User
                </Link>
                <Popover placement="leftTop" content={content} trigger="click">
                  <Button
                    className="btn btn-secondary float-right mb-2"
                    type=""
                    name=""
                    text="Help"
                  >Help</Button>
                </Popover>
                <div className="danger float-right"
                >
                  <button
                    type="danger"
                    className={`btn btn-danger btn- sm" waves-effect`}
                    onClick={() => this.props.history.goBack()}
                    style={{ marginLeft: 10 }}
                  >
                    X

            </button>

                </div>

              </div>

              <div className="card m-b-20">

                {this.props.users.results && (
                  <DataTable
                    heading={heading}
                    data={this.props.users.results}
                    onEdit={this.onEditUser}
                    onDelete={this.onDeleteUser}
                    onStatusChange={this.onMutateUserStatus}
                    onView={this.onViewUser}
                    count={this.props.users.count}
                    userId={this.props.auth && this.props.auth.user && this.props.auth.user.id}
                    pageChange={this.pageChange}
                    pageSize={10}
                  />
                )}
              </div>
            </div>

          </div >

        </HubContent >
      );
  }
}

const mapStateToProps = (state) => ({
  group: state.auth.group || false,
  auth: state.auth,
  users: state.user.users || false,
  failed: state.user.failed || false,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUser: (payload) => dispatch(getAllUser(payload)),
  deleteUser: (id) => dispatch(deleteUser(id)),
  mutateUserStatus: (id, action, data = {}) => dispatch(mutateUserStatus(id, action, data)),
});

export const UserList = connect(mapStateToProps, mapDispatchToProps)(UL);
