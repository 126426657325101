import config, {
	GET_INVOICE_TEMPLATE_LIST,
	GET_INVOICE_TEMPLATE,
	CREATE_UPDATE_INVOICE_TEMPLATE,
	DELETE_INVOICE_TEMPLATE,
	UPLOAD_INVOICE_TEMPLATE,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get invoice template list
export const getInvoiceTemplateListAction = (payload) => ({
	type: GET_INVOICE_TEMPLATE_LIST,
	payload,
});

// Get invoice template
export const getInvoiceTemplateAction = (payload) => ({
	type: GET_INVOICE_TEMPLATE,
	payload,
});

// Create-Update invoice template
export const createUpdateInvoiceTemplateAction = (payload) => ({
	type: CREATE_UPDATE_INVOICE_TEMPLATE,
	payload,
});

// Delete invoice template
export const deleteInvoiceTemplateAction = (payload) => ({
	type: DELETE_INVOICE_TEMPLATE,
	payload,
});

// file upload
export const fileUploadAction = (payload) => ({
	type: UPLOAD_INVOICE_TEMPLATE,
	payload,
});

export const getInvoiceTemplateList = (payload) => {
	return (dispatch) => {
		let url = config.endpoint.invoice_template;
		let params = {};

		if (payload.pageNum && payload.pageSize) {
			params["page"] = payload.pageNum;
			params["page_size"] = payload.pageSize;
		} else if (payload.pageNum) {
			params["page"] = payload.pageNum;
		} else if (payload.pageSize) {
			params["page_size"] = payload.pageSize;
		}

		if (payload.organisation) {
			params["organisation_id"] = payload.organisation;
		}

		if (!params.page_size) {
			params["page_size"] = 99999;
		}

		url += `?${serialize(params)}`;

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getInvoiceTemplateListAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Get Invoice Template
export const getInvoiceTemplate = (id) => {
	return (dispatch) => {
		let url = config.endpoint.invoice_template + `${id}/`;

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getInvoiceTemplateAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Create-Update invoice template

export const createUpdateInvoiceTemplate = (payload, id) => {
	return (dispatch) => {
		let url = config.endpoint.invoice_template;
		let apiRequest = "";
		return new Promise((resolve, reject) => {
			if (id) {
				url += `${id}/`;
				apiRequest = api.put(url, payload);
			} else {
				apiRequest = api.post(url, payload);
			}

			apiRequest
				.then((res) => {
					dispatch(createUpdateInvoiceTemplateAction(res.data));
					resolve(Object.assign(res.data, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Delete invoice template
export const deleteInvoiceTemplate = (id) => {
	return (dispatch) => {
		let url = config.endpoint.invoice_template + `${id}/`;

		return new Promise((resolve, reject) => {
			api.delete(url)
				.then((res) => {
					dispatch(deleteInvoiceTemplateAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// file upload action
export const uploadInvoiceTemplate = (payload) => {
	let url = `${config.endpoint.invoice_template}upload/`;
	return (dispatch, getState) => {
		const token = getState().auth.token;

		return new Promise((resolve, reject) => {
			let filename = payload.get("filename");
			let headers = {
				"Content-Disposition": `attachment; filename="${filename}"`,
			};

			payload.delete("filename");

			api.post(url, payload, token, headers)
				.then((res) => {
					dispatch(fileUploadAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {

					dispatch(fileUploadAction(error.response));
					reject(Object.assign(error.response, { status: false }));
				});
		});
	};
};
