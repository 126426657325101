import React from 'react'

const MetrixInfo = ({ crossref_count, alt_metric_score }) => {

    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Crorssref citations</strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            <strong className="info_seperator"> : </strong>
                            {crossref_count}
                        </p>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="article-label">
                        <p className="m-1">
                            <strong>Altmetric Attention Score </strong>
                        </p>
                    </div>
                    <div className="article-content">
                        <p className="m-1">
                            <strong className="info_seperator"> : </strong>
                            {alt_metric_score}
                        </p>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default MetrixInfo