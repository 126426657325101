import React from 'react'

const ReviewerInfo = (props) => {
    let reviewer = props && props.reviewers
    return (
        <div>

            {reviewer && reviewer.length > 0 ? reviewer && reviewer.map(item => (
                <div className="row" key={item.id} style={{ marginTop: 5, marginLeft: 15 }}>
                    <div className="author-icon"><i className="mdi mdi-account-circle m-r-5"></i></div>
                    <div className="author-content">
                        <p>{`${item.name}`}  {item.affiliation}</p>
                    

                    </div>
                </div>
            )) : <div className="row" style={{ marginTop: 5, marginLeft: 15 }}>
                    <div className="author-icon"><i className="mdi mdi-account-circle m-r-5"></i></div>
                    <div className="author-content">
                        <p><strong>Name: </strong>- </p>

                    </div>
                </div>}
        </div >
    )
}

export default ReviewerInfo
