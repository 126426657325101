import React, { Component } from "react";
import { connect } from "react-redux";

class App extends Component {
  render() {
    return (
      <>
        <div className="row app">
          <div className="col-md-12">
            <h3> Payment Getway</h3>
          </div>
        </div>
        <div className="row app">
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://img.icons8.com/color/48/000000/stripe.png"
                alt=""
                className="app-icon"
              />

              <h4 className="Licence-label"> </h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://img.icons8.com/color/48/000000/paypal.png"
                alt=""
                className="app-icon"
              />
              <h4 className="Licence-label"> </h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="/assets/images/app/saga-pay.png"
                alt=""
                className="app-icon edit1"
              />
              <h4 className="Licence-label"> </h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
        </div>

        <div className="row app">
          <div className="col-md-12">
            <h3> Peer Review and Production System</h3>
          </div>
        </div>
        <div className="row app">
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://img.icons8.com/color/48/000000/coming-soon.png"
                alt=""
                className="app-icon"
              />
              <h4 className="Licence-label ScholarOne">Submission System</h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://img.icons8.com/color/48/000000/coming-soon.png"
                alt=""
                className="app-icon "
              />
              <h4 className="Licence-label ScholarOne">Submission System</h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://img.icons8.com/color/48/000000/coming-soon.png"
                alt=""
                className="app-icon"
              />
              <h4 className="Licence-label ScholarOne">Comming Soon</h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
        </div>
        <div className="row app">
          <div className="col-md-12">
            <h3> CRM and Accounting System</h3>
          </div>
        </div>
        <div className="row app">
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://c1.sfdcstatic.com/content/dam/web/en_us/www/images/home/logo-salesforce-m.svg"
                alt=""
                className="app-icon"
              />

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://www.sageone.co.za/wp-content/themes/arcturus/images/logo-sage.svg"
                alt=""
                className="app-icon edi3"
              />
              <h4 className="Licence-label"> </h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="add-Licence-fields Licence-fields">
              <img
                src="https://www.xero.com/etc/designs/xero-cms/clientlib/assets/img/logo/logo-xero-blue.svg"
                alt=""
                className="app-icon"
              />
              <h4 className="Licence-label"> </h4>

              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Connect
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light licence-btn"
              >
                Revoke
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
