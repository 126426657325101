import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';

class AddImp extends BaseComponent {
    render() {
        return (
            <HubContent title="Add Organization">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Add Imprint details</a>
                                    </li>
                                </ul>


                                <div className="tab-content">
                                    <div className="tab-pane active p-3" id="home-1" role="tabpanel">

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Imprint</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="imprint name" />
                                        </div>

                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Domain</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder=" domain address @imprint.co.uk" />
                                        </div>

                                        <label className="col-sm-2 col-form-label">Parent publisher</label>
                                        <div className="col-sm-4">
                                            <select className="custom-select">
                                                <option selected>Parent publisher</option>
                                                <option defaultValue="Taylor & Francis">Taylor & Francis</option>
                                                <option defaultValue="Wiley">Wiley</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Address line 1</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="position" />
                                        </div>
                                        <label htmlFor="" className="col-sm-2 col-form-label">Address line 2</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="enter department" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">City/Town</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="City or Town" />
                                        </div>
                                        <label htmlFor="" className="col-sm-2 col-form-label">Post/Zip code</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="Poost/Zip code" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">County/State</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="County/State/Province" />
                                        </div>

                                        <label htmlFor="" className="col-sm-2 col-form-label">Country</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="country" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Website</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="website" />
                                        </div>

                                        <label htmlFor="" className="col-sm-2 col-form-label">VAT number</label>
                                        <div className="col-sm-4">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="VAT number" />
                                        </div>

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="" className="col-sm-2 col-form-label">Note</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="text" defaultValue="" id="" placeholder="Note" />
                                        </div>
                                    </div>
                                    <div className="text-center m-t-15">
                                        <button type="button" className="btn btn-primary waves-effect waves-light">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

export const AddImprint = AddImp;
