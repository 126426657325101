import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent, DataTable } from "../../../../../core/components";
import {
  SkeletonForm,
} from "../../../../../core/components/common";
import { getFaultyAuthorAction } from "../../../../actions";
class FaultyAuthor extends BaseComponent {

  state = {

  };
  componentDidMount() {
    this.getFaultyAuthor()
  }

  getFaultyAuthor = async (pageNum = 1) => {
    await this.props.getFaultyAuthorAction(pageNum);


    let heading = ["name", "affiliation", "email", "orcid_id"];
    let tableData = []

    this.props.faulty_author && this.props.faulty_author.results && this.props.faulty_author.results.map((data) => {
      return tableData.push({
        id: data.id,
        name: data.name,
        affiliation: data.affiliation,
        email: data.email,
        orcid_id: data.orcid_id,

      });
    });
    this.setState({
      tableData,
      heading,
      count: this.props.faulty_author && this.props.faulty_author.count
    })
  };

  pageChange = (pageNumber) => {
    this.getFaultyAuthor(pageNumber);
  };

  onEditFaultAuthor = (id) => {
    this.props.history.push(`/affiliation/update-faulty-author/${id}`)
  }
  // onViewFaultAuthor = (id)=>{
  //   this.props.history.push(`/affiliation/faulty-author-list/${id}`)
  // }

  render() {


    return (
      <HubContent >
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body profile">

                {this.state.count > 0 ? (
                  <DataTable
                    heading={this.state.heading}
                    data={this.state.tableData}
                    onEdit={this.onEditFaultAuthor}
                    action={false}
                    // onView={this.onViewFaultAuthor}
                    count={this.state.count}
                    pageChange={this.pageChange}
                    pageSize={10}
                  />

                ) : (
                    <SkeletonForm rows={4} />
                  )}

              </div>
            </div>
          </div>
        </div>

      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  faulty_author: state.articlefaultyAuthor.article_faulty_author_view || false,
});

const mapDispatchToProps = (dispatch) => ({
  getFaultyAuthorAction: (page) => dispatch(getFaultyAuthorAction(page)),
});

export const ArticleFaultyAuthor = connect(mapStateToProps, mapDispatchToProps)(FaultyAuthor);
