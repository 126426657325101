import React from "react";
import { HubContent } from "../../../../../../core/components";
import { Button } from "../../../../../../core/components/common";

class Message extends React.PureComponent {
  render() {
    const { subject, from_email = " ", recipients, date_created, html_message,from_user_email } =
      this.props.location && this.props.location.state;
    return (
      <HubContent>
        <div className="col-sm-12 email-history-content">
          <div className="vat-tax-button">
            <Button
              type="danger"
              name="danger"
              text="X"
              onClick={() =>  this.props.history.push({
                pathname: `/audit/audit-trail/${this.props.location && this.props.location.article_id}`,
                filter: this.props.location && this.props.location.filter,
                id: this.props.location && this.props.location.id

              })}
            />
          </div>
          <div className="row email-content">
            <div className="col-md-12 email-subject ">
              <p>Subject: {subject}</p>
            </div>
            <div className="col-md-6">
              <div className="email-box">
                <div className="email-icon">
                  <i className="mdi mdi-account"></i>
                </div>
                <div className="email-description">
         

<p>
<strong>from:</strong>  {`${from_email && from_email.split("<").slice(0, -1).length>0 ?`${from_email && from_email.split("<").slice(0, -1)},`: `${from_email.split("@")[0]},`}  <${from_user_email || from_email }>`}
</p>
<p>
<strong>to: </strong>
{recipients}
</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="email-date">
                {" "}
                <p>{new Date(date_created).toLocaleString()}</p>
              </div>
            </div>
          </div>

          <div
            className="email-content-message"
            dangerouslySetInnerHTML={{
              __html: html_message,
            }}
          ></div>
        </div>
      </HubContent>
    );
  }
}

export const AuditMessage = Message;
