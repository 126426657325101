import React from "react";
import { withRouter } from "react-router-dom";
import BaseComponent from "..";

class Btn extends BaseComponent {
  render() {

    let { action, text, color, notAllowed } = this.props;
    let name = "primary";
    let data = color ? color : text;
    switch (data && data.toLowerCase()) {
      case "highlight_action_approve": {
        name = "primary";
        break;
      }
      case "highlight_no_action_approved": {
        name = "green";
        break;
      }

      case "highlight_action_correction_request": {
        name = "secondary";
        break;
      }
      case "highlight_no_action_pending": {
        name = "primary";
        break;
      }
      case "highlight_action_oa_req": {
        name = "secondary";
        break;
      }
      case "highlight_action_non_oa_req": {
        name = "dark";
        break;
      }
      case "highlight_action_pay": {
        name = "indigo";
        break;
      }
      case "highlight_action_raise_invoice": {
        name = "pink";
        break;
      }
      case "highlight_no_action_invoiced": {
        name = "orange";
        break;
      }

      case "approve": {
        name = "primary";
        break;
      }
      case "rejected": {
        name = "danger";
        break;
      }
      case "approved": {
        name = "success";
        break;
      }
      case "reject": {
        name = "warning";
        break;
      }
      case "correction_request": {
        name = "secondary";
        break;
      }
      case "correction_requested": {
        name = "secondary";
        break;
      }
      case "correction_request_approve": {
        name = "secondary";
        break;
      }
      case "correction request": {
        name = "secondary";
        break;
      }
      case "apc fund requested": {
        name = "info";
        break;
      }
      case "non oa requested": {
        name = "info";
        break;
      }
      case "pending": {
        name = "primary";
        break;
      }
      case "request apc fund": {
        name = "secondary";
        break;
      }
      case "non oa request": {
        name = "secondary";
        break;
      }
      case "request no oa": {
        name = "secondary";
        break;
      }
      case "delete": {
        name = "danger";
        break;
      }
      case "create": {
        name = "primary";
        break;
      }
      case "upload": {
        name = "secondary";
        break;
      }
      case "download": {
        name = "primary";
        break;
      }
      case "pay": {
        name = "default";
        break;
      }
      case "payment update": {
        name = "primary";
        break;
      }
      case "Audit": {
        name = "secondary";
        break;
      }
      default: {
        name = "primary";
        break;
      }
    }

    return (
      <div className={this.props.actionClass ? this.props.actionClass : ""}>
        {notAllowed ? (
          !notAllowed.includes(action) && (

            <button
              type={this.props.type}
              className={`btn mb-2 btn-${name} btn-sm ${action !== "no_action" && "waves-effect"
                }`}
              onClick={(e) => {
                e.preventDefault();
                this.props.onClickHander && this.props.onClickHander(e);
              }}
              style={{ width: "130px", cursor: "default", whiteSpace: "normal" }}
            >
              {this.props.text}
            </button>

          )
        ) : this.props.action === "ACTION_AUDIT" ? (

          <button
            type="info"
            className={`btn mb-2 btn-info btn-sm`}
            style={{ width: "130px", cursor: "default", whiteSpace: "normal" }}
            onClick={() =>
              this.props.history.push(`/audit/audit-trail/${this.props.article_id}`)
            }
          >
            {this.props.text}
          </button>

        ) : this.props.text === "Approved" ? (

          <button
            type={this.props.type}
            className={`btn mb-2 btn-${name} btn-sm ${action !== "no_action" && "waves-effect"
              }`}
            onClick={(e) => {
              e.preventDefault();
              this.props.onClickHander && this.props.onClickHander(e);
            }}
            style={{ width: "130px", cursor: "default", whiteSpace: "normal" }}
          >
            {this.props.text}
          </button>

        ) : this.props.text !== "Approved" ? (

          <button
            type={this.props.type}
            className={`btn mb-2 btn-${name} btn-sm ${action !== "no_action" && "waves-effect"
              }`}
            onClick={(e) => {
              e.preventDefault();
              this.props.onClickHander && this.props.onClickHander(e);
            }}
            style={{ width: "130px", cursor: "default", whiteSpace: "normal" }}
          >
            {this.props.text}
          </button>

        ) : null
        }
      </div>
    );
  }
}

export const ActionButton = withRouter(Btn);
