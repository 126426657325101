import React from "react";
import { HubContent } from "../../../../../core/components";
import { connect } from "react-redux";
import { SelectBox } from "../../../../../core/components/common";
import {
  createDashboardDataControl,
  getDashboardDataControl,
  getGroupList,
  getOrgList,
  updateDashboardDataControl,
  getDashboardDataControlSingle,
  getDashboardLevelList,
  getPreimumDataList
} from "../../../../actions";
import { Checkbox } from "antd";
import { dashboardcompontents } from "../../../../components/common/userPermission";
const CheckboxGroup = Checkbox.Group;

class DashboardControlUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      dashboard_options: [],
      dashboardOptions: ["BASIC", "PREMIUM"],
      checkedList: [],
      checkedPremiumList: [],
      indeterminate: true,
      checkAll: false,
      permissionValue: [],
      groupKey: [],
    };
  }
  async componentDidMount() {
    if (this.props.match.params.id) {
      let data = await this.props.getDashboardDataControlSingle(
        this.props.match.params.id
      );
      let quick_sight_list = await this.props.getPreimumDataList()

      let dataCompontent = await this.props.getDashboardLevelList(
        data.organisation
      );



      this.setState({
        checkedPremiumList: data && data.premium_dashboards,
        dashboard_options: data.dashboard_options,
        premiumDashBoardItemList: quick_sight_list && quick_sight_list.results,
        user: {
          organisation: data.organisation,
          group: data.group.id,
        },
        permissionValue: Object.entries(dataCompontent.components).map(
          ([key, value]) => key
        ),
        groupKey: Object.entries(dataCompontent.components).map(
          ([key, value]) => ({
            label: value.label,
            key: key,
          })
        ),
        checkedList: Object.entries(data.components).map(([key, value]) => key),
      });
      await this.props.getOrgList({ group: data.group.id });
      this.props.getGroupList();

    } else {
      if (
        this.props.group !== "INSTITUTION" &&
        this.props.group !== "PUBLISHER"
      ) {
        let quick_sight_list = await this.props.getPreimumDataList()
        this.setState({
          premiumDashBoardItemList: quick_sight_list.results,
        })
        this.props.getGroupList();
      }
    }



  }
  onChangeHandler = async (item) => {
    let data = await this.props.getDashboardLevelList(item.organisation);
    this.setState({
      permissionValue: Object.entries(data.components).map(
        ([key, value]) => key
      ),
      groupKey: Object.entries(data.components).map(([key, value]) => ({
        label: value.label,
        key: key,
      })),
    });
    this.setState((prevState) => {
      return Object.assign(prevState.user, item);
    });
  };

  // using this event handler
  onChangeGroupHandler = async (value) => {
    this.setState((prevState) => {
      prevState.user = Object.assign(prevState.user, value);
      return prevState;
    });
    const org = value.group && (await this.props.getOrgList(value));
    org &&
      org.status &&
      this.setState((prevState) => {
        prevState.orgStatus = true;
        return prevState;
      });
  };

  onChange = (checkedList) => {
    this.setState({
      checkedList,
    });
  };

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? this.state.permissionValue : [],
    });
  };

  onCheckItem = (value) => (e) => {
    this.setState({
      checkedList: this.state.checkedList.includes(value)
        ? this.state.checkedList.filter((x) => x !== value)
        : [...this.state.checkedList, value],
    });
  };

  onCheckPremiumItem = (value) => (e) => {
    this.setState({
      checkedPremiumList: this.state.checkedPremiumList.includes(value)
        ? this.state.checkedPremiumList.filter((x) => x !== value)
        : [...this.state.checkedPremiumList, value],
    });
  };
  onSubmitHandler = async () => {
    let mainData = Object.fromEntries(
      this.state.checkedList && this.state.checkedList.map((key) => [key, {}])
    );
    let data = {
      dashboard_options: this.state.dashboard_options,
      organisation: this.state.user.organisation,
      components: mainData,
      premium_dashboards: this.state.checkedPremiumList
    };

    if (this.props.match.params.id) {
      this.props.updateDashboardDataControl(this.props.match.params.id, data);

      this.props.history.push("/settings/dashboard-control-list");
    } else {
      this.props.createDashboardDataControl(data);

      this.props.history.push("/settings/dashboard-control-list");
    }
  };
  onChangeDashboard = (dashboard_options) => {
    console.log(dashboard_options)
    this.setState({
      dashboard_options,
      indeterminate:
        !!dashboard_options.length &&
        dashboard_options.length < this.state.dashboard_options.length,
    });
  };

  render() {
    const { checkedList } = this.state;
    let groupKey;
    if (this.state.groupKey) {
      groupKey = dashboardcompontents.filter(
        (item) =>
          this.state.groupKey &&
          this.state.groupKey.some((findItem) => findItem.key === item.key)
      );
    }

    return (
      <HubContent>
 

        <div className="user-permission-content dashboard-control" style={{padding:30}}>
          <div className="form-group row">
            <SelectBox
              label="Group"
              onChange={this.onChangeGroupHandler}
              field="group"
              data={this.props.groups}
              isRequired={true}
              defaultValue={this.state.user.group}
            />
            <SelectBox
              label="Organisation"
              onChange={this.onChangeHandler}
              field="organisation"
              data={this.props.orgs || ["Loading"]}
              isRequired={true}
              onRestFields={this.onChangeEmailDomain}
              defaultValue={this.state.user ? this.state.user.organisation : ""}
              isData={true}
            />
          </div>
          {this.state.groupKey && this.state.groupKey.length > 0 &&
            <div className="form-group row">
              <div className="col-md-12 permission-checkbox">
                <div className="uermission-key">
                  <CheckboxGroup
                    options={this.state.dashboardOptions}
                    value={this.state.dashboard_options}
                    onChange={this.onChangeDashboard}
                  />
                </div>
              </div>
            </div>}
          {
            this.state.dashboard_options.length > 0 &&
            <div className="form-group row">
              <div className="col-md-12 permission-checkbox">
                <ul
                  className="nav nav-pills nav-justified"
                  role="tablist"
                  style={{ marginBottom: 15 }}
                >
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Basic
                  </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Premium
                  </a>
                  </li>
                </ul>
              </div>
            </div>
          }
          <div className="tab-content">
            <div className="tab-pane active p-3" id="home-1" role="tabpanel">
              <div className="user-permission">
    

                <div className="permission-checkbox">
                  <div className="uermission-key">
                    {groupKey &&
                      groupKey.map((item, idx) => (
                        <div className="userpermission-content" key={idx}>
                          <div className="content-item">
                            <div className="item">
                              <label>{item.label}</label>
                            </div>
                            <div className="item-checkbox">
                              <Checkbox
                                key={item.key}
                                onChange={this.onCheckItem(item.key)}
                                checked={
                                  checkedList && checkedList.includes(item.key)
                                }
                              >
                                {item.key}
                              </Checkbox>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

              </div>
            </div>
            <div className="tab-pane p-3" id="profile-1" role="tabpanel">
  
              <div className="permission-checkbox">
                <div className="uermission-key">
                  {this.state.premiumDashBoardItemList &&
                    this.state.premiumDashBoardItemList.map((item, idx) => (
                      <div className="userpermission-content" key={idx}>
                        <div className="content-item">
                          <div className="item">
                            <label>{item.name}</label>
                          </div>
                          <div className="item-checkbox">
                            <Checkbox
                              key={item.id}
                              onChange={this.onCheckPremiumItem(item.id)}
                              checked={
                                this.state.checkedPremiumList && this.state.checkedPremiumList.includes(item.id)
                              }
                            >
                              {item.id}
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>{" "}
            </div>
          </div>
          <div className="text-center m-t-15">
            <button
              className="btn btn-primary btn-md waves-effect waves-light"
              onClick={this.onSubmitHandler}
            >
              {this.props.match.params.id
                ? "Edit DashboardControl"
                : " Add DashboardControl"}
            </button>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.app.groups,
  orgs: state.app.organizations,
  group: (state.auth && state.auth.group) || false,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardDataControl: (payload) =>
    dispatch(getDashboardDataControl(payload)),
  createDashboardDataControl: (payload) =>
    dispatch(createDashboardDataControl(payload)),
  getOrgList: (payload) => dispatch(getOrgList(payload)),
  getDashboardDataControlSingle: (id) =>
    dispatch(getDashboardDataControlSingle(id)),
  updateDashboardDataControl: (id, payload) =>
    dispatch(updateDashboardDataControl(id, payload)),
  getGroupList: (payload) => dispatch(getGroupList(payload)),
  getPreimumDataList: () => dispatch(getPreimumDataList()),
  getDashboardLevelList: (id) => dispatch(getDashboardLevelList(id)),
});

export const DashboardControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardControlUI);
