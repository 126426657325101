import React from 'react';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { OrgSingle } from '../../_multiaccess';

class Pubs extends BaseComponent {
  render() {
    return (
      <OrgSingle
        OrgID={this.props.match.params.id}
        type="university"
      />
    )
  }
}



export const PubSingle = Pubs