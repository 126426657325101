import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { InputBox, SelectBox } from "../../../../../core/components/common";

class DB extends BaseComponent {
  render() {
    return (
      <HubContent title="Allocate offset/tokens">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="card m-b-20">
              <div className="card-body">
                <form action="">
                  <div className="form-group row">
                    <InputBox
                      className="col-sm-8"
                      label="University"
                      labelClass="col-sm-4"
                      onChange={this.onChangeHandler}
                      field="salute"
                      placeholder="auto complete university"
                    />
                  </div>

                  <div className="form-group row">
                    <SelectBox
                      className="col-sm-8"
                      label="Allocate"
                      labelClass="col-sm-4"
                      onChange={this.onChangeHandler}
                      field="Select"
                      data={this.props.roles}
                      isRequired={false}
                    />
                  </div>

                  <div className="form-group row">
                    <SelectBox
                      className="col-sm-8"
                      label="Currency"
                      labelClass="col-sm-4"
                      onChange={this.onChangeHandler}
                      field="GBP"
                      data={this.props.roles}
                      isRequired={false}
                    />
                  </div>

                  <div className="form-group row">
                    <InputBox
                      className="col-sm-8"
                      label="Amount or qty"
                      labelClass="col-sm-4"
                      onChange={this.onChangeHandler}
                      field="salute"
                      placeholder="placeholder"
                    />
                  </div>

                  <div className="form-group float-right">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

export const AllocateOffsetTokens = DB;
