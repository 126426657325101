import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import {
  getOrganisationToken,
  getOrganisationTokenDownloadlist
} from "../../../../actions";
import {
  Loading,
} from "../../../../../core/components/common";
import NotFound from "../../NotFound";
import { serialize } from "../../../../../core/lib";

class OrgToken extends BaseComponent {
  state = {
    deleteConfirm: false,
    waitingMsg: null,
    deleteStatus: null,
    state: [],
    filterParams: {page:1},
    page:1
  };
  getOrgTokenData = async (pageNum = 1) => {
    let value = {
      page: pageNum,
  };

  await this.setState((prevState) => {
    prevState.filterParams = Object.assign(prevState.filterParams, value);

  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getOrganisationToken({ params, filter });


    let OffsetFundList = this.props.OrgTokenList.results;
    let dataOrgToken = [];
    OffsetFundList.map(item => {
      return dataOrgToken.push({
        organisation: item.organisation_name,
        reference: item.token_details.name,
        "Cur/Percent": item.token_details.reward_currency,
        instituation: item.institution_name ? item.institution_name : "-",
        token_id: item.token_info,
        "Amt/Dis": item.token_details.reward
          ? item.token_details.reward && item.token_details.reward.amount
          : "-",
        valid_from: item.token_details.valid_from
          ? item.token_details.valid_from
          : "-",
        valid_to: item.token_details.valid_to
          ? item.token_details.valid_to
          : "-",
        status: item.is_used ? "Already used" : "Not used",
        id: item.id
      });
    });
    this.setState({
      data: dataOrgToken,
      count: this.props.OrgTokenList.count
    });
  };
   componentDidMount() {
    this.getOrgTokenData();
  }
  pageChange =async (pageNumber) => {
    let value = {
      page: pageNumber,
  };

  await this.setState((prevState) => {
    prevState.filterParams = Object.assign(prevState.filterParams, value);

  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.getOrganisationToken({ params, filter });

    let OffsetFundList = this.props.OrgTokenList.results;
    let dataOrgToken = [];
    OffsetFundList.map(item => {
      return dataOrgToken.push({
        organisation: item.organisation_name,
        reference: item.token_details.name,
        "Cur/Percent": item.token_details.reward_currency,
        instituation: item.institution_name ? item.institution_name : "-",
        token_id: item.token_info,
        "Amt/Dis": item.token_details.reward
          ? item.token_details.reward && item.token_details.reward.amount
          : "-",
        valid_from: item.token_details.valid_from
          ? item.token_details.valid_from
          : "-",
        valid_to: item.token_details.valid_to
          ? item.token_details.valid_to
          : "-",
        status: item.is_used ? "Already used" : "Not used",
        id: item.id
      });
    });
    this.setState({
      page:pageNumber,
      data: dataOrgToken,
      count: this.props.OrgTokenList.count
    });
  };

  onSearchfilterData = async (value) => {
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = await serialize(this.state.filterParams);
    let params = await serialize(this.state.params);
    await this.props.getOrganisationToken({ params, filter });
    let OffsetFundList = this.props.OrgTokenList.results;
    let dataOrgToken = [];
    OffsetFundList.map(item => {
      return dataOrgToken.push({
        organisation: item.organisation_name,
        reference: item.token_details.name,
        "Cur/Percent": item.token_details.reward_currency,
        instituation: item.institution_name ? item.institution_name : "-",
        token_id: item.token_info,
        "Amt/Dis": item.token_details.reward
          ? item.token_details.reward && item.token_details.reward.amount
          : "-",
        valid_from: item.token_details.valid_from
          ? item.token_details.valid_from
          : "-",
        valid_to: item.token_details.valid_to
          ? item.token_details.valid_to
          : "-",
        status: item.is_used ? "Already used" : "Not used",
        id: item.id
      });
    });
    this.setState({
      data: dataOrgToken,
      count: this.props.OrgTokenList.count
    });
};

  onViewToken = id => {

    this.props.history.push(`/institute/token-view/${id}`)
};


  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      waitingMsg: null,
      deleteStatus: null
    });
  };
  onArticleDownloadClick = async () => {
    try {
      await this.props.getOrganisationTokenDownloadlist();
      const url = window.URL.createObjectURL(this.props.token_download_inst);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `oa_token_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  render() {
    const heading = [
      "organisation",
      "instituation",
      "reference",
      "token_id",
      "Cur/Percent",
      "Amt/Dis",
      "valid_from",
      "valid_to",
      "status"
    ];
    return this.state.status === 403 ? (
      <NotFound />
    ) : (
        <HubContent
          title="Tokens"
          onArticleDownloadClickHandler={this.onArticleDownloadClick}
        >



          <div className="row filter">
           
            <div className="col-12">
              <div className="card m-b-20">
                {this.state.data ? (
                  <DataTable
                    heading={heading}
                    data={this.state.data}
                    onView={this.onViewToken}
                    count={this.state.count}
                    current={this.state.page}
                    pageChange={this.pageChange}
                    pageSize={10}
                  />
                ) : (
                    <Loading />
                  )}
              </div>
            </div>
          </div>
        </HubContent>
      );
  }
}

const mapStateToProps = state => ({
  OrgTokenList: state.oaToken.organisation_token_list || false,
  token_download_inst: state.oaToken.token_download_inst || false
});

const mapDispatchToProps = dispatch => ({
  getOrganisationToken: payload => dispatch(getOrganisationToken(payload)),
  getOrganisationTokenDownloadlist: () =>
    dispatch(getOrganisationTokenDownloadlist())
});

export const OrgTokenList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgToken);