import React from 'react';
import BaseComponent from '..';

class InvoiceComponent extends BaseComponent {
  state = {
    msg: this.props.errMsg || '',
    status: false
  }



  render() {
    return(
      <>
        Hello Invoice
      </>
    )
  }
}

export const InvoiceModal = InvoiceComponent;