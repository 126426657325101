
import {
  REQUEST_CREATE_SOCIETY_MEMBER, GET_SOCIETY_LIST, REQUEST_UPDATE_SOCIETY_MEMBER, GET_ALL_SOCIETY_MEMBER, SINGLE_SOCIETY_MEMBER

  , FETCH_OFFER_DETAILS, SUCCESS_OFFER_DETAILS, FAIL_OFFER_DETAILS
} from "../../config";

export default (state = {
  create_society_membership: {},
  get_all_society_membership: {},
  update_society_membership: {},
  isloading: false,
  single_society_membership: {},
}, action) => {
  switch (action.type) {
    case FETCH_OFFER_DETAILS:
      return (state = {
        ...state,
        isloading: true
      })

    case SUCCESS_OFFER_DETAILS:
      return (state = {
        ...state,
        offer_details: action.payload,
        isloading: false,
      })
    case FAIL_OFFER_DETAILS:
      return (state = {
        ...state,
        offer_details: action.payload,
        isloading: false
      })
    case REQUEST_CREATE_SOCIETY_MEMBER:
      return (state = {
        ...state,
        create_society_membership: action.payload,
      })

    case GET_ALL_SOCIETY_MEMBER:
      return (state = {
        ...state,
        get_all_society_membership: action.payload,
      })
    case REQUEST_UPDATE_SOCIETY_MEMBER:
      return (state = {
        ...state,
        deposit_pub: action.payload,
      })
    case SINGLE_SOCIETY_MEMBER:
      return (state = {
        ...state,
        single_society_membership: action.payload,
      })

    case GET_SOCIETY_LIST:
      return (state = {
        ...state,
        societies: action.payload,
      })

    default:
      return state
  }
}