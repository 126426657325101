import { CREATE_CONTENT_TYPE,DELETE_CONTENT_TYPE_DATA,UPDATE_CONTENT_TYPE_DATA, UPLOAD_CONTENT_TYPE } from "../../config";

const ContentReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CONTENT_TYPE: 
            return state = {
                ...state,
                success_content_type: action.payload
            }
        case DELETE_CONTENT_TYPE_DATA: 
            return state = {
                ...state,
                success_delete_type: action.payload
            }
        case UPDATE_CONTENT_TYPE_DATA: 
            return state = {
                ...state,
                success_update_type: action.payload
            }
        case UPLOAD_CONTENT_TYPE: 
            return state = {
                ...state,
                content_type_file_upload: action.payload,
            }
        default:
            return state;
    }
}
export default ContentReducer;
