import React, { Component } from 'react'
import {
    RadioButtonGroup,
} from "../../../../../../core/components/common";
import VatTable from "../vatTable";

import MultiSelectCheckBox from "../../../../../../core/components/common/MultiSelectCheckBox";
class RestOfTheCountry extends Component {
    render() {
        return (
            <div className="vat-detail-form">
            <p className="eu_vat_para">
              Let's setup VAT/tax for countries in the rest of the world.
            </p>

            <div className="vat-question-panel">
              <p className="vat_charge">
                Are there any other countries for which you waive the VAT or
                Sales Tax when they provide a valid VAT/Tax number?
              </p>
              <div className="vat_charge">
                <RadioButtonGroup
                  onChange={this.props.onChangeHandleRadioBox}
                  field="waive_countries_charge_vat"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.props.waive_countries_charge_vat}
                />
              </div>
            </div>

            {this.props.waive_countries_charge_vat === true && (
              <div>
                <div className="vat-country">
                  <div className="vat-country-details">
                    <div className="vat-info">
                      <p>Select countries from below dropdown.</p>
                    </div>
                    <div className="country-list">
                      <MultiSelectCheckBox
                        field="STATES"
                        array={this.props.waiverscountrieslist }
                        data={this.props.other_countries}
                        selectCountry={(props) =>
                          this.props.selectCountrywaiveCountry(props)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 vat-margin">
                    <VatTable
                      data={this.props.waiverscountries}
                      editHandler={(value) => this.props.editHandler_waiver_1(value)}
                      field="REST"
                    />
                  </div>
                </div>

                <div className="vat-question-panel">
                  <p>
                    Is there any other countries that you would like to add VAT
                    or Sales Tax?
                  </p>

                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="other_countries_vat_charge_1"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.other_countries_vat_charge_1}
                  />
                </div>
              </div>
            )}

            {this.props.waive_countries_charge_vat === false && (
              <div className="vat-question-panel">
                <p>
                  Is there any other countries that you would like to add VAT or
                  Sales Tax?
                </p>

                <RadioButtonGroup
                  onChange={this.props.onChangeHandleRadioBox}
                  field="vat_tax_other_countries_charge2"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.props.vat_tax_other_countries_charge2}
                />
              </div>
            )}
            {this.props.waive_countries_charge_vat !== true
              ? this.props.vat_tax_other_countries_charge2 === true && (
                <div>
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          array={this.props.SalesOrVatTaxes}
                          data={this.props.other_countries}
                          selectCountry={(props) =>
                            this.props.selectCountryVATorTaxesCountry2(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={this.props.restVatOrTaxesApiData_2}
                        editHandler={(value) =>
                          this.props.editHandler_rest_2(value)
                        }
                      />

                    </div>
                  </div>

                  <div className="vat-question-panel">
                    <p>
                      Is there any countries that you Charge VAT based on
                      customer's billing address?
                      </p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charge_policy_other_coutries_2"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charge_policy_other_coutries_2}
                    />
                  </div>
                </div>
              )
              : null}

            {this.props.waive_countries_charge_vat !== false
              ? this.props.other_countries_vat_charge_1 === true && (
                <div>
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          array={this.props.SalesOrVatTaxes2}
                          data={this.props.other_countries}
                          selectCountry={(props) =>
                            this.props.selectCountryVATorTaxesCountry(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={this.props.restVatOrTaxesApiData_1}
                        editHandler={(value) =>
                          this.props.editHandler_rest_1(value)
                        }
                      />

                    </div>
                  </div>

                  <div className="vat-question-panel">
                    <p>
                      Is there any countries that you Charge VAT based on
                      customer's billing address?
                      </p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charge_policy_other_coutries_1"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charge_policy_other_coutries_1}
                    />
                  </div>
                </div>
              )
              : null}
            {this.props.waive_countries_charge_vat !== false ? (
              this.props.other_countries_vat_charge_1 !== false ? (
                this.props.charge_policy_other_coutries_1 === true ? (
                  <div>
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix.
                            You can override default GST/VAT/Tax rate to apply
                            correct one.
                          </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            array={this.props.vat_charge_billing_tax2}
                            data={this.props.other_countries}
                            selectCountry={(props) =>
                              this.props.selectCountrybillingaddressCountry(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.VatRestBillingApiData_1}
                     
                          field="RESTOFTHEWORLD"
                        />
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}
            {this.props.waive_countries_charge_vat !== true ? (
              this.props.vat_tax_other_countries_charge2 !== false ? (
                this.props.charge_policy_other_coutries_2 === true ? (
                  <div>
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix.
                            You can override default GST/VAT/Tax rate to apply
                            correct one.
                          </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                             array={this.props.vat_charge_billing_tax}
                            field="STATES"
                            data={this.props.other_countries}
                            selectCountry={(props) =>
                              this.props.selectCountrybillingaddressCountry2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <div className="card m-b-20">
                          <VatTable
                            editHandler={(value) =>
                              this.props.editHandler_rest_4(value)
                            }
                            data={this.props.VatRestBillingApiData_2}
                            field="RESTOFTHEWORLD"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}
          </div>
        )
    }
}

export const VatRestOfTheCountry= RestOfTheCountry