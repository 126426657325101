import React from "react";
import { connect } from "react-redux";
import { AutoComplete, } from "antd";
import BaseComponent, { HubContent } from "../../../../../../core/components";

import {
    sendFreeTextEmail,
    searchArticleFull,
} from "../../../../../actions";

import {
    SelectBox,
    Loading,
    TextBox,
    InputBox,

} from '../../../../../../core/components/common';

export class CustomEmail extends BaseComponent {
    state = {}


    cancelLoader = async () => {
        this.setState({
            isEmpty: false,
            successMsg: false,
        })
    }



    onSelect = (value, item) => {
        this.setState({
            article_id: item.id,
        });
    };

    onSearch = (value) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            let params = {
                q: value,
            };

            this.props.searchArticleFull(params).then(response => {
                if (response && response.success) {
                    this.setState({
                        dataList: response.results && response.results.map(item => ({
                            id: item.id,
                            value: item.title,
                        }))
                    });
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });

        }, 200);
    };

    onChangeHandler = (value) => {

        if (value && value.context === 'ARTICLE') {
            this.props.searchArticleFull({}).then(response => {
                if (response && response.success) {
                    this.setState({
                        dataList: response.results && response.results.map(item => ({
                            id: item.id,
                            value: item.title,
                        }))
                    });
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });
        }

        this.setState((prevState) => {
            return Object.assign(prevState, value);
        });
    };

    onSubmitHandler = async (e) => {

        let data = {
            context: this.state.context,
            article_id: this.state.context === 'ARTICLE' ? this.state.article_id : null,
            subject: this.state.subject,
            recipients: this.state.recipients,
            message_body: this.state.message_body,
        };

        if (!data.context || !data.subject || !data.recipients || !data.message_body) {
            this.setState({
                isEmpty: <div>
                    {!data.context && <p>Context is required.</p>}
                    {(data.context === 'ARTICLE' && !data.article_id) && <p>Context is required.</p>}
                    {!data.recipients && <p>Recipient is required.</p>}
                    {!data.subject && <p>Subject is required.</p>}
                    {!data.message_body && <p>Message is required.</p>}
                </div>
            })
        } else {

            this.props.sendFreeTextEmail(data).then(response => {
                if (response.data && response.data.success) {
                    this.setState({
                        showCustomEmailForm: false,
                        successMsg: response.data.message,
                        context: '',
                        article_id: '',
                        subject: '',
                        recipients: '',
                        message_body: '',
                    })
                    this.props.history.push("/custom-email/email-custom-log")
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });
        }
    };

    onCustomEmailButtonClick = () => {
        this.setState({
            showCustomEmailForm: true,
        })
    }

    renderCustomEmailButton = () => {
        return (
            <li className="d-none d-sm-block pr-3">
                <div className="pt-3">
                    <button onClick={this.onCustomEmailButtonClick} className="btn btn-info w-md waves-effect waves-light" type="button"><b>Custom email</b></button>
                </div>
            </li>
        )
    }

    renderCustomEmailForm = () => {
        return (
            <form className="apc-form">
                {this.state.loading && <Loading />}

                <div className="form-group row">
                    <SelectBox
                        placeholder="Select from dropdown"
                        label="Relevance"
                        onChange={this.onChangeHandler}
                        field="context"
                        className="col-sm-10"
                        data={[{ id: 'ARTICLE', name: 'Article' }, { id: 'NON_ARTICLE', name: 'Non Article' }]}
                        defaultValue={this.state.context}
                        isRequired={true}
                    />
                </div>

                {
                    this.state.context === 'ARTICLE' &&
                    <div className="form-group row">

                        <label className="col-form-label col-sm-2">
                            Article
                  <span className="text-danger"> * </span>
                        </label>

                        <div className="col-sm-10">

                            <AutoComplete
                                defaultValue={this.state.article_id}
                                notFoundContent="No found Data"
                                options={this.state.dataList || []}
                                style={{
                                    width: "100%",
                                }}
                                onSelect={(val, option) => this.onSelect(val, option)}
                                onSearch={this.onSearch}
                                onChange={this.onChangeHandler}
                                placeholder="Type your article name"
                            />
                        </div>
                    </div>
                }

                <div className="form-group row">
                    <InputBox
                        label="Recipient"
                        onChange={this.onChangeHandler}
                        className="col-sm-10"
                        field="recipients"
                        placeholder="johndoe@test.com (use comma for multiple recipients)"
                        defaultValue={this.state.recipients}
                        isRequired={true}
                    />
                </div>

                <div className="form-group row">
                    <InputBox
                        label="Subject"
                        onChange={this.onChangeHandler}
                        className="col-sm-10"
                        field="subject"
                        placeholder="Enter your email subject"
                        defaultValue={this.state.subject}
                        isRequired={true}
                    />
                </div>

                <div className="form-group row">
                    <TextBox
                        rows={8}
                        label="Message"
                        onChange={this.onChangeHandler}
                        field="message_body"
                        className="col-sm-10"
                        placeholder="Enter your email message"
                        defaultValue={this.state.message_body}
                        isRequired={true}
                    />
                </div>
            </form>
        )
    }
      // help menu function 
  HelpButtonClick = () => {
    const url = '/links';
    window.open(url, '_blank');
    
  }
    render() {


        return (
            <HubContent title="Send custom email" onHelpHandler={ this.HelpButtonClick}>
                <div className="card m-b-20">
                    <div className="card-body">

                        <div className="modal-body">
                            {this.renderCustomEmailForm()}

                        </div>

                        <div className="text-center"> <button type="button" className="btn btn-primary"
                            onClick={this.onSubmitHandler}
                        >Send</button></div>
                    </div>
                </div>

            </HubContent >
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    group: state.auth.user && state.auth.group,
    kind: state.auth.user && state.auth.user.kind,
    token: state.auth.token,
    articles: state.articles.article_full,
});

const mapDispatchToProps = (dispatch) => ({
    sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
    searchArticleFull: (payload = {}) => dispatch(searchArticleFull(payload)),
});

export const SendCustomEmail = connect(mapStateToProps, mapDispatchToProps)(CustomEmail);
