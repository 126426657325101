import React from "react";
import BaseComponent from "..";
import { DatePicker,Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

import moment from "moment";

const format = "Do MMM YYYY";

class Input extends BaseComponent {
  state = {
    msg: this.props.errMsg || "",
    date: this.props.date || new Date(),
  };

  onChange = (value) => {
    this.setState({ value });
    this.props.onChange && this.props.onChange({ [this.props.field]: value });
  };
  componentDidUpdate() {
    this.props.errMsg &&
      setTimeout(() => {
        this.setState({
          msg: this.props.errMsg,
        });
      }, 300);
  }

  render() {
    return this.props.isPublic ? (
      <>
        <label
          htmlFor={this.props.field || ""}
          className={`${this.props.labelClass || ""}`}
        >
          {this.props.label ? this.props.label : ""}{" "}
          {this.props.isRequired && <span className="text-danger"> * </span>}
        </label>
        <div style={{ position: "relative" }}>
          {this.state.msg && (
            <span
              className="text-danger text-small"
              style={{
                fontSize: 12,
                position: "absolute",
                right: 20,
                top: -10,
                backgroundColor: "#fff",
                padding: "0px 10px",
                zIndex: 2,
              }}
            >
              {this.state.msg}
            </span>
          )}

          {this.props.errMsg && (
            <span
              className="text-danger text-small"
              style={{
                fontSize: 12,
                position: "absolute",
                right: 20,
                top: -10,
                backgroundColor: "#fff",
                padding: "0px 10px",
                zIndex: 2,
              }}
            >
              {this.props.errMsg}
            </span>
          )}

          <DatePicker
            format={format}
            defaultValue={moment(new Date(), format)}
            placeholder={this.props.placeholder}
            allowClear
            size="large"
            style={{ width: "100%" }}
            id={this.props.field || ""}
            disabled={this.props.disabled || false}
            onChange={this.onChange}
          />
        </div>
      </>
    ) : (
        <>
          {this.props.label && (
            <div    className={`col-sm-2 col-form-label ${this.props.labelClass || ""}`}
            >
                   <label
              htmlFor={this.props.field || ""}
            >
              {this.props.label ? this.props.label : ""}{" "}
              {this.props.isRequired && <span className="text-danger"> * </span>}
            </label>
    {
      this.props.labelInfo && <Tooltip title={this.props.labelInfoTitle}>
      <InfoCircleTwoTone style={{
          fontSize: 18,
          marginLeft:15
      }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
          onClick={this.handleInstMessage}
      />
      </Tooltip>
   }
       
       </div>     

          )}

          <div
            className={` ${this.props.className ? this.props.className : "col-sm-4"
              }`}
            style={{ position: "relative" }}
          >
            {this.state.msg && (
              <span
                className="text-danger text-small"
                style={{
                  fontSize: 12,
                  position: "absolute",
                  right: 20,
                  top: -10,
                  backgroundColor: "#fff",
                  padding: "0px 10px",
                  zIndex: 2,
                }}
              >
                {this.state.msg}
              </span>
            )}

            {this.props.errMsg && (
              <span
                className="text-danger text-small"
                style={{
                  fontSize: 12,
                  position: "absolute",
                  right: 20,
                  top: -10,
                  backgroundColor: "#fff",
                  padding: "0px 10px",
                  zIndex: 2,
                }}
              >
                {this.props.errMsg}
              </span>
            )}


            <DatePicker
              format={this.props.format || format}
              defaultValue={this.props.defaultValue}
              showTime={this.props.showTime || false}
              allowClear
              size="large"
              placeholder={this.props.placeholder}
              style={{ width: "100%" }}
              popupStyle={{ zIndex: 99999 }}
              id={this.props.field || ""}
              disabled={this.props.disabled || false}
              onChange={this.onChange}
            />
          </div>
        </>
      );
  }
}

export const OaDatePicker = Input;