import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { connect } from 'react-redux';
import { PopupBox } from '../../../../../core/components/common';


class OaTarget extends BaseComponent {
  state = {
    
  }

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.journal, value)
    })
  }

  onSubmitHandler = async (journal) => {
    
  }

  componentDidMount() {
    
  }

  render() {
    let errorHander = <>
      {this.state.isEmpty && <PopupBox
        Title="Following field cannot be empty"
        msg={this.state.isEmpty}
        onCancel={this.cancelLoader}
      />}
    </>
    return (
      <HubContent title="Add OA target">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Enter OA target details</a>
                  </li>
                  
                </ul>

                <div className="tab-content">
                  <div className="tab-pane active p-3" id="home-1" role="tabpanel">
                    <h6>Add OA target</h6>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    )
  }
}


const mapStateToProps = (state) => ({
  
})
const mapDispatchToProps = (dispatch) => ({
  
})
export const AddOaTarget = connect(mapStateToProps, mapDispatchToProps)(OaTarget);

