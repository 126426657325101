import {
  ArticleFull,
  OAPolicy,
  Uapcf,
  ProfileUser,
  ArticleCustomEmailMessage,
  ArticleCustomSendEmailMessage,
  ArticleCustomEmail,
  UserHelpMenu,
  PaymentHistory,
  InvoiceHistory,
  ArticleOutBoxEmail,
  SendCustomEmail,
} from "../../openaccess/components/views/user";

import AddPayment from "../components/partials/payment/addPayment.jsx";
import { ApcRequestList } from "../components/views/university";

export const root = {
  dashboard: "/",
  articles: "/my-article",
  payment: "/payment",
  profileuser: "/user-profile",
  custom_email: "/author-custom-email",
  help_menu: "/author-help-menu",
  refund_request: "/author-refund",
  author_request: "/author-request",
};

export const UserSidebar = [
  {
    icon: "mdi-pencil-box",
    label: "My Articles",
    to: root.articles,
    exact: true,
    component: ArticleFull,
  },
  {
    icon: " ion-clipboard",
    label: "OA Policy",
    isHide: true,
    to: "/oa-policy",
    exact: true,
    component: OAPolicy,
  },
  {
    to: `${root.profileuser}/:id`,
    exact: true,
    component: ProfileUser,
    isHide: true,
  },
  {
    to: "/request-apc-fund/:id",
    exact: true,
    component: Uapcf,
    isHide: true,
  },
  {
    icon: "mdi-book-multiple",
    label: "My APC Requests",
    exact: true,
    to: root.author_request,
    showBadge: false,
    component: ApcRequestList,
  },
  {
    icon: "mdi-cart",
    label: "Transactions",
    exact: true,
    to: root.payment,
    content: [
      {
        label: "Invoice",
        to: "/author-invoice-history",
        component: InvoiceHistory,
      },
      {
        label: "Addpayment",
        to: "/addpayment/:id",
        component: AddPayment,
        isHide: true,
      },
      {
        label: "Card Payment",
        to: "/author-payment-history",
        component: PaymentHistory,
      },
    ],
  },
  // {
  //     icon: "mdi-send",
  //     label: "Refund request",
  //     exact: true,
  //     to: root.refund_request,
  //     content: [
  //         {
  //             label: "Add Refund Request",
  //             to: "/author-refund-request",
  //             component: RefundRequest,
  //         },
  //         {
  //             label: "Refund Request List",
  //             to: "/author-refund-request-list",
  //             component: RefundRequestList,
  //         },
  //     ]
  // },
  {
    icon: "mdi-message-draw",
    label: "Emails",
    exact: true,
    to: root.custom_email,
    content: [
      {
        label: "Send Ad Hoc Email",
        to: "/send-addHoc-email",
        component: SendCustomEmail,
      },
      {
        label: "Inbox",
        to: "/author-custom-email-list",
        component: ArticleCustomEmail,
      },
      {
        label: "Outbox",
        to: "/author-outbox-email-list",
        component: ArticleOutBoxEmail,
      },
      {
        to: "/author-custom-send-message/:id",
        component: ArticleCustomSendEmailMessage,
        isHide: true,
      },
      {
        label: "Addpayment",
        to: "/author-custom-email-message/:id",
        component: ArticleCustomEmailMessage,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-comment-question-outline",
    label: "Help",
    to: root.help_menu,
    exact: true,
    component: UserHelpMenu,
  },
];
