import React from 'react';
import BaseComponent from '..';

class Input extends BaseComponent {
    state = {
        msg: this.props.errMsg || '',
    }

    onBlurHandler = (e) => {
        e.preventDefault();
        if (e.target.value === '' && this.props.isRequired){
            this.setState({
                msg: 'Filled is Required'
            })
        } else {
            this.setState({
                msg: ''
            })
        }
    }

    onChange = (e) => { 
        if (this.props.field) { 
            this.setState({
                [this.props.field]: e.target.value
            });

            if (this.props.onChange) {
                this.props.onChange({ [this.props.field]: e.target.value });
            }
        }
    }

    onClick = (e) => {
        let value = '';

        if (this.props.field && this.state[this.props.field]) { 
            value = this.state[this.props.field];
        }
        
        this.props.onClick({ [this.props.field]: value });
    }

    componentDidUpdate() {
        this.props.errMsg && setTimeout(() => {
            this.setState({
                msg: this.props.errMsg
            })
        }, 300)
    }


    render(){
        return(
            <>
                {this.props.label && <label htmlFor={this.props.field || ''} className="col-sm-2 col-form-label">{this.props.label ? this.props.label : ''} {this.props.isRequired && <span className="text-danger"> * </span>}</label>}
                <div className={` ${this.props.className ? this.props.className : 'col-sm-4'}`}>
                    
                    {this.state.msg && <span className="text-danger text-small" style={{ fontSize: 12, position: 'absolute', right: 20, top: -10, backgroundColor: '#fff', padding: '0px 10px' }}>{this.state.msg}</span>}
                    
                    <div className="input-group">
                        <input
                            className={`form-control`}
                            type={this.props.type ? this.props.type : "text"}
                            defaultValue={this.props.defaultValue || ''}
                            id={this.props.field || ''}
                            placeholder={this.props.placeholder || ""}
                            disabled={this.props.disabled || false}
                            onChange = {this.onChange}
                            onBlur={this.onBlurHandler}
                            required={this.props.isRequired}
                            name={this.props.name}
                            autoComplete="off"
                        />
                        <span className="input-group-btn">
                            <button 
                                className="btn btn-primary" 
                                type="button"
                                onClick={this.onClick}
                            >
                                <i className="fa fa-search"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </>
        )
    }
}

export const InputGroup = Input;