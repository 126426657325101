import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import {
  Button, Loading,PopupBox
} from "../../../../../core/components/common";
import {
  getSocietyDiscountList,
  deleteSocietyDiscount,
} from "../../../../actions";
import { Link } from "react-router-dom";
import { Popover } from "antd";

const content = (
  <div className="">
    <a href="https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/8+-+Society+Member+and+Society+Discount+Setup.pdf" target="_blank" rel="noopener noreferrer">Society Member and Society Discount Setup</a>
    <br />

  </div>
);
class SocietyDiscount extends BaseComponent {
  state = {};
  getSocietyDiscountListData = async (pageNum = 1, page = 10) => {
    await this.props.getSocietyDiscountList({
      pageNum: pageNum,
      pageSize: page,
    });
      this.setState({
        data: this.props.societyDiscountsList.results,
        count: this.props.societyDiscountsList.count,
      });
  };
  
  componentDidMount() {
    this.getSocietyDiscountListData();
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
 

  pageChange = (pageNumber) => {
    this.getSocietyDiscountListData(pageNumber);
  };

  onEditSocietyDiscount = (id, data) => {
    this.props.history.push("/settings/edit-society-discount/" + id);
  };

  onViewSocietyDiscount = (id, data) => {
    this.props.history.push("/settings/view-society-discount/" + id);
  };




  onDeleteSocietyDiscount = (deleteId) => {
    if (deleteId) {
      this.setState({
        deleteConfirm: true,
        deleteId,
      });
    }
  };
  onDeleteHandler = async () => {
    this.setState({
      waitingMsg: "Deleting...",
    });
    const deleteResponse = await this.props.deleteSocietyDiscount(this.state.deleteId);
    this.setState({
      waitingMsg: null,
      deleteId: null,
      deleteConfirm: false,
      deleteStatus: deleteResponse.status,
    });

   this.getSocietyDiscountListData()
  
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      deleteId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };



  amountFormatting = (offerAmount, offerType) => {
    if (offerType === "DISCOUNT") {
      return `${offerAmount}%`;
    } else {
      return offerAmount;
    }
  };

  render() {
    const heading = ["society_name", "offer_type", "currency", "amount/dis"];
    const data = [];
    this.state.data &&
      this.state.data.map((item) => {
        return data.push({
          ...item,
          currency: item.offer_currency_name,
          "amount/dis": this.amountFormatting(
            item.offer_amount,
            item.offer_type
          ),
        });
      });

    return (
      <HubContent title="Society discount list" >

       {this.state.deleteConfirm && (
          <PopupBox
            title="Are you sure to delete this society Discount?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteHandler}
            waitingMsg={this.state.waitingMsg}
          />
        )}

        <div className="vat-tax-button email d-flex justify-content-end">
          <Link
            to="/settings/add-society-discount"
            className="btn btn-primary float-right mb-2"
            style={{ marginRight: 10 }}
          >
            Add Society Discount
            </Link>

          <Popover placement="leftTop" content={content} trigger="click">
            <Button
              className="btn btn-secondary float-right mb-2"
              type=""
              name=""
              text="Help"
            >Help</Button>
          </Popover>
          <div className="danger float-right"
          >
            <button
              type="danger"
              className={`btn btn-danger btn- sm" waves-effect`}
              onClick={() => this.props.history.push("/settings")}
              style={{ marginLeft: 10 }}
            >
              X

            </button>

          </div>

        </div>
        
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  {this.state.data ? (
                    <DataTable
                      heading={heading}
                      data={data}
                      onEdit={this.onEditSocietyDiscount}
                      onDelete={this.onDeleteSocietyDiscount}
                      onView={this.onViewSocietyDiscount}
                      count={this.state.count}
                      pageChange={this.pageChange}
                      pageSize={10}
                    />
                  ):<Loading type="flat" />}
             
                </div>
              </div>
            </div> 
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  societyDiscountsList: state.societyDiscount.societyDiscountsList || [],
  isLoading: state.societyDiscount.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  getSocietyDiscountList: (payload) =>
    dispatch(getSocietyDiscountList(payload)),
  deleteSocietyDiscount: (id) => dispatch(deleteSocietyDiscount(id)),
});

export const SocietyDiscountList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocietyDiscount);
