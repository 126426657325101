import React from "react";
import { connect } from "react-redux";
import { GeographicalDiscountForm } from "../../../partials";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { Loading } from "../../../../../core/components/common";

class Discount extends BaseComponent {
  state = {
    isLoading: false,
    pageSubtitle: "Geographical Discount",
  };

  cancelLoader = async () => {
    this.setState({
      status: null,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };

  render() {
    return (
      <HubContent>
        {/* Loader */}
        {this.state.isLoading && <Loading />}
        {/* Loader */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body addbook">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      {this.state.pageSubtitle}
                    </a>
                  </li>
                </ul>
                <GeographicalDiscountForm />
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export const GeographicalDiscount = connect(
  mapStateToProps,
  mapDispatchToProps
)(Discount);
