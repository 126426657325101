import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { DataTable, HubContent } from '../../../../../core/components';
import { getAllUser, deleteUser } from '../../../../actions';
import NotFound from '../../NotFound';
import { PopupBox, Loading } from '../../../../../core/components/common';

class UL extends BaseComponent {
    state = {
        deleteConfirm: false,
        userId: null,
        waitingMsg: null,
        deleteStatus: null,
        data: []
    }
    getUserData = async (pageNum = 1, page = 10) => {
        await this.props.getAllUser({ pageNum: pageNum, pageSize: page });
        this.setState({
            data: this.props.users.results,
            count: this.props.users.count
        })
    }
    async componentDidMount() {
        this.getUserData();
    }
    pageChange = (pageNumber) => {
        this.getUserData(pageNumber);
    }

    onEditUser = (id, data) => {
        this.props.history.push('/user/edit-user/' + id, { user: data })
    }

    onViewUser = (id, data) => {
        this.props.history.push('/user/' + id, { user: data })
    }

    onDeleteUser = (id) => {
        if (id) {
            this.setState({
                deleteConfirm: true,
                userId: id
            })
        }
    }

    onDeleteUserHander = async () => {
        const userId = this.state.userId;
        this.setState({
            waitingMsg: "Deleting..."
        })
        const user = await this.props.deleteUser(userId);
        this.setState({
            waitingMsg: null,
            userId: null,
            deleteConfirm: false,
            deleteStatus: user.status
        })
    }

    onCancel = () => {
        this.setState({
            deleteConfirm: false,
            userId: null,
            waitingMsg: null,
            deleteStatus: null
        })
    }
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();

        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        // }
    };
    render() {
        const heading = ['fullname', 'email', 'group_name', 'organisation_name', 'role_name']
        let user = this.props.users;
        let failedMsg = this.props.failed

        let errorHander = <>
            {(failedMsg && failedMsg.status === 400 && !this.props.users) && <PopupBox
                title="Invalid Request"
                msg="Your Request is not valid"
                onCancel={this.cancelLoader}
            />}

            {(failedMsg && failedMsg.status === 500 && !this.props.users) && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {!user && !failedMsg && failedMsg !== null && <Loading />}
            
        </>


        return (
            this.state.status === 403 ? <NotFound /> :
                <HubContent title="User List" onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                    {this.state.deleteConfirm && <PopupBox
                        title="Do you want to delete?"
                        YesText="Yes"
                        yesBtnType="danger"
                        type="danger"
                        NoBtnType="success"
                        onCancel={this.onCancel}
                        onPressOK={this.onDeleteUserHander}
                        waitingMsg={this.state.waitingMsg}
                    />}

                    {this.state.deleteStatus && <PopupBox
                        title="Successfully deleted"
                        onCancel={this.onCancel}
                    />}

                    {this.state.deleteStatus === false && <PopupBox
                        title="Failed... Try Again"
                        onCancel={this.onCancel}
                        NoBtnType="danger"
                    />}
                    {errorHander}

                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                {this.props.users.results &&
                                    <DataTable
                                        heading={heading}
                                        data={this.props.users.results}
                                        onEdit={this.onEditUser}
                                        onDelete={this.onDeleteUser}
                                        onView={this.onViewUser}
                                        count={this.props.users.count}
                                        pageChange={this.pageChange}
                                        pageSize={10}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    users: state.user.users || false,
    failed: state.user.failed || false
})

const mapDispatchToProps = (dispatch) => ({
    getAllUser: (payload) => dispatch(getAllUser(payload)),
    deleteUser: (id) => dispatch(deleteUser(id))
})

export const UserList = connect(mapStateToProps, mapDispatchToProps)(UL);
