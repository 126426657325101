import {
    GET_MEMBERSHIP_DISCOUNT_LIST,
    GET_MEMBERSHIP_DISCOUNT,
    CREATE_UPDATE_MEMBERSHIP_DISCOUNT,
    DELETE_MEMBERSHIP_DISCOUNT,
    REQUEST_MEMBERSHIP_DISCOUNT,
    REQUEST_MEMBERSHIP_DISCOUNT_LIST
} from "../../config";

const SocietyMemberReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_MEMBERSHIP_DISCOUNT_LIST:
            return state = {
                ...state,
                member_discount_lists: action.payload,
                isLoading: false

            }
        case CREATE_UPDATE_MEMBERSHIP_DISCOUNT:
            return state = {
                ...state,
                create_member: action.payload
            }
        case DELETE_MEMBERSHIP_DISCOUNT:
            return state = {
                ...state,
                delete_members: action.payload
            }
        case GET_MEMBERSHIP_DISCOUNT:
            return state = {
                ...state,
                isLoading: false,
                single_members: action.payload
            }
        case REQUEST_MEMBERSHIP_DISCOUNT:
            return state = {
                ...state,
                isLoading: true
            }
        case REQUEST_MEMBERSHIP_DISCOUNT_LIST:
            return state = {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }
}

export default SocietyMemberReducer;
