import React from "react";
import BaseComponent, {
  HubContent,
  DataTable,
} from "../../../../../core/components";
import {
  Loading,
  // SelectBox,
  // InputBox
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import {
  GetApprovedDepositList,
  DepositApprovalDownload,
} from "../../../../actions";
import moment from "moment";

class DB extends BaseComponent {
  componentDidMount() {
    this.getDepositList();
  }

  getDepositList = async (pageNum = 1, page = 20) => {
    await this.props.GetApprovedDepositList({
      pageNum: pageNum,
      pageSize: page,
    });
  };

  pageChange = (pageNumber) => {
    this.getDepositList(pageNumber);
  };
  onDepositDownloadClick = async () => {
    try {
      await this.props.DepositApprovalDownload();
      const url = window.URL.createObjectURL(this.props.deposit_approve_data);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `Deposit_list_data_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {

    }
  };

  render() {
    let heading = [
      "date",
      "publisher",
      "institution",
      "oa_deal",
      "currency",
      "read_fee",
      "publish_fee",
      "total",
      "deposit_by",
    ];
    this.props.deposit &&
      this.props.deposit.results &&
      this.props.deposit.results.map((deposit) => {
        return Object.assign(deposit, {
          date: moment(deposit.date_created).format(),
          publisher: deposit.counter_organisation_name,
          institution: deposit.organisation_name,
          oa_deal: deposit.oa_deal_type,
          currency: deposit.currency_name,
          read_fee: deposit.read_fee ? deposit.read_fee : null,
          publish_fee: deposit.publish_fee ? deposit.publish_fee : null,
          total: deposit.amount ? deposit.amount : null,
          deposit_by: deposit.created_by_name,
        });
      });

    return (
      <HubContent
        title="Deposit list"
        onArticleDownloadClickHandler={this.onDepositDownloadClick}
      >
        <div className="row filter">
          {/* <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <InputBox className="col-md-12" placeholder="Search here... " />
          </div>{" "} */}
          <div className="col-lg-12">
            <div className="card m-b-20">
              {this.props.deposit ? (
                <DataTable
                  heading={heading}
                  data={this.props.deposit.results}
                  noAction={true}
                  count={this.props.deposit.count || false}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  deposit: state.deposit.approved,
  deposit_approve_data: state.deposit.deposit_approve_data,
});

const mapDispatchToProps = (dispatch) => ({
  GetApprovedDepositList: (payload) =>
    dispatch(GetApprovedDepositList(payload)),
  DepositApprovalDownload: (ids) => dispatch(DepositApprovalDownload()),
});

export const DipositList = connect(mapStateToProps, mapDispatchToProps)(DB);
