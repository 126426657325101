import React from "react";
import BaseComponent from "../../../../core/components";
import { setNewPassword, logoutUserData } from "../../../actions";
import { connect } from "react-redux";
import queryString from "query-string";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

class Password extends BaseComponent {
  state = {
    errMsg: null,
    reset_password: {},
    password_reseted: false,
    expired: true,
  };
  onChange = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.reset_password, value);
      return prevState;
    });
  };
  logOut = () => {
    if (this.props.logoutUserData()) {
      setTimeout(() => {
        this.props.history.push("/signin")
      }, 500);
    }
  }
  componentDidMount = async () => {
    const values = queryString.parse(this.props.location.search);
    if (this.props.auth) {
      let msg = `You are currently signed in on this site.
                    If you want to continue you will be logged out.`;
      confirmAlert({
        title: "",
        message: msg,
        buttons: [
          {
            label: "Cancel",
            onClick: () => {
              this.props.history.push("/");
            },
          },
          {
            label: "Continue",
            onClick: () => this.logOut()
          },
        ],
      });

    } else {
      if (values.security_code) {
        let security_code = values.security_code;
        await this.props.setNewPassword({
          security_code: security_code,
          sec_code_enquiry: true,
        });
        if (this.props.data.reason !== "sec_code_valid") {
          confirmAlert({
            title: "",
            message: this.props.data.message,
            buttons: [
              {
                label: "Reset Password",
                onClick: () => this.props.history.push("/forgot"),
              },
            ],
          });
        }

      }
    }
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();

    const values = queryString.parse(this.props.location.search);
    let security_code = values.security_code;
    let password = this.state.reset_password.password;
    let confirm_password = this.state.reset_password.confirm_password;
    await this.props.setNewPassword({
      security_code: security_code,
      password: password,
      confirm_password: confirm_password,
    });
    if (this.props.data.success === true) {
      confirmAlert({
        title: "",
        message: this.props.data.message,
        buttons: [
          {
            label: "Close",
            onClick: () => this.props.history.push("/signin"),
          },
        ],
      });
    } else {
      confirmAlert({
        title: "",
        message: this.props.data.message,
        buttons: [
          {
            label: "Close",
            onClick: () => { },
          },
        ],
      });
    }
  };

  render() {
    return (
      <div className="wrapper-page pt-5">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center m-0">
              <Link to="/" className="logo logo-admin">
                <img
                  src="/assets/images/logo-sm.png"
                  style={{ height: "30px" }}
                  alt="logo"
                />
              </Link>
            </h3>
            <div className="p-3">
              <h4 className="text-muted font-18 mb-5 text-center">
                Enter a new password for your account
              </h4>
              <div className="" style={{ marginBottom: 15 }}>
                <label htmlFor="userpassword">
                  Your password should contain at least 8 characters, with at least one digit and one uppercase letter.
                </label>
              </div>

              <form
                className="form-horizontal mt-30"
                action=""
                onSubmit={this.onSubmit}
              >
                <div className="form-group">
                  <label htmlFor="username">New password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="oa-password"
                    placeholder="New password"
                    onChange={(e) => {
                      this.onChange({ password: e.target.value });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="userpassword">Confirm new password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="oa-confirm-password"
                    placeholder="New confirm password"
                    onChange={(e) => {
                      this.onChange({ confirm_password: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group row m-t-20">
                  <div className="col-12 text-center">
                    <button
                      onClick={this.onSubmitHandler}
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: (state.auth && state.auth.success) || false,
  data: (state.common && state.common.auth && state.common.auth.data) || false,
});

const mapDispatchToProps = (dispatch) => ({
  setNewPassword: (payload) => dispatch(setNewPassword(payload)),
  logoutUserData: (payload) => dispatch(logoutUserData(payload)),
});

export const resetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(Password);