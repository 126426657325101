import config, {
	GET_ARTICLE_TRIGGER_CONFIG,
	ADD_ARTICLE_TRIGGER_CONFIG,
	GET_JOURNAL_LIST_TRIGGER_CONFIG,
} from "../../config";
import { api } from "../../core/api";

export const getArticleConfigAction = (payload) => ({
	type: GET_ARTICLE_TRIGGER_CONFIG,
	payload,
});

export const AddtArticleConfigAction = (payload) => ({
	type: ADD_ARTICLE_TRIGGER_CONFIG,
	payload,
});
export const getJournalsConfigAction = (payload) => ({
	type: GET_JOURNAL_LIST_TRIGGER_CONFIG,
	payload,
});
export const getArticleTransferConfigList = (payload) => {
	let url = config.endpoint.article_transfer_info;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			api.post(url, payload)
				.then((res) => {
					dispatch(getArticleConfigAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {
					dispatch(getArticleConfigAction(error.response));
					resolve(Object.assign(error.response, { status: false }));
				});
		});
	};
};

export const AddtArticleTransferConfig = (payload) => {
	let url = config.endpoint.trigger_article_transfer;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			api.post(url, payload)
				.then((res) => {
					dispatch(AddtArticleConfigAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {
					reject(error.response);
				});
		});
	};
};

export const getJournalLists = (id) => {
	let url = `journal/?publisher=${id}&fields=id,name`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getJournalsConfigAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {
					reject(error.response);
				});
		});
	};
};
