import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { AutoComplete,Spin } from "antd";
import {LoadingOutlined } from '@ant-design/icons';

import {
  InputBox,
  SelectBox,
  OaDatePicker,
  ModalSelectBox,
  RadioButtonGroup,
} from "../../../../../core/components/common";

import {
  getPublicationList,
  getOaDisciplineList,
  getOaSubjectList,
  getContentTypeList,
  getOaDealSummary,
  getInstitutionDataLists,
} from "../../../../actions";
import { serialize } from "../../../../../core/lib";

const format = "Do MMM YYYY";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class OAdealIndividualAuthor extends React.Component {
  state = {
    deal_type: this.props.deal_type,
    deal_name: this.props.deal_name,
    isShow: this.props.isShow,
    oaDeal: {},
    isIndividualMember: false,
    publisher_deposit_payment: {},
    showConstraint: false,
    all_deal_features: [],
    invoice_config_options: {},
    config_options: [
      {
        label:
          "Would you like to allow system generates an invoice for an article when processed automatically under this Read & Publish deal?",
        name: "raise_invoice",
      },
      {
        label: "Would you like to add a custom watermark in the invoice?",
        name: "has_custom_watermark",
        depends_on: "raise_invoice",
      },
      {
        label: "Would you like to show net payable Zero in the invoice?",
        name: "is_void_invoice",
        depends_on: "raise_invoice",
      },
    ],
    constrainData: [
      { id: "no_constraint", name: "No cap" },
      { id: "by_total_read", name: "By total article" },
      { id: "by_total_price", name: "By total price" },
    ],
  };

  defaultDate = () => {
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; 
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    return year + "-" + month + "-" + day;

  }
  componentDidMount() {
    let viewData = this.props.value;
    let featureCodes = {};

    if (viewData && viewData.all_deal_features) {
      viewData.all_deal_features.map((item) =>
        item.deal_feature
          ? (featureCodes[item.deal_feature.feature_code] = item.selected)
          : null
      );
    }
    this.props.value &&
      this.setState({
        deal_type: (viewData && viewData.deal_type) || this.props.deal_type,
        invoice_config_options: viewData.invoice_config_options,
        counter_organisation_name:
          viewData && viewData.counter_organisation_name,
        featureCodes,
        oaDeal: {
          content_types: viewData.content_types,
          currency: viewData.currency,
          all_deal_features: viewData.all_deal_features,
          deal_type: viewData.deal_type,
          disciplines: viewData.disciplines,
          discount: viewData.discount,
          expire_date: viewData.expire_date,
          submission_date: viewData.submission_date,
          acceptance_date: viewData.acceptance_date,
          start_date: viewData.start_date,

          counter_organisation: viewData && viewData.counter_organisation,
          publication_types: viewData.publication_types,
          publications: viewData.publications,
          subjects: viewData.subjects,
          totals:
            viewData &&
            viewData.publisher_deposit_payment &&
            viewData.publisher_deposit_payment.amount,
          publish_fee:
            viewData &&
            viewData.publisher_deposit_payment &&
            viewData.publisher_deposit_payment.publish_fee,
          read_fee:
            viewData &&
            viewData.publisher_deposit_payment &&
            viewData.publisher_deposit_payment.read_fee,
          amount:
            viewData &&
            viewData.publisher_deposit_payment &&
            viewData.publisher_deposit_payment.amount,

          constraint_by:
            viewData &&
            viewData.constraint &&
            viewData.constraint.constraint_by,
          max_total_read:
            viewData &&
            viewData.constraint &&
            viewData.constraint.max_total_read,
          max_total_price:
            viewData &&
            viewData.constraint &&
            viewData.constraint.max_total_price,
          approval_note:
            viewData &&
            viewData.publisher_deposit_payment &&
            viewData.publisher_deposit_payment.approval_note,
        },
      });

    this.apiDataList()
  }
  apiDataList = async () => {
    
    this.getDealSummaryData();

    let payload = {};
    await this.props.getOaSubjectList(payload);
    this.props.getOaDisciplineList();

      this.props.publication_list && this.setState({
        publicationList: [
          { id: "all", name: "All publications" },
          ...this.props.publication_list.results,
        ],
        oa_subject_list:
          this.props.oa_subjects && this.props.oa_subjects.results,
      });
    
    this.getPublicationListOnParameterChange();
  }
 
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  onCloseModal = () => {
    this.props.onClose();
  };

  onSaveUpdateOadeal = (e) => {
    e.preventDefault();
    let { oaDeal } = this.state;


    if (Object.entries(oaDeal).length === 0) {
      alert("fields are empty");
    } else {
      this.props.onSaveAuthor({
        ...oaDeal,
        counter_organisation_name: this.state.counter_organisation_name,
        invoice_config_options: this.state.invoice_config_options,
        deal_type: this.state.deal_type,

      });
    }
  };

  getPublicationListOnParameterChange = async () => {
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.oaDeal.publication_types &&
      this.state.oaDeal.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.oaDeal.publication_types[0] !== "all"
          ? this.state.oaDeal.publication_types.join(",")
          : "";
    }

    if (this.state.disciplines && this.state.disciplines.length > 0) {
      filterParams.discipline_ids =
        this.state.disciplines[0] !== "all"
          ? this.state.disciplines.join(",")
          : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }
  };

  onChangeHandler = async (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.oaDeal, { ...value });
    });
    if (
      value.deposit_publish_fee !== undefined ||
      value.deposit_read_fee !== undefined
    )
      this.setState((prevState) => {
        Object.assign(
          prevState.oaDeal,
          {
            ...value,
          },

        )
      });
    if (
      value.deposit_publish_fee !== undefined ||
      value.deposit_read_fee !== undefined
    ) {
      this.setState({
        ...value,
      });
    }

    this.setState(
      (prevState) => {
        if (
          value.deposit_amount ||
          value.deposit_publish_fee ||
          value.deposit_read_fee ||
          value.approval_note
        ) {
          let depositValue = {};
          if (value.deposit_amount) {
            depositValue["amount"] = Number(value.deposit_amount);
          } else if (value.deposit_publish_fee) {
            depositValue["publish_fee"] = Number(value.deposit_publish_fee);
          } else if (value.deposit_read_fee) {
            depositValue["read_fee"] = Number(value.deposit_read_fee);
          } else if (value.note) {
            depositValue["note"] = value.approval_note;
          }

          let data = Object.assign(
            prevState.publisher_deposit_payment,
            depositValue
          );
          Object.assign(prevState.oaDeal, data);
        } else {
          Object.assign(prevState.oaDeal, value);
        }
      },
      () => {
        if (value.hasOwnProperty("publication_types")) {
          this.getPublicationListOnParameterChange();
        }
      }
    );
    //constraint_by LOGIC
    if (value.hasOwnProperty("constraint_by")) {
      this.setState({
        constraint_by: value.constraint_by,
      });
    }

  };

  handePublishersBasedonParent = async (fieldName, data) => {
    this.setState((prevState) => {
      Object.assign(prevState.oaDeal, { [fieldName]: data });
    });

    let payload = {};
    let filterParams = {
      page_size: 999999,
    };

    if (data && data.length > 0) {
      filterParams.publication_type_ids = data !== "all" ? data.join(",") : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }
    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publicationList: [
          { id: "all", name: "All publications" },
          ...this.props.publication_list.results,
        ],
      });
  };

  onShowConstraint = () => {
    this.setState({
      showConstraint: true,
    });
  };

  getDealSummaryData = async (payload = {}) => {
    await this.props.getOaDealSummary(this.props.organisation_id, { deal_type: this.state.deal_type });

    if (this.props.oadeal_summary && this.props.oadeal_summary.deal_features) {
      this.setState((prevState) => {
        Object.assign(prevState.oaDeal, {
          all_deal_features: this.props.oadeal_summary.deal_features,
        });
      });
    }
  };

  onChangeRadioButtonHandler = (value) => {
    let { oaDeal, featureCodes } = this.state;
    let itemKey = Object.keys(value).length && Object.keys(value)[0];

    if (!featureCodes) {
      featureCodes = {};
    }

    oaDeal.all_deal_features = oaDeal.all_deal_features.map((item) => {
      if (itemKey === `feature_${item.id}`) {
        if (item.deal_feature) {
          featureCodes[item.deal_feature.feature_code] = value[itemKey];
        }

        Object.assign(item, { selected: value[itemKey] });
      }

      return item;
    });

    if (!featureCodes["OADF-00001"]) {
      this.setState({
        invoice_config_options: null,
      });
    }

    this.setState((prevState) => {
      Object.assign(prevState.oaDeal, {
        all_deal_features: oaDeal.all_deal_features,
      });
    });

    this.setState({
      featureCodes,
    });
  };

  renderDealFeatues = (items) => {
    return items.map((item) => {
      return (
        <div key={item.id} className="row">
          <div className="col-md-8">

            <p>{item.deal_feature && item.deal_feature.name}</p>

          </div>
          <div className="col-md-2">
            <RadioButtonGroup
              onChange={this.onChangeRadioButtonHandler}
              field={`feature_${item.id}`}
              options={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
              value={item.selected}
            />
          </div>

        </div>
      );
    });
  };

  onChangeInvoiceConfigOptionHandler = (value) => {
    let { invoice_config_options } = this.state;

    if (!invoice_config_options) {
      invoice_config_options = {};
    }

    if (
      value.hasOwnProperty("has_custom_watermark") &&
      !value.has_custom_watermark
    ) {
      value.custom_watermark = null;
    }

    if (value.hasOwnProperty("raise_invoice") && !value.raise_invoice) {
      value.has_custom_watermark = false;
      value.is_void_invoice = false;
      value.custom_watermark = null;
    }

    Object.assign(invoice_config_options, value);

    this.setState({
      invoice_config_options,
    });
  };

  renderInvoiceConfigOptions = (items) => {
    let { invoice_config_options, featureCodes } = this.state;

    return items.map((item, index) => {
      if (
        item.hasOwnProperty("depends_on") &&
        (!invoice_config_options || !invoice_config_options[item.depends_on])
      ) {
        return null;
      } else if (!featureCodes || !featureCodes["OADF-00001"]) {
        return null;
      }

      return (
        <div key={`popup-${item.name}-${index}`} className="row">
          <div className="col-md-8">
            <p>{item.label}</p>
          </div>
          <div className="col-md-2">
            <RadioButtonGroup

              onChange={this.onChangeInvoiceConfigOptionHandler}
              field={item.name}
              options={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
              value={
                !!invoice_config_options && !!invoice_config_options[item.name]
              }
            />
          </div>
          {item.name === "has_custom_watermark" &&
            !!invoice_config_options &&
            !!invoice_config_options[item.name] && (
              <div className="col-md-2">

                <InputBox
                  label={false}
                  onChange={this.onChangeInvoiceConfigOptionHandler}
                  field="custom_watermark"
                  className="invoice-mark"
                  defaultValue={
                    !!invoice_config_options &&
                    invoice_config_options.custom_watermark
                  }
                />
              </div>

            )}

        </div>
      );
    });
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = async (fieldName, data) => {
    this.setState((prevState) => {
      Object.assign(prevState.oaDeal, {
        [fieldName]: data && data.length > 0 && data[0] !== "all" ? data : [],
      });
    });
    if (fieldName === "publications") {
      this.props.getContentType(data.join());
    }
    if (fieldName === "disciplines") {
      if (data.length > 0) {
        let payload = {
          discipline: data[0] !== "all" ? data.join(",") : "",
        };

        await this.props.getOaSubjectList(payload);

        this.props.oa_subjects &&
          this.setState({
            oa_subject_list: this.props.oa_subjects.results,
          });
      }

      this.getPublicationListOnParameterChange();
    } else if (fieldName === "subjects") {
      this.getPublicationListOnParameterChange();
    }
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "disciplines":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "subjects":
        this.setState({
          isShowSubjectModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      case "publication_types":
        this.setState({
          isShowPublicationTypeModal: true,
        });
        break;
      case "Members":
        this.setState({
          isShowMemberTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
      isShowPublicationTypeModal: false,
      isShowMemberTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state.oaDeal && this.state.oaDeal[fieldName];

    let labelText = "";
    switch (fieldName) {
      case "publication_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Publication type selected`
              : `${fieldValues.length} Publication types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Publications type selected"
              : "Select publications type";
        }
        break;
      case "disciplines":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} discipline selected`
              : `${fieldValues.length} disciplines selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Disciplines"
              : "Select Discipline";
        }

        break;
      case "subjects":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} subject selected`
              : `${fieldValues.length} subjects selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All subjects"
              : "Select Subject";
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} publication selected`
              : `${fieldValues.length} publications selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Publications"
              : "Select Publication";
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues && fieldValues.length === 1
              ? `${fieldValues && fieldValues.length} content type selected`
              : `${fieldValues && fieldValues.length} content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Content Types"
              : "Select content type";
        }
        break;
      case "Members":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Members  selected`
              : `${fieldValues.length} Members selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Members  selected"
              : "Select Members ";
        }
        break;
      default:
        break;
    }

    return labelText;
  };



  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState((prevState) => {
        Object.assign(prevState.oaDeal, { counter_organisation: item.id });
        prevState.orgsError = false
      });
    }
  };

    onChange = (value) => {
      let data = this.props.institution_list && this.props.institution_list.find(item => item.name === value)
      if (this.isNumber(data && data.id)) {
        this.setState({
          counter_organisation_name: value,
          orgsError: false,
        });
      } else {
        if (value === "") {
          this.setState({
            orgsError: false,
            counter_organisation_name: value
  
          })
        } else {
          this.setState({
            orgsError: true,
            counter_organisation_name: value
  
          })
        }
      }
    };

  onSearch = async (value) => {
    await this.props.getInstitutionDataLists(value);
    let InstDataList = [];
    this.props.institution_list &&
      this.props.institution_list.map((item) =>
        InstDataList.push({
          id: item.id,
          value: item.name,
        })
      );
    this.setState({
      InstDataList:InstDataList
    })
  };
  readAndSubscribeDeal = (sum) => (
    <div>
      <div className=" row">
        <div className="col-md-3">
          <label className="deal-label-name">Select Currency</label>
          <SelectBox
            onChange={this.onChangeHandler}
            field="currency"
            className="deal-currency"
            data={this.props.oadeal_currency}
            isRequired={false}
            defaultValue={this.state.oaDeal.currency}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Enter Discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"
            defaultValue={this.state.oaDeal.discount}
          />
        </div>


        <div className="col-md-3">
          <label className="deal-label-name">Enter Deal Start Date</label>
          <OaDatePicker
            className="deal-start-date"
            field="start_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              moment(
                (this.props.value && this.props.value.start_date) || this.defaultDate()
              )
            }
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Enter Deal Expiry Date</label>

          <OaDatePicker
            className="deal-expire-date"
            field="expire_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}

            defaultValue={
              moment(
                (this.props.value && this.props.value.expire_date) || this.defaultDate()
              )
            }
          />
        </div>

      </div>
      <div className="row">

        <div className="col-md-3">
          <label className="deal-label-name">Enter Submission Date</label>

          <OaDatePicker
            className="deal-submission-date"
            field="submission_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              moment(
                (this.props.value && this.props.value.submission_date) || this.defaultDate()
              )
            }
          />
        </div>

        <div className="col-md-3">
          <label className="deal-label-name">Enter Acceptance Date</label>
          <OaDatePicker
            className="deal-acceptance-date"
            field="acceptance_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
            defaultValue={
              moment(
                (this.props.value && this.props.value.acceptance_date) || this.defaultDate()
              )
            }
          />
        </div>
        <div className="col-md-3">

          <label className="deal-label-name">{this.state.deal_name === "read & publish"
            ? `Enter Publish Fee`
            : `Enter Subscription Fee`}</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="deposit_publish_fee"
            className="deal-publish-fee"
            InputType="number"
            defaultValue={this.state.oaDeal.publish_fee}
          />

        </div>

        <div className="col-md-3">

          <label className="deal-label-name">{this.state.deal_name === "read & publish"
            ? `Enter Read Fee`
            : `Enter Other Fee`}</label><InputBox
            onChange={this.onChangeHandler}
            field="deposit_read_fee"
            className="deal-read-fee"
            InputType="number"
            defaultValue={this.state.oaDeal.read_fee}
          />

        </div>
        <div className="col-md-3">
          <label className="deal-label-name">{this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"

            ? `Total Amount`
            : `Enter Deposit Amount`}</label>
          {
            this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open" ? <p

              className="total-amount"

            >{((this.state.oaDeal && this.state.oaDeal.totals && this.state.oaDeal.totals.toFixed(2)) || 0) || (sum.toFixed(2))
              }</p>
              : <InputBox
                onChange={this.onChangeHandler}
                field="deposit_amount"
                className="deal-deposit-amount"
                InputType="number"
                defaultValue={
                  this.state.oaDeal.amount
                }
              />

          }


        </div>

        <div className="col-md-3">
          <label className="deal-label-name">Select Cap or No Cap</label>

          <SelectBox
            className="deal-set-cap"
            onChange={this.onChangeHandler}
            field="constraint_by"
            data={this.state.constrainData}
            isRequired={false}
            defaultValue={this.state.oaDeal.constraint_by}
          />
        </div>

        {this.state.oaDeal.constraint_by === "by_total_read" && (
          <div className="col-md-3">
            <label className="deal-label-name">Enter No. of Articles</label>
            <InputBox
              className="deal-articles"
              onChange={this.onChangeHandler}
              field="max_total_read"
              defaultValue={this.state.oaDeal.max_total_read}
              InputType="number"
            />
          </div>

        )}
        {this.state.oaDeal.constraint_by === "by_total_price" && (
          <div className="col-md-3">
            <label className="deal-label-name">Enter Cap Amount</label>
            <InputBox
              className="deal-price"
              onChange={this.onChangeHandler}
              field="max_total_price"
              defaultValue={this.state.oaDeal.max_total_price}
            />
          </div>
        )}
      </div>

    </div>
  )
  render() {


    let sum = Number(this.state.oaDeal.deposit_publish_fee || 0) + Number(this.state.oaDeal.deposit_read_fee || 0)
    return (
      <div style={modal.overlay}>
        <div className={`col-sm-10 bg-white alert`} style={modal.boxStyle}>
          <>
            <div className=" row auto-complete-search">
              <label className="deal-label-name">Type Consortia Name</label>
              <div className="col-md-12">
                <AutoComplete
                  value={this.state.counter_organisation_name}
                  options={this.state.InstDataList}
                  style={{
                    width: "100%",
                  }}
                  notFoundContent={this.state.counter_organisation_name && "The institution name you have typed has no match on OaMetrix."}

                  onSelect={(val, option) => this.onSelect(val, option)}
                  onSearch={this.onSearch}
                  onChange={this.onChange}
                />
                  <div className="search-auto-spin" style={{marginTop:0}}>
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>

                      {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure you have selected Institution/Consortia name from dropdown.
</span>}
              </div>
            </div>

            {
              (this.state.deal_name === "pre-payment" || this.state.deal_name === "credit") && (
                <div className="row">
                  <div className="col-md-3">
                    <label className="deal-label-name">Select Currency</label>
                    <SelectBox
                      onChange={this.onChangeHandler}
                      field="currency"
                      className="deal-currency"
                      data={this.props.oadeal_currency}
                      isRequired={false}
                      defaultValue={this.state.oaDeal.currency}
                    />
                  </div>
                  {this.state.deal_name === "pre-payment" &&
                    <div className="col-md-3">
                      <label className="deal-label-name">Enter Deposit Amount </label>
                      <InputBox
                        onChange={this.onChangeHandler}
                        field="deposit_amount"
                        className="deal-deposit"
                        InputType="number"
                        defaultValue={
                          this.state.oaDeal.amount
                        }
                      />

                    </div>
                  }
                  <div className="col-md-3">
                    <label className="deal-label-name">Enter Discount%</label>
                    <InputBox
                      onChange={this.onChangeHandler}
                      field="discount"
                      className="deal-discount-name"
                      defaultValue={this.state.oaDeal.discount}
                    />
                  </div>
                  <div className="col-md-3">
            <label className="deal-label-name">Enter Submission Date</label>
  
            <OaDatePicker
              className="deal-submission-date"
              field="submission_date"
              onChange={this.onChangeHandler}
              showTime={false}
              format={format}
              defaultValue={
                moment(
                  (this.props.value && this.props.value.submission_date) || this.defaultDate()
                )
              }
            />
          </div>
          <div className="col-md-3">
        <label className="deal-label-name">Enter Acceptance Date</label>
        <OaDatePicker
          className="deal-acceptance-date"
          field="acceptance_date"
          onChange={this.onChangeHandler}
          showTime={false}
          format={format}
          defaultValue={
            moment(
              (this.props.value && this.props.value.acceptance_date) || this.defaultDate()
            )
          }
        />
      </div>
                  <div className="col-md-3">
                    <label className="deal-label-name">Enter Deal Expiry Date</label>
                    <OaDatePicker
                      className="deal-expiry-date"
                      field="expire_date"
                      onChange={this.onChangeHandler}
                      showTime={false}
                      format={format}
                      defaultValue={
                        moment(
                          (this.props.value && this.props.value.expire_date) || this.defaultDate()
                        )
                      }
                    />
                  </div>

                </div>
              )}


            {this.state.deal_name === "oa-discount" &&
              <div className="row">
                <div className="col-md-3">
                  <label className="deal-label-name">Enter Discount%</label>
                  <InputBox
                    onChange={this.onChangeHandler}
                    field="discount"
                    className="deal-deposit"
                    defaultValue={this.state.oaDeal.discount}
                  />
              </div>
              <div className="col-md-3">
              <label className="deal-label-name">Enter Deal Start Date</label>
              <OaDatePicker
                className="deal-start-date"
                field="start_date"
                onChange={this.onChangeHandler}
                showTime={false}
                format={format}
                defaultValue={
                  moment(
                    (this.props.value && this.props.value.start_date) || this.defaultDate()
                  )
                }
              />
            </div>
          
              <div className="col-md-3">
            <label className="deal-label-name">Enter Submission Date</label>
  
            <OaDatePicker
              className="deal-submission-date"
              field="submission_date"
              onChange={this.onChangeHandler}
              showTime={false}
              format={format}
              defaultValue={
                moment(
                  (this.props.value && this.props.value.submission_date) || this.defaultDate()
                )
              }
            />
              </div>
              <div className="col-md-3">
        <label className="deal-label-name">Enter Acceptance Date</label>
        <OaDatePicker
          className="deal-acceptance-date"
          field="acceptance_date"
          onChange={this.onChangeHandler}
          showTime={false}
          format={format}
          defaultValue={
            moment(
              (this.props.value && this.props.value.acceptance_date) || this.defaultDate()
            )
          }
        />
              </div>
              <div className="col-md-3">
              <label className="deal-label-name">Enter Deal Expiry Date</label>
    
              <OaDatePicker
                className="deal-expire-date"
                field="expire_date"
                onChange={this.onChangeHandler}
                showTime={false}
                format={format}
    
                defaultValue={
                  moment(
                    (this.props.value && this.props.value.expire_date) || this.defaultDate()
                  )
                }
              />
            </div>
              </div>

            }








            {(this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && (
              this.readAndSubscribeDeal(sum)
            )}



            <div className="row" style={{ marginTop: 30 }}>
              <div className="col-sm-4 deal-model">
                {this.showSelectItemsLabel("disciplines")}
                <button
                  onClick={() => this.onModalSelectBoxShow("disciplines")}
                  className="btn btn-success waves-effect waves-light float-right"
                  type="button"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>

              {this.state.isShowDisciplineModal && (
                <ModalSelectBox
                  label="Disciplines"
                  field="disciplines"
                  modalClass="col-md-6"
                  labelClass="col-md-3"
                  className="col-sm-9"
                  data={
                    this.props.oa_disciplines && [
                      { id: "all", name: "All disciplines" },
                      ...this.props.oa_disciplines.results,
                    ]
                  }
                  defaultValue={this.state.oaDeal.disciplines}
                  isRequired={false}
                  submitBtnTxt="Save"
                  onSubmit={this.onSaveSelectedItems}
                  onClose={() => this.onModalSelectBoxClose()}
                />
              )}

              <div className="col-sm-4 deal-model">
                {this.showSelectItemsLabel("subjects")}
                <button
                  onClick={() => this.onModalSelectBoxShow("subjects")}
                  className="btn btn-success waves-effect waves-light float-right"
                  type="button"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>

              {this.state.isShowSubjectModal && (
                <ModalSelectBox
                  label="subjects"
                  field="subjects"
                  modalClass="col-md-6"
                  labelClass="col-md-3"
                  className="col-sm-9"
                  data={
                    (this.state.oa_subject_list && [
                      { id: "all", name: "All subjects" },
                      ...this.state.oa_subject_list,
                    ]) ||
                    []
                  }
                  defaultValue={this.state.oaDeal.subjects}
                  isRequired={true}
                  submitBtnTxt="Save"
                  onSubmit={this.onSaveSelectedItems}
                  onClose={() => this.onModalSelectBoxClose()}
                />
              )}

              <div className="col-sm-4 deal-model">
                {this.showSelectItemsLabel("publication_types")}
                <button
                  onClick={() => this.onModalSelectBoxShow("publication_types")}
                  className="btn btn-success waves-effect waves-light float-right"
                  type="button"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>

              {this.state.isShowPublicationTypeModal && (
                <ModalSelectBox
                  label="Publications types"
                  field="publication_types"
                  modalClass="col-md-6"
                  labelClass="col-md-4"
                  className="col-sm-8"
                  data={this.props.publication_types}
                  defaultValue={this.state.oaDeal.publication_types}
                  isRequired={false}
                  submitBtnTxt="Save"
                  onSubmit={this.handePublishersBasedonParent}
                  onClose={() => this.onModalSelectBoxClose()}
                />
              )}

              <div className="col-sm-4 deal-model">
                {this.showSelectItemsLabel("publications")}
                <button
                  onClick={() => this.onModalSelectBoxShow("publications")}
                  className="btn btn-success waves-effect waves-light float-right"
                  type="button"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>

              {this.state.isShowPublicationModal && (
                <ModalSelectBox
                  title="Select publications"
                  label="Publications"
                  field="publications"
                  modalClass="col-md-6"
                  labelClass="col-md-3"
                  className="col-sm-9"
                  data={this.state.publicationList || []}
                  defaultValue={this.state.oaDeal.publications}
                  submitBtnTxt="Save"
                  onSubmit={this.onSaveSelectedItems}
                  onClose={() => this.onModalSelectBoxClose()}
                />
              )}

              <div className="col-sm-4 deal-model">
                {this.showSelectItemsLabel("content_types")}
                <button
                  onClick={() => this.onModalSelectBoxShow("content_types")}
                  className="btn btn-success waves-effect waves-light float-right"
                  type="button"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>

              {this.state.isShowContentTypeModal && (
                <ModalSelectBox
                  label="Content types"
                  field="content_types"
                  modalClass="col-md-6"
                  labelClass="col-md-4"
                  className="col-sm-8"
                  data={
                    (this.props.content_type && [
                      { id: "all", name: "All content types" },
                      ...this.props.content_type,
                    ]) ||
                    []
                  }
                  defaultValue={this.state.oaDeal.content_types}
                  submitBtnTxt="Save"
                  onSubmit={this.onSaveSelectedItems}
                  onClose={() => this.onModalSelectBoxClose()}
                />
              )}
            </div>
            {(this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && (
              <div className="all-deal-feature">

                {this.state.oaDeal.all_deal_features &&
                  this.state.oaDeal.all_deal_features.length > 0 &&
                  this.renderDealFeatues(
                    this.state.oaDeal.all_deal_features
                  )}


                {this.state.config_options &&
                  this.state.config_options.length > 0 &&
                  this.renderInvoiceConfigOptions(
                    this.state.config_options
                  )}

              </div>
            )}

          </>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.onCloseModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSaveUpdateOadeal}
            >
              Save changes
            </button>
          </div>
        </div>
      </div >
    );
  }
}
let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0, 25px)",
    height: boxHeight,
    padding: 30,
  },
  error: { border: "1px solid red" },
};

const mapStateToProps = (state) => ({
  userId: state.auth.user.id || false,
  failed_oa_deal: state.OaDeals.failed_create_oadeal || false,
  organisation_id: state.auth.user.organisation_id || false,
  token: state.auth.token || false,
  publication_list: state.institutions.publication_list,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  content_type: state.journals.content_type,
  institution_list:(state.institutions &&  state.institutions.institution_list )|| [],
  loading: state.institutions.loading,

  oa_subjects: state.OaSubject.oa_subjects || false,
  oadeal_summary: state.OaDeals.oadeal_summary || false,
});
const mapDispatchToProps = (dispatch) => ({
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getContentType: (id) => dispatch(getContentTypeList(id)),
  getInstitutionDataLists: (value) => dispatch(getInstitutionDataLists(value)),
  getOaDealSummary: (orgId, payload) =>
    dispatch(getOaDealSummary(orgId, payload)),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OAdealIndividualAuthor);
