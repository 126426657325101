import React from "react";
import { Link } from "react-router-dom";
import {
  CheckCircleOutlined,
  DollarCircleOutlined,
  FundOutlined,
  DiffOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { PUBLISHER, INSTITUTION, FUNDER } from "../../data";

export const NotificationDropdown = ({ group }) => {
  const data = {
    pubnotification: [
      {
        icon: <CheckCircleOutlined />,
        name: "Account approval",
      },
      {
        icon: <DollarCircleOutlined />,
        name: "Deposit funds",
      },
      {
        icon: <FundOutlined />,
        name: "Fund threshold",
      },
      {
        icon: <DiffOutlined />,
        name: "Correction request",
      },
    ],
    uninotification: [
      {
        icon: <CheckCircleOutlined />,
        name: "Author request",
      },
      {
        icon: <DollarCircleOutlined />,
        name: "OA Account",
      },
      {
        icon: <FundOutlined />,
        name: "Tokens/Offset fund",
      },
      {
        icon: <DiffOutlined />,
        name: "Fund threshold",
      },
      {
        icon: <CheckSquareOutlined />,
        name: "Correction approval",
      },
    ],
  };
  return (
    <li className="dropdown notification-list">
      <Link
        to="/dashboard"
        className="nav-link dropdown-toggle arrow-none waves-effect"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <i className="ti-bell noti-icon"></i>
        <span className="badge badge-pill badge-danger noti-icon-badge">3</span>
      </Link>
      <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
        {/* <!-- item--> */}
        <div className="slimscroll notification-item-list">
          {/* <!-- item--> */}
          {group === PUBLISHER
            ? data.pubnotification.map((item, i) => (
                <Link
                  to="#"
                  className="dropdown-item notify-item active"
                  key={i}
                >
                  <div className="notify-icon">{item.icon}</div>
                  <p className="notify-details">{item.name}</p>
                </Link>
              ))
            : group === INSTITUTION || group === FUNDER
            ? data.uninotification.map((item, i) => (
                <Link
                  to="#"
                  className="dropdown-item notify-item active"
                  key={i}
                >
                  <div className="notify-icon">{item.icon}</div>

                  <p className="notify-details">{item.name}</p>
                </Link>
              ))
            : null}
        </div>
        {/* <!-- All--> */}
      </div>
    </li>
  );
};

export default NotificationDropdown;
