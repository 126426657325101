import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getOaDisciplineList,
  createUpdateOaSubject,
} from "../../../../actions";
import {
  PopupBox,
  Loading,
  InputBox,
  SelectBox,
  TextBox,
} from "../../../../../core/components/common";

class Add extends BaseComponent {
  state = {
    subject: {},
  };

  async componentDidMount() {
    await this.props.getOaDisciplineList();

    this.setState((prevState) => {
      return Object.assign(prevState.subject, this.state.subject);
    });
  }

  
  
  componentWillMount() {
    // when params sent via url
    if (this.props.history.location.state) {
      let params = this.props.history.location.state;
      this.setState({ params });
    }
  }
 
  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.subject, value);
    });
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();

    let data = this.state.subject;
    if (!data.discipline || !data.name) {
      this.setState({
        isEmpty: (
          <div>
            {!data.discipline && <p>Discipline must be selected</p>}
            {!data.name && <p>Subject name must be filled</p>}
          </div>
        ),
      });
    } else {
      this.setState({
        isRegistering: true,
      });
      let id = this.props.match.params.id;
      await this.props.createUpdateOaSubject(data, id);

      this.cancelLoader();

      if (this.props.oa_subject.success) {
        this.props.history.push("/subjects/subject-list");
      }
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
    });
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent title="Add subject">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        {/* LoadinG Handling */}
        {this.state.isRegistering && <Loading />}
        {/* LoadinG Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card m-b-20">
              <div className="card-body">
                <form>
                  <div className="form-group row">
                    <SelectBox
                      label="Discipline"
                      onChange={this.onChangeHandler}
                      field="discipline"
                      data={this.props.oa_disciplines && this.props.oa_disciplines.results}
                      isRequired={true}
                      className="col-sm-9"
                      labelClass="col-sm-3"
                      defaultValue={this.state.subject.discipline}
                    />
                  </div>
                  <div className="form-group row">
                    <InputBox
                      label="Name"
                      onChange={this.onChangeHandler}
                      field="name"
                      placeholder="Subject name"
                      className="col-sm-9"
                      labelClass="col-sm-3"
                      isRequired={true}
                      defaultValue={this.state.subject.name}
                    />
                  </div>
                  <div className="form-group row">
                    <TextBox
                      label="Description"
                      onChange={this.onChangeHandler}
                      field="description"
                      placeholder="Description"
                      className="col-sm-9"
                      labelClass="col-sm-3"
                      defaultValue={this.state.subject.description}
                    />
                  </div>

                  <div className="text-center m-t-15">
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      onClick={this.onSubmitHandler}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  oa_subject: state.OaSubject.oa_subject || false,
});

const mapDispatchToProps = (dispatch) => ({
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  createUpdateOaSubject: (payload, id) =>
    dispatch(createUpdateOaSubject(payload, id)),
});

export const EditSubject = connect(mapStateToProps, mapDispatchToProps)(Add);
