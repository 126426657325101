import config, {
    GET_GENERIC_OAUTH_LOGIN_URL,
    SUBMIT_GENERIC_OAUTH_AUTH_CODE,
    RECIEVED_LOGIN_DATA
} from '../../config'
import { api } from '../../core/api';

export const getGenericAuthUrlReducer = (payload) => ({
    type: GET_GENERIC_OAUTH_LOGIN_URL,
    payload
})

export const submitGenericAuthCodeReducer = (payload) => ({
    type: SUBMIT_GENERIC_OAUTH_AUTH_CODE,
    payload
})



// Successfull Login  Action Creator
export const recievedLoginData = (payload) => ({
    type: RECIEVED_LOGIN_DATA, // eslint-disable-line
    payload,
});


export const submitGenericAuthCode = (payload) => {
    let url = config.endpoint.submit_generic_oauth_auth_code;

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            api.post(url, payload).then((res) => {
                if (res.data.result === "USER_LOGGED_IN") {
                    dispatch(
                        recievedLoginData(Object.assign(res.data.login_response, { status: res.status }))
                    );
                    localStorage.setItem("auth", JSON.stringify(res.data.login_response));
                    resolve(res);

                } else {
                    dispatch(submitGenericAuthCodeReducer(res.data.orcid_response))
                    resolve(res.data.orcid_response);
                }
            }).catch(function (error) {
                dispatch(submitGenericAuthCodeReducer(error.response))
                reject(error);
            })
        })
    }
}
export const getGenericAuthUrl = (payload) => {
    let url = config.endpoint.obtain_generic_oauth_login_url;

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            api.post(url, payload).then((res) => {
                dispatch(getGenericAuthUrlReducer(res))
                resolve(res);
            }).catch(function (error) {
                dispatch(getGenericAuthUrlReducer(error.response))
                reject(error);
            })
        })
    }
}

export const createGenericAuthUrl = (payload) => {
    let url = config.endpoint.create_oauth_user;
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            api.post(url, payload).then((res) => {
                dispatch(recievedLoginData(Object.assign(res.data, { status: res.status })));
                localStorage.setItem("auth", JSON.stringify(res.data));

            }).catch(function (error) {
                dispatch(submitGenericAuthCodeReducer(error.response))
                reject(error);
            })

        })
    }
}
