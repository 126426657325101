import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { getAllUser, deleteUser, fetchLoginUser, mutateUserStatus } from "../../../../actions";
import NotFound from "../../NotFound";
import {
  PopupBox,
  Loading,
  InputGroup,
  // SelectBox,
} from "../../../../../core/components/common";
import { confirmAlert } from "react-confirm-alert";

const timezone = moment.tz.guess();

class UL extends BaseComponent {
  state = {
    deleteConfirm: false,
    userId: null,
    waitingMsg: null,
    deleteStatus: null,
    data: [],
  };
  getUserData = async (payload = {}) => {
    payload.pageNum = payload.page || 1;

    if (!payload.pageSize) {
      payload.pageSize = 10;
    }

    await this.props.getAllUser(payload);
    this.setState({
      data: this.props.users.results,
      count: this.props.users.count,
    });
  };
  componentDidMount() {

 /*   if (this.props.location && this.props.location.filter && this.props.location.filter.page) {
      this.pageChange(this.props.location && this.props.location.filter && this.props.location.filter.page)
  }
  */
    this.getUserData();
  }

    
   componentWillUnmount() {
            // fix Warning: Can't perform a React state update on an unmounted component
            this.setState = (state,callback)=>{
                return;
            };
        
    }

  pageChange = (pageNum) => {
    let payload = {
      page: pageNum,
    };

    if (this.state.q) {
      payload["q"] = this.state.q;
    }

    this.getUserData(payload);
  };

  onEditUser = (id, data) => {
    this.props.history.push("/user/edit-user/" + id, { user: data });
  };

  onViewUser = (id, data) => {
    this.props.history.push("/user/" + id, { user: data });
  };

  onMutateUserStatus = (id, status = true) => {
    let action = ''
    let popupTitle = ''
    let popupSuccessMsg = ''

    if (status) {
      action = 'deactivate-user'
      popupTitle = 'Are you sure to deactivate the user?'
      popupSuccessMsg = 'The user has been deactivated successfully.'
    } else {
      action = 'activate-user'
      popupTitle = 'Are you sure to activate the user?'
      popupSuccessMsg = 'The user has been activated successfully.'
    }

    if (id) {
      this.setState({
        changeStatusConfirm: true,
        mutateAction: action,
        userId: id,
        popupTitle,
        popupSuccessMsg,
      });
    }
  };

  onMutateUserStatusHandler = async () => {
    const userId = this.state.userId;
    const mutateAction = this.state.mutateAction;
    this.setState({
      waitingMsg: "Proceesing...",
    });

    this.props.mutateUserStatus(userId, mutateAction)
      .then((response) => {
        this.getUserData();

        this.setState({
          waitingMsg: null,
          userId: null,
          mutateAction: null,
          changeStatusConfirm: false,
          popupTitle: null,
          changeStatus: response.status,
        });
      }).catch(error => {
      });
    
  };

  onDeleteUser = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        userId: id,
      });
    }
  };

  onProxyUserLogin = (id, user) => {
    let data = { user: id, timezone, is_proxy: true };

    let msg = "";

    let buttons = [];

    msg = `You will be logged in as ${user.fullname}. Are you sure?`;
    buttons = [
      {
        label: "Yes",
        onClick: async () => {
          await this.props.fetchLoginUser(data);
          this.props.history.push('/');
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ];
  

    return confirmAlert({
      title: "",
      message: msg,
      buttons: buttons,
    });
  };

  onDeleteUserHander = async () => {
    const userId = this.state.userId;
    this.setState({
      waitingMsg: "Deleting...",
    });
    await this.props.deleteUser(userId);
    let data = this.state.data;
    let newData = data.filter(function (element) {
      return element.id !== userId;
    });
    this.setState({
      waitingMsg: null,
      userId: null,
      deleteConfirm: false,
      data: newData,
    });
 
  };

  filterData = async (value) => {
    value.page = 1;

    this.setState({
      ...value,
    });

    this.getUserData(value);
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      userId: null,
      waitingMsg: null,
      deleteStatus: null,
      mutateAction: null,
      changeStatusConfirm: false,
      changeStatus: false,
      popupTitle: null,
      popupSuccessMsg: null,
    });
  };

  render() {
    const heading = [
      "fullname",
      "email",
      "group_name",
      "organisation_name",
      "role_name",
      "active_status_label",
    ];
    let user = this.props.users;
    let failedMsg = this.props.failed;

    let errorHander = (
      <>
        {failedMsg && failedMsg.status === 400 && !this.props.users && (
          <PopupBox
            title="Invalid Request"
            msg="Your Request is not valid"
            onCancel={this.cancelLoader}
          />
        )}

        {failedMsg && failedMsg.status === 500 && !this.props.users && (
          <PopupBox
            title="Network Error Found"
            msg="Please Check Internet Connection and Try Again"
            onCancel={this.cancelLoader}
          />
        )}

        {!user && !failedMsg && failedMsg !== null && <Loading />}
   
      </>
    );

    return this.state.status === 403 ? (
      <NotFound />
    ) : (
      <HubContent
        title="User List"
      >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteUserHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}

        {this.state.deleteStatus && (
          <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
        )}

        {this.state.changeStatusConfirm && (
          <PopupBox
            title={this.state.popupTitle}
            YesText="Yes"
            NoText="No"
            yesBtnType="danger"
            type="danger"
            NoBtnType="primary"
            onCancel={this.onCancel}
            onPressOK={this.onMutateUserStatusHandler}
            waitingMsg={this.state.waitingMsg}
          />
        )}

        {this.state.changeStatus && (
          <PopupBox title={this.state.popupSuccessMsg} onCancel={this.onCancel} />
        )}

        {this.state.deleteStatus === false && (
          <PopupBox
            title="Failed... Try Again"
            onCancel={this.onCancel}
            NoBtnType="danger"
          />
        )}
        {errorHander}

        <div className="row">
        <div className="col-md-8">
            <Link
              to={this.props.group === "HUB" ? "/user/add-user":"/users/add-user"}
              className="btn btn-primary float-right mb-2"
            >
              Add User
            </Link>
          </div>
          <div className="col-md-4">
            <InputGroup
              onClick={this.filterData}
              field="q"
              placeholder="Search"
              className="col-md-12 mb-2 free-text-search"
            />
          </div>{" "}
          <div className="col-12">
            <div className="card m-b-20">
              {this.props.users.results && (
                <DataTable
                  heading={heading}
                  data={this.props.users.results}
                  onEdit={this.onEditUser}
                  onDelete={this.onDeleteUser}
                  onStatusChange={this.onMutateUserStatus}
                  onView={this.onViewUser}
                  onProxyLogin={
                    this.props.group === "HUB" && this.onProxyUserLogin
                  }
                  count={this.props.users.count}
                  userId={this.props.auth && this.props.auth.user && this.props.auth.user.id}
                  pageChange={this.pageChange}
                  pageSize={10}
                  group={this.props.group}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: state.auth.group || false,
  auth: state.auth,
  users: state.user.users || false,
  failed: state.user.failed || false,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUser: (payload) => dispatch(getAllUser(payload)),
  deleteUser: (id) => dispatch(deleteUser(id)),
  fetchLoginUser: (data) => dispatch(fetchLoginUser(data)),
  mutateUserStatus: (id, action, data = {}) => dispatch(mutateUserStatus(id, action, data)),
});

export const UserList = connect(mapStateToProps, mapDispatchToProps)(UL);
