import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import {
  InputBox,
  SelectBox
} from "../../../../core/components/common";
import moment from 'moment'
import { Checkbox, Select } from 'antd';
import {
  articleInvoiceSummary,
  articleInvoice,
  applyTokenAction,
  getLicense,
  getPublisherLicense,
  downloadInvoice
} from '../../../actions';
import { history } from '../../../route';
import { confirmAlert } from 'react-confirm-alert';
import ReactHtmlParser from 'react-html-parser';
import config from '../../../../config';

const { Option } = Select;

const articlePublisherTerms = config.endpoint.article_publisher_terms;
const articlePublisherLicence = config.endpoint.article_publisher_licence;

class Invoice extends React.Component {
  state = {
    cardData: {},
    tokenData: {},
    billing_address: {},
    payment_data: {},
    cardToken: {},
    radio_value: 'new_address',
    pay_value: 0,
    apply_token_status: false,
    article_payment_status: false,
    edit: false,
    invoice_data: {
      payment_source: "bank",
      transaction_ref: "",
      expired_in: 30,
      description: "",
      amount: "100.00",
      token_discount: "0",
      other_discount: "0",
      subtotal: "100.00",
      tax: "0",
      tax_percent: "0",
      total: "100.00",
      created_by: 1,
      article: 84,
      open_by_user: "1",
      token: "",
      licence: "1",
      currency: "3"
    },
    existing_address: [
      {
        "id": 1,
        "street_address1": "House #14/1, Road: Hazi chinu mia road",
        "street_address2": "Mohammadpur, Dhaka",
        "city": "Dhaka",
        "state": "Dhaka",
        "postal_code": "1207",
        "country": "Bangladesh",
      }
    ]
  }
  async componentDidMount() {
    await this.onArticleInvoiceSummary();
    let billing_address = this.props.billing_address;
    this.setState({
      billing_address: billing_address
    });

    let pubLicense = false;
    pubLicense = await this.props.getLicense();

    // if (article && article.publisher) {
    //   let payload = {
    //     publisher_id: article.publisher.id,
    //   };

    //   pubLicense = await this.props.getPublisherLicense(payload);
    // }

    this.setState(prevState => {
      if (pubLicense) prevState.pub_license = pubLicense;

      return prevState
    });
  }
  _renderSummery = (data) => {
    let summaryElements = [];
    data && data.map((summary, index) => {
      if (summary.description !== 'DRAW_BORDER') {
        return summaryElements.push(
          <div key={`summary-${index}`}>
            <div className={`col-sm-8 text-right ${summary.bold && "font-weight-bold"}`} style={{ float: 'left' }}>{summary.description}</div>
            <div className={`col-sm-4 text-right ${summary.bold && "font-weight-bold"}`} style={{ float: 'left', fontSize: summary.bold ? 13 : undefined }}>{summary.value !== "" ? summary.value : 0}</div>
          </div>
        );
      } else {
        return summaryElements.push(
          <div key={`summary-${index}`} className="col-sm-12" style={{ clear: 'both', width: '100%' }}></div>
        );
      }
    });
    return summaryElements;
  }
  _renderRow = (title, content) => {
    return (
      <div className="payment-head" style={{ width: '100%', clear: 'both' }}>
        <h5>{title}</h5>
        <h5>{content}</h5>
      </div>
    )
  }

  formatCurrency = () => {
    let currencyData = [];
    this.props.currency && this.props.currency.map((value, index) => {
      return currencyData.push({
        id: value.id,
        name: value.short_name
      })
    })
    return currencyData;
  }


  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  filterObject(allowed = [], object = {}) {
    let filtered = Object.keys(object).filter(key => allowed.includes(key)).reduce((obj, key) => {
      return {
        ...obj,
        [key]: object[key]
      }
    }, {})
    return this.clean(filtered);
  }

  errorCount = (data, required_fields, required_field_messages) => {
    let error_field = [];
    required_fields.map((value) => {
      if (!(value in data)) {
        error_field.push(required_field_messages[value]);
      }
      return error_field;
    })
    return error_field.length;
  }

  errorMessage = (data, required_fields, required_field_messages) => {
    let error_field = [];
    required_fields.map((value) => {
      if (!(value in data)) {
        error_field.push(required_field_messages[value]);
      }
      return error_field;
    })
    let errorMessage = ReactHtmlParser(error_field.join('<br>'));
    let errorLength = error_field.length;
    if (errorLength > 0) {
      return (
        confirmAlert({
          message: errorMessage,
          buttons: [
            {
              label: 'Close',
              onClick: () => { }
            }
          ]
        })
      )
    }
    return errorLength;
  }

  onPayment = async () => {
    let articleId = { article: history.location.state.id };
    let data = this.state.cardData;
    let billing_address = this.state.billing_address;
    let allowed = ['street_address1', 'street_address2', 'city', 'state', 'postal_code', 'country'];
    let address_data = this.filterObject(allowed, billing_address);
    let invoice_data = { ...articleId, ...data, ...{ new_billing_address: address_data } }
    let required_fields = ['new_billing_address', 'licence', 'currency'];
    let required_field_messages = {
      new_billing_address: 'Billing address is required',
      licence: 'Licence is required',
      currency: 'Currency is required',
    }
    let errorCount = this.errorCount(invoice_data, required_fields, required_field_messages);
    if (errorCount > 0) {
      this.errorMessage(invoice_data, required_fields, required_field_messages);
    } else {
      delete invoice_data.token;
      await this.props.articleInvoice(invoice_data);
      history.push('/transactions/invoice')
    }
  }

  onChangeCard = async (value) => {
    let keys = Object.keys(value);
    let values = Object.values(value);
    if (keys[0] === 'currency') {
      let data = this.formatCurrency();
      let index = data && data.findIndex(x => x.id === values[0]);
      let currency_code = data[index].name;
      this.setState({
        currency_code: currency_code.toLowerCase()
      })
      this.onArticleInvoiceSummary({ isParam: true, currency_code: currency_code.toLowerCase() })
    }

    let billing_address = ['address_line1', 'address_line2', 'address_zip', 'address_city', 'address_state', 'address_country'];
    let payment_data = ['licence', 'currency', 'remarks'];
    if (billing_address.includes(keys[0])) {
      let newVal;
      if (keys[0] === 'address_line1') {
        newVal = { street_address1: values[0] }
      } else if (keys[0] === 'address_line2') {
        newVal = { street_address2: values[0] }
      } else if (keys[0] === 'address_city') {
        newVal = { city: values[0] }
      } else if (keys[0] === 'address_state') {
        newVal = { state: values[0] }
      } else if (keys[0] === 'address_country') {
        newVal = { country: values[0] }
      } else if (keys[0] === 'address_zip') {
        newVal = { postal_code: values[0] }
      }
      this.setState((prevState) => {
        Object.assign(prevState.billing_address, newVal);
        return prevState;
      })
    } else if (payment_data.includes(keys[0])) {
      this.setState((prevState) => {
        Object.assign(prevState.payment_data, value);
        return prevState;
      })
    }
    if (!payment_data.includes(keys[0])) {
      this.setState((prevState) => {
        Object.assign(prevState.cardToken, value);
        return prevState;
      })
    }
    this.setState((prevState) => {
      Object.assign(prevState.cardData, value);
      return prevState;
    })
  }

  setEdit = () => {
    this.setState({
      edit: !this.state.edit
    })
  }


  //  payload Ex. {id:id,currency_code:'USD',licence:2,isParam:true}
  onArticleInvoiceSummary = async (payload = false) => {
    let articleId = history.location.state.id
    if (payload) {
      await this.props.articleInvoiceSummary(Object.assign({ id: articleId }, payload));
    } else {
      await this.props.articleInvoiceSummary({ id: articleId });
    }
  }

  async onApplyToken() {
    if (this.state.cardData.token) {
      await this.props.applyTokenAction({ token: this.state.cardData.token });
      this.state.currency_code ? this.onArticleInvoiceSummary({ isParam: true, currency_code: this.state.currency_code }) : this.onArticleInvoiceSummary();
      if (this.props.apply_token_status) {
        this.setState({
          apply_token_status: true
        })
      }
    }
  }

  OnClickterms = (terms) => {
    this.setState({
      terms
    })
  }

  onChangeChecked = (e) => {
    this.setState({
      termsandcondition: e.target.checked
    })
  }

  licencepdfdownload = async (e, url, name) => {
    e.preventDefault()
    if (!url) {
      return;
    }

    let payload = {
      url: url,
    };

    await this.props.licensepdfdownload(payload);

    const urlObj = window.URL.createObjectURL(this.props.licence_download);
    const link = document.createElement("a");
    link.href = urlObj;
    var d = new Date();

    link.setAttribute(
      "download",
      `${name}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  }


  onLicenceChange = (data) => {
    let parsedData = JSON.parse(data);

    let { cardData } = this.state;
    Object.assign(cardData, {
      licence: parsedData.id,
    });
    let url = `${articlePublisherLicence}${history.location.state.id}/download/?licence=${parsedData.id}`;

    this.setState({
      cardData: cardData,
      downloadlink: {
        link: url,
        name: "Licence"
      }
    });
  }

  render() {
    let ArticleSummary = this.props.article_invoice_summary;
    const articlePkId = this.props.article && this.props.article.id;

    let ArticleSummarySingleLine = [];
    let ArticleSummarySingleLineLast = [];
    ArticleSummary && ArticleSummary.auther_name && ArticleSummarySingleLine.push(`Author : ${ArticleSummary.auther_name}`)
    ArticleSummary && ArticleSummary.publisher_name && ArticleSummarySingleLine.push(`Publisher : ${ArticleSummary.publisher_name}`)
    ArticleSummary && ArticleSummary.content_type_name && ArticleSummarySingleLineLast.push(`Content type  : ${ArticleSummary.content_type_name}`)
    ArticleSummary && ArticleSummary.author_affiliation && ArticleSummarySingleLineLast.push(`Affiliation : ${ArticleSummary.author_affiliation}`)
    ArticleSummary && ArticleSummary.funder_name && ArticleSummarySingleLineLast.push(`Funder : ${ArticleSummary.funder_name}`)
    ArticleSummary && ArticleSummary.grant_number && ArticleSummarySingleLineLast.push(`Grunt Number : ${ArticleSummary.grant_number}`)

    return (
      this.props.addpay ? <div id="CoAuthorDialog" className="pay" style={modal.overlay}>
        <div className={`col-10 col-lg-8 bg-white alert`} style={modal.boxStyle}>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card-box pay-box">
                          <div className="row mt-3">
                            <div className="col-md-6">
                              <h3>Billing Address</h3>
                              <div>
                                <button
                                  style={{ height: 35, width: 65, marginTop: -85, marginLeft: 208 }}
                                  onClick={() => this.setEdit()}
                                  className="btn btn-primary">
                                  {this.state.edit ? 'Save' : 'Edit'}
                                </button>
                              </div>
                              {!this.state.edit && <div style={{ marginLeft: -15, marginTop: -20 }}>
                                {this.props.article_invoice_summary && this.props.article_invoice_summary.auther_affiliation && <div>
                                  <div className="col-sm-12 text-left false" style={{ float: 'left' }}>
                                    <h6>{this.props.article_invoice_summary.auther_affiliation}</h6>
                                  </div>
                                </div>}
                                {this.state.billing_address && this.state.billing_address.street_address1 && <div>
                                  <div className="col-sm-12 text-left false" style={{ float: 'left' }}>
                                    {this.state.billing_address.street_address1}
                                  </div>
                                </div>}
                                {this.state.billing_address && this.state.billing_address.street_address2 && <div>
                                  <div className="col-sm-12 text-left false" style={{ float: 'left' }}>
                                    {this.state.billing_address.street_address2}
                                  </div>
                                </div>}
                                {this.state.billing_address && this.state.billing_address.city && <div>
                                  <div className="col-sm-12 text-left false" style={{ float: 'left' }}>
                                    {[this.state.billing_address.city, this.state.billing_address && this.state.billing_address.state].join(', ')}
                                  </div>
                                </div>}
                                {this.state.billing_address && this.state.billing_address.postal_code && <div>
                                  <div className="col-sm-12 text-left false" style={{ float: 'left' }}>
                                    {this.state.billing_address.postal_code}
                                  </div>
                                </div>}
                                {this.state.billing_address && this.state.billing_address.country && <div>
                                  <div className="col-sm-12 text-left false" style={{ float: 'left' }}>
                                    {this.state.billing_address.country}
                                  </div>
                                </div>}
                              </div>}

                              {this.state.edit && <div style={{ width: '100%', marginLeft: -30, marginTop: -20 }}>
                                <div className="col-md-6 address" style={style.floatLeft}>
                                  <label>Address line 1</label>
                                  <InputBox
                                    onChange={(value) => this.onChangeCard(value)}
                                    field='address_line1'
                                    placeholder="Address 1"
                                    className="col-md-12 pay-right-ii"
                                    defaultValue={this.state.billing_address.street_address1}
                                  />
                                </div>
                                <div className="col-md-6 address" style={style.floatLeft}>
                                  <label>Address line 2</label>
                                  <InputBox
                                    onChange={(value) => this.onChangeCard(value)}
                                    field='address_line2'
                                    placeholder="Address 2"
                                    className="col-md-12 pay-right-ii"
                                    defaultValue={this.state.billing_address.street_address2}
                                  />
                                </div>
                                <div className="col-md-6 address" style={style.floatLeft}>
                                  <label>City</label>
                                  <InputBox
                                    onChange={(value) => this.onChangeCard(value)}
                                    field='address_city'
                                    placeholder="City"
                                    className="col-md-12 pay-right-ii"
                                    defaultValue={this.state.billing_address.city}
                                  />
                                </div>
                                <div className="col-md-6 address" style={style.floatLeft}>
                                  <label>Zip code</label>
                                  <InputBox
                                    onChange={(value) => this.onChangeCard(value)}
                                    field='address_zip'
                                    placeholder="Zip code"
                                    className="col-md-12 pay-right-ii"
                                    defaultValue={this.state.billing_address.postal_code}
                                  />
                                </div>
                                <div className="col-md-6 address" style={style.floatLeft}>
                                  <label>State</label>
                                  <InputBox
                                    onChange={(value) => this.onChangeCard(value)}
                                    field='address_state'
                                    placeholder="State"
                                    className="col-md-12 pay-right-ii"
                                    defaultValue={this.state.billing_address.state}
                                  />
                                </div>
                                <div className="col-md-6 address" style={style.floatLeft}>
                                  <label>Country</label>
                                  <InputBox
                                    onChange={(value) => this.onChangeCard(value)}
                                    field='address_country'
                                    placeholder="Country"
                                    className="col-md-12 pay-right-ii"
                                    defaultValue={this.state.billing_address.country}
                                  />
                                </div>
                              </div>
                              }

                            </div>
                            <div className="col-md-6">
                              {this.props.licence && <div className="token-m-30" style={style.commonWidth}>
                                <div className="row" style={{ width: '100%', marginLeft: 43, marginTop: 45 }}>
                                  <div className="col-sm-12">
                                    <SelectBox
                                      className="col-sm-12"
                                      onChange={(value) => this.onChangeCard(value)}
                                      field='currency'
                                      data={this.formatCurrency()}
                                      defaultValue="Select currency"
                                    />
                                  </div>
                                </div>
                              </div>}
                            </div>
                          </div>
                          <div className="row pay-m-10">
                            <div className="col-md-12">
                              <div className="row pay-row-bac">
                                <div className="col-md-10"><p>Description</p></div>
                                <div className="col-md-2"><p className="amount-right">Amount</p></div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="payment-title" style={{ marginBottom: 20 }}>
                                    <h1>{ArticleSummary && `${ArticleSummary.title}`}</h1>
                                    {ArticleSummarySingleLine.join(', ')} <><br /></> {ArticleSummarySingleLineLast.join(', ')}

                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row">
                                    {ArticleSummary && <div className="token-m-30" style={style.commonWidth}>
                                      <div style={{ width: '70%', float: 'left' }}>
                                        <InputBox
                                          className="col-md-12"
                                          onChange={(value) => this.onChangeCard(value)}
                                          field='token'
                                          placeholder="Enter token"
                                          dafault={this.state.cardData.token}
                                        />
                                      </div>
                                      <div style={{ width: '30%', float: 'left' }}>
                                        <button style={{ float: 'left', marginTop: 0, height: 30 }} onClick={() => { return this.onApplyToken() }} className="btn btn-primary btn-md waves-effect waves-light">
                                          Apply token
                                      </button>
                                      </div>
                                    </div>}
                                    {ArticleSummary && <div className="token-m-30" style={style.commonWidth}>
                                      <div style={{ width: '70%', float: 'left' }}>
                                        <InputBox
                                          className="col-md-12"
                                          onChange={(value) => this.onChangeCard(value)}
                                          field='vat'
                                          placeholder="VAT"
                                          dafault={this.state.cardData.token}
                                        />
                                      </div>
                                      <div style={{ width: '30%', float: 'left' }}>
                                        <button style={{ float: 'left', marginTop: 0, height: 30 }} onClick={() => { return this.onApplyToken() }} className="btn btn-primary btn-md waves-effect waves-light">
                                          VAT Submit
                                      </button>
                                      </div>
                                    </div>}
                                    {this.state.pub_license && <div className="token-m-30" style={style.commonWidth}>
                                      <div style={{ width: '70%', float: 'left' }}>
                                        <div className="col-sm-12">
                                          <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Select licence"
                                            optionFilterProp="children"
                                            onChange={this.onLicenceChange}

                                            filterOption={(input, option) =>
                                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                          >
                                            {this.state.pub_license && this.state.pub_license.map((item) => (

                                              <Option value={JSON.stringify(item)} key={item.name}>{item.name}</Option>

                                            ))
                                            }
                                          </Select>
                                        </div>
                                      </div>
                                      <div style={{ width: '30%', float: 'left' }}>
                                        <button
                                          type="button"
                                          style={{ float: 'left', width: 90, marginTop: 0, height: 30 }}
                                          onClick={(e) => this.licencepdfdownload(e, this.state.downloadlink && this.state.downloadlink.link, "License")}
                                          className="btn btn-primary btn-md waves-effect waves-light"
                                        >View</button>
                                      </div>
                                    </div>}

                                    <div className="token-m-30" style={style.commonWidth}>
                                      <div style={{ width: '70%', float: 'left' }}>
                                        <Checkbox onChange={this.onChangeChecked}>I  agree with Publisher’s Terms & Conditions </Checkbox>

                                      </div>
                                      <div style={{ width: '30%', float: 'left' }}>
                                        <button
                                          onClick={(e) => this.licencepdfdownload(e, `${articlePublisherTerms}${articlePkId}/download/`, "TermsandCondition")}
                                          style={{ float: 'left', width: 90, marginTop: 0, height: 30 }}

                                          type="button"
                                          className="btn btn-primary btn-md waves-effect waves-light"
                                        >View</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div>
                                    <div className="row">
                                      <div style={{ width: '100%', marginBottom: 20, marginTop: 8 }}>
                                        {ArticleSummary && this._renderSummery(ArticleSummary.summary)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-right">
                              <button
                                style={{ height: 40 }}
                                onClick={() => this.onPayment()}
                                disabled={!this.state.termsandcondition}
                                className={`btn btn-success btn-md waves-effect waves-light text-center${!this.state.termsandcondition && ' disabled'}`}
                              >
                                Generate invoice
                            </button>
                              <button style={{ height: 40 }} onClick={() => this.props.history.push("/payment/addpayment", { id: this.props.articleId, article: this.props.article })} className="btn btn-secondary btn-md waves-effect waves-light">
                                Close
                            </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : ''
    )
  }
}

let boxHeight = ''
if (navigator.userAgent.toLowerCase().includes('firefox')) {
  boxHeight = '-moz-fit-content'
} else if (navigator.userAgent.toLowerCase().includes('chrome')) {
  boxHeight = '-webkit-fit-content'
}

const modal = {
  overlay: {
    position: 'fixed',
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center'
  },
  boxStyle: {
    transform: 'translate(125px, 125px)',
    height: boxHeight
  }
}

const style = {
  commonWidth: {
    width: '100%'
  },
  floatLeft: {
    float: 'left',
  }
}



const mapStateToProps = (state) => ({
  article_invoice_summary: (state.payment && state.payment.article_invoice_summary) || false,
  billing_address: (state.payment && state.payment.article_invoice_summary && state.payment.article_invoice_summary.last_billing_address) || false,
  currency: (state.OaDeals && state.OaDeals.currency_list && state.OaDeals.currency_list.results) || false,
  licence: (state.articles && state.articles.licence_list && state.articles.licence_list.results) || false,
  licence_download: (state.payment && state.payment.invoice_download) || false,
})

const mapDispatchToProps = (dispatch) => ({
  articleInvoice: (payload) => dispatch(articleInvoice(payload)),
  articleInvoiceSummary: (payload) => dispatch(articleInvoiceSummary(payload)),
  applyTokenAction: (payload) => dispatch(applyTokenAction(payload)),
  getPublisherLicense: (payload) => dispatch(getPublisherLicense(payload)),
  licensepdfdownload: (payload = {}) => dispatch(downloadInvoice(payload)),
  getLicense: (payload = {}) => dispatch(getLicense(payload))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Invoice));


