import React from "react";
import { connect } from "react-redux";
import { AutoComplete, } from "antd";
import BaseComponent from "../../../core/components/BaseComponent";
import { CHeader, PubHeader, UnivHeader, UserHeader } from "./Headers";
import { LeftSidebar } from "./LeftSidebar";
import {
  HubSidebar,
  PubSidebar,
  UnivSidebar,
  UserSidebar,
  HUB,
  AUTHOR,
  PUBLISHER,
  INSTITUTION,
  CONSORTIUM,
  ConsortiaSidebar,
  FUNDER,
  FunderSidebar,
} from "../../data";
import MenuItem from "../partials/dashboard/MenuItem";
import {
  getPendingOaDealRequestCount,
  sendFreeTextEmail,
  searchArticleFull,
  logoutProxyUserData,
  logoutUserData
} from "../../actions";

import {
  SelectBox,
  Loading,
  TextBox,
  InputBox,
  ModalCustom,
  PopupBox
} from '../../../core/components/common';

import {
  getAllApcRequests
} from "../../actions";

export class Header extends BaseComponent {

  state = {}

  logoutUser = (e) => {
    if (this.props.is_proxy_login) {
      this.props.logoutProxyUser();
    } else {
      this.props.logoutUser();
    }
  };

  componentDidMount() {
    window.addEventListener("storage", () => {
      window.location.reload();
    });
    //if (this.props.group === "INSTITUTION") {
      this.getApcRequestsData();
   // }

  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  getApcRequestsData = async (pageNum = 1, page = 20) => {
    await this.props.getAllApcRequests({ pageNum: pageNum, pageSize: page, actionable: true, });

    this.setState({
      apc_requests_count: this.props.apc_requests_count && this.props.apc_requests_count.count
    })
  }

  showAuthUserInfo = () => {
    const { auth } = this.props;

    if (auth && auth.proxy_login) {
      return ` as ${auth.user && auth.user.fullname}`;
    }

    return "";
  };

  cancelLoader = async () => {
    this.setState({
      isEmpty: false,
      successMsg: false,
    })
  }

  onCloseHandler = () => {
    this.setState({
      showCustomEmailForm: false,
      context: '',
      article_id: '',
      subject: '',
      recipients: '',
      message_body: '',
    });
  }

  onSelect = (value, item) => {
    this.setState({
      article_id: item.id,
    });
  };

  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      let params = {
        q: value,
      };

      this.props.searchArticleFull(params).then(response => {
        if (response && response.success) {
          this.setState({
            dataList: response && response.results && response.results.map(item => ({
              id: item.id,
              value: item.title,
            }))
          });
        }
      }).catch(error => {
        return error.response && error.response.data && error.response.data.success;
      });

    }, 200);
  };

  onChangeHandler = (value) => {

    if (value && value.context === 'ARTICLE') {
      this.props.searchArticleFull({}).then(response => {

        if (response && response.success) {
          this.setState({
            dataList: response.results && response.results.map(item => ({
              id: item.id,
              value: item.title,
            }))
          });
        }
      }).catch(error => {

        return error.response && error.response.data && error.response.data.success;
      });
    }

    this.setState((prevState) => {
      return Object.assign(prevState, value);
    });
  };

  onSubmitHandler = async (e) => {

    let data = {
      context: this.state.context,
      article_id: this.state.context === 'ARTICLE' ? this.state.article_id : null,
      subject: this.state.subject,
      recipients: this.state.recipients,
      message_body: this.state.message_body,
    };

    if (!data.context || !data.subject || !data.recipients || !data.message_body) {
      this.setState({
        isEmpty: <div>
          {!data.context && <p>Context is required.</p>}
          {(data.context === 'ARTICLE' && !data.article_id) && <p>Context is required.</p>}
          {!data.recipients && <p>Recipient is required.</p>}
          {!data.subject && <p>Subject is required.</p>}
          {!data.message_body && <p>Message is required.</p>}
        </div>
      })
    } else {

      this.props.sendFreeTextEmail(data).then(response => {
        if (response.data && response.data.success) {
          this.setState({
            showCustomEmailForm: false,
            successMsg: response.data.message,
            context: '',
            article_id: '',
            subject: '',
            recipients: '',
            message_body: '',
          })
        }
      }).catch(error => {
        return error.response && error.response.data && error.response.data.success;
      });
    }
  };

  onCustomEmailButtonClick = () => {
    this.setState({
      showCustomEmailForm: true,
    })
  }

  renderCustomEmailButton = () => {
    return (
      <li className="d-none d-sm-block pr-3">
        <div className="pt-3">
          <button onClick={this.onCustomEmailButtonClick} className="btn btn-info w-md waves-effect waves-light" type="button"><b>Send Ad Hoc Email</b></button>
        </div>
      </li>
    )
  }

  renderCustomEmailForm = () => {
    return (
      <form className="apc-form">
        {this.state.loading && <Loading />}

        <div className="form-group row">
          <SelectBox
            placeholder="Select from dropdown"
            label="Relevance"
            onChange={this.onChangeHandler}
            field="context"
            className="col-sm-10"
            data={[{ id: 'ARTICLE', name: 'Article' }, { id: 'NON_ARTICLE', name: 'Non Article' }]}
            defaultValue={this.state.context}
            isRequired={true}
          />
        </div>

        {
          this.state.context === 'ARTICLE' &&
          <div className="form-group row">

            <label className="col-form-label col-sm-2">
              Article
                  <span className="text-danger"> * </span>
            </label>

            <div className="col-sm-10">

              <AutoComplete
                defaultValue={this.state.article_id}
                notFoundContent="No found Data"
                options={this.state.dataList || []}
                style={{
                  width: "100%",
                }}
                onSelect={(val, option) => this.onSelect(val, option)}
                onSearch={this.onSearch}
                onChange={this.onChangeHandler}
                placeholder="Type your article name"
              />
            </div>
          </div>
        }

        <div className="form-group row">
          <InputBox
            label="Recipient"
            onChange={this.onChangeHandler}
            className="col-sm-10"
            field="recipients"
            placeholder="johndoe@test.com (use comma for multiple recipients)"
            defaultValue={this.state.recipients}
            isRequired={true}
          />
        </div>

        <div className="form-group row">
          <InputBox
            label="Subject"
            onChange={this.onChangeHandler}
            className="col-sm-10"
            field="subject"
            placeholder="Enter your email subject"
            defaultValue={this.state.subject}
            isRequired={true}
          />
        </div>

        <div className="form-group row">
          <TextBox
            rows={8}
            label="Message"
            onChange={this.onChangeHandler}
            field="message_body"
            className="col-sm-10"
            placeholder="Enter your email message"
            defaultValue={this.state.message_body}
            isRequired={true}
          />
        </div>
      </form>
    )
  }

  render() {

    let { showCustomEmailForm, } = this.state;

    let res;
    let { group, pendingOadealRequestCount, kind } = this.props;

/*
    let userKey = {
      manage_email_inbox: { key: "manage_email_inbox" },
      manage_email_outbox: { key: "manage_email_outbox" },
    }

     
    const allowed = Array.isArray(this.props.auth && this.props.auth.permissions) ? this.props.auth && this.props.auth.permissions : []
    //console.log(allowed)
    const filtered = Object.keys(userKey)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = userKey[key];
        return obj;
      }, {});

   let pubData = PubSidebar.map(item => ({ ...item, value: item.value === "manage_institutions" ? "manage_email_outbox" : item.value }))
   let pubData2 = PubSidebar.map(item => console.log(item && filtered[item && item.value]))


console.log("pubData2",pubData2)
*/
     let sideBar = group === PUBLISHER ? PubSidebar :group === INSTITUTION ? UnivSidebar : group === CONSORTIUM ? ConsortiaSidebar : []
    if (group === PUBLISHER|| group===INSTITUTION ||  group === CONSORTIUM) {
      let TempPubSidebar = sideBar.map((item) => {
        return Object.assign({}, item);
      });

      let permissionObj = this.props.auth && this.props.auth.permissions;
      res = TempPubSidebar.filter((x) => {
        if (
          x.role === "public" ||
          (x.role === "private" && permissionObj && permissionObj.includes(x.value))
        ) {
          if (x.content) {
            let tempX = x.content.filter(
              (y) =>
                y.role === "public" ||
                (y.role === "private" &&
                  permissionObj &&
                  permissionObj.includes(y.value))
              /* || y.value === y.value */
            );

            x.content = tempX;

            return x;
          } else {
            return x;
          }
        }
        return null;
      });
    }
    let title = "",
      navigation = "";

    let extraData = {
      pendingOadealRequestCount,
    };

    if (group === HUB) {
      // Hub
      title = "OaMetrix - Hub";
      navigation = HubSidebar.map((context, i) => {
        return (
          !context.isHide && (
            <MenuItem context={context} key={i} baseUrl={context.to || ""} />
          )
        );
      });
    } else if (group === PUBLISHER) {
      // Publisher
      title = "OaMetrix - Publisher";
      navigation =
        res &&
        res.map((context, i) => {
          return (
            !context.isHide &&
            (!context.kind || context.kind === kind) && (
              <MenuItem
                data={extraData}
                context={context}
                key={i}
                kind={kind}
                baseUrl={context.to || ""}
              />
            )
          );
        });
    } else if (group === INSTITUTION) {
      // University
      title = "OaMetrix - University";
      navigation =res && res.map((context, i) => {
        return (
          !context.isHide && (
            <MenuItem
              data={extraData}
              context={context}
              key={i}
              baseUrl={context.to || ""}
              dataCount={this.state.apc_requests_count}
            />
          )
        );
      });
    } else if (group === FUNDER) {
      // Funder
      navigation = FunderSidebar.map((context, i) => {
        return (
          !context.isHide && (
            <MenuItem
              data={extraData}
              context={context}
              key={i}
              baseUrl={context.to || ""}
            />
          )
        );
      });
    } else if (group === CONSORTIUM) {
      // University
      title = "OaMetrix - Consortia";
      navigation =res&& res.map((context, i) => {
        return (
          !context.isHide && (
            <MenuItem context={context} key={i} baseUrl={context.to || ""} />
          )
        );
      });
    } else {
      // Users
      title = "OaMetrix - User";
      navigation = UserSidebar.map((context, i) => {
        return (
          !context.isHide && (
            <MenuItem context={context} key={i} baseUrl={context.to || ""} />
          )
        );
      });
    }

    let errorHander = <>
      {this.state.isEmpty && <PopupBox
        Title="Following field cannot be empty"
        msg={this.state.isEmpty}
        onCancel={this.cancelLoader}
      />}
      {this.state.successMsg && <PopupBox
        Title="Success!"
        msg={this.state.successMsg}
        onCancel={this.cancelLoader}
      />}
    </>

    return (
      <div>
        { errorHander}

        {showCustomEmailForm && <ModalCustom
          title="Send Ad Hoc Email"
          onClose={this.onCloseHandler}
          modalClass="col-sm-10 col-md-6 col-lg-6"
          isShow={showCustomEmailForm}
          submitBtnTxt="Send"
          onSubmit={this.onSubmitHandler}
        >

          {this.renderCustomEmailForm()}

        </ModalCustom>}

        {group === PUBLISHER ? (
          <PubHeader
            logoutUser={this.logoutUser}
            showAuthUserInfo={this.showAuthUserInfo}
            title={title} 
            group={this.props.group}
          />
        ) : group === INSTITUTION || group === FUNDER ? (
          <UnivHeader
            logoutUser={this.logoutUser}
            showAuthUserInfo={this.showAuthUserInfo}
            title={title}
            group={this.props.group}
            renderCustomEmailButton={this.renderCustomEmailButton}
          />
        ) : group === AUTHOR ? <UserHeader
          logoutUser={this.logoutUser}
          showAuthUserInfo={this.showAuthUserInfo}
          title={title}
          group={this.props.group}
        />
          : (
            <CHeader
              logoutUser={this.logoutUser}
              showAuthUserInfo={this.showAuthUserInfo}
              title={title}
              group={this.props.group}
            />
          )}
        <LeftSidebar navigation={navigation} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  group: state.auth.user && state.auth.group,
  kind: state.auth.user && state.auth.user.kind,
  token: state.auth.token,
  is_proxy_login: state.auth && state.auth.proxy_login,
  pendingOadealRequestCount:
    state.OaDeals.pending_oadeal_request_count || false,
  articles: state.articles.article_full,
  apc_requests_count: state.authorRequests.apc_requests,
});

const mapDispatchToProps = (dispatch) => ({
  logoutProxyUser: () => dispatch(logoutProxyUserData()),
  logoutUser: () => dispatch(logoutUserData()),
  getPendingOaDealRequestCount: (payload) =>
    dispatch(getPendingOaDealRequestCount(payload)),
  sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
  searchArticleFull: (payload = {}) => dispatch(searchArticleFull(payload)),
  getAllApcRequests: (payload) => dispatch(getAllApcRequests(payload)),
});

export const AuthHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
