import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import {
  Button, Loading,PopupBox
} from "../../../../../core/components/common";
import {
  getPublicationApcWaiverConfigList,
  deletePublicationApcWaiverConfig,
} from "../../../../actions";
import { Popover } from "antd"
import moment from "moment";

const format = "Do MMM YYYY";
const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Zero Apc</a>
    <br />

  </div>
);
class PublicationApcWaiverConfig extends BaseComponent {
  state = {
    data: [],
  };

  getPublicationApcWaiverConfigListData = async (pageNum = 1, page = 10) => {
    await this.props.getPublicationApcWaiverConfigList({
      pageNum: pageNum,
      pageSize: page,
    });

    let data = [];
    data =
      this.props.publication_apc_waiver_configs.results &&
      this.props.publication_apc_waiver_configs.results.map((item) => {
        return {
          id: item.id,
          publisher_name: item.publisher_name,
          publication_name: item.publication_name,
          expiry_date: item.expiry_date
            ? moment(item.expiry_date).format(format)
            : "-",
          content_type:
            (item.content_type_details &&
              item.content_type_details
                .map((contentType) => {
                  return contentType.name;
                })
                .join(", ")) ||
            "All content type",
        };
      });
    if (this._isMounted) {
      this.setState({
        data: data,
        count: this.props.publication_apc_waiver_configs.count,
      });
    }
  }; 

  _isMounted = false

  componentDidMount() {
   this._isMounted = true
    this.getPublicationApcWaiverConfigListData();
  }
  componentWillUnmount() {
  this._isMounted = false
}
  pageChange = (pageNumber) => {
    this.getPublicationApcWaiverConfigListData(pageNumber);
  };

  onEditPubApcWaiverConfig = (id, data) => {
    this.props.history.push("/settings/update-apc-waiver/" + id);
  };


  onDeletePubApcWaiverConfig = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        id,
      });
    }
  };

  ondeletePublicationApcWaiverConfig = async () => {
    const id = this.state.id;
    this.setState({
      waitingMsg: "Deleting...",
    });
    const deleteArticle = await this.props.deletePublicationApcWaiverConfig(id);
    this.setState({
      waitingMsg: null,
      id: null,
      deleteConfirm: false,
      deleteStatus: deleteArticle.status,
    });
    this.getPublicationApcWaiverConfigListData();
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      id: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

  render() {
    let heading = ["publication_name", "content_type", "expiry_date"];

    if (this.props.group === "HUB") {
      heading = ["publisher_name", "publication_name"];
    }

    let { data, count } = this.state;

    return (
      <HubContent
        title="Zero APC Journal Configuration"
      >
              {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            btnSize="200px"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.ondeletePublicationApcWaiverConfig}
            waitingMsg={this.state.waitingMsg}
          />
        )}

        <div className="vat-tax-button email d-flex justify-content-end">
          <Link
            style={{ marginRight: 10 }}
            className="btn btn-primary float-right mb-2"
            to="/settings/create-apc-waiver-config"
          >
            Add APC Waiver
            </Link>

          <Popover placement="leftTop" content={content} trigger="click">
            <Button
              className="btn btn-secondary float-right mb-2"
              type=""
              name=""
              text="Help"
            >Help</Button>
          </Popover>
          <div className="danger float-right"
          >
            <button
              type="danger"
              className={`btn btn-danger btn- sm" waves-effect`}
              onClick={() => this.props.history.push("/settings")}
              style={{ marginLeft: 10 }}
            >
              X

            </button>

          </div>

        </div>

        {
          this.props.isLoading !== true ?

            <div className="row filter-md-4">
              <div className="col-12">
                <div className="card m-b-20 pub-apc-waiver-config">
                  {
                    <DataTable
                      group={this.props.auth && this.props.auth.group}
                      heading={heading}
                      data={data}
                      onEdit={this.onEditPubApcWaiverConfig}
                      onDelete={this.onDeletePubApcWaiverConfig}
                      count={count}
                      pageChange={this.pageChange}
                      pageSize={10}
                    />
                  }


                </div>
              </div>
            </div> : <Loading type="flat" />}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: (state.auth && state.auth.group) ,
  organisation_id: state.auth.user.organisation_id ,
  publication_apc_waiver_configs:
    state.PublicationApcWaiverConfig.publication_apc_waiver_configs || [],
  isLoading: state.PublicationApcWaiverConfig.isLoading,


});

const mapDispatchToProps = (dispatch) => ({
  getPublicationApcWaiverConfigList: (payload) =>
    dispatch(getPublicationApcWaiverConfigList(payload)),
  deletePublicationApcWaiverConfig: (id) =>
    dispatch(deletePublicationApcWaiverConfig(id)),

});

export const PublicationApcWaiverConfigList = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationApcWaiverConfig);