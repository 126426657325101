import React from "react";
import BaseComponent from "..";
import { InputNewBox } from "./InputNewBox";
import { TextBox } from "./TextBox";
import { SelectBox } from "./SelectBox";
import { Button } from "./Button";
import Cropper from "react-cropper";
import { connect } from "react-redux";
import {
  getAllCountry,
  getAllInvoiceCity,
  getAllState,
  getPublisherConfigSingle,
  updatePublisherConfigSingle,
  getPublisherInvoicePreview,
  getCurrency, 
} from "../../../openaccess/actions";

class Item extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      bank_details: { address_details: {} },
      address_details: {},
      country_states: {},
    };

    this.cropImage = this.cropImage.bind(this);
  }

  onChangeCard =  (value) => {
    this.setState((prevState) => {
      if (value.hasOwnProperty('note_1') || value.hasOwnProperty('note_2')) {
        Object.assign(prevState, value);
      } else {
        Object.assign(prevState.bank_details, value);
      }

      return prevState;
    });
  };
  apiDataList = async () => {
    this.props.getCurrency();
    await this.props.getAllCountry();
    await this.props.getPublisherConfigSingle();

    let countryStates = {}

    if (this.props.invoice_config && this.props.invoice_config.all_bank_accounts) {
      
      let bankAccounts = this.props.invoice_config.all_bank_accounts
      
      for (let bankAccount of bankAccounts) {
        let country = bankAccount && bankAccount.address_details && bankAccount.address_details.country
        if (country) {
          let states = await this.props.getAllState({
            country: country,
            page_size: 999999999,
          });

          countryStates[country] = (states && states.results) || []
        }
      }
    }

    this.setState((prevState) => {
      Object.assign(prevState, {
        note_1: this.props.invoice_config && this.props.invoice_config.note_1,
        note_2: this.props.invoice_config && this.props.invoice_config.note_2,
        bank_account_list: (this.props.invoice_config && this.props.invoice_config.all_bank_accounts) || [{'address_details': {}}],
        country_states: countryStates,
      });

      return prevState;
    });
  }
  
   componentDidMount() { 
    this.apiDataList()
  }

  onChangeHanlder = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);

    this.setState({
      filename: files[0].name,
      isOpenImageModal: !this.state.isOpenImageModal,
    });
  };

  closeImageModal = () => {
    this.setState({
      isOpenImageModal: false,
    });
  }

  cropImage = async (filename) => {

    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    let data = this.cropper
      .getCroppedCanvas()
      .toDataURL()
      .replace(/^data:.+;base64,/, "");

    this.setState({
      company_logo: data,
      logo_name: String(this.state.filename),
      isOpenImageModal: !this.state.isOpenImageModal,
    });
  };

  formatCurrency = () => {
    let currencyData = [{id:"", name:"Select Currency"}];
    this.props.currencies &&
      this.props.currencies.map((value, index) => {
        return currencyData.push({
          id: value.id,
          name: value.short_name,
        });
      });
    return currencyData;
  };

  addNewBankAccount = () => {
    let bankAccounts = this.state.bank_account_list

    bankAccounts.push({})

    this.setState({
      bank_account_list: bankAccounts,
    })
  }

  removeBankAccount = (index) => { 
    let bankAccounts = this.state.bank_account_list

    bankAccounts.splice(index, 1)

    this.setState({
      bank_account_list: bankAccounts,
    })
  }

  renderBankAccounts = (bankAccounts) => { 
    let initialObj = {}

    if (!bankAccounts || !Array.isArray(bankAccounts)) {
      bankAccounts = [
        initialObj
      ]
    } else if (bankAccounts.length === 0) {
      bankAccounts.push(
        initialObj
      )
    }

    let countryStates = this.state.country_states || {}

    return bankAccounts.map((bankAccount, index) => { 
      let country = bankAccount && bankAccount.address_details && bankAccount.address_details.country

      let states = countryStates[country] || []

      return (
        <div key={`${index}-${bankAccount.currency || ''}`}>

          <div className="row">
            <label className="col-sm-12">
              <strong>Bank account {bankAccounts.length > 1 && `${index + 1}`}</strong>
            </label>
          </div>
          <div className="row">
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <SelectBox
                labelFormCls={false}
                ml15={true}
                labelClass="col-sm-12"
                className="col-md-12"
                onChange={(value) => this.onBankAccountChangeHandler(value, index)}
                field="currency"
                id={`currency-${index}`}
                placeholder="Select currency"
                data={this.formatCurrency()}
                defaultValue={
                  bankAccount &&
                  bankAccount.currency
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onBankAccountChangeHandler(value, index)}
                field="bank_name"
                id={`bank_name-${index}`}
                placeholder="Enter Bank name [required]"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.bank_name
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onBankAccountChangeHandler(value, index)}
                field="account_number"
                id={`account_number-${index}`}
                placeholder="Enter Account number [required]"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.account_number
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onBankAccountChangeHandler(value, index)}
                field="sort_code"
                id={`sort_code-${index}`}
                placeholder="Enter your Sort code"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.sort_code
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onBankAccountChangeHandler(value, index)}
                field="iban"
                id={`iban-${index}`}
                placeholder="Enter your IBAN"
                className="pay-right-ii"
                defaultValue={
                  bankAccount && bankAccount.iban
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onBankAccountChangeHandler(value, index)}
                field="swift_code"
                id={`swift_code-${index}`}
                placeholder="Enter your BIC/Swift code"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.swift_code
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onBankAccountChangeHandler(value, index)}
                field="rounting_number"
                id={`rounting_number-${index}`}
                placeholder="Enter your Routing number"
                className="pay-right-ii"
                defaultValue={
                  (bankAccount &&
                    bankAccount.rounting_number) ||
                  (bankAccount &&
                    bankAccount.routing_number)
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onChangeAddressHandler(value, index)}
                field="address1"
                id={`address1-${index}`}
                placeholder="Enter your Address 1"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.address_details &&
                  bankAccount.address_details.address1
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onChangeAddressHandler(value, index)}
                field="address2"
                id={`address2-${index}`}
                placeholder="Enter your Address 2"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.address_details &&
                  bankAccount.address_details.address2
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <SelectBox
                labelFormCls={false}
                ml15={true}
                labelClass="col-sm-12"
                className="col-md-12"
                onChange={(value) => this.onChangeAddressHandler(value, index)}
                field="country"
                id={`country-${index}`}
                placeholder="Select your country"
                data={this.props.countries}
                defaultValue={
                  bankAccount &&
                  bankAccount.address_details &&
                  bankAccount.address_details.country
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <SelectBox
                labelFormCls={false}
                labelClass="col-sm-12"
                ml15={true}
                className="col-md-12"
                onChange={(value) => this.onChangeAddressHandler(value, index)}
                field="state"
                id={`state-${index}`}
                placeholder="Select your state"
                // disabled={this.props.states ? false : true}
                data={states || []}
                defaultValue={
                  bankAccount &&
                  bankAccount.address_details &&
                  bankAccount.address_details.state
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>

              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onChangeAddressHandler(value, index)}
                field="city_name_text"
                id={`city_name_text-${index}`}
                placeholder="Enter your City"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.address_details &&
                  bankAccount.address_details.city_name_text
                }
              />
            </div>
            <div className="col-md-4" style={{ marginBottom: 8 }}>
              <InputNewBox
                style={{ minHeight: 36 }}
                onChange={(value) => this.onChangeAddressHandler(value, index)}
                field="postal_code"
                id={`postal_code-${index}`}
                placeholder="Enter your Postal code"
                className="pay-right-ii"
                defaultValue={
                  bankAccount &&
                  bankAccount.address_details &&
                  bankAccount.address_details.postal_code
                }
              />
            </div>
            <div className="col-md-8" style={{ marginBottom: 8 }}>
              { bankAccounts && bankAccounts.length > 1 && <button 
                      type="button"
                      className="btn btn-danger mb-2 float-right"
                      onClick={() => this.removeBankAccount(index)}
                    >
                      <i className="mdi mdi-close"></i>
                    </button> }
            </div>

          </div>
        </div>
      )
    })
  }

  onBankAccountChangeHandler = (value, index) => { 
    let bankAccounts = this.state.bank_account_list || []

    if (!bankAccounts[index]) {
      bankAccounts[index] = {}
    }

    Object.assign(bankAccounts[index], value)

    this.setState({
      bank_account_list: bankAccounts,
    })
  }

  onChangeAddressHandler = async (value, index) => { 
    let bankAccounts = this.state.bank_account_list || []
    let countryStates = this.state.country_states || {}
    
    if (value.hasOwnProperty('country') && value.country) {
      let states = await this.props.getAllState({
        country: value.country,
        page_size: 999999999,
      });
      countryStates[value.country] = (states && states.results) || []

      value.state = ''
    }
    
    if (!bankAccounts[index]['address_details']) {
      bankAccounts[index]['address_details'] = {}
    } 
    
    Object.assign(bankAccounts[index]['address_details'], value)

    this.setState({
      bank_account_list: bankAccounts,
      country_states: countryStates,
    })
  } 

  onSubmitBankAddressHandler = async () => {

      let invoiceData = {
        bank_details: this.state.bank_account_list,
        note_1: this.state.note_1,
        note_2: this.state.note_2
      };
  
      if (this.state.company_logo && this.state.filename) {
        Object.assign(invoiceData, {
          company_logo: this.state.company_logo,
          logo_name: this.state.logo_name,
        });
      }
  
      let id = this.props.invoice_id;
      await this.props.updatePublisherConfigSingle(id, invoiceData);
  
      await this.props.getPublisherInvoicePreview();
      this.props.handleOpenAddress();
  
  };

  render() { 

    return (
      <>
        {this.props.isOpenAddress ? (
          <div className="addmultfield" style={modal.overlay}>
            <div className={`col-sm-8 bg-white alert`} style={modal.boxStyle}>

              {
                (this.props.currencies 
                  && this.state.bank_account_list 
                  && this.state.bank_account_list.length < this.props.currencies.length)
                  && (<div className="row">
                      <div className="col-md-12">
                        <div className="float-right">
                          <button 
                            type="button"
                            className="btn btn-primary mb-2 mr-4"
                            onClick={this.addNewBankAccount}
                          >
                            <i className="mdi mdi-plus"></i> Add more
                          </button>
                        </div>
                      </div>
                    </div>)
              }

              {
                this.renderBankAccounts(this.state.bank_account_list)
              }

              <div className="row">
                <div className="col-md-12">
                  <h3 className="profile-heading">Upload your logo</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="logo">
                    <input
                      className="upload-image"
                      type="file"
                      onChange={this.onChangeHanlder}
                    />

                  </div>
                </div>
              </div>

              {this.state.isOpenImageModal && (
                      <div
                        className="addmultfield"
                        style={modal.overlay}
                      >
                        <div
                          className={`col-md-6 bg-white `}
                            style={{
                              padding: 20,
                              height:380
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <Cropper
                              style={{
                                height: 300,
                                width: "100%",
                                float: "left",
                              }}
                              aspectRatio={16 / 9}
                              preview=".img-preview"
                              guides={false}
                              src={this.state.src}
                              ref={(cropper) => {
                                this.cropper = cropper;
                              }}
                            />

                          </div>
                          <div style={{ marginTop: 15 }}>
                            <Button
                              type="button"
                              name="primary"
                              text="Crop Image"
                              onClick={this.cropImage}
                            />
                          </div>
                        </div>
                      </div>
                    )}

              <div className="row">
                <div className="col-md-6">
                  <TextBox
                    style={{ minHeight: 36 }}
                    onChange={(value) => this.onChangeCard(value)}
                    field="note_1"
                    placeholder="Enter your Note one"
                    className="pay-right-ii"
                    defaultValue={
                      this.state.note_1
                    }
                  />
                </div>
                <div className="col-md-6">
                  <TextBox
                    style={{ minHeight: 36 }}
                    onChange={(value) => this.onChangeCard(value)}
                    field="note_2"
                    placeholder="Enter your Note two"
                    className="pay-right-ii"
                    defaultValue={
                      this.state.note_2
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center" style={{ marginTop: 20, fontSize: 14 }}>
                    <Button
                      type="primary"
                      name="primary"
                      text="Update"
                      onClick={this.onSubmitBankAddressHandler}
                    />
                    <Button
                      type="secondary"
                      name="secondary"
                      text="Close"

                      onClick={this.props.handleOpenAddress}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
            <div className="row">
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeCard(value)}
                  field="bank_name"
                  placeholder="Enter Bank name [required]"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details && this.state.bank_details.bank_name
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeCard(value)}
                  field="account_number"
                  placeholder="Enter  Account number [required]"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details &&
                    this.state.bank_details.account_number
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeCard(value)}
                  field="sort_code"
                  placeholder="Enter your Sort code"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details && this.state.bank_details.sort_code
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeCard(value)}
                  field="iban"
                  placeholder="Enter your IBAN"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details && this.state.bank_details.iban
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeCard(value)}
                  field="swift_code"
                  placeholder="Enter your BIC/Swift code"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details && this.state.bank_details.swift_code
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeCard(value)}
                  field="rounting_number"
                  placeholder="Enter your Routing number"
                  className="pay-right-ii"
                  defaultValue={
                    (this.state.bank_details &&
                      this.state.bank_details.rounting_number) ||
                    (this.state.bank_details &&
                      this.state.bank_details.routing_number)
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeAddressHandler(value)}
                  field="address1"
                  placeholder="Enter your Address 1"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details &&
                    this.state.bank_details.address_details &&
                    this.state.bank_details.address_details.address1
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeAddressHandler(value)}
                  field="address2"
                  placeholder="Enter your Adress2"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details &&
                    this.state.bank_details.address_details &&
                    this.state.bank_details.address_details.address2
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <SelectBox
                  labelFormCls={false}
                  ml15={true}
                  labelClass="col-sm-12"
                  className="col-md-12"
                  onChange={(value) => this.onChangeAddressHandler(value)}
                  field="country"
                  placeholder="Select your Country"
                  data={this.props.countries}
                  defaultValue={
                    this.state.bank_details &&
                    this.state.bank_details.address_details &&
                    this.state.bank_details.address_details.country
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <SelectBox
                 labelFormCls={false}

                  labelClass="col-sm-12"
                  ml15={true}
                  className="col-md-12"
                  onChange={(value) => this.onChangeAddressHandler(value)}
                  field="state"
                  placeholder="Select your state"
                  data={this.props.states}
                  defaultValue={
                    this.state.bank_details &&
                    this.state.bank_details.address_details &&
                    this.state.bank_details.address_details.state
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <SelectBox
                labelFormCls={false}

                  ml15={true}
                  labelClass="col-sm-12"
                  className="col-md-12"
                  onChange={(value) => this.onChangeAddressHandler(value)}
                  field="city"
                  disabled={this.props.states ? false : true}
                  data={this.props.city}
                  placeholder="Select your city"
                  defaultValue={
                    this.state.bank_details &&
                    this.state.bank_details.address_details &&
                    this.state.bank_details.address_details.city
                  }
                />
              </div>
              <div className="col-md-4" style={{ marginBottom: 8 }}>
                <InputNewBox
                  style={{ minHeight: 36 }}
                  onChange={(value) => this.onChangeAddressHandler(value)}
                  field="postal_code"
                  placeholder="Enter your Postal code"
                  className="pay-right-ii"
                  defaultValue={
                    this.state.bank_details &&
                    this.state.bank_details.address_details &&
                    this.state.bank_details.address_details.postal_code
                  }
                />
              </div>
              <div className="col-md-12">
                <div className="text-center">
                  <button
                    className="btn btn-primary btn-md waves-effect waves-light"
                    onClick={this.onSubmitBankAddressHandler}
                  >
                    Edit Notes
                </button>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bank_details:
    (state.payment &&
      state.payment.payment_transaction &&
      state.payment.payment_transaction.bank_details) ,
  invoice_config: (state.payment &&
    state.payment.payment_transaction) ,
  address_details:
    (state.payment &&
      state.payment.payment_transaction &&
      state.payment.payment_transaction.bank_details &&
      state.payment.payment_transaction.bank_details.address_data),
  countries: (state.app && state.app.countries) ,
  city: (state.app && state.app.city && state.app.city.results) || [],
  states: (state.orgs && state.orgs.state_list),
  currencies:
    (state.OaDeals &&
      state.OaDeals.currency_list &&
      state.OaDeals.currency_list.results)
  ,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrency: () => dispatch(getCurrency()),
  getAllCountry: () => dispatch(getAllCountry()),
  getAllState: (payload) => dispatch(getAllState(payload)),
  getAllInvoiceCity: (payload) => dispatch(getAllInvoiceCity(payload)),
  getPublisherConfigSingle: (payload) =>
    dispatch(getPublisherConfigSingle(payload)),
  updatePublisherConfigSingle: (id, payload) =>
    dispatch(updatePublisherConfigSingle(id, payload)),
  getPublisherInvoicePreview: (payload) =>
    dispatch(getPublisherInvoicePreview(payload)),
});

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    overflowY: 'auto',
  },
  boxStyle: {
    transform: "translate(90px, 90px)",
    height: boxHeight,
  },
};
export const BankDetailsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Item);
