import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import {
  getOaPolicyList,
  getOrganisationRequirementList,
  deleteOaPolicy,
  deleteOaRequirement,
} from "../../../../actions";
import { PopupBox, Loading } from "../../../../../core/components/common";

class OaPolicies extends BaseComponent {
  state = {
    deleteConfirm: false,
    orgId: null,
    waitingMsg: null,
    deleteStatus: null,
    deleteing: false,
  };
  async componentDidMount() {
    this.getPolicyData();
  }

  getPolicyData = async (pageNum = 1, pageSize = 10) => {
    this.props.group === "INSTITUTION"
      ? await this.props.getOrganisationRequirementList({
          pageNum: pageNum,
          pageSize: pageSize,
        })
      : await this.props.getOaPolicyList({
          pageNum: pageNum,
          pageSize: pageSize,
        });
    let policyObj = this.props.oa_policy_list;
    let dataPolicy = [];
    policyObj.map((item) => {
      return this.props.group === "INSTITUTION"
        ? dataPolicy.push({
            policy: item.date_created,
            organisation: item.organisation_name,
            requirement:
              item.requirement_details && item.requirement_details.title,
            response: item.response,
            id: item.id,
          })
        : dataPolicy.push({
            date: item.date_created,
            publisher: item.publisher_name,
            response: item.response,
            policy: item.policy_details && item.policy_details.title,
            publication: item.publication_name,
            id: item.id,
          });
    });
    this.setState({
      data: dataPolicy,
    });
  };

  onEditPolicy = (id) => {
    this.props.history.push(`/oa-policy/edit-oa-policy/${id}`);
  };

  onView = (id) => {
    this.props.history.push("/organizations/single-org/" + id);
  };

  onDeleteOrg = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        orgId: id,
      });
    }
  };

  onDeleteOrgHander = async () => {
    const orgId = this.state.orgId;
    this.setState({
      deleteing: true,
      waitingMsg: "Deleting...",
    });
    const org = await this.props.deleteOaPolicy(orgId);
    this.setState({
      waitingMsg: null,
      orgId: null,
      deleteConfirm: false,
      deleteStatus: org.status,
      deleteing: false,
    });
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      orgId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };
  pageChange = (pageNumber) => {
    this.getPolicyData(pageNumber);
  };

  render() {
    const heading =
      this.props.group === "INSTITUTION"
        ? ["organisation", "requirement", "response"]
        : ["publisher", "publication", "policy", "response"];
    const title = `Oa policies`;
    return (
      <HubContent title={title}>
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteOrgHander}
            waitingMsg={this.state.waitingMsg}
            deleting={this.state.deleteing}
          />
        )}

        {this.state.deleteStatus && (
          <PopupBox title="Successfully deleted" onCancel={this.onCancel} />
        )}

        {this.state.deleteStatus === false && (
          <PopupBox
            title="Failed... Try Again"
            onCancel={this.onCancel}
            NoBtnType="danger"
          />
        )}
        <div className="row">
          <div className="col-12">
            <Link
              to="/oa-policy/oa-policy-list"
              className="btn btn-primary float-right mb-2"
            >
              Add OA Policy
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.props.count ? (
                <DataTable
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditPolicy}
                  onDelete={this.onDeleteOrg}
                  onView={this.onView}
                  count={this.props.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                  noAction={true}
                />
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  oa_policy_list:
    (state.oa_policy &&
      state.oa_policy.oa_policy_list &&
      state.oa_policy.oa_policy_list.results) ||
    false,
  count:
    (state.oa_policy &&
      state.oa_policy.oa_policy_list &&
      state.oa_policy.oa_policy_list.count) ||
    false,
  group: (state.auth && state.auth.group) || false,
});

const mapDispatchToProps = (dispatch) => ({
  getOaPolicyList: (payload) => dispatch(getOaPolicyList(payload)),
  getOrganisationRequirementList: (payload) =>
    dispatch(getOrganisationRequirementList(payload)),
  deleteOaPolicy: (id) => dispatch(deleteOaPolicy(id)),
  deleteOaRequirement: (id) => dispatch(deleteOaRequirement(id)),
});

export const OaPolicyList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OaPolicies);
