
import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { HubContent } from "../../../../core/components";


class Invoiceinfo extends React.Component {
  render() {
    return (
      <HubContent>
        <div id="CoAuthorDialog" className="pay" style={modal.overlay}>
          <div className={`col-10 col-lg-8 bg-white alert pay-border-radius`} style={modal.boxStyle}>

            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="card-body pay-body">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card-box pay-invoices">
                            <div class="row">
                              <div class="col-8">
                                <div class="pull-left mt-3">
                                  <h4>eScienta Ltd</h4>
                                  <ul><li>9 Merritt Road</li><li>Didcot</li><li>OX</li><li>OX11 7DF</li><li>VAT Registration No.: 293527673</li></ul>
                                </div>

                              </div>
                              <div class="col-2 offset-2">
                                <div class="mt-3 pull-right">
                                  <img src="/assets/images/logo-sm.png" alt="" width="100" />
                                </div>
                              </div>
                            </div>

                            <div class="row mt-3">
                              <div class="col-9">
                                <div class="pull-left-invoice  left-none">
                                  <h3>Billing Address</h3>

                                  <ul><li>Dr. Nourah Algdeb</li><li>Princes Nourah University</li><li>Riyadh Saudi Arabia</li></ul>

                                </div>
                              </div>

                              <div class="col-3">
                                <div class="pull-right-invoice">

                                  <ul><li><span>INVOICE NO.</span> ESI-48202</li><li><span>DATE</span>18/07/2019</li><li><span>DUE DATE</span>25/07/2019</li><li><span>TERMS</span>Net 7</li></ul>
                                </div>
                              </div>
                            </div>

                            <div class="row pay-m-10">
                              <div className="col-md-12">
                                <div className="row pay-row-bac">
                                  <div className="col-md-10"><p>Description</p></div>
                                  <div className="col-md-2"><p className="amount-right">Amount</p> </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12 m-b-10"> <p className="pay-para">Impact of climate change on dengue transmission in Dhaka, Bangladesh</p>
                                    <p> Author: Rob Mason, Affiliation: Oametrix University, Funder name: FAO ,Grant number: IJNE-4747-71</p>
                                    <p>Publisher article number: IJAR-37276-01031</p>
                                  </div>

                                </div>
                                <div className="row">
                                  <div className="col-md-6">


                                  </div>
                                  <div className="col-md-6">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="apc-charge">
                                          <p>APC Charge(GBP)</p>
                                          <p>Submission Charge(GBP)</p>
                                          <p>Pages & Other Charge(GBP)</p>
                                          <p>Sub Total</p>
                                          <p>Token</p>

                                          <p className="pay-net">Net Payable(GBP)</p>

                                        </div>

                                      </div>
                                      <div className="col-md-6">
                                        <div className="amount">
                                          <p>$44.00</p>
                                          <p>$44.00</p>
                                          <p>$44.00</p>
                                          <p>$136.00</p>
                                          <p>$50.00</p>
                                          <p className="pay-net">$186.00</p>

                                        </div></div></div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            {/* <div class="row">
                          <div class="col-md-12  text-right">
                             
                           
                  <button style={{height: 30}} onClick={()=>this.props.history.push("/payment/addpayment",{id:this.props.articleId})}  className="btn btn-secondary btn-md waves-effect waves-light">
                    Download
                  </button>
                               
                          </div>
                        </div>
         */}
                            <div class="row">
                              <div class="col-md-12">
                                <div class="bank-info bank-invoice">
                                  <p>Bank transfer: HSBC Bank |Bank a/c: 345342 |Sort code: 410-12-314 |IBAN: GB80HBUK40353411241449210 |BIC: HBWERUKGB4B |Bank address: 65 Cornmarket St., Oxford, OX1 3HY, UK |eScienta Ltd. registered in England & Wales with number 1126233048</p>
                                  {/* <ul><li>Bank transfer: HSBC Bank |</li><li>Bank a/c: 345342 |</li><li>Sort code: 410-12-314 |</li>
                              <li>IBAN: GB80HBUK40353411241449210 |</li><li>BIC: HBWERUKGB4B |
                                </li><li>Bank address: 65 Cornmarket St., Oxford, OX1 3HY, UK |</li>
                                <li> eScienta Ltd. registered in England & Wales with number 1126233048</li></ul> */}
                                </div>
                              </div>
                              {/* <div class="col-md-3 text-right">
                             
                             <button style={{height: 30}}  className="btn btn-primary btn-md waves-effect waves-light">
                     Print
                   </button>
                   <button style={{height: 30}} onClick={()=>this.props.history.push("/payment/addpayment")}  className="btn btn-primary btn-md waves-effect waves-light">
                     Close
                   </button>
                                
                           </div> */}
                            </div>

                            <div class="row">
                              <div class="col-md-12  text-right">


                                <button style={{ height: 30 }} onClick={() => this.props.history.push("/payment/addpayment", { id: this.props.articleId })} className="btn btn-secondary btn-md waves-effect waves-light">
                                  Download
                  </button>
                                <button style={{ height: 30 }} onClick={() => this.props.history.push("/articles")} className="btn btn-secondary btn-md waves-effect waves-light">
                                  Close
                  </button>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>         </HubContent>

    )
  }
}

let boxHeight = ''
if (navigator.userAgent.toLowerCase().includes('firefox')) {
  boxHeight = '-moz-fit-content'
} else if (navigator.userAgent.toLowerCase().includes('chrome')) {
  boxHeight = '-webkit-fit-content'
}

const modal = {
  overlay: {
    position: 'fixed',
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center'
  },
  boxStyle: {
    transform: 'translate(125px, 125px)',
    height: boxHeight
  }
}



const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Invoiceinfo));


