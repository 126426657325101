
import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
    emailLogHistory
} from "../../../../actions";
import {
    ExpandHeadingTable,
    PaginationForm,
    Loading
} from "../../../../../core/components/common";
import {
    authorEmail,
    column,

} from "../../../../../core/lib";
import moment from "moment"
class EmailLog extends BaseComponent {
    state = {
        current: 1
    }
    getEmaiLogData = async (pageNum = 1, page = 10) => {
        await this.props.emailLogHistory({ pageNum: pageNum, pageSize: page }, "outbox");
        let dataOrg = [];
        this.props.email_history_log && this.props.email_history_log.results.map((item) => {
            return dataOrg.push({
                "recipients": item.recipients,
                "subject": item.subject,
                "date_created": moment(item.date_created).format("Do MMM YYYY"),

                id: item.id
            });
        })
        if (this._isMounted) {
            this.setState({
                data: dataOrg,
                count: this.props.email_history_log.count
            })
        }

    }
    _isMounted = false
    componentDidMount() {
        this._isMounted = true
        this.getEmaiLogData();
    }
    componentWillUnmount() {
        this._isMounted=false
    }
    pageChange = (pageNumber) => {
        this.setState({
            current:pageNumber
        })
        this.getEmaiLogData(pageNumber);
    }

    onViewCustomEmail = (id) => {
        this.props.history.push('/author-custom-email/author-custom-send-message/' + id)
    }
    render() {



        return (

            <HubContent
                title="Outbox List"
            >

                <div className="col-12">
                    <div className="card m-b-20">
                        <ExpandHeadingTable heading={authorEmail} />

                        {this.state.data ? (
                            <div>
                                {   this.state.data && this.state.data.length > 0 ? (
                                    this.state.data && this.state.data.map((item, index) => (
                                        <div id="accordion" key={item.id}>

                                            <div className="data-table">
                                                <div className="row-table">
                                                    <div className="row table-data">
                                                        <div className={column[2]}>
                                                            <p>{item.recipients}  </p>  </div>



                                                        <div className={column[5]}>


                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={(e) => this.onViewCustomEmail(item.id)}
                                                                title="View"
                                                                style={{ textDecoration: "underline", color: "blue" }}
                                                            >
                                                                {item.subject}

                                                            </span>

                                                        </div>


                                                        <div className={column[1]} style={{ textAlign: "center", marginLeft: 20 }}>

                                                            <p style={{ textAlign: "center" }}>  {item.date_created}</p>
                                                        </div>


                                                    </div>

                                                </div >
                                            </div >


                                        </div>
                                    ))
                                ) : (
                                        <div className="card-body" style={{ textAlign: "center" }}>
                                            No Data Found
                                        </div>
                                    )}
                                {this.state.data && this.state.data.length > 0 ? (
                                    <PaginationForm
                                        pageSize={10}
                                        pageChange={this.pageChange}
                                        count={this.state.count}
                                        current={this.state.current}
                                    />
                                ) : null}
                            </div>
                        ) : (
                                <Loading type="flat" />
                            )}



                    </div>
                </div>
            </HubContent >
        );
    }
}


const mapStateToProps = (state) => ({
    email_history_log: state.articles.email_history_log
})

const mapDispatchToProps = (dispatch) => ({
    emailLogHistory: (payload, outbox) => dispatch(emailLogHistory(payload, outbox)),
})
export const ArticleOutBoxEmail = connect(mapStateToProps, mapDispatchToProps)(EmailLog);
