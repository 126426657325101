import React from "react";
import { connect } from "react-redux";

import {
  SelectBox,
  InputBox,
  Button as Buttons,
  RadioButtonGroup,PopupBox,Loading
} from "../../../../../core/components/common";
import MultiSelectCheckBox from "../../../../../core/components/common/MultiSelectCheckBox";

import { HubContent } from "../../../../../core/components";
import {
  getAllCountry,
  getVatRatesPubVatConfig,
  getStatePubVatConfig,
  getCAStatePubVatConfig,
  publisherVatCreateConfigure,
  publisherGetSingleVatCreateConfigure,
  publisherUpdateVatCreateConfigure,
  publisherDeleteVatCreateConfigure,
  publisherGetAllVatCreateConfigure,
  createVatConfig,
  createUpdateVatConfig,
  getSingleVatConfig,
  getVatConfig,
  DeleteVatConfig,
} from "../../../../actions";
import VatTable from "./vatTable";
import { Steps, Button } from "antd";
import { PoweroffOutlined } from '@ant-design/icons';

const { Step } = Steps;

class VatForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      enable: true,
      data: {},
      isShow: false,
      height: 0,
      offices: [],
      state: null,
      charge_based_on_states: null,
      other_country_chg_policies: [],
      charge_policy: "",
      other_waived_countries: [],
      value: null,
      arrayValue: [],
      countriesList: [],
      countryList: [],
      eudata: [],
      isDisabled: false,
    
      // SELF COUNTRY
      apc_including_vat: null,
      charge_vat: null,
      charge_no_vat: null,
      not_charge_vat_number: null,
      no_charge_vat_us: null,
      billingAdress: [],
      editingKey: "",
    };
  }
  componentDidMount() {
    this.apiDataList()
  }

   apiDataList = async() =>{
    const datavatconfig = await this.props.getVatConfig();
    const vatData = Object.assign({}, datavatconfig.results);

      this.setState({
        vatid: vatData && vatData[0] && vatData[0].id,
        vat_number:
          vatData && vatData[0] && vatData[0].vat_number
            
      });


    const data = await this.props.publisherGetSingleVatCreateConfigure(
      this.props.match.params.id
    );
    //For SELF COUNTRY

    this.props
      .getVatRatesPubVatConfig({
        country: data.offices && data.offices[0].country,
      })
      .then((res) => {
        this.setState({
          selfCountryvatRates_default_1: res.results,
          selfCountryvatRates_default_2: res.results,
          selfCountryRatesDefaultForEU_1: res.results,
          selfCountryRatesDefaultForEU_2: res.results,
        });
      });
    //For CA COUNTRY

    this.props
      .getVatRatesPubVatConfig({
        eucontext: "EU",
        id: data.offices && data.offices[0].country,
      })
      .then((res) => {
        this.setState({
          vatforeu: res.results,
        });
      });

    this.props
      .getVatRatesPubVatConfig({ context: "OTHER_COUNTRY" })
      .then((res) => {
        this.setState({
          other_countries: res.results,
        });
      });
    this.props.getVatRatesPubVatConfig({ context: "US" }).then(async (res) => {
      const data = await this.props.getStatePubVatConfig(res.results[0].id);
      this.setState({
        stateList: data.results,
        vatforus: res.results,
      });
    });
    //For CA COUNTRY
    this.props.getVatRatesPubVatConfig({ context: "CA" }).then(async (res) => {
      let data = await this.props.getCAStatePubVatConfig(res.results[0].id);
      this.setState({
        vatforca: res.results,
        stateListCA: data.results,
      });
    });
    this.props.getAllCountry();

    //LETS SETUP FOR SELF COUNTRY
    let nochargevat;
    let nochargeselfvat;
    let vatrates;
    let vatchargeoffice =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0].rules[0].charge_vat;
    let vatnochargeOffice =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0] &&
      data.offices[0].rules[0].charge_no_vat;
    let rates_vat_self =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0] &&
      data.offices[0].rules[0].rates_vat;

    let rates_no_vat_self =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[0] &&
      data.offices[0].rules[0].rates_no_vat;

    if (vatchargeoffice === true) {
      nochargevat = vatnochargeOffice;
      vatrates = rates_vat_self;
      if (vatnochargeOffice === false) {
        nochargevat = vatnochargeOffice;
      } else {
        nochargeselfvat = rates_no_vat_self;
        nochargevat = vatnochargeOffice;
      }
    } else if (vatchargeoffice === false) {
      nochargevat = vatnochargeOffice;
      if (nochargevat) {
        nochargeselfvat = rates_no_vat_self;
      } else {
        nochargevat = vatnochargeOffice;
      }
    }

    //LET"S SETUP FOR USA
    let statePosition;
    let nochargevatforus;
    let nochargevatforus2;

    let ratesvatforstates;
    let ratesvatforstates2;

    let ratesvatforcountry2;
    let vatnochargeofficeforus;

    let vatchargeofficeforus;
    let vatchargeStatesOfficeforus;
    let rates_vat_forus;
    let rates_no_vat_forus;
    let vatnochargeStatesOfficeforus;
    if (
      data &&
      data.offices[0] &&
      data.offices[0].country_name === "United States"
    ) {
      vatnochargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_no_vat;

      vatchargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_vat;
      vatchargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_vat;
      vatnochargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_no_vat;
      rates_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_vat;
      rates_no_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_no_vat;
    } else {
      vatnochargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_no_vat;

      vatchargeofficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_vat;
      vatchargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_vat;
      vatnochargeStatesOfficeforus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_no_vat;
      rates_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_vat;
      rates_no_vat_forus =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_no_vat;
    }

    let statePositioncharge_false;
    let notchargebasedstates2;
    let noratesvatforcountry;
    let noratesvatforstates;
    let charge_no_vat_usa_2;
    let noratesvatforcountry4;
    let noratesvatforstates4;
    let charge_no_vat_states_usa_3;
    let ratesvatforCountry;
    if (vatchargeofficeforus === true) {
      statePosition = vatchargeStatesOfficeforus;
      if (vatchargeStatesOfficeforus === true) {
        ratesvatforstates = rates_vat_forus;
        statePosition = vatchargeStatesOfficeforus;

        nochargevatforus = vatnochargeofficeforus;
        if (vatnochargeofficeforus === true) {
          //Do charge based on states if don't provide // comes from database
          notchargebasedstates2 = vatnochargeStatesOfficeforus;

          if (vatnochargeStatesOfficeforus === true) {
            //no state
            notchargebasedstates2 = vatnochargeStatesOfficeforus;

            noratesvatforstates = rates_no_vat_forus;
          } else {
            notchargebasedstates2 = vatnochargeStatesOfficeforus;

            //no country
            noratesvatforcountry = rates_no_vat_forus;
          }
        } else {
          nochargevatforus = vatnochargeofficeforus;
        }
      } else {
        ratesvatforCountry = rates_vat_forus;
        statePosition = vatchargeStatesOfficeforus;

        charge_no_vat_usa_2 = vatnochargeofficeforus;
        if (vatnochargeofficeforus === true) {
          //Do charge based on states if don't provide // comes from database
          charge_no_vat_states_usa_3 = vatnochargeStatesOfficeforus;
          if (vatnochargeStatesOfficeforus === true) {
            //no state
            noratesvatforstates4 = rates_no_vat_forus;
          } else {
            charge_no_vat_states_usa_3 = vatnochargeStatesOfficeforus;

            //no country
            noratesvatforcountry4 = rates_no_vat_forus;
          }
        }
      }
    } else if (vatchargeofficeforus === false) {
      nochargevatforus2 = vatnochargeofficeforus;
      if (vatnochargeofficeforus === true) {
        statePositioncharge_false = vatchargeStatesOfficeforus;
        if (vatchargeStatesOfficeforus === true) {
          statePositioncharge_false = vatchargeStatesOfficeforus;
          ratesvatforstates2 = rates_no_vat_forus;
        } else {
          statePositioncharge_false = vatchargeStatesOfficeforus;
          ratesvatforcountry2 = rates_no_vat_forus;
        }
      }
    }

    //LET"S SETUP FOR CA
    let statePositionca;
    let statePositionca2;
    let nochargevatforca;
    let ratesvatforstatesca;
    let ratesvatforstatesca2;
    let ratesvatforcountryca;
    let ratesvatforcountryca2;

    let vatnochargeofficeca;
    let vatchargeofficeforca;
    let vatchargeStatesOfficeforca;
    let rates_vat_forca;
    let rates_no_vat_forca;
    let vatnochargeStatesOfficeforca;
    let canada_no_rates_provience3;
    let canadanoratescountry_vat3;

    // Don't valid boolen Field
    let charge_no_vat_canada_1;
    let provience_no_charge_vat;
    let canadanoratescountry_vat;
    let canada_no_rates_provience;
    let charge_no_vat_canada_2;
    let provience_basedon_vat_no_charge_2;

    if (
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[2] &&
      data.offices[0].rules[2].context === "CA"
    ) {
      vatnochargeofficeca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_no_vat;
      vatchargeofficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_vat;
      vatchargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_vat;
      vatnochargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].charge_based_on_states_no_vat;

      rates_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_vat;
      rates_no_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[2] &&
        data.offices[0].rules[2].rates_no_vat;
    } else if (
      data.offices &&
      data.offices[0] &&
      data.offices[0].country_name === "Canada"
    ) {
      vatnochargeofficeca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_no_vat;
      vatchargeofficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_vat;
      vatchargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_vat;
      vatnochargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].charge_based_on_states_no_vat;

      rates_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_vat;
      rates_no_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[0] &&
        data.offices[0].rules[0].rates_no_vat;
    } else {
      vatnochargeofficeca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_no_vat;
      vatchargeofficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_vat;
      vatchargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_based_on_states_vat;
      vatnochargeStatesOfficeforca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].charge_based_on_states_no_vat;

      rates_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].rates_vat;
      rates_no_vat_forca =
        data.offices &&
        data.offices[0] &&
        data.offices[0].rules[3] &&
        data.offices[0].rules[3].rates_no_vat;
    }

    if (vatchargeofficeforca === true) {
      statePositionca = vatchargeStatesOfficeforca;
      if (vatchargeStatesOfficeforca === true) {
        statePositionca = vatchargeStatesOfficeforca;

        ratesvatforstatesca = rates_vat_forca;
        charge_no_vat_canada_1 = vatnochargeofficeca;

        if (vatnochargeofficeca === true) {
          charge_no_vat_canada_1 = vatnochargeofficeca;

          provience_no_charge_vat = vatnochargeStatesOfficeforca;

          if (vatnochargeStatesOfficeforca === true) {
            provience_no_charge_vat = vatnochargeStatesOfficeforca;

            canada_no_rates_provience = rates_no_vat_forca;
          } else {
            provience_no_charge_vat = vatnochargeStatesOfficeforca;
            canadanoratescountry_vat = rates_no_vat_forca;
          }
        } else {
          charge_no_vat_canada_1 = vatnochargeofficeca;
        }
      } else {
        //NEXT CHARGES
        ratesvatforcountryca = rates_vat_forca;
        statePositionca = vatchargeStatesOfficeforca;
        charge_no_vat_canada_2 = vatnochargeofficeca;

        //don't provide charge
        if (vatnochargeofficeca === true) {
          //DO prvoince
          charge_no_vat_canada_2 = vatnochargeofficeca;
          provience_basedon_vat_no_charge_2 = vatnochargeStatesOfficeforca;
          if (vatnochargeStatesOfficeforca === true) {
            provience_basedon_vat_no_charge_2 = vatnochargeStatesOfficeforca;

            canada_no_rates_provience3 = rates_no_vat_forca;
          } else {
            provience_basedon_vat_no_charge_2 = vatnochargeStatesOfficeforca;
            canadanoratescountry_vat3 = rates_no_vat_forca;
          }
        } else {
          charge_no_vat_canada_2 = vatnochargeofficeca;
        }
      }
    } else if (vatchargeofficeforca === false) {
      nochargevatforca = vatnochargeofficeca;
      if (vatnochargeofficeca === true) {
        statePositionca2 = vatchargeStatesOfficeforca;
        if (vatchargeStatesOfficeforca === true) {
          statePositionca2 = vatchargeStatesOfficeforca;

          ratesvatforstatesca2 = rates_no_vat_forca;
        } else {
          statePositionca2 = vatchargeStatesOfficeforca;
          ratesvatforcountryca2 = rates_no_vat_forca;
        }
      }
    }

    //LET"S SETUP FOR EU

    let blilling_charge_rates_address;
    let blilling_no_charge_rates_address;
    let blilling_no_charge_rates_address2;

    let charge_eu =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].charge_vat;
    let vatnochargeOfficeforeu =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].charge_no_vat;

    let vat_based_billing_address =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].vat_based_billing_address;

    let no_vat_based_billing_address =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].no_vat_based_billing_address;
    let vat_charge_billing_rates =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].rates_vat;
    let vat_charge_no_billing_rates =
      data.offices &&
      data.offices[0] &&
      data.offices[0].rules[1] &&
      data.offices[0].rules[1].rates_no_vat;

    // boolen values for eu :
    let datanotvalidboolean;
    let charge_billing_charge_vat;
    let charge_billing_no_charge_vat;
    let charge_billing_no_charge_vat2;
    let datanotvalidboolean2;
    let charge_country_rates_address_eu;
    let charge_country_rates_address_eu_2;

    if (charge_eu === true) {
      //How Do you charge
      charge_billing_charge_vat = vat_based_billing_address; //;

      if (vat_based_billing_address === true) {
        charge_billing_charge_vat = vat_based_billing_address; //;
        blilling_charge_rates_address = vat_charge_billing_rates; //rates_vat

        // Do you charge VAT to EU customers if they do not provide you a valid VAT number?
        datanotvalidboolean = vatnochargeOfficeforeu;
        if (vatnochargeOfficeforeu === true) {
          datanotvalidboolean = vatnochargeOfficeforeu;

          charge_billing_no_charge_vat = no_vat_based_billing_address;

          if (no_vat_based_billing_address === true) {
            charge_billing_no_charge_vat = no_vat_based_billing_address;
            blilling_no_charge_rates_address = vat_charge_no_billing_rates; //rates_no_vat but billing address
          } else {
            blilling_no_charge_rates_address = vat_charge_no_billing_rates; //rates_no_vat
          }
        } else {
          datanotvalidboolean = vatnochargeOfficeforeu;
        }
      } else {
        charge_country_rates_address_eu = vat_charge_billing_rates; //rates_vat

        datanotvalidboolean = vatnochargeOfficeforeu;

        if (vatnochargeOfficeforeu === true) {
          charge_billing_no_charge_vat = no_vat_based_billing_address;
          if (no_vat_based_billing_address === true) {
            blilling_no_charge_rates_address = vat_charge_no_billing_rates; // rates_no_vat
          } else {
            charge_country_rates_address_eu_2 = vat_charge_no_billing_rates; //rates_no_vat
          }
        }
      }
    } else if (charge_eu === false) {
      datanotvalidboolean2 = vatnochargeOfficeforeu;
      if (vatnochargeOfficeforeu === true) {
        charge_billing_no_charge_vat2 = no_vat_based_billing_address;
        if (no_vat_based_billing_address === true) {
          charge_billing_no_charge_vat2 = no_vat_based_billing_address;
          blilling_no_charge_rates_address2 = vat_charge_no_billing_rates; // no_vates
        } else {
          charge_country_rates_address_eu_2 = vat_charge_no_billing_rates; //rates_no_vat

          // blilling_no_charge_rates_address2 = vat_charge_no_billing_rates; //no_vates
        }
      }
    }
    //LET"S SETUP FOR REST OF WORLD
    let wavierCharge;
    let waviercountry;
    let has_other_country_vat;
    let has_other_charge_policy_vat;
    let other_charge_policy;
    let other_charge_policy2;

    let other_country_vat;
    let other_country_vat2;
    let restbillingaddress1;

    let restbillingaddress2;
    let other_country;
    let other_country_vat_tax;
    let other_country_vat_tax1;

    let other_country_chg_policies;
    let other_waived_countries;

    wavierCharge = data.waive_other_country_vat;
    other_waived_countries = data.other_waived_countries;
    has_other_charge_policy_vat =
      data.offices &&
      data.offices[0] &&
      data.offices[0].has_other_country_charge_policy;
    has_other_country_vat =
      data.offices && data.offices[0] && data.offices[0].has_other_country_vat;
    other_country =
      data.offices && data.offices[0] && data.offices[0].other_country_vat;
    other_country_chg_policies =
      data.offices &&
      data.offices[0] &&
      data.offices[0].other_country_chg_policies;

    if (wavierCharge === true) {
      //waiver countries vat
      waviercountry = other_waived_countries;
      //boolen value
      other_country_vat = has_other_country_vat;

      if (other_country_vat === true) {
        //boolen value
        other_country_vat = has_other_country_vat;
        //vat rates
        other_country_vat_tax = other_country;

        other_charge_policy = has_other_charge_policy_vat;
        if (other_charge_policy === true) {
          other_charge_policy = has_other_charge_policy_vat;
          //vat rates
          restbillingaddress1 = other_country_chg_policies;
        } else {
          other_charge_policy = has_other_charge_policy_vat;
          // billingaddressforrest = null;
        }
      }
    } else if (wavierCharge === false) {
      other_country_vat2 = has_other_country_vat;

      if (other_country_vat2 === true) {
        other_country_vat_tax1 = other_country;
        other_country_vat2 = has_other_country_vat;
        other_charge_policy2 = has_other_charge_policy_vat;
        if (other_charge_policy2 === true) {
          other_charge_policy2 = has_other_charge_policy_vat;
          restbillingaddress2 = other_country_chg_policies;
        } else {
          other_charge_policy2 = has_other_charge_policy_vat;
        }
      } else {
      }
    }

    if ( data &&
      data.offices[0] &&
      data.offices[0].country_name !== "United States" &&
      data &&
      data.offices[0] &&
      data.offices[0].country_name !== "Canada") {
      this.setState({
        tab_1: data && data.offices[0] && data.offices[0].country_name,
        tab_2: "EU",
        tab_3: "United States",
        tab_4: "Canada",
        tab_5: "Rest of the World",
        origin:   data &&
        data.offices[0] &&
        data.offices[0].country_name,
      });
    } else if (  data &&
      data.offices[0] &&
      data.offices[0].country_name === "United States") {
      this.setState({
        tab_1: "United States",
        tab_2: "EU",
        tab_3: "Canada",
        tab_4: "Rest of the World",
      });
    } else {
      this.setState({
        tab_1: "Canada",
        tab_2: "EU",
        tab_3: "United States",
        tab_4: "Rest of the World",
      });
    }
    this.setState({
      origin: data.offices && data.offices[0].country_name,
      data: data,
      publisher: data && data.publisher,
      id: data && data.id,
      country: data.offices && data.offices[0].country,
      apc_including_vat: data.offices && data.offices[0].apc_include_vat,
      charge_vat: vatchargeoffice,
      charge_no_vat: nochargevat,
      not_charge_vat_number: nochargevat,

      selfCountryRates_1: vatrates,
      selfCountrynoRates_1: nochargeselfvat,
      charge_vat_number_eu: charge_eu,
      charge_vat_billing_address_eu_1: charge_billing_charge_vat,
      charge_no_vat_number_eu_1: datanotvalidboolean,
      charge_no_vat_billing_address_eu_2: charge_billing_no_charge_vat,

      charge_no_vat_number_eu_2: datanotvalidboolean2,
      charge_no_vat_billing_address_eu_3: charge_billing_no_charge_vat2,
      //RATES
      selfCountryforEuRates_1: charge_country_rates_address_eu,
      selfCountryforEuNoRates_2: charge_country_rates_address_eu_2,

      billingAdress: blilling_charge_rates_address,
      billingAdress2: blilling_no_charge_rates_address,
      billingAdress3: blilling_no_charge_rates_address2, //US
      charage_no_vat_usa_1: nochargevatforus,

      charge_vat_number_usa: vatchargeofficeforus,
      charge_vat_states_usa_1: statePosition,
      charge_no_vat_states_usa_3: charge_no_vat_states_usa_3, 

      notcharge_vat_usa2: nochargevatforus,
      charge_no_vat_states_usa_2: notchargebasedstates2,
      charge_no_vat_usa_2: charge_no_vat_usa_2,

      countrychargeStates3: noratesvatforstates,
      countrychargeusa3: noratesvatforcountry,
      charage_no_vat_usa_3: nochargevatforus2,

      charge_no_vat_states_usa_4: statePositioncharge_false,

      countrychargeusa: ratesvatforCountry,
      countrychargeStates: ratesvatforstates,
      countrychargeusa2: ratesvatforcountry2,
      countrychargeStates2: ratesvatforstates2,
      countrychargeStates4: noratesvatforstates4,
      countrychargeusa4: noratesvatforcountry4,

      //canada

      charge_vat_number_canada: vatchargeofficeforca,
      proviencebasedoncharge: statePositionca,

      charge_no_vat_canada_1: charge_no_vat_canada_1,
      provience_basedon_vat_no_charge_1: provience_no_charge_vat,
      charge_no_vat_canada_2: charge_no_vat_canada_2,
      provience_basedon_vat_no_charge_2: provience_basedon_vat_no_charge_2,

      charge_no_vat_canada_3: nochargevatforca,
      provience_basedon_vat_no_charge_3: statePositionca2,

      // VAT RATES
      selecteddatabasedonprovince_1: ratesvatforstatesca,
      selecteddatabasedonprovince_2: canada_no_rates_provience,
      selecteddatabasedonprovince_3: canada_no_rates_provience3,
      selecteddatabasedonprovince_4: ratesvatforstatesca2,
      selecteddatabasedoncanadacountry_1: ratesvatforcountryca,
      selecteddatabasedoncanadacountry_2: canadanoratescountry_vat,
      selecteddatabasedoncanadacountry_3: canadanoratescountry_vat3,
      selecteddatabasedoncanadacountry_4: ratesvatforcountryca2,

      //REST OF WORLD
      waive_countries_charge: wavierCharge,
      other_charge_policy: other_charge_policy,
      vat_tax_other_countries_charge: other_country_vat,
      vat_tax_other_countries_charge2: other_country_vat2,
      rest_billing_address2: other_charge_policy,
      rest_billing_address: other_charge_policy2,
      //rates
      countrychargewaive: waviercountry ? waviercountry : [],
      other_countries_vat_tax_2: other_country_vat_tax1,
      billingaddressCountry2: restbillingaddress2,
      other_countries_vat_tax_1: other_country_vat_tax,
      billingaddressCountry: restbillingaddress1,
    });
   }
 
  // ONCHANGE METHOD FOR RADIO BOX
  onChangeHandleRadioBox = (value) => {
    this.setState({
      ...value,
    });
  };
  next=() =>{
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev=()=> {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onChangeSelect = (value) => {
    let data;
    data = this.props.countries
      .filter((item) => item.id === value.country)
      .map((item) => item);
    this.setState({
      ...value,
    });
    this.setState({
      selfCountrynoRates_1: [],
      selfCountryRates_1: [],
      selfCountryforEuRates_1: [],
      selfCountryforEuNoRates_2: [],
    });
    //For SELF COUNTRY
    this.props
      .getVatRatesPubVatConfig({ country: value.country })
      .then((res) => {
        let countryVatList =
          res.results &&
          res.results.map((item) => {
            delete item.vat_rate;

            return item;
          });

        this.setState({
          selfCountryvatRates_default_1: countryVatList,
          selfCountryvatRates_default_2: countryVatList,
          selfCountryRatesDefaultForEU_1: countryVatList,
          selfCountryRatesDefaultForEU_2: countryVatList,
        });
      });
    //For EU COUNTRY
    this.props
      .getVatRatesPubVatConfig({ eucontext: "EU", id: value.country })
      .then((res) => {
        let countryVatList =
          res.results &&
          res.results.map((item) => {
            delete item.vat_rate;

            return item;
          });
        this.setState({
          vatforeu: countryVatList,
        });
      });

    this.props
      .getVatRatesPubVatConfig({ context: "OTHER_COUNTRY" })
      .then((res) => {
        let countryVatList =
          res.results &&
          res.results.map((item) => {
            delete item.vat_rate;

            return item;
          });
        this.setState({
          other_countries: countryVatList,
        });
      });
    this.props.getVatRatesPubVatConfig({ context: "US" }).then(async (res) => {
      const data = await this.props.getStatePubVatConfig(res.results[0].id);
      let countryVatList =
        res.results &&
        res.results.map((item) => {
          delete item.vat_rate;

          return item;
        });
      this.setState({
        stateList: data.results,
        vatforus: countryVatList,
      });
    });

    this.props.getVatRatesPubVatConfig({ context: "CA" }).then(async (res) => {
      let data = await this.props.getCAStatePubVatConfig(res.results[0].id);
      let countryVatList =
        res.results &&
        res.results.map((item) => {
          delete item.vat_rate;

          return item;
        });
      this.setState({
        vatforca: countryVatList,
        stateListCA: data.results,
      });
    });
    if (data[0].name !== "United States" && data[0].name !== "Canada") {
      this.setState({
        tab_1: data[0].name,
        tab_2: "EU",
        tab_3: "United States",
        tab_4: "Canada",
        tab_5: "Rest of the World",
        origin: data[0].name,
      });
    } else if (data[0].name === "United States") {
      this.setState({
        tab_1: "United States",
        tab_2: "EU",
        tab_3: "Canada",
        tab_4: "Rest of the World",
      });
    } else {
      this.setState({
        tab_1: "Canada",
        tab_2: "EU",
        tab_3: "United States",
        tab_4: "Rest of the World",
      });
    }

 
  };

  onSubmit = async () => {
    let vatRatesForCharges;
    let vatRatesNotForCharges;
    let vatState;
    let vatnocharge;
    let statePosition;

    //for rest of the world
    let billingaddressforrest;
    let othercountires;
    let othercountireswaive;
    //  FOR EU
    let datanotvalidboolean;

    if (this.state.charge_vat_number_eu === true) {
      if (this.state.charge_no_vat_number_eu_1) {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_1;
      } else {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_1;
      }
    } else if (this.state.charge_vat_number_eu === false) {
      if (this.state.charge_no_vat_number_eu_2) {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_2;
      } else {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_2;
      }
    }

    if (this.state.charge_vat_number_eu === true) {
      if (this.state.charge_no_vat_number_eu_1) {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_1;
      } else {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_1;
      }
    } else if (this.state.charge_vat_number_eu === false) {
      if (this.state.charge_no_vat_number_eu_2) {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_2;
      } else {
        datanotvalidboolean = this.state.charge_no_vat_number_eu_2;
      }
    }

    // LET SETUP CONTEX RULES FOR SELF COUNTRY

    //VATNOCHARGE DUPLICATE LOGIC

    if (this.state.charge_vat === true) {
      if (this.state.charge_no_vat === true) {
        vatnocharge = this.state.charge_no_vat;
      } else {
        vatnocharge = this.state.charge_no_vat;
      }
    } else if (this.state.charge_vat === false) {
      if (this.state.not_charge_vat_number === true) {
        vatnocharge = this.state.not_charge_vat_number;
      } else {
        vatnocharge = this.state.not_charge_vat_number;
      }
    }

    //VAT RATES

    let vat_country_self;

    vat_country_self =
      this.state.selfCountryRates_1 &&
      this.state.selfCountryRates_1.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );

    const vat_country_self_2 =
      this.state.selfCountrynoRates_1 &&
      this.state.selfCountrynoRates_1.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );

    const self_country_default_1 = this.state.selfCountryvatRates_default_1.map(
      (item) => ({
        country: item.id,
        state: null,
        federal_vat_rate: null,
        state_provincial_vat_rate: null,
        vat_rate: item.vat_rate,
      })
    );
    const self_country_default_2 = this.state.selfCountryvatRates_default_2.map(
      (item) => ({
        country: item.id,
        state: null,
        federal_vat_rate: null,
        state_provincial_vat_rate: null,
        vat_rate: item.vat_rate,
      })
    );

    if (this.state.charge_vat === true) {
      vatRatesForCharges =
        vat_country_self && vat_country_self.length === 0
          ? self_country_default_1
          : vat_country_self;

      if (this.state.charge_no_vat === true) {
        vatRatesNotForCharges =
          vat_country_self_2 && vat_country_self_2.length === 0
            ? self_country_default_2
            : vat_country_self_2;
      }
    } else if (this.state.charge_vat === false) {
      if (this.state.not_charge_vat_number === true) {
        vatRatesNotForCharges = vat_country_self_2;
        vatRatesNotForCharges =
          vat_country_self_2 && vat_country_self_2.length === 0
            ? self_country_default_2
            : vat_country_self_2;
      }
    }
    let chargebilling_address;
    let ratesbasedonAddressnotvat;
    let ratesbasedonAddress;

    let billingAddress2 =
      this.state.billingAdress2 &&
      this.state.billingAdress2.map((item) =>
        item.country
          ? {
            country: item.country,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
          : {
            country: item.id,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
      );
    let billingAddress3 =
      this.state.billingAdress3 &&
      this.state.billingAdress3.map((item) =>
        item.country
          ? {
            country: item.country,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
          : {
            country: item.id,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
      );
    let billingAddress =
      this.state.billingAdress &&
      this.state.billingAdress.map((item) =>
        item.country
          ? {
            country: item.country,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
          : {
            country: item.id,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
      );

    let selfCountryforEuRates_1;
    selfCountryforEuRates_1 =
      this.state.selfCountryforEuRates_1 &&
      this.state.selfCountryforEuRates_1.map((item) =>
        item.country
          ? {
            country: item.country,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
          : {
            country: item.id,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
      );

    let selfCountryforEuNoRates_2;
    selfCountryforEuNoRates_2 =
      this.state.selfCountryforEuNoRates_2 &&
      this.state.selfCountryforEuNoRates_2.map((item) =>
        item.country
          ? {
            country: item.country,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
          : {
            country: item.id,
            vat_rate: item.vat_rate,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
          }
      );
    let self_country_vat_1 = this.state.selfCountryRatesDefaultForEU_1.map(
      (item) => ({
        country: item.id,
        vat_rate: item.vat_rate,
        state: null,
        federal_vat_rate: null,
        state_provincial_vat_rate: null,
      })
    );
    let self_country_vat_2 = this.state.selfCountryRatesDefaultForEU_2.map(
      (item) => ({
        country: item.id,
        vat_rate: item.vat_rate,
        state: null,
        federal_vat_rate: null,
        state_provincial_vat_rate: null,
      })
    );
    if (this.state.charge_vat_number_eu === true) {
      if (this.state.charge_vat_billing_address_eu_1 === true) {
        chargebilling_address = "vat:customer_billing_address";
        ratesbasedonAddress = billingAddress;

        if (this.state.charge_no_vat_number_eu_1 === true) {
          if (this.state.charge_no_vat_billing_address_eu_2 === true) {
            chargebilling_address =
              "vat:customer_billing_address,no_vat:customer_billing_address";
            ratesbasedonAddressnotvat = billingAddress2;
          } else {
            //pending
            chargebilling_address =
              "vat:customer_billing_address,no_vat:publisher_location";
            ratesbasedonAddressnotvat = selfCountryforEuNoRates_2
              ? selfCountryforEuNoRates_2
              : self_country_vat_2;
          }
        }
      } else {
        chargebilling_address = "vat:publisher_location";
        ratesbasedonAddress = selfCountryforEuRates_1
          ? selfCountryforEuRates_1
          : self_country_vat_1;

        if (this.state.charge_no_vat_number_eu_1 === true) {
          if (this.state.charge_no_vat_billing_address_eu_2 === true) {
            chargebilling_address =
              "vat:publisher_location,no_vat:customer_billing_address";
            ratesbasedonAddressnotvat = billingAddress2;
          } else {
            //pending
            chargebilling_address =
              "vat:publisher_location,no_vat:publisher_location";
            ratesbasedonAddressnotvat = selfCountryforEuNoRates_2
              ? selfCountryforEuNoRates_2
              : self_country_vat_2;
          }
        }
      }
    } else if (this.state.charge_vat_number_eu === false) {
      if (this.state.charge_no_vat_number_eu_2 === true) {
        if (this.state.charge_no_vat_billing_address_eu_3 === true) {
          chargebilling_address = "no_vat:customer_billing_address";
          ratesbasedonAddressnotvat = billingAddress3;
        } else {
          chargebilling_address = "no_vat:publisher_location";
          ratesbasedonAddressnotvat = selfCountryforEuNoRates_2
            ? selfCountryforEuNoRates_2
            : self_country_vat_2;
        }
      }
    }

    let notcharge;

    const dataStates =
      this.state.countrychargeStates &&
      this.state.countrychargeStates.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id ? item.id : item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const dataCountry =
      this.state.countrychargeusa &&
      this.state.countrychargeusa.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );

    const dataStates2 =
      this.state.countrychargeStates2 &&
      this.state.countrychargeStates2.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id ? item.id : item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const dataCountry2 =
      this.state.countrychargeusa2 &&
      this.state.countrychargeusa2.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );
    const dataStates3 =
      this.state.countrychargeStates3 &&
      this.state.countrychargeStates3.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id ? item.id : item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const dataCountry3 =
      this.state.countrychargeusa3 &&
      this.state.countrychargeusa3.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );

    const dataStates4 =
      this.state.countrychargeStates4 &&
      this.state.countrychargeStates4.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id ? item.id : item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const dataCountry4 =
      this.state.countrychargeusa4 &&
      this.state.countrychargeusa4.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );
    let vatnostate;
    if (this.state.charge_vat_number_usa === true) {
      if (this.state.charge_vat_states_usa_1 === true) {
        vatState = dataStates;
      } else {
        vatState = dataCountry;
      }
    } else if (this.state.charge_vat_number_usa === false) {
      if (this.state.charage_no_vat_usa_3 === true) {
        if (this.state.charge_no_vat_states_usa_4 === true) {
          vatnostate = dataStates2;
        } else {
          vatnostate = dataCountry2;
        }
      }
    }

    if (this.state.charge_vat_number_usa === true) {
      if (this.state.charge_no_vat_states_usa_4 === true) {
        statePosition = this.state.charge_vat_states_usa_1;
      } else {
        statePosition = this.state.charge_vat_states_usa_1;
      }
    } else if (this.state.charge_vat_number_usa === false) {
      if (this.state.charage_no_vat_usa_3 === true) {
        if (this.state.charge_no_vat_states_usa_4 === true) {
          statePosition = this.state.charge_no_vat_states_usa_4;
        } else {
          statePosition = this.state.charge_no_vat_states_usa_4;
        }
      } else if (this.state.charage_no_vat_usa_3 === false) {
        statePosition = null;
      }
    }

    let charge_no_vat_states;
    if (this.state.charge_vat_number_usa === true) {
      if (this.state.charge_vat_states_usa_1 === true) {
        if (this.state.charage_no_vat_usa_1 === true) {
          notcharge = this.state.charage_no_vat_usa_1;
          if (this.state.charge_no_vat_states_usa_2 === true) {
            charge_no_vat_states = this.state.charge_no_vat_states_usa_2;
            vatnostate = dataStates3;
          } else {
            charge_no_vat_states = this.state.charge_no_vat_states_usa_2;

            vatnostate = dataCountry3;
          }
        } else {
          notcharge = this.state.charage_no_vat_usa_1;
        }
      } else {

        if (this.state.charge_no_vat_usa_2 === true) {
          notcharge = this.state.charge_no_vat_usa_2;
          if (this.state.charge_no_vat_states_usa_3 === true) {
            charge_no_vat_states = this.state.charge_no_vat_states_usa_3;

            vatnostate = dataStates4;
          } else {
            charge_no_vat_states = this.state.charge_no_vat_states_usa_3;

            vatnostate = dataCountry4;
          }
        } else {
          notcharge = this.state.charge_no_vat_usa_2;
        }
      }
    } else {
      notcharge = this.state.charage_no_vat_usa_3;
    }

    // LET SETUP CONTEX RULES FOR CA COUNTRY

    const CanadaProvince_1 =
      this.state.selecteddatabasedonprovince_1 &&
      this.state.selecteddatabasedonprovince_1.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const CanadaCountry_1 =
      this.state.selecteddatabasedoncanadacountry_1 &&
      this.state.selecteddatabasedoncanadacountry_1.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );
    const CanadaCountry_2 =
      this.state.selecteddatabasedoncanadacountry_2 &&
      this.state.selecteddatabasedoncanadacountry_2.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );
    let canadaprovince_2 =
      this.state.selecteddatabasedonprovince_2 &&
      this.state.selecteddatabasedonprovince_2.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const CanadaCountry_3 =
      this.state.selecteddatabasedoncanadacountry_3 &&
      this.state.selecteddatabasedoncanadacountry_3.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );
    let canadaprovince_3 =
      this.state.selecteddatabasedonprovince_3 &&
      this.state.selecteddatabasedonprovince_3.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const canadaProvince_4 =
      this.state.selecteddatabasedonprovince_4 &&
      this.state.selecteddatabasedonprovince_4.map((item) =>
        item.country
          ? {
            country: item.country,
            state: item.state,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.parent ? item.parent : item.country,
            state: item.id,
            federal_vat_rate: item.federal_vat_rate,
            state_provincial_vat_rate: item.state_provincial_vat_rate,
            vat_rate: item.vat_rate,
          }
      );
    const CanadaCountry_4 =
      this.state.selecteddatabasedoncanadacountry_4 &&
      this.state.selecteddatabasedoncanadacountry_4.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            state: null,
            federal_vat_rate: null,
            state_provincial_vat_rate: null,
            vat_rate: item.vat_rate,
          }
      );

    let charge_no_vat_canada;
    let vatprovincenorate;
    let vatprovincerate;
    let statePositionforca;
    let no_charge_vat_province;

    if (this.state.charge_vat_number_canada === true) {
      if (this.state.proviencebasedoncharge === true) {
        vatprovincerate = CanadaProvince_1;
      } else {
        vatprovincerate = CanadaCountry_1;
      }
    } else if (this.state.charge_vat_number_canada === false) {
      if (this.state.charge_no_vat_canada_3 === true) {
        if (this.state.provience_basedon_vat_no_charge_3 === true) {
          vatprovincenorate = canadaProvince_4;
        } else {
          vatprovincenorate = CanadaCountry_4;
        }
      }
    }

    if (this.state.charge_vat_number_canada === true) {
      if (this.state.provience_basedon_vat_no_charge_3 === true) {
        statePositionforca = this.state.proviencebasedoncharge;
      } else {
        statePositionforca = this.state.proviencebasedoncharge;
      }
    } else if (this.state.charge_vat_number_canada === false) {
      if (this.state.charge_no_vat_canada_3 === true) {
        if (this.state.provience_basedon_vat_no_charge_3 === true) {
          statePositionforca = this.state.provience_basedon_vat_no_charge_3;
        } else {
          statePositionforca = this.state.provience_basedon_vat_no_charge_3;
        }
      } else if (this.state.charge_no_vat_canada_3 === false) {
        statePositionforca = null;
      }
    }

    if (this.state.charge_vat_number_canada === true) {
      if (this.state.proviencebasedoncharge === true) {
        if (this.state.charge_no_vat_canada_1 === true) {
          charge_no_vat_canada = this.state.charge_no_vat_canada_1;
          if (this.state.provience_basedon_vat_no_charge_1 === true) {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_1;
            vatprovincenorate = canadaprovince_2;
          } else {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_1;
            vatprovincenorate = CanadaCountry_2;
          }
        } else {
          // no_charge_vat_province = null;
          charge_no_vat_canada = this.state.charge_no_vat_canada_1;
        }
      } else {
        if (this.state.charge_no_vat_canada_2 === true) {
          charge_no_vat_canada = this.state.charge_no_vat_canada_2;
          if (this.state.provience_basedon_vat_no_charge_2 === true) {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_2;
            vatprovincenorate = canadaprovince_3;
          } else {
            no_charge_vat_province = this.state
              .provience_basedon_vat_no_charge_2;
            vatprovincenorate = CanadaCountry_3;
          }
        } else {
          // no_charge_vat_province = null;

          charge_no_vat_canada = this.state.charge_no_vat_canada_2;
        }
      }
    } else {
      charge_no_vat_canada = this.state.charge_no_vat_canada_3;
    }

    //LETS SETUP FOR REST OF WORLD
    let vatOrSalesTax;
    let ChargeCustomerBilling;

    const waivercountry =
      this.state.countrychargewaive &&
      this.state.countrychargewaive.map((item) =>
        item.country
          ? {
            country: item.country,
          }
          : {
            country: item.id,
          }
      );

    //Rates FOR REST OF WORLD

    const billingaddres =
      this.state.billingaddressCountry &&
      this.state.billingaddressCountry.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            charge_policy: "customer_billing_address",
          }
          : {
            country: item.id,
            state: null,
            charge_policy: "customer_billing_address",
          }
      );
    const billingaddres2 =
      this.state.billingaddressCountry2 &&
      this.state.billingaddressCountry2.map((item) =>
        item.country
          ? {
            country: item.country,
            state: null,
            charge_policy: "customer_billing_address",
          }
          : {
            country: item.id,
            state: null,
            charge_policy: "customer_billing_address",
          }
      );
    const selfCountryRatesDefaultortaxesvatrate =
      this.state.other_countries_vat_tax_1 &&
      this.state.other_countries_vat_tax_1.map((item) =>
        item.country
          ? {
            country: item.country,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            vat_rate: item.vat_rate,
          }
      );
    const selfCountryRatesDefaultortaxesvatrate2 =
      this.state.other_countries_vat_tax_2 &&
      this.state.other_countries_vat_tax_2.map((item) =>
        item.country
          ? {
            country: item.country,
            vat_rate: item.vat_rate,
          }
          : {
            country: item.id ? item.id : item.country,
            vat_rate: item.vat_rate,
          }
      );

    if (this.state.waive_countries_charge === true) {
      othercountireswaive = waivercountry;
      if (this.state.vat_tax_other_countries_charge === true) {
        vatOrSalesTax = this.state.vat_tax_other_countries_charge;
        othercountires = selfCountryRatesDefaultortaxesvatrate;
        if (this.state.rest_billing_address2 === true) {
          ChargeCustomerBilling = this.state.rest_billing_address2;
          billingaddressforrest = billingaddres;
        } else {
          ChargeCustomerBilling = this.state.rest_billing_address2;
          billingaddressforrest = null;
        }
      } else {
        othercountires = null;
      }
    } else if (this.state.waive_countries_charge === false) {
      if (this.state.vat_tax_other_countries_charge2 === true) {
        vatOrSalesTax = this.state.vat_tax_other_countries_charge2;
        othercountires = selfCountryRatesDefaultortaxesvatrate2;
        if (this.state.rest_billing_address === true) {
          ChargeCustomerBilling = this.state.rest_billing_address;

          billingaddressforrest = billingaddres2;
        } else {
          ChargeCustomerBilling = this.state.rest_billing_address;
          billingaddressforrest = null;
        }
      } else {
        // vatOrSalesTax = this.state.vat_tax_other_countries_charge2
        othercountires = null;
      }
    }
    //new context rules
    let contextrules;

    if (this.state.tab_1 !== "United States" && this.state.tab_1 !== "Canada") {
      contextrules = [
        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[0] &&
            this.state.data.offices[0].rules[0].id,
          context: "SELF",
          charge_vat: this.state.charge_vat,
          charge_no_vat: vatnocharge,
          charge_policy: null,
          charge_based_on_states: false,
          country: this.state.country,
          state: null,
          rates_vat: vatRatesForCharges ? vatRatesForCharges : [],
          rates_no_vat: vatRatesNotForCharges ? vatRatesNotForCharges : [],
        },

        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[1] &&
            this.state.data.offices[0].rules[1].id,
          context: "EU",
          charge_vat: this.state.charge_vat_number_eu,
          charge_no_vat: datanotvalidboolean,
          charge_based_on_states: true,
          country: null,
          state: null,
          charge_policy: chargebilling_address ? chargebilling_address : null,
          rates_vat: ratesbasedonAddress ? ratesbasedonAddress : [],
          rates_no_vat: ratesbasedonAddressnotvat
            ? ratesbasedonAddressnotvat
            : [],
        },

        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[2] &&
            this.state.data.offices[0].rules[2].id,

          context: "US",
          charge_vat: this.state.charge_vat_number_usa,
          charge_no_vat: notcharge,
          country: null,
          charge_policy: null,
          charge_based_on_states_vat: statePosition,
          charge_based_on_states_no_vat: charge_no_vat_states,
          state: null,
          rates_vat: vatState ? vatState : [],
          rates_no_vat: vatnostate ? vatnostate : [],
        },
        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[3] &&
            this.state.data.offices[0].rules[3].id,
          context: "CA",
          charge_policy: null,
          charge_vat: this.state.charge_vat_number_canada,
          charge_no_vat: charge_no_vat_canada,
          charge_based_on_states_vat: statePositionforca,
          charge_based_on_states_no_vat: no_charge_vat_province,
          country: null,
          rates_vat: vatprovincerate ? vatprovincerate : [],
          rates_no_vat: vatprovincenorate ? vatprovincenorate : [],
        },
      ];
    } else if (this.state.tab_1 === "United States") {
      contextrules = [
        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[0] &&
            this.state.data.offices[0].rules[0].id,
          context: "SELF",

          charge_vat: this.state.charge_vat_number_usa,
          charge_no_vat: notcharge,
          country: this.state.country,
          charge_policy: null,
          charge_based_on_states_vat: statePosition,
          charge_based_on_states_no_vat: charge_no_vat_states,
          state: null,
          rates_vat: vatState ? vatState : [],
          rates_no_vat: vatnostate ? vatnostate : [],
        },
        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[1] &&
            this.state.data.offices[0].rules[1].id,
          context: "EU",
          charge_vat: this.state.charge_vat_number_eu,
          charge_no_vat: datanotvalidboolean,
          charge_based_on_states: true,
          country: null,
          state: null,
          charge_policy: chargebilling_address ? chargebilling_address : null,
          rates_vat: ratesbasedonAddress ? ratesbasedonAddress : [],
          rates_no_vat: ratesbasedonAddressnotvat
            ? ratesbasedonAddressnotvat
            : [],
        },

        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[2] &&
            this.state.data.offices[0].rules[2].id,
          context: "CA",
          charge_policy: null,
          charge_vat: this.state.charge_vat_number_canada,
          charge_no_vat: charge_no_vat_canada,
          charge_based_on_states_vat: statePositionforca,
          charge_based_on_states_no_vat: no_charge_vat_province,
          country: null,
          rates_vat: vatprovincerate ? vatprovincerate : [],
          rates_no_vat: vatprovincenorate ? vatprovincenorate : [],
        },
      ];
    } else if (this.state.tab_1 === "Canada") {
      contextrules = [
        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[0] &&
            this.state.data.offices[0].rules[0].id,
          context: "SELF",
          charge_policy: null,
          charge_vat: this.state.charge_vat_number_canada,
          charge_no_vat: charge_no_vat_canada,
          charge_based_on_states_vat: statePositionforca,
          charge_based_on_states_no_vat: no_charge_vat_province,
          country: this.state.country,
          rates_vat: vatprovincerate ? vatprovincerate : [],
          rates_no_vat: vatprovincenorate ? vatprovincenorate : [],
        },
        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[1] &&
            this.state.data.offices[0].rules[1].id,
          context: "EU",
          charge_vat: this.state.charge_vat_number_eu,
          charge_no_vat: datanotvalidboolean,
          charge_based_on_states: true,
          country: null,
          state: null,
          charge_policy: chargebilling_address ? chargebilling_address : null,
          rates_vat: ratesbasedonAddress ? ratesbasedonAddress : [],
          rates_no_vat: ratesbasedonAddressnotvat
            ? ratesbasedonAddressnotvat
            : [],
        },

        {
          id:
            this.state.data &&
            this.state.data.offices &&
            this.state.data.offices[0] &&
            this.state.data.offices[0].rules[2] &&
            this.state.data.offices[0].rules[2].id,
          context: "US",
          charge_vat: this.state.charge_vat_number_usa,
          charge_no_vat: notcharge,
          country: null,
          charge_policy: null,
          charge_based_on_states_vat: statePosition,
          charge_based_on_states_no_vat: charge_no_vat_states,
          state: null,
          rates_vat: vatState ? vatState : [],
          rates_no_vat: vatnostate ? vatnostate : [],
        },
      ];
    }

    const data = {
      country: this.state.country,
      has_other_country_vat: vatOrSalesTax ? vatOrSalesTax : false,
      has_other_country_charge_policy: ChargeCustomerBilling
        ? ChargeCustomerBilling
        : false,
      apc_include_vat: this.state.apc_including_vat
        ? this.state.apc_including_vat
        : false,
      other_country_vat: othercountires ? othercountires : [],
      rules: this.state.apc_including_vat === true ? [] : contextrules,
      other_country_chg_policies: billingaddressforrest
        ? billingaddressforrest
        : [],
    };
    const offices = this.state.offices.concat(data);
    //APC INCLUDING VAT
    const mainData = {
      publisher: this.state.publisher,
      id: this.state.id,
      offices,
      // waive_other_country_vat: this.state.waive_countries_charge,
      other_waived_countries: othercountireswaive ? othercountireswaive : [],
    };

    const contexData = {
      offices: [
        {
          // id: this.state.id,
          apc_include_vat: this.state.apc_including_vat,
          country: this.state.country,

          other_country_vat: [],
          rules: [],
          has_other_country_charge_policy: true,
          other_country_chg_policies: [],
        },
      ],
      // waive_other_country_vat: false,
      other_waived_countries: [],
    };
    let apc_include_data = this.state.apc_including_vat ? contexData : mainData;

    let createVate = await this.props.publisherUpdateVatCreateConfigure(
      apc_include_data,
      this.state.id
    );

    if ( createVate && createVate.data && createVate.data.success === true) {
      this.setState({
        success: true,
        createVate:createVate &&createVate.data
      });
    }
  };
  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push("/settings/vat-configuration", {
      id:this.state.createVate && this.state.createVate.id
    });
  }


  //Multi CheckBox Selected Method
  selectCountryChargeforeu = (props) => {
    this.setState({ countryChargeforeu: props });
  };
  selectCountrynotChargeforeu = (props) => {
    this.setState({ countrynotChargeforeu1: props });
  };
  selectCountryBillingAddressforeu = (props) => {
    this.setState({ billingAdress: props, eu: "ONE" });
  };
  selectCountryBillingAddressforeu1 = (props) => {
    this.setState({ billingAdress1: props });
  };
  selectCountryBillingAddressforeu2 = (props) => {
    this.setState({ billingAdress2: props });
  };

  selectCountryBillingAddressforeu3 = (props) => {
    this.setState({ billingAdress3: props });
  };

  //Multi CheckBox Selected Method FOR USA
  selectCountryBasedonCountry = (props) => {
    this.setState({ countrychargeusa: props });
  };
  selectCountryBasedonStates = (props) => {
    this.setState({ countrychargeStates: props });
  };
  selectCountryBasedonCountry2 = (props) => {
    this.setState({ countrychargeusa2: props });
  };
  selectCountryBasedonStates2 = (props) => {
    this.setState({ countrychargeStates2: props });
  };
  selectCountryBasedonCountry3 = (props) => {
    this.setState({ countrychargeusa3: props });
  };
  selectCountryBasedonStates3 = (props) => {
    this.setState({ countrychargeStates3: props });
  };
  selectCountryBasedonCountry4 = (props) => {
    this.setState({ countrychargeusa4: props });
  };
  selectCountryBasedonStates4 = (props) => {
    this.setState({ countrychargeStates4: props });
  };
  //Multi CheckBox Selected Method FOR CA
  selectCountryBasedonCountryforCA = (props) => {
    this.setState({ countrychargeca: props });
  };
  selectCountryBasedonProvience = (props) => {
    this.setState({ countrychargeprovince: props });
  };
  SelectCheckBoxBasedOnProvince_4 = (props) => {
    this.setState({ countrychargeCA2: props });
  };
  SelectCheckBoxBasedCanadaCountry_4 = (props) => {
    this.setState({ countrychargeprovinc2: props });
  };
  //Multi CheckBox Selected Method FOR CA

  SelectCheckBoxBasedOnProvince_1 = (props) => {
    this.setState({ selecteddatabasedonprovince_1: props });
  };
  SelectCheckBoxBasedOnProvince_2 = (props) => {
    this.setState({ selecteddatabasedonprovince_2: props });
  };
  SelectCheckBoxBasedOnProvince_3 = (props) => {
    this.setState({ selecteddatabasedonprovince_3: props });
  };
  SelectCheckBoxBasedOnProvince_4 = (props) => {
    this.setState({ selecteddatabasedonprovince_4: props });
  };

  SelectCheckBoxBasedCanadaCountry_1 = (props) => {
    this.setState({ selecteddatabasedoncanadacountry_1: props });
  };
  SelectCheckBoxBasedCanadaCountry_2 = (props) => {
    this.setState({ selecteddatabasedoncanadacountry_2: props });
  };
  SelectCheckBoxBasedCanadaCountry_3 = (props) => {
    this.setState({ selecteddatabasedoncanadacountry_3: props });
  };

  SelectCheckBoxBasedCanadaCountry_4 = (props) => {
    this.setState({ selecteddatabasedoncanadacountry_4: props });
  };

  //REST OF WORLD
  selectCountrywaiveCountry = (props) => {
    this.setState({ countrychargewaive: props });
  };
  selectselfCountryRatesDefaultorTaxesCountry = (props) => {
    this.setState({ other_countries_vat_tax_1: props });
  };
  selectselfCountryRatesDefaultorTaxesCountry2 = (props) => {
    this.setState({ other_countries_vat_tax_2: props });
  };
  selectCountrybillingaddressCountry = (props) => {
    this.setState({ billingaddressCountry: props });
  };
  selectCountrybillingaddressCountry2 = (props) => {
    this.setState({ billingaddressCountry2: props });
  };

  onSubmitVatForPublisher = async () => {
    const data = await this.props.createVatConfig(this.state.vat_number);
    this.setState({
      id: data.data.vatid,
      vat_number: data.data.vat_number,
    });
  };
  updateHandle = async () => {
    await this.props.createUpdateVatConfig({
      vat_number: this.state.vat_number,
      id: this.state.vatid,
    });
    this.setState({
      enable: true,
    });
  };
  onHandleEdit = async (id) => {
    const data = await this.props.getSingleVatConfig(id);
    this.setState({
      vat_number: data.vat_number,
      enable: !this.state.enable,
    });
  };
  onHandleDelete = async (id) => {
    await this.props.DeleteVatConfig(id);

    const data = await this.props.getVatConfig();
    const vatData = Object.assign({}, data.results);
    this.setState({
      vatid: vatData && vatData[0] && vatData[0].id ? vatData[0].id : null,
      vat_number:
        vatData && vatData[0] && vatData[0].vat_number
          ? vatData[0].vat_number
          : null,
    });
  };

  onTextFieldChange = (value) => {
    this.setState((prevState) => Object.assign(prevState.vat_number, value));
  };
  onTextField = (value) => {
    this.setState({
      vat_number: value,
    });
  };

  EditHandler = (value) => {
    this.setState({
      selfCountryRates_1: value,
    });
  };
  EditHandler_2 = (value) => {
    this.setState({
      selfCountrynoRates_1: value,
    });
  };

  EditHandler_default_1 = (value) => {
    this.setState({
      selfCountryvatRates_default_1: value,
    });
  };
  EditHandler_default_2 = (value) => {
    this.setState({
      selfCountryvatRates_default_2: value,
    });
  };
  EditHandler_eu_1 = (value) => {
    this.setState({
      selfCountryforEuRates_1: value,
    });
  };
  EditHandler_eu_2 = (value) => {
    this.setState({
      selfCountryforEuNoRates_2: value,
    });
  };

  EditHandler_default_EU_1 = (value) => {
    this.setState({
      selfCountryRatesDefaultForEU_1: value,
    });
  };

  EditHandler_default_EU_2 = (value) => {
    this.setState({
      selfCountryRatesDefaultForEU_2: value,
    });
  };

  EditHandlerforeu_1 = (value) => {
    this.setState({
      billingAdress: value,
    });
  };
  EditHandlerforeu_2 = (value) => {
    this.setState({
      billingAdress2: value,
    });
  };
  EditHandlerforeu_3 = (value) => {
    this.setState({
      billingAdress3: value,
    });
  };

  //USA FOR OVERRIDE EDIT HANDLER
  EditHandler_for_us_1 = (value) => {
    this.setState({
      countrychargeStates: value,
    });
  };
  EditHandler_for_us_2 = (value) => {
    this.setState({
      countrychargeStates2: value,
    });
  };
  EditHandler_for_us_3 = (value) => {
    this.setState({
      countrychargeStates3: value,
    });
  };
  EditHandler_for_us_4 = (value) => {
    this.setState({
      countrychargeStates4: value,
    });
  };

  EditHandler_country_us_1 = (value) => {
    this.setState({
      countrychargeusa: value,
    });
  };
  EditHandler_country_us_2 = (value) => {
    this.setState({
      countrychargeusa2: value,
    });
  };
  EditHandler_country_us_3 = (value) => {
    this.setState({
      countrychargeusa3: value,
    });
  };
  EditHandler_country_us_4 = (value) => {
    this.setState({
      countrychargeusa4: value,
    });
  };
  //CA FOR OVERRIDE EDIT HANDLER
  EditHandler_for_ca_1 = (value) => {
    this.setState({
      selecteddatabasedonprovince_1: value,
    });
  };
  EditHandler_for_ca_2 = (value) => {
    this.setState({
      selecteddatabasedonprovince_2: value,
    });
  };
  EditHandler_for_ca_3 = (value) => {
    this.setState({
      selecteddatabasedonprovince_3: value,
    });
  };
  EditHandler_for_ca_4 = (value) => {
    this.setState({
      selecteddatabasedonprovince_4: value,
    });
  };

  EditHandler_country_ca_1 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_1: value,
    });
  };
  EditHandler_country_ca_2 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_2: value,
    });
  };
  EditHandler_country_ca_3 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_3: value,
    });
  };
  EditHandler_country_ca_4 = (value) => {
    this.setState({
      selecteddatabasedoncanadacountry_4: value,
    });
  };
  //REST
  editHandler_waiver_1 = (value) => {
    this.setState({
      countrychargewaive: value,
    });
  };
  editHandler_rest_1 = (value) => {
    this.setState({
      other_countries_vat_tax_1: value,
    });
  };
  editHandler_rest_2 = (value) => {
    this.setState({
      other_countries_vat_tax_2: value,
    });
  };
  editHandler_rest_3 = (value) => {
    this.setState({
      billingaddressCountry: value,
    });
  };
  editHandler_rest_4 = (value) => {
    this.setState({
      billingaddressCountry2: value,
    });
  };
  render() {

    function fixedTwoDigit(x) {
      return Number.parseFloat(x).toFixed(2);
    }
    const canadaState =
      this.props.state_list_ca && this.props.state_list_ca.results;
    //EU HEADING
    const headingforeu = [
      "Country",
      "Default VAT %",
      "Set your own VAT rate %",
    ];

    //USA
    const array =
      this.state.countrychargeStates &&
      this.state.countrychargeStates.map((item) => ({
        id: item.state,
        parent: item.country,
        country_name: item.country_name,
        name: item.name,
        short_name: item.name,
        federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
        state_provincial_vat_rate: fixedTwoDigit(
          item.state_provincial_vat_rate
        ),
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));

    const arrayStates2 =
      this.state.countrychargeStates2 &&
      this.state.countrychargeStates2.map((item) => ({
        id: item.state,
        parent: item.country,
        country_name: item.country_name,
        name: item.name,
        short_name: item.name,
        federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
        state_provincial_vat_rate: fixedTwoDigit(
          item.state_provincial_vat_rate
        ),
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));

    const array2 = [];
    this.state.countrychargeusa &&
      this.state.countrychargeusa.map((item) =>
        array2.push({
          id: item.country,
          name: item.country_name,
          short_name: item.short_name,
          vat_rate: fixedTwoDigit(item.vat_rate),
          Country: "item.country_name",
          "Federal Rate": item.country_name,
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
        })
      );

    const arraycountry2 =
      this.state.countrychargeusa2 &&
      this.state.countrychargeusa2.map((item) => ({
        id: item.country,
        name: item.country_name,
        short_name: item.short_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        Country: item.country_name,
        " Federal Rate": "-",
        States: "-",
        "State Rate": "-",
        "Total %": fixedTwoDigit(item.vat_rate),
      }));

    const SelectStates3 = [];
    this.state.countrychargeStates3 &&
      this.state.countrychargeStates3.map((item) =>
        SelectStates3.push({
          id: item.state,
          parent: item.country,
          country_name: item.country_name,
          name: item.name,
          short_name: item.name,
          federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          vat_rate: fixedTwoDigit(item.vat_rate),
        })
      );

    let SelectCountry3 = [];
    this.state.countrychargeusa3 &&
      this.state.countrychargeusa3.map((item) =>
        SelectCountry3.push({
          id: item.country,
          name: item.country_name,
          short_name: item.short_name,
          vat_rate: fixedTwoDigit(item.vat_rate),
          Country: item.country_name,
          " Federal Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
        })
      );

    const SelectStates4 = [];
    this.state.countrychargeStates4 &&
      this.state.countrychargeStates4.map((item) =>
        SelectStates4.push({
          id: item.state,
          parent: item.country,
          country_name: item.country_name,
          name: item.name,
          short_name: item.name,
          federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          vat_rate: fixedTwoDigit(item.vat_rate),
        })
      );

    let SelectCountry4 = [];
    this.state.countrychargeusa4 &&
      this.state.countrychargeusa4.map((item) =>
        SelectCountry4.push({
          id: item.country,
          name: item.country_name,
          short_name: item.short_name,
          vat_rate: fixedTwoDigit(item.vat_rate),
          Country: item.country_name,
          "Federal Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
        })
      );

    let arrayofbillingaddrss = [];
    this.state.billingAdress &&
      this.state.billingAdress.map((item) =>
        arrayofbillingaddrss.push({
          id: item.country,
          country: item.country,
          name: item.country_name ? item.country_name : item.name,
          vat_rate: fixedTwoDigit(item.vat_rate),
          country_name: item.country_name ? item.country_name : item.name,
        })
      );

    let arrayofbillingaddrss2 = [];
    this.state.billingAdress2 &&
      this.state.billingAdress2.map((item) =>
        arrayofbillingaddrss2.push({
          id: item.country,
          country: item.country,
          name: item.country_name ? item.country_name : item.name,
          vat_rate: fixedTwoDigit(item.vat_rate),
          country_name: item.country_name ? item.country_name : item.name,
        })
      );

    let arrayofbillingaddrss3 = [];
    this.state.billingAdress3 &&
      this.state.billingAdress3.map((item) =>
        arrayofbillingaddrss3.push({
          id: item.country,
          country: item.country,
          name: item.country_name ? item.country_name : item.name,
          vat_rate: fixedTwoDigit(item.vat_rate),
          country_name: item.country_name ? item.country_name : item.name,
        })
      );

    let eubillingaddress =
      this.state.billingAdress &&
      this.state.billingAdress.map((item) => {
        return Object.assign(item, {
          key: item.id && item.id.toString(),
          country: item.country ? item.country : item.id,
          country_name: item.country_name ? item.country_name : item.name,
          default_vat_rate: item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: fixedTwoDigit(item.vat_rate) || 0,
        });
      });

    let eubillingaddress2 =
      this.state.billingAdress2 &&
      this.state.billingAdress2.map((item) => {
        return Object.assign(item, {
          key: item.id && item.id.toString(),
          country: item.country ? item.country : item.id,
          country_name: item.country_name ? item.country_name : item.name,
          default_vat_rate: item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: fixedTwoDigit(item.vat_rate) || 0,
        });
      });

    let eubillingaddress3 =
      this.state.billingAdress3 &&
      this.state.billingAdress3.map((item) => {
        return Object.assign(item, {
          key: item.id && item.id.toString(),
          country: item.country ? item.country : item.id,
          country_name: item.country_name ? item.country_name : item.name,
          default_vat_rate: item.default_vat_rate ? item.default_vat_rate : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let basedonStates = [];
    this.state.countrychargeStates &&
      this.state.countrychargeStates.map((item) =>
        basedonStates.push({
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: item.federal_vat_rate,
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        })
      );

    let basedonCountry =
      this.state.countrychargeusa &&
      this.state.countrychargeusa.map((item) => {
        return Object.assign(item, {
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let basedonStates2 =
      this.state.countrychargeStates2 &&
      this.state.countrychargeStates2.map((item) => {
        return Object.assign(item, {
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: item.federal_vat_rate,
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let basedonCountry2 =
      this.state.countrychargeusa2 &&
      this.state.countrychargeusa2.map((item) => {
        return Object.assign(item, {
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });
    let basedonStates3 =
      this.state.countrychargeStates3 &&
      this.state.countrychargeStates3.map((item) => {
        return Object.assign(item, {
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: item.federal_vat_rate,
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let basedonCountry3 =
      this.state.countrychargeusa3 &&
      this.state.countrychargeusa3.map((item) => {
        return Object.assign(item, {
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let basedonStates4 =
      this.state.countrychargeStates4 &&
      this.state.countrychargeStates4.map((item) => {
        return Object.assign(item, {
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: item.federal_vat_rate,
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });
    let basedonCountry4 =
      this.state.countrychargeusa4 &&
      this.state.countrychargeusa4.map((item) => {
        return Object.assign(item, {
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });
    //CA SELECT CHECKBOX

    let ProvienceCA = [];
    this.state.countrychargeca &&
      this.state.countrychargeca.map((item) =>
        ProvienceCA.push({
          Country: item.country_name,
          "PST Rate": fixedTwoDigit(item.federal_vat_rate),
          States: item.name,
          "State Rate": fixedTwoDigit(item.state_provincial_vat_rate),
          "Total %": fixedTwoDigit(item.vat_rate),
          vat: "-",
        })
      );

    let Canadaprovincedata_4 = [];
    this.state.countrychargeCA2 &&
      this.state.countrychargeCA2.map((item) =>
        Canadaprovincedata_4.push({
          Country: item.country_name,
          "PST Rate": fixedTwoDigit(item.federal_vat_rate),
          States: item.name,
          "State Rate": fixedTwoDigit(item.state_provincial_vat_rate),
          "Total %": fixedTwoDigit(item.vat_rate),
          vat: "-",
        })
      );

    let countryData1 = [];
    this.state.countrychargeprovince &&
      this.state.countrychargeprovince.map((item) =>
        countryData1.push({
          Country: item.country_name ? item.country_name : item.name,
          "PST Rate": "-",
          States: "-",
          "State Rate": "-",
          "Total %": fixedTwoDigit(item.vat_rate),
          vat: "-",
        })
      );

    //CANADA ALL SELECT CHECKBOX
    const SelectShowCanadaProvience1 =
      this.state.selecteddatabasedonprovince_1 &&
      this.state.selecteddatabasedonprovince_1.map((item) => ({
        id: item.state,
        parent: item.country,
        country_name: item.country_name,
        name: item.name,
        short_name: item.name,
        federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
        state_provincial_vat_rate: fixedTwoDigit(
          item.state_provincial_vat_rate
        ),
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));
    const SelectShowCanadaProvience2 =
      this.state.selecteddatabasedonprovince_2 &&
      this.state.selecteddatabasedonprovince_2.map((item) => ({
        id: item.state,
        parent: item.country,
        country_name: item.country_name,
        name: item.name,
        short_name: item.name,
        federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
        state_provincial_vat_rate: fixedTwoDigit(
          item.state_provincial_vat_rate
        ),
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));
    const SelectShowCanadaProvience3 =
      this.state.selecteddatabasedonprovince_3 &&
      this.state.selecteddatabasedonprovince_3.map((item) => ({
        id: item.state,
        parent: item.country,
        country_name: item.country_name,
        name: item.name,
        short_name: item.name,
        federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
        state_provincial_vat_rate: fixedTwoDigit(
          item.state_provincial_vat_rate
        ),
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));
    const SelectShowCanadaProvience4 =
      this.state.selecteddatabasedonprovince_4 &&
      this.state.selecteddatabasedonprovince_4.map((item) => ({
        id: item.state,
        parent: item.country,
        country_name: item.country_name,
        name: item.name,
        short_name: item.name,
        federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
        state_provincial_vat_rate: fixedTwoDigit(
          item.state_provincial_vat_rate
        ),
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));

    const SelectCheckBoxShowCanadaCountry1 =
      this.state.selecteddatabasedoncanadacountry_1 &&
      this.state.selecteddatabasedoncanadacountry_1.map((item) => ({
        id: item.country,
        name: item.country_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        Country: item.country_name,
        "Federal Rate": "-",
        States: "-",
        "State Rate": "-",
        "Total %": fixedTwoDigit(item.vat_rate),
      }));

    const SelectCheckBoxShowCanadaCountr2 =
      this.state.selecteddatabasedoncanadacountry_2 &&
      this.state.selecteddatabasedoncanadacountry_2.map((item) => ({
        id: item.country,
        name: item.country_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        Country: item.country_name,
        "Federal Rate": "-",
        States: "-",
        "State Rate": "-",
        "Total %": fixedTwoDigit(item.vat_rate),
      }));

    const SelectCheckBoxShowCanadaCountry3 =
      this.state.selecteddatabasedoncanadacountry_3 &&
      this.state.selecteddatabasedoncanadacountry_3.map((item) => ({
        id: item.country,
        name: item.country_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        Country: item.country_name,
        " Federal Rate": "-",
        States: "-",
        "State Rate": "-",
        "Total %": fixedTwoDigit(item.vat_rate),
      }));

    const selecteddatabasedoncanadacountry_4 =
      this.state.selecteddatabasedoncanadacountry_4 &&
      this.state.selecteddatabasedoncanadacountry_4.map((item) => ({
        id: item.country,
        name: item.country_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        Country: item.country_name,
        "Federal Rate": "-",
        States: "-",
        "State Rate": "-",
        "Total %": fixedTwoDigit(item.vat_rate),
      }));

    //Table Data

    let canadaprovincedata_1 =
      this.state.selecteddatabasedonprovince_1 &&
      this.state.selecteddatabasedonprovince_1.map((item) => {
        return Object.assign(item, {
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });
    let canadaprovincedata_2 =
      this.state.selecteddatabasedonprovince_2 &&
      this.state.selecteddatabasedonprovince_2.map((item) => {
        return Object.assign(item, {
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let canadaprovincedata_3 =
      this.state.selecteddatabasedonprovince_3 &&
      this.state.selecteddatabasedonprovince_3.map((item) => {
        return Object.assign(item, {
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let canadaprovincedata_4 =
      this.state.selecteddatabasedonprovince_4 &&
      this.state.selecteddatabasedonprovince_4.map((item) => {
        return Object.assign(item, {
          id: item.id,
          state: item.state ? item.state : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country: item.parent ? item.parent : item.country,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: fixedTwoDigit(item.federal_vat_rate),
          states_name: item.name ? item.name : "-",
          name: item.name ? item.name : "-",
          state_provincial_vat_rate: fixedTwoDigit(
            item.state_provincial_vat_rate
          ),
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let canadadatacountrytable_1 =
      this.state.selecteddatabasedoncanadacountry_1 &&
      this.state.selecteddatabasedoncanadacountry_1.map((item) => {
        return Object.assign(item, {
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let canadadataforcountry2 =
      this.state.selecteddatabasedoncanadacountry_2 &&
      this.state.selecteddatabasedoncanadacountry_2.map((item) => {
        return Object.assign(item, {
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });

    let canadadataforcountry3 =
      this.state.selecteddatabasedoncanadacountry_3 &&
      this.state.selecteddatabasedoncanadacountry_3.map((item) => {
        return Object.assign(item, {
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        });
      });
    let canadadataforcountry4 = [];
    this.state.selecteddatabasedoncanadacountry_4 &&
      this.state.selecteddatabasedoncanadacountry_4.map((item) =>
        canadadataforcountry4.push({
          id: item.id,
          country: item.country ? item.country : item.id,
          key:
            item.id && item.id.toString()
              ? item.id && item.id.toString()
              : item.key,
          country_name: item.country_name ? item.country_name : item.name,
          federal_vat_rate: "-",
          states_name: "-",
          state_provincial_vat_rate: "-",
          total_rates: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        })
      );

    //REST OF WORLD

    let waiverscountrieslist = [];
    this.state.countrychargewaive &&
      this.state.countrychargewaive.map((item) =>
        waiverscountrieslist.push({
          id: item.country,
          name: item.country_name,

          short_name: item.short_name,
          vat_rate: fixedTwoDigit(item.vat_rate),
        })
      );

    let SalesOrVatTaxes =
      this.state.other_countries_vat_tax_2 &&
      this.state.other_countries_vat_tax_2.map((item) => ({
        id: item.country,
        name: item.country_name,
        short_name: item.short_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        is_active: item.is_active,
      }));

    let vat_charge_billing_tax =
      this.state.billingaddressCountry2 &&
      this.state.billingaddressCountry2.map((item) => ({
        id: item.country,
        name: item.country_name,
        short_name: item.short_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        is_active: item.is_active,
      }));

    let SalesOrVatTaxes2 =
      this.state.other_countries_vat_tax_1 &&
      this.state.other_countries_vat_tax_1.map((item) => ({
        id: item.country,
        name: item.country_name,
        short_name: item.short_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        is_active: item.is_active,
      }));

    let vat_charge_billing_tax2 =
      this.state.billingaddressCountry &&
      this.state.billingaddressCountry.map((item) => ({
        id: item.country,
        name: item.country_name,
        short_name: item.short_name,
        vat_rate: fixedTwoDigit(item.vat_rate),
        is_active: item.is_active,
      }));

    let other_countries_vat_tax_1 =
      this.state.other_countries_vat_tax_1 &&
      this.state.other_countries_vat_tax_1.map((item) => {
        return Object.assign(
          item,
          item.country
            ? {
              key:
                item.country && item.country.toString()
                  ? item.country && item.country.toString()
                  : item.key,
              country: item.id ? item.id : item.country,
              country_name: item.name ? item.name : item.country_name,
              default_vat_rate: item.default_vat_rate
                ? fixedTwoDigit(item.default_vat_rate)
                : 0,
              vat_rate: item.vat_rate,
            }
            : {
              key:
                item.id && item.id.toString()
                  ? item.id && item.id.toString()
                  : item.key,
              country: item.id ? item.id : item.country,
              country_name: item.name ? item.name : item.country_name,
              default_vat_rate: item.default_vat_rate
                ? fixedTwoDigit(item.default_vat_rate)
                : 0,
              vat_rate: fixedTwoDigit(item.vat_rate),
            }
        );
      });

    let other_countries_vat_tax_2 =
      this.state.other_countries_vat_tax_2 &&
      this.state.other_countries_vat_tax_2.map((item) => {
        return Object.assign(
          item,
          item.country
            ? {
              key:
                item.country && item.country.toString()
                  ? item.country && item.country.toString()
                  : item.key,
              country: item.id ? item.id : item.country,
              country_name: item.name ? item.name : item.country_name,
              default_vat_rate: item.default_vat_rate
                ? fixedTwoDigit(item.default_vat_rate)
                : 0,
              vat_rate: item.vat_rate,
            }
            : {
              key:
                item.id && item.id.toString()
                  ? item.id && item.id.toString()
                  : item.key,
              country: item.id ? item.id : item.country,
              country_name: item.name ? item.name : item.country_name,
              default_vat_rate: item.default_vat_rate
                ? fixedTwoDigit(item.default_vat_rate)
                : 0,
              vat_rate: fixedTwoDigit(item.vat_rate),
            }
        );
      });

    let billingaddres =
      this.state.billingaddressCountry &&
      this.state.billingaddressCountry.map((item) => {
        return Object.assign(item, {
          country_name: item.name ? item.name : item.country_name,
          default_vat_rate: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
        });
      });
    let billingaddres2 =
      this.state.billingaddressCountry2 &&
      this.state.billingaddressCountry2.map((item) => {
        return Object.assign(item, {
          Country: item.name ? item.name : item.country_name,
          "Default VAT %": item.vat_rate ? fixedTwoDigit(item.vat_rate) : "-",
          vat_rate: "-",
        });
      });
    let waiverscountries =
      this.state.countrychargewaive &&
      this.state.countrychargewaive.map((item) => {
        return Object.assign(item, {
          country_name: item.name ? item.name : item.country_name,
        });
      });

    let selfCountryvatRates_default_1 = [];
    this.state.selfCountryvatRates_default_1 &&
      this.state.selfCountryvatRates_default_1.map((item) =>
        selfCountryvatRates_default_1.push({
          country: item.country,
          id: item.id,
          key: item.id && item.id.toString(),
          country_name: item.country_name ? item.country_name : item.name,
          default_vat_rate: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate ||0 ),
        })
      );
    let selfCountryvatRates_default_2 = [];
    this.state.selfCountryvatRates_default_2 &&
      this.state.selfCountryvatRates_default_2.map((item) =>
        selfCountryvatRates_default_2.push({
          country: item.country,
          id: item.id,
          key: item.id && item.id.toString(),
          country_name: item.country_name ? item.country_name : item.name,
          default_vat_rate: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate ||0),
        })
      );

    ///EU

    let selfCountryRates_1;
    selfCountryRates_1 = [];
    this.state.selfCountryRates_1 &&
      this.state.selfCountryRates_1.map((item) =>
        selfCountryRates_1.push({
          country: item.country,
          id: item.id,
          key: item.id && item.id.toString(),
          country_name: item.name ? item.name : item.country_name,
          default_vat_rate: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate ||0),
        })
      );


    selfCountryRates_1 =
      selfCountryRates_1.length === 0
        ? selfCountryvatRates_default_1
        : selfCountryRates_1;

    let selfCountrynoRates_1;
    selfCountrynoRates_1 = [];
    this.state.selfCountrynoRates_1 &&
      this.state.selfCountrynoRates_1.map((item) =>
        selfCountrynoRates_1.push({
          country: item.country,
          id: item.id,
          key: item.id && item.id.toString(),
          country_name: item.name ? item.name : item.country_name,
          default_vat_rate: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate ||0 ),
        })
      );
    selfCountrynoRates_1 =
      selfCountrynoRates_1.length === 0
        ? selfCountryvatRates_default_2
        : selfCountrynoRates_1;

    let vat_country_self_eu_1 =
      this.state.selfCountryRatesDefaultForEU_1 &&
      this.state.selfCountryRatesDefaultForEU_1.map((item) => ({
        country: item.id,
        country_name: item.name ? item.name : item.country_name,
        key: item.id && item.id.toString(),
        state: null,
        default_vat_rate: item.default_vat_rate
          ? fixedTwoDigit(item.default_vat_rate)
          : 0,
        federal_vat_rate: null,
        state_provincial_vat_rate: null,
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));

    let selfCountryforEuRates_1 = [];
    this.state.selfCountryforEuRates_1 &&
      this.state.selfCountryforEuRates_1.map((item) =>
        selfCountryforEuRates_1.push({
          country: item.country,
          id: item.id,
          key: item.id && item.id.toString(),
          country_name: item.name ? item.name : item.country_name,
          default_vat_rate: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        })
      );

    selfCountryforEuRates_1 =
      selfCountryforEuRates_1.length === 0
        ? vat_country_self_eu_1
        : selfCountryforEuRates_1;

    let vat_country_self_eu_2 =
      this.state.selfCountryRatesDefaultForEU_2 &&
      this.state.selfCountryRatesDefaultForEU_2.map((item) => ({
        country: item.id,
        key: item.id && item.id.toString(),
        state: null,
        country_name: item.name ? item.name : item.country_name,
        default_vat_rate: item.default_vat_rate
          ? fixedTwoDigit(item.default_vat_rate)
          : 0,

        federal_vat_rate: null,
        state_provincial_vat_rate: null,
        vat_rate: fixedTwoDigit(item.vat_rate),
      }));
    let selfCountryforEuNoRates_2 = [];
    this.state.selfCountryforEuNoRates_2 &&
      this.state.selfCountryforEuNoRates_2.map((item) =>
        selfCountryforEuNoRates_2.push({
          country: item.country,
          id: item.id,
          key: item.id && item.id.toString(),
          country_name: item.name ? item.name : item.country_name,
          default_vat_rate: item.default_vat_rate
            ? fixedTwoDigit(item.default_vat_rate)
            : 0,
          vat_rate: fixedTwoDigit(item.vat_rate),
        })
      );

    selfCountryforEuNoRates_2 =
      selfCountryforEuNoRates_2.length === 0
        ? vat_country_self_eu_2
        : selfCountryforEuNoRates_2;

    const steps = [
      {
        title: "Start",
        content: (
          <div className="vat-step">
            <p className="eu_vat_para">
              Let's set up your VAT/Tax for your customers
            </p>
            <div className="">
              <div className="">
                {this.state.vatid ? (
                  <div className="d-flex">
                    <div className="vat-question-panel w-100  d-flex justify-content-between align-items-center">
                      {this.state.enable === true ? (
                        <p className="vat-number-para float-left">
                          Your VAT & Taxes Number
                        </p>
                      ) : (
                          <p className="vat-number-para float-left">
                            Please add your VAT or Taxes Number
                          </p>
                        )}

                      <div className="vat-input view w-100 d-flex align-items-center">
                        <div className="w-100">
                          {this.state.enable === true ? (
                            <div className="w-100">
                              <h3>{this.state.vat_number}</h3>
                              <Buttons
                                type="primary"
                                name="primary"
                                text="Edit"
                                onClick={() =>
                                  this.onHandleEdit(this.state.vatid)
                                }
                              />
                            </div>
                          ) : (
                              <InputBox
                                placeholder="Name"
                                field="vat_number"
                                className="w-100"
                                onChange={this.onTextFieldChange}
                                defaultValue={this.state.vat_number}
                              />
                            )}
                        </div>
                        <div className="vat-input-btn ml-2">
                          {this.state.enable !== true ? (
                            <Buttons
                              type="primary"
                              name="primary"
                              text="Update"
                              onClick={this.updateHandle}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <p className="margin-10">
                Please select the country from where you will raise an invoice
                for open access APC
              </p>

              <div className="d-flex w-100 align-items-center mt-4 pb-4 pb-md-0">
                <label className="col-md-2 vat-country mr-2">Country :</label>
                {
                  this.props.isVatLoading !== true ?<Loading type="flat"/> :  <div className="col-md-8 vat-county-list">
                      <SelectBox
                        onChange={this.onChangeSelect}
                        field="country"
                        className="col-md-8"
                        disabled={true}
                        data={this.props.countries}
                        placeholder="Select a Country"
                        defaultValue={this.state.tab_1}
                      />
                    </div>
                }
              </div>

            </div>
          </div>
        ),
      },
      {
        title: this.state.tab_1 || "Self Origin",

        content:
          this.state.tab_1 === "United States" ? (
            <div className="vat-detail-form">
              <p className="eu_vat_para">
                Let's setup VAT/Tax for customers in USA.
              </p>
              <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                <p>
                  Do you charge Tax/GST to USA customers if they provide a valid
                  VAT/GST/Business Tax Number?
                </p>
                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="charge_vat_number_usa"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.charge_vat_number_usa}
                />
              </div>
              {this.state.charge_vat_number_usa === true && (
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>Do you charge Tax/GST based on the States?</p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charge_vat_states_usa_1"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charge_vat_states_usa_1}
                  />
                </div>
              )}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 === true && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.state.stateList}
                          array={array}
                          selectCountry={(props) =>
                            this.selectCountryBasedonStates(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={basedonStates}
                        editHandler={(value) =>
                          this.EditHandler_for_us_1(value)
                        }
                        field="USA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 === false && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.state.vatforus}
                          array={array2}
                          selectCountry={(props) =>
                            this.selectCountryBasedonCountry(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={basedonCountry}
                        editHandler={(value) =>
                          this.EditHandler_country_us_1(value)
                        }
                        countryType="USA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 === true ? (
                  <div className="vat-question-panel d-flex justify-content-between align-items-center">
                    <p>
                      Do you charge Tax/GST if customer’s has no valid Tax/GST
                      number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charage_no_vat_usa_1"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charage_no_vat_usa_1}
                    />
                  </div>
                ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== false ? (
                  // this.state.charge_vat_states_usa_1 !== false ? (
                  this.state.charage_no_vat_usa_1 === true ? (
                    <div className="vat-question-panel d-flex justify-content-between align-items-center">
                      <p>Do you charge Tax/GST based on the States?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_states_usa_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_states_usa_2}
                      />
                    </div>
                  ) : null
                ) : // ) : null
                  null
              ) : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== false
                  ? this.state.charage_no_vat_usa_1 !== false
                    ? this.state.charge_no_vat_states_usa_2 === true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override de3fault GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={SelectStates3}
                              data={
                                this.props.state_list &&
                                this.props.state_list.results
                              }
                              selectCountry={(props) =>
                                this.selectCountryBasedonStates3(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonStates3}
                            editHandler={(value) =>
                              this.EditHandler_for_us_3(value)
                            }
                            field="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== false
                  ? this.state.charage_no_vat_usa_1 !== false
                    ? this.state.charge_no_vat_states_usa_2 === false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.3434
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              array={SelectCountry3}
                              field="STATES"
                              data={this.state.vatforus}
                              selectCountry={(props) =>
                                this.selectCountryBasedonCountry3(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonCountry3}
                            editHandler={(value) =>
                              this.EditHandler_country_us_3(value)
                            }
                            countryType={"USA"}
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {}{" "}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== true ? (
                  this.state.charge_vat_states_usa_1 === false ? (
                    <div className="vat-question-panel d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge Tax/GST if customer’s has no valid Tax/GST
                        number?
                      </p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_usa_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_usa_2}
                      />
                    </div>
                  ) : null
                ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== true ? (
                  // this.state.charge_vat_states_usa_1 !== false ? (
                  this.state.charge_no_vat_usa_2 === true ? (
                    <div className="vat-question-panel d-flex justify-content-between align-items-center">
                      <p>Do you charge Tax/GST based on the States?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_states_usa_3"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_states_usa_3}
                      />
                    </div>
                  ) : null
                ) : // ) : null
                  null
              ) : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== true
                  ? this.state.charge_no_vat_usa_2 !== false
                    ? this.state.charge_no_vat_states_usa_3 === true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={SelectStates4}
                              data={
                                this.props.state_list &&
                                this.props.state_list.results
                              }
                              selectCountry={(props) =>
                                this.selectCountryBasedonStates4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonStates4}
                            editHandler={(value) =>
                              this.EditHandler_for_us_4(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== true
                  ? this.state.charge_no_vat_usa_2 !== false
                    ? this.state.charge_no_vat_states_usa_3 === false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              array={SelectCountry4}
                              field="STATES"
                              data={this.state.vatforus}
                              selectCountry={(props) =>
                                this.selectCountryBasedonCountry4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonCountry4}
                            editHandler={(value) =>
                              this.EditHandler_country_us_4(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa === false && (
                <div className="vat-question-panel d-flex justify-content-between align-items-center">
                  <p>
                    Do you charge Tax/GST if customer’s has no valid Tax/GST
                    number?
                  </p>
                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charage_no_vat_usa_3"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charage_no_vat_usa_3}
                  />
                </div>
              )}
              {this.state.charge_vat_number_usa !== true
                ? this.state.charage_no_vat_usa_3 === true && (
                  <div className="vat-question-panel d-flex justify-content-between align-items-center">
                    <p>Do you charge Tax/GST based on the States?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charge_no_vat_states_usa_4"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charge_no_vat_states_usa_4}
                    />
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== true ? (
                this.state.charage_no_vat_usa_3 === true &&
                  this.state.charge_no_vat_states_usa_4 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.state.stateList}
                            array={arrayStates2}
                            selectCountry={(props) =>
                              this.selectCountryBasedonStates2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={basedonStates2}
                          editHandler={(value) =>
                            this.EditHandler_for_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== true ? (
                this.state.charage_no_vat_usa_3 === true &&
                  this.state.charge_no_vat_states_usa_4 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.state.vatforus}
                            array={arraycountry2}
                            selectCountry={(props) =>
                              this.selectCountryBasedonCountry2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={basedonCountry2}
                          editHandler={(value) =>
                            this.EditHandler_country_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
            </div>
          ) : this.state.tab_1 !== "United States" &&
            this.state.tab_1 !== "Canada" ? (
                // <div className="Uk-form">
                <div>
                  <div className="vat-question-panel d-flex justify-content-between align-items-center">
                    <p>Is your Open Access APC including VAT?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="apc_including_vat"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.apc_including_vat}
                    />
                  </div>

                  {this.state.apc_including_vat === false && (
                    <div className="vat-question-panel d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge VAT to customers in {this.state.origin} if
                    they provide a valid VAT number?
                  </p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_vat"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_vat}
                      />
                    </div>
                  )}

                  {this.state.apc_including_vat !== true ? (
                    this.state.charge_vat === true ? (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Add VAT rate for the customers in {this.state.origin}{" "}
                          who has valid VAT number
                        </p>
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            heading={headingforeu}
                            editHandler={(value) => this.EditHandler(value)}
                            data={selfCountryRates_1}
                          />
                        </div>
                      </div>
                    ) : null
                  ) : null}
                  {this.state.apc_including_vat !== true
                    ? this.state.charge_vat === true && (
                      <div className="vat-question-panel d-flex justify-content-between align-items-center">
                        <p>
                          Do you charge VAT to customers in {this.state.origin} if
                        they do not have a valid VAT number?
                      </p>

                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="charge_no_vat"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.charge_no_vat}
                        />
                      </div>
                    )
                    : null}

                  {this.state.apc_including_vat !== true
                    ? this.state.charge_vat !== false
                      ? this.state.charge_no_vat === true && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Add VAT rate for the customers in{" "}
                                {this.state.origin} who has no valid VAT number
                            </p>
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              heading={headingforeu}
                              editHandler={(value) => this.EditHandler_2(value)}
                              data={selfCountrynoRates_1}
                            />
                          </div>
                        </div>
                      )
                      : null
                    : null}

                  {this.state.apc_including_vat !== true
                    ? this.state.charge_vat === false && (
                      <div className="vat-question-panel d-flex justify-content-between align-items-center">
                        <p>
                          Do you charge VAT to customers in {this.state.origin} if
                        they do not have a valid VAT number?
                      </p>

                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="not_charge_vat_number"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.not_charge_vat_number}
                        />
                      </div>
                    )
                    : null}
                  {this.state.apc_including_vat !== true
                    ? this.state.charge_vat !== true
                      ? this.state.not_charge_vat_number === true && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Add VAT rate for the customers in{" "}
                                {this.state.origin} who has no valid VAT number
                            </p>
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              heading={headingforeu}
                              editHandler={(value) => this.EditHandler_2(value)}
                              data={selfCountrynoRates_1}
                            />
                          </div>
                        </div>
                      )
                      : null
                    : null}
                  <div></div>
                </div>
              ) : this.state.tab_1 === "Canada" ? (
                <div className="vat-detail-form canada">
                  <div>


                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge Tax/GST/HST/PST to customers in Canada if they
                        provide a valid VAT/GST/Business Tax Number?
                  </p>

                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_vat_number_canada"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_vat_number_canada}
                      />
                    </div>
                    {this.state.charge_vat_number_canada === true && (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>Do you charge Tax/GST/HST/PST based on province?</p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="proviencebasedoncharge"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.proviencebasedoncharge}
                        />
                      </div>
                    )}
                    {this.state.charge_vat_number_canada !== false
                      ? this.state.proviencebasedoncharge === true && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            {/* <div className="vat-info">
                          <p>
                            Here is the default Tax/GST/HST/PST on OaMetrix.
                            You can override default VAT rate to apply your
                            own rate.
                          </p>
                        </div> */}

                            <div className="country-list">
                              <MultiSelectCheckBox
                                field="STATES"
                                array={SelectShowCanadaProvience1}
                                data={this.state.stateListCA}
                                selectCountry={(props) =>
                                  this.SelectCheckBoxBasedOnProvince_1(props)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            {/* <VatTable data={ProvienceCA} countryType="CA" /> */}

                            <VatTable
                              data={canadaprovincedata_1}
                              editHandler={(value) =>
                                this.EditHandler_for_ca_1(value)
                              }
                              countryType="CA"
                            />
                          </div>
                        </div>
                      )
                      : null}
                    {this.state.charge_vat_number_canada !== false
                      ? this.state.proviencebasedoncharge === false && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Here is the default Tax/GST/HST/PST on OaMetrix.
                                You can override default VAT rate to apply your
                                own rate.
                            </p>
                            </div>
                            <div className="country-list">
                              <MultiSelectCheckBox
                                field="STATES"
                                data={this.state.vatforca}
                                array={SelectCheckBoxShowCanadaCountry1}
                                selectCountry={(props) =>
                                  this.SelectCheckBoxBasedCanadaCountry_1(props)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              data={canadadatacountrytable_1}
                              editHandler={(value) =>
                                this.EditHandler_country_ca_1(value)
                              }
                              countryType="CA"
                            />
                          </div>
                        </div>
                      )
                      : null}

                    {this.state.charge_vat_number_canada !== false ? (
                      this.state.proviencebasedoncharge === true ? (
                        <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                          <p>
                            Do you charge Tax/GST if customer’s has no valid Tax/GST
                            number?
                      </p>
                          <RadioButtonGroup
                            onChange={this.onChangeHandleRadioBox}
                            field="charge_no_vat_canada_1"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={this.state.charge_no_vat_canada_1}
                          />
                        </div>
                      ) : null
                    ) : null}

                    {this.state.charge_vat_number_canada !== false ? (
                      this.state.proviencebasedoncharge !== false ? (
                        // this.state.charge_vat_states_usa !== false ? (
                        this.state.charge_no_vat_canada_1 === true ? (
                          <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                            <p>Do you charge Tax/GST/HST/PST based on province?</p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="provience_basedon_vat_no_charge_1"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.state.provience_basedon_vat_no_charge_1}
                            />
                          </div>
                        ) : null
                      ) : // ) : null
                        null
                    ) : null}

                    {this.state.charge_vat_number_canada !== false
                      ? this.state.proviencebasedoncharge !== false
                        ? this.state.charge_no_vat_canada_1 !== false
                          ? this.state.provience_basedon_vat_no_charge_1 ===
                          true && (
                            <div className="vat-country">
                              <div className="vat-country-details">
                                {/* <div className="vat-info">
                              <p>
                                Here is the default GST/VAT/Tax rate on
                                OaMetrix. You can override default GST/VAT/Tax
                                rate to apply correct one.
                              </p>
                            </div> */}
                                <div className="country-list">
                                  <MultiSelectCheckBox
                                    array={SelectShowCanadaProvience2}
                                    field="STATES"
                                    data={canadaState}
                                    selectCountry={(props) =>
                                      this.SelectCheckBoxBasedOnProvince_2(props)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12 vat-margin">
                                <VatTable
                                  editHandler={(value) =>
                                    this.EditHandler_for_ca_2(value)
                                  }
                                  data={canadaprovincedata_2}
                                  countryType="CA"
                                />
                              </div>
                            </div>
                          )
                          : null
                        : null
                      : null}
                    {this.state.charge_vat_number_canada !== false
                      ? this.state.proviencebasedoncharge !== false
                        ? this.state.charge_no_vat_canada_1 !== false
                          ? this.state.provience_basedon_vat_no_charge_1 ===
                          false && (
                            <div className="vat-country">
                              <div className="vat-country-details">
                                <div className="vat-info">
                                  <p>
                                    Here is the default Tax/GST/HST/PST on
                                    OaMetrix. You can override default VAT rate to
                                    apply your own rate.
                                </p>
                                </div>
                                <div className="country-list">
                                  <MultiSelectCheckBox
                                    field="STATES"
                                    array={SelectCheckBoxShowCanadaCountr2}
                                    data={this.state.vatforca}
                                    selectCountry={(props) =>
                                      this.SelectCheckBoxBasedCanadaCountry_2(
                                        props
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12 vat-margin">
                                <VatTable
                                  editHandler={(value) =>
                                    this.EditHandler_country_ca_2(value)
                                  }
                                  data={canadadataforcountry2}
                                  countryType="CA"
                                />
                              </div>
                            </div>
                          )
                          : null
                        : null
                      : null}

                    {/* IF PROVIENC FALSE  */}

                    {this.state.charge_vat_number_canada !== false ? (
                      this.state.proviencebasedoncharge !== true ? (
                        this.state.proviencebasedoncharge === false ? (
                          <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                            <p>
                              Do you charge Tax/GST if customer’s has no valid
                              Tax/GST number?
                        </p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="charge_no_vat_canada_2"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.state.charge_no_vat_canada_2}
                            />
                          </div>
                        ) : null
                      ) : null
                    ) : null}

                    {this.state.charge_vat_number_canada !== false ? (
                      this.state.proviencebasedoncharge !== true ? (
                        // this.state.charge_vat_states_usa !== false ? (
                        this.state.charge_no_vat_canada_2 === true ? (
                          <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                            <p>Do you charge Tax/GST/HST/PST based on province?</p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="provience_basedon_vat_no_charge_2"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.state.provience_basedon_vat_no_charge_2}
                            />
                          </div>
                        ) : null
                      ) : // ) : null
                        null
                    ) : null}

                    {this.state.charge_vat_number_canada !== false
                      ? this.state.proviencebasedoncharge !== true
                        ? this.state.charge_no_vat_canada_2 !== false
                          ? this.state.provience_basedon_vat_no_charge_2 ===
                          true && (
                            <div className="vat-country">
                              <div className="vat-country-details">
                                <div className="vat-info">
                                  <p>
                                    Here is the default GST/VAT/Tax rate on
                                    OaMetrix. You can override default GST/VAT/Tax
                                    rate to apply correct one.
                                </p>
                                </div>
                                <div className="country-list">
                                  <MultiSelectCheckBox
                                    field="STATES"
                                    array={SelectShowCanadaProvience3}
                                    data={canadaState}
                                    selectCountry={(props) =>
                                      this.SelectCheckBoxBasedOnProvince_3(props)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12 vat-margin">
                                <VatTable
                                  editHandler={(value) =>
                                    this.EditHandler_for_ca_3(value)
                                  }
                                  data={canadaprovincedata_3}
                                  countryType="CA"
                                />
                              </div>
                            </div>
                          )
                          : null
                        : null
                      : null}
                    {this.state.charge_vat_number_canada !== false
                      ? this.state.proviencebasedoncharge !== true
                        ? this.state.charge_no_vat_canada_2 !== false
                          ? this.state.provience_basedon_vat_no_charge_2 ===
                          false && (
                            <div className="vat-country">
                              <div className="vat-country-details">
                                <div className="vat-info">
                                  <p>
                                    Here is the default Tax/GST/HST/PST on
                                    OaMetrix. You can override default VAT rate to
                                    apply your own rate.
                                </p>
                                </div>

                                <div className="country-list">
                                  <MultiSelectCheckBox
                                    field="STATES"
                                    array={SelectCheckBoxShowCanadaCountry3}
                                    data={this.state.vatforca}
                                    selectCountry={(props) =>
                                      this.SelectCheckBoxBasedCanadaCountry_3(
                                        props
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12 vat-margin">
                                <VatTable
                                  editHandler={(value) =>
                                    this.EditHandler_country_ca_3(value)
                                  }
                                  data={canadadataforcountry3}
                                  countryType="CA"
                                />
                              </div>
                            </div>
                          )
                          : null
                        : null
                      : null}

                    {/*comment*/}
                    {this.state.charge_vat_number_canada === false && (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>
                          Do you charge Tax/GST if customer’s has no valid Tax/GST
                          number?
                    </p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="charge_no_vat_canada_3"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.charge_no_vat_canada_3}
                        />
                      </div>
                    )}
                    {this.state.charge_vat_number_canada !== true
                      ? this.state.charge_no_vat_canada_3 === true && (
                        <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                          <p>Do you charge Tax/GST based on province?</p>

                          <RadioButtonGroup
                            onChange={this.onChangeHandleRadioBox}
                            field="provience_basedon_vat_no_charge_3"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={this.state.provience_basedon_vat_no_charge_3}
                          />
                        </div>
                      )
                      : null}

                    {this.state.charge_vat_number_canada !== true ? (
                      this.state.charge_no_vat_canada_3 === true &&
                        this.state.provience_basedon_vat_no_charge_3 === true ? (
                          <div className="vat-country">
                            <div className="vat-country-details">
                              <div className="vat-info">
                                <p>
                                  Here is the default Tax/GST/HST/PST on OaMetrix. You
                                  can override default VAT rate to apply your own
                                  rate.
                          </p>
                              </div>
                              <div className="country-list">
                                <MultiSelectCheckBox
                                  field="STATES"
                                  data={canadaState}
                                  array={SelectShowCanadaProvience4}
                                  selectCountry={
                                    (props) =>
                                      this.SelectCheckBoxBasedOnProvince_4(props)

                                    // this.SelectCheckBoxBasedOnProvince_4(props)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12 vat-margin">
                              <VatTable
                                data={canadaprovincedata_4}
                                editHandler={(value) =>
                                  this.EditHandler_for_ca_4(value)
                                }
                                countryType="CA"
                              />

                              {/* <VatTable data={Canadaprovincedata_4} countryType="CA" /> */}
                            </div>
                          </div>
                        ) : null
                    ) : null}
                    {this.state.charge_vat_number_canada !== true ? (
                      this.state.charge_no_vat_canada_3 === true &&
                        this.state.provience_basedon_vat_no_charge_3 === false ? (
                          <div className="vat-country">
                            <div className="vat-country-details">
                              <div className="vat-info">
                                <p>
                                  Here is the default Tax/GST/HST/PST on OaMetrix. You
                                  can override default VAT rate to apply your own
                                  rate.
                          </p>
                              </div>
                              <div className="country-list">
                                <MultiSelectCheckBox
                                  field="STATES"
                                  array={selecteddatabasedoncanadacountry_4}
                                  data={this.state.vatforca}
                                  selectCountry={(props) =>
                                    this.SelectCheckBoxBasedCanadaCountry_4(props)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12 vat-margin">
                              <VatTable
                                data={canadadataforcountry4}
                                editHandler={(value) =>
                                  this.EditHandler_country_ca_4(value)
                                }
                                countryType="CA"
                              />
                            </div>
                          </div>
                        ) : null
                    ) : null}
                  </div>
                </div>
              ) : null,
      },
      {
        title: this.state.tab_2 || "EU",
        content:
          this.state.tab_2 === "EU" ? (
            <div>
              <div className="vat_eu">
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Do you charge VAT to EU customers if they provide you a
                    valid VAT number?
                  </p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charge_vat_number_eu"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charge_vat_number_eu}
                  />
                </div>

                {this.state.charge_vat_number_eu === true && (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>How do you charge VAT?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charge_vat_billing_address_eu_1"
                      options={[
                        {
                          id: true,
                          name: "Based on customer's billing address",
                        },
                        {
                          id: false,
                          name: "Based on your country of location",
                        },
                      ]}
                      value={this.state.charge_vat_billing_address_eu_1}
                    />
                  </div>
                )}
                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_vat_billing_address_eu_1 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default VAT rate on OaMetrix. You can
                            override default VAT rate to apply your own rate.
                          </p>
                        </div>

                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            array={arrayofbillingaddrss}
                            data={this.state.vatforeu}
                            selectCountry={(props) =>
                              this.selectCountryBillingAddressforeu(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={eubillingaddress}
                          editHandler={(value) =>
                            this.EditHandlerforeu_1(value)
                          }
                        />
                      </div>
                    </div>
                  ) : null
                ) : null}
                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_vat_billing_address_eu_1 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default VAT rate on OaMetrix. You can
                            override default VAT rate to apply your own rate.
                          </p>
                        </div>
                      </div>
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <div className="col-12">
                          <div className="card m-b-20">
                            <VatTable
                              data={selfCountryforEuRates_1}
                              editHandler={(value) =>
                                this.EditHandler_eu_1(value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}

                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_vat_billing_address_eu_1 === true ||
                    this.state.charge_vat_billing_address_eu_1 === false ? (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>
                          Do you charge VAT to EU customers if they do not provide
                          you a valid VAT number?
                      </p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="charge_no_vat_number_eu_1"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.charge_no_vat_number_eu_1}
                        />
                      </div>
                    ) : null
                ) : null}

                {this.state.charge_vat_number_eu === true ? (
                  this.state.charge_no_vat_number_eu_1 === true ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>How do you charge VAT?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_billing_address_eu_2"
                        options={[
                          {
                            id: true,
                            name: "Based on customer's billing address",
                          },
                          {
                            id: false,
                            name: "Based on your country of location",
                          },
                        ]}
                        value={this.state.charge_no_vat_billing_address_eu_2}
                      />
                    </div>
                  ) : null
                ) : null}
                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_no_vat_number_eu_1 !== false ? (
                    this.state.charge_no_vat_billing_address_eu_2 === true ? (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              {" "}
                              Here is the default VAT rate on OaMetrix. You can
                              override default VAT rate to apply your own rate.
                            </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={arrayofbillingaddrss2}
                              data={this.state.vatforeu}
                              selectCountry={(props) =>
                                this.selectCountryBillingAddressforeu2(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={eubillingaddress2}
                            editHandler={(value) =>
                              this.EditHandlerforeu_2(value)
                            }
                          />
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}

                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_no_vat_number_eu_1 !== false ? (
                    this.state.charge_no_vat_billing_address_eu_2 === false ? (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default VAT rate on OaMetrix. You can
                              override default VAT rate to apply your own rate.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={selfCountryforEuNoRates_2}
                            editHandler={(value) =>
                              this.EditHandler_eu_2(value)
                            }
                          />
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}

                {this.state.charge_vat_number_eu === false && (
                  <div>
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge VAT to EU customers if they do not provide
                        you a valid VAT number?
                      </p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_number_eu_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_number_eu_2}
                      />
                    </div>
                    {this.state.charge_no_vat_number_eu_2 === true ? (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>How do you charge VAT?</p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="charge_no_vat_billing_address_eu_3"
                          options={[
                            {
                              id: true,
                              name: "Based on customer's billing address",
                            },
                            {
                              id: false,
                              name: "Based on your country of location",
                            },
                          ]}
                          value={this.state.charge_no_vat_billing_address_eu_3}
                        />
                      </div>
                    ) : null}

                    {this.state.charge_no_vat_number_eu_2 === true
                      ? this.state.charge_no_vat_billing_address_eu_3 ===
                      true && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                {" "}
                                  Here is the default VAT rate on OaMetrix. You
                                  can override default VAT rate to apply your
                                  own rate.
                                </p>
                            </div>
                            <div className="country-list">
                              <MultiSelectCheckBox
                                field="STATES"
                                array={arrayofbillingaddrss3}
                                data={this.state.vatforeu}
                                selectCountry={(props) =>
                                  this.selectCountryBillingAddressforeu3(
                                    props
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="vat-question-panel">
                            <div className="col-12">
                              <div className="card m-b-20">
                                <VatTable
                                  data={eubillingaddress3}
                                  editHandler={(value) =>
                                    this.EditHandlerforeu_3(value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : null}
                    {this.state.charge_no_vat_number_eu_2 === true
                      ? this.state.charge_no_vat_billing_address_eu_3 ===
                      false && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Here is the default VAT rate on OaMetrix. You
                                can override default VAT rate to apply your
                                own rate.
                                </p>
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              data={selfCountryforEuNoRates_2}
                              editHandler={(value) =>
                                this.EditHandler_eu_2(value)
                              }
                            />

                            {/* <VatTable data={selfCountryforEuNoRates_2} editHandler={(value)=>this.EditHandler_eu_2(value)} /> */}
                          </div>
                        </div>
                      )
                      : null}
                  </div>
                )}
              </div>
            </div>
          ) : null,
      },
      {
        title: this.state.tab_3 || "United States",
        content:
          this.state.tab_3 === "United States" ? (
            <div className="vat-detail-form">
              <p className="eu_vat_para">
                Let's setup VAT/Tax for customers in USA.
              </p>
              <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                <p>
                  Do you charge Tax/GST to USA customers if they provide a valid
                  VAT/GST/Business Tax Number?
                </p>
                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="charge_vat_number_usa"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.charge_vat_number_usa}
                />
              </div>
              {this.state.charge_vat_number_usa === true && (
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>Do you charge Tax/GST based on the States?</p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charge_vat_states_usa_1"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charge_vat_states_usa_1}
                  />
                </div>
              )}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 === true && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.state.stateList}
                          array={array}
                          selectCountry={(props) =>
                            this.selectCountryBasedonStates(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={basedonStates}
                        editHandler={(value) =>
                          this.EditHandler_for_us_1(value)
                        }
                        countryType="USA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 === false && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.state.vatforus}
                          array={array2}
                          selectCountry={(props) =>
                            this.selectCountryBasedonCountry(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={basedonCountry}
                        editHandler={(value) =>
                          this.EditHandler_country_us_1(value)
                        }
                        countryType="USA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 === true ? (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>
                      Do you charge Tax/GST if customer’s has no valid Tax/GST
                      number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charage_no_vat_usa_1"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charage_no_vat_usa_1}
                    />
                  </div>
                ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== false ? (
                  // this.state.charge_vat_states_usa_1 !== false ? (
                  this.state.charage_no_vat_usa_1 === true ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>Do you charge Tax/GST based on the States?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_states_usa_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_states_usa_2}
                      />
                    </div>
                  ) : null
                ) : // ) : null
                  null
              ) : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== false
                  ? this.state.charage_no_vat_usa_1 !== false
                    ? this.state.charge_no_vat_states_usa_2 === true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override de3fault GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={SelectStates3}
                              data={
                                this.props.state_list &&
                                this.props.state_list.results
                              }
                              selectCountry={(props) =>
                                this.selectCountryBasedonStates3(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonStates3}
                            editHandler={(value) =>
                              this.EditHandler_for_us_3(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== false
                  ? this.state.charage_no_vat_usa_1 !== false
                    ? this.state.charge_no_vat_states_usa_2 === false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.3434
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              array={SelectCountry3}
                              field="STATES"
                              data={this.state.vatforus}
                              selectCountry={(props) =>
                                this.selectCountryBasedonCountry3(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonCountry3}
                            editHandler={(value) =>
                              this.EditHandler_country_us_3(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {}{" "}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== true ? (
                  this.state.charge_vat_states_usa_1 === false ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge Tax/GST if customer’s has no valid Tax/GST
                        number?
                      </p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_usa_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_usa_2}
                      />
                    </div>
                  ) : null
                ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== true ? (
                  // this.state.charge_vat_states_usa_1 !== false ? (
                  this.state.charge_no_vat_usa_2 === true ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>Do you charge Tax/GST based on the States?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_states_usa_3"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_states_usa_3}
                      />
                    </div>
                  ) : null
                ) : // ) : null
                  null
              ) : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== true
                  ? this.state.charge_no_vat_usa_2 !== false
                    ? this.state.charge_no_vat_states_usa_3 === true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={SelectStates4}
                              data={
                                this.props.state_list &&
                                this.props.state_list.results
                              }
                              selectCountry={(props) =>
                                this.selectCountryBasedonStates4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonStates4}
                            editHandler={(value) =>
                              this.EditHandler_for_us_4(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== true
                  ? this.state.charge_no_vat_usa_2 !== false
                    ? this.state.charge_no_vat_states_usa_3 === false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              array={SelectCountry4}
                              field="STATES"
                              data={this.state.vatforus}
                              selectCountry={(props) =>
                                this.selectCountryBasedonCountry4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonCountry4}
                            editHandler={(value) =>
                              this.EditHandler_country_us_4(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa === false && (
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Do you charge Tax/GST if customer’s has no valid Tax/GST
                    number?
                  </p>
                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charage_no_vat_usa_3"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charage_no_vat_usa_3}
                  />
                </div>
              )}
              {this.state.charge_vat_number_usa !== true
                ? this.state.charage_no_vat_usa_3 === true && (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>Do you charge Tax/GST based on the States?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charge_no_vat_states_usa_4"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charge_no_vat_states_usa_4}
                    />
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== true ? (
                this.state.charage_no_vat_usa_3 === true &&
                  this.state.charge_no_vat_states_usa_4 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.state.stateList}
                            array={arrayStates2}
                            selectCountry={(props) =>
                              this.selectCountryBasedonStates2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={basedonStates2}
                          editHandler={(value) =>
                            this.EditHandler_for_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== true ? (
                this.state.charage_no_vat_usa_3 === true &&
                  this.state.charge_no_vat_states_usa_4 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.state.vatforus}
                            array={arraycountry2}
                            selectCountry={(props) =>
                              this.selectCountryBasedonCountry2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={basedonCountry2}
                          editHandler={(value) =>
                            this.EditHandler_country_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
            </div>
          ) : this.state.tab_3 === "EU" ? (
            <div>
              <div className="vat_eu">
                <p className="eu_vat_para">
                  Let's setup VAT/Tax for customers in EU.
                </p>
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Do you charge VAT to EU customers if they provide you a
                    valid VAT number?
                  </p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charge_vat_number_eu"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charge_vat_number_eu}
                  />
                </div>

                {this.state.charge_vat_number_eu === true && (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>How do you charge VAT?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charge_vat_billing_address_eu_1"
                      options={[
                        {
                          id: true,
                          name: "Based on customer's billing address",
                        },
                        {
                          id: false,
                          name: "Based on your country of location",
                        },
                      ]}
                      value={this.state.charge_vat_billing_address_eu_1}
                    />
                  </div>
                )}
                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_vat_billing_address_eu_1 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">


                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            array={arrayofbillingaddrss}
                            data={this.state.vatforeu}
                            selectCountry={(props) =>
                              this.selectCountryBillingAddressforeu(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={eubillingaddress}
                          editHandler={(value) =>
                            this.EditHandlerforeu_1(value)
                          }
                        />
                      </div>
                    </div>
                  ) : null
                ) : null}
                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_vat_billing_address_eu_1 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default VAT rate on OaMetrix. You can
                            override default VAT rate to apply your own rate.
                          </p>
                        </div>
                      </div>
                      <div className="vat-question-panel ">
                        <div className="col-12">
                          <div className="card m-b-20">
                            <VatTable
                              data={selfCountryforEuRates_1}
                              editHandler={(value) =>
                                this.EditHandler_eu_1(value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}

                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_vat_billing_address_eu_1 === true ||
                    this.state.charge_vat_billing_address_eu_1 === false ? (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>
                          Do you charge VAT to EU customers if they do not provide
                          you a valid VAT number?
                      </p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="charge_no_vat_number_eu_1"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.charge_no_vat_number_eu_1}
                        />
                      </div>
                    ) : null
                ) : null}

                {this.state.charge_vat_number_eu === true ? (
                  this.state.charge_no_vat_number_eu_1 === true ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>How do you charge VAT?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_billing_address_eu_2"
                        options={[
                          {
                            id: true,
                            name: "Based on customer's billing address",
                          },
                          {
                            id: false,
                            name: "Based on your country of location",
                          },
                        ]}
                        value={this.state.charge_no_vat_billing_address_eu_2}
                      />
                    </div>
                  ) : null
                ) : null}
                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_no_vat_number_eu_1 !== false ? (
                    this.state.charge_no_vat_billing_address_eu_2 === true ? (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              {" "}
                              Here is the default VAT rate on OaMetrix. You can
                              override default VAT rate to apply your own rate.
                            </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={arrayofbillingaddrss2}
                              data={this.state.vatforeu}
                              selectCountry={(props) =>
                                this.selectCountryBillingAddressforeu2(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={eubillingaddress2}
                            editHandler={(value) =>
                              this.EditHandlerforeu_2(value)
                            }
                          />
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}

                {this.state.charge_vat_number_eu !== false ? (
                  this.state.charge_no_vat_number_eu_1 !== false ? (
                    this.state.charge_no_vat_billing_address_eu_2 === false ? (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default VAT rate on OaMetrix. You can
                              override default VAT rate to apply your own rate.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={selfCountryforEuNoRates_2}
                            editHandler={(value) =>
                              this.EditHandler_eu_2(value)
                            }
                          />
                        </div>
                      </div>
                    ) : null
                  ) : null
                ) : null}

                {this.state.charge_vat_number_eu === false && (
                  <div>
                    <div className="vat-question-panel   d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge VAT to EU customers if they do not provide
                        you a valid VAT number?
                      </p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_number_eu_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_number_eu_2}
                      />
                    </div>
                    {this.state.charge_no_vat_number_eu_2 === true ? (
                      <div className="vat-question-panel d-flex justify-content-between align-items-center">
                        <p>How do you charge VAT?</p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="charge_no_vat_billing_address_eu_3"
                          options={[
                            {
                              id: true,
                              name: "Based on customer's billing address",
                            },
                            {
                              id: false,
                              name: "Based on your country of location",
                            },
                          ]}
                          value={this.state.charge_no_vat_billing_address_eu_3}
                        />
                      </div>
                    ) : null}

                    {this.state.charge_no_vat_number_eu_2 === true
                      ? this.state.charge_no_vat_billing_address_eu_3 ===
                      true && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                {" "}
                                  Here is the default VAT rate on OaMetrix. You
                                  can override default VAT rate to apply your
                                  own rate.
                                </p>
                            </div>
                            <div className="country-list">
                              <MultiSelectCheckBox
                                field="STATES"
                                array={arrayofbillingaddrss3}
                                data={this.state.vatforeu}
                                selectCountry={(props) =>
                                  this.selectCountryBillingAddressforeu3(
                                    props
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="vat-question-panel">
                            <div className="col-12">
                              <div className="card m-b-20">
                                <VatTable
                                  data={eubillingaddress3}
                                  editHandler={(value) =>
                                    this.EditHandlerforeu_3(value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : null}
                    {this.state.charge_no_vat_number_eu_2 === true
                      ? this.state.charge_no_vat_billing_address_eu_3 ===
                      false && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Here is the default VAT rate on OaMetrix. You
                                can override default VAT rate to apply your
                                own rate.
                                </p>
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              data={selfCountryforEuNoRates_2}
                              editHandler={(value) =>
                                this.EditHandler_eu_2(value)
                              }
                            />

                            {/* <VatTable data={selfCountryforEuNoRates_2} editHandler={(value)=>this.EditHandler_eu_2(value)} /> */}
                          </div>
                        </div>
                      )
                      : null}
                  </div>
                )}
              </div>
            </div>
          ) : this.state.tab_3 === "CANADA" ? (
            "ca"
          ) :  <div className="vat-detail-form canada">
          <div>


            <div className="vat-question-panel  d-flex justify-content-between align-items-center">
              <p>
                Do you charge Tax/GST/HST/PST to customers in Canada if they
                provide a valid VAT/GST/Business Tax Number?
              </p>

              <RadioButtonGroup
                onChange={this.onChangeHandleRadioBox}
                field="charge_vat_number_canada"
                options={[
                  { id: true, name: "Yes" },
                  { id: false, name: "No" },
                ]}
                value={this.state.charge_vat_number_canada}
              />
            </div>
            {this.state.charge_vat_number_canada === true && (
              <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                <p>Do you charge Tax/GST/HST/PST based on province?</p>
                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="proviencebasedoncharge"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.proviencebasedoncharge}
                />
              </div>
            )}
            {this.state.charge_vat_number_canada !== false
              ? this.state.proviencebasedoncharge === true && (
                <div className="vat-country">
                  <div className="vat-country-details">
                    <div className="vat-info">
                      <p>
                        Here is the default Tax/GST/HST/PST on OaMetrix.
                        You can override default VAT rate to apply your
                        own rate.
                        </p>
                    </div>

                    <div className="country-list">
                      <MultiSelectCheckBox
                        field="STATES"
                        array={SelectShowCanadaProvience1}
                        data={this.state.stateListCA}
                        selectCountry={(props) =>
                          this.SelectCheckBoxBasedOnProvince_1(props)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 vat-margin">
                    {/* <VatTable data={ProvienceCA} countryType="CA" /> */}

                    <VatTable
                      data={canadaprovincedata_1}
                      editHandler={(value) =>
                        this.EditHandler_for_ca_1(value)
                      }
                      countryType="CA"
                    />
                  </div>
                </div>
              )
              : null}
            {this.state.charge_vat_number_canada !== false
              ? this.state.proviencebasedoncharge === false && (
                <div className="vat-country">
                  <div className="vat-country-details">
                    <div className="vat-info">
                      <p>
                        Here is the default Tax/GST/HST/PST on OaMetrix.
                        You can override default VAT rate to apply your
                        own rate.
                        </p>
                    </div>
                    <div className="country-list">
                      <MultiSelectCheckBox
                        field="STATES"
                        data={this.state.vatforca}
                        array={SelectCheckBoxShowCanadaCountry1}
                        selectCountry={(props) =>
                          this.SelectCheckBoxBasedCanadaCountry_1(props)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 vat-margin">
                    <VatTable
                      data={canadadatacountrytable_1}
                      editHandler={(value) =>
                        this.EditHandler_country_ca_1(value)
                      }
                      countryType="CA"
                    />
                  </div>
                </div>
              )
              : null}

            {this.state.charge_vat_number_canada !== false ? (
              this.state.proviencebasedoncharge === true ? (
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Do you charge Tax/GST if customer’s has no valid Tax/GST
                    number?
                  </p>
                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charge_no_vat_canada_1"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charge_no_vat_canada_1}
                  />
                </div>
              ) : null
            ) : null}

            {this.state.charge_vat_number_canada !== false ? (
              this.state.proviencebasedoncharge !== false ? (
                // this.state.charge_vat_states_usa !== false ? (
                this.state.charge_no_vat_canada_1 === true ? (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>Do you charge Tax/GST/HST/PST based on province?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="provience_basedon_vat_no_charge_1"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.provience_basedon_vat_no_charge_1}
                    />
                  </div>
                ) : null
              ) : // ) : null
                null
            ) : null}

            {this.state.charge_vat_number_canada !== false
              ? this.state.proviencebasedoncharge !== false
                ? this.state.charge_no_vat_canada_1 !== false
                  ? this.state.provience_basedon_vat_no_charge_1 ===
                  true && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on
                            OaMetrix. You can override default GST/VAT/Tax
                            rate to apply correct one.
                            </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            array={SelectShowCanadaProvience2}
                            field="STATES"
                            data={canadaState}
                            selectCountry={(props) =>
                              this.SelectCheckBoxBasedOnProvince_2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          editHandler={(value) =>
                            this.EditHandler_for_ca_2(value)
                          }
                          data={canadaprovincedata_2}
                          countryType="CA"
                        />
                      </div>
                    </div>
                  )
                  : null
                : null
              : null}
            {this.state.charge_vat_number_canada !== false
              ? this.state.proviencebasedoncharge !== false
                ? this.state.charge_no_vat_canada_1 !== false
                  ? this.state.provience_basedon_vat_no_charge_1 ===
                  false && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default Tax/GST/HST/PST on
                            OaMetrix. You can override default VAT rate to
                            apply your own rate.
                            </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            array={SelectCheckBoxShowCanadaCountr2}
                            data={this.state.vatforca}
                            selectCountry={(props) =>
                              this.SelectCheckBoxBasedCanadaCountry_2(
                                props
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          editHandler={(value) =>
                            this.EditHandler_country_ca_2(value)
                          }
                          data={canadadataforcountry2}
                          countryType="CA"
                        />
                      </div>
                    </div>
                  )
                  : null
                : null
              : null}

            {/* IF PROVIENC FALSE  */}

            {this.state.charge_vat_number_canada !== false ? (
              this.state.proviencebasedoncharge !== true ? (
                this.state.proviencebasedoncharge === false ? (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>
                      Do you charge Tax/GST if customer’s has no valid
                      Tax/GST number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charge_no_vat_canada_2"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charge_no_vat_canada_2}
                    />
                  </div>
                ) : null
              ) : null
            ) : null}

            {this.state.charge_vat_number_canada !== false ? (
              this.state.proviencebasedoncharge !== true ? (
                // this.state.charge_vat_states_usa !== false ? (
                this.state.charge_no_vat_canada_2 === true ? (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>Do you charge Tax/GST/HST/PST based on province?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="provience_basedon_vat_no_charge_2"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.provience_basedon_vat_no_charge_2}
                    />
                  </div>
                ) : null
              ) : // ) : null
                null
            ) : null}

            {this.state.charge_vat_number_canada !== false
              ? this.state.proviencebasedoncharge !== true
                ? this.state.charge_no_vat_canada_2 !== false
                  ? this.state.provience_basedon_vat_no_charge_2 ===
                  true && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on
                            OaMetrix. You can override default GST/VAT/Tax
                            rate to apply correct one.
                            </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            array={SelectShowCanadaProvience3}
                            data={canadaState}
                            selectCountry={(props) =>
                              this.SelectCheckBoxBasedOnProvince_3(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          editHandler={(value) =>
                            this.EditHandler_for_ca_3(value)
                          }
                          data={canadaprovincedata_3}
                          countryType="CA"
                        />
                      </div>
                    </div>
                  )
                  : null
                : null
              : null}
            {this.state.charge_vat_number_canada !== false
              ? this.state.proviencebasedoncharge !== true
                ? this.state.charge_no_vat_canada_2 !== false
                  ? this.state.provience_basedon_vat_no_charge_2 ===
                  false && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default Tax/GST/HST/PST on
                            OaMetrix. You can override default VAT rate to
                            apply your own rate.
                            </p>
                        </div>

                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            array={SelectCheckBoxShowCanadaCountry3}
                            data={this.state.vatforca}
                            selectCountry={(props) =>
                              this.SelectCheckBoxBasedCanadaCountry_3(
                                props
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          editHandler={(value) =>
                            this.EditHandler_country_ca_3(value)
                          }
                          data={canadadataforcountry3}
                          countryType="CA"
                        />
                      </div>
                    </div>
                  )
                  : null
                : null
              : null}

            {/*comment*/}
            {this.state.charge_vat_number_canada === false && (
              <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                <p>
                  Do you charge Tax/GST if customer’s has no valid Tax/GST
                  number?
                </p>
                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="charge_no_vat_canada_3"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.charge_no_vat_canada_3}
                />
              </div>
            )}
            {this.state.charge_vat_number_canada !== true
              ? this.state.charge_no_vat_canada_3 === true && (
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>Do you charge Tax/GST based on province?</p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="provience_basedon_vat_no_charge_3"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.provience_basedon_vat_no_charge_3}
                  />
                </div>
              )
              : null}

            {this.state.charge_vat_number_canada !== true ? (
              this.state.charge_no_vat_canada_3 === true &&
                this.state.provience_basedon_vat_no_charge_3 === true ? (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default Tax/GST/HST/PST on OaMetrix. You
                          can override default VAT rate to apply your own
                          rate.
                      </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={canadaState}
                          array={SelectShowCanadaProvience4}
                          selectCountry={
                            (props) =>
                              this.SelectCheckBoxBasedOnProvince_4(props)

                            // this.SelectCheckBoxBasedOnProvince_4(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={canadaprovincedata_4}
                        editHandler={(value) =>
                          this.EditHandler_for_ca_4(value)
                        }
                        countryType="CA"
                      />

                      {/* <VatTable data={Canadaprovincedata_4} countryType="CA" /> */}
                    </div>
                  </div>
                ) : null
            ) : null}
            {this.state.charge_vat_number_canada !== true ? (
              this.state.charge_no_vat_canada_3 === true &&
                this.state.provience_basedon_vat_no_charge_3 === false ? (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default Tax/GST/HST/PST on OaMetrix. You
                          can override default VAT rate to apply your own
                          rate.
                      </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          array={selecteddatabasedoncanadacountry_4}
                          data={this.state.vatforca}
                          selectCountry={(props) =>
                            this.SelectCheckBoxBasedCanadaCountry_4(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={canadadataforcountry4}
                        editHandler={(value) =>
                          this.EditHandler_country_ca_4(value)
                        }
                        countryType="CA"
                      />
                    </div>
                  </div>
                ) : null
            ) : null}
          </div>
        </div>,
      },
      {
        title: this.state.tab_4 || "Canada",
        content:
          this.state.tab_4 === "Canada" ? (
            <div className="vat-detail-form canada">
              <div>


                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Do you charge Tax/GST/HST/PST to customers in Canada if they
                    provide a valid VAT/GST/Business Tax Number?
                  </p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charge_vat_number_canada"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charge_vat_number_canada}
                  />
                </div>
                {this.state.charge_vat_number_canada === true && (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>Do you charge Tax/GST/HST/PST based on province?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="proviencebasedoncharge"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.proviencebasedoncharge}
                    />
                  </div>
                )}
                {this.state.charge_vat_number_canada !== false
                  ? this.state.proviencebasedoncharge === true && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default Tax/GST/HST/PST on OaMetrix.
                            You can override default VAT rate to apply your
                            own rate.
                            </p>
                        </div>

                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            array={SelectShowCanadaProvience1}
                            data={this.state.stateListCA}
                            selectCountry={(props) =>
                              this.SelectCheckBoxBasedOnProvince_1(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        {/* <VatTable data={ProvienceCA} countryType="CA" /> */}

                        <VatTable
                          data={canadaprovincedata_1}
                          editHandler={(value) =>
                            this.EditHandler_for_ca_1(value)
                          }
                          countryType="CA"
                        />
                      </div>
                    </div>
                  )
                  : null}
                {this.state.charge_vat_number_canada !== false
                  ? this.state.proviencebasedoncharge === false && (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default Tax/GST/HST/PST on OaMetrix.
                            You can override default VAT rate to apply your
                            own rate.
                            </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.state.vatforca}
                            array={SelectCheckBoxShowCanadaCountry1}
                            selectCountry={(props) =>
                              this.SelectCheckBoxBasedCanadaCountry_1(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={canadadatacountrytable_1}
                          editHandler={(value) =>
                            this.EditHandler_country_ca_1(value)
                          }
                          countryType="CA"
                        />
                      </div>
                    </div>
                  )
                  : null}

                {this.state.charge_vat_number_canada !== false ? (
                  this.state.proviencebasedoncharge === true ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge Tax/GST if customer’s has no valid Tax/GST
                        number?
                      </p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_canada_1"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_canada_1}
                      />
                    </div>
                  ) : null
                ) : null}

                {this.state.charge_vat_number_canada !== false ? (
                  this.state.proviencebasedoncharge !== false ? (
                    // this.state.charge_vat_states_usa !== false ? (
                    this.state.charge_no_vat_canada_1 === true ? (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>Do you charge Tax/GST/HST/PST based on province?</p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="provience_basedon_vat_no_charge_1"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.provience_basedon_vat_no_charge_1}
                        />
                      </div>
                    ) : null
                  ) : // ) : null
                    null
                ) : null}

                {this.state.charge_vat_number_canada !== false
                  ? this.state.proviencebasedoncharge !== false
                    ? this.state.charge_no_vat_canada_1 !== false
                      ? this.state.provience_basedon_vat_no_charge_1 ===
                      true && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Here is the default GST/VAT/Tax rate on
                                OaMetrix. You can override default GST/VAT/Tax
                                rate to apply correct one.
                                </p>
                            </div>
                            <div className="country-list">
                              <MultiSelectCheckBox
                                array={SelectShowCanadaProvience2}
                                field="STATES"
                                data={canadaState}
                                selectCountry={(props) =>
                                  this.SelectCheckBoxBasedOnProvince_2(props)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              editHandler={(value) =>
                                this.EditHandler_for_ca_2(value)
                              }
                              data={canadaprovincedata_2}
                              countryType="CA"
                            />
                          </div>
                        </div>
                      )
                      : null
                    : null
                  : null}
                {this.state.charge_vat_number_canada !== false
                  ? this.state.proviencebasedoncharge !== false
                    ? this.state.charge_no_vat_canada_1 !== false
                      ? this.state.provience_basedon_vat_no_charge_1 ===
                      false && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Here is the default Tax/GST/HST/PST on
                                OaMetrix. You can override default VAT rate to
                                apply your own rate.
                                </p>
                            </div>
                            <div className="country-list">
                              <MultiSelectCheckBox
                                field="STATES"
                                array={SelectCheckBoxShowCanadaCountr2}
                                data={this.state.vatforca}
                                selectCountry={(props) =>
                                  this.SelectCheckBoxBasedCanadaCountry_2(
                                    props
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              editHandler={(value) =>
                                this.EditHandler_country_ca_2(value)
                              }
                              data={canadadataforcountry2}
                              countryType="CA"
                            />
                          </div>
                        </div>
                      )
                      : null
                    : null
                  : null}

                {/* IF PROVIENC FALSE  */}

                {this.state.charge_vat_number_canada !== false ? (
                  this.state.proviencebasedoncharge !== true ? (
                    this.state.proviencebasedoncharge === false ? (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>
                          Do you charge Tax/GST if customer’s has no valid
                          Tax/GST number?
                        </p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="charge_no_vat_canada_2"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.charge_no_vat_canada_2}
                        />
                      </div>
                    ) : null
                  ) : null
                ) : null}

                {this.state.charge_vat_number_canada !== false ? (
                  this.state.proviencebasedoncharge !== true ? (
                    // this.state.charge_vat_states_usa !== false ? (
                    this.state.charge_no_vat_canada_2 === true ? (
                      <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                        <p>Do you charge Tax/GST/HST/PST based on province?</p>
                        <RadioButtonGroup
                          onChange={this.onChangeHandleRadioBox}
                          field="provience_basedon_vat_no_charge_2"
                          options={[
                            { id: true, name: "Yes" },
                            { id: false, name: "No" },
                          ]}
                          value={this.state.provience_basedon_vat_no_charge_2}
                        />
                      </div>
                    ) : null
                  ) : // ) : null
                    null
                ) : null}

                {this.state.charge_vat_number_canada !== false
                  ? this.state.proviencebasedoncharge !== true
                    ? this.state.charge_no_vat_canada_2 !== false
                      ? this.state.provience_basedon_vat_no_charge_2 ===
                      true && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Here is the default GST/VAT/Tax rate on
                                OaMetrix. You can override default GST/VAT/Tax
                                rate to apply correct one.
                                </p>
                            </div>
                            <div className="country-list">
                              <MultiSelectCheckBox
                                field="STATES"
                                array={SelectShowCanadaProvience3}
                                data={canadaState}
                                selectCountry={(props) =>
                                  this.SelectCheckBoxBasedOnProvince_3(props)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              editHandler={(value) =>
                                this.EditHandler_for_ca_3(value)
                              }
                              data={canadaprovincedata_3}
                              countryType="CA"
                            />
                          </div>
                        </div>
                      )
                      : null
                    : null
                  : null}
                {this.state.charge_vat_number_canada !== false
                  ? this.state.proviencebasedoncharge !== true
                    ? this.state.charge_no_vat_canada_2 !== false
                      ? this.state.provience_basedon_vat_no_charge_2 ===
                      false && (
                        <div className="vat-country">
                          <div className="vat-country-details">
                            <div className="vat-info">
                              <p>
                                Here is the default Tax/GST/HST/PST on
                                OaMetrix. You can override default VAT rate to
                                apply your own rate.
                                </p>
                            </div>

                            <div className="country-list">
                              <MultiSelectCheckBox
                                field="STATES"
                                array={SelectCheckBoxShowCanadaCountry3}
                                data={this.state.vatforca}
                                selectCountry={(props) =>
                                  this.SelectCheckBoxBasedCanadaCountry_3(
                                    props
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-12 vat-margin">
                            <VatTable
                              editHandler={(value) =>
                                this.EditHandler_country_ca_3(value)
                              }
                              data={canadadataforcountry3}
                              countryType="CA"
                            />
                          </div>
                        </div>
                      )
                      : null
                    : null
                  : null}

                {/*comment*/}
                {this.state.charge_vat_number_canada === false && (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>
                      Do you charge Tax/GST if customer’s has no valid Tax/GST
                      number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charge_no_vat_canada_3"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charge_no_vat_canada_3}
                    />
                  </div>
                )}
                {this.state.charge_vat_number_canada !== true
                  ? this.state.charge_no_vat_canada_3 === true && (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>Do you charge Tax/GST based on province?</p>

                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="provience_basedon_vat_no_charge_3"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.provience_basedon_vat_no_charge_3}
                      />
                    </div>
                  )
                  : null}

                {this.state.charge_vat_number_canada !== true ? (
                  this.state.charge_no_vat_canada_3 === true &&
                    this.state.provience_basedon_vat_no_charge_3 === true ? (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default Tax/GST/HST/PST on OaMetrix. You
                              can override default VAT rate to apply your own
                              rate.
                          </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              data={canadaState}
                              array={SelectShowCanadaProvience4}
                              selectCountry={
                                (props) =>
                                  this.SelectCheckBoxBasedOnProvince_4(props)

                                // this.SelectCheckBoxBasedOnProvince_4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={canadaprovincedata_4}
                            editHandler={(value) =>
                              this.EditHandler_for_ca_4(value)
                            }
                            countryType="CA"
                          />

                          {/* <VatTable data={Canadaprovincedata_4} countryType="CA" /> */}
                        </div>
                      </div>
                    ) : null
                ) : null}
                {this.state.charge_vat_number_canada !== true ? (
                  this.state.charge_no_vat_canada_3 === true &&
                    this.state.provience_basedon_vat_no_charge_3 === false ? (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default Tax/GST/HST/PST on OaMetrix. You
                              can override default VAT rate to apply your own
                              rate.
                          </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={selecteddatabasedoncanadacountry_4}
                              data={this.state.vatforca}
                              selectCountry={(props) =>
                                this.SelectCheckBoxBasedCanadaCountry_4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={canadadataforcountry4}
                            editHandler={(value) =>
                              this.EditHandler_country_ca_4(value)
                            }
                            countryType="CA"
                          />
                        </div>
                      </div>
                    ) : null
                ) : null}
              </div>
            </div>
          ) : this.state.tab_4 === "United States" ? (
            <div className="vat-detail-form">
              <p className="eu_vat_para">
                Let's setup VAT/Tax for customers in USA.
              </p>
              <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                <p>
                  Do you charge Tax/GST to USA customers if they provide a valid
                  VAT/GST/Business Tax Number?
                </p>
                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="charge_vat_number_usa"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.charge_vat_number_usa}
                />
              </div>
              {this.state.charge_vat_number_usa === true && (
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>Do you charge Tax/GST based on the States?</p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charge_vat_states_usa_1"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charge_vat_states_usa_1}
                  />
                </div>
              )}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 === true && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.state.stateList}
                          array={array}
                          selectCountry={(props) =>
                            this.selectCountryBasedonStates(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={basedonStates}
                        editHandler={(value) =>
                          this.EditHandler_for_us_1(value)
                        }
                        countryType="USA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 === false && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.state.vatforus}
                          array={array2}
                          selectCountry={(props) =>
                            this.selectCountryBasedonCountry(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={basedonCountry}
                        editHandler={(value) =>
                          this.EditHandler_country_us_1(value)
                        }
                        countryType="USA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 === true ? (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>
                      Do you charge Tax/GST if customer’s has no valid Tax/GST
                      number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charage_no_vat_usa_1"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charage_no_vat_usa_1}
                    />
                  </div>
                ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== false ? (
                  // this.state.charge_vat_states_usa_1 !== false ? (
                  this.state.charage_no_vat_usa_1 === true ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>Do you charge Tax/GST based on the States?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_states_usa_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_states_usa_2}
                      />
                    </div>
                  ) : null
                ) : // ) : null
                  null
              ) : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== false
                  ? this.state.charage_no_vat_usa_1 !== false
                    ? this.state.charge_no_vat_states_usa_2 === true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override de3fault GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={SelectStates3}
                              data={
                                this.props.state_list &&
                                this.props.state_list.results
                              }
                              selectCountry={(props) =>
                                this.selectCountryBasedonStates3(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonStates3}
                            editHandler={(value) =>
                              this.EditHandler_for_us_3(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== false
                  ? this.state.charage_no_vat_usa_1 !== false
                    ? this.state.charge_no_vat_states_usa_2 === false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.3434
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              array={SelectCountry3}
                              field="STATES"
                              data={this.state.vatforus}
                              selectCountry={(props) =>
                                this.selectCountryBasedonCountry3(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonCountry3}
                            editHandler={(value) =>
                              this.EditHandler_country_us_3(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {}{" "}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== true ? (
                  this.state.charge_vat_states_usa_1 === false ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>
                        Do you charge Tax/GST if customer’s has no valid Tax/GST
                        number?
                      </p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_usa_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_usa_2}
                      />
                    </div>
                  ) : null
                ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== false ? (
                this.state.charge_vat_states_usa_1 !== true ? (
                  // this.state.charge_vat_states_usa_1 !== false ? (
                  this.state.charge_no_vat_usa_2 === true ? (
                    <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                      <p>Do you charge Tax/GST based on the States?</p>
                      <RadioButtonGroup
                        onChange={this.onChangeHandleRadioBox}
                        field="charge_no_vat_states_usa_3"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.state.charge_no_vat_states_usa_3}
                      />
                    </div>
                  ) : null
                ) : // ) : null
                  null
              ) : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== true
                  ? this.state.charge_no_vat_usa_2 !== false
                    ? this.state.charge_no_vat_states_usa_3 === true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              array={SelectStates4}
                              data={
                                this.props.state_list &&
                                this.props.state_list.results
                              }
                              selectCountry={(props) =>
                                this.selectCountryBasedonStates4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonStates4}
                            editHandler={(value) =>
                              this.EditHandler_for_us_4(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa !== false
                ? this.state.charge_vat_states_usa_1 !== true
                  ? this.state.charge_no_vat_usa_2 !== false
                    ? this.state.charge_no_vat_states_usa_3 === false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              array={SelectCountry4}
                              field="STATES"
                              data={this.state.vatforus}
                              selectCountry={(props) =>
                                this.selectCountryBasedonCountry4(props)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={basedonCountry4}
                            editHandler={(value) =>
                              this.EditHandler_country_us_4(value)
                            }
                            countryType="USA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.state.charge_vat_number_usa === false && (
                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Do you charge Tax/GST if customer’s has no valid Tax/GST
                    number?
                  </p>
                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="charage_no_vat_usa_3"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.charage_no_vat_usa_3}
                  />
                </div>
              )}
              {this.state.charge_vat_number_usa !== true
                ? this.state.charage_no_vat_usa_3 === true && (
                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>Do you charge Tax/GST based on the States?</p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="charge_no_vat_states_usa_4"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.charge_no_vat_states_usa_4}
                    />
                  </div>
                )
                : null}
              {this.state.charge_vat_number_usa !== true ? (
                this.state.charage_no_vat_usa_3 === true &&
                  this.state.charge_no_vat_states_usa_4 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.state.stateList}
                            array={arrayStates2}
                            selectCountry={(props) =>
                              this.selectCountryBasedonStates2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={basedonStates2}
                          editHandler={(value) =>
                            this.EditHandler_for_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
              {this.state.charge_vat_number_usa !== true ? (
                this.state.charage_no_vat_usa_3 === true &&
                  this.state.charge_no_vat_states_usa_4 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix. You
                            can override default GST/VAT/Tax rate to apply your
                            own rate.
                        </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.state.vatforus}
                            array={arraycountry2}
                            selectCountry={(props) =>
                              this.selectCountryBasedonCountry2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={basedonCountry2}
                          editHandler={(value) =>
                            this.EditHandler_country_us_2(value)
                          }
                          countryType="USA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
            </div>
          ) :   <div className="vat-detail-form">
          <p className="eu_vat_para">
            Let's setup VAT/tax for your rest of the world countries
          </p>

          <div className="vat-question-panel  d-flex justify-content-between align-items-center">
            <p className="vat_charge">
              Is there any other countries that you waive the VAT or Sales Tax
              when they provide a valid VAT/Tax number?
            </p>
            <div className="vat_charge">
              <RadioButtonGroup
                onChange={this.onChangeHandleRadioBox}
                field="waive_countries_charge"
                options={[
                  { id: true, name: "Yes" },
                  { id: false, name: "No" },
                ]}
                value={this.state.waive_countries_charge}
              />
            </div>
          </div>

          {this.state.waive_countries_charge === true && (
            <div>
              <div className="vat-country">
                <div className="vat-country-details">
                  <div className="vat-info">
                    <p>Select countries from below dropdown.</p>
                  </div>
                  <div className="country-list">
                    <MultiSelectCheckBox
                      array={waiverscountrieslist}
                      field="STATES"
                      data={this.state.other_countries}
                      selectCountry={(props) =>
                        this.selectCountrywaiveCountry(props)
                      }
                    />
                  </div>
                </div>
                <div className="col-12 vat-margin">
                  <VatTable
                    data={waiverscountries}
                    editHandler={(value) => this.editHandler_waiver_1(value)}
                    countryType="REST"
                  />
                </div>
              </div>

              <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                <p>
                  Is there any other countries that you would like to add VAT
                  or Sales Tax?
                </p>

                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="vat_tax_other_countries_charge"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.vat_tax_other_countries_charge}
                />
              </div>
            </div>
          )}

          {this.state.waive_countries_charge === false && (
            <div className="vat-question-panel  d-flex justify-content-between align-items-center">
              <p>
                Is there any other countries that you would like to add VAT or
                Sales Tax?
              </p>

              <RadioButtonGroup
                onChange={this.onChangeHandleRadioBox}
                field="vat_tax_other_countries_charge2"
                options={[
                  { id: true, name: "Yes" },
                  { id: false, name: "No" },
                ]}
                value={this.state.vat_tax_other_countries_charge2}
              />
            </div>
          )}
          {this.state.waive_countries_charge !== true
            ? this.state.vat_tax_other_countries_charge2 === true && (
              <div>
                <div className="vat-country">
                  <div className="vat-country-details">
                    <div className="vat-info">
                      <p>
                        Here is the default GST/VAT/Tax rate on OaMetrix.
                        You can override default GST/VAT/Tax rate to apply
                        correct one.
                        </p>
                    </div>
                    <div className="country-list">
                      <MultiSelectCheckBox
                        array={SalesOrVatTaxes}
                        field="STATES"
                        data={this.state.other_countries}
                        selectCountry={(props) =>
                          this.selectselfCountryRatesDefaultorTaxesCountry2(
                            props
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 vat-margin">
                    <VatTable
                      data={other_countries_vat_tax_2}
                      editHandler={(value) =>
                        this.editHandler_rest_2(value)
                      }
                    />
                  </div>
                </div>

                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Is there any countries that you Charge VAT based on
                    customer's billing address?
                    </p>
                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="rest_billing_address"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.rest_billing_address}
                  />
                </div>
              </div>
            )
            : null}

          {this.state.waive_countries_charge !== false
            ? this.state.vat_tax_other_countries_charge === true && (
              <div>
                <div className="vat-country">
                  <div className="vat-country-details">
                    <div className="vat-info">
                      <p>
                        Here is the default GST/VAT/Tax rate on OaMetrix.
                        You can override default GST/VAT/Tax rate to apply
                        correct one.
                        </p>
                    </div>
                    <div className="country-list">
                      <MultiSelectCheckBox
                        field="STATES"
                        array={SalesOrVatTaxes2}
                        data={this.state.other_countries}
                        selectCountry={(props) =>
                          this.selectselfCountryRatesDefaultorTaxesCountry(
                            props
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 vat-margin">
                    <VatTable
                      data={other_countries_vat_tax_1}
                      editHandler={(value) =>
                        this.editHandler_rest_1(value)
                      }
                    />
                  </div>
                </div>

                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Is there any countries that you Charge VAT based on
                    customer's billing address?
                    </p>
                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="rest_billing_address2"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.rest_billing_address2}
                  />
                </div>
              </div>
            )
            : null}
          {this.state.waive_countries_charge !== false ? (
            this.state.vat_tax_other_countries_charge !== false ? (
              this.state.rest_billing_address2 === true ? (
                <div>
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                        </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          array={vat_charge_billing_tax2}
                          field="STATES"
                          data={this.state.other_countries}
                          selectCountry={(props) =>
                            this.selectCountrybillingaddressCountry(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={billingaddres}
                        editHandler={(value) =>
                          this.editHandler_rest_3(value)
                        }
                        countryType="RESTOFTHEWORLD"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            ) : null
          ) : null}
          {this.state.waive_countries_charge !== true ? (
            this.state.vat_tax_other_countries_charge2 !== false ? (
              this.state.rest_billing_address === true ? (
                <div>
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                        </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          array={vat_charge_billing_tax}
                          field="STATES"
                          data={this.state.other_countries}
                          selectCountry={(props) =>
                            this.selectCountrybillingaddressCountry2(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <div className="card m-b-20">
                        <VatTable
                          editHandler={(value) =>
                            this.editHandler_rest_4(value)
                          }
                          data={billingaddres2}
                          countryType="RESTOFTHEWORLD"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null
          ) : null}
        </div>,
      },
      {
        title: this.state.tab_5 || "Rest of World",
        content: (
          <div className="vat-detail-form">
            <p className="eu_vat_para">
              Let's setup VAT/tax for your rest of the world countries
            </p>

            <div className="vat-question-panel  d-flex justify-content-between align-items-center">
              <p className="vat_charge">
                Is there any other countries that you waive the VAT or Sales Tax
                when they provide a valid VAT/Tax number?
              </p>
              <div className="vat_charge">
                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="waive_countries_charge"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.waive_countries_charge}
                />
              </div>
            </div>

            {this.state.waive_countries_charge === true && (
              <div>
                <div className="vat-country">
                  <div className="vat-country-details">
                    <div className="vat-info">
                      <p>Select countries from below dropdown.</p>
                    </div>
                    <div className="country-list">
                      <MultiSelectCheckBox
                        array={waiverscountrieslist}
                        field="STATES"
                        data={this.state.other_countries}
                        selectCountry={(props) =>
                          this.selectCountrywaiveCountry(props)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 vat-margin">
                    <VatTable
                      data={waiverscountries}
                      editHandler={(value) => this.editHandler_waiver_1(value)}
                      countryType="REST"
                    />
                  </div>
                </div>

                <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                  <p>
                    Is there any other countries that you would like to add VAT
                    or Sales Tax?
                  </p>

                  <RadioButtonGroup
                    onChange={this.onChangeHandleRadioBox}
                    field="vat_tax_other_countries_charge"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.state.vat_tax_other_countries_charge}
                  />
                </div>
              </div>
            )}

            {this.state.waive_countries_charge === false && (
              <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                <p>
                  Is there any other countries that you would like to add VAT or
                  Sales Tax?
                </p>

                <RadioButtonGroup
                  onChange={this.onChangeHandleRadioBox}
                  field="vat_tax_other_countries_charge2"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.state.vat_tax_other_countries_charge2}
                />
              </div>
            )}
            {this.state.waive_countries_charge !== true
              ? this.state.vat_tax_other_countries_charge2 === true && (
                <div>
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          array={SalesOrVatTaxes}
                          field="STATES"
                          data={this.state.other_countries}
                          selectCountry={(props) =>
                            this.selectselfCountryRatesDefaultorTaxesCountry2(
                              props
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={other_countries_vat_tax_2}
                        editHandler={(value) =>
                          this.editHandler_rest_2(value)
                        }
                      />
                    </div>
                  </div>

                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>
                      Is there any countries that you Charge VAT based on
                      customer's billing address?
                      </p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="rest_billing_address"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.rest_billing_address}
                    />
                  </div>
                </div>
              )
              : null}

            {this.state.waive_countries_charge !== false
              ? this.state.vat_tax_other_countries_charge === true && (
                <div>
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default GST/VAT/Tax rate on OaMetrix.
                          You can override default GST/VAT/Tax rate to apply
                          correct one.
                          </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          array={SalesOrVatTaxes2}
                          data={this.state.other_countries}
                          selectCountry={(props) =>
                            this.selectselfCountryRatesDefaultorTaxesCountry(
                              props
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={other_countries_vat_tax_1}
                        editHandler={(value) =>
                          this.editHandler_rest_1(value)
                        }
                      />
                    </div>
                  </div>

                  <div className="vat-question-panel  d-flex justify-content-between align-items-center">
                    <p>
                      Is there any countries that you Charge VAT based on
                      customer's billing address?
                      </p>
                    <RadioButtonGroup
                      onChange={this.onChangeHandleRadioBox}
                      field="rest_billing_address2"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.state.rest_billing_address2}
                    />
                  </div>
                </div>
              )
              : null}
            {this.state.waive_countries_charge !== false ? (
              this.state.vat_tax_other_countries_charge !== false ? (
                this.state.rest_billing_address2 === true ? (
                  <div>
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix.
                            You can override default GST/VAT/Tax rate to apply
                            correct one.
                          </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            array={vat_charge_billing_tax2}
                            field="STATES"
                            data={this.state.other_countries}
                            selectCountry={(props) =>
                              this.selectCountrybillingaddressCountry(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={billingaddres}
                          editHandler={(value) =>
                            this.editHandler_rest_3(value)
                          }
                          countryType="RESTOFTHEWORLD"
                        />
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}
            {this.state.waive_countries_charge !== true ? (
              this.state.vat_tax_other_countries_charge2 !== false ? (
                this.state.rest_billing_address === true ? (
                  <div>
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default GST/VAT/Tax rate on OaMetrix.
                            You can override default GST/VAT/Tax rate to apply
                            correct one.
                          </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            array={vat_charge_billing_tax}
                            field="STATES"
                            data={this.state.other_countries}
                            selectCountry={(props) =>
                              this.selectCountrybillingaddressCountry2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <div className="card m-b-20">
                          <VatTable
                            editHandler={(value) =>
                              this.editHandler_rest_4(value)
                            }
                            data={billingaddres2}
                            countryType="RESTOFTHEWORLD"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}
          </div>
        ),
      },
    ];

    const { current } = this.state;

    return (
      <HubContent>
                        {this.state.success  && (
            <PopupBox
              NoText="Close"
              msg="You have successfully Updated VAT."
              onCancel={this.redirectLink}
            />
          )}
        <div className="row">
          <div className="col-lg-12 vat">
            <div className="card m-b-20 settings-card vat-card">
              <div className="card-body  profile">
                <div className="vat-tax-button">
                  <Buttons
                    type="danger"
                    name="danger"
                    text="X"
                    onClick={() => this.props.history.push("/settings/vat-configuration")}
                  />
                </div>
                <Steps current={current}>

                  {this.state.apc_including_vat === true
                    ? steps
                      && steps
                      .slice(0, 2)
                      && steps
                      .slice(0, 2)
                      .map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))        
                    :(this.state.tab_1 ==="Canada" || this.state.tab_1 ==="United States") ? steps && steps.slice(0,5) &&steps.slice(0,5).map((item) => (
                      <Step key={item.title} title={item.title} />
                    )):steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  
                </Steps>
                <div id="steps-div" className="steps-content">
                  {steps[current].content}
                </div>
                <div className="steps-action">
                {this.state.apc_including_vat !== true
                    ? current > 0 && (
                      <Button
                        style={{ marginRight: 8 }}
                        onClick={this.prev}
                      >
                        Previous
                      </Button>
                    )
                    : null}
                  {this.state.apc_including_vat === true
                    ? current <= 0 && (
                      <Button type="primary" onClick={ this.next}>
                        Next
                      </Button>
                    )
                    :  this.state.tab_1 !== undefined && (this.state.tab_1 ==="Canada" || this.state.tab_1 ==="United States")  ?  
                    current < steps.length - 2 && (
                      <Button type="primary" onClick={this.next}>
                        Next
                      </Button>
                    )
                   :  this.state.tab_1 !== undefined && current < steps.length - 1 && (
                    <Button type="primary" onClick={this.next}>
                      Next
                    </Button>
                  )}

                
                  {(this.state.tab_1 ==="Canada" || this.state.tab_1 ==="United States") ? current === steps.length - 2 && (
                    this.props.isLoading !== true ?
                        
                    <Button type="primary" onClick={this.onSubmit}>
                        Submit
                    </Button> :
                    <Button icon={<PoweroffOutlined />}
                    loading={true} type="secondary" onClick={this.onSubmit}>
                    Processing
                  </Button>): this.state.apc_including_vat === true
                    ? current === steps.length - 5 && (
                      this.props.isLoading !== true ?
                      <Button type="primary" onClick={this.onSubmit}>
                           Submit
                     </Button>
                     :
                     <Button icon={<PoweroffOutlined />} loading={true} type="secondary" onClick={this.onSubmit}>
                       Processing
                     </Button>
                      
                    )
                    : current === steps.length - 1 ? (
                      this.props.isLoading !== true ?
                      <Button type="primary" onClick={this.onSubmit}>
                           Submit
                     </Button>
                     :
                     <Button icon={<PoweroffOutlined />} loading={true} type="secondary" onClick={this.onSubmit}>
                       Processing
                     </Button>
                     
                   ):null}
                  
                </div>
                

     
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.app.countries,
  state_list: state.vat.us_state_list,
  state_list_ca: state.vat.ca_state_list,
  country_list: state.vat.country_list,
  isLoading: state.vat.isLoading,
  isVatLoading:state.vat.isVatLoading
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountry: () => dispatch(getAllCountry()),
  publisherVatCreateConfigure: (payload) =>
    dispatch(publisherVatCreateConfigure(payload)),
  getVatRatesPubVatConfig: (value) => dispatch(getVatRatesPubVatConfig(value)),
  getStatePubVatConfig: (value) => dispatch(getStatePubVatConfig(value)),
  getCAStatePubVatConfig: (value) => dispatch(getCAStatePubVatConfig(value)),
  publisherGetSingleVatCreateConfigure: (id) =>
    dispatch(publisherGetSingleVatCreateConfigure(id)),
  publisherDeleteVatCreateConfigure: (id) =>
    dispatch(publisherDeleteVatCreateConfigure(id)),
  publisherGetAllVatCreateConfigure: () =>
    dispatch(publisherGetAllVatCreateConfigure()),
  publisherUpdateVatCreateConfigure: (payload, id) =>
    dispatch(publisherUpdateVatCreateConfigure(payload, id)),
  createVatConfig: (payload) => dispatch(createVatConfig(payload)),
  createUpdateVatConfig: (payload) => dispatch(createUpdateVatConfig(payload)),
  getVatConfig: () => dispatch(getVatConfig()),
  getSingleVatConfig: (id) => dispatch(getSingleVatConfig(id)),
  DeleteVatConfig: (id) => dispatch(DeleteVatConfig(id)),
});

export const editvatform = connect(
  mapStateToProps,
  mapDispatchToProps
)(VatForm);