import React from "react";
import { AutoComplete, Popover,Spin } from "antd";
import {  LoadingOutlined } from '@ant-design/icons';
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  InputBox,
  OaDatePicker,
  SelectBox,
  PopupBox,
  Button
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import {
  allocatOrganisationOffsetFund,
  getAllCurrencyList,
  getInstitutionDataLists,
} from "../../../../actions";
import moment from "moment";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Offset fund</a>
    <br />

  </div>
);
class DB extends BaseComponent {
  state = {
    org_token: {
      offset_fund: {
        is_active: true,
        valid_from: null,
        valid_to: null,
      },
      is_active: true,
    },
    error_message: this.props.error_message,
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.org_token, value);
    });
  };
  onChangeHandlerOffsetFund = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.org_token.offset_fund, value);
    });

    if (value.hasOwnProperty("valid_from")) {
      this.setState((prevState) => {
        Object.assign(prevState.org_token.offset_fund, {
          valid_from: moment(value.valid_from).format(
            "YYYY-MM-DD"
          ) !== "Invalid date" ?moment(value.valid_from).format(
            "YYYY-MM-DD"
          ):null,
        });
      });
    }
    if (value.hasOwnProperty("valid_to")) {
      this.setState((prevState) => {
        Object.assign(prevState.org_token.offset_fund, {
          valid_to: moment(value.valid_to).format(
            "YYYY-MM-DD"
          ) !== "Invalid date" ? moment(value.valid_to).format(
            "YYYY-MM-DD"
          ):null,
        });
      });
    }
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      journalId: null,
      waitingMsg: null,
      deleteStatus: null,
    });
  };


  onSubmitHandler = async () => {
    const orgOffsetFund = {
      ...this.state.org_token,
      organisation: this.state.organisation,

    };

    if (
      !this.state.org_token.offset_fund.currency ||
      !this.state.org_token.offset_fund.amount ||
      !this.state.org_token.offset_fund.valid_from ||
      !this.state.org_token.offset_fund.valid_to
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!this.state.org_token.offset_fund.currency && (
              <p>Currency Type must be selected</p>
            )}
            {!this.state.org_token.offset_fund.amount && (
              <p>Amount must be selected</p>
            )}
            {!this.state.org_token.offset_fund.valid_from && (
              <p>Valid From Name must be filled</p>
            )}
            {!this.state.org_token.offset_fund.valid_to && (
              <p>Valid To Name must be filled</p>
            )}
          </div>
        ),
      });
    } else {
      this.setState({
        error_message: this.props.error_message,
      });

      await this.props.allocatOrganisationOffsetFund(orgOffsetFund);
      this.props.history.push("/token/offset-fund-list");
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
      error_message: false,
    });
  };
  componentDidMount() {
   this.apiDataList()
  }

  apiDataList =async () => {
    await this.props.getAllCurrencyList();


  }

  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState({
        organisation: item.id,
        orgsError: false

      });
    }
  };
  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  onChange = (value) => {
    let data = this.props.institution_list && this.props.institution_list.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        organisation: data && data.id,
        orgsError: false,
      });
    } else {
      this.setState({
        orgsError: true
      });
    }
  };

  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getInstitutionDataLists(value);
      this.setState({
        dataList: this.props.institution_list && this.props.institution_list.map(item => ({
          id: item.id,
          value: item.name,
        }))

      })
    }, 200);
  };

  render() {
   
    let errorHander = (
      <>
        {this.state.isEmpty || this.state.error_message ? (
          <PopupBox
            Title="Following field cannot be empty"
            className="col-10 col-md-6"
            msg={this.state.isEmpty || this.state.error_message}
            onCancel={this.cancelLoader}
          />
        ) : (
            <></>
          )}
      </>
    );
    return (
      <HubContent title="Offset fund">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <div className="vat-tax-button email d-flex justify-content-end">

                  <Popover placement="leftTop" content={content} trigger="click">
                    <Button
                      className="btn btn-secondary float-right "
                      type=""
                      name=""
                      text="Help"
                    >Help</Button>
                  </Popover>
                  <div className="danger float-right"
                  >
                    <button
                      type="danger"
                      className="btn btn-danger float-right "
                      onClick={() => this.props.history.goBack()}
                      style={{ marginLeft: 10 }}
                    >
                      X

</button>                  </div>

                </div>
       

                <div className="form-group row auto-complete-search m-t-20">
                    <div className="col-form-label col-md-2">
                      <label>Organisation<span style={{ color: "red", paddingLeft: "5px" }}>*</span></label>
                    </div>

                    <div className="col-md-10 search-compelete">
                      <div className="search-auto-complete">

                        <AutoComplete
                          defaultValue={this.state.organisation}
                          notFoundContent="Type your full Organisation name"
                          placeholder="Type your Organisation name and select from the drop down"

                          options={this.state.dataList}
                          style={{
                            width: "100%",
                          }}
                          onSelect={(val, option) => this.onSelect(val, option)}
                          onSearch={this.onSearch}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="search-auto-spin">
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>

                      {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure  you have selected Organisation name from dropdown.
</span>}
                    </div>

                  </div>

                <div className="form-group row">
                  <SelectBox
                    label="Currency"
                    onChange={this.onChangeHandlerOffsetFund}
                    field="currency"
                    placeholder="Please Select Currency"
                    className="col-sm-4"
                    data={this.props.currency}
                    defaultValue={this.state.org_token.offset_fund.currency}
                    isRequired={true}
                  />

                  <InputBox
                    InputType="number"
                    minLength="0"
                    label="Amount"
                    onChange={this.onChangeHandlerOffsetFund}
                    field="amount"
                    className="col-sm-4"
                    placeholder="Please enter amount"
                    defaultValue={this.state.amount}
                    isRequired={true}
                  />
                </div>

                <div className="form-group row">
                  <OaDatePicker
                    className="col-sm-4"
                    label="Valid From"
                    field="valid_from"
                    onChange={this.onChangeHandlerOffsetFund}
                    placeholder="Please enter Valid from"
                    showTime={false}
                  
                    isRequired={true}
                  />
                  <OaDatePicker
                    className="col-sm-4"
                    label="Valid until"
                    field="valid_to"

                    onChange={this.onChangeHandlerOffsetFund}
                    placeholder="Please enter Valid until"
                    showTime={false}

                    isRequired={true}
                  />
                </div>
                <div className="text-center m-t-15">
                  <button
                    onClick={this.onSubmitHandler}
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Add offset fund
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.OaDeals.oadeal_currency ,
  institution_list:( state.institutions && state.institutions.institution_list )|| [],
  loading:state.institutions.loading,
  error_message: state.common.error_message
    ? state.common.error_message.message
    : false,
});

const mapDispatchToProps = (dispatch) => ({
  getInstitutionDataLists: (payload) =>
    dispatch(getInstitutionDataLists(payload)),
  getAllCurrencyList: (payload) => dispatch(getAllCurrencyList(payload)),
  allocatOrganisationOffsetFund: (payload) =>
    dispatch(allocatOrganisationOffsetFund(payload)),
});
export const OrganisationOffsetFund = connect(
  mapStateToProps,
  mapDispatchToProps
)(DB);
