import React from "react";
import { connect } from "react-redux";
import { HubContent } from "../../../../../core/components";
import { BaseComponent } from "../../../../../core/components/BaseComponent";
import { DataTable } from "../../../../../core/components";
import {
  InputBox,
  TextBox,
  Loading,
  ModalCustom,
  PopupBox,
  OaDatePicker,
} from "../../../../../core/components/common";
import { confirmAlert } from "react-confirm-alert";
import {
  getRefundRequestList,
  mutateRefundRequest,
  acceptRefundRequest,
  rejectRefundRequest,
  cancelRefundRequest,
  deleteRefundRequest,
} from "../../../../actions";

import moment from "moment";

import {
  REQUEST_CANCELED,
  REFUND_REQUEST_ACCEPTED,
  REJECTED,
  // REFUND_ACKNOWLEDGED,
  REFUND_AMOUNT_PAID,
  REFUND_PAYMENT_RECEIVED,
  // ON_HOLD,
} from "../../../../../config";

const format = "Do MMM YYYY";

class List extends BaseComponent {
  state = {
    data: null,
  };

  getRefundRequestData = async (pageNum = 1, page = 10) => {
    await this.props.getRefundRequestList({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.refund_request_list.results,
      count: this.props.refund_request_list.count,
    });
  };

  async componentDidMount() {
    this.getRefundRequestData();
  }

  pageChange = (pageNumber) => {
    this.getRefundRequestData(pageNumber);
  };

  actionHandler = async (action, item) => {
    try {
      let id = item.id;

      switch (action.toLowerCase()) {
        case "cancel_request":
          this.setState({
            mutate_action: REQUEST_CANCELED,
            id: id,
            isRefundRequestAcceptance: false,
            modalTitle: "Cancel refund request",
            showConfirmationModal: true,
          });
          break;
        case "accept_refund_request":
          this.setState({
            mutate_action: REFUND_REQUEST_ACCEPTED,
            id: id,
            requested_amount: item.requested_amount,
            currency_name: item.currency,
            isRefundRequestAcceptance: true,
            modalTitle: "Accept refund request",
            showConfirmationModal: true,
          });
          break;

        case "reject":
          this.setState({
            mutate_action: REJECTED,
            id: id,
            modalTitle: "Reject refund request",
            showConfirmationModal: true,
          });
          break;

        case "refund_paid":
          this.setState({
            isRefundPaymentUpdate: true,
            mutate_action: REFUND_AMOUNT_PAID,
            id: id,
            modalTitle: "Update refund payment",
            showConfirmationModal: true,
          });
          break;

        case "refund_received":
          this.setState({
            isRefundPaymentAcknowledge: true,
            mutate_action: REFUND_PAYMENT_RECEIVED,
            requested_amount: item.requested_amount,
            accepted_amount: item.accepted_amount,
            currency_name: item.currency,
            payment_deposit_date: item.payment_deposit_date,
            id: id,
            modalTitle: "Acknowledge refund payment",
            showConfirmationModal: true,
          });
          break;
        case "update":
          this.props.history.push(
            `/refund-request/update-refund-request/${id}`
          );
          break;
        case "view":
          this.props.history.push(`/refund-request/view-refund-request/${id}`);
          break;

        case "delete":
          confirmAlert({
            message: "Please confirm to delete",
            buttons: [
              {
                label: "Yes",
                onClick: async () => {
                  await this.props.deleteRefundRequest(id);
                },
              },
              {
                label: "No",
                onClick: () => {},
              },
            ],
          });
          break;

        default:
          break;
      }
    } catch (error) {
      this.log("error: ", error);
    }
  };

  onChangeHandler = (value) => {
    this.setState({
      ...value,
    });
  };

  onSubmit = async () => {
    let {
      id,
      mutate_action,
      accepted_amount,
      payment_deposit_date,
      payment_receive_date,
      note,
    } = this.state;

    if (id && mutate_action) {
      let data = {
        note,
      };

      if (this.state.isRefundRequestAcceptance && accepted_amount) {
        data["accepted_amount"] = parseInt(accepted_amount);
      }

      if (this.state.isRefundPaymentUpdate && payment_deposit_date) {
        data["payment_deposit_date"] = moment(payment_deposit_date).format(
          "YYYY-MM-DD"
        );
      }

      if (this.state.isRefundPaymentAcknowledge && payment_receive_date) {
        data["payment_receive_date"] = moment(payment_receive_date).format(
          "YYYY-MM-DD"
        );
      }

      await this.props.mutateRefundRequest(id, mutate_action, data);

      if (this.props.refund_request_status) {
        this.props.history.push("/refund-request/refund-request-list");
      }
    }
  };

  onClose = () => {
    this.setState({
      isRefundRequestAcceptance: false,
      isRefundPaymentUpdate: false,
      isRefundPaymentAcknowledge: false,
      showConfirmationModal: false,
      modalTitle: "",
      accepted_amount: "",
      requested_amount: "",
      payment_deposit_date: "",
      currency_name: "",
      note: "",
      mutate_action: false,
      id: false,
    });
  };

  cancelLoader = () => {
    this.setState({
      isEmpty: false,
    });
  };

  modalForm = () => {
    return (
      <form>
        {this.state.isRefundRequestAcceptance && (
          <div>
            <div className="form-group row">
              <label className="col-form-label col-sm-4">
                Requested amount
              </label>
              <div className="col-sm-8" style={{ position: "relative" }}>
                {this.state.currency_name} {this.state.requested_amount}
              </div>
            </div>
            <div className="form-group row">
              <InputBox
                label="Accepted amount"
                labelClass="col-sm-4"
                onChange={this.onChangeHandler}
                field="accepted_amount"
                placeholder="Accepted amount"
                className="col-sm-8"
              />
            </div>
          </div>
        )}
        {this.state.isRefundPaymentUpdate && (
          <div className="form-group row">
            <OaDatePicker
              className="col-sm-8"
              label="Payment deposit date"
              labelClass="col-sm-4"
              onChange={this.onChangeHandler}
              field="payment_deposit_date"
              placeholder="Payment deposit date"
              showTime={false}
              format={format}
            />
          </div>
        )}
        {this.state.isRefundPaymentAcknowledge && (
          <div>
            <div className="form-group row">
              <label className="col-form-label col-sm-4">
                Requested amount
              </label>
              <div className="col-sm-8" style={{ position: "relative" }}>
                {this.state.currency_name} {this.state.requested_amount}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-sm-4">Accepted amount</label>
              <div className="col-sm-8" style={{ position: "relative" }}>
                {this.state.currency_name} {this.state.accepted_amount}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-sm-4">Deposit date</label>
              <div className="col-sm-8" style={{ position: "relative" }}>
                {this.state.payment_deposit_date &&
                  moment(this.state.payment_deposit_date).format(format)}
              </div>
            </div>
            <div className="form-group row">
              <OaDatePicker
                className="col-sm-8"
                label="Payment receive date"
                labelClass="col-sm-4"
                onChange={this.onChangeHandler}
                field="payment_receive_date"
                placeholder="Payment receive date"
                showTime={false}
                format={format}
              />
            </div>
          </div>
        )}
        <div className="form-group row">
          <TextBox
            label="Note"
            labelClass="col-sm-4"
            onChange={this.onChangeHandler}
            field="note"
            placeholder="Note"
            rows={3}
            className="col-sm-8"
          />
        </div>
      </form>
    );
  };
    // help menu function 
  HelpButtonClick = () => {
    const url = '/links';
    window.open(url, '_blank');
    
  }
  render() {
    const data = [];
    this.state.data &&
      this.state.data.map((item) => {
        return data.push({
          id: item.id,
          Organisation: item.deal_organisation_name,
          deal_type: item.deal_name,
          currency: item.currency_name,
          requested_amount: item.requested_amount,
          accepted_amount: item.accepted_amount,
          payment_deposit_date: item.payment_deposit_date,
          payment_receive_date: item.payment_receive_date,
          note: item.approval_note,
          action: item.actions,
        });
      });

    const heading = [
      "Organisation",
      "deal_type",
      "currency",
      "requested_amount",
      "accepted_amount",
      "note",
      "action",
    ];

    let errorHandler = (
      <>
        {this.state.isEmpty && (
          <PopupBox msg={this.state.isEmpty} onCancel={this.cancelLoader} />
        )}
      </>
    );

    return (
      <HubContent
        title="Refund request list"
        onArticleDownloadClickHandler={this.onApcDownloadClick}
        onHelpHandler={ this.HelpButtonClick}
      >
        {this.state.showConfirmationModal && (
          <ModalCustom
            title={this.state.modalTitle}
            submitBtnTxt="Submit"
            onClose={this.onClose}
            onSubmit={this.onSubmit}
            modalClass="col-sm-6"
            isShow={this.state.showConfirmationModal}
          >
            {this.modalForm()}
          </ModalCustom>
        )}

        {errorHandler}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              {this.state.data ? (
                <DataTable
                  heading={heading}
                  data={data}
                  noAction={true}
                  isRefundRequest={true}
                  count={this.state.count}
                  actionHandler={this.actionHandler}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : (
                <Loading type="flat" />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  refund_request_list: state.RefundRequest.refund_request_list || false,
  refund_request_status: state.RefundRequest.refund_request_status || false,
});

const mapDispatchToProps = (dispatch) => ({
  getRefundRequestList: (payload) => dispatch(getRefundRequestList(payload)),
  acceptRefundRequest: (ids) => dispatch(acceptRefundRequest(ids)),
  rejectRefundRequest: (ids) => dispatch(rejectRefundRequest(ids)),
  cancelRefundRequest: (ids) => dispatch(cancelRefundRequest(ids)),
  deleteRefundRequest: (ids) => dispatch(deleteRefundRequest(ids)),
  mutateRefundRequest: (ids, action, payload) =>
    dispatch(mutateRefundRequest(ids, action, payload)),
});

export const RefundRequestList = connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
