import React from 'react';
import { connect } from 'react-redux';
import { HubContent } from '../../../../../core/components';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { DataTable } from '../../../../../core/components';
import { Loading } from '../../../../../core/components/common';
import { getAllApcRequests } from '../../../../actions';


class ApcRequest extends BaseComponent {
    state = {
        data: null,
        count: 0,
    }

    async componentDidMount() {

        await this.props.getAllApcRequests({ pageNum: 1, pageSize: 10 });

        this.props.apc_requests && this.setState({
            data: this.props.apc_requests.results,
            count: this.props.apc_requests.count,
        });
    }
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();

        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        // }
    };
    render() {
        const data = [];
        this.state.data && this.state.data.map(item => {
            return data.push({
                id: item.article,
                licence: item.licence_name,
                date: item.date_created,
                title: item.article_title,
                author_name: item.article_author_name

            })
        });

        const heading = ['date', 'title', 'licence', 'author_name']

        return (
            <HubContent title="Apc request list" onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            {this.state.data ?
                                <DataTable
                                    heading={heading}
                                    data={data}
                                    noAction={true}
                                    wrap={true}
                                    count={this.state.count}
                                    pageChange={this.pageChange}
                                    pageSize={10}
                                /> : <Loading type="flat" />
                            }
                        </div>
                    </div>
                </div>

            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    apc_requests: state.authorRequests.apc_requests
})

const mapDispatchToProps = (dispatch) => ({
    getAllApcRequests: (payload) => dispatch(getAllApcRequests(payload))
})

export const ApcRequestList = connect(mapStateToProps, mapDispatchToProps)(ApcRequest)