import React from "react";
import { HubContent } from "../../../../../core/components";
import { Button, Loading } from "../../../../../core/components/common";
import { connect } from "react-redux";
import {
  dashboardDragAndDropControlList,
  dashboardDragAndDropControl,
  getDashboardLevelList,
} from "../../../../actions";
import { Checkbox, Popover } from "antd";

import { dashboardcompontents } from "../../../../components/common/userPermission";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Dashboard configuration</a>
    <br />
  </div>
);
class PubDashboardControlUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      permissionValue: [],
      groupKey: [],
      uncheckedList: [],
    };
  }
  async componentDidMount() {
    if (this.props.auth && this.props.auth.user.id) {
      let dashboardLevelList = await this.props.getDashboardLevelList(
        this.props.auth.user.organisation_id
      );
      let arr = Object.entries(dashboardLevelList.components).map(
        ([key, value]) => ({
          label: value.label,
          key: key,
        })
      );

      let dataCompontent = await this.props.dashboardDragAndDropControlList(
        this.props.auth && this.props.auth.user.id
      );

      let result = arr.filter((item) =>
        Object.keys(dataCompontent.components).includes(item.key)
      );

      const filteredDataList = Object.keys(
        dashboardLevelList.components
      ).filter(
        (prop) =>
          dataCompontent.components[prop] &&
          !dataCompontent.components[prop].hide
      );
      this.setState({
        permissionValue: result.map((item) => item.key),
        groupKey: result,
        checkedList: filteredDataList,
        dashboard_layout: dataCompontent.dashboard_layout,
      });
    }
  }

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? this.state.permissionValue : [],
      uncheckedList: e.target.checked ? [] : this.state.permissionValue,
    });
  };

  onCheckItem = (value) => (e) => {
    this.setState(
      {
        checkedList: this.state.checkedList.includes(value)
          ? this.state.checkedList.filter((x) => x !== value)
          : [...this.state.checkedList, value],
      },
      () => {
        this.setState({
          uncheckedList:
            this.state.permissionValue &&
            this.state.permissionValue.filter(
              (o) => !this.state.checkedList.includes(o)
            ),
        });
      }
    );
  };
  onSubmitHandler = async () => {
    let data = await this.props.dashboardDragAndDropControl(
      this.props.auth.user.id,
      {
        disabled_components: this.state.uncheckedList,
        dashboard_layout: this.state.dashboard_layout,
      }
    );
    if (data.status === true) {
      await this.props.history.push("/");
    }
  };

  render() {
    const { checkedList } = this.state;
    let groupKey;
    if (this.state.groupKey) {
      groupKey = dashboardcompontents.filter(
        (item) =>
          this.state.groupKey &&
          this.state.groupKey.some((findItem) => findItem.key === item.key)
      );
    }

    return (
      <HubContent>

        {this.props.isLoading !== true ? <div className="user-permission-content dashboard-control">
          <div className="vat-tax-button email d-flex justify-content-end" style={{ marginTop: 20 }}>


            <Popover placement="leftTop" content={content} trigger="click">
              <Button
                className="btn btn-secondary float-right "
                type=""
                name=""
                text="Help"
              >Help</Button>
            </Popover>

            <div className="danger float-right" style={{ marginRight: 10 }}
            >
              <button
                type="danger"
                className="btn btn-danger float-right "
                onClick={() => this.props.history.goBack()}
                style={{ marginLeft: 10 }}
              >
                X

</button>                  </div>

          </div>

          <div className="user-permission">
            {groupKey && groupKey.length > 0 ? (
              <div className="checkbox-all">
                <div className="permission-select-all">
                  <Checkbox
                    indeterminate={
                      checkedList.length < this.state.permissionValue.length &&
                      checkedList.length > 0
                    }
                    onChange={this.onCheckAllChange}
                    checked={
                      checkedList.length === this.state.permissionValue.length
                    }
                  ></Checkbox>
                  Select all
                </div>
                <div className="checkbox-info">
                  Tick the checkboxes below to view charts and tables on your
                  Dashboard. You can customise, resize and rearrange your charts
                  on the Dashboard.
                </div>
              </div>
            ) : null}


            <div className="permission-checkbox">
              <div className="uermission-key">
                <div className="row">

                  {groupKey &&
                    groupKey.map((item, idx) => (
                      <div className="userpermission-content col-6 col-sm-6 col-md-6" key={idx}>
                        <div className="content-item d-flex align-items-start">
                          <div className="item">
                            <label>{item.label}</label>
                          </div>
                          <div className="item-checkbox mr-0">
                            <Checkbox
                              key={item.key}
                              onChange={this.onCheckItem(item.key)}
                              checked={
                                checkedList && checkedList.includes(item.key)
                              }
                            >
                              {item.key}
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    ))}
{groupKey && groupKey.length === 0 && <div className="card-body no-data-found">No Dashboard Configuration Found</div>}
                </div>
              </div>
            </div>

            {groupKey && groupKey.length > 0 && <div className="text-center m-t-15">
              <button
                className="btn btn-primary btn-md waves-effect waves-light"
                onClick={this.onSubmitHandler}
              >
                {this.props.match.params.id
                  ? "Update DashboardControl"
                  : "Configuration Dashboardcontrol"}
              </button>
            </div>}
          </div>
        </div> : <Loading type="flat" />}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: state.DashboardControl.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  getDashboardLevelList: (id) => dispatch(getDashboardLevelList(id)),
  dashboardDragAndDropControlList: (id) =>
    dispatch(dashboardDragAndDropControlList(id)),
  dashboardDragAndDropControl: (id, payload) =>
    dispatch(dashboardDragAndDropControl(id, payload)),
});

export const PubDashboardControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(PubDashboardControlUI);
