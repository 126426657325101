import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { Link } from "react-router-dom";
// import { SelectBox, InputBox } from "../../../../../core/components/common";
import {
  getInvoiceTemplateList,
  deleteInvoiceTemplate,
} from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";

class UL extends BaseComponent {
  state = {
    data: [],
  };

  getInvoiceTemplateListData = async (pageNum = 1, page = 10) => {
    await this.props.getInvoiceTemplateList({
      pageNum: pageNum,
      pageSize: page /* has_df_only: true, */,
    });

    this.setState({
      data: this.props.invoice_templates.results,
      count: this.props.invoice_templates.count,
    });
  };

  async componentDidMount() {
    this.getInvoiceTemplateListData();
  }

  pageChange = (pageNumber) => {
    this.getInvoiceTemplateListData(pageNumber);
  };

  onEditInvoiceTemplate = (id) => {
    this.props.history.push("/invoice-templates/edit/" + id);
  };

  onDeleteInvoiceTemplate = (id) => {
    confirmAlert({
      title: "",
      message: "Please confirm to delete",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteInvoiceTemplate(id).then((res) => {
              if (res.status) {
                this.props.history.push("/invoice-templates/list");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const heading = ["organisation_name", "title", "description"];

    return (
      <HubContent title="Invoice template list">
        <div className="row">
          <div className="col-12">
            <Link
              to="/invoice-templates/add"
              className="btn btn-primary float-right mb-2"
            >
              Add invoice template
            </Link>
          </div>
        </div>
        <div className="row filter-md-4">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditInvoiceTemplate}
                  onDelete={this.onDeleteInvoiceTemplate}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  invoice_templates: state.InvoiceTemplate.invoice_templates || false,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceTemplateList: (payload) =>
    dispatch(getInvoiceTemplateList(payload)),
  deleteInvoiceTemplate: (id) => dispatch(deleteInvoiceTemplate(id)),
});

export const InvoiceTemplateList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UL);
