import { 
    GET_ARTICLE_TAB_CONTROL_CONFIG_LIST, 
    GET_ARTICLE_TAB_CONTROL_CONFIG, 
    CREATE_UPDATE_ARTICLE_TAB_CONTROL_CONFIG, 
    DELETE_ARTICLE_TAB_CONTROL_CONFIG,
} from "../../config";

const ArticleTabControlConfigReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ARTICLE_TAB_CONTROL_CONFIG_LIST: 
            return state = {
                ...state,
                article_tab_configs: action.payload
            }
        case GET_ARTICLE_TAB_CONTROL_CONFIG: 
            return state = {
                ...state,
                article_tab_config: action.payload
            }
        case CREATE_UPDATE_ARTICLE_TAB_CONTROL_CONFIG: 
            return state = {
                ...state,
                article_tab_config: action.payload
            }
        case DELETE_ARTICLE_TAB_CONTROL_CONFIG: 
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default ArticleTabControlConfigReducer;
