import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import { getChildOrganisationList } from "../../../../actions";
import {
  Loading,
} from "../../../../../core/components/common";

class UL extends BaseComponent {
  state = {
    orgId: null
  };
  

  getChildOrgData = async (pageNum = 1, page = 10) => {
    await this.props.getChildOrganisationList({
      pageNum: pageNum,
      pageSize: page
    });

  };

  onView = id => {
    this.props.history.push("/affiliated-organisations/single-org/" + id);
  };

  onCancel = () => {
    this.setState({
      orgId: null
    });
  };
  pageChange = pageNumber => {
    this.getChildOrgData(pageNumber);
  };

  render() {
    let orgObj = this.props.orgs;
    let dataOrg = [];
    orgObj && orgObj.map(item => {
      return dataOrg.push({
        organization_name: item.name,
        email_domain: item.email_domain,
        grid_number: item.meta ? item.meta.grid_number : "",
        country: item.address ? item.address.country_name : "",
        id: item.id
      });
    });

    const heading = [
      "organization_name",
      "email_domain",
      "grid_number",
      "country"
    ];
    return (
      <HubContent
        title="Affiliated organisation list "
      >
        <div className="row filter">

          <div className="col-12">
            <div className="card m-b-20">
              {dataOrg ? <DataTable
                heading={heading}
                data={dataOrg}
                onView={this.onView}
                isChildOrgList={true}
                count={this.props.count}
                pageChange={this.pageChange}
                pageSize={10}
              /> : <Loading type="flat" />}


            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  orgs: state.orgs.org || [],
  count: state.orgs.org_list_count || 0
});

const mapDispatchToProps = dispatch => ({
  getChildOrganisationList: payload =>
    dispatch(getChildOrganisationList(payload))
});

export const OrgList = connect(mapStateToProps, mapDispatchToProps)(UL);
