import { 
    GET_QS_DASHBOARD_LIST, 
    GET_QS_DASHBOARD,
    CREATE_UPDATE_QS_DASHBOARD,
    DELETE_QS_DASHBOARD,
} from "../../config";

const QsDashboardReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_QS_DASHBOARD_LIST: 
            return state = {
                ...state,
                qs_dashboards: action.payload
            }
        case GET_QS_DASHBOARD: 
            return state = {
                ...state,
                qs_dashboard: action.payload
            }
        case CREATE_UPDATE_QS_DASHBOARD: 
            return state = {
                ...state,
                qs_dashboard: action.payload
            }
        case DELETE_QS_DASHBOARD: 
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default QsDashboardReducer;
