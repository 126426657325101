import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getPublicPublisher,
  createUpdatePublicationApcWaiverConfig,
  getPublicationList,
  getPublicationApcWaiverConfig,
  getJournalList,
  getContentTypeList,
} from "../../../../actions";
import {
  PopupBox,
  SelectBox,
  OaDatePicker,
  ModalSelectBox,
} from "../../../../../core/components/common";
import { serialize } from "../../../../../core/lib";

import moment from "moment";
const format = "Do MMM YYYY";

class PublicationApcWaiverConfig extends BaseComponent {
  state = {
    publication_apc_waiver_config: {
    }
  };

  async componentDidMount() {
    if (this.props.group === 'HUB') {
      this.props.getPublicPublisher();
    }

    let payload = {
      page_size: 999999
    };

    this.props.getContentType(payload);

    let id = this.props.match && this.props.match.params && this.props.match.params.id;

    await this.props.getPublicationApcWaiverConfig(id);

    if (this.props.publication_apc_waiver_config) {
      let publication_apc_waiver_config = {
        publisher: this.props.publication_apc_waiver_config.publisher,
        publication: this.props.publication_apc_waiver_config.publication,
        content_types: this.props.publication_apc_waiver_config.content_types,
        is_price_waived: this.props.publication_apc_waiver_config.is_price_waived,
        expiry_date: this.props.publication_apc_waiver_config.expiry_date ? moment(this.props.publication_apc_waiver_config.expiry_date) : undefined,
      };

      this.setState({
        publication_apc_waiver_config,
        ...publication_apc_waiver_config,
      }, () => {
        this.getPublicationListOnParameterChange();
      });
    }

  }

  onChangeHandler = value => {

      this.setState(prevState => {
        return Object.assign(prevState.publication_apc_waiver_config, value);
      }, () => {
        if (this.props.group === 'HUB' && value.publisher) {
          this.getPublicationListOnParameterChange();
        }
      });
    
  };

  onSubmitHandler = async e => {
    e.preventDefault();

    let data = this.state.publication_apc_waiver_config;
    if (data.offer_expiry) {
      Object.assign(data, {
        offer_expiry: moment(this.state.publication_apc_waiver_config.offer_expiry).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        offer_expiry:null
      });
    }

    if (!data.publication) {
      this.setState({
        isEmpty: (
          <div>
            {!data.publication && <p>Publication must be selected</p>}
          </div>
        )
      });
    } else {

      if (!data.publisher) {
        data.publisher = this.props.organisation_id;
      }

      if (data.expiry_date) {
        data.expiry_date = moment(data.expiry_date).format("YYYY-MM-DD");
      }

      data.is_price_waived = true;

      let id = this.props.match && this.props.match.params && this.props.match.params.id;

      await this.props.createUpdatePublicationApcWaiverConfig(data, id);

      if (this.props.publication_apc_waiver_config.success) {
        this.props.history.push("/settings/apc-config-list");
      }
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };


  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999
    };

    if (this.state.publisher) {
      filterParams.publisher = this.state.publisher;
    } else if (this.props.organisation_id) {
      // filterParams.publisher = this.props.organisation_id;
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload.filter);

    this.props.publication_list &&
      this.setState({
        publicationList: this.props.publication_list.results,
      });
  };

  onChangeChecked = e => {
    let value = {
      is_price_waived: e.target.checked,
    };

    this.setState((prevState) => {
      return Object.assign(prevState.publication_apc_waiver_config, value);
    });
  };

  onCancelHandler = () => {
    this.props.history.push("/settings/apc-config-list");
  }

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data
      },
      async () => {
        this.setState(prevState => {
          Object.assign(prevState.publication_apc_waiver_config, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : []
          });
        });
      }
    );
  };

  onModalSelectBoxShow = fieldName => {
    switch (fieldName) {
      case "content_types":
        this.setState({
          isShowContentTypeModal: true
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = e => {
    this.setState({
      isShowContentTypeModal: false,
    });
  };

  showSelectItemsLabel = fieldName => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} content type selected`
              : `${fieldValues.length} content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Content types"
              : "Select Content type";
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Update Journal for APC waiver
                    </a>
                  </li>

                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      {this.props.group === 'HUB' && <div className="form-group row">
                        <SelectBox
                          label="Publisher"
                          onChange={this.onChangeHandler}
                          field="publisher"
                          labelInfo={true}
                          labelInfoTitle="Select Publication"
                          data={(this.props.publishers && this.props.publishers.results) || []}
                          defaultValue={this.state.publisher}
                          isRequired={true}
                          className="col-sm-9"
                          labelClass="col-sm-3"
                        />
                      </div>}

                      <div className="form-group row">
                        <SelectBox
                          label="Publication"
                          onChange={this.onChangeHandler}
                          field="publication"
                          labelInfo={true}
                          labelInfoTitle="Select Publication"
                          data={this.state.publicationList || []}
                          defaultValue={this.state.publication}
                          isRequired={true}
                          className="col-sm-6"
                          labelClass="col-sm-3"
                        />
                      </div>

                      <div className="form-group row mt-4">
                        <label className=" col-form-label col-sm-3">
                          Content types
                        </label>
                        
                        <div
                          className="col-sm-3"
                          style={{ position: "relative",marginTop:10 }}
                        >
                          {this.showSelectItemsLabel("content_types")}
                          <button
                            onClick={() =>
                              this.onModalSelectBoxShow("content_types")
                            }
                            className="btn btn-info waves-effect waves-light float-right"
                            type="button"
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>

                        {this.state.isShowContentTypeModal && (
                          <ModalSelectBox
                            title="Select content types"
                            label="Content types"
                            field="content_types"
                            className="col-sm-4"
                            data={
                              (this.props.content_type && [
                                { id: "all", name: "All content types" },
                                ...this.props.content_type
                              ]) ||
                              []
                            }
                            defaultValue={this.state.content_types}
                            submitBtnTxt="Save"
                            onSubmit={this.onSaveSelectedItems}
                            onClose={() => this.onModalSelectBoxClose()}
                          />
                        )}
                      </div>
                      <div className="form-group row">
                        {this.state.expiry_date && <OaDatePicker
                          className="col-sm-6"
                          label="Expiry date"
                          labelClass="col-sm-3"
                          field="expiry_date"
                          onChange={this.onChangeHandler}
                          labelInfo={true}
                          labelInfoTitle="Select expiration date"
                          placeholder="Select expiration date"
                          showTime={false}
                          format={format}
                          defaultValue={
                            this.state.expiry_date
                          }
                        />}
                        {!this.state.expiry_date && <OaDatePicker
                          className="col-sm-9"
                          label="Expiry date"
                          labelClass="col-sm-3"
                          field="expiry_date"
                          labelInfo={true}
                          labelInfoTitle="Select expiration date"
                          onChange={this.onChangeHandler}
                          placeholder="Select expiration date"
                          showTime={false}
                          format={format}
                     
                        />}
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-danger waves-effect waves-light mx-1"
                          onClick={this.onCancelHandler}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  group: (state.auth && state.auth.group) ,
  organisation_id: state.auth.user.organisation_id ,
  publishers: state.orgs.publisher_list ,
  publication_apc_waiver_config: state.PublicationApcWaiverConfig.publication_apc_waiver_config ,
  publication_list: state.institutions.publication_list ,
  journals: state.journals.journal_list,
  content_type: state.journals.content_type,
});

const mapDispatchToProps = dispatch => ({
  getJournalList: payload => dispatch(getJournalList(payload)),
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getPublicationList: payload => dispatch(getPublicationList(payload)),
  createUpdatePublicationApcWaiverConfig: (payload, id) => dispatch(createUpdatePublicationApcWaiverConfig(payload, id)),
  getPublicationApcWaiverConfig: id => dispatch(getPublicationApcWaiverConfig(id)),
  getContentType: (payload) => dispatch(getContentTypeList(payload)),
});

export const EditPublicationApcWaiverConfig = connect(mapStateToProps, mapDispatchToProps)(PublicationApcWaiverConfig);
