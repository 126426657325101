import React from 'react';
import { connect } from 'react-redux'
import BaseComponent, { HubContent } from '../../../../../core/components';
import { Card, Skeleton } from 'antd';
import { getSingleCorrectionRequested } from '../../../../actions';

class View extends BaseComponent {
  state = {
    info: null
  }

  async componentDidMount() {
    let id = this.props.match.params.id;
    try {
      await this.props.getSingle(id)
      this.setState({
        info: this.props.location.state
      })
    } catch (e) {
    }
  }


  checkStatus = (str) => {
    if (str === 'pending') {
      return <p className="alert alert-warning px-2 py-1 d-inline">{str}</p>
    } else if (str === "approved") {
      return <p className="alert alert-success px-2 py-1 d-inline">{str}</p>
    } else {
      return <p className="alert alert-info px-2 py-1 d-inline">{str}</p>
    }
  }

  renderDetails = () => {
    let info = null
    if (this.props.location.state) {
      info = this.props.location.state
    } else {
      info = this.props.correction_single
    }
    return (
      info &&
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body row">
              <div className="col-sm-12">
                <table style={styles.table} >{/******** User Basic Info *******/}
                  <tbody>
                    <tr style={styles.tr}>
                      <th className="mb-5"><h6>Correction request details</h6></th>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Request date</td>
                      <td style={styles.td2}>{info.request_date}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Request to (Publisher)</td>
                      <td style={styles.td2}>{info.request_to}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Article</td>
                      <td style={styles.td2}>{info.article}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Note</td>
                      <td style={styles.td2}>{info.note}</td>
                    </tr>
                    <tr style={styles.tr}>
                      <td style={styles.td}>Status</td>
                      <td style={styles.td2}>{this.checkStatus(info.approval_status)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <HubContent title="Correction requst">
        <Card>
          {this.state.info ? this.renderDetails() :
            <Skeleton
              active
              paragraph={{ row: 10 }}
            />
          }
        </Card>
      </HubContent>
    )
  }
}

const mapStateToProps = (state) => ({
  correction_single: state.articles.correction_single
})

const mapDispatchToProps = (dispatch) => ({
  getSingle: (id) => dispatch(getSingleCorrectionRequested(id))
})

export const CorrectionView = connect(mapStateToProps, mapDispatchToProps)(View)


const styles = {
  table: {
    borderCollapse: "collapse",
    width: '100%'
  },
  tr: {
    marginTop: 20,
    verticalAlign: 'baseline'
  },
  td: {
    paddingTop: 10
  },
  td2: {
    paddingTop: 10,
    width: '75%'
  }
};
