import config, { 
    CREATE_SUBMISSION,
    GET_SUBMISSION_LIST,
    GET_SUB_STATUS,
    UPDATE_SUBMISSION,DELETE_SUBMISSION

  } from '../../config'
  import { api } from '../../core/api';
  
  
  export const getSubmissionReducer = (payload) => ({
    type: GET_SUBMISSION_LIST,
    payload
  })
  export const getOaSubStatusReducer = (payload) => ({
    type: GET_SUB_STATUS,
    payload
  })
    
  export const createSubmissionReducer = (payload) => ({
    type: CREATE_SUBMISSION,
    payload
  })
  export const UpdateSubmissionReducer = (payload) => ({
    type: UPDATE_SUBMISSION,
    payload
  })
  export const deleteSubmissionReducer = (payload) => ({
    type: DELETE_SUBMISSION,
    payload
  })
  
  
  export const createSubmission= (payload) => {
    let url = config.endpoint.submission_credentials;
    
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        api.post(url,payload).then((res) => {
          dispatch(createSubmissionReducer(res))
          resolve(Object.assign(res, { status: true }));
        }).catch(function (error) {
          resolve(Object.assign(error, { status: false }));
        })
      })
    }
  }
  
  export const updateSubmission= (payload) => {
    return (dispatch) => {
        let url = config.endpoint.submission_credentials +`${payload.id}/`;;

        return new Promise((resolve, reject) => {
            api.put(url, payload).then((res) => {
                dispatch(updateSubmission(res));
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            });
        })
    }
  }
  
  export const getSubmissionList= () => {
   
    return (dispatch) => {
      let url = config.endpoint.submission_credentials;

      return new Promise((resolve, reject) => {
        api.get(url).then((res) => {
          dispatch(getSubmissionReducer(res))
          resolve(Object.assign(res, { status: true }));
        }).catch(function (error) {
          resolve(Object.assign(error, { status: false }));
        })
      })
    }
  }
  export const getOaSubStatus= () => {
    return (dispatch) => {
      let url = config.endpoint.oa_sub_status;

      return new Promise((resolve, reject) => {
        api.get(url).then((res) => {
          dispatch(getOaSubStatusReducer(res))
          resolve(Object.assign(res, { status: true }));
        }).catch(function (error) {
          resolve(Object.assign(error, { status: false }));
        })
      })
    }
  }
  export const deleteSubmission= (id) => {
    return (dispatch) => {
      let url = config.endpoint.submission_credentials +`${id}/`;;

      return new Promise((resolve, reject) => {
        api.delete(url).then((res) => {
          dispatch(deleteSubmissionReducer(res))
          resolve(Object.assign(res, { status: true }));
        }).catch(function (error) {
          resolve(Object.assign(error, { status: false }));
        })
      })
    }
  }