import React from "react";
import BaseComponent from "..";
import { SelectBox } from ".";

class Modal extends BaseComponent {
  state = {
    selectedItemIds: this.props.defaultValue || [],
    selectedItems:
      this.props.data && this.props.defaultValue
        ? this.props.data.filter((item) =>
            this.props.defaultValue.includes(item.id)
          )
        : [],
  };


  onCloseModal = (e) => {
    this.props.onClose();
  };

  onSubmit = () => {
    const { field } = this.props;
    let { selectedItemIds } = this.state;
    this.props.onSubmit(field, selectedItemIds);
    this.onCloseModal();
  };

  onDeleteSelectedItem = (id) => {
    let { selectedItemIds, selectedItems } = this.state;

    this.setState({
      selectedItemIds: selectedItemIds.filter((value) => value !== id),
      selectedItems: selectedItems.filter((item) => item.id !== id),
    });
  };

  onMultiSelectChangeHandler = (value) => {
    const { field } = this.props;
    let { selectedItemIds } = this.state;

    if (!selectedItemIds.includes(value[field])) {
      if (value[field] === "all") {
        selectedItemIds = [];
      } else {
        if (this.props.singleField === "singleField") {
          selectedItemIds = [value[field]];
        } else {
          selectedItemIds.push(value[field]);
        }
      }

      this.setState(
        {
          selectedItemIds: selectedItemIds,
          selectedItems: this.props.data.filter((item) =>
            selectedItemIds.includes(item.id)
          ),
        },
        async () => {}
      );
    }
  };

  render() {
    const {
      label,
      field,
      className,
      labelClass,
      isRequired,
    
    } = this.props;
    let data = Array.isArray(this.props.data) ? this.props.data : []

    return (
      <div style={modal.overlay}>
        <div
          className={`${this.props.modalClass || "col-sm-6"} bg-white alert`}
          style={modal.boxStyle}
        >
          <div className="modal-body">
            <div className="form-group row">
              <SelectBox
                label={label}
                labelClass={labelClass || `col-sm-3`}
                onChange={this.onMultiSelectChangeHandler}
                placeholder={this.props.placeholder || label}
                field={field}
                className={className || `col-sm-9`}
                data={data || false}
                value={this.state.field}
                isRequired={isRequired || false}
                multiple={false}
              />
            </div>
            <div className="form-group row">
              {this.state.selectedItems.length > 0 && (
                <table className="table">
                  <tbody>
                    {this.state.selectedItems && this.state.selectedItems.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td className="py-1">{item.name}</td>
                          <td className="py-1 text-center">
                            <button
                              onClick={() => this.onDeleteSelectedItem(item.id)}
                              className="btn btn-danger waves-effect waves-light"
                              type="button"
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="modal-footer">
          
            {this.props.submitBtnTxt && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSubmit}
              >
                {this.props.submitBtnTxt}
              </button>
            )}
              <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.props.onClose && this.props.onClose();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 1001,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflow: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0px, 100px)",
    height: boxHeight,
  },
};

export const ModalSelectBox = Modal;
