import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { getOrgInfo, getDashboardData } from '../../../../actions';
import { DBCard } from '../../../../../core/components/common';
import { AddressCard } from '../../../partials';
import InstitutionArticle from './InstitutionArticle';
import { serialize } from '../../../../../core/lib';

class ViewOrg extends BaseComponent {
  state = {
    org: "",
    errMsg: "",
    address: null
  }

  componentDidMount() {
   this.apiDataList()
  }

  apiDataList =async () => {
    let id = this.props.match.params.id;
    try {
      let orgInfo = await this.props.getOrgInfo(id)

      if (orgInfo.address) {
        this.setState({
          address: orgInfo.address,
          errMsg: "",
          org: orgInfo
        })
      } else {
        this.setState({
          errMsg: "This organization does not have address."
        })
      }
    } catch (e) {
      this.setState({
        errMsg: "There is something error"
      })
    }
    this.filterData(id)
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
}
  filterData = async (id) => {
    let filterParams = 'component=available_offset_component,spend_offset_component,pub_deposit_component'
    await this.setState((prevState) => {
        prevState.filterParams = { filter_institution: id }
    
    })

    let filter = filterParams + '&' + serialize(this.state.filterParams);
    try {
      this.props.getDashboardData({ filter })
    } catch (e) {
    }
  }


  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }


  currencyCalculation = (component) => {
    if (component) {
      let gbp = '£ ' + this.numberWithCommas(parseFloat(component.data.gbp).toFixed(2));
      let usd = '$ ' + this.numberWithCommas(parseFloat(component.data.usd).toFixed(2));
      let eur = '€ ' + this.numberWithCommas(parseFloat(component.data.eur).toFixed(2));
      return [gbp, usd, eur];
    } else {
      return ['£ 0.00', '$ 0.00', '€ 0.00'];
    }
  }

  render() {
    return (
      <div>
        <HubContent title={this.state.org.name} className="mb-0">
          <div className="row">
            <AddressCard
              errMsg={this.state.errMsg}
              address={this.state.address}
            />
            <DBCard
              icon="mdi mdi-cube-outline"
              title="TOTAL DEPOSIT"
              body={this.currencyCalculation(this.props.deposit_component)}
            />

            <DBCard
              icon="mdi mdi-buffer"
              title="AVAILABLE FUNDS"
              body={this.currencyCalculation(this.props.available_fund_component)}
            />

            <DBCard
              icon="mdi mdi-tag-text-outline"
              title="OA INCOME"
              body={this.currencyCalculation(this.props.oa_spent_component)}
            />
          </div>

        </HubContent>

        <InstitutionArticle
          className={"mt-0"}
          institute={this.props.match.params.id}
        />
      </div>
    )
  }
}


const mapStateToProps = (state, props) => {

    return {
      articles: state.articles.article_full || [],
      oa_spent_component: state.dashboard.oa_income_component || false,
      deposit_component: state.dashboard.pub_deposit_component || false,
      available_fund_component: state.dashboard.pub_available_fund_component || false
    }
  
}

const mapDispatchToProps = (dispatch) => ({
  getOrgInfo: (id) => dispatch(getOrgInfo(id)),
  getDashboardData: (payload) => dispatch(getDashboardData(payload))
})


export const ViewInstitution = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewOrg)

