import React, { Component } from "react";

class ExpandheadingForm extends Component {
  render() {
    const { heading } = this.props;

    return (
      <div className="row heading" style={{ textAlign: "center" }}>
        {heading.map((item, idx) => (
          <div className={item.column} key={idx}>
            {item.name}
          </div>
        ))}
      </div>
    );
  }
}
export const ExpandHeadingTable = ExpandheadingForm;
