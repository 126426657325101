import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { createUpdateSocietyMember, getSocietyList } from '../../../../actions';
import { Checkbox } from "antd";
import { PopupBox, InputNewBox, OaDatePicker, SelectBox, Button } from '../../../../../core/components/common';

import moment from "moment";

const format = "Do MMM YYYY";

class Add extends BaseComponent {

    state = {
        society_member: {},
    }

    componentDidMount() {
        this.props.getSocietyList()

        this.setState((prevState) => {
            return Object.assign(prevState.society_member, this.state.params.society_member);
        })
    }

    componentWillMount() {
        if (this.props.history.location.state) {
            let params = this.props.history.location.state;
            this.setState({ params });
        }
    }

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            if (value.membership_expiry) {
                value.membership_expiry = moment(value.membership_expiry).format("YYYY-MM-DD");
            }

            return Object.assign(prevState.society_member, value);
        })
    }

    onChangeChecked = e => {
        let value = {
            is_visible_to_other: e.target.checked,
        };

        this.setState((prevState) => {
            return Object.assign(prevState.society_member, value);
        });
    };

    onSubmitHandler = async (e) => {
        e.preventDefault();

        let {
            first_name,
            last_name,
            salutation,
            middle_name,
            email,
            orcid_id,
            membership_id,
            membership_expiry,
            fellow_affiliation,
            is_visible_to_other,
        } = this.state.society_member;

        if (!first_name || !last_name || !membership_id) {
            this.setState({
                isEmpty: <div>
                    {!first_name && <p>First name must be filled</p>}
                    {!last_name && <p>Last name must be filled</p>}
                    {!membership_id && <p>Membership ID must be filled</p>}
                </div>
            })
        } else {

            let id = this.props.match.params.id;
            let data = {
                salutation: salutation,
                id,
                first_name: first_name,
                middle_name: middle_name,
                last_name: last_name,
                orcid_id: orcid_id,
                email: email,
                membership_id: membership_id,
                membership_expiry: membership_expiry,
                fellow_affiliation: fellow_affiliation,
                is_visible_to_other: !!is_visible_to_other,
            };
            await this.props.createUpdateSocietyMember({ ...data, society: this.props.auth && this.props.auth.group === "HUB" ? this.state.society_member.society : this.props.auth && this.props.auth.user && this.props.auth.user.organisation_id });


            if (this.props.society_member.success) {
                this.props.history.push('/society-members/society-member-list')
            }

        }
    }

    cancelLoader = async () => {
        this.setState({
            isEmpty: false
        })
    }

    render() {
        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>
        return (
            <HubContent title="Edit society member">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="vat-tax-button email">
                                <Button
                                    type="danger"
                                    name="danger"
                                    text="x"
                                    onClick={() => this.props.history.goBack()}
                                />
                            </div>
                            <div className="card-body">

                                <form>
                                    {this.props.auth.group === "HUB" && <div className="form-group row">

                                        <SelectBox
                                            label="Society"
                                            onChange={this.onChangeHandler}
                                            field="society"
                                            data={
                                                this.props.societies
                                                    ? this.props.societies.results
                                                    : []
                                            }
                                            isRequired={true}
                                            placeholder="Select society"
                                            defaultValue={this.state.society_member.society}

                                        />
                                    </div>}

                                    <div className="form-group row">
                                        <InputNewBox
                                            label="Salutation"
                                            onChange={this.onChangeHandler}
                                            field='salutation'
                                            placeholder="Salutation"
                                            defaultValue={this.state.society_member.salutation || ''}
                                        />
                                        <InputNewBox
                                            label="First name"
                                            onChange={this.onChangeHandler}
                                            field='first_name'
                                            placeholder="First name"
                                            isRequired={true}
                                            defaultValue={this.state.society_member.first_name || ''}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputNewBox
                                            label="Middle name"
                                            onChange={this.onChangeHandler}
                                            field='middle_name'
                                            placeholder="Middle name"
                                            defaultValue={this.state.society_member.middle_name || ''}
                                        />
                                        <InputNewBox
                                            label="Last name"
                                            onChange={this.onChangeHandler}
                                            field='last_name'
                                            placeholder="Last name"
                                            isRequired={true}
                                            defaultValue={this.state.society_member.last_name || ''}
                                        />
                                    </div>

                                    <div className="form-group row">
                                        <InputNewBox
                                            label="Email"
                                            onChange={this.onChangeHandler}
                                            field='email'
                                            placeholder="Email"
                                            InputType="email"
                                            defaultValue={this.state.society_member.email || ''}
                                        />
                                        <InputNewBox
                                            label="Orcid ID"
                                            onChange={this.onChangeHandler}
                                            field='orcid_id'
                                            placeholder="Orcid ID"
                                            defaultValue={this.state.society_member.orcid_id || ''}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputNewBox
                                            label="Membership ID"
                                            onChange={this.onChangeHandler}
                                            field='membership_id'
                                            placeholder="Membership ID"
                                            isRequired={true}
                                            defaultValue={this.state.society_member.membership_id || ''}
                                        />

                                        {this.state.society_member.membership_expiry && <OaDatePicker
                                            className="col-sm-4"
                                            label="Membership expiry"
                                            labelClass="col-sm-2"
                                            onChange={this.onChangeHandler}
                                            field='membership_expiry'
                                            placeholder="Membership expiry"
                                            showTime={false}
                                            format={format}
                                            defaultValue={moment(this.state.society_member.membership_expiry)}
                                        />
                                        }

                                        {!this.state.society_member.membership_expiry && <OaDatePicker
                                            className="col-sm-4"
                                            label="Membership expiry"
                                            labelClass="col-sm-2"
                                            onChange={this.onChangeHandler}
                                            field='membership_expiry'
                                            placeholder="Membership expiry"
                                            showTime={false}
                                            format={format}
                                        />
                                        }

                                    </div>
                                    <div className="form-group row">

                                        <InputNewBox
                                            label="fellow Affiliation"
                                            onChange={this.onChangeHandler}
                                            field='fellow_affiliation'
                                            placeholder="fellow affiliation"
                                            defaultValue={this.state.society_member.fellow_affiliation || ''}
                                        />

                                        <div className="col-md-6">
                                            {this.state.society_member.is_visible_to_other && <Checkbox defaultChecked={this.state.society_member.is_visible_to_other} onChange={this.onChangeChecked}>
                                                Click on checkbox to allow publishers to view Society members.                                            </Checkbox>}

                                            {!this.state.society_member.is_visible_to_other && <Checkbox defaultChecked={this.state.society_member.is_visible_to_other} onChange={this.onChangeChecked}>
                                                Click on checkbox to allow publishers to view Society members.                                            </Checkbox>}
                                        </div>
                                    </div>

                                    <div className="text-center m-t-15">
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={this.onSubmitHandler}
                                        >Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,

    society_member: state.SocietyMember.society_member || false,
    societies: state.society.societies || false,


})

const mapDispatchToProps = (dispatch) => ({
    createUpdateSocietyMember: (payload, id) => dispatch(createUpdateSocietyMember(payload, id)),
    getSocietyList: (payload) =>
        dispatch(getSocietyList(payload)),
})

export const EditSocietyMember = connect(mapStateToProps, mapDispatchToProps)(Add);
