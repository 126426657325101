import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { createUpdateOaDiscipline } from '../../../../actions';
import { PopupBox, Loading, InputBox, TextBox } from '../../../../../core/components/common';

class Add extends BaseComponent {

    state = {
        discipline: {},
    }

    componentDidMount() {
        this.setState((prevState) => {
            return Object.assign(prevState.discipline, this.state.params.discipline);
        })
    }

    /**
     * When component mounts, grab the params if there is any and setState
     */
    componentWillMount() {
        // when params sent via url
        if (this.props.history.location.state) {
            let params = this.props.history.location.state;
            this.setState({ params });
        }
    }

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.discipline, value);
        })
    }

    onSubmitHandler = async (e) => {
        e.preventDefault();

        let data = this.state.discipline;
        if (!data.name) {
            this.setState({
                isEmpty: <div>
                    {!data.name && <p>Discipline name must be filled</p>}
                </div>
            })
        } else {
            this.setState({
                isRegistering: true,
            });
            let id = this.props.match.params.id;

            await this.props.createUpdateOaDiscipline(data, id);

            this.cancelLoader();

            if (this.props.oa_discipline.success) {
                this.props.history.push('/disciplines/discipline-list')
            }

        }
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            isEmpty: false
        })
    }

    render() {
        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <HubContent title="Edit discipline">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                {/* LoadinG Handling */}
                {this.state.isRegistering && <Loading />}
                {/* LoadinG Handling */}
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card m-b-20">
                            <div className="card-body">

                                <form>
                                    <div className="form-group row">
                                        <InputBox
                                            label="Name"
                                            onChange={this.onChangeHandler}
                                            field='name'
                                            placeholder="Discipline name"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                            isRequired={true}
                                            defaultValue={this.state.discipline.name}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <TextBox
                                            label="Description"
                                            onChange={this.onChangeHandler}
                                            field='description'
                                            placeholder="Description"
                                            className="col-sm-9"
                                            labelClass="col-sm-3"
                                            defaultValue={this.state.discipline.description}
                                        />
                                    </div>

                                    <div className="text-center m-t-15">
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={this.onSubmitHandler}
                                        >Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    oa_discipline: state.OaDiscipline.oa_discipline || false,
})

const mapDispatchToProps = (dispatch) => ({
    createUpdateOaDiscipline: (payload, id) => dispatch(createUpdateOaDiscipline(payload, id)),
})

export const EditDiscipline = connect(mapStateToProps, mapDispatchToProps)(Add);
