import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { PopupBox }  from '../../../../../core/components/common';
import { DataTable } from '../../../../../core/components';
import { connect } from 'react-redux';
import moment from 'moment';

import { oADealListApproved,deleteOaDeal,oADealListApproval,oADealListReject } from '../../../../actions';


const format = 'Do MMM YYYY';


class DB extends BaseComponent {
    async componentDidMount(){
        await this.props.oADealListApproved();
    }
    state = {
        oaDealIds:[]
    }
    ids = [];
    onSelect=(id)=>{
        let index = this.ids.indexOf(id);
        (index !== -1) ? this.ids.splice(index, 1) : this.ids.push(id);
        this.setState({
            oaDealIds:this.ids.toString()
        })
    }
    onDeleteOaDealHander = async () => {
        const oAdealId = this.state.oAdealId;
        this.setState({
            waitingMsg: "Deleting..."
        })
        const journal = await this.props.deleteOaDeal(oAdealId);
        let data = this.state.data;
        let newData = data
        .filter(function(element) {
            return element.id !== oAdealId;
        });
        this.setState({
            waitingMsg: null,
            oAdealId: null,
            deleteConfirm: false,
            deleteStatus: journal.status,
            data:newData
        })
    }
    onApproval=async ()=>{
        let ids = this.state.oaDealIds;
        await this.props.oADealListApproval(ids);
        this.props.history.push('/oa-account/oa-account-list')
    }
    onReject=async ()=>{
        let ids = this.state.oaDealIds;
        await this.props.oADealListReject(ids);
        this.props.history.push('/oa-account/add-apc-fund')
    }
    onCancel = () => {
        this.setState({
            deleteConfirm: false,
            oAdealId: null,
            waitingMsg: null,
            deleteStatus: null
        })
    }
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();
    
        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        // }
      };
    render() {
        // const heading = ['organisation_name', 'deal_name', 'currency_name', 'vat_country_name']
        const data = [];
        this.props.oaDealList && this.props.oaDealList.map(item => {
            return data.push({
                id: item.id,
                Publisher: item.organisation_name,
                deal_type: item.deal_name,
                currency: item.currency_name,
                vat_country: item.vat_country_name,
                approval_status: item.approval_status,
                discount: item.discount ? parseFloat(item.discount).toFixed(2) + '%' : '-',
                expiry: item.expire_date ? moment(item.expire_date).format(format) : '-',
            })
        })

        const heading = ['Publisher', 'deal_type', 'currency', 'discount', 'expiry', 'vat_country']

        return (
            <HubContent title="List of OA deals" onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                {this.state.deleteConfirm && <PopupBox
                    title="Do you want to delete?"
                    YesText="Yes"
                    yesBtnType="danger"
                    type="danger"
                    NoBtnType="success"
                    onCancel={this.onCancel}
                    onPressOK={this.onDeleteOaDealHander}
                    waitingMsg={this.state.waitingMsg}
                />}
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                        {this.props.oaDealList &&
                            <DataTable
                                heading={heading}
                                data={data}
                                onSelect={this.onSelect}
                                noAction={true}
                            />
                        }
                        </div>
                    </div>
                </div>

            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    userId: state.auth.user.id || false,
    oaDealList: state.OaDeals.oadeal_approved_list || false,
    orgId: state.auth.user.organisation_id || false
})
const mapDispatchToProps = (dispatch) => ({
    oADealListApproved: () => dispatch(oADealListApproved()),
    deleteOaDeal: (id) => dispatch(deleteOaDeal(id)),
    oADealListApproval: (ids) => dispatch(oADealListApproval(ids)),
    oADealListReject: (ids) => dispatch(oADealListReject(ids)),
})

export const oaDealApproveList = connect(mapStateToProps, mapDispatchToProps)(DB);