import React from "react";
import { withRouter } from "react-router-dom";
import {
  getAllSocietyMemberList
} from "../../../../actions";
import { connect } from "react-redux";
import { Alert } from "antd";

import { SocietyMember } from "./SocietyMember";


class PaymentArticle extends React.Component {
  state = {}
  _isMounted= false
  componentDidMount() {
     this._isMounted =true
    this.apiDataList()
   }
  apiDataList = async () => {
    let memberData = await this.props.getAllSocietyMemberList();
    if (this._isMounted) {
      this.setState({
        memberData: memberData && memberData.results && memberData.results.map(item => ({ id: item.id, name: item.name })),
      })
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  addSocietyMember = e => {
    e.preventDefault();
    this.setState({
      isShow: true
    });
  };

  onClose = () => {
    this.setState({
      isShow: false,
    });
  };
  _renderSummery = data => {
    let summaryElements = [];
    data &&
      data.map((summary, index) => {
        if (summary.description !== "DRAW_BORDER") {
          return summaryElements.push(
            <div key={`summary-${index}`} className="row">
              <div
                className={` col-md-8 ${summary.bold ? "font-weight-bold" : null
                  }`}
                key={index}
              >
                <p>{summary.description}</p>
              </div>
              <div
                className={`col-md-4 text-right ${summary.bold ? "font-weight-bold" : null
                  }`}
              >
                <p>{summary.value !== "" ? summary.value : 0.00}</p>


              </div>
            </div>
          );
        } else {
          return summaryElements.push(
            <div
              key={`summary-${index}`}
              className="col-sm-12"
              style={{ clear: "both", width: "100%" }}
            ></div>
          );
        }
      });
    return summaryElements;
  };
  render() {
    const {
      title,
      summary,
      content_type_name,
      publisher_name,
      society_membership_info
    } = this.props.data;
    return (
      <div className="col-md-5 payment-right">
           <Alert
                    message="When there are multiple discounts for an article, OaMetrix will only apply the highest discount"
                    type="info"
                    showIcon
                  />
        <div className="payment-heading">
          <h3>

            {title}
          </h3>

          <ul>
            <li>Publisher : {publisher_name}</li>
            <li>Content type: {content_type_name}</li>
          </ul>
        </div>
        <div className="article-price">
          {this._renderSummery(summary)}
          <p>{society_membership_info && society_membership_info.code === 200 ? society_membership_info.message : null
          }</p>
          {((society_membership_info && society_membership_info.code === 200) && this.props.group === "AUTHOR") &&

            <button
              style={{ marginTop: 20 }}
              className="btn btn-primary mb-2"
              onClick={this.addSocietyMember}
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="mdi mdi-plus"></i>Add Membership
      </button>


          }


          {this.state.isShow &&
            <SocietyMember
              payment={this.props.id}
              data={this.state.memberData}
              isShow={this.state.isShow}
              onClose={this.onClose}

            />
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  group: (state.auth && state.auth.group) ,

});

const mapDispatchToProps = (dispatch) => ({
  getAllSocietyMemberList: () => dispatch(getAllSocietyMemberList()),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentArticle)
);


