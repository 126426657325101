import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { UpdateContentType, ClearStatus } from '../../../../actions';
import { PopupBox, Loading, InputBox } from '../../../../../core/components/common';

class Add extends BaseComponent {
    constructor(props) {
        super(props)
        this.error = props.failed;
        this.status = 100;
    }
    state = {
        contentType: {},
    }

    componentDidMount() {
        this.setState((prevState) => {
            return Object.assign(prevState.contentType, this.state.params.contentType );
        })
    }


    componentWillMount()
    {
        // when params sent via url
        if (this.props.history.location.state)
        {
            let params = this.props.history.location.state;
            this.setState({ params });
        }
    }

    
    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.contentType, value);
        })
    }

    onSubmitHandler = async (e) => {
        e.preventDefault();
        let data = this.state.contentType;
        await this.props.UpdateContentType(this.state.contentType.id,data);
        this.props.history.push('/content-type/content-type-list')
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        })
        await this.props.ClearStatus()
    }


    render() {
        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Bad Request"
                msg="Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <HubContent title="Update content type form">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}

                {/* LoadinG Handling */}
                {this.state.isRegistering && !this.state.status && <Loading />}
                {/* LoadinG Handling */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Update Content Type</a>
                                    </li>
                                </ul>

                                <form>
                                    <div className="form-group row" style={{marginTop:20}} >
                                        <InputBox
                                            label="Content type name"
                                            onChange={this.onChangeHandler}
                                            field='name'
                                            placeholder="Name of content"
                                            className="col-sm-10"
                                            defaultValue={this.state.contentType.name || ""}
                                        />
                                    </div>

                                    {this.props.msg &&
                                        <div className="form-group row">
                                            <span className="alert alert-success col-sm-12">{this.props.msg}</span>
                                        </div>
                                    }

                                    <div className="text-right m-t-15">
                                        <button
                                            className="btn btn-primary btn-lg w-25 waves-effect waves-light"
                                            onClick={this.onSubmitHandler}
                                        >Save</button>
                                    </div>
                                </form>
           
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    failed: state.journals.content_type || false,
})

const mapDispatchToProps = (dispatch) => ({
    UpdateContentType: (id,payload) => dispatch(UpdateContentType(id,payload)),
    ClearStatus: () => dispatch(ClearStatus())
})


export const EditContentType = connect(mapStateToProps, mapDispatchToProps)(Add);
