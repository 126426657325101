import React from "react";
import { Checkbox } from "antd";

class UserPermissionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedList:
        this.props.permissions && Array.isArray(this.props.permissions)
          ? this.props.permissions
          : [],
      indeterminate: true,
      checkAll: false,
      permissionValue: props.permissible_objects
        ? props.permissible_objects &&
        props.permissible_objects.map((item) => item.key)
        : [],

      groupKey: props.permissible_objects,
    };
  }

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? this.state.permissionValue : [],
    });
  };

  onCheckItem = (value) => (e) => {
    this.setState({
      checkedList: this.state.checkedList.includes(value)
        ? this.state.checkedList.filter((x) => x !== value)
        : [...this.state.checkedList, value],
    });
  };
  onSubmitHandler = () => {
    this.props.onSubmitHandler({
      checkedList: this.state.checkedList,
    });
  };

  render() {
    const { checkedList } = this.state;

    const authId =
      this.props.auth && this.props.auth.user && this.props.auth.user.id;

    return (
      <div className="user-permission-content">

        <div className="user-permission">
          {this.state.groupKey && this.state.groupKey.length>0 &&
                this.props.auth.group === "PUBLISHER" &&
            authId === this.props.userId ? (
            <div className="checkbox-all">
              <div className="permission-select-all">
                <Checkbox
                  indeterminate={
                    checkedList.length < this.state.permissionValue.length &&
                    checkedList.length > 0
                  }
                  disabled
                  onChange={this.onCheckAllChange}
                  checked={
                    checkedList.length === this.state.permissionValue.length
                  }
                ></Checkbox>
                Select all
              </div>
              <div className="publisher-checkbox-info">
                <p>List of allowed permissions</p>
              </div>
            </div>
          ) :this.props.auth.group !== "INSTITUTION" ?  (
            <div className="checkbox-all">
              <div className="permission-select-all">
                <Checkbox
                  indeterminate={
                    checkedList.length < this.state.permissionValue.length &&
                    checkedList.length > 0
                  }
                  onChange={this.onCheckAllChange}
                  checked={
                    checkedList.length === this.state.permissionValue.length
                  }
                ></Checkbox>
                Select all
              </div>
              <div className="publisher-checkbox-info">
                {" "}
                Click on the below checkboxes to allow appropriate permissions
              </div>
            </div>
          ):null}
          <div className="permission-checkbox">
            <div className="uermission-key">
              {this.state.groupKey &&
                this.state.groupKey.map((item, idx) => (
                  <div className="userpermission-content content_height" key={item.key}>
                    <div className="content-item d-flex justify-content-between">
                      <div className="item">
                        <label>{item.label}</label>
                        <p>{item.hint}</p>
                      </div>
                      <div className="item-checkbox mt-0">
                        {this.props.auth.group === "PUBLISHER" &&
                          authId === this.props.userId ? (
                          <Checkbox
                            key={item.key}
                            onChange={this.onCheckItem(item.key)}
                            checked={
                              checkedList && checkedList.includes(item.key)
                            }
                            disabled
                          >
                            {item.key}
                          </Checkbox>
                        ) : (
                          <Checkbox
                            key={item.key}
                            onChange={this.onCheckItem(item.key)}
                            checked={
                              checkedList && checkedList.includes(item.key)
                            }
                          >
                            {item.key}
                          </Checkbox>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              {authId !== this.props.userId ? (
                <div className="text-center m-t-15">
                  <button
                    className="btn btn-primary btn-md waves-effect waves-light"
                    onClick={this.onSubmitHandler}
                  >
                    {this.props.userId ? "Update User" : " Add User"}
                  </button>
                </div>
              ) : <div className="text-center m-t-15">
              <button
                className="btn btn-primary btn-md waves-effect waves-light"
                onClick={this.onSubmitHandler}
              >
                {this.props.userId ? "Update User" : " Add User"}
              </button>
            </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserPermissionForm;
