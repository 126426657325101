import React from "react";
import { connect } from "react-redux";
import BaseComponent from '../../../core/components/BaseComponent';
import {
    SelectBox,
    InputBox,
    OaDatePicker
} from "../../../core/components/common";
import SelectBoxName from "../../../core/components/common/SelectBoxName.jsx";
import {
    getPublicationListAll,
    getLicense,
    getDepartByFiltering,
    getAllCurrencyList
} from "../../actions";
import { filterStatus, filterDays  } from "../../components/views/hub/articles/data";
// import { serialize } from "../../../core/lib";


class Filter extends BaseComponent {
    state = {
        page: 1
    };

    async componentDidMount(){
        let filter_fields = this.props.filter_fields;
        let default_fields = filter_fields.filter((value)=>{
            return value.type === 'default';
        })
        this.isFilterValue(default_fields,'filter_publication') && await this.props.getPublicationListAll();
        this.isFilterValue(default_fields,'filter_licence') && await this.props.getLicense();
        this.isFilterValue(default_fields,'filter_department') && await this.props.getDepartByFiltering();
        this.isFilterValue(default_fields,'filter_currency') && await this.props.getAllCurrencyList();
    }

    

    filterData = async value => {
        await this.setState(prevState => {
            prevState.filterParams = Object.assign(prevState.filterParams, value);
        });
        // let filter = await serialize(this.state.filterParams);
    };

    toTitleCase = (str) => {
        return str.replace(/_/g, ' ').charAt(0).toUpperCase() + str.replace(/_/g, ' ').substr(1).toLowerCase();
    }
    
    filterFields=(data_array)=>{
        let selectboxArray = [];
        if( data_array.length > 0 ){
            data_array.forEach((value)=>{
                if (value.field_name && value.field_type && value.field_type === "date"){
                    selectboxArray.push(
                        <OaDatePicker
                            onChange={this.props.filterData}
                            field={value.field_name}
                            placeholder={value.placeholder || this.toTitleCase(value.field_name)}
                            className="col-md-3 mb-2"
                            defaultValue={ null}
                        />
                    )
                }else {
                    value.field_name && value.field_type && value.field_type === "text" ?
                        selectboxArray.push(<InputBox
                            className="col-md-3 mb-2"
                            onChange={ this.props.filterData }
                            field={value.field_name}
                            placeholder={value.placeholder || this.toTitleCase(value.field_name)}
                            defaultValue={""}
                        />
                        )
                    : value.field_name && selectboxArray.push(<SelectBox
                        className="col-md-3 mb-2"
                        field={value.field_name}
                        defaultValue="all"
                        disabled = { value.parent_field ? true : false }
                        data={ value.data }
                        onChange={ this.props.filterData }
                    />
                    )
                }
            })
        }
        return selectboxArray;
    }

    isFilterValue =(fields_array,fields_name)=>{
        return fields_array.some(item => item.field_name === fields_name);
    }
    
    // Example data list
    // let filter_fields = [
    //     {
    //       data:[
    //         {name: "All Demo One",id: "all"},
    //         {id:1,name:'demo_one_1'},
    //         {id:2,name:'demo_one_2'},
    //         {id:3,name:'demo_one_3'},
    //         {id:4,name:'demo_one_4'},
    //         {id:5,name:'demo_one_5'}
    //       ],
    //       field_name:'demo_one',
    //       is_dependent :true,
    //       has_child : false,
    //       child_field: 'demo_two'
    //     },
    //     {
    //       data:[  
    //         {name: "All Demo Two",id: "all"},        
    //         {id:1,name:'demo_two_1'},
    //         {id:2,name:'demo_two_2'},
    //         {id:3,name:'demo_two_3'},
    //         {id:4,name:'demo_two_4'},
    //         {id:5,name:'demo_two_5'}
    //       ],
    //       field_name:'demo_two',
    //       is_dependent : true,
    //       has_child : true,
    //       parent_field: 'demo_one'
    //     },
    //     {
    //       field_name: 'demo_three',
    //       field_type:'text'
    //     },
    //     {
    //       field_name: 'filter_status',
    //       type: 'default'
    //     },
    //     {
    //       field_name: 'filter_days',
    //       type: 'default'
    //     }
    // ]

    // [filter_status,filter_days,filter_department,filter_publication,filter_deal,filter_licence]

    render() {
        let filter_fields = this.props.filter_fields;
        
        let non_default_fields = filter_fields.filter((value)=>{
            return value.type !== 'default';
        })

        let default_fields = filter_fields.filter((value)=>{
            return value.type === 'default';
        })

        let department = [
            {
                name: "All Departments",
                id: "all"
            },
            {
                name: "No Department",
                id: "no_dept"
            }
        ];

        let licenses = [
            {
                name: "All Licences",
                id: "all"
            }
        ];

        let currencies = [
            {
                name: "All Currencies",
                id: "all"
            }
        ];

        let filterDeal = [
            { name: 'All Deals', id: 'all' },
            { name: 'OA Deal', id: 'deal' },
            { name: 'Non OA Deal', id: 'non-deal' },
        ];

        let concated_currency = currencies.concat(this.props.currency)
        let concated_licence = licenses.concat(this.props.licence_list)
        let concated_departments = department.concat(this.props.department_list)
        return (
            <div className="row mb-3">
                { this.isFilterValue(default_fields,'filter_status') && <SelectBox
                    className="col-md-3 mb-2"
                    field="filter_status"
                    defaultValue="all"
                    data={filterStatus}
                    onChange={this.props.filterData}
                />
                }
                { this.isFilterValue(default_fields,'filter_days') &&
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_days"
                        defaultValue=""
                        data={filterDays}
                        onChange={this.props.filterData}
                    />
                }

                { this.isFilterValue(default_fields,'filter_department') &&
                    <SelectBoxName
                        className="col-md-3 mb-2"
                        field="filter_department"
                        defaultValue="All Departments"
                        data={concated_departments}
                        onChange={this.props.filterData}
                    />
                }
                { this.isFilterValue(default_fields,'filter_publication') &&
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_publication"
                        defaultValue={this.state.filterParams.filter_publication || "all"}
                        data={
                            this.props.publication_list
                            ? [
                                { id: "all", name: "All Publications" },
                                ...this.props.publication_list
                                ]
                            : [{ id: "all", name: "All Publications" }]
                        }
                        onChange={this.props.filterData}
                    />
                }

                { this.isFilterValue(default_fields,'filter_deal') &&
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_deal"
                        defaultValue="all"
                        data={ filterDeal }
                        onChange={ this.props.filterData }
                    />
                }

                { this.isFilterValue(default_fields,'filter_licence') &&
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_licence"
                        defaultValue="all"
                        data={concated_licence}
                        onChange={this.props.filterData}
                    />
                }

                { this.isFilterValue(default_fields,'filter_currency') &&
                    <SelectBox
                        className="col-md-3 mb-2"
                        field="filter_currency"
                        defaultValue="all"
                        data={concated_currency}
                        onChange={this.props.filterData}
                    />
                }



                { this.filterFields(non_default_fields|| []) }

                { this.props.onClickFilter &&
                    <div className="col-md-3 mb-2">
                        <button
                            className="btn btn-primary btn-md waves-effect waves-light"
                            onClick={this.props.onFilter}
                            style={{  width:'100%' }}
                        >
                            Apply Filter
                        </button>
                    </div>
                }

            </div>
        )
    }
}

const mapStateToProps = state => ({
    publication_list: ( state.institutions && state.institutions.publication_list && state.institutions.publication_list.results ) || [],
    licence_list: (state.articles && state.articles.article_licence) || [],
    department_list: (state.institutions && state.institutions.department_types ) || [],
    currency: ( state.oadeal_currency && state.oadeal_currency.oadeal_currency ) || false
});

const mapDispatchToProps = dispatch => ({
    getPublicationListAll: payload => dispatch(getPublicationListAll(payload)),
    getLicense: () => dispatch(getLicense()),
    getDepartByFiltering: () => dispatch(getDepartByFiltering()),
    getAllCurrencyList: () => dispatch(getAllCurrencyList()),
});

export const DataFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);