import React from 'react';
import { connect } from 'react-redux'; 
import { SelectBox, InputBox, OaDatePicker, PopupBox } from '../../../../../core/components/common';
import moment from 'moment';
import { HubContent } from '../../../../../core/components';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { getAllCurrencyList, addUpdateApcFund,  } from '../../../../actions';


const format = 'Do MMM YYYY';

class AddApc extends BaseComponent {
    state = {
        date: false,
        apcFund: {}, 
    }
    componentDidMount() {
        this.props.getAllCurrencyList();
    }

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            Object.assign(prevState.apcFund, value)
        });

        if (value.hasOwnProperty('date')) {
            this.setState((prevState) => {
                Object.assign(prevState.apcFund, { date: moment(value.date, format) })
            })
        }
    }

    onSubmitHandler = async (e) => {
        let apcFund = this.state.apcFund;
        let newObject = {};

        Object.keys(apcFund).forEach(function (key) {
            var value = apcFund[key];
            if (key === 'date') {
                newObject[key] = moment(value).format('YYYY-MM-DD');
            } else {
                newObject[key] = value;
            }
        });

        if (!newObject.fund_source || !newObject.name || !newObject.currency) {
            this.setState({
                isEmpty: <div>
                    {!newObject.fund_source && <p>Fund source must be selected</p>}
                    {!newObject.name && <p>Funder or department must be filled</p>}
                    {!newObject.currency && <p>Currency must be selected</p>}
                </div>
            })
        } else { 
            
            await this.props.addUpdateApcFund(newObject);
            this.props.history.push('/apc-fund/apc-fund-list')
        }
    }

    cancelLoader = () => {
        this.setState({
            status: false,
            isEmpty: false
        });
    }

    render() { 
        
        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>
        
        return (
            <HubContent title="Add OA APC budget fund form">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Add OA APC budget fund details</a>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    <div className="tab-pane active p-3" id="" role="tabpanel">
                                    </div>

                                    <form action="">
                                        <div className="form-group row">
                                            <SelectBox
                                                label="Fund source"
                                                onChange={this.onChangeHandler}
                                                field='fund_source'
                                                data={this.props.fund_source_list}
                                                isRequired={false}
                                                defaultValue={this.state.fund_source}
                                            />
                                            <InputBox
                                                label="Name"
                                                onChange={this.onChangeHandler}
                                                field='name'
                                                placeholder="Enter funder or department name"
                                                defaultValue={this.state.Name}
                                            />
                                        </div>

                                        <div className="form-group row">
                                            <SelectBox
                                                label="Currency"
                                                onChange={this.onChangeHandler}
                                                field='currency'
                                                data={this.props.currency_list}
                                                isRequired={false}
                                                defaultValue={this.state.currency}
                                            />
                                            <InputBox
                                                label="Amount"
                                                onChange={this.onChangeHandler}
                                                field='amount'
                                                placeholder="Enter amount"
                                                defaultValue={this.state.amount}
                                                InputType="number"
                                            />
                                        </div>
                                        <div className="form-group row">
                                            <OaDatePicker
                                                label="Date"
                                                field="date"
                                                onChange={this.onChangeHandler}
                                                placeholder="Select date"
                                                showTime={false}
                                                format={format}
                                                defaultValue={this.state.date || moment(new Date(), format)}
                                            />
                                            <InputBox
                                                label="Name"
                                                onChange={this.onChangeHandler}
                                                field='user_name'
                                                placeholder="auto generated logged in user name"
                                                defaultValue={this.state.user_name}

                                            />
                                        </div>
                                        
                                        <div className="text-center m-t-15">
                                            <button onClick={this.onSubmitHandler} type="button" className="btn btn-primary waves-effect waves-light">Add APC Fund</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({ 
    fund_source_list: state.fund_source_list || [
                                                    { id: 'department', name: 'Department' },
                                                    { id: 'funder', name: 'Funder' }
                                                ],
    currency_list: state.OaDeals.oadeal_currency || false,
    apc_fund: state.apcFund.apc_fund || false, 
})

const mapDispatchToProps = (dispatch) => ({
    getAllCurrencyList: () => dispatch(getAllCurrencyList()),
    addUpdateApcFund: (payload) => dispatch(addUpdateApcFund(payload)),
})

export const AddApcFund = connect(mapStateToProps, mapDispatchToProps)(AddApc)