import React from 'react'
import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

const ToolTip = ({ labelInfoTitle }) => {
    return (
        <Tooltip title={labelInfoTitle}>
            <InfoCircleTwoTone style={{
                fontSize: 18,
                marginLeft: 15
            }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
            />
        </Tooltip>



    )
}


export const ToolTipField = ToolTip;
