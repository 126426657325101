import React from "react";
import { connect } from "react-redux";
// import { confirmAlert } from "react-confirm-alert"; // Import

import BaseComponent, { HubContent } from "../../../../../core/components";
import { DragAndDrop } from "../../../partials";
import { VATForm } from "./VATForm";
import { createVatConfigHub } from "../../../../actions";

class Add extends BaseComponent {
  onSubmitHandler = async data => {
    try {
      await this.props.createVatConfigHub(data);
      this.props.history.push("/vat/vat-list");
      if (this.props.vat_info) {
        this.props.history.push("/vat/vat-list");
      }
    } catch (error) { }
  };
  onFileDrop = payload => {
  
    // confirmAlert({
    //   title: "Upload book",
    //   message: "Are you sure, you want to upload file?",
    //   buttons: [
    //     {
    //       label: "Upload",
    //       onClick: () => this.props.articleFileUploadHandler(payload)
    //     },
    //     {
    //       label: "Cancel",
    //       onClick: () => {}
    //     }
    //   ]
    // });
  };

  render() {
    return (
      <HubContent title="Add VAT">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Enter VAT
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload VAT details
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <VATForm onSubmitHandler={this.onSubmitHandler} />
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <h4 className="mt-0 header-title">VAT information</h4>
                    <p className="text-muted m-b-30">
                      Please drop your file. The sequense of columns will be
                      Publisher name, address line 1, address line 2, City/Town,
                      County/State, Post or zip code, Country, website, VAT,
                      Note
                    </p>

                    <div className="m-b-30">
                      <DragAndDrop onDropHandler={this.onFileDrop} />
                    </div>

                    <div className="text-center m-t-15">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  vat_info: state.vat.vat_info || false
});

const mapDispatchToProps = dispatch => ({
  // createUpdateVat: (payload, id) => dispatch(createUpdateVat(payload, id)),
  createVatConfigHub: (payload, id) => dispatch(createVatConfigHub(payload))
});
export const AddVAT = connect(mapStateToProps, mapDispatchToProps)(Add);
