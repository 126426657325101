import React from 'react';

export class ValidationMsg extends React.Component {
    state = {
        errorObject: {},
        validationObject: {},
        msg: this.props.msg
    }
    componentDidMount() {
    }
    processErrorMessage(errorObject = null, validationObject = null) {
        // let referenceObject = validationObject;
        Object.keys(errorObject).forEach(function (key) {
            // let field_name = referenceObject[key] ? referenceObject[key] : key;
        });
    }
    render() {
        let errorObject = this.props.errorObject;
        let validationObject = this.props.validationObject;
        this.processErrorMessage(errorObject, validationObject);
        return (
            <div>
                {this.props.msg}
            </div>
        )
    }
}


