import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../../../core/components";
import { Checkbox } from "antd";
import {
  getOrgList,
  getOaDisciplineList,
  getOaSubjectList,
  getContentTypeList,
  getJournalTypeList,
  getPublicatonTypeList,
  getBookSeriesType,
} from "../../../../../actions";
import { Prices } from "../../../../partials/journal/prices";
import {
  SelectBox,
  InputNewBox,
  InputBox,
  Badge,
} from "../../../../../../core/components/common";

class JournalForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.baseState = this.state;
  }

  contactTypes = [
    {
      id: '',
      name: 'Select department',
    },
    {
      id: 'customer_service',
      name: 'Customer service',
    },
    {
      id: 'editorial',
      name: 'Editorial',
    },
    {
      id: 'finance',
      name: 'Finance',
    },
    {
      id: 'production',
      name: 'Production',
    },
    {
      id: 'sales',
      name: 'Sales',
    }
  ]

  state = {
    bookseries: {},
    prices: [],
    selected_content_type: [],
    content_type: [],
    publication_contacts: [
      {
        contact_department: undefined,
        contact_name: undefined,
        contact_email: undefined,
        is_email_signature: false,
        receive_payment_notification: false,
      }
    ],
    email_signature_index: null,
  };
  is_Mounted = false
  async componentDidMount() {
    this.is_Mounted = true
    await this.props.getOrgList();
    await this.props.getPublicatonTypeList();
    await this.props.getJournalTypeList();
    await this.props.getContentTypeList();
    await this.props.getBookSeriesType();
    if (this.is_Mounted) {
      this.setState({
        content_type: this.props.content_type,
      });
    }

  }

  onSubmitHandler = async (e) => {
    let bookseries = Object.assign(this.state.bookseries, {
      prices: this.state.prices,
      is_series: true,
      visible_to_publisher: true,
      source_pub_type_name: "BOOK_SERIES",
      publication_type:
        this.props.book_series_type && this.props.book_series_type[0].id,
        publication_contacts: this.state.publication_contacts,

    });

    this.props.onSubmitHandler(bookseries);
  };

  AddNewPrices = (e) => {
    e.preventDefault();
    this.setState({
      isPrices: true,
    });
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.bookseries, value);
    });
  };

  onClosePrice = () => {
    this.setState({
      isPrices: false,
      editing: false,
    });
  };

  onSavePriceHandler = (price_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.prices[prevState.editing] = price_info;
      } else {
        prevState.prices.push(price_info);
      }
      prevState.editing = false;
      prevState.isPrices = false;
      return prevState;
    });
  };

  onEditPrices = (id) => {
    this.setState({
      editing: id.toString(),
      isPrices: true,
    });
  };

  onDeletePrice = (id) => {
    this.setState((prevState) => {
      prevState.prices.splice(id, 1);
      return prevState;
    });
  };

  getSelectedContentTypeId() {
    let selectedPrices = this.state.prices;
    let selected = [];
    selectedPrices.forEach(function (element) {
      selected.push(element.content_type);
    });
    return selected;
  }

  onMultiSelectChangeHandler = (values) => {
    this.setState(
      {
        values,
        bookseries: Object.assign(this.state.bookseries, values),
      },
      async () => {
        if (values.hasOwnProperty("disciplines")) {
          if (values.disciplines.length > 0) {
            let payload = {
              discipline: values.disciplines.join(),
            };
            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }
        }
      }
    );
  };

  addNewPubContact = () => {
    let publicationContacts = this.state.publication_contacts

    publicationContacts.push({
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
      is_email_signature: false,
      receive_payment_notification: false,
    })

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  removePubContact = (index) => { 
    let publicationContacts = this.state.publication_contacts

    publicationContacts.splice(index, 1)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  renderPublicationContact = (publicationContacts) => { 
    let initialObj = {
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
      is_email_signature: false,
      receive_payment_notification: false,
    }

    if (!publicationContacts || !Array.isArray(publicationContacts)) {
      publicationContacts = [
        initialObj
      ]
    } else if (publicationContacts.length === 0) {
      publicationContacts.push(
        initialObj
      )
    }
    
    return publicationContacts.map((contact, index) => {
      return (
        <div key={index}>
         <div className="form-group row" style={{float:"right",marginLeft:10}}>
          <div 
            className=""
          >
              { index > 0 && <button 
                  type="button"
                  className="btn btn-danger mb-2"
                  onClick={() => this.removePubContact(index)}
                >
                  <i className="mdi mdi-close"></i>
                </button> }
          </div>
          </div>
         <div className="form-group row">

          <SelectBox
            labelInfo={false}
            labelInfoTitle="Select department"
            placeholder="Select department"
            label="Department"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_department"
            className="col-sm-4"
            labelClass="col-sm-2"
            data={this.contactTypes || []}
            defaultValue={contact.contact_department}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter contact name"
            placeholder="Enter contact name"
            label="Name"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_name"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_name}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter contact email"
            placeholder="Enter contact email"
            label="Email"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_email"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_email}
          />

          <div className="col-sm-6">
            <div className="row" style={{marginTop:15}}>
              <div
                className="col-form-label journal-contact-form col-sm-4"
                >

                <label

              >
                <Checkbox
                  onChange={(evt) => this.onPubContactCheckedChangeHandler(evt, index)}
                  checked={!!contact.receive_payment_notification}
                  name="receive_payment_notification"
                >
              
                </Checkbox>
              </label>
            </div>
            <div 
              className="col-sm-8"
            >
            
            <p> Receive payment notification email</p>
              
            </div>
            <div
                className="col-form-label journal-contact-form col-sm-4"
                >

                <label

              >
                <Checkbox
                  onChange={(evt) => this.onPubContactCheckedChangeHandler(evt, index)}
                  checked={!!contact.receive_invoice_notification}
                  name="receive_invoice_notification"
                >
              
                </Checkbox>
              </label>
            </div>
            <div 
              className="col-sm-8"
            >
            
            <p> Receive invoice notification email</p>
              
            </div>
            <div
                className="col-form-label journal-contact-form col-sm-4"
                >

              <label

              >
                <Checkbox
                  onChange={(evt) => this.onPubContactSignatureCheckedChangeHandler(evt, index)}
                  checked={!!contact.is_email_signature}
                  name="is_email_signature"
                >
              
                </Checkbox>
                </label>
              </div>
              <div 
                className="col-sm-8"
              >
              
              <p>Include my name in the signature of author notification emails</p>
                
              </div>
            </div>
            </div>

       
          </div>

        </div>
      )
    })
  }

  onPubContactChangeHandler = (value, index) => { 
    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }
  
  onPubContactCheckedChangeHandler = (evt, index) => {
    let value = {}

    let checked = evt.target.checked
    let name = evt.target.name 

    value[name] = checked

    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  onPubContactSignatureCheckedChangeHandler = (evt, index) => {
    let value = {}

    let checked = evt.target.checked
    let name = evt.target.name 

    value[name] = checked

    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    let email_signature_index = this.state.email_signature_index

    if (email_signature_index !== null && email_signature_index < publicationContacts.length) {
      let uncheckedValue = {}
      uncheckedValue[name] = false 
      Object.assign(publicationContacts[email_signature_index], uncheckedValue)
    }

    this.setState({
      publication_contacts: publicationContacts,
      email_signature_index: checked ? index : null,
    })
  }

  render() {
    let prices_id = this.getSelectedContentTypeId();
    return (
      <div className="tab-content">
        <div className="tab-pane active p-3" id="home-1" role="tabpanel"></div>
        <form>
          <div className="form-group row">
            <InputNewBox
              onChange={this.onChangeHandler}
              field="name"
              labelClass="col-sm-2"
              label="Title"
              className="col-sm-10"
              defaultValue={this.state.name}
              labelInfo={true}
              labelInfoTitle="Enter Book series, Monograph, Reference"
      
              placeholder="Enter Book series, Monograph, Reference"
            />
          </div>

          <div className="form-group row">
            <SelectBox
              label="Book type"
              labelClass="col-sm-2"
              labelInfo={true}
              labelInfoTitle="Select Book Type"
              placeholder="Select Book Type"
              onChange={this.onChangeHandler}
              field="journal_type"
              className="col-sm-4"
              data={this.props.journal_type}
              defaultValue={this.state.journal_type}
              isRequired={true}
            />
            <SelectBox
                        labelInfo={true}
                        labelInfoTitle="Select Discipline"
              label="Discipline"
              onChange={this.onMultiSelectChangeHandler}
              field="disciplines"
              className="col-sm-4"
              labelClass="col-sm-2"
              placeholder="Select Discipline"
              data={this.props.oa_disciplines &&this.props.oa_disciplines.results}
              defaultValue={this.state.disciplines}
              multiple={true}
            />
          </div>
          <div className="form-group row">
            <SelectBox
              placeholder="Select Subject"
                  labelInfo={true}
                  labelInfoTitle="Select Subject"
              label="Subject"
              onChange={this.onMultiSelectChangeHandler}
              field="subjects"
              className="col-sm-10"
              data={this.state.oa_subject_list}
              defaultValue={this.state.subjects}
              multiple={true}
            />
          </div>

          <div className="form-group row">
            <SelectBox
              placeholder="Select Publisher"
              labelInfo={true}
              labelInfoTitle="Select Publisher"
              label="Publisher"
              onChange={this.onChangeHandler}
              field="publisher"
              className="col-sm-4"
              data={this.props.orgs}
              defaultValue={this.state.publisher}
              isRequired={true}
            />

            <SelectBox
                placeholder="Select owner"
                labelInfo={true}
                labelInfoTitle="Select owner"
              label="Owner"
              onChange={this.onChangeHandler}
              field="owner"
              className="col-sm-4"
              data={this.props.orgs}
              defaultValue={this.state.owner}
            />
          </div>

          <div className="form-group row">
            <InputNewBox
                      labelInfo={true}
                      labelInfoTitle="Enter Acronym"
              label="Acronym"
              onChange={this.onChangeHandler}
              field="pub_acronym"
              className="col-sm-4"
              placeholder="Enter Acronym"
              defaultValue={this.state.pub_acronym}
            />
            <InputNewBox
              label="Site"
              labelInfo={true}
              labelInfoTitle="Enter Site"
              onChange={this.onChangeHandler}
              field="sub_sys_acronym"
              className="col-sm-4"
              placeholder="Enter Site"
              defaultValue={this.state.sub_sys_acronym}
            />
          </div>
          <div className="form-group row">
            <InputNewBox
                       labelInfo={true}
                       labelInfoTitle="Enter pISSN/pISBN"
              label="pISSN/pISBN"
              onChange={this.onChangeHandler}
              field="pissn"
              className="col-sm-4"
              placeholder="Enter p-ISSN/p-ISBN"
              defaultValue={this.state.pissn}
            />
            <InputNewBox
                   labelInfo={true}
                   labelInfoTitle="Enter e-ISSN/e-ISBN"
              label="eISSN/eISBN"
              onChange={this.onChangeHandler}
              field="eissn"
              className="col-sm-4"
              placeholder="Enter e-ISSN/e-ISBN"
              defaultValue={this.state.eissn}
            />
          </div>

          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                <button
                  className="btn btn-primary mb-2"
                  onClick={this.AddNewPrices}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="mdi mdi-plus"></i> Add price
                </button>
              </div>
            </div>
            <s style={{ borderBottom: "1px solid #375ba8", width: "100%" }}></s>
          </div>

          {this.state.isPrices && (
            <Prices
              isShow={this.state.isPrices} // if true modal will show up else it will be hidden
              onClose={this.onClosePrice} // Handle Close
              onSavePrices={this.onSavePriceHandler}
              value={this.state.prices[this.state.editing]}
              selected_array={prices_id}
            />
          )}

          <div className="form-group row">
            {this.state.prices.length > 0 &&
              this.state.prices.map((price, id) => {
                return (
                  <Badge
                    label={
                      (price.additional_info && price.additional_info.name) ||
                      price.content_type_name
                    }
                    onEdit={() => this.onEditPrices(id)}
                    onDelete={() => this.onDeletePrice(id)}
                    key={id}
                  />
                );
              })}
          </div>
          <div className="form-group row">
            <InputNewBox
                    labelInfo={true}
                    labelInfoTitle="Enter Editor-Manager"
              label="Editor-Manager"
              onChange={this.onChangeHandler}
              field="editor_manager"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Enter Editor-Manager"
              defaultValue={this.state.editor_manager}
            />

            <InputNewBox
                      labelInfo={true}
                      labelInfoTitle="Enter Editor-Support"
              label="Editor-Support"
              onChange={this.onChangeHandler}
              field="editor_support"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Enter Editor-Support"
              defaultValue={this.state.editor_support}
            />
          </div>
          <div className="form-group row">
            <InputNewBox
                    labelInfo={true}
                    labelInfoTitle="Enter Co-Support"
              label="Editor Co-Support"
              onChange={this.onChangeHandler}
              field="editor_co_support"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Editor Co-Support"
              defaultValue={this.state.editor_co_support}
            />

          </div>
          <div className="form-group row">
            <InputNewBox
              label="IF"
              onChange={this.onChangeHandler}
              field="impact_factor"
              labelInfo={true}
              labelInfoTitle="Enter Impact factor"
              className="col-sm-2"
              placeholder="Enter Impact factor"
              defaultValue={this.state.impact_factor}
            />

            <InputNewBox
                       labelInfo={true}
                       labelInfoTitle="Enter H-index"
              label="H-index"
              onChange={this.onChangeHandler}
              field="hindex"
              className="col-sm-2"
              placeholder="Enter H-index"
              defaultValue={this.state.hindex}
            />

            <InputNewBox
              label="Indexed"
              labelInfo={true}
              labelInfoTitle="Enter Indexed"
              onChange={this.onChangeHandler}
              field="indexed"
              className="col-sm-2"
              placeholder="Enter Indexed in"
              defaultValue={this.state.indexed}
            />
          </div>

          <div className="form-group row mt-2">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Publication contacts </label>
              </div>
              
            </div>
            <s style={{ borderBottom: "1px solid #375ba8", width: "100%" }}></s>

          </div>

          { this.renderPublicationContact(this.state.publication_contacts) }

          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Add Contacts </label>
              </div>
              
              <div style={{marginLeft:15,float:"left"}}>
                <button 
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={this.addNewPubContact}
                >
                  <i className="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
          </div>


          <div className="text-right m-t-15"></div>
          {this.props.msg && (
            <div className="form-group row">
              <span className="alert alert-success col-sm-12">
                {this.props.msg}
              </span>
            </div>
          )}
          <div className="text-right m-t-15">
            <div className="text-center m-t-15">
              <button
                onClick={this.onSubmitHandler}
                type="button"
                className="btn btn-primary waves-effect waves-light"
              >
                Add bookseries
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.app.groups,
  orgs: state.app.organizations,
  book_series_type: state.journals.book_series_type,
  journal_type: state.journals.journal_type,
  content_type: state.journals.content_type,
  oa_disciplines: state.OaDiscipline.oa_disciplines ,
  oa_subjects: state.OaSubject.oa_subjects ,
});

const mapDispatchToProps = (dispatch) => ({
  getBookSeriesType: () => dispatch(getBookSeriesType()),
  getOrgList: (payload) => dispatch(getOrgList(payload)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getJournalTypeList: (payload) => dispatch(getJournalTypeList(payload)),
  getPublicatonTypeList: (payload) => dispatch(getPublicatonTypeList(payload)),
  getContentTypeList: (payload) => dispatch(getContentTypeList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JournalForm);
