import React from "react";
import BaseComponent from "../BaseComponent.jsx";

class Card extends BaseComponent {
  render() {
    const props = this.props;

    const style = {
      minHeight: props.height || "auto",
      overflow: "hidden",
    };
    return (
      <div className={`${props.className || "col-xl-3 col-md-6"}`}>
        <div
          className={`${props.no_background ? props.no_background : "card"}`}
          style={style}
        >
          <div
            className={`${
              props.className === "col-md-8 top_journals_padding TopJournals" ||
              "col-md-4 no_background"
                ? "card-header text-white"
                : "card-header text-white text-center"
            }`}
          >
       
            <h6 className={`${props.color ? props.color : "m-0 p-0"}`}>
              {props.title}
            </h6>
       
          </div>
          <div className="card-body p-0">
            <div className="card-padding">{props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default Card;
