import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../core/components";
import { InputBox } from "../../../../core/components/common";
class Form extends BaseComponent {
  state = {
    isEmpty: false,
    addressCounter: 0,
    isShowPlus: true,
    isShowMinus: false,
    addresses: [],
  };

  incrementAddress = () => {
    if (this.state.addressCounter >= 0) {
      this.setState({
        addressCounter: 1,
        isShowPlus: !this.state.isShowPlus,
        isShowMinus: !this.state.isShowMinus,
      });
    }
  };

  decrementAddress = () => {
    if (this.state.addressCounter > 0) {
      this.setState((prevState) => {
        prevState.addressCounter = this.state.addressCounter - 1;
        prevState.addresses.pop();
        prevState.isShowMinus = false;
        prevState.isShowPlus = true;

        return prevState;
      });
    }
  };

  render() {
    let { prices, onChangeHandler } = this.props;
    let addresses = [];

    for (let i = 0; i < this.state.addressCounter; i++) {
      addresses.push(
        <div className="addresses" key={i}>
          <div className="form-group row">
            <InputBox
                                labelInfo={true}
                                labelInfoTitle="Enter Submission charge fees"
              onChange={onChangeHandler}
              field="submission_fee_gbp"
              label="Submission charge  "
              labelClass="col-sm-3 col-lg-2"
              className="col-sm-3"
              placeholder="GBP"
              defaultValue={prices.submission_fee_gbp}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="submission_fee_eur"
              className="col-sm-3"
              placeholder="EUR"
              defaultValue={prices.submission_fee_eur}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="submission_fee_usd"
              className="col-sm-3"
              placeholder="USD"
              defaultValue={prices.submission_fee_usd}
              InputType="number"
            />
          </div>
          <div className="form-group row">
            <InputBox
               labelInfo={true}
               labelInfoTitle="Enter Colour charge fees"
              onChange={onChangeHandler}
              field="colour_charge_gbp"
              labelClass="col-sm-3 col-lg-2"
              className="col-sm-3"
              label="Colour charge fees"
              placeholder="GBP"
              defaultValue={prices.colour_charge_gbp}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="colour_charge_eur"
              className="col-sm-3"
              placeholder="EUR"
              defaultValue={prices.colour_charge_eur}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="colour_charge_usd"
              className="col-sm-3"
              placeholder="USD"
              defaultValue={prices.colour_charge_usd}
              InputType="number"
            />
          </div>
          <div className="form-group row">
            <InputBox
              onChange={onChangeHandler}
              field="page_charge_gbp"
              className="col-sm-3 "
              labelInfo={true}
              labelInfoTitle="Enter Page charge fees"
              labelClass="col-sm-3  col-lg-2"
              label="Page charge fees"
              placeholder="GBP"
              defaultValue={prices.page_charge_gbp}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="page_charge_eur"
              className="col-sm-3"
              placeholder="EUR"
              defaultValue={prices.page_charge_eur}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="page_charge_usd"
              className="col-sm-3"
              placeholder="USD"
              defaultValue={prices.page_charge_usd}
              InputType="number"
            />
          </div>
          <div className="form-group row">
            <InputBox
               labelInfo={true}
               labelInfoTitle="Enter Other charge fees"
              onChange={onChangeHandler}
              field="other_charge_gbp"
              className="col-sm-3"
              label="Other charge fees"
              labelClass="col-sm-3  col-lg-2"
              placeholder="GBP"
              defaultValue={prices.other_charge_gbp}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="other_charge_eur"
              className="col-sm-3"
              placeholder="EUR"
              defaultValue={prices.other_charge_eur}
              InputType="number"
            />

            <InputBox
              onChange={onChangeHandler}
              field="other_charge_usd"
              className="col-sm-3"
              placeholder="USD"
              defaultValue={prices.other_charge_usd}
              InputType="number"
            />
          </div>
        </div>
      );
    }
    let CoAuthorInfo = prices && (
      <div className="addresses">
        <div className=" row">
          <div className="col-sm-12">
            <h3 className="profile-heading">Book Price</h3>
          </div>
        </div>

        <div className="form-group row">
          <InputBox
                            labelInfo={true}
                            labelInfoTitle="Enter APC charge fees"
            label="APC charge fees"
            onChange={onChangeHandler}
            field="pub_fee_gbp"
            labelClass="col-sm-3 col-lg-2"
            className="col-sm-3"
            placeholder="GBP"
            defaultValue={prices.pub_fee_gbp}
            InputType="number"
          />

          <InputBox
            onChange={onChangeHandler}
            field="pub_fee_eur"
            className="col-sm-3"
            placeholder="EUR"
            defaultValue={prices.pub_fee_eur}
            InputType="number"
          />
          <InputBox
            onChange={onChangeHandler}
            field="pub_fee_usd"
            className="col-sm-3"
            placeholder="USD"
            defaultValue={prices.pub_fee_usd}
            InputType="number"
          />
        </div>
        {addresses}
      </div>
    );
    return (
      <div className={`col-sm-12 col-lg-12 bg-white`}>
        {CoAuthorInfo}
        <div className="row">
          <div className="col-sm-12 col-lg-12 text-right">
            {this.state.isShowMinus && (
              <button
                className="btn btn-primary mr-2 btn-price"
                type="button"
                onClick={() => this.decrementAddress()}
              >
                <i className="mdi mdi-minus" style={{ fontSize: 20 }}></i>
              </button>
            )}
            {this.state.isShowPlus && (
              <button
                className="btn btn-primary btn-price"
                type="button"
                onClick={() => this.incrementAddress()}
              >
                <i className="mdi mdi-plus" style={{ fontSize: 20 }}></i>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  content_type: state.journals.content_type,
});

const mapDispatchToProps = (dispatch) => ({});

export const Prices = connect(mapStateToProps, mapDispatchToProps)(Form);
