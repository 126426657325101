import { 
    GET_INVOICE_TEMPLATE_LIST, 
    GET_INVOICE_TEMPLATE, 
    CREATE_UPDATE_INVOICE_TEMPLATE, 
    DELETE_INVOICE_TEMPLATE,
    UPLOAD_INVOICE_TEMPLATE,
} from "../../config";

const InvoiceTemplateReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_INVOICE_TEMPLATE_LIST: 
            return state = {
                ...state,
                invoice_templates: action.payload
            }
        case GET_INVOICE_TEMPLATE: 
            return state = {
                ...state,
                invoice_template: action.payload
            }
        case CREATE_UPDATE_INVOICE_TEMPLATE: 
            return state = {
                ...state,
                invoice_template: action.payload
            }
        case DELETE_INVOICE_TEMPLATE: 
            return state = {
                ...state,
            }
        case UPLOAD_INVOICE_TEMPLATE: 
            return state = {
                ...state,
                invoice_template_file_upload: action.payload,
            }
        default:
            return state;
    }
}

export default InvoiceTemplateReducer;
