import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BaseComponent from "../../../../../core/components";
import { SelectBox, InputBox } from "../../../../../core/components/common";
import {
    CreateSocietyMemberShip,
} from "../../../../actions";

class Form extends BaseComponent {
    state = {
        isShow: false,
        membership: {},
        isEmpty: false,
        value: []
    };

    onChangeHandler = value => {
        this.setState({
            ...value
        });
    };
    onCloseModal = e => {
        let overlay = document.getElementById("CoAuthorDialog");
        if (e.target === overlay) {
            this.props.onClose();
        }
    };

    onSubmit = async e => {
        let payload = {
            membership_id: this.state.membership_id,
            society: this.state.name
        };
        this.props.CreateSocietyMemberShip(payload)

        this.props.history.push(
            `/payment/addpayment/${this.props.payment}`
        );
        this.props.onClose()

    };

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        });
        await this.props.ClearStatus();
    };

    render() {
        let SocietyMemberForm = (
            <div className="addresses" style={{ paddingTop: 20 }}>
                <div className="form-group row">
                    <div className="col-md-12">

                        <SelectBox
                            onChange={this.onChangeHandler}
                            field="name"
                            className="col-sm-12"
                            data={this.props.data}
                            placeholder="Select Society name from dropdown"
                            isData={true}
                            defaultValue={this.state.name}
                            isRequired={true}
                        />
                    </div>
                    <div className="col-md-12 orgs-margin-top">

                        <InputBox
                            onChange={this.onChangeHandler}
                            field="membership_id"
                            className="col-sm-12"
                            placeholder="Enter Society Membership id"
                            defaultValue={this.state.membership_id}
                        />
                    </div>
                </div>
            </div>
        );

        return (
            <div
                onClick={this.onCloseModal}
                id="CoAuthorDialog"
                style={modal.overlay}
            >
                <div
                    className={`col-10 col-lg-8 memeber-author bg-white alert`}
                    style={modal.boxStyle}
                >
                    {SocietyMemberForm}
                    <div className="col-md-12 text-center">
                        <div className="modal-footer border-hide">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.onClose && this.props.onClose();
                                }}
                            >
                                Close
              </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.onSubmit}
                            >
                                Save
              </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
    boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
    boxHeight = "-webkit-fit-content";
}

const modal = {
    overlay: {
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.64)",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 1,
        display: "flex",
        alignItem: "center",
        justifyContent: "center"
    },
    boxStyle: {
        height: boxHeight
    }
};

const mapStateToProps = state => ({
    user: state.auth.user || {}
});

const mapDispatchToProps = dispatch => ({
    CreateSocietyMemberShip: payload =>
        dispatch(CreateSocietyMemberShip(payload)),

});

export const SocietyMember = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Form)
);
