import React from "react";
import { withRouter } from "react-router-dom"
class AddHocModel extends React.Component {
    state = {

        loading: false,

        message: false,
        cancel: false,

    };



    render() {
        let className = this.props.className ? this.props.className : "col-sm-4";

        return (
            <div
                style={{
                    position: "fixed",
                    zIndex: 1002,
                    backgroundColor: "rgba(0, 0, 0, 0.64)",
                    width: "100%",
                    height: "100%",
                    overflow: "scroll",
                    top: 0,
                    left: 0,
                }}
            >
                <div
                    className={`${className} text-center bg-white alert apc-request`}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        zIndex: 999999,
                        padding: 40,
                        transform: "translate(-36%, -45%)",
                        overflowY: "auto",
                    }}
                >

                    <p style={{ marginBottom: 25, fontSize: 16 }}>would you like to Add Hoc email </p>
                    {this.props.elgible && <p style={{ marginBottom: 25, fontSize: 16 }}>You are not eligible for refund </p>}

                    {this.props.elgible && <button className="add-btn-danger" type="danger" onClick={() => { }}>Submit</button>}

                    <button className="add-btn-primary" type="primary" onClick={() => this.props.history.push("/custom-email/custom-email")}>Yes</button>
                    <button className="add-btn-danger" type="danger" onClick={() => this.props.history.push("/oa-deal-activities/refund-request-list")}>No</button>
                </div>
            </div>);
    }
}


export default withRouter(AddHocModel)
