import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { DataTable, HubContent } from '../../../../../core/components';
import { getChildOrganisationList, } from '../../../../actions';
import { Loading } from '../../../../../core/components/common';


class UL extends BaseComponent {
    state = {
        orgId: null,
    }
    async componentDidMount() {
        this.getChildOrgData();
    }

    getChildOrgData = async (pageNum=1,page=10) =>{
        await this.props.getChildOrganisationList({pageNum:pageNum,pageSize:page});
        let orgObj = this.props.orgs;
        let dataOrg=[];
        orgObj.map((item)=>{
            return dataOrg.push({
                organization_name:item.name,
                email_domain:item.email_domain,
                grid_number:item.meta ? item.meta.grid_number : '',
                country:item.address ? item.address.country_name : '',
                id:item.id
            });
        })
        this.setState({
            data:dataOrg
        })
    }

    onView = (id) =>{
        this.props.history.push('/affiliated-organisations/single-org/'+id)
    }

    onCancel = () => {
        this.setState({
            orgId: null,
        })
    }
    pageChange =(pageNumber) =>{
        this.getChildOrgData(pageNumber);
    }  
     onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();
    
        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        // }
      };

    render() {
        const heading = ['organization_name', 'email_domain', 'grid_number', 'country'];
        return (
            <HubContent title="Affiliated organisation list " onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            {this.state.data ?
                                <DataTable
                                    heading={ heading }
                                    data={ this.state.data }
                                    onView={this.onView }
                                    isChildOrgList={true}
                                    count={this.props.count}
                                    pageChange={this.pageChange}
                                    pageSize={10}
                                />
                                : <Loading/>
                            }
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    orgs: state.app.organizations || false,
    count:state.orgs.org_list_count || false
})

const mapDispatchToProps = (dispatch) => ({
    getChildOrganisationList: (payload) => dispatch(getChildOrganisationList(payload)),
})

export const OrgList = connect(mapStateToProps, mapDispatchToProps)(UL);
