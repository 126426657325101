import config, {
  RECIEVED_LOGIN_DATA,
  FAILED_LOGIN_DATA,
  SET_USER_DATA,
  LOGOUT_USER,
  RECIEVED_SIGNUP_DATA,
  ACTIVATE_ACCOUNT,
  GET_GROUPS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../../config";
import { api } from "../../core/api";
import { history } from "../route";

let url;

// Failed Login Action Creator
export const failedLoginData = (payload) => ({
  type: FAILED_LOGIN_DATA, // eslint-disable-line
  payload,
});

// Post forogot password Action Creator
export const postForgotPasswordData = (payload) => ({
  type: FORGOT_PASSWORD, // eslint-disable-line
  payload,
});

// Post validate forogot password Action
export const validateForgotPasswordData = (payload) => ({
  type: RESET_PASSWORD, // eslint-disable-line
  payload,
});

// Create organisation auto approval status
export const postForgotPasswordRequest = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.oa_forgot_password;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(postForgotPasswordData(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Create organisation auto approval status
export const validateForgotPasswordRequest = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.validate_forgot_password;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(validateForgotPasswordData(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
export const setNewPassword = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.set_name_password;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(validateForgotPasswordData(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Successfull Login  Action Creator
export const recievedLoginData = (payload) => ({
  type: RECIEVED_LOGIN_DATA, // eslint-disable-line
  payload,
});

// Set User Data Action Creator
export const setUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
});

// Logout Action Creator
export const logoutUser = () => ({
  type: LOGOUT_USER,
});

// Sign Up Action Creator
export const recievedSignupData = (payload) => ({
  type: RECIEVED_SIGNUP_DATA,
  payload,
});

export const activateAccountData = (payload) => ({
  type: ACTIVATE_ACCOUNT,
  payload,
});

// Loaded into App Reducers
export const getGroups = (payload) => ({
  type: GET_GROUPS,
  payload,
});

// Login User
export const oaForgoPassword = (userData = {}) => {
  return (dispatch) => {
    return api.signinHandler(userData).then(
      (res) => {
        localStorage.setItem("auth", JSON.stringify(res.data));
        dispatch(
          recievedLoginData(Object.assign(res.data, { status: res.status }))
        );
        localStorage.setItem("auth", JSON.stringify(res.data));
      },
      (error) => {
        dispatch(
          failedLoginData(
            error.response
              ? Object.assign(error.response.data, {
                  status: error.response.status,
                })
              : { status: 500 }
          )
        );
      }
    );
  };
};

// Login User
export const fetchLoginUser = (userData = {}) => {
  return (dispatch, getState) => {
    let loginRequest = null;

    if (userData.is_proxy) {
      const token = getState().auth.token;
      loginRequest = api.proxySigninHandler(userData, token);
    } else {
      loginRequest = api.signinHandler(userData);
    }

    return loginRequest.then(
      (res) => {
        dispatch(
          recievedLoginData(Object.assign(res.data, { status: res.status }))
        );
        localStorage.setItem("auth", JSON.stringify(res.data));
      },
      (error) => {
        if (!userData.is_proxy) {
          dispatch(
            failedLoginData(
              error.response
                ? Object.assign(error.response.data, {
                    status: error.response.status,
                  })
                : { status: 500 }
            )
          );
        }
      }
    );
  };
};

// Sign Up User
export const requestSignUpUser = (userData = {}) => {
  const url = config.endpoint.public_registration;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(url, userData)
        .then(
          (res) => {
            dispatch(
              recievedSignupData(
                Object.assign(res.data, { status: res.status })
              )
            );
            resolve(res);
          },
          (error) => {
            dispatch(
              failedLoginData(
                Object.assign(error.response.data, {
                  status: error.response.status,
                })
              )
            );
          }
        )
        .catch((e) => {
          dispatch(failedLoginData(Object.assign({ status: 500 })));
        });
    });
  };
};

// Logout User
export const logoutUserData = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return new Promise((resolve, reject) => {
      api
        .logoutHandler(token)
        .then(
          (res) => {
            dispatch(logoutUser()); // Store Clearing
            localStorage.clear(); // LocalStorage clearing
            resolve(res);
          },
          (error) => {
            dispatch(logoutUser()); // Store Clearing
            localStorage.clear(); // LocalStorage clearing
            resolve(error);
          }
        )
        .catch((error) => {
          dispatch(logoutUser()); // Store Clearing
          localStorage.clear(); // LocalStorage clearing
          resolve(error);
        });
    });
  };
};

// Logout User

export const logoutProxyUserData = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return api
      .logoutHandler(token)
      .then((res) => {
        dispatch(
          recievedLoginData(Object.assign(res.data, { status: res.status }))
        );
        localStorage.setItem("auth", JSON.stringify(res.data));

        history.push("/");
      })
      .catch((error) => {
        dispatch(logoutUser()); // Store Clearing
        localStorage.clear(); // LocalStorage clearing
      });
  };
};

export const ChangePassword = (payload, token) => {
  return () => {
    url = config.endpoint.change_pwd;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload, token)
        .then(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
};

// Get Group List
export const getGroupList = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return new Promise((resolve, reject) => {
      api
        .getGroupHandler(token)
        .then((res) => {
          dispatch(getGroups(res.results));
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Activate Account
export const activateAccount = (payload) => {
  return () => {
    return new Promise((resolve, reject) => {
      api
        .activateHandler(payload)
        .then(
          (res) => {
            resolve(res.data);
          },
          (e) => {
            resolve(e);
          }
        )
        .catch((e) => {
          resolve(e);
        });
    });
  };
};

export const validateToken = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return new Promise((resolve, reject) => {
      api.validateTokenHandler(token).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          dispatch(logoutUser()); // Store Clearing
          localStorage.clear(); // LocalStorage clearing
        }
      );
    });
  };
};
