import { 
    GET_ALL_OA_POLICY,
    GET_ALL_OA_REQUIREMENT, 
    POST_OA_POLICY,
    POST_OA_REQUIREMENT,
    PUT_OA_POLICY,
    PUT_OA_REQUIREMENT,
    SINGLE_OA_POLICY,
    SINGLE_OA_REQUIREMENT
} from "../../config";

const OaPolicyReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_OA_POLICY: 
            return state = {
                ...state,
                oa_policy_list: action.payload
            }
        case GET_ALL_OA_REQUIREMENT: 
            return state = {
                ...state,
                oa_requirement_list: action.payload
            }
        case POST_OA_POLICY: 
            return state = {
                ...state,
                oa_policy_success: action.payload
            }
        case POST_OA_REQUIREMENT: 
            return state = {
                ...state,
                oa_requirement_success: action.payload
            }
        case PUT_OA_POLICY: 
            return state = {
                ...state,
                oa_policy_success: action.payload
            }
        case PUT_OA_REQUIREMENT: 
            return state = {
                ...state,
                oa_requirement_success: action.payload
            }
        case SINGLE_OA_POLICY: 
            return state = {
                ...state,
                oa_policy_single: action.payload
            }
        case SINGLE_OA_REQUIREMENT: 
            return state = {
                ...state,
                oa_policy_single: action.payload
            }
        default:
            return state;
    }
}
export default OaPolicyReducer;
