import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../../../../core/components';
import { getAllCountry } from '../../../actions';
import { InputBox, ModalSelectBox } from '../../../../core/components/common';
var _ = require('lodash');



class Form extends BaseComponent {
    state = {
        program: {},
    }

    componentDidMount() {
        this.props.getAllCountry();
        let program = this.props.program;
        this.setState((prevState) => {
            return Object.assign(prevState.program, program);
        })
    }

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.program, value);
        })
    }

    filterByKeys = (obj, keys = []) => {
        const filtered = {}
        keys.forEach((key) => {
            if (obj.hasOwnProperty(key)) {
                filtered[key] = obj[key]
            }
        })
        return filtered;
    }

    onSubmitHandler = async (e) => {
        e.preventDefault();
        let program = this.state.program;
        program = this.filterByKeys(program, ['name', 'coverage', 'exclude_countries'])
        await this.props.onSubmitHandler(program)
    }

    onEditHandler = async (e) => {
        e.preventDefault();
        await this.props.onSubmitHandler(this.state.program)
    }

    onModalSelectBoxShow = (fieldName) => {
        switch (fieldName) {
            case 'coverage':
                this.setState({
                    isShowCoverageModal: true,
                });
                break;
            case 'exclude_countries':
                this.setState({
                    isShowExcludeModal: true,
                });
                break;
            default:
                break;
        }
    }

    onModalSelectBoxClose = (e) => {
        this.setState({
            isShowCoverageModal: false,
            isShowExcludeModal: false
        });
    }

    showSelectItemsLabel = (fieldName) => {
        let fieldValues = this.state[fieldName];
        let labelText = '';
        switch (fieldName) {
            case 'coverage':
                if (fieldValues && fieldValues.length > 0) {
                    labelText = `${fieldValues.length} coverage selected`;
                } else {
                    labelText = `No coverage selected`;
                }
                break;
            case 'exclude_countries':
                if (fieldValues && fieldValues.length > 0) {
                    labelText = `${fieldValues.length} countries selected`;
                } else {
                    labelText = `No exclude countries selected`;
                }
                break;
            default:
                break;

        }
        return labelText;
    }

    onSaveSelectedItems = (fieldName, data) => {
        this.setState((prevState) => {
            return Object.assign(prevState.program, { [fieldName]: data });
        })
    }

    render() {
        let countries = this.props.countries;
        let includes = this.state.coverage;
        let exclude_countries = _.filter(countries, (v) => _.includes(includes, v.id));
        return (
            <div className="tab-content">
                <div className="tab-pane active p-3" id="home-1" role="tabpanel">
                </div>
                <form >
                    <div className="form-group row">
                        <InputBox
                            label="Name"
                            className="col-sm-10"
                            onChange={this.onChangeHandler}
                            field='name'
                            placeholder="Program name"
                            defaultValue={this.state.name}
                        />
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-6" style={{ position: 'relative', }}>
                            {
                                this.showSelectItemsLabel('coverage')
                            }
                            <button
                                onClick={() => this.onModalSelectBoxShow('coverage')}
                                className="btn btn-info waves-effect waves-light float-right"
                                type="button">
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        {this.state.isShowCoverageModal && this.state.program && <ModalSelectBox
                            title="Select coverage"
                            label="Coverage"
                            placeholder="Countries"
                            field='coverage'
                            className="col-sm-4"
                            data={this.props.countries}
                            defaultValue={this.state.program.coverage}
                            isRequired={false}
                            submitBtnTxt="Save"
                            onSubmit={this.onSaveSelectedItems}
                            onClose={() => this.onModalSelectBoxClose()}
                        />}
                        <div className="col-sm-6" style={{ position: 'relative', }}>
                            {
                                this.showSelectItemsLabel('exclude_countries')
                            }
                            <button
                                onClick={() => this.onModalSelectBoxShow('exclude_countries')}
                                className="btn btn-info waves-effect waves-light float-right"
                                type="button">
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        {this.state.isShowExcludeModal && <ModalSelectBox
                            title="Select exclude countries"
                            label="Exclude"
                            field='exclude_countries'
                            className="col-sm-4"
                            data={exclude_countries}
                            defaultValue={this.state.program.exclude_countries}
                            isRequired={false}
                            submitBtnTxt="Save"
                            placeholder="Countries"
                            onSubmit={this.onSaveSelectedItems}
                            onClose={() => this.onModalSelectBoxClose()}
                        />}
                    </div>
                    <div className="text-center m-t-15">
                        <button
                            className="btn btn-primary btn-md waves-effect waves-light"
                            onClick={this.props.program_id ? this.onSubmitHandler : this.onEditHandler}
                        > {this.props.program_id ? 'Edit program' : 'Add Program'} </button>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    countries: state.app.countries,
})

const mapDispatchToProps = (dispatch) => ({
    getAllCountry: () => dispatch(getAllCountry()),
})
export const EditProgramForm = connect(mapStateToProps, mapDispatchToProps)(Form);