import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../core/components";
import { Checkbox } from "antd";
import {
  getOrgList,
  getOaDisciplineList,
  getOaSubjectList,
  getContentTypeList,
  getJournalTypeList,
  getPublicatonTypeList,
  getSpecificJournalType,
  getPublicPublisher,
  getLicenseList, 
} from "../../../actions";
import { Prices } from "./prices";
import { SelectBox, InputBox, Badge } from "../../../../core/components/common";
import { api } from "../../../../core/api";

class Form extends BaseComponent {

  state = {
    journal: {},
    prices: [],
    selected_content_type: [],
    content_type: [],
    publication_contacts: [
      {
        contact_department: undefined,
        contact_name: undefined,
        contact_email: undefined,
        is_email_signature: false,
        receive_payment_notification: false,
      }
    ],
    email_signature_index: null,
  };

  contactTypes = [
    {
      id: '',
      name: 'Select department',
    },
    {
      id: 'customer_service',
      name: 'Customer service',
    },
    {
      id: 'editorial',
      name: 'Editorial',
    },
    {
      id: 'finance',
      name: 'Finance',
    },
    {
      id: 'production',
      name: 'Production',
    },
    {
      id: 'sales',
      name: 'Sales',
    }
  ]

  resetForm = () => {
    this.setState(this.baseState);
  };

  getSingle = (id) => {
    this.resetForm();
    api
      .get("publication/" + id + "/")
      .then(

        async (res) => { 

          let publicationContacts = res.publication_contacts || []
          let email_signature_index = publicationContacts.findIndex(item => item.is_email_signature === true)
          
          let payload = {
            discipline: res.disciplines.join(),
          };
          await this.props.getOaSubjectList(payload);

          this.setState({
            ...res,
            email_signature_index: email_signature_index >= 0 ? email_signature_index : null,
            oa_subject_list:
              (this.props.oa_subjects && this.props.oa_subjects.results) || [],
          });
        },
        (error) => {
        }
      )
      .catch();
  };



  componentDidMount() {
    this._isMouted = true
   this.apiDataList()
  }
  apiDataList = () => {
    const id = this.props.journal_id;

    this.props.getSpecificJournalType();
    this.props.getPublicPublisher();
    this.props.getOrgList();
    this.props.getOaDisciplineList();
    this.props.getPublicatonTypeList();
    this.props.getJournalTypeList();
    this.props.getContentTypeList();
    this.props.getLicenseList()

      this.setState({
        content_type: this.props.content_type,
      });

    if (id) {
      this.getSingle(id);
    }
  }

  onChangeOrcidHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.user_meta, value);
      return prevState;
    });
  };

  formData() {
    let data = {
      name: this.state.name,
      publication_type: this.state.publication_type,
      publisher: this.state.publisher,
      journal_type: this.state.journal_type,
      pissn: this.state.pissn,
      eissn: this.state.eissn,
      impact_factor: this.state.impact_factor,
      hindex: this.state.hindex,
      indexed: this.state.indexed,
      sherpa_romeo_info: this.state.sherpa_romeo_info,
      note: this.state.note,
      status: this.state.status,
      sub_sys_acronym: this.state.sub_sys_acronym,
      owner: this.state.owner,
      editor_manager: this.state.editor_manager,
      editor_support: this.state.editor_support,
      editor_co_support: this.state.editor_co_support,
      disciplines: this.state.journal.disciplines,
      subjects: this.state.journal.subjects,
      pub_acronym: this.state.journal.pub_acronym,
      publication_contacts: this.state.publication_contacts,
    };

    let Journal = Object.assign(data, { prices: this.state.prices });
    return Journal;
  }

  onSubmitHandler = async (e) => {
    e.preventDefault();
    let Journal = this.props.isEdit
      ? this.formData()
      : Object.assign(this.state.journal, {
        prices: this.state.prices,
        owner: this.props.auth && this.props.auth.user.organisation_id,
        publisher: this.props.auth && this.props.auth.user.organisation_id,
        publication_type:
          this.props.specific_journal_type &&
          this.props.specific_journal_type[0].id,
      });
    this.props.onSubmitHandler(Journal);
  };

  AddNewPrices = (e) => {
    e.preventDefault();
    this.setState({
      isPrices: true,
    });
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.journal, value);
    });
  };

  onCloseAuthor = () => {
    this.setState({
      isPrices: false,
      editing: false,
    });
  };

  addNewPubContact = () => {
    let publicationContacts = this.state.publication_contacts

    publicationContacts.push({
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
      is_email_signature: false,
      receive_payment_notification: false,
    })

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  removePubContact = (index) => { 
    let publicationContacts = this.state.publication_contacts

    publicationContacts.splice(index, 1)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  renderPublicationContact = (publicationContacts) => { 
    let initialObj = {
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
      is_email_signature: false,
      receive_payment_notification: false,
    }

    if (!publicationContacts || !Array.isArray(publicationContacts)) {
      publicationContacts = [
        initialObj
      ]
    } else if (publicationContacts.length === 0) {
      publicationContacts.push(
        initialObj
      )
    }
    
    return publicationContacts.map((contact, index) => {
      return (
        <div key={index}>
         <div className="form-group row" style={{float:"right",marginLeft:10}}>
          <div 
            className=""
          >
              { index > 0 && <button 
                  type="button"
                  className="btn btn-danger mb-2"
                  onClick={() => this.removePubContact(index)}
                >
                  <i className="mdi mdi-close"></i>
                </button> }
          </div>
          </div>
         <div className="form-group row">

          <SelectBox
            labelInfo={false}
            labelInfoTitle="Select department"
            placeholder="Select department"
            label="Department"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_department"
            className="col-sm-4"
            labelClass="col-sm-2"
            data={this.contactTypes || []}
            defaultValue={contact.contact_department}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter contact name"
            placeholder="Enter contact name"
            label="Name"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_name"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_name}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter contact email"
            placeholder="Enter contact email"
            label="Email"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_email"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_email}
          />

          <div className="col-sm-6">
            <div className="row" style={{marginTop:15}}>
              <div
                className="col-form-label journal-contact-form col-sm-4"
              >

                <label>
                  <Checkbox
                    onChange={(evt) => this.onPubContactCheckedChangeHandler(evt, index)}
                    checked={!!contact.receive_payment_notification}
                    name="receive_payment_notification"
                  >
                
                  </Checkbox>
                </label>
              </div>
              <div 
                className="col-sm-8"
              >
              
                <p> Receive payment notification email</p>
                
              </div>
              <div
                className="col-form-label journal-contact-form col-sm-4"
              >

                <label>
                  <Checkbox
                    onChange={(evt) => this.onPubContactCheckedChangeHandler(evt, index)}
                    checked={!!contact.receive_invoice_notification}
                    name="receive_invoice_notification"
                  >
                
                  </Checkbox>
                </label>
              </div>
              <div 
                className="col-sm-8"
              >
              
                <p> Receive invoice notification email</p>
                
              </div>

              <div
                className="col-form-label journal-contact-form col-sm-4"
              >

                <label>
                  <Checkbox
                    onChange={(evt) => this.onPubContactSignatureCheckedChangeHandler(evt, index)}
                    checked={!!contact.is_email_signature}
                    name="is_email_signature"
                  >
                
                  </Checkbox>
                  </label>
                </div>
                <div 
                  className="col-sm-8"
                >
                
                <p>Include my name in the signature of author notification emails</p>
                  
                </div>
              </div>
            </div>

       
          </div>

        </div>
      )
    })
  }

  onPubContactChangeHandler = (value, index) => { 
    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  onPubContactCheckedChangeHandler = (evt, index) => {
    let value = {}

    let checked = evt.target.checked
    let name = evt.target.name 

    value[name] = checked

    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  onPubContactSignatureCheckedChangeHandler = (evt, index) => {
    let value = {}

    let checked = evt.target.checked
    let name = evt.target.name 

    value[name] = checked

    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    let email_signature_index = this.state.email_signature_index

    if (email_signature_index !== null && email_signature_index < publicationContacts.length) {
      let uncheckedValue = {}
      uncheckedValue[name] = false 
      Object.assign(publicationContacts[email_signature_index], uncheckedValue)
    }

    this.setState({
      publication_contacts: publicationContacts,
      email_signature_index: checked ? index : null,
    })
  }
  
  onSavePriceHandler = (price_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.prices[prevState.editing] = price_info;
      } else {
        prevState.prices.push(price_info);
      }
      prevState.editing = false;
      prevState.isPrices = false;
      return prevState;
    });
  };

  onEditPrices = (id) => {
    this.setState({
      editing: id.toString(),
      isPrices: true,
    });
  };

  onDeletePrice = (id) => {
    this.setState((prevState) => {
      prevState.prices.splice(id, 1);
      return prevState;
    });
  };

  getSelectedContentTypeId() {
    let selectedPrices = this.state.prices;
    let selected = [];
    selectedPrices.forEach(function (element) {
      selected.push(element.content_type);
    });
    return selected;
  }

  onMultiSelectChangeHandler = (values) => {
    this.setState(
      {
        values,
        journal: Object.assign(this.state.journal, values),
      },
      async () => {
        if (values.hasOwnProperty("disciplines")) {
          if (values.disciplines.length > 0) {
            let payload = {
              discipline: values.disciplines.join(),
            };
            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }
        }
      }
    );
  };

  priceLabel = (price) => {
    let priceLabel = ''

    if (price.additional_info && price.licence_info) {
      priceLabel = `${price.additional_info.name} (${price.licence_info.name})`
    } else if (price.additional_info) {
      priceLabel = `${price.additional_info.name}`
    } else if (price.content_type_name && price.licence_name) {
      priceLabel = `${price.content_type_name} (${price.licence_name})`
    } else {
      priceLabel = `${price.content_type_name}`
    }

    return priceLabel
  }

  render() {
    let prices_id = this.getSelectedContentTypeId();
    return (
      <div className="tab-content">
        <form>
          <div className="form-group row">
            <InputBox
                         labelInfo={true}
                         labelInfoTitle="Enter Journal name"
              label="Journal name"
              onChange={this.onChangeHandler}
              field="name"
              className="col-sm-10"
              placeholder="Enter Journal name"
              defaultValue={this.state.name}
              isRequired={true}
            />
          </div>
          <div className="form-group row">
            <SelectBox
                       labelInfo={true}
                       labelInfoTitle="Select Journal type"
              label="Journal type"
              onChange={this.onChangeHandler}
              field="journal_type"
              className="col-sm-4"
              placeholder="Select Journal type"
              data={this.props.journal_type}
              defaultValue={this.state.journal_type}
              isRequired={true}
            />
            <SelectBox
              labelInfo={true}
              labelInfoTitle="Select Disciplines"
              placeholder="Select Disciplines"

              label="Disciplines"
              onChange={this.onMultiSelectChangeHandler}
              field="disciplines"
              className="col-sm-4"
              data={this.props.oa_disciplines&&this.props.oa_disciplines.results}
              defaultValue={this.state.disciplines}
              isRequired={true}
              multiple={true}
            />
          </div>

          <div className="form-group row">
            <SelectBox
              labelInfo={true}
              labelInfoTitle="Select Subjects"
              placeholder="Select Subjects"
                
              label="Subjects"
              onChange={this.onMultiSelectChangeHandler}
              field="subjects"
              className="col-sm-10"
              data={this.state.oa_subject_list}
              defaultValue={this.state.subjects}
              isRequired={true}
              multiple={true}
            />
          </div>

          <div className="form-group row">



<label               className="col-form-label  col-sm-2"
                        >
                                    Publisher
                        </label>
                                    <p className="col-sm-4" style={{marginTop:10}}>
                                    {this.props.auth.user.organisation_name}  
                        </p>

<label               className="col-form-label  col-sm-2"
                        >
                                    Owner
                        </label>
                                    <p className="col-sm-4" style={{marginTop:10}}>
                                    {this.props.auth.user.organisation_name || this.state.owner}  
                        </p>
                                
          </div>

          <div className="form-group row">
            <InputBox
                            labelInfo={true}
                            labelInfoTitle="Enter Acronym"
                            placeholder="Enter Acronym"
              label="Acronym"
              onChange={this.onChangeHandler}
              field="pub_acronym"
              className="col-sm-4"
 
              defaultValue={this.state.pub_acronym}
            />
            <InputBox
              label="Site"
              labelInfo={true}
              labelInfoTitle="Enter Site"
              placeholder="Enter Site"
              onChange={this.onChangeHandler}
              field="sub_sys_acronym"
              className="col-sm-4"
         
              defaultValue={this.state.sub_sys_acronym}
            />
          </div>
          <div className="form-group row">
            <InputBox
              label="pISSN"
              onChange={this.onChangeHandler}
              field="pissn"
              labelInfo={true}
              labelInfoTitle="Enter p-ISSN"
              placeholder="Enter p-ISSN"
              className="col-sm-4"
            
              isRequired={true}
              defaultValue={this.state.pissn}
            />
            <InputBox
              label="eISSN"
              onChange={this.onChangeHandler}
              field="eissn"
              className="col-sm-4"
              labelInfo={true}
              labelInfoTitle="Enter eISSN"
              placeholder="Enter eISSN"
              isRequired={true}
              defaultValue={this.state.eissn}
            />
          </div>

          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                <button
                  className="btn btn-primary mb-2"
                  onClick={this.AddNewPrices}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="mdi mdi-plus"></i> Add price
                </button>
              </div>
            </div>
            <s style={{ borderBottom: "1px solid #375ba8", width: "100%" }}></s>
          </div>

          {/* Co Author Form Modal */}
          {this.state.isPrices && (
            <Prices
              className="col-10 col-sm-10 col-md-10"
              isShow={this.state.isPrices} // if true modal will show up else it will be hidden
              onClose={this.onCloseAuthor} // Handle Close
              onSavePrices={this.onSavePriceHandler}
              value={this.state.prices[this.state.editing]}
              selected_array={prices_id}
            />
          )}
          {/* Co Author Form Modal End */}

          <div className="form-group row">
            {this.state.prices.length > 0 &&
              this.state.prices.map((price, id) => {
                return (
                  <div key={id}>   <Badge
                    label={
                      this.priceLabel(price)
                    }
                    onEdit={() => this.onEditPrices(id)}
                    onDelete={() => this.onDeletePrice(id)}
                  /></div>
                );
              })}
          </div>
          <div className="form-group row">
            <InputBox
              labelInfo={true}
              labelInfoTitle="Enter Editor Manager"
              placeholder="Enter Editor Manager"
              label="Editor-Manager"
              onChange={this.onChangeHandler}
              field="editor_manager"
              className="col-sm-4"
              defaultValue={this.state.editor_manager}
            />

            <InputBox
              labelInfo={true}
              labelInfoTitle="Enter Editor Support"
              placeholder="Enter Editor Support"
              label="Editor-Support"
              onChange={this.onChangeHandler}
              field="editor_support"
              className="col-sm-4"
              defaultValue={this.state.editor_support}
            />
          </div>
          <div className="form-group row">
            <InputBox
              labelInfo={true}
              labelInfoTitle="Enter Editor Co-support"
              placeholder="Enter Editor Co-support"
              label="Co-support"
              onChange={this.onChangeHandler}
              field="editor_co_support"
              className="col-sm-4"
    
              defaultValue={this.state.editor_co_support}
            />
            <InputBox
              label="IF"
              labelInfo={true}
              labelInfoTitle="Enter Impact Factor"
              placeholder="Enter Impact Factor"
              onChange={this.onChangeHandler}
              field="impact_factor"
              className="col-sm-4"
              defaultValue={this.state.impact_factor}
            />
          </div>
          <div className="form-group row">
            <InputBox
              labelInfo={true}
              labelInfoTitle="Enter h-index"
              placeholder="Enter h-index"
              label="H-index"
              onChange={this.onChangeHandler}
              field="hindex"
              className="col-sm-4"
              defaultValue={this.state.hindex}
            />

            <InputBox
              labelInfo={true}
              labelInfoTitle="Enter Indexed in"
              placeholder="Enter Indexed in"
              label="Indexed"
              onChange={this.onChangeHandler}
              field="indexed"
              className="col-sm-4"
              defaultValue={this.state.indexed}
            />
          </div>
          <div className="form-group row">
            <InputBox
                labelInfo={true}
                labelInfoTitle="Enter Status"
                placeholder="Enter Status"
              label="Status"
              onChange={this.onChangeHandler}
              field="status"
              className="col-sm-4"
              defaultValue={this.state.status}
            />
          </div>


          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                Publication contacts
              </div>
              
              <div className="float-right">
                <button 
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={this.addNewPubContact}
                >
                  <i className="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
            <s style={{ borderBottom: "1px solid #375ba8", width: "100%" }}></s>
          </div>

          { this.renderPublicationContact(this.state.publication_contacts) }

          <div className="text-right m-t-15"></div>
          {this.props.msg && (
            <div className="form-group row">
              <span className="alert alert-success col-sm-12">
                {this.props.msg}
              </span>
            </div>
          )}

          <div className="text-right m-t-15">
            <div className="text-center m-t-15">
              <button
                onClick={this.onSubmitHandler}
                type="button"
                className="btn btn-primary waves-effect waves-light"
              >
                {" "}
                {this.props.isEdit ? "Update journal" : "Add journal"}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  orgs: state.app.organizations,
  journal_type: state.journals.journal_type,
  specific_journal_type: state.journals.specific_journal_type,
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  oa_disciplines: state.OaDiscipline.oa_disciplines ,
  oa_subjects: state.OaSubject.oa_subjects ,
  publishers: state.orgs.publisher_list ,
  licence:
    (state.articles &&
      state.articles.licence_list &&
      state.articles.licence_list.results),
});

const mapDispatchToProps = (dispatch) => ({
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getOrgList: (payload) => dispatch(getOrgList(payload)),
  getSpecificJournalType: (payload) =>
    dispatch(getSpecificJournalType(payload)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getJournalTypeList: (payload) => dispatch(getJournalTypeList(payload)),
  getPublicatonTypeList: (payload) => dispatch(getPublicatonTypeList(payload)),
  getContentTypeList: (payload) => dispatch(getContentTypeList(payload)),
  getLicenseList: () => dispatch(getLicenseList()),
});

export const JournalForm = connect(mapStateToProps, mapDispatchToProps)(Form);
