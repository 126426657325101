import React from "react";
import { connect } from "react-redux";
import { AutoComplete, } from "antd";
import BaseComponent, { HubContent } from "../../../../../core/components";
import moment from "moment"
import {
    createAuthorRefundRequest,
    searchArticleFull,
    getAuthorRefundRequestInfo
} from "../../../../actions";

import {
    Loading,
    TextBox,
    InputBox,
    PopupBox

} from '../../../../../core/components/common';

export class RefundRequestForm extends BaseComponent {
    state = {}


    cancelLoader = async () => {
        this.setState({
            isEmpty: false,
            successMsg: false,
        })
    }



    onSelect = async (value, item) => {
        await this.props.getAuthorRefundRequestInfo(item.id)
        if (this.props.author_refund_request && this.props.author_refund_request.refund_info !== null) {
            this.setState({
                article_id: item.id,
                amount: this.props.author_refund_request && this.props.author_refund_request.refund_info && this.props.author_refund_request.refund_info.amount

            });
        } else {
            this.setState({
                msg: "Reunfd is not included"
            });
        }
    };

    onSearch = (value) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            let params = {
                q: value,
            };

            this.props.searchArticleFull(params).then(response => {
                if (response && response.success) {
                    this.setState({
                        dataList: response.results && response.results.map(item => ({
                            id: item.id,
                            value: item.title,
                        }))
                    });
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });

        }, 200);
    };

    onChangeHandler = (value) => {

        if (value && value.context === 'ARTICLE') {

            this.props.searchArticleFull({}).then(response => {
                if (response && response.success) {
                    this.setState({
                        dataList: response.results && response.results.map(item => ({
                            id: item.id,
                            value: item.title,
                        }))
                    });
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });
        }

        this.setState((prevState) => {
            return Object.assign(prevState, value);
        });
    };

    onSubmitHandler = async (e) => {
        if (this.props.author_refund_request && this.props.author_refund_request.refund_info !== null) {
            let data = {
                article: this.props.author_refund_request && this.props.author_refund_request.id,
                request_note: this.state.request_note,
                currency: this.props.author_refund_request && this.props.author_refund_request.refund_info && this.props.author_refund_request.refund_info.currency,
                requested_amount: this.state.amount,
                date_created: moment(
                    new Date())
            };
            this.props.createAuthorRefundRequest(data)
            this.props.history.push("/author-refund/author-refund-request-list")

        }

        // if (!data.context || !data.subject || !data.recipients || !data.message_body) {
        //     this.setState({
        //         isEmpty: <div>
        //             {!data.context && <p>Context is required.</p>}
        //             {(data.context === 'ARTICLE' && !data.article_id) && <p>Context is required.</p>}
        //             {!data.recipients && <p>Recipient is required.</p>}
        //             {!data.subject && <p>Subject is required.</p>}
        //             {!data.message_body && <p>Message is required.</p>}
        //         </div>
        //     })
        // } else {

        //     this.props.createAuthorRefundRequest(data).then(response => {
        //         if (response.data && response.data.success) {
        //             this.setState({
        //                 showCustomEmailForm: false,
        //                 successMsg: response.data.message,
        //                 context: '',
        //                 article_id: '',
        //                 subject: '',
        //                 recipients: '',
        //                 message_body: '',
        //             })
        //             this.props.history.push("/author-custom-email/author-custom-email-list")
        //         }
        //     }).catch(error => {
        //         return error.response && error.response.data && error.response.data.success;
        //     });
        // }
    };

    onCustomEmailButtonClick = () => {
        this.setState({
            showCustomEmailForm: true,
        })
    }

    renderCustomEmailButton = () => {
        return (
            <li className="d-none d-sm-block pr-3">
                <div className="pt-3">
                    <button onClick={this.onCustomEmailButtonClick} className="btn btn-info w-md waves-effect waves-light" type="button"><b>Custom email</b></button>
                </div>
            </li>
        )
    }

    renderCustomEmailForm = () => {
        return (
            <form className="apc-form">
                {this.state.loading && <Loading />}

                {/* <div className="form-group row">
                    <SelectBox
                        placeholder="Select from dropdown"
                        label="Refund Fund"
                        onChange={this.onChangeHandler}
                        field="context"
                        className="col-sm-10"
                        data={[{ id: 'ARTICLE', name: 'Article' }, { id: 'NON_ARTICLE', name: 'Non Article' }]}
                        defaultValue={this.state.context}
                        isRequired={true}
                    />
                </div> */}


                <div className="form-group row">

                    <label className="col-form-label col-sm-2">
                        Article
                  <span className="text-danger"> * </span>
                    </label>

                    <div className="col-sm-10">

                        <AutoComplete
                            defaultValue={this.state.article_id}
                            notFoundContent="No found Data"
                            options={this.state.dataList || []}
                            style={{
                                width: "100%",
                            }}
                            onSelect={(val, option) => this.onSelect(val, option)}
                            onSearch={this.onSearch}
                            onChange={this.onChangeHandler}
                            placeholder="Type your article name"
                        />
                    </div>
                </div>


                <div className="form-group row">
                    <InputBox
                        label="Amount"
                        onChange={this.onChangeHandler}
                        className="col-sm-10"
                        field="amount"
                        disabled={true}
                        placeholder="Enter Amount"
                        defaultValue={this.state.amount}
                        isRequired={true}
                        InputType="number"
                    />
                </div>

                <div className="form-group row">
                    <TextBox
                        rows={8}
                        label="Message"
                        onChange={this.onChangeHandler}
                        field="request_note"
                        className="col-sm-10"
                        placeholder="Enter your email message"
                        defaultValue={this.state.request_note}
                        isRequired={true}
                    />
                </div>
            </form>
        )
    }

    onCancel = () => {
        this.setState({
            isHide: false
        })
    }
    render() {

        return (
            <HubContent title="Send Refund Request">
                {this.state.isHide && <PopupBox msg={this.state.msg} onCancel={this.onCancel} />}
                <div className="card m-b-20">
                    <div className="card-body">

                        <div className="modal-body">
                            {this.renderCustomEmailForm()}

                        </div>

                        <div className="text-center"> <button disabled={this.props.author_refund_request && this.props.author_refund_request.refund_info !== null ? false : true} type="button" className="btn btn-primary"
                            onClick={this.onSubmitHandler}
                        >Send</button></div>
                    </div>
                </div>

            </HubContent >
        );
    }
}

const mapStateToProps = (state) => ({
    author_refund_request: state.RefundRequest.author_refund_request_info,
    auth: state.auth,
    group: state.auth.user && state.auth.group,
    kind: state.auth.user && state.auth.user.kind,
    token: state.auth.token,
    articles: state.articles.article_full,
});

const mapDispatchToProps = (dispatch) => ({
    createAuthorRefundRequest: (payload = {}) => dispatch(createAuthorRefundRequest(payload)),
    searchArticleFull: (payload = {}) => dispatch(searchArticleFull(payload)),
    getAuthorRefundRequestInfo: (id) => dispatch(getAuthorRefundRequestInfo(id))
});

export const RefundRequest = connect(mapStateToProps, mapDispatchToProps)(RefundRequestForm);
