import config, {
	GET_AUTHOR_APC_REQUEST,
	GET_APC_REQUEST,
	GET_AUTHOR_APC_REQUEST_DOWNLOAD,
} from "../../config";
import { api } from "../../core/api";

export const getApcRequestsReducer = (payload) => ({
	type: GET_AUTHOR_APC_REQUEST,
	payload,
});
export const getApcRequestsDownload = (payload) => ({
	type: GET_AUTHOR_APC_REQUEST_DOWNLOAD,
	payload,
});
export const ApcRequestsAction = () => ({
	type: GET_APC_REQUEST,
});

// Get Author Apc Fund Request
export const getAllApcRequests = (payload) => {
	let url = config.endpoint.author_apc_fund_request;
	if (payload.pageNum) {
		url += "?page=" + payload.pageNum;
	}

	if (payload.hasOwnProperty('actionable')) {
		url += "&actionable=" + payload.actionable;
	}

	return (dispatch) => {

		return new Promise((resolve, reject) => {
			dispatch(ApcRequestsAction());

			api.get(url)
				.then((res) => {
					dispatch(getApcRequestsReducer(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {
					dispatch(getApcRequestsReducer(error && error.response && error.response.data));
					resolve(Object.assign(error, { status: false }));
				});
		});
	};
};

export const getAllApcRequestsDownload = () => {
	const url = `${config.endpoint.author_apc_fund_request}download?fields=date,title,licence_name,author&page_size=999999`;
	return (dispatch, getState) => {
		const token = getState().auth.token;
		let options = {
			responseType: "blob",
		};
		return new Promise((resolve, reject) => {
			api.get(url, token, options)
				.then((res) => {
					dispatch(getApcRequestsDownload(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {
					reject(Object.assign(error.response, { status: false }));
				});
		});
	};
};


// Mutate APC Request status
export const mutateApcRequestStatus = (ids, action, data = {}) => {
	return (dispatch) => {
	  let url = config.endpoint.author_apc_fund_request + `${ids}/mutate/${action}/`;
  
	  return new Promise((resolve, reject) => {
		api
		  .put(url, data)
		  .then((res) => {
			resolve(Object.assign(res, { status: true }));
		  })
		  .catch((e) => {
			reject(e);
		  });
	  });
	};
};
