import config, {
  CREATE_EMAIL_MESSAGE,
  GET_EMAIL_MESSAGE,
  GET_EMAIL_MESSAGE_LIST,
  GET_DEFAULT_EMAIL_MESSAGE,
  GET_EMAIL_TAG,
  GET_EMAIL_EDIT,
  REQUEST_EMAIL_MESSAGE_LIST,
  REQUEST_EMAIL_UPDATE,
} from "../../config";
import { api } from "../../core/api";
export const Emailtaglist = (payload) => ({
  type: GET_EMAIL_TAG,
  payload,
});

export const getDefaultEmailMessageAction = (payload) => ({
  type: GET_DEFAULT_EMAIL_MESSAGE,
  payload,
});

export const createEmailMessageAction = (payload) => ({
  type: CREATE_EMAIL_MESSAGE,
  payload,
});

export const getEmailMessageAction = (payload) => ({
  type: GET_EMAIL_MESSAGE,
  payload,
});

export const successGetEmailMessageListAction = (payload) => ({
  type: GET_EMAIL_MESSAGE_LIST,
  payload,
});
export const requestEmailMessageListAction = (payload) => ({
  type: REQUEST_EMAIL_MESSAGE_LIST,
  payload,
});

// Get Default Email Message
export const getDefaultEmailMessage = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.default_email_message;

    if (payload && payload.hasOwnProperty("tag_name")) {
      url += payload.tag_name + '/';
    }

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getDefaultEmailMessageAction(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Get Email Message
export const getEmailMessage = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.email_message;

    if (payload && payload.hasOwnProperty("id")) {
      url += payload.id + "/";
    }

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getEmailMessageAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Get Email Message
export const getEmailMessageList = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.email_message;

    if (payload && payload.hasOwnProperty("tag_id")) {
      url += "?tag_id=" + payload.tag_id;
    }
    dispatch(requestEmailMessageListAction());

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(successGetEmailMessageListAction(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Create Email Message
export const createEmailMessage = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.email_message;

    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createEmailMessageAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Update Email Message
export const updateEmailMessage = (id, payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.email_message + id + "/";

    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(createEmailMessageAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Delete Email Message
export const deleteEmailMessage = (id) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.email_message}${id}/mutate/delete/`;

    return new Promise((resolve, reject) => {
      api
        .put(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
export const Emailtag = (payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.email_tag}?page_size=99999`;

    if (payload.group) {
      url += "&group=" + payload.group;
    }

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(Emailtaglist(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const Emailedit = (payload) => ({
  type: GET_EMAIL_EDIT,
  payload,
});
export const requestEmailUpdate = () => ({
  type: REQUEST_EMAIL_UPDATE,
});

export const SingleEmailedit = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.email_message_edit + id + "/";
    dispatch(requestEmailUpdate());

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(Emailedit(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// send free text email
export const sendFreeTextEmail = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.free_text_email;

    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
