import React from 'react';
import { connect } from 'react-redux';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { Organizations } from '../../_multiaccess';

class Pubs extends BaseComponent {
  render() { 
    
    return (
      <Organizations
        name="Publishers"
        baseUrl="/funder-publishers"
        type="university"
      />
    )
  }
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export const FunderPubs = connect(mapStateToProps, mapDispatchToProps)(Pubs)