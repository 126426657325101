import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { 
  getPublicPublisher,
  createUpdateInvoiceTemplate,
  getInvoiceTemplate
 } from '../../../../actions';
import { 
  PopupBox,
  SelectBox,
  InputBox,
  TextBox,
} from '../../../../../core/components/common';

class Add extends BaseComponent {
    state = {
      invoice_template: {
      }
    };

    async componentDidMount() {
      this.props.getPublicPublisher();

      let id = this.props.match && this.props.match.params && this.props.match.params.id;
      
      if (id) { 
        await this.props.getInvoiceTemplate(id);
        let response = this.props.invoice_template;
        
        let invoice_template = {
          title: response && response.title ? response.title : '',
          description: response && response.description ? response.description : '',
          organisation: response && response.organisation ? response.organisation : '',
          portrait_html: response && response.portrait_html ? response.portrait_html : '',
          landscape_html: response && response.landscape_html ? response.landscape_html : '',
        };

        this.setState({
          invoice_template
        });
      }
    }
    
    onChangeHandler = value => {
      this.setState(prevState => {
        return Object.assign(prevState.invoice_template, value);
      });
    };
  
    onSubmitHandler = async e => {
      e.preventDefault();
  
      let data = this.state.invoice_template;
      
      let id = this.props.match && this.props.match.params && this.props.match.params.id;
    
      await this.props.createUpdateInvoiceTemplate(data, id);

      if (this.props.invoice_template.success) {
        this.props.history.push("/invoice-templates/list");
      }
    };
  
    cancelLoader = async () => {
      this.setState({
        isRegistering: false,
        isEmpty: false,
        fileUploadStatus: null,
        fileUploadStatusMsg: null,
      });
    };
  
    render() {
      let errorHander = (
        <>
          {this.state.isEmpty && (
            <PopupBox
              title="Required Information Missing"
              msg={this.state.isEmpty}
              onCancel={this.cancelLoader}
            />
          )}
        </>
      );
  
      return (
        <HubContent>
          {/* ErroR handling */}
          {errorHander}
          {/* ErroR Handling */}
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="card m-b-20">
                <div className="card-body">
                  <ul className="nav nav-pills nav-justified" role="tablist">
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#home-1"
                        role="tab"
                      >
                        Edit invoice template
                      </a>
                    </li>
                    
                  </ul>
                  <div className="tab-content books">
                    <div
                      className="tab-pane active p-3"
                      id="home-1"
                      role="tabpanel"
                    >
                      <form>
                        <div className="form-group row">
                          <SelectBox
                            label="Publisher"
                            onChange={this.onChangeHandler}
                            field="organisation"
                            data={this.props.publishers.results || []}
                            className="col-sm-9"
                            labelClass="col-sm-3"
                            defaultValue={this.state.invoice_template.organisation}
                          />
                        </div>
                        
                        <div className="form-group row">
                          <InputBox
                            label="Title"
                            onChange={this.onChangeHandler}
                            field="title"
                            className="col-sm-9"
                            labelClass="col-sm-3"
                            placeholder="Title"
                            defaultValue={this.state.invoice_template.title}
                          />
                        </div>
                        <div className="form-group row">
                          <TextBox
                            label="Description"
                            onChange={this.onChangeHandler}
                            field="description"
                            className="col-sm-9"
                            labelClass="col-sm-3"
                            placeholder="Description"
                            defaultValue={this.state.invoice_template.description}
                          />
                        </div>

                        <div className="form-group row">
                          <TextBox
                            label="Portrait"
                            onChange={this.onChangeHandler}
                            field="portrait_html"
                            className="col-sm-9"
                            labelClass="col-sm-3"
                            placeholder="Portrait HTML"
                            defaultValue={this.state.invoice_template.portrait_html}
                          />
                        </div>

                        <div className="form-group row">
                          <TextBox
                            label="Landscape"
                            onChange={this.onChangeHandler}
                            field="landscape_html"
                            className="col-sm-9"
                            labelClass="col-sm-3"
                            placeholder="Landscape HTML"
                            defaultValue={this.state.invoice_template.landscape_html}
                          />
                        </div>
  
                        <div className="text-center m-t-15">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={this.onSubmitHandler}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HubContent>
      );
    }
  }
  
  const mapStateToProps = state => ({
    publishers: state.orgs.publisher_list || false,
    invoice_template: state.InvoiceTemplate.invoice_template || false,
  });
  
  const mapDispatchToProps = dispatch => ({
    getPublicPublisher: () => dispatch(getPublicPublisher()),
    createUpdateInvoiceTemplate: (payload, id) => dispatch(createUpdateInvoiceTemplate(payload, id)),
    getInvoiceTemplate: (id) => dispatch(getInvoiceTemplate(id)),
  });
  
  export const EditInvoiceTemplate = connect(mapStateToProps, mapDispatchToProps)(Add);
