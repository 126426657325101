import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serialize } from "../../../../../core/lib";
import { getPublicationList } from "../../../../actions";
import { Button } from "../../../../../core/components/common";
class ViewEmailModal extends React.Component {
    state = {
        active: false,
        institutionList:
            (this.props.listData && this.props.listData.institutionList) || [],
        funderList: (this.props.listData && this.props.listData.funderList) || [],
        publicationTypeList:
            (this.props.listData && this.props.listData.publicationTypeList) || [],
    };

    setActive = () => {
        this.setState({
            active: !this.state.active,
        });
    };

    async componentDidMount() {
        if (this.props.emailData) {
            let emailData = this.props.emailData;

            this.setState({
                title: emailData.title,
                description: emailData.description,
                subject: emailData.subject,
                only_for_waiver_countries:
                    emailData.only_for_waiver_countries !== null
                        ? emailData.only_for_waiver_countries
                        : false,
                publicationTypeListSelected:
                    emailData.publication_types.length > 0
                        ? this.state.publicationTypeList.filter((publicationType) =>
                            emailData.publication_types.includes(publicationType.id)
                        )
                        : [{ id: "all", name: "All publication types" }],
                institutionListSelected:
                    emailData.author_affiliation_details.length > 0
                        ? emailData.author_affiliation_details.map((item) => ({
                            id: item.id,
                            name: item.name,
                        }))
                        : [{ id: "all", name: "All institutions" }],
                funderListSelected:
                    emailData.funders.length > 0
                        ? this.state.funderList.filter((funder) =>
                            emailData.funders.includes(funder.id)
                        )
                        : [{ id: "all", name: "All funders" }],
                institutionDealSelected:
                    emailData.institution_deal !== "non-deal" ? "OA Deal" : "Non OA Deal",
            });

            let data = emailData.publication_types;

            let payload = {};
            let publicationList = [];

            let filterParams = {
                page_size: 999999,
            };

            for (let i = 0; i < data.length; i++) {
                if (data[i] === "" || data[i] === "all") {
                    payload.filter = serialize({ page_size: 999999 });

                    await this.props.getPublicationList(payload);

                    if (this.props.publication_list) {
                        let res = this.props.publication_list;
                        this.setState({
                            publicationListSelected: res.results.filter((publication) =>
                                emailData.publications.includes(publication.id)
                            ),
                        });
                    }

                    break;
                } else {
                    filterParams.publication_type = data[i];

                    payload.filter = serialize(filterParams);

                    await this.props.getPublicationList(payload);

                    if (this.props.publication_list) {
                        let res = this.props.publication_list;
                        publicationList = publicationList.concat(res.results);

                        this.setState({
                            publicationListSelected: publicationList.filter((publication) =>
                                emailData.publications.includes(publication.id)
                            ),
                        });
                    }
                }
            }

            if (emailData.publications.length <= 0) {
                this.setState({
                    publicationListSelected: [{ id: "all", name: "All publications" }],
                });
            }
        }
    }

    changeObjectProperty = (listOfObj = []) => {
        let newListOfObj = [];
        newListOfObj = listOfObj.map((obj) => ({
            value: obj.id,
            label: obj.name,
        }));

        return newListOfObj;
    };

    renderList = (listOfObj) => {
        return listOfObj.map((obj) => obj.name).join();
    };

    render() {
        const {
            subject,
            only_for_waiver_countries,
            publicationTypeListSelected,
            publicationListSelected,
            institutionListSelected,
            funderListSelected,
            institutionDealSelected,
        } = this.state;

        let main_body = "";

        try {
            let data = "";
            if (this.props.emailData) {
                data = this.props.emailData && this.props.emailData.template;
            }

            const doc = new DOMParser().parseFromString(data, "text/html");
            main_body = doc.getElementById("id_full_email_body").innerHTML;
        } catch (error) { }

        return this.props.emailData ? (
            <div className="addmultfield" style={modal.overlay}>
                <div className={`col-10 col-sm-10 col-lg-7 bg-white alert`} style={modal.boxStyle}>
                    <div className="vat-tax-button email">
                        <Button
                            type="danger"
                            name="danger"
                            text="X"
                            onClick={() => this.props.closeModal()}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 view-email">
                            <div className="email-view-area p-10">
                                <div className="row email-subject">
                                    <div className="col-md-12">
                                        <label>Subject: </label>
                                        {subject}
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row d-flex justify-content-center">
                                        <div
                                            className="w-100"
                                            dangerouslySetInnerHTML={{ __html: main_body }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-7">
                                    <p>Click on the "More Details" button to view email setup</p>
                                </div>
                                <div className="col-md-5">
                                    <div className="more-detail">
                                        <button onClick={this.setActive}>
                                            More Details
                      <i
                                                style={{
                                                    background: "inherit",
                                                    textAlign: "inherit",
                                                    height: "0px",
                                                    lineHeight: "0px",
                                                    marginLeft: "10px",
                                                    color: "inherit",
                                                    marginRight: "10px",
                                                    width: "0px",
                                                }}
                                                className={
                                                    this.state.active
                                                        ? "fa fa-chevron-up"
                                                        : "fa fa-chevron-down"
                                                }
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {this.state.active === true ? (
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Publications types: </label>
                                        {publicationTypeListSelected &&
                                            publicationTypeListSelected.map((item) => (
                                                <ul key={item.id}>
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))}
                                    </div>
                                    <div className="col-md-4">
                                        <label>Publications: </label>
                                        {publicationListSelected &&
                                            publicationListSelected.map((item) => (
                                                <ul key={item.id}>
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))}
                                    </div>
                                    <div className="col-md-4">
                                        <label>Institutions: </label>
                                        {institutionListSelected &&
                                            institutionListSelected.map((item) => (
                                                <ul key={item.id}>
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))}
                                    </div>
                                    <div className="col-md-4">
                                        <label> Funders: </label>
                                        {funderListSelected &&
                                            funderListSelected.map((item) => (
                                                <ul key={item.id}>
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))}
                                    </div>
                                    <div className="col-md-4">
                                        <label>Institution deal: </label>
                                        <p>{institutionDealSelected}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Only for waiver Countries: </label>

                                        <p>{only_for_waiver_countries ? "Yes" : "No"}</p>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
                ""
            );
    }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
    boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
    boxHeight = "-webkit-fit-content";
}

const modal = {
    overlay: {
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.64)",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 1,
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
    },
    boxStyle: {
        transform: "translate(0px, 20px)",
        height: boxHeight,
    },
};

const mapStateToProps = (state) => ({
    publication_list: state.institutions.publication_list,
});

const mapDispatchToProps = (dispatch) => ({
    getPublicationList: (payload) => dispatch(getPublicationList(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewEmailModal));
