import React from 'react';
import BaseComponent from '../../../../core/components/BaseComponent';
import { HubContent } from '../../../../core/components';

class OAP extends BaseComponent {
    render() {
        return (
            <HubContent title="OA Policy">
                <div className="row">
                    <h1>OA Policy</h1>
                </div>
            </HubContent>
        )
    }
}

export const OAPolicy = OAP;