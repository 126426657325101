import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { createContentType, uploadContentType, ClearStatus } from "../../../../actions";
import {
  PopupBox,
  Loading,
  InputBox
} from "../../../../../core/components/common";
import DragAndDrop from "../../../partials/DragAndDrop";

class Add extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    contentType: {}
  };

  componentDidUpdate() {}

  onChangeHandler = value => {
    this.setState(prevState => {
      return Object.assign(prevState.contentType, value);
    });
  };

  onSubmitHandler = async e => {
    e.preventDefault();
    let data = this.state.contentType;
    await this.props.createContentType(data);
    this.props.history.push("/content-type/content-type-list");
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
    await this.props.ClearStatus();
  };
  

  uploadContentTypeHandler = async (payload) => { 
    this.setState({
      isRegistering: true,
    });

    await this.props.uploadContentType(payload);
    
    this.setState({
      fileUploadStatus: this.props.content_type_file_upload.status,
      fileUploadStatusMsg: this.props.content_type_file_upload.data.message,
      isRegistering: false,
    });

  }

  onFileDrop = payload => {
    confirmAlert({
      title: "Upload content type",
      message: "Are you sure, you want to upload file?",
      buttons: [
        {
          label: "Upload",
            onClick: () => this.uploadContentTypeHandler(payload)
        },
        {
          label: "Cancel",
          onClick: () => {}
        }
      ]
    });
  };


  render() {
    let errorHander = (
      <>
        {this.state.status === 400 && (
          <PopupBox
            title="Bad Request"
            msg="Please Check Your Form and Try again"
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.status === 500 && (
          <PopupBox
            title="Network Error Found"
            msg="Please Check Internet Connection and Try Again"
            onCancel={this.cancelLoader}
          />
        )}

        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}

        {this.state.msg && (
          <PopupBox
            Title={this.state.msg}
            msg={this.state.msg}
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.fileUploadStatus === false && <PopupBox
            title="Bad Request"
            msg={this.state.fileUploadStatusMsg && this.state.fileUploadStatusMsg}
            onCancel={this.cancelLoader}
        />}
        {this.state.fileUploadStatus === true && <PopupBox
            title="Success"
            msg={this.state.fileUploadStatusMsg}
            onCancel={this.cancelLoader}
        />}
      </>
    );

    return (
      <HubContent>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        {/* LoadinG Handling */}
        {this.state.isRegistering && !this.state.status && <Loading />}
        {/* LoadinG Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add Content Type
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload Content Types
                    </a>
                  </li>
                </ul>
                <div className="tab-content books">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      <div className="form-group row" style={{ marginTop: 20 }}>
                        <InputBox
                          label="Content type name"
                          onChange={this.onChangeHandler}
                          field="name"
                          placeholder="Name of content"
                          className="col-sm-9"
                          labelClass="col-sm-3"
                          // defaultValue={user ? user.salute : ""}
                        />
                      </div>

                      {this.props.msg && (
                        <div className="form-group row">
                          <span className="alert alert-success col-sm-12">
                            {this.props.msg}
                          </span>
                        </div>
                      )}

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <div className="m-b-30">
                      <DragAndDrop onDropHandler={this.onFileDrop} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  failed: state.journals.content_type || false,
  content_type_file_upload: state.content.content_type_file_upload || false,
});

const mapDispatchToProps = dispatch => ({
  createContentType: payload => dispatch(createContentType(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
  uploadContentType: payload => dispatch(uploadContentType(payload))
});

export const AddContentType = connect(mapStateToProps, mapDispatchToProps)(Add);
