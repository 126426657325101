
// filtering demo 
export const filterStatus = [
  {
    name: "All Status",
    id: "all"
  },
  {
    name: "Approved",
    id: "approved"
  },
  {
    name: "Pending",
    id: "pending"
  },
  {
    name: "Rejected",
    id: "rejected"
  },
  {
    name: "Author request",
    id: "author_request"
  },
  {
    name: "Correction request",
    id: "correction_request"
  },
  {
    name: "Invoiced",
    id: "invoiced"
  },
]

export const filterDays = [
  {
    name: "Time period",
    id: ""
  },
  {
    name: "Current Year",
    id: 365
  },
  {
    name: "Last 30 days",
    id: 30
  },
  {
    name: "Last 10 days",
    id: 10
  },
  {
    name: "Last 7 days",
    id: 7
  },
]

export const filterDeal = [
  { name: 'All Deals', id: 'all' },
  { name: 'OA Deal', id: 'deal' },
  { name: 'Non OA Deal', id: 'non-deal' },
]


