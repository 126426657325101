import config, {
  REQUEST_SOCIETY_MEMBER_LIST,
  FALIED_SOCIETY_MEMBER_LIST,
  SUCCESS_SOCIETY_MEMBER_LIST,
  GET_SOCIETY_MEMBER,
  CREATE_UPDATE_SOCIETY_MEMBER,
  DELETE_SOCIETY_MEMBER,
} from "../../config";
import { api } from "../../core/api";

// Get Society Member list
export const successGetSocietyMemberListAction = (payload) => ({
  type: SUCCESS_SOCIETY_MEMBER_LIST,
  payload,
});

export const requestGetSocietyMemberListAction = (payload) => ({
  type: REQUEST_SOCIETY_MEMBER_LIST,
  payload,
});

export const failedGetSocietyMemberListAction = () => ({
  type: FALIED_SOCIETY_MEMBER_LIST,
});

// Get Society Member
export const getSocietyMemberAction = (payload) => ({
  type: GET_SOCIETY_MEMBER,
  payload,
});

// Create-Update Society Member
export const createUpdateSocietyMemberAction = (payload) => ({
  type: CREATE_UPDATE_SOCIETY_MEMBER,
  payload,
});

// Delete Society Member
export const deleteSocietyMemberAction = (payload) => ({
  type: DELETE_SOCIETY_MEMBER,
  payload,
});

export const getSocietyMemberList = (payload = {}) => {
  return (dispatch) => {
    const params = payload ? payload.params || "" : "";
    const filter = payload ? payload.filter || "" : "";
    let url = `${config.endpoint.society_member}?${
      params && params + "&"
    }${filter}`;
    dispatch(requestGetSocietyMemberListAction());
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(successGetSocietyMemberListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          failedGetSocietyMemberListAction(e);
          reject(e);
        });
    });
  };
};

// Get single society member
export const getSocietyMember = (id) => {
  return (dispatch) => {
    let url = config.endpoint.society_member + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getSocietyMemberAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create-Update society member

export const createUpdateSocietyMember = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.society_member;
    let apiRequest = "";
    return new Promise((resolve, reject) => {
      if (payload.id) {
        url += `${payload.id}/`;
        apiRequest = api.put(url, payload);
      } else {
        apiRequest = api.post(url, payload);
      }

      apiRequest
        .then((res) => {
          dispatch(createUpdateSocietyMemberAction(res.data));
          resolve(Object.assign(res.data, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Delete society member
export const deleteSocietyMember = (id) => {
  return (dispatch) => {
    let url = config.endpoint.society_member + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          dispatch(deleteSocietyMemberAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
