import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getPublicPublisher,
  createUpdateInvoiceTemplate,
} from "../../../../actions";
import {
  PopupBox,
  SelectBox,
  InputBox,
  TextBox,
} from "../../../../../core/components/common";

class Add extends BaseComponent {
  state = {
    invoice_template: {
    }
  };

  async componentDidMount() {
    this.props.getPublicPublisher();
  }

  onChangeHandler = value => {
    this.setState(prevState => {
      return Object.assign(prevState.invoice_template, value);
    });
  };

  onSubmitHandler = async e => {
    e.preventDefault();

    let data = this.state.invoice_template;
    
    await this.props.createUpdateInvoiceTemplate(data);

    if (this.props.invoice_template.success) {
      this.props.history.push("/invoice-templates/list");
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add invoice template
                    </a>
                  </li>
                  
                </ul>
                <div className="tab-content books">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      <div className="form-group row">
                        <SelectBox
                          label="Publisher"
                          onChange={this.onChangeHandler}
                          field="organisation"
                          data={this.props.publishers.results || []}
                          className="col-sm-9"
                          labelClass="col-sm-3"
                        />
                      </div>
                      
                      <div className="form-group row">
                        <InputBox
                          label="Title"
                          onChange={this.onChangeHandler}
                          field="title"
                          className="col-sm-9"
                          labelClass="col-sm-3"
                          placeholder="Title"
                        />
                      </div>
                      <div className="form-group row">
                        <TextBox
                          label="Description"
                          onChange={this.onChangeHandler}
                          field="description"
                          className="col-sm-9"
                          labelClass="col-sm-3"
                          placeholder="Description"
                        />
                      </div>

                      <div className="form-group row">
                        <TextBox
                          label="Portrait"
                          onChange={this.onChangeHandler}
                          field="portrait_html"
                          className="col-sm-9"
                          labelClass="col-sm-3"
                          placeholder="Portrait HTML"
                        />
                      </div>

                      <div className="form-group row">
                        <TextBox
                          label="Landscape"
                          onChange={this.onChangeHandler}
                          field="landscape_html"
                          className="col-sm-9"
                          labelClass="col-sm-3"
                          placeholder="Landscape HTML"
                        />
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  publishers: state.orgs.publisher_list || false,
  invoice_template: state.InvoiceTemplate.invoice_template || false,
});

const mapDispatchToProps = dispatch => ({
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  createUpdateInvoiceTemplate: payload => dispatch(createUpdateInvoiceTemplate(payload)),
});

export const AddInvoiceTemplate = connect(mapStateToProps, mapDispatchToProps)(Add);
