import React from "react";
import BaseComponent, { HubContent } from "../../../../core/components";
import { PaymentHome } from ".";

class AddPayment extends BaseComponent {
  render() {
    return (
      <HubContent>
        <div className="container">
          <div className="card m-b-20">
            <PaymentHome/>
          </div>
        </div>
      </HubContent>
    );
  }
}



export default AddPayment;
