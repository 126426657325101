import React from "react";
import BaseComponent from "..";
import { Radio } from "antd";

export class RadioButtonGroup extends BaseComponent {
  state = {
    msg: this.props.errMsg || "",
    options: this.props.options || [],
    value: this.props.value
  };

  onChange = e => {
    this.setState({ value: e.target.value });
    this.props.onChange &&
      this.props.onChange({ [this.props.field]: e.target.value });
  };

  componentDidUpdate() {
    this.props.errMsg &&
      setTimeout(() => {
        this.setState({
          msg: this.props.errMsg
        });
      }, 300);
  }

  render() {
    let { value, options } = this.state;
    let { radioStyle, field } = this.props;

    return (
      <Radio.Group name={field} onChange={this.onChange} value={value}>
        {options.length > 0 &&
          options.map((item, index) => {
            return (
              <Radio key={`radio-${index}`} style={radioStyle} value={item.id}>
                {item.name}
              </Radio>
            );
          })}
      </Radio.Group>
    );
  }
}
