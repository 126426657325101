import React, { Component } from "react";
import ViewVatTable from "../viewVatTable";

export default class UKview extends Component {
  render() {
    const {
      apc_including_vat,
      charge_vat,
      charge_no_vat,
      vatselfcountry,
      selfCountrynoRates,
      not_charge_vat_number,
    } = this.props;
    return (
      <div className="vat-single-view">
        <div className="">
          <div className="vat-question-panel margin-top">
            <p>
              APC including VAT :
              {apc_including_vat === true ? (
                <span className="vat_text">Yes</span>
              ) : (
                <span className="vat_text">No</span>
              )}
            </p>
          </div>

          {apc_including_vat === false && (
            <div className="vat-question-panel">
              <p>
                You charge VAT to customers in {this.props.origin} if they have a valid
                Number:
                {charge_vat === true ? (
                  <span className="vat_text">Yes</span>
                ) : (
                  <span className="vat_text">No</span>
                )}
              </p>
            </div>
          )}

          {apc_including_vat !== true ? (
            charge_vat === true ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <ViewVatTable
                    viewTable="view"
                    pagination="none"
                    data={vatselfcountry}
                  />
                </div>
              </div>
            ) : null
          ) : null}
          {apc_including_vat !== true
            ? charge_vat === true && (
                <div className="vat-question-panel">
                  <p>
                    You charge VAT to customers in
                     {this.props.origin} if they do not have a valid VAT number:
                    {charge_no_vat === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              )
            : null}

          {apc_including_vat !== true
            ? charge_vat !== false
              ? charge_no_vat === true && (
                  <div className="vat-country">
                    <div className="col-12 vat-margin">
                      <ViewVatTable
                        viewTable="view"
                        pagination="none"
                        data={selfCountrynoRates}
                      />
                    </div>
                  </div>
                )
              : null
            : null}

          {apc_including_vat !== true
            ? charge_vat === false && (
                <div className="vat-question-panel">
                  <p>
                    You charge VAT to customers in
                     {this.props.origin} if they do not have a valid VAT number:
                    {not_charge_vat_number === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              )
            : null}
          {apc_including_vat !== true
            ? charge_vat !== true
              ? not_charge_vat_number === true && (
                  <div className="vat-country">
                    <div className="col-12 vat-margin">
                      <ViewVatTable
                        pagination="none"
                        viewTable="view"
                        data={selfCountrynoRates}
                      />
                    </div>
                  </div>
                )
              : null
            : null}
          <div></div>
        </div>
      </div>
    );
  }
}
