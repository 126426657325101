import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { DataTable, HubContent } from '../../../../../core/components';
import { getOaToken, deleteOaToken } from '../../../../actions';
import { Loading, PopupBox } from '../../../../../core/components/common';
import NotFound from '../../NotFound';

class OrgToken extends BaseComponent {
    state = {
        deleteConfirm: false,
        journalId: null,
        waitingMsg: null,
        deleteStatus: null,
        state: []
    }
    getOaTokenData = async (pageNum = 1, page = 10) => {
        await this.props.getOaToken({ pageNum: pageNum, pageSize: page });
        let OffsetFundList = this.props.OaTokenList.results;
        let dataTokenOa = [];
        OffsetFundList.map((item) => {
            return dataTokenOa.push({
                name: item.name,
                publisher_name: item.publisher_name,
                token: item.token,
                valid_from: item.valid_from,
                valid_to: item.valid_to,
                is_active: item.is_active ? 'Active' : 'Already Used',
                id: item.id
            });
        })
        this.setState({
            data: dataTokenOa,
            count: this.props.OaTokenList.count
        })
    }
     componentDidMount() {
        this.getOaTokenData();
    }
    pageChange = (pageNumber) => {
        this.getOaTokenData(pageNumber);
    }

    onEditjournal = (id) => {
        // this.props.history.push('/journals/edit-journal/' + id)
    }

    onViewjournal = (id) => {
        // this.props.history.push('/journals/single-journal/' + id)
    }

    onDeleteOaToken = (id) => {
        if (id) {
            this.setState({
                deleteConfirm: true,
                oaTokenId: id
            })
        }
    }

    onDeleteOaTokenHander = async () => {
        const oaTokenId = this.state.oaTokenId;
        this.setState({
            waitingMsg: "Deleting..."
        })
        await this.props.deleteOaToken(oaTokenId);
        let data = this.state.data;
        let newData = data
            .filter(function (element) {
                return element.id !== oaTokenId;
            });
        this.setState({
            waitingMsg: null,
            oaTokenId: null,
            deleteConfirm: false,
            data: newData
        })
    }

    onCancel = () => {
        this.setState({
            deleteConfirm: false,
            oaTokenId: null,
            waitingMsg: null,
            deleteStatus: null
        })
    }
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();

        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        // }
    };

    render() {
        const heading = ['name', 'publisher_name', 'token', 'valid_from', 'valid_to', 'is_active']
        return (
            this.state.status === 403 ? <NotFound /> :
                <HubContent title="OA tokens" onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                    {this.state.deleteConfirm && <PopupBox
                        title="Do you want to delete?"
                        YesText="Yes"
                        yesBtnType="danger"
                        type="danger"
                        NoBtnType="success"
                        onCancel={this.onCancel}
                        onPressOK={this.onDeleteOaTokenHander}
                        waitingMsg={this.state.waitingMsg}
                    />}
                    {this.state.deleteStatus && <PopupBox
                        title="Successfully deleted"
                        onCancel={this.onCancel}
                    />}

                    {this.state.deleteStatus === false && <PopupBox
                        title="Failed... Try Again"
                        onCancel={this.onCancel}
                        NoBtnType="danger"
                    />}
                    {/* {errorHander} */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                {this.state.data ?
                                    <DataTable
                                        heading={heading}
                                        data={this.state.data}
                                        onEdit={this.onEditjournal}
                                        onDelete={this.onDeleteOaToken}
                                        onView={this.onViewjournal}
                                        count={this.state.count}
                                        pageChange={this.pageChange}
                                        pageSize={10}
                                    />
                                    :
                                    <Loading />
                                }
                            </div>
                        </div>
                    </div>
                </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    OaTokenList: state.oaToken.oa_token_list || false,
})

const mapDispatchToProps = (dispatch) => ({
    getOaToken: (payload) => dispatch(getOaToken(payload)),
    deleteOaToken: (id) => dispatch(deleteOaToken(id))
})

export const OaTokenList = connect(mapStateToProps, mapDispatchToProps)(OrgToken);

