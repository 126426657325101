import config, {
  GET_COUNTRIES,
  GET_STATE_LIST,
  GET_CITY_LIST,
  COVERAGE_COUNTRIES,
  GET_INVOCE_CITY_LIST,
} from "../../config";
import { api } from "../../core/api";

export const getCountries = (payload = {}) => ({
  type: GET_COUNTRIES,
  payload,
});

export const getCoverageList = (payload = {}) => ({
  type: COVERAGE_COUNTRIES,
  payload,
});

export const getStateList = (payload = {}) => ({
  type: GET_STATE_LIST,
  payload,
});

export const getCityList = (payload = {}) => ({
  type: GET_CITY_LIST,
  payload,
});

export const getInvoiceCityList = (payload = {}) => ({
  type: GET_INVOCE_CITY_LIST,
  payload,
});

export const getAllCountry = (payload = {}) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    api.getCountryHandler(token).then(
      (res) => {
        dispatch(getCountries(res.results));
      },
      (error) => {}
    );
  };
};

export const getInvoiceAllCountry = (payload = {}) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    api.getCountryHandler(token).then(
      (res) => {
        dispatch(getCountries(res.results));
      },
      (error) => {}
    );
  };
};

// Get Social Program List
export const getProgramCoverageAll = (payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.program_coverage}?pc_plan_id=${payload.id}&page_size=999999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getCoverageList(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Social Program List
export const getDiscountCoverageAll = (payload) => {
  return (dispatch, getState) => {
    let url = payload.geo_discount_pk
      ? `${config.endpoint.discount_country}?org_id=${payload.org_id}&geo_discount_pk=${payload.geo_discount_pk}&page_size=999999999`
      : `${config.endpoint.discount_country}?org_id=${payload.org_id}&page_size=999999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getCoverageList(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getAllState = (payload) => {
  return (dispatch, getState) => {
    let qs = "";
    if (payload.hasOwnProperty("page_size")) {
      qs = `&page_size=${payload.page_size}`;
    }
    let url = config.endpoint.state + "?parent=" + payload.country + qs;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getStateList(Object.assign(res, { index: payload.index })));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};

export const getAllCity = (payload) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return new Promise((resolve, reject) => {
      api.getCityHandler(payload, token).then(
        (res) => {
          dispatch(getCityList(Object.assign(res, { index: payload.index })));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};

export const getAllInvoiceCity = (payload) => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    return new Promise((resolve, reject) => {
      api.getCityHandler(payload, token).then(
        (res) => {
          dispatch(
            getInvoiceCityList(Object.assign(res, { index: payload.index }))
          );
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};
