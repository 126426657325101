import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { ViewRow} from '../../../common';
import { history } from '../../../../route';
import moment from 'moment';


class ViewPayment extends BaseComponent {
  render() {
    let transation = history.location.state.paymentData
    function numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    return (
        <HubContent title="payment info">
            <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ViewRow 
                                    label="Date"
                                    value={moment(transation.date_created).format('Do MMM YYYY')}
                                />
                                   <ViewRow 
                                    label="Article title"
                                    value={transation.article_title}
                                />
                                  <ViewRow 
                                    label="Article id"
                                    value={transation.id}
                                />
                                 <ViewRow 
                                    label="Licence"
                                    value={transation.licence_name ? transation.licence_name : "-" }
                                />
                                 <ViewRow 
                                    label="Author"
                                    value={transation.author_name ?transation.author_name:  "-" }
                                />
                                
                            
                                     <ViewRow 
                                    label="Publisher"
                                    value={transation.publisher}
                                />
                                     <ViewRow 
                                    label="Currency"
                                    value={transation.currency}
                                />
                                     <ViewRow 
                                    label="Total"
                                    value={ numberWithCommas( parseFloat(transation.amount).toFixed(2))}
                                />
                                     <ViewRow 
                                    label="Paid by"
                                    value={transation.paid_by_user}
                                />
                                 <ViewRow 
                                    label="Payer org"
                                    value={transation.paid_by_organisation}
                                />
                                <ViewRow 
                                    label="Payment method "
                                    value={transation.payment_source ? transation.payment_source : "-"}
                                />
                                 <ViewRow 
                                    label="Download"
                                    value="Receipt"
                                />

                                </div>
                                </div>
                                </div>
                                </div>

    </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
});


export const SinglePayment =  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ViewPayment);
  
