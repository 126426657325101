import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../core/components";
import { confirmAlert } from "react-confirm-alert";
import {
  getAllCurrencyList,
  getCountryAllHandler,
  createGeographicalDiscount,
  getGeographicalDiscountSingle,
  updateGeographicalDiscount,
  getDiscountCoverageAll,
} from "../../../actions";

import {
  SelectBox,
  PopupBox,
  InputBox,
  Button,
  ModalSelectBox,
  RadioButtonGroup,
  OaDatePicker,
} from "../../../../core/components/common";


import { Popover } from "antd";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Geographical</a>
    <br />
  </div>
);
const format = "Do MMM YYYY";

class Form extends BaseComponent {
  state = {
    discount: {
      options: {
        option_1: false,
        option_2: false,
        option_3: false,
      },
      options_formated: {
        option_1: false,
        option_2: false,
        option_3: false,
      },
      offer_expiry_formated: false,
    },
    load_data: false,
    offer_expiry: false,
    showConstraint: false,
    offer_type: [
      { id: "DISCOUNT", name: "Discount" },
      { id: "AMOUNT", name: "Fix Amount" },
      { id: "SPECIAL_APC", name: "Special APC" },
    ],
  };

  stringToBoolean = (val) => {
    var a = {
      true: true,
      false: false,
    };
    return a[val];
  };

  componentDidMount = async () => {
    await this.props.getAllCurrencyList();
    await this.props.getCountryAllHandler();
    let discount_id = this.props.match.params.id;

    if (discount_id) {
      await this.props.getGeographicalDiscountSingle(discount_id);
      let org_id = this.props.organisatin_id;
      await this.props.getDiscountCoverageAll({
        org_id: org_id,
        geo_discount_pk: discount_id,
      });
      if (this.props.discount_single) {
        let { discount_single } = this.props;
        let discount = this.props.discount_single;
        discount["options"] = JSON.parse(this.props.discount_single.options);
        this.setState(
          {
            discount: discount,
            load_data: true,
          },
          function () {
            this.state.discount["offer_expiry"] =
              discount_single.offer_expiry_formated;
          }
        );
      }
    } else {
      let org_id = this.props.organisatin_id;
      await this.props.getDiscountCoverageAll({ org_id: org_id });
    }
  };


  onChangeHandler = async (value) => {
      this.setState((prevState) => {
        Object.assign(prevState.discount, value);
        return prevState;
      });
    
  };

  onChangeHandleRadioBox = async (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.discount.options, value);
      return prevState;
    });
  };

  formatOption = (options) => {
    let option_values =
      typeof options === "string"
        ? JSON.stringify(JSON.parse(options))
        : JSON.stringify(options);
    return option_values;
  };

  onSubmitHandler = async (e) => {
    let id = this.props.match.params.id;

      if (  this.state.discount.offer_expiry) {
        Object.assign(this.state.discount, {
          offer_expiry: moment(  this.state.discount.offer_expiry).format(
            "YYYY-MM-DD"
          ),
        });
      } else {
        Object.assign(this.state.discount, {
          offer_expiry:null
        });
      }
    this.state.discount["options"] = this.formatOption(
      this.state.discount.options
    );
    if (!this.state.discount.coverage) {
      confirmAlert({
        title: "",
        message: "Countries should be selected",
        buttons: [
          {
            label: "Close",
            onClick: () => { },
          },
        ],
      });
    } else if (!this.state.discount.offer_type) {
      confirmAlert({
        title: "",
        message: "Offer should be selected",
        buttons: [
          {
            label: "Close",
            onClick: () => { },
          },
        ],
      });
    } else if (!this.state.discount.offer_amount) {
      confirmAlert({
        title: "",
        message: "Discount should be selected",
        buttons: [
          {
            label: "Close",
            onClick: () => { },
          },
        ],
      });
    } else if (id) {
      await this.props.updateGeographicalDiscount(id, this.state.discount);
      if (this.props.update_discount) {
        this.props.history.push("/settings/discount-list/");
      }
    } else {
      await this.props.createGeographicalDiscount(this.state.discount);
      if (this.props.create_discount) {
        this.props.history.push("/settings/discount-list/");
      }
    }
  };

  onShowConstraint = () => {
    this.setState({
      showConstraint: true,
    });
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState((prevState) => {
      Object.assign(prevState.discount, { [fieldName]: data });
    });
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "countries":
        this.setState({
          isShowPublicationModal: true,
        });
        break;

      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName] || this.state.discount.coverage;
    let labelText = "";
    switch (fieldName) {
      case "coverage":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length > 1
              ? `${fieldValues.length} countries selected`
              : `${fieldValues.length} country selected`;
        } else {
          labelText = `All countries selected`;
        }
        break;

      default:
        break;
    }

    return labelText;
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    let countries = this.props.countries;
    let includes = this.state.coverage || [];
    let exclude_countries = countries.filter((v) => !includes.includes(v.id));
    return (
      <HubContent title="Geographical Discount" className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <div className="vat-tax-button email d-flex justify-content-end">


                  <Popover placement="leftTop" content={content} trigger="click">
                    <Button
                      className="btn btn-secondary float-right "
                      type=""
                      name=""
                      text="Help"
                    >Help</Button>
                  </Popover>
                  <div className="danger float-right"
                  >
                    <button
                      type="danger"
                      className="btn btn-danger float-right "
                      onClick={() => this.props.history.goBack()}
                      style={{ marginLeft: 10 }}
                    >
                      X

</button>                  </div>
                </div>

                <div className="tab-content">


                  <form action="">
                    <div className="form-group row">
                      <InputBox
                        labelInfo={true}
                        labelInfoTitle="Enter  Reference"
                        className="col-sm-4 col-md-4"
                        labelClass="col-sm-2 col-md-2"
                        label="Reference"
                        onChange={this.onChangeHandler}
                        field="reference_number"
                        placeholder="Reference"
                        defaultValue={this.state.discount.reference_number}
                      />
                      <label className=" col-form-label col-sm-2">
                        Country type
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("coverage")}
                        <button
                          onClick={() => this.onModalSelectBoxShow("countries")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationModal && (
                        <ModalSelectBox
                          title="Select countries"
                          label="Country"
                          field="coverage"
                          modalClass="col-10 col-md-6"
                          className="col-6"
                          data={exclude_countries}
                          defaultValue={this.state.discount.coverage}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>

                    <div className="form-group row">
                      <SelectBox
                        label="Offer"
                        onChange={this.onChangeHandler}
                        field="offer_type"
                        labelInfo={true}
                        labelInfoTitle="Select Offer Type"
                        data={this.state.offer_type}
                        isRequired={false}
                        defaultValue={this.state.discount.offer_type}
                      />
                      {(this.state.discount.offer_type === "AMOUNT" ||
                        this.state.discount.offer_type === "SPECIAL_APC") && (
                          <SelectBox
                            label="Currency"
                            onChange={this.onChangeHandler}
                            field="offer_currency"
                            data={this.props.currency}
                            labelInfo={true}
                            labelInfoTitle="Select Currency"
                            isRequired={false}
                            defaultValue={this.state.discount.offer_currency}
                          />
                        )}
                    </div>
                    <div>
                      <div className="form-group row">
                        <InputBox
                          className="col-sm-4"
                          label="Discount"
                          labelInfo={true}
                          labelInfoTitle="Enter  Discount"
                          onChange={this.onChangeHandler}
                          field="offer_amount"
                          placeholder="Discount"
                          defaultValue={this.state.discount.offer_amount}
                        />
                        {this.state.discount.offer_expiry_formated && (
                          <OaDatePicker
                            className="col-sm-4"
                            label="Expiry"
                            field="offer_expiry"
                            onChange={this.onChangeHandler}
                            placeholder="Select Expiration Date"
                            labelInfo={true}
                            labelInfoTitle="Select Expiration Date"
                            showTime={false}
                            format={format}
                            defaultValue={
                              moment(
                                
                                  this.state.discount.offer_expiry_formated
                          
                              ) || undefined
                            }
                          />
                        )}
                        {!this.state.discount.offer_expiry_formated && (
                          <OaDatePicker
                            className="col-sm-4"
                            label="Expiry"
                            field="offer_expiry"
                            onChange={this.onChangeHandler}
                            placeholder="Select Expiration Date"
                            showTime={false}
                            labelInfo={true}
                            labelInfoTitle="Select Expiration Date"
                            format={format}
                          />
                        )}
                      </div>
                    </div>
                    {this.props.match.params.id && (
                      <div className="form-group row  society">
                        {this.state.load_data && (
                          <div className="vat-question-panel society d-flex align-items-center justify-content-between">
                            <p>
                              Would you like to send a token automatically to
                              the corresponding author if he/she origin in the
                              above countries?
                            </p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="option_1"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.stringToBoolean(
                                this.state.discount.options.option_1
                              )}
                            />
                          </div>
                        )}
                        {this.state.load_data && (
                          <div className="vat-question-panel society d-flex align-items-center justify-content-between">
                            <p>
                              Would you like to allow univeristy admin to use
                              token on behalf or author?
                            </p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="option_2"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.stringToBoolean(
                                this.state.discount.options.option_2
                              )}
                            />
                          </div>
                        )}
                        {this.state.load_data && (
                          <div className="vat-question-panel society d-flex align-items-center justify-content-between">
                            <p>
                              Would you like to allow anyone else to use the
                              token on behalf of author if they fund APC
                            </p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="option_3"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.stringToBoolean(
                                this.state.discount.options.option_3
                              )}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {!this.props.match.id && (
                      <div className="form-group row  society">
                        {!this.state.load_data && (
                          <div className="vat-question-panel society d-flex align-items-center justify-content-between">
                            <p>
                              Would you like to automatically send a token to
                              the corresponding author email address if he/she
                              is based in one of the countries you selected
                              above?
                            </p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="option_1"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.state.discount.options.option_1}
                            />
                          </div>
                        )}
                        {!this.state.load_data && (
                          <div className="vat-question-panel society d-flex align-items-center justify-content-between">
                            <p>
                              Would you like to allow the univeristy admin to
                              apply the token on behalf of the corresponding
                              author?
                            </p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="option_2"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.state.discount.options.option_2}
                            />
                          </div>
                        )}
                        {!this.state.load_data && (
                          <div className="vat-question-panel society d-flex align-items-center justify-content-between">
                            <p>
                              Would you like to allow anyone else to use the
                              token on behalf of author if they fund APC
                            </p>
                            <RadioButtonGroup
                              onChange={this.onChangeHandleRadioBox}
                              field="option_3"
                              options={[
                                { id: true, name: "Yes" },
                                { id: false, name: "No" },
                              ]}
                              value={this.state.discount.options.option_3}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    <div className="text-center m-t-15">
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        {this.props.match.params.id ? "Update" : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  organisatin_id:
    (state.app &&
      state.app.organizations &&
      state.app.organizations[0] &&
      state.app.organizations[0].id) ||
    false,
  currency: (state.OaDeals && state.OaDeals.oadeal_currency) || [],
  countries:
    (state.app && state.app.coverage && state.app.coverage.results) || [],
  discount_single: (state.discount && state.discount.discount_single),
  create_discount:
    (state.discount &&
      state.discount.create_discount &&
      state.discount.create_discount.data &&
      state.discount.create_discount.data.success) ||
    false,
  update_discount:
    (state.discount &&
      state.discount.discount_update &&
      state.discount.discount_update.data &&
      state.discount.discount_update.data.success) ||
    false,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCurrencyList: (payload) => dispatch(getAllCurrencyList(payload)),
  getDiscountCoverageAll: (payload) =>
    dispatch(getDiscountCoverageAll(payload)),
  getCountryAllHandler: () => dispatch(getCountryAllHandler()),
  getGeographicalDiscountSingle: (id) =>
    dispatch(getGeographicalDiscountSingle(id)),
  updateGeographicalDiscount: (id, payload) =>
    dispatch(updateGeographicalDiscount(id, payload)),
  createGeographicalDiscount: (id) => dispatch(createGeographicalDiscount(id)),
});

export const GeographicalDiscountForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
