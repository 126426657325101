import React from "react";
import { confirmAlert } from "react-confirm-alert";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { Loading } from "../../../../../core/components/common";
// import { DataFilter } from '../../../../../openaccess/components/common';
import { serialize } from "../../../../../core/lib";
import { connect } from "react-redux";
import {
  getAllBookList,
  ReviewArticle,
  deleteBookSingleItem,
} from "../../../../actions";

class Book extends BaseComponent {
  state = {
    review: false,
    filterParams: { page: 1 },
  };

  getBookData = async (pageNum = 1) => {
    await this.props.getAllBookList({ pageNum: pageNum });
    this.setState({
      data: this.props.articles.results,
      count: this.props.articles.count,
    });
  };
  async componentDidMount() {
    this.getBookData();
    // await this.props.ReviewArticle();
    setTimeout(() => {
      this.setState({
        review: true,
      });
    }, 300);
  }
  onEditBook = (id) => {
    this.props.history.push(`/article/update-book/${id}`);
  };
  onViewUser = (id) => {
    this.props.history.push(`/article/singlebooks/${id}`);
  };
  removeBookItem = (id, pageNum = 1) => {
    confirmAlert({
      title: "",
      message: "Please confirm to delete",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteBookSingleItem(id).then((res) => {
              if (res.status) {
                this.setState({
                  deletedMsg: "Email deleted successfully",
                });

                this.props.getAllBookList({ pageNum: pageNum }).then((res) => {
                  this.setState({
                    data: this.props.articles.results,
                    count: this.props.articles.count,
                  });
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  pageChange = (pageNumber) => {
    this.getBookData(pageNumber);
  };

  filterData = async (value) => {
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = await serialize(this.state.filterParams);
    this.setState({
      filter_url: filter,
    });
  };

  onFilter = async () => {
    let filter = this.state.filter_url;
    await this.props.getAllBookList(filter);
  };

  render() {
    let heading = ["title", "book_Id", "author", "pISBN", "eISBN"];
    let TableData = [];
    // let filter_fields = [
    //   {
    //     field_name: 'filter_title',
    //     field_type:'text',
    //     placeholder:'Title'
    //   },
    //   {
    //     field_name: 'filter_book_id',
    //     field_type:'text',
    //     placeholder:'Book id'
    //   },
    //   {
    //     field_name: 'filter_author_name',
    //     field_type:'text',
    //     placeholder:'Author name'
    //   },
    //   {
    //     field_name: 'filter_pisbn',
    //     field_type: 'text',
    //     placeholder:'Pisbn'
    //   },
    //   {
    //     field_name: 'filter_eisbn',
    //     field_type: 'text',
    //     placeholder:'Eisbn'
    //   }
    // ];

    if (this.state.data) {
      this.state.data.map((data) => {
        return TableData.push({
          id: data.id,
          title: data.title,
          // doi: data.doi,
          book_Id: data.article_id,
          author:
            data.author &&
            `${data.author.first_name || ""} ${data.author.last_name || ""}`,
          // journal_name: data.journal_name,
          pISBN: data.pisbn,
          eISBN: data.eisbn,
        });
      });
    }

    return (
      <HubContent title="Books">
        <div className="row filter"></div>

        <div className="row">
          <div className="col-12">
            {/* { this.state.data && <DataFilter
                filterData = { this.filterData }
                filter_fields = { filter_fields }
                onClickFilter = { true }
                onFilter = { this.onFilter }
              /> 
            } */}
            <div className="card m-b-20">
              {this.state.data ? (
                <DataTable
                  heading={heading}
                  data={TableData}
                  onEdit={this.onEditBook}
                  onDelete={this.removeBookItem}
                  onView={this.onViewUser}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
          {/* <!-- end col --> */}
        </div>
        {/* <!-- end row --> */}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  articles: state.articles.book_list || false,
});

const mapDispatchToProps = (dispatch) => ({
  getAllBookList: (payload) => dispatch(getAllBookList(payload)),
  ReviewArticle: () => dispatch(ReviewArticle()),
  deleteBookSingleItem: (id) => dispatch(deleteBookSingleItem(id)),
});

export const BookList = connect(mapStateToProps, mapDispatchToProps)(Book);
