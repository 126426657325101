import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import {
    InputBox,
    Button,
    PopupBox,
} from "../../../../../../core/components/common";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import { StripeConfiguration } from "./index"
import {
    createSubmission,
    updateSubmission,
    getSubmissionList,
    getOaSubStatus,
    deleteSubmission,
    getXEROAuthUrl,
    submitXEROAuthCode
} from "../../../../../actions";
import { Popover } from "antd";


const content = (
    <div className="">
        <a href="/" target="_blank" rel="noopener noreferrer">Apps </a>
        <br />

    </div>
);
class PaymentPage extends BaseComponent {
    state = {
        isOpen: false,
        disabled: true,
        isScholarOpen:false,
    };
    async componentDidMount() {

        this.props.getOaSubStatus();
        this.props.getXEROAuthUrl();
         try {
            let data = JSON.parse(localStorage.getItem('sc'));
            this.setState({
                connected: data &&data.status,
            });
         } catch (e) {
          
          }
        
        let queryParm = this.checkUrl(this.props.location.search);

        if (queryParm.code) {
            // let xero_response = await this.props.submitXEROAuthCode({ "auth_code": queryParm.code });
        }
    }


    checkUrl = (query) => {
        try {
            const q =
                query &&
                JSON.parse(
                    '{"' +
                    decodeURI(query.substring(1))
                        .replace(/"/g, '\\"')
                        .replace(/&/g, '","')
                        .replace(/=/g, '":"') +
                    '"}'
                );
            return q;
        } catch (e) {
            return false;
        }
    };

    handleOpenModel = (submissionSystem = null) => { 
        let popupTitle = '', popupDescription = '';

        if (submissionSystem === 'OA_SUB') {
            popupTitle = 'Connect to OaSub';
            popupDescription = 'Please enter your OaSub username and either password or API key.';
        } else if (submissionSystem === 'EM') {
            popupTitle = 'Connect to Editorial Manager';
            popupDescription = 'Please enter your Editorial Manager username and either password or API key.';
        } 

        this.setState({
            sub_system_name: submissionSystem,
            isOpen: !this.state.isOpen,
            submissionSystem,
            popupTitle, 
            popupDescription, 
        });
    };

    handleScholarOpenModel = (scholarOne = null) => { 
        let popupTitle = '', popupDescription = '';

            popupTitle = 'Connect to ScholarOne';
            popupDescription = 'Please enter your ScholarOne username and either password or API key.';
        

        this.setState({
            isScholarOpen: !this.state.isScholarOpen,
            scholarOne,
            popupTitle, 
            popupDescription, 
        });
    };



    onChangeHandler = (value) => {
        this.setState(
            {
                ...value,
            },
            () =>
                this.state.username && (this.state.api_key || this.state.password )
                    ? this.setState({
                        disabled: false,
                    })
                    : this.setState({
                        disabled: true,
                    })
        );
    };
    onDeleteHandler = async (id) => {
        confirmAlert({
            title: "",
            message: "Do you want to disconnect?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        let data = await this.props.deleteSubmission(id);
                        if (data) {
                            await this.props.getOaSubStatus();
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };
    onSubmit = async () => {
     
            const submissionData = {
                name: this.state.sub_system_name,
                organisation:
                    this.props.user &&
                    this.props.user.user &&
                    this.props.user.user.organisation_id,
                credentials: JSON.stringify({
                    username: this.state.username,
                    password: this.state.password,
                    api_key: this.state.api_key,
                }),
            };
            let data = await this.props.createSubmission(submissionData);
            await this.props.getOaSubStatus();
            if (data && data.status === true) {
                this.setState({
                    isOpen: false,
                    status: data.status,
                });
            }            

    };
    onSubmitScholarOne =  () => {
        const scData = {
                username: this.state.username,
                password: this.state.password,
                api_key: this.state.api_key,
                status: true,
                connected:true

            }
        
        
        try {
           localStorage.setItem("sc", JSON.stringify(scData))

            let data = JSON.parse(localStorage.getItem('sc'));
            if (data && data.status === true) {
                this.setState({
                    isScholarOpen: false,
                    status: data.status,
                    connected:data.connected
                });
            }
       } catch (error) {
           
       }
      

    };
    onSubmitXero = async () => {
        window.location.href = this.props.xero_auth_url.auth_url;
        // let queryParm = this.checkUrl(this.props.location.search);
    }
    removeScRemove = () => {
        localStorage.removeItem('sc')
        this.setState({
            connected: false
        });
}
    render() {

        return (
            <HubContent className="setting-heading">
                <div className="row">
                    <div className="col-lg-12">
                        {this.state.status && (
                            <PopupBox
                                className="col-md-6 meta-data"
                                NoText="Metadata Transfer"
                                msgone="Please click now on the below button for article metadata transfer."
                                successMesage="You have successfully provided your submission system Credentials."
                                msgtwo="Once you click on the button, you will be redirected to article
                 metadata transfer page to activate it."
                                onCancel={() =>
                                    this.props.history.push(
                                        "/settings/publisher-article-transfer-config"
                                    )
                                }
                            />
                        )}
                        <div className="card m-b-20 settings-card app">

                            <div className="vat-tax-button d-flex justify-content-end" style={{ marginTop: 20 }}>
                                <Popover placement="leftTop" content={content} trigger="click">
                                    <Button
                                        className="btn btn-secondary float-right "
                                        type=""
                                        name=""
                                        text="Help"
                                    >Help</Button>
                                </Popover>
                                <div className="danger float-right"
                                >
                                    <button
                                        type="danger"
                                        className="btn btn-danger float-right "
                                        onClick={() => this.props.history.push("/settings")}
                                        style={{ marginLeft: 10, marginRight: 10 }}
                                    >
                                        X

</button>                  </div>

                            </div>
                            <div className="card-body">
                                {this.state.isOpen && (
                                    <div className="addmultfield" style={modal.overlay}>
                                        <div
                                            className={`col-sm-6 bg-white alert`}
                                            style={modal.boxStyle}
                                        >
                                            <div className="form-group row sumbmission-row">
                                                <div
                                                    className="col-md-12 text-center submission-title"
                                                    style={{ marginBottom: 15 }}
                                                >
                                                    <label className="submission-system">
                                                        { this.state.popupTitle }
                                                    </label>
                                                </div>
                                                <div className="col-md-12" style={{ marginBottom: 25 }}>
                                                    <div className="row">
                                                        <div
                                                            className="col-md-12 text-center"
                                                            style={{ marginBottom: 10 }}
                                                        >
                                                            <label className="submission-system">
                                                            { this.state.popupDescription }
                                                            </label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="submission-system">
                                                                {" "}
                                Username
                              </label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <InputBox
                                                                onChange={this.onChangeHandler}
                                                                field="username"
                                                                className="col-md-12"
                                                                placeholder="Username"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12" style={{ marginBottom: 25 }}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label className="submission-system">
                                                                API Key
                              </label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <InputBox
                                                                onChange={this.onChangeHandler}
                                                                field="api_key"
                                                                className="col-md-12"
                                                                placeholder="API KEY"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12" style={{ marginBottom: 25 }}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label className="submission-system">
                                                                Password
                              </label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <InputBox
                                                                onChange={this.onChangeHandler}
                                                                field="password"
                                                                className="col-md-12"
                                                                placeholder="Password"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-center sub-btn">
                                                    <button
                                                        className="btn btn-primary btn-md waves-effect "
                                                        onClick={this.onSubmit}
                                                        disabled={this.state.disabled}
                                                    >
                                                        Connect now
                          </button>
                                                    <button
                                                        className="btn btn-secondary btn-md waves-effect "
                                                        onClick={this.handleOpenModel}
                                                    >
                                                        Cancel
                          </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

{this.state.isScholarOpen && (
                                    <div className="addmultfield" style={modal.overlay}>
                                        <div
                                            className={`col-sm-6 bg-white alert`}
                                            style={modal.boxStyle}
                                        >
                                            <div className="form-group row sumbmission-row">
                                                <div
                                                    className="col-md-12 text-center submission-title"
                                                    style={{ marginBottom: 15 }}
                                                >
                                                    <label className="submission-system">
                                                        { this.state.popupTitle }
                                                    </label>
                                                </div>
                                                <div className="col-md-12" style={{ marginBottom: 25 }}>
                                                    <div className="row">
                                                        <div
                                                            className="col-md-12 text-center"
                                                            style={{ marginBottom: 10 }}
                                                        >
                                                            <label className="submission-system">
                                                            { this.state.popupDescription }
                                                            </label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="submission-system">
                                                                {" "}
                                Username
                              </label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <InputBox
                                                                onChange={this.onChangeHandler}
                                                                field="username"
                                                                className="col-md-12"
                                                                placeholder="Username"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12" style={{ marginBottom: 25 }}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label className="submission-system">
                                                                API Key
                              </label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <InputBox
                                                                onChange={this.onChangeHandler}
                                                                field="api_key"
                                                                className="col-md-12"
                                                                placeholder="API KEY"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12" style={{ marginBottom: 25 }}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <label className="submission-system">
                                                                Password
                              </label>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <InputBox
                                                                onChange={this.onChangeHandler}
                                                                field="password"
                                                                className="col-md-12"
                                                                placeholder="Password"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-center sub-btn">
                                                    <button
                                                        className="btn btn-primary btn-md waves-effect "
                                                        onClick={this.onSubmitScholarOne}
                                                        disabled={this.state.disabled}
                                                    >
                                                        Connect now
                          </button>
                                                    <button
                                                        className="btn btn-secondary btn-md waves-effect "
                                                        onClick={this.handleScholarOpenModel}
                                                    >
                                                        Cancel
                          </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="profile-heading">Payment Gateway </h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <StripeConfiguration className="col-6 col-sm-6 col-md-4" />
        
                             
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="profile-heading  m-30">
                                            Peer Review and Production System{" "}
                                        </h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <div className="single-setting">
                                            <div className="setting-icon">

                                                <img
                                                    src="https://blog.growkudos.com/hs-fs/hubfs/Imported_Blog_Media/em_dot_only_camtasia-2-1.png?width=246&name=em_dot_only_camtasia-2-1.png"
                                                    alt=""
                                                    className="app-icon img-fluid"
                                                />
                                            </div>
                                            <div className="setting-info">
                                                <h4>Editorial Manager</h4>

                                                <p>Connect to Editorial Manager.</p>

                                                {this.props.sub_status &&
                                                    this.props.sub_status.EM.connected === true ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light green"
                                                        >
                                                            Connected
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={ () => this.handleOpenModel('EM')}
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light "
                                                        >
                                                            Connect
                                                        </button>
                                                    )}
                                                <button
                                                    disabled={
                                                        this.props.sub_status &&
                                                            this.props.sub_status.EM.connected === false
                                                            ? true
                                                            : false
                                                    }
                                                    type="button"
                                                    onClick={() =>
                                                        this.onDeleteHandler(
                                                            this.props.sub_status &&
                                                            this.props.sub_status.EM.object_id
                                                        )
                                                    }
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    Revoke
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <div className="single-setting">
                                            <div className="setting-icon">

                                            <img
                                                    src="/assets/images/manuscript.png"
                                                    alt=""
                                                    className="app-icon img-fluid"
                                                />
                                            </div>
                                            <div className="setting-info">
                                                <h4>OaSub</h4>

                                                <p>Connect to OaSub.</p>
                                                {this.props.sub_status && this.props.sub_status.OA_SUB && 
                                                    this.props.sub_status.OA_SUB.connected === true ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light green"
                                                        >
                                                            Connected
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => this.handleOpenModel('OA_SUB')}
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light "
                                                        >
                                                            Connect
                                                        </button>
                                                    )}
                                                <button
                                                    disabled={
                                                        this.props.sub_status &&
                                                            this.props.sub_status.OA_SUB.connected === false
                                                            ? true
                                                            : false
                                                    }
                                                    type="button"
                                                    onClick={() =>
                                                        this.onDeleteHandler(
                                                            this.props.sub_status &&
                                                            this.props.sub_status.OA_SUB.object_id
                                                        )
                                                    }
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    Revoke
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4">
                                        <div className="single-setting">
                                            <div className="setting-icon">
                                               
                                            </div>
                                            <div className="setting-info">
                                                <h4>ScholarOne</h4>

                                                

                                                <p>Connect to ScholarOne.</p>

                            
                                                {this.state.connected === true ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light green"
                                                        >
                                                            Connected
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => this.handleScholarOpenModel('SC')}
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light "
                                                        >
                                                            Connect
                                                        </button>
                                                    )}
                                                <button
                                                    disabled={
                                                        this.state.connected === false
                                                            ? true
                                                            : false
                                                    }
                                                    type="button"
                                                    onClick={this.removeScRemove}
                                                    
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    Revoke
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="profile-heading  m-30">
                                            {" "}
                      CRM and Accounting System{" "}
                                        </h3>
                                    </div>
                                </div>
                                <div className="row">
            
                                    <div className="col-md-4">
                                        <div className="single-setting">
                                            <div className="setting-icon">
                                                {" "}
                                                <img
                                                    src="https://www.xero.com/etc/designs/xero-cms/clientlib/assets/img/logo/logo-xero-blue.svg"
                                                    alt=""
                                                    className="app-icon"
                                                />
                                            </div>
                                            <div className="setting-info">
                                                <h4>XERO</h4>
                                                <p>Connect to XERO.</p>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    onClick={this.onSubmitXero}
                                                >
                                                    Connect
                        </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    Revoke
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth || {},
    createSubmission: state.submissionCreds.createSubmission,
    update_submission: state.submissionCreds.update_submission,
    submission_creds_info: state.submissionCreds.submission_creds_info,
    sub_status: state.submissionCreds.sub_status,
    xero_auth_url: state.xero.xero_auth_url,
    xero_auth_code_submission: state.xero.submit_auth_code,
});

const mapDispatchToProps = (dispatch) => ({
    createSubmission: (payload) => dispatch(createSubmission(payload)),
    updateSubmission: (payload, id) => dispatch(updateSubmission(payload, id)),
    getSubmissionList: () => dispatch(getSubmissionList()),
    getOaSubStatus: () => dispatch(getOaSubStatus()),
    deleteSubmission: (id) => dispatch(deleteSubmission(id)),
    getXEROAuthUrl: () => dispatch(getXEROAuthUrl()),
    submitXEROAuthCode: (payload) => dispatch(submitXEROAuthCode(payload))
});

export const PaymentGateWay = connect(mapStateToProps, mapDispatchToProps)(PaymentPage);

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
    boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
    boxHeight = "-webkit-fit-content";
}

const modal = {
    overlay: {
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.64)",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 1,
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
    },
    boxStyle: {
        transform: "translate(100px, 100px)",
        height: boxHeight,
    },
};














