import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { DataTable, HubContent } from '../../../../../core/components';
import {  getOrganisationToken ,deleteJournal} from '../../../../actions';
import { Loading, PopupBox } from '../../../../../core/components/common';
import NotFound from '../../NotFound';

class OrgToken extends BaseComponent {
    state = {
        deleteConfirm: false,
        journalId: null,
        waitingMsg: null,
        deleteStatus: null,
        state:[]
    }
    getOrgTokenData = async (pageNum=1,page=10) =>{
        await this.props.getOrganisationToken({pageNum:pageNum,pageSize:page});
        let OffsetFundList = this.props.OrgTokenList.results;
        let dataOrgToken=[];
        OffsetFundList.map((item)=>{
            return dataOrgToken.push({
                organisation_name:item.organisation_name,
                token_id:item.token_info,
                status:item.is_used ? 'Active' : 'Already Used',
                id:item.id
            });
        })
        this.setState({
            data:dataOrgToken,
            count:this.props.OrgTokenList.count
        })
    }
     componentDidMount() {
        this.getOrgTokenData();
    }
    pageChange =(pageNumber) =>{
        this.getOrgTokenData(pageNumber);
    }

    onEditjournal = (id) => {
        // this.props.history.push('/journals/edit-journal/' + id)
    }

    onViewjournal = (id) => {
        // this.props.history.push('/journals/single-journal/' + id)
    }

    onDeletejournal = (id) => {
        if (id) {
            this.setState({
                deleteConfirm: true,
                journalId: id
            })
        }
    }

    onDeletejournalHander = async () => {
        const journalId = this.state.journalId;
        this.setState({
            waitingMsg: "Deleting..."
        })
        const journal = await this.props.deleteJournal(journalId);
        let data = this.state.data;
        let newData = data
        .filter(function(element) {
            return element.id !== journalId;
        });
        this.setState({
            waitingMsg: null,
            journalId: null,
            deleteConfirm: false,
            deleteStatus: journal.status,
            data:newData
        })
    }

    onCancel = () => {
        this.setState({
            deleteConfirm: false,
            journalId: null,
            waitingMsg: null,
            deleteStatus: null
        })
    }

    render() {
        const heading = ['organisation_name', 'token_id', 'status']
        return (
            this.state.status === 403 ? <NotFound/> :
            <HubContent title="Tokens">
                {this.state.deleteConfirm && <PopupBox
                    title="Do you want to delete?"
                    YesText="Yes"
                    yesBtnType="danger"
                    type="danger"
                    NoBtnType="success"
                    onCancel={this.onCancel}
                    onPressOK={this.onDeletejournalHander}
                    waitingMsg={this.state.waitingMsg}
                />}
                {this.state.deleteStatus && <PopupBox
                    title="Successfully deleted"
                    onCancel={this.onCancel}
                />}

                {this.state.deleteStatus === false && <PopupBox
                    title="Failed... Try Again"
                    onCancel={this.onCancel}
                    NoBtnType="danger"
                />}
                {/* {errorHander} */}
                <div className="row">
                    <div className="col-12">
                            <div className="card m-b-20">
                                {this.state.data ?
                                    <DataTable
                                        heading={heading}
                                        data={this.state.data}
                                        onEdit={this.onEditjournal}
                                        onDelete={this.onDeletejournal}
                                        onView={this.onViewjournal}
                                        count={this.state.count}
                                        pageChange={this.pageChange}
                                        pageSize={10}
                                    />
                                    : 
                                    <Loading/>
                                }
                            </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    OrgTokenList: state.oaToken.organisation_token_list || false,
})

const mapDispatchToProps = (dispatch) => ({
    getOrganisationToken: (payload) => dispatch(getOrganisationToken(payload)),
    deleteJournal: (id) => dispatch(deleteJournal(id))
})

export const OrgTokenList = connect(mapStateToProps, mapDispatchToProps)(OrgToken);

