import React, { Component } from "react";
import {
  Button,
  InputBox,
  Loading,
  DBCard,
} from "../../../../../core/components/common";
import {
  getPublisherConfigSingle,
  getPublisherInvoicePreview,
  updatePublisherConfigSingle,
  updateInvoiceNofitication,
  getInvoiceNotficationList,
} from "../../../../actions";
import { connect } from "react-redux";
import Cropper from "react-cropper";

import ReactHtmlParser from "react-html-parser";
import { confirmAlert } from "react-confirm-alert"; // Import

import { Radio, Popover } from "antd";
import "antd/dist/antd.css";
import "react-color-picker/index.css";

import { HubContent } from "../../../../../core/components";
import { BankDetailsForm } from "../../../../../core/components/common/BankDetailsForm";
import InvoiceReminder from "./reminder";
import DuePaymentReminder from "./DuePaymentDate";

const src = "img/child.jpg";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Invoice</a>
    <br />

  </div>
);
class CustomInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src,
      cropResult: null,
      bankData: {
        address_details: {},
      },
      tokenData: {},
      billing_address: {},
      payment_data: {},
      bank_details: {},
      address_details: {},
      cardToken: {},
      invoiceData: {},
      notification: {},
      isOpen: true,
      isOpenImageModel: false,
      iseditOpen: true,
      isClose: false,
      isDueOpen: true,
      isDueEditOpen: true,
      isDueClose: false,
      color: "red",
      is_landscape:
        this.props.payment_transaction &&
        this.props.payment_transaction.is_landscape,
      send_email_on_trigger: 1,
      isOpenAddress: false,
      bank_account_list: [],
    };
    this.cropImage = this.cropImage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.useDefaultImage = this.useDefaultImage.bind(this);
  }
  onSubmitHandler = async (e) => {
    e.preventdefault();
  };
  onDrop = (picture) => {

    let image = new FormData();
    image.append("company_logo", picture[0]);
    this.onUpload(image);
  };
  _is_Mounted = false
  componentDidMount() {
    this._is_Mounted = true
    this.customInvoice()
  }
  customInvoice = async () => {
    const data = await this.props.getInvoiceNotficationList();
    await this.props.getPublisherInvoicePreview();
    await this.props.getPublisherConfigSingle();

    if (this._is_Mounted) {
      this.setState({
        reminders_data_1: data.reminder_1,
        reminders_data_2: data.reminder_2,
        reminders_data_3: data.reminder_3,
        success: data.reminder_1 || data.reminder_2 || data.reminder_3,
        is_landscape:
          this.props.payment_transaction &&
          this.props.payment_transaction.is_landscape,
        show_currency_instruction:
          this.props.payment_transaction &&
          this.props.payment_transaction.show_currency_instruction,
        currency_instruction:
          this.props.payment_transaction &&
          this.props.payment_transaction.currency_instruction,
        disclaimer_note:
          this.props.payment_transaction &&
          this.props.payment_transaction.disclaimer_note,
        bank_account_list:
            this.props.payment_transaction &&
            this.props.payment_transaction.all_bank_accounts,
        bank_details:
          this.props.payment_transaction &&
          this.props.payment_transaction.bank_details,
        invoice: this.props.payment_transaction && this.props.payment_transaction,
        reminder_1:
          this.props.invoice_notification &&
          this.props.invoice_notification.reminder_1,
        reminder_2:
          this.props.invoice_notification &&
          this.props.invoice_notification.reminder_2,
        reminder_3:
          this.props.invoice_notification &&
          this.props.invoice_notification.reminder_3,
        due_day:
          this.props.invoice_notification &&
          this.props.invoice_notification.due_day,
        send_email_on_trigger:
          this.props.invoice_notification &&
          this.props.invoice_notification.send_email_on_trigger,
        id: this.props.invoice_notification && this.props.invoice_notification.id,
        organisation:
          this.props.invoice_notification &&
          this.props.invoice_notification.organisation,
        bankData:
          (this.props.payment_transaction &&
            this.props.payment_transaction.bank_details) ||
          {},
      });
    }
  }
  componentWillUnmount() {
    this._is_Mounted = false
  }
  onUpload = async (picture) => {
    if (this.props.invoice_id) {
      await this.props.updatePublisherConfigSingle(
        this.props.invoice_id,
        picture
      );
      await this.props.getPublisherInvoicePreview();
    }
  };
  onChangeCard = async (value) => {
    if (value.licence === 1) {
      this.setState({
        licence: value.licence,
      });
    } else if (value.licence === 2) {
      this.setState({
        licence: value.licence,
      });
    } else if (value.licence === 3) {
      this.setState({
        licence: value.licence,
      });
    }
    let keys = Object.keys(value);
    let values = Object.values(value);
    if (keys[0] === "currency") {
      let data = this.formatCurrency();
      let index = data && data.findIndex((x) => x.id === values[0]);
      let currency_code = data[index].name;
      this.setState({
        currency_code: currency_code.toLowerCase(),
      });
      this.onArticlePaymentSummary({
        isParam: true,
        currency_code: currency_code.toLowerCase(),
      });
    }
    if (keys[0] === "note_1" || keys[0] === "note_2") {
      this.setState((prevState) => {
        Object.assign(prevState.invoiceData, value);
        return prevState;
      });
    }

    let billing_address = [
      "address_line1",
      "address_line2",
      "address_zip",
      "address_city",
      "address_state",
      "address_country",
    ];
    let payment_data = ["licence", "currency", "remarks"];
    if (billing_address.includes(keys[0])) {
      let newVal;
      if (keys[0] === "address_line1") {
        newVal = { street_address1: values[0] };
      } else if (keys[0] === "address_line2") {
        newVal = { street_address2: values[0] };
      } else if (keys[0] === "address_city") {
        newVal = { city: values[0] };
      } else if (keys[0] === "address_state") {
        newVal = { state: values[0] };
      } else if (keys[0] === "address_country") {
        newVal = { country: values[0] };
      } else if (keys[0] === "address_zip") {
        newVal = { postal_code: values[0] };
      }
      this.setState((prevState) => {
        Object.assign(prevState.billing_address, newVal);
        return prevState;
      });
    } else if (payment_data.includes(keys[0])) {
      this.setState((prevState) => {
        Object.assign(prevState.payment_data, value);
        return prevState;
      });
    }
    if (!payment_data.includes(keys[0])) {
      this.setState((prevState) => {
        Object.assign(prevState.cardToken, value);
        return prevState;
      });
    }
    this.setState((prevState) => {
      Object.assign(prevState.bankData, value);
      return prevState;
    });
  };

  onSubmitHandler = async () => {
    let id = this.props.invoice_id;
    this.state.bankData &&
      this.state.bankData.note_1 &&
      delete this.state.bankData.note_1;
    this.state.bankData &&
      this.state.bankData.note_2 &&
      delete this.state.bankData.note_2;
    let invoiceBankData = Object.assign(this.state.invoiceData, {
      bank_details: this.state.bankData,
    });
    let invoiceData = Object.assign(invoiceBankData, {
      address_details: this.state.address_details,
    });
    await this.props.updatePublisherConfigSingle(id, invoiceData);
    await this.props.getPublisherInvoicePreview();
  };

  updateCurrencyNoteHandler = async () => {
    if (this.props.invoice_id) {
      await this.props.updatePublisherConfigSingle(this.props.invoice_id, {
        show_currency_instruction: this.state.show_currency_instruction,
        currency_instruction: this.state.currency_instruction,
        disclaimer_note: this.state.disclaimer_note,
      })

      confirmAlert({
        title: "",
        message: "Updated successfully.",
        buttons: [
          {
            label: "Close",
            onClick: () => { },
          },
        ],
      });
    }
  }

  onChange = async (e) => {
    this.setState({
      value: e.target.value,
    });
    let isLandscape = e.target.value === 2 ? true : false;
    if (this.props.invoice_id) {
      await this.props.updatePublisherConfigSingle(this.props.invoice_id, {
        is_landscape: isLandscape,
      });
      await this.props.getPublisherInvoicePreview();
      this.setState({
        is_landscape: isLandscape,
      });
    }
  };
  
  onRadioChangeHandler = async (event) => {
    this.setState({
      show_currency_instruction: event.target.value,
    })
  }

  onInstructionChangeHandler = async (event) => { 
    let name = event.target.name
    
    let value = {
      [name]: event.target.value,
    }

    this.setState((prevState) => {
      Object.assign(prevState, value)
      return prevState;
    });
  }

  onDrag(color, c) {
    this.setState({
      color,
    });
  }
  // Callback~
  getFiles(files) {
    this.onUpload({ company_logo: files[0].base64, logo_name: files[0].name });
  }
  onFileDrop = (payload) => {
    if (payload.height >= 300 || payload.width >= 300) {
      confirmAlert({
        title: "",
        message: `Logo dimension should be (200 X 100). your uploaded photo ${payload.width} X ${payload.height}`,
        buttons: [
          {
            label: "Cancel",
            onClick: () => { },
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Upload Logo",
        message: "Are you sure, you want to upload logo?",
        buttons: [
          {
            label: "Upload",
            onClick: () => {
              this.onUpload(payload);
            },
          },
          {
            label: "Cancel",
            onClick: () => { },
          },
        ],
      });
      delete payload.width;
      delete payload.height;
    }
  };

  handleOpen = () => {
    this.setState({
      isOpen: true,
    });
  };
  handleInvoiceReminder = () => {
    this.setState({
      isClose: !this.state.isClose,
      isOpen: !this.state.isOpen,
    });
  };
  isHandleEditOpen = () => {
    this.setState({
      iseditOpen: !this.state.iseditOpen,
      isClose: !this.state.isClose,
    });
  };
  handleDuePayment = () => {
    this.setState({
      isDueClose: !this.state.isDueClose,
      isDueOpen: !this.state.isDueOpen,
    });
  };
  handleEditDuePayment = () => {
    this.setState({
      isDueEditOpen: !this.state.isDueEditOpen,
      isDueClose: !this.state.isDueClose,
    });
  };
  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.notification, value);
    });
  };
  onChangeRemdiner = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeValue = (e) => {
    this.setState({
      send_email_on_trigger: e.target.value,
    });
  };
  UpdateInvoiceNotification = async () => {
    const notification = {
      id: this.state.id,
      reminder_1: this.state.reminder_1,
      reminder_2: this.state.reminder_2,
      reminder_3: this.state.reminder_3,
      due_day: this.state.due_day,
      organisation: this.state.organisation,
      send_email_on_trigger: this.state.send_email_on_trigger,
      context: "INVOICE",
    };
    let data = await this.props.UpdateinvoiceNotification(
      this.state.id,
      notification
    );

    this.setState({
      isClose: !this.state.isClose,
      iseditOpen: !this.state.iseditOpen,

      success: data.reminder_1 || data.reminder_2 || data.reminder_3,
    });
  };
  UpdateInvoiceDuePayment = async () => {
    const notification = {
      id: this.state.id,
      reminder_1: this.state.reminder_1,
      reminder_2: this.state.reminder_2,
      reminder_3: this.state.reminder_3,
      due_day: this.state.due_day,
      organisation: this.state.organisation,
      send_email_on_trigger: this.state.send_email_on_trigger,
      context: "INVOICE",
    };
    await this.props.UpdateinvoiceNotification(this.state.id, notification);
    this.setState({
      isDueClose: !this.state.isDueClose,
      isDueEditOpen: !this.state.isDueEditOpen,
    });
  };
  handleOpenAddress = () => {
    this.setState({
      isOpenAddress: !this.state.isOpenAddress,
    });
  };
  onChangeHanlder = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);

    this.setState({
      filename: files[0].name,
      isOpenImageModel: !this.state.isOpenImageModel,
    });
  };

  cropImage = async (filename) => {

    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    let data = this.cropper
      .getCroppedCanvas()
      .toDataURL()
      .replace(/^data:.+;base64,/, "");


    if (this.props.invoice_id) {
      await this.props.updatePublisherConfigSingle(this.props.invoice_id, {
        company_logo: data,
        logo_name: String(this.state.filename),
      });
      await this.props.getPublisherInvoicePreview();
    }
    this.setState({
      cropResult:
        this.cropper.getCroppedCanvas() &&
        this.cropper.getCroppedCanvas().toDataURL(),
      isOpenImageModel: !this.state.isOpenImageModel,
    });
  };

  useDefaultImage() {
    this.setState({ src });
  }

  render() {
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body useremail invoice">
                {this.state.isOpenAddress && (
                  <BankDetailsForm
                    handleOpenAddress={this.handleOpenAddress}
                    isOpenAddress={this.state.isOpenAddress}
                    invoice_id={this.props.invoice_id}
                  />
                )}
                <div className="vat-tax-button d-flex justify-content-end">
                  <Popover placement="leftTop" content={content} trigger="click">
                    <Button
                      className="btn btn-secondary float-right "
                      type=""
                      name=""
                      text="Help"
                    >Help</Button>
                  </Popover>
                  <div className="danger float-right"
                  >
                    <button
                      type="danger"
                      className="btn btn-danger float-right "
                      onClick={() => this.props.history.push("/settings")}
                      style={{ marginLeft: 10, marginRight: 10 }}
                    >
                      X

</button>                  </div>

                </div>

                <div className="useremail-right">
                  <ul className="nav nav-pills nav-justified" role="tablist">
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#home-template"
                        role="tab"
                      >
                        Invoice Template Config
                      </a>
                    </li>

                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#home-reminder"
                        role="tab"
                      >
                        Payment Reminder Config
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#home-due"
                        role="tab"
                      >
                        Invoice Due Config
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#home-payment-instruction"
                        role="tab"
                      >
                        Currency Note
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      style={style.minHeight}
                      className="tab-pane active p-3"
                      id="home-template"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-md-10">
                          <Button
                            type="primary"
                            text="+ Customise"
                            onClick={this.handleOpenAddress}
                            name="primary"
                            className="float-right"
                          />
                        </div>
                      </div>
                      <div className="row">
                        {/* Will be removed later */}
                        {false && <div className="col-md-3">
                          <label className="invoice-template-pick">
                            Select invoice template{" "}
                          </label>
                          <Radio.Group
                            onChange={this.onChange}
                            value={this.state.is_landscape ? 2 : 1}
                          >
                            <Radio value={1}>Portrait</Radio>
                            <Radio value={2}>Landscape</Radio>
                          </Radio.Group>
                          <div
                            className="bank-details-info"
                            style={{ marginTop: 10 }}
                          >
                            <label className="bank-address">
                              Add Bank Details
                            </label>
                            <Button
                              type="primary"
                              text="+"
                              onClick={this.handleOpenAddress}
                              name="primary"
                            />
                          </div>

                          <div className="logo invoice">
                            <label className="logo-text">Logo</label>
                            <input
                              className="upload-image"
                              type="file"
                              onChange={this.onChangeHanlder}
                            />
                            {this.state.isOpenImageModel && (
                              <div
                                className="addmultfield"
                                style={modal.overlay}
                              >
                                <div
                                  className={`col-md-6 bg-white alert`}
                                  style={modal.boxStyle}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Cropper
                                      style={{
                                        height: 300,
                                        width: "100%",
                                        float: "left",
                                      }}
                                      aspectRatio={16 / 9}
                                      preview=".img-preview"
                                      guides={false}
                                      src={this.state.src}
                                      ref={(cropper) => {
                                        this.cropper = cropper;
                                      }}
                                    />


                                  </div>
                                  <div style={{ marginTop: 15 }}>
                                    <Button
                                      type="primary"
                                      name="primary"
                                      text="Crop Image"
                                      onClick={this.cropImage}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}


                          </div>

                          <label className="bank-address">Add your Notes</label>
                          <div className="invoice-note">
                            <InputBox
                              onChange={(value) => this.onChangeCard(value)}
                              field="note_1"
                              placeholder="Note 1"
                              className="pay-right-ii"
                              rows={7}
                              defaultValue={
                                this.state.invoice && this.state.invoice.note_1
                              }
                            />
                          </div>
                          <div className="invoice-note">
                            <InputBox
                              onChange={(value) => this.onChangeCard(value)}
                              field="note_2"
                              placeholder="Note 2"
                              className="pay-right-ii"
                              rows={7}
                              defaultValue={
                                this.state.invoice && this.state.invoice.note_2
                              }
                            />
                          </div>

                          <div className="text-center">
                            <Button
                              type="primary"
                              text="Update Notes"
                              name="primary"
                              onClick={this.onSubmitHandler}
                            />
                          </div>
                        </div>
                        }

                        <div className="col-md-11">
                          {this.props.invoice_preview ? (
                            <div
                              className="custom-template"
                              style={{
                                width: this.state.is_landscape ? "100%" : "90%",
                                margin: "0 auto",
                              }}
                            >
                              {ReactHtmlParser(this.props.invoice_preview)}
                            </div>
                          ) : (
                              <Loading type="flat" />
                            )}
                        </div>
                      </div>
                    </div>

                    <div
                      style={style.minHeight}
                      className="tab-pane p-3"
                      id="home-reminder"
                      role="tabpanel"
                    >
                      {this.state.success ? (
                        <div className="col-md-10 invoice-reminder">
                          <div className="reminder-results">
                            <p>
                              Oametrix will send automatic reminders to authors
                              or institiutions for APC payment.
                            </p>
                            <p className="reminder-para">
                              You can view your saved payment reminders below.
                            </p>
                            <div className="reminder-days">
                              <div className="row">
                                <DBCard
                                  className="col-md-4 bg-one"
                                  date="days"
                                  icon="mdi mdi-cube-outline"
                                  title="1st Reminder"
                                  body={this.state.reminder_1}
                                />

                                <DBCard
                                  className="col-md-4 bg-two"
                                  date="days"
                                  icon="mdi mdi-cube-outline"
                                  title="2nd Reminder"
                                  body={this.state.reminder_2}
                                />
                                <DBCard
                                  date="days"
                                  className="col-md-4 bg-three"
                                  icon="mdi mdi-cube-outline"
                                  title="Final Reminder"
                                  body={this.state.reminder_3}
                                />
                              </div>
                            </div>
                            <div
                              className="reminder-button"
                              style={{ marginTop: 20 }}
                            >
                              <Button
                                type="primary"
                                text="Update Payment Reminders"
                                name="primary"
                                onClick={() => this.isHandleEditOpen()}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                          <div className="col-md-8 invoice-reminder">
                            {this.state.isOpen && (
                              <div className="reminder">
                                <h3>Please setup your payment reminder</h3>
                                <button
                                  onClick={() => this.handleInvoiceReminder()}
                                  className="btn btn-primary btn-md waves-effect waves-light"
                                >
                                  Add
                              </button>
                              </div>
                            )}
                          </div>
                        )}
                      {this.state.isClose && (
                        <InvoiceReminder
                          paymentReminder={this.state.isClose}
                          reminder_1={this.state.reminder_1}
                          reminder_2={this.state.reminder_2}
                          reminder_3={this.state.reminder_3}
                          onChangeRemdiner={this.onChangeRemdiner}
                          onChangeValue={this.onChangeValue}
                          UpdateInvoiceNotification={() =>
                            this.UpdateInvoiceNotification()
                          }
                          send_email_on_trigger={
                            this.state.send_email_on_trigger
                          }
                        />
                      )}
                    </div>
                    <div
                      style={style.minHeight}
                      className="tab-pane p-3"
                      id="home-due"
                      role="tabpanel"
                    >
                      {this.state.due_day ? (
                        <div className="col-md-8 invoice-reminder">
                          <div className="reminder-results">
                            <h3>
                              You have setup invoice due {this.state.due_day} days
                            </h3>
                            <p>
                              All the invoices raised by authors or institutions
                              will be valid for {this.state.due_day} days.
                              Invoice will be expired if no payment made within
                              set due days
                            </p>
                            <Button
                              type="primary"
                              text="Update Due dates"
                              name="primary"
                              onClick={() => this.handleEditDuePayment()}
                            />
                          </div>
                        </div>
                      ) : null}
                      {this.state.isDueClose && (
                        <DuePaymentReminder
                          DueReminder={this.state.isDueClose}
                          due_day={this.state.due_day}
                          UpdateInvoiceNotification={() =>
                            this.UpdateInvoiceDuePayment()
                          }
                          onChangeRemdiner={this.onChangeRemdiner}
                        />
                      )}
                    </div>
                    <div
                      style={style.minHeight}
                      className="tab-pane p-3"
                      id="home-payment-instruction"
                      role="tabpanel"
                    >
                      <div className="container pt-4">
                        <div className="row">
                          <div className="offset-sm-2 col-sm-8">
                            <div className="form-group row">
                              <label>
                                Would you like to show currency selection note? &nbsp;&nbsp;
                                <Radio.Group
                                  onChange={this.onRadioChangeHandler}
                                  value={!!this.state.show_currency_instruction}
                                >
                                  <Radio value={true}>Yes</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              </label>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-4">
                                Currency note
                              </label>
                              <div className="col-sm-6">
                                <textarea 
                                  onChange={this.onInstructionChangeHandler}
                                  name="currency_instruction"
                                  placeholder="Note"
                                  className="form-control"
                                  rows={7}
                                  value={
                                    this.state.currency_instruction
                                  }
                                />
                              </div>
                            </div>
                            
                            <div className="form-group row">
                              <label className="col-sm-4">
                                Payment disclaimer note
                              </label>
                              <div className="col-sm-6">
                                <textarea 
                                  onChange={this.onInstructionChangeHandler}
                                  name="disclaimer_note"
                                  placeholder="Note"
                                  className="form-control"
                                  rows={7}
                                  value={
                                    this.state.disclaimer_note
                                  }
                                />
                              </div>
                            </div>
                            
                            <div className="form-group row">
                              <div className="offset-sm-4 col-sm-6">
                                <Button
                                  type="primary"
                                  text="Update Note"
                                  name="primary"
                                  onClick={() => this.updateCurrencyNoteHandler()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const style = {
  commonWidth: {
    width: "100%",
  },
  floatLeft: {
    marginTop: 10,
  },
  labelAdjustment: {
  },
  minHeight: {
  },
};

const mapStateToProps = (state) => ({
  invoice_preview:
    (state.payment &&
      state.payment.invoice_preview &&
      state.payment.invoice_preview.html) ||
    null,
  payment_transaction:
    (state.payment && state.payment.payment_transaction) ,
  is_landscape: (state.payment && state.payment.payment_transaction) ,
  invoice_single: (state.payment && state.payment.payment_transaction) ,
  invoice_id:
    (state.payment &&
      state.payment.payment_transaction &&
      state.payment.payment_transaction.id) ||
    null,
  invoice_notification: state.payment && state.payment.invoice_notificatin,
  update_notificatin:
    state.update_notificatin && state.payment.update_notificatin,
  countries: state.app.countries,
});

const mapDispatchToProps = (dispatch) => ({
  getPublisherConfigSingle: (payload) =>
    dispatch(getPublisherConfigSingle(payload)),
  getPublisherInvoicePreview: (payload) =>
    dispatch(getPublisherInvoicePreview(payload)),
  updatePublisherConfigSingle: (id, payload) =>
    dispatch(updatePublisherConfigSingle(id, payload)),
  UpdateinvoiceNotification: (id, payload) =>
    dispatch(updateInvoiceNofitication(id, payload)),
  getInvoiceNotficationList: () => dispatch(getInvoiceNotficationList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomInvoice);

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(90px, 90px)",
    height: boxHeight,
  },
};
