import React from "react";
import { connect } from "react-redux";
import { AutoComplete,Tooltip} from "antd";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import Ckditor from "ckeditor4-react";
import CreatableSelect from 'react-select';

import { InfoCircleTwoTone} from "@ant-design/icons";
import {
    sendFreeTextEmail,
    searchArticleFull,
} from "../../../../../actions";

import {
    SelectBox,
    Loading,
    InputBox,

} from '../../../../../../core/components/common';

const components = {
  DropdownIndicator: null,
};

const createOption = (label, value = null) => ({
  label,
  value: value || label,
});

export class CustomEmail extends BaseComponent {

    state = {
        inputValue: '',
        message_body: "",
        recipients: this.props.recipients || [],
      }
    
      contactTypes = {
        customer_service: 'Customer service',
        editorial: 'Editorial',
        finance: 'Finance',
        production: 'Production',
        sales: 'Sales',
      }
    
      componentDidMount() {
        this.setPublicationContacts();
      };
    
      setPublicationContacts = () => {
        let publication = this.props.article && this.props.article.publication
    
        if (publication && Array.isArray(publication.publication_contacts)) {
          let publicationContacts = publication.publication_contacts
    
          let recipients = publicationContacts.map(contact => {
            let label = `${contact.contact_email}(${contact.contact_name}, ${this.contactTypes[contact.contact_department]})`
            return createOption(label, contact.contact_email)
          })
    
          this.setState({
            recipients,
          })
        }
      }
    
      handleChange = (recipients, actionMeta) => {
        this.setState({
          recipients
        }, () => {
          this.onChangeHandler({ recipients, });
        });
      };
    
      handleInputChange = (inputValue) => {
        this.setState({ inputValue });
      };
    
      handleKeyDown = (event) => {
        const { inputValue, recipients } = this.state;
        if (!inputValue) return;
    
        switch (event.key) {
          case 'Enter':
          case 'Tab':
          case ' ':
            let recipientsList = [...recipients, createOption(inputValue)];
            this.setState({
              inputValue: '',
              recipients: recipientsList,
            }, () => {
              this.onChangeHandler({ recipients: recipientsList, });
            });
            event.preventDefault();
            break;
          default:
            break;
        }
      };
    
      handleBlurEvent = (event) => {
        const { inputValue, recipients } = this.state;
        if (!inputValue) return;
    
        if (this.isValidEmail(inputValue)) {
          let recipientsList = [...recipients, createOption(inputValue)];
          this.setState({
            inputValue: '',
            recipients: recipientsList,
          }, () => {
            this.onChangeHandler({ recipients: recipientsList, });
          });
        }
      }
    
      isValidEmail = (value) => {
        // eslint-disable-next-line
        const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        return pattern.test(value && value.trim())
      }
    


      onEditorChange = evt => {
        let value = {
          message_body: evt.editor.getData()
        };
    
        this.onChangeHandler(value);
      };
    

    onSelect = (value, item) => {
        this.setState({
            article_id: item.id,
        });
    };

    onSearch = (value) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            let params = {
                q: value,
            };

            this.props.searchArticleFull(params).then(response => {
                if (response && response.success) {
                    this.setState({
                        dataList: response.results && response.results.map(item => ({
                            id: item.id,
                            value: item.title,
                        }))
                    });
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });

        }, 200);
    };

    onChangeHandler = (value) => {

        if (value && value.context === 'ARTICLE') {
            this.props.searchArticleFull({}).then(response => {
                if (response && response.success) {
                    this.setState({
                        dataList: response.results && response.results.map(item => ({
                            id: item.id,
                            value: item.title,
                        }))
                    });
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });
        }

        this.setState((prevState) => {
            return Object.assign(prevState, value);
        });
    };

    onSubmitHandler = async (e) => {

        let data = {
            context: this.state.context,
            article_id: this.state.context === 'ARTICLE' ? this.state.article_id : null,
            subject: this.state.subject,
            recipients: this.state.recipients,
            message_body: this.state.message_body,
        };

        if (!data.context || !data.subject || !data.recipients || !data.message_body) {
            this.setState({
                isEmpty: <div>
                    {!data.context && <p>Context is required.</p>}
                    {(data.context === 'ARTICLE' && !data.article_id) && <p>Context is required.</p>}
                    {!data.recipients && <p>Recipient is required.</p>}
                    {!data.subject && <p>Subject is required.</p>}
                    {!data.message_body && <p>Message is required.</p>}
                </div>
            })
        } else {

            this.props.sendFreeTextEmail(data).then(response => {
                if (response.data && response.data.success) {
                    this.setState({
                        showCustomEmailForm: false,
                        successMsg: response.data.message,
                        context: '',
                        article_id: '',
                        subject: '',
                        recipients: '',
                        message_body: '',
                    })
                    this.props.history.push(this.props.group==="AUTHOR" ?"/author-custom-email/author-outbox-email-list": "/author-custom-email/author-custom-email-list")
                }
            }).catch(error => {
                return error.response && error.response.data && error.response.data.success;
            });
        }
    };

    onCustomEmailButtonClick = () => {
        this.setState({
            showCustomEmailForm: true,
        })
    }

    renderCustomEmailButton = () => {
        return (
            <li className="d-none d-sm-block pr-3">
                <div className="pt-3">
                    <button onClick={this.onCustomEmailButtonClick} className="btn btn-info w-md waves-effect waves-light" type="button"><b>Custom email</b></button>
                </div>
            </li>
        )
    }

    onEditorChange = evt => {
        let value = {
            message_body: evt.editor.getData()
        };

        this.onChangeHandler(value);
    };

    renderCustomEmailForm = () => {
        return (
            <form className="apc-form">
                {this.state.loading && <Loading />}

                <div className="form-group row">
                    <SelectBox
                        placeholder="Select from dropdown"
                        label="Relevance"
                        onChange={this.onChangeHandler}
                        field="context"
                        className="col-sm-10"
                        data={[{ id: 'ARTICLE', name: 'Article' }, { id: 'NON_ARTICLE', name: 'Non Article' }]}
                        defaultValue={this.state.context}
                        isRequired={true}
                    />
                </div>

                {
                    this.state.context === 'ARTICLE' &&
                    <div className="form-group row">

                        <label className="col-form-label col-sm-2">
                            Article
                  <span className="text-danger"> * </span>
                        </label>

                        <div className="col-sm-10">

                            <AutoComplete
                                defaultValue={this.state.article_id}
                                notFoundContent="No found Data"
                                options={this.state.dataList || []}
                                style={{
                                    width: "100%",
                                }}
                                onSelect={(val, option) => this.onSelect(val, option)}
                                onSearch={this.onSearch}
                                onChange={this.onChangeHandler}
                                placeholder="Type your article name"
                            />
                        </div>
                    </div>
                }
          <div className="form-group row">
            <label htmlFor="request-to-email" className="col-form-label col-sm-2">
              Recipient
              <span className="text-danger"> * </span>

              <Tooltip title="Recipients of this email">
                <InfoCircleTwoTone style={{
                  fontSize: 18,
                  marginLeft: 10
                }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                />
              </Tooltip>
            </label>
            <div className="col-sm-10">
              <CreatableSelect
                components={components}
                inputValue={this.state.inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                onBlur={this.handleBlurEvent}
                placeholder="johndoe@test.com (press Tab or Space Bar for multiple recipients)"
                value={this.state.recipients || []}
              />
            </div>
          </div>


                <div className="form-group row">
                    <InputBox
                        label="Subject"
                        onChange={this.onChangeHandler}
                        className="col-sm-10"
                        field="subject"
                        placeholder="Enter your email subject"
                        defaultValue={this.state.subject}
                        isRequired={true}
                    />
                </div>

                <div className="form-group row">


                    <label htmlFor="message_body" className="col-form-label col-sm-2">
                        Message
                        <span className="text-danger"> * </span>

                    </label>
                    <div className="col-sm-10">
                        <Ckditor
                            onChange={this.onEditorChange}
                            data={this.state.message_body}
                            config={{
                                height: "200px",
                                toolbar: [
                                    ['Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'Link', 'Unlink', 'Format']
                                ]
                            }}
                        />

                    </div>

                </div>
            </form>
        )
    }

    render() {


        return (
            <HubContent title="Send custom email">
                <div className="card m-b-20">
                    <div className="card-body">

                        <div className="modal-body">
                            {this.renderCustomEmailForm()}

                        </div>

                        <div className="text-center"> <button type="button" className="btn btn-primary"
                            onClick={this.onSubmitHandler}
                        >Send</button></div>
                    </div>
                </div>

            </HubContent >
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    group: state.auth.user && state.auth.group,
    kind: state.auth.user && state.auth.user.kind,
    token: state.auth.token,
    articles: state.articles.article_full,
});

const mapDispatchToProps = (dispatch) => ({
    sendFreeTextEmail: (payload = {}) => dispatch(sendFreeTextEmail(payload)),
    searchArticleFull: (payload = {}) => dispatch(searchArticleFull(payload)),
});

export const SendCustomEmail = connect(mapStateToProps, mapDispatchToProps)(CustomEmail);
