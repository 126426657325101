import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Progress } from "antd";
import { Link } from "react-router-dom";
import { HubContent } from "../../../../../core/components";
import { Button, ModalSelectBox } from "../../../../../core/components/common";
import {
  AddtArticleTransferConfig,
  getJournalLists,
} from "../../../../actions";
import ArticleConfigLoader from "./articleConfigLoader";
import { Popover } from "antd";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Article-transfer-config</a>
    <br />
  </div>
);
class ArticleConfig extends Component {
  state = {
    JournalTypes: "",
    loading: true,
  };
  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "JournalTypes":
        this.setState({
          isShowJournalModal: true,
        });
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    this.articleMetaDataTransferConfig()
  }
  articleMetaDataTransferConfig = async () => {
    let journalist = await this.props.getJournalLists(
      this.props.auth && this.props.auth.user.organisation_id
    );
    let result = await this.props.AddtArticleTransferConfig({
      publication_ids: this.state.publication_ids
        ? this.state.publication_ids
        : [],
      ref_id: null,
      publisher_id: this.props.auth && this.props.auth.user.organisation_id,
    });
      this.setState(
        {
          journalist: journalist.results,
          article_count: result.data.total_count,
          ref_id: result.data.ref_id,
          transfer_status: result.data.transfer_status,
          executed: result.data.executed,
          article_full_completed: result.data.article_full_completed,
          article_manual_completed: result.data.article_manual_completed,
        },
        () => {
          this.interval = setInterval(async () => {
            let result = await this.props.AddtArticleTransferConfig({
              publication_ids: this.state.publication_ids
                ? this.state.publication_ids
                : [],
              publisher_id:
                this.props.auth && this.props.auth.user.organisation_id,
              ref_id: this.state.ref_id,
            });
            this.setState({
              article_count: result.data.total_count,
              ref_id: result.data.ref_id,
              transfer_status: result.data.transfer_status,
              executed: result.data.executed,
              article_full_completed: result.data.article_full_completed,
              article_manual_completed: result.data.article_manual_completed,
            });
            this.onChangeSwitch(result.data.executed);
          }, 3000);
        }
      );
    
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowJournalModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "JournalTypes":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Journal selected`
              : `${fieldValues.length} Journal selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Journal  selected"
              : "All Journals Selected";
        }
        break;
      default:
        break;
    }
    return labelText;
  };

  onChangeSwitch = async (checked) => {
    this.setState({
      executed: checked,
    });

    if (checked === true) {
      let articalTransferTrigger = await this.props.AddtArticleTransferConfig({
        executed: true,
        ref_id: this.state.ref_id,
        publisher_id: this.props.auth && this.props.auth.user.organisation_id,
        publication_ids: this.state.JournalTypes ? this.state.JournalTypes : [],
      });
      this.setState({
        executed: articalTransferTrigger.data.executed,
        article_full_completed:
          articalTransferTrigger.data.article_full_completed,
        article_manual_completed:
          articalTransferTrigger.data.article_manual_completed,
        article_count: articalTransferTrigger.data.total_count,
        total_completed: articalTransferTrigger.data.total_completed,
        totalSum:
          articalTransferTrigger.data.article_manual_completed +
          articalTransferTrigger.data.article_full_completed,
      });
    } else {
      let articalTransferTrigger = await this.props.AddtArticleTransferConfig({
        ref_id: this.state.ref_id,
        executed: false,
        publisher_id: this.props.auth && this.props.auth.user.organisation_id,
        publication_ids: this.state.JournalTypes ? this.state.JournalTypes : [],
      });
      this.setState({
        executed: articalTransferTrigger.data.executed,
        article_full_completed:
          articalTransferTrigger.data.article_full_completed,
        article_manual_completed:
          articalTransferTrigger.data.article_manual_completed,
        article_count: articalTransferTrigger.data.total_count,
        total_completed: articalTransferTrigger.data.total_completed,
      });
    }
  };

  onSaveSelectedItems = (fieldName, data) => {

    this.setState(
      {
        article_count: this.state.article_count,
        [fieldName]: data,
      },
      async () => {
        let result = await this.props.AddtArticleTransferConfig({
          publisher_id: this.props.auth && this.props.auth.user.organisation_id,
          publication_ids: this.state.JournalTypes,
        });
        this.setState({
          publication_ids: result.data.publication_ids,
          article_count: result.data.total_count,
          ref_id: result.data.ref_id,
          transfer_status: result.data.transfer_status,
          executed: result.data.executed,
          article_full_completed: result.data.article_full_completed,
          article_manual_completed: result.data.article_manual_completed,
        });
      }
    );
  };

  render() {
    let division = this.state.article_count / this.state.totalSum;
    let sum = division * 100;
    return (
      <HubContent>
        {this.state.transfer_status === "FETCH_IN_PROGRESS" ? (
          <ArticleConfigLoader />
        ) : null}
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20 article-config-item">

              <div className="vat-tax-button email d-flex justify-content-end" style={{ marginTop: 20 }}>


                <Popover placement="leftTop" content={content} trigger="click">
                  <Button
                    className="btn btn-secondary float-right "
                    type=""
                    name=""
                    text="Help"
                  >Help</Button>
                </Popover>
                <div className="danger float-right" style={{ marginRight: 10 }}
                >
                  <button
                    type="danger"
                    className="btn btn-danger float-right "
                    onClick={() => this.props.history.goBack()}
                    style={{ marginLeft: 10 }}
                  >
                    X

</button>                  </div>

              </div>

              <div className="row article-config-content">

                <div className="col-md-12 article-config-heading">
                  <h3>Articles Metadata Transfer Configuration</h3>
                  <h3 style={{ marginTop: 10 }}>
                    Submission system - OaMetrix
                  </h3>
                </div>
                <div className="col-md-12 article-config-fetch">
                  <p>
                    Articles currently in acceptance stage on your submission
                    system but not yet transferred in OaMetrix.
                  </p>
                </div>
                <div className="col-md-5 article-config-journal">
                  <div className="article-config-drop-down">
                    <p style={{ cursor: "pointer" }} onClick={() => this.onModalSelectBoxShow("JournalTypes")}
                    >{this.showSelectItemsLabel("JournalTypes")}</p>
                    <button
                      onClick={() => this.onModalSelectBoxShow("JournalTypes")}
                      className="btn btn-primary waves-effect waves-light float-right"
                      type="button"
                    >
                      <i className="fa fa-chevron-down"></i>
                    </button>
                  </div>

                  {this.state.isShowJournalModal && (
                    <ModalSelectBox
                      label="Journal"
                      field="JournalTypes"
                      className="col-sm-8"
                      data={this.state.journalist}
                      defaultValue={this.state.JournalTypes}
                      isRequired={false}
                      submitBtnTxt="Save"
                      onSubmit={this.onSaveSelectedItems}
                      onClose={() => this.onModalSelectBoxClose()}
                    />
                  )}
                </div>
                <div className="col-md-4 single-article-config">
                  <div className="article-config">
                    <h3>Total Accepted Articles:</h3>
                  </div>
                </div>
                <div className="col-md-3 single-article-btn">
                  <div className="article-config">
                    <Button
                      type="primary"
                      text={this.state.article_count ? this.state.article_count : 0}
                      name="primary"
                    />
                  </div>

                </div>
              </div>
              <div className="row article-config-switch">
                <div className="col-md-12">
                  <div className="article-config-fetch">
                    <p>
                      Select “All Journals” or the list of journals from the
                      above drop-down and click on the below switch to start
                      fetching the article metadata to OaMetrix.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 single-article-config switch">
                  <p style={{ color: this.state.executed ? "#1890ff" : "red" }}>
                    {this.state.executed ? "ON" : "OFF"}
                  </p>

                  <div className="article-config">
                    <Switch
                      checked={this.state.executed}
                      onChange={this.onChangeSwitch}
                    />
                  </div>
                </div>

                <div className="col-md-5  single-article-config">
                  <div className="article-config">
                    <Progress percent={sum} />
                  </div>
                </div>
              </div>
              <div className="row article-config-switch">
                <div className="col-md-4  single-article-config">
                  <div className="article-config">
                    <p> Article successfully transferred </p>

                    <p>
                      Click here to{" "}
                      <Link to="/article/publisher-articles"> view articles</Link>
                    </p>

                    <div
                      className="article-config-btn"
                      style={{ marginBottom: 10 }}
                    >
                      <Button
                        type="success"
                        text={
                          this.state.article_full_completed
                            ? this.state.article_full_completed
                            : 0
                        }
                        name="success"
                        size="lg"
                        onClick={() =>
                          this.props.history.push("/article/publisher-articles")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8  single-article-config">
                  <div className="article-config">
                    <p>
                      Article required manual attention click here to view the
                      articles
                    </p>
                    <p>
                      Click here to
                      <Link to="/article/failed-transfers"> view articles</Link>
                    </p>

                    <div
                      className="article-config-btn"
                      style={{ marginBottom: 10 }}
                    >
                      <Button
                        type="danger"
                        text={
                          this.state.article_manual_completed
                            ? this.state.article_manual_completed
                            : 0
                        }
                        name="danger"
                        size="lg"
                        onClick={() =>
                          this.props.history.push("/article/failed-transfers")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getJournalLists: (id) => dispatch(getJournalLists(id)),
  AddtArticleTransferConfig: (payload) =>
    dispatch(AddtArticleTransferConfig(payload)),
});

export const ArticleConfigForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleConfig);
