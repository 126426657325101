import React from "react";
import { ArticleFull } from "./ArticleFull";
import BaseComponent from "../../../../../core/components";

class Article extends BaseComponent {
  render() {
    return <ArticleFull title="Article Full" />;
  }
}

export const ArticleFullPage = Article;
