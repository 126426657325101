import React from "react";
import { HubContent } from "../../../../../core/components";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { emailLogDisplayHistory } from "../../../../actions";
import { Button } from "../../../../../core/components/common";
class ViewEmailModal extends React.Component {
    state = {
    };


    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.emailLogDisplayHistory(this.props.match.params.id)
        }
    }

    render() {



        return (
            <HubContent>
                <div className="col-sm-12 email-history-content">
                    <div className="vat-tax-button">
                        <Button
                            type="danger"
                            name="danger"
                            text="X"
                            onClick={() => this.props.history.goBack()}
                        />
                    </div>
                    <div className="row email-content">
                        <div className="col-md-12 email-subject ">
                            <p>Subject: {this.props.article_email_log_view && this.props.article_email_log_view.subject}</p>
                        </div>
                        <div className="col-md-6">
                            <div className="email-box">
                                <div className="email-icon">
                                    <i class="mdi mdi-account"></i>
                                </div>
                                <div className="email-description">
                                    <p>
                                        from: <span>{this.props.article_email_log_view && this.props.article_email_log_view.from_email}</span>
                                    </p>
                                    <p>
                                        to:
                    {this.props.article_email_log_view && this.props.article_email_log_view.recipients}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="email-date">
                                {" "}
                                <p>{new Date(this.props.article_email_log_view && this.props.article_email_log_view.date_created).toLocaleString()}</p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="email-content-message"
                        dangerouslySetInnerHTML={{
                            __html: this.props.article_email_log_view && this.props.article_email_log_view.html_message,
                        }}
                    ></div>
                </div>
            </HubContent>
        )


    }
}


const mapStateToProps = (state) => ({
    article_email_log_view: state.articles.article_email_log_view

});

const mapDispatchToProps = (dispatch) => ({
    emailLogDisplayHistory: (payload) => dispatch(emailLogDisplayHistory(payload)),
});

export const ViewArticleEmail = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewEmailModal));
