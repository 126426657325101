import React from "react";
import { Checkbox } from "antd";

class UserPermissionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedList:
        this.props.permissions && Array.isArray(this.props.permissions)
          ? this.props.permissions
          : [],
      indeterminate: true,
      checkAll: false,
      permissionValue:
        props.permissible_objects &&
        props.permissible_objects.map((item) => item.key),
      AllGroupKey:
        props.permissible_objects &&
        props.permissible_objects.map((item) => item.key),

      groupKey: props.permissible_objects,
    };
  }

  onChange = (checkedList) => {
    this.setState({
      checkedList,
    });
  };

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? this.state.AllGroupKey : [],
    });
  };

  onCheckItem = (value) => (e) => {
    this.setState({
      checkedList: this.state.checkedList.includes(value)
        ? this.state.checkedList.filter((x) => x !== value)
        : [...this.state.checkedList, value],
    });
  };
 

  render() {
    const { checkedList } = this.state;
    const authId =
      this.props.auth && this.props.auth.user && this.props.auth.user.id;

    return (
      <div className="user-permission-content">
        <div className="heading">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
          </div>
        </div>
        <div className="user-permission">
          <div className="permission-checkbox">
            <div className="uermission-key">
              {this.state.groupKey &&
                this.state.groupKey.map((item, idx) => (
                  <div className="userpermission-content" key={idx}>
                    <div className="content-item">
                      <div className="item">
                        <label>{item.label}</label>
                        <p>{item.hint}</p>
                      </div>
                      <div className="item-checkbox">
                        {this.props.disabled !== true ? (
                          this.props.auth.group === "PUBLISHER" &&
                          authId === this.props.userId ? (
                            <Checkbox
                              key={item.key}
                              onChange={this.onCheckItem(item.key)}
                              checked={
                                checkedList && checkedList.includes(item.key)
                              }
                              disabled
                            >
                              {item.key}
                            </Checkbox>
                          ) : (
                            <Checkbox
                              key={item.key}
                              onChange={this.onCheckItem(item.key)}
                              checked={
                                checkedList && checkedList.includes(item.key)
                              }
                            >
                              {item.key}
                            </Checkbox>
                          )
                        ) : (
                          <Checkbox
                            key={item.key}
                            onChange={this.onCheckItem(item.key)}
                            checked={checkedList.includes(item.key)}
                            disabled
                          >
                            {item.key}
                          </Checkbox>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserPermissionForm;
