import React, { Component } from 'react';
import { HubContent } from '../../../../../core/components';
import { Collapse, Button } from 'antd'
import styled from 'styled-components';



const { Panel } = Collapse;



class PublisherHelpComponent extends Component {
    state = {
        questions: [
            {
                id: 1,
                value: "Publisher Admin Login and Password Setup",
                buttons: [
                    { pdfbtn: "Publisher Admin Login", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/1-Admin+Log+in+and+Password.pdf" },
                ]
            },
            {
                id: 2,
                value: "Creating Another Admin and Assign Roles",
                buttons: [
                    { pdfbtn: "Create New Admin", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/2-Assign+Roles+to+Other+Admin.pdf" },
                ]
            },
            {
                id: 3,
                value: "Overview of Different Types of OA Deals on OaMetrix",
                buttons: [
                    { pdfbtn: "Different Types of OA Deal", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/3+-Overview+of+Different+Types+OA+Deals.pdf" },
                ]
            },
            {
                id: 4,
                value: "Setting up an OA-Discount Deal",
                buttons: [
                    { pdfbtn: "OA-Discount Deal", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/4+-+OA-Discount.pdf" },
                ]
            },
            {
                id: 5,
                value: "Setting up a Pre-Payment OA Deal",
                buttons: [
                    { pdfbtn: "Pre-Payment Deal", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/5-+OA-Pre-Payment+Deal.pdf" },
                ]
            },
            {
                id: 6,
                value: "Setting up a Read & Publish OA Deal",
                buttons: [
                    { pdfbtn: "Read & Publish Deal", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/6+-+OA-Read+%26+Publish+Deal+setup+for+institution.pdf" },
                ]
            },
            {
                id: 7,
                value: "Setting up a Subscribe-to-Open OA Deal",
                buttons: [
                    { pdfbtn: "Subscribe-to-Open Deal", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/7+-+OA-Subscribe2Open+Deal+setup+for+institution.pdf" },
                ]
            },
            {
                id: 8,
                value: "Generate different types of tokens",
                buttons: [
                    { pdfbtn: "Generate tokens", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/9+-+Generate+tokens.pdf" },
                ]
            },
            {
                id: 9,
                value: "Authors affiliation recognition on OaMetrix",
                buttons: [
                    { pdfbtn: "Author affiliation", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/10+-+Author-affiliation-recognition.pdf" },
                ]
            },
            
        ]
    };

    render() {

        return (

            <HubContent>

                <div className="row">

                    {this.state.questions.map(ques =>
                        <div key={ques.id} className="col-md-6">
                            <Collapse className="my-3" accordion>
                                <Panel className="mb-2" header={ques.value}>
                                    <div className="buttons__area">
                                        {ques.buttons.map(btn => <span key={btn.link}>
                                            <a href={btn.link} target="_blank" rel="noopener noreferrer">
                                                <Button type="link" className="mx-2">
                                                    <PdfIcon className="mr-2"><i className="fas fa-file-pdf"></i></PdfIcon>
                                                    {btn.videobtn}

                                                    {btn.pdfbtn}

                                                    {btn.pptbtn}
                                                </Button>
                                            </a>
                                        </span>)}
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    )}




                </div>

            </HubContent>
        );
    }
}

export const PublisherHelpMenu = PublisherHelpComponent;



const PdfIcon = styled.span`
    i.fa-file-pdf{
        color:#171a1d;
    }
`;