import React from "react";
import { withRouter } from "react-router-dom";
import BookSeriesForm from "./BookSeriesForm";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import { connect } from "react-redux";
import {
  ClearStatus,
  getOaDisciplineList,
  CreatePublication,
} from "../../../../../actions";
import { PopupBox, Button } from "../../../../../../core/components/common";

class BookSeries extends BaseComponent {
  state = {
    journal: {},
    isEmpty: false,
    success: false,
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.journal, value);
    });
  };

  onSubmitHandler = async (journal) => {

    let data = await this.props.CreatePublication(journal);

    if (data.status === 201) {
      this.setState({
        success: true,
      });
    }
  };
  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push(`/article/add-publications`, {
      index:
        (this.props.location &&
          this.props.location.state &&
          this.props.location.state.index) ||
        0,
    });
  };
  componentDidMount() {
    this.props.getOaDisciplineList();
  }

  OnChangeTextBox(value, name) {
    const state = {};
    state[name] = value;
    this.setState(state);
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            className="col-12 col-lg-6"
            title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    let successMessage = (
      <>
        {this.state.success && (
          <PopupBox
            msg="You have succesfully created a book series"
            onCancel={this.redirectLink}
          />
        )}
      </>
    );
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={this.redirectLink}
                />
              </div>
              <div className="card-body addbook">
                {successMessage}
                {errorHander}
                <BookSeriesForm
                  onSubmitHandler={this.onSubmitHandler}
                  OnChangeTextBox={this.OnChangeTextBox.bind(this)}
                  msg={this.state.msg}
                />
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.orgs.failed || false,
  token: state.auth.token || false,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
});
const mapDispatchToProps = (dispatch) => ({
  ClearStatus: () => dispatch(ClearStatus()),
  CreatePublication: (payload) => dispatch(CreatePublication(payload)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
});
export const AddBookSeriesForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookSeries)
);
