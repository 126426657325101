import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../core/components";
import DragAndDrop from "../DragAndDrop";
import validator from "validator";
import { Author } from "../book/Author";
import { CoAuthor } from "../book/CoAuthor";
import { Prices } from "../book/prices";

import moment from "moment";
import { getOaSubjectList } from "../../../actions";

import {
  Badge,
  InputBox,
  SelectBox,
  OaDatePicker,
  PopupBox,
  Button,
  InputNewBox
} from "../../../../core/components/common";
import { confirmAlert } from "react-confirm-alert"; // Import

const BtnText = "Add Book";

class Form extends BaseComponent {
  state = {
    article: {},
    SubmitBtnText: BtnText,
    errMsg: false,
    isCoAuthor: false,
    isAuthor: false,
    edit_modal: false,
    isPrices: false,
    co_authors: [],
    author: {},
    subjects: [],
    prices: [],

    disciplines: [],
    editing: false,
    loaded: false,
    contactTypes : [
      {
        id: 'customer_service',
        name: 'Customer service',
      },
      {
        id: 'editorial',
        name: 'Editorial',
      },
      {
        id: 'finance',
        name: 'Finance',
      },
      {
        id: 'production',
        name: 'Production',
      },
      {
        id: 'sales',
        name: 'Sales',
      }
    ],
    publication_contacts: [
      {
        contact_department: undefined,
        contact_name: undefined,
        contact_email: undefined,
      }
    ],
  };

  AddNewPrices = (e) => {
    e.preventDefault();
    this.setState({
      isPrices: true,
    });
  };

  decrementAddress = () => {
    if (this.state.addressCounter > 0) {
      this.setState((prevState) => {
        prevState.addressCounter = this.state.addressCounter - 1;
        prevState.addresses.pop();
        return prevState;
      });
    }
  };

  onSaveAuthorHander = (author_info) => {
    this.setState((prevState) => {
      prevState.author = author_info;
      prevState.isAuthor = false;
      return prevState;
    });
  };

  // onChangeHandler = (value) => {
  //   this.setState((prevState) => {
  //     return Object.assign(prevState.journal, value);
  //   });
  // };

  onSavePriceHandler = (price_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.prices[prevState.editing] = price_info;
      } else {
        prevState.prices.push(price_info);
      }
      prevState.editing = false;
      prevState.isPrices = false;
      return prevState;
    });
  };

  onEditPrices = (id) => {
    this.setState({
      editing: id.toString(),
      isPrices: true,
    });
  };

  onDeletePrice = (id) => {
    this.setState((prevState) => {
      prevState.prices.splice(id, 1);
      return prevState;
    });
  };

  getSelectedContentTypeId() {
    let selectedPrices = this.state.article;
    let selected = [];
    selectedPrices.forEach(function (element) {
      selected.push(element.content_type);
    });
    return selected;
  }

  async componentDidMount() {
    if (this.props.article && !this.state.loaded) {
      let article = {
        title: this.props.article.title,
        article_id: this.props.article.article_id,
        content_type: this.props.article.content_type,
        doi: this.props.article.doi,
        book_acronym: this.props.article.book_acronym,
        sub_sys_acronym: this.props.article.sub_sys_acronym,
        sub_system_acronym: this.props.article.sub_system_acronym,
        pisbn: this.props.article.pisbn,
        eisbn: this.props.article.eisbn,
        publisher_name: this.props.article.publisher_name,
        funder_name: this.props.article.funder_name,
        fund_acknowledgement: this.props.article.fund_acknowledgement,
        grant_number: this.props.article.grant_number,
        submission_date: this.props.article.submission_date,
        acceptance_date: this.props.article.acceptance_date,
        pub_date: this.props.article.pub_date,

        note: this.props.article.note,
      };

      let author = {};

      if (this.props.article.author.id) {
        author = {
          id: this.props.article.author.id,
          salutation: this.props.article.author.salutation,
          middle_name: this.props.article.author.middle_name,
          first_name: this.props.article.author.first_name,
          last_name: this.props.article.author.last_name,
          email: this.props.article.author.email,
          affiliation: this.props.article.author.affiliation,
          department: this.props.article.author.department.name,
          division: this.props.article.author.department.division,
          position: this.props.article.author.position,
        };
      }

      this.setState({
        article: article,
        co_authors: this.props.article.co_authors || [],
        author: author,
        is_author_affiliation_match: this.props.is_author_affiliation_match,
        is_eissn_match: this.props.article.is_eissn_match,
        is_pissn_match: this.props.article.is_pissn_match,
        is_publisher_name_match: this.props.article.is_publisher_name_match,
        loaded: true,
        SubmitBtnText: "Update",
      });
    }
  }


  onChangeHandler = (value) =>
    this.setState((prevState) => {
      Object.assign(prevState.article, value);
    });
  onChangeDepartmentHandler = (value) => {
    this.setState((prevState) => {
      if (Object.keys(value).includes("department")) {
        prevState.author = {
          ...prevState.author,
          department: {
            name: value.department,
          },
        };
      } else if (Object.keys(value).includes("division")) {
        prevState.author = {
          ...prevState.author,
          department: {
            ...prevState.author.department,
            division: {
              name: value.division,
            },
          },
        };
      } else {
        prevState.author = {
          ...prevState.author,
          ...value,
        };
      }
      return prevState;
    });
  };
  componentWillUnmount() {
    this.onMultiSelectChangeHandler();
  }
  onMultiSelectChangeHandler = (values) => {
    this.setState(
      {
        values,
        article: Object.assign(this.state.article, values),
      },
      async () => {
        if (values.hasOwnProperty("disciplines")) {
          if (values.disciplines.length > 0) {
            let payload = {
              discipline: values.disciplines.join(),
            };

            await this.props.getOaSubjectList(payload);

            this.props.OaSubjectList &&
              this.setState({
                OaSubjectList: this.props.OaSubjectList.results,
              });
          }
        }
      }
    );
  };

  onChangeAuthorHandler = (value) =>
    this.setState((prevState) => {
      Object.assign(prevState.author, value);
    });

  onChangeDate = (date) => this.setState({ submission_date: date });
  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
      if (
        !obj[key] ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  };
  onSubmitHandler = async (e) => {
    e.preventDefault();
    let data = {
      ...this.state.article,
      visible_to_publisher: true,
      publication_contacts: this.state.publication_contacts,

      source_pub_type_name: "BOOK",

      colour_charge_eur:
        this.state.article && this.state.article.colour_charge_eur
          ? this.state.article.colour_charge_eur
          : 0,
      colour_charge_gbp:
        this.state.article && this.state.article.colour_charge_gbp
          ? this.state.article.colour_charge_gbp
          : 0,
      colour_charge_usd:
        this.state.article && this.state.article.colour_charge_usd
          ? this.state.article.colour_charge_usd
          : 0,
      other_charge_eur:
        this.state.article && this.state.article.other_charge_eur
          ? this.state.article.other_charge_eur
          : 0,
      other_charge_gbp:
        this.state.article && this.state.article.other_charge_gbp
          ? this.state.article.other_charge_gbp
          : 0,
      other_charge_usd:
        this.state.article && this.state.article.other_charge_usd
          ? this.state.article.other_charge_usd
          : 0,
      page_charge_eur:
        this.state.article && this.state.article.page_charge_eur
          ? this.state.article.page_charge_eur
          : 0,
      page_charge_gbp:
        this.state.article && this.state.article.page_charge_gbp
          ? this.state.article.page_charge_gbp
          : 0,
      page_charge_usd:
        this.state.article && this.state.article.page_charge_usd
          ? this.state.article.page_charge_usd
          : 0,
      pub_fee_usd:
        this.state.article && this.state.article.pub_fee_usd
          ? this.state.article.pub_fee_usd
          : 0,
      pub_fee_gbp:
        this.state.article && this.state.article.pub_fee_gbp
          ? this.state.article.pub_fee_gbp
          : 0,
      pub_fee_eur:
        this.state.article && this.state.article.pub_fee_eur
          ? this.state.article.pub_fee_eur
          : 0,
      submission_fee_eur:
        this.state.article && this.state.article.submission_fee_eur
          ? this.state.article.submission_fee_eur
          : 0,
      submission_fee_gbp:
        this.state.article && this.state.article.submission_fee_gbp
          ? this.state.article.submission_fee_gbp
          : 0,
      submission_fee_usd:
        this.state.article && this.state.article.submission_fee_usd
          ? this.state.article.submission_fee_usd
          : 0,
    };

    this.state.author && Object.assign(data, { author: this.state.author });

    if (data.acceptance_date) {
      Object.assign(data, {
        acceptance_date: moment(this.state.article.acceptance_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        acceptance_date: null,
      });
    }
    if (data.pub_date) {
      Object.assign(data, {
        pub_date: moment(this.state.article.pub_date).format("YYYY-MM-DD"),
      });
    } else {
      Object.assign(data, {
        pub_date: null,
      });
    }
    if (data.submission_date) {
      Object.assign(data, {
        submission_date: moment(this.state.article.submission_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        submission_date: moment().format("YYYY-MM-DD"),
      });
    }

    Object.assign(data, { co_authors: this.state.co_authors });
    if (typeof data && data.author !== undefined) {
      this.removeEmpty(data && data.author)
    }
    data && data.co_authors && data.co_authors.map((item) => this.removeEmpty(item))

    if (
      !data.title ||
      !data.content_type ||
      !data.article_id ||
      !data.pisbn ||
      !data.eisbn
    ) {
      this.setState({
        errMsg: (
          <div>
            {!data.title && <p>Title cannot be empty!</p>}
            {!data.article_id && <p>Book id cannot be empty</p>}

            {!data.content_type && <p>Content name cannot be empty</p>}
            {!data.pisbn && <p>Pisbn cannot be empty</p>}
            {!data.eisbn && <p>Eisbn cannot be empty</p>}

            {data.doi && !validator.isURL(data.doi) && (
              <p>URL format is not correct</p>
            )}
            {data.author &&
              data.author.email &&
              !validator.isEmail(data.author.email) && (
                <p>Email format is not correct</p>
              )}
          </div>
        ),
      });
    } else {
      this.setState({
        errMsg: false,
      });

      this.props.onSubmitHandler(data);
    }
  };

  cancelLoader = async () => {
    this.setState({
      SubmitBtnText: BtnText,
      errMsg: false,
    });
  };

  AddNewAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isAuthor: true,
    });
  };

  AddNewCoAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isCoAuthor: true,
    });
  };

  onClose = () => {
    this.setState({
      isPrices: false,
      isCoAuthor: false,
      isAuthor: false,
      editing: false,
    });
  };

  onSaveCoAuthorHander = (author_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.co_authors[prevState.editing] = author_info;
        prevState.editing = false;
      } else {
        prevState.co_authors.push(author_info);
      }
      prevState.isCoAuthor = false;
      return prevState;
    });
  };

  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isCoAuthor: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.co_authors.splice(id, 1);
      return prevState;
    });
  };

  onFileDrop = (payload) => {
    confirmAlert({
      title: "Upload book",
      message: "Are you sure, you want to upload file?",
      buttons: [
        {
          label: "Upload",
          onClick: () => this.props.articleFileUploadHandler(payload),
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
  };
  onChangeSelectHandler = (value) => {
    this.setState({
      ...value,
    });
  };
  addNewPubContact = () => {
    let publicationContacts = this.state.publication_contacts

    publicationContacts.push({
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
    })

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  removePubContact = (index) => { 
    let publicationContacts = this.state.publication_contacts

    publicationContacts.splice(index, 1)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  renderPublicationContact = (publicationContacts) => { 
    let initialObj = {
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
    }

    if (!publicationContacts || !Array.isArray(publicationContacts)) {
      publicationContacts = [
        initialObj
      ]
    } else if (publicationContacts.length === 0) {
      publicationContacts.push(
        initialObj
      )
    }
    
    return publicationContacts.map((contact, index) => {
      return (
        <div className="form-group row" key={index}>
          <SelectBox
            labelInfo={true}
            labelInfoTitle="Select department"
            placeholder="Select department"
            label="Department"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_department"
            className="col-sm-4"
            labelClass="col-sm-2"
            data={this.state.contactTypes || []}
            defaultValue={contact.contact_department}
          />
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter contact name"
            placeholder="Enter contact name"
            label="Name"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_name"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_name}
          />
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter contact email"
            placeholder="Enter contact email"
            label="Email"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_email"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_email}
          />
          <div 
            className="col-sm-1"
          >
              { index > 0 && <button 
                  type="button"
                  className="btn btn-danger mb-2"
                  onClick={() => this.removePubContact(index)}
                >
                  <i className="mdi mdi-close"></i>
                </button> }
            </div>
        </div>
      )
    })
  }

  onPubContactChangeHandler = (value, index) => { 
    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }
  render() {
    let { article, is_author_affiliation_match, is_eissn_match } = this.state;

    const content_type = this.props.content_type && this.props.content_type;
    return (
      <div className="tab-content books">
        {/* Error Messages */}

        {this.state.errMsg && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.errMsg}
            onCancel={this.cancelLoader}
          />
        )}
        {/* End Error Messages */}

        <div className="tab-pane active p-3" id="home-1" role="tabpanel">
          <div className="form-group row">
            <InputBox
                          placeholder="Enter Book title"
                          labelInfo={true}
                          labelInfoTitle="Enter Book title"
              onChange={this.onChangeHandler}
              field="title"
              labelClass="col-sm-3 col-lg-2"
              className="col-sm-9 col-lg-10"
              label="Book title"
              isRequired={true}
              defaultValue={article.title}
            />
          </div>
          <div className="form-group row">
            <InputBox
              placeholder="Enter Book ID"
              labelInfo={true}
              labelInfoTitle="Enter Book ID"
              onChange={this.onChangeHandler}
              field="article_id"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label="Book ID"
              defaultValue={article.article_id}
            />
            <InputBox
              onChange={this.onChangeHandler}
              field="pisbn"
              placeholder="Enter pISBN"
              labelInfo={true}
              labelInfoTitle="Enter pISBN"
              label="pISBN"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              defaultValue={article.pisbn}
            />
          </div>
          <div className="form-group row">
            <InputBox
              onChange={this.onChangeHandler}
              field="eisbn"
              label="eISBN"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              defaultValue={article.eisbn}
              style={is_eissn_match === false ? styles.error : {}}
              placeholder="Enter eISBN"
              labelInfo={true}
              labelInfoTitle="Enter eISBN"
            />
            <SelectBox
                          placeholder="Select article type"
                          labelInfo={true}
                          labelInfoTitle="Select article type"
              onChange={this.onChangeHandler}
              field="content_type"
              data={content_type}
              label="Article type"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              isRequired={true}
              defaultValue={
                article.content_type
                  ? article.content_type
                  : "Select article type"
              }
            />
          </div>
          <div className="form-group row">
            <SelectBox
                   placeholder="Select Discipline"
                   labelInfo={true}
                   labelInfoTitle="Select Discipline"
              label="Discipline"
              onChange={this.onMultiSelectChangeHandler}
              field="disciplines"
              data={this.props.OaDisciplineList}
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              multiple={true}
              defaultValue={article.disciplines}
            />
            <SelectBox
                  placeholder="Select Subjects"
                  labelInfo={true}
                  labelInfoTitle="Select Subjects"
              onChange={this.onMultiSelectChangeHandler}
              field="subjects"
              label="Subjects"
              data={this.state.OaSubjectList}
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              defaultValue={article.subjects}
              multiple={true}
            />
          </div>

          <div className="row">
            <div className="col-md-12">
              <h3 className="profile-heading">Corresponding author </h3>
            </div>
          </div>
          <div className="form-group row">
            <Author
              fieldLabel="Author"
              onChangeDepartmentHandler={this.onChangeDepartmentHandler}
              onChangeHandler={this.onChangeDepartmentHandler}
              value={this.state.author}
              is_author_affiliation_match={is_author_affiliation_match}
            />
          </div>

          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="float-left" style={{marginRight:10}}>
                <h3 className="profile-heading">Co authors information</h3>
              </div>
              <div className="float-left">
                <button
                  className="btn btn-light btn-o-light mb-2"
                  onClick={this.AddNewCoAuthor}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="mdi mdi-plus"></i> Add Co-authors
                </button>
              </div>
            </div>
          </div>

          {/* Co Author Form Modal */}
          {this.state.isCoAuthor && (
            <CoAuthor
              isShow={this.state.isCoAuthor} // if true modal will show up else it will be hidden
              onClose={this.onClose} // Handle Close
              onSaveAuthor={this.onSaveCoAuthorHander}
              fieldLabel="Co author"
              value={this.state.co_authors[this.state.editing]}
            />
          )}
          <div className="form-group row">
            {this.state.co_authors.length > 0 &&
              this.state.co_authors.map((co_author, id) => {
                return (
                  <div key={id}>
                  <Badge
                    label={
                      !co_author.first_name && !co_author.last_name
                        ? "No Name"
                        : (co_author.first_name || "") +
                        " " +
                        (co_author.last_name || "")
                    }
                    onEdit={() => this.onEditCoAuthor(id)}
                    onDelete={() => this.onDeleteCoAuthor(id)}
                  />
                    </div>
                );
              })}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h3 className="profile-heading">Book Publisher Information </h3>
            </div>
          </div>
          <div className="form-group row">
            <SelectBox
                              placeholder="Select Publisher Name"
                              labelInfo={true}
                              labelInfoTitle="Select Publisher Name"
              onChange={this.onChangeHandler}
              field="publisher"
              label="Publisher Name"
              data={this.props.publisherList}
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              isRequired={true}
              defaultValue={
                 article.publisher 
              }
            />

            <InputBox
              placeholder="Enter Book acronym"
              labelInfo={true}
              labelInfoTitle="Enter Book acronym"
              onChange={this.onChangeHandler}
              field="book_acronym"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label="Book acronym"
              defaultValue={article.book_acronym}
            />
          </div>
          <div className="form-group row">
            <InputBox
                            placeholder="Enter Site"
                            labelInfo={true}
                            labelInfoTitle="Enter Site"
              onChange={this.onChangeHandler}
              field="sub_sys_acronym"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label="Site"
              defaultValue={article.sub_sys_acronym}
            />
            {article.submission_date && (
              <OaDatePicker
              placeholder="Select Submission Date"
              labelInfo={true}
              labelInfoTitle="Select Submission Date"
                onChange={this.onChangeHandler}
                field="submission_date"
                
                label="Submission date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                defaultValue={
                  article.submission_date
                    ? moment(article.submission_date)
                    : null
                }
              />
            )}
            {!article.submission_date && (
              <OaDatePicker
              placeholder="Select Submission Date"
              labelInfo={true}
              labelInfoTitle="Select Submission Date"
                onChange={this.onChangeHandler}
                field="submission_date"
                label="Submission date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                defaultValue={
                  article.submission_date
                    ? moment(article.submission_date)
                    : null
                }
              />
            )}
          </div>
          <div className="form-group row">
            {!article.acceptance_date && (
              <OaDatePicker
                onChange={this.onChangeHandler}
                field="acceptance_date"
                label="Acceptance date"
                placeholder="Select Acceptance Date"
                labelInfo={true}
                labelInfoTitle="Select Acceptance Date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                defaultValue={
                  article.acceptance_date
                    ? moment(article.acceptance_date)
                    : null
                }
              />
            )}
            {article.acceptance_date && (
              <OaDatePicker
                onChange={this.onChangeHandler}
                field="acceptance_date"
                placeholder="Select Acceptance Date"
                labelInfo={true}
                labelInfoTitle="Select Acceptance Date"
                label="Acceptance date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                defaultValue={
                  article.acceptance_date
                    ? moment(article.acceptance_date)
                    : null
                }
              />
            )}

            {article.pub_date && (
              <OaDatePicker
              placeholder="Select Publish Date"
                labelInfo={true}
                
              labelInfoTitle="Select Publish Date"
                onChange={this.onChangeHandler}
                field="pub_date"
                label="Publish date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                defaultValue={
                  article.pub_date ? moment(article.pub_date) : null
                }
              />
            )}

            {!article.pub_date && (
              <OaDatePicker
              placeholder="Select Publish Date"
              labelInfo={true}
              labelInfoTitle="Select Publish Date"
                onChange={this.onChangeHandler}
                field="pub_date"
                label="Publish date"
                className="col-sm-3 col-lg-4"
                labelClass="col-sm-3 col-lg-2"
                defaultValue={
                  article.pub_date ? moment(article.pub_date) : null
                }
              />
            )}
          </div>

          <div className="row">
            <Prices
              prices={this.state.article}
              onChangeHandler={this.onChangeHandler}
            />
          </div>

          <div className="row">
            <div className="col-sm-12">
              <h3 className="profile-heading">Funders </h3>
            </div>
          </div>

          <div className="form-group row">
            <InputBox
              onChange={this.onChangeHandler}
              field="funder_name"
              label="Funder"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              defaultValue={article.funder_name}
              placeholder="Enter Funder"
              labelInfo={true}
              labelInfoTitle="Enter Funder"
            />

            <InputBox
              onChange={this.onChangeHandler}
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label="Grant Note"
              field="fund_acknowledgement"
              placeholder="Enter Grant Note"
              labelInfo={true}
              labelInfoTitle="Enter Grant Note"
              defaultValue={article.fund_acknowledgement}
            />
          </div>

          <div className="form-group row ">
            <InputBox
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="grant_number"
              label="Grant number"
              placeholder="Enter Grant number"
              labelInfo={true}
              labelInfoTitle="Enter Grant number"
              defaultValue={article.grant_number}
            />
            <InputBox
              label="Book DOI"
              onChange={this.onChangeHandler}
              field="doi"
              placeholder="Enter Book/Chapter doi"
              labelInfo={true}
              labelInfoTitle="Enter Book/Chapter doi"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              InputType="url"
              defaultValue={article.doi}
            />
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h3 className="profile-heading">Editor </h3>
            </div>
          </div>
          <div className="form-group row">
            <InputBox
              onChange={this.onChangeHandler}
              field="editor_manager"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label="Editor Manager"
              placeholder="Enter Manager"
              labelInfo={true}
              labelInfoTitle="Enter Manager"
              defaultValue={article.editor_manager}
            />
            <InputBox
              onChange={this.onChangeHandler}
              field="editor_support"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label="Editor Support"
              placeholder="Enter Editor Support"
              labelInfo={true}
              labelInfoTitle="Enter Editor Support"
              defaultValue={article.editor_support}
            />
          </div>
          <div className="form-group row">
            <InputBox
                        labelInfo={true}
                        labelInfoTitle="Enter Editor Co-support"
              label="Editor Co-support"
              onChange={this.onChangeHandler}
              field="editor_co_support"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Enter Editor Co-support"
              defaultValue={article.editor_co_support}
            />
          </div>
          <div className="form-group row mt-2">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Publication contacts </label>
              </div>
              
            </div>
            <s style={{ borderBottom: "1px solid #375ba8", width: "100%" }}></s>

          </div>

          { this.renderPublicationContact(this.state.publication_contacts) }

          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Add Contacts </label>
              </div>
              
              <div style={{marginLeft:15,float:"left"}}>
                <button 
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={this.addNewPubContact}
                >
                  <i className="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              name="primary"
              text="Submit"
              type="primary"
              onClick={this.onSubmitHandler}
            />
          </div>
        </div>

        <div className="tab-pane p-3" id="profile-1" role="tabpanel">
          <div className="m-b-30">
            <DragAndDrop onDropHandler={this.onFileDrop} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  content_type: state.journals.content_type ,
  publication_type: state.journals.publication_type ,
  article_status: state.articles.article_status ,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  oa_subjectsList: state.OaSubject.oa_subjects ,
});

const mapDispatchToProps = (dispatch) => ({
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
});

const styles = {
  error: { border: "1px solid red" },
};

export const BookForm = connect(mapStateToProps, mapDispatchToProps)(Form);
