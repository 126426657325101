import React from "react";
import ReactDOM from "react-dom";
import App from "./__base__/App";
import * as serviceWorker from "./__base__/serviceWorker";
ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById("root")
);

serviceWorker.register();
