import {
  ADD_UPDATE_ARTICLE,
  GET_EMAIL_HISTORY_ACTION,
  GET_EMAIL_LOG_ACTION,
  MUTATE_ARTICLE_PRICE,
  ARTICLE_APPROVE_SUMMARY,
  BOOK_SEARCH,
  BOOK_DOWNLOAD,
  CORRECTION_DOWNLOAD,
  ARTICLE_DOWNLOAD,
  ARTICLE_FILE_UPLOAD,
  TRACK_ARTICLE_FILE_UPLOAD_STATUS,
  CLEAR_FAILED_STATUS,
  ALL_ARTICLES,
  GET_ARTICLE_MANUAL,
  GET_SINGLE_ARTICLE,
  GET_LICENCE_LIST,
  GET_LICENCE_NON_OA,
  CREATE_AUTHOR_APC_FUND,
  FAILED_AUTHOR_APC_FUND,
  CORRECTION_REQUEST,
  CORRECTION_REQUEST_SINGLE,
  BOOK_FILE_UPLOAD,
  TRACK_BOOK_FILE_UPLOAD_STATUS,
  SUCCESS_BOOK_LIST,
  FAILED_BOOK_LIST,
  REQUEST_BOOK_LIST,
  TRACK_ARTICLE_FILE_UPLOAD_STATUS_DOWNLOAD,
  GET_SINGLE_BOOK,
  GET_ARTICLE_FULL_ALL,
  CREATE_AUTHOR_APC_FUND_NO_OA,
  GET_ALL_LICENCE,
  ARTICLE_FETCH_REQUEST,
  ARTICLE_BTN_ACTION,
  ARTICLE_FETCH_SUCCESS,
  ARTICLE_FETCH_ERROR,
  EMAIL_HISTORY_ERRROR,
  EMAIL_HISTORY_REQUEST,
  EMAIL_HISTORY_SUCCESS,
  ARTICLE_VIEW_LOG,
  REQUEST_SINGLE_ARTICLE_FULL,
  ARTICLE_REQUEST_ACTION_BUTTON,
  ARTICLE_MESSAGE_EMAIL_REQUEST,
  REQUEST_ARTICLE_MANUAL_ACTION,
  REQUEST_ARTICLE_ERROR_ACTION,
  REQUEST_ARTICLE_MANUAL_UPDATE_ACTION,
  REQUEST_UPDATE_ARTICLE,
  REQUEST_CANCEL_ARTICLE,
  SUCCESS_GET_APC,
  ARTICLE_AUTHOR_NOTIFICATION_SUMMARY
} from "../../config";

const ArticleReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case REQUEST_ARTICLE_ERROR_ACTION:
      return (state = {
        ...state,
        isRequestLoading: false,
      });
    case ARTICLE_AUTHOR_NOTIFICATION_SUMMARY: 
    return (state = {
      ...state,
      articleAuthorNotification:  action.payload,
    });
    case SUCCESS_GET_APC:
      return (state = {
        ...state,
        apcOptionRequestList: action.payload,
      });
    case REQUEST_ARTICLE_MANUAL_ACTION:
      return (state = {
        ...state,
        isRequestLoading: true,
      });
    case ARTICLE_MESSAGE_EMAIL_REQUEST:
      return (state = {
        ...state,
        loading: true,
      });
    case ARTICLE_VIEW_LOG:
      return (state = {
        ...state,
        article_email_log_view: action.payload,
        loading: false,
      });

    case EMAIL_HISTORY_ERRROR:
      return (state = {
        email_history_log: action.payload,

        loading: false,
      });
    case EMAIL_HISTORY_REQUEST:
      return (state = {
        loading: true,
      });
    case EMAIL_HISTORY_SUCCESS:
      return (state = {
        loading: false,
        email_history_log: action.payload,
      });
    case ARTICLE_FETCH_REQUEST:
      return (state = {
        loading: true,
        isArticleloading: true,
      });
    case ARTICLE_FETCH_SUCCESS:
      return (state = {
        ...state,
        article_full: action.payload && action.payload.results,
        count: action.payload && action.payload.count,
        isArticleloading: false,
        loading: false,
      });
    case ARTICLE_FETCH_ERROR:
      return (state = {
        article_full: action.payload,
        loading: false,
      });
    case ADD_UPDATE_ARTICLE:
      return (state = {
        ...state,
        article_status: action.payload,
        isUpdateLoading: false,
      });
    case REQUEST_ARTICLE_MANUAL_UPDATE_ACTION:
      return (state = {
        ...state,
        isUpdateLoading: true,
      });
    case GET_ALL_LICENCE:
      return (state = {
        ...state,
        article_licence: action.payload,
      });
    case MUTATE_ARTICLE_PRICE:
      return (state = {
        ...state,
        article_price: action.payload,
      });
    case ARTICLE_APPROVE_SUMMARY:
      return (state = {
        ...state,
        article_approve_summary: action.payload,
      });
    case ALL_ARTICLES:
      return (state = {
        ...state,
        article_list: action.payload,
      });
    case SUCCESS_BOOK_LIST:
      return (state = {
        ...state,
        isLoading: false,
        book_list: action.payload,
      });
    case REQUEST_BOOK_LIST:
      return (state = {
        ...state,
        isLoading: true,
      });
    case FAILED_BOOK_LIST:
      return (state = {
        ...state,
        isLoading: false,
        book_list: action.payload,
      });
    case ARTICLE_FILE_UPLOAD:
      return (state = {
        ...state,
        article_file_upload: action.payload,
      });
    case BOOK_FILE_UPLOAD:
      return (state = {
        ...state,
        article_file_upload: action.payload,
      });
    case TRACK_ARTICLE_FILE_UPLOAD_STATUS:
      return (state = {
        ...state,
        track_upload_status: action.payload,
      });
    case TRACK_ARTICLE_FILE_UPLOAD_STATUS_DOWNLOAD:
      return (state = {
        ...state,
        track_upload_status_download: action.payload,
      });
    case TRACK_BOOK_FILE_UPLOAD_STATUS:
      return (state = {
        ...state,
        track_upload_status: action.payload,
      });
    case GET_ARTICLE_MANUAL:
      return (state = {
        ...state,
        article_manual: action.payload.results,
        count: action.payload.count,
        isRequestLoading: false,
      });

    case GET_ARTICLE_FULL_ALL:
      return (state = {
        ...state,
        article_full_all: action.payload.results,
        count: action.payload.count,
      });
    case REQUEST_UPDATE_ARTICLE:
      return (state = {
        ...state,
        isUpdateLoading: true,
      });
    case REQUEST_CANCEL_ARTICLE:
      return (state = {
        ...state,
        isUpdateLoading: false,
      });

    case GET_SINGLE_ARTICLE:
      return (state = {
        ...state,
        isUpdateLoading: false,
        article_single: action.payload,
        isLoading: false,
      });
    case REQUEST_SINGLE_ARTICLE_FULL:
      return (state = {
        ...state,
        isLoading: true,
      });
    case GET_LICENCE_LIST:
      return (state = {
        ...state,
        licence_list: action.payload,
      });
    case GET_LICENCE_NON_OA:
      return (state = {
        ...state,
        licence_non_oa: action.payload,
      });
    case CREATE_AUTHOR_APC_FUND:
      return (state = {
        ...state,
        author_apc_fund_success: action.payload,
      });
    case CREATE_AUTHOR_APC_FUND_NO_OA:
      return (state = {
        ...state,
        author_apc_fund_no_oa_success: action.payload,
      });
    case FAILED_AUTHOR_APC_FUND:
      return (state = {
        ...state,
        author_apc_fund_failed: action.payload,
      });
    case CORRECTION_REQUEST:
      return (state = {
        ...state,
        correction_list: action.payload,
      });
    case CORRECTION_REQUEST_SINGLE:
      return (state = {
        ...state,
        correction_single: action.payload,
      });
    case ARTICLE_DOWNLOAD:
      return (state = {
        ...state,
        download_data: action.payload,
      });
    case CORRECTION_DOWNLOAD:
      return (state = {
        ...state,
        correction_data: action.payload,
      });
    case BOOK_DOWNLOAD:
      return (state = {
        ...state,
        book_list_data: action.payload,
      });
    case GET_SINGLE_BOOK:
      return (state = {
        ...state,
        single_book: action.payload,
      });
    case BOOK_SEARCH: {
      return (state = {
        ...state,
        book_search_list: action.payload,
      });
    }
    case GET_EMAIL_HISTORY_ACTION: {
      return (state = {
        ...state,
        email_history_list: action.payload,
      });
    }
    case GET_EMAIL_LOG_ACTION: {
      return (state = {
        ...state,
        email_logs: action.payload,
      });
    }
    case ARTICLE_REQUEST_ACTION_BUTTON: {
      return (state = {
        ...state,
        loading: true,
      });
    }
    case ARTICLE_BTN_ACTION: {
      return (state = {
        ...state,
        loading: false,
        article_single_action: action.payload,
      });
    }

    case CLEAR_FAILED_STATUS:
      return (state = { article_status: null });

    default:
      return state;
  }
};
export default ArticleReducer;
