import React from "react";
import AppSettings from "../../../__base__/AppSettings";
import { connect } from "react-redux";
import { HubDashboard } from "./hub";
// import { UserDashboard } from './user';
// import { PubDashboard } from "./publisher";
import { ConsDashboard } from "./consortia";
//import { UnivDashboard } from "./university";
import { FunderDashboard } from "./funder";
import { history } from "../../route";
import { root } from "../../data/user";
import { HUB, PUBLISHER, INSTITUTION, CONSORTIUM, FUNDER } from "../../data";

class DB extends AppSettings {
  render() {
    let { group } = this.props;

    if (group === HUB) {
      return <HubDashboard />;
    } else if (group === PUBLISHER) {
      history.push("/article/publisher-articles");
      return ""
    } else if (group === INSTITUTION) {
      history.push("/articles")
      return ""
      //return <UnivDashboard />;
    } else if (group === CONSORTIUM) {
      return <ConsDashboard />;
    } else if (group === FUNDER) {
      return <FunderDashboard />;
    } else {
      history.push(root.articles);
      return "";
      // return <UserDashboard />
    }
  }
}

const mapStateToProps = (state) => ({
  role_id: state.auth.user && state.auth.user.role_id,
  group: state.auth.user && state.auth.group,
});

export const Dashboard = connect(mapStateToProps)(DB);
