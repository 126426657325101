import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "../../../../../core/components/common";
class DuePaymentUI extends React.Component {
  render() {
    const {
      DueReminder,
      onChangeRemdiner,
      UpdateInvoiceNotification,
      due_day,
    } = this.props;

    return DueReminder ? (
      <div className="addmultfield" style={modal.overlay}>
        <div className={`col-sm-5 bg-white alert`} style={modal.boxStyle}>
          <div className="vat-tax-button email">
            <Button
              type="danger"
              name="danger"
              text="X"
              onClick={() => this.props.history.push("/settings/invoice-configuration")}
            />
          </div>
          <div className="invoice-reminder" style={{ height: 250 }}>
            <div className="reminder" style={{ marginTop: 50 }}>
              <h3>Please enter your invoice due days </h3>
              <p>
                Invoice will be valid accoriding to the days you are going to
                enter below
              </p>
              <input
                 min="0"
                id="number"
                name="due_day"
                type="number"
                value={due_day}
                onChange={onChangeRemdiner}
              />
            </div>

            <div className="row reminder-submit" style={{ marginTop: 30 }}>
              <div className="col-md-12 invoice-notification">
                <Button
                  onClick={UpdateInvoiceNotification}
                  type="button"
                  name="primary"
                  text="Submit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
        ""
      );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(25px, 100px)",
    height: boxHeight,
  },
};


export default withRouter(DuePaymentUI)
