import React from "react";
import { connect } from "react-redux";
import { BookForm, EditBookForm } from "../../../partials";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getContentTypeList,
  bookFileUpload,
  trackArticleFileUploadStatus,
  getSinglePublicatonType,
  getPublicatonTypeList,
  CreateBook,
  EditArticle,
  trackArticleFileUploadStatusDownload,
  getOaDisciplineList,
  getOaSubjectList,
  getSingleBookForm,
  UpdateBookForm,
} from "../../../../actions";
import {
  Loading,
  PopupBox,
  Button,
} from "../../../../../core/components/common";

class Book extends BaseComponent {
  state = {
    article: false,
    isLoading: false,
    status: null,
    fileUploadStatus: null,
    fileUploadStatusMsg: null,
    pageSubtitle: "Enter book details",
    publisherList: [],
    success: false,
  };

  async componentDidMount() {
    this.checkUrl();
    const data = await this.props.getSinglePublicatonType();

    await this.props.getContentType();
    await this.props.getPublicatonType();
    await this.props.getOaDisciplineList();

    this.setState({
      publisherList: data.results,
    });
    const OaDisciplineList = [];

    this.props.oa_disciplinesList.results &&
      this.props.oa_disciplinesList.results.map((item) =>
        OaDisciplineList.push({
          id: item.id,
          name: item.name,
        })
      );
    this.setState({
      OaDisciplineList: OaDisciplineList,
    });
  }

  checkUrl = async () => {
    if (this.props.match.params.id) {
      const article = await this.props.getSingleBookForm(
        this.props.match.params.id
      );
      this.setState({
        pageTitle: "Edit Article Form",
        pageSubtitle: "Edit Article Details",
        article,
      });
    }
  };

  onSubmitHandler = async (payload) => {

    if (this.props.match.params.id) {
      let data = await this.props.UpdateBookForm(
        this.props.match.params.id,
        payload
      );
      if (data.status === 200) {
        this.setState({
          success: true,
        });
      }
    } else {
      let data = await this.props.CreateBook(payload);
      if (data.status === 201) {
        this.setState({
          success: true,
        });
      }
    }

  };

  onArticleFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });

    await this.props.bookFileUpload(payload);
    if (this.props.article_file_upload.status) {
      let payload = {
        upload_status: this.props.article_file_upload.data.upload_status.slice(
          1
        ),
      };
      this.setState({
        upload_payload: payload,
      });
      await this.props.trackArticleFileUploadStatus(payload);
    }
    if (this.props.track_upload_status) {
      let upload_id = this.props.track_upload_status.id;
      let upload_download_status = `file-upload-error-response/${upload_id}/`;
      let payloadDownload = {
        upload_status: upload_download_status,
      };
      await this.props.trackArticleFileUploadStatusDownload(payloadDownload);
    }

    this.setState({
      fileUploadStatus: this.props.article_file_upload.status,
      fileUploadStatusMsg: this.props.article_file_upload.data.message,
      isLoading: false,
    });
  };

  cancelLoader = async () => {
    this.setState({
      status: null,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };
  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push(`/article/add-publications`, {
      index: this.props.location.state.index || 0,
    });
  };

  render() {
    let { article } = this.state;

    // let errorHander = <>
    //     {this.props.status === 400 && <PopupBox
    //         title="Bad Request"
    //         msg="Please Check Your Form and Try again"
    //         onCancel={this.cancelLoader}
    //     />}
    //     {this.state.fileUploadStatus === false && <PopupBox
    //         title="Bad Request"
    //         msg={this.state.fileUploadStatusMsg && this.state.fileUploadStatusMsg}
    //         onCancel={this.cancelLoader}
    //     />}
    //     {this.state.fileUploadStatus === true && <PopupBox
    //         title="Success"
    //         msg={this.state.fileUploadStatusMsg}
    //         upload={true}
    //         upload_payload={this.state.upload_payload}
    //         onCancel={this.cancelLoader}
    //         className="col-sm-8"
    //     />}
    //     {this.props.status === 500 && <PopupBox
    //         title="Network Error Found"
    //         msg="Please Check Internet Connection and Try Again"
    //     />}

    //     {this.props.msg && <PopupBox
    //         Title={this.state.msg}
    //         msg={this.state.msg}
    //     />}
    // </>
    let successMessage = (
      <>
        {this.state.success && (
          <PopupBox
            NoText="Close"
            msg="You have succesfully created a book "
            onCancel={this.redirectLink}
          />
        )}
      </>
    );
    return (
      <HubContent>
        {/* Error Handler */}
        {/* {errorHander} */}
        {/* Error Handler */}

        {/* Loader */}
        {this.state.isLoading && <Loading />}
        {/* Loader */}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={this.redirectLink}
                />
              </div>
              <div className="card-body addbook">
                {successMessage}
               

                {this.props.match.params.id ? (
                  <EditBookForm
                    onSubmitHandler={this.onSubmitHandler}
                    articleFileUploadHandler={this.onArticleFileUpload}
                    article={article}
                    OaSubjectList={this.props.oa_subjectsList}
                    OaDisciplineList={this.state.OaDisciplineList}
                    publisherList={this.state.publisherList}
                  />
                ) : null}
                {!this.props.match.params.id && (
                  <BookForm
                    onSubmitHandler={this.onSubmitHandler}
                    OaSubjectList={this.props.oa_subjectsList}
                    publisherList={this.state.publisherList}
                    OaDisciplineList={this.state.OaDisciplineList}
                    articleFileUploadHandler={this.onArticleFileUpload}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  article_file_upload: state.articles.article_file_upload ,
  track_upload_status: state.articles.track_upload_status ,
  track_upload_status_download:
    state.articles.track_upload_status_download ,
  oa_disciplinesList: state.OaDiscipline.oa_disciplines ,
  oa_subjectsList: state.OaSubject.oa_subjects ,
});

const mapDispatchToProps = (dispatch) => ({
  getContentType: () => dispatch(getContentTypeList()),
  getSinglePublicatonType: () => dispatch(getSinglePublicatonType()),
  getPublicatonType: () => dispatch(getPublicatonTypeList()),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getOaDisciplineList: () => dispatch(getOaDisciplineList()),
  CreateBook: (payload) => dispatch(CreateBook(payload)),
  getSingleBookForm: (payload) => dispatch(getSingleBookForm(payload)),
  EditArticle: (payload) => dispatch(EditArticle(payload)),
  UpdateBookForm: (id, payload) => dispatch(UpdateBookForm(id, payload)),
  bookFileUpload: (payload) => dispatch(bookFileUpload(payload)),
  trackArticleFileUploadStatus: (payload) =>
    dispatch(trackArticleFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) =>
    dispatch(trackArticleFileUploadStatusDownload(payload)),
});

export const AddBook = connect(mapStateToProps, mapDispatchToProps)(Book);
