import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  HubContent,
  DataTable,
} from "../../../../../core/components";
import { Loading } from "../../../../../core/components/common";
import { ViewRow } from "../../../common";
import { ClearStatus, UpdateUser, singleJournal } from "../../../../actions";
import { PopupBoxTable } from "../../../../../core/components/common";
import { api } from "../../../../../core/api";

class Add extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    user: {},
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
    journal: this.props.journal,
  };

  getSingle = (id) => {
    this.setState({
      loader: true,
    });
    api
      .get("publication/" + id + "/")
      .then(
        (res) => {
          this.setState(
            {
              ...res,
            },
            function () {
              this.setState({
                loader: false,
              });
            }
          );
        },
        (error) => {
          this.setState({
            loader: false,
          });
        }
      )
      .catch();
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.getSingle(id);
    }
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  active = (index) => {
    if (index === 0) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  };

  makeAccordion(prices) {
    if (prices) {
      const listItems = prices.map((item, index) => (
        <li className="nav-item">
          <a
            className={this.active(index)}
            data-toggle="tab"
            href={"#main-info-" + (index + 1)}
            role="tab"
          >
            {item.content_type_name}
          </a>
        </li>
      ));
      return (
        <ul
          className="nav nav-tabs nav-tabs-custom nav-justified"
          role="tablist"
        >
          {listItems}
        </ul>
      );
    } else {
      return "";
    }
  }

  activeTab = (index) => {
    if (index === 0) {
      return "tab-pane active  p-3";
    } else {
      return "tab-pane p-3";
    }
  };

  onViewjournal = (id) => {
    this.setState({
      singlePrices: {},
    });
    let obj = this.state.prices.find((obj) => obj.id === id);
    if (obj) {
      this.setState({
        singlePrices: obj,
        isEmpty: true,
      });
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  PricePopUp = () => {};

  render() {
    const heading = [
      "content_type_name",
      "submission_fee_eur",
      "submission_fee_gbp",
      "submission_fee_usd",
    ];
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBoxTable
            Title="Following field cannot be empty"
            prices={[this.state.singlePrices]}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return (
      <HubContent title={this.state.name}>
        {/* LoadinG Handling */}
        {this.state.loader && <Loading />}
        {/* LoadinG Handling */}
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <ViewRow
                  label="Journal Type"
                  value={this.state.journal_type_name}
                />
                <ViewRow
                  label="Publication Type"
                  value={this.state.publication_type_name}
                />
                <ViewRow label="Publisher" value={this.state.publisher_name} />
                <ViewRow label="Journal Name" value={this.state.name} />
                <ViewRow label="Journal Owner" value={this.state.owner_name} />
                <ViewRow
                  label="Publisher Acronym"
                  value={this.state.pub_acronym}
                />
                <ViewRow
                  label="Sub sys acronym"
                  value={this.state.sub_sys_acronym}
                />
                <ViewRow label="pISSN" value={this.state.pissn} />
                <ViewRow label="eISSN" value={this.state.eissn} />
                <ViewRow label="IF" value={this.state.impact_factor} />
                <ViewRow label="Hindex" value={this.state.hindex} />
                <ViewRow label="Indexed" value={this.state.indexed} />
                <ViewRow label="Status" value={this.state.status} />
                <ViewRow
                  label="Sherpa Romeo Info"
                  value={this.state.sherpa_romeo_info}
                />
                <ViewRow label="Note" value={this.state.note} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div
                id="collapse1"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul
                        className="nav nav-tabs nav-tabs-custom nav-justified"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#main-info-1"
                            role="tab"
                          >
                            Prices
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#other-info-1"
                            role="tab"
                          >
                            Other
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane active p-3"
                          id="main-info-1"
                          role="tabpanel"
                        >
                          {" "}
                          {this.state.prices && (
                            <DataTable
                              heading={heading}
                              data={this.state.prices}
                              onEdit={this.onEditjournal}
                              onDelete={this.onDeletejournal}
                              onView={this.onViewjournal}
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane p-3"
                          id="other-info-1"
                          role="tabpanel"
                        >
                          Other Info
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

// const styles = {
//     table:{
//         fontFamily: "arial, sans-serif",
//         borderCollapse: "collapse",
//         width: '100%'
//     },
//     td:{
//         border: "1px solid #dddddd",
//         textAlign:'left',
//         padding:"8px"
//     },
//     th:{
//         border: "1px solid #dddddd",
//         textAlign:'left',
//         padding:"8px"
//     }
// };

const mapStateToProps = (state) => ({
  failed: state.user.failed || false,
  getUser: (userId) => {
    return state.user.users
      ? state.user.users.find((user) => {
          return user.id.toString() === userId.toString() && user;
        })
      : false;
  },
  demo_state: "demo",
  org: state.orgs.org_single || false,
  journal: state.journals.single_journal || false,
});

const mapDispatchToProps = (dispatch) => ({
  UpdateUser: (payload) => dispatch(UpdateUser(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
  singleJournal: (id) => dispatch(singleJournal(id)),
  
});

export const SingleJournal = connect(mapStateToProps, mapDispatchToProps)(Add);
