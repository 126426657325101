import React from "react";
import { connect } from "react-redux";
import { InputBox, Button,PopupBox } from "../../../../../core/components/common";
import {
  createVatConfig,
  createUpdateVatConfig,
  getSingleVatConfig,
  getVatConfig,
  DeleteVatConfig,
} from "../../../../actions";

import BaseComponent, { HubContent } from "../../../../../core/components";

class VatForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      enable: true,
    };
  }
  onTextFieldChange = (value) => {
    this.setState((prevState) => Object.assign(prevState.vat_number, value));
  };
  onTextField = (value) => {
    this.setState({
      vat_number: value,
    });
  };

  async componentDidMount() {
    const data = await this.props.getVatConfig();
    const vatData = Object.assign({}, data.results);
    this.setState({
      id: vatData && vatData[0] && vatData[0].id ? vatData[0].id : null,
      vat_number:vatData[0] && vatData[0].vat_number
          ? vatData[0].vat_number
          : null,
    });
  }

  onSubmit = async () => {
    const res = await this.props.createVatConfig(this.state.vat_number);
    this.setState({
      id: res.data.id,
      vat_number: res.data.vat_number,
    });
  };
  updateHandle = async () => {
    await this.props.createUpdateVatConfig({
      vat_number: this.state.vat_number,
      id: this.state.id,
    });
    this.setState({
      enable: true,
    });
  };
  onHandleEdit = async (id) => {
    const data = await this.props.getSingleVatConfig(id);
    this.setState({
      vat_number: data.vat_number,
      enable: !this.state.enable,
    });
  };
  onHandleDelete = async (id) => {

    this.setState({
      waitingMsg: "Deleting...",
    });
    const res = await this.props.DeleteVatConfig(this.state.id);
    this.setState({
      waitingMsg: null,
      id: null,
      deleteConfirm: false,
      deleteStatus: res.status,
      vat_number:""
    });

    const data = await this.props.getVatConfig();
    const vatData = Object.assign({}, data.results);
    this.setState({
      id: vatData && vatData[0] && vatData[0].id ? vatData[0].id : null,
      vat_number:vatData[0] && vatData[0].vat_number
          ? vatData[0].vat_number
          : null,
    });
  };


  onDelete = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        id,
      });
    }
  };


  onCancel = () => {
    this.props.history.push("/vat/addvat")
  };
  render() {
    return (
      <HubContent>
          {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            btnSize="200px"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onHandleDelete}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        <div className="row">
          <div className="col-12 vat-form-uni">
            <div className="add-vat-form">
              <div className="row">
                {this.state.id ? (
                  <div className="vat-number-details">
                    {this.state.enable === true ? (
                      <div className="" style={{textAlign:"right"}}>
            
                        <Button
                          type="danger"
                          name="danger"
                          text="Delete"
                          onClick={ ()=> this.onDelete(this.state.id)}
                        />
                       <Button
                          type="primary"
                          name="primary"
                          text="Edit"
                          onClick={() => this.onHandleEdit(this.state.id)}
                        />
                      </div>
                      
                    ) : null}
                    <div className="col-md-12 vat-form-number">
                      {this.state.enable === true ? (
                        <div className="single-vat-number2">
                          <p>Your VAT & Taxes Number</p>
                        </div>
                      ) : (
                        <div className="vat-input-form">
                          <p>Please add your VAT & Taxes Number</p>
                        </div>
                      )}
                    
                      {this.state.enable === true ? (
                        <div className="single-vat-number">
                          <h3>{this.state.vat_number}</h3>
                        </div>
                      ) : (
                        <div className="vat-input-form">
                          <InputBox
                            placeholder="Name"
                            field="vat_number"
                            className="col-md-12"
                            onChange={this.onTextFieldChange}
                            defaultValue={this.state.vat_number}
                          />
                        </div>
                      )}
                      <div className="vat-input-form">
                        {this.state.enable !== true ? (
                          <Button
                            type="primary"
                            name="primary"
                            text="Update"
                            onClick={this.updateHandle}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="vat-number-details">
                    <div className="col-md-12 vat-form-number">
                      <div className="vat-input-form">
                        <p>Please add Your VAT & Taxes Number</p>
                      </div>
                      <div className="vat-input-form">
                        <InputBox
                          placeholder="Name"
                          field="vat_number"
                          className="col-md-12"
                          onChange={this.onTextField}
                          defaultValue={this.state.vat_number}
                        />
                      </div>
                      <div className="vat-input-form">
                        <Button
                          type="primary"
                          name="primary"
                          text="submit"
                          onClick={this.onSubmit}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading:state.vat.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  createVatConfig: (payload) => dispatch(createVatConfig(payload)),
  createUpdateVatConfig: (payload) => dispatch(createUpdateVatConfig(payload)),
  getVatConfig: () => dispatch(getVatConfig()),
  getSingleVatConfig: (id) => dispatch(getSingleVatConfig(id)),
  DeleteVatConfig: (id) => dispatch(DeleteVatConfig(id)),
});

export const Vat = connect(mapStateToProps, mapDispatchToProps)(VatForm);
