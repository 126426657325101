import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';

class Report extends BaseComponent {
   
    render() {
        return (
            <HubContent title="Detail report">
                <h4>We are working on it...</h4>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    
})
const mapDispatchToProps = (dispatch) => ({

})

export const DetailReport = connect(mapStateToProps, mapDispatchToProps)(Report);