import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../../../../../core/components';
import { ArticleFull } from '../../hub';

class Article extends BaseComponent {
    render() {
        return (
            <ArticleFull
                title="Article List"
                type="university"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    // articles: state.articles.article_full
})

const mapDispatchToProps = (dispatch) => ({
    // ArticleFull: () => dispatch(getArticleFullList()),
})

export const UniArticles = connect(mapStateToProps, mapDispatchToProps)(Article);
