import React from 'react';
import { connect } from 'react-redux';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { OrgSingle } from '../../_multiaccess';

// This Component is onfigured By Shahan
class Pubs extends BaseComponent {
  render() {
    return (
      <OrgSingle
        OrgID={this.props.match.params.id}
        type="university"
      />
    )
  }
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})


export const PubSingle = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pubs)