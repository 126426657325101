import React, { Component } from 'react'
import {
    RadioButtonGroup,
} from "../../../../../../core/components/common";
import VatTable from "../vatTable";

import MultiSelectCheckBox from "../../../../../../core/components/common/MultiSelectCheckBox";
 class EU extends Component {
    render() {
        return (
            <div className="vat_eu">
            <div>
              <div className="vat-question-panel">
                <p>
                  Do you charge VAT to EU customers if they provide a valid
                  VAT number?
                </p>
            
                <RadioButtonGroup
                  onChange={this.props.onChangeHandleRadioBox}
                  field="charge_vat_number_eu"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.props.charge_vat_number_eu}
                />
              </div>
            
              {this.props.charge_vat_number_eu === true && (
                <div className="vat-question-panel" >
                  <p>How do you charge VAT?</p>
                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="charge_vat_billing_address_eu_1"
                    options={[
                      {
                        id: true,
                        name: "Based on customer's billing address",
                      },
                      {
                        id: false,
                        name: "Based on your country of location",
                      },
                    ]}
                    value={this.props.charge_vat_billing_address_eu_1}
                  />
                </div>
              )}
              {this.props.charge_vat_number_eu !== false ? (
                this.props.charge_vat_billing_address_eu_1 === true ? (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default VAT rate on OaMetrix. You can
                          override default VAT rate to apply your own rate.
                        </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.props.vatforeu}
                          selectCountry={(props) =>
                            this.props.selectCountryBillingAddressforeu(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={ this.props.vatEuBillingApiData_1}
                        editHandler={(value) =>
                          this.props.EditHandlerforeu_1(value)
                        }
                      />
                    </div>
                  </div>
                ) : null
              ) : null}
              {this.props.charge_vat_number_eu !== false ? (
                this.props.charge_vat_billing_address_eu_1 === false ? (
                  <div className="vat-country" >
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default VAT rate on OaMetrix. You can
                          override default VAT rate to apply your own rate.
                        </p>
                      </div>
                    </div>
                    <div className="vat-question-panel">
                      <div className="col-12">
                        <div className="card m-b-20">
                          <VatTable
                            data={ this.props.selfCountryVat_3}
                            editHandler={(value) =>  this.props.EditHandler_3(value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : null}
            
              {this.props.charge_vat_number_eu !== false ? (
                this.props.charge_vat_billing_address_eu_1 === true ||
                  this.props.charge_vat_billing_address_eu_1 === false ? (
                    <div className="vat-question-panel">
                      <p>
                        Do you charge VAT to EU customers if they do not provide
                        you a valid VAT number?
                    </p>
                      <RadioButtonGroup
                        onChange={this.props.onChangeHandleRadioBox}
                        field="charge_no_vat_number_eu_1"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.props.charge_no_vat_number_eu_1}
                      />
                    </div>
                  ) : null
              ) : null}
            
              {this.props.charge_vat_number_eu === true ? (
                this.props.charge_no_vat_number_eu_1 === true ? (
                  <div className="vat-question-panel">
                    <p>How do you charge VAT?</p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charge_no_vat_billing_address_eu_2"
                      options={[
                        {
                          id: true,
                          name: "Based on customer's billing address",
                        },
                        {
                          id: false,
                          name: "Based on your country of location",
                        },
                      ]}
                      value={this.props.charge_no_vat_billing_address_eu_2}
                    />
                  </div>
                ) : null
              ) : null}
              {this.props.charge_vat_number_eu !== false ? (
                this.props.charge_no_vat_number_eu_1 !== false ? (
                  this.props.charge_no_vat_billing_address_eu_2 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            {" "}
                            Here is the default VAT rate on OaMetrix. You can
                            override default VAT rate to apply your own rate.
                          </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.props.vatforeu}
                            selectCountry={(props) =>
                              this.props.selectCountryBillingAddressforeu2(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={ this.props.vatEuBillingApiData_2}
                          editHandler={(value) =>
                            this.props.EditHandlerforeu_2(value)
                          }
                        />
                      </div>
                    </div>
                  ) : null
                ) : null
              ) : null}
            
              {this.props.charge_vat_number_eu !== false ? (
                this.props.charge_no_vat_number_eu_1 !== false ? (
                  this.props.charge_no_vat_billing_address_eu_2 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default VAT rate on OaMetrix. You can
                            override default VAT rate to apply your own rate.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={ this.props.selfCountryVat_4}
                          editHandler={(value) =>  this.props.EditHandler_4(value)}
                        />
                      </div>
                    </div>
                  ) : null
                ) : null
              ) : null}
            
              {this.props.charge_vat_number_eu === false && (
                <div>
                  <div className="vat-question-panel">
                    <p>
                      Do you charge VAT to EU customers if they do not provide
                      you a valid VAT number?
                    </p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charge_no_vat_number_eu_2"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charge_no_vat_number_eu_2}
                    />
                  </div>
                  {this.props.charge_no_vat_number_eu_2 === true ? (
                    <div className="vat-question-panel">
                      <p>How do you charge VAT?</p>
                      <RadioButtonGroup
                        onChange={this.props.onChangeHandleRadioBox}
                        field="charge_no_vat_billing_address_eu_3"
                        options={[
                          {
                            id: true,
                            name: "Based on customer's billing address",
                          },
                          {
                            id: false,
                            name: "Based on your country of location",
                          },
                        ]}
                        value={this.props.charge_no_vat_billing_address_eu_3}
                      />
                    </div>
                  ) : null}
            
                  {this.props.charge_no_vat_number_eu_2 === true
                    ? this.props.charge_no_vat_billing_address_eu_3 ===
                    true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              {" "}
                                Here is the default VAT rate on OaMetrix. You
                                can override default VAT rate to apply your
                                own rate.
                              </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              data={this.props.vatforeu}
                              selectCountry={(props) =>
                                this.props.selectCountryBillingAddressforeu3(
                                  props
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="vat-question-panel">
                          <div className="col-12">
                            <div className="card m-b-20">
                              <VatTable
                                data={ this.props.vatEuBillingApiData_3}
                                editHandler={(value) =>
                                  this.props.EditHandlerforeu_3(value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    : null}
                  {this.props.charge_no_vat_number_eu_2 === true
                    ? this.props.charge_no_vat_billing_address_eu_3 ===
                    false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default VAT rate on OaMetrix. You
                              can override default VAT rate to apply your
                              own rate.
                              </p>
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            data={ this.props.selfCountryVat_4}
                            editHandler={(value) =>
                              this.props.EditHandler_4(value)
                            }
                          />
                        </div>
                      </div>
                    )
                    : null}
                </div>
              )}
            </div>
            </div>
        )
    }
}


export const VatEu = EU