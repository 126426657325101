import React from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";
var props = {};
export class DragAndDrop extends React.PureComponent {
  onDrop = (files) => {
    if (this.props.base_64) {
      var file = files[0];
      let reader = new FileReader();
      props = this.props;
      reader.onloadend = function (entry) {
        // Since it contains the Data URI, we should remove the prefix and keep  Base64 string
        let base_64 = reader.result.replace(/^data:.+;base64,/, "");
        let fileObj = { company_logo: base_64, logo_name: file.name };
        if (props.isImage) {
          // The Image() constructor creates a new HTMLImageElement instance.
          var image = new Image();
          image.src = entry.target.result;
          image.onload = function () {
            props.onDropHandler(
              Object.assign(fileObj, { height: this.height, width: this.width })
            );
          };
        } else {
          props.onDropHandler(fileObj);
        }
      };
      reader.readAsDataURL(file);
    } else {
      let data = new FormData();
      let filename = files.length > 0 && files[0].name;
      data.append("file", files[0]);
      data.append("filename", filename);
      this.props.onDropHandler(data);
    }
  };

  render() {
    return (
      <div className={this.props.className && this.props.className}>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames(
                  "dropzone d-flex align-items-center justify-content-center",
                  { "dropzone--isActive": isDragActive }
                )}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop files here...</p>
                ) : (
                  <p>
                    {this.props.uploadText
                      ? "Click here upload logo"
                      : "Please drop your file or click here to upload it ."}
                  </p>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

export default DragAndDrop;
