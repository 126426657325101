import React from "react";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";

import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  Loading,
  SelectBox,
  InputGroup,
  ExpandData,
  ExpandHeadingTable,
  PaginationForm,
} from "../../../../../core/components/common";
import { PopupBox } from "../../../../../core/components/common";
import {
  serialize,
  trxCardHeading,
  column,
  filterDays,
} from "../../../../../core/lib";
import moment from "moment";

import {
  paymentTransaction,
  getCardPayment,
  getInvoiceDownload,
  paymentTransactionDownload,
  getInstitutionsList,
} from "../../../../actions";

class ViewPayment extends BaseComponent {
  state = {
    columnToSort: "",
    sortDirection: "desc",
    showPopup: false,
    acknowledge: false,
    current: 1,
    institution_list: [],
    publisher_list: [],
    filterParams: {page:1},
        page:1
  };
  

   componentDidMount() {

   this.apiDataList()
   }
   isArrayCheck = value => {
    return Array.isArray(value)? value :[]
  }
  apiDataList = async() => { 
    await this.props.paymentTransaction();
    this.getInvoiceList();

    await this.props.getInstitutionsList();
      this.setState({
        institution_list: [ { id: "all", name: "All institutions" }].concat(this.isArrayCheck(this.props.institution_list)) 
      })
  

  }

  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }

  getInvoiceList = async (pageNum = 1,) => {
    let value = {
      page: pageNum,
    };
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
  
    });
  
    let params = serialize(this.state.params);
    let filter = serialize(this.state.filterParams);
  
    await this.props.getCardPayment({ params, filter });

    
  };
  pageChange = async (pageNumber) => {
    let value = {
        page: pageNumber,
      };
      await this.setState((prevState) => {
        prevState.filterParams = Object.assign(prevState.filterParams, value);
    
      });
    
      let params = serialize(this.state.params);
      let filter = serialize(this.state.filterParams);
    
      await this.props.getCardPayment({ params, filter });
  
      this.setState({
        page: pageNumber,
      })
      
  };
 
  onActionHandler = async (action, id) => {
    try {
      switch (action.toLowerCase()) {
        case "invoice_paid":
          this.setState({
            showPopup: true,
            article_id: id,
          });
          break;
        case "invoice_acknowledged":
          this.setState({
            showPopup: true,
            article_id: id,
            acknowledge: true,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      this.log("error: ", error);
    }
  };
  onPaymentHistoryDownloadClick = async () => {
    try {
      await this.props.paymentTransactionDownload();
      const url = window.URL.createObjectURL(
        this.props.transction_download_data
      );
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `payment_transction_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {

    }
  };

  cancelLoader = async (id) => {
    this.setState({
      showPopup: false,
      acknowledge: false,
    });
    if (id) {
      this.getInvoiceList();
    }
  };

  filterData = async value => {
    value.page = 1;

    if (value.hasOwnProperty("search_text")) {
      this.setState({
        ...value,
      });
    }
       this.setState((prevState) => {
         if (value.filter_institution === "all") {
            value.filter_institution = "";
        } 
        return (prevState.filterParams = Object.assign(
            prevState.filterParams,
            value
        ));
           
           
    });

    
    let params = await serialize(this.state.params);
    let filter = await serialize(this.state.filterParams);
    await this.props.getCardPayment({ params, filter });
  };

  render() {
    let errorHander = (
      <>
        {this.state.showPopup && (
          <PopupBox
            title="Update status"
            msg=""
            id={this.state.article_id}
            hideOk={true}
            acknowledge={this.state.acknowledge}
            NoText="Cancel"
            className="col-sm-8"
            onCancel={this.cancelLoader}
            markAsPaid={true}
          />
        )}
      </>
    );

    let payment_by_card = this.isArrayCheck(this.props.payment_by_card);
    
    let payment_by_card_data = [];
    payment_by_card &&
      payment_by_card.map((data, index) => {
        return payment_by_card_data.push({
          id: data && data.id,
          licence: data && data.licence_name,
          date: data && data.date_created,
          article_payment_id:
            data && data.article_data && data.article_data.article_id,
          article: data && data.article_data && data.article_data.article_title,
          publisher:
            data && data.article_data && data.article_data.publisher_name,
          author: data && data.article_data && data.article_data.author_name,
          institution: data && data.institution,
          currency: data && data.currency_name,
          amount: data && data.amount,
          invoice_number: data && data.invoice_number,
          payment_status: data.payment_source,
          licence_name: data && data.licence_name,

          status: data && data.status,
          file_name: data && data.receipt_download && data.receipt_download.file_name,
          link: data && data.receipt_download && data.receipt_download.link,
          label: data && data.receipt_download && data.receipt_download.label,
        });
      });
    let data = orderBy(
      payment_by_card_data,
      this.state.columnToSort,
      this.state.sortDirection
    );
    return (
      <HubContent
        title="Card payments"
        onArticleDownloadClickHandler={this.onPaymentHistoryDownloadClick}
      >
        {errorHander}
        <div className="row filter">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-7">
                {this.props.group === "PUBLISHER" && (
                  <SelectBox
                    className="col-md-12"
                    label={false}
                    onChange={this.filterData}
                    field="filter_institution"
                    defaultValue="all"
                    data={
                      
                        this.state.institution_list 
                      

                    }
                  />
                )}
              </div>
              <div className="col-md-5">
                <SelectBox
                  className="col-md-12"
                  field="filter_days"
                  defaultValue=""
                  data={filterDays}
                  onChange={this.filterData}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <InputGroup
              className="col-md-12"
              onClick={this.filterData}
              field="search_text"
              placeholder="Search here... "
            />
          </div>{" "}
          <div className="col-12">
            <div className="card m-b-20">
              <ExpandHeadingTable
                handleSort={this.handleSort}
                heading={trxCardHeading}
                column={column}
                columnToSort={this.state.columnToSort}
                sortDirection={this.state.sortDirection}
              />

              {this.props.payment_by_card ? (
                <div>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <div id="accordion" key={index}>
                        <ExpandData
                          onActionHandler={this.onActionHandler}
                          invoiceUpdate={true}
                          key={index}
                          item={item}
                          column={column}
                          downloadName="Payment-card"
                        />
                      </div>
                    ))
                  ) : (
                      <div className="card-body" style={{ textAlign: "center" }}>
                        No Data Found
                      </div>
                    )}
                  {payment_by_card_data.length > 0 ? (
                    <PaginationForm
                      pageSize={10}
                      pageChange={this.pageChange}
                      current={this.state.page}
                      count={this.props.payment_by_card_count}
                    />
                  ) : null}
                </div>
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: (state.auth && state.auth.group) || false,
  payment_by_card:
    (state.payment &&
      state.payment.paymentCardList &&
      state.payment.paymentCardList.results) ||
    [],
  payment_by_card_count:
    (state.payment &&
      state.payment.paymentCardList &&
      state.payment.paymentCardList.count) ||
    0,
  transction_download_data:
    state.payment && state.payment.transction_download_data,
  institution_list: (state.institutions && state.institutions.institution_list) ,
});

const mapDispatchToProps = (dispatch) => ({
  paymentTransaction: (payload) => dispatch(paymentTransaction(payload)),
  getCardPayment: (payload) => dispatch(getCardPayment(payload)),
  getInvoiceDownload: (payload = {}) => dispatch(getInvoiceDownload(payload)),
  paymentTransactionDownload: () => dispatch(paymentTransactionDownload()),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
});

export const PaymentHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPayment);
