import React from "react";
import { connect } from "react-redux";
import { Button } from "../../../../../core/components/common";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { getArticleControlConfig } from "../../../../actions";
import { Skeleton } from "antd";

class ViewTable extends BaseComponent {
  state = {
    info: null,
  };

  async componentDidMount() {
    let id = this.props.match.params.id;

    try {
      await this.props.getArticleControlConfig(id);
      this.setState({
        info: this.props.article_control_config,
      });
    } catch (e) {

    }
  }

  renderListItems = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => item.name).join(", ");
    }

    return "";
  };

  renderDetails = () => {
    let info = this.props.article_control_config;
    let optionsObj = this.props.article_control_config.email_notif || {};

    return (
      info && (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">

                <div className="vat-tax-button email">
                  <Button
                    type="danger"
                    name="danger"
                    text="X"
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <div className="col-sm-12">

                  <table style={styles.table}>
                    <tbody>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Institutions</td>
                        <td style={styles.td2}>
                          {info.institution_details.length > 0
                            ? this.renderListItems(info.institution_details)
                            : `All institutions`}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Publication types</td>
                        <td style={styles.td2}>
                          {info.publication_type_details.length > 0
                            ? this.renderListItems(
                              info.publication_type_details
                            )
                            : `All publication types`}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Publications</td>
                        <td style={styles.td2}>
                          {info.publication_details.length > 0
                            ? this.renderListItems(info.publication_details)
                            : `All publications`}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Content types</td>
                        <td style={styles.td2}>
                          {info.content_type_details.length > 0
                            ? this.renderListItems(info.content_type_details)
                            : `All content types`}
                        </td>
                      </tr>

                      <tr style={styles.tr}>
                        <td style={styles.td}>Funders</td>
                        <td style={styles.td2}>
                          {info.funder_details.length > 0
                            ? this.renderListItems(info.publication_details)
                            : `All funders`}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Countries</td>
                        <td style={styles.td2}>
                          {info.country_details.length > 0
                            ? this.renderListItems(info.country_details)
                            : `All countries`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="d-flex society">
                  <p className="col-md-6">
                    Would you like to make licence required for author?
                    </p>
                  <p className="col-md-6 text-left text-color">
                    <strong>
                      {info.author_licence_required ? "Yes" : "No"}
                    </strong>
                  </p>
                </div>
                <div className="d-flex society">
                  <p className="col-md-6">
                    Would you like to make terms and condition required for
                    author?
                    </p>
                  <p className="col-md-6 text-left text-color">
                    <strong>
                      {info.author_terms_required ? "Yes" : "No"}
                    </strong>
                  </p>
                </div>
                <div className="d-flex society">
                  <p className="col-md-6">
                    Would you like to send email to author?
                    </p>
                  <p className="col-md-6 text-left text-color">
                    <strong>
                      {optionsObj.send_email_to_author ? "Yes" : "No"}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <HubContent title="Article control config details">
        {this.state.info ? (
          this.renderDetails()
        ) : (
            <Skeleton active paragraph={{ row: 10 }} />
          )}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  article_control_config:
    state.articleControlConfig.article_control_config || false,
});

const mapDispatchToProps = (dispatch) => ({
  getArticleControlConfig: (id) => dispatch(getArticleControlConfig(id)),
});

export const ArticleControlConfigView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTable);

const styles = {
  color: "red",
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tr: {
    marginTop: 20,
    verticalAlign: "baseline",
  },
  td: {
    paddingTop: 10,
    color: "rgb(0, 122, 206)",
  },
  td2: {
    paddingTop: 10,
    width: "75%",
  },
};
