import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  Loading
} from "../../../../../core/components/common";
import { DataTable } from "../../../../../core/components";
import { connect } from "react-redux";
import moment from "moment";

import {
  oADealListApproved,
  oadealDownloadlist
} from "../../../../actions";
import { serialize } from "../../../../../core/lib";

const format = "Do MMM YYYY";

class DB extends BaseComponent {
  state = {
    filterParams: { page: 1 },
  };

  async componentDidMount() {
    await this.props.oADealListApproved();
  }
 
  pageChange =async (pageNumber) => {
    let value = {
      page: pageNumber,
  };

  await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
  });

  let params = serialize(this.state.params);
  let filter = serialize(this.state.filterParams);

  await this.props.oADealListApproved({ params, filter });
 
  };

  onViewOaDeal = (id, data) => {
    this.props.history.push("/action/oa-account-list/" + id, {
      ...data
    });
  };

  onArticleDownloadClick = async () => {
    try {
      await this.props.oadealDownloadlist();
      const url = window.URL.createObjectURL(this.props.oadeal_data_download);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `oadeal_approval_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };
  
  
  render() {
    const heading = [
      "Publisher",
      "deal_type",
      "currency",
      "discount",
      "expiry"
    ];
    const data = [];
    this.props.oaDealList &&
      this.props.oaDealList.map(item => {
        return data.push({
          id: item.id,
          Publisher: item.organisation_name,
          deal_type: item.deal_name,
          currency: item.currency_name,
          approval_status: item.approval_status,
          discount: item.discount
            ? parseFloat(item.discount).toFixed(2) + "%"
            : "-",
          expiry: item.expire_date
            ? moment(item.expire_date).format(format)
            : "-"
        });
      });

    return (
      <HubContent
        title="List of OA deals"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >

        <div className="row filter">
         

          <div className="col-lg-12">
            <div className="card m-b-20">
              {this.props.oaDealList ? (
               <DataTable
               heading={heading}
               data={data}
               count={this.props.oaDealList && this.props.oaDealList.length}
                  onView={this.onViewOaDeal}
                  pageChange={this.pageChange}
                  pageSize={10}
             />
           
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  oaDealList: state.OaDeals.oadeal_approved_list || false,
  oadeal_data_download: state.OaDeals.oadeal_data_download || false
});
const mapDispatchToProps = dispatch => ({
  oADealListApproved: (payload) => dispatch(oADealListApproved(payload)),
  oadealDownloadlist: () => dispatch(oadealDownloadlist())
});

export const oaDealApproveList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DB);
