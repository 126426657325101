import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  PopupBox,
  SelectBox,
  Loading
  // InputBox
} from "../../../../../core/components/common";
import { DataTable } from "../../../../../core/components";
import { connect } from "react-redux";
import { history } from "../../../../route";
import { serialize } from "../../../../../core/lib";

import moment from "moment";

import {
  getAllOaDealRequestList,
  deleteOaDealRequest,
  oaDealRequestDownload,
  getInstitutionsList
} from "../../../../actions";

const format = "Do MMM YYYY";
const approvalStatus = [
  { id: "all", name: "All" },
  { id: "approved", name: "Approved" },
  { id: "pending", name: "Pending" },
  { id: "rejected", name: "Rejected" },
  { id: "cancelled", name: "Cancelled" }
];

class DB extends BaseComponent {
  getOaDealData = async (page = 1, pageSize = 10) => {
    let params = serialize(
      Object.assign(
        { page: page, page_size: pageSize },
        this.state.filterParams
      )
    );

    await this.props.getAllOaDealRequestList(params);
    this.setState({
      data: this.props.oaDealList.results,
      count: this.props.oaDealList.count
    });
  };
  async componentDidMount() {
    this.props.getInstitutionsList();
    this.getOaDealData();
  }
  pageChange = pageNumber => {
    this.getOaDealData(pageNumber);
  };
  state = {
    data: null,
    filterParams: {
      approval_status: "pending"
    }
  };
  onEditOaDeal = id => {
    this.props.history.push("/oa-deal-activities/edit-oadeal-request/" + id);
  };
  onDeleteOaDeal = id => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        oAdealId: id
      });
    }
  };

  onViewOaDeal = (id, data) => {
    history.push("/oa-deal-activities/view-oadeal-request/" + id, {
      ...data
    });
  };
  onDeleteOaDealHander = async () => {
    const oAdealId = this.state.oAdealId;
    this.setState({
      waitingMsg: "Deleting..."
    });
    const oADeal = await this.props.deleteOaDealRequest(oAdealId);
    let data = this.state.data;
    let newData = data.filter(element => {
      return element.id !== oAdealId;
    });
    this.setState({
      waitingMsg: null,
      oAdealId: null,
      deleteConfirm: false,
      deleteStatus: oADeal.status,
      data: newData
    });
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      oAdealId: null,
      waitingMsg: null,
      deleteStatus: null
    });
  };
  onArticleDownloadClick = async () => {
    try {
      await this.props.oaDealRequestDownload();
      const url = window.URL.createObjectURL(this.props.oadeal_download_data);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `oa-deals_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      
    }
  };

  filterData = async value => {
    await this.setState(prevState => {
      if (value.approval_status === "all") {
        value.approval_status = "";
      } else if (value.counter_organisation === "all") {
        value.counter_organisation = "";
      }

      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });

    this.getOaDealData();
  };

  capitalize = s => {
    if (s.trim() === "") return s;
    return s[0].toUpperCase() + s.slice(1);
  };

  render() {
    const data = [];
    this.state.data &&
      this.state.data.map(item => {
        return data.push({
          id: item.id,
          Organisation: item.counter_organisation_name,
          deal_type: item.deal_name,
          currency: item.currency_name,
          discount: item.discount
            ? parseFloat(item.discount).toFixed(2) + "%"
            : "-",
          expiry: item.expire_date
            ? moment(item.expire_date).format(format)
            : "-",
          status: item.approval_status && this.capitalize(item.approval_status),
          approval_status: item.approval_status
        });
      });

    const heading = [
      "Organisation",
      "deal_type",
      "currency",
      "discount",
      "expiry",
      "status"
    ];

    return (
      <HubContent
        title="List of OA deal requests"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteOaDealHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        <div className="row fillter">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <SelectBox
                  className="col-md-12"
                  data={approvalStatus}
                  onChange={this.filterData}
                  field="approval_status"
                  defaultValue="all"
                />
              </div>
              {/* <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div> */}
            </div>
          </div>
          {/* 
          <div className="col-md-4">
            <InputBox className="col-md-12" placeholder="Search here... " />
          </div> */}
          <div className="col-lg-12">
            <div className="card m-b-20">
              {this.state.data ? (
                this.state.data && (
                  <DataTable
                    heading={heading}
                    data={data}
                    isOaDealRequest={true}
                    onEdit={this.onEditOaDeal}
                    onDelete={this.onDeleteOaDeal}
                    onView={this.onViewOaDeal}
                    count={this.state.count}
                    pageChange={this.pageChange}
                    pageSize={10}
                  />
                )
              ) : (
                <Loading type="flat" />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.user.id || false,
  oaDealList: state.OaDeals.oadeal_request_list || false,
  oadeal_download_data: state.OaDeals.download_data,
  institution_list:( state.institutions && state.institutions.institution_list) || []
});
const mapDispatchToProps = dispatch => ({
  getAllOaDealRequestList: payload =>
    dispatch(getAllOaDealRequestList(payload)),
  deleteOaDealRequest: id => dispatch(deleteOaDealRequest(id)),
  oaDealRequestDownload: (payload = {}) =>
    dispatch(oaDealRequestDownload(payload)),
  getInstitutionsList: () => dispatch(getInstitutionsList())
});

export const OaDealRequestsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DB);
