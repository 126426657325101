import React from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { Tooltip, Card, Spin, Button as SendAdhocBtn } from "antd";
import Loader from "react-loader-spinner";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { history } from "../../../../../route";
import { PopupBox, Button, Loading } from "../../../../../../core/components/common";
import {
    UpdateArticleRequired,
    downloadInvoice,
    getArticleFullSingleList,
    articleButton,
} from "../../../../../actions";
import { ArticleInfo, AuthorInfo, MetrixInfo, FunderInfo, ArticlePriceInfo } from "./index";
import ActionButton from "./ActionButton"
import SocietyOffer from "./ArticleOffer"

import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


class ArticleSingle extends React.PureComponent {
    state = {
        id: null,
        view_co_author: false,
        error: null,
        errorInfo: null,
        extraField: {},
        active: false,
        isHovered: false,
        article_id:""

    };
    componentDidMount() {
        if (this.props.index === 0) {
            this.handleArticleActionBtn(this.props.article && this.props.article.id)
        }
        this.setState({
            article_id: (this.props.location && this.props.location.id),
        })
    }
  
    componentDidUpdate(prevProps,prevState) {
        if (prevProps.location.id !== prevState.article_id) {
            const element = document.getElementById(this.state.article_id);
            element && element.scrollIntoView({behavior: 'smooth'});
        }
      }
      
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
    }
    onClickHander = (e) => {
        e.preventDefault();
        history.push("/request-apc-fund/" + this.props.article.id);
    };

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.extraField, value);
        });
    };

    onSubmitHandler = async (e) => {
        e.preventDefault();
        this.setState({
            status: true,
        });
    };
    cancelLoader = async (e) => {
        this.setState({
            status: false,
            showPopup: false,
            showPopupLicense: false,
        });
    };

    async onSave(obj, id) {
        await this.props.UpdateArticleRequired(id, obj);
    }

    onEdit = async (key, title, id) => {
        if (key === "license") {
            this.setState({
                showPopupLicense: true,
                key: key,
            });
        } else {
            this.setState({
                title: title,
                showPopup: true,
                key: key,
            });
        }
    };

    onPopupSuccess = async (payload) => {
        this.setState(payload);
    };

    priceofFixed = (price) => {
        let dataPrice = Number(price).toFixed(2);
        return dataPrice;
    };

    onClickDownloadInvoice = async (url, item) => {
        let payload = {
            url: url,
        };

        await this.props.downloadInvoice(payload);

        const urlObj = window.URL.createObjectURL(this.props.invoice_download);
        const link = document.createElement("a");
        link.href = urlObj;
        var d = new Date();

        link.setAttribute(
            "download",
            `${item.replace(/\s+/g, "")}_${moment(d).format("YYYYMMDD_HHmmss")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
    };

    isLicenceUpdateRequired = (article) => {
        if (
            article.status === "approved" &&
            (!article.selected_licence || !article.selected_licence.id)
        ) {
            if (this.props.group === "AUTHOR") {
                return (
                    <Button
                        size="sm"
                        name="warning"
                        onClick={(e) => {
                            e.preventDefault();
                            this.onEdit("license", article.id);
                        }}
                        type="button"
                        text="Add licence"
                    />
                );
            } else {
                return (
                    <Button
                        size="sm"
                        name="warning"
                        onClick={null}
                        type="button"
                        text="Licence missing"
                    />
                );
            }
        }

        return "";
    };

    highlightSearchText = (text, searchText, fullText = false) => {
        if (!searchText || !text) {
            return text;
        }

        let lowerText = text.toLowerCase();
        let lowerSearchText = searchText.toLowerCase();

        if (fullText && lowerText !== lowerSearchText) {
            return text;
        }

        return (
            <Highlighter
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={text}
            />
        );
    };
    handleArticleActionBtn = (id) => {
        this.setState(
            {
                active: !this.state.active,
            },
            async () => {
                let data;
                if (this.state.active === true) {
                    data = await this.props.articleActionButton(id);
                }
                this.setState({
                    actions: data && data.actions,
                });
            }
        );
    };

    handleArticleDetail = (id) => {
        this.props.getArticleFullSingleList(id);
    };

    handleSocietyDiscount = () => {
        this.setState({
            isShow: true
        });
    };

    onClose = () => {
        this.setState({
            isShow: false,
            isAlterNativeMail: false
        });
    };
    enterLoading = index => {
        this.setState(({ sendAdhocBtnLoading }) => {
       
          return {
            sendAdhocBtnLoading: true,
          };
        });
        setTimeout(() => {
          this.setState(({ sendAdhocBtnLoading }) => {
       
            return {
                sendAdhocBtnLoading: false,
            };
          });
        }, 3000);
    };
    handleClick = (id,articleId) => {
        this.setState(
          {
            active: !this.state.active,
          },
          async () => {
            let data;
            if ( this.props.show === this.props.index) {
              data = await this.props.articleActionButton(articleId);
            }
            this.setState({
              actions: data && data.actions,
            });
          }
        );
        this.props.articleHandleClick(id);
      };
    render() {
        let { article, url_article_id, search_text, articleOffer } = this.props;

        let showState =
            article && article.id.toString() === url_article_id ? true : false;
        let errorHander = (
            <>

                {this.state.showPopup && (
                    <PopupBox
                        title={this.state.title}
                        keyField={true}
                        className="col-sm-4 departments-fields"
                        keyName={this.state.key}
                        NoText="Cancel"
                        id={article.id}
                        onPopupSuccess={this.onPopupSuccess}
                        onCancel={this.cancelLoader}
                    />
                )}
                {this.state.showPopupLicense && (
                    <PopupBox
                        title={"License"}
                        LicenseForm={true}
                        className="col-sm-6 departments-fields"
                        keyName={this.state.key}
                        NoText="Cancel"
                        license_article_id={article.id}
                        onPopupSuccess={this.onPopupSuccess}
                        onCancel={this.cancelLoader}
                    />
                )}
            </>
        );
        let { author, status_label, selected_licence } = article;
        let articleDetail = this.props.article_single;
        let selected_license_anchor =
            article && selected_licence && selected_licence.license_link ? (
                <a
                    href={article && selected_licence && selected_licence.license_link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    {selected_licence.name}{" "}
                </a>
            ) : (
                article && selected_licence && selected_licence.name
            );

        let author_name =
            author &&
            `${author.salutaion || ""} ${author.first_name || ""} ${author.middle_name || ""} ${author.last_name || ""
            }`;

        return this.state.error ? (
            <Card className="bg-danger text-white">
                Unavailable to reach data...!
            </Card>
        ) : (
            <div className="card mb-1" key={this.props.key}>
                {errorHander}

                <div className=" p-3" id="heading"  >
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box  pt-0 pb-3" >


                                <h4 className="page-title">
                                    <a
                                        className="article_open_arrow ss"
                                        href={`#collapse_${article.id}`}
                                        data-toggle="collapse"
                                        onClick={() => this.handleArticleDetail(article.id)}
                                            aria-expanded="true"
                                            id={article.id}
                                        aria-controls={`collapse_${article.id}`}
                                        style={{ color: "#5874ab", fontWeight: 500 }}
                                    >
                                        {this.highlightSearchText(article.title, search_text) ||
                                            "Untitled"}




                                    </a>
                                    <div className="article-offer-icon" style={{ cursor: "pointer" }}>

                                        {article.status === 'published' && article.openaccess_type === 'GOLD_OPENACCESS' && (
                                                <Tooltip
                                                    placement="topLeft"
                                                    title={`Gold Open Access`}
                                                >
                                                    <img
                                                        alt="special discount"
                                                        className="discount-icon mx-1 gold-oa-icon"
                                                        src="/assets/images/gold-openaccess-icon.svg"
                                                    />
                                                </Tooltip>
                                            )}

                                        {article.status !== 'published' && articleOffer && articleOffer.has_inst_discount && (
                                            <Tooltip
                                                placement="topLeft"
                                                title={`Institution or Author may eligible for special discount for this article.`}
                                            >
                                                <img
                                                    alt="special discount"
                                                    className="discount-icon mx-1"
                                                    src="/assets/images/icon-member-discount.png"
                                                />
                                            </Tooltip>
                                        )}

                                        {article.status !== 'published' && (this.props.offerLoading !== true ? articleOffer && articleOffer.show_society_discount_badge && (
                                            <Tooltip
                                                placement="topLeft"
                                                onClick={this.handleSocietyDiscount}

                                            >
                                                <img
                                                    alt="special discount"
                                                    className="discount-icon mx-1"
                                                    src="/assets/images/icon-discount.png"
                                                />
                                            </Tooltip>
                                            ) : <Loader type="ThreeDots" color="#2667a4" height="22" width="22" />)
                                        }
                                    </div>
                                </h4>
                            </div>
                            <a
                                className="article_open_arrow ss"
                                href={`#collapse_${article.id}`}
                                data-toggle="collapse"
                                aria-expanded="true"
                                onClick={() => this.handleArticleDetail(article.id)}
                                aria-controls={`collapse_${article.id}`}
                                style={{ color: "#5874ab", fontWeight: 500, }}
                            >
                                <i className="ion-arrow-down-b float-right" />
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-5">
                            <p className="m-0">
                                <strong>Corresponding author : </strong>
                                {this.highlightSearchText(author_name, search_text)}
                            </p>
                            <p className="m-0">
                                <strong>Email : </strong> {author && author.email}
                            </p>
                            <p className="m-0">

                                {article && selected_licence && selected_licence.license_link && <strong>License : </strong>}
                                {selected_license_anchor}
                                {article && selected_licence && selected_licence.license_link && (
                                    <a
                                        style={{ marginLeft: 20 }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={selected_licence.license_link}
                                    >
                                        {" "}
                                        <i className="mdi mdi-download"></i>
                                    </a>
                                )}
                                {article && article.is_licence_edit_icon === true
                                    ? this.isLicenceUpdateRequired(article)
                                    : null}
                            </p>
                            {articleOffer && articleOffer.apc_waived_text && (
                                <p className="m-0">
                                    <strong>Note : </strong>
                                    <span className="apc-waiver-note">
                                        {articleOffer && articleOffer.apc_waived_text}
                                    </span>

                                    {articleOffer.invoice_download &&
                                        articleOffer.invoice_download.pdf_link && (
                                            <a
                                                href={articleOffer.invoice_download.pdf_link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="mdi mdi-download cursor-pointer ml-2 receipt-download"></i>
                                            </a>
                                        )}

                                    {articleOffer && articleOffer.invoice_download &&
                                        !articleOffer.invoice_download.pdf_link &&
                                        articleOffer && articleOffer.invoice_download.link && (
                                            <i
                                                className="mdi mdi-download cursor-pointer ml-2 receipt-download"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.onClickDownloadInvoice(
                                                        articleOffer && articleOffer.invoice_download.link,
                                                        articleOffer && articleOffer.invoice_download.name
                                                    );
                                                }}
                                            ></i>
                                        )}
                                </p>
                            )}
                        </div>
                        <div className="col-sm-4">
                            <div className="m-0">
                                <strong>Institution :</strong>{" "}
                                {author &&
                                    this.highlightSearchText(author.affiliation, search_text)}
                            </div>
                            <div className="m-0">
                                <strong>Department :</strong>{" "}
                                {author && author.department && author.department.name}
                                {this.state.department}
                                {!this.state.department &&
                                    articleOffer &&
                                    articleOffer.department_missing && (
                                        <i
                                            className="mdi mdi-pen cursor-pointer ml-2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.onEdit("department", "Department", articleOffer.id);
                                            }}
                                        ></i>
                                    )}
                            </div>

                            <p className="m-0">
                                <strong>Approval date :</strong>{" "}
                                {article && article.approved_date !== null
                                    ? moment(article && article.approved_date).format(
                                        "Do MMMM YYYY"
                                    )
                                    : null}
                            </p>
                        </div>

                        {this.state.isShow &&
                            <SocietyOffer
                                onActionHandler={(data) => this.props.onActionHandler(data)}
                                publication_name={articleDetail && articleDetail.publication && articleDetail.publication.name}
                                article_id={article.id}
                                isShow={this.state.isShow}
                                onClose={this.onClose}
                            />
                        }
                        <div className="col-sm-3">
                            <div className="button-show" >
                                {status_label && status_label.action !== null && (
                                    <ActionButton
                                            article_id={article.id}
                                            payment_text={article && article.payment_badge_text}

                                        text={status_label && status_label.label}
                                        color={status_label && status_label.color_key}
                                        action={status_label && status_label.action}
                                        onClickHander={() =>
                                            this.props.onActionHandler &&
                                            this.props.onActionHandler(
                                                status_label && status_label.action
                                            )
                                        }
                                    />
                                )}

                                <button
                                    className="button-expend"

                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() => this.handleClick(this.props.index,article.id)}
                                    >
                                    <i
                                        className={
                                            this.state.active === true
                                                ? "mdi mdi-chevron-up"
                                                : "mdi mdi-chevron-down"
                                        }
                                    />


                                </button>
                            </div>





                            {
                                this.props.show === this.props.index&&  (this.props.loading !== true ? < ul className="action-button university">

                                    {
                                        this.state.actions &&
                                        this.state.actions.map((data, key) => {
                                            return (
                                                <div key={key}  className="article-button-dropdown">



{
                                                        data.action === "ARTICLE_INVOICE" && <li><Link onClick={() =>
                                                            this.props.onActionHandler && this.props.onActionHandler(data.action)
                                                        } to="#"><i className="mdi mdi-cart-outline"></i>{data.label} </Link></li>}
     {
                                                        data.action === "ARTICLE_PAYMENT" && <li><Link onClick={() =>
                                                            this.props.onActionHandler && this.props.onActionHandler(data.action)
                                                        } to="#"><i className="mdi mdi-shopping"></i>{data.label} </Link></li>}

                                                    {
                                                        data.action === "author_apc_fund_request" && <li style={{  cursor: "pointer", }} onClick={() => this.props.onActionHandler && this.props.onActionHandler(data.action)}><i style={{ marginRight: 10 }} className="mdi mdi-check"></i>{data.label} </li>}

                                               
                                                  
                                                    {  data.action === "author_non_oa_request" && <li style={{  cursor: "pointer" }} onClick={() => this.props.onActionHandler && this.props.onActionHandler(data.action)
                                                    }><i style={{ marginRight: 10 }} className="mdi mdi-open-in-app"></i>{data.label} </li>}


                                                    {
                                                        data.action === "no_action" && <li ><i style={{ marginRight: 5 }} className="mdi mdi-checkbox-blank-outline"></i>{data.label}</li>}

                                                    {  data.action === "ACTION_AUDIT" && <li><Link onClick={() =>
                                                        this.props.onActionHandler && this.props.onActionHandler(data.action)

                                                    } to="#"><i className="mdi mdi-store"></i>{data.label} </Link></li>}

                                                </div>

                                            );
                                        })
                                        }
      
                                    {
                                        this.props.onCustomEmailButtonClick && <li className="send-adhoc-btn" onClick={() =>
                                            this.props.onCustomEmailButtonClick(
                                                article && article.id
                                            )} style={{  cursor: "pointer" }}>  {this.state.sendAdhocBtnLoading !==true && <i className="mdi mdi-message-draw"></i> }    <SendAdhocBtn type="" loading={this.state.sendAdhocBtnLoading} onClick={() => this.enterLoading()}>
                                            Send Ad Hoc Email                                          </SendAdhocBtn>
    </li>}

                                </ul> : <div style={{ textAlign: "center" }}><Spin indicator={antIcon} /></div>)}

                        </div>
                    </div>
                </div>
                {
                    article && article.id && (
                        <div
                            id={`collapse_${article.id}`}
                            className={`collapse ${showState && "show"}`}
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                        >
                            <div className="container-fluid">
                                <div className="row info_border_top">
                                    <div className="col-sm-12">
                                        {this.props.isLoading !== true && <ul
                                            className="nav nav-tabs nav-tabs-custom nav-justified"
                                            role="tablist"
                                        >
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    data-toggle="tab"
                                                    href={`#main-info_${article.id}`}
                                                    role="tab"
                                                >
                                                    Article Info </a>
                                            </li>
                                            <li className="nav-item">

                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href={`#author-info_${article.id}`}
                                                    role="tab"
                                                >
                                                    Authors  </a>
                                            </li>
                                            {((articleDetail && articleDetail.all_funders_json && articleDetail.all_funders_json.length > 0) || ((articleDetail && articleDetail.funder_name) || (articleDetail && articleDetail.fund_acknowledgement) || (articleDetail && articleDetail.grant_number))) &&

                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href={`#funder_${article.id}`}
                                                        role="tab"
                                                    >
                                                        Funders </a>
                                                </li>}
                                            {((articleDetail && articleDetail.alt_metric_score) || (articleDetail && articleDetail.crossref_count)) &&

                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href={`#metrix_${article.id}`}
                                                        role="tab"
                                                    >
                                                        Metrics</a>
                                                </li>}
                                            {
                                                articleDetail && articleDetail.custom_questions_modified && articleDetail.custom_questions_modified.length > 0 && <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href={`#miscellinious_${article.id}`}
                                                        role="tab"
                                                    >
                                                        Miscellaneous </a>
                                                </li>
                                            }

                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    data-toggle="tab"
                                                    href={`#price-gbp_${article.id}`}
                                                    role="tab"
                                                >
                                                    Prices</a>
                                            </li>
                                        </ul>}
                                        {
                                            this.props.isLoading !== true ?

                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane active p-3"
                                                        id={`main-info_${article.id}`}
                                                        role="tabpanel"
                                                    >
                                                        <ArticleInfo search_text={search_text} articleDetail={articleDetail} highlightSearchText={(value, search) => this.highlightSearchText(value, search)} />
                                                    </div>
                                                    <div
                                                        className="tab-pane p-3"
                                                        id={`author-info_${article.id}`}
                                                        role="tabpanel"
                                                    >
                                                        <AuthorInfo author={articleDetail && articleDetail.author} co_authors={articleDetail && articleDetail.co_authors} />
                                                    </div>
                                                    {
                                                        ((articleDetail && articleDetail.alt_metric_score) || (articleDetail && articleDetail.crossref_count)) &&
                                                        <div

                                                            className="tab-pane p-3"
                                                            id={`metrix_${article.id}`}
                                                            role="tabpanel"
                                                        >
                                                            <MetrixInfo alt_metric_score={articleDetail && articleDetail.alt_metric_score} crossref_count={articleDetail && articleDetail.crossref_count} />
                                                        </div>}


                                                    {
                                                        ((articleDetail && articleDetail.all_funders_json && articleDetail.all_funders_json.length > 0) || ((articleDetail && articleDetail.funder_name) || (articleDetail && articleDetail.fund_acknowledgement) || (articleDetail && articleDetail.grant_number))) &&

                                                        < div
                                                            className="tab-pane p-3"
                                                            id={`funder_${article.id}`}
                                                            role="tabpanel"
                                                        >
                                                            <FunderInfo funders={articleDetail && articleDetail.all_funders_json} funderdetail={articleDetail} />
                                                        </div>}
                                                    <div
                                                        className="tab-pane p-3"
                                                        id={`miscellinious_${article.id}`}
                                                        role="tabpanel"
                                                        style={{ marginBottom: 15 }}
                                                    >
                                                        {
                                                            articleDetail && articleDetail.custom_questions_modified && articleDetail.custom_questions_modified.map((item, index) => (
                                                                <div className="row" key={index}>
                                                                    <div className="col-sm-12">
                                                                        <p className="m-1">{item.question_text}
                                                                            <strong> {item.answer_text}</strong>
                                                                        </p>
                                                                    </div>

                                                                </div>


                                                            ))

                                                        }

                                                    </div>

                                                    <div
                                                        className="tab-pane p-3"
                                                        id={`price-gbp_${article.id}`}
                                                        role="tabpanel"
                                                    >
                                                        <ArticlePriceInfo prices={articleDetail} priceofFixed={(value) => this.priceofFixed(value)} />
                                                    </div>

                                                </div> : <Loading type="flat" />}

                                    </div>

                                </div>


                            </div>

                        </div>
                    )
                }
            </div >
        );
    }
}

const mapStateToProps = (state, props) => ({
    group: (state.auth && state.auth.group),
    invoice_download: (state.payment && state.payment.invoice_download),
    article_single: state.articles.article_single,
    isLoading: state.articles.isLoading,
    loading: state.articles.loading,
});

const mapDispatchToProps = (dispatch, props) => ({
    UpdateArticleRequired: (id, payload) =>
        dispatch(UpdateArticleRequired(id, payload)),
    downloadInvoice: (payload = {}) => dispatch(downloadInvoice(payload)),
    articleActionButton: (payload) => dispatch(articleButton(payload)),
    getArticleFullSingleList: (payload) => dispatch(getArticleFullSingleList(payload)),
});

export const ArticleFullSingle = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ArticleSingle)
);
