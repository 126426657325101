import { GET_SUB_STATUS,GET_SUBMISSION_LIST,CREATE_SUBMISSION,UPDATE_SUBMISSION
 } from "../../config";
 
 export default (state = {
   createSubmission: {},
   update_submission: {},
   submission_creds_info: {},
   single_submission_creds:{},
 }, action) => {
   switch (action.type) {
     case CREATE_SUBMISSION:
       return (state = {
         ...state,
         createSubmission: action.payload,
       })
    
     case UPDATE_SUBMISSION:
       return (state = {
         ...state,
         update_submission: action.payload,
       })
     case GET_SUBMISSION_LIST :
       return (state = {
         ...state,
         submission_creds_info: action.payload,
       })
     case GET_SUB_STATUS :
       return (state = {
         ...state,
         sub_status: action.payload,
       })
       
     default:
       return state
   }
 }