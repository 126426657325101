import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../core/components";

import { AutoComplete, Spin,Checkbox } from "antd";
import {LoadingOutlined } from '@ant-design/icons';


import {
  getGroupList,
  hubgetOrgList,
  getAllCountry,
  getAllState,
  getAllCity,
  singleOrg,
  getConsortiumList,
  getGroupPermission
} from "../../../actions";
import { getRoleList } from "../../../actions";
import {
  SelectBox,
  InputNewBox,
  ModalSelectBox,InputBox, 
} from "../../../../core/components/common";
import { serialize } from "../../../../core/lib";
import { AlternativeNameForm } from "../../../../core/components/common/AlternativeNameForm";
import UserPermission from "../../views/hub/group/usergroupPermission"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Form extends BaseComponent {
  state = {
    org: {
      is_active: true,
      is_society: false,
      meta: {
        is_active: true,
      },
      address: {
        is_active: true,
        title: "",
      },
    },
    roleList: false,
    title: "",
    groupList: false,
    addresses: {},
    user_meta: {},
    domain_meta: {},
    orgStatus: false,
    stateStatus: [],
    cityStatus: [],
    selectedOrganization: false,
    address: {},
    meta: {},
    alt_names: [],
  };


   async componentDidMount() {
     
    const id = this.props.org_id;


    this.props.getRoleList();
    this.props.getGroupList();
    this.props.getAllCountry();
    this.props.getConsortiumList();
     
    let altNames = this.state.alt_names;

    if (id) {
      await this.props.singleOrg(id);
      await this.props.getGroupPermission(this.props.org_details&& this.props.org_details.domain)

      this.setState(
        {
          dataList: this.props.orgs && this.props.orgs.map(item => ({
            id: item.id,
            value: item.name,
          })),  
          permissions:this.props.org_details&& this.props.org_details.permissions,
          parent_name:this.props.org_details && this.props.org_details.parent_name,
          org: this.props.org_details,
          consortiums: this.props.org_details.consortiums || [],
          alt_names:
          this.props.org_details.alt_names && this.props.org_details.alt_names.length > 0
              ? this.props.org_details.alt_names
              : altNames,
        },
        /*
        async function () {
          this.state.org.address &&
            this.state.org.address.country &&
            (await this.props.getAllState({
              country: this.state.org.address.country,
            }));
          this.setState((prevState) => {
            prevState.stateStatus[0] =
              this.state.org.address && this.state.org.address.country
                ? true
                : false;
            return prevState;
          });
        }*/
      );
    }
  }


  onChangeGroupHandler = async (value) => {
    await this.props.getGroupPermission(value.group)
    this.setState({
      domain_name: value.group,
    });
    this.setState((prevState) => {
      prevState.user = Object.assign(this.state.org, value);
      return prevState;
    });
    this.setState((prevState) => {
      return Object.assign(prevState.org, value);
    });
  };


  onSubmitHandler = async (value) => {
    let data ={ ...this.state.org, permissions:value.checkedList};
    this.props.onSubmitHandler(data);
  };

  onChangeAddressHandler = async (i, value) => {
    let adds = this.state.addresses[i] || {};
    let state, city;
    this.setState((prevState) => {
      prevState.addresses[i] = Object.assign(adds, value);
      return prevState;
    });
    let newValue = {
      ...value,
      index: i,
      page_size: 9999,
    };

    if (value.country) {
      this.setState((prevState) => {
        prevState.stateStatus[i] = false;
        return prevState;
      });

      state = await this.props.getAllState(newValue);
      state.status &&
        this.setState((prevState) => {
          prevState.stateStatus[i] = true;
          return prevState;
        });
    } else if (value.state) {
      this.setState((prevState) => {
        prevState.cityStatus[i] = false;
        return prevState;
      });
      city = await this.props.getAllCity(newValue);
      city.status &&
        this.setState((prevState) => {
          prevState.cityStatus[i] = true;
          return prevState;
        });
    }
    this.setState((prevState) => {
      return Object.assign(prevState.org.address || {}, value);
    });
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.org, value);
    });
  };
  onChangeSelectAddressHandlers = (value) => {

      this.setState((prevState) => {
      return Object.assign(prevState.org, {address:value});
    });
  };
  onChangeAddressHandlers = (value) => {
    this.setState((prevState) => {

     return Object.assign(prevState.org.address, value);
    });
  };
  onChangeMetaHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.meta, value);
    });
    this.setState((prevState) => {
      return Object.assign(prevState.org, { meta: this.state.meta });
    });
  };

  // onChangeAltNameHandler = (value) => {
  //   let altNames = this.state.alt_names;

  //   if (value.hasOwnProperty("name_1_other_lang")) {
  //     try {
  //       altNames[0].name = value.name_1_other_lang;
  //     } catch (error) {
  //       altNames.push({
  //         name: value.name_1_other_lang,
  //       });
  //     }
  //   } else if (value.hasOwnProperty("name_2_other_lang")) {
  //     try {
  //       altNames[1].name = value.name_2_other_lang;
  //     } catch (error) {
  //       altNames.push({
  //         name: value.name_2_other_lang,
  //       });
  //     }
  //   }

  //   this.setState((prevState) => {
  //     return Object.assign(prevState.alt_names, altNames);
  //   });

  //   this.setState((prevState) => {
  //     return Object.assign(prevState.org, { alt_names: this.state.alt_names });
  //   });
  // };

  getAltName = (index) => {
    try {
      let altNames = this.state.alt_names;

      return altNames[index].name;
    } catch (error) {
      return "";
    }
  };

  onChangeChecked = (e) => {
    let value = {
      is_society: e.target.checked,
    };

    this.setState((prevState) => {
      return Object.assign(prevState.org, value);
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.org, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });
      }
    );
  };

  onModalShow = (fieldName) => {
    switch (fieldName) {
      case "consortiums":
        this.setState({
          isShowConsortiumModal: true,
        });
        break;
      case "alt_names":
        this.setState({
          showAltNameModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalClose = (e) => {
    this.setState({
      isShowConsortiumModal: false,
      showAltNameModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "consortiums":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} consortium selected`
              : `${fieldValues.length} consortiums selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "No consortium selected"
              : "Select consortiums";
        }
        break;
      
      case "alt_names":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} alternative name`
              : `${fieldValues.length} alternative names`
        } else {
          labelText = "No alternative name"
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState((prevState) => {
        prevState.user = Object.assign(this.state.org, { parent: item && item.id });
        prevState.orgsError = false;
        return prevState;
      })
    
    } 
    
  };
  onChange = (value) => {
    let data = this.props.orgs && this.props.orgs.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState((prevState) => {
        prevState.user = Object.assign(this.state.org, { parent: data && data.id});
        return prevState;
      });
     
    } else {
      this.setState((prevState) => {
        prevState.user = Object.assign(this.state.org, { parent:null});
        return prevState;
      });
      if (value === "") {
        this.setState({
          orgsError: false,
          org_name: value

        })
      } else {
        this.setState({
          orgsError: true,
          org_name: value

        })
      }
    }
  };

  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      let filterParams = {
        domain: this.state.domain_name ?  this.state.domain_name: (this.props.org_details && this.props.org_details.domain),
        q: value,
      }
      let params = serialize(filterParams);
      await this.props.hubgetOrgList(params);
      this.setState({
        dataList: this.props.orgs && this.props.orgs.map(item => ({
          id: item.id,
          value: item.name,
        }))
      })
    }, 200);
  };

  onSaveAltName = (altNames) => { 
    let org = this.state.org
    
    Object.assign(org, {
      alt_names: altNames,
    })

    this.setState({
      org,
      alt_names: altNames,
      showAltNameModal: false,
    })
  }

  render() { 

    let { showAltNameModal } = this.state

    let addresses = (
      
        <div className="addresses" >
          <div className="form-group row">
            <SelectBox
              label="Country"
              onChange={this.onChangeSelectAddressHandlers}

              field="country"
              isRequired={true}
              data={this.props.countries}
              defaultValue={
                this.state.org.address && this.state.org.address.country
              }
            />

            <InputNewBox
              label="County/state"
              onChange={this.onChangeAddressHandlers}
              field="state_name"
              placeholder="Enter state name"
              defaultValue={
                this.state.org.address && this.state.org.address.state_name
              }
            />
          </div>
          <div className="form-group row">
            <InputNewBox
              label="City"
              onChange={this.onChangeAddressHandlers}
              field="city_name"
              placeholder="Enter city name"
              defaultValue={
                this.state.org.address && this.state.org.address.city_name
              }
            />
            <InputNewBox
              label="Address 1"
              isRequired={false}
              onChange={this.onChangeAddressHandlers}
              field="address1"
              placeholder="Address 1"
              defaultValue={
                this.state.org.address && this.state.org.address.address1
              }
            />
          </div>
          <div className="form-group row">
            <InputNewBox
              label="Address 2"
              onChange={this.onChangeAddressHandlers}
              field="address2"
              placeholder="Address 2"
              defaultValue={
                this.state.org.address && this.state.org.address.address2
              }
            />
            <InputNewBox
              label="Post/Zip code"
              onChange={this.onChangeAddressHandlers}
              field="postal_code"
              placeholder="post code"
              defaultValue={
                this.state.org.address && this.state.org.address.postal_code
              }
            />
          </div>
        </div>
      )
      let userGroupKey =
        this.props.group_permission && this.props.group_permission.permissible_objects
    return (
      this.props.isSingleOrgLoading!== true ? 
        
       <div className="tab-content">
  
          <div className="form-group row">
            <SelectBox
              label="Group"
              onChange={this.onChangeGroupHandler}
              field="group"
              data={this.props.groups}
              defaultValue={this.state.org.domain}
            />
            <div className="col-md-4">
              {this.state.org.kind && (
                <Checkbox
                  defaultChecked={this.state.org.kind ? this.state.org.kind : !!this.state.org.kind}
                  onChange={this.onChangeChecked}
                >
                  Is society?
                </Checkbox>
              )}

              {!this.state.org.kind && (
                <Checkbox
                  defaultChecked={!!this.state.org.kind}
                  onChange={this.onChangeChecked}
                >
                  Is society?
                </Checkbox>
              )}
            </div>
          </div>
          <div className="form-group row">
            <InputNewBox
              label="Organization name"
              onChange={this.onChangeHandler}
              field="name"
              placeholder="Enter Organisation Name"
              defaultValue={this.state.org.name}
            />
                    <div className="col-md-2">
                      <label>Parent Orgs<span style={{ color: "red", paddingLeft: "5px" }}>*</span></label>
                    </div>

                    <div className="col-md-4 search-compelete">
                      <div className="search-auto-complete">
                {
               this.state.parent_name !== null ? this.props.org_id && this.state.parent_name && <AutoComplete
                  defaultValue={ this.state.parent_name}
                  notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}
                  placeholder="Type your institution name and select from the drop down"

                  options={this.state.dataList}
                  style={{
                    width: "100%",
                  }}
                  onSelect={(val, option) => this.onSelect(val, option)}
                  onSearch={this.onSearch}
                  onChange={this.onChange}
                />:<AutoComplete
                defaultValue={this.state.parent_name}
                notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}
                placeholder="Type your institution name and select from the drop down"
                options={this.state.dataList}
                style={{
                  width: "100%",
                }}
                onSelect={(val, option) => this.onSelect(val, option)}
                onSearch={this.onSearch}
                onChange={this.onChange}
              />
                }
                

                {
                  typeof(this.props.org_id) !== "string" &&  <AutoComplete
                  defaultValue={ this.state.parent_name}
                  notFoundContent={this.state.orgs_name && "The institution name you have typed has no match on OaMetrix."}
                  placeholder="Type your institution name and select from the drop down"

                  options={this.state.dataList}
                  style={{
                    width: "100%",
                  }}
                  onSelect={(val, option) => this.onSelect(val, option)}
                  onSearch={this.onSearch}
                  disabled={this.state.domain_name ? false:true}
                  onChange={this.onChange}
                />
               }
                
                
                    
                      <div className="search-auto-spin">
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>

                      {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
                  </span>}
              </div>
              </div>

          
            

          </div>
          {/* <div className="form-group row">
            <InputNewBox
              label="Name in other language"
              field="name_1_other_lang"
              placeholder="Name in other language if any"
              onChange={this.onChangeAltNameHandler}
              defaultValue={this.getAltName(0)}
            />
            <InputNewBox
              label="Name in other language"
              onChange={this.onChangeAltNameHandler}
              field="name_2_other_lang"
              placeholder="name in other language if any"
              defaultValue={this.getAltName(1)}
              disabled={this.getAltName(0) === ""}
            />
          </div> */}

          <div className="form-group row">
            <InputNewBox
              label="Email domain"
              onChange={this.onChangeHandler}
              field="email_domain"
              className="col-sm-4"
              placeholder="enter your org domain: i.e. @ox.ac.uk"
              isRequired={true}
              defaultValue={this.state.org.email_domain}
            />
            <label className=" col-form-label col-sm-2">Consortiums </label>
            <div className="col-sm-4 py-2" style={{ position: "relative" }}>
              {this.showSelectItemsLabel("consortiums")}
              <button
                onClick={() => this.onModalShow("consortiums")}
                className="btn btn-info waves-effect waves-light float-right"
                type="button"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>

            {this.state.isShowConsortiumModal && (
              <ModalSelectBox
                title="Select consortiums"
                label="Consortiums"
                labelClass="col-sm-3"
                className="col-sm-5"
                field="consortiums"
                data={
                  (this.props.consortium_list && [
                    ...this.props.consortium_list,
                  ]) ||
                  []
                }
                defaultValue={this.state.consortiums}
                isRequired={false}
                submitBtnTxt="Save"
                onSubmit={this.onSaveSelectedItems}
                onClose={() => this.onModalClose()}
              />
            )}
          </div>

          <div className="form-group row">
            
            <label className=" col-form-label col-sm-2">Alternative names </label>
            <div className="col-sm-4 py-2" style={{ position: "relative" }}>
              {this.showSelectItemsLabel("alt_names")}
              <button
                onClick={() => this.onModalShow("alt_names")}
                className="btn btn-info waves-effect waves-light float-right"
                type="button"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>

            {showAltNameModal && (
              <AlternativeNameForm 
                showAltNameModal={showAltNameModal}
                onModalClose={this.onModalClose}
                onSaveAltName={this.onSaveAltName}
                alt_names={this.state.alt_names}
              />
            )}
          </div>

          {addresses}

          <div className="form-group row">
            <InputNewBox
              label="Website"
              onChange={this.onChangeHandler}
              field="website"
              placeholder="enter your website"
              defaultValue={this.state.org.website}
            />
         {   this.state.org.ror_id&& <label className=" col-form-label col-sm-2">ROR ID</label>}
           { this.state.org.ror_id&& <div className="col-sm-4 py-2" style={{ position: "relative" }}>
              {this.state.org.ror_id || "-"}
            </div>
  }
          </div>
          <div className="form-group row">
            <InputBox
              label="GRID no."
              field="grid_number"
              placeholder="GRID number"
              onChange={this.onChangeMetaHandler}
              defaultValue={
                this.state.org.meta && this.state.org.meta.grid_number
              }
            />
            <InputBox
              label="Ringgold"
              field="ring_gold_number"
              placeholder="Ringgold number"
              onChange={this.onChangeMetaHandler}
              defaultValue={
                this.state.org.meta && this.state.org.meta.ring_gold_number
              }
            />
          </div>
          <div className="form-group row">
            <InputBox
              label="ISNI no."
              field="isni_number"
              placeholder="ISNI"
              onChange={this.onChangeMetaHandler}
              defaultValue={
                this.state.org.meta && this.state.org.meta.isni_number
              }
            />
            <InputNewBox
              label="Note"
              field="note"
              placeholder="Note"
              onChange={this.onChangeHandler}
              defaultValue={this.state.org.note || ""}
            />
          </div>

          {this.props.msg && (
            <div className="form-group row">
              <span className="alert alert-success col-sm-12">
                {this.props.msg}
              </span>
            </div>
          )}

          
          

          {((this.state.permissions && this.state.permissions && this.state.permissions.length === 0) ||
(this.state.permissions &&this.state.permissions &&this.state.permissions.length >0) ) &&
              <UserPermission
                auth={this.props.auth}
                userId={this.props.auth.user.id}
                permissible_objects={userGroupKey}
                permissions={this.state.permissions}
                onSubmitHandler={this.onSubmitHandler}
              /> 
  }
  {!this.props.org_id&& userGroupKey&& userGroupKey.length>0&& 
    <UserPermission
              auth={this.props.auth}
              userId={this.props.auth.user.id}
              permissible_objects={userGroupKey}
              permissions={this.state.permissions}
              onSubmitHandler={this.onSubmitHandler}
            />
  }
     
      </div> :null

    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.app.groups,
  orgs: state.app.organizations,
  countries: state.app.countries,
  geoLocation: state.app.geoLocation,
  org_details: state.orgs.org_single,
  isSingleOrgLoading:state.orgs.isSingleOrgLoading,
  state_list: state.orgs.state_list,
  publishers: state.orgs.publisher_list || [],
  Messages: {},
  consortium_list:
    (state.consortia.consortium_list &&
      state.consortia.consortium_list.results) ||
    [],
    group_permission: state.orgs.orgGroupPermission || false,

});

const mapDispatchToProps = (dispatch) => ({
  getRoleList: () => dispatch(getRoleList()),
  getGroupList: () => dispatch(getGroupList()),
  hubgetOrgList: (payload) => dispatch(hubgetOrgList(payload)),
  getConsortiumList: (payload) => dispatch(getConsortiumList(payload)),
  getAllCountry: () => dispatch(getAllCountry()),
  getAllState: (payload) => dispatch(getAllState(payload)),
  getAllCity: (payload) => dispatch(getAllCity(payload)),
  singleOrg: (id) => dispatch(singleOrg(id)),
  getGroupPermission:(id)=>dispatch(getGroupPermission(id))


});

export const OrgForm = connect(mapStateToProps, mapDispatchToProps)(Form);
