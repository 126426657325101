import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { createGroup, ClearStatus, getParentGroupListTable,getUserPermissionList } from '../../../../actions';
import { PopupBox, Loading, InputBox, SelectBox } from '../../../../../core/components/common';
import UserPermission from "./usergroupPermission";

class Add extends BaseComponent {
    constructor(props) {
        super(props)
        this.status = 100;
        this.state = {
            group: {},
        }
    
    }

    async componentDidMount() {
        await this.props.getParentGroupListTable();
        let userPermissionList = await this.props.getUserPermissionList(
             this.props.auth && this.props.auth && this.props.auth.user.id
          );

        this.setState({
            userPermissionList
        })

    }

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.group, value);
        })
    }

    onSubmitHandler = async (value) => {
        let data = {...this.state.group,permissions:value.checkedList};
        if(!data.parent || !data.name  ){
            this.setState({
                isEmpty: <div>
                    {!data.parent && <p>Parent group must be selected</p>} 
                    {!data.name && <p>Group name must be filled</p>} 
                </div>
            })
        }else{
             await this.props.createGroup(data);
             this.props.history.push('/group/group-list')
        }
    }

    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false
        })
        await this.props.ClearStatus()
    }


   render() {

        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Bad Request"
                msg="Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>
      let userGroupKey =[
         {
            "label":"Classic Dashboard",
            "key":"manage_dashboard",
            "hint":"Allow users view classic dashboard "
         },
         {
            "label":"Premium Dashboard",
            "key":"manage_premium_dashboard",
            "hint":"Allow users view premium dashboard "
         },
         {
            "label":"Institutions",
            "key":"manage_institutions",
            "hint":"Allow users view institutions Menu"
         },
         {
            "label":"Article & Books",
            "key":"manage_articles_books",
            "hint":"Allow user view article & books"
         },
         {
            "label":"Failed Articles",
            "key":"manage_failed_articles",
            "hint":" Allow users view failed articles  "
         },
         {
            "label":"Add Articles",
            "key":"manage_create_articles",
            "hint":"Allow user add articles "
         },
         {
            "label":"Add Book Series",
            "key":"manage_create_book_series",
            "hint":"Allow users add book series"
         },
         {
            "label":"Add Books",
            "key":"manage_books",
            "hint":"Allow user create/edit Books"
         },
         {
            "label":"Add Journals",
            "key":"manage_journals",
            "hint":"Allow user create/edit journals"
         },
         {
          "label":"OA deals",
          "key":"manage_oa_deals",
          "hint":"allow user perform oa-deal-activities"
         },
         {
            "label":"Create Token",
            "key":"manage_token",
            "hint":"Allow user create/edit token"
         },
         {
          "label":"Token list",
          "key":"manage_token_list",
          "hint":"Allow user view token list"
       },
         {
            "label":"Add Allocate Offset",
            "key":"manage_offset",
            "hint":"Allow user add allocate offset"
         },
         {
          "label":"Allocate Offset List",
          "key":"manage_offset_list",
          "hint":"Allow user view allocate offset List"
       },
         {
            "label":"Inbox email",
            "key":"manage_email_inbox",
            "hint":"Allow users view email inbox"
         },
         {
            "label":"Outbox email",
            "key":"manage_email_outbox",
            "hint":"Allow user view email send"
         },
         {
            "label":"Non OA Request",
            "key":"manage_non_oa_request",
            "hint":"Allow user view Non-OA Request"
         },
         {
            "label":"Deposit Fund",
            "key":"manage_deposit_fund",
            "hint":"Allow user manage Deposit fund"
         },
         {
            "label":"Transactions Token",
            "key":"manage_transations_token",
            "hint":"Allow user view transations token"
         },
         {
            "label":"Card Payment",
            "key":"manage_card_payment",
            "hint":"Allow users view card payment transactions"
         },
         {
            "label":"Invoice",
            "key":"manage_transatction_invoice",
            "hint":" Allow users view invoices"
         },
         {
            "label":"Add Society Fellows",
            "key":"manage_society_fellows",
            "hint":"Allow user add society fellows"
         },
         {
            "label":"Society Fellows List",
            "key":"manage_society_fellows_list",
            "hint":"Allow user view society fellows list"
         },
         {
            "label":"Affiliated Organistations",
            "key":"manage_affiliated_orgs",
            "hint":"Allow user view affiliated organistations"
         },
    
         {
            "label":"Button label config",
            "key":"manage_btn_labels",
            "hint":"allow user configure buttons"
         },
         {
            "label":"Organisation",
            "key":"manage_orgs_labels",
            "hint":"Allow user view organisation"
         },

           {
              "label":"Allow user update payment",
              "key":"manage_update_payment"
           },
           {
              "label":"Allow user cancel invoice",
              "key":"manage_cancel_invoice"
           },

         {
            "label":"Article Metadata Transfer ",
            "key":"manage_metadata_transfer",
            "hint":"Allow user manage article metadata transfer"
         },
         {
            "label":"Dashboard Configuration",
            "key":"manage_dashboard_config",
            "hint":"Allow user manage article dashboard transfer"
         },
         {
            "label":"Invoice & Payment Reminder ",
            "key":"manage_invoice_reminder",
            "hint":"Allow user manage article invoice & Payment Reminder"
         },
         {
            "label":"Author Notification Request ",
            "key":"manage_metadata_author_notification",
            "hint":"Allow user manage article author notification Request"
         },
           {
              "label":"Manage Users",
              "key":"manage_users",
              "hint":"Allow create/edit/delete users"
           },
           {
              "label":"Manage Invoice",
              "key":"manage_invoice",
              "hint":"Allow user manage invoice"
           },
           {
              "label":"Manage Terms & Conditions",
              "key":"manage_terms",
              "hint":"Allow user to create/update/delete terms & conditions"
           },
           {
              "label":"Manage Vat",
              "key":"manage_vat",
              "hint":"Allow user manage configure vat"
           },
           {
              "label":"Manage Apps",
              "key":"manage_apps",
              "hint":"Allow user manage manage apps"
           },
           {
              "label":"Manage Emails",
              "key":"manage_emails",
              "hint":"Allow user manage configure customized emails"
           },

         {
            "label":"Manage Society Discount",
            "key":"manage_society_discount",
            "hint":"Allow user manage Society discount"
         },
           {
              "label":"Manage Geographical Discount",
              "key":"manage_discount",
              "hint":"Allow user  manage geographical discount"
           },
           {
            "label":"Manage membership Discount",
            "key":"manage_membership_discount",
            "hint":"Allow user manage membership discount"
         },
           
   
           {
              "label":"Manage Licence control",
              "key":"manage_licence_control",
              "hint":"Allow user control author emails and licence"
           },
           {
            "label":"Manage APC Waiver",
            "key":"manage_apc_waiver",
            "hint":"Allow user manage APC waiver"
         },
         {
            "label":"Waiver Policies",
            "key":"manage_waiver",
            "hint":"Allow user manage manage waiver policies"
         },
         {
            "label":"Publishers",
            "key":"manage_publishers",
            "hint":"Allow user  Publishers"
             },
         {
            "label":"Add Consortium Member",
            "key":"manage_constoria_members",
            "hint":"Allow create/edit/delete members"
          },
          {
            "label":"Consortium Member List",
            "key":"manage_constoria_members_list",
            "hint":"Allow Consortium members View List"
         }
      ]
       
     let uniGroupKey =[
         {
            "label":"Basic Dashboard",
            "key":"manage_dashboard",
            "hint":"Allow user Dashboard "
        },
        {
         "label":"Premium Dashboard",
         "key":"manage_premium_dashboard",
         "hint":"Allow user Dashboard "
      },
         {
            "label":"Institutions",
            "key":"manage_institutions",
            "hint":"Allow user Institutions "
         },
            {
            "label":"OA Account List",
            "key":"manage_oa_account",
            "hint":"Allow user OA Account List "
         },
         {
            "label":"Settings",
            "key":"manage_settings",
            "hint":"Allow user settings "
         },
         {
            "label":"Inbox email",
            "key":"manage_email_inbox",
            "hint":"Allow user Inbox Email"
         },
         {
            "label":"Outbox email",
            "key":"manage_email_outbox",
            "hint":"Allow user outbox Email"
         },
         {
            "label":"Apc Request List",
            "key":"manage_apc_request",
            "hint":"Allow user NON OA Request"
         },
         {
            "label":"Deposit Fund",
            "key":"manage_deposit_fund",
            "hint":"Allow user manage Deposit fund"
         },
         {
            "label":"Transactions Token",
            "key":"manage_transations_token",
            "hint":"Allow user Transations Token"
         },
         {
            "label":"Card Payment",
            "key":"manage_card_payment",
            "hint":"Allow user Card Payment"
         },
         {
            "label":"Invoice",
            "key":"manage_transatction_invoice",
            "hint":"Allow user invoice"
         },
   
         {
            "label":"Affiliated Organistations",
            "key":"manage_affiliated_orgs",
            "hint":"Allow user Affiliated Organistations"
         },

           {
            "label":"OA deals List",
            "key":"manage_oa_deals_List",
            "hint":"Allow user manage oa deals activities"
         },
           {
              "label":"Allow user update payment",
              "key":"manage_update_payment"
           },
           {
              "label":"Allow user cancel invoice",
              "key":"manage_cancel_invoice"
           },
           {
              "label":"Offset Fund",
              "key":"manage_offset_fund",
              "hint":"Allow user create/edit token"
           },
           {
            "label":"Token list",
            "key":"manage_token_list",
            "hint":"Allow user token List"
         },

           {
              "label":"Manage Users",
              "key":"manage_users",
              "hint":"Allow create/edit/delete organisation users"
           },
           {
              "label":"Manage Invoice",
              "key":"manage_invoice",
              "hint":"Allow user to manage invoice"
           },
          ]
          let consGroupKey =[
            {
               "label":"Classic Dashboard",
               "key":"manage_dashboard",
               "hint":"Allow user Dashboard "
           },
           {
            "label":"Premium Dashboard",
            "key":"manage_premium_dashboard",
            "hint":"Allow user Dashboard "
         },
         {
            "label":"Articles & Books",
            "key":"manage_articles_books",
            "hint":"Allow user  articles & Books"
             },
             {
               "label":"Publishers",
               "key":"manage_publishers",
               "hint":"Allow user  Publishers"
                },
             {
               "label":"Add Consortium Member",
               "key":"manage_constoria_members",
               "hint":"Allow create/edit/delete members"
             },
             {
               "label":"Consortium Member List",
               "key":"manage_constoria_members_list",
               "hint":"Allow Consortium members View List"
            },
              {
                 "label":"Manage Users",
                 "key":"manage_users",
                 "hint":"Allow create/edit/delete organisation users"
              },
          
             ]
          let userKeyList = this.state.parent ===5 ? consGroupKey : this.state.parent ===2 ? userGroupKey : this.state.parent===3 ? uniGroupKey :userGroupKey
      return (
            <HubContent title="Add group form">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                {/* LoadinG Handling */}
                {this.state.isRegistering && !this.state.status && <Loading />}
                {/* LoadinG Handling */}
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                              
                                <div className="form-group row" style={{marginTop:20}}>
                                    {this.props.group_top && <SelectBox
                                        label="Parent group"
                                        onChange={this.onChangeHandler}
                                        field='parent'
                                        className="col-sm-4"
                                        labelClass="col-sm-2"
                                        data={this.props.group_top.results} 
                                        defaultValue={this.state.parent}
                                        isRequired={true}   
                                    />}
                                        <InputBox
                                            label="Group name"
                                            onChange={this.onChangeHandler}
                                            field='name'
                                            placeholder="Name of the group"
                                            className="col-sm-4"
                                            labelClass="col-sm-2"
                                        />
                                </div>

                                    {
         
                                <UserPermission
                auth={this.props.auth}
                userId={this.props.auth.user.id}
                permissible_objects={userKeyList}
                permissions={this.props.permissions}
                onSubmitHandler={this.onSubmitHandler}
              />
         
        }
                                  
           
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    group_top: state.group.parent_group_list || false,
    auth: state.auth ,
    userPermissionList: state.userPermission.userPermissionList,

})

const mapDispatchToProps = (dispatch) => ({
    createGroup: (payload) => dispatch(createGroup(payload)),
    getParentGroupListTable: () => dispatch(getParentGroupListTable()),
    ClearStatus: () => dispatch(ClearStatus()),
    getUserPermissionList: (id) => dispatch(getUserPermissionList(id)),

})


export const AddGroup = connect(mapStateToProps, mapDispatchToProps)(Add);
