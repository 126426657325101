import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { getOaDisciplineList, deleteOaDiscipline } from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";

class UL extends BaseComponent {
  state = {
    data: [],
  };
  getOaDisciplineListData = async (pageNum = 1, page = 10) => {
    await this.props.getOaDisciplineList({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.oa_disciplines &&this.props.oa_disciplines.results,
      count: this.props.oa_disciplines.count,
    });
  };
  async componentDidMount() {
    this.getOaDisciplineListData();
  }
  pageChange = (pageNumber) => {
    this.getOaDisciplineListData(pageNumber);
  };

  onEditDiscipline = (id, data) => {
    this.props.history.push("/disciplines/edit-discipline/" + id, {
      discipline: data,
    });
  };

  onDeleteDiscipline = (id) => {
    confirmAlert({
      title: "",
      message: "Please confirm to delete",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteOaDiscipline(id).then((res) => {
              if (res.status) {
                this.props.history.push("/disciplines/discipline-list");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const heading = ["name", "description"];

    return (
      <HubContent title="OA discipline list">
        <div className="row">
          <div className="col-12">
            <Link
              to="/disciplines/add-discipline"
              className="btn btn-primary float-right mb-2"
            >
              Add Discipline
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditDiscipline}
                  onDelete={this.onDeleteDiscipline}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,

  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
});

const mapDispatchToProps = (dispatch) => ({
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  deleteOaDiscipline: (id) => dispatch(deleteOaDiscipline(id)),
});

export const DisciplineList = connect(mapStateToProps, mapDispatchToProps)(UL);
