import config, {
  GET_LICENCE_ALL_LIST,
  GET_LICENCE_PDFPDFDWNLOAD,
  GET_TERMS_PDFDWNLOAD,
  UPDATE_LICENCE_LIST,
  SINGLE_TERMS_CONDITION_LIST,
  SINGLE_LICENCE_LIST,
  GET_TERMS_CONDITION_LIST,
  REQUEST_GET_TERMS_CONDITION_LIST,
  UPDATE_TERMS_CONDITION_LIST,
  POST_ADD_LICENSE,
  GET_ALL_POST_LICENSE,
  POST_TERMS_CONDITION,
  DELETE_ALL_POST_LICENSE,
  DELETE_ALL_POST_TERMS,
  ORGANISATION_LICENSE_AGREEMENTS,
  ORGANISATION_LICENSE_DOWNLOAD,
  ARTICLE_FULL_LICENCE_UPDATE,
  REQUEST_ALL_POST_LICENSE,
  REQUEST_LICENCE_ALL_LIST,
  REQUEST_SINGLE_LICENSE_ACTION,
  PRICING_LICENCE_LIST,
} from "../../config";
import { api } from "../../core/api";
export const requestLicencesListAction = () => ({
  type: REQUEST_LICENCE_ALL_LIST,
});
export const successLicencesListAction = (payload) => ({
  type: GET_LICENCE_ALL_LIST,
  payload,
});
export const SingleLicence = (payload) => ({
  type: SINGLE_LICENCE_LIST,
  payload,
});
export const LicenceupdateList = (payload) => ({
  type: UPDATE_LICENCE_LIST,
  payload,
});
export const requestTermsandConditionListAction = () => ({
  type: REQUEST_GET_TERMS_CONDITION_LIST,
});
export const successTermsandConditionListAction = (payload) => ({
  type: GET_TERMS_CONDITION_LIST,
  payload,
});
export const UpdateTermsandCondition = (payload) => ({
  type: UPDATE_TERMS_CONDITION_LIST,
  payload,
});
export const SingleCondition = (payload) => ({
  type: SINGLE_TERMS_CONDITION_LIST,
  payload,
});
export const termsdownload = (payload) => ({
  type: GET_TERMS_PDFDWNLOAD,
  payload,
});

export const licenseDownload = (payload) => ({
  type: GET_LICENCE_PDFPDFDWNLOAD,
  payload,
});

export const PostLicense = (payload) => ({
  type: POST_ADD_LICENSE,
  payload,
});
export const PostTerms = (payload) => ({
  type: POST_TERMS_CONDITION,
  payload,
});
export const requestLicenseAction = () => ({
  type: REQUEST_ALL_POST_LICENSE,
});
export const successLicenseAction = (payload) => ({
  type: GET_ALL_POST_LICENSE,
  payload,
});

export const deletelicense = (payload) => ({
  type: DELETE_ALL_POST_LICENSE,
  payload,
});
export const deleteterms = (payload) => ({
  type: DELETE_ALL_POST_TERMS,
  payload,
});
export const organistaionLicenceAgreements = (payload) => ({
  type: ORGANISATION_LICENSE_AGREEMENTS,
  payload,
});

export const organistaionLicenceDownload = (payload) => ({
  type: ORGANISATION_LICENSE_DOWNLOAD,
  payload,
});

export const articleFullLicenseUpdate = (payload) => ({
  type: ARTICLE_FULL_LICENCE_UPDATE,
  payload,
});

export const requestSingleLicenceAction = (payload) => ({
  type: REQUEST_SINGLE_LICENSE_ACTION,
  payload,
});

export const getPricingLicenceAction = (payload) => ({
  type: PRICING_LICENCE_LIST,
  payload,
});

// Organisation licence agreements
export const OrganisationLicenseAgreements = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.organisation_licence + "?" + payload;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(organistaionLicenceAgreements(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
// Organisation licence agreements
export const OrganisationLicenseDownload = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.organisation_licence}${payload.licence_id}/download?${payload.author_id}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(organistaionLicenceDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Create CreateLicense Message
export const CreateLicense = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.licence_update;
    return new Promise((resolve, reject) => {
      dispatch({ type: "REQUEST_POSTS" });
      api
        .post(url, payload)
        .then((res) => {
          dispatch(PostLicense(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch({ type: "INVALID_POSTS" });
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Create CreateLicense Message
export const CreateTermsAndCondition = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.terms;
    return new Promise((resolve, reject) => {
      dispatch({ type: "REQUEST_POSTS" });
      api
        .post(url, payload)
        .then((res) => {
          dispatch(PostLicense(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch({ type: "INVALID_POSTS" });
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getAllLicenceDataList = () => {
  return (dispatch) => {
    let url = config.endpoint.license;
    dispatch(requestLicencesListAction());
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(successLicencesListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const deleteLicenseMessage = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.licence_update + id + "/";

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
export const singleLicenceList = (id) => {
  return (dispatch) => {
    const url = `${config.endpoint.licence_update}${id}`;
    dispatch(requestSingleLicenceAction());

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(SingleLicence(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const UpdateLicense = (id, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .put(config.endpoint.licence_update + id + "/", payload)
        .then((res) => {
          // dispatch(UpdateTermsandCondition(res.data));
          // dispatch(LicenceupdateList({success:true}))
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          // dispatch(failedCreateOaDealReducer(error.response.data))
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};
export const LicenceDownloadList = (id) => {
  return (dispatch) => {
    const url = `${config.endpoint.licence_update}${id}/download`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(licenseDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getTermsAndConditionlist = () => {
  return (dispatch) => {
    dispatch(requestTermsandConditionListAction());

    return new Promise((resolve, reject) => {
      api
        .get(config.endpoint.terms)
        .then((res) => {
          dispatch(successTermsandConditionListAction(res));
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const UpdateTermsandCondtionlist = (id, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .put(config.endpoint.terms + id + "/", payload)
        .then((res) => {
          dispatch(UpdateTermsandCondition(res.data));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};
export const deleteTermsConditionsMessage = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.terms + id + "/";

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getAllLicenseList = (id) => {
  return (dispatch, getState) => {
    dispatch(requestLicenseAction());

    return new Promise((resolve, reject) => {
      api
        .get(config.endpoint.licence_terms + `?licence=${id}`)
        .then((res) => {
          dispatch(successLicenseAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

export const singleTermsandCondition = (id) => {
  return (dispatch) => {
    const url = `${config.endpoint.terms}${id}`;
    dispatch(requestLicenseAction());

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(SingleCondition(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const TermsandConditionDownloadList = (payload) => {
  let url = payload.url;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(termsdownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const articleFullLicenceUpdate = (id, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .put(
          config.endpoint.article_full_licence_update +
            id +
            "/mutate/update-article-licence/",
          payload
        )
        .then((res) => {
          dispatch(articleFullLicenseUpdate(res.data));
          // dispatch(LicenceupdateList({success:true}))
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          // dispatch(failedCreateOaDealReducer(error.response.data))
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

// Get Pricing Licence List
export const getPricingLicenceList = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.pricing_licence + "?page_size=99999";
    
    if (payload && payload.article_id) {
      url = `${url}&article_id=${payload.article_id}`
    }

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getPricingLicenceAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
