import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { Button } from "../../../../../core/components/common";

import { getMembershipDiscount } from "../../../../actions";
import { Skeleton } from "antd";
import moment from "moment";

class ViewTable extends BaseComponent {
  state = {
    info: null
  };

  async componentDidMount() {
    let id = this.props.match.params.id;

    try {
      await this.props.getMembershipDiscount(id);
      this.setState({
        info: this.props.single_membership
      });
    } catch (e) {

    }
  }

  dateFormat = date => {
    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  };

  priceFormat = price => {
    let dataPrice = Number(price).toFixed(2);
    return dataPrice;
  };

  renderListItems = data => {
    if (data && data.length > 0) {
      return data.map(item => item.name).join(", ");
    }

    return "";
  };
  amountFormatting = (offerAmount, offerType) => {
    if (offerType === "DISCOUNT") {
      return `${offerAmount}%`;
    } else {
      return offerAmount;
    }
  };

  renderDetails = () => {
    let info = this.props.single_membership;

    return (
      info && (
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.push("/membership-discounts/member-discount-list/")}
                />
              </div>
              <div className="card-body row">
                <div className="col-sm-12">
                  <h3 className="oadeal-view-heading">Membership Discount </h3>
                  <table style={styles.table}>
                    {/******** User Basic Info *******/}
                    <tbody>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Institution</td>
                        <td style={styles.td2}>
                          {this.props.single_membership.society_name}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Currency</td>
                        <td style={styles.td2}>
                          {this.props.single_membership.offer_currency_name
                            ? this.props.single_membership.offer_currency_name
                            : "-"}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Membership fees</td>
                        <td style={styles.td2}>
                          {this.props.single_membership.membership_fees}
                        </td>
                      </tr>

                      <tr style={styles.tr}>
                        <td style={styles.td}>Discount type</td>
                        <td style={styles.td2}>
                          {this.props.single_membership.offer_type}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Currency(Offer)</td>
                        <td style={styles.td2}>
                          {this.props.single_membership
                            .membership_fees_currency === 3
                            ? "GBP"
                            : this.props.single_membership
                              .membership_fees_currency === 2
                              ? "EUR"
                              : "USD"}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Amount/Discount</td>
                        <td style={styles.td2}>
                          {this.amountFormatting(
                            this.props.single_membership.offer_amount,
                            this.props.single_membership.offer_type
                          )}
                          {/* {this.priceFormat(
                            this.props.single_membership.offer_amount != null
                              ? this.props.single_membership.offer_amount
                              : "-"
                          )} */}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Expiry</td>
                        <td style={styles.td2}>
                          {this.props.single_membership.offer_expiry !== null
                            ? this.dateFormat(
                              this.props.single_membership.offer_expiry
                            )
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row" style={{ padding: 15 }}>
                  <div className="col-md-4">
                    <label className="oadeal-view">Disciplines</label>
                    {this.props.single_membership.discipline_details.length > 0
                      ? this.renderListItems(
                        this.props.single_membership.discipline_details
                      )
                      : `All disciplines`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Subjects </label>
                    {this.props.single_membership.subject_details.length > 0
                      ? this.renderListItems(
                        this.props.single_membership.subject_details
                      )
                      : `All subjects`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Publication types </label>
                    {this.props.single_membership.publication_type_details
                      .length > 0
                      ? this.renderListItems(
                        this.props.single_membership.publication_type_details
                      )
                      : `All publication types`}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Publications </label>
                    {this.props.single_membership.publication_details.length
                      .length > 0
                      ? this.renderListItems(
                        this.props.single_membership.publication_details
                      )
                      : `All publications`}{" "}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Content types</label>
                    {this.props.single_membership.content_type_details.length >
                      0
                      ? this.renderListItems(
                        this.props.single_membership.content_type_details
                      )
                      : `All content types`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <HubContent title="Membership discount details">
        {this.state.info ? (
          this.renderDetails()
        ) : (
            <Skeleton active paragraph={{ row: 10 }} />
          )}
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  single_membership: state.membershipDiscount.single_members
});

const mapDispatchToProps = dispatch => ({
  getMembershipDiscount: id => dispatch(getMembershipDiscount(id))
});

export const MemberDiscountView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTable);

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%"
  },
  tr: {
    marginTop: 20,
    verticalAlign: "baseline"
  },
  td: {
    paddingTop: 10,
    color: "rgb(0, 122, 206)"
  },
  td2: {
    paddingTop: 10,
    width: "75%"
  }
};
