import React from "react";
import { DragAndDrop, JournalForm } from "../../../partials";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { connect } from "react-redux";
import {
  ClearStatus,
  getOaDisciplineList,
  journalFileUpload,
  trackJournalFileUploadStatus,
  CreatePublication,
  trackArticleFileUploadStatusDownload,
} from "../../../../actions";
import { PopupBox, Loading } from "../../../../../core/components/common";

class Journal extends BaseComponent {
  state = {
    journal: {},
    isEmpty: false,
    isLoading: false,
    fileUploadStatus: null,
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.journal, value);
    });
  };

  onSubmitHandler = async (journal) => {
   
    if (
      !journal.journal_type ||
      !journal.publication_type ||
      !journal.publisher ||
      !journal.name
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!journal.journal_type && <p>Journal Type must be selected</p>}
            {!journal.publication_type && (
              <p>Publication Type must be selected</p>
            )}
            {!journal.publisher && <p>Publisher must be selected</p>}
            {!journal.name && <p>Journal Name must be filled</p>}
          </div>
        ),
      });
    } else {
      await this.props.CreatePublication(journal);
      if (this.props.user_group === "HUB") {
        this.props.history.push("/journals/journals-list/");
      } else {
        this.props.history.push("/journal/journals-list/");
      }
    }
  };

  async componentDidMount() {
    this.props.getOaDisciplineList();
  }

  OnChangeTextBox(value, name) {
    const state = {};
    state[name] = value;
    this.setState(state);
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  organisationFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });

    await this.props.organisationFileUpload(payload);
    if (this.props.organisation_file_upload.status) {
      let payload = {
        upload_status: this.props.organisation_file_upload.data.upload_status.slice(
          1
        ),
      };
      this.setState({
        upload_payload: payload,
      });
      await this.props.trackOrganisationFileUploadStatus(payload);
    }
    if (this.props.track_upload_status) {
      let upload_id = this.props.track_upload_status.id;
      let upload_download_status = `file-upload-error-response/${upload_id}/`;
      let payloadDownload = {
        upload_status: upload_download_status,
      };
      await this.props.trackArticleFileUploadStatusDownload(payloadDownload);
    }

    this.setState({
      fileUploadStatus:
        this.props.organisation_file_upload.status ||
        this.props.organisation_file_upload.status === 200
          ? true
          : false,
      fileUploadStatusMsg: this.props.organisation_file_upload.data.message,
      isLoading: false,
    });
  };

  journalFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });
    try {
      await this.props.journalFileUpload(payload);
      let msg = this.props.journal_file_upload.data.message;

      this.setState({
        fileUploadStatus: this.props.journal_file_upload.status,
        isEmpty: msg,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        fileUploadStatus: null,
        isEmpty: "Upload failed!",
        isLoading: false,
      });
    }
  };

  onFileDrop = (payload) => {
    this.journalFileUpload(payload);
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return (
      <HubContent title="Add journal form">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        {/* Loader */}
        {this.state.isLoading && <Loading />}
        {/* Loader */}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Enter journal details
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload journal details
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <JournalForm
                      onSubmitHandler={this.onSubmitHandler}
                      OnChangeTextBox={this.OnChangeTextBox.bind(this)}
                      msg={this.state.msg}
                    />
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <div className="m-b-30">
                      <DragAndDrop onDropHandler={this.onFileDrop} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.orgs.failed || false,
  token: state.auth.token || false,
  journal_file_upload: state.journals.journal_file_upload || false,
  track_upload_status: state.journals.track_upload_status || false,
  track_upload_status_download:
    state.articles.track_upload_status_download || false,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  user_group: state.auth && state.auth.group,
});
const mapDispatchToProps = (dispatch) => ({
  ClearStatus: () => dispatch(ClearStatus()),
  CreatePublication: (payload) => dispatch(CreatePublication(payload)),
  journalFileUpload: (payload) => dispatch(journalFileUpload(payload)),
  trackJournalFileUploadStatus: (payload) =>
    dispatch(trackJournalFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) =>
    dispatch(trackArticleFileUploadStatusDownload(payload)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
});
export const AddJournal = connect(mapStateToProps, mapDispatchToProps)(Journal);
