//DATA TABLE HEADING
//COlUMN
const column = [
  "col-md-1",
  "col-md-2",
  "col-md-3",
  "col-md-4",
  "col-md-5",
  "col-md-6",
  "col-md-7",
  "col-md-8",
  "col-md-9",
  "col-md-10",
  "col-md-11",
  "col-md-12",
];

//Heading Features

let trxDepositHeading = [
  {
    name: "Institution/Publisher",
    id: "1",
    column: column[2],
  },
  {
    name: "Transaction type",
    id: "2",
    column: column[1],
  },
  {
    name: "Currency",
    id: "3",
    column: column[1],
  },
  {
    name: "Total",
    id: "4",
    column: column[1],
  },
  {
    name: "Grant number",
    id: "5",
    column: column[1],
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
  },
];

let trxTokenHeading = [
  {
    name: "Institution/Publisher",
    id: "1",
    column: column[2],
  },
  {
    name: "Token ID",
    id: "2",
    column: column[1],
  },
  {
    name: "Currency",
    id: "3",
    column: column[1],
  },
  {
    name: "Dis/ Amount",
    id: "4",
    column: column[1],
  },
  {
    name: "Article ID",
    id: "5",
    column: column[1],
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
  },
];

let trxInvoiceHeading = [
  {
    name: "Billing Address",
    id: "1",
    column: column[2],
  },
  {
    name: "Invoice number",
    id: "2",
    column: column[1],
  },
  {
    name: "Currency",
    id: "3",
    column: column[1],
  },
  {
    name: "Amount",
    id: "4",
    column: column[1],
  },
  {
    name: "Invoice status",
    id: "5",
    column: column[1],
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
  },
];

let authorInvoiceHeading = [
  {
    name: "Publisher",
    id: "1",
    column: column[2],
  },
  {
    name: "Invoice number",
    id: "2",
    column: column[1],
  },
  {
    name: "Currency",
    id: "3",
    column: column[1],
  },
  {
    name: "Amount",
    id: "4",
    column: column[1],
  },
  {
    name: "Invoice status",
    id: "5",
    column: column[1],
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
  },
];

let trxCardHeading = [
  {
    name: "Inst/Author/Sub/Journal",
    id: "1",
    column: column[2],
    props: "institution",
  },
  {
    name: "Author",
    id: "2",
    column: column[1],
    props: "author",
  },
  {
    name: "Currency",
    id: "3",
    column: column[1],
    props: "currency",
  },
  {
    name: "Amount",
    id: "4",
    column: column[1],
    props: "amount",
  },
  {
    name: "Payment status",
    id: "5",
    column: column[1],
    props: "",
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
    props: "",
  },
];
let publisheHeading = [
  {
    name: "Publisher",
    id: "1",
    column: column[2],
    props: "publisher",
  },
  {
    name: "Author",
    id: "2",
    column: column[1],
    props: "author",
  },
  {
    name: "Currency",
    id: "3",
    column: column[1],
    props: "currency",
  },
  {
    name: "Amount",
    id: "4",
    column: column[1],
    props: "amount",
  },
  {
    name: "Payment status",
    id: "5",
    column: column[1],
    props: "",
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
    props: "",
  },
];

let oaTokenHeading = [
  {
    name: "Inst/Author/Sub/Journal",
    id: "1",
    column: column[2],
    props: "assigned_organisation_name",
  },
  {
    name: "Reference",
    id: "2",
    column: column[1],
    props: "reference",
  },
  {
    name: "Cur/Percent",
    id: "3",
    column: column[1],
    props: "currency",
  },
  {
    name: "Amt/Dis",
    id: "4",
    column: column[1],
    props: "amount",
  },
  {
    name: "Token ID",
    id: "5",
    column: column[1],
    props: "token_id",
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
    props: "",
  },
];
let dealDepositHeading = [
  {
    name: "Institution",
    id: "1",
    column: column[2],
    props: "institution",
  },
  {
    name: "Oa Deal",
    id: "2",
    column: column[1],
    props: "oa_deal",
  },
  {
    name: "currency",
    id: "3",
    column: column[1],
    props: "currency",
  },
  {
    name: "Amount",
    id: "4",
    column: column[1],
    props: "amount",
  },
  {
    name: "Oa Deal",
    id: "5",
    column: column[1],
    props: "oa_deal",
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
    props: "",
  },
];

let journalPriceHeading = [
  {
    name: "Content type",
    id: "1",
    column: column[2],
    props: "content_type_name",
  },
  {
    name: "APC fee gbp",
    id: "2",
    column: column[1],
    props: "APC_fee_gbp",
  },
  {
    name: "APC fee eur",
    id: "3",
    column: column[1],
    props: "APC_fee_eur",
  },
  {
    name: "APC fee usd",
    id: "4",
    column: column[1],
    props: "APC_fee_usd",
  },
  {
    name: "More details",
    id: "5",
    column: column[1],
    props: "more_details",
  },
];

let MemberHeading = [
  {
    name: "Orgs",
    id: "1",
    column: column[2],
    props: "Orgs",
  },
  {
    name: "Deal Name",
    id: "2",
    column: column[1],
    props: "deal_name",
  },
  {
    name: "Currency",
    id: "3",
    column: column[1],
    props: "currency",
  },
  {
    name: "Discount",
    id: "4",
    column: column[1],
    props: "discount",
  },
  {
    name: "More details",
    id: "5",
    column: column[1],
    props: "more_details",
  },
];
const EmailHeading = [
  {
    name: "Date",
    id: "1",
    column: column[1],
    props: "date",
  },

  {
    name: "To",
    id: "5",
    column: column[2],
    props: "to",
  },

  {
    name: "Subject",
    id: "3",
    column: column[5],
    props: "subject",
  },

  {
    name: "View",
    id: "6",
    column: column[0],
    props: "email",
  },
];
const apcPubRequestUnivHeading = [
  {
    name: "Date",
    id: "1",
    column: column[1],
    props: "date",
  },
  {
    name: "Title",
    id: "3",
    column: column[2],
    props: "title",
  },

  {
    name: "Author Name",
    id: "6",
    column: column[1],
    props: "author_name",
  },
  {
    name: "Licence",
    id: "4",
    column: column[0],
    props: "licence",
  },
  {
    name: "Text",
    id: "5",
    column: column[0],
    props: "action",
  },
  {
    name: "Action",
    id: "5",
    column: "col-md-3 action-text-center",
    props: "action",
  },
];
const apcPubRequestHeading = [
  {
    name: "Date",
    id: "1",
    column: column[1],
    props: "date",
  },
  {
    name: "Title",
    id: "3",
    column: column[2],
    props: "title",
  },

  {
    name: "Author Name",
    id: "6",
    column: column[1],
    props: "author_name",
  },
  {
    name: "Licence",
    id: "4",
    column: column[1],
    props: "licence",
  },

  {
    name: "Email",
    id: "5",
    column: column[0],
    props: "action",
  },
  {
    name: "Action",
    id: "5",
    column: column[1],
    props: "action",
  },
];
const apcRequestHeading = [
  {
    name: "Date",
    id: "1",
    column: column[1],
    props: "date",
  },
  {
    name: "Title",
    id: "3",
    column: column[4],
    props: "title",
  },
  {
    name: "Author Name",
    id: "6",
    column: column[1],
    props: "author_name",
  },
  {
    name: "Licence",
    id: "4",
    column: column[1],
    props: "licence",
  },

  {
    name: "Action",
    id: "5",
    column: column[0],
    props: "action",
  },
];

const authoApcRequestHeading = [
  {
    name: "Date",
    id: "1",
    column: column[1],
    props: "date",
  },
  {
    name: "Category",
    id: "2",
    column: column[1],
    props: "category",
  },
  {
    name: "Title",
    id: "3",
    column: column[2],
    props: "title",
  },
  {
    name: "Organisation",
    id: "7",
    column: column[2],
    props: "organisation_name",
  },
  {
    name: "Licence",
    id: "4",
    column: column[0],
    props: "licence",
  },

  {
    name: "Action",
    id: "6",
    column: column[0],
    props: "author_name",
  },
];

const authorEmail = [
  {
    name: "To",
    id: "1",
    column: column[2],
    props: "recipients",
  },
  {
    name: "Subject",
    id: "3",
    column: column[6],
    props: "subjects",
  },

  {
    name: "Date",
    id: "5",
    column: column[0],
    props: "action",
  },
];
const authorSenderEmail = [
  {
    name: "From",
    id: "1",
    column: column[2],
    props: "recipients",
  },
  {
    name: "Subject",
    id: "3",
    column: column[6],
    props: "subjects",
  },

  {
    name: "Date",
    id: "5",
    column: column[0],
    props: "action",
  },
];

let requestHeading = [
  {
    name: "Request By",
    id: "1",
    column: column[2],
  },
  {
    name: "User",
    id: "2",
    column: column[1],
  },
  {
    name: "Orgs",
    id: "3",
    column: column[1],
  },
  {
    name: "Date",
    id: "4",
    column: column[1],
  },
  {
    name: "Article",
    id: "5",
    column: column[1],
  },
  {
    name: "Details",
    id: "6",
    column: column[0],
  },
];
export {
  requestHeading,
  EmailHeading,
  trxInvoiceHeading,
  trxCardHeading,
  trxTokenHeading,
  trxDepositHeading,
  apcRequestHeading,
  authoApcRequestHeading,
  oaTokenHeading,
  dealDepositHeading,
  journalPriceHeading,
  column,
  MemberHeading,
  authorEmail,
  publisheHeading,
  authorInvoiceHeading,
  authorSenderEmail,
  apcPubRequestHeading,
  apcPubRequestUnivHeading,
};
