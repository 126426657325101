import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import BaseComponent, {
  HubContent,
  DataTable,
} from "../../../../core/components";
import { DepositPubTrxnOffset, OffsetpubTrxnDownload } from "../../../actions";
import {
  Loading,
  // SelectBox,
  // InputBox
} from "../../../../core/components/common";
import { serialize } from "../../../../core/lib";

class TransactionOffset extends BaseComponent {
  state = {
    filterParams: { page: 1 },
  };
  componentDidMount() {
    this.getOffsetFund();
  }

  getOffsetFund = async (pageNum = 1, page = 10) => {
    await this.props.OffsetPubTrxn({ pageNum: pageNum, pageSize: page });
  };

  pageChange = (pageNumber) => {
    this.getOffsetFund(pageNumber);
  };

  OffsetpubTrxnDownloadClick = async () => {
    try {
      await this.props.offsetpubTrxnDownload();
      const url = window.URL.createObjectURL(this.props.offset_fund_download);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `${this.props.group.toLowerCase()}-offset-fund-transaction-${moment(
          d
        ).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  filterData = async (value) => {
    await this.setState((prevState) => {
      prevState.filterParams = Object.assign(prevState.filterParams, value);
    });
    let filter = await serialize(this.state.filterParams);
    await this.props.OffsetPubTrxn(filter);
  };

  render() {
    let { offset_pub } = this.props;
    const offset = offset_pub && offset_pub.results;

    let heading = [
      "date",
      "publisher",
      "institution",
      "currency",
      "total",
      "article",
    ];
    let data = [];

    if (offset) {
      if (this.props.group === "PUBLISHER") {
        offset.map((trx) => {
          return data.push({
            ...trx,
            date: moment(trx.date_created, "DD-MM-YYYY").format(),
            institution: trx.institution_name,
            publisher: trx.publisher_name,
            currency: trx.currency_name,
            total: trx.amount,
            article: trx.article ? trx.article : "-",
          });
        });
      } else {
        offset.map((trx) => {
          return data.push({
            ...trx,
            date: moment(trx.date_created, "DD-MM-YYYY").format(),
            institution: trx.institution_name,
            publisher: trx.publisher_name,
            currency: trx.currency_name,
            total: trx.amount,
            article: trx.article ? trx.article : "-",
          });
        });
      }
    }

    return (
      <HubContent
        title="Transaction - offset fund"
        onArticleDownloadClickHandler={this.OffsetpubTrxnDownloadClick}
     
      >
        <div className="row filter">
          <div className="col-md-8">
            <div className="row"></div>
          </div>
          <div className="col-12">
          
            <div className="card m-b-20">
              {offset ? (
                <DataTable
                  heading={heading}
                  data={data}
                  noAction={true}
                  pageChange={this.pageChange}
                  pageSize={10}
                  count={offset_pub.count}
                />
              ) : (
                  <Loading type="flat" />
                )}
            
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  offset_pub: state.trxn.offset_pub,
  offset_fund_download: state.trxn.offset_fund_download,
  group: (state.auth && state.auth.group) || false,
});

const mapDispatchToProps = (dispatch) => ({
  OffsetPubTrxn: (payload) => dispatch(DepositPubTrxnOffset(payload)),
  offsetpubTrxnDownload: () => dispatch(OffsetpubTrxnDownload()),
});

export const TrxOffset = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionOffset);
