import React from "react";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";

class PubList extends BaseComponent {
  render() {
    return (
      <HubContent title="Publisher List">
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <DataTable
                heading={["first_name", "last_name", "role"]}
                data={[
                  {
                    id: "1",
                    first_name: "University",
                    last_name: "Hub",
                    action: "A",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

export const PublisherList = PubList;
