import config, {
  GET_ALL_VAT_PUBLISHER_ACTION,
  GET_SINGLE_VAT_PUBLISHER_ACTION,
  UPDATE_VAT_PUBLISHER_ACTION,
  GET_DELETE_VAT_PUBLISHER_ACTION,
  CREATE_VAT_PUBLISHER_ACTION,
  REQUEST_VAT_PUBLISHER_ACTION,
  US_STATE_VAT_PUB,
  CA_STATE_VAT_PUB,
  GET_COUNTRY_VAT_PUB,
  CREATE_VAT_PUB,
  VAT_LIST,
  GET_SINGLE_VAT_CONFIG,
  CREATE_VAT_CONFIG,
  GET_VAT_HUB,
  UPDATE_VAT_HUB,
  GET_SINGLE_VAT_HUB,
  CREATE_UPDATE_VAT,
  CREATE_VAT_HUB,
  GET_VAT,
  CREATE_UPDATE_VAT_CONFIG,
  GET_VAT_CONFIG,
  REQUEST_UPDATE_VAT_PUBLISHER_ACTION,
  GET_UPDATE_SINGLE_VAT_PUBLISHER_ACTION,
  REQUEST_CREATE_CONFIG_ACTION,
  REQUEST_VAT_CONFIGURE,
} from "../../config";
import { api } from "../../core/api";

export const vatListAction = (payload) => ({
  type: VAT_LIST,
  payload,
});

export const createUpdateVatAction = (payload) => ({
  type: CREATE_UPDATE_VAT,
  payload,
});

export const getVatAction = (payload) => ({
  type: GET_VAT,
  payload,
});

export const createUpdateVatConfigAction = (payload) => ({
  type: CREATE_UPDATE_VAT_CONFIG,
  payload,
});
export const getVatConfigAction = (payload) => ({
  type: GET_VAT_CONFIG,
  payload,
});
export const createVatConfigAction = (payload) => ({
  type: CREATE_VAT_CONFIG,
  payload,
});
export const getSingleVatConfigAction = (payload) => ({
  type: GET_SINGLE_VAT_CONFIG,
  payload,
});

export const createVatHubConfigAction = (payload) => ({
  type: CREATE_VAT_HUB,
  payload,
});

export const UpdateVatHubConfigAction = (payload) => ({
  type: UPDATE_VAT_HUB,
  payload,
});
export const getSingleVatHubConfigAction = (payload) => ({
  type: GET_SINGLE_VAT_HUB,
  payload,
});
export const getVatHubConfigAction = (payload) => ({
  type: GET_VAT_HUB,
  payload,
});
export const createVatPubConfigAction = (payload) => ({
  type: CREATE_VAT_PUB,
  payload,
});

export const getStateVatPubConfigAction = (payload) => ({
  type: US_STATE_VAT_PUB,
  payload,
});

export const getStateCAVatPubConfigAction = (payload) => ({
  type: CA_STATE_VAT_PUB,
  payload,
});

export const getVatCountryRateConfigAction = (payload) => ({
  type: GET_COUNTRY_VAT_PUB,
  payload,
});
export const publisherVatCreateConfigureAction = (payload) => ({
  type: CREATE_VAT_PUBLISHER_ACTION,
  payload,
});
export const publisherVatCreateConfigureRequestAction = (payload) => ({
  type: REQUEST_VAT_PUBLISHER_ACTION,
});

export const publisherGetAllVatCreateConfigureAction = (payload) => ({
  type: GET_ALL_VAT_PUBLISHER_ACTION,
  payload,
});
export const publisherGetSingleVatCreateConfigureAction = (payload) => ({
  type: GET_SINGLE_VAT_PUBLISHER_ACTION,
  payload,
});
export const publisherDeleteVatCreateConfigureAction = (payload) => ({
  type: GET_DELETE_VAT_PUBLISHER_ACTION,
  payload,
});
export const publisherUpdateVatCreateConfigureAction = (payload) => ({
  type: UPDATE_VAT_PUBLISHER_ACTION,
  payload,
});
export const getPublisherVatRequest = (payload) => ({
  type: REQUEST_UPDATE_VAT_PUBLISHER_ACTION,
});
export const getSinglePublisherVat = (payload) => ({
  type: GET_UPDATE_SINGLE_VAT_PUBLISHER_ACTION,
});

export const requestCreateConfigureAction = (payload) => ({
  type: REQUEST_CREATE_CONFIG_ACTION,
});
export const requestgetVatConfigure = () => ({
  type: REQUEST_VAT_CONFIGURE,
});

// Create vat
export const createUpdateVat = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.vat;
    let apiRequest = "";
    return new Promise((resolve, reject) => {
      if (id) {
        url += `${id}/`;
        apiRequest = api.put(url, payload);
      } else {
        apiRequest = api.post(url, payload);
      }

      apiRequest
        .then((res) => {
          dispatch(createUpdateVatAction(res.data));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get vat list list all
export const getVatList = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.vat;

    if (payload.pageNum && payload.pageSize) {
      url += `?page=${payload.pageNum}&page_size=${payload.pageSize}`;
    } else if (payload.pageNum) {
      url += `?page=${payload.pageNum}`;
    } else if (payload.pageSize) {
      url += `?page_size=${payload.pageSize}`;
    }

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(vatListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get vat
export const getVaT = (id) => {
  return (dispatch) => {
    let url = config.endpoint.vat + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getVatAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Delete vat
export const deleteVaT = (id) => {
  return (dispatch) => {
    let url = config.endpoint.vat + `${id}/`;
    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create vat
export const createUpdateVatConfig = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.vat_config + `${payload.id}/`;
    // let apiRequest = '';
    return new Promise((resolve, reject) => {
      // if (id) {
      //     url += `${id}/`;
      //     apiRequest = api.put(url, payload);
      // } else {
      //     apiRequest = api.post(url, payload);
      // }

      api
        .put(url, payload)
        .then((res) => {
          dispatch(createUpdateVatConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const DeleteVatConfig = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.vat_config + `${payload}/`;
    // let apiRequest = '';
    return new Promise((resolve, reject) => {
      // if (id) {
      //     url += `${id}/`;
      //     apiRequest = api.put(url, payload);
      // } else {
      //     apiRequest = api.post(url, payload);
      // }

      api
        .delete(url)
        .then((res) => {
          // dispatch(createUpdateVatConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create vat
export const createVatConfig = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.vat_config;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createVatConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getVatConfig = () => {
  return (dispatch) => {
    let url = config.endpoint.vat_config;
    return new Promise((resolve, reject) => {
      dispatch(requestgetVatConfigure());

      api
        .get(url)
        .then((res) => {
          dispatch(getVatConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getSingleVatConfig = (id) => {
  return (dispatch) => {
    let url = config.endpoint.vat_config + `${id}/`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getSingleVatConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create vat For Hub
export const createVatConfigHub = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.vat_hub;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createVatHubConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
export const getVatHubConfig = () => {
  return (dispatch) => {
    let url = `${config.endpoint.vat_hub}?page_size=999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getVatHubConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getSingleHubVatConfig = (id) => {
  return (dispatch) => {
    let url = config.endpoint.vat_hub + `${id}/`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getSingleVatConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const createUpdateHubVatConfig = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.vat_hub + `${id}/`;
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(getVatHubConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Delete vat
export const deleteHubVaT = (id) => {
  return (dispatch) => {
    let url = config.endpoint.vat_hub + `${id}/`;
    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create vat For Pub
export const createVatConfigPub = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.vat_hub;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createVatPubConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getVatRatesPubVatConfig = (payload) => {
  return (dispatch) => {
    let url;
    // let url = `${config.endpoint.vat_pub}?context=${payload.eucontext}&exclude=${payload.country}`

    url = payload.country
      ? `${config.endpoint.vat_pub}?id=${payload.country}&page_size=99999`
      : payload.eucontext
      ? `${config.endpoint.vat_pub}?context=${payload.eucontext}&exclude=${payload.id}&page_size=99999`
      : `${config.endpoint.vat_pub}?context=${payload.context}&page_size=99999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getVatCountryRateConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getStatePubVatConfig = (payload) => {
  return (dispatch) => {
    let url;

    url = `${config.endpoint.state_vat}?parent=${payload}&page_size=99999`;

    // url = payload.state_vat ? `${config.endpoint.vat_hub}?country=${payload.country}`: `${config.endpoint.vat_hub}?context=${payload.context}`
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getStateVatPubConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getCAStatePubVatConfig = (payload) => {
  return (dispatch) => {
    let url;

    url = `${config.endpoint.state_vat}?parent=${payload}&page_size=99999`;

    // url = payload.state_vat ? `${config.endpoint.vat_hub}?country=${payload.country}`: `${config.endpoint.vat_hub}?context=${payload.context}&exclude=`
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getStateCAVatPubConfigAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Publisher Vat Configuartion
export const publisherVatCreateConfigure = (payload) => {
  return (dispatch, getState) => {
    dispatch(publisherVatCreateConfigureRequestAction());

    let url = config.endpoint.publish_vat_config;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(publisherVatCreateConfigureAction(res));
          resolve(Object.assign(res.data, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const publisherGetAllVatCreateConfigure = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.publish_vat_config;
    dispatch(requestCreateConfigureAction());
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(publisherGetAllVatCreateConfigureAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
export const getCountryAllHandler = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.country + "?page_size=9999";
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(publisherGetAllVatCreateConfigureAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// getCountryHandler = (token) => {
//     let url = config.endpoint.country+'?page_size=9999';
//     let accessToken = 'token ' + token;
//     return this.callAPI(url, 'get', {}, { headers: { Authorization: accessToken } })
// }

export const publisherGetSingleVatCreateConfigure = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.publish_vat_config + `${id}/`;
    return new Promise((resolve, reject) => {
      dispatch(getSinglePublisherVat());
      api
        .get(url)
        .then((res) => {
          dispatch(publisherGetSingleVatCreateConfigureAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const publisherUpdateVatCreateConfigure = (payload, id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.publish_vat_config + `${id}/`;
    dispatch(getPublisherVatRequest());
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(publisherUpdateVatCreateConfigureAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const publisherDeleteVatCreateConfigure = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.publish_vat_config + `${id}/`;
    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          dispatch(publisherDeleteVatCreateConfigureAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
