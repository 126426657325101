import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent
} from "../../../../../core/components";
import {
  getCorrectionRequested,
  getCorrectiondownload
} from "../../../../actions";
import {
  ErrorHandler,
  Loading
  // SelectBox,
  // InputBox
} from "../../../../../core/components/common";
import moment from "moment";
import { UnivRoot } from "../../../../data";
import { history } from "../../../../route";

const format = "Do MMM YYYY";

class RL extends BaseComponent {
  state = {
    error: null,
    errorInfo: null
  };

  async componentDidMount() {
    try {
      await this.props.getCorrectionRequested();
    } catch (e) {
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  onView = (id, data) => {
    history.push(UnivRoot.action + "/correction-list/" + id, {
      ...data
    });
  };
  onCorrectionDownloadClick = async () => {
    try {
      await this.props.getCorrectiondownload();
      const url = window.URL.createObjectURL(this.props.correction_data);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `correction_list_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
    }
  };

  render() {
    const heading = ["request_date", "request_to", "article"];
    const { correction_list } = this.props;
    let data =
      correction_list &&
      correction_list.map(d => {
        return {
          id: d.id,
          request_date: moment(d.request_date).format(format),
          request_to: d.request_to,
          article: d.article_title,
          note: d.note
        };
      });

    return this.state.error ? (
      <ErrorHandler />
    ) : (
        <HubContent
          title="Correction list"
          onArticleDownloadClickHandler={this.onCorrectionDownloadClick}
        >
          <div className="row filter">
          
            <div className="col-12">
            
                {correction_list ? (
                  <DataTable
                    heading={heading}
                    data={data}
                    onView={this.onView}
                    wrap={true}
                  />
                ) : (
                    <Loading type="flat" />
                  )}
               
            </div>
          </div>
        </HubContent>
      );
  }
}

const mapStateToProps = state => ({
  correction_list: state.articles.correction_list,
  correction_data: state.articles.correction_data
});

const mapDispatchToProps = dispatch => ({
  getCorrectionRequested: () => dispatch(getCorrectionRequested()),
  getCorrectiondownload: () => dispatch(getCorrectiondownload())
});

export const CorrectionList = connect(mapStateToProps, mapDispatchToProps)(RL);
