import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";

// import { SelectBox, InputBox } from "../../../../../core/components/common";
import {
  getOrganisationDealFeatureList,
  deleteOrganisationDealFeature,
} from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";

class UL extends BaseComponent {
  state = {
    data: [],
  };

  getOrganisationDealFeatureListData = async (pageNum = 1, page = 10) => {
    await this.props.getOrganisationDealFeatureList({
      pageNum: pageNum,
      pageSize: page /* has_df_only: true, */,
    });

    this.setState({
      data: this.props.organisation_deal_features.results,
      count: this.props.organisation_deal_features.count,
    });
  };

  async componentDidMount() {
    this.getOrganisationDealFeatureListData();
  }

  pageChange = (pageNumber) => {
    this.getOrganisationDealFeatureListData(pageNumber);
  };

  onEditOrgDealFeature = (id) => {
    this.props.history.push("/org-deal-features/edit/" + id);
  };

  onDeleteOrgDealFeature = (id) => {
    confirmAlert({
      title: "",
      message: "Please confirm to delete",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteOrganisationDealFeature(id).then((res) => {
              if (res.status) {
                this.props.history.push("/org-deal-features/list");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const heading = ["organisation_name", "deal_feature"];

    return (
      <HubContent title="Organisation deal feature list">
        <div className="row filter-md-4">
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count >= 0 && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditOrgDealFeature}
                  // onDelete={this.onDeleteOrgDealFeature}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,

  organisation_deal_features:
    state.OrganisationDealFeature.organisation_deal_features || false,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganisationDealFeatureList: (payload) =>
    dispatch(getOrganisationDealFeatureList(payload)),
  deleteOrganisationDealFeature: (id) =>
    dispatch(deleteOrganisationDealFeature(id)),
});

export const OrganisationDealFeatureList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UL);
