import React from "react";

import {
  SelectBox,
  InputBox,
  PopupBox,
} from "../../../../../../core/components/common";
class Prices extends React.Component {
  state = {
    isShow: false,
    content_type: this.props.content_type,
    content_type_taken: {},
    prices: {},
    selected_array: this.props.selected_array,
    isEmpty: false,
  };

  componentDidMount() {

    this.props.value &&
      this.setState({
        prices: this.props.value,
      });
  }


  onChangeHandler = (value) => {
    this.setState((prevState) => {
      prevState.prices = {
        ...prevState.prices,
        ...value,
      };

      return prevState;
    });
  };

  onChangeContentHandler = (value) => {
    this.setState((prevState) => {
      prevState.prices = {
        ...prevState.prices,
        ...value,
      };
      return prevState;
    });
  };

  onCloseModal = (e) => {
    let overlay = document.getElementById("CoAuthorDialog");
    if (e.target === overlay) {
      this.props.onClose();
    }
  };

  onSavePrices = (e) => {
    let { prices } = this.state;
    if (!prices.content_type) {
      this.setState({
        isEmpty: (
          <div>
            {!prices.content_type && <p>Content Type Field is required!!</p>}
          </div>
        ),
      });
    } else {
      if (Object.entries(prices).length === 0) {
        alert("fields are empty");
      } else {
        let content_type_name = this.state.content_type && this.state.content_type.find(item => item.id === this.state.prices.content_type)
        Object.assign(this.state.prices, {
          content_type_name: content_type_name.name
        })
        let data = {
          ...this.state.prices,
          colour_charge_eur:
            this.state.prices && this.state.prices.colour_charge_eur
              ? this.state.prices.colour_charge_eur
              : 0,
          colour_charge_gbp:
            this.state.prices && this.state.prices.colour_charge_gbp
              ? this.state.prices.colour_charge_gbp
              : 0,
          colour_charge_usd:
            this.state.prices && this.state.prices.colour_charge_usd
              ? this.state.prices.colour_charge_usd
              : 0,
          other_charge_eur:
            this.state.prices && this.state.prices.other_charge_eur
              ? this.state.prices.other_charge_eur
              : 0,
          other_charge_gbp:
            this.state.prices && this.state.prices.other_charge_gbp
              ? this.state.prices.other_charge_gbp
              : 0,
          other_charge_usd:
            this.state.prices && this.state.prices.other_charge_usd
              ? this.state.prices.other_charge_usd
              : 0,
          page_charge_eur:
            this.state.prices && this.state.prices.page_charge_eur
              ? this.state.prices.page_charge_eur
              : 0,
          page_charge_gbp:
            this.state.prices && this.state.prices.page_charge_gbp
              ? this.state.prices.page_charge_gbp
              : 0,
          page_charge_usd:
            this.state.prices && this.state.prices.page_charge_usd
              ? this.state.prices.page_charge_usd
              : 0,
          pub_fee_usd:
            this.state.prices && this.state.prices.pub_fee_usd
              ? this.state.prices.pub_fee_usd
              : 0,
          pub_fee_gbp:
            this.state.prices && this.state.prices.pub_fee_gbp
              ? this.state.prices.pub_fee_gbp
              : 0,
          pub_fee_eur:
            this.state.prices && this.state.prices.pub_fee_eur
              ? this.state.prices.pub_fee_eur
              : 0,
          submission_fee_eur:
            this.state.prices && this.state.prices.submission_fee_eur
              ? this.state.prices.submission_fee_eur
              : 0,
          submission_fee_gbp:
            this.state.prices && this.state.prices.submission_fee_gbp
              ? this.state.prices.submission_fee_gbp
              : 0,
          submission_fee_usd:
            this.state.prices && this.state.prices.submission_fee_usd
              ? this.state.prices.submission_fee_usd
              : 0,
        };

        this.props.onSavePrices(data);
      }
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  render() {

    let { prices } = this.state;

    let CoAuthorInfo = prices && (
      <div className="addresses">
        <div className="form-group row">
          <SelectBox
            label="Article type"
            onChange={this.onChangeContentHandler}
            field="content_type"
            labelClass="col-md-3"
            className="col-sm-9"
            data={this.props.content_type}
            placeholder="Select article type"
            isData={true}
            defaultValue={prices.content_type}
            isRequired={true}
          />

        </div>
        <div className="form-group row">
          <InputBox
            label="APC charge fees"
            onChange={this.onChangeHandler}
            field="pub_fee_gbp"
            labelClass="col-sm-3"
            className="col-sm-3"
            placeholder="GBP"
            defaultValue={prices.pub_fee_gbp}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="pub_fee_eur"
            className="col-sm-3"
            placeholder="EUR"
            defaultValue={prices.pub_fee_eur}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="pub_fee_usd"
            className="col-sm-3"
            placeholder="USD"
            defaultValue={prices.pub_fee_usd}
            InputType="number"
          />
        </div>
        <div className="form-group row">
          <InputBox
            label="Submission charge fees"
            onChange={this.onChangeHandler}
            field="submission_fee_gbp"
            labelClass="col-sm-3"
            className="col-sm-3"
            placeholder="GBP"
            defaultValue={prices.submission_fee_gbp}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="submission_fee_eur"
            className="col-sm-3"
            placeholder="EUR"
            defaultValue={prices.submission_fee_eur}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="submission_fee_usd"
            className="col-sm-3"
            placeholder="USD"
            defaultValue={prices.submission_fee_usd}
            InputType="number"
          />
        </div>
        <div className="form-group row">
          <InputBox
            label="Colour charge fees"
            onChange={this.onChangeHandler}
            field="colour_charge_gbp"
            className="col-sm-3"
            labelClass="col-sm-3"
            placeholder="GBP"
            defaultValue={prices.colour_charge_gbp}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="colour_charge_eur"
            className="col-sm-3"
            placeholder="EUR"
            defaultValue={prices.colour_charge_eur}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="colour_charge_usd"
            className="col-sm-3"
            placeholder="USD"
            defaultValue={prices.colour_charge_usd}
            InputType="number"
          />
        </div>
        <div className="form-group row">
          <InputBox
            label="Page charge fees"
            onChange={this.onChangeHandler}
            field="page_charge_gbp"
            className="col-sm-3"
            labelClass="col-sm-3"
            placeholder="GBP"
            defaultValue={prices.page_charge_gbp}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="page_charge_eur"
            className="col-sm-3"
            placeholder="EUR"
            defaultValue={prices.page_charge_eur}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="page_charge_usd"
            className="col-sm-3"
            placeholder="USD"
            defaultValue={prices.page_charge_usd}
            InputType="number"
          />
        </div>
        <div className="form-group row">
          <InputBox
            label="Other charge fees"
            onChange={this.onChangeHandler}
            field="other_charge_gbp"
            className="col-sm-3"
            labelClass="col-sm-3"
            placeholder="GBP"
            defaultValue={prices.other_charge_gbp}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="other_charge_eur"
            className="col-sm-3"
            placeholder="EUR"
            defaultValue={prices.other_charge_eur}
            InputType="number"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="other_charge_usd"
            className="col-sm-3"
            placeholder="USD"
            defaultValue={prices.other_charge_usd}
            InputType="number"
          />
        </div>
      </div>
    );

    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return this.props.isShow ? (
      <div
        onClick={this.onCloseModal}
        id="CoAuthorDialog"
        style={modal.overlay}
      >
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className={`col-10 col-lg-8 bg-white alert`} style={modal.boxStyle}>
          {CoAuthorInfo}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.props.onClose && this.props.onClose();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onSavePrices}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    ) : (
        ""
      );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    padding: 35,
    transform: "translate(0px, 20px)",
    height: boxHeight,
  },
};



export default Prices;
