import React from 'react';
import { connect } from 'react-redux';
import { HubContent } from '../../../../../core/components';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { DataTable } from '../../../../../core/components';
import { Loading, PopupBox } from '../../../../../core/components/common';
import { createConsortiaMember, getConsortiaMembersList } from '../../../../actions';



class ConsortiumMembers extends BaseComponent {
    state = {
        data: null,
        member_ids: [],
    }

    ids = [];
    onSelect = (id) => {
        let index = this.ids.indexOf(id);
        (index !== -1) ? this.ids.splice(index, 1) : this.ids.push(id);
        this.setState({
            member_ids: this.ids
        })
    }

    getConsortiaMemberData = async (pageNum = 1, page = 10) => {
        await this.props.getConsortiaMembersList({ pageNum: pageNum, pageSize: page });
        this.setState({
            data: this.props.consortia_list.results,
            count: this.props.consortia_list.count
        })
    }

    pageChange = (pageNumber) => {
        this.getConsortiaMemberData(pageNumber);
    }


    onSubmitHandler = async (e) => {
        e.preventDefault();
        let params = {
            id: this.props.organisation_id,
            member: {
                mode: 'remove',
                members: this.state.member_ids
            }
        }
        if (this.state.member_ids.length < 1) {
            this.setState({
                isEmpty: <div>
                    <p>Please select consortium</p>
                </div>
            })
        } else {
            await this.props.createConsortiaMember(params);
            this.props.history.push('/consortium/consortium-member-list')
        }
    }

     componentDidMount() {
       this.apiDataList()
    } 
    apiDataList =async () => {
        try {
            await this.props.getConsortiaMembersList({ pageNum: 1, pageSize: 10 });
            let orgObj = this.props.consortia_list.results || [];
            let dataOrg = [];
            orgObj && orgObj.map((item) => {
                return dataOrg.push({
                    organization_name: item.name,
                    email_domain: item.email_domain,
                    grid_number: item.meta ? item.meta.grid_number : '',
                    country: item.address ? item.address.country_name : '',
                    id: item.id
                });
            })
            this.setState({
                data: dataOrg,
            });
        } catch (error) {
            this.log(error);
        }

    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
    }
    

    cancelLoader = async () => {
        this.setState({
            isEmpty: false
        })
    }

    render() {
        this.log(this.state.member_ids, 'memberIds memberIds', this.props.count);
        const heading = ['organization_name', 'email_domain', 'grid_number', 'country'];
        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>
        return (
            <HubContent title="Consortium members">
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}
                <div className="row">
                    
                    <div className="col-lg-12">
                        
                        <div className="card m-b-20">
                        <div className=" m-t-15" style={{ marginBottom: 20 ,marginLeft:10}}>
                                <button onClick={this.onSubmitHandler} type="button" className="btn btn-primary waves-effect waves-light">Remove members</button>
                            </div>
                            {this.state.data ?
                                <DataTable
                                    heading={heading}
                                    data={this.state.data || []}
                                    noAction={true}
                                    onSelect={this.onSelect}
                                    checkbox={true}
                                    count={this.props.count}
                                    pageChange={this.pageChange}
                                    pageSize={10}
                                /> : <Loading type="flat" />
                            }
                         
                        </div>
                    </div>
                </div>

            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    consortia_list: state.consortia.consortia_list || false,
    count: state.consortia.consortia_list ? state.consortia.consortia_list.count : false || false,
    user_id: state.auth ? state.auth.user.id : false,
    organisation_id: state.auth && state.auth.user ? state.auth.user.organisation_id : false,
})

const mapDispatchToProps = (dispatch) => ({
    getConsortiaMembersList: (payload) => dispatch(getConsortiaMembersList(payload)),
    createConsortiaMember: (payload) => dispatch(createConsortiaMember(payload)),
})

export const ConsortiumMemberList = connect(mapStateToProps, mapDispatchToProps)(ConsortiumMembers)