import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UpdateArticleFull from "./UpdateArticleFull";
import BaseComponent, { HubContent } from "../../../../../../core/components";
import {
  getContentTypeList,
  articleFileUpload,
  trackArticleFileUploadStatus,
  getPublicatonTypeList,
  CreateArticle,
  EditArticle,
  getSingleArticle,
  trackArticleFileUploadStatusDownload,
  getArticleFullSingleList
} from "../../../../../actions";

class Article extends BaseComponent {
  state = {
    article: false,
    isLoading: false,
    status: null,
    fileUploadStatus: null,
    fileUploadStatusMsg: null,
    pageSubtitle: "Enter article details",
  };
 is_Mounted = false
  componentDidMount() {
    this.is_Mounted = true
    this.apiDataList()
  }

  apiDataList = async () => {
    let article = await this.props.getArticleFullSingleList(this.props.match.params.id);
    await this.props.getContentType();
    await this.props.getPublicatonType();
    if (this.is_Mounted) {
      this.setState({
        article
      })
     }
  }

  componentWillUnmount() {
    this.is_Mounted = false
  }



  onArticleFileUpload = async (payload) => {
    this.setState({
      isLoading: true,
    });

    await this.props.articleFileUpload(payload);
    if (this.props.article_file_upload.status) {
      let payload = {
        upload_status: this.props.article_file_upload.data.upload_status.slice(
          1
        ),
      };
      this.setState({
        upload_payload: payload,
      });
      await this.props.trackArticleFileUploadStatus(payload);
    }

    if (
      this.props.track_upload_status &&
      this.props.track_upload_status.reason
    ) {
      let upload_id = this.props.track_upload_status.id;

      let upload_download_status = `file-upload-error-response/${upload_id}/`;
      let payloadDownload = {
        upload_status: upload_download_status,
      };
      await this.props.trackArticleFileUploadStatusDownload(payloadDownload);
    }

    this.setState({
      fileUploadStatus: this.props.article_file_upload.status,
      fileUploadStatusMsg: this.props.article_file_upload.data.message,
      isLoading: false,
    });
  };

  cancelLoader = async () => {
    this.setState({
      status: null,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };

  render() {
    let { article } = this.state;
    return (
      <HubContent>
        <UpdateArticleFull
          article={article}
          filter={this.props.location&& this.props.location.filter}
          id={this.props.match.params.id}
        />
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
  content_type: state.journals.content_type,
  article_file_upload: state.articles.article_file_upload ,
  track_upload_status: state.articles.track_upload_status,
  track_upload_status_download:
    state.articles.track_upload_status_download ,
});

const mapDispatchToProps = (dispatch) => ({
  getContentType: () => dispatch(getContentTypeList()),
  getPublicatonType: () => dispatch(getPublicatonTypeList()),
  CreateArticle: (payload) => dispatch(CreateArticle(payload)),
  EditArticle: (payload) => dispatch(EditArticle(payload)),
  getSingleArticle: (id) => dispatch(getSingleArticle(id)),
  articleFileUpload: (payload) => dispatch(articleFileUpload(payload)),
  trackArticleFileUploadStatus: (payload) =>
    dispatch(trackArticleFileUploadStatus(payload)),
  trackArticleFileUploadStatusDownload: (payload) =>
    dispatch(trackArticleFileUploadStatusDownload(payload)),
  getArticleFullSingleList: (payload) => dispatch(getArticleFullSingleList(payload)),
});

export const UpdateArticleFullEditing = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Article)
);


