import React from "react";
import { connect } from "react-redux";

import { InputNewBox, Button } from "../../../../../core/components/common";
import { HubContent } from "../../../../../core/components";
import { updateFaultyAuthorAction, getSingleFaultyAuthor } from "../../../../actions";

class AuthorArticle extends React.Component {
  state = {
    author: {},
  };
  async componentDidMount() {
    if (this.props.match.params.id) {

      await this.props.getSingleFaultyAuthor(this.props.match.params.id)
      let faultyAuthor = this.props.article_faulty_author
      this.setState({
        id: this.props.match.params.id,
        author: {
          department: {
            name: faultyAuthor && faultyAuthor.department && faultyAuthor.department.name,
          },
          affiliated_organisation: faultyAuthor.affiliated_organisation,
          affiliation: faultyAuthor.affiliation,
          last_name: faultyAuthor.last_name,
          middle_name: faultyAuthor.middle_name,
          orcid_id: faultyAuthor.orcid_id,
          pmc_id: faultyAuthor.pmc_id,
          position: faultyAuthor.position,
          salutation: faultyAuthor.salutation,
          first_name: faultyAuthor.first_name,
          email: faultyAuthor.email,
        },
      });
    }

  }

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      if (Object.keys(value).includes("department")) {
        if (!value.department) {

          delete prevState.author.department.name;
        } else {
          prevState.author = {
            ...prevState.author,
            department: {
              name: value.department,
            },
          };
        }
      } else {
        prevState.author = {
          ...prevState.author,
          ...value,
        };
      }
      return prevState;
    });
  };
  removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
      if (
        (!obj[key] && obj[key] !== 0) ||
        (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  };
  onSubmitHandler = async () => {
    let { author, id } = this.state;

    let authorData = this.removeEmpty(author)


    await this.props.updateFaultyAuthorAction(id, authorData)

    this.props.history.push("/affiliation/faulty-author-list")
  };

  render() {
    let { author } = this.state;
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <div className="form-group row">
                  <InputNewBox
                    label="Salutation"
                    onChange={this.onChangeHandler}
                    field="salutation"
                    className="col-md-4"
                    placeholder="Salutation"
                    labelClass="col-md-2"
                    defaultValue={author.salutation}
                  />

                  <InputNewBox
                    label="First name"
                    onChange={this.onChangeHandler}
                    field="first_name"
                    className="col-md-4"
                    placeholder="First name"
                    labelClass="col-md-2"
                    defaultValue={author.first_name}
                  />
                </div>

                <div className="form-group row">
                  <InputNewBox
                    label="Middle name"
                    onChange={this.onChangeHandler}
                    field="middle_name"
                    className="col-md-4"
                    placeholder="Middle name"
                    labelClass="col-md-2"
                    defaultValue={author.middle_name}
                  />

                  <InputNewBox
                    label="Last name"
                    onChange={this.onChangeHandler}
                    field="last_name"
                    className="col-md-4"
                    placeholder="Last name"
                    labelClass="col-md-2"
                    defaultValue={author.last_name}
                  />
                </div>

                <div className="form-group row">
                  <InputNewBox
                    label="email"
                    onChange={this.onChangeHandler}
                    field="email"
                    className="col-md-4"
                    placeholder="author email"
                    labelClass="col-md-2"
                    InputType="email"
                    defaultValue={author.email}
                  />

                  <InputNewBox
                    onChange={this.onChangeHandler}
                    field="affiliation"
                    label="Affiliation"
                    className="col-md-4"
                    placeholder="Affiliation"
                    defaultValue={author.affiliation}
                  />
                </div>

                <div className="form-group row">
                  <InputNewBox
                    label="Author Department"
                    onChange={this.onChangeHandler}
                    field="department"
                    className="col-md-4"
                    placeholder="Author department"
                    labelClass="col-md-2"
                    defaultValue={author && author.department && author.department.name}
                  />
                  <InputNewBox
                    label="Orcid ID"
                    onChange={this.onChangeHandler}
                    field="orcid_id"
                    placeholder="Orcid ID"
                    defaultValue={author.orcid_id}
                  />
                </div>

                <div className="form-group row">

                  <InputNewBox
                    label="PMC id"
                    onChange={this.onChangeHandler}
                    field="pmc_id"
                    placeholder="PMC id"
                    defaultValue={author.pmc_id}
                  />
                </div>
                <div className="text-center">
                  <Button
                    name="primary"
                    text="Update Faulty Author"
                    type="primary"
                    onClick={this.onSubmitHandler}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  article_faulty_author: state.articlefaultyAuthor.article_faulty_author || false,
  faulty_author: state.articlefaultyAuthor.article_faulty_author_view || false,
});

const mapDispatchToProps = (dispatch) => ({
  getSingleFaultyAuthor: (id) => dispatch(getSingleFaultyAuthor(id)),
  updateFaultyAuthorAction: (id, payload) => dispatch(updateFaultyAuthorAction(id, payload)),
});

export const UpdateFaultyAuthor = connect(mapStateToProps, mapDispatchToProps)(AuthorArticle);


