import React from 'react';
import BaseComponent from '../../../../core/components';
import { connect } from "react-redux";

import {
  Button,
  PopupBox,
  TextBox, 
} from '../../../../core/components/common';


class ArticleRevert extends BaseComponent {

  state = {
    revert_reason: '',
  }

  onChangeHandler = value => {
    this.setState({
      ...value
    });
  };

  onClickHandler = () => { 
    if (!this.state.revert_reason) {
      this.setState({
        errorMsg: 'Revert reason is required.'
      })

      return
    }

    let mutateAction = 'unpublish'

    let articleId = this.props.article_id

    let data = {
      revert_reason: this.state.revert_reason,
    }

    this.props.mutateArticleStatus(articleId, mutateAction, data).then(response => { 
       
        this.setState({
          successMsg: response && response.data && response.data.message
        })
        
        this.props.onSuccessHandler()
    }).catch(error => { 
        
    })
  }

  onCancel = () => {
    this.setState({
      successMsg: false,
      errorMsg: false,
    });
  };

  render() {
    console.log(this.props.article_id)


    let { revert_reason } = this.state

    let successErrMsgPop = <>
            {this.state.successMsg && <PopupBox 
                className="col-10 col-sm-8 col-lg-6"
                title="Success!"
                msg={this.state.successMsg}
                onCancel={this.onCancel}
            />}
            
            {this.state.errorMsg && <PopupBox 
                className="col-10 col-sm-8 col-lg-6"
                title="Error!"
                msg={this.state.errorMsg}
                onCancel={this.onCancel}
            />}
        </>

    return (
      <div>
        
        { successErrMsgPop }

        <form className="apc-form">
               
          <div className="form-group row">
            <TextBox
              rows={8}
              label="Revert reason"
              labelClass="col-sm-3"
              onChange={this.onChangeHandler}
              field="revert_reason"
              className="col-sm-9"
              placeholder="Enter revert reason"
              defaultValue={revert_reason}
              isRequired={true}
            />
          </div>

          <div className="d-flex justify-content-center mb-4">
            <Button 
              text="Cancel"
              onClick={this.props.onClose}
              className="btn-danger mr-2"
            />
            <Button 
              text="Submit"
              onClick={this.onClickHandler}
              className="btn-primary"
            />
          </div>
        </form>
        
        
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({

});

export const ArticleRevertForm = connect(mapStateToProps, mapDispatchToProps)(ArticleRevert);
