import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  SelectBox,
  DBCard,
  // PaginationForm,
} from "../../../../../core/components/common";
import {getInstitutionsList, getPubsByFiltering, getUnivByFiltering } from "../../../../actions";
import { serialize } from "../../../../../core/lib";

class Org extends BaseComponent {
  state = {
    filterParams: {},
    filteringField: "",
  };

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  getInstDataList = (pageNum = 1, page = 10) => {
    let data = {
      filterParams: {
        publisher_deal_type: "all",
        category: "all",
        page: pageNum,
        // page_size: 99999999,
      },
    };

    let filter = serialize(data.filterParams);
    this.props.getPubsByFiltering({ filter });
  };
  pageChange = (pageNumber) => {
    this.getInstDataList(pageNumber);
  };

  componentDidMount() {


      this.setState({
        filteringField: "publisher_deal_type",
        filterParams: {
          publisher_deal_type: "all",
          page: 1,
          // page_size: 10,
        },
      });
      let filter = serialize(this.state.filterParams);
      this.props.getUnivByFiltering({ filter });

  }

  filterData = async (value) => {
    await this.setState((prevState) => {
      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });
    let filter = serialize(this.state.filterParams);

      this.props.getUnivByFiltering({ filter });
    
  };

  render() {
    let deal = [
      { name: "All deal types", id: "all" },
      { name: "Pre payment", id: "pre_payment" },
      { name: "Read and publish", id: "read_and_publish" },
      { name: "OA deal", id: "oa_deal" },
      { name: "Non OA deal", id: "non_oa_deal" },
    ];

    let category = [
      { name: "All types", id: "all" },
      { name: "Funder", id: "funder" },
      { name: "University", id: "university" },
      { name: "Research organisation", id: "research" },
      { name: "Consortia", id: "consortium" },
    ];


    return (
      <HubContent title={this.props.name || "University"}>
        <div className="row mb-3">
          {this.props.type === "publisher" ? (
            <>
              <SelectBox
                field={this.state.filteringField}
                data={deal}
                defaultValue="all"
                onChange={this.filterData}
              />
              <SelectBox
                field="category"
                data={category}
                defaultValue="all"
                onChange={this.filterData}
              />
            </>
          ) : (
              <SelectBox
                field={this.state.filteringField}
                data={deal}
                defaultValue="all"
                onChange={this.filterData}
              />
            )}
        </div>

      
          <div className="row institution" style={{ marginTop: 25 }}>
            {this.props.institutions && this.props.institutions.results && this.props.institutions.results.map((ins) => {
              return (
                <DBCard
                  key={ins.id}
                  className="col-md-4"
                  title={ins.name}
                  hasLink={`/consortia-publishers/${ins.id}`}
                  body={[
                    "Pending " + ins.acceptance || 0,
                    "Approved " + ins.accepted || 0,
                    "Declined " + ins.declined || 0,
                  ]}
                />
              );
            })}
          </div>
      
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  institutions:( state.institutions && state.institutions.institution_list )|| [],
  loading: state.institutions.loading, 
});

const mapDispatchToProps = (dispatch) => ({
  getPubsByFiltering: (payload) => dispatch(getPubsByFiltering(payload)),
  getUnivByFiltering: (payload) => dispatch(getUnivByFiltering(payload)),
  getInstitutionsList:()=>dispatch(getInstitutionsList()),
});

export const Organizations = connect(mapStateToProps, mapDispatchToProps)(Org);
