import {
    GET_APC_ELG_INST_LIST,
    GET_APC_ELG_JNL_LIST,
    GET_APC_ELG_FUNDER_LIST,
    GET_APC_ELG_COUNTRY_LIST,
    GET_APC_ELG_CONTENT_TYPE_LIST,
    GET_APC_ELG_SOCIETY_LIST,
    GET_APC_ELG_SUBMIT_RESPONSE
} from "../../config";


const APCEligibilityReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_APC_ELG_INST_LIST: 
            return state = {
                ...state,
                affiliations: action.payload
            }
        case GET_APC_ELG_JNL_LIST:
            return state = {
                ...state,
                journals: action.payload
            }
        case GET_APC_ELG_FUNDER_LIST:
            return state = {
                ...state,
                funders: action.payload
            }
        case GET_APC_ELG_COUNTRY_LIST:
            return state = {
                ...state,
                countries: action.payload
            }
        case GET_APC_ELG_CONTENT_TYPE_LIST:
            return state = {
                ...state,
                content_types: action.payload
            }
        case GET_APC_ELG_SOCIETY_LIST:
            return state = {
                ...state,
                societies: action.payload
            }
        case GET_APC_ELG_SUBMIT_RESPONSE:
            return state = {
                ...state,
                results: action.payload
            }
                        
        default:
            return state;
    }
}

export default APCEligibilityReducer;