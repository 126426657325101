import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { confirmAlert } from "react-confirm-alert";
import uuidv1 from "uuid/v1";
import { AutoComplete } from "antd";
import { InfoCircleTwoTone } from '@ant-design/icons';

import { Loading, InputBox, PopupBox, InputNewBox } from "../../../../../core/components/common";
import {
    ClearStatus,
    UpdateUser,
    getSingleUser,
    deleteUser,
    getAllUser,
    getSingleSocietyMemberShip,
    deleteSocietyMemberShip,
    getAllSocietyMemberShip,
    getAllSocietyMemberList,
    updateAlterNativeUser,
    getInstitutionDataLists

} from "../../../../actions";
import { SocietyMember } from "./SocietyMember";

class User extends BaseComponent {
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false,
        status: false,
        isEmpty: false,
        deleteConfirm: false,
        deleteStatus: null,
        isInputEnable: true,
        emailMessageVisable: false,
        isUpdateBtn: true
    };
    removeEmpty = (obj) => {
        Object.keys(obj).forEach(key => {
            if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
            if (
                (!obj[key] && obj[key] !== 0) ||
                (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
            ) {
                delete obj[key];
            }
        });
        return obj;
    };
    onEdit = async id => {
        let editData = await this.props.getSingleSocietyMemberShip(id);
        this.setState({
            isShow: true,
            editData,
        });
    };

    apiDataList =async () => {
        await this.props.getAllSocietyMemberShipList();
        let memberData = await this.props.getAllSocietyMemberShip();
        let user;
        const userId = this.props.match.params.id;
        user = await this.props.getSingleUser(userId);


        this.setState({
            organisation_name: user.organisation_name,
            organisation: user.organisation,
            user,
            position: user && user.domain_meta && user.domain_meta.position,
            department: user && user.domain_meta && user.domain_meta.department,
            memberData: memberData,
            tableData: this.props.society_list
        });
    }
     componentDidMount() {
      this.apiDataList()
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
}
    listofMemeberShip = async () => {
        await this.props.getAllSocietyMemberShipList();

        this.setState({
            tableData: this.props.society_list
        });
    };
    onChangeDomainHandler = (value) => {
        this.setState((prevState) => {

            Object.assign(prevState.user, {
                domain_meta: {
                    ...prevState.user.domain_meta,
                    ...value,
                },
            });
            return prevState;
        });
    };


  
    onSubmit = async () => {
        if (this.isNumber(this.state.organisation)) {
            let { salute,
                last_name,
                middle_name,
                first_name,
                department,
                email,
                position,
                username } = this.state.user
            const userData = {
                salute,
                ...this.state.user,
                last_name,
                middle_name,
                first_name,
                email,
                domain_meta: {
                    department: this.state.department || department,
                    position: this.state.position || position

                },
                username
            }
            let res = await this.props.UpdateUser({ ...userData, organisation: this.state.organisation });

            if (res.status === 200) {
                this.setState({
                    successMessage: true,
                    isUpdateBtn: !this.state.isUpdateBtn

                });
            }
        } else {
            this.setState({

                errorinstitutionMessage: true
            })
        }

    };
    onDeleteVat = id => {
        try {
            confirmAlert({
                title: "",
                message: "Are you sure that you want to delete this membership?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: async () => {
                            await this.props.deleteSocietyMemberShip(id);

                            let data = await this.props.getAllSocietyMemberShipList();
                            this.setState({
                                tableData: data
                            });
                        }
                    },
                    {
                        label: "No",
                        onClick: () => { }
                    }
                ]
            });
        } catch (error) { }
    };
    addSocietyMember = e => {
        e.preventDefault();
        this.setState({
            isShow: true
        });
    };

    addAlterNativeEmail = e => {
        e.preventDefault();
        this.setState({
            isAlterNativeMail: true
        });
    };
    onClose = () => {
        this.setState({
            isShow: false,
            isAlterNativeMail: false
        });
    };

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            return Object.assign(prevState.user, value);
        });

    };


    onSubmitMultipleAuthor = async () => {
        let id = this.props.match.params.id
        let payload = {
            name: this.state.name,
            email: this.state.useremail
        };
        this.props.updateAlterNativeUser(
            { add: [payload] }, id

        )
        this.props.history.push(`/user-profile/${id}`)

    }
    onMultipleEmailDelete = async (item) => {
        let id = this.props.match.params.id
        let payload = {
            email: item.email
        };
        try {
            confirmAlert({
                title: "",
                message: "Are you sure that you want to delete email?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: async () => {
                            this.props.updateAlterNativeUser(
                                { delete: [payload] }, id
                            )
                            this.props.history.push(`/user-profile/${id}`)

                        }
                    },
                    {
                        label: "No",
                        onClick: () => { }
                    }
                ]
            });
        } catch (error) { }


    }
    onChangeMultipleEmailHandler = (value) => {
        this.setState({
            ...value
        });

    }
    updateBtn = () => {
        this.setState({
            isInputEnable: !this.state.isInputEnable,
            isUpdateBtn: !this.state.isUpdateBtn
        })
    }

    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    onSelect = (value, item) => {
        if (this.isNumber(item.id)) {
            this.setState({
                organisation: item.id,
                orgsError: false
            });
        }
    };


    onChangeAutoComplete = async (value) => {
        this.setState({
            organisation_name: value,
        });

        await this.props.getInstitutionDataLists(value.trim());
        let data = this.props.institution_list && this.props.institution_list.find(item => item.name === value)
        if (this.isNumber(data && data.id)) {
            this.setState({
                organisation_name: value,
                orgsError: false
            });
        } else {
            this.setState({
                orgsError: true
            });
        }

    };
    onSearch = (value) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            await this.props.getInstitutionDataLists(value);
            this.setState({
                institution_list: this.props.institution_list && this.props.institution_list.map(item => ({ id: item.id, value: item.name }))
            });
        }, 200);
    };
    handleEmailMessageVisableInfo = () => {
        this.setState({
            emailMessageVisable: !this.state.emailMessageVisable

        })
    }

    handleSocietyMessageVisableInfo = () => {
        this.setState({
            societyMessageVisable: !this.state.societyMessageVisable

        })
    }
    handleOrgMessage = () => {
        this.setState({
            InstMessageVisable: !this.state.InstMessageVisable

        })
    }
    onCancel = () => {
        this.setState({
            societyMessageVisable: false,
            emailMessageVisable: false,
            errorinstitutionMessage: false,
            InstMessageVisable: false,

        });
    };
    redirectLink = () => {
        this.setState({
            successMessage: false,
        });
        this.props.history.push(`/user-profile/${this.props.match.params.id}`)

    };
    render() {
        let data =
            this.state.memberData &&
            this.state.memberData.results &&
            this.state.memberData.results.map(item => {
                return Object.assign(item, {
                    id: item.id,
                    name: item.name
                });
            });

        let tableData = (
            <div>

                <div className="row mutltiple-author-header">
                    <div className="col-md-4">
                        Society Name
                    </div>

                    <div className="col-md-4">
                        Membership ID
                    </div>

                    <div className="col-md-4">
                        Action
                    </div>

                </div>

                {this.state.tableData && this.state.tableData.results ? (
                    this.state.tableData &&
                    this.state.tableData.results &&
                    this.state.tableData.results.map(item => (

                        <div className="row mutltiple-author" key={uuidv1(item.id)}>
                            <div className="col-md-4">
                                {item.society_name}                </div>

                            <div className="col-md-4">
                                {item.membership_id}                    </div>

                            <div className="col-md-4">
                                <span
                                    style={{ marginRight: 5 }}
                                    className="cursor-pointer"
                                    onClick={e => this.onDeleteVat(item.id)}
                                    id="deleteBtn"
                                    title="Delete"
                                >
                                    <i className="mdi mdi-delete"></i>
                                </span>
                  |
                  <span
                                    style={{ marginLeft: 5 }}
                                    className="cursor-pointer"
                                    onClick={e => this.onEdit(item.id)}
                                    title="Edit"
                                >
                                    <i className="mdi mdi-pen"></i>
                                </span>
                            </div>

                        </div>
                    ))
                ) : (
                        <Loading type="flat" />
                    )}
            </div >
        );

        let alternativeEmail = (
            <div style={{ marginTop: 20 }}>

                <div className="row" style={{ marginBottom: 15 }}>
                    <div className="col-md-4 profile-icon-user profile-other-email "><h3 className="profile-heading-user profile-other-email">  Add your other email:</h3>  <div className="profile-icon">
                        <InfoCircleTwoTone style={{
                            fontSize: 18,
                            marginLeft: 15
                        }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                            onClick={this.handleEmailMessageVisableInfo

                            }
                        /></div></div>

                    <InputBox
                        onChange={this.onChangeMultipleEmailHandler}
                        field="useremail"
                        className="col-md-4"
                        placeholder="Enter your other email"
                    />
                    <div className="col-md-1">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.onSubmitMultipleAuthor}
                        >
                            + Add
              </button>
                    </div>
                </div>

                <div className="row mutltiple-author-header">
                    <div className="col-md-4">
                        Email          </div>


                </div>
                {(this.state.user && this.state.user.alt_emails) && (
                    this.state.user && this.state.user.alt_emails.map(item => (
                        <div key={uuidv1(item.id)}>

                            <div className="row">

                                <div className="col-md-4"
                                >


                                    <p>
                                        {item.email}<span
                                            style={{ marginLeft: 5, color: "red" }}
                                            className="cursor-pointer"
                                            onClick={() => this.onMultipleEmailDelete(item)}
                                            id="deleteBtn"
                                            title="Delete"
                                        >
                                            <i className="mdi mdi-delete"></i>
                                        </span>

                                    </p>

                                </div>
                            </div >


                        </div >

                    ))
                )
                }


            </div >
        );


        return (
            <HubContent title={`${this.state.user.fullname || ""} `}>

                <div className="container">
                    {this.state.successMessage && (
                        <PopupBox
                            NoText="Close"
                            msg="You have successfully updated user information."
                            onCancel={this.redirectLink}
                        />
                    )}
                    {this.state.emailMessageVisable && (
                        <PopupBox
                            first_name="To easily track your articles, you may wish to add your secondary email addresses used in past or future papers."
                            last_name="This helps OaMetrix to cross check and find your papers accurately."

                            onCancel={this.onCancel}
                        />
                    )}
                    {this.state.errorinstitutionMessage && (
                        <PopupBox
                            msg="It appears you have not selected your correct affiliated institution name from organisation dropdown field, select your correct institution and resubmit.                "

                            onCancel={this.onCancel}
                        />
                    )}


                    {this.state.societyMessageVisable && (
                        <PopupBox
                            msg="To check your society membership discount eligibility and apply the discount accurately, please enter the society name and your society membership ID in your profile."
                            onCancel={this.onCancel}
                        />
                    )}


                    {this.state.InstMessageVisable && (
                        <PopupBox

                            first_name="Select the institution or organisation name from the dropdown. OaMetrix will display an error message if you do not select your institution name from the dropdown."

                            link={true} onCancel={this.onCancel}
                        />
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-12">




                        <div className="profile-content" style={{ paddingTop: 10 }}>


                            {this.props.loading !== true ? <div className="row">
                                <div className="col-md-12 profile-m-15" style={{ marginBottom: 15, paddingLeft: 15, paddingRight: 15 }}>
                                    {this.state.isUpdateBtn && <div className="col-md-12 update-btn">
                                        <button
                                            className="btn btn-info btn-md"
                                            onClick={this.updateBtn}
                                            style={{ marginTop: 10 }}
                                        >
                                            Edit</button>
                                    </div>}
                                    {
                                        (this.state.isInputEnable !== true && this.state.successMessage !== true) &&
                                        <div className="col-md-12 update-btn">
                                            <button
                                                className="btn btn-primary btn-md"
                                                onClick={this.onSubmit}
                                            >
                                                Save
            </button>
                                        </div>
                                    }

                                    <div className="inst-profile-label">
                                        <label className="profile-label">Institution <strong style={{ color: "red" }}> *</strong> </label>
                                        <div className="inst-icon">
                                            <InfoCircleTwoTone style={{
                                                fontSize: 15,
                                                marginLeft: 13
                                            }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                                                onClick={this.handleOrgMessage}
                                            />
                                        </div>
                                    </div>
                                    {this.state.isInputEnable ? <InputBox
                                        onChange={this.onChangeHandler}
                                        field="organisation_name"
                                        disabled={this.state.isInputEnable}

                                        className="col-md-12"
                                        defaultValue={this.state.organisation_name}
                                    /> :

                                        <div>
                                            <AutoComplete
                                                value={this.state.organisation_name}
                                                options={this.state.institution_list}
                                                style={{
                                                    width: "100%",
                                                }}
                                                onSelect={(val, option) =>
                                                    this.onSelect(val, option)
                                                }
                                                placeholder="Type your institution name and select from the drop down"
                                                onSearch={this.onSearch}
                                                onChange={this.onChangeAutoComplete}
                                            />
                                            {this.state.orgsError
                                                && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
</span>}
                                        </div>

                                    }


                                </div>
                                {this.state.isInputEnable && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Name </label>
                                    <InputBox
                                        onChange={this.onChangeHandler}
                                        className="col-md-12"
                                        disabled={true}
                                        defaultValue={this.state.user.fullname}
                                    />
                                </div>}
                                {this.state.isInputEnable !== true && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Salutation </label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        className="col-md-12"
                                        field="salute"
                                        disabled={this.state.isInputEnable}
                                        defaultValue={this.state.user.salute}
                                    />

                                </div>}

                                {this.state.isInputEnable !== true && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">First name <span style={{ color: "red", marginLeft: "5px" }}>*</span></label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="first_name"
                                        disabled={this.state.isInputEnable}

                                        className="col-md-12"
                                        defaultValue={this.state.user.first_name}
                                    />
                                </div>}
                                {this.state.isInputEnable !== true && <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Middle Name</label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="middle_name"
                                        className="col-md-12"
                                        disabled={this.state.isInputEnable}
                                        defaultValue={this.state.user.middle_name}
                                    />
                                </div>}
                                {this.state.isInputEnable !== true &&
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Last Name <span style={{ color: "red", marginLeft: "5px" }}>*</span></label>
                                        <InputNewBox
                                            onChange={this.onChangeHandler}
                                            field="last_name"
                                            disabled={this.state.isInputEnable}

                                            className="col-md-12"
                                            defaultValue={this.state.user.last_name}
                                        />
                                    </div>}
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Position </label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="position"
                                        disabled={this.state.isInputEnable}
                                        defaultValue={this.state.position}
                                        className="col-md-12"

                                    />
                                </div>
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label"> Department</label>
                                    <InputNewBox
                                        disabled={this.state.isInputEnable}

                                        onChange={this.onChangeHandler}
                                        field="department"
                                        defaultValue={this.state.department}

                                        className="col-md-12"
                                    />
                                </div>

                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Email Address <span style={{ color: "red", marginLeft: "5px" }}>*</span></label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="email"
                                        disabled={this.state.isInputEnable}

                                        className="col-md-12"
                                        defaultValue={this.state.user.email}
                                    />
                                </div>
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Username</label>
                                    <InputNewBox
                                        onChange={this.onChangeHandler}
                                        field="username"
                                        disabled={true}

                                        className="col-md-12"
                                        defaultValue={this.state.user.username}
                                    />
                                </div>
                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Oametrix ID</label>
                                    <InputBox
                                        disabled={true}

                                        id="name"
                                        onChange={this.onChangeHandler}
                                        field="unique_id"


                                        className="col-md-12"
                                        defaultValue={this.state.user.unique_id}
                                    />
                                </div>

                                <div className="col-md-4 profile-m-15">
                                    <label className="profile-label">Orcid ID</label>
                                    <InputBox
                                        id="name"
                                        onChange={this.onChangeHandler}
                                        field="orcid_id"
                                        disabled={true}

                                        className="col-md-12"
                                        defaultValue={
                                            this.state.user.user_meta && this.state.user.user_meta.orcid_id
                                        }
                                    />
                                </div>



                            </div> : <Loading type="flat" />}






                        </div>
                        <div className="mutliple-email">
                            {this.state.group_name !== "Publisher" &&
                                this.state.group_name !== "Hub" ? (
                                    <div
                                        className="profile-author"
                                    >

                                        {alternativeEmail}
                                    </div>
                                ) : null}



                        </div>
                        <div className="society-member-ui">
                            {this.state.group_name !== "Hub" &&
                                this.state.group_name !== "Publisher" ? (
                                    <div

                                    >
                                        <div
                                            className="row"
                                            style={{ marginTop: 30, }}
                                        >
                                            {this.state.editData && this.state.editData.id
                                                ? this.state.isShow && (
                                                    <SocietyMember
                                                        data={data}
                                                        editData={this.state.editData}
                                                        listofMemeberShip={this.listofMemeberShip}
                                                        isShow={this.state.isShow}
                                                        onClose={this.onClose}
                                                    />
                                                )
                                                : this.state.isShow && (
                                                    <SocietyMember
                                                        data={data}
                                                        listofMemeberShip={this.listofMemeberShip}
                                                        isShow={this.state.isShow}
                                                        onClose={this.onClose}
                                                    />
                                                )}

                                        </div>
                                        <div className="row" style={{ marginBottom: 15 }}>
                                            <div className="col-md-4 profile-icon-user"><h3 className="profile-heading-user">   Add your society membership</h3>  <div className="profile-icon">
                                                <InfoCircleTwoTone style={{
                                                    fontSize: 18,
                                                    marginLeft: 15
                                                }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                                                    onClick={this.handleSocietyMessageVisableInfo}
                                                /></div></div>

                                            <div className="col-md-6">
                                                <button
                                                    className="btn btn-primary mb-2"
                                                    onClick={this.addSocietyMember}
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                >
                                                    <i className="mdi mdi-plus"></i>Add
                    </button>
                                            </div>
                                        </div>
                                    </div>

                                ) : null}
                            {this.state.group_name !== "Publisher" &&
                                this.state.group_name !== "Hub" ? (
                                    <div
                                        className="profile-author"
                                    >
                                        {tableData}
                                    </div>
                                ) : null}
                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.user.userData,
    loading: state.user.loading,
    user: state.auth.user || {},

    society_list: state.society.get_all_society_membership,
    institution_list: (state.institutions && state.institutions.institution_list )|| [],


});

const mapDispatchToProps = dispatch => ({
    ClearStatus: () => dispatch(ClearStatus()),
    getSingleUser: id => dispatch(getSingleUser(id)),
    deleteUser: id => dispatch(deleteUser(id)),
    getAllUser: payload => dispatch(getAllUser(payload)),
    getSingleSocietyMemberShip: id => dispatch(getSingleSocietyMemberShip(id)),
    deleteSocietyMemberShip: id => dispatch(deleteSocietyMemberShip(id)),
    getAllSocietyMemberShipList: () => dispatch(getAllSocietyMemberShip()),
    getAllSocietyMemberShip: () => dispatch(getAllSocietyMemberList()),
    UpdateUser: (payload) => dispatch(UpdateUser(payload)),
    updateAlterNativeUser: (payload, id) =>
        dispatch(updateAlterNativeUser(payload, id)),
    getInstitutionDataLists: (value) => dispatch(getInstitutionDataLists(value)),

});

export const ProfileUser = connect(mapStateToProps, mapDispatchToProps)(User);
