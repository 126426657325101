import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../../core/components";
import EmailCommon from "./EmailCommon";

class AddPublisher extends BaseComponent {
  
  render() { 
    
    return (
      <EmailCommon group="publisher" />
    );
  }
}


const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => ({
  
});

export const Publisher = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPublisher);
