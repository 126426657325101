import React from "react";
import { connect } from "react-redux";

import { getInstitutionDataLists } from "../../../openaccess/actions";
import { AutoComplete,Spin } from "antd";
import {LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Modal extends React.PureComponent {
  state = {
    selectedItemIds: this.props.defaultValue || [],
    selectedItems: [],
    filteredArr: [],
  };

  componentDidMount() {
    this.setState(
      {
        selectedItems: this.props.members ? this.props.members : [],
      },
      () => {
        const filteredArr = this.state.selectedItems.reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.setState({
          filteredArr,
        });
      }
    );
  }

  onCloseModal = (e) => {
    this.props.onClose();
  };

  onSubmit = () => {
    const filteredArr = this.state.selectedItems.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    this.props.onSubmit(filteredArr);
    this.onCloseModal();
  };

  onDeleteSelectedItem = (id) => {
    let { selectedItemIds, selectedItems } = this.state;

    this.setState(
      {
        selectedItemIds: selectedItemIds.filter((value) => value !== id),
        selectedItems: selectedItems.filter((item) => item.id !== id),
      },
      () => {
        const filteredArr = this.state.selectedItems.reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.setState({
          filteredArr,
        });
      }
    );
  };

  onSelect = (options, item) => {
    this.setState(
      {
        selectedItems: [...this.state.selectedItems, item],
        orgsError: false

      },
      () => {
        const filteredArr = this.state.selectedItems.reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.setState({
          filteredArr,
        });
      }
    );
  };
  onChange = (value) => {

    if (value === "") {
      this.setState({
        value: value,
        org_name: value
,
        orgsError: false,

      })
    } else {
      if (value === "") {
        this.setState({
          orgsError: false,
          org_name: value

        })
      } else {
        this.setState({
          orgsError: true,
          org_name: value

        })
      }
    }

  };
  onSearch = async (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getInstitutionDataLists(value);
      this.setState({
        InstList:
          this.props.institution_list &&
          this.props.institution_list.map((item) => ({
            id: item.id,
            value: item.name,
          })),
      });
    }, 200);
  };
  render() {
    const { placeholder } = this.props;
    const { filteredArr, InstList } = this.state;
    return (
      <div style={modal.overlay}>
        <div
          className={`${this.props.modalClass || "col-sm-6"} bg-white alert`}
          style={modal.boxStyle}
        >
          <div className="modal-body">
            <div className="form-group row auto-complete-search">
              <AutoComplete
                value={this.state.org_name}
                notFoundContent={this.state.org_name && "The institution name you have typed has no match on OaMetrix."}

                options={InstList}
                style={{
                  width: "100%",
                }}
                onSelect={(val, option) => this.onSelect(val, option)}
                onSearch={this.onSearch}
                onChange={this.onChange}
                placeholder={placeholder}
              />
                                <div className="search-auto-spin" style={{marginTop:20}}>
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>

                      {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure you have selected Institution/Consortia name from dropdown.
</span>}
            
            </div>
            <div className="form-group row">
              <div className="col-md-12">
                {filteredArr && filteredArr.length > 0 && (
                  <table className="table">
                    <tbody>
                      {filteredArr &&
                        filteredArr.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td className="py-1">{item.value}</td>
                              <td className="py-1 text-center">
                                <button
                                  onClick={() =>
                                    this.onDeleteSelectedItem(item.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                  type="button"
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.props.onClose && this.props.onClose();
              }}
            >
              Close
            </button>
            {this.props.submitBtnTxt && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSubmit}
              >
                {this.props.submitBtnTxt}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

let boxHeight = "";
if (navigator.userAgent.toLowerCase().includes("firefox")) {
  boxHeight = "-moz-fit-content";
} else if (navigator.userAgent.toLowerCase().includes("chrome")) {
  boxHeight = "-webkit-fit-content";
}

const modal = {
  overlay: {
    position: "fixed",
    zIndex: 1001,
    backgroundColor: "rgba(0, 0, 0, 0.64)",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  },
  boxStyle: {
    transform: "translate(0px, 100px)",
    height: boxHeight,
  },
};

const mapStateToProps = (state) => ({
  institution_list: (state.institutions && state.institutions.institution_list) || [],
  loading: state.institutions.loading,

});
const mapDispatchToProps = (dispatch) => ({
  getInstitutionDataLists: (value) => dispatch(getInstitutionDataLists(value)),
});

export const ModalAutoComplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
