import config, {
    SUCCESS_SOCIETY_DISCOUNT_LIST,
    FAILED_SOCIETY_DISCOUNT_LIST,
    REQUEST_SOCIETY_DISCOUNT_LIST,
    GET_SOCIETY_DISCOUNT,
    CREATE_UPDATE_SOCIETY_DISCOUNT,
    DELETE_SOCIETY_DISCOUNT,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get Society Member list
export const successSocietyDiscountListAction = (payload) => ({
    type: SUCCESS_SOCIETY_DISCOUNT_LIST,
    payload
});

export const failedSocietyDiscountListAction = (payload) => ({
    type: FAILED_SOCIETY_DISCOUNT_LIST,
    payload
});

export const requestSocietyDiscountListAction = (payload) => ({
    type: REQUEST_SOCIETY_DISCOUNT_LIST,
    payload
});

// Get Society Member
export const getSocietyDiscountAction = (payload) => ({
    type: GET_SOCIETY_DISCOUNT,
    payload
});

// Create-Update Society Member
export const createUpdateSocietyDiscountAction = (payload) => ({
    type: CREATE_UPDATE_SOCIETY_DISCOUNT,
    payload
});

// Delete Society Member
export const deleteSocietyDiscountAction = (payload) => ({
    type: DELETE_SOCIETY_DISCOUNT,
    payload
});

export const getSocietyDiscountList = (payload = {}) => {
    return (dispatch) => {
        let url = config.endpoint.publisher_membership_discount_config;
        let params = {};

        if (payload.pageNum && payload.pageSize) {
            params['page'] = payload.pageNum;
            params['page_size'] = payload.pageSize;
        } else if (payload.pageNum) {
            params['page'] = payload.pageNum;
        } else if (payload.pageSize) {
            params['page_size'] = payload.pageSize;
        }

        if (!params.page_size) {
            params['page_size'] = 99999;
        }

        url += `?${serialize(params)}`;
        dispatch(requestSocietyDiscountListAction())

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(successSocietyDiscountListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                dispatch(failedSocietyDiscountListAction(e))

                reject(e)
            })
        })
    }
}

// Get discount config
export const getSocietyDiscount = (id) => {
    return (dispatch) => {
        let url = config.endpoint.publisher_membership_discount_config + `${id}/`;

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getSocietyDiscountAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Create-Update discount config

export const createUpdateSocietyDiscount = (payload, id) => {
    return (dispatch) => {
        let url = config.endpoint.publisher_membership_discount_config;
        let apiRequest = '';
        return new Promise((resolve, reject) => {
            if (id) {
                url += `${id}/`;
                apiRequest = api.put(url, payload);
            } else {
                apiRequest = api.post(url, payload);
            }

            apiRequest.then((res) => {
                dispatch(createUpdateSocietyDiscountAction(res.data));
                resolve(Object.assign(res.data, { status: true }));
            }).catch(e => {
                reject(e)
            });
        })
    }
}

// Delete discount config
export const deleteSocietyDiscount = (id) => {
    return (dispatch) => {
        let url = config.endpoint.publisher_membership_discount_config + `${id}/`;

        return new Promise((resolve, reject) => {
            api.delete(url).then((res) => {
                dispatch(deleteSocietyDiscountAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}
