import React from "react";
import BaseComponent from "../../../../core/components";
import { InputBox } from "../../../../core/components/common";

class Form extends BaseComponent {
  state = {
    addressCounter: 0,
    addresses: [],
    isShowPlus: true,
    isShowMinus: false,
  };

  incrementAddress = () => {
    if (this.state.addressCounter >= 0) {
      this.setState({
        addressCounter: 1,
        isShowPlus: !this.state.isShowPlus,
        isShowMinus: !this.state.isShowMinus,
      });
    }
  };

  decrementAddress = () => {
    if (this.state.addressCounter > 0) {
      this.setState((prevState) => {
        prevState.addressCounter = this.state.addressCounter - 1;
        prevState.addresses.pop();
        prevState.isShowMinus = false;
        prevState.isShowPlus = true;

        return prevState;
      });
    }
  };
  render() {
    let { fieldLabel, value } = this.props;
    let addresses = [];

    for (let i = 0; i < this.state.addressCounter; i++) {
      addresses.push(
        <div className="addresses" key={i}>
          <div className="form-group row">
            <InputBox
              onChange={this.props.onChangeHandler}
              field="email"
              label={`${fieldLabel} email`}
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Corresponding author email"
              InputType="email"
              defaultValue={value.email}
            />

            <InputBox
              onChange={this.props.onChangeHandler}
              field="affiliation"
              label={`${fieldLabel} affiliation`}
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Author affiliated org"
              defaultValue={value.affiliation}
            />
          </div>

          <div className="form-group row">
            <InputBox
              onChange={this.props.onChangeHandler}
              field="department"
              placeholder="Author department"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              label={`${fieldLabel} dept`}
              defaultValue={value.department && value.department.name}
            />

            <InputBox
              onChange={this.props.onChangeHandler}
              field="division"
              label="Faculty Name"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              defaultValue={
                value.department &&
                value.department.division &&
                value.department.division.name
              }
              placeholder="Faculty name"
            // isDisable={true}
            />
          </div>

          <div className="form-group row">
            <InputBox
              label="ORCID ID"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.props.onChangeHandler}
              field="orcid_id"
              placeholder="ORCID ID"
              defaultValue={value.orcid_id}
            />

            <InputBox
              onChange={this.props.onChangeHandler}
              field="pmc_id"
              label="PMC id"
              placeholder="PMC id"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              defaultValue={value.pmc_id}
            />
          </div>
          <div className="form-group row">
            <InputBox
              onChange={this.props.onChangeHandler}
              field="position"
              label="Position"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              placeholder="Position"
              defaultValue={value.position}
            />
          </div>
        </div>
      );
    }
    let AuthorInfo = (
      <div className="author">
        <div className="form-group row">
          <InputBox
            onChange={this.props.onChangeHandler}
            field="salutation"
            label="Salutation"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Salutation"
            defaultValue={value.salutation}
          />

          <InputBox
            onChange={this.props.onChangeHandler}
            field="first_name"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            label="First Name"
            placeholder="First name"
            defaultValue={value.first_name}
          />
        </div>
        <div className="form-group row">
          <InputBox
            onChange={this.props.onChangeHandler}
            field="middle_name"
            label="Middle name"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Middle name"
            defaultValue={value.middle_name}
          />
          <InputBox
            onChange={this.props.onChangeHandler}
            field="last_name"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            label="Last name"
            placeholder="Last name"
            defaultValue={value.last_name}
          />
        </div>
        {addresses}
      </div>
    );

    return (
      <div className={`col-sm-12`}>
        {AuthorInfo}
        <div className="row">
          <div className="col-sm-12 text-right">
            {this.state.isShowMinus && (
              <button
                className="btn btn-primary mr-2 btn-author"
                type="button"
                onClick={() => this.decrementAddress()}
              >
                <i className="mdi mdi-minus" style={{ fontSize: 20 }}></i>
              </button>
            )}
            {this.state.isShowPlus && (
              <button
                className="btn btn-primary btn-author"
                type="button"
                onClick={() => this.incrementAddress()}
              >
                <i className="mdi mdi-plus" style={{ fontSize: 20 }}></i>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const Author = Form;
