import React from "react";
import { DragAndDrop, JournalForm } from "../../../partials";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { connect } from "react-redux";
import {
  ClearStatus,
  getOaDisciplineList,
  getOrgList,
  getPublicatonTypeList,
  getJournalTypeList,
  getContentTypeList,
  UpdateJournal,
  singleJournal
} from "../../../../actions";
import { PopupBox, Loading } from "../../../../../core/components/common";

class Journal extends BaseComponent {
  state = {
    journal: this.props.journal,
    isEmpty: false,
    loading: false
  };

  onChangeHandler = value => {
    this.setState(prevState => {
      Object.assign(prevState.journal, value);
    });
  };

  onSubmitHandler = async journal => {
    if (
      !journal.journal_type ||
      !journal.publication_type ||
      !journal.publisher ||
      !journal.name
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!journal.journal_type && <p>Journal Type must be selected</p>}
            {!journal.publication_type && (
              <p>Publication Type must be selected</p>
            )}
            {!journal.publisher && <p>Publisher must be selected</p>}
            {!journal.name && <p>Journal Name must be filled</p>}
          </div>
        )
      });
    } else {
      await this.props.UpdateJournal(this.props.match.params.id, journal);
      this.props.history.push("/journals/journals-list/");
    }
  };

  async componentDidMount() {
    const journalId = this.props.match.params.id;
    this.props.getOaDisciplineList();
    await this.props.getOrgList();
    await this.props.getPublicatonTypeList();
    await this.props.getJournalTypeList();
    await this.props.getContentTypeList();
    this.setState({
      loading: true
    });
    await this.props.singleJournal(journalId);
    this.setState({
      loading: false
    });
  }

  OnChangeTextBox(value, name) {
    const state = {};
    state[name] = value;
    this.setState(state);
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false
    });
    await this.props.ClearStatus();
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return (
      <HubContent title="Add journal form">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Enter journal details
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload journal details
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <h4 className="mt-0 header-title">
                      Enter journal information
                    </h4>
                    <JournalForm
                      onSubmitHandler={this.onSubmitHandler}
                      OnChangeTextBox={this.OnChangeTextBox.bind(this)}
                      msg={this.state.msg}
                      journal={this.state.journal}
                      journal_id={this.props.match.params.id}
                      isEdit={true}
                    />
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <h4 className="mt-0 header-title">
                      Publishier information
                    </h4>
                    <p className="text-muted m-b-30">
                      Please drop your file. The sequense of columns will be
                      Publisher name, address line 1, address line 2, City/Town,
                      County/State, Post or zip code, Country, website, VAT,
                      Note
                    </p>

                    <div className="m-b-30">
                      <DragAndDrop />
                    </div>

                    <div className="text-center m-t-15">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  failed: state.orgs.failed || false,
  token: state.auth.token || false,
  journal: state.journals.single_journal || false,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false
});
const mapDispatchToProps = dispatch => ({
  ClearStatus: () => dispatch(ClearStatus()),
  getOrgList: payload => dispatch(getOrgList(payload)),
  getPublicatonTypeList: payload => dispatch(getPublicatonTypeList(payload)),
  getJournalTypeList: payload => dispatch(getJournalTypeList(payload)),
  getContentTypeList: payload => dispatch(getContentTypeList(payload)),
  UpdateJournal: (id, payload) => dispatch(UpdateJournal(id, payload)),
  singleJournal: id => dispatch(singleJournal(id)),
  getOaDisciplineList: payload => dispatch(getOaDisciplineList(payload))
});
export const EditJournal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Journal);
