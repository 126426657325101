import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { DragAndDrop } from "../../../partials";
import { VATForm } from "./VATForm";
import {
  getSingleHubVatConfig,
  createUpdateHubVatConfig,
} from "../../../../actions";

class Edit extends BaseComponent {
  state = {};

  async componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      await this.props.getSingleHubVatConfig(id);

      if (this.props.vat_info) {
        this.setState({
          id: id,
          vat_info: {
            country: this.props.vat_info.country,
            state_provincial_vat_rate: this.props.vat_info
              .state_provincial_vat_rate,
            res: this.props.vat_info.vat_rate,
            federal_vat_rate: this.props.vat_info.federal_vat_rate,
            state: this.props.vat_info.state,
          },
        });
      }
    }
  }

  onSubmitHandler = async (data) => {
    try {
      const id = this.props.match.params.id;
      await this.props.createUpdateHubVatConfig(data, id);

      if (this.props.vat_info) {
        this.props.history.push("/vat/vat-list");
      }
    } catch (error) {}
  };

  render() {
    let { vat_info, id } = this.state;

    return (
      <HubContent title="Edit VAT">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Enter VAT
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload VAT details
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    {vat_info && (
                      <VATForm
                        onSubmitHandler={this.onSubmitHandler}
                        data={vat_info}
                        id={id}
                      />
                    )}
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <h4 className="mt-0 header-title">VAT information</h4>
                    <p className="text-muted m-b-30">
                      Please drop your file. The sequense of columns will be
                      Publisher name, address line 1, address line 2, City/Town,
                      County/State, Post or zip code, Country, website, VAT,
                      Note
                    </p>

                    <div className="m-b-30">
                      <DragAndDrop />
                    </div>

                    <div className="text-center m-t-15">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  vat_info: state.vat.single_vat_config || false,
});

const mapDispatchToProps = (dispatch) => ({
  getSingleHubVatConfig: (id) => dispatch(getSingleHubVatConfig(id)),
  createUpdateHubVatConfig: (payload, id) =>
    dispatch(createUpdateHubVatConfig(payload, id)),
});

export const EditVAT = connect(mapStateToProps, mapDispatchToProps)(Edit);
