import React, { Component } from 'react'
import {
    RadioButtonGroup,
} from "../../../../../../core/components/common";
import VatTable from "../vatTable";

import MultiSelectCheckBox from "../../../../../../core/components/common/MultiSelectCheckBox";

 class Canada extends Component {

    render() {
   
        return (
            <div className="vat-detail-form">
            <div className="vat_charge">
              <div className="vat-question-panel">
                <p className="vat_charge">
                  Do you charge Tax/GST/HST/PST to customers in Canada if they
                  provide a valid VAT/GST/Business Tax Number?
            </p>

                <div className="vat_charge">
                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="charge_vat_number_canada"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.charge_vat_number_canada}
                  />
                </div>
              </div>
              {this.props.charge_vat_number_canada === true && (
                <div className="vat-question-panel">
                  <p>Do you charge Tax/GST/HST/PST based on province?</p>
                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="proviencebasedoncharge"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.proviencebasedoncharge}
                  />
                </div>
              )}
              {this.props.charge_vat_number_canada !== false
                ? this.props.proviencebasedoncharge === true && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default Tax/GST/HST/PST on OaMetrix.
                          You can override default VAT rate to apply your
                          own rate.
                      </p>
                      </div>
                      <div className="country-list">
                        <MultiSelectCheckBox
                          field="STATES"
                          data={this.props.canadaState}
                          selectCountry={(props) =>
                            this.props.SelectCheckBoxBasedOnProvince_1(props)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={this.props.vatCanadaProviceApiData_1}
                        editHandler={(value) =>
                            this.props.EditHandler_for_ca_1(value)
                        }
                        countryType="CA"
                      />
                    </div>
                  </div>
                )
                : null}
              {this.props.charge_vat_number_canada !== false
                ? this.props.proviencebasedoncharge === false && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Here is the default Tax/GST/HST/PST on OaMetrix.
                          You can override default VAT rate to apply your
                          own rate.
                      </p>
                      </div>
                      <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.props.vatforca}
                            selectCountry={(props) =>
                              this.props.SelectCheckBoxBasedCanadaCountry_1(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.vatCanadaCountryApiData_1}
                          editHandler={(value) =>
                            this.props.EditHandler_country_ca_1(value)
                          }
                          countryType="CA"
                        />{" "}
                    </div>
                  </div>
                )
                : null}

              {this.props.charge_vat_number_canada !== false ? (
                this.props.proviencebasedoncharge === true ? (
                  <div className="vat-question-panel">
                    <p>
                    Do you charge Tax/GST/HST/PST to customers in Canada if they
                  do not provide a valid VAT/GST/Business Tax Number?
                </p>
                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="charge_no_vat_canada_1"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.charge_no_vat_canada_1}
                    />
                  </div>
                ) : null
              ) : null}

              {this.props.charge_vat_number_canada !== false ? (
                this.props.proviencebasedoncharge !== false ? (
                  this.props.charge_no_vat_canada_1 === true ? (
                    <div className="vat-question-panel">
                      <p>Do you charge Tax/GST/HST/PST based on province?</p>
                      <RadioButtonGroup
                        onChange={this.props.onChangeHandleRadioBox}
                        field="provience_basedon_vat_no_charge_1"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.props.provience_basedon_vat_no_charge_1}
                      />
                    </div>
                  ) : null
                ) : 
                  null
              ) : null}

              {this.props.charge_vat_number_canada !== false
                ? this.props.proviencebasedoncharge !== false
                  ? this.props.charge_no_vat_canada_1 !== false
                    ? this.props.provience_basedon_vat_no_charge_1 ===
                    true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                          </p>
                          </div>
                          <div className="country-list">
                          <MultiSelectCheckBox
                          field="STATES"
                          data={this.props.canadaState}
                          selectCountry={(props) =>
                            this.props.SelectCheckBoxBasedOnProvince_2(props)
                          }
                        />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                        <VatTable
                        data={this.props.vatCanadaProviceApiData_2}
                        editHandler={(value) =>
                            this.props.EditHandler_for_ca_2(value)
                        }
                        countryType="CA"
                      />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.props.charge_vat_number_canada !== false
                ? this.props.proviencebasedoncharge !== false
                  ? this.props.charge_no_vat_canada_1 !== false
                    ? this.props.provience_basedon_vat_no_charge_1 ===
                    false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default Tax/GST/HST/PST on
                              OaMetrix. You can override default VAT rate to
                              apply your own rate.
                          </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              data={this.props.vatforca}
                              selectCountry={(props) =>
                                this.props.SelectCheckBoxBasedCanadaCountry_2(
                                  props
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            editHandler={(value) =>
                                this.props.EditHandler_country_ca_2(value)
                            }
                            data={this.props.vatCanadaCountryApiData_2}
                            countryType="CA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}

              {/* IF PROVIENC FALSE  */}

              {this.props.charge_vat_number_canada !== false ? (
                this.props.proviencebasedoncharge !== true ? (
                  this.props.proviencebasedoncharge === false ? (
                    <div className="vat-question-panel">
                      <p>
                        Do you charge Tax/GST if customer’s has no valid
                        Tax/GST number?
                  </p>
                      <RadioButtonGroup
                        onChange={this.props.onChangeHandleRadioBox}
                        field="charge_no_vat_canada_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.props.charge_no_vat_canada_2}
                      />
                    </div>
                  ) : null
                ) : null
              ) : null}

              {this.props.charge_vat_number_canada !== false ? (
                this.props.proviencebasedoncharge !== true ? (
                  this.props.charge_no_vat_canada_2 === true ? (
                    <div className="vat-question-panel">
                      <p>Do you charge Tax/GST/HST/PST based on province?</p>
                      <RadioButtonGroup
                        onChange={this.props.onChangeHandleRadioBox}
                        field="provience_basedon_vat_no_charge_2"
                        options={[
                          { id: true, name: "Yes" },
                          { id: false, name: "No" },
                        ]}
                        value={this.props.provience_basedon_vat_no_charge_2}
                      />
                    </div>
                  ) : null
                ) : 
                  null
              ) : null}

              {this.props.charge_vat_number_canada !== false
                ? this.props.proviencebasedoncharge !== true
                  ? this.props.charge_no_vat_canada_2 !== false
                    ? this.props.provience_basedon_vat_no_charge_2 ===
                    true && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default GST/VAT/Tax rate on
                              OaMetrix. You can override default GST/VAT/Tax
                              rate to apply correct one.
                          </p>
                          </div>
                          <div className="country-list">
                          <MultiSelectCheckBox
                          field="STATES"
                          data={this.props.canadaState}
                          selectCountry={(props) =>
                            this.props.SelectCheckBoxBasedOnProvince_3(props)
                          }
                        />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                        <VatTable
                        data={this.props.vatCanadaProviceApiData_3}
                        editHandler={(value) =>
                            this.props.EditHandler_for_ca_3(value)
                        }
                        countryType="CA"
                      />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}
              {this.props.charge_vat_number_canada !== false
                ? this.props.proviencebasedoncharge !== true
                  ? this.props.charge_no_vat_canada_2 !== false
                    ? this.props.provience_basedon_vat_no_charge_2 ===
                    false && (
                      <div className="vat-country">
                        <div className="vat-country-details">
                          <div className="vat-info">
                            <p>
                              Here is the default Tax/GST/HST/PST on
                              OaMetrix. You can override default VAT rate to
                              apply your own rate.
                          </p>
                          </div>
                          <div className="country-list">
                            <MultiSelectCheckBox
                              field="STATES"
                              data={this.props.vatforca}
                              selectCountry={(props) =>
                                this.props.SelectCheckBoxBasedCanadaCountry_3(
                                  props
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 vat-margin">
                          <VatTable
                            editHandler={(value) =>
                                this.props.EditHandler_country_ca_3(value)
                            }
                            data={this.props.vatCanadaCountryApiData_3}
                            countryType="CA"
                          />
                        </div>
                      </div>
                    )
                    : null
                  : null
                : null}

              {/*No charge*/}

              {this.props.charge_vat_number_canada === false && (
                <div className="vat-question-panel">
                  <p>
                    Do you charge Tax/GST if customer’s has no valid Tax/GST
                    number?
              </p>
                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="charge_no_vat_canada_3"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.charge_no_vat_canada_3}
                  />
                </div>
              )}
              {this.props.charge_vat_number_canada !== true
                ? this.props.charge_no_vat_canada_3 === true && (
                  <div className="vat-question-panel">
                    <p>Do you charge Tax/GST based on province?</p>

                    <RadioButtonGroup
                      onChange={this.props.onChangeHandleRadioBox}
                      field="provience_basedon_vat_no_charge_3"
                      options={[
                        { id: true, name: "Yes" },
                        { id: false, name: "No" },
                      ]}
                      value={this.props.provience_basedon_vat_no_charge_3}
                    />
                  </div>
                )
                : null}
              {this.props.charge_vat_number_canada !== true ? (
                this.props.charge_no_vat_canada_3 === true &&
                  this.props.provience_basedon_vat_no_charge_3 === true ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default Tax/GST/HST/PST on OaMetrix. You
                            can override default VAT rate to apply your own
                            rate.
                    </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.props.canadaState}
                            selectCountry={(props) =>
                                this.props.SelectCheckBoxBasedOnProvince_4(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.vatCanadaProviceApiData_4}
                          editHandler={(value) =>
                            this.props.EditHandler_for_ca_4(value)
                          }
                          countryType="CA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
              {this.props.charge_vat_number_canada !== true ? (
                this.props.charge_no_vat_canada_3 === true &&
                  this.props.provience_basedon_vat_no_charge_3 === false ? (
                    <div className="vat-country">
                      <div className="vat-country-details">
                        <div className="vat-info">
                          <p>
                            Here is the default Tax/GST/HST/PST on OaMetrix. You
                            can override default VAT rate to apply your own
                            rate.
                    </p>
                        </div>
                        <div className="country-list">
                          <MultiSelectCheckBox
                            field="STATES"
                            data={this.props.vatforca}
                            selectCountry={(props) =>
                              this.props.SelectCheckBoxBasedCanadaCountry_4(props)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 vat-margin">
                        <VatTable
                          data={this.props.vatCanadaCountryApiData_4}
                          editHandler={(value) =>
                            this.props.EditHandler_country_ca_4(value)
                          }
                          countryType="CA"
                        />
                      </div>
                    </div>
                  ) : null
              ) : null}
            </div>
          </div>
        )
    }
}


export const VatCanada= Canada