import { Dashboard } from "../components/views";
import {
  ArticleFull,
  // DepositFund,
  // oaAccountApproval,
  // oaDealApproveList,
  ArticleDecForm,
  ArticalAprForm,
  // OrgOffsetFundList,
  // OrgTokenList,
  // DepositList,
  // AddApcFund,
  // ApcFundList,
  // ApcRequestList,
  UserList
} from "../components/views/university";
import {
  AddConsortiumMember,
  ConsortiumMemberList,
  Organizations,
  OrgSingle,
} from "../components/views/consortia";
import { userKey } from "../components/common/userPermission";

export const root = {
  dashboard: "/",
  publishers: "/consortia-publishers",
  users: "/users",
  articles: "/articles",
  apc_fund: "/apc-fund",
  consortium: "/consortium",
  author_request: "/author-request",
  pub_fund: "/publisher-fund",
  oa_account: "/oa-account",
  article_approval: "/article-approval",
  article_decline: "/article-decline",
  token: "/institute",
  transaction: "/transaction",
  books: "/books",
};

export const ConsortiaSidebar = [
  {
    icon: "mdi-view-dashboard",
    label: "Dashboard",
    to: root.dashboard,
    exact: true,
    component: Dashboard,
    role: "public",
  },
  {
    icon: " mdi-bank",
    label: "Publishers",
    to: root.publishers,
    exact: true,
    component: Organizations,
    value: "manage_publishers",
    role: "private",
  },
  {
    to: root.publishers + "/:id",
    exact: true,
    component: OrgSingle,
    isHide: true,
  },
  {
    icon: " mdi-pencil-box",
    label: "Articles",
    to: root.articles,
    exact: true,
    component: ArticleFull,
    role: "private",
    value: userKey.manage_articles
  },
  {
    icon: " mdi-pencil-box",
    label: "ArticleApprovalForm",
    to: root.article_approval + "/:id",
    exact: true,
    component: ArticalAprForm,
    isHide: true,
  },
  {
    icon: " mdi-pencil-box",
    label: "Article Decline Form",
    to: root.article_decline + "/:id",
    exact: true,
    component: ArticleDecForm,
    isHide: true,
  },
  // {
  //     icon: 'mdi mdi-cash-usd',
  //     label: 'APC fund',
  //     exact: true,
  //     to: root.apc_fund,
  //     content: [
  //         {
  //             label: 'Add fund',
  //             to: '/add-apc-fund',
  //             component: AddApcFund,
  //             exact: true,
  //         },
  //         {
  //             label: 'APC fund list',
  //             to: '/apc-fund-list',
  //             component: ApcFundList,
  //             exact: true,
  //         },
  //     ],
  // },
  {
    icon: "mdi mdi-account-plus",
    label: "Add member",
    exact: true,
    to: root.consortium,
    role: "private",
    isHide:true,
    content: [
      {
        label: "Add consortium member",
        to: "/add-consortium-member",
        component: AddConsortiumMember,
        exact: true,
        role: "private",
        value: "manage_constoria_members",
      },
      {
        label: "Consortium members",
        to: "/consortium-member-list",
        component: ConsortiumMemberList,
        exact: true,
        role: "private",
        value: "manage_constoria_members_list",
      },
    ],
  },
  // {
  //     icon: 'mdi-cash-multiple',
  //     label: 'Publisher fund',
  //     exact: true,
  //     to: root.pub_fund,
  //     content: [
  //         {
  //             label: 'Deposit fund',
  //             to: '/deposit-fund',
  //             component: DepositFund,
  //             exact: true,
  //         },
  //         {
  //             label: 'Deposit fund list',
  //             to: '/deposit-list',
  //             component: DepositList,
  //             exact: true,
  //         },
  //     ],
  // },
  // {
  //     icon: 'mdi-key-variant',
  //     label: 'Tokens & Offsets',
  //     to: root.token,
  //     exact: true,
  //     content: [
  //         {
  //             label: 'Tokens',
  //             to: '/org-token-list',
  //             component: OrgTokenList,
  //         },
  //         {
  //             label: 'Offset funds',
  //             to: '/org-offset-fund-list',
  //             component: OrgOffsetFundList,
  //         },
  //     ],
  // },
  // {
  //     icon: 'mdi-book-multiple',
  //     label: 'Author request',
  //     exact: true,
  //     to: root.author_request,
  //     content: [
  //         {
  //             label: 'APC request list',
  //             to: '/apc-request-list',
  //             component: ApcRequestList,
  //         },
  //     ],
  // },

  // {
  //     icon: 'ion-gear-b',
  //     label: 'Action',
  //     exact: true,
  //     to: root.oa_account,
  //     content: [
  //         {
  //             label: 'OA account requests',
  //             to: '/oa-account-request',
  //             component: oaAccountApproval,
  //         },
  //         // {
  //         //   label: 'Offset fund',
  //         //   to: '/add-apc-fund',
  //         // },
  //         // {
  //         //   label: 'Tokens',
  //         //   to: '/add-apc-fund',
  //         // },
  //         {
  //             label: 'Correction request',
  //             to: '/add-apc-fund',
  //         },
  //         {
  //             label: 'OA account list',
  //             to: '/oa-account-list',
  //             component: oaDealApproveList,
  //         },
  //         // {
  //         //   label: 'Offset list',
  //         //   to: '/add-apc-fund',
  //         // },
  //         // {
  //         //   label: 'Tokens list',
  //         //   to: '/add-apc-fund',
  //         // },
  //         {
  //             label: 'Correction list',
  //             to: '/add-apc-fund',
  //         },
  //     ],
  // },
  // {
  //     icon: 'mdi-book-multiple',
  //     label: 'Transaction',
  //     exact: true,
  //     to: root.transaction,
  //     content: [
  //         {
  //             label: 'Deposit fund',
  //             to: '/deposit-fund',
  //             component: TrxDeposit,
  //         },
  //         {
  //             label: 'Offset fund',
  //             to: '/offset-fund',
  //             component: TrxOffset,
  //         },
  //         {
  //             label: 'Token',
  //             to: '/token',
  //             component: TrxToken,
  //         },
  //     ],
  // },

  {
    icon: "mdi-account-multiple",
    label: "Users",
    exact: true,
    role: "private",
    value: "manage_users",
    to: root.users,
    content: [
      {
        label: "User List",
        to: "/user-list",
        role: "private",
        value: "manage_users",
        component: UserList,
      },
    ],
  },
];
