import React from "react";
import { connect } from "react-redux";
import { Tooltip ,Button as SendAdhocBtn} from "antd";

import { withRouter } from "react-router-dom"
import moment from "moment";
import {
    articleButton,
} from "../../../../../openaccess/actions";

import ViewMessage from "./authorMessage"

class ExpandTable extends React.Component {
    state = {
   
    };


    enterLoading = index => {
        this.setState(({ sendAdhocBtnLoading }) => {
       
          return {
            sendAdhocBtnLoading: true,
          };
        });
        setTimeout(() => {
          this.setState(({ sendAdhocBtnLoading }) => {
       
            return {
                sendAdhocBtnLoading: false,
            };
          });
        }, 3000);
      };
    setActive = () => {
        this.setState({
            active: !this.state.active,
        });
    };
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
    }

    handleArticleActionBtn = (id)=> {
        this.setActive()
        this.setState(
            {
                active: !this.state.active,
            },
            async () => {
                let data;
                if (this.state.active === true) {
                    data = await this.props.articleButton(id)
;
                }
                this.setState({
                    actions: data && data.actions,
                });
            }
        );
    };

    apcHandlerArticle = (id)=> {
        if (this.props.group === 'AUTHOR') {

            this.props.history.push({
                pathname: '/my-article',
                apc_article_id: id,
            });
        } else if (this.props.group === 'PUBLISHER') {
            this.props.history.push({
                pathname: '/article/publisher-articles',
                apc_article_id: id,
            });
        } else {
            this.props.history.push({
                pathname: '/articles',
                apc_article_id: id,
            });
        }

    }
    addInvidualMember = (e) => {
        this.setState({
            isIndividualMember: !this.state.isIndividualMember,
        });
    };
    onCloseInvidualMember = () => {
        this.setState({
            isIndividualMember: false,
        });
    };

    capitalize(s) {
        return (s&& s[0] && s[0].toUpperCase()) + (s&& s.slice(1));
    }

    render() {
        const { item, column, group } = this.props;
        return (
            <div className="data-table apc-request" >
                <div className="row-table">
                    <div className="row table-data">
                        <div className={column[1]}>
                            <p>{item.date
                                ? moment(item.date).format("Do MMM YYYY")
                                : "-"} </p>  </div>
                        {group === "AUTHOR" && <div className={column[1]}>
                            <p> {item.category}</p>
                        </div>}
                        <div className={group === "AUTHOR" ? column[2] : group === "PUBLISHER" ? column[4] : column[2]}>
                            <p style={{ textDecoration: "underline", cursor: "pointer", color: "blue" }} onClick={() => this.apcHandlerArticle(item.id)}>{item.title}</p>
                        </div>

                        {
                            group === 'AUTHOR' && <div className={column[2]} >
                                <p style={{ textAlign: "left" }}> {item.organisation_name}</p>
                            </div>
                        }
                        {group !== 'AUTHOR' && <div className={column[1]}>
                            <p> {item.author_name}</p>
                        </div>}

                        {group === 'INSTITUTION' && <div className={column[0]}>
                            <p> {item.licence}</p>
                        </div>}
                        {group === 'AUTHOR' && <div className={column[0]}>
                            <p> {item.licence}</p>
                        </div>}


                        {group === 'PUBLISHER' && <div className={column[1]}>
                            <p> {item.licence}</p>
                        </div>}

                        <div className={column[0]} >


                            <span
                                className="cursor-pointer"
                                onClick={() => this.addInvidualMember()}
                                title="View"
                            >
                                <i className="mdi mdi-eye"></i>
                            </span>

                            {this.state.isIndividualMember && <ViewMessage author={item.author_name} note={item.note} onClose={this.onCloseInvidualMember} />}

                        </div>


                        {(group !== 'AUTHOR' && group !== 'PUBLISHER') && <div className={group !== 'AUTHOR' && group !== 'PUBLISHER' ? column[2] : column[2]}>


                            {item.status === 'pending' && <button
                                className="button-expend apc-fund-list"

                                style={{
                                    cursor: "pointer",
                                    float: "left",
                                    marginRight: 5,
                                    marginLeft:35
                                }}
                                onClick={() => this.handleArticleActionBtn(item.id)}
                            >
                                <i
                                    className={
                                        this.state.active === true
                                            ? "mdi mdi-chevron-up"
                                            : "mdi mdi-chevron-down"
                                    }
                                />
                            </button>}

                            {item.status !== 'pending' && <button className={`btn ${item.status === "approved"? "btn-green" : "btn-red"}`}> {this.capitalize(item.status ||'')} </button>}

                            {
                                this.state.active === true ? <ul className="apc-data-table-btn">

                                    {
                                        this.state.actions &&
                                        this.state.actions.map((data, key) => {
                                            return (
                                                <div key={key}>
                                                    {data.action === "approve" &&  <li className="send-adhoc-btn" onClick={() =>
                                            this.props.onActionHandler(
                                                data.action
                                            )}>   <SendAdhocBtn type="" loading={this.state.sendAdhocBtnLoading} onClick={() => this.enterLoading()}>
{data.label}                                     </SendAdhocBtn>
                                      </li>}
                                                    {
                                                        data.action === "decline_author_apc_fund_request" && <li style={{cursor:"pointer",textDecoration: "underline" }} onClick={() => this.props.onActionHandler && this.props.onActionHandler(data.action, item.apc_request_id)}>{data.label}</li>
                                                    }

                                                    {
                                                        data.action === "ARTICLE_PAYMENT" && <li style={{ textDecoration: "underline" }} onClick={() => this.props.onActionHandler && this.props.onActionHandler(data.action)}  > {data.label}</li>}

                                                    {
                                                        data.action === "ARTICLE_INVOICE" &&<li style={{ textDecoration: "underline" }} onClick={() => this.props.onActionHandler && this.props.onActionHandler(data.action)}  > {data.label}</li>}


                                                    {  data.action === "no_action" &&
                                                        <li className="uni-action-show-button" > <Tooltip placement="topLeft" title={data.label} arrowPointAtCenter>
                                                            {data.label}   </Tooltip></li>
                                                    }




                                                </div>

                                            );
                                        })
                                    }

                                </ul>:null}
                        </div>}




                    </div>

                </div >
            </div >
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.auth || {},
    group: state.auth.group,
});

const mapDispatchToProps = (dispatch) => ({
    articleButton: (payload) => dispatch(articleButton(payload)),

});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpandTable));
