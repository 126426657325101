import config, {
  GET_QS_DASHBOARD_LIST,
  GET_QS_DASHBOARD,
  CREATE_UPDATE_QS_DASHBOARD,
  DELETE_QS_DASHBOARD,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get OA Discipline list
export const getQsDashboardListAction = (payload) => ({
  type: GET_QS_DASHBOARD_LIST,
  payload,
});

// Get OA Discipline
export const getQsDashboardAction = (payload) => ({
  type: GET_QS_DASHBOARD,
  payload,
});

// Create-Update OA Discipline
export const createUpdateQsDashboardAction = (payload) => ({
  type: CREATE_UPDATE_QS_DASHBOARD,
  payload,
});

// Delete OA Discipline
export const deleteQsDashboardAction = (payload) => ({
  type: DELETE_QS_DASHBOARD,
  payload,
});

export const getQsDashboardList = (payload = {}) => {
  return (dispatch) => {
    let url = `${config.endpoint.quicksight_dashboard}`;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getQsDashboardListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA single discipline
export const getQsDashboard = (id) => {
  return (dispatch) => {
    let url = config.endpoint.quicksight_dashboard + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getQsDashboardAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create-Update OA discipline

export const createUpdateQsDashboard = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.quicksight_dashboard;
    let apiRequest = "";
    return new Promise((resolve, reject) => {
      if (id) {
        url += `${id}/`;
        apiRequest = api.put(url, payload);
      } else {
        apiRequest = api.post(url, payload);
      }

      apiRequest
        .then((res) => {
          dispatch(createUpdateQsDashboardAction(res.data));
          resolve(Object.assign(res.data, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Delete OA discipline
export const deleteQsDashboard = (id) => {
  return (dispatch) => {
    let url = config.endpoint.quicksight_dashboard + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          dispatch(deleteQsDashboardAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
