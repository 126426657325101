import React from 'react';
import BaseComponent from '../../../../../core/components';
import { UserList } from '../../hub';

class Add extends BaseComponent {
    render() {
        return <UserList/>
    }
}

export const PubUserList = Add;