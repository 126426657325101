import React from "react";
import BaseComponent from "../../../../core/components";
import { InputBox } from "../../../../core/components/common";
import { postForgotPasswordRequest } from "../../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";

class Password extends BaseComponent {
  state = {
    errMsg: null,
    forgot_password: {},
    emailError: true
  };
  onChange = (value) => {
    if (value.email === '' || value.email == null) {
      this.setState({
        emailError: true
      })
    } else {
      this.setState({
        emailError: false,
        email: value.email
      })
    }

  };

  onSubmitHandler = async (e) => {
    e.preventDefault();
    if (this.state.email !== "") {
      await this.props.postForgotPasswordRequest({ email: this.state.email });

        this.props.data &&
        this.props.data.message &&
        this.setState({
          errMsg: this.props.data.message,
        });
      if (this.props.data.success) {
     let msg = `Verification email has been sent to your email, please check your email to reset the password.`;
      this.props.data.success &&
        confirmAlert({
          title: "",
          message: msg,
          buttons: [
            {
              label: "Close",
              onClick: () => this.props.history.push("/signin"),
            },
          ],
        });
      }
    } else {
      this.setState({
        errMsg: ""
      })
    }

  };


  render() {
    return (
      <form className="forget-password">
        <div className="lock">
          <img src="/assets/images/lock.jpg" alt="" width="100" />
        </div>
        <div className="forget-right">
          <div className="forgot-content">
            <h3>Request a password Reset</h3>
          </div>

          <div className="d-flex justify-content-center">
            <InputBox
              InputType="email"
              onChange={this.onChange}
              field="email"
              placeholder="please enter your email"
              isRequired={true}
              className="col-md-8"
              defaultValue={this.state.email}
            />
          </div>
          <div className="d-flex justify-content-center" style={{marginTop:10}}>
          <span className="text-danger"> {this.state.errMsg} </span>
          </div>

          <div className="text-center d-flex justify-content-center">
      
            <button
              style={{marginRight:10}}
              disabled={this.state.emailError}
              className="btn btn-primary waves-effect waves-light"
              onClick={this.onSubmitHandler}
            >
              Send
            </button>
            <button
              className="btn btn-danger waves-effect waves-light"
              onClick={()=>this.props.history.push("/")}
            >
              Close
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: (state.auth && state.auth.data) || false,
});

const mapDispatchToProps = (dispatch) => ({
  postForgotPasswordRequest: (data) =>
    dispatch(postForgotPasswordRequest(data)),
});

export const forgetPword = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Password)
);
