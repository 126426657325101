import config, { 
    GET_ARTICLE_TAB_CONTROL_CONFIG_LIST, 
    GET_ARTICLE_TAB_CONTROL_CONFIG, 
    CREATE_UPDATE_ARTICLE_TAB_CONTROL_CONFIG, 
    DELETE_ARTICLE_TAB_CONTROL_CONFIG,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get article tab control config list
export const getArticleTabControlConfigListAction = (payload) => ({
    type: GET_ARTICLE_TAB_CONTROL_CONFIG_LIST,
    payload
});

// Get article tab control config
export const getArticleTabControlConfigAction = (payload) => ({
    type: GET_ARTICLE_TAB_CONTROL_CONFIG,
    payload
});

// Create-Update article tab control config
export const createUpdateArticleTabControlConfigAction = (payload) => ({
    type: CREATE_UPDATE_ARTICLE_TAB_CONTROL_CONFIG,
    payload
});

// Delete article tab control config
export const deleteArticleTabControlConfigAction = (payload) => ({
    type: DELETE_ARTICLE_TAB_CONTROL_CONFIG,
    payload
});


export const getArticleTabControlConfigList = (payload) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_tab_control_config;
        let params = {};

        if (payload.pageNum && payload.pageSize) {
            params['page'] = payload.pageNum;
            params['page_size'] = payload.pageSize;
        } else if (payload.pageNum) {
            params['page'] = payload.pageNum;
        } else if (payload.pageSize) {
            params['page_size'] = payload.pageSize;
        }
        
        if (payload.organisation) {
            params['organisation_id'] = payload.organisation;      
        }

        if (!params.page_size) {
            params['page_size'] = 99999;
        }

        url += `?${serialize(params)}`;

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getArticleTabControlConfigListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}


// Get article tab control config
export const getArticleTabControlConfig = (id) => { 
    return (dispatch) => {
        let url = config.endpoint.organisation_article_tab_control_config + `${id}/`;
        
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getArticleTabControlConfigAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Create-Update article tab control config

export const createUpdateArticleTabControlConfig = (payload, id) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_tab_control_config;
        let apiRequest = '';
        return new Promise((resolve, reject) => {
            if (id) {
                url += `${id}/`;
                apiRequest = api.put(url, payload);
            } else {
                apiRequest = api.post(url, payload);
            }

            apiRequest.then((res) => {
                dispatch(createUpdateArticleTabControlConfigAction(res.data));
                resolve(Object.assign(res.data, { status: true }));
            }).catch(e => {
                reject(e)
            });
        })
    }
}

// Delete article tab control config
export const deleteArticleTabControlConfig = (id) => {
    return (dispatch) => {
        let url = config.endpoint.organisation_article_tab_control_config + `${id}/`;
        
        return new Promise((resolve, reject) => {
            api.delete(url).then((res) => {
                dispatch(deleteArticleTabControlConfigAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

