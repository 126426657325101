import React from "react";
import moment from "moment";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { Button } from "../../../../../core/components/common";

import {
  InputBox,
  SelectBox,
  RadioButtonGroup,
  OaDatePicker,
  PopupBox,
  ModalSelectBox,
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import { serialize } from "../../../../../core/lib";
import {
  getAllCurrencyList,
  getPublicatonTypeList,
  getPublicationList,
  getContentTypeList,
  getOaDisciplineList,
  getOaSubjectList,
  getSocietyList,
  createUpdateSocietyDiscount,
  getSocietyDiscount,
} from "../../../../actions";

const format = "Do MMM YYYY";

const offerTypes = [
  { id: "DISCOUNT", name: "Discount" },
  { id: "AMOUNT", name: "Amount" },
  { id: "SPECIAL_APC", name: "Special APC" },
];

class Society extends BaseComponent {
  state = {
    societyDiscount: {},
    options: {},
    checked: false
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.societyDiscount.publication_types &&
      this.state.societyDiscount.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.societyDiscount.publication_types[0] !== "all"
          ? this.state.societyDiscount.publication_types.join(",")
          : "";
    }

    if (this.state.disciplines && this.state.disciplines.length > 0) {
      filterParams.discipline_ids =
        this.state.disciplines[0] !== "all"
          ? this.state.disciplines.join(",")
          : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publication_list: this.props.publication_list.results,
      });
  };

  onChangeHandler = (value) => {
    if (value.hasOwnProperty("offer_type")) {
      this.setState({
        ...value,
      });
    }

    this.setState(
      (prevState) => {
 

        if (value.hasOwnProperty("offer_type")) {
          Object.assign(prevState.societyDiscount, { offer_currency: null });
        }

        Object.assign(prevState.societyDiscount, value);
      },
      () => {
        if (value.hasOwnProperty("publication_types")) {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  async componentDidMount() {
    const id = this.props.match.params.id;

    this.props.getSocietyList();
    this.props.getOaDisciplineList();
    this.props.getAllCurrencyList();
    this.props.getPublicatonTypeList();
    this.props.getContentType();
    let payload = {};

    if (id) {
      await this.props.getSocietyDiscount(id);
      let societyDiscountData = this.props.society_discount;

      if (societyDiscountData) {
        let societyDiscount = {
          show_badge: societyDiscountData && societyDiscountData.show_badge,

          society: societyDiscountData.society,
          content_types: societyDiscountData.content_types,
          publication_types: societyDiscountData.publication_types,
          disciplines: societyDiscountData.disciplines,
          subjects: societyDiscountData.subjects,
          publications: societyDiscountData.publications,
          offer_type: societyDiscountData.offer_type,
          offer_currency: societyDiscountData.offer_currency,
          offer_amount: societyDiscountData.offer_amount,
          offer_expiry: societyDiscountData.offer_expiry,
          options: societyDiscountData.options,
          society_name:societyDiscountData.society_name
        };
        const { option_2 } = societyDiscountData.options || {};
        this.setState({

          societyDiscount: societyDiscount,
          offer_expiry:
            (societyDiscountData.offer_expiry &&
              moment(societyDiscountData.offer_expiry)) ||
           undefined,
          disciplines: societyDiscountData.disciplines || [],
          subjects: societyDiscountData.subjects || [],
          publications: societyDiscountData.publications || [],
          content_types: societyDiscountData.content_types || [],
          publication_types: societyDiscountData.publication_types || [],
          options: societyDiscountData.options,
          offer_type: societyDiscountData.offer_type,
          checked: option_2 === "na" ? true : false,
          option_2: option_2,
        });
      }

      if (
        societyDiscountData.disciplines &&
        societyDiscountData.disciplines.length > 0
      ) {
        payload["discipline"] = societyDiscountData.disciplines.join(",");
      }
    }

    await this.props.getOaSubjectList(payload);

    this.props.oa_subjects &&
      this.setState({
        oa_subject_list: this.props.oa_subjects.results,
      });

    this.getPublicationListOnParameterChange();
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.societyDiscount, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });

        if (fieldName === "disciplines") {
          if (data.length > 0) {
            let payload = {
              discipline: data[0] !== "all" ? data.join(",") : "",
            };

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }

          this.getPublicationListOnParameterChange();
        } else if (fieldName === "subjects") {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "disciplines":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "subjects":
        this.setState({
          isShowSubjectModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "disciplines":
        if (fieldValues && fieldValues.length > 0) {
          labelText = `${fieldValues.length} disciplines selected`;
        } else {
          labelText = `All disciplines selected`;
        }
        break;
      case "subjects":
        if (fieldValues && fieldValues.length > 0) {
          labelText = `${fieldValues.length} subjects selected`;
        } else {
          labelText = `All subjects selected`;
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText = `${fieldValues.length} publications selected`;
        } else {
          labelText = `All publications selected`;
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText = `${fieldValues.length} content types selected`;
        } else {
          labelText = `All content types selected`;
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  onChangeRadioButtonHandler = (value) => {
    this.setState((prevState) => {
      let options = {};
      if (prevState.societyDiscount.options) {
        options = prevState.societyDiscount.options;
      }

      Object.assign(options, value);

      Object.assign(prevState.societyDiscount, { options: { ...options } });
    });
  };
  onChangeRadioBadageHandler = (value) => {
    this.setState((prevState) => {
      Object.assign(prevState.societyDiscount, { ...value });
    });
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();
    let data = {};
    Object.assign(data, this.state.societyDiscount);
    if (data.offer_expiry) {
      Object.assign(data, {
        offer_expiry: moment(this.state.societyDiscount.offer_expiry).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        offer_expiry:null
      });
    }
    let mainData;
    if (this.state.checked === true) {
      mainData = {
        ...data.options,
        option_2: "na",
      };
    } else {
      mainData = {
        ...data.options,
        option_2: this.state.societyDiscount.options && this.state.societyDiscount.options.option_2 !== "na" ? this.state.societyDiscount.options.option_2 : null
      }
    }
    if (
      !data.society ||
      !data.offer_type ||
      (this.state.societyDiscount.offer_type !== "DISCOUNT" &&
        !data.offer_currency) ||
      !data.offer_amount
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!data.society && <p>Society is required.</p>}
            {!data.offer_type && <p>Offer type is required.</p>}
            {!data.offer_currency && <p>Currency is required.</p>}
            {!data.offer_amount && <p>Amount is required.</p>}
          </div>
        ),
      });
    } else {


      if (mainData !== null && Object.keys(mainData).length > 0) {
        data.options = JSON.stringify(mainData);
      } else {
        data.options = null;
      }

      const id = this.props.match.params.id;

      await this.props.createUpdateSocietyDiscount(data, id);

      if (this.props.society_discount.success) {
        this.props.history.push("/settings/society-discount-list");
      }
    }
  };
  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });

  };

  onChangeInputBox = (value) => {
    this.setState({
      option_2: value,
    });
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    const { societyDiscount } = this.state;
    // const { option_1, option_3, option_4, option_5, option_6 } =
      // this.state.options || {};
    return (
      <HubContent title="Edit society discount" className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="x"
                  onClick={() => this.props.history.push("/settings/society-discount-list")}
                />
              </div>
              <div className="card-body">

                <div className="tab-content">
                  <form action="">
                    <div className="form-group row">
            <div className="col-form-label col-sm-2"
            >
                        <label >
                        Society Name
            </label>
                      </div>
                      
                      <div className="col-sm-4">
                        <p>{societyDiscount && societyDiscount.society_name }</p>
                      </div>

                      <SelectBox
                          labelInfo={true}
                          labelInfoTitle="Select Publication Types"
                        label="Publication types"
                        onChange={this.onChangeHandler}
                        field="publication_types"
                        className="col-sm-4"
                        data={this.props.publication_type}
                        defaultValue={this.state.publication_types}
                        isRequired={false}
                        multiple={true}
                      />
                    </div>

                    <div className="form-group row">
                      <label className=" col-form-label col-sm-2">
                        Disciplines{" "}
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("disciplines")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("disciplines")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowDisciplineModal && (
                        <ModalSelectBox
                          title="Select disciplines"
                          label="Disciplines"
                          field="disciplines"
                          modalClass="col-md-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            this.props.oa_disciplines && [
                              { id: "all", name: "All disciplines" },
                              ...this.props.oa_disciplines.results,
                            ]
                          }
                          defaultValue={this.state.disciplines}
                          isRequired={false}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}

                      <label className=" col-form-label col-sm-2">
                        Subjects{" "}
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("subjects")}
                        <button
                          onClick={() => this.onModalSelectBoxShow("subjects")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowSubjectModal && (
                        <ModalSelectBox
                          title="Select subjects"
                          label="Subjects"
                          field="subjects"
                          modalClass="col-md-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            (this.state.oa_subject_list && [
                              { id: "all", name: "All subjects" },
                              ...this.state.oa_subject_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.subjects}
                          isRequired={true}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>
                    <div className="form-group row">
                      <label className=" col-form-label col-sm-2">
                        Content types
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("content_types")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("content_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowContentTypeModal && (
                        <ModalSelectBox
                          title="Select content types"
                          label="Content types"
                          field="content_types"
                          modalClass="col-md-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            (this.props.content_type && [
                              { id: "all", name: "All content types" },
                              ...this.props.content_type,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.content_types}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                      <label className=" col-form-label col-sm-2">
                        Publications
                      </label>
                      <div
                        className="col-sm-4"
                        style={{ position: "relative" }}
                      >
                        {this.showSelectItemsLabel("publications")}
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publications")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationModal && (
                        <ModalSelectBox
                          title="Select publications"
                          label="Publications"
                          field="publications"
                          modalClass="col-md-6"
                          labelClass="col-md-4"
                          className="col-sm-8"
                          data={
                            (this.state.publication_list && [
                              { id: "all", name: "All publications" },
                              ...this.state.publication_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.publications}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>
                    <div className="form-group row">
                      <SelectBox
                        label="Offer"
                        onChange={this.onChangeHandler}
                        field="offer_type"
                        data={offerTypes}
                        labelInfo={true}
                        labelInfoTitle="Select offer Type"
                        isRequired={false}
                        defaultValue={societyDiscount.offer_type}
                      />

                      {this.state.offer_type === "DISCOUNT" ? (
                        <label className="col-form-label col-sm-2">%</label>
                      ) : (
                          <SelectBox
                          labelInfo={true}
                          labelInfoTitle="Select Offer Currency Type"
                            label="Currency"
                            onChange={this.onChangeHandler}
                            field="offer_currency"
                            data={this.props.currency_list}
                            isRequired={false}
                            defaultValue={societyDiscount.offer_currency}
                          />
                        )}
                    </div>
                    <div>
                      <div className="form-group row">
                        <InputBox
                          label="Amount/Dis"
                          labelInfo={true}
                          labelInfoTitle="Enter  Amount/Dis (No percent sign or decimal)"
                          onChange={this.onChangeHandler}
                          field="offer_amount"
                          placeholder="Amount/Dis (No percent sign or decimal) "
                          defaultValue={societyDiscount.offer_amount}
                          InputType="number"
                        />

                        {this.state.offer_expiry && (
                          <OaDatePicker
                            className="col-sm-4"
                            label="Offer expiry"
                            field="offer_expiry"
                            onChange={this.onChangeHandler}
                            placeholder="Select offer expiry"
                            showTime={false}
                            labelInfo={true}
                            labelInfoTitle="Select offer expiry"
                            format={format}
                            defaultValue={this.state.offer_expiry}
                          />
                        )}
  {!this.state.offer_expiry && (
                          <OaDatePicker
                            className="col-sm-4"
                            label="Offer expiry"
                            field="offer_expiry"
                            labelInfo={true}
                            labelInfoTitle="Select offer expiry"
                            onChange={this.onChangeHandler}
                            placeholder="Select offer expiry"
                            showTime={false}
                            format={format}
                          />
                        )}
                      
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society">
                      <div className="col-md-8">
                        <p>
                          Would you like to display society discount icon for authors in article?
                        </p>
                      </div>
                      <div className="col-md-4">
                        {societyDiscount.show_badge !== undefined &&
                          < RadioButtonGroup
                            onChange={this.onChangeRadioBadageHandler}
                            field="show_badge"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={societyDiscount.show_badge}
                          />}
                      </div>
                    </div>
                    {/*
                    <div className="form-group row vat-question-panel society">
                      <div className="col-md-8">
                        <p>
                          Would you apply society discount or special price if
                          any of the authors of this article claims member of
                          above society but Oametrix have not managed to cross
                          check?
                        </p>
                      </div>
                      <div className="col-md-4">
                        {option_1 !== undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_1"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={option_1}
                          />
                        )}

                        {option_1 === undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_1"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society">
                      <div className="col-md-8">
                        <p>
                          In the event of a corresponding author not being a
                          society member, enter a minimum percentage of the
                          total number of co-authors required to be society
                          members to be eligible for a society membership
                          discount.
                        </p>
                      </div>
                      <div className="col-md-4">
                        <div className="society-discount-block">
                          {this.state.checked !== true && (
                            <div className="discount-number">
                              <InputBox
                                defaultValue={
                                  this.state.option_2 !== "na"
                                    ? this.state.option_2 || (this.state.societyDiscount && this.state.societyDiscount.options && this.state.societyDiscount.options.option_2)
                                    : ""}
                                min={0}
                                field="option_2"
                                max={100}
                                className="col-md-12"
                                onChange={this.onChangeRadioButtonHandler}
                              />

                            </div>
                          )}
                          {this.state.checked !== true && (
                            <div className="discount-percentage">%</div>
                          )}
                          <div className="discount-checkbox">
                            <Checkbox
                              checked={this.state.checked}
                              onChange={this.onChange}
                            >
                              Not Applicable
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society">
                      <div className="col-md-8">
                        <p>
                          Would you apply society discount or special price when
                          institutional admin approve the article where author
                          or co-author is above society member and OaMetrix
                          system managed cross check?
                        </p>
                      </div>
                      <div className="col-md-4">
                        {option_3 !== undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_3"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={option_3}
                          />
                        )}

                        {option_3 === undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_3"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society">
                      <div className="col-md-8">
                        <p>
                          Would you apply society discount or special price when
                          institutional admin approve the article where author
                          or co-author is above society member and OaMetrix
                          system has not managed to cross check?
                        </p>
                      </div>
                      <div className="col-md-4">
                        {option_4 !== undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_4"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={option_4}
                          />
                        )}

                        {option_4 === undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_4"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society">
                      <div className="col-md-8">
                        <p>
                          Would you apply society discount or special price when
                          institutional admin approve the article under a OA
                          deal where author or co-author is above society member
                          OaMetrix system managed cross check?
                        </p>
                      </div>
                      <div className="col-md-4">
                        {option_5 !== undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_5"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={option_5}
                          />
                        )}

                        {option_5 === undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_5"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group row vat-question-panel society">
                      <div className="col-md-8">
                        <p>
                          Would you apply society discount or special price when
                          institutional admin approve the article under a OA
                          deal where author or co-author is above society member
                          OaMetrix system has not managed cross check?
                        </p>
                      </div>
                      <div className="col-md-4">
                        {option_6 !== undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_6"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                            value={option_6}
                          />
                        )}

                        {option_6 === undefined && (
                          <RadioButtonGroup
                            onChange={this.onChangeRadioButtonHandler}
                            field="option_6"
                            options={[
                              { id: true, name: "Yes" },
                              { id: false, name: "No" },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                          */}

                    <div className="text-center m-t-15">
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id ,
  organisation_id: state.auth.user.organisation_id ,
  token: state.auth.token ,
  currency_list: state.OaDeals.oadeal_currency ,
  societies: state.society.societies ,
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  oa_disciplines: state.OaDiscipline.oa_disciplines ,
  oa_subjects: state.OaSubject.oa_subjects ,
  society_discount: state.societyDiscount.society_discount ,
});

const mapDispatchToProps = (dispatch) => ({
  getSocietyDiscount: (id) => dispatch(getSocietyDiscount(id)),
  getAllCurrencyList: () => dispatch(getAllCurrencyList()),
  createUpdateSocietyDiscount: (payload, id) =>
    dispatch(createUpdateSocietyDiscount(payload, id)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: () => dispatch(getContentTypeList()),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getSocietyList: (payload) => dispatch(getSocietyList(payload)),
});

export const EditSocietyDiscount = connect(
  mapStateToProps,
  mapDispatchToProps
)(Society);
