import { ArticalAprForm, ArticleDecForm } from "../components/views/university";
import {
  AddUser,
  UserList,
  AddRole,
  RoleList,
  AddOrg,
  OrgList,
  AddJournal,
  JournalList,
  AddArticle,
  ArticleBasic,
  ChangePwd,
  EditUser,
  EditOrg,
  SingleOrg,
  EditJournal,
  SingleJournal,
  ArticleFullPage,
  ArticleManualPage,
  OaDealsList,
  AddContentType,
  AddGroup,
  EditContentType,
  EditGroup,
  GroupList,
  ContentTypeList,
  ViewContentType,
  ViewGroup,
  AddVAT,
  EditVAT,
  VATList,
  AddBook,
  BookList,
  Institution,
  Publisher,
  Hub,
  Author,
  AddOaPolicy,
  OaPolicyList,
  AddProgram,
  EditProgram,
  ProgramList,
  ViewProgram,
  SubjectList,
  AddSubject,
  EditSubject,
  DisciplineList,
  AddDiscipline,
  EditDiscipline,
  DashboardList,
  AddDashboard,
  EditDashboard,
  OrganisationDealFeatureList,
  // AddOrganisationDealFeature,
  EditOrganisationDealFeature,
  PublicationApcWaiverConfigList,
  AddPublicationApcWaiverConfig,
  EditPublicationApcWaiverConfig,
  DashboardControl,
  DashboardControlList,
  DashboardControlView,
  ArticleConfigForm,
  InvoiceTemplateList,
  AddInvoiceTemplate,
  EditInvoiceTemplate,
  QuicksightLogList,
  ArticleTabControlConfigList,
  AddArticleTabControlConfig,
  EditArticleTabControlConfig,
  LicencePricingControlConfigList,
  AddLicencePricingControlConfig,
  EditLicencePricingControlConfig,
  // AddPublicationForm,
  ArticleFaultyAuthor,
  UpdateFaultyAuthor,
  addCountry,
  CountryList,
} from "../../openaccess/components/views/hub";
import { Dashboard, Settings } from "../components/views";
import { ProfileUser, AddPublicationForm ,EditManualArticalForm} from "../components/views/publisher";

import Invoice from "../components/partials/payment/Invoice.jsx";
import AddPayment from "../components/partials/payment/addPayment.jsx";

export const HubRoot = {
  dashboard: "/",
  organizations: "/organizations",
  users: "/user",
  programs: "/program",
  articles: "/articles",
  journals: "/journals",
  article_approval: "/article-approval",
  article_decline: "/article-decline",
  role: "/role",
  orcid_id: "/orcid-id",
  oaDeal: "/oa-deal",
  books: "/books",
  email: "/email",
  payment: "/payment",
  disciplines: "/disciplines",
  subjects: "/subjects",
  organisation_deal_features: "/org-deal-features",
  pub_apc_waiver_configs: "/pub-apc-waiver-configs",
  settings: "/settings",
  dashboardcontrol: "/dashboardcontrol",
  invoice_templates: "/invoice-templates",
  quicksight_dashboards: "/quicksight-dashboards",
  logs: "/logs",
  article_tab_configs: "/article-tab-configs",
  licence_pricing_configs: "/licence-pricing-configs",
  affiliation_missing: "/affiliation",

};

export const HubSidebar = [
  {
    icon: "mdi-view-dashboard",
    label: "Dashboard",
    to: HubRoot.dashboard,
    exact: true,
    component: Dashboard,
  },

  {
    icon: "mdi-settings",
    label: "Settings",
    to: HubRoot.settings,
    exact: true,
    component: Settings,
  },

  {
    icon: " mdi-pencil-box",
    label: "Articles & Books",
    to: HubRoot.articles,
    content: [
      {
        label: "Add & View Publications",
        to: "/add-publications",
        component: AddPublicationForm,
      },
      {
        label: "Add Article",
        to: "/add-article",
        component: AddArticle,
      },
      {
        label: "Edit Article",
        to: "/article-manual/edit/:id",
        component: EditManualArticalForm,
        isHide: true,
      },
      {
        label: "Articles - Basic",
        to: "/article-basic",
        component: ArticleBasic,
      },
      {
        label: "Articles - Manual",
        to: "/article-manual",
        component: ArticleManualPage,
        exact: true,
      },
      {
        label: "Articles - Full",
        to: "/article-full",
        component: ArticleFullPage,
      },
    ],
  },
  {
    icon: "mdi-settings",
    label: "Settings",
    to: HubRoot.settings,
    exact: true,
    component: Settings,
    isHide: true,
    content: [
      {
        label: "Add Dashboard control",
        to: "/add-dashboard-control",
        component: DashboardControl,
      },
      {
        label: "Dashboard control List",
        to: "/dashboard-control-list",
        component: DashboardControlList,
      },
      {
        label: "Dashboard control view",
        to: "/dashboard-control-view/:id",
        component: DashboardControlView,
      },
      {
        label: "Edit Dashboard control",
        to: "/edit-dashboard-control/:id",
        component: DashboardControl,
      },
      {
        label: "Add article Config",
        to: "/add-article-config/",
        component: ArticleConfigForm,
      },
    ],
  },

  {
    icon: "ion-university",
    label: "Organizations",
    to: HubRoot.organizations,
    content: [
      {
        label: "Add Organization",
        to: "/add-organization",
        component: AddOrg,
      },
      {
        label: "Organizations List",
        to: "/organization-list",
        component: OrgList,
      },
      {
        label: "Edit Organization",
        to: "/edit-org/:id",
        component: EditOrg,
        isHide: true,
      },
      {
        label: "Organization View",
        to: "/single-org/:id",
        component: SingleOrg,
        isHide: true,
      },
    ],
  },

  {
    icon: "mdi-account-multiple",
    label: "Users",
    to: HubRoot.users,
    isHide: true,
    content: [
      {
        label: "Add User",
        to: "/add-user",
        component: AddUser,
      },
      {
        label: "User List",
        to: "/user-list",
        component: UserList,
      },
      {
        label: "Change Password",
        to: "/change-password",
        component: ChangePwd,
      },
      {
        label: "Edit User",
        to: "/edit-user/:id",
        component: EditUser,
        isHide: true,
      },
      {
        label: "View User",
        to: "/:id",
        component: ProfileUser,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-email",
    label: "Email",

    to: HubRoot.email,
    content: [
      {
        label: "Publishers",
        to: "/addPublishers",
        component: Publisher,
      },
      {
        label: "Institutions",
        to: "/addinstitutions",
        component: Institution,
      },
      //  {
      //     label: 'edit-email',
      //     to: '/edit-email/:id',
      //     component: EmailEditor,
      //     isHide: true,

      // },
      {
        label: "Authors",
        to: "/addAuthor",
        component: Author,
      },
      {
        label: "Hub",
        to: "/addHub",
        component: Hub,
      },
    ],
  },
  {
    icon: "mdi-key-variant",
    label: "Quicksight dashboard",
    isHide: true,
    to: HubRoot.quicksight_dashboards,
    exact: true,
    content: [
      {
        label: "Add Dashboard",
        to: "/add-dashboard",
        component: AddDashboard,
        exact: true,
      },
      {
        label: "Dashboard List",
        to: "/dashboard-list",
        component: DashboardList,
        exact: true,
      },
      {
        label: "Edit Dashboard",
        to: "/edit-dashboard/:id",
        component: EditDashboard,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-pen",
    label: "Programs",
    to: HubRoot.programs,
    isHide: true,
    content: [
      {
        label: "Add Program",
        to: "/add-program",
        component: AddProgram,
      },
      {
        label: "Program list",
        to: "/program-list",
        component: ProgramList,
      },
      {
        label: "Edit Program",
        to: "/edit-program/:id",
        component: EditProgram,
        isHide: true,
      },
      {
        label: "View Program",
        to: "/:id",
        component: ViewProgram,
        isHide: true,
      },
    ],
  },
  {
    icon: " mdi-book-multiple",
    label: "Journals",
    to: HubRoot.journals,
    isHide: true,
    content: [
      {
        label: "Add Journal",
        to: "/add-journals",
        component: AddJournal,
      },
      {
        label: "Journals List",
        to: "/journals-list",
        component: JournalList,
      },
      {
        label: "Edit Journals",
        to: "/edit-journal/:id",
        component: EditJournal,
        isHide: true,
      },
      {
        label: "Journal View",
        to: "/single-journal/:id",
        component: SingleJournal,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-book",
    label: "Books",
    isHide: true,

    to: HubRoot.books,
    content: [
      {
        label: "Add book",
        to: "/add-upload-book",
        component: AddBook,
      },
      {
        label: "Edit Article",
        to: "/article-manual/edit/:id",
        component: AddBook,
        isHide: true,
      },
      {
        label: "Books",
        isHide: true,
        to: "/editbook/:id",
        component: AddBook,
      },
      {
        label: "Books",
        to: "/books",
        component: BookList,
      },
    ],
  },
  {
    label: "ArticleApprovalForm",
    to: HubRoot.article_approval + "/:id",
    exact: true,
    component: ArticalAprForm,
    isHide: true,
    isCommon: true,
  },
  {
    label: "Article Decline Form",
    to: HubRoot.article_decline + "/:id",
    exact: true,
    component: ArticleDecForm,
    isHide: true,
    isCommon: true,
  },
  {
    icon: " mdi-pencil",
    label: "Country",
    to: "/country",
    isHide: true,
    content: [
      {
        label: "Add country",
        to: "/update-country/:id",
        component: addCountry,
        isHide: true,
      },
      {
        label: "Country List",
        to: "/country-list",
        component: CountryList,
      },
    ],
  },
  {
    icon: "mdi-account-key",
    label: "Role",
    to: "/role",
    content: [
      {
        label: "Add Role",
        to: "/add-role",
        component: AddRole,
      },
      {
        label: "Role List",
        to: "/role-list",
        component: RoleList,
      },
    ],
  },
  {
    icon: "mdi-tumblr-reblog",
    label: "Oa deal",
    to: "/oa-deal",
    content: [
      {
        label: "Oa deal list",
        to: "/list",
        component: OaDealsList,
      },
    ],
  },
  {
    icon: "mdi-nature-people",
    label: "Groups",
    to: "/group",
    content: [
      {
        label: "Add group",
        to: "/add-group",
        component: AddGroup,
      },
      {
        label: "Group list",
        to: "/group-list",
        component: GroupList,
      },
      {
        label: "Edit group",
        to: "/edit-group/:id",
        component: EditGroup,
        isHide: true,
      },
      {
        label: "Group View",
        to: "/single-group/:id",
        component: ViewGroup,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-content-save-all",
    label: "Content type",
    to: "/content-type",
    isHide: true,
    content: [
      {
        label: "Add content type",
        to: "/add-content-type",
        component: AddContentType,
      },
      {
        label: "Content type list",
        to: "/content-type-list",
        component: ContentTypeList,
      },
      {
        label: "Edit content type",
        to: "/edit-content-type/:id",
        component: EditContentType,
        isHide: true,
      },
      {
        label: "Content type view",
        to: "/single-content-type/:id",
        component: ViewContentType,
        isHide: true,
      },
    ],
  },

  {
    icon: "mdi-application",
    label: "OA Policy",
    to: "/oa-policy",
    isHide: true,

    content: [
      {
        label: "Add OA Policy",
        to: "/add-oa-policy",
        component: AddOaPolicy,
      },
      {
        label: "OA Policy list",
        to: "/oa-policy-list",
        component: OaPolicyList,
      },
      {
        label: "OA Policy list",
        to: "/edit-oa-policy/:id",
        isHide: true,
        component: AddOaPolicy,
      },
    ],
  },
  {
    icon: "mdi-account-multiple",
    label: "Payment",
    exact: true,
    to: HubRoot.payment,
    isHide: true,
    content: [
      {
        label: "Invoice",
        to: "/addInvoice",
        component: Invoice,
      },
      {
        label: "Addpayment",
        to: "/addpayment",
        component: AddPayment,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "OA discipline",
    to: HubRoot.disciplines,
    isHide: true,

    exact: true,
    content: [
      {
        label: "Add OA Discipline",
        to: "/add-discipline",
        component: AddDiscipline,
        exact: true,
      },
      {
        label: "OA Discipline List",
        to: "/discipline-list",
        component: DisciplineList,
        exact: true,
      },
      {
        label: "Edit OA Discipline",
        to: "/edit-discipline/:id",
        component: EditDiscipline,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    isHide: true,

    label: "OA subject",
    to: HubRoot.subjects,
    exact: true,
    content: [
      {
        label: "Add OA Subject",
        to: "/add-subject",
        component: AddSubject,
        exact: true,
      },
      {
        label: "OA Subject List",
        to: "/subject-list",
        component: SubjectList,
        exact: true,
      },
      {
        label: "Edit OA Subject",
        to: "/edit-subject/:id",
        component: EditSubject,
        exact: true,
        isHide: true,
      },
    ],
  },

  {
    icon: "mdi-application",
    label: "Org Deal Features",
    to: HubRoot.organisation_deal_features,
    isHide: true,
    exact: true,
    content: [
      // {
      //     label: 'Add Org Deal Feature',
      //     to: '/add',
      //     component: AddOrganisationDealFeature,
      //     exact: true,
      // },
      {
        label: "Org Deal Feature List",
        to: "/list",
        component: OrganisationDealFeatureList,
        exact: true,
      },
      {
        label: "Edit Org Deal Feature",
        to: "/edit/:id",
        component: EditOrganisationDealFeature,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "Invoice Template",
    to: HubRoot.invoice_templates,
    isHide: true,
    exact: true,
    content: [
      {
        label: "Add Invoice Template",
        to: "/add",
        component: AddInvoiceTemplate,
        exact: true,
      },
      {
        label: "Invoice Template List",
        to: "/list",
        component: InvoiceTemplateList,
        exact: true,
      },
      {
        label: "Edit Invoice Template",
        to: "/edit/:id",
        component: EditInvoiceTemplate,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "Article Tab Config",
    to: HubRoot.article_tab_configs,
    isHide: true,
    exact: true,
    content: [
      {
        label: "Add Article Tab Config",
        to: "/add",
        component: AddArticleTabControlConfig,
        exact: true,
      },
      {
        label: "Article Tab Config List",
        to: "/list",
        component: ArticleTabControlConfigList,
        exact: true,
      },
      {
        label: "Edit Article Tab Config",
        to: "/edit/:id",
        component: EditArticleTabControlConfig,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "Licence Pricing Config",
    to: HubRoot.licence_pricing_configs,
    isHide: true,
    exact: true,
    content: [
      {
        label: "Add Licence Pricing Config",
        to: "/add",
        component: AddLicencePricingControlConfig,
        exact: true,
      },
      {
        label: "Licence Pricing Config List",
        to: "/list",
        component: LicencePricingControlConfigList,
        exact: true,
      },
      {
        label: "Edit Licence Pricing Config",
        to: "/edit/:id",
        component: EditLicencePricingControlConfig,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "Affiliation Missing",
    isHide: true,
    to: HubRoot.affiliation_missing,
    exact: true,
    content: [
      {
        label: "Faulty Author",
        to: "/faulty-author-list",
        component: ArticleFaultyAuthor,
        exact: true,
      },
      {
        label: "",
        to: "/update-faulty-author/:id",
        component: UpdateFaultyAuthor,
        exact: true,
        isHide: true,
      },
      {
        label: "",
        to: "/view-faulty-author",
        component: EditPublicationApcWaiverConfig,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    isHide: true,
    label: "OA subject",
    to: HubRoot.subjects,
    exact: true,
    content: [
      {
        label: "Add OA Subject",
        to: "/add-subject",
        component: AddSubject,
        exact: true,
      },
      {
        label: "OA Subject List",
        to: "/subject-list",
        component: SubjectList,
        exact: true,
      },
      {
        label: "Edit OA Subject",
        to: "/edit-subject/:id",
        component: EditSubject,
        exact: true,
        isHide: true,
      },
    ],
  },

  {
    icon: "mdi-application",
    label: "Invoice Template",
    to: HubRoot.invoice_templates,
    isHide: true,
    exact: true,
    content: [
      {
        label: "Add Invoice Template",
        to: "/add",
        component: AddInvoiceTemplate,
        exact: true,
      },
      {
        label: "Invoice Template List",
        to: "/list",
        component: InvoiceTemplateList,
        exact: true,
      },
      {
        label: "Edit Invoice Template",
        to: "/edit/:id",
        component: EditInvoiceTemplate,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-application",
    label: "Article Tab Config",
    to: HubRoot.article_tab_configs,
    isHide: true,
    exact: true,
    content: [
      {
        label: "Add Article Tab Config",
        to: "/add",
        component: AddArticleTabControlConfig,
        exact: true,
      },
      {
        label: "Article Tab Config List",
        to: "/list",
        component: ArticleTabControlConfigList,
        exact: true,
      },
      {
        label: "Edit Article Tab Config",
        to: "/edit/:id",
        component: EditArticleTabControlConfig,
        exact: true,
        isHide: true,
      },
    ],
  },

  {
    icon: "mdi-application",
    label: "Apc Waiver Config",
    to: HubRoot.pub_apc_waiver_configs,
    isHide: true,

    exact: true,
    content: [
      {
        label: "Add Apc Waiver Config",
        to: "/add",
        component: AddPublicationApcWaiverConfig,
        exact: true,
      },
      {
        label: "Apc Waiver Config List",
        to: "/list",
        component: PublicationApcWaiverConfigList,
        exact: true,
      },
      {
        label: "Edit Apc Waiver Config",
        to: "/edit/:id",
        component: EditPublicationApcWaiverConfig,
        exact: true,
        isHide: true,
      },
    ],
  },
  {
    icon: "mdi-percent",
    label: "VAT",
    to: "/vat",
    isHide: true,

    content: [
      {
        label: "Add VAT",
        to: "/add-vat",
        component: AddVAT,
      },
      {
        to: "/edit-vat/:id",
        component: EditVAT,
        isHide: true,
      },
      {
        label: "VAT list",
        to: "/vat-list",
        component: VATList,
      },
    ],
  },

  {
    icon: "mdi-application",
    label: "Logs",
    to: HubRoot.logs,
    exact: true,
    isHide: true,
    content: [
      {
        label: "Quicksight Logs",
        to: "/quicksight-log-list",
        component: QuicksightLogList,
        exact: true,
      },
    ],
  },
];
