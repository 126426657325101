import { api } from "../../core/api";
import config, { GET_CURRENCY_LIST } from "../../config";

// Loaded into App Reducers
export const getCurrencyList = (payload) => ({
  type: GET_CURRENCY_LIST,
  payload,
});

export const GetOaDeal = (id) => {
  return () => {
    let url = config.endpoint.oa_deal + "?organisation=" + id;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => resolve(res.results),
        (error) => reject(error)
      );
    });
  };
};

export const getCurrency = () => {
  return (dispatch) => {
    const url = config.endpoint.currency;
    return new Promise((resolve, reject) => {
      api.get(url + "?page_size=99999").then(
        (res) => {
          dispatch(getCurrencyList(res));
          resolve(res.results);
        },
        (error) => {
          delete error.config;
          delete error.headers;
          delete error.request;
          delete error.response.request;
          delete error.response.headers;
          delete error.response.config;
          reject(error.response);
        }
      );
    });
  };
};
