import config, {
  GET_ORGANISATION_DEAL_FEATURE_LIST,
  GET_ORGANISATION_DEAL_FEATURE,
  CREATE_UPDATE_ORGANISATION_DEAL_FEATURE,
  DELETE_ORGANISATION_DEAL_FEATURE,
  UPLOAD_ORGANISATION_DEAL_FEATURE,
  GET_OA_DEAL_FEATURE_LIST,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get organisation deal feature list
export const getOrganisationDealFeatureListAction = (payload) => ({
  type: GET_ORGANISATION_DEAL_FEATURE_LIST,
  payload,
});

// Get oa deal feature list
export const getOaDealFeatureListAction = (payload) => ({
  type: GET_OA_DEAL_FEATURE_LIST,
  payload,
});

// Get organisation deal feature
export const getOrganisationDealFeatureAction = (payload) => ({
  type: GET_ORGANISATION_DEAL_FEATURE,
  payload,
});

// Create-Update organisation deal feature
export const createUpdateOrganisationDealFeatureAction = (payload) => ({
  type: CREATE_UPDATE_ORGANISATION_DEAL_FEATURE,
  payload,
});

// Delete organisation deal feature
export const deleteOrganisationDealFeatureAction = (payload) => ({
  type: DELETE_ORGANISATION_DEAL_FEATURE,
  payload,
});

// file upload
export const fileUploadAction = (payload) => ({
  type: UPLOAD_ORGANISATION_DEAL_FEATURE,
  payload,
});

export const getOrganisationDealFeatureList = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.organisation_deal_feature_assignment;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (payload.organisation) {
      params["organisation_id"] = payload.organisation;
    }

    if (payload.has_df_only) {
      params["has_df_only"] = payload.has_df_only;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrganisationDealFeatureListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getOaDealFeatureList = (payload = {}) => {
  return (dispatch) => {
    let url = config.endpoint.oa_deal_feature;
    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOaDealFeatureListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get OA single
export const getOrganisationDealFeature = (id) => {
  return (dispatch) => {
    let url = config.endpoint.organisation_deal_feature_assignment + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrganisationDealFeatureAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create-Update organisation deal feature

export const createUpdateOrganisationDealFeature = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.organisation_deal_feature_assignment;
    let apiRequest = "";
    return new Promise((resolve, reject) => {
      if (id) {
        url += `${id}/`;
        apiRequest = api.put(url, payload);
      } else {
        apiRequest = api.post(url, payload);
      }

      apiRequest
        .then((res) => {
          dispatch(createUpdateOrganisationDealFeatureAction(res.data));
          resolve(Object.assign(res.data, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Delete organisation deal feature
export const deleteOrganisationDealFeature = (id) => {
  return (dispatch) => {
    let url = config.endpoint.organisation_deal_feature_assignment + `${id}/`;

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          dispatch(deleteOrganisationDealFeatureAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// file upload action
export const uploadOrganisationDealFeature = (payload) => {
  let url = `${config.endpoint.organisation_deal_feature_assignment}upload/`;
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return new Promise((resolve, reject) => {
      let filename = payload.get("filename");
      let headers = {
        "Content-Disposition": `attachment; filename="${filename}"`,
      };

      payload.delete("filename");

      api
        .post(url, payload, token, headers)
        .then((res) => {
          dispatch(fileUploadAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(fileUploadAction(error.response));
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};
