import React from "react";
import BaseComponent from "..";
import { Select,Tooltip} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
const { Option } = Select;
class SelectDropDownBoxName extends BaseComponent {
  state = {
    msg: this.props.errMsg || "",
  };

  handleChange = (value,) => {
   this.props.onChange({ [this.props.field]: value });
  };
  render() {
    return (
        <>
          {this.props.label && (
            <div   className={` col-form-label ${this.props.labelClass || "col-sm-2"}`}
            >
                <label
              style={{ marginLeft: this.props.ml15 ? -15 : undefined }}
              htmlFor={this.props.field || ""}
            >
              {this.props.label ? this.props.label : ""}{" "}
              {this.props.isRequired && <span className="text-danger"> * </span>}
            </label>
            {
              this.props.labelInfo && <Tooltip title={this.props.labelInfoTitle}>
              <InfoCircleTwoTone style={{
                  fontSize: 18,
                  marginLeft:15
              }} twoToneColor="rgb(122, 111, 190)" className="signup-email"
                  onClick={this.handleInstMessage}
              />
              </Tooltip>
           }
          </div>
          )}


          <div
            className={` ${this.props.className ? this.props.className : "col-sm-4"
              }`}
            style={{ position: "relative" }}
          >
            {this.state.msg && (
              <span
                className="text-danger text-small"
                style={{
                  fontSize: 12,
                  position: "absolute",
                  right: 20,
                  top: -10,
                  backgroundColor: "#fff",
                  padding: "0px 10px",
                  zIndex: 99999,
                }}
              >
                {this.state.msg}
              </span>
            )}

              <Select
                showSearch
                mode={this.props.multiple ? "multiple" : ""}
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : this.props.label
                      ? this.props.label
                      : ""
                }
                optionFilterProp="children"
                onChange={this.handleChange}
                style={styles.select}
                size="large"
                required={this.props.isRequired || false}
                disabled={this.props.disabled || false}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.props.data &&
                  this.props.data.map((data, index) => {
                    return (
                      <Option key={index} value={data.id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
          
            
          </div>
        </>
      );
  }
}

const styles = {
  select: {
    width: "100%",
    fontSize: 14,
  },
};


export const SelectDropDownBox = SelectDropDownBoxName