import {
    ARTICLE_OFFER_DETAILS,
    ARTICLE_OFFER_DETAILS_REQUEST
} from "../../config";

const OrgsReducer = (state = {}, action) => {
    switch (action.type) {
        case ARTICLE_OFFER_DETAILS:
            return (state = {
                article_detail_loading: false,
                article_offer_detail: action.payload
            });
        case ARTICLE_OFFER_DETAILS_REQUEST:
            return (state = {
                article_detail_loading: true,
            });
        default:
            return state;
    }
};
export default OrgsReducer;
