import config, {
  GET_JOURNAL_LIST,
  GET_BOOK_PUBLICATION_TYPE,
  GET_COUNTRY,
  GET_JOURNAL_SEARCHLIST,
  JOURNAL_FILE_UPLOAD,
  TRACK_JOURNAL_FILE_UPLOAD_STATUS,
  REQUEST_CREATE_JOURNAL,
  FAILED_CREATE_JOURNAL,
  GET_JOURNAL_LIST_ALL,
  REQUEST_UPDATE_JOURNAL,
  DELETE_JOURNAL_DATA,
  REQUEST_FAILED_DELETE_USER,
  SINGLE_JOURNAL_DATA,
  FAILED_SINGLE_JOURNAL_DATA,
  GET_PUBLICATION_TYPE,
  FAILED_PUBLICATION_TYPE,
  GET_JOURNAL_TYPE,
  GET_BOOK_SERIES_ACTION,
  GET_CONTENT_TYPE,
  REQUEST_CREATE_PUBLICATION,
  FAILED_CREATE_PUBLICAITON,
  GET_JOURNAL_DOWNLOAD,
  GET_JOURNAL_ACTION,
  SUCESS_BOOK_SERIES,
  REQUEST_BOOK_SERIES,
  FAILURE_BOOK_SERIES,
  SUCCESS_GET_PUBLICATION,
  FAIELD_GET_PUBLICATION,
  REQUEST_GET_PUBLICATION,
  GET_SINGLE_COUNTRY,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Loaded into App Reducers
export const getJournals = (payload) => ({
  type: GET_JOURNAL_LIST,
  payload,
});

// Loaded into App Reducers
export const getAllJournals = (payload) => ({
  type: GET_JOURNAL_LIST_ALL,
  payload,
});
export const getSingleCountry = (payload) => ({
  type: GET_SINGLE_COUNTRY,
  payload,
});

// Sign Up Action Creator
export const recievedJournalData = (payload) => ({
  type: REQUEST_CREATE_JOURNAL,
  payload,
});

// Failed Response
export const failedCreatingJournal = (payload) => ({
  type: FAILED_CREATE_JOURNAL,
  payload,
});

export const failedUpdateJournal = (payload) => ({
  type: REQUEST_UPDATE_JOURNAL,
  payload,
});

export const updateJournalData = (payload) => ({
  type: REQUEST_UPDATE_JOURNAL,
  payload,
});

export const failedDeleteUser = (payload) => ({
  type: REQUEST_FAILED_DELETE_USER,
  payload,
});

export const requestArticlePublicationAction = () => ({
  type: REQUEST_GET_PUBLICATION,
});

export const successArticlePublicationAction = (payload) => ({
  type: SUCCESS_GET_PUBLICATION,
  payload,
});

export const failedArticlePublicationAction = (payload) => ({
  type: FAIELD_GET_PUBLICATION,
  payload,
});

// Clearing failer status
export const deleteJournalData = (id) => ({
  type: DELETE_JOURNAL_DATA,
  id,
});

export const singleJournalData = (payload) => ({
  type: SINGLE_JOURNAL_DATA,
  payload,
});

export const failedSingleJournalData = (payload) => ({
  type: FAILED_SINGLE_JOURNAL_DATA,
  payload,
});

export const getPublicationType = (payload) => ({
  type: GET_PUBLICATION_TYPE,
  payload,
});
export const getSinglePublicatonTypeAction = (payload) => ({
  type: GET_BOOK_PUBLICATION_TYPE,
  payload,
});

export const getJournalType = (payload) => ({
  type: GET_JOURNAL_TYPE,
  payload,
});

export const getContentType = (payload) => ({
  type: GET_CONTENT_TYPE,
  payload,
});

export const failedPublicationType = (payload) => ({
  type: FAILED_PUBLICATION_TYPE,
  payload,
});

export const recievedPublicationData = (payload) => ({
  type: REQUEST_CREATE_PUBLICATION,
  payload,
});

export const failedCreatingPublication = (payload) => ({
  type: FAILED_CREATE_PUBLICAITON,
  payload,
});

// Journal file upload reducer
export const journalFileUploadReducer = (payload) => ({
  type: JOURNAL_FILE_UPLOAD,
  payload,
});

// Journal file upload tracking reducer
export const trackJournalFileUploadStatusReducer = (payload) => ({
  type: TRACK_JOURNAL_FILE_UPLOAD_STATUS,
  payload,
});
// Journal file upload tracking reducer
export const journaldwload = (payload) => ({
  type: GET_JOURNAL_DOWNLOAD,
  payload,
});
export const getCountry = (payload) => ({
  type: GET_COUNTRY,
  payload,
});
export const journalSearchList = (payload) => ({
  type: GET_JOURNAL_SEARCHLIST,
  payload,
});
export const getBookSeriesActionType = (payload) => ({
  type: GET_BOOK_SERIES_ACTION,
  payload,
});
export const getJournalActionType = (payload) => ({
  type: GET_JOURNAL_ACTION,
  payload,
});
export const requestBookSeriesAction = () => ({
  type: REQUEST_BOOK_SERIES,
});

export const successBookSeriesActionTable = (payload) => ({
  type: SUCESS_BOOK_SERIES,
  payload,
});

export const failureBookSeriesAction = (payload) => ({
  type: FAILURE_BOOK_SERIES,
  payload,
});

export const getJournalDownload = () => {
  const url = `${config.endpoint.publication_download}?fields=name,publisher_name,journal_type_name,pub_acronym,eissn,pissn,content_type_name,apc_gbp,colour_charge_gbp,submission_fee_gbp,page_charge_gbp,other_charge_gbp,apc_eur,colour_charge_eur,submission_fee_eur,page_charge_eur,other_charge_eur,apc_usd,colour_charge_usd,submission_fee_usd,page_charge_usd,other_charge_usd,indexed,status,impact_factor,hindex,sherpa_romeo_info,note,date&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(journaldwload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Get Content Type List
export const getContentTypeList = (id) => {
  return (dispatch, getState) => {
    let url = id
      ? `${config.endpoint.content_type}?pub-ids=${id}&fields=id,name&page_size=999999`
      : `${config.endpoint.content_type}?fields=id,name&page_size=999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getContentType(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Journal Type List
export const getJournalTypeList = (payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.journal_type}?fields=id,name&page_size=9999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getJournalType(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Publication Type List
export const getPublicatonTypeList = (payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.publication_type}?fields=id,name&page_size=9999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getPublicationType(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getBookSeriesTableList = (payload, name) => {
  return (dispatch, getState) => {
    let url = payload.pageNum
      ? `${config.endpoint.publication}?publication_type_name=${name}&fields=id,name,journal_type_name,pissn,eissn,subjects_list&page=${payload.pageNum}`
      : `${config.endpoint.publication}?publication_type_name=${name}&fields=id,name,journal_type_name,pissn,eissn,subjects_list&${payload}`;
    dispatch(requestBookSeriesAction());

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(successBookSeriesActionTable(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(failureBookSeriesAction(e));
        });
    });
  };
};

export const getBookSeriesType = () => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.publication_type}?name=BookSeries`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getBookSeriesActionType(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getSpecificJournalType = () => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.publication_type}?name=Journal`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getJournalActionType(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Publication Type List
export const getSinglePublicatonType = (payload) => {
  const url = config.endpoint.book_publisher;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getSinglePublicatonTypeAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Journalanization List
export const getJournalList = (payload) => {
  return (dispatch, getState) => {
    let url = payload.pageNum
      ? config.endpoint.journal + "?page=" + payload.pageNum
      : config.endpoint.journal + "?" + payload;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getJournals(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Journalanization List for hub
export const getJournalListHub = (payload = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let params = {};
      let url = "journal-publication/";

      if (payload.pageNum && payload.pageSize) {
        params["page"] = payload.pageNum;
        params["page_size"] = payload.pageSize;
      } else if (payload.pageNum) {
        params["page"] = payload.pageNum;
      } else if (payload.pageSize) {
        params["page_size"] = payload.pageSize;
      }

      if (payload.q) {
        params["q"] = payload.q;
      }

      if (!params.page_size) {
        params["page_size"] = 99999;
      }

      url += `?${serialize(params)}`;

      api
        .get(url)
        .then((res) => {
          dispatch(getJournals(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Journalanization List for hub
export const getCountryList = (payload) => {
  const filter = payload ? payload.filter || "" : "";
  const url = config.endpoint.country + `?${filter}`;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getCountry(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getHubCountryList = (payload, num) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  const url = config.endpoint.country + `?${params && params + "&"}${filter}`;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getCountry(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getSingleCountryList = (payload) => {
  const url = `${config.endpoint.country}${payload}`;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getSingleCountry(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const postCountryList = (payload) => {
  const url = `${config.endpoint.country}${payload.id}/`;

  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(getCountry(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get journal list all
export const getJournalListAll = (paylaod) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("journal-publication?page_size=999999")
        .then((res) => {
          dispatch(getJournals(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get journal list all
export const getPublicationByPublisher = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.publication}?publisher=${payload.publisher_id}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getJournals(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Request from hub
export const CreateJournal = (JournalData = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;
      return api
        .createJournalHandler(JournalData, token)
        .then(
          (res) => {
            dispatch(
              recievedJournalData(
                Object.assign(res.data, { status: res.status })
              )
            );
            resolve(res.data);
          },
          (error) => {
            dispatch(
              failedCreatingJournal(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            reject(error);
          }
        )
        .catch((error) => {
          dispatch(failedCreatingJournal(Object.assign({ status: 500 })));
          reject(error);
        });
    });
  };
};

export const CreatePublication = (PublicationData = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;
      return api
        .createPublicationHandler(PublicationData, token)
        .then(
          (res) => {
            dispatch(
              recievedPublicationData(
                Object.assign(res.data, { status: res.status })
              )
            );
            resolve(res.data);
          },
          (error) => {
            dispatch(
              failedCreatingPublication(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            reject(error);
          }
        )
        .catch((error) => {
          dispatch(failedCreatingJournal(Object.assign({ status: 500 })));
          reject(error);
        });
    });
  };
};

export const UpdateJournal = (payload, id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return api
        .put("publication/" + id + "/", payload)
        .then(
          (res) => {
            dispatch(
              updateJournalData(Object.assign(res.data, { status: res.status }))
            );
            resolve(res.data);
          },
          (error) => {
            dispatch(
              failedUpdateJournal(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            reject(error);
          }
        )
        .catch((error) => {
          dispatch(failedUpdateJournal(Object.assign({ status: 500 })));
          reject(error);
        });
    });
  };
};

export const deleteJournal = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .delete("publication/" + id + "/")
        .then(
          (res) => {
            dispatch(deleteJournalData(id));
            resolve({ status: true });
          },
          (error) => {
            dispatch(
              failedDeleteUser(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

export const getArticlePublication = (id) => {
  return (dispatch) => {
    let url = `${config.endpoint.publication}${id}/`;
    dispatch(requestArticlePublicationAction());

    return new Promise((resolve, reject) => {
      return api
        .get(url)
        .then(
          (res) => {
            dispatch(successArticlePublicationAction(res));
          },
          (error) => {
            dispatch(failedArticlePublicationAction(error));
          }
        )
        .catch(reject);
    });
  };
};

export const singleJournal = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;
      return api
        .singleJournalHander(id, token)
        .then(
          (res) => {
            dispatch(singleJournalData(res));
            resolve({ status: true });
          },
          (error) => {
            dispatch(
              failedSingleJournalData(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Journal file upload action
export const journalFileUpload = (payload) => {
  let url = config.endpoint.journal_file_upload;
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return new Promise((resolve, reject) => {
      let filename = payload.get("filename");
      let headers = {
        "Content-Disposition": `attachment; filename="${filename}"`,
      };

      payload.delete("filename");

      api
        .post(url, payload, token, headers)
        .then((res) => {
          dispatch(journalFileUploadReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(journalFileUploadReducer(error.response));
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Track file upload status
export const trackJournalFileUploadStatus = (payload) => {
  let url = payload.upload_status;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(trackJournalFileUploadStatusReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(trackJournalFileUploadStatusReducer(error.response));
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

export const getJournalSearchList = (payload) => {
  let url = payload.upload_status;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(journalSearchList(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(journalSearchList(error.response));
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};
