import React from 'react';
import BaseComponent from '../../../../../core/components';
import { connect } from 'react-redux';
import { InputBox, SelectBox } from '../../../../../core/components/common';
import { GetOaDeal, getCurrency, GetOaPubList } from '../../../../actions';

// This Component is onfigured By Shahan
class Form extends BaseComponent{
    state = {
        deposit: {},
        oa_deals: null,
        currency: null,
        deal_type: 1,
        publisher: null,
        total: 0
    }

    async componentDidMount() {
        let publisher = await this.props.GetOaPubList();
        let currency = await this.props.getCurrency();
        this.setState((prevState) => {
            prevState.currency = currency;
            prevState.publisher = publisher;
            return prevState;
        })
        
        
    }

    onChangeHandler = async (value) => {
        this.setState((prevState) => {
            Object.assign(prevState.deposit, value);
            if (prevState.deposit.publish_fee && prevState.deposit.read_fee) {
                prevState.deposit.amount = Number(prevState.deposit.read_fee) + Number(prevState.deposit.publish_fee)
            } else if (prevState.deposit.read_fee) {
                prevState.deposit.amount = Number(prevState.deposit.read_fee)
            } else if (prevState.deposit.publish_fee) {
                prevState.deposit.amount = Number(prevState.deposit.publish_fee)
            } else {
                prevState.total = 0;
            }
            return prevState;
        })
    }

    onChangePubHandler = async (value) => {
        let oa_deals;
        if (value.counter_organisation)
            oa_deals = await this.props.GetOaDeal(value.counter_organisation);

        let newOaDeals = oa_deals && oa_deals.map((deal) => {
            return Object.assign(deal, {
                name: deal.deal_name
            })
        })
        this.setState((prevState) => {
            Object.assign(prevState.deposit, value);
            prevState.oa_deals = newOaDeals;
            return prevState;
        })
    } 

    onChangeOaDealHandler = (value) => {   
        this.setState((prevState) => {
            if (value.additional_info.deal_type === 1) {
                delete prevState.deposit.publish_fee;
                delete prevState.deposit.read_fee;
            } else {
                delete prevState.deposit.amount;
            }

            Object.assign(prevState.deposit, {
                oa_deal: value.oa_deal
            })

            prevState.deal_type = value.additional_info ? value.additional_info.deal_type : 1;
            return prevState;
        })
    }

    onSubmitHandler = async (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.deposit)
    }

    render() {
        return(
            <div className="tab-content">
                <div className="tab-pane active p-3" id="home-1" role="tabpanel"></div>
                <form >
                    <div className="form-group row">
                        <SelectBox
                            label="Publisher" // Need Info
                            onChange={this.onChangePubHandler}
                            field='counter_organisation'
                            data={this.state.publisher}
                            isRequired={true}
                            className={this.state.deal_type !== 3 ? 'col-md-4' : 'col-md-10'}
                        />
                        {this.state.deal_type !== 3 ? <InputBox
                            label="Deal year"
                            onChange={this.onChangeHandler}
                            field='year'
                            placeholder="YYYY"
                            minLength={4}
                            maxLength={4}
                            type="number"
                        /> : <></>}
                    </div>
                    <div className="form-group row">
                        <SelectBox
                            label="OA deal"
                            onChange={this.onChangeOaDealHandler}
                            field='oa_deal'
                            data={this.state.oa_deals}
                            isRequired={true}
                            isData={true}
                            className="col-sm-4 text-uppercase"
                        />
                        <SelectBox
                            label="Currency"
                            onChange={this.onChangeHandler}
                            field='currency'
                            data={this.state.currency}
                            isRequired={true}
                        />
                    </div>
                    {this.state.deal_type === 1 || this.state.deal_type === 3 ?
                    <div className="form-group row">
                        <InputBox
                            label="Amount"
                            onChange={this.onChangeHandler}
                            field='amount'
                            placeholder="e.g. 1250"
                        />
                    </div>
                    :
                    <>
                        <div className="form-group row">
                            <InputBox
                                label="Publish fee"
                                onChange={this.onChangeHandler}
                                field='publish_fee'
                                placeholder="Publish Fee"
                            />
                            <InputBox
                                label="Read fee"
                                onChange={this.onChangeHandler}
                                field='read_fee'
                                placeholder="Read fee"
                            />
                        </div>
                        <div className="form-group row">
                            <InputBox
                                label="Total"
                                onChange={this.onChangeHandler}
                                field='total'
                                placeholder="Total Amount"
                                defaultValue={this.state.deposit && this.state.deposit.amount}
                                disabled={true}
                            />
                        </div>
                    </>
                    }
                    <div className="text-center m-t-15">
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={this.onSubmitHandler}
                        >Deposit</button>
                    </div>

                </form>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    group_id: state.auth.user.group_id || null,
    orgs: state.orgs.org
})

const mapDispatchToProps = (dispatch) => ({
    GetOaPubList: () => dispatch(GetOaPubList()),
    GetOaDeal: (id) => dispatch(GetOaDeal(id)),
    getCurrency: () => dispatch((getCurrency()))
})


export const DepositForm = connect(mapStateToProps, mapDispatchToProps)(Form);
