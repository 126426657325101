import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { DataTable, HubContent } from "../../../../../core/components";
import { RoleContext } from "../../../../../core/context/hub/RoleContext";
import {
  Loading,
  SelectBox,
  InputBox /* PopupBox */,
} from "../../../../../core/components/common";
import {
  getVatHubConfig,
  deleteHubVaT,
  getAllCountry,
  getAllState,
} from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";

class Vat extends RoleContext {
  getVatListData = async () => {
    await this.props.getVatHubConfig();
    this.setState({
      data: this.props.vat_list.results,
      count: this.props.vat_list.count,
    });
  };

  async componentDidMount() {
    this.getVatListData();
    this.props.getAllCountry();
  }

  pageChange = (pageNumber) => {
    this.getVatListData(pageNumber);
  };

  onEditVat = (id) => {
    this.props.history.push("/vat/edit-vat/" + id);
  };

  onDeleteVat = (id) => {
  
    try {
      confirmAlert({
        title: "",
        message: "Please confirm to delete",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              await this.props.deleteHubVaT(id);

              this.getVatListData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {}
  };

  render() {
    const heading = [
      "VAT Country",
      "Country or Federal VAT %",
      "State name",
      "State/Province Rate %",
      "VAT %",
    ];
    let data =
      this.state.data &&
      this.state.data.map((item) => {
        return Object.assign(item, {
          "VAT Country": item.country_name,
          "Country or Federal VAT %": item.federal_vat_rate,
          "State name": item.state_name,
          "State/Province Rate %": item.state_provincial_vat_rate,
          "VAT %": item.vat_rate,
        });
      });

    return (
      <HubContent
        title="VAT List"
        onArticleDownloadClickHandler={this.onArticleDownloadClick}
      >
        <div className="row">
          <div className="col-12">
            <Link
              to="/vat/add-vat"
              className="btn btn-primary float-right mb-2"
            >
              Add Vat
            </Link>
          </div>
        </div>
        <div className="row filter">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <InputBox className="col-md-12" placeholder="Search here... " />
          </div>{" "}
          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data ? (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={data}
                  onEdit={this.onEditVat}
                  onDelete={this.onDeleteVat}
                  onView={false}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  vat_list: state.vat.vat_list_hub || false,
  countries: state.app.countries,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getVatHubConfig: (payload) => dispatch(getVatHubConfig()),
  getAllCountry: () => dispatch(getAllCountry()),
  getAllState: (payload) => dispatch(getAllState(payload)),
  deleteHubVaT: (id) => dispatch(deleteHubVaT(id)),
});

export const VATList = connect(mapStateToProps, mapDispatchToProps)(Vat);
