import React from "react";
import { connect } from "react-redux";
import { getVatConfig } from '../../../../actions';

import BaseComponent, { HubContent } from "../../../../../core/components";

import { Button } from "antd";

class VatForm extends BaseComponent {

	componentDidMount() {
		this.props.getVatConfig()
	}

	onUpdate = (item) => {
		this.props.history.push(`/settings/update-vat/${item.id}`)

	};
	// onCancel=()=>{
	//     this.props.history.push(`/settings/vat`)
	// }
	render() {

		return (
			<HubContent>
				<div className="row">
					<div className="col-12">
						<div className="card m-b-20 vatform">
							<div className="license-body vatContent">
								<div className="row license-info">
									<div className="col-md-12">
										<h3>
											Vat number: {this.props.vat_config && this.props.vat_config.results[0].vat_number}
										</h3>
									</div>
									<div className="vat-button">
										{this.props.vat_config && this.props.vat_config.results.map(item => (
											<Button
												key={item.id}
												type="primary"
												onClick={() => this.onUpdate(item)}
											>
												Update
											</Button>
										))}
										{/* <Button
													type="primary"
													onClick={() => this.onCancel()}
												>
													cancel
												</Button> */}
									</div>
								</div>
							</div>




						</div>
					</div>
				</div>
			</HubContent>
		);
	}
}

const mapStateToProps = state => ({
	vat_config: state.vat.vat_config_list,
});

const mapDispatchToProps = dispatch => ({
	getVatConfig: () => dispatch(getVatConfig())
});

export const viewNumberForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(VatForm);
