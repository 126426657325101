import config, {
	GET_OA_SUBJECT_LIST,
	GET_OA_SUBJECT,
	CREATE_UPDATE_OA_SUBJECT,
	DELETE_OA_SUBJECT,
	UPLOAD_OA_SUBJECT,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get OA Subject list
export const getOaSubjectListAction = (payload) => ({
	type: GET_OA_SUBJECT_LIST,
	payload,
});

// Get OA Subject
export const getOaSubjectAction = (payload) => ({
	type: GET_OA_SUBJECT,
	payload,
});

// Create-Update OA Subject
export const createUpdateOaSubjectAction = (payload) => ({
	type: CREATE_UPDATE_OA_SUBJECT,
	payload,
});

// Delete OA Subject
export const deleteOaSubjectAction = (payload) => ({
	type: DELETE_OA_SUBJECT,
	payload,
});

// Subject file upload
export const fileUploadAction = (payload) => ({
	type: UPLOAD_OA_SUBJECT,
	payload,
});

export const getOaSubjectList = (payload) => {
	return (dispatch) => {
		let url = `${config.endpoint.oa_subject}?fields=id,name,description,discipline,discipline_name`;

		let params = {};

		if (payload.pageNum && payload.pageSize) {
			params["page"] = payload.pageNum;
			params["page_size"] = payload.pageSize;
		} else if (payload.pageNum) {
			params["page"] = payload.pageNum;
		} else if (payload.pageSize) {
			params["page_size"] = payload.pageSize;
		}

		if (payload.discipline) {
			params["discipline_ids"] = payload.discipline;
		}

		if (payload.q) {
			params["q"] = payload.q;
		}

		if (!params.page_size) {
			params["page_size"] = 99999;
		}

		url += `&${serialize(params)}`;

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getOaSubjectListAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

export const getOaTokenSubjectList = (payload) => {
	return (dispatch) => {
		let url = `${config.endpoint.oa_subject}?fields=id,name&q=${payload}`;

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getOaSubjectListAction(res.results));
					resolve(Object.assign(res.results, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Get OA single subject
export const getOaSubject = (id) => {
	return (dispatch) => {
		let url = config.endpoint.oa_subject + `${id}/`;

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getOaSubjectAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Create-Update OA subject

export const createUpdateOaSubject = (payload, id) => {
	return (dispatch) => {
		let url = config.endpoint.oa_subject;
		let apiRequest = "";
		return new Promise((resolve, reject) => {
			if (id) {
				url += `${id}/`;
				apiRequest = api.put(url, payload);
			} else {
				apiRequest = api.post(url, payload);
			}

			apiRequest
				.then((res) => {
					dispatch(createUpdateOaSubjectAction(res.data));
					resolve(Object.assign(res.data, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Delete OA subject
export const deleteOaSubject = (id) => {
	return (dispatch) => {
		let url = config.endpoint.oa_subject + `${id}/`;

		return new Promise((resolve, reject) => {
			api.delete(url)
				.then((res) => {
					dispatch(deleteOaSubjectAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Subject file upload action
export const uploadOaSubject = (payload) => {
	let url = `${config.endpoint.oa_subject}upload/`;
	return (dispatch, getState) => {
		const token = getState().auth.token;

		return new Promise((resolve, reject) => {
			let filename = payload.get("filename");
			let headers = {
				"Content-Disposition": `attachment; filename="${filename}"`,
			};

			payload.delete("filename");

			api.post(url, payload, token, headers)
				.then((res) => {
					dispatch(fileUploadAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch(function (error) {

					dispatch(fileUploadAction(error.response));
					reject(Object.assign(error.response, { status: false }));
				});
		});
	};
};
