import React from 'react';
import BaseComponent from '..';
import { Checkbox } from 'antd';

class Check extends BaseComponent {
    state = {
        msg: this.props.errMsg || '',
        checked: this.props.checked || false,
    }

    onChange = (e) => {
        this.setState({ checked: e.target.checked });
        this.props.onChange && this.props.onChange(e);
    }

    componentDidUpdate() {
        this.props.errMsg && setTimeout(() => {
            this.setState({
                msg: this.props.errMsg,
            })
        }, 300)
    }

    render() {
        let { checked } = this.state;

        return (

            <div className={` ${this.props.containerClassName ? this.props.containerClassName : 'col-sm-4'}`} style={{ position: 'relative' }}>

                {this.state.msg && <span className="text-danger text-small" style={{ fontSize: 12, position: 'absolute', right: 20, top: -10, backgroundColor: '#fff', padding: '0px 10px', zIndex: 2 }}>{this.state.msg}</span>}

                {this.props.errMsg && <span className="text-danger text-small" style={{ fontSize: 12, position: 'absolute', right: 20, top: -10, backgroundColor: '#fff', padding: '0px 10px', zIndex: 2 }}>{this.props.errMsg}</span>}

                <Checkbox
                    checked={checked}
                    disabled={this.props.disabled || false}
                    id={this.props.field || ''}
                    onChange={this.onChange}
                    field={this.props.field}
                    value={this.props.value || ''}
                >
                    {this.props.label}
                </Checkbox>
            </div>
        )
    }
}

export const CustomCheckBox = Check;