import BaseAPI from "./BaseAPI";
import config from "../../config";
import store from "../context/store";
import { serialize } from "../../core/lib";

export class RestAPI extends BaseAPI {
  // Authentications
  signinHandler = ({ username, password, timezone }) => {
    let url = config.endpoint.login;
    let payload = { username, password, timezone };
    return this.callAPI(url, "post", payload);
  };

  oricIDCreateSigninHandler = (accessToken) => {
    let url = config.endpoint.create_oauth_user;
    return this.callAPI(url, "post", accessToken);
  };

  proxySigninHandler = ({ user, timezone }, accessToken) => {
    let url = config.endpoint.proxy_login;
    let payload = { user, timezone };
    let headers = { headers: { Authorization: `token ${accessToken}` } };

    return this.callAPI(url, "post", payload, headers);
  };

  signupHandler = (payload = {}) => {
    let url = config.endpoint.sign_up;
    return this.callAPI(url, "post", payload);
  };

  activateHandler = (payload = {}) => {
    let url = config.endpoint.active_account;
    return this.callAPI(url, "post", payload);
  };

  logoutHandler = (token) => {
    let url = config.endpoint.logout;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "post",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  getGroupHandler = (token) => {
    let url = config.endpoint.group;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  validateTokenHandler = (token) => {
    let url = config.endpoint.validate_token;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  // Users
  getUserHandler = (token, payload = {}) => {
    let url = config.endpoint.user;

    let params = {};

    if (payload.pageNum && payload.pageSize) {
      params["page"] = payload.pageNum;
      params["page_size"] = payload.pageSize;
    } else if (payload.pageNum) {
      params["page"] = payload.pageNum;
    } else if (payload.pageSize) {
      params["page_size"] = payload.pageSize;
    }

    if (payload.q) {
      params["q"] = payload.q;
    }

    if (!params.page_size) {
      params["page_size"] = 99999;
    }

    url += `?${serialize(params)}`;

    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  createUserHandler = (payload, token) => {
    let url = config.endpoint.user;
    let accessToken = "token " + token;
    return this.callAPI(url, "post", payload, {
      headers: { Authorization: accessToken },
    });
  };

  updateUserHandler = (payload, token) => {
    let userId = payload.id;
    let url = config.endpoint.user + userId + "/";
    let accessToken = "token " + token;
    return this.callAPI(url, "put", payload, {
      headers: { Authorization: accessToken },
    });
  };

  deleteUserHander = (userId, token) => {
    let url = `${config.endpoint.user}${userId}/mutate/delete/`;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "put",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  // Organizations
  getOrgHandler = (payload, token) => {
    let url = config.endpoint.org + "?domain=" + payload.group;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  getOrgAllHandler = (token) => {
    let url = config.endpoint.org;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  createOrgHandler = (payload, token) => {
    let url = config.endpoint.org;
    let accessToken = "token " + token;
    return this.callAPI(url, "post", payload, {
      headers: { Authorization: accessToken },
    });
  };

  updateOrgHandler = (payload, token) => {
    let orgId = payload.id;
    let url = config.endpoint.org + orgId + "/";
    let accessToken = "token " + token;
    return this.callAPI(url, "put", payload, {
      headers: { Authorization: accessToken },
    });
  };

  deleteOrgHander = (OrgId, token) => {
    let url = config.endpoint.org + OrgId + "/";
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "delete",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  singleOrgHander = (OrgId, token) => {
    let url = config.endpoint.org + OrgId + "/";
    let accessToken = `token ${token}`;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  // Journal

  createPublicationHandler = (payload, token) => {
    let url = config.endpoint.publication;
    let accessToken = "token " + token;
    return this.callAPI(url, "post", payload, {
      headers: { Authorization: accessToken },
    });
  };

  createJournalHandler = (payload, token) => {
    let url = config.endpoint.journal;
    let accessToken = "token " + token;
    return this.callAPI(url, "post", payload, {
      headers: { Authorization: accessToken },
    });
  };

  getPublicatonTypeHandler = (token) => {
    let url = config.endpoint.publication_type;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  getJournalTypeHandler = (token) => {
    let url = config.endpoint.journal_type;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  getContentTypeHandler = (token) => {
    let url = `${config.endpoint.content_type}?page_size=999999`;

    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  singleJournalHander = (journalId, token) => {
    let url = config.endpoint.publication + journalId;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  // Roles
  createRoleHandler = (payload, token) => {
    let url = config.endpoint.role;
    let accessToken = "token " + token;
    return this.callAPI(url, "post", payload, {
      headers: { Authorization: accessToken },
    });
  };

  getRoleHandler = (token) => {
    let url = config.endpoint.role;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  // Countries
  getCountryHandler = (token) => {
    let url = config.endpoint.country + "?page_size=9999";
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  getStateHandler = (payload, token) => {
    let qs = "";
    if (payload.hasOwnProperty("page_size")) {
      qs = `&page_size=${payload.page_size}`;
    }
    let url = config.endpoint.state + "?parent=" + payload.country + qs;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  getCityHandler = (payload, token) => {
    let qs = "";
    if (payload.hasOwnProperty("page_size")) {
      qs = `&page_size=${payload.page_size}`;
    }
    let url = config.endpoint.city + "?parent=" + payload.state + qs;
    let accessToken = "token " + token;
    return this.callAPI(
      url,
      "get",
      {},
      { headers: { Authorization: accessToken } }
    );
  };

  get = (url, token = store.getState().auth.token, options = {}) => {
    let accessToken = "token " + token;
    if (token) {
      let headers = { Authorization: accessToken };
      options.headers && Object.assign(headers, options.headers);
      Object.assign(options, { headers: headers });
      return this.callAPI(url, "get", {}, options);
    } else {
      return this.callAPI(url, "get");
    }
  };

  put = (url, payload, token = store.getState().auth.token) => {
    let accessToken = "token " + token;
    if (token)
      return this.callAPI(url, "put", payload, {
        headers: { Authorization: accessToken },
      });
    else return this.callAPI(url, "put", payload);
  };

  putInvoice = (url, payload, token = store.getState().auth.token) => {
    let accessToken = "token " + token;
    if (token)
      return this.callAPI(url, "put", payload, {
        headers: {
          Authorization: accessToken,
          "content-type": "multipart/form-data",
        },
      });
    else return this.callAPI(url, "put", payload);
  };

  post = (url, payload, token = store.getState().auth.token, headers = {}) => {
    let accessToken = "token " + token;
    if (token) {
      Object.assign(headers, { Authorization: accessToken });
      return this.callAPI(url, "post", payload, { headers: headers });
    } else {
      return this.callAPI(url, "post", payload, { headers: headers });
    }
  };

  delete = (url, token = store.getState().auth.token) => {
    let accessToken = "token " + token;
    if (token)
      return this.callAPI(
        url,
        "delete",
        {},
        { headers: { Authorization: accessToken } }
      );
    else return this.callAPI(url, "delete");
  };
}

const api = new RestAPI();

export { api, RestAPI as default };
