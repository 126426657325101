import React from 'react';
import EdiText from 'react-editext'
import BaseComponent from '../../../core/components/BaseComponent';

export class EditText extends BaseComponent {
    onSave = val => {
      let obj = {[this.props.keyName]:val}
      let id = this.props.article_id;
      this.props.onSave(obj,id);
    }
    render() {
        if(this.props.rowEdit){
          return(
            <div className="row">
              <div className="col-sm-3">
                <p className="m-1">
                  <strong>{this.props.title}</strong>
                </p>
              </div>
              <div className="col-sm-9">
                  <div style={{float:'left',width:'2%'}}>
                    <strong className="info_seperator">
                      {' '}
                      :{' '}
                    </strong>
                  </div>
                  { this.props.value }
                  { this.props.isMissing && <div style={{width:'97%',float:'right',marginTop:-8,    marginBottom:10,marginLeft:0}}>
                    <EdiText
                      type="text"
                      viewProps={{
                        className: 'react-answer-1',
                        style: { borderRadius: 3,marginLeft:-5}
                      }}
                      value=""
                      onSave={this.onSave}
                    />
                    </div> 
                  }
              </div>     
            </div>
          )
        }else{
          return (
            <div className="row">
              <div className="col-sm-12">
                <form className="form-inline">
                  <div className="form-group">
                    <label> <strong>{this.props.title} :</strong></label>
                    {this.props.isMissing &&
                      <div style={{marginLeft:5}}>
                        <EdiText
                          type="text"
                          viewProps={{
                            className: 'react-answer-1',
                            style: { borderRadius: 3,marginLeft:-5 }
                          }}
                          value=""
                          onSave={this.onSave}
                        />
                      </div>
                    }
                    { this.props.value }
                  </div>
                </form> 
              </div>
            </div>
          )
        }
    }
}


export default EditText