import { 
    GET_ARTICLE_SINGLE_FAULTY_AUTHOR,
    GET_ARTICLE_FAULTY_AUTHOR
} from "../../config";

const ArticleFaultyReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ARTICLE_FAULTY_AUTHOR: 
            return state = {
                ...state,
                article_faulty_author_view: action.payload
            }
            case GET_ARTICLE_SINGLE_FAULTY_AUTHOR: 
            return state = {
                ...state,
                article_faulty_author: action.payload
            }
       
        default:
            return state;
    }
}

export default ArticleFaultyReducer;
