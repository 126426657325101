import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../../core/components";
import validator from "validator";
import CoAuthorArticle from "./CoAuthorArticle";
import moment from "moment";
import {
  Badge,
  InputBox,
  SelectBox,
  OaDatePicker,
  PopupBox,
} from "../../../../../core/components/common";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const BtnText = "Add Article";

class PublisherArticalForm extends BaseComponent {
  state = {
    article: {},
    SubmitBtnText: BtnText,
    errMsg: false,
    isCoAuthor: false,
    isAuthor: false,
    edit_modal: false,
    co_authors: [],
    author: null,
    editing: false,
    loaded: false,
  };

  componentDidMount() {
    if (this.props.article && !this.state.loaded) {
      let article = {
        title: this.props.article.title,
        article_id: this.props.article.article_id,
        content_type: this.props.article.content_type,
        doi: this.props.article.doi,
        journal_name: this.props.article.journal_name,
        journal_acronym: this.props.article.journal_acronym,
        sub_system_acronym: this.props.article.sub_system_acronym,
        pissn: this.props.article.pissn,
        eissn: this.props.article.eissn,
        publisher_name: this.props.article.publisher_name,
        funder_name: this.props.article.funder_name,
        fund_acknowledgement: this.props.article.fund_acknowledgement,
        grant_number: this.props.article.grant_number,
        submission_date: this.props.article.submission_date,
        acceptance_date: this.props.article.acceptance_date,
        note: this.props.article.note,
      };

      let author = {};

      if (
        this.props.article &&
        this.props.article.author &&
        this.props.article.author.id
      ) {
        author = {
          id: this.props.article.author.id,
          first_name: this.props.article.author.first_name,
          last_name: this.props.article.author.last_name,
          email: this.props.article.author.email,
          affiliation: this.props.article.author.affiliation,
        };
      }

      this.setState({
        article: article,
        co_authors: (this.props.article && this.props.article.co_authors) || [],
        author: author,
        is_author_affiliation_match: this.props.is_author_affiliation_match,
        is_publication_match: this.props.article.is_publication_match,
        is_eissn_match: this.props.article.is_eissn_match,
        is_pissn_match: this.props.article.is_pissn_match,
        is_publisher_name_match: this.props.article.is_publisher_name_match,
        loaded: true,
        SubmitBtnText: "Update",
      });
    }
  }

  onChangeHandler = (value) =>
    this.setState((prevState) => Object.assign(prevState.article, value));

  onSubmitHandler = async (e) => {
    e.preventDefault();

    let data = Object.assign(this.state.article, {
      publisher_name: this.props.publisher_name,
      source_pub_type_name: "ARTICLE",
      visible_to_publisher: true,
    });

    this.state.author && Object.assign(data, { author: this.state.author });

    if (data.acceptance_date) {
      Object.assign(data, {
        acceptance_date: moment(this.state.article.acceptance_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        acceptance_date:null,
      });
    }

    if (data.submission_date) {
      Object.assign(data, {
        submission_date: moment(this.state.article.submission_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        submission_date:null
      });
    }
    Object.assign(data, { co_authors: this.state.co_authors });

    if (!data.title || !data.content_type) {
      this.setState({
        errMsg: (
          <div>
            {!data.title && <p>Title cannot be empty!</p>}
            {!data.content_type && <p>Content name cannot be empty</p>}
            {data.doi && !validator.isURL(data.doi) && (
              <p>URL format is not correct</p>
            )}
            {data.author &&
              data.author.email &&
              !validator.isEmail(data.author.email) && (
                <p>Email format is not correct</p>
              )}
          </div>
        ),
      });
    } else {
      this.setState({
        errMsg: false,
      });

      this.props.article && Object.assign(data, { id: this.props.article.id });

      this.props.onSubmitHandler(data)

    }
  };

  cancelLoader = async () => {
    this.setState({
      SubmitBtnText: BtnText,
      errMsg: false,
    });
  };

  AddNewAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isAuthor: true,
    });
  };

  AddNewCoAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isCoAuthor: true,
    });
  };

  onCloseAuthor = () => {
    this.setState({
      isCoAuthor: false,
      isAuthor: false,
      editing: false,
    });
  };

  onSaveCoAuthorHander = (author_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.co_authors[prevState.editing] = author_info;
        prevState.editing = false;
      } else {
        prevState.co_authors.push(author_info);
      }
      prevState.isCoAuthor = false;
      return prevState;
    });
  };

  onSaveAuthorHander = (author_info) => {
    this.setState((prevState) => {
      prevState.author = author_info;
      prevState.isAuthor = false;
      return prevState;
    });
  };

  onEditAuthor = () => {
    this.setState({
      isAuthor: true,
    });
  };

  onDeleteAuthor = () => {
    this.setState({
      author: null,
    });
  };

  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isCoAuthor: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.co_authors.splice(id, 1);
      return prevState;
    });
  };

  onFileDrop = (payload) => {
    confirmAlert({
      title: "Upload book",
      message: "Are you sure, you want to upload file?",
      buttons: [
        {
          label: "Upload",
          onClick: () => {
            this.props.articleFileUploadHandler(payload);
          },
        },
        {
          label: "Cancel",
          onClick: () => { },
        },
      ],
    });
  };

  render() {
    const format = "Do MMM YYYY";
    let {
      article,
      is_author_affiliation_match,
      is_publication_match,
      is_eissn_match,
      is_pissn_match,
      is_publisher_name_match,
    } = this.state;

    return (
      <div className="">
        {this.state.errMsg && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.errMsg}
            onCancel={this.cancelLoader}
          />
        )}

        <div className="form-group row">
          <InputBox
            label="Article title"
            onChange={this.onChangeHandler}
            field="title"
            className="col-sm-9 col-lg-10"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Article title"
            isRequired={true}
            labelInfo={true}
            labelInfoTitle="Enter Article title"
            defaultValue={article.title}
          />
        </div>

        <div className="form-group row">
          <InputBox
            label="Article id"
            onChange={this.onChangeHandler}
            field="article_id"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Article or document id"
            labelInfo={true}
            labelInfoTitle="Enter Article or document id"
            defaultValue={article.article_id}
          />

          <SelectBox
            label="Content type"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            onChange={this.onChangeHandler}
            field="content_type"
            labelInfo={true}
            labelInfoTitle="Select Content Type"
            data={this.props.content_type}
            isRequired={true}
            defaultValue={article.content_type}
          />
        </div>

        <div className="form-group row mt-4">
          <div className="col-sm-12">
            <div className="float-left" style={{ marginRight: 20 }}>
              <h6>Add Author</h6>
            </div>
            <div className="float-left">
              <button
                className="btn btn-light btn-o-light"
                onClick={this.AddNewAuthor}
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="mdi mdi-plus"></i> Add Author
              </button>
            </div>
          </div>
        </div>

        {this.state.isAuthor && (
          <CoAuthorArticle
            counter_org={this.props.counter_org}
            isShow={this.state.isAuthor} // if true modal will show up else it will be hidden
            onClose={this.onCloseAuthor} // Handle Close
            onSaveAuthor={this.onSaveAuthorHander}
            fieldLabel="Author"
            value={this.state.author}
            is_author_affiliation_match={is_author_affiliation_match}
          />
        )}

        <div className="form-group row">
          {this.state.author && (
            <Badge
              label={
                !this.state.author.first_name && !this.state.author.last_name
                  ? "No Name"
                  : (this.state.author.first_name || "") +
                  " " +
                  (this.state.author.last_name || "")
              }
              onEdit={this.onEditAuthor}
              onDelete={this.onDeleteAuthor}
              style={is_author_affiliation_match === false ? styles.error : {}}
            />
          )}
        </div>
        {/* Author Information End */}

        <div className="form-group row">
          <InputBox
            label="Journal name"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            labelInfo={true}
            labelInfoTitle="Enter Journal Name"
            onChange={this.onChangeHandler}
            field="journal_name"
            placeholder="Enter Journal name"
            defaultValue={article.journal_name}
            style={is_publication_match === false ? styles.error : {}}
          />
          <InputBox
            label="Article DOI"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            onChange={this.onChangeHandler}
            field="doi"
            placeholder="Enter Doi"
            labelInfo={true}
            labelInfoTitle="Enter Doi"
            InputType="url"
            defaultValue={article.doi}
          />
        </div>

        <div className="form-group row">
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter Acronym"
            label="Acronym"
            onChange={this.onChangeHandler}
            field="journal_acronym"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Acronym"
            defaultValue={article.journal_acronym}
          />

          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter Site"
            label="Site"
            onChange={this.onChangeHandler}
            field="sub_system_acronym"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Site"
            defaultValue={article.sub_system_acronym}
          />
        </div>

        <div className="form-group row">
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter pISSN"
            label="pISSN"
            onChange={this.onChangeHandler}
            field="pissn"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter pISSN"
            defaultValue={article.pissn}
            style={is_pissn_match === false ? styles.error : {}}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter eISSN"
            label="eISSN"
            onChange={this.onChangeHandler}
            field="eissn"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder=" Enter eISSN"
            defaultValue={article.eissn}
            style={is_eissn_match === false ? styles.error : {}}
          />
        </div>

        <div className="form-group row">
          <InputBox
            label="Publisher Name"
            onChange={this.onChangeHandler}
            field="publisher_name"
            disabled={true}
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            style={is_publisher_name_match === false ? styles.error : {}}
            defaultValue={this.props.publisher_name}
          />

          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter Funder"
            label="Funder"
            onChange={this.onChangeHandler}
            field="funder_name"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            defaultValue={article.funder_name}
            placeholder="Enter Funder"
            style={{}}
          />
        </div>

        <div className="form-group row">
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter Grant Note"
            label="Grant Note"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            onChange={this.onChangeHandler}
            field="fund_acknowledgement"
            placeholder="Enter Grant Note"
            defaultValue={article.fund_acknowledgement}
          />
          <InputBox
            labelInfo={true}
            labelInfoTitle="Enter Grant number"
            label="Grant number"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            onChange={this.onChangeHandler}
            field="grant_number"
            placeholder="Enter Grant number"
            defaultValue={article.grant_number}
          />
        </div>

        <div className="form-group row">
            <OaDatePicker
            labelInfo={true}
            labelInfoTitle="Select submission date"
              label="Submission date"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="submission_date"
              showTime={false}
              format={format}
              placeholder="Select submission date"
            />
          

            <OaDatePicker
            labelInfo={true}
            labelInfoTitle="Select Acceptance date"
              label="Acceptance date"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="acceptance_date"
              showTime={false}
              format={format}
              placeholder="Select Acceptance date"
            />
          
 
        </div>
        {/* Article Information End */}

        
        <div className="form-group row mt-4">
          <div className="col-sm-12">
            <div className="float-left" style={{ marginRight: 30 }}>
              <h6>Add Co Author</h6>
            </div>
            <div className="float-left">
              <button
                className="btn btn-light btn-o-light"
                onClick={this.AddNewCoAuthor}
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="mdi mdi-plus"></i> Add Co-Author
              </button>
            </div>
          </div>
        </div>
        {this.state.isCoAuthor && (
          <CoAuthorArticle
            isShow={this.state.isCoAuthor}
            onClose={this.onCloseAuthor}
            onSaveAuthor={this.onSaveCoAuthorHander}
            fieldLabel="Co author"
            value={this.state.co_authors[this.state.editing]}
          />
        )}
        <div className="form-group row">
          {this.state.co_authors.length > 0 &&
            this.state.co_authors.map((co_author, id) => {
              return (
                <div key={id}>
                  <Badge
                    label={
                      !co_author.first_name && !co_author.last_name
                        ? "No Name"
                        : (co_author.first_name || "") +
                        " " +
                        (co_author.last_name || "")
                    }
                    onEdit={() => this.onEditCoAuthor(id)}
                    onDelete={() => this.onDeleteCoAuthor(id)}
                  />
                </div>
              );
            })}
        </div>

        <div className="text-center m-t-15">
          <button
            type="button"
            className="btn btn-primary btn-md  waves-effect waves-light"
            onClick={this.onSubmitHandler}
          >
            {this.state.SubmitBtnText}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  publisher_name: state.auth.user && state.auth.user.organisation_name,
  content_type: state.journals.content_type ,
  publication_type: state.journals.publication_type ,
  article_status: state.articles.article_status ,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
});

const mapDispatchToProps = (dispatch) => ({});

const styles = {
  error: { border: "1px solid red" },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublisherArticalForm);
