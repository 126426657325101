import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { SelectBox, PopupBox } from "../../../../../core/components/common";
import { connect } from "react-redux";
import {
  generateOrganisationToken,
  getOrganisationToken,
  getAllCounterOrgList,
  getOaTokenisActive,
} from "../../../../actions";
class DB extends BaseComponent {
  state = {
    org_token: {},
  };

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.org_token, value);
    });
    // if(value.hasOwnProperty('token')){
    //     this.setState((prevState) => {
    //         Object.assign(prevState.org_token, {token:value.token.toString()})
    //     })
    // }
  };
  onSubmitHandler = async () => {
    let orgToken = this.state.org_token;
    if (!this.state.org_token.organisation || !this.state.org_token.token) {
      this.setState({
        isEmpty: (
          <div>
            {!this.state.org_token.organisation && (
              <p>Organisation must be selected</p>
            )}
            {!this.state.org_token.token && <p>Token must be selected</p>}
          </div>
        ),
      });
    } else {
      try {
        await this.props.generateOrganisationToken(orgToken);
        this.props.history.push("/token/allocate-token-list");
      } catch (e) {
        if (e.response) {
          this.setState({
            isEmpty: (
              <div>
                <p>{e.response.data.message}</p>
              </div>
            ),
          });
        }
      }
    }
  };
  async componentDidMount() {
    await this.props.getOaToken();
    await this.props.getAllCounterOrgList();
    let oaTokenObj = this.props.oaTokenList;
    let dataOaToken = [];
    oaTokenObj.map((item) => {
      return dataOaToken.push({
        name: item.lable,
        token: item.token,
        valid_from: item.valid_from,
        valid_to: item.valid_to,
        id: item.id,
      });
    });
    this.setState({
      data: dataOaToken,
    });
  }

  cancelLoader = async () => {
    this.setState({
      isEmpty: false,
    });
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return (
      <HubContent title="Allocate token" className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="card m-b-20">
              <div className="card-body">
                <div className="form-group row">
                  <SelectBox
                    label="Organisation"
                    onChange={this.onChangeHandler}
                    field="organisation"
                    className="col-sm-9"
                    labelClass="col-sm-3"
                    data={this.props.orgList}
                    defaultValue={this.state.organisation}
                    isRequired={true}
                  />
                </div>
                <div className="form-group row">
                  <SelectBox
                    label="Token"
                    onChange={this.onChangeHandler}
                    field="token"
                    className="col-sm-9"
                    labelClass="col-sm-3"
                    data={this.state.data}
                    defaultValue={this.state.token}
                    isRequired={true}
                    // multiple={true}
                  />
                </div>
                <div className="text-center m-t-15">
                  <button
                    onClick={this.onSubmitHandler}
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Allocate token
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  oaTokenList: state.oaToken.oa_token_list || false,
  orgList: state.OaDeals.counter_org || false,
});

const mapDispatchToProps = (dispatch) => ({
  generateOrganisationToken: (payload) =>
    dispatch(generateOrganisationToken(payload)),
  getOrganisationToken: () => dispatch(getOrganisationToken()),
  getAllCounterOrgList: (payload) => dispatch(getAllCounterOrgList(payload)),
  getOaToken: (payload) => dispatch(getOaTokenisActive(payload)),
});
export const OrganisationToken = connect(
  mapStateToProps,
  mapDispatchToProps
)(DB);
