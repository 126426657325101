
const transactionTypes = [
  { id: "all", name: "All" },
  { id: "CREDIT", name: "Credit" },
  { id: "DEBIT", name: "Debit" },
  { id: "REFUND", name: "Refund" }
];

const filterDays = [
  {
    name: "Time Period",
    id: ""
  },
  {
    name: "Current Year",
    id: 365
  },
  {
    name: "Last 180 days",
    id: 180
  },
  {
    name: "Last 90 days",
    id: 90
  },
  {
    name: "Last 30 days",
    id: 30
  },
  {
    name: "Last 15 days",
    id: 15
  },
  {
    name: "Last 7 days",
    id: 7
  }
];

const filterMonths = [
  {
    name: "Month",
    id: ""
  },
  {
    name: "January",
    id: 1
  },
  {
    name: "February",
    id: 2
  },
  {
    name: "March",
    id: 3
  },
  {
    name: "April",
    id: 4
  },
  {
    name: "May",
    id: 5
  },
  {
    name: "June",
    id: 6
  },
  {
    name: "July",
    id: 7
  },
  {
    name: "August",
    id: 8
  },
  {
    name: "September",
    id: 9
  },
  {
    name: "October",
    id: 10
  },
  {
    name: "November",
    id: 11
  },
  {
    name: "December",
    id: 12
  }
];

const invoiceStatusList = [
  { id: "all", name: "All" },
  { id: "cancelled", name: "Cancelled" },
  { id: "closed", name: "Closed" },
  { id: "expired", name: "Expired" },
  { id: "opened", name: "Opened" },
  { id: "acknowledged", name: "Paid" },
  { id: "paid", name: "Payment updated" },
  { id: "rejected", name: "Rejected" },
];


export {
  transactionTypes,
  filterDays,
  filterMonths,
  invoiceStatusList
}