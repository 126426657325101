import React from 'react';
import { connect } from 'react-redux';
// import { Link, NavLink } from 'react-router-dom';
import BaseComponent from '../../../core/components/BaseComponent';
import '../../../static/styles/index.scss'

export class ViewRow extends BaseComponent {
    render() {
        return (
            <div className="row">
                <div className="col-sm-3">
                    <p className="m-1"><strong>{this.props.label}</strong></p>
                </div>
                {/* {{this.props.onClick ? this.props.value : this.props.value }`} */}
                <div className="col-sm-9">
                    <p className="m-1">
                        {
                         this.props.onClick ?  <strong className="info_seperator" onClick={this.props.onClick}> {this.props.value }  </strong> : <strong className="info_seperator"> {this.props.value }</strong>
                        }                    </p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ViewRow)