import React from "react";
import Iframe from "react-iframe";
import RGL, { WidthProvider } from "react-grid-layout";
import { connect } from "react-redux";
import { HubContent } from "../../../../core/components";
import {
  ProgressBar,
  DBCard,
  DBChart,
  NewOAdeals,
  WhitePublisherCard,
  ReadPublishList,
} from "./PublisheruserDashboard";
import { SelectBox } from "../../../../core/components/common";
import {
  getPremiumDashboardData,
  getPublisherDefaultDashboardData,
  getInstitutionsList,
  getoaDealFilterList,
  getChildOrganisationList,
  getPublicPublisher,
  dashboardDragAndDropControl,
  dashboardDragAndDropControlList,
  getDashboardLevelList,
} from "../../../actions";
import { serialize } from "../../../../core/lib";
import { Card } from "antd";


const ReactGridLayout = WidthProvider(RGL);

class DB extends React.PureComponent {
  static defaultProps = {
    isResizable: true,
    className: "layout",
    cols: 12,
    rowHeight: 30,
    margin: [15, 30],
    onLayoutChange: function () { },
  };

  constructor(props) {
    super(props);

    this.state = {
      filterParams: {},
      executed: false,

      grid_1: { x: 0, y: 0, w: 3, h: 3 },
      grid_2: { x: 3, y: 0, w: 3, h: 3 },
      grid_3: { x: 6, y: 0, w: 3, h: 3 },
      grid_4: { x: 9, y: 0, w: 3, h: 3 },
      grid_5: { w: 4, h: 6, x: 0, y: 3 },
      grid_6: { w: 5, h: 6, x: 4, y: 3 },
      grid_7: { w: 3, h: 3, x: 9, y: 3 },
      grid_8: { w: 3, h: 3, x: 9, y: 6 },
      grid_9: { w: 4, h: 6, x: 0, y: 9 },
      grid_10: { w: 4, h: 6, x: 4, y: 9 },
      grid_11: { w: 4, h: 6, x: 8, y: 10 },
      grid_12: { w: 4, h: 3, x: 0, y: 15 },
      grid_13: { w: 4, h: 3, x: 0, y: 18 },
      grid_14: { w: 4, h: 6, x: 4, y: 15 },
      grid_15: { w: 4, h: 6, x: 8, y: 15 },
      grid_16: { w: 4, h: 3, x: 0, y: 21 },
      grid_17: { w: 4, h: 3, x: 4, y: 21 },
      grid_18: { w: 4, h: 3, x: 8, y: 21 },
      grid_19: { w: 4, h: 3, x: 0, y: 24 },
      grid_20: { w: 4, h: 3, x: 8, y: 24 },
      grid_21: { w: 4, h: 3, x: 4, y: 21 },
      grid_22: { w: 4, h: 6, x: 0, y: 27 },
      grid_23: { w: 4, h: 6, x: 4, y: 27 },
      hideSaveBtn: false,
      hideTab: false,
    };
  }

  onResize = async (value) => {
    this.setState({ value, hideSaveBtn: true });
    function saveToLS(key, value) {
      if (global.localStorage) {
        global.localStorage.setItem(
          "rgl-7",
          JSON.stringify({
            [key]: value,
          })
        );
      }
    }
    if (value) {
      saveToLS("layout", value);
    }

    function getFromLS(key) {
      let ls = {};
      if (global.localStorage) {
        try {
          ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
        } catch (e) {
          /*Ignore*/
        }
      }
      return ls[key];
    }
    const originalLayout = getFromLS("layout") || [];

    this.setState({
      value: value,
      id: this.props.auth && this.props.auth.id,
      layout: JSON.parse(JSON.stringify(originalLayout)),
    });
  };

  onLayoutChange = async (value) => {
    this.setState({ value: value });
    function saveToLS(key, value) {
      if (global.localStorage) {
        global.localStorage.setItem(
          "rgl-7",
          JSON.stringify({
            [key]: value,
          })
        );
      }
    }
    if (value) {
      saveToLS("layout", value);
    }

    function getFromLS(key) {
      let ls = {};
      if (global.localStorage) {
        try {
          ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
        } catch (e) {
          /*Ignore*/
        }
      }
      return ls[key];
    }
    const originalLayout = getFromLS("layout") || [];

    this.setState({
      value: value,
      id: this.props.auth && this.props.auth.id,
      layout: JSON.parse(JSON.stringify(originalLayout)),
    });

  };
  handleSaveBtn = () => {
    this.setState({
      hideSaveBtn: true,
    });
  };
  onDragAndDropSave = async () => {
    await this.props.dashboardDragAndDropControl(this.state.id, {
      disabled_components: this.state.disabled_compontents,

      dashboard_layout: {
        layout: this.state.value,
      },
    });
    this.setState({
      hideSaveBtn: false,
    });
  };


  defaultCurrency() {
    return ["£ 0.00", "$ 0.00", "€ 0.00"];
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  currencyCalculation(component) {
    if (component && component.status_code && component.status_code !== 200) {
      return ["£ 0.00", "$ 0.00", "€ 0.00"];
    }

    let gbp =
      "£ " +
      this.numberWithCommas(
        component.data.gbp ? parseFloat(component.data.gbp).toFixed(2) : 0.0
      );
    let usd =
      "$ " +
      this.numberWithCommas(
        component.data.usd ? parseFloat(component.data.usd).toFixed(2) : 0.0
      );
    let eur =
      "€ " +
      this.numberWithCommas(
        component.data.eur ? parseFloat(component.data.eur).toFixed(2) : 0.0
      );

    return [gbp, usd, eur];
  }
  pieChartCalculation(component) {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    return Object.values(component.data);
  }

  pieChartCalculationArticleSummary(component) {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let componentData = component.data;

    let acceptedTotal =
      (componentData.accepted && componentData.accepted.total_count) || 0;
    let approvedTotal =
      (componentData.approved && componentData.approved.total_count) || 0;
    let rejectedTotal = componentData.rejected || 0;

    let chartData = [acceptedTotal, approvedTotal, rejectedTotal];

    return chartData;
  }
  _isMounted = false
   componentDidMount() {
    this._isMounted = true;

    this.props.getDashboardLevelList(
      this.props.auth.organisation_id
    ).then(dashboardLevelList => {
      this.props.dashboardDragAndDropControlList(
        this.props.auth && this.props.auth.id
      ).then((data) => {
        const filteredDataList = Object.keys(dashboardLevelList.components).filter(
          (prop) => data.components[prop] && data.components[prop].hide
        );
        function saveToLS(key, value) {
          if (global.localStorage) {
            global.localStorage.setItem(
              "rgl-7",
              JSON.stringify({
                [key]: value,
              })
            );
          }
        }
        if (data.dashboard_layout.layout) {
          saveToLS("layout", data.dashboard_layout.layout);
        }
        function getFromLS(key) {
          let ls = {};
          if (global.localStorage) {
            try {
              ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
            } catch (e) {
              /*Ignore*/
            }
          }
          return ls[key];
        }
        const originalLayout = getFromLS("layout") || [];

        if (this._isMounted) {
          this.setState({
            components: Object.keys(data.components),
            value: data.dashboard_layout.layout,
            layout: JSON.parse(JSON.stringify(originalLayout)),
            settingcomponents: data.components,
            disabled_compontents: filteredDataList,
          });
        }
      });

    });


    this.props.getoaDealFilterList();
    this.props.getChildOrganisationList();
    this.props.getInstitutionsList();
    this.getDashboardData(
      (this.props.dashboardcontrol &&
        this.props.dashboardcontrol.dashboards &&
        this.props.dashboardcontrol.dashboards.DEFAULT) || 'BASIC'
    );




  }

  componentWillUnmount() {
    this._isMounted = false;

    this.setState({
      dashBoardURL: null,
      success: false,
    });
  }

  filterData = (value) => {
    this.setState((prevState) => {
      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });
    let filter = serialize(this.state.filterParams);
    this.props.getPublisherDefaultDashboardData({ filter });
  };

  apcAverageCalculation = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [0.0, 0.0, 0.0];
    }

    let gbp = component.data.gbp
      ? parseFloat(component.data.gbp).toFixed(2)
      : 0.0;
    let usd = component.data.usd
      ? parseFloat(component.data.usd).toFixed(2)
      : 0.0;
    let eur = component.data.eur
      ? parseFloat(component.data.eur).toFixed(2)
      : 0.0;

    return [usd, eur, gbp];
  };

  getLabels = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let data = Object.keys(component.data);
    return data.map((item) => this.capitalize(item.replace(/_/g, " ")));
  };

  chartCalculation(component) {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let keys = ["funder", "journal", "publisher"];
    let values = [];
    if ("items" in component) {
      let data = component.items ? component.items : [];
      data.map((item) => {
        let ObjectKeys = Object.keys(item);
        let key = this.common(ObjectKeys, keys).toString();
        if (key === "funder") {
          values.push(item.total_funding);
        } else if (key === "publisher") {
          values.push(item.total_publish);
        } else {
          values.push(item.total);
        }
        return item;
      });
      return values;
    } else {
      return "data" in component ? Object.values(component.data) : [];
    }
  }
  chartArrayofType = (data) => data && data.map((item) => item.total);
  chartaArrayOfLabel = (data) => data && data.map((item) => item.journal.name);

  chartJournalsdata = (data) => data && data.map((item) => item.total);
  chartJournalsLabel = (data) => data && data.map((item) => item.journal.name);

  chartfunderType = (data) => data && data.map((item) => item.total_funding);
  chartfunderLabels = (data) => data && data.map((item) => item.funder);

  chartinstitutionLabels = (data) =>
    data && data.map((item) => item.institution.name);
  chartinstitutionType = (data) =>
    data && data.map((item) => item.total_publish);

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  oadeal_filter_list = (data) => {
    let ObjecttoArray = Object.keys(data);

    return ObjecttoArray;
  };

  onChangeSwitch = async (checked) => {
    this.setState(
      {
        executed: checked,
        hideTab: checked !== true ? true : false,
      },
      async () => {
        let dashboardType = this.state.executed === true ? 'PREMIUM' : 'BASIC';

        this.getDashboardData(dashboardType);
      }
    );
  };

  getDashboardData = async (dashboardType) => {
    try {
      this.setState({
        executed: dashboardType === 'PREMIUM',
      });

      await this.props.getPremiumDashboardData(dashboardType);

      if (this.props.dashboardcontrol
        && this.props.dashboardcontrol.dashboards
        && this.props.dashboardcontrol.dashboards.DEFAULT !== dashboardType
        && this.props.dashboardcontrol.dashboards.OTHERS.length > 0
      ) {
        let authData = this.props.dashboardcontrol;
        let dashboards = Object.assign({}, authData.dashboards);
        let others = dashboards.OTHERS;

        others = others.filter(item => item !== dashboardType);

        others.push(dashboards.DEFAULT);

        Object.assign(authData.dashboards, {
          DEFAULT: dashboardType,
          OTHERS: others,
        });

        localStorage.setItem("auth", JSON.stringify(authData));
      }

      if (dashboardType === 'PREMIUM') {
          this.setState({
            dashBoardURL: this.props.dashbaordPremiumData.data,
            success: this.props.dashbaordPremiumData.success,
          });
        


      }

    } catch (error) {

    }
  }

  render() {
    let { institute_list } = this.props;
    let institutions = [
      {
        name: "All Institutions",
        id: "all",
      },
    ];

    let year = [
      {
        name: "Years",
        id: "all",
      },
    ];

    function oaDeal(input) {
      var output = [];

      for (var index in input) {
        output.push({
          id: index,
          name: input[index],
        });
      }

      return output;
    }

    for (let i = 2018; i <= 2050; i++) {
      year.push({
        name: i,
        id: i,
      });
    }

    institutions = institute_list && institutions.concat(institute_list);
    let manage_dashboard = this.props.dashboardcontrol &&  this.props.dashboardcontrol.permissions && this.props.dashboardcontrol.permissions.find(item=>item==="manage_dashboard") 
   
    let manage_premium_dashboard =this.props.dashboardcontrol &&  this.props.dashboardcontrol.permissions && this.props.dashboardcontrol.permissions.find(item=>item==="manage_premium_dashboard") 
    return (
      
      <HubContent
        hideTab={this.state.hideTab}
        executed={this.state.executed}
        onChangeSwitch={this.onChangeSwitch}
        manage_premium_dashboard={
          manage_premium_dashboard
          
        }
        manage_dashboard={manage_dashboard}
        dashboardcontrol={true}
        article={this.props.dashbaordData.estimated_income_summary_component}
        article_title="Pending articles"
        article_income_title="Estimated income"
        estimated_income_hide_summery={
          this.state.settingcomponents &&
          this.state.settingcomponents.estimated_income_summary_component &&
          this.state.settingcomponents.estimated_income_summary_component
            .hide !== true &&
          this.state.components &&
          this.state.components.includes("estimated_income_summary_component")
        }
        handleSaveDesign={this.onDragAndDropSave}
        hideSaveBtn={this.state.hideSaveBtn}
      >
        {this.state.executed !== true  ? (
           manage_dashboard==="manage_dashboard"? <div className="card m-b-20">
            <div className="row dashboard-filter">
              <SelectBox
                className="col-md-3"
                data={oaDeal(
                  this.props.oadeal_filter && this.props.oadeal_filter.data
                )}
                onChange={this.filterData}
                field="filter_deal"
                defaultValue="All"
              />
              <SelectBox
                className="col-md-4"
                data={institutions}
                onChange={this.filterData}
                field="filter_institution"
                defaultValue="all"
              />
              <SelectBox
                className="col-md-5"
                data={year}
                onChange={this.filterData}
                field="filter_year"
                defaultValue="all"
              />
            </div>
            <div
              id="collapse1"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="row">
                <div className="col-sm-12">
                  <div onClick={this.handleSaveBtn}>
                    <ReactGridLayout
                      {...this.props}
                      layout={this.state.layout}
                      onResize={this.onResize}
                      onLayoutChange={this.onLayoutChange}
                    >
                      {this.state.settingcomponents &&
                        this.state.settingcomponents.oa_income_component &&
                        this.state.settingcomponents.oa_income_component.hide !==
                        true &&
                        this.state.components &&
                        this.state.components.includes("oa_income_component") ? (
                          <div
                            key="1"
                            className="oa-income-responsive"
                            data-grid={this.state.grid_1}
                          >
                            <DBCard
                              icon="mdi mdi-cube-outline"
                              title="OA INCOME"
                              body={
                                this.props.oa_income_data
                                  ? this.currencyCalculation(
                                    this.props.oa_income_data
                                  )
                                  : this.defaultCurrency()
                              }
                            />
                          </div>
                        ) : (
                          <div
                            key="1"
                            data-grid={this.state.grid_1}
                            style={{ display: "none" }}
                          ></div>
                        )}

                      {this.state.settingcomponents &&
                        this.state.settingcomponents.pub_deposit_component &&
                        this.state.settingcomponents.pub_deposit_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "pub_deposit_component"
                        ) ? (
                          <div
                            key="2"
                            className="oa-income-responsive"
                            data-grid={this.state.grid_2}
                          >
                            <DBCard
                              icon="mdi mdi-cube-outline"
                              title="DEPOSIT"
                              body={
                                this.props.deposit_data
                                  ? this.currencyCalculation(
                                    this.props.deposit_data
                                  )
                                  : this.defaultCurrency()
                              }
                            />
                          </div>
                        ) : (
                          <div
                            key="2"
                            data-grid={this.state.grid_2}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .publisher_payment_invoice_component &&
                        this.state.settingcomponents
                          .publisher_payment_invoice_component.hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "publisher_payment_invoice_component"
                        ) ? (
                          <div
                            key="3"
                            className="oa-income-responsive"
                            data-grid={this.state.grid_3}
                          >
                            <DBCard
                              icon="mdi mdi-cube-outline"
                              title="Card and Invoice"
                              body={
                                this.props.publisher_payment_invoice_component
                                  ? this.currencyCalculation(
                                    this.props
                                      .publisher_payment_invoice_component &&
                                    this.props
                                      .publisher_payment_invoice_component
                                  )
                                  : this.defaultCurrency()
                              }
                            />
                          </div>
                        ) : (
                          <div
                            key="3"
                            data-grid={this.state.grid_3}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .pub_available_fund_component &&
                        this.state.settingcomponents.pub_available_fund_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "pub_available_fund_component"
                        ) ? (
                          <div
                            key="4"
                            className="oa-income-responsive"
                            data-grid={this.state.grid_4}
                          >
                            <DBCard
                              icon="mdi mdi-cube-outline"
                              title="AVAILABLE FUNDS"
                              body={
                                this.props.available_fund_data
                                  ? this.currencyCalculation(
                                    this.props.available_fund_data
                                  )
                                  : this.defaultCurrency()
                              }
                            />
                          </div>
                        ) : (
                          <div
                            key="4"
                            data-grid={this.state.grid_4}
                            style={{ display: "none" }}
                          ></div>
                        )}

                      {this.state.settingcomponents &&
                        this.state.settingcomponents.article_summary_component &&
                        this.state.settingcomponents.article_summary_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "article_summary_component"
                        ) ? (
                          <div key="5" data-grid={this.state.grid_5}>
                            {this.props.article_summary_data && (
                              <DBChart
                                title="Articles"
                                showpop={this.state.showPop}
                                titleIcon="info-circle"
                                type="doughnut"
                                width={220}
                                height={220}
                                containerHeight={320}
                                chartMarginTopClassName="m-t-40"
                                className="col-md-5"
                                labels={["Acceptance", "Approved", "Declined"]}
                                data={this.pieChartCalculationArticleSummary(
                                  this.props.article_summary_data
                                )}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            key="5"
                            data-grid={this.state.grid_5}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .read_and_publish_deal_component &&
                        this.state.settingcomponents
                          .read_and_publish_deal_component.hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "read_and_publish_deal_component"
                        ) ? (
                          <div key="6" data-grid={this.state.grid_6}>
                            {this.state.components && (
                              <ReadPublishList

                                titleIcon="info-circle"
                                data={
                                  this.props.read_and_publish_deal &&
                                  this.props.read_and_publish_deal.data
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            key="6"
                            data-grid={this.state.grid_6}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .oa_inst_membership_component &&
                        this.state.settingcomponents.oa_inst_membership_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "oa_inst_membership_component"
                        ) ? (
                          <div key="7" data-grid={this.state.grid_7}>
                            <DBCard
                              noBg={true}
                              icon="mdi mdi-cube-outline"
                              title="OA Membership"
                              body={
                                this.props.oa_inst_membership_component
                                  ? this.currencyCalculation(
                                    this.props.oa_inst_membership_component &&
                                    this.props.oa_inst_membership_component
                                  )
                                  : this.defaultCurrency()
                              }
                            />
                          </div>
                        ) : (
                          <div
                            key="7"
                            data-grid={this.state.grid_7}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {(this.state.settingcomponents &&
                        this.state.settingcomponents.credit_payment_component &&
                        this.state.settingcomponents.credit_payment_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "credit_payment_component"
                        )) ||
                        (this.state.settingcomponents &&
                          this.state.settingcomponents.credit_pending_component &&
                          this.state.settingcomponents.credit_pending_component
                            .hide !== true &&
                          this.state.components &&
                          this.state.components.includes(
                            "credit_pending_component"
                          )) ? (
                          <div key="8" data-grid={this.state.grid_8}>
                            <Card>
                              <div className="row">
                                <div className="col-md-3 padding-none">
                                  Credit
                              </div>
                                <div className="col-md-3 padding-right"> £ </div>
                                <div className="col-md-3 padding-right">€ </div>
                                <div className="col-md-3 padding-right">$</div>
                                {this.state.settingcomponents &&
                                  this.state.settingcomponents
                                    .credit_pending_component &&
                                  this.state.settingcomponents
                                    .credit_pending_component.hide !== true &&
                                  this.state.components &&
                                  this.state.components.includes(
                                    "credit_pending_component"
                                  ) ? (
                                    <>
                                      <div className="col-md-3 padding-none">
                                        {" "}
                                    Due
                                  </div>
                                      <div className="col-md-3 padding-right">
                                        <p>
                                          {" "}
                                          {this.props.credit_pending_data.data &&
                                            this.props.credit_pending_data.data.gbp}
                                        </p>
                                      </div>

                                      <div className="col-md-3 padding-right">
                                        {" "}
                                        <p>
                                          {" "}
                                          {this.props.credit_pending_data.data &&
                                            this.props.credit_pending_data.data.eur}
                                        </p>
                                      </div>
                                      <div className="col-md-3 padding-right">
                                        {" "}
                                        <p>
                                          {this.props.credit_pending_data.data &&
                                            this.props.credit_pending_data.data.usd}
                                        </p>
                                      </div>
                                    </>
                                  ) : null}
                                {this.state.settingcomponents &&
                                  this.state.settingcomponents
                                    .credit_payment_component &&
                                  this.state.settingcomponents
                                    .credit_payment_component.hide !== true &&
                                  this.state.components &&
                                  this.state.components.includes(
                                    "credit_payment_component"
                                  ) ? (
                                    <>
                                      <div className="col-md-3 padding-none">
                                        Payment
                                  </div>
                                      <div className="col-md-3 padding-right">
                                        <p>
                                          {this.props.credit_payment_data.data &&
                                            this.props.credit_payment_data.data.gbp}
                                        </p>
                                      </div>

                                      <div className="col-md-3 padding-right">
                                        <p>
                                          {this.props.credit_payment_data.data &&
                                            this.props.credit_payment_data.data.eur}
                                        </p>
                                      </div>
                                      <div className="col-md-3 padding-right">
                                        <p>
                                          {" "}
                                          {this.props.credit_payment_data.data &&
                                            this.props.credit_payment_data.data.usd}
                                        </p>
                                      </div>
                                    </>
                                  ) : null}
                              </div>
                            </Card>
                          </div>
                        ) : (
                          <div
                            key="8"
                            data-grid={this.state.grid_8}
                            style={{ display: "none" }}
                          ></div>
                        )}

                      {this.state.settingcomponents &&
                        this.state.settingcomponents.book_summary_component &&
                        this.state.settingcomponents.book_summary_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "book_summary_component"
                        ) ? (
                          <div key="9" data-grid={this.state.grid_9}>
                            {this.props.book_summary_component && (
                              <DBChart
                                title="Books"
                                type="doughnut"
                                titleIcon="info-circle"
                                width={220}
                                height={220}
                                containerHeight={320}
                                chartMarginTopClassName="m-t-40"
                                className="col-md-4"
                                labels={["Acceptance", "Approved", "Declined"]}
                                data={this.pieChartCalculation(
                                  this.props.book_summary_component
                                )}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            key="9"
                            data-grid={this.state.grid_9}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .publisher_institution_component &&
                        this.state.settingcomponents
                          .publisher_institution_component.hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "publisher_institution_component"
                        ) ? (
                          <div key="10" data-grid={this.state.grid_10}>
                            <DBChart
                              chartMarginTopClassName="m-t-40"
                              height={220}
                              titleIcon="info-circle"
                              containerHeight={320}
                              title="Institutions"
                              className="col-md-4"
                              type="bar"
                              labels={this.chartinstitutionLabels(
                                this.props.publisher_institution_component
                                  ? this.props.publisher_institution_component &&
                                  this.props.publisher_institution_component
                                    .data.items
                                  : null
                              )}
                              data={this.chartinstitutionType(
                                this.props.publisher_institution_component
                                  ? this.props.publisher_institution_component &&
                                  this.props.publisher_institution_component
                                    .data.items
                                  : null
                              )}
                            />
                          </div>
                        ) : (
                          <div
                            data-grid={this.state.grid_10}
                            key="10"
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents.publisher_funder_component &&
                        this.state.settingcomponents.publisher_funder_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "publisher_funder_component"
                        ) ? (
                          <div key="11" data-grid={this.state.grid_11}>
                            <DBChart
                              height={220}
                              containerHeight={320}
                              titleIcon="info-circle"
                              title="Funders"
                              chartMarginTopClassName="m-t-40"
                              className="col-md-4"
                              type="pie"
                              labels={this.chartfunderLabels(
                                this.props.publisher_funder_component
                                  ? this.props.publisher_funder_component &&
                                  this.props.publisher_funder_component.data
                                    .items
                                  : null
                              )}
                              data={this.chartfunderType(
                                this.props.publisher_funder_component
                                  ? this.props.publisher_funder_component &&
                                  this.props.publisher_funder_component.data
                                    .items
                                  : null
                              )}
                            />
                          </div>
                        ) : (
                          <div
                            key="11"
                            data-grid={this.state.grid_11}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents.available_offset_component &&
                        this.state.settingcomponents.available_offset_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "available_offset_component"
                        ) ? (
                          <div key="12" data-grid={this.state.grid_12}>
                            <WhitePublisherCard
                              icon="mdi mdi-cube-outline"
                              title="Available offset"
                              className="padding"
                              titleIcon="info-circle"
                              body={
                                this.props.available_offset_data
                                  ? this.currencyCalculation(
                                    this.props.available_offset_data
                                  )
                                  : this.defaultCurrency()
                              }
                            />
                          </div>
                        ) : (
                          <div
                            key="12"
                            data-grid={this.state.grid_12}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents.allocated_offset_component &&
                        this.state.settingcomponents.allocated_offset_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "allocated_offset_component"
                        ) ? (
                          <div key="13" data-grid={this.state.grid_13}>
                            {this.props.allocated_offset_data && (
                              <WhitePublisherCard
                                icon="mdi mdi-briefcase-check"
                                title="Allocated offset"
                                titleIcon="info-circle"
                                className="padding"
                                body={this.currencyCalculation(
                                  this.props.allocated_offset_data
                                )}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            key="13"
                            data-grid={this.state.grid_13}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .publisher_content_type_component &&
                        this.state.settingcomponents
                          .publisher_content_type_component.hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "publisher_content_type_component"
                        ) ? (
                          <div key="14" data-grid={this.state.grid_14}>
                            <DBChart
                              height={220}
                              titleIcon="info-circle"
                              containerHeight={320}
                              title="Content Types"
                              chartMarginTopClassName="m-t-40"
                              className="col-md-4"
                              type="doughnut"
                              labels={this.chartaArrayOfLabel(
                                this.props.publisher_content_type_component
                                  ? this.props.publisher_content_type_component &&
                                  this.props.publisher_content_type_component
                                    .data.items
                                  : null
                              )}
                              data={this.chartArrayofType(
                                this.props.publisher_content_type_component
                                  ? this.props.publisher_content_type_component &&
                                  this.props.publisher_content_type_component
                                    .data.items
                                  : null
                              )}
                            />
                          </div>
                        ) : (
                          <div
                            key="14"
                            data-grid={this.state.grid_14}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .publisher_journal_component &&
                        this.state.settingcomponents.publisher_journal_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "publisher_journal_component"
                        ) ? (
                          <div key="15" data-grid={this.state.grid_15}>
                            <DBChart
                              titleIcon="info-circle"
                              title="Journals"
                              type="bar"
                              chartMarginTopClassName="m-t-40"
                              labels={this.chartaArrayOfLabel(
                                this.props.publisher_journal_component
                                  ? this.props.publisher_journal_component &&
                                  this.props.publisher_journal_component.data
                                    .items
                                  : null
                              )}
                              data={this.chartArrayofType(
                                this.props.publisher_journal_component
                                  ? this.props.publisher_journal_component &&
                                  this.props.publisher_journal_component.data
                                    .items
                                  : null
                              )}
                              height={220}
                              containerHeight={320}
                              className="col-md-4"
                              isShowLabel={false}
                              grid={false} // Should Implement
                              titleClass="mb-4"
                            />
                          </div>
                        ) : (
                          <div
                            key="15"
                            data-grid={this.state.grid_15}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents.spent_offset_component &&
                        this.state.settingcomponents.spent_offset_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "spent_offset_component"
                        ) ? (
                          <div key="16" data-grid={this.state.grid_16}>
                            {this.props.spent_offset_data && (
                              <WhitePublisherCard
                                icon="mdi mdi-briefcase-check"
                                className="col-md-4"
                                titleIcon="info-circle"
                                title="Spent offset"
                                body={this.currencyCalculation(
                                  this.props.spent_offset_data
                                )}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            key="16"
                            data-grid={this.state.grid_16}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .apc_revenue_compare_component &&
                        this.state.settingcomponents.apc_revenue_compare_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "apc_revenue_compare_component"
                        ) ? (
                          <div key="17" data-grid={this.state.grid_17}>
                            <ProgressBar
                              titleIcon="info-circle"
                              title="APC revenue GBP"

                              publication_name={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.gbp
                                    .publisher_name
                                  : ""
                              }
                              publication_percentage={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.gbp
                                    .publisher_percentage
                                  : 0
                              }
                              other_percentage={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.gbp
                                    .other_publishers_percentage
                                  : 0
                              }
                              pub_value={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.gbp
                                    .publisher
                                  : 0
                              }
                              other_value={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.gbp
                                    .other_publishers
                                  : 0
                              }
                              last_part=""
                              className="col-md-4"
                            />
                          </div>
                        ) : (
                          <div
                            key="17"
                            data-grid={this.state.grid_17}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .apc_revenue_compare_component &&
                        this.state.settingcomponents.apc_revenue_compare_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "apc_revenue_compare_component"
                        ) ? (
                          <div key="18" data-grid={this.state.grid_18}>
                            <ProgressBar
                              titleIcon="info-circle"
                              title="APC revenue EUR"
                              publication_name={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.eur
                                    .publisher_name
                                  : ""
                              }
                              publication_percentage={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.eur
                                    .publisher_percentage
                                  : 0
                              }
                              other_percentage={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.eur
                                    .other_publishers_percentage
                                  : 0
                              }
                              pub_value={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.eur
                                    .publisher
                                  : 0
                              }
                              other_value={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.eur
                                    .other_publishers
                                  : 0
                              }
                              last_part=""
                              className="col-md-4"
                            />
                          </div>
                        ) : (
                          <div
                            key="18"
                            data-grid={this.state.grid_18}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .apc_revenue_compare_component &&
                        this.state.settingcomponents.apc_revenue_compare_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "apc_revenue_compare_component"
                        ) ? (
                          <div key="19" data-grid={this.state.grid_19}>
                            <ProgressBar
                              titleIcon="info-circle"
                              title="APC revenue USD"
                              publication_name={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.usd
                                    .publisher_name
                                  : ""
                              }
                              publication_percentage={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.usd
                                    .publisher_percentage
                                  : 0
                              }
                              other_percentage={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.usd
                                    .other_publishers_percentage
                                  : 0
                              }
                              pub_value={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.usd
                                    .publisher
                                  : 0
                              }
                              other_value={
                                this.props.apc_revenue_compare_component
                                  ? this.props.apc_revenue_compare_component.usd
                                    .other_publishers
                                  : 0
                              }
                              last_part=""
                              className="col-md-4"
                            />
                          </div>
                        ) : (
                          <div
                            key="19"
                            data-grid={this.state.grid_19}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .number_of_articles_compare_component &&
                        this.state.settingcomponents
                          .number_of_articles_compare_component.hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "number_of_articles_compare_component"
                        ) ? (
                          <div key="20" data-grid={this.state.grid_20}>
                            <ProgressBar
                              titleIcon="info-circle"
                              title="Number of articles"
                              publication_name={
                                this.props.number_of_articles_compare_component
                                  ? this.props
                                    .number_of_articles_compare_component
                                    .publisher_name
                                  : ""
                              }
                              publication_percentage={
                                this.props.number_of_articles_compare_component
                                  ? this.props
                                    .number_of_articles_compare_component
                                    .my_avg_percentage
                                  : 0
                              }
                              other_percentage={
                                this.props.number_of_articles_compare_component
                                  ? this.props
                                    .number_of_articles_compare_component
                                    .total_avg_percentage
                                  : 0
                              }
                              pub_value={
                                this.props.number_of_articles_compare_component
                                  ? this.props
                                    .number_of_articles_compare_component.my_avg
                                  : 0
                              }
                              other_value={
                                this.props.number_of_articles_compare_component
                                  ? this.props
                                    .number_of_articles_compare_component
                                    .total_avg
                                  : 0
                              }
                              className="col-md-4"
                              isRounded={true}
                            />
                          </div>
                        ) : (
                          <div
                            key="20"
                            data-grid={this.state.grid_20}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents
                          .number_of_days_compare_component &&
                        this.state.settingcomponents
                          .number_of_days_compare_component.hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "number_of_days_compare_component"
                        ) ? (
                          <div key="21" data-grid={this.state.grid_21}>
                            <ProgressBar
                              titleIcon="info-circle"
                              title="Number of days"
                              publication_name={
                                this.props.number_of_days_compare_component
                                  ? this.props.number_of_days_compare_component
                                    .publisher_name
                                  : ""
                              }
                              publication_percentage={
                                this.props.number_of_days_compare_component
                                  ? this.props.number_of_days_compare_component
                                    .publisher_percentage
                                  : 0
                              }
                              other_percentage={
                                this.props.number_of_days_compare_component
                                  ? this.props.number_of_days_compare_component
                                    .other_publishers_percentage
                                  : 0
                              }
                              pub_value={
                                this.props.number_of_days_compare_component
                                  ? this.props.number_of_days_compare_component
                                    .publisher
                                  : 0
                              }
                              other_value={
                                this.props.number_of_days_compare_component
                                  ? this.props.number_of_days_compare_component
                                    .other_publishers
                                  : 0
                              }
                              className="col-md-4"
                              isRounded={true}
                            />
                          </div>
                        ) : (
                          <div
                            key="21"
                            data-grid={this.state.grid_21}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents.token_summary_component &&
                        this.state.settingcomponents.token_summary_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "token_summary_component"
                        ) ? (
                          <div key="22" data-grid={this.state.grid_22}>
                            {this.props.token_summary_data && (
                              <DBChart
                                title="Tokens"
                                className="col-md-4"
                                width={220}
                                height={220}
                                titleIcon="info-circle"
                                containerHeight={320}
                                chartMarginTopClassName="m-t-40"
                                type="pie"
                                labels={["Available", "Allocated", "Spent"]}
                                data={this.pieChartCalculation(
                                  this.props.token_summary_data
                                )}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            key="22"
                            data-grid={this.state.grid_22}
                            style={{ display: "none" }}
                          ></div>
                        )}
                      {this.state.settingcomponents &&
                        this.state.settingcomponents.oa_deal_list_component &&
                        this.state.settingcomponents.oa_deal_list_component
                          .hide !== true &&
                        this.state.components &&
                        this.state.components.includes(
                          "oa_deal_list_component"
                        ) ? (
                          <div key="23" data-grid={this.state.grid_23}>
                            <NewOAdeals
                              titleIcon="info-circle"
                              data={this.props.oa_deal_list_data}
                              title="New OA deals"
                              className="col-md-4 padding_reduce"
                              isStyle={320}
                            />
                          </div>
                        ) : (
                          <div
                            key="23"
                            data-grid={this.state.grid_23}
                            style={{ display: "none" }}
                          ></div>
                        )}
                    </ReactGridLayout>
                  </div>
                </div>

              </div>
            </div>
          </div> :  <div className="card">
              <div className="card-body">
                
            {this.state.executed !== true && <h6>Please slide right to view the dashboard.</h6>}
        </div>
            </div>
        ) : manage_premium_dashboard === "manage_premium_dashboard" && this.state.success && this.state.dashBoardURL ? (
          <Iframe
            url={this.state.dashBoardURL}
            width="100%"
            height="800px"
            id="myId"
            frameBorder={0}
            className="myClassname"
            display="initial"
            position="relative"
            allow="fullscreen"
          />
        ) : null}
           
      </HubContent>
    );
  }
}
const mapStateToProps = (state) => ({
  dashbaordData: state.dashboard || false,
  dashbaordPremiumData: state.dashboard.premium_dashboard || false,
  dashboardControlList: state.DashboardControl.dashboard_drag_drop_list,
  oa_income_data: state.dashboard.oa_income_component || false,
  deposit_data: state.dashboard.pub_deposit_component || false,
  available_fund_data: state.dashboard.pub_available_fund_component || false,
  available_offset_data: state.dashboard.available_offset_component || false,
  article_summary_data: state.dashboard.article_summary_component || false,
  token_summary_data: state.dashboard.token_summary_component || false,
  allocated_offset_data: state.dashboard.allocated_offset_component || false,
  spent_offset_data: state.dashboard.spent_offset_component || false,
  credit_payment_data: state.dashboard.credit_payment_component || false,
  credit_pending_data: state.dashboard.credit_pending_component || false,
  oa_deal_list_data:
    state.dashboard.publisher_new_deals_component &&
      state.dashboard.publisher_new_deals_component.status_code === 200
      ? state.dashboard.publisher_new_deals_component.data
      : false,
  apc_revenue_compare_component: state.dashboard.apc_revenue_compare_component
    ? state.dashboard.apc_revenue_compare_component.data
    : false,
  number_of_days_compare_component: state.dashboard
    .number_of_days_compare_component
    ? state.dashboard.number_of_days_compare_component.data
    : false,
  number_of_articles_compare_component: state.dashboard
    .number_of_articles_compare_component
    ? state.dashboard.number_of_articles_compare_component.data
    : false,
  read_and_publish_deal:
    state.dashboard && state.dashboard.read_and_publish_deal_component,
  oa_inst_membership_component:
    state.dashboard && state.dashboard.oa_inst_membership_component,

  article_summary:
    state.dashboard.institution_article_summary_component || false,
  institute_list: state.institutions.institution_list,
  child_orgs_list: state.app.organizations || false,
  open_access: state.dashboard.oa_journal_component || false,
  publisher_list: state.orgs.publisher_list || false,
  publisher_funder_component:
    state.dashboard.publisher_funder_component || false,
  book_summary_component: state.dashboard.book_summary_component || false,
  publisher_journal_component:
    state.dashboard.publisher_journal_component || false,
  publisher_content_type_component:
    state.dashboard.publisher_content_type_component || false,
  publisher_institution_component:
    state.dashboard.publisher_institution_component || false,
  publisher_payment_invoice_component:
    state.dashboard.publisher_payment_invoice_component || false,

  oadeal_filter: state.institutions.oadeal_filter,
  auth: state.auth.user,
  dashboardcontrol: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  getPremiumDashboardData: (payload) =>
    dispatch(getPremiumDashboardData(payload)),
  getPublisherDefaultDashboardData: (payload) =>
    dispatch(getPublisherDefaultDashboardData(payload)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getoaDealFilterList: () => dispatch(getoaDealFilterList()),
  getChildOrganisationList: (payload) =>
    dispatch(getChildOrganisationList(payload)),
  dashboardDragAndDropControl: (id, payload) =>
    dispatch(dashboardDragAndDropControl(id, payload)),
  dashboardDragAndDropControlList: (id) =>
    dispatch(dashboardDragAndDropControlList(id)),
  getDashboardLevelList: (id) => dispatch(getDashboardLevelList(id)),
});
export const PubDashboard = connect(mapStateToProps, mapDispatchToProps)(DB);