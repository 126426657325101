import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  SelectBox,
  InputBox,
  OaDatePicker,
  PopupBox,
  Button,
  ModalSelectBox,
  Badge,
  RadioButtonGroup,
  ModalAutoComplete,
} from "../../../../../core/components/common";
import moment from "moment";
import {LoadingOutlined } from '@ant-design/icons';

import { connect } from "react-redux";
import { AutoComplete, Popover,Spin } from "antd";


import {
  dealInstituationList,
  getAllDealType,
  getAllCurrencyList,
  createOaDeal,
  getPublicatonTypeList,
  getPublicationList,
  getContentTypeList,
  getOaDisciplineList,
  getOaSubjectList,
  getOaDealSummary,
} from "../../../../actions";
import CreateConstInvidiualMemberOADeal from "./CreateConstInvidiualMemberOADeal";

import { serialize } from "../../../../../core/lib";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const format = "Do MMM YYYY";
const content = (
  <div className="">
    <a href="https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/3+-Overview+of+Different+Types+OA+Deals.pdf" target="_blank" rel="noopener noreferrer">Different types of OA Deals</a>
    <br />
    <a href="https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/6+-+OA-Read+%26+Publish+Deal+setup+for+institution.pdf" target="_blank" rel="noopener noreferrer">Read & Publish Deal Setup</a>
    <br />
    <a href="https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/7+-+OA-Subscribe2Open+Deal+setup+for+institution.pdf" target="_blank" rel="noopener noreferrer">Subscribe-to-Open OA Deal setup</a>
    <br />
    <a href="https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/4+-+OA-Discount.pdf" target="_blank" rel="noopener noreferrer">OA Discount Deal Setup</a>
    <br />
    <a href="https://oametrix-video.s3.eu-west-2.amazonaws.com/publisher-user-guide/5-+OA-Pre-Payment+Deal.pdf" target="_blank" rel="noopener noreferrer">Pre-Payment OA Deal Setup</a>
    <br />
  </div>
);

class DB extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      isDealReadSubscribe: false,
      oaDeal: {},
      member_organisations: null,
      added_individual_member_deal: false,
      isIndividualMember: false,
      individualMembers: [],
      publisher_deposit_payment: {},
      expire_date: false,
      showConstraint: false,
      all_deal_features: [],
      deal_name: "",
      invoice_config_options: {},
      value: "",
      config_options: [
        {
          label:
            "Would you like to allow system generates an invoice for an article when processed automatically under this Read & Publish deal?",
          name: "raise_invoice",
        },
        {
          label: "Would you like to add a custom watermark in the invoice?",
          name: "has_custom_watermark",
          depends_on: "raise_invoice",
        },
        {
          label: "Would you like to show net payable Zero in the invoice?",
          name: "is_void_invoice",
          depends_on: "raise_invoice",
        },
      ],
      constrainData: [
        { id: "no_constraint", name: "No cap" },
        { id: "by_total_read", name: "By total article" },
        { id: "by_total_price", name: "By total price" },
      ],
    };
  }

  getPublicationListOnParameterChange = async () => {
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.oaDeal.publication_types &&
      this.state.oaDeal.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.oaDeal.publication_types[0] !== "all"
          ? this.state.oaDeal.publication_types.join(",")
          : "";
    }

    if (this.state.disciplines && this.state.disciplines.length > 0) {
      filterParams.discipline_ids =
        this.state.disciplines[0] !== "all"
          ? this.state.disciplines.join(",")
          : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }
  };
  onChangeHandlerDealType = (value) => {
    if (value.deal_type) {
      this.setState({
        deposit_publish_fee: 0,
        deposit_read_fee: 0,
        oaDeal: {
          deposit_amount: 0,
          constraint: {
            constraint_by: "no_constraint"
          },

          content_types: [],
          disciplines: [],
          publication_types: [],
          publications: [],
          subjects: [],
          added_individual_member_deal: null,
          member_deals: [],
          member_organisations: [],

        }
      })


    }
    this.getDealSummaryData({ deal_type: value.deal_type })
    let select_deal_type = this.props.deal_type && this.props.deal_type.find(
      (item) => item.id === value.deal_type
    );
    if (select_deal_type.name === "read & publish") {
      value.invoice_config_options = null;
    }
    this.dealTypeCheckHandler(select_deal_type.name)

    this.setState({
      deal_type: value.deal_type,
      deal_name: select_deal_type.name,
    });
  };


  handePublishersBasedonParent = async (fieldName, data) => {
    this.setState({
      [fieldName]: data,
    });
    this.setState((prevState) => {
      Object.assign(prevState.oaDeal, { [fieldName]: data });
    });

    let payload = {};
    let filterParams = {
      page_size: 999999,
    };

    if (data && data.length > 0) {
      filterParams.publication_type_ids = data !== "all" ? data.join(",") : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }
    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publicationList: [
          { id: "all", name: "All publications" },
          ...this.props.publication_list.results,
        ],
      });
  };
  onChangeHandler = async (value) => {

    if (
      value.deposit_publish_fee !== undefined ||
      value.deposit_read_fee !== undefined
    ) {
      this.setState(
        {
          ...value,
        },
        () => {
          this.setState((prevState) => {
            Object.assign(prevState.oaDeal, {
              amount:
                Number(prevState.deposit_publish_fee || 0) +
                Number(prevState.deposit_read_fee || 0),
            });
          });
        }
      );
    } else {
      this.setState((prevState) => {
        Object.assign(prevState.oaDeal, value);
      });
    }

    if (value.hasOwnProperty("publication_types")) {
      this.getPublicationListOnParameterChange();
    }

    if (value.hasOwnProperty("constraint_by")) {
      this.setState({
        constraint_by: value.constraint_by,
      });
    }
  };
  onSaveInvidualMemberHander = (member_info) => {

    let constraint;
    if (member_info.constraint_by === "by_total_read") {
      constraint = {
        constraint_by: member_info.constraint_by,
        max_total_read: Number(member_info.max_total_read) || 0,
      };
    } else if (member_info.constraint_by === "no_constraint") {
      constraint = {
        constraint_by: member_info.constraint_by,
      };
    } else if (member_info.constraint_by === "by_total_price") {
      constraint = {
        constraint_by: member_info.constraint_by,
        max_total_price: Number(member_info.max_total_price) || 0,
      };
    }
    let commonObject = {
      counter_organisation_name: member_info.counter_organisation_name,
      all_deal_features: member_info.all_deal_features,
      counter_organisation: member_info.counter_organisation,
      deal_type: member_info.deal_type,
      currency: member_info.currency,
      discount: member_info.discount || 0,
      expire_date: moment(member_info.expire_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(member_info.expire_date).format("YYYY-MM-DD") : null,
      submission_date: moment(member_info.submission_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(member_info.submission_date).format("YYYY-MM-DD") : null,
      acceptance_date: moment(member_info.acceptance_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(member_info.acceptance_date).format("YYYY-MM-DD") : null,
      start_date: moment(member_info.start_date).format("YYYY-MM-DD") !== "Invalid date" ? moment(member_info.start_date).format("YYYY-MM-DD") : null,

      deal_features:
        member_info &&
        member_info.all_deal_features &&
        member_info.all_deal_features.map((feature) => ({
          id: feature.id,
          selected: feature.selected,
        })),
      content_types:
        member_info.content_types && member_info.content_types.length > 0
          ? member_info.content_types
          : [],
      disciplines:
        member_info.disciplines && member_info.disciplines.length > 0
          ? member_info.disciplines
          : [],

      publication_types:
        member_info.publication_types &&
          member_info.publication_types.length > 0
          ? member_info.publication_types && member_info.publication_types
          : [],

      publications:
        member_info.publications && member_info.publications.length > 0
          ? member_info.publications && member_info.publications
          : [],

      subjects:
        member_info.subjects && member_info.subjects.length > 0
          ? member_info.subjects && member_info.subjects
          : [],
    };
    if (this.state.deal_name === "oa-discount") {
      delete commonObject.currency;
    }
    let memberInfoData;
    let memberInfoReadAndPublish = {
      ...commonObject,
      invoice_config_options: member_info.invoice_config_options,
      constraint: constraint,
      publisher_deposit_payment: {
        publish_fee: member_info.publish_fee || 0,
        read_fee: member_info.read_fee || 0,
        amount: Number(member_info.deposit_publish_fee || 0) + Number(member_info.deposit_read_fee || 0) || member_info.totals

      },
    };
    let memberInfoCreditDeal = {
      ...commonObject,
    };
    this.state.deal_name !== "oa-discount" && delete memberInfoCreditDeal.start_date;
    let memberInfoPrePayment = {
      ...commonObject,
      publisher_deposit_payment: {
        amount: member_info.amount || 0,
      },
    };
    this.state.deal_name !== "oa-discount" &&  delete memberInfoPrePayment.start_date;
    memberInfoData =
      this.state.deal_name === "pre-payment"
        ? memberInfoPrePayment
        : this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
          ? memberInfoReadAndPublish
          : memberInfoCreditDeal;
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.individualMembers[prevState.editing] = memberInfoData;
        prevState.editing = false;
      } else {
        prevState.individualMembers.push(memberInfoData);
      }
      prevState.isIndividualMember = false;
      return prevState;
    });
  };



  onSubmitHandler = async (e) => {
    e.preventDefault();

    let constraint;
    let modelData = {
      content_types:
        this.state.content_types && this.state.content_types.length > 0
          ? this.state.content_types
          : [],
      disciplines:
        this.state.disciplines && this.state.disciplines.length > 0
          ? this.state.disciplines
          : [],

      publication_types:
        this.state.publication_types && this.state.publication_types.length > 0
          ? this.state.publication_types
          : [],

      publications:
        this.state.publications && this.state.publications.length > 0
          ? this.state.publications
          : [],

      subjects:
        this.state.subjects && this.state.subjects.length > 0
          ? this.state.subjects
          : [],
      added_individual_member_deal: null,
      member_deals: [],
      member_organisations: [],
    }

    if (this.state.constraint_by === "by_total_read") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
        max_total_read: this.state.oaDeal.max_total_read || 0,
      };
    } else if (this.state.constraint_by === "no_constraint") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
      };
    } else if (this.state.constraint_by === "by_total_price") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
        max_total_price: this.state.oaDeal.max_total_price || 0,
      };
    }

    let commonObject = {
      ...this.state.oaDeal,
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.deal_type,
      deal_features:
        this.state.all_deal_features &&
        this.state.all_deal_features.map((feature) => ({
          id: feature.id,
          selected: feature.selected,
        })),
      ...modelData,
    };

    let readAndPublishOaDeal = {
      ...commonObject,
      invoice_config_options: this.state.invoice_config_options,
      constraint: constraint,
      expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
      acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
      start_date: (this.state.oaDeal.start_date !== null && this.state.oaDeal.start_date !== undefined) !== false ? moment(this.state.oaDeal.start_date).format("YYYY-MM-DD") : null,
      submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
      publisher_deposit_payment: {
        publish_fee: this.state.deposit_publish_fee,
        read_fee: this.state.deposit_read_fee,
        amount: this.getPubReadFeeTotal() || 0,
      },
    };

    let subcribeandReadPublish = {
      expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.deal_type,
    };

    let readPublish = this.state.isDealReadSubscribe !== true ? subcribeandReadPublish : readAndPublishOaDeal
    let oaDiscount = {
      ...modelData,
      discount: this.state.oaDeal.discount,
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.deal_type,
      expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
      start_date: (this.state.oaDeal.start_date !== null && this.state.oaDeal.start_date !== undefined) !== false ? moment(this.state.oaDeal.start_date).format("YYYY-MM-DD") : null,

      acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
      submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
    }
    let creditDeal = {
      ...oaDiscount,
      currency: this.state.oaDeal.currency,
      discount: this.state.oaDeal.discount,
      acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
      submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
      expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
    }
    let prePayment = {
      ...creditDeal,
      publisher_deposit_payment: {
        amount: this.state.oaDeal.deposit_amount || 0,
      },
      acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
      submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
    }
    let oaDealDataFormat =
      this.state.deal_name === "pre-payment"
        ? prePayment
        : this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
          ? readPublish
          : this.state.deal_name === "oa-discount" ? oaDiscount
            : creditDeal;


    if (!commonObject.counter_organisation && !commonObject.deal_type) {
      this.setState({
        errMsg: (
          <div>
            {!commonObject.counter_organisation && (
              <p>Counter organisation cannot be empty!</p>
            )}

            {!commonObject.deal_type && <p>Deal Type name cannot be empty</p>}
          </div>
        ),
      });
    } else {
      this.setState({
        errMsg: false,
      });
      let result = await this.props.createOaDeal(oaDealDataFormat);
      if (result.status === true) {
        this.setState({
          success: true,
        });
      }
    }
  };

  //DataList For OaDeal
  commonOaDealData = () => ({
    counter_organisation: this.state.counter_organisation,
    deal_type: this.state.deal_type,
    member_organisations:
      this.state.member_organisations &&
        this.state.member_organisations.length > 0
        ? this.state.member_organisations
        : [],
    content_types:
      this.state.content_types && this.state.content_types.length > 0
        ? this.state.content_types
        : [],
    disciplines:
      this.state.disciplines && this.state.disciplines.length > 0
        ? this.state.disciplines
        : [],

    publication_types:
      this.state.publication_types && this.state.publication_types.length > 0
        ? this.state.publication_types
        : [],
    publications:
      this.state.publications && this.state.publications.length > 0
        ? this.state.publications
        : [],
    subjects:
      this.state.subjects && this.state.subjects.length > 0
        ? this.state.subjects
        : [],
    added_individual_member_deal: this.state.added_individual_member_deal || false,
    member_deals: []
  })

  oaDiscountOaDeal = () => ({
    ...this.commonOaDealData(),
    discount: this.state.oaDeal.discount || 0,
    expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
    start_date: (this.state.oaDeal.start_date !== null && this.state.oaDeal.start_date !== undefined) !== false ? moment(this.state.oaDeal.start_date).format("YYYY-MM-DD") : null,

    acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
    submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
  })
  oaPrePaymentOaDeal = () => ({
    ...this.commonOaDealData(),
    discount: this.state.oaDeal.discount || 0,
    expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
    publisher_deposit_payment: { amount: this.state.oaDeal.deposit_amount || 0 },
    currency: this.state.oaDeal.currency,
    acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
    submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
  })
  oaCreditOaDeal = () => ({
    ...this.commonOaDealData(),
    discount: this.state.oaDeal.discount || 0,
    currency: this.state.oaDeal.currency,
    acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
    submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
    expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
  })
  oaReadPublishSubScribeOpenOaDeal = () => {
    let constraint;
    if (this.state.constraint_by === "by_total_read") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
        max_total_read: this.state.oaDeal.max_total_read || 0,
      };
    } else if (this.state.constraint_by === "no_constraint") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
      };
    } else if (this.state.constraint_by === "by_total_price") {
      constraint = {
        constraint_by: this.state.oaDeal.constraint_by,
        max_total_price: this.state.oaDeal.max_total_price || 0,
      };
    }

    let readAndPublishOaDeal = {
      ...this.commonOaDealData(),
      deal_features:
        this.state.all_deal_features &&
        this.state.all_deal_features.map((feature) => ({
          id: feature.id,
          selected: feature.selected,
        })),
      currency: this.state.oaDeal.currency,

      discount: this.state.oaDeal.discount || 0,
      invoice_config_options: this.state.invoice_config_options,
      constraint: constraint,
      expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
      acceptance_date: (this.state.oaDeal.acceptance_date !== null && this.state.oaDeal.acceptance_date !== undefined) !== false ? moment(this.state.oaDeal.acceptance_date).format("YYYY-MM-DD") : null,
      start_date: (this.state.oaDeal.start_date !== null && this.state.oaDeal.start_date !== undefined) !== false ? moment(this.state.oaDeal.start_date).format("YYYY-MM-DD") : null,
      submission_date: (this.state.oaDeal.submission_date !== null && this.state.oaDeal.submission_date !== undefined) !== false ? moment(this.state.oaDeal.submission_date).format("YYYY-MM-DD") : null,
      publisher_deposit_payment: {
        publish_fee: this.state.deposit_publish_fee,
        read_fee: this.state.deposit_read_fee,
        amount: this.getPubReadFeeTotal() || 0,
      },
    };
    let dealData = {
      expire_date: (this.state.oaDeal.expire_date !== null && this.state.oaDeal.expire_date !== undefined) !== false ? moment(this.state.oaDeal.expire_date).format("YYYY-MM-DD") : null,
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.deal_type,
      member_organisations:
        this.state.member_organisations &&
          this.state.member_organisations.length > 0
          ? this.state.member_organisations
          : [],
      added_individual_member_deal: false,

    }
    let readPublish = this.state.isDealReadSubscribe !== true ? dealData : readAndPublishOaDeal

    return readPublish

  }
  onIndiviualSubmit = async () => {
    let oaDealAllMembersdataFormat =
      this.state.deal_name === "pre-payment"
        ? this.oaPrePaymentOaDeal()
        : this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
          ? this.oaReadPublishSubScribeOpenOaDeal()
          : this.state.deal_name === "oa-discount" ? this.oaDiscountOaDeal() : this.oaCreditOaDeal();

    let individualOaDealData = {
      member_deals: this.state.individualMembers,
      counter_organisation: this.state.counter_organisation,
      deal_type: this.state.deal_type,
      added_individual_member_deal: this.state.added_individual_member_deal,
    };

    let dataFormat = this.state.added_individual_member_deal === true
      ? individualOaDealData
      : oaDealAllMembersdataFormat;


    let result = await this.props.createOaDeal(dataFormat);
    if (result.status === true) {
      this.setState({
        success: true,
      });
    }

  }

  onShowConstraint = () => {
    this.setState({
      showConstraint: true,
    });
  };

  componentDidMount() {
    this.apiDataList()
  }
  apiDataList = async () => {
    await this.props.getAllDealType();

    this.props.getOaDisciplineList();
    this.props.getAllCurrencyList();
    this.props.getPublicatonTypeList();


    let DealTypeProps = this.props.deal_type;
    let DealType = [];
    DealTypeProps &&
      DealTypeProps.map((item) => {
        let name = item.name;
        switch (item.name) {
          case "read & publish":
            name = "Read & Publish";
            break;
          case "pre-payment":
            name = "Pre-payment";
            break;
          case "credit":
            name = "Credit";
            break;
          case "oa-discount":
            name = "OA-Discount";
            break;
          case "subscribe-to-open":
            name = "Subscribe-to-Open";
            break;
          default:
            name = item.name;
        }
        return DealType.push({ id: item.id, name: name });
      });

      this.setState({
        DealType,
        oa_subject_list: this.props.oa_subjects && this.props.oa_subjects.results,
        deal_type_list: this.props.deal_type,
        publication_type:
          (this.props.publication_type && [
            { id: "all", name: "All publication types" },
            ...this.props.publication_type,
          ]) ||
          [],
      });
      this.getPublicationListOnParameterChange();
    
  }
 
  getDealSummaryData = async (payload = {}) => {
    await this.props.getOaDealSummary(this.props.organisation_id, payload);

    if (this.props.oadeal_summary && this.props.oadeal_summary.deal_features) {
      this.setState({
        all_deal_features: this.props.oadeal_summary.deal_features,
      });
    }
  };

  onChangeRadioButtonHandler = (value) => {
    let { all_deal_features } = this.state;
    let featureCodes = {};

    let itemKey = Object.keys(value).length && Object.keys(value)[0];
    all_deal_features = all_deal_features.map((item) => {
      if (itemKey === `feature_${item.id}`) {
        if (item.deal_feature) {
          featureCodes[item.deal_feature.feature_code] = value[itemKey];
        }

        Object.assign(item, { selected: value[itemKey] });
      }

      return item;
    });

    if (!this.state["OADF-00001"] || !featureCodes["OADF-00001"]) {
      this.setState({
        invoice_config_options: null,
      });
    }

    this.setState({
      all_deal_features,
      ...featureCodes,
    });
  };

  onChangeInvoiceConfigOptionHandler = (value) => {
    let { invoice_config_options } = this.state;

    if (!invoice_config_options) {
      invoice_config_options = {};
    }

    if (
      value.hasOwnProperty("has_custom_watermark") &&
      !value.has_custom_watermark
    ) {
      value.custom_watermark = null;
    }

    if (value.hasOwnProperty("raise_invoice") && !value.raise_invoice) {
      value.has_custom_watermark = false;
      value.is_void_invoice = false;
      value.custom_watermark = null;
    }

    Object.assign(invoice_config_options, value);

    this.setState({
      invoice_config_options,
    });
  };

  /*1. Read&publish and subscribe components*/
  dealCompontent = () => (
    <div>
      <div className="row">

        {
          this.state.isDealReadSubscribe !== true ?

            this.state.deal_name !== this.dealTyeNameCheckerHandler().checkExpireDate[this.state.deal_name] && (
              <div className="col-md-3">
                <label className="deal-label-name">Select Deal Expiry Date</label>
                <OaDatePicker
                  className="deal-expire-date"
                  field="expire_date"
                  onChange={this.onChangeHandler}
                  showTime={false}
                  placeholder="Select Expiry Date"
                  format={format}
                />
              </div>
            )
            : (this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div className="col-md-6" style={{ marginTop: 20 }}>
              <Button type="success" text="Customise OA Deal Setup" name="success"
                icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
            </div>
        }

        {
          this.state.isDealReadSubscribe !== true &&
          (this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div className="col-md-6" style={{ marginTop: 35, marginBottom: 50 }}>
            <Button type="success" text="Customise OA Deal Setup" name="success"
              icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
          </div>
        }
      </div>

      {this.state.isDealCredit && this.creditDeal()}
      {this.state.isDealPrePayment && this.prePaymentDeal()}
      {this.state.isDealOaDiscount && this.oaDiscountDeal()}
      { this.state.isDealReadSubscribe && this.readAndPublisherDeal()}
    </div>
  )
  readAndPublisherDeal = () => (
    <div>
      <div className="row">
        <div className="col-md-3">
          <label className="deal-label-name">Select Currency</label>

          <SelectBox
            className="deal-currency"
            onChange={this.onChangeHandler}
            field="currency"
            data={this.props.oadeal_currency}
            isRequired={false}
            defaultValue={this.state.oadeal_currency}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Enter Discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"

          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Deal Start Date</label>
          <OaDatePicker
            className="deal-start-date"
            field="start_date"
            placeholder="Select Start Date"

            onChange={this.onChangeHandler}
            showTime={false}
            format={format}

          />
        </div>
        {this.state.isDealReadSubscribe === true && this.state.deal_name !== this.dealTyeNameCheckerHandler().checkExpireDate[this.state.deal_name] && (
          <div className="col-md-3">
            <label className="deal-label-name">Select Deal Expiry Date</label>

            <OaDatePicker
              className="deal-expire-date"
              field="expire_date"
              placeholder="Select Expiry Date"

              onChange={this.onChangeHandler}
              showTime={false}
              format={format}
            />
          </div>

        )}

      </div>
      <div className="row">

        <div className="col-md-3">
          <label className="deal-label-name">Select Submission Date</label>
          <OaDatePicker
            className="deal-submission-date"
            field="submission_date"
            onChange={this.onChangeHandler}
            showTime={false}
            placeholder="Select Submission Date"

            format={format}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Acceptance Date</label>
          <OaDatePicker
            className="deal-acceptance-date"
            field="acceptance_date"
            placeholder="Select Acceptance Date"

            onChange={this.onChangeHandler}
            showTime={false}
            format={format}

          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">{this.state.deal_name === "read & publish"
            ? `Enter Publish Fee`
            : `Enter Subscription Fee`}</label>
          <InputBox

            onChange={this.onChangeHandler}
            field="deposit_publish_fee"
            defaultValue={this.state.deposit_publish_fee}

            className="deal-publish-fee"
            InputType="number"
          />
        </div>

        <div className="col-md-3">
          <label className="deal-label-name">{this.state.deal_name === "read & publish"
            ? `Enter Read Fee`
            : `Enter Other Fee`}</label>
          <InputBox

            onChange={this.onChangeHandler}
            field="deposit_read_fee"
            defaultValue={this.state.deposit_read_fee}

            className="deal-read-fee"
            InputType="number"
          />
        </div>


        <div className="col-md-3">
          <label className="deal-label-name">{this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
            ? ` Total Amount`
            : `Enter deposit amount`}</label>

          {this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open"
            ? <p className="total-amount">{this.getPubReadFeeTotal()}</p> : <InputBox
              onChange={this.onChangeHandler}
              field="deposit_amount"
              className="deal-deposit-amount"
              InputType="number"

            />}
        </div>

        <div className="col-md-3">
          <label className="deal-label-name">Select Cap or No Cap</label>

          <SelectBox
            className="deal-set-cap"
            onChange={this.onChangeHandler}
            field="constraint_by"
            data={this.state.constrainData}
            isRequired={false}
            defaultValue={this.state.constraint_by}
          />
        </div>
        {this.state.constraint_by ===
          "by_total_read" && (
            <div className="col-md-3">
              <label className="deal-label-name">Enter No. of Articles</label>

              <InputBox
                className="deal-articles"
                onChange={this.onChangeHandler}
                field="max_total_read"
                defaultValue={this.state.oaDeal.max_total_read}
                InputType="number"
              />
            </div>


          )}
        {this.state.constraint_by ===
          "by_total_price" && (
            <div className="col-md-3">
              <label className="deal-label-name">Enter Cap Amount</label>

              <InputBox
                className="deal-price"
                onChange={this.onChangeHandler}
                field="max_total_price"
                defaultValue={this.state.oaDeal.max_total_price}
              />
            </div>
          )}
      </div>

      {  this.dealModelSetupHandler()}
      <div className="all-deal-feature" style={{ marginTop: 15 }}>
        {this.state.all_deal_features &&
          this.state.all_deal_features.length > 0 &&
          this.renderDealFeatues(
            this.state.all_deal_features
          )}
        {this.state.config_options &&
          this.state.config_options.length > 0 &&
          this.renderInvoiceConfigOptions(
            this.state.config_options
          )}
      </div>

    </div>
  )
  currencyAndDiscount = () => (
    <div>
      <div className="row">
        <div className="col-md-3">
          <label className="deal-label-name">Select Currency</label>
          <SelectBox
            onChange={this.onChangeHandler}
            field="currency"
            className="deal-currency"
            data={this.props.oadeal_currency}
            isRequired={false}
            defaultValue={this.state.oadeal_currency}
          />
        </div>
        {this.state.deal_name === "pre-payment" && <div className="col-md-3">
          <label className="deal-label-name">Enter Deposit Amount</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="deposit_amount"
            className="deal-desposit-amount"
            InputType="number"
            defaultValue={this.state.deposit_amount}

          />


        </div>}

        <div className="col-md-3">
          <label className="deal-label-name">Enter Discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"

          />
        </div>

        <div className="col-md-3">
          <label className="deal-label-name">Select Submission Date</label>
          <OaDatePicker
            className="deal-submission-date"
            field="submission_date"
            onChange={this.onChangeHandler}
            showTime={false}
            placeholder="Select Submission Date"

            format={format}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Acceptance Date</label>
          <OaDatePicker
            className="deal-acceptance-date"
            field="acceptance_date"
            placeholder="Select Acceptance Date"

            onChange={this.onChangeHandler}
            showTime={false}
            format={format}

          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Deal Expiry Date</label>
          <OaDatePicker
            className="deal-expire-date"
            placeholder="Select Expiry Date"

            field="expire_date"
            onChange={this.onChangeHandler}
            showTime={false}
            format={format}
          />
        </div>


      </div>

    </div>
  )
  prePaymentDeal = () => (
    <div>
      {this.currencyAndDiscount()}

      <div className="row">
        <div className="col-md-12" style={{ marginTop: 30 }}>
          <Button type="success" text="Customise OA Deal Setup" name="success"
            icon={true} iconName={this.state.isdealModelShowHandler} onClick={this.dealModelShowHandler} />
        </div>
      </div>

      {this.state.isdealModelShowHandler && this.dealModelSetupHandler()}

    </div>
  )
  oaDiscountDeal = () => (
    <div>

      <div className="form-group row">

        <div className="col-md-3">
          <label className="deal-label-name">Enter Discount%</label>
          <InputBox
            onChange={this.onChangeHandler}
            field="discount"
            className="deal-discount"
          />
        </div>
        <div className="col-md-3">
        <label className="deal-label-name">Select Deal Start Date</label>
        <OaDatePicker
          className="deal-start-date"
          field="start_date"
          placeholder="Select Start Date"

          onChange={this.onChangeHandler}
          showTime={false}
          format={format}

        />
      </div>




        <div className="col-md-3">
          <label className="deal-label-name">Select Submission Date</label>
          <OaDatePicker
            className="deal-submission-date"
            field="submission_date"
            onChange={this.onChangeHandler}
            showTime={false}
            placeholder="Select Submission Date"

            format={format}
          />
        </div>
        <div className="col-md-3">
          <label className="deal-label-name">Select Acceptance Date</label>
          <OaDatePicker
            className="deal-acceptance-date"
            field="acceptance_date"
            placeholder="Select Acceptance Date"

            onChange={this.onChangeHandler}
            showTime={false}
            format={format}

          />
        </div>

        <div className="col-md-3">
        <label className="deal-label-name">Select Deal Expiry Date</label>
        <OaDatePicker
          className="deal-expire-date"
          field="expire_date"
          onChange={this.onChangeHandler}
          showTime={false}
          placeholder="Select Expiry Date"
          format={format}
        />
      </div>
      
      </div>
      {this.dealModelSetupHandler()}
    </div>
  )

  creditDeal = () => (
    <div>
      {this.currencyAndDiscount()}
      {this.dealModelSetupHandler()}
    </div>
  )
  dealModelSetupHandler = () => (
    <div className="row" style={{ marginTop: 30 }}>
      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("disciplines")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("disciplines")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowDisciplineModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Disciplines"
          field="disciplines"
          className="col-sm-9"
          data={
            this.props.oa_disciplines && [
              { id: "all", name: "All disciplines" },
              ...this.props.oa_disciplines.results,
            ]
          }
          defaultValue={this.state.disciplines}
          isRequired={false}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("subjects")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("subjects")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowSubjectModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Subjects"
          field="subjects"
          className="col-sm-9"
          data={
            (this.state.oa_subject_list && [
              { id: "all", name: "All subjects" },
              ...this.state.oa_subject_list,
            ]) ||
            []
          }
          defaultValue={this.state.subjects}
          isRequired={true}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("publication_types")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("publication_types")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowPublicationTypeModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Publications types"
          labelClass="col-md-3"
          className="col-sm-8"
          field="publication_types"
          data={
            (this.props.publication_type && [
              { id: "all", name: "All publication types" },
              ...this.props.publication_type,
            ]) ||
            []
          }
          defaultValue={this.state.publication_types}
          isRequired={false}
          submitBtnTxt="Save"
          onSubmit={this.handePublishersBasedonParent}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("publications")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("publications")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowPublicationModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Publications"
          field="publications"
          className="col-sm-9"
          data={this.state.publicationList || []}
          defaultValue={this.state.publications}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}

      <div className="col-sm-4 deal-model">
        {this.showSelectItemsLabel("content_types")}
        <button
          onClick={() =>
            this.onModalSelectBoxShow("content_types")
          }
          className="btn btn-success waves-effect waves-light float-right"
          type="button"
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>

      {this.state.isShowContentTypeModal && (
        <ModalSelectBox
          modalClass="col-md-6"
          label="Content types"
          field="content_types"
          className="col-sm-9"
          data={
            (this.props.content_type && [
              { id: "all", name: "All content types" },
              ...this.props.content_type,
            ]) ||
            []
          }
          defaultValue={this.state.content_types}
          submitBtnTxt="Save"
          onSubmit={this.onSaveSelectedItems}
          onClose={() => this.onModalSelectBoxClose()}
        />
      )}
    </div>
  )
  renderDealFeatues = (items) => {
    return items.map((item) => {
      return (
        <div key={item.id} className="row">
          <div className="col-md-8">
            <p>{item.deal_feature && item.deal_feature.name}</p>
          </div>
          <div className="col-md-2">

            <RadioButtonGroup
              onChange={this.onChangeRadioButtonHandler}
              field={`feature_${item.id}`}
              options={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
              value={item.selected}
            />
          </div>

        </div>
      );
    });
  };

  renderInvoiceConfigOptions = (items) => {
    let { invoice_config_options } = this.state;

    return items.map((item, index) => {
      if (
        item.hasOwnProperty("depends_on") &&
        (!invoice_config_options || !invoice_config_options[item.depends_on])
      ) {
        return null;
      } else if (!this.state["OADF-00001"]) {
        return null;
      }

      return (
        <div key={`${item.name}-${index}`} className="row">
          <div className="col-md-8">

            <p>{item.label}</p>
          </div>
          <div className="col-md-2">
            <RadioButtonGroup
              onChange={this.onChangeInvoiceConfigOptionHandler}
              field={item.name}
              options={[
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
              value={
                !!invoice_config_options && !!invoice_config_options[item.name]
              }
            />
          </div>
          {item.name === "has_custom_watermark" &&
            !!invoice_config_options &&
            !!invoice_config_options[item.name] && (
              <InputBox
                className="col-md-2"
                label={false}
                onChange={this.onChangeInvoiceConfigOptionHandler}
                field="custom_watermark"
                defaultValue={
                  !!invoice_config_options &&
                  invoice_config_options.custom_watermark
                }
              />
            )}
        </div>
      );
    });
  };

  /*Constrioa Compontents */

  individualMembersRadioBox = () => (
    <div className="row" style={{ marginTop: 30 }}>
      <div className="col-md-6">
        <label className="mr-3" style={{color:"#2e80b9",fontSize:17}}>
          Do you want to create OA deal separately for each
          member?
          </label>
      </div>

      <div className="col-md-3">
        <RadioButtonGroup
          onChange={this.onChangeMemberRadioHandler}
          field="added_individual_member_deal"
          options={[
            { id: true, name: "Yes" },
            { id: false, name: "No" },

          ]}
          value={this.state.added_individual_member_deal}
        />
      </div>
    </div>
  )

  selectDealForAllMembersComponent = () => (
    <div>

      <div className="row">
        {
          this.state.isDealReadSubscribe !== true ?

            this.state.deal_name !== this.dealTyeNameCheckerHandler().checkExpireDate[this.state.deal_name] && (
              <div className="col-md-3">
                <label className="deal-label-name">Select Deal Expiry Date</label>
                <OaDatePicker
                  className="deal-expire-date"
                  field="expire_date"
                  onChange={this.onChangeHandler}
                  showTime={false}
                  placeholder="Select Expiry Date"

                  format={format}

                />

              </div>
            ) : (this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div className="col-md-3" style={{
              marginTop: 30,

            }}>
              <Button type="success" text="Customise OA Deal Setup" name="success"
                icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
            </div>
        }

        <div
          className="col-md-3"
          style={{marginTop:30}}
        >
          <label className="member-orgs">
            {this.showSelectItemsLabel("member_organisations")}
          </label>
          <div className="add-member-btn">
          <button
            onClick={() =>
              this.onModalSelectBoxShow("member_organisations")
            }
            className="btn btn-success waves-effect waves-light float-right"
            type="button"
          >
            <i className="fa fa-plus"></i>
          </button>

          {this.state.isShowMemberTypeModal && (
            <ModalAutoComplete
              field="member_organisations"
              modalClass="col-md-6"
              className="col-sm-9"

              placeholder="Please select your full Member name"
              defaultValue={this.state.member_organisations}
              submitBtnTxt="Save"
              members={this.state.members}
              onSubmit={this.onSaveSelectedMembersItems}
              onClose={() => this.onModalSelectBoxClose()}
            />
          )}
        </div>
        </div>

        
        {
          this.state.deal_name === "oa-discount" && <div className="col-md-3">
            <label className="deal-label-name">Enter Discount%</label>
            <InputBox
              onChange={this.onChangeHandler}
              field="discount"
              className="col-md-12"
              InputType="number"
            />
          </div>
        }

        {  this.state.deal_name === "oa-discount" &&
          <div className="col-md-3">
          <label className="deal-label-name">Select Deal Start Date</label>
          <OaDatePicker
            className="deal-start-date"
            field="start_date"
            placeholder="Select Start Date"

            onChange={this.onChangeHandler}
            showTime={false}
            format={format}

          />
        </div>
    }
{
          this.state.deal_name === "oa-discount" && <div className="col-md-3">
          <label className="deal-label-name">Select Submission Date</label>
          <OaDatePicker
            className="deal-submission-date"
            field="submission_date"
            onChange={this.onChangeHandler}
            showTime={false}
            placeholder="Select Submission Date"

            format={format}
          />
        </div>
        }
          {
          this.state.deal_name === "oa-discount" && <div className="col-md-3">
          <label className="deal-label-name">Select Acceptance Date</label>
          <OaDatePicker
            className="deal-acceptance-date"
            field="acceptance_date"
            placeholder="Select Acceptance Date"

            onChange={this.onChangeHandler}
            showTime={false}
            format={format}

            />
          </div>

        }

        { this.state.deal_name === "oa-discount" &&
          <div className="col-md-3">
          <label className="deal-label-name">Select Deal Expiry Date</label>
          <OaDatePicker
            className="deal-expire-date"
            field="expire_date"
            onChange={this.onChangeHandler}
            showTime={false}
            placeholder="Select Expiry Date"
            format={format}
          />
        </div>
        }



        {this.state.isDealReadSubscribe !== true &&

          <div className="col-md-12">
            {(this.state.deal_name === "read & publish" || this.state.deal_name === "subscribe-to-open") && <div style={{
              marginTop: 30,

            }}>
              <Button type="success" text="Customise OA Deal Setup" name="success"
                icon={true} iconName={this.state.isDealReadSubscribe} onClick={this.dealOtherHandler} />
            </div>}
          </div>
        }
      </div>
      {this.state.isDealCredit && this.individualMembersRadioBox()}
      {this.state.isDealCredit && this.creditDeal()}

      {this.state.isDealPrePayment && this.individualMembersRadioBox()}

      {this.state.isDealPrePayment && this.prePaymentDeal()}
      {this.state.isDealOaDiscount && this.individualMembersRadioBox()}

      {this.state.isDealOaDiscount && this.dealModelSetupHandler()}
      {this.state.isDealReadSubscribe && this.individualMembersRadioBox()}

      { this.state.isDealReadSubscribe && this.readAndPublisherDeal()}
    </div>

  )

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        if (fieldName === "publications") {
          this.props.getContentType(data.join());
        }
        this.setState((prevState) => {
          Object.assign(prevState.oaDeal, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });

        if (fieldName === "disciplines") {
          if (data.length > 0) {
            let payload = {
              discipline: data[0] !== "all" ? data.join(",") : "",
            };

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }

          this.getPublicationListOnParameterChange();
        } else if (fieldName === "subjects") {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };
  onSaveSelectedMembersItems = (members) => {
    this.setState({
      member_organisations: members && members.map((item) => item.id),
      members: members,
    });
  };
  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "disciplines":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "subjects":
        this.setState({
          isShowSubjectModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      case "publication_types":
        this.setState({
          isShowPublicationTypeModal: true,
        });
        break;
      case "member_organisations":
        this.setState({
          isShowMemberTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
      isShowPublicationTypeModal: false,
      isShowMemberTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];

    let labelText = "";
    switch (fieldName) {
      case "publication_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Publication type selected`
              : `${fieldValues.length} Publication types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Publications type selected"
              : "Select publications type";
        }
        break;
      case "disciplines":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} discipline selected`
              : `${fieldValues.length} disciplines selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All disciplines"
              : "Select discipline";
        }

        break;
      case "subjects":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} subject selected`
              : `${fieldValues.length} subjects selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All subjects"
              : "Select subject";
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} publication selected`
              : `${fieldValues.length} publications selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All publications"
              : "Select publication";
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Content type selected`
              : `${fieldValues.length} Content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All content types"
              : "Select content type";
        }
        break;
      case "member_organisations":
        if (
          this.state.member_organisations &&
          this.state.member_organisations.length > 0
        ) {
          labelText =
            this.state.member_organisations &&
              this.state.member_organisations.length === 1
              ? `${this.state.member_organisations &&
              this.state.member_organisations.length
              }  Consortia Member Selected `
              : `${this.state.member_organisations &&
              this.state.member_organisations.length
              } Consortia Members Selected`;
        } else {
          labelText =
            this.state.member_organisations &&
              this.state.member_organisations.length === 0
              ? "All Consortia Members Selected"
              : "Click on PLUS button to select Consortia members ";
        }
        break;
      default:
        break;
    }

    return labelText;
  };

  getPubReadFeeTotal = () => {
    let depositPublishFee = Number(this.state.deposit_publish_fee) || 0;
    let depositReadFee = Number(this.state.deposit_read_fee) || 0;
    let total = depositPublishFee + depositReadFee;

    return total.toFixed(2);
  };

  addInvidualMember = (e) => {
    e.preventDefault();
    this.setState({
      isIndividualMember: !this.state.isIndividualMember,
    });
  };

  onCloseInvidualMember = () => {
    this.setState({
      editing: false,
      isIndividualMember: false,
    });
  };


  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isIndividualMember: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.individualMembers.splice(id, 1);
      return prevState;
    });
  };
  onChangeMemberRadioHandler = (value) => {
    this.setState({
      added_individual_member_deal: value.added_individual_member_deal,
    });
  };

  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push("/oa-deal-activities/oa-deals-table");
  };
  cancelLoader = () => {
    this.setState({
      errMsg: false,
      isEmpty: false,
    });
  };




  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState({
        counter_organisation: item.id,
        is_manager_organisation:
          item.is_manager_organisation.toString() === "true" ? true : false,
        orgsError: false

      });
    }
  };

  onChange = (value) => {
    let data = this.props.oaDeal_inst && this.props.oaDeal_inst.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        value: value,
        orgsError: false,
      });
    } else {
      if (value === "") {
        this.setState({
          orgsError: false,
          org_name: value

        })
      } else {
        this.setState({
          orgsError: true,
          org_name: value

        })
      }
    }


  };
  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.dealInstituationList(value);
      this.setState({
        odealInstList:
          this.props.oaDeal_inst &&
          this.props.oaDeal_inst.map((item) => ({
            id: item.id,
            value: item.value,
            is_manager_organisation: item.is_manager_organisation.toString(),
          })),
      });
    }, 200);
  };




  dealOtherHandler = () => {
    this.setState({
      isDealReadSubscribe: !this.state.isDealReadSubscribe,
      isDealPrePayment: false,
      isDealCredit: false,
      isDealOaDiscount: false,

    })
  }
  dealModelShowHandler = () => {
    this.setState({
      isDealReadSubscribe: false,
      isdealModelShowHandler: !this.state.isdealModelShowHandler
    })
  }

  dealTypeCheckHandler = (value) => {
    switch (value) {
      case "pre-payment":
        this.setState({
          isDealPrePayment: !this.state.isDealPrePayment,
          isDealCredit: false,
          isDealOaDiscount: false,
          isDealReadSubscribe: false,
        })
        break;
      case "credit":
        this.setState({
          isDealCredit: !this.state.isDealCredit,
          isDealPrePayment: false,
          isDealOaDiscount: false,
          isDealReadSubscribe: false,

        })
        break;
      case "oa-discount":
        this.setState({
          isDealOaDiscount: !this.state.isDealOaDiscount,
          isDealCredit: false,
          isDealPrePayment: false,
          isDealReadSubscribe: false,

        })
        break;
      default:
        this.setState({
          isDealOaDiscount: false,
          isDealCredit: false,
          isDealPrePayment: false,
          isDealReadSubscribe: false,
        })
        break;
    }
  }

  dealTyeNameCheckerHandler = () => {

    let checkExpireDate = {
      "pre-payment": "pre-payment",
      "credit": "credit",
      "oa-discount": "oa-discount"

    }

    return {
      checkExpireDate
    }
  }

  render() {
    const { odealInstList, DealType } = this.state;


    let errorHander = (
      <>
        {this.state.errMsg && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.errMsg}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );
    return (
      <HubContent>
        <>
          {this.state.success && (
            <PopupBox
              NoText="Close"
              msg="You have successfully created OA Deal."
              onCancel={this.redirectLink}
            />
          )}
        </>
        {errorHander}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">

              <div className="card-body">
                <div className="row create-oa-deal">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <ul className="nav nav-pills nav-justified" role="tablist">
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#home-1"
                          role="tab"
                        >
                          Create OA Deal
                    </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <div className="vat-tax-button email d-flex justify-content-end">
                      <Popover placement="leftTop" content={content} trigger="click">
                        <Button
                          className="btn btn-primary btn-sm waves-effect help-menu"
                          type=""
                          name=""
                          text="Help"
                        >Help</Button>
                      </Popover>

                      <Button
                        type="danger"
                        name="danger"
                        text="X"
                        onClick={() => this.props.history.goBack()}
                      />
                    </div>
                  </div>
                </div>


                <div className="create-oa-deal-content ">
                  <div className="row institution-create-oadeal">
                    <div className="col-md-3">
                      <label className="deal-label-name">Select Deal Type</label>
                      <SelectBox
                        className="select-deal-type"
                        onChange={this.onChangeHandlerDealType}
                        field="deal_type"
                        data={DealType}
                        isRequired={true}
                        defaultValue={this.state.deal_type}
                      />
                    </div>



                    <div className="col-md-9">
              
                      <div className="row auto-complete-search">
                       
                        <label className="deal-label-name">
                          Enter Institution or Consortia Name </label>
                        <div className="col-md-12">
                          <AutoComplete
                            defaultValue={this.state.value}
                            notFoundContent={this.state.org_name && "The institution name you have typed has no match on OaMetrix."}

                            options={odealInstList}
                            style={{
                              width: "100%",
                            }}
                            onSelect={(val, option) =>
                              this.onSelect(val, option)
                            }
                            onSearch={this.onSearch}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      <div className="search-auto-spin" style={{marginTop:30}}>
                        {this.props.loading && <Spin indicator={antIcon} />}
                      </div>

                      {this.state.orgsError
                        && <span style={{ color: "red" }}>Please make sure you have selected Institution/Consortia name from dropdown.
</span>}
                    </div>


                  </div>
                  {
                    this.state.is_manager_organisation !== true && (
                      this.dealCompontent()
                    )
                  }

                  {this.state.added_individual_member_deal === true &&
                    this.individualMembersRadioBox()
                  }

                  {this.state.is_manager_organisation === true &&
                    this.state.added_individual_member_deal === false && (
                      this.selectDealForAllMembersComponent()
                    )}


                  {this.state.is_manager_organisation === true && this.state.added_individual_member_deal === true && (
                    <div className="row mt-4">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-light btn-o-light mb-2"
                          onClick={this.addInvidualMember}
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="mdi mdi-plus"></i> Add Individual
      Members
    </button>
                      </div>
                    </div>
                  )}

                  {
                    this.state.is_manager_organisation === true && this.state.added_individual_member_deal === true && (
                      <div>
                        <div className="form-group row">
                          {this.state.individualMembers.length > 0 &&
                            this.state.individualMembers.map((members, id) => {
                              return (

                                <div className="col-md-6" key={id}>
                                  <Badge
                                    label={members.counter_organisation_name}
                                    onEdit={() => this.onEditCoAuthor(id)}
                                    onDelete={() => this.onDeleteCoAuthor(id)}
                                  />
                                </div>

                              );
                            })}
                        </div>

                        {this.state.isIndividualMember && (
                          <CreateConstInvidiualMemberOADeal
                            oadeal_currency={this.props.oadeal_currency}
                            constrainData={this.props.constrainData}
                            publication_types={ (this.props.publication_type && [
                              { id: "all", name: "All publication types" },
                              ...this.props.publication_type,
                            ]) ||
                            []}
                            publicationList={this.state.publicationList || []}
                            content_types={
                              (this.props.content_type && [
                                { id: "all", name: "All content types" },
                                ...this.props.content_type,
                              ]) ||
                              []
                            }
                            deal_type={this.state.deal_type}
                            deal_name={this.state.deal_name}
                            onClose={this.onCloseInvidualMember}
                            onSaveAuthor={this.onSaveInvidualMemberHander}
                            fieldLabel="Co author"
                            value={
                              this.state.individualMembers[this.state.editing]
                            }
                          />
                        )}

                      </div>
                    )
                  }

                  {
                    this.state.isIndividualMember !== true &&
                    <div className="text-center m-t-15">
                      <button type="button" className="btn btn-primary waves-effect waves-light" onClick={
                        this.state.is_manager_organisation === true
                          ? this.onIndiviualSubmit
                          : this.onSubmitHandler
                      }>Create OA deal</button>
                    </div>

                  }


                </div>
              </div>
            </div>


          </div>

        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.OaDeals.isLoading,

  userId: state.auth.user.id || false,
  oaDeal_inst: state.OaDeals.oaDeal_inst || false,
  failed_oa_deal: state.OaDeals.failed_create_oadeal || false,
  organisation_id: state.auth.user.organisation_id || false,
  token: state.auth.token || false,
  counter_org: state.OaDeals.counter_org || false,
  deal_type: state.OaDeals.deal_type || false,
  oadeal_currency: state.OaDeals.oadeal_currency || false,
  content_type: state.journals.content_type,
  publication_type: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  oa_subjects: state.OaSubject.oa_subjects || false,
  oadeal_summary: state.OaDeals.oadeal_summary || false,
});
const mapDispatchToProps = (dispatch) => ({
  dealInstituationList: (value) => dispatch(dealInstituationList(value)),
  getAllDealType: () => dispatch(getAllDealType()),
  getAllCurrencyList: () => dispatch(getAllCurrencyList()),
  createOaDeal: (payload) => dispatch(createOaDeal(payload)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: (id) => dispatch(getContentTypeList(id)),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getOaDealSummary: (orgId, payload) =>
    dispatch(getOaDealSummary(orgId, payload)),
});

export const CreateOaDeals = connect(mapStateToProps, mapDispatchToProps)(DB);

