import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { UserForm } from "../../../partials";
import { CreateUser, ClearStatus } from "../../../../actions";
import { PopupBox, Loading,Button } from "../../../../../core/components/common";

class AddUserForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    user: {},
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
  };

  componentDidUpdate() {
    this.state.msg &&
      setTimeout(() => {
        this.setState({
          msg: false,
        });
      }, 2000);

    if (this.props.failed) {
      if (this.props.failed.status === 500 && this.state.status !== 500) {
        this.setState({
          status: 500,
        });
      } else if (
        this.props.failed.status === 400 &&
        this.state.status !== 400
      ) {
        this.setState({
          status: 400,
        });
      }
    }
  }

  onSubmitHandler = async (user) => {
    if (
      user.email &&
      user.first_name &&
      user.last_name &&
      user.role &&
      user.organisation &&
      user.group
    ) {
      this.setState({
        isRegistering: true,
        msg: false,
        user: user,
      });
      const data = Object.assign(user, { request_source: "hub" });
      await this.props.CreateUser(data);
     this.props.history.push(this.props.auth ==="PUBLISHER"?"/pub-user/user-list" :"/user/user-list");
    } else {
      this.setState({
        isEmpty: (
          <div>
            {!user.email && <p>Email Cannot Be Empty!</p>}
            {!user.first_name && <p>Firstname Cannot Be Empty</p>}
            {!user.last_name && <p>Lastname Cannot Be Empty</p>}
            {!user.role && <p>Role Cannot Be Empty</p>}
            {!user.group && <p>Group Cannot Be Empty</p>}
            {!user.organisation && <p>Organization Cannot Be Empty</p>}
          </div>
        ),
      });
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  render() {
    let errorHander = (
      <>
        {this.state.status === 400 && (
          <PopupBox
            title="Bad Request"
            msg="Please Check Your Form and Try again"
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.status === 500 && (
          <PopupBox
            title="Network Error Found"
            msg="Please Check Internet Connection and Try Again"
            onCancel={this.cancelLoader}
          />
        )}

        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}

        {this.state.msg && (
          <PopupBox
            Title={this.state.msg}
            msg={this.state.msg}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent title="Add user form">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        {/* LoadinG Handling */}
        {this.state.isRegistering && !this.state.status && <Loading />}
        {/* LoadinG Handling */}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
              <div className="vat-tax-button email">
                                <Button
                                    type="danger"
                                    name="danger"
                                    text="x"
                                    onClick={() => this.props.history.goBack()}
                                />
                            </div>
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add user details
                    </a>
                  </li>
                </ul>

                <UserForm
                  onSubmitHandler={this.onSubmitHandler}
                  msg={this.state.msg}
                  user={this.state.user}
                />
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.user.failed || false,
  auth:state.auth.group
});

const mapDispatchToProps = (dispatch) => ({
  CreateUser: (payload) => dispatch(CreateUser(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
});

export const AddUser = connect(mapStateToProps, mapDispatchToProps)(AddUserForm);
