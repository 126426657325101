import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { DashContext } from '../../../../core/context';
import { Loading, ModalCustom } from '../../../../core/components/common';
import { PopupBox, AutoApprovalTableRow, } from '../../../../core/components/common';
import {
    getLicense,
    getPublicPublisher,
    getAllDealType,
    getPublicatonTypeList,
    getOrganisationAutoApprovalConfig,
    triggerOrganisationAutoApprovalConfig,
    createOrganisationAutoApprovalStatus,
    updateOrganisationAutoApprovalStatus,
    getOrganisationList
} from '../../../actions';

import { Offline } from "react-detect-offline";

export class Header extends DashContext {

    state = {
        isLogout: false,
        hasConnectionError: true,
        showAutoApprovalConfig: false,
        isEmpty: false,
        addNewStatus: true,
        isNew: false,
        data: null,
        deleteConfirm: false,
        orgId: null,
        waitingMsg: null,
        deleteStatus: null,
        deleteing: false
    }
    logoutUser =async  (e) => {
        this.setState({
            isLogout: true
        })
       await this.props.logoutUser();
    }
     componentDidMount() {
       this.apiDataList()
    }
    apiDataList = async() => {
        this.getOrgData();
        this.isEnlarged();

        if (!this.props.deal_type || !this.props.publishers || !this.props.publication_type) {
            this.props.getAllDealType();
            this.props.getPublicPublisher();
            this.props.getPublicatonTypeList();
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
    }

    getOrgData = async (pageNum = 1, page = 20) => {
        try {
            await this.props.getOrganisationList({ pageNum: pageNum, pageSize: page });
            let orgObj = this.props.orgs;
            let dataOrg = [];
            orgObj && orgObj.map((item) => {
                return dataOrg.push({
                    organization_name: item.name,
                    email_domain: item.email_domain,
                    grid_number: item.meta ? item.meta.grid_number : '',
                    country: item.address ? item.address.country_name : '',
                    id: item.id
                });
            })
            this.setState({
                data: dataOrg
            });
        } catch (error) {
            this.log("getOrgData: ", error);
        }
    }

    isEnlarged = () => {
        if (localStorage.getItem('menuEnlarged') === '1') {
            window.$('body').addClass('enlarged');
        } else {
            window.$('body').removeClass('enlarged');
        }
    }

 

    onClose = (e) => {
        this.setState({
            showAutoApprovalConfig: false,
            addNewStatus: true,
            isNew: false,
        });
    }

    onClick = async (e) => {
        this.setState({
            isLoading: true,
        });

        await this.props.getOrgAutoApprovalConfig();

        this.props.org_auto_approval_config && this.setState({
            showAutoApprovalConfig: true,
            data: this.props.org_auto_approval_config.results,
            isLoading: false,
        });
    }

    onSubmit = async (data) => {
        let id = data.id || false;
        delete data.id;

        id ? await this.props.updateOrgAutoApprovalStatus(id, data) : await this.props.createOrgAutoApprovalStatus(data);

        if (this.props.org_auto_approval_status.status) {
            await this.props.getOrgAutoApprovalConfig();

            this.props.org_auto_approval_config && this.setState({
                data: this.props.org_auto_approval_config.results,
                addNewStatus: true,
                isNew: false,
            });
        }
    }

    onError = (errorMsg) => {
        if (errorMsg) {
            this.setState({
                isEmpty: errorMsg
            })
        }
    }

    renderTableBody = (data) => (
        data ? data.map((item, key) => {

            return (
                <AutoApprovalTableRow key={key} organisationList={this.props.publishers} dealTypeList={this.props.deal_type} publicationTypeList={this.props.publication_type} item={item} onSubmitHandler={this.onSubmit} onErrorHandler={this.onError} />
            )
        }) : <AutoApprovalTableRow isNew={true} key={'0_0'} organisationList={this.props.publishers} dealTypeList={this.props.deal_type} publicationTypeList={this.props.publication_type} item={false} onSubmitHandler={this.onSubmit} onErrorHandler={this.onError} />
    )

    addNewHandler = () => {
        this.setState({
            addNewStatus: false,
            isNew: true,
        });
    }

    cancelLoader = async () => {
        this.setState({
            isEmpty: false,
        });
    }

    renderAutoApprovalButton = () => {
        return this.props.auth.group === 'INSTITUTION' && (
            <li className="d-none d-sm-block pr-3">
                <div className="pt-3">
                    <button onClick={this.onClick} className="btn btn-info w-md waves-effect waves-light" type="button">Auto approval</button>
                </div>
            </li>
        )
    }

    render() {
        let { showAutoApprovalConfig, data } = this.state;

        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>

        return (
            <div>
                {this.state.isLogout && <Loading />}
                {this.state.isLoading && <Loading />}

                {errorHander}

                {showAutoApprovalConfig ? <ModalCustom
                    title="Auto approval setup"
                    onClose={this.onClose}
                    modalClass="col-sm-10"
                    isShow={showAutoApprovalConfig}>
                    <div className="text-right">
                        {this.state.addNewStatus && <button type="button" className="btn btn-primary"
                            onClick={this.addNewHandler}
                        >Add new</button>}
                    </div>
                    <table className="table table-borderless mb-0 mb-0">
                        <thead>
                            <tr>
                                <th className="" width="20%">Deal type</th>
                                <th className="" width="20%">Publication type</th>
                                <th className="" width="25%">Publisher</th>
                                <th className="" width="20%">Expiry</th>
                                <th className="" width="15%">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.isNew && this.renderTableBody()}
                            {data && this.renderTableBody(data)}
                        </tbody>
                    </table>
                </ModalCustom> : ''}

                <div className="topbar">
                    {/* <!-- LOGO --> */}
                    <div className="topbar-left">
                        <a href="/" className="logo">
                            <span>
                                <img src="/assets/images/logo-sm.png" alt="" width="100" />
                            </span>
                            <i>
                                <img src="/assets/images/logo-fav.png" alt="" height="40" />
                            </i>
                        </a>
                    </div>
                    {/* <!-- LOGO END --> */}

                    {/* Topbar Navigation */}
                    <nav className="navbar-custom">
                        <ul className="navbar-right d-flex list-inline float-right mb-0">

                            {/* {this.props.auth && this.renderAutoApprovalButton()} */}

                            {/* 
                            <li className="d-none d-sm-block" style={{ marginRight: 10 }}>
                                <div className="dropdown pt-3 d-inline-block">

                                    <a className="btn btn-outline-info  dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                        <Tooltip
                                            placement="topLeft"
                                            title={`Institution or Author may eligible for special discount for this article.`}
                                        >
                                            info <i className="mdi mdi-arrow-down"></i>

                                        </Tooltip>
                                    </a>
                                </div>
                            </li> */}

                            <li className="d-none d-sm-block">
                                <div className="dropdown pt-3 d-inline-block">
                                    <a className="btn btn-outline-info  dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Action <i className="mdi mdi-arrow-down"></i>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <Link to="/users/add-user" className="dropdown-item">Add admin user</Link>
                                    </div>
                                </div>
                            </li>

                            {/* Notificatin Dropdown */}
                             {/*<NotificationDropdown group={this.props.group} /> */}
                            {/* Notificatin Dropdown End */}

                            <li className="dropdown notification-list">
                                <div className="dropdown notification-list nav-pro-img">
                                    <a className="dropdown-toggle nav-link arrow-none waves-effect nav-user" data-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
                                        <img src="/assets/images/users/user-4.jpg" alt="user" className="rounded-circle" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                        {/* <!-- item--> */}
                                        <div className="dropdown-item">

                                            <Link to={`/users/user/${this.props.user &&
                                                this.props.user.id}`}> <i className="mdi mdi-account-circle m-r-5"></i> My Account </Link>
                                        </div>


                                        <div className="dropdown-item">
                                            <Link to={`/settings/list`}><i className="mdi mdi-poll m-r-5"></i> Settings </Link>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <span className="dropdown-item text-danger cursor-pointer" onClick={this.logoutUser}><i className="mdi mdi-power text-danger"></i>Logout {this.props.showAuthUserInfo()}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <ul className="list-inline menu-left mb-0">
                            <li className="float-left">
                                <button className="button-menu-mobile open-left waves-effect">
                                    <i className="mdi mdi-menu"></i>
                                </button>
                            </li>
                            <li className="float-left">
                                <p style={{ marginTop: '20px', fontWeight: 700, fontSize: '16px' }}>
                                {`${this.props.user.fullname} (${this.props.user.organisation_name})` || "No Title"}
                                </p>
                            </li>
                        </ul>
                    </nav>
                    <Offline>
                        <div className="text-white bg-danger" style={{
                            position: 'fixed',
                            bottom: this.state.hasConnectionError ? 0 : -100,
                            left: 0,
                            right: 0,
                            display: 'block',
                            // backgroundColor: "red",
                            padding: 10,
                            textAlign: 'center',
                            fontSize: 20,
                            transition: '0.5s ease 0s'
                            // color: "#fff"
                        }}>No Internet Connection. Please check your internet connection.
                            <span className="float-right mr-2" style={{ cursor: 'pointer' }} onClick={() => {
                                this.setState({ hasConnectionError: false })
                            }}>
                                <i className="mdi mdi-close"></i>
                            </span>
                        </div>
                    </Offline>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth || {},
    user: state.auth.user || {},
    org_auto_approval_config: state.orgs.org_approval_config || false,
    org_auto_approval_status: state.orgs.org_approval_status || false,
    publishers: state.orgs.publisher_list ? state.orgs.publisher_list.results : false,
    publication_type: state.journals.publication_type ? state.journals.publication_type : false,
    deal_type: state.OaDeals.deal_type || false,
    orgs: state.app.organizations || false,
    count: state.orgs.org_list_count || false
})

const mapDispatchToProps = (dispatch) => ({
    getAllDealType: () => dispatch(getAllDealType()),
    getOrganisationList: (payload) => dispatch(getOrganisationList(payload)),
    getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
    getLicense: () => dispatch(getLicense()),
    getPublicPublisher: () => dispatch(getPublicPublisher()),
    getOrgAutoApprovalConfig: () => dispatch(getOrganisationAutoApprovalConfig()),
    triggerOrgAutoApprovalConfig: (payload) => dispatch(triggerOrganisationAutoApprovalConfig(payload)),
    updateOrgAutoApprovalStatus: (id, payload) => dispatch(updateOrganisationAutoApprovalStatus(id, payload)),
    createOrgAutoApprovalStatus: (payload) => dispatch(createOrganisationAutoApprovalStatus(payload)),
})

export const UnivHeader = connect(mapStateToProps, mapDispatchToProps)(Header)