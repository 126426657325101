import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { DataTable } from '../../../../../core/components';

class DB extends BaseComponent {
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();
    
        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        
        // }
      };
    render() {
        return (
            <HubContent title="Offset or tokens allocation table"  onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <DataTable
                                // heading={heading}
                                data={this.props.users}
                                onEdit={this.onEditUser}
                                onDelete={this.onDeleteUser}
                                onView={this.onViewUser}
                            />
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}



export const TokensOffsetList = (DB)