import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { getOrgInfo, getDashboardData } from '../../../../actions';
import { AddressCard } from '../../../partials';
import { ArticleFull } from '../../hub';
import { serialize } from '../../../../../core/lib';

class ConsortiaSingleOrg extends BaseComponent {
  state = {
    org: "",
    errMsg: "",
    address: null
  }

   componentDidMount() {
  this.apiDataList()

  }
  apiDataList =async () => {
    let id = this.props.match.params.id
    try {
      let orgInfo = await this.props.getOrgInfo(id)

      if (orgInfo.address) {
        this.setState({
          address: orgInfo.address,
          errMsg: "",
          org: orgInfo
        })
      } else {
        this.setState({
          errMsg: "This organization does not have address."
        })
      }
    } catch (e) {
      this.setState({
        errMsg: "There is something error"
      })
    }
    this.filterData(id)
  }

  filterData = async (id) => {
    let filterParams = 'component=available_offset_component,spend_offset_component,pub_deposit_component'
     this.setState((prevState) => {

        prevState.filterParams = { filter_publisher: id }
      
    })

    let filter = filterParams + '&' + serialize(this.state.filterParams);
    try {
      this.props.getDashboardData({ filter })
    } catch (e) {
    }
  }


  render() {
    return (
      <div>
        <HubContent title={this.state.org.name} className="mb-0">
          <div className="row">
            <AddressCard
              errMsg={this.state.errMsg}
              address={this.state.address}
            />
      
          </div>

        </HubContent>

        <ArticleFull
          className={"mt-0"}
          institute={this.props.match.params.id}
        />
      </div>
    )
  }
}


const mapStateToProps = (state, props) => {
    return {
      articles: state.articles.article_full || [],
      available_fund_component: state.dashboard.available_fund_component || false,
      oa_spent_component: state.dashboard.oa_spent_component || false,
      deposit_component: state.dashboard.total_deposit_component || false
    }
  
}

const mapDispatchToProps = (dispatch) => ({
  getOrgInfo: (id) => dispatch(getOrgInfo(id)),
  getDashboardData: (payload) => dispatch(getDashboardData(payload))
})


export const OrgSingle = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsortiaSingleOrg)