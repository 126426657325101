import React from "react";
import { connect } from "react-redux";
import { CreatePublisherArticle } from "../../publisher/index";
import JournalList from "../../publisher/publications/journal/JournalTable";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { Button, Loading } from "../../../../../core/components/common";
import BookChapterForm from "../../publisher/publications/chapter/ChapterForm";
import BookSeriesTable from "../../publisher/publications/series/BookSeriesTable";
import BookTable from "../../publisher/publications/book/BookTable";

import {
  getJournalListHub,
  deleteJournal,
  getJournalDownload,
} from "../../../../actions";

import { serialize } from "../../../../../core/lib";
import moment from "moment";

class PublicationForm extends BaseComponent {
  state = {
    selectedTab: 0,
  };

  componentDidMount() {
    let tabList = [
      {
        id: 1,
        name: "Journals",
      },
      {
        id: 2,
        name: "Articles",
      },
      {
        id: 3,
        name: "Books",
      },
      {
        id: 4,
        name: "Book Series",
      },
      {
        id: 5,
        name: "Book Chapter",
      },
    ];
    let index =
      (this.props.location.state && this.props.location.state.index) ||
      this.state.selectedTab;
    this.publicationtabList(tabList[index]);

    this.setState({
      tabList: [...tabList],
      selectedTab: index,
    });
  }

  publicationtabList = async (item, index = 0) => {
    this.setState({
      selectedTab: index,
    });
  };

  handleJournal = (index) => {
    this.props.history.push({
      pathname:
        index === 0
          ? "/article/create-journal"
          : index === 2
            ? "/article/create-book"
            : index === 3
              ? "/article/create-bookseries"
              : "/article",
      state: { id: this.state.id, index: index },
    });
  };

  handleBookChapter = (index) => {
    this.setState({
      tabIndex: index,
    });
  };

  onArticleDownloadClick = async () => {
    try {
      let params = await serialize(this.state.params);
      let filter = await serialize(this.state.filterParams);
      await this.props.getJournalDownload({ params, filter });
      const url = window.URL.createObjectURL(this.props.journalDownload);
      const link = document.createElement("a");
      link.href = url;
      var d = new Date();

      link.setAttribute(
        "download",
        `journal_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) { }
  };

  render() {
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12 publication-form">
            <div className="card-body">
              <div className="card m-b-20">
                <div
                  id="collapse1"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-12">
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-justified"
                          role="tablist"
                        >
                          {this.state.tabList ? (
                            this.state.tabList.map((item, index) => (
                              <li className="nav-item" key={item.name}>
                                <a
                                  className={`nav-link${this.state.selectedTab === index
                                    ? " active"
                                    : ""
                                    }`}
                                  data-toggle="tab"
                                  href={`#${item.name}`}
                                  onClick={() =>
                                    this.publicationtabList(item, index)
                                  }
                                  role="tab"
                                >
                                  {item.name}
                                </a>
                              </li>
                            ))
                          ) : (
                              <Loading type="flat" loading="loading" />
                            )}
                        </ul>

                        <div className="tab-content">
                          <div
                            className={`tab-pane p-3${this.state.selectedTab === 0 ? " active" : ""
                              }`}
                            id="Journals"
                            role="tabpanel"
                          >
                            <div className="journal-content">
                              <div className="journal-tab">
                                <Button
                                  type="primary"
                                  text="Add Journal"
                                  name="primary"
                                  className="journal-add-btn"
                                  onClick={() => this.handleJournal(0)}
                                />
                                <Button
                                  type="secondary"
                                  text="Download"
                                  name="secondary"
                                  className="journal-download-btn"
                                  onClick={this.onArticleDownloadClick}
                                />
                              </div>

                              <JournalList />
                            </div>
                          </div>
                          <div
                            className={`tab-pane p-3${this.state.selectedTab === 1 ? " active" : ""
                              }`}
                            id="Articles"
                            role="tabpanel"
                          >
                            <CreatePublisherArticle />
                          </div>
                          <div
                            className={`tab-pane p-3${this.state.selectedTab === 2 ? " active" : ""
                              }`}
                            id="Books"
                            role="tabpanel"
                          >
                            <div className="journal-content">
                              <div className="journal-tab">
                                <Button
                                  type="primary"
                                  text="Add Book"
                                  name="primary"
                                  className="journal-add-btn"
                                  onClick={() => this.handleJournal(2)}
                                />
                              </div>
                              <BookTable />
                            </div>
                          </div>
                          <div
                            className={`tab-pane p-3${this.state.selectedTab === 3 ? " active" : ""
                              }`}
                            id="Book Series"
                            role="tabpanel"
                          >
                            <div className="journal-content">
                              <div className="journal-tab">
                                <Button
                                  type="primary"
                                  text="Add Book series"
                                  name="primary"
                                  className="journal-add-btn"
                                  onClick={() => this.handleJournal(3)}
                                />
                              </div>
                              <BookSeriesTable />
                            </div>{" "}
                          </div>
                          <div
                            className={`tab-pane p-3${this.state.selectedTab === 4 ? " active" : ""
                              }`}
                            id="Book Chapter"
                            role="tabpanel"
                          >
                            <BookChapterForm
                              location={this.state.selectedTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  group: state.auth.group,
  journals: state.journals.journal_list || false,
  journalDownload: state.journals.journal_download,
});

const mapDispatchToProps = (dispatch) => ({
  getJournalListHub: (payload) => dispatch(getJournalListHub(payload)),
  deleteJournal: (id) => dispatch(deleteJournal(id)),
  getJournalDownload: (payload = {}) => dispatch(getJournalDownload(payload)),
});

export const AddPublicationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicationForm);
