import config, {
  ADD_UPDATE_ARTICLE,
  ARTICLE_APPROVE_SUMMARY,
  BOOK_DOWNLOAD,
  CORRECTION_DOWNLOAD,
  ARTICLE_DOWNLOAD,
  ARTICLE_FILE_UPLOAD,
  TRACK_ARTICLE_FILE_UPLOAD_STATUS,
  ALL_ARTICLES,
  GET_ARTICLE_MANUAL,
  ARTICLE_FETCH_SUCCESS,
  ARTICLE_FETCH_ERROR,
  GET_ARTICLE_FULL_ALL,
  GET_SINGLE_ARTICLE,
  GET_LICENCE_LIST,
  GET_LICENCE_NON_OA,
  CREATE_AUTHOR_APC_FUND,
  CREATE_AUTHOR_APC_FUND_NO_OA,
  FAILED_AUTHOR_APC_FUND,
  CORRECTION_REQUEST,
  CORRECTION_REQUEST_SINGLE,
  BOOK_FILE_UPLOAD,
  REQUEST_BOOK_LIST,
  SUCCESS_BOOK_LIST,
  FAILED_BOOK_LIST,
  GET_SINGLE_BOOK,
  BOOK_SEARCH,
  ADD_UPDATE_BOOK,
  TRACK_ARTICLE_FILE_UPLOAD_STATUS_DOWNLOAD,
  MUTATE_ARTICLE_PRICE,
  GET_ALL_LICENCE,
  ARTICLE_BTN_ACTION,
  ARTICLE_FETCH_REQUEST,
  EMAIL_RESEND_ACTION,
  EMAIL_HISTORY_REQUEST,
  EMAIL_HISTORY_SUCCESS,
  EMAIL_HISTORY_ERRROR,
  ARTICLE_VIEW_LOG,
  ARTICLE_OFFER_DETAILS,
  ARTICLE_OFFER_DETAILS_REQUEST,
  REQUEST_SINGLE_ARTICLE_FULL,
  SUCCESS_SINGLE_ARTICLE_FULL,
  ARTICLE_REQUEST_ACTION_BUTTON,
  ARTICLE_MESSAGE_EMAIL_REQUEST,
  REQUEST_ARTICLE_MANUAL_ACTION,
  REQUEST_ARTICLE_ERROR_ACTION,
  REQUEST_ARTICLE_MANUAL_UPDATE_ACTION,
  REQUEST_UPDATE_ARTICLE,
  REQUEST_CANCEL_ARTICLE,
  SUCCESS_GET_APC,
  ARTICLE_AUTHOR_NOTIFICATION_SUMMARY,
} from "../../config";
import { api } from "../../core/api";

export const requestArticleSingleAction = () => ({
  type: REQUEST_SINGLE_ARTICLE_FULL,
});
export const getArticleApcOptionSuccess = (payload) => ({
  type: SUCCESS_GET_APC,
  payload,
});

export const loadingUpdateAction = () => ({
  type: REQUEST_UPDATE_ARTICLE,
});
export const cancelRequestAction = () => ({
  type: REQUEST_CANCEL_ARTICLE,
});

export const articleManualRequestUpdateAction = () => ({
  type: REQUEST_ARTICLE_MANUAL_UPDATE_ACTION,
});

export const articleErrorRequestAction = () => ({
  type: REQUEST_ARTICLE_ERROR_ACTION,
});

export const articleManualRequestAction = () => ({
  type: REQUEST_ARTICLE_MANUAL_ACTION,
});

export const successArticleSingleAction = (payload) => ({
  type: SUCCESS_SINGLE_ARTICLE_FULL,
  payload,
});
export const mutateArticlePriceAction = (payload) => ({
  type: MUTATE_ARTICLE_PRICE,
  payload,
});

export const articleFetchRequest = () => ({
  type: ARTICLE_FETCH_REQUEST,
});
export const articleFetchSuccess = (payload) => ({
  type: ARTICLE_FETCH_SUCCESS,
  payload,
});
export const articleFetchError = (payload) => ({
  type: ARTICLE_FETCH_ERROR,
  payload,
});

export const createNewArticleAction = (payload) => ({
  type: ADD_UPDATE_ARTICLE,
  payload,
});
export const CreateNewBook = (payload) => ({
  type: ADD_UPDATE_BOOK,
  payload,
});

export const GetArticles = (payload) => ({
  type: ALL_ARTICLES,
  payload,
});

export const articleDownloadAction = (payload) => ({
  type: ARTICLE_DOWNLOAD,
  payload,
});

export const requestBookListAction = () => ({
  type: REQUEST_BOOK_LIST,
});

export const sucessBookListAction = (payload) => ({
  type: SUCCESS_BOOK_LIST,
  payload,
});
export const failedBooksListAction = (payload) => ({
  type: FAILED_BOOK_LIST,
  payload,
});
export const SingleBookForm = (payload) => ({
  type: GET_SINGLE_BOOK,
  payload,
});

export const articleSuccessManualAction = (payload) => ({
  type: GET_ARTICLE_MANUAL,
  payload,
});

export const ArticleFull = (payload) => ({
  type: ARTICLE_FETCH_SUCCESS,
  payload,
});

export const ArticleFullALL = (payload) => ({
  type: GET_ARTICLE_FULL_ALL,
  payload,
});

export const getLicenseReducer = (payload) => ({
  type: GET_LICENCE_LIST,
  payload,
});

export const getLicenseNonOaReducer = (payload) => ({
  type: GET_LICENCE_NON_OA,
  payload,
});

export const createAuthorApcFundReducer = (payload) => ({
  type: CREATE_AUTHOR_APC_FUND,
  payload,
});

export const createAuthorApcNoOaReducer = (payload) => ({
  type: CREATE_AUTHOR_APC_FUND,
  payload,
});

export const createAuthorApcFundNoOAReducer = (payload) => ({
  type: CREATE_AUTHOR_APC_FUND_NO_OA,
  payload,
});

export const failedAuthorApcFundReducer = (payload) => ({
  type: FAILED_AUTHOR_APC_FUND,
  payload,
});

export const CorrectionRequest = (payload) => ({
  type: CORRECTION_REQUEST,
  payload,
});

export const CorrectionRequestSingle = (payload) => ({
  type: CORRECTION_REQUEST_SINGLE,
  payload,
});

export const articleFileUploadReducer = (payload) => ({
  type: ARTICLE_FILE_UPLOAD,
  payload,
});

export const bookFileUploadReducer = (payload) => ({
  type: BOOK_FILE_UPLOAD,
  payload,
});

export const trackArticleFileUploadStatusAction = (payload) => ({
  type: TRACK_ARTICLE_FILE_UPLOAD_STATUS,
  payload,
});

export const trackArticleFileUploadStatusActionDownload = (payload) => ({
  type: TRACK_ARTICLE_FILE_UPLOAD_STATUS_DOWNLOAD,
  payload,
});

// function name need to be changed
export const trackBookFileUploadStatusReducer = (payload) => ({
  type: TRACK_ARTICLE_FILE_UPLOAD_STATUS,
  payload,
});

export const articleApproveSummaryAction = (payload) => ({
  type: ARTICLE_APPROVE_SUMMARY,
  payload,
});
export const articleAuthorNotificationAction = (payload) => ({
  type: ARTICLE_AUTHOR_NOTIFICATION_SUMMARY,
  payload,
});

export const CorrectiondownloadAction = (payload) => ({
  type: CORRECTION_DOWNLOAD,
  payload,
});

export const AllBooksDownloadAction = (payload) => ({
  type: BOOK_DOWNLOAD,
  payload,
});

export const bookSearchList = (payload) => ({
  type: BOOK_SEARCH,
  payload,
});

export const getAllLicence = (payload) => ({
  type: GET_ALL_LICENCE,
  payload,
});
export const auditEmailRequest = () => ({
  type: EMAIL_HISTORY_REQUEST,
});

export const auditEmailSucces = (payload) => ({
  type: EMAIL_HISTORY_SUCCESS,
  payload,
});

export const auditEmailError = (payload) => ({
  type: EMAIL_HISTORY_ERRROR,
  payload,
});

export const articleButtonAction = (payload) => ({
  type: ARTICLE_BTN_ACTION,
  payload,
});
export const articleEmailResendAction = (payload) => ({
  type: EMAIL_RESEND_ACTION,
  payload,
});
export const articleEmailViewSucces = (payload) => ({
  type: ARTICLE_VIEW_LOG,
  payload,
});
export const articleEmailViewRequest = () => ({
  type: ARTICLE_MESSAGE_EMAIL_REQUEST,
});

export const getArticleOfferDetailsAction = (payload) => ({
  type: ARTICLE_OFFER_DETAILS,
  payload,
});
export const requestArticleOfferDetailsAction = (payload) => ({
  type: ARTICLE_OFFER_DETAILS_REQUEST,
});
export const requestArticleButtonAction = () => ({
  type: ARTICLE_REQUEST_ACTION_BUTTON,
});
export const articleAuthorNotification = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.send_notification + `${payload}/`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(articleAuthorNotificationAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Article Approve Summary
export const articleApproveSummary = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.article_approve_summary + `${payload.id}/`;
    if (payload.apc_option) {
      url += "?apc_option=" + payload.apc_option;
    }

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(articleApproveSummaryAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Article file upload reducer
export const articleFileUpload = (payload) => {
  let url = config.endpoint.article_basic_upload;
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return new Promise((resolve, reject) => {
      let filename = payload.get("filename");
      let headers = {
        "Content-Disposition": `attachment; filename="${filename}"`,
      };

      payload.delete("filename");

      api
        .post(url, payload, token, headers)
        .then((res) => {
          dispatch(articleFileUploadReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(articleFileUploadReducer(error.response));
          resolve(Object.assign(error.response, { status: false }));
        });
    });
  };
};

export const SocietyFileUpload = (payload) => {
  let url = `${config.endpoint.society_member}upload/`;
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return new Promise((resolve, reject) => {
      let filename = payload.get("filename");
      let headers = {
        "Content-Disposition": `attachment; filename="${filename}"`,
      };

      payload.delete("filename");

      api
        .post(url, payload, token, headers)
        .then((res) => {
          dispatch(articleFileUploadReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(articleFileUploadReducer(error.response));
          resolve(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Books upload reducer
export const bookFileUpload = (payload) => {
  let url = config.endpoint.book_upload;
  return (dispatch, getState) => {
    const token = getState().auth.token;

    return new Promise((resolve, reject) => {
      let filename = payload.get("filename");
      let headers = {
        "Content-Disposition": `attachment; filename="${filename}"`,
      };

      payload.delete("filename");

      api
        .post(url, payload, token, headers)
        .then((res) => {
          dispatch(bookFileUploadReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(bookFileUploadReducer(error.response));
          resolve(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Track file upload status
export const trackArticleFileUploadStatus = (payload) => {
  let url = payload.upload_status;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(trackArticleFileUploadStatusAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(trackArticleFileUploadStatusAction(error.response));
          resolve(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Track file upload status & download
export const trackArticleFileUploadStatusDownload = (payload) => {
  let url = payload.upload_status;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(trackArticleFileUploadStatusActionDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(trackArticleFileUploadStatusActionDownload(error.response));
          resolve(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Download articles
export const articleDownload = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  const url =
    config.endpoint.article_download +
    `?fields=title,corresponding_author,author_email,author_department,author_affiliation,publication_type,content_type,article_id,journal,publisher_name,submission_date,acceptance_date,approved_date_date_only,doi,licence_name,funder_info,grant_number,co_author,co_author_email,co_author_department,co_author_affiliation,apc_gbp,apc_eur,apc_usd,submission_fee_gbp,submission_fee_eur,submission_fee_usd,colour_charge_gbp,colour_charge_eur,colour_charge_usd,page_charge_gbp,page_charge_eur,page_charge_usd,other_charge_gbp,other_charge_eur,other_charge_usd&page_size=${
      params && params + "&"
    }${filter}`;

  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };

    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(articleDownloadAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Create Author Apc Fund Request
export const createAuthorApcFund = (payload) => {
  let url = config.endpoint.author_apc_fund_request;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createAuthorApcFundReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(createAuthorApcFundReducer(error.response.data));
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

// Create Author Apc Fund Request
export const createAuthorApcNoOa = (payload) => {
  let url = config.endpoint.author_apc_no_oa;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createAuthorApcFundReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(createAuthorApcFundReducer(error.response.data));
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

// Create Author Apc Fund Request No OA
export const createAuthorApcNoOA = (payload) => {
  let url = config.endpoint.author_apc_no_oa;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createAuthorApcFundNoOAReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          dispatch(createAuthorApcFundNoOAReducer(error.response.data));
          resolve(Object.assign(error, { status: false }));
        });
    });
  };
};

// Request from hub
export const CreateArticle = (payload) => {
  let url = config.endpoint.article_basic;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => {
          delete res.config;
          delete res.headers;
          delete res.request;
          dispatch(createNewArticleAction(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(createNewArticleAction(error.response));
            reject(error);
          } else {
            dispatch(
              createNewArticleAction({
                status: 500,
              })
            );
          }
        }
      );
    });
  };
};

// Request from hub
export const CreateBook = (payload) => {
  let url = config.endpoint.book;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => {
          delete res.config;
          delete res.headers;
          delete res.request;
          dispatch(createNewArticleAction(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(createNewArticleAction(error.response));
            reject(error);
          } else {
            dispatch(
              createNewArticleAction({
                status: 500,
              })
            );
          }
        }
      );
    });
  };
};
export const getSingleBookForm = (payload) => {
  let url = config.endpoint.book + payload + "/";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(SingleBookForm(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(SingleBookForm(error.response));
          } else {
            dispatch(SingleBookForm({ status: 500 }));
          }
        }
      );
    });
  };
};

export const UpdateBookForm = (id, payload) => {
  let url = config.endpoint.book + id + "/";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (res) => {
          dispatch(CreateNewBook(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(CreateNewBook(error.response));
          } else {
            dispatch(CreateNewBook({ status: 500 }));
          }
        }
      );
    });
  };
};

// Request from hub
export const EditArticle = (payload) => {
  let url = config.endpoint.article_manual + payload.id + "/";

  return (dispatch) => {
    dispatch(articleManualRequestUpdateAction());

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (res) => {
          delete res.config;
          delete res.headers;
          delete res.request;
          dispatch(createNewArticleAction(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(createNewArticleAction(error.response));
            reject(error);
          } else {
            dispatch(
              createNewArticleAction({
                status: 500,
              })
            );
          }
        }
      );
    });
  };
};

export const createBookChapter = (payload) => {
  let url = config.endpoint.article_manual;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => {
          delete res.config;
          delete res.headers;
          delete res.request;
          dispatch(createNewArticleAction(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(createNewArticleAction(error.response));
            reject(error);
          } else {
            dispatch(
              createNewArticleAction({
                status: 500,
              })
            );
          }
        }
      );
    });
  };
};

export const SingleArticle = (payload) => ({
  type: GET_SINGLE_ARTICLE,
  payload,
});

export const getSingleArticle = (id) => {
  let url = config.endpoint.article_manual + id + "/";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(SingleArticle(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(SingleArticle(error.response));
          } else {
            dispatch(SingleArticle({ status: 500 }));
          }
        }
      );
    });
  };
};

export const getArticleFullSingleList = (id) => {
  let url = config.endpoint.article_full + id + "/";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestArticleSingleAction());
      api.get(url).then(
        (res) => {
          dispatch(SingleArticle(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(SingleArticle(error.response));
          } else {
            dispatch(SingleArticle({ status: 500 }));
          }
        }
      );
    });
  };
};

export const articleFullUpdate = (id, payload) => {
  let url = config.endpoint.article_full + id + "/";
  return (dispatch) => {
    dispatch(loadingUpdateAction());

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (res) => {
          dispatch(SingleArticle(res));
          resolve(res);
        },
        (error) => {
          dispatch(cancelRequestAction());

          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(SingleArticle(error.response));
          } else {
            dispatch(SingleArticle({ status: 500 }));
          }
        }
      );
    });
  };
};
export const UpdateArticleRequired = (id, payload) => {
  let url = config.endpoint.article_required_field + id + "/";
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return api
        .put(url, payload)
        .then(
          (res) => {
            dispatch(
              SingleArticle(Object.assign(res.data, { status: res.status }))
            );
            resolve(res.data);
          },
          (error) => {
            dispatch(
              SingleArticle(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            resolve(error.response);
          }
        )
        .catch((error) => {
          dispatch(SingleArticle(Object.assign({ status: 500 })));
          reject(error);
        });
    });
  };
};

export const getArticleRequired = (id) => {
  let url = config.endpoint.article_required_field + id + "/";
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(SingleArticle(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(SingleArticle(error.response));
          } else {
            dispatch(SingleArticle({ status: 500 }));
          }
        }
      );
    });
  };
};

export const GetAllArticles = (payload) => {
  const url =
    config.endpoint.article_basic +
    "?page=" +
    payload.pageNum +
    "&page_size=" +
    payload.pageSize;

  return (dispatch) => {
    api.get(url).then(
      (res) => {
        dispatch(GetArticles(res.results));
      },
      (error) => {
        if (error.response) {
          delete error.config;
          delete error.headers;
          delete error.request;
          delete error.response.request;
          delete error.response.headers;
          delete error.response.config;
          dispatch(GetArticles(error.response));
        } else {
          dispatch(
            GetArticles({
              status: 500,
            })
          );
        }
      }
    );
  };
};

// Get Journalanization List
export const getAllBookList = (payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = payload.pageNum
        ? `${config.endpoint.book}?page=${payload.pageNum}`
        : `${config.endpoint.book}?${payload}`;
      dispatch(requestBookListAction());

      api
        .get(url)
        .then((res) => {
          dispatch(sucessBookListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(failedBooksListAction(e.response));
        });
    });
  };
};

export const deleteBookSingleItem = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.book + id + "/";

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getAllBookListDownload = () => {
  const url = `${config.endpoint.book}download/?fields=title,corresponding_author,author_email,author_department,author_affiliation,publication_type,content_type,article_id,journal,publisher_name,submission_date,acceptance_date,approved_date_date_only,licence_name,funder_info,grant_number,co_author,co_author_email,co_author_department,co_author_affiliation,doi,apc_gbp,apc_eur,apc_usd,submission_fee_gbp,submission_fee_eur,submission_fee_usd,colour_charge_gbp,colour_charge_eur,colour_charge_usd,page_charge_gbp,page_charge_eur,page_charge_usd,other_charge_gbp,other_charge_eur,other_charge_usd&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(AllBooksDownloadAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

export const getArticleManual = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  const url = `${config.endpoint.article_manual}?${
    params && params + "&"
  }${filter}`;

  return (dispatch) => {
    dispatch(articleManualRequestAction());
    api.get(url).then(
      (res) => {
        dispatch(articleSuccessManualAction(res));
      },
      (error) => {
        if (error.response) {
          delete error.config;
          delete error.headers;
          delete error.request;
          delete error.response.request;
          delete error.response.headers;
          delete error.response.config;
          dispatch(articleErrorRequestAction(error.response));
        }
      }
    );
  };
};

export const getApcArticleList = (id) => {
  const url = `${config.endpoint.article_full}?id=${id}&fields=id,apc_request,title,publisher,author,is_licence_edit_icon,selected_licence,status,status_label,approved_date,openaccess_type,payment_badge_text`;
  return (dispatch) => {
    dispatch(articleFetchRequest());
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(articleFetchSuccess(res));
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(articleFetchError(error.response));
          } else {
            dispatch(
              articleFetchError({
                status: 500,
              })
            );
          }
        }
      );
    });
  };
};

export const getArticleFullList = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  const id = filter ? filter.id : null;
  const url = id
    ? config.endpoint.article_full
    : `${
        config.endpoint.article_full
      }?fields=id,title,publisher,author,is_licence_edit_icon,selected_licence,status,status_label,apc_waived_text,is_book,department_missing,invoice_download,approved_date,openaccess_type&${
        params && params + "&"
      }${filter}`;
  return (dispatch) => {
    dispatch(articleFetchRequest());
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(articleFetchSuccess(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(articleFetchError(error.response));
          } else {
            dispatch(
              articleFetchError({
                status: 500,
              })
            );
          }

          reject(error);
        }
      );
    });
  };
};

// }?fields=id,title,publisher,author,is_licence_edit_icon,selected_licence,status,status_label&${params && params + "&"

export const getPublisherArticleFullList = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  const id = filter ? filter.id : null;
  const url = id
    ? config.endpoint.article_full
    : `${
        config.endpoint.article_full
      }?fields=id,title,publisher,author,apc_request,is_licence_edit_icon,selected_licence,status,status_label,approved_date,openaccess_type,payment_badge_text,apc_waived_text,invoice_download&${
        params && params + "&"
      }${filter}`;
  return (dispatch) => {
    dispatch(articleFetchRequest());
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(articleFetchSuccess(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(articleFetchError(error.response));
          } else {
            dispatch(
              articleFetchError({
                status: 500,
              })
            );
          }

          reject(error);
        }
      );
    });
  };
};
export const getFunders = () => {
  let url = config.endpoint.apc_funder;
  return () => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          resolve(res.results);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};

export const ReviewArticle = () => {
  return () => {
    let url = config.endpoint.article_reviewer;
    api.get(url).then(
      (res) => {},
      (error) => {}
    );
  };
};

export const CorrectionRequestAction = (payload) => {
  return () => {
    let url = config.endpoint.correction_request;
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
};

export const getCorrectionRequested = (payload) => {
  let url = config.endpoint.correction_request;
  return (dispatch) => {
    api.get(url).then(
      (res) => {
        dispatch(CorrectionRequest(res.results));
      },
      (error) => {
        if (error.response) {
          delete error.config;
          delete error.headers;
          delete error.request;
          delete error.response.request;
          delete error.response.headers;
          delete error.response.config;
          dispatch(CorrectionRequest(error.response));
        } else {
          dispatch(
            CorrectionRequest({
              status: 500,
            })
          );
        }
      }
    );
  };
};

export const getCorrectiondownload = () => {
  const url = `${config.endpoint.correction_request}download/?fields=request_date,request_to,article_title&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(CorrectiondownloadAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

export const getSingleCorrectionRequested = (id) => {
  let url = config.endpoint.correction_request + id;
  return (dispatch) => {
    api.get(url).then(
      (res) => {
        dispatch(CorrectionRequestSingle(res));
      },
      (error) => {
        if (error.response) {
          delete error.config;
          delete error.headers;
          delete error.request;
          delete error.response.request;
          delete error.response.headers;
          delete error.response.config;
          dispatch(CorrectionRequestSingle(error.response));
        } else {
          dispatch(
            CorrectionRequestSingle({
              status: 500,
            })
          );
        }
      }
    );
  };
};

export const getLicense = () => {
  return (dispatch) => {
    let url = config.endpoint.license;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(getAllLicence(res.results));
          resolve(res.results);
        },
        (error) => reject(error)
      );
    });
  };
};

export const getPublisherLicense = (payload) => {
  return (dispatch) => {
    let url =
      config.endpoint.pub_license + `?publisher_id=${payload.publisher_id}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => resolve(res.results),
        (error) => reject(error)
      );
    });
  };
};

// Get Journalanization List
export const getLicenseList = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.license;
    return new Promise((resolve, reject) => {
      api
        .get(url + "?&page_size=99999")
        .then((res) => {
          dispatch(getLicenseReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Journalanization List
export const getLicenseListNonOa = (payload) => {
  return (dispatch, getState) => {
    let url = config.endpoint.non_oa_license;
    return new Promise((resolve, reject) => {
      api
        .get(url + "?&page_size=99999")
        .then((res) => {
          dispatch(getLicenseNonOaReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getApcOption = () => {
  return (dispatch) => {
    let url = config.endpoint.apc_option;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => resolve(res.results),
        (error) => reject(error)
      );
    });
  };
};

export const getArticleApcOption = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.article_apc_option;
    if (payload.articleId) {
      url += `?article_id=${payload.articleId}`;
    }

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          resolve(res.results);
          dispatch(getArticleApcOptionSuccess(res.results));
        },
        (error) => reject(error)
      );
    });
  };
};

export const getArticleOfferDetails = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.article_full + id + "/offers/";
    dispatch(requestArticleOfferDetailsAction());

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
          dispatch(getArticleOfferDetailsAction(res));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const getApcFundSrc = () => {
  return (dispatch) => {
    let url = config.endpoint.apc_fund_source;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => resolve(res.results),
        (error) => reject(error)
      );
    });
  };
};

export const ArticleApprove = (payload) => {
  return () => {
    let url = config.endpoint.article_approve;
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => resolve({ status: res.status }),
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            reject(error.response);
          } else {
            reject({ status: 500 });
          }
        }
      );
    });
  };
};

export const ArticleReject = (payload) => {
  return () => {
    let url = config.endpoint.article_reject;
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => resolve({ status: res.status }),
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            reject(error.response);
          } else {
            reject({ status: 500 });
          }
        }
      );
    });
  };
};

export const getbookSearchListItem = (id) => {
  return (dispatch, getState) => {
    let url = config.endpoint.book + id + "/";

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
          dispatch(bookSearchList(res));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Mutate Article Price
export const mutateArticlePrice = (ids, action, data) => {
  return (dispatch) => {
    let url = config.endpoint.article_full_price + `${ids}/mutate/${action}/`;
    return new Promise((resolve, reject) => {
      api
        .put(url, data)
        .then((res) => {
          dispatch(mutateArticlePriceAction(res.data));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const emailLogHistory = (payload, name) => {
  let url = `${config.endpoint.email_log}?message_type=${name}&page=${payload.pageNum}&page_size=${payload.pageSize}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(auditEmailSucces(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(auditEmailError(e));
        });
    });
  };
};

export const emailLogDisplayHistory = (id) => {
  let url = `${config.endpoint.email_log}${id}`;
  return (dispatch) => {
    dispatch(articleEmailViewRequest());
    api.get(url).then(
      (res) => {
        dispatch(articleEmailViewSucces(res));
      },
      (error) => {}
    );
  };
};
export const articleActionButton = (payload) => {
  return () => {
    let url = config.endpoint.article_action_config;
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => resolve({ status: res.status }),
        (error) => {}
      );
    });
  };
};
export const articleActionUpdate = (id, payload) => {
  return () => {
    let url = `${config.endpoint.article_action_config}${id}/`;
    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (res) => resolve({ status: res.status }),
        (error) => {}
      );
    });
  };
};

export const articleSingleActionUpdate = () => {
  let url = `${config.endpoint.article_action_summery}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(articleButtonAction(res));
          resolve(res);
        },
        (error) => {
          if (error.response) {
            delete error.config;
            delete error.headers;
            delete error.request;
            delete error.response.request;
            delete error.response.headers;
            delete error.response.config;
            dispatch(articleButtonAction(error.response));
          } else {
            dispatch(articleButtonAction({ status: 500 }));
          }
        }
      );
    });
  };
};

export const ArticalEmailHistoryLog = (payload) => {
  return (dispatch) => {
    let url = `article-full/${payload}/timeline/`;
    dispatch(auditEmailRequest());

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(auditEmailSucces(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(auditEmailError(e));
          reject(e);
        });
    });
  };
};

export const auditEmailResend = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.email_resend}`;
    dispatch(auditEmailRequest());

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (res) => {
          dispatch(articleEmailResendAction(res));
          resolve(res);
        },
        (error) => {
          dispatch(auditEmailError(error));
        }
      );
    });
  };
};

export const articleButton = (id) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.article_full}${id}/actions/`;
    dispatch(requestArticleButtonAction());
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(articleButtonAction(res));
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// send free text email
export const searchArticleFull = (payload) => {
  let filter = "";

  if (payload.q) {
    filter += `&q=${payload.q}`;
  }

  return (dispatch) => {
    let url = `${config.endpoint.article_full}?fields=id,title&article_stage=POST_ACCEPTANCE${filter}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// article status
export const articleStatus = (id) => {
  return (dispatch) => {
    let url = `${config.endpoint.article_status}`;

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Mutate Article status
export const mutateArticleStatus = (ids, action, data) => {
  return (dispatch) => {
    let url = config.endpoint.article_full_status + `${ids}/mutate/${action}/`;

    return new Promise((resolve, reject) => {
      api
        .put(url, data)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
