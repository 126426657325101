import config, {
  ADD_UPDATE_APC_FUND,
  GET_APC_FUND_LIST,
  APC_FUND_SEARCH,
  DOWLOAD_APC_FUND
} from '../../config'
import { api } from '../../core/api';


export const getApcFundListReducer = (payload) => ({
  type: GET_APC_FUND_LIST,
  payload
})

export const addUpdateApcFundReducer = (payload) => ({
  type: ADD_UPDATE_APC_FUND,
  payload
})
export const ApcFunddownloadReducer = (payload) => ({
  type: DOWLOAD_APC_FUND,
  payload
})

export const ApcFundSearch = (payload) => ({
  type: APC_FUND_SEARCH,
  payload
})

// Get APC fund list
export const getApcFundList = (payload) => {
  let url = config.endpoint.apc_fund;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then((res) => {
        dispatch(getApcFundListReducer(res))
        resolve(Object.assign(res, { status: true }));
      }).catch(function (error) {
        dispatch(getApcFundListReducer(error.response))
        resolve(Object.assign(error.response, { status: false }));
      })
    })
  }
}
export const getApcdownloadFundList = () => {
  const url = `${config.endpoint.apc_fund}download?fields=organisation,department,name,currency_name,amount,date&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: 'blob',
    };
    return new Promise((resolve, reject) => {
      api.get(url, token, options).then((res) => {
        dispatch(ApcFunddownloadReducer(res));
        resolve(Object.assign(res, { status: true }));
      }).catch(function (error) {
        reject(Object.assign(error.response, { status: false }));
      })
    })
  }
}

// Add & update APC fund 
export const addApcFund = (payload) => {
  let url = config.endpoint.apc_fund;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.post(url, payload).then((res) => {
        dispatch(addUpdateApcFundReducer(res))
        resolve(Object.assign(res, { status: true }));
      }).catch(function (error) {
        reject(error.response);
      })
    })
  }
}


export const addApcFundSearchList = (payload) => {
  let url = config.endpoint.apc_fund;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.post(url, payload).then((res) => {
        dispatch(ApcFundSearch(res))
        resolve(Object.assign(res, { status: true }));
      }).catch(function (error) {
        reject(error.response);
      })
    })
  }
}


