import React from "react";
import "antd/dist/antd.css";
import { Table } from "antd";

class ViewVatTable extends React.Component {
  render() {
    const { data, field, viewTable } = this.props;
    const rest = [
      {
        title: "Country",
        dataIndex: "Country",
        width: 150,
        key: 'Country',

      },
    ];
    const headingforca = [
      {
        title: "Country",
        dataIndex: "Country",
        width: 150,
        key: 'Country',

      },
      {
        title: "PST Rate",
        dataIndex: "PST Rate",
        width: 150,
        key: 'PST Rate',

      },
      {
        title: "States",
        dataIndex: "States",
        width: 200,
        key: 'States',

      },
      {
        title: "State Rate",
        dataIndex: "State Rate",
        key: 'State Rate',

      },
      {
        title: "Total %",
        dataIndex: "Total %",
        key: 'Total %',

      },
      {
        title: "Set your own VAT rate %",
        dataIndex: "vat_rate",
        key: 'vat_rate',

      },
    ];
    const headingforus = [
      {
        title: "Country",
        dataIndex: "Country",
        width: 150,
        key: 'Country',

      },
      {
        title: "Federal Rate",
        dataIndex: "Federal Rate",
        width: 150,
        key: 'Federal Rate',

      },
      {
        title: "States",
        dataIndex: "States",
        width: 200,
        key: 'States',

      },
      {
        title: "State Rate",
        dataIndex: "State Rate",
        key: 'State Rate',

      },
      {
        title: "Total %",
        dataIndex: "Total %",
        key: 'Total %',

      },
      {
        title: "Set your own VAT rate %",
        dataIndex: "vat_rate",
        key: 'vat_rate',

      },
    ];
    const taxescolumns = [
      {
        title: "Country",
        dataIndex: "Country",
        width: 150,
        key: 'Country',

      },
      {
        title: "Default VAT %",
        dataIndex: "Default VAT %",
        width: 150,
        key: 'Default VAT %',

      },
      {
        title: "Set your own VAT rate %",
        dataIndex: "vat_rate",
        key: 'vat_rate',

      },
    ];
    const showTable = [
      {
        title: "Country",
        dataIndex: "Country",
        width: 150,
        key: 'Country',

      },
      {
        title: "Default VAT %",
        dataIndex: "Default VAT %",
        width: 150,
        key: 'Default VAT %',

      },
      {
        title: "Set your own VAT rate %",
        dataIndex: "vat_rate",
        width: 150,
        key: 'vat_rate',

      },
    ];
    const columns = [
      {
        title: "Country",
        dataIndex: "Country",
        width: 150,
        key: 'Country',

      },
      {
        title: "Default VAT %",
        dataIndex: "Default VAT %",
        width: 150,
        key: 'Default VAT %',

      },
      {
        title: "Set your own VAT rate %",
        dataIndex: "vat_rate",
        key: 'vat_rate',

      },
    ];
    return (
      <Table
        columns={
          field === "USA"
            ? headingforus
            : field === "CA"
            ? headingforca
            : field === "REST"
            ? rest
            : field === "RESTOFTHEWORLD"
            ? taxescolumns
            : viewTable === "view"
            ? showTable
            : columns
        }
        dataSource={data}
        // pagination={
        //   pagination
        //     ? { pageSize: null, hideOnSinglePage: true }
        //     : { pageSize: 3 }
        // }
      />
    );
  }
}

export default ViewVatTable;
