import React, { Component } from 'react';
import { HubContent } from '../../../../../core/components';
import { Collapse, Button } from 'antd'
import styled from 'styled-components';



const { Panel } = Collapse;



class UserHelpComponent extends Component {
    state = {
        questions: [
            {
                id: 1,
                value: "How to create an author account in Oametrix?",
                buttons: [
                    { pdfbtn: "Researchers Registration", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Registration.pdf" },
                ]
            },
            {
                id: 2,
                value: "How do I login to OaMetrix with ORCID?",
                buttons: [
                    { pdfbtn: "Login with ORCID", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Login-with-Orcid.pdf" },
                ]
            },
            {
                id: 3,
                value: "How do I submit my APC funding request?",
                buttons: [
                    { pdfbtn: "APC Funding Request", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/APC-Funding-REquest.pdf" },
                ]
            },
            {
                id: 6,
                value: "How do I make sure my society fellowship discount will be applied to the APC?",
                buttons: [
                    { pdfbtn: "Check Society Fellowship Discount", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Society-Fellowship-Discount.pdf" },
                ]
            },
            {
                id: 5,
                value: "How do I pay my APC online?",
                buttons: [
                    { pdfbtn: "Pay APC Online", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Pay-APC-Online.pdf" },
                ]
            },

            {
                id: 4,
                value: "How do I generate a self-service invoice for my APC?",
                buttons: [
                    { pdfbtn: "Generate Self-Service Invoice", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/author-user-guide/Generate-Self-Service-Invoice.pdf" },
                ]
            },


        ]
    };

    render() {

        return (
            <HubContent>

                <div className="row">

                    {this.state.questions.map(ques =>
                        <div key={ques.id} className="col-md-6">
                            <Collapse className="my-3" accordion>
                                <Panel className="mb-2" header={ques.value}>
                                    <div className="buttons__area">
                                        {ques.buttons.map(btn => <span key={btn.link}>
                                            <a href={btn.link} target="_blank" rel="noopener noreferrer">

                                                <Button type="link" className="mx-2">
                                                    <PdfIcon className="mr-2"><i className="fas fa-file-pdf"></i></PdfIcon>
                                                    {btn.videobtn}

                                                    {btn.pdfbtn}

                                                    {btn.pptbtn}
                                                </Button>
                                            </a>
                                        </span>)}
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    )}




                </div>

            </HubContent>
        );
    }
}

export const UserHelpMenu = UserHelpComponent;



const PdfIcon = styled.span`
    i.fa-file-pdf{
        color:#171a1d;
    }
`;