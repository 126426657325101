import config, {
  GET_ORGS_TOKEN_SEARCH_LIST,
  CLEAR_TOKEN,
  CREATE_OA_TOKEN,
  GET_ALLOCATED_TOKEN_SEARCH_LIST,
  GET_TOKEN_SEARCH_LIST,
  APPLY_TOKEN,
  CREATE_AND_APPLY_TOKEN,
  GET_TOKEN_AUTHOR_ARTICLE,
  GET_TOKEN_ORGS_DOWNLOAD,
  GET_ORGS_DOWNLOAD,
  CREATE_ORGANISATION_TOKEN,
  GET_TOKEN_DOWNLOAD,
  ORGANISATION_OFFSET_FUND,
  GET_OFFSET_FUND,
  GET_ORGANISATION_TOKEN,
  GET_OA_TOKEN,
  DELETE_OA_TOKEN,
  FAILED_DELETE_OA_TOKEN,
  ERROR_MESSAGE,
  REQUEST_CREATE_TOKEN,
} from "../../config";
import { api } from "../../core/api";

// Loaded into App Reducers
export const createOaToken = (payload) => ({
  type: CREATE_OA_TOKEN,
  payload,
});

export const applyTokenReducer = (payload) => ({
  type: APPLY_TOKEN,
  payload,
});

export const createAndApplyTokenAction = (payload) => ({
  type: CREATE_AND_APPLY_TOKEN,
  payload,
});

export const clearTokenAction = (payload) => ({
  type: CLEAR_TOKEN,
  payload,
});

export const createOrganisationToken = (payload) => ({
  type: CREATE_ORGANISATION_TOKEN,
  payload,
});

export const createOffsetFund = (payload) => ({
  type: ORGANISATION_OFFSET_FUND,
  payload,
});

export const getOffsetFund = (payload) => ({
  type: GET_OFFSET_FUND,
  payload,
});

export const getOrgToken = (payload) => ({
  type: GET_ORGANISATION_TOKEN,
  payload,
});

export const getOaTokenReducer = (payload) => ({
  type: GET_OA_TOKEN,
  payload,
});

export const deleteOaTokenReducer = (payload) => ({
  type: DELETE_OA_TOKEN,
  payload,
});

export const failedDeleteOaTokenReducer = (payload) => ({
  type: FAILED_DELETE_OA_TOKEN,
  payload,
});

export const failedResponse = (payload) => ({
  type: ERROR_MESSAGE,
  payload,
});

export const getOaTokenDownload = (payload) => ({
  type: GET_TOKEN_DOWNLOAD,
  payload,
});
export const getOrgsDownload = (payload) => ({
  type: GET_ORGS_DOWNLOAD,
  payload,
});
export const OrganisationTokentDwAction = (payload) => ({
  type: GET_TOKEN_ORGS_DOWNLOAD,
  payload,
});

export const authorArticle = (payload) => ({
  type: GET_TOKEN_AUTHOR_ARTICLE,
  payload,
});
export const tokenSearchList = (payload) => ({
  type: GET_TOKEN_SEARCH_LIST,
  payload,
});

export const allocatedOffsetSearch = (payload) => ({
  type: GET_ALLOCATED_TOKEN_SEARCH_LIST,
  payload,
});

export const createOffsetFundSearchList = (payload) => ({
  type: GET_ORGS_TOKEN_SEARCH_LIST,
  payload,
});

export const requestCreateToken = (payload) => ({
  type: REQUEST_CREATE_TOKEN,
  payload,
});

// Create OA Token
export const generateOaToken = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("oa-token/", payload)
        .then((res) => {
          dispatch(createOaToken(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create Organisation Token
export const createOrganisationTokenAction = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.create_organisation_token;
    return new Promise((resolve, reject) => {
      dispatch(requestCreateToken());
      api
        .post(url, payload)
        .then((res) => {
          dispatch(createOrganisationToken(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create Organisation Token
export const generateOrganisationToken = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("organisation-token/", payload)
        .then((res) => {
          dispatch(createOrganisationToken(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
// Allocate Offset Fund
export const allocatOrganisationOffsetFund = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("organisation-offset-fund/", payload)
        .then((res) => {
          dispatch(createOffsetFund(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(failedResponse(e.response.data));
          reject(e);
        });
    });
  };
};

// Get Offset Fund
export const getOrganisationOffsetFund = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          "organisation-offset-fund/?page=" +
            payload.pageNum +
            "&page_size=" +
            payload.pageSize
        )
        .then(
          (res) => {
            dispatch(getOffsetFund(res));
            resolve(Object.assign(res, { status: true }));
          },
          (error) => {
            reject(Object.assign(error, { status: false }));
          }
        );
    });
  };
};

// Get Organisation Token List
export const getOrganisationToken = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  let url = `organisation-token/?${params && params + "&"}${filter}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgToken(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getOrganisationSingleToken = (id) => {
  let url = `organisation-token/${id}/`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOrgToken(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getOrganisationTokenDownloadlist = () => {
  const url = `${config.endpoint.orgs_token_download}?fields=publisher_name,institution_name,reference,token_id,currency,amount,valid_from,valid_to,status&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(OrganisationTokentDwAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Get OA Token List
export const getOaToken = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("oa-token/?page=" + payload.pageNum)
        .then((res) => {
          dispatch(getOaTokenReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getPublisherOaToken = (payload = {}) => {
  return (dispatch) => {
    const params = payload ? payload.params || "" : "";
    const filter = payload ? payload.filter || "" : "";
    let url = `${config.endpoint.oa_token}?${params && params + "&"}${filter}`;
    dispatch(requestCreateToken());
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getOaTokenReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get Organisation Token List
export const getOaTokenisActive = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get("oa-token/?is_assigned=0")
        .then((res) => {
          dispatch(getOaTokenReducer(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const deleteOaToken = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return api
        .delete("oa-token/" + id + "/")
        .then(
          (res) => {
            dispatch(deleteOaTokenReducer(id));
            resolve({ status: true });
          },
          (error) => {
            dispatch(
              failedDeleteOaTokenReducer(
                error.response
                  ? Object.assign(error.response.data, {
                      status: error.response.status,
                    })
                  : { status: 500 }
              )
            );
            resolve({ status: false });
          }
        )
        .catch(reject);
    });
  };
};

// Create Organisation Token
export const applyTokenAction = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.apply_token;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(applyTokenReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Create and apply Token
export const createAndApplyToken = (payload, id) => {
  return (dispatch) => {
    let url = config.endpoint.create_and_apply_token;

    let apiRequest = "";

    return new Promise((resolve, reject) => {
      if (id) {
        url += `${id}/`;

        apiRequest = api.put(url, payload);
      } else {
        apiRequest = api.post(url, payload);
      }

      apiRequest
        .then((res) => {
          dispatch(createAndApplyTokenAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Clear Applied Token
export const clearToken = (payload = {}) => {
  return (dispatch) => {
    let url = config.endpoint.clear_token + "?silent=true";
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(clearTokenAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getOaTokenisActiveDownload = () => {
  const url = `${config.endpoint.oa_token_download}?fields=publisher_name,institution_name,reference,token_id,currency,amount,valid_from,valid_to,assign_status,used_status&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(getOaTokenDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};
export const getOrganisationTokenDownload = () => {
  const url = `${config.endpoint.orgs_offset_token_download}?fields=institution_name,publisher_name,currency,amount,valid_from,valid_to&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(getOrgsDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

export const getAuthorArticleToken = (payload) => {
  const url = `${config.endpoint.article_author}?fields=id,name&page_size=9999999`;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(authorArticle(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};
export const getAuthorArticleSearchQueryToken = (payload) => {
  const url = `${config.endpoint.article_author}?fields=id,name&q=${payload}`;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(authorArticle(res.results));
          resolve(Object.assign(res.results, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};

export const getTokenSearchList = (payload) => {
  const url = `${config.endpoint.article_author}`;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(tokenSearchList(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};

export const getAllocatedOffsetSearchList = (payload) => {
  const url = `${config.endpoint.article_author}`;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (res) => {
          dispatch(allocatedOffsetSearch(res));
          resolve(Object.assign(res, { status: true }));
        },
        (error) => {
          reject(Object.assign(error, { status: false }));
        }
      );
    });
  };
};

export const OrganisationSearchListOffsetFund = (payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .post("organisation-offset-fund/", payload)
        .then((res) => {
          dispatch(createOffsetFundSearchList(res.results));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          dispatch(failedResponse(e.response.data));
          reject(e);
        });
    });
  };
};
