import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../core/components";
import { Loading } from "../../../../core/components/common";

import { publisherGetAllVatCreateConfigure } from "../../../actions";
import { userKey, userKeyPermission } from "../../common/userPermission";
import HubSetting from "./HubSetting/HubSettings";
class SettingPage extends BaseComponent {
  state = {};
  componentDidMount() {
    if (this.props.group === "PUBLISHER") {
      this.props.publisherGetAllVatCreateConfigure();
    }
  }

  render() {
    
    let results;
    if (this.props.auth.permissions) {
      results = userKeyPermission.filter(
        (item) =>
          this.props.auth.permissions &&
          this.props.auth.permissions.includes(item)
      );
    }
    let compontentList = [{
      icon:"mdi mdi-checkbox-marked-circle-outline",
      key: userKey.manage_meta_transfer,
      label:"Article Metadata Transfer ",
      paragraph: "Configure to fetch article metadata from submission system",
      link:"/settings/publisher-article-transfer-config"
    },{
      icon:"mdi mdi-table-edit",
      key: userKey.manage_config_dashboard,
      label:"Dashboard Configuration",
      paragraph: "Customise your dashboard",
      link:"/settings/publisher-dashboard-control"
      },
      {
        icon:"mdi mdi-clipboard-text",
        key: userKey.manage_invoice,
        label:"Invoice & Payment reminder",
        paragraph: "Set up & customise your invoices",
        link:"/settings/invoice-configuration"
        },
      
        {
          icon:"mdi mdi-bell-outline",
          key: "manage_metadata_author_notification" ,
          label:"Author Notification & License Req Setup",
          paragraph: "Configure notification & license requirements",
          link:"/settings/author-notification-list"
          },
        
          {
            icon:"mdi mdi-briefcase-check",
            key: userKey.manage_licence_control,
            label:"License",
            paragraph:"Manage your Open Access licenses",
            link:"/settings/license-configuration"
            },
          
            {
              icon:"mdi mdi-email",
              key: userKey.manage_emails,
              label:"Email Configuration",
              paragraph: "Create & manage emails",
              link:"/settings/email-configuration"
              },
              {
                icon:"mdi mdi-water-percent",
                key: userKey.manage_apc_waiver,
                label:"Zero APC Journal Configuration",
                paragraph: "Add or remove Zero APC Journals",
                link:"/settings/apc-config-list"
      },
      {
        icon:"mdi  mdi-clipboard",
        key: userKey.manage_waiver,
        label:"Emerging Countries APC Waiver",
        paragraph: "Author Notification for APC Wavier",
        link:"/settings/waiver-policy"
      },
      {
        icon:"mdi mdi-file-xml",
        key: userKey.manage_terms,
        label:"Terms & Conditions",
        paragraph: "Create Terms & Conditions",
        link:"/settings/termsconditions-configuration"
      },
      {
        icon:"mdi mdi-target",
        key: userKey.manage_society_discount,
        label:"Society Discount",
        paragraph: "Manage Society discounts",
        link:"/settings/society-discount-list"
      },
      {
        icon:"mdi mdi-percent",
        key: userKey.manage_discount,
        label:" Geographical Discount",
        paragraph: "Manage Geographical discounts",
        link:"/settings/discount-list/"
      },
      {
        icon:"mdi mdi-account-multiple",
        key: userKey.manage_membership_discount,
        label:"Membership Discount",
        paragraph: "Manage Membership discounts",
        link:"/membership-discounts/member-discount-list/"
      },
      {
        icon:"mdi mdi-apps",
        key: userKey.manage_apps,
        label:"Apps",
        paragraph: "Enable and manage your connected Apps",
        link:"/settings/apps"
      },
      {
        icon:"mdi mdi-application",
        key: userKey.manage_users,
        label:"Users",
        paragraph: "Create & manage your users",
        link:"/pub-user/user-list"
      },
    
    ]

     return (
      <HubContent title="Settings" className="setting-heading">
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20 settings-card">
              <div className="card-body">
                {this.props.group === "PUBLISHER" ? (
                  <div>
                    <div className="row">
                    
                      {
                        compontentList.map(item=>results && results.includes(item.key)?<div className="col-md-4 col-sm-6" key={item.key}>
                          <div className="single-setting" >
                          <Link to={item.link}>
                            <div className="setting-icon">
                              <i className={item.icon}></i>
                            </div>
                            <div className="setting-info">
                              <h4>{item.label}</h4>
                              <p>
                                {item.paragraph}
                              </p>
                            </div>
                          </Link>
                        </div>
                          </div>
  : null )
                      }
{       results &&  results.includes(userKey.manage_vat)  &&                   
   <div
          className="col-md-4 col-sm-6"
          key={`${userKey.manage_vat}`}
        >
                <div className="single-setting">
            
            {this.props.isLoading !==true ? this.props.vat_publisher_list &&
              this.props.vat_publisher_list.count > 0 ? (
                <Link to="/settings/vat-configuration">
                  <div className="setting-icon">
                    <i className="mdi mdi-open-in-app"></i>
                  </div>
                  <div className="setting-info">
                    <h4>VAT Configuration</h4>
                    <p>Manage your global VAT & Taxes</p>
                  </div>
                </Link>
              ) : (
                <Link to="/settings/create-vat-configuration">
                  <div className="setting-icon">
                    <i className="mdi mdi-open-in-app"></i>
                  </div>
                  <div className="setting-info">
                    <h4>VAT Configuration</h4>
                    <p>Manage your global VAT & Taxes</p>
                  </div>
                </Link>
              ):<Loading type="flat"/>}
          </div>
        </div>}
                       {
       results &&  results.includes(userKey.manage_btn_labels)  &&                   
        <div className="col-md-4 col-sm-6" key={userKey.manage_btn_labels}>
      <div className="single-setting">
        {this.props.auth.article_action_config ? (
          <Link
            to={`/settings/article-action/${this.props.auth.article_action_config}`}
          >
            <div className="setting-icon">
            <i className="mdi mdi-settings-box"></i>
            </div>
            <div className="setting-info">
              <h4>Button label config</h4>
              <p>
                View and update your button label
                configuartion
              </p>
            </div>
          </Link>
        ) : (
            <Link to={`/settings/article-action`}>
              <div className="setting-icon">
                
                <i className="mdi mdi-settings-box"></i>
              </div>
              <div className="setting-info">
                <h4>Button Config</h4>
                <p>View and update your organisation details</p>
              </div>
            </Link>
          )}
      </div>
    </div>}
           
  <div className="col-md-4 col-sm-6" >
                        <div className="single-setting">
                          <Link
                            to={`/settings/profile/${this.props.user.organisation_id}`}
                          >
                            <div className="setting-icon">
                              <i className="mdi mdi-account"></i>
                            </div>
                            <div className="setting-info">
                              <h4>Organisation</h4>
                              <p>View and update your organisation details</p>
                            </div>
                          </Link>
                        </div>
                      </div>
  
                    </div>
                  </div>
                ) : this.props.group === "HUB"? (
                  <HubSetting />
                ) : (
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <div className="single-setting">
                            <Link
                              to={`/settings/profile/${this.props.user.organisation_id}`}
                            >
                              <div className="setting-icon">
                              </div>
                              <div className="setting-info">
                                <h4>Organisation</h4>
                                <p>View and update your organisation details.</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="single-setting">
                            <Link to="/vat/addvat">
                              <div className="setting-icon">
                              </div>
                              <div className="setting-info">
                                <h4>VAT & Taxes</h4>
                                <p>View and update your VAT & Tax number.</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="single-setting">
                            <Link to="/users/user-list">
                              <div className="setting-icon">
                              </div>
                              <div className="setting-info">
                                <h4>Users</h4>
                                <p>View users</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}
const mapStateToProps = (state) => ({
  group: state.auth.user && state.auth.group,
  user: state.auth.user ,
  auth: state.auth,
  vat_publisher_list: state.vat.vat_publisher_list,
  isLoading: state.vat.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  publisherGetAllVatCreateConfigure: () =>
    dispatch(publisherGetAllVatCreateConfigure()),
});

export const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingPage);