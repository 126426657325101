import React from "react";
import { AutoComplete } from "antd";

import moment from "moment";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  InputBox,
  SelectBox,
  OaDatePicker,
  Button,
  PopupBox,
  ModalSelectBox,
  SearchLoader,
  ToolTipField
} from "../../../../../core/components/common";
import { connect } from "react-redux";
import { serialize } from "../../../../../core/lib";
import {
  getAllCurrencyList,
  getPublicatonTypeList,
  getPublicationList,
  getContentTypeList,
  getOaDisciplineList,
  getOaSubjectList,
  getInstitutionDataLists,
  getMembershipDiscount,
  createUpdateMembershipDiscount,
} from "../../../../actions";

const format = "Do MMM YYYY";

const offerTypes = [
  { id: "DISCOUNT", name: "Discount" },
  { id: "AMOUNT", name: "Amount" },
  { id: "SPECIAL_APC", name: "Special APC" },
];

class MemberShip extends BaseComponent {
  state = {
    societyDiscount: {},
  };

  getPublicationListOnParameterChange = async () => {
    let payload = {};
    let filterParams = {
      page_size: 999999,
    };
    if (
      this.state.societyDiscount.publication_types &&
      this.state.societyDiscount.publication_types.length > 0
    ) {
      filterParams.publication_type_ids =
        this.state.societyDiscount.publication_types[0] !== "all"
          ? this.state.societyDiscount.publication_types.join(",")
          : "";
    }

    if (this.state.disciplines && this.state.disciplines.length > 0) {
      filterParams.discipline_ids =
        this.state.disciplines[0] !== "all"
          ? this.state.disciplines.join(",")
          : "";
    }

    if (this.state.subjects && this.state.subjects.length > 0) {
      filterParams.subject_ids =
        this.state.subjects[0] !== "all" ? this.state.subjects.join(",") : "";
    }

    payload.filter = serialize(filterParams);
    await this.props.getPublicationList(payload);

    this.props.publication_list &&
      this.setState({
        publication_list: this.props.publication_list.results,
      });
  };

  onChangeHandler = async (value) => {
    if (value.hasOwnProperty("offer_type")) {
      this.setState({
        ...value,
      });
    }

    this.setState(
      (prevState) => {
        if (value.offer_expiry) {
          value.offer_expiry = moment(value.offer_expiry).format("YYYY-MM-DD");
        }

        Object.assign(prevState.societyDiscount, value);
      },
      () => {
        if (value.hasOwnProperty("publication_types")) {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  async componentDidMount() {
    this.props.getOaDisciplineList();
    this.props.getAllCurrencyList();
    this.props.getPublicatonTypeList();
    this.props.getContentType();

    let payload = {};
    await this.props.getOaSubjectList(payload);

    this.props.oa_subjects &&
      this.setState({
        oa_subject_list: this.props.oa_subjects.results,
      });

    this.getPublicationListOnParameterChange();
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
  };

  onSaveSelectedItems = (fieldName, data) => {
    this.setState(
      {
        [fieldName]: data,
      },
      async () => {
        this.setState((prevState) => {
          Object.assign(prevState.societyDiscount, {
            [fieldName]: data.length > 0 && data[0] !== "all" ? data : [],
          });
        });

        if (fieldName === "disciplines") {
          if (data.length > 0) {
            let payload = {
              discipline: data[0] !== "all" ? data.join(",") : "",
            };

            await this.props.getOaSubjectList(payload);

            this.props.oa_subjects &&
              this.setState({
                oa_subject_list: this.props.oa_subjects.results,
              });
          }

          this.getPublicationListOnParameterChange();
        } else if (fieldName === "subjects") {
          this.getPublicationListOnParameterChange();
        }
      }
    );
  };

  onModalSelectBoxShow = (fieldName) => {
    switch (fieldName) {
      case "disciplines":
        this.setState({
          isShowDisciplineModal: true,
        });
        break;
      case "subjects":
        this.setState({
          isShowSubjectModal: true,
        });
        break;
      case "publications":
        this.setState({
          isShowPublicationModal: true,
        });
        break;
      case "content_types":
        this.setState({
          isShowContentTypeModal: true,
        });
        break;
      default:
        break;
    }
  };

  onModalSelectBoxClose = (e) => {
    this.setState({
      isShowDisciplineModal: false,
      isShowSubjectModal: false,
      isShowPublicationModal: false,
      isShowContentTypeModal: false,
    });
  };

  showSelectItemsLabel = (fieldName) => {
    let fieldValues = this.state[fieldName];
    let labelText = "";
    switch (fieldName) {
      case "disciplines":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} discipline selected`
              : `${fieldValues.length} disciplines selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Disciplines"
              : "Select Discipline";
        }
        break;
      case "subjects":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} subject selected`
              : `${fieldValues.length} subjects selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Subjects"
              : "Select Subject";
        }
        break;
      case "publications":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} Publication type selected`
              : `${fieldValues.length} Publication types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Publications type selected"
              : "Select Publications Types";
        }
        break;
      case "content_types":
        if (fieldValues && fieldValues.length > 0) {
          labelText =
            fieldValues.length === 1
              ? `${fieldValues.length} content type selected`
              : `${fieldValues.length} content types selected`;
        } else {
          labelText =
            fieldValues && fieldValues.length === 0
              ? "All Content types"
              : "Select Content type";
        }
        break;
      default:
        break;
    }

    return labelText;
  };


  onSubmitHandler = async (e) => {

    e.preventDefault();
    let data = {};
    Object.assign(data, {
      ...this.state.societyDiscount,
      society: this.state.society,
    });

    if (
      !data.membership_fees_currency ||
      !data.membership_fees ||
      !data.offer_type ||
      !data.offer_amount
    ) {
      this.setState({
        isEmpty: (
          <div>
            {!data.membership_fees_currency && <p>Currency must be selected</p>}
            {!data.membership_fees && (
              <p>Membership fee is required </p>
            )}
            {!data.offer_type && <p>Offer Type must be selected</p>}
            {!data.offer_amount && <p>Offer Amount is required</p>}
          </div>
        ),
      });
    } else {
      await this.props.createUpdateMembershipDiscount(data);
      this.props.history.push("/membership-discounts/member-discount-list/");
    }

  };

  isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  onSearch = (value) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.props.getInstitutionDataLists(value);
      this.setState({
        InstList:
          this.props.institute_list &&
          this.props.institute_list.map((item) => ({
            id: item.id,
            value: item.name
          }))
      });
    }, 200);
  };

  onSelect = (value, item) => {
    if (this.isNumber(item.id)) {
      this.setState({
        society: item.id,
        orgsError: false
      });
    }
  };
  onChange = (value) => {
    let data = this.props.institute_list && this.props.institute_list.find(item => item.name === value)
    if (this.isNumber(data && data.id)) {
      this.setState({
        society: data && data.id,
        orgsError: false,
      });
    } else {
      this.setState({
        orgsError: true
      });
    }
  };

  render() {
    const currency = [];
    const currency_2 = [];

    this.props.currency_list &&
      this.props.currency_list.map((item) =>
        currency.push({
          id: item.id,

          name:
            item.name === "British Pound Sterling"
              ? "GBP"
              : item.name === "Euro"
                ? "EUR"
                : "USD",
        })
      );

    this.props.currency_list &&
      this.props.currency_list.map((item) =>
        currency_2.push({
          id: item.id,
          name:
            item.name === "British Pound Sterling"
              ? "GBP"
              : item.name === "Euro"
                ? "EUR"
                : "USD",
        })
      );

    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent className="mb-5">
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.push("/membership-discounts/member-discount-list/")}
                />
              </div>
              <div className="card-body membership">

                <div className="tab-content">


                  <form action="">
                    <div className="form-group row auto-complete-search">
                      <div className="col-form-label col-sm-3 col-lg-2">
                        <label>Institution<span className="label-icon-red">*</span></label>
                        <ToolTipField labelInfoTitle="Type your full institution name and select from the drop down" />

                      </div>


                      <div className="col-sm-9 col-lg-10">
                        <AutoComplete
                          value={this.state.value}
                          options={this.state.InstList} π
                          style={{
                            width: "100%",
                          }}
                          onSelect={(val, option) => this.onSelect(val, option)}
                          onSearch={this.onSearch}
                          onChange={this.onChange}
                          placeholder="Type your full institution name and select from the drop down"
                        />
                        <div className="search-auto-spin">
                          {this.props.loading && <SearchLoader />}
                        </div>

                        {this.state.orgsError
                          && <span style={{ color: "red" }}>Please make sure  you have selected institution name from dropdown.
</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <SelectBox

                        label="Membership types"
                        onChange={this.onChangeHandler}
                        field="membership_types"
                        className="col-sm-8 col-md-3 mb-sm-3 col-lg-4 mb-md-0"
                        labelClass="col-sm-3 col-md-3 col-lg-2"
                        data={[
                          { id: 1, name: "Standard" },
                          { id: 2, name: "Premium" },
                        ]}
                        defaultValue={this.state.membership_types}
                        isRequired={false}
                        multiple={false}
                      />
                      <SelectBox
                        label="Publication types"
                        onChange={this.onChangeHandler}
                        field="publication_types"
                        className="col-sm-8 col-md-3 col-lg-4"
                        labelClass="col-sm-3 col-md-3 col-lg-2"
                        data={this.props.publication_type}
                        defaultValue={this.state.publication_types}
                        isRequired={false}
                        multiple={true}
                      />
                    </div>

                    <div className="form-group row">

                      <div className="col-12 col-sm-6 col-md-4 model-class py-3 no-color">
                        <label>  {this.showSelectItemsLabel("disciplines")}  </label>
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("disciplines")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowDisciplineModal && (
                        <ModalSelectBox
                          title="Select disciplines"
                          label="Disciplines"
                          field="disciplines"
                          className="col-sm-9"
                          data={
                            this.props.oa_disciplines && [
                              { id: "all", name: "All disciplines" },
                              ...this.props.oa_disciplines.results,
                            ]
                          }
                          defaultValue={this.state.disciplines}
                          isRequired={false}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                      <div className="col-12 col-sm-6 col-md-4 model-class py-3 no-color">
                        <label>  {this.showSelectItemsLabel("subjects")}  </label>
                        <button
                          onClick={() => this.onModalSelectBoxShow("subjects")}
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                      {this.state.isShowSubjectModal && (
                        <ModalSelectBox
                          title="Select subjects"
                          label="Subjects"
                          field="subjects"
                          className="col-sm-9"
                          data={
                            (this.state.oa_subject_list && [
                              { id: "all", name: "All subjects" },
                              ...this.state.oa_subject_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.subjects}
                          isRequired={true}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}


                      <div className="col-12 col-sm-6 col-md-4 model-class py-3 no-color">
                        <label> {this.showSelectItemsLabel("content_types")} </label>
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("content_types")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowContentTypeModal && (
                        <ModalSelectBox
                          title="Select content types"
                          label="Content types"
                          field="content_types"
                          className="col-sm-9"
                          data={
                            (this.props.content_type && [
                              { id: "all", name: "All content types" },
                              ...this.props.content_type,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.content_types}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}        </div>
                    <div className="form-group row">

                      <div className="col-12 col-sm-6 col-md-4 model-class py-3 no-color">
                        <label> {this.showSelectItemsLabel("publications")}</label>
                        <button
                          onClick={() =>
                            this.onModalSelectBoxShow("publications")
                          }
                          className="btn btn-info waves-effect waves-light float-right"
                          type="button"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>

                      {this.state.isShowPublicationModal && (
                        <ModalSelectBox
                          title="Select publications"
                          label="Publications"
                          field="publications"
                          className="col-sm-9"
                          data={
                            (this.state.publication_list && [
                              { id: "all", name: "All publications" },
                              ...this.state.publication_list,
                            ]) ||
                            []
                          }
                          defaultValue={this.state.publications}
                          submitBtnTxt="Save"
                          onSubmit={this.onSaveSelectedItems}
                          onClose={() => this.onModalSelectBoxClose()}
                        />
                      )}
                    </div>
                    <div className="form-group row">
                      <SelectBox
                        label="Currency"
                        className="col-sm-8 col-md-3 col-lg-4 pb-sm-3 mb-md-0"
                        labelClass="col-sm-3 col-md-3 col-lg-2"
                        placeholder="Select your Currency"
                        onChange={this.onChangeHandler}
                        field="membership_fees_currency"
                        labelInfo={true}
                        labelInfoTitle="Select your Currency"
                        data={currency}
                        isRequired={false}
                        defaultValue={this.state.membership_fees_currency}
                      />

                      <InputBox
                        className="col-sm-8 col-md-3 col-lg-4"
                        labelClass="col-sm-3 col-md-3 col-lg-2"
                        label="Membership fee"
                        labelInfo={true}
                        labelInfoTitle="Enter  membership fee"
                        onChange={this.onChangeHandler}
                        field="membership_fees"
                        placeholder="Enter membership fee"
                        defaultValue={this.state.membership_fees}
                        InputType="number"
                      />
                    </div>
                    <div>
                      <div className="form-group row memberhsip">
                        <SelectBox
                          label="Offer"
                          onChange={this.onChangeHandler}
                          field="offer_type"
                          className="col-md-4"
                          data={offerTypes}
                          labelInfo={true}
                          labelInfoTitle="Select Offer"
                          isRequired={false}
                          defaultValue={
                            this.state.offer_type || "Select Offer Type"
                          }
                        />
                        {this.state.offer_type === "SPECIAL_APC" ||
                          this.state.offer_type === "AMOUNT" ? (
                          <div className="col-md-2">
                            <SelectBox
                              onChange={this.onChangeHandler}
                              field="offer_currency"
                              className="col-md-12"
                              labelInfo={true}
                              labelInfoTitle="Select Offer Currency"
                              defaultValue={
                                this.state.offer_currency
                                  ? this.state.offer_currency
                                  : "Currency"
                              }
                              data={currency}
                              isRequired={false}
                            />
                          </div>
                        ) : (
                          <div className="col-md-6">
                            <div className="row">
                              <InputBox
                                label={
                                  this.state.offer_type === "DISCOUNT"
                                    ? "Discount"
                                    : "Amount"
                                }
                                labelInfo={true}
                                labelInfoTitle={this.state.offer_type === "DISCOUNT"
                                  ? "Enter discount"
                                  : "Enter amount"}
                                labelClass="col-md-4"
                                className="col-md-8"
                                onChange={this.onChangeHandler}
                                field="offer_amount"
                                placeholder={
                                  this.state.offer_type === "DISCOUNT"
                                    ? "Enter discount"
                                    : "Enter amount"
                                }
                                defaultValue={this.state.offer_amount}
                                InputType="number"
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-md-4">
                          {this.state.offer_type !== "AMOUNT" ? null : (
                            <InputBox
                              className="col-md-12"
                              onChange={this.onChangeHandler}
                              field="offer_amount"
                              placeholder={
                                this.state.offer_type === "DISCOUNT"
                                  ? "Enter discount"
                                  : "Enter amount"
                              }
                              labelInfo={true}
                              labelInfoTitle={this.state.offer_type === "DISCOUNT"
                                ? "Enter discount"
                                : "Enter amount"}
                              defaultValue={this.state.offer_amount}
                              InputType="number"
                            />
                          )}
                          {this.state.offer_type !== "SPECIAL_APC" ? null : (
                            <InputBox
                              labelInfo={true}
                              labelInfoTitle={this.state.offer_type === "DISCOUNT"
                                ? "Enter discount"
                                : "Enter amount"}
                              className="col-md-12"
                              onChange={this.onChangeHandler}
                              field="offer_amount"
                              placeholder={
                                this.state.offer_type === "DISCOUNT"
                                  ? "Enter discount"
                                  : "Enter amount"
                              }
                              defaultValue={this.state.offer_amount}
                              InputType="number"
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <OaDatePicker
                          label="Offer expiry"
                          field="offer_expiry"
                          onChange={this.onChangeHandler}
                          placeholder="Select offer expiry"
                          showTime={false}
                          labelInfo={true}
                          labelInfoTitle="Select offer expiry"
                          format={format}
                          defaultValue={
                            this.state.offer_expiry
                          }
                        />
                      </div>
                    </div>

                    <div className="text-center m-t-15">
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  single_membership: state.membershipDiscount.single_members,
  organisation_id: state.auth.user.organisation_id,
  token: state.auth.token,
  currency_list: state.OaDeals.oadeal_currency,
  institute_list: (state.institutions && state.institutions.institution_list )|| [],
  content_type: state.journals.content_type,
  loading: state.institutions.loading,
  publication_type: state.journals.publication_type,
  publication_list: state.institutions.publication_list,
  oa_disciplines: state.OaDiscipline.oa_disciplines,
  oa_subjects: state.OaSubject.oa_subjects,
  society_discount: state.societyDiscount.society_discount,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCurrencyList: () => dispatch(getAllCurrencyList()),
  createUpdateMembershipDiscount: (payload) =>
    dispatch(createUpdateMembershipDiscount(payload)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getPublicationList: (payload) => dispatch(getPublicationList(payload)),
  getContentType: () => dispatch(getContentTypeList()),
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  getOaSubjectList: (payload) => dispatch(getOaSubjectList(payload)),
  getMembershipDiscount: (payload) => dispatch(getMembershipDiscount(payload)),
  getInstitutionDataLists: (payload) =>
    dispatch(getInstitutionDataLists(payload)),
});

export const AddMemberShipDiscount = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberShip);
