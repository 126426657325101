import React from "react";
import { connect } from "react-redux";
import { Checkbox, Popover } from "antd";

import {
  articleSingleActionUpdate,
  articleActionButton,
  articleActionUpdate,
  getSinglePublicatonType,
} from "../../../actions";
import {
  Button,
  InputBox,
  PopupBox,
  SelectBox,
} from "../../../../core/components/common";
import { HubContent } from "../../../../core/components";
import { isEmptyObjPropertyCheck } from "../../../../core/lib/ValidationRule";
const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Button config</a>
    <br />

  </div>
);
const preAcceptanceButtonsConfig = [
  {
    event_name: 'New Submission Sent to Publication',
    field_name: 'new_submission',
    default_btn_label: 'New submission',
  },
  {
    event_name: 'Reviewer Invited',
    field_name: 'reviewer_invited',
    default_btn_label: 'Reviewer invited',
  },
  {
    event_name: 'Reviewer Agree',
    field_name: 'reviewer_agree',
    default_btn_label: 'Reviewer agree',
  },
  {
    event_name: 'Accept',
    field_name: 'accept',
    default_btn_label: 'Accept',
  },
  {
    event_name: 'Minor revision',
    field_name: 'minor_revision',
    default_btn_label: 'Minor revision',
  },
  {
    event_name: 'Major revision',
    field_name: 'major_revision',
    default_btn_label: 'Major revision',
  },
  {
    event_name: 'Reject without Review',
    field_name: 'reject_without_review',
    default_btn_label: 'Reject without Review',
  },
  {
    event_name: 'Reject',
    field_name: 'reject',
    default_btn_label: 'Reject',
  },
  {
    event_name: 'Final Disposition - Accept on Submission',
    field_name: 'fd_accept_on_submission',
    default_btn_label: 'Accept on submission',
  },
  {
    event_name: 'Final Disposition - Accept',
    field_name: 'fd_accept',
    default_btn_label: 'Final Disposition - Accept',
  },
  {
    event_name: 'Final Disposition - Reject',
    field_name: 'fd_reject',
    default_btn_label: 'Final Disposition - Reject',
  },


];

class ArticleButtonForm extends React.PureComponent {
  state = {};

  async componentDidMount() {
    let publisher_list = await this.props.getSinglePublicatonType();
    let data = await this.props.articleSingleActionUpdate();

    let preAcceptanceStateData = {};
    let preAcceptancePropsData = (data && data.approval_labels && data.approval_labels.pre_acceptance_labels) || {};

    for (let item of preAcceptanceButtonsConfig) {
      if (preAcceptancePropsData[item.field_name]) {
        preAcceptanceStateData[item.field_name] = preAcceptancePropsData[item.field_name];
      } else {
        preAcceptanceStateData[item.field_name] = item.default_btn_label;
      }
    }

    this.setState({
      publisherList: publisher_list.results,
      publisher: this.props.user_id,
      ...preAcceptanceStateData,
      pay_author: data && data.action_buttons && data.action_buttons.pay.author,
      pay_institution:
        data && data.action_buttons && data.action_buttons.pay.institution,
      pay_funder: data && data.action_buttons && data.action_buttons.pay.funder,

      invoice_author:
        data && data.action_buttons && data.action_buttons.invoice.author,
      invoice_institution:
        data && data.action_buttons && data.action_buttons.invoice.institution,

      invoice_funder:
        data && data.action_buttons && data.action_buttons.invoice.funder,

      approve_institution:
        data && data.action_buttons && data.action_buttons.approve.institution,
      approve_funder:
        data && data.action_buttons && data.action_buttons.approve.funder,

      apc_fund_request_author:
        data &&
        data.action_buttons &&
        data.action_buttons.apc_fund_request.author,

      non_oa_request_author:
        data &&
        data.action_buttons &&
        data.action_buttons.non_oa_request.author,

      read_and_publish:
        data &&
        data.approval_labels &&
        data.approval_labels.auto_approvals &&
        data.approval_labels.auto_approvals.read_and_publish,

      subscribe_to_open:
        data &&
        data.approval_labels &&
        data.approval_labels.auto_approvals &&
        data.approval_labels.auto_approvals.subscribe_to_open,

      "pre-payment":
        data &&
        data.approval_labels &&
        data.approval_labels.auto_approvals &&
        data.approval_labels.auto_approvals["pre-payment"],

      credit:
        data &&
        data.approval_labels &&
        data.approval_labels.auto_approvals &&
        data.approval_labels.auto_approvals.credit,
      paid: data && data.approval_labels && data.approval_labels.paid,
      online_first: data && data.published_labels && data.published_labels.online_first,
      published: data && data.published_labels && data.published_labels.published,
      invoiced: data && data.approval_labels && data.approval_labels.invoiced,
      apc_waived: data && data.approval_labels && data.approval_labels.apc_waived,

      approve: data && data.action_labels && data.action_labels.approve,
      manual_approval: data && data.action_labels && data.action_labels.manual_approval,
      pay: data && data.action_labels && data.action_labels.pay,
      invoice: data && data.action_labels && data.action_labels.invoice,
      apc_fund_request:
        data && data.action_labels && data.action_labels.apc_fund_request,
      non_oa_request:
        data && data.action_labels && data.action_labels.non_oa_request,
      id: data && data.id,
      organisation: data && data.organisation,
    });
  }

  preAcceptanceButtonsUI = (item, key) => {
    return (
      <div className="form-group row" key={key}>
        <div className="col-md-3">
          <label className="m-10">
            {item.event_name}
          </label>
        </div>
        <div className="col-md-3">
          <Button
            type="text"
            text={item.default_btn_label}
            name="success"
            className="col-md-12 btn-article-configure"
          />
        </div>
        <InputBox
          className="col-md-3"
          onChange={this.onChangeHandler}
          field={item.field_name}
          defaultValue={this.state[item.field_name]}
        />
        <div className="col-md-3">
          <Button
            type="text"
            text={this.state[item.field_name]}
            name="success"
            className="col-md-12 btn-article-configure"
          />
        </div>
      </div>
    )
  }

  onChangeHandler = (value) => {
    this.setState({ ...value });
  };

  onChange = (e) => {
    this.setState({
      [e.target.field]: e.target.checked,
    });
  };
  onSubmit = async () => {
    let preAcceptanceStateData = {};

    for (let item of preAcceptanceButtonsConfig) {
      if (this.state[item.field_name]) {
        preAcceptanceStateData[item.field_name] = this.state[item.field_name];
      } else {
        preAcceptanceStateData[item.field_name] = item.default_btn_label;
      }
    }

    let approval_labels = isEmptyObjPropertyCheck({
      subscribe_to_open: this.state.subscribe_to_open,
      read_and_publish: this.state.read_and_publish,
      credit: this.state.credit,
      "pre-payment": this.state["pre-payment"],
    });

    let published_labels = isEmptyObjPropertyCheck({
      online_first: this.state.online_first,
      published: this.state.published,
    });

    let invoicedAndpaid = {
      paid: this.state.paid,
      invoiced: this.state.invoiced,
      apc_waived: this.state.apc_waived,
    };
    let payment_labels = isEmptyObjPropertyCheck(invoicedAndpaid);

    let auto_approval_labels = {
      approval_labels: {
        auto_approvals: approval_labels,
        ...payment_labels,
        pre_acceptance_labels: preAcceptanceStateData,
      },
    };
    let actions_labels = isEmptyObjPropertyCheck({
      approve: this.state.approve,
      manual_approval: this.state.manual_approval,
      pay: this.state.pay,
      invoice: this.state.invoice,
      apc_fund_request: this.state.apc_fund_request,
      non_oa_request: this.state.non_oa_request,
    });
    let actionData = {
      organisation:
        this.props.auth.group === "HUB"
          ? this.state.publisher
          : this.props.user_id,
      action_buttons: {
        pay: {
          author: this.state.pay_author,
          institution: this.state.pay_institution,
          funder: this.state.pay_funder,
        },
        invoice: {
          author: this.state.invoice_author,
          institution: this.state.invoice_institution,
          funder: this.state.invoice_funder,
        },
        approve: {
          institution: this.state.approve_institution,
          funder: this.state.approve_funder,
        },
        apc_fund_request: {
          author: this.state.apc_fund_request_author,
        },
        non_oa_request: {
          author: this.state.non_oa_request_author,
        },
      },
      approval_labels: auto_approval_labels.approval_labels,
      actions_labels: actions_labels,
      published_labels: published_labels,
    };
    if (this.state.id) {
      let data = await this.props.articleActionUpdate(
        this.state.id,
        actionData
      );

      if (data.status === 200) {
        this.setState({
          success: true,
        });
      }
    } else {
      let results = await this.props.articleActionButton(actionData);
      if (results.status === 201) {
        this.setState({
          success: true,
        });
      }
    }
  };
  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push("/settings");
  };

  render() {
    return (
      <HubContent>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body publisher-article-button">
                {this.state.success && (
                  <PopupBox
                    NoText="Close"
                    msg="You have succesfully created your button label configuartion on articles"
                    onCancel={this.redirectLink}
                  />
                )}
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="article-display-heading">
                      Button label configuartion on articles
                    </h3>
                  </div>
                  <div className="col-md-4">

                    <div className="vat-tax-button email d-flex justify-content-end">


                      <Popover placement="leftTop" content={content} trigger="click">
                        <Button
                          className="btn btn-secondary float-right "
                          type=""
                          name=""
                          text="Help"
                        >Help</Button>
                      </Popover>
                      <div className="danger float-right"
                      >
                        <button
                          type="danger"
                          className="btn btn-danger float-right "
                          onClick={() => this.props.history.goBack()}
                          style={{ marginLeft: 10 }}
                        >
                          X

</button>                  </div>

                    </div>



                  </div>

                </div>

                <div className="row">
                  <div className="col-md-12 pl-0">
                    <p className="article-display-paragraph">
                      The following buttons display as default for
                      authors,universities and funders admins.
                    </p>
                    <p className="article-display-paragraph">
                      Uncheck if you wish to hide any buttons for authors,
                      universities and funder admins.
                    </p>
                  </div>
                </div>
                {this.props.auth.group === "HUB" ? (
                  <div className="form-group row">
                    <SelectBox
                      label="Publisher"
                      onChange={this.onChangeHandler}
                      field="publisher"
                      data={this.state.publisherList}
                      isRequired={true}
                    />
                  </div>
                ) : null}
                <div className="form-group row">
                  <div className="col-md-2">
                    <div className="row">
                      <Button
                        type="indigo"
                        name="indigo"
                        text="Pay"
                        className="col-md-9"
                      />
                    </div>
                  </div>

                  <div className="col-md-10">
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.pay_author}
                        id="pay_author"
                        onChange={this.onChange}
                        field="pay_author"
                      >
                        Author
                      </Checkbox>
                    </div>
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.pay_institution}
                        id="pay_institution"
                        onChange={this.onChange}
                        field="pay_institution"
                      >
                        University
                      </Checkbox>
                    </div>
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.pay_funder}
                        id="pay_funder"
                        onChange={this.onChange}
                        field="pay_funder"
                      >
                        Funder
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-2">
                    <div className="row">
                      <Button
                        name="pink"
                        type="pink"
                        text="Invoice"
                        className="col-md-9"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.invoice_author}
                        field="invoice_author"
                        id="invoice_author"
                        onChange={this.onChange}
                      >
                        Author
                      </Checkbox>
                    </div>
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.invoice_institution}
                        field="invoice_institution"
                        id="invoice_institution"
                        onChange={this.onChange}
                      >
                        University
                      </Checkbox>
                    </div>
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.invoice_funder}
                        field="invoice_funder"
                        id="invoice_funder"
                        onChange={this.onChange}
                      >
                        Funder
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-2">
                    <div className="row">
                      <Button
                        name="primary"
                        type="text"
                        text="Approve"
                        className="col-md-9"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.approve_institution}
                        id="pay_funder"
                        onChange={this.onChange}
                        field="approve_institution"
                      >
                        University
                      </Checkbox>
                    </div>
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.approve_funder}
                        id="approve_funder"
                        onChange={this.onChange}
                        field="approve_funder"
                      >
                        Funder
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-2">
                    <div className="row">
                      <Button
                        name="secondary"
                        type="text"
                        text="APC Fund Request"
                        className="col-md-9"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.apc_fund_request_author}
                        id="apc_fund_request_author"
                        onChange={this.onChange}
                        field="apc_fund_request_author"
                      >
                        Author
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-2">
                    <div className="row">
                      <Button
                        type="primary"
                        name="dark"
                        text="Non OA Request"
                        className="col-md-9"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div
                      className={`col-sm-4"`}
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        checked={this.state.non_oa_request_author}
                        id="non_oa_request_author"
                        onChange={this.onChange}
                        field="non_oa_request_author"
                      >
                        Author
                      </Checkbox>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="label-action">Button Labels</label>
                  <p className="article-btn-paragraph-one">
                    Enter the wording you would like to be displayed in the
                    "Override default button text" box.
                  </p>
                  <p className="article-btn-paragraph-two">
                    Leave the box blank if you want to use the default label.
                  </p>
                </div>

                <div className="form-group row">
                  <div className="col-md-12 permission-checkbox">
                    <ul
                      className="nav nav-tabs nav-tabs-custom nav-justified"
                      role="tablist"
                      style={{ marginBottom: 15 }}
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#profile-1"
                          role="tab"
                        >
                          Pre-acceptance
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#home-1"
                          role="tab"
                        >
                          {`Accepted & Post-acceptance`}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane active " id="profile-1" role="tabpanel">
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="article-btn-label">Description</label>
                      </div>
                      <div className="col-md-3">
                        <label className="article-btn-label">
                          Default buttons with labels
                        </label>
                      </div>
                      <div className="col-md-3">
                        <label className="article-btn-label">
                          Override default button text
                        </label>
                      </div>
                      <div className="col-md-3">
                        <label className="article-btn-label">
                          Your custom buttons
                        </label>
                      </div>
                    </div>
                    {
                      preAcceptanceButtonsConfig && preAcceptanceButtonsConfig.map((item, index) => (
                        this.preAcceptanceButtonsUI(item, index)
                      ))
                    }
                  </div>
                  <div className="tab-pane " id="home-1" role="tabpanel">
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="article-btn-label">Description</label>
                      </div>
                      <div className="col-md-3">
                        <label className="article-btn-label">
                          Default buttons with labels
                        </label>
                      </div>
                      <div className="col-md-3">
                        <label className="article-btn-label">
                          Override default button text
                        </label>
                      </div>
                      <div className="col-md-3">
                        <label className="article-btn-label">
                          Your custom buttons
                        </label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Article has been processed/approved under a Read & Publish
                          deal:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Approved"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        onChange={this.onChangeHandler}
                        field="read_and_publish"
                        defaultValue={this.state.read_and_publish}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.read_and_publish}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Article has been processed/approved under a Subscribe to Open
                          deal:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Approved"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        onChange={this.onChangeHandler}
                        field="subscribe_to_open"
                        defaultValue={this.state.subscribe_to_open}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.subscribe_to_open}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Article has been approved under a Pre-payment OA deal:{" "}
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Approved"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        onChange={this.onChangeHandler}
                        className="col-md-3"
                        field="pre-payment"
                        defaultValue={this.state["pre-payment"]}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state["pre-payment"]}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Article has been approved/processed under a Credit or Bulk
                          payment deal:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Approved"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>

                      <InputBox
                        className="col-md-3"
                        field="credit"
                        defaultValue={this.state.credit}
                        onChange={this.onChangeHandler}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.credit}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Author or university paid APC for an article:
                        </label>
                      </div>{" "}
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Paid"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        onChange={this.onChangeHandler}
                        className="col-md-3"
                        field="paid"
                        defaultValue={this.state.paid}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.paid}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>{" "}
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          When article has been approved under Zero APC:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="APC Waived"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>

                      <InputBox
                        onChange={this.onChangeHandler}
                        className="col-md-3"
                        field="apc_waived"
                        defaultValue={this.state.apc_waived}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.apc_waived}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Publisher, author or university raised an invoice for an
                          article:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Invoiced"
                          name="orange"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>

                      <InputBox
                        onChange={this.onChangeHandler}
                        className="col-md-3"
                        field="invoiced"
                        defaultValue={this.state.invoiced}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.invoiced}
                          name="orange"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Publisher admin to generate an invoice:
                        </label>
                      </div>{" "}
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Invoice"
                          name="pink"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                      <InputBox
                        className="col-md-3"
                        field="invoice"
                        defaultValue={this.state.invoice}
                        onChange={this.onChangeHandler}
                      />{" "}
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.invoice}
                          name="pink"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          University admin to approve an article under an OA deal:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Approve"
                          name="primary"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        field="approve"
                        defaultValue={this.state.approve}
                        onChange={this.onChangeHandler}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.approve}
                          name="primary"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Author or university wishes to pay or generate an invoice
                          for an article:
                        </label>
                      </div>{" "}
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Pay"
                          name="indigo"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                      <InputBox
                        className="col-md-3"
                        defaultValue={this.state.pay}
                        field="pay"
                        onChange={this.onChangeHandler}
                      />{" "}
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.pay}
                          name="indigo"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Author wishes to publish his/her article behind paywall:
                        </label>
                      </div>{" "}
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Non OA Request"
                          name="dark"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        field="non_oa_request"
                        defaultValue={this.state.non_oa_request}
                        onChange={this.onChangeHandler}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.non_oa_request}
                          name="dark"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Author wishes to request APC fund to cover APC cost:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="APC Fund Request"
                          name="secondary"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        field="apc_fund_request"
                        defaultValue={this.state.apc_fund_request}
                        onChange={this.onChangeHandler}
                      />{" "}
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.apc_fund_request}
                          name="secondary"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Publisher admin to approve an article manually under an OA deal:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Manual Approval"
                          name="primary"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        field="manual_approval"
                        defaultValue={this.state.manual_approval}
                        onChange={this.onChangeHandler}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.manual_approval}
                          name="primary"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Accepted Manuscript (AM) made available online:
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Online First"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        field="online_first"
                        defaultValue={this.state.online_first}
                        onChange={this.onChangeHandler}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.online_first}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-3">
                        <label className="m-10">
                          Final publication (VoR):
                        </label>
                      </div>
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text="Published"
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />
                      </div>
                      <InputBox
                        className="col-md-3"
                        field="published"
                        defaultValue={this.state.published}
                        onChange={this.onChangeHandler}
                      />
                      <div className="col-md-3">
                        <Button
                          type="text"
                          text={this.state.published}
                          name="green"
                          className="col-md-12 btn-article-configure"
                        />{" "}
                      </div>
                    </div>

                  </div>

                </div>
                <div className="form-group row">
                  <div
                    className="col-md-12 text-center"
                    style={{ marginTop: 20 }}
                  >
                    <Button
                      size="200"
                      type="primary"
                      onClick={this.onSubmit}
                      name="primary"
                      text="Update button configuration"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,

  user_id: state.auth && state.auth.user && state.auth.user.organisation_id,
  article_single_btn: state.articles.article_single_action,
});

const mapDispatchToProps = (dispatch) => ({
  articleActionButton: (payload) => dispatch(articleActionButton(payload)),
  articleActionUpdate: (id, payload) =>
    dispatch(articleActionUpdate(id, payload)),
  getSinglePublicatonType: () => dispatch(getSinglePublicatonType()),

  articleSingleActionUpdate: () => dispatch(articleSingleActionUpdate()),
});

export const ArticleButtonCustomization = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleButtonForm);