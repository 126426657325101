import React from "react";


const TableRowData = () => {
  return <tbody></tbody>;
};
class NewOAdealsCompontent extends React.Component {

  dataTable = (data) => {
    return (
      <tbody>
        {data.map((value, index) => {
          return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{value.institution_name}</td>
              <td>{value.country_name}</td>
            </tr>
          );
        })}
      </tbody>
    );
  };
  render() {
    const props = this.props;
    return (
      <div className="db_test">
        <div className="card m-b-30">
          <div
            className="card-body"
            style={{ minHeight: this.props.isStyle ? 320 : "auto" }}
          >

            <h4 className="mt-0 header-title">{props.title}</h4>

            <table className="table table-hover mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Institution</th>
                  <th>Country</th>
                </tr>
              </thead>
              {this.props.data ? (
                this.dataTable(this.props.data.items)
              ) : (
                  <TableRowData />
                )}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export const NewOAdeals = NewOAdealsCompontent;
