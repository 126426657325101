import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getPublicPublisher,
  createUpdateOrganisationDealFeature,
  getOaDealFeatureList,
} from "../../../../actions";
import {
  PopupBox,
  SelectBox,
  CustomCheckBox,
} from "../../../../../core/components/common";

class Add extends BaseComponent {
  state = {
    organisation_deal_feature: {
      deal_features: []
    }
  };

  async componentDidMount() {
    this.props.getPublicPublisher();
    this.props.getOaDealFeatureList();
  }

  onChangeHandler = value => {
    this.setState(prevState => {
      return Object.assign(prevState.organisation_deal_feature, value);
    });
  };

  onSubmitHandler = async e => {
    e.preventDefault();

    let data = this.state.organisation_deal_feature;


    if (!data.organisation) {
      this.setState({
        isEmpty: (
          <div>
            {!data.organisation && <p>Publisher must be selected</p>}
          </div>
        )
      });
    } else {

      await this.props.createUpdateOrganisationDealFeature(data);

      if (this.props.organisation_deal_feature.success) {
        this.props.history.push("/org-deal-features/list");
      }
    }
  };

  onChangeChecked = e => {
    let dealFeatures = this.state.organisation_deal_feature.deal_features || [];
    if (e.target.checked) {
      dealFeatures.push(e.target.value);
    } else {
      dealFeatures = dealFeatures.filter(val => val !== e.target.value);
    }

    let value = {
      deal_features: dealFeatures,
    };

    this.setState(prevState => {
      return Object.assign(prevState.organisation_deal_feature, value);
    });

  };

  renderOaDealFeatureList = (data) => {
    return data && data.map(feature => (
      <div key={feature && feature.id} className="row">
        <CustomCheckBox
          containerClassName="col-md-12"
          field={`deal_feature`}
          value={feature && feature.id}
          onChange={this.onChangeChecked}
          label={feature && feature.name}
        />
      </div>
    ));
  }

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add organisation deal feature
                    </a>
                  </li>

                </ul>
                <div className="tab-content books">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      <div className="form-group row">
                        <SelectBox
                          label="Publisher"
                          onChange={this.onChangeHandler}
                          field="organisation"
                          data={this.props.publishers.results || []}
                          isRequired={true}
                          className="col-sm-9"
                          labelClass="col-sm-3"
                        />
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">{' '}</div>
                        <div className="col-sm-9">
                          {
                            this.props.oa_deal_features && this.renderOaDealFeatureList(this.props.oa_deal_features.results)
                          }
                        </div>
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  publishers: state.orgs.publisher_list || false,
  organisation_deal_feature: state.OrganisationDealFeature.organisation_deal_feature || false,
  oa_deal_features: state.OrganisationDealFeature.oa_deal_features || false,
});

const mapDispatchToProps = dispatch => ({
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getOaDealFeatureList: payload => dispatch(getOaDealFeatureList(payload)),
  createUpdateOrganisationDealFeature: payload => dispatch(createUpdateOrganisationDealFeature(payload)),
});

export const AddOrganisationDealFeature = connect(mapStateToProps, mapDispatchToProps)(Add);
