import React from "react";
import { withRouter } from "react-router-dom";

import { Button } from "../../../../../core/components/common";

import { Spin } from "antd";

class ArticleConfigLoadingForm extends React.Component {
  render() {
    return (
      <div
        className="article-config-loading"
        style={{
          position: "fixed",
          zIndex: 1002,
          overflow: "hidden",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.64)",
          width: "100%",
          height: "100%",
          right: 0,
          bottom: 0,
          opacity: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className={`col-md-6 text-center alert`}
          style={{
            position: "absolute",
            background: "white",
            top: "50%",
            left: "40%",
            zIndex: 999999,
            transform: "translate(-15%, -50%)",
            overflowY: "auto",
          }}
        >
          <div className="vat-tax-button email">
            <Button
              type="danger"
              name="danger"
              text="x"
              onClick={() => this.props.history.goBack()}
            />
          </div>

          <div className="article-loading">
            <div className="article-loading-text">
              <h3>
                OaMetrix is checking accepted articles on the submission system.
              </h3>
              <h4>Please wait about 10 seconds while OaMetrix is checking.</h4>
              <Spin size="large" />
              {/* <Progress percent={50} status="active" /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ArticleConfigLoadingForm);
