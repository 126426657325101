import {
    POST_DASHBOARD_USER_PERMISSION,
    SINGLE_DASHBOARD_USER_PERMISSION,
    GET_DASHBOARD_USER_PERMISSION_LIST,
    DASHBOARD_DRAG_DROP_CONTROL,
    SUCCESS_DASHBOARD_DRAG_DROP_CONTROL_LIST,
    REQUEST_DASHBOARD_DRAG_DROP_CONTROL_LIST,
    FAILED_DASHBOARD_DRAG_DROP_CONTROL_LIST,

    GET_DASHBOARD_LEVEL_LIST
} from "../../config";

const DashboardControlReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_DASHBOARD_USER_PERMISSION:
            return state = {
                ...state,
                create_dashboard_control: action.payload
            }
        case GET_DASHBOARD_USER_PERMISSION_LIST:
            return state = {
                ...state,
                get_dashboard_user: action.payload
            }
        case SINGLE_DASHBOARD_USER_PERMISSION:
            return state = {
                ...state,
                single_dashboard: action.payload
            }
        case DASHBOARD_DRAG_DROP_CONTROL:
            return state = {
                ...state,
                dashboard_drag_drop: action.payload
            }
        case SUCCESS_DASHBOARD_DRAG_DROP_CONTROL_LIST:
            return state = {
                ...state,
                isLoading: false,
                dashboard_drag_drop_list: action.payload
            }
        case REQUEST_DASHBOARD_DRAG_DROP_CONTROL_LIST:
            return state = {
                ...state,
                isLoading: true
            }
        case FAILED_DASHBOARD_DRAG_DROP_CONTROL_LIST:
            return state = {
                ...state,
                isLoading: false,
                dashboard_drag_drop_list: action.payload
            }
        case GET_DASHBOARD_LEVEL_LIST:
            return state = {
                ...state,
                dashboardLevelList: action.payload
            }
        default:
            return state;
    }
}
export default DashboardControlReducer;
