import { GET_PROGRAM_LIST,SINGLE_PROGRAM,GET_PUB_PROGRAM_LIST } from "../../config";

const PlanReducer = (state = {}, action) => {
    switch (action.type) {
        case SINGLE_PROGRAM: 
            return state = {
                ...state,
                single_program: action.payload
            }
        case GET_PROGRAM_LIST: 
            return state = {
                ...state,
                program_list: action.payload
            }
        case GET_PUB_PROGRAM_LIST: 
            return state = {
                ...state,
                pub_program_list: action.payload
            }
        default:
            return state;
    }
}
export default PlanReducer;