import config, {
  ARTICLE_PAYMENT_SUMMARY,
  GET_INVOICE_DOWNLOAD_TRANCTION,
  ARTICLE_PAYMENT,
  PAYMENT_BILLING_ADDRESS,
  PAYMENT_TRANSACTION_DOWNLOAD,
  PAYMENT_TRANSACTION,
  PAYMENT_AUTHORIZE_URL,
  POST_STRIPE_CODE,
  STRIPE_CONNECT_STATUS,
  ARTICLE_INVOICE_SUMMARY,
  UPDATE_INVOICE,
  GET_INVOICE_DOWNLOAD,
  PUBLISHER_INVOICE_CONFIG_UPDATE,
  INVOICE_SEARCH,
  CARD_PAYMENT_SEARCH,
  ARTICLE_PAYMENT_BY_CARD_LIST,
  ARTICLE_PAYMENT_BY_CARD,
  PUBLISHER_INVOICE_PREVIEW,
  GET_INVOICE_TEMPLATE,
  GET_INVOICE_NOTIFICATION,
  UPDATE_INVOICE_NOTIFICATION,
  REQUEST_PAYMENT_SUMMERY,
  ARTICLE_PAYMENT_REQUEST,
  ARTICLE_PAYMENT_CANCEL,
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Loaded into App Reducers
export const getPaymentSummary = (payload) => ({
  type: ARTICLE_PAYMENT_SUMMARY,
  payload,
});
export const requestPayment = () => ({
  type: ARTICLE_PAYMENT_REQUEST,
});
export const requestCancelPayment = () => ({
  type: ARTICLE_PAYMENT_CANCEL,
});

export const getPaymentRequestAction = () => ({
  type: REQUEST_PAYMENT_SUMMERY,
});

// Loaded into App Reducers
export const updateInvoice = (payload) => ({
  type: UPDATE_INVOICE,
  payload,
});

// Loaded into App Reducers
export const getInvoiceSummary = (payload) => ({
  type: ARTICLE_INVOICE_SUMMARY,
  payload,
});

// Loaded into App Reducers
export const updatePublisherConfigInvoiceReducer = (payload) => ({
  type: PUBLISHER_INVOICE_CONFIG_UPDATE,
  payload,
});

// Loaded into App Reducers
export const publisherInvoicePreview = (payload) => ({
  type: PUBLISHER_INVOICE_PREVIEW,
  payload,
});

// Loaded into App Reducers
export const postPayment = (payload) => ({
  type: ARTICLE_PAYMENT,
  payload,
});
export const postPaymentByCard = (payload) => ({
  type: ARTICLE_PAYMENT_BY_CARD_LIST,
  payload,
});

// Loaded into App Reducers
export const postStipreCode = (payload) => ({
  type: POST_STRIPE_CODE,
  payload,
});

// Loaded into App Reducers
export const paymentBillingAddressReducer = (payload) => ({
  type: PAYMENT_BILLING_ADDRESS,
  payload,
});

// Loaded into App Reducers
export const paymentTransactionReducer = (payload) => ({
  type: PAYMENT_TRANSACTION,
  payload,
});
export const paymentTransactionBycard = (payload) => ({
  type: ARTICLE_PAYMENT_BY_CARD,
  payload,
});

export const TransactionDownload = (payload) => ({
  type: PAYMENT_TRANSACTION_DOWNLOAD,
  payload,
});
// Loaded into App Reducers
export const paymentAuthorizeUrlReducer = (payload) => ({
  type: PAYMENT_AUTHORIZE_URL,
  payload,
});

// Loaded into App Reducers
export const getStripeConnectStatusReducer = (payload) => ({
  type: STRIPE_CONNECT_STATUS,
  payload,
});

export const invoiceDownload = (payload) => ({
  type: GET_INVOICE_DOWNLOAD,
  payload,
});

export const TransactionInvoiceDownload = (payload) => ({
  type: GET_INVOICE_DOWNLOAD_TRANCTION,
  payload,
});

export const invoiceTemplateListAction = (payload) => ({
  type: GET_INVOICE_TEMPLATE,
  payload,
});

export const getInvoiceNofitication = (payload) => ({
  type: GET_INVOICE_NOTIFICATION,
  payload,
});
export const updateNofitication = (payload) => ({
  type: UPDATE_INVOICE_NOTIFICATION,
  payload,
});

export const InvoiceSearchList = (payload) => ({
  type: INVOICE_SEARCH,
  payload,
});

export const CardPaymentSearchList = (payload) => ({
  type: CARD_PAYMENT_SEARCH,
  payload,
});

// Get invoice template lists
export const invoiceTemplateList = (payload) => {
  return (dispatch) => {
    const url = config.endpoint.invoice_template;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(invoiceTemplateListAction(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// send email on publisher opened invoice
export const sendInvoiceEmail = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.publisher_opened_invoice_email;

    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Get Article Payment Summary
export const articlePaymentSummary = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.article_payment_summary}${payload.id}/`;

    let queryParamsObj = {};
    if (payload.currency_code) {
      queryParamsObj["currency_code"] = payload.currency_code;
    }

    if (payload.price_licence) {
      queryParamsObj["price_licence"] = payload.price_licence;
    }

    if (payload.biladdr_ccode) {
      queryParamsObj["biladdr-ccode"] = payload.biladdr_ccode;
    }

    if (payload.biladdr_scode) {
      queryParamsObj["biladdr-scode"] = payload.biladdr_scode;
    }

    if (payload.organisation) {
      queryParamsObj["vt-bll-name"] = payload.organisation;
    }

    if (payload.group) {
      queryParamsObj["vt-bll-grp"] = payload.group;
    }

    if (payload.org_vat_number) {
      queryParamsObj["vt-bll-vt-num"] = payload.org_vat_number;
    }

    if (payload.org_author_id) {
      queryParamsObj["org-author-id"] = payload.org_author_id;
    }

    if (payload.skip_vat) {
      queryParamsObj["skip-vat"] = payload.skip_vat;
    }

    if (Object.keys(queryParamsObj).length > 0) {
      url += "?" + serialize(queryParamsObj);
    }

    dispatch(getPaymentRequestAction());
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getPaymentSummary(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
// Get Article Invoice Summary
export const articleInvoiceSummary = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.article_invoice_summary}${payload.id}/`;

    let queryParamsObj = {};
    if (payload.currency_code) {
      queryParamsObj["currency_code"] = payload.currency_code;
    }

    if (payload.biladdr_ccode) {
      queryParamsObj["biladdr-ccode"] = payload.biladdr_ccode;
    }

    if (payload.biladdr_scode) {
      queryParamsObj["biladdr-scode"] = payload.biladdr_scode;
    }

    if (Object.keys(queryParamsObj).length > 0) {
      url += serialize(queryParamsObj);
    }

    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getInvoiceSummary(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get payment billing address
export const paymentBillingAddress = (payload) => {
  return (dispatch) => {
    const url = config.endpoint.payment_billing_address;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(paymentBillingAddressReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get article payment transaction
export const paymentTransaction = (payload) => {
  return (dispatch) => {
    const url = `${config.endpoint.payment_transaction}?page_size=999999`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(paymentTransactionBycard(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get publisher invoice config single
export const getPublisherConfigSingle = (payload) => {
  return (dispatch) => {
    const url = config.endpoint.publisher_invoice_config_single;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(paymentTransactionReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get publisher invoice config single
export const getPublisherInvoicePreview = (payload) => {
  return (dispatch) => {
    const url = config.endpoint.publisher_invoice_preview;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(publisherInvoicePreview(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Put publisher invoice config
export const updatePublisherConfigSingle = (id, payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.publisher_invoice_config}${id}/`;
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(updatePublisherConfigInvoiceReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {});
    });
  };
};

export const getInvoiceDownload = () => {
  const url = `${config.endpoint.invoice_download}?fields=name,publisher_name,journal_type_name,pub_acronym,eissn,pissn,content_type_name,apc_gbp,colour_charge_gbp,submission_fee_gbp,page_charge_gbp,other_charge_gbp,apc_eur,colour_charge_eur,submission_fee_eur,page_charge_eur,other_charge_eur,apc_usd,colour_charge_usd,submission_fee_usd,page_charge_usd,other_charge_usd,indexed,status,impact_factor,hindex,sherpa_romeo_info,note,date&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(invoiceDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

export const downloadArticleInvoice = (payload) => {
  let url = payload.url.split("/api/v1/").join("");

  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(invoiceDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
export const downloadInvoice = (payload) => {
  let url = payload.url;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(invoiceDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const paymentTransactionDownload = () => {
  const url = `${config.endpoint.payment_transaction}download?fields=date,article_title,publisher_name,institution_name,paid_by,payer_org,currency_name,total,payment_method,licence&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(TransactionDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Get article payment transaction
export const getPaymentAuthorizeUrl = (payload) => {
  return (dispatch) => {
    const url = `${config.endpoint.get_payment_authorize_url}?redirect_uri=${payload.redirect_uri}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(paymentAuthorizeUrlReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get stripe connect status
export const getStripeConnectStatus = (payload) => {
  return (dispatch) => {
    const url = `${config.endpoint.check_stripe_connect_status}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getStripeConnectStatusReducer(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Post article payment
export const articlePayment = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.article_payment;
    return new Promise((resolve, reject) => {
      dispatch(requestPayment());
      api
        .post(url, payload)
        .then((res) => {
          dispatch(postPayment(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          dispatch(requestCancelPayment());
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
// Update invoice
export const UpdateInvoice = (id, payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.update_invoice}${id}/`;
    return new Promise((resolve, reject) => {
      api
        .put(url, payload)
        .then((res) => {
          dispatch(updateInvoice(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// export const getInvoiceUpdateInformation = () => {
//     return (dispatch, getState) => {
//         let url = `${config.endpoint.update_invoice}`;
//         return new Promise((resolve, reject) => {
//             api.get(url).then((res) => {
//                 // dispatch(updateInvoice(res))
//                 resolve(Object.assign(res, { status: true }));
//             }).catch(error => {
//                 reject(Object.assign(error, { status: false }))
//             })
//         })
//     }
// }
// Post article invoice
export const articleInvoice = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.opened_invoice;

    let queryParamsObj = {};

    if (payload.organisation) {
      queryParamsObj["vt-bll-name"] = payload.organisation;
      delete payload.organisation;
    }

    if (payload.group) {
      queryParamsObj["vt-bll-grp"] = payload.group;
      delete payload.group;
    }

    if (payload.org_vat_number) {
      queryParamsObj["vt-bll-vt-num"] = payload.org_vat_number;
      delete payload.org_vat_number;
    }

    if (Object.keys(queryParamsObj).length > 0) {
      url += "?" + serialize(queryParamsObj);
    }

    return new Promise((resolve, reject) => {
      dispatch(requestPayment());
      api
        .post(url, payload)
        .then((res) => {
          dispatch(postPayment(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          dispatch(requestCancelPayment());

          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

// Get article invoice
export const getArticleInvoice = (payload) => {
  const params = payload ? payload.params || "" : "";
  const filter = payload ? payload.filter || "" : "";
  let url = `${config.endpoint.invoice}?${params && params + "&"}${filter}`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(postPayment(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Get card payment
export const getCardPayment = (payload) => {
  return (dispatch) => {
    const params = payload ? payload.params || "" : "";
    const filter = payload ? payload.filter || "" : "";
    let url = `${config.endpoint.payment_transaction}?${
      params && params + "&"
    }${filter}`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(postPaymentByCard(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

// Submit stripe code for backend store
export const stripeCodeSubmit = (payload) => {
  return (dispatch) => {
    let url = config.endpoint.submit_for_user_credentials;
    return new Promise((resolve, reject) => {
      api
        .post(url, payload)
        .then((res) => {
          dispatch(postStipreCode(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};

export const paymentinvoiceTransactionDownload = () => {
  const url = `${config.endpoint.invoice_download}?fields=date,publisher,organisation,invoice_number,currency_name,amount,article_id,article_title,status&page_size=999999`;
  return (dispatch, getState) => {
    const token = getState().auth.token;
    let options = {
      responseType: "blob",
    };
    return new Promise((resolve, reject) => {
      api
        .get(url, token, options)
        .then((res) => {
          dispatch(TransactionInvoiceDownload(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch(function (error) {
          reject(Object.assign(error.response, { status: false }));
        });
    });
  };
};

// Invoice Notification List
export const getInvoiceNotficationList = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.orgs_notification_config}single/`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(getInvoiceNofitication(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};
export const getInvoiceSearchList = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.orgs_notification_config}single/`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(InvoiceSearchList(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const getCardPaymentSearchList = (payload) => {
  return (dispatch) => {
    let url = `${config.endpoint.orgs_notification_config}single/`;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          dispatch(CardPaymentSearchList(res));
          resolve(Object.assign(res, { status: true }));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

export const updateInvoiceNofitication = (id, payload) => {
  return (dispatch, getState) => {
    let url = `${config.endpoint.orgs_notification_config}`;
    return new Promise((resolve, reject) => {
      return api
        .put(url + id + "/", payload)
        .then((res) => {
          dispatch(
            updateNofitication(Object.assign(res.data, { status: res.status }))
          );
          resolve(res.data);
        })
        .catch((error) => {});
    });
  };
};

// revoke stripe account permission
export const revokeStripeConnection = (id) => {
  return (dispatch) => {
    let url = `${config.endpoint.publisher_stripe_account}${id}/`;

    return new Promise((resolve, reject) => {
      api
        .delete(url)
        .then((res) => {
          resolve(Object.assign(res, { status: true }));
        })
        .catch((error) => {
          reject(Object.assign(error, { status: false }));
        });
    });
  };
};
