import React from 'react';
import BaseComponent, {  } from '../../../../../core/components';
import { SelectBox, Badge } from '../../../../../core/components/common';


class AddChild extends BaseComponent {
    state = {
        members: [],
        data: [],
        member_ids: []
    }
    async componentDidMount() {
        let selectedMembers = this.props.members || false;
        if (selectedMembers) {
            let organizations = this.props.organizations.filter((x) => !selectedMembers.includes(x.id));
            let members = this.props.organizations.filter((x) => selectedMembers.includes(x.id));
            this.setState({
                data: organizations,
                members: members,
                member_ids: selectedMembers,
            })
        } else {
            this.setState({
                data: this.props.organizations
            })
        }
    }

    removById(array, element) {
        return array.filter(el => el !== element);
    }

    onChangeHandler = (value) => {
        this.setState((prevState) => {
            let memberData = this.state.data.find(x => x.id === value.members)
            prevState.members.push(memberData);
            prevState.member_ids.push(value.members);
            return prevState;
        }, function () {
            let organisations = this.state.data.filter(x => x.id !== value.members);
            this.setState({
                data: organisations
            })
        })
    }

    onDeleteMember = (id) => {
      
        this.setState((prevState) => {
            prevState.data.push(this.state.members[id])
            prevState.members.splice(id, 1)
            prevState.member_ids.splice(id, 1);
            return prevState
        })
    }

    onSubmit = () => {
        let params = {
            mode: 'add',
            members: this.state.member_ids
        };

        if (this.state.member_ids.length < 1) {
            this.setState({
                isEmpty: <div>
                    <p>Please select organisation</p>
                </div>
            })
        } else {
            this.props.onSubmitHandler(params);
        }
    }

    cancelLoader = async () => {
        this.setState({
            isEmpty: false
        })
    }

    render() { 
        let props = this.props;
        let className = this.props.className ? this.props.className : 'col-sm-4';

        return (
            <div style={{ position: 'fixed', zIndex: 1001, backgroundColor: 'rgba(0, 0, 0, 0.64)', width: '100%', height: '100%', overflow: 'hidden', top: 0, left: 0 }}>
                <div className={`${className} text-center bg-white alert`} style={{ position: "absolute", top: '50%', left: '50%', zIndex: 999999, transform: 'translate(-50%, -50%)' }}>
                    <h4>{props.title}</h4>
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <ul className="nav nav-pills nav-justified" role="tablist">
                                        <li className="nav-item waves-effect waves-light">
                                            <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Add child organisation</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div className="tab-pane active p-3" id="" role="tabpanel">
                                        </div>
                                        <div className="form-group row">
                                            {this.state.data &&
                                                <SelectBox
                                                    label="Organisations"
                                                    className="col-sm-10"
                                                    onChange={this.onChangeHandler}
                                                    field='members'
                                                    data={this.state.data}
                                                    isRequired={false}
                                                />
                                            }
                                        </div>
                                        <div className="form-group row" style={{ marginLeft: 3 }}>
                                            {this.state.members.length > 0 && this.state.members.map((member, id) => {
                                                return <Badge
                                                    label={member.name}
                                                    onDelete={() => this.onDeleteMember(id)}
                                                    key={id}
                                                />
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="text-center">
                        {props.YesText && <button className={`m-3 col-sm-${props.btnWidth || '4'} btn btn-${props.btnSize || 'lg'} btn-${props.type || 'primary'}`} onClick={(e) => {
                            e.preventDefault();
                            this.onSubmit()
                        }}>{props.YesText || 'Yes'}</button>
                        }

                        {props.NoText && <button className={`m-3 col-sm-${props.btnWidth || '4'} btn btn-${props.btnSize || 'lg'} btn-${props.NoBtnType || 'primary'}`} onClick={(e) => {
                            e.preventDefault();
                            props.onCancel && props.onCancel();
                        }}>{props.NoText}</button>}
                    </div>
                </div>
            </div>
            
        )
    }
}

export const AddChildOrganisation = AddChild;
