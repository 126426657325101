export const APP_LOADED = "APP_LOADED";
export const APP_UNLOADED = "APP_UNLOADED";
export const MENU_ENLARGED = "MENU_ENLARGED";

// Filters

export const FILTER_DATA = "FILTER_DATA";

// Authentications
export const REQUEST_LOGIN_DATA = "REQUEST_LOGIN_DATA";
export const RECIEVED_LOGIN_DATA = "RECIEVED_LOGIN_DATA";
export const FAILED_LOGIN_DATA = "FAILED_LOGIN_DATA";
export const REQUEST_SIGNUP_DATA = "REQUEST_LOGIN_DATA";
export const RECIEVED_SIGNUP_DATA = "RECIEVED_LOGIN_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT_USER = "LOGOUT_USER";
export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

// Roles
export const GET_ROLES = "GET_ROLES";

// Groups
export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUP_PERMISSION = "GET_GROUP_PERMISSION";
export const GET_PERMISSION_ORGS = "GET_PERMISSION_ORGS";
export const REQUEST_ORG_SINGLE_DATA = "REQUEST_ORG_SINGLE_DATA";



// Organizations
export const GET_ORG_LIST = "GET_ORG_LIST";
export const REQUEST_ORG_SEARCH = "REQUEST_ORG_SEARCH";
export const FAILED_ORG_LIST = "FAILED_ORG_LIST";

export const GET_ORG_LIST_ALL = "GET_ORG_LIST_ALL";
export const REQUEST_CREATE_ORG = "REQUEST_CREATE_ORG";
export const FAILED_CREATE_ORG = "FAILED_CREATE_ORG";
export const REQUEST_UPDATE_ORG = "REQUEST_UPDATE_ORG";
export const FAILED_UPDATE_ORG = "FAILED_UPDATE_ORG";
export const DELETE_ORG_DATA = "DELETE_ORG_DATA";
export const REQUEST_FAILED_DELETE_USER = "REQUEST_FAILED_DELETE_USER";
export const SINGLE_ORG_DATA = "SINGLE_ORG_DATA";
export const FAILED_SINGLE_ORG_DATA = "FAILED_SINGLE_ORG_DATA";
export const GET_ORG_FINANCIAL_REPORT = "GET_ORG_FINANCIAL_REPORT";
export const ORG_LIST_COUNT = "ORG_LIST_COUNT";
export const GET_ORG_AUTO_APPROVAL_CONFIG = "GET_ORG_AUTO_APPROVAL_CONFIG";
export const TRIGGER_ORG_AUTO_APPROVAL_CONFIG =
  "TRIGGER_ORG_AUTO_APPROVAL_CONFIG";
export const CREATE_UPDATE_ORG_AUTO_APPROVAL_STATUS =
  "CREATE_UPDATE_ORG_AUTO_APPROVAL_STATUS";
export const UPDATE_CHILD_ORGANISATION = "UPDATE_CHILD_ORGANISATION";
export const ORGANISATION_FILE_UPLOAD = "ORGANISATION_FILE_UPLOAD";
export const TRACK_ORGANISATION_FILE_UPLOAD_STATUS =
  "TRACK_ORGANISATION_FILE_UPLOAD_STATUS";
// Programs
export const GET_PROGRAM_LIST = "GET_PROGRAM_LIST";
export const GET_PUB_PROGRAM_LIST = "GET_PUB_PROGRAM_LIST";
export const SINGLE_PROGRAM = "SINGLE_PROGRAM";
export const REQUEST_CREATE_PROGRAM = "REQUEST_CREATE_PROGRAM";

// Users
export const REQUEST_CREATE_USER = "REQUEST_CREATE_USER";
export const FAILED_CREATE_USER = "FAILED_CREATE_USER";
export const GET_ALL_USER = "GET_ALL_USER";
export const REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER";
export const FAILED_UPDATE_USER = "FAILED_UPDATE_USER";
export const DELETE_USER_DATA = "DELETE_USER_DATA";
export const GET_PUBLIC_ORG = "GET_PUBLIC_ORG";
export const GET_PUBLISHER = "GET_PUBLISHER";

export const FAILED_USER_DATA = "FAILED_USER_DATA";
export const SUCCESS_USER_DATA = "SUCCESS_USER_DATA";
export const FETCH_USER_DATA = "FETCH_USER_DATA";

// Locations
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_INVOCE_CITY_LIST = "GET_INVOCE_CITY_LIST";
export const COVERAGE_COUNTRIES = "COVERAGE_COUNTRIES";
export const CLEAR_FAILED_STATUS = "CLEAR_FAILED_STATUS";

// Journal
export const GET_JOURNAL_LIST = "GET_JOURNAL_LIST";
export const REQUEST_CREATE_JOURNAL = "REQUEST_CREATE_JOURNAL";
export const REQUEST_CREATE_PUBLICATION = "REQUEST_CREATE_PUBLICATION";
export const FAILED_CREATE_JOURNAL = "FAILED_CREATE_JOURNAL";
export const FAILED_CREATE_PUBLICAITON = "FAILED_CREATE_PUBLICAITON";
export const GET_JOURNAL_LIST_ALL = "GET_JOURNAL_LIST_ALL";
export const REQUEST_UPDATE_JOURNAL = "REQUEST_UPDATE_JOURNAL";
export const DELETE_JOURNAL_DATA = "DELETE_JOURNAL_DATA";
export const SINGLE_JOURNAL_DATA = "SINGLE_JOURNAL_DATA";
export const FAILED_SINGLE_JOURNAL_DATA = "FAILED_SINGLE_JOURNAL_DATA";
export const GET_PUBLICATION_TYPE = "GET_PUBLICATION_TYPE";
export const FAILED_PUBLICATION_TYPE = "FAILED_PUBLICATION_TYPE";
export const GET_JOURNAL_TYPE = "GET_JOURNAL_TYPE";
export const GET_CONTENT_TYPE = "GET_CONTENT_TYPE";
export const FAILED_JOURNAL_TYPE = "FAILED_JOURNAL_TYPE";
export const COUNT_JOURNAL_LIST = "COUNT_JOURNAL_LIST";
export const JOURNAL_FILE_UPLOAD = "JOURNAL_FILE_UPLOAD";
export const TRACK_JOURNAL_FILE_UPLOAD_STATUS =
  "TRACK_JOURNAL_FILE_UPLOAD_STATUS";
export const GET_JOURNAL_SEARCHLIST = "GET_JOURNAL_SEARCHLIST";
export const GET_BOOK_SERIES_ACTION = "GET_BOOK_SERIES_ACTION";
export const GET_JOURNAL_ACTION = "GET_JOURNAL_ACTION";
export const SUCESS_BOOK_SERIES = "SUCESS_BOOK_SERIES";
export const REQUEST_BOOK_SERIES = "REQUEST_BOOK_SERIES";
export const FAILURE_BOOK_SERIES = "FAILURE_BOOK_SERIES";

export const SUCCESS_GET_PUBLICATION = "SUCCESS_GET_PUBLICATION";
export const FAIELD_GET_PUBLICATION = "FAIELD_GET_PUBLICATION";
export const REQUEST_GET_PUBLICATION = "REQUEST_GET_PUBLICATION";
export const GET_SINGLE_COUNTRY = "GET_SINGLE_COUNTRY";

// Articles Action Creator
export const ADD_UPDATE_ARTICLE = "ADD_UPDATE_ARTICLE";
export const ALL_ARTICLES = "ALL_ARTICLES";
export const REQUEST_BOOK_LIST = "REQUEST_BOOK_LIST";
export const SUCCESS_BOOK_LIST = "SUCCESS_BOOK_LIST";
export const FAILED_BOOK_LIST = "FAILED_BOOK_LIST";

export const ADD_UPDATE_BOOK = "ADD_UPDATE_BOOK";
export const GET_SINGLE_BOOK = "GET_SINGLE_BOOK";
export const BOOK_SEARCH = "BOOK_SEARCH";

export const GET_BOOK_PUBLICATION_TYPE = "GET_BOOK_PUBLICATION_TYPE";
export const GET_ARTICLE_MANUAL = "GET_ARTICLE_MANUAL";
export const GET_ARTICLE_FULL_ALL = "GET_ARTICLE_FULL_ALL";
export const ARTICLE_DOWNLOAD = "ARTICLE_DOWNLOAD";

// export const GET_UNI_ARTICLE_FULL       = 'GET_UNI_ARTICLE_FULL'
export const GET_SINGLE_ARTICLE = "GET_ARTICLE_SINGLE";
export const GET_LICENCE_LIST = "GET_LICENCE_LIST";
export const GET_LICENCE_AGREEMENTS = "GET_LICENCE_AGREEMENTS";
export const GET_LICENCE_NON_OA = "GET_LICENCE_NON_OA";
export const CREATE_AUTHOR_APC_FUND = "CREATE_AUTHOR_APC_FUND";
export const CREATE_AUTHOR_APC_FUND_NO_OA = "CREATE_AUTHOR_APC_FUND_NO_OA";
export const FAILED_AUTHOR_APC_FUND = "FAILED_AUTHOR_APC_FUND";
export const CORRECTION_REQUEST = "CORRECTION_REQUEST";
export const CORRECTION_REQUEST_SINGLE = "CORRECTION_REQUEST_SINGLE";
export const ARTICLE_FILE_UPLOAD = "ARTICLE_FILE_UPLOAD";
export const BOOK_FILE_UPLOAD = "BOOK_FILE_UPLOAD";
export const TRACK_ARTICLE_FILE_UPLOAD_STATUS =
  "TRACK_ARTICLE_FILE_UPLOAD_STATUS";
export const TRACK_ARTICLE_FILE_UPLOAD_STATUS_DOWNLOAD =
  "TRACK_ARTICLE_FILE_UPLOAD_STATUS_DOWNLOAD";
export const TRACK_BOOK_FILE_UPLOAD_STATUS = "TRACK_BOOK_FILE_UPLOAD_STATUS";
export const ARTICLE_APPROVE_SUMMARY = "ARTICLE_APPROVE_SUMMARY";
export const CORRECTION_DOWNLOAD = "CORRECTION_DOWNLOAD";
export const BOOK_DOWNLOAD = "BOOK_DOWNLOAD";
export const ARTICLE_BTN_ACTION = "ARTICLE_BTN_ACTION";
export const MUTATE_ARTICLE_PRICE = "MUTATE_ARTICLE_PRICE";
export const GET_ALL_LICENCE = "GET_ALL_LICENCE";

export const EMAIL_HISTORY_REQUEST = "EMAIL_HISTORY_REQUEST";
export const EMAIL_HISTORY_SUCCESS = "EMAIL_HISTORY_SUCCESS";
export const EMAIL_HISTORY_ERRROR = "EMAIL_HISTORY_ERROR";
export const SUCCESS_GET_APC = "SUCCESS_GET_APC";

export const ARTICLE_AUTHOR_NOTIFICATION_SUMMARY = "ARTICLE_AUTHOR_NOTIFICATION_SUMMARY"
export const ARTICLE_VIEW_LOG = "ARTICLE_VIEW_LOG";
export const ARTICLE_OFFER_DETAILS = "ARTICLE_OFFER_DETAILS";
export const ARTICLE_OFFER_DETAILS_REQUEST = "ARTICLE_OFFER_DETAILS_REQUEST";
export const REQUEST_SINGLE_ARTICLE_FULL = "REQUEST_SINGLE_ARTICLE_FULL";
export const SUCCESS_SINGLE_ARTICLE_FULL = "SUCCESS_SINGLE_ARTICLE_FULL";
export const ARTICLE_REQUEST_ACTION_BUTTON = "ARTICLE_REQUEST_ACTION_BUTTON";

export const GET_EMAIL_HISTORY_ACTION = "GET_EMAIL_HISTORY_ACTION";
export const GET_EMAIL_LOG_ACTION = "GET_EMAIL_LOG_ACTION";
export const EMAIL_RESEND_ACTION = "EMAIL_RESEND_ACTION";
export const ARTICLE_MESSAGE_EMAIL_REQUEST = "ARTICLE_MESSAGE_EMAIL_REQUEST";
export const REQUEST_ARTICLE_MANUAL_ACTION = "REQUEST_ARTICLE_MANUAL_ACTION";
export const REQUEST_ARTICLE_ERROR_ACTION = "REQUEST_ARTICLE_ERROR_ACTION";
export const REQUEST_ARTICLE_MANUAL_UPDATE_ACTION =
  "REQUEST_ARTICLE_MANUAL_UPDATE_ACTION";
export const REQUEST_UPDATE_ARTICLE = "REQUEST_UPDATE_ARTICLE";
export const REQUEST_CANCEL_ARTICLE = "REQUEST_CANCEL_ARTICLE";

// Institution Article Creator
export const CREATE_GEOGRAPHICAL_DISCOUNT = "CREATE_GEOGRAPHICAL_DISCOUNT";
export const UPDATE_GEOGRAPHICAL_DISCOUNT = "UPDATE_GEOGRAPHICAL_DISCOUNT";
export const FAILED_GEOGRAPHICAL_DISCOUNT = "FAILED_GEOGRAPHICAL_DISCOUNT";
export const GEOGRAPHICAL_DISCOUNT_LIST = "GEOGRAPHICAL_DISCOUNT_LIST";
export const GEOGRAPHICAL_DISCOUNT_LIST_SINGLE =
  "GEOGRAPHICAL_DISCOUNT_LIST_SINGLE";

// Institution Article Creator
export const GET_INSTITUTION_LIST = "GET_INSTITUTION_LIST";
export const GET_REQUEST_ACTION = "GET_REQUEST_ACTION";

// Oadeals related constant
export const GET_COUNTER_ORG_LIST = "GET_COUNTER_ORG_LIST";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_DEAL_TYPE = "GET_DEAL_TYPE";
export const GET_OADEAL_LIST = "GET_OADEAL_LIST";
export const GET_OA_DISCIPLINE_LIST = "GET_OA_DISCIPLINE_LIST";
export const GET_OA_DISCIPLINE = "GET_OA_DISCIPLINE";
export const CREATE_UPDATE_OA_DISCIPLINE = "CREATE_UPDATE_OA_DISCIPLINE";
export const DELETE_OA_DISCIPLINE = "DELETE_OA_DISCIPLINE";
export const GET_OA_SUBJECT_LIST = "GET_OA_SUBJECT_LIST";
export const GET_OA_SUBJECT = "GET_OA_SUBJECT";
export const UPLOAD_OA_SUBJECT = "UPLOAD_OA_SUBJECT";
export const CREATE_UPDATE_OA_SUBJECT = "CREATE_UPDATE_OA_SUBJECT";
export const DELETE_OA_SUBJECT = "DELETE_OA_SUBJECT";
export const GET_OADEAL = "GET_OADEAL";
export const CREATE_OA_DEAL = "CREATE_OA_DEAL";
export const UPDATE_OA_DEAL = "UPDATE_OA_DEAL";
export const REQUEST_OA_DEAL = "REQUEST_OA_DEAL";
export const REQUEST_OA_DEAL_SEARCH_LIST = "REQUEST_OA_DEAL_SEARCH_LIST";

export const FAILED_CREATE_OA_DEAL = "FAILED_CREATE_OA_DEAL";
export const DELETE_OA_DEAL = "DELETE_OA_DEAL";
export const GET_OA_DEAL_FILTERED_LIST = "GET_OA_DEAL_FILTERED_LIST";
export const GET_OA_DEAL_APPROVED_LIST = "GET_OA_DEAL_APPROVED_LIST";
export const GET_OA_DEAL_APPROVAL = "GET_OA_DEAL_APPROVAL";
export const GET_OA_DEAL_REJECT = "GET_OA_DEAL_REJECT";
export const GET_OA_DEAL_CANCEL = "GET_OA_DEAL_CANCEL";
export const OA_DEAL_DOWNLOAD = "OA_DEAL_DOWNLOAD";
export const GET_OA_DEAL_DOWNLOAD = "GET_OA_DEAL_DOWNLOAD";
export const GET_OADEAL_FILTER_LIST = "GET_OADEAL_FILTER_LIST";
export const GET_OA_REQUEST_DEAL_SEARCH = "GET_OA_REQUEST_DEAL_SEARCH";
export const GET_ALL_OA_DEAL_SEARCH = "GET_ALL_OA_DEAL_SEARCH";
export const GET_DEAL_INSTITUATION = "GET_DEAL_INSTITUATION";

export const PUBLISHER_DEPOSIT_PAYMENT = "PUBLISHER_DEPOSIT_PAYMENT";

export const GET_OA_DEAL_SUMMARY = "GET_OA_DEAL_SUMMARY";
export const SET_LOADING_OA_DEAL = "SET_LOADING_OA_DEAL";

export const GET_OADEAL_REQUEST_LIST = "GET_OADEAL_REQUEST_LIST";
export const GET_PENDING_OADEAL_REQUEST_COUNT =
  "GET_PENDING_OADEAL_REQUEST_COUNT";

//publication list
export const GET_PUBLICATION_LIST = "GET_PUBLICATION_LIST";
export const GET_PUBLICATION_LIST_NEW = "GET_PUBLICATION_LIST_NEW";
export const GET_DEPARTMENT_LIST = "GET_DEPARTMENT_LIST";

//COUNTRY LIST
export const GET_COUNTRY = "GET_COUNTRY";

// OA Token Generation List
export const CREATE_OA_TOKEN = "CREATE_OA_TOKEN";
export const APPLY_TOKEN = "APPLY_TOKEN";
export const CREATE_AND_APPLY_TOKEN = "CREATE_AND_APPLY_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";
export const CREATE_ORGANISATION_TOKEN = "CREATE_ORGANISATION_TOKEN";
export const ORGANISATION_OFFSET_FUND = "ORGANISATION_OFFSET_FUND";
export const GET_OFFSET_FUND = "GET_OFFSET_FUND";
export const GET_ORGANISATION_TOKEN = "GET_ORGANISATION_TOKEN";
export const GET_OA_TOKEN = "GET_OA_TOKEN";
export const DELETE_OA_TOKEN = "DELETE_OA_TOKEN";
export const FAILED_DELETE_OA_TOKEN = "FAILED_DELETE_OA_TOKEN";

export const REQUEST_CREATE_TOKEN = "REQUEST_CREATE_TOKEN";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const GET_TOKEN_DOWNLOAD = "GET_TOKEN_DOWNLOAD";
export const GET_ORGS_DOWNLOAD = "GET_ORGS_DOWNLOAD";
export const GET_TOKEN_ORGS_DOWNLOAD = "GET_TOKEN_ORGS_DOWNLOAD";
export const GET_TOKEN_AUTHOR_ARTICLE = "GET_TOKEN_AUTHOR_ARTICLE";
export const GET_TOKEN_SEARCH_LIST = "GET_TOKEN_SEARCH_LIST";
export const GET_ALLOCATED_TOKEN_SEARCH_LIST =
  "GET_ALLOCATED_TOKEN_SEARCH_LIST";
export const GET_ORGS_TOKEN_SEARCH_LIST = "GET_ORGS_TOKEN_SEARCH_LIST";

// Content Type
export const CREATE_CONTENT_TYPE = "CREATE_CONTENT_TYPE";
export const UPLOAD_CONTENT_TYPE = "UPLOAD_CONTENT_TYPE";
export const DELETE_CONTENT_TYPE_DATA = "DELETE_CONTENT_TYPE_DATA";
export const UPDATE_CONTENT_TYPE_DATA = "UPDATE_CONTENT_TYPE_DATA";

// Oa policy Type
export const GET_ALL_OA_POLICY = "GET_ALL_OA_POLICY";
export const GET_ALL_OA_REQUIREMENT = "GET_ALL_OA_REQUIREMENT";
export const POST_OA_POLICY = "POST_OA_POLICY";
export const POST_OA_REQUIREMENT = "POST_OA_REQUIREMENT";
export const PUT_OA_POLICY = "PUT_OA_POLICY";
export const PUT_OA_REQUIREMENT = "PUT_OA_REQUIREMENT";
export const SINGLE_OA_POLICY = "SINGLE_OA_POLICY";
export const SINGLE_OA_REQUIREMENT = "SINGLE_OA_REQUIREMENT";

// Group
export const CREATE_GROUP = "CREATE_GROUP";
export const GET_GROUP_TYPE = "GET_GROUP_TYPE";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const DELETE_GROUP = "DELETE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const PARENT_GROUP_LIST = "PARENT_GROUP_LIST";

// Consortia
export const CONSORTIA_ADD_MEMBER = "CONSORTIA_ADD_MEMBER";
export const CONSORTIA_MEMBER_LIST = "CONSORTIA_MEMBER_LIST";

export const GET_CONSORTIUM_LIST = "GET_CONSORTIUM_LIST";

// Payment
export const ARTICLE_PAYMENT_SUMMARY = "ARTICLE_PAYMENT_SUMMARY";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const ARTICLE_INVOICE_SUMMARY = "ARTICLE_INVOICE_SUMMARY";
export const GET_INVOICE_DOWNLOAD = "GET_INVOICE_DOWNLOAD";
export const ARTICLE_PAYMENT = "ARTICLE_PAYMENT";
export const PAYMENT_BILLING_ADDRESS = "PAYMENT_BILLING_ADDRESS";
export const PAYMENT_TRANSACTION = "PAYMENT_TRANSACTION";
export const PAYMENT_TRANSACTION_DOWNLOAD = "PAYMENT_TRANSACTION_DOWNLOAD";
export const PAYMENT_AUTHORIZE_URL = "PAYMENT_AUTHORIZE_URL";
export const STRIPE_CONNECT_STATUS = "STRIPE_CONNECT_STATUS";
export const POST_STRIPE_CODE = "POST_STRIPE_CODE";

// Invoice
export const PUBLISHER_INVOICE_CONFIG_UPDATE =
  "PUBLISHER_INVOICE_CONFIG_UPDATE";
export const PUBLISHER_INVOICE_PREVIEW = "PUBLISHER_INVOICE_PREVIEW";
export const GET_INVOICE_TEMPLATE = "GET_INVOICE_TEMPLATE";
export const GET_INVOICE_NOTIFICATION = "GET_INVOICE_NOTIFICATION";
export const UPDATE_INVOICE_NOTIFICATION = "UPDATE_INVOICE_NOTIFICATION";
export const INVOICE_SEARCH = "INVOICE_SEARCH";
export const CARD_PAYMENT_SEARCH = "INVOICE_SEARCH";
export const ARTICLE_PAYMENT_BY_CARD_LIST = "ARTICLE_PAYMENT_BY_CARD_LIST";
export const ARTICLE_PAYMENT_BY_CARD = "ARTICLE_PAYMENT_BY_CARD";
export const REQUEST_PAYMENT_SUMMERY = "REQUEST_PAYMENT_SUMMERY";
export const ARTICLE_PAYMENT_REQUEST = "ARTICLE_PAYMENT_REQUEST";
export const ARTICLE_PAYMENT_CANCEL = "ARTICLE_PAYMENT_CANCEL";

// Payment Pub
export const PAYMENT_TRANSACTION_PUB = "PAYMENT_TRANSACTION_PUB";
export const TOKEN_FUND_TRANX_SEARCH = "TOKEN_FUND_TRANX_SEARCH";
export const DEPOSIT_FUND_TRANX_SEARCH = "DEPOSIT_FUND_TRANX_SEARCH";
export const OFFSET_TRANSACTION_SEARCH = "OFFSET_TRANSACTION_SEARCH";
export const DEPOSIT_SEARCH_LIST = "DEPOSIT_SEARCH_LIST";

// University Publisher Fund
export const DEPOSIT_FUND = "DEPOSIT_FUND";
export const DEPOSIT_DOWNLOAD = "DEPOSIT_DOWNLOAD";
export const DEPOSIT_FUND_LIST = "DEPOSIT_FUND_LIST";
export const DEPOSIT_DOWNLOAD_LIST = "DEPOSIT_DOWNLOAD_LIST";

export const APPROVED_DEPOSIT_LIST = "APPROVED_DEPOSIT_LIST";
export const PENDING_DEPOSIT_LIST = "PENDING_DEPOSIT_LIST";
export const DEPOSIT_APPROVAL_DOWNLOAD = "DEPOSIT_APPROVAL_DOWNLOAD";
export const DEPOSIT_LIST_DOWNLOAD = "DEPOSIT_LIST_DOWNLOAD";

// Author APC Request
export const GET_AUTHOR_APC_REQUEST = "GET_AUTHOR_APC_REQUEST";
export const GET_AUTHOR_APC_REQUEST_DOWNLOAD =
  "GET_AUTHOR_APC_REQUEST_DOWNLOAD";
export const APC_FUND_SEARCH = "APC_FUND_SEARCH";
export const GET_APC_REQUEST = "GET_APC_REQUEST";

// Dashboard
export const DASHBOARD_DATA = "DASHBOARD_DATA";

// APC Fund
export const GET_APC_FUND_LIST = "GET_APC_FUND_LIST";
export const ADD_UPDATE_APC_FUND = "ADD_UPDATE_APC_FUND";
export const DOWLOAD_APC_FUND = "DOWLOAD_APC_FUND";

// Article Required Field
export const ARTICLE_REQUIRED_FIELD = "ARTICLE_REQUIRED_FIELD";
export const ARTICLE_APC_REQUEST_ORGANISATION =
  "ARTICLE_APC_REQUEST_ORGANISATION";

// Currency
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";

export const REJECT_ARTICLE_FULL = "REJECT_ARTICLE_FULL";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

export const GET_PREMIUM_DASHBOARD_DATA = "GET_PREMIUM_DASHBOARD_DATA";

export const OA_TOKEN_TRXN = "OA_TOKEN_TRXN";
export const REQUEST_DEPOSIT_FUND_TRXN = "REQUEST_DEPOSIT_FUND_TRXN";
export const FAILED_DEPOSIT_FUND_TRXN = "FAILED_DEPOSIT_FUND_TRXN";
export const SUCCESS_DEPOSIT_FUND_TRXN = "SUCCESS_DEPOSIT_FUND_TRXN";

// export const OFFSET_FUND_TRXN = 'OFFSET_FUND_TRXN';

export const DEPOSIT_FUND_PUB = "DEPOSIT_FUND_PUB";
export const DEPOSIT_OFFSET_PUB = "DEPOSIT_OFFSET_PUB";
export const OFFSET_FUND_DOWNLOAD = "OFFSET_FUND_DOWNLOAD";

// Error Config

export const ERROR_PUT = "ERROR_PUT";
export const ERROR_POST = "ERROR_POST";
export const ERROR_DELETE = "ERROR_DELETE";
export const ERROR_GET = "ERROR_GET";

// EmailMessage Config

export const GET_EMAIL_MESSAGE = "GET_EMAIL_MESSAGE";
export const GET_EMAIL_MESSAGE_LIST = "GET_EMAIL_MESSAGE_LIST";
export const REQUEST_EMAIL_MESSAGE_LIST = "REQUEST_EMAIL_MESSAGE_LIST";
export const REQUEST_EMAIL_UPDATE = "REQUEST_EMAIL_UPDATE";

export const CREATE_EMAIL_MESSAGE = "CREATE_EMAIL_MESSAGE";
export const GET_DEFAULT_EMAIL_MESSAGE = "GET_DEFAULT_EMAIL_MESSAGE";
export const GET_EMAIL_TAG = "GET_EMAIL_TAG";
export const GET_EMAIL_EDIT = "GET_EMAIL_EDIT";

// Vat
export const VAT_LIST = "VAT_LIST";
export const GET_SINGLE_VAT_CONFIG = "GET_SINGLE_VAT_CONFIG";
export const CREATE_UPDATE_VAT = "CREATE_UPDATE_VAT";
export const GET_VAT = "GET_VAT";
export const CREATE_UPDATE_VAT_CONFIG = "CREATE_UPDATE_VAT_CONFIG";
export const GET_VAT_CONFIG = "GET_VAT_CONFIG";
export const CREATE_VAT_CONFIG = "CREATE_VAT_CONFIG";
export const CREATE_VAT_HUB = "CREATE_VAT_HUB";
export const UPDATE_VAT_HUB = "UPDATE_VAT_HUB";
export const GET_VAT_HUB = "GET_VAT_HUB";
export const GET_SINGLE_VAT_HUB = "GET_SINGLE_VAT_HUB";
export const CREATE_VAT_PUB = "CREATE_VAT_PUB";
export const REQUEST_UPDATE_VAT_PUBLISHER_ACTION =
  "REQUEST_UPDATE_VAT_PUBLISHER_ACTION";
export const GET_UPDATE_SINGLE_VAT_PUBLISHER_ACTION =
  "GET_UPDATE_SINGLE_VAT_PUBLISHER_ACTION";
export const REQUEST_CREATE_CONFIG_ACTION = "REQUEST_CREATE_CONFIG_ACTION";
export const REQUEST_VAT_CONFIGURE = "REQUEST_VAT_CONFIGURE";

export const GET_COUNTRY_VAT_PUB = "GET_COUNTRY_VAT_PUB";
export const US_STATE_VAT_PUB = "US_STATE_VAT_PUB";
export const CREATE_VAT_PUBLISHER_ACTION = "CREATE_VAT_PUBLISHER_ACTION";
export const REQUEST_VAT_PUBLISHER_ACTION = "REQUEST_VAT_PUBLISHER_ACTION";

export const GET_ALL_VAT_PUBLISHER_ACTION = "GET_ALL_VAT_PUBLISHER_ACTION";
export const GET_SINGLE_VAT_PUBLISHER_ACTION =
  "GET_SINGLE_VAT_PUBLISHER_ACTION";
export const GET_DELETE_VAT_PUBLISHER_ACTION =
  "GET_DELETE_VAT_PUBLISHER_ACTION";
export const UPDATE_VAT_PUBLISHER_ACTION = "UPDATE_VAT_PUBLISHER_ACTION";

export const CA_STATE_VAT_PUB = "CA_STATE_VAT_PUB";

//Download
export const GET_JOURNAL_DOWNLOAD = "GET_JOURNAL_DOWNLOAD";
export const GET_DEPOSIT_DOWNLOAD = "GET_DEPOSIT_DOWNLOAD";
export const GET_ORGS_TOKEN_DOWNLOAD = "GET_ORGS_TOKEN_DOWNLOAD";

//LICENCE
export const GET_LICENCE_ALL_LIST = "GET_LICENCE_ALL_LIST";
export const SINGLE_LICENCE_LIST = "SINGLE_LICENCE_LIST";
export const UPDATE_LICENCE_LIST = "UPDATE_LICENCE_LIST";
export const GET_TERMS_CONDITION_LIST = "GET_TERMS_CONDITION_LIST";
export const REQUEST_GET_TERMS_CONDITION_LIST =
  "REQUEST_GET_TERMS_CONDITION_LIST";

export const UPDATE_TERMS_CONDITION_LIST = "UPDATE_TERMS_CONDITION_LIST";
export const SINGLE_TERMS_CONDITION_LIST = "SINGLE_TERMS_CONDITION_LIST";
export const GET_LICENCE_PDFPDFDWNLOAD = "GET_LICENCE_PDFPDFDWNLOAD";
export const GET_TERMS_PDFDWNLOAD = "GET_TERMS_PDFDWNLOAD";
export const REQUEST_ALL_POST_LICENSE = "REQUEST_ALL_POST_LICENSE";

export const POST_TERMS_CONDITION = "POST_TERMS_CONDITION";
export const POST_ADD_LICENSE = "POST_ADD_LICENSE";
export const ARTICLE_FULL_LICENCE_UPDATE = "ARTICLE_FULL_LICENCE_UPDATE";
export const GET_ALL_POST_LICENSE = "GET_ALL_POST_LICENSE";
export const REQUEST_LICENCE_ALL_LIST = "REQUEST_LICENCE_ALL_LIST";
export const REQUEST_SINGLE_LICENSE_ACTION = "REQUEST_SINGLE_LICENSE_ACTION";

export const ORGANISATION_LICENSE_AGREEMENTS =
  "ORGANISATION_LICENSE_AGREEMENTS";
export const ORGANISATION_LICENSE_DOWNLOAD = "ORGANISATION_LICENSE_DOWNLOAD";
export const DELETE_ALL_POST_LICENSE = "DELETE_ALL_POST_LICENSE";
export const DELETE_ALL_POST_TERMS = "DELETE_ALL_POST_TERMS";

export const GET_INVOICE_DOWNLOAD_TRANCTION = "GET_INVOICE_DOWNLOAD_TRANCTION";

// Refund request actions
export const GET_REFUND_REQUEST_LIST = "GET_REFUND_REQUEST_LIST";
export const GET_REFUND_REQUEST = "GET_REFUND_REQUEST";
export const CREATE_UPDATE_REFUND_REQUEST = "CREATE_UPDATE_REFUND_REQUEST";
export const DELETE_REFUND_REQUEST = "DELETE_REFUND_REQUEST";
export const ACCEPT_REFUND_REQUEST = "ACCEPT_REFUND_REQUEST";
export const CANCEL_REFUND_REQUEST = "CANCEL_REFUND_REQUEST";
export const REJECT_REFUND_REQUEST = "REJECT_REFUND_REQUEST";
export const MUTATE_REFUND_REQUEST = "MUTATE_REFUND_REQUEST";
export const AUTHOR_REFUND_REQUEST = "AUTHOR_REFUND_REQUEST";
export const GET_AUTHOR_REFUND_REQUEST_INFO = "GET_AUTHOR_REFUND_REQUEST_INFO";
export const APPROVE_REFUND_REQUEST = "APPROVE_REFUND_REQUEST";
export const GET_ALL_REFUND_INFO_LIST = "GET_ALL_REFUND_INFO_LIST";
export const REQUEST_ALL_REFUND_INFO_LIST = "REQUEST_ALL_REFUND_INFO_LIST";

export const REQUEST_CANCELED = "request-canceled";
export const REFUND_REQUEST_ACCEPTED = "refund-request-accepted";
export const REFUND_ACKNOWLEDGED = "refund-acknowledged";
export const REFUND_AMOUNT_PAID = "refund-paid";
export const REFUND_PAYMENT_RECEIVED = "refund-payment-received";
export const ON_HOLD = "on-hold";
export const REJECTED = "rejected";

export const GET_OADEAL_BALANCE_LIST = "GET_OADEAL_BALANCE_LIST";

// SOCIETY_MEMBER

export const SUCCESS_SOCIETY_MEMBER_LIST = "SUCCESS_SOCIETY_MEMBER_LIST";
export const FALIED_SOCIETY_MEMBER_LIST = "FALIED_SOCIETY_MEMBER_LIST";
export const REQUEST_SOCIETY_MEMBER_LIST = "REQUEST_SOCIETY_MEMBER_LIST";

export const GET_SOCIETY_MEMBER = "GET_SOCIETY_MEMBER";
export const CREATE_UPDATE_SOCIETY_MEMBER = "CREATE_UPDATE_SOCIETY_MEMBER";
export const DELETE_SOCIETY_MEMBER = "DELETE_SOCIETY_MEMBER";

//SOCIETY_MEMBER_SHIP

export const REQUEST_CREATE_SOCIETY_MEMBER = "REQUEST_CREATE_SOCIETY_MEMBER";
export const REQUEST_UPDATE_SOCIETY_MEMBER = "REQUEST_UPDATE_SOCIETY_MEMBER";
export const GET_ALL_SOCIETY_MEMBER = "GET_ALL_SOCIETY_MEMBER";
export const SINGLE_SOCIETY_MEMBER = "SINGLE_SOCIETY_MEMBER";
export const GET_ALL_SOCIETY_MEMBER_LIST = "GET_ALL_SOCIETY_MEMBER_LIST";

// Society discount config
export const SUCCESS_SOCIETY_DISCOUNT_LIST = "SUCCESS_SOCIETY_DISCOUNT_LIST";
export const FAILED_SOCIETY_DISCOUNT_LIST = "FAILED_SOCIETY_DISCOUNT_LIST";
export const REQUEST_SOCIETY_DISCOUNT_LIST = "REQUEST_SOCIETY_DISCOUNT_LIST";

export const GET_SOCIETY_DISCOUNT = "    GET_SOCIETY_DISCOUNT";
export const CREATE_UPDATE_SOCIETY_DISCOUNT = "CREATE_UPDATE_SOCIETY_DISCOUNT";
export const DELETE_SOCIETY_DISCOUNT = "DELETE_SOCIETY_DISCOUNT";

export const GET_SOCIETY_LIST = "GET_SOCIETY_LIST";

// Membership discount config

export const GET_MEMBERSHIP_DISCOUNT_LIST = "GET_MEMBERSHIP_DISCOUNT_LIST";
export const GET_MEMBERSHIP_DISCOUNT = "    GET_MEMBERSHIP_DISCOUNT";
export const CREATE_UPDATE_MEMBERSHIP_DISCOUNT =
  "CREATE_UPDATE_MEMBERSHIP_DISCOUNT";
export const DELETE_MEMBERSHIP_DISCOUNT = "DELETE_MEMBERSHIP_DISCOUNT";
export const REQUEST_MEMBERSHIP_DISCOUNT = "REQUEST_MEMBERSHIP_DISCOUNT";
export const REQUEST_MEMBERSHIP_DISCOUNT_LIST =
  "REQUEST_MEMBERSHIP_DISCOUNT_LIST";

export const GET_MEMBERSHIP_LIST = "GET_MEMBERSHIP_LIST";

export const GET_ORGANISATION_DEAL_FEATURE_LIST =
  "GET_ORGANISATION_DEAL_FEATURE_LIST";
export const GET_ORGANISATION_DEAL_FEATURE = "GET_ORGANISATION_DEAL_FEATURE";
export const UPLOAD_ORGANISATION_DEAL_FEATURE =
  "UPLOAD_ORGANISATION_DEAL_FEATURE";
export const CREATE_UPDATE_ORGANISATION_DEAL_FEATURE =
  "CREATE_UPDATE_ORGANISATION_DEAL_FEATURE";
export const DELETE_ORGANISATION_DEAL_FEATURE =
  "DELETE_ORGANISATION_DEAL_FEATURE";

export const GET_OA_DEAL_FEATURE_LIST = "GET_OA_DEAL_FEATURE_LIST";

export const SUCCESS_PUBLICATION_APC_WAIVER_CONFIG_LIST =
  "SUCCESS_PUBLICATION_APC_WAIVER_CONFIG_LIST";

export const REQUEST_PUBLICATION_APC_WAIVER_CONFIG_LIST =
  "REQUEST_PUBLICATION_APC_WAIVER_CONFIG_LIST";

export const FAIELD_PUBLICATION_APC_WAIVER_CONFIG_LIST =
  "FAIELD_PUBLICATION_APC_WAIVER_CONFIG_LIST";
export const GET_PUBLICATION_APC_WAIVER_CONFIG =
  "GET_PUBLICATION_APC_WAIVER_CONFIG";
export const UPLOAD_PUBLICATION_APC_WAIVER_CONFIG =
  "UPLOAD_PUBLICATION_APC_WAIVER_CONFIG";
export const CREATE_UPDATE_PUBLICATION_APC_WAIVER_CONFIG =
  "CREATE_UPDATE_PUBLICATION_APC_WAIVER_CONFIG";
export const DELETE_PUBLICATION_APC_WAIVER_CONFIG =
  "DELETE_PUBLICATION_APC_WAIVER_CONFIG";

export const GET_SUBMISSION_LIST = "GET_SUBMISSION_LIST";
export const GET_SUB_STATUS = "GET_SUB_STATUS";
export const CREATE_SUBMISSION = "CREATE_SUBMISSION";
export const UPDATE_SUBMISSION = "UPDATE_SUBMISSION";
export const DELETE_SUBMISSION = "DELETE_SUBMISSION";

// Article control config
export const FAIELD_ARTICLE_CONTROL_CONFIG_LIST =
  "FAIELD_ARTICLE_CONTROL_CONFIG_LIST";
export const REQUEST_ARTICLE_CONTROL_CONFIG_LIST =
  "REQUEST_ARTICLE_CONTROL_CONFIG_LIST";
export const SUCCESS_ARTICLE_CONTROL_CONFIG_LIST =
  "SUCCESS_ARTICLE_CONTROL_CONFIG_LIST";

export const GET_ARTICLE_CONTROL_CONFIG = "GET_ARTICLE_CONTROL_CONFIG";
export const CREATE_UPDATE_ARTICLE_CONTROL_CONFIG =
  "CREATE_UPDATE_ARTICLE_CONTROL_CONFIG";
export const DELETE_ARTICLE_CONTROL_CONFIG = "DELETE_ARTICLE_CONTROL_CONFIG";

// USER Permission

export const GET_USER_PERMISSION_LIST = "GET_USER_PERMISSION_LIST";

// APC Eligibility
export const GET_APC_ELG_INST_LIST = "GET_APC_ELG_INST_LIST";
export const GET_APC_ELG_JNL_LIST = "GET_APC_ELG_JNL_LIST";
export const GET_APC_ELG_FUNDER_LIST = "GET_APC_ELG_FUNDER_LIST";
export const GET_APC_ELG_COUNTRY_LIST = "GET_APC_ELG_COUNTRY_LIST";
export const GET_APC_ELG_CONTENT_TYPE_LIST = "GET_APC_ELG_CONTENT_TYPE_LIST";
export const GET_APC_ELG_SOCIETY_LIST = "GET_APC_ELG_SOCIETY_LIST";
export const GET_APC_ELG_SUBMIT_RESPONSE = "GET_APC_ELG_SUBMIT_RESPONSE";

// USER DASHBOARD CONTROL

export const GET_DASHBOARD_USER_PERMISSION_LIST =
  "GET_DASHBOARD_USER_PERMISSION_LIST";
export const POST_DASHBOARD_USER_PERMISSION = "POST_DASHBOARD_USER_PERMISSION";
export const SINGLE_DASHBOARD_USER_PERMISSION =
  "SINGLE_DASHBOARD_USER_PERMISSION";
export const GET_DASHBOARD_LEVEL_LIST = "GET_DASHBOARD_LEVEL_LIST";

export const DASHBOARD_DRAG_DROP_CONTROL = "DASHBOARD_DRAG_DROP_CONTROL";

export const SUCCESS_DASHBOARD_DRAG_DROP_CONTROL_LIST =
  "SUCCESS_DASHBOARD_DRAG_DROP_CONTROL_LIST";
export const REQUEST_DASHBOARD_DRAG_DROP_CONTROL_LIST =
  "REQUEST_DASHBOARD_DRAG_DROP_CONTROL_LIST";
export const FAILED_DASHBOARD_DRAG_DROP_CONTROL_LIST =
  "FAILED_DASHBOARD_DRAG_DROP_CONTROL_LIST";

export const UPDATE_DASHBOARD_DRAG_DROP = "UPDATE_DASHBOARD_DRAG_DROP";

// Article config

export const GET_ARTICLE_TRIGGER_CONFIG = "GET_ARTICLE_TRIGGER_CONFIG";
export const ADD_ARTICLE_TRIGGER_CONFIG = "ADD_ARTICLE_TRIGGER_CONFIG";
export const GET_JOURNAL_LIST_TRIGGER_CONFIG =
  "GET_JOURNAL_LIST_TRIGGER_CONFIG";

// Invoice template actions
export const GET_INVOICE_TEMPLATE_LIST = "GET_INVOICE_TEMPLATE_LIST";
// export const GET_INVOICE_TEMPLATE = "GET_INVOICE_TEMPLATE";
export const CREATE_UPDATE_INVOICE_TEMPLATE = "CREATE_UPDATE_INVOICE_TEMPLATE";
export const DELETE_INVOICE_TEMPLATE = "DELETE_INVOICE_TEMPLATE";
export const UPLOAD_INVOICE_TEMPLATE = "UPLOAD_INVOICE_TEMPLATE";

// Quicksight Dashboard actions
export const GET_QS_DASHBOARD_LIST = "GET_QS_DASHBOARD_LIST";
export const GET_QS_DASHBOARD = "GET_QS_DASHBOARD";
export const CREATE_UPDATE_QS_DASHBOARD = "CREATE_UPDATE_QS_DASHBOARD";
export const DELETE_QS_DASHBOARD = "DELETE_QS_DASHBOARD";
//XERO integration actions
export const GET_XERO_AUTHORIZE_URL = "GET_XERO_AUTHORIZE_URL";
export const SUBMIT_XERO_AUTH_CODE = "SUBMIT_XERO_AUTH_CODE";

// Article tab control config actions
export const GET_ARTICLE_TAB_CONTROL_CONFIG_LIST =
  "GET_ARTICLE_TAB_CONTROL_CONFIG_LIST";
export const GET_ARTICLE_TAB_CONTROL_CONFIG = "GET_ARTICLE_TAB_CONTROL_CONFIG";
export const CREATE_UPDATE_ARTICLE_TAB_CONTROL_CONFIG =
  "CREATE_UPDATE_ARTICLE_TAB_CONTROL_CONFIG";
export const DELETE_ARTICLE_TAB_CONTROL_CONFIG =
  "DELETE_ARTICLE_TAB_CONTROL_CONFIG";

//Generic Oauth integration actions
export const GET_GENERIC_OAUTH_LOGIN_URL = "GET_GENERIC_OAUTH_LOGIN_URL";
export const SUBMIT_GENERIC_OAUTH_AUTH_CODE = "SUBMIT_GENERIC_OAUTH_AUTH_CODE";
export const CREATE_OAUTH_AUTH_CODE = "CREATE_OAUTH_AUTH_CODE";

//Generic Oauth integration actions
export const GET_ARTICLE_FAULTY_AUTHOR = "GET_ARTICLE_FAULTY_AUTHOR";
export const GET_ARTICLE_SINGLE_FAULTY_AUTHOR =
  "GET_ARTICLE_SINGLE_FAULTY_AUTHOR";

// ARTICLE_FULL ACTION CREATOR
export const ARTICLE_FETCH_REQUEST = "ARTICLE_FETCH_REQUEST";
export const ARTICLE_FETCH_SUCCESS = "ARTICLE_FETCH_SUCCESS";
export const ARTICLE_FETCH_ERROR = "ARTICLE_FETCH_ERROR";

export const FETCH_OFFER_DETAILS = "FETCH_OFFER_DETAILS";
export const SUCCESS_OFFER_DETAILS = "SUCCESS_OFFER_DETAILS";
export const FAIL_OFFER_DETAILS = "FAIL_OFFER_DETAILS";

export const GET_LICENCE_PRICING_CONTROL_CONFIG_LIST = "GET_LICENCE_PRICING_CONTROL_CONFIG_LIST";
export const GET_LICENCE_PRICING_CONTROL_CONFIG = "GET_LICENCE_PRICING_CONTROL_CONFIG";
export const CREATE_UPDATE_LICENCE_PRICING_CONTROL_CONFIG = "CREATE_UPDATE_LICENCE_PRICING_CONTROL_CONFIG";
export const DELETE_LICENCE_PRICING_CONTROL_CONFIG = "DELETE_LICENCE_PRICING_CONTROL_CONFIG";

export const PRICING_LICENCE_LIST = "PRICING_LICENCE_LIST";
