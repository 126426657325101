import React from "react";
import { connect } from "react-redux";
import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { getQsDashboardList, deleteQsDashboard } from "../../../../actions";
import { confirmAlert } from "react-confirm-alert";
import {Link} from "react-router-dom"
class UL extends BaseComponent {
  state = {
    data: [],
  };
  getQsDashboardListData = async (pageNum = 1, page = 10) => {
    await this.props.getQsDashboardList({ pageNum: pageNum, pageSize: page });
    this.setState({
      data: this.props.qs_dashboards.results,
      count: this.props.qs_dashboards.count,
    });
  };

  async componentDidMount() {
    this.getQsDashboardListData();
  }

  pageChange = (pageNumber) => {
    this.getQsDashboardListData(pageNumber);
  };

  onEditDashboard = (id, data) => {
    this.props.history.push("/quicksight-dashboards/edit-dashboard/" + id, {
      dashboard: data,
    });
  };

  onDeleteDashboard = (id) => {
    confirmAlert({
      title: "",
      message: "Please confirm to delete",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteQsDashboard(id).then((res) => {
              if (res.status) {
                this.props.history.push("/quicksight-dashboards/dashboard-list");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    const heading = ["name", "dashboard_group"];

    return (
      <HubContent title="Quicksight dashboard list">
        <div className="row">
        <div className="col-md-12">
            <Link
             to="/quicksight-dashboards/add-dashboard"
              className="btn btn-primary float-right mb-2"
            >
              Add Quicksight Dashboard
            </Link>
          </div>

          <div className="col-12">
            <div className="card m-b-20">
              {this.state.data && this.state.count && (
                <DataTable
                  group={this.props.auth && this.props.auth.group}
                  heading={heading}
                  data={this.state.data}
                  onEdit={this.onEditDashboard}
                  onDelete={this.onDeleteDashboard}
                  count={this.state.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,

  qs_dashboards: state.QsDashboard.qs_dashboards || false,
});

const mapDispatchToProps = (dispatch) => ({
  getQsDashboardList: (payload) => dispatch(getQsDashboardList(payload)),
  deleteQsDashboard: (id) => dispatch(deleteQsDashboard(id)),
});

export const DashboardList = connect(mapStateToProps, mapDispatchToProps)(UL);
