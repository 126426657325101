import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
    singleOrg
} from "../../../../actions";
class ProfilePage extends BaseComponent {
    constructor(props) {
        super(props);
        this.error = props.failed;
        this.status = 100;
    }
    state = {
        user: {},
        msg: false,
        loader: false,
        status: false,
    };

     componentDidMount() {
      this.ApiDataList()
     }
    ApiDataList =async () => {
        const id = this.props.match.params.id;
        if (id) {
            await this.props.singleOrg(id);
                this.setState({
                    org: this.props.org
                })
            
        }

    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    
    }

    render() {

        return (
            <HubContent>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20 settings-card">
                            <div className="card-body  profile">
                            <button
                    type="danger"
                    className="btn btn-danger float-right "
                    onClick={() => this.props.history.push("/settings/list")}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    X

</button>            
                                <div className="row">
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Organization name </label>
                                        <p
                                        > {this.state.org && this.state.org.name}
                                        </p>
                                    </div>
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Parent Organization</label>
                                        <p
                                        > {(this.state.org && this.state.org.parent_name)|| "-"}
                                        </p>

                                    </div>
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Name in other language one</label>
                                        <p
                                        >{(this.state.org && this.state.org.meta && this.state.org.meta.name_1_other_lang)||"-" }
                                        </p>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="profile-label">Name in other language two</label>
                                        <p
                                        >{(this.state.org && this.state.org.meta && this.state.org.meta.name_2_other_lang )||"-"}</p>


                                    </div>

             
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Address one  </label>
                                        <p
                                        >{(this.state.org && this.state.org.address && this.state.org.address.address1 )|| "-"}</p>

                                    </div>
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Address two </label>
                                        <p> {(this.state.org && this.state.org.address && this.state.org.address.address2)|| "-"}
                                        </p>
                                    </div>
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">City </label>
                                        <p>{(this.state.org && this.state.org.address && this.state.org.address.city_name) || "-"} </p>


                                    </div>
                                    <div className="col-md-4">
                                        <label className="profile-label">Post/Zip Code</label>

                                        <p>{(this.state.org && this.state.org.address && this.state.org.address.postal_code) || "-"}</p>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="profile-label">State / County</label>
                                        <p>{(this.state.org && this.state.org.address && this.state.org.address.state_name)|| "-"}</p>

                                    </div>
                                    <div className="col-md-4">
                                        <label className="profile-label">Country</label>
                                        <p>{(this.state.org && this.state.org.address && this.state.org.address.country_name) ||"-"}</p>

                                    </div>
            
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Domain  </label>

                                        <p> {(this.state.org && this.state.org.dommain_name )|| "-"}
                                        </p>
                                    </div>
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label">Website </label>

                                        <p> {(this.state.org && this.state.org.website) || "-"}
                                        </p>
                                    </div>
                                    <div className="col-md-4 profile-m-15">
                                        <label className="profile-label"> GRID NO.</label>


                                        <p> {(this.state.org && this.state.org.meta && this.state.org.meta.grid_number) || "-"}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="profile-label">Ringgold No</label>

                                        <p> {(this.state.org && this.state.org.meta && this.state.org.meta.ring_gold_number) || "-"}
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="profile-label">ISNI No.</label>
                                        <p>  {(this.state.org && this.state.org.meta && this.state.org.meta.isni_number) || "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = state => ({
    org: state.orgs.org_single ,
});

const mapDispatchToProps = dispatch => ({
    singleOrg: id => dispatch(singleOrg(id)),
});

export const Profile = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);
