import React from "react";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  PopupBox
  // SelectBox,
  // InputBox
} from "../../../../../core/components/common";
import { DataTable } from "../../../../../core/components";
import { connect } from "react-redux";
import { serialize } from "../../../../../core/lib";
import {
  getAllOaDealRequestList,
  deleteOaDeal,
  oADealListApproval,
  oADealListReject,
  oADealListCancel
} from "../../../../actions";

import moment from "moment";

const format = "Do MMM YYYY";

class DB extends BaseComponent {
  getOaDealData = async (pageNum = 1, page = 10) => {
    let params = serialize(
      Object.assign({ page: pageNum, page_size: page }, this.state.filterParams)
    );

    await this.props.getAllOaDealRequestList(params);
    this.setState({
      data: this.props.oaDealList.results,
      count: this.props.oaDealList.count
    });
  };

  async componentDidMount() {
    this.getOaDealData();
  }

  pageChange = pageNumber => {
    this.getOaDealData(pageNumber);
  };

  state = {
    oaDealIds: [],
    filterParams: {
      approval_status: "pending"
    }
  };
  ids = [];
  onSelect = id => {
    let index = this.ids.indexOf(id);
    index !== -1 ? this.ids.splice(index, 1) : this.ids.push(id);
    this.setState({
      oaDealIds: this.ids.toString()
    });
  };
  onDeleteOaDealHander = async () => {
    const oAdealId = this.state.oAdealId;
    this.setState({
      waitingMsg: "Deleting..."
    });
    const journal = await this.props.deleteOaDeal(oAdealId);
    let data = this.state.data;
    let newData = data.filter(function(element) {
      return element.id !== oAdealId;
    });
    this.setState({
      waitingMsg: null,
      oAdealId: null,
      deleteConfirm: false,
      deleteStatus: journal.status,
      data: newData
    });
  };

  onEditOaDeal = id => {
    this.props.history.push("/oa-deal-activities/edit-oa-deal/" + id);
  };

  onApproval = async () => {
    let ids = this.state.oaDealIds;
    if (ids.length) {
      await this.props.oADealListApproval(ids);
      this.props.history.push("/action/oa-account-list");
    }
  };
  onReject = async () => {
    let ids = this.state.oaDealIds;
    await this.props.oADealListReject(ids);
  };
  onOaDealCancel = async () => {
    let ids = this.state.oaDealIds;
    await this.props.oADealListCancel(ids);
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      oAdealId: null,
      waitingMsg: null,
      deleteStatus: null
    });
  };

  capitalize = s => {
    if (s.trim() === "") return s;
    return s[0].toUpperCase() + s.slice(1);
  };

      // help menu function 
  HelpButtonClick = () => {
    const url = '/links';
    window.open(url, '_blank');
    
  }

  render() {
    const heading = [
      "organisation_name",
      "deal_type",
      "currency",
      "discount",
      "expiry",
      "vat_country_name",
      "status"
    ];
    const data = [];
    this.state.data &&
      this.state.data.map(item => {
        return data.push({
          id: item.id,
          organisation_name: item.organisation_name,
          deal_type: item.deal_name,
          currency: item.currency_name,
          discount: item.discount
            ? parseFloat(item.discount).toFixed(2) + "%"
            : "-",
          expiry: item.expire_date
            ? moment(item.expire_date).format(format)
            : "-",
          vat_country_name: item.vat_country_name && item.vat_country_name,
          status: item.approval_status && this.capitalize(item.approval_status),
          approval_status: item.approval_status
        });
      });

    return (
      <HubContent title="OA account approval" onHelpHandler={ this.HelpButtonClick}>
        {this.state.deleteConfirm && (
          <PopupBox
            title="Do you want to delete?"
            YesText="Yes"
            yesBtnType="danger"
            type="danger"
            NoBtnType="success"
            onCancel={this.onCancel}
            onPressOK={this.onDeleteOaDealHander}
            waitingMsg={this.state.waitingMsg}
          />
        )}
        <div className="row filter">
          <div className="col-md-8">
            <div className="row">
              {/* <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div>
              <div className="col-md-4">
                <SelectBox className="col-md-12" />
              </div> */}
            </div>
          </div>
          {/* <div className="col-md-4">
            <InputBox className="col-md-12" placeholder="Search here... " />
          </div> */}
          <div className="col-lg-12">
            <div className="card m-b-20">
              {this.state.data && (
                <DataTable
                  heading={heading}
                  data={data}
                  onSelect={this.onSelect}
                  checkbox={true}
                  isOaDealRequest={true}
                  noAction={true}
                />
              )}
              {this.state.data && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    marginBottom: 20
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    style={{ marginRight: 20 }}
                    onClick={this.onApproval}
                    disabled={!this.state.oaDealIds.length ? "disabled" : ""}
                  >
                    Accept
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    style={{ marginRight: 20 }}
                    onClick={this.onReject}
                    disabled={!this.state.oaDealIds.length ? "disabled" : ""}
                  >
                    Reject
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={this.onOaDealCancel}
                    disabled={!this.state.oaDealIds.length ? "disabled" : ""}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth || false,
  userId: state.auth.user.id || false,
  oaDealList: state.OaDeals.oadeal_request_list || false,
  orgId: state.auth.user.organisation_id || false
});
const mapDispatchToProps = dispatch => ({
  // oADealListPending: () => dispatch(oADealListPending()),
  getAllOaDealRequestList: payload =>
    dispatch(getAllOaDealRequestList(payload)),
  deleteOaDeal: id => dispatch(deleteOaDeal(id)),
  oADealListApproval: ids => dispatch(oADealListApproval(ids)),
  oADealListReject: ids => dispatch(oADealListReject(ids)),
  oADealListCancel: ids => dispatch(oADealListCancel(ids))
});

export const oaAccountApproval = connect(
  mapStateToProps,
  mapDispatchToProps
)(DB);
