import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";
import { ViewRow} from '../../../common';
import { history } from '../../../../route';
import moment from 'moment';



class ViewInvoice extends BaseComponent {
  render() {
    let transation = history.location.state.paymentData
    function numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    return (
        <HubContent>

            <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <ViewRow 
                                    label="Date"
                                    value={moment(transation.date_created).format('Do MMM YYYY')}
                                />
                                   <ViewRow 
                                    label="Invoice number"
                                    value={transation.article}
                                />
                                     
                                 <ViewRow 
                                    label="Article "
                                    value={transation.article}
                                />
                                     <ViewRow 
                                    label="Publisher"
                                    value={transation.publisher}
                                />
                                     <ViewRow 
                                    label="Currency"
                                    value={transation.currency}
                                />
                                     <ViewRow 
                                    label="Total"
                                    value={ numberWithCommas( parseFloat(transation.amount).toFixed(2))}
                                />
                                     
                                 <ViewRow 
                                    label="Payer org"
                                    value={transation.paid_by_organisation}
                                />
                                 <ViewRow 
                                    label="Download"
                                    value="Recipt"
                                />
                                </div>
                                </div>
                                </div>
                                </div>

    </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
});

export const Singleinvoice =  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ViewInvoice);
  
