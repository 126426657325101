import React from "react";
import AppSettings from "../../../../__base__/AppSettings";
import { connect } from "react-redux";
import { HubContent, } from "../../../../core/components";
import WhitePublisherCard from "./components/WhitePublisherCard";
import TopJournals from "./components/TopJournals";
import APC from "./components/APC";
import Iframe from "react-iframe";

import {
  Card,
  DBCard,
  DBChart,
  SelectBox,
} from "../../../../core/components/common";
import {
  getDashboardData,
  getInstitutionsList,
  getConsortiaMembersListAll,
  getPublicPublisher,
  getPremiumDashboardData,
  getoaDealFilterList,
  getChildOrganisationList,
  getArticlePublisher,
} from "../../../actions";
import ReadPublishList from "./components/ReadPublishList";
import { serialize } from "../../../../core/lib";


class DB extends AppSettings {
  state = {
    filterParams: {},
    showPop: false,
    onClose: false,
    executed: false,

  };
  HandleClick = () => {
    this.setState({
      showPop: !this.state.showPop,
      onClose: !this.state.onClose,
    });
  };
  HandleOnClose = () => {
    this.setState({
      showPop: false,
      onClose: false,
    });
  };

  defaultCurrency() {
    return ["£ 0.00", "$ 0.00", "€ 0.00"];
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  currencyCalculation(component) {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    if (component) {
      let gbp =
        "£ " +
        this.numberWithCommas(
          component.data.gbp ? parseFloat(component.data.gbp).toFixed(2) : 0.0
        );
      let usd =
        "$ " +
        this.numberWithCommas(
          component.data.usd ? parseFloat(component.data.usd).toFixed(2) : 0.0
        );
      let eur =
        "€ " +
        this.numberWithCommas(
          component.data.eur ? parseFloat(component.data.eur).toFixed(2) : 0.0
        );
      return [gbp, usd, eur];
    } else {
      return [];
    }
  }

  // currencyCalculation = (component) => {
  //   let gbp = '£ ' + this.numberWithCommas( parseFloat(component.data.gbp).toFixed(2));
  //   let usd = '$ ' + this.numberWithCommas( parseFloat(component.data.usd).toFixed(2));
  //   let eur = '€ ' + this.numberWithCommas( parseFloat(component.data.eur).toFixed(2));
  //   return [gbp, usd, eur];
  // }

  chartCalculation(component) {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let keys = ["funder", "journal", "publisher"];
    let values = [];
    if ("items" in component) {
      let data = component.items ? component.items : [];
      data.map((item) => {
        let ObjectKeys = Object.keys(item);
        let key = this.common(ObjectKeys, keys).toString();
        if (key === "funder") {
          values.push(item.total_funding);
        } else if (key === "publisher") {
          values.push(item.total_publish);
        } else {
          values.push(item.total);
        }
        return item;
      });
      return values;
    } else {
      return "data" in component ? Object.values(component.data) : [];
    }
  }

  async componentDidMount() {
    await this.props.getInstitutionsList();
    await this.props.getConsortiaMembersListAll();
    await this.props.getPublicPublisher();

    this.getDashboardData(
      (this.props.dashboardcontrol &&
        this.props.dashboardcontrol.dashboards &&
        this.props.dashboardcontrol.dashboards.DEFAULT) || 'BASIC'
    );
  }

  common(arr1, arr2) {
    var newArr = [];
    newArr = arr1.filter(function (v) {
      return arr2.indexOf(v) >= 0;
    });
    newArr.concat(
      arr2.filter(function (v) {
        return newArr.indexOf(v) >= 0;
      })
    );
    return newArr;
  }
  getLabels = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let keys = ["funder", "journal", "publisher"];
    if (component) {
      if ("items" in component) {
        let data = component.items ? component.items : [];
        return data.map((item) => {
          let ObjectKeys = Object.keys(item);
          let key = this.common(ObjectKeys, keys).toString();
          return typeof item && item[key] && item[key].name === "undefined"
            ? this.capitalize(item && item[key] && item[key].replace(/_/g, " "))
            : this.capitalize(item[key] && item[key].name && item[key].name.replace(/_/g, " ")) || [];
        });
      } else {
        let data = "data" in component ? Object.keys(component.data) : [];
        return data.map((item) => this.capitalize(item.replace(/_/g, " ")));
      }
    } else {
      return [];
    }
  };

  filterData = async (value) => {
    await this.setState((prevState) => {
      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });
    let filter = serialize(this.state.filterParams);
    this.props.getDashboardData({ filter });
  };

  capitalize(s) {
    return  s && s[0].toUpperCase() + s && s.slice(1);
  }

  renderTableBody = (data) => (
    <tbody>
      {Object.keys(data).map((key) => {
        return (
          <tr key={key}>
            <td>{this.capitalize(String(key.replace(/_/g, " ")))}</td>
            <td className="text-center">{data[key].toFixed(0)}</td>
          </tr>
        );
      })}
    </tbody>
  );

  formatMemberData = () => {
    let article_by_member =
      this.props.article_by_members &&
        this.props.article_by_members.status_code === 200
        ? this.props.article_by_members.data
        : [];
    let article_label = [];
    let accepted = [];
    let approved = [];
    let rejected = [];
    article_by_member.map((item) => {
      article_label.push(item.organisation_name);
      accepted.push(item.accepted);
      approved.push(item.approved);
      rejected.push(item.rejected);
      return accepted;
    });
  };
  formatProcessData(data) {
    Object.keys(data).filter((key) => {
      if (key === "avg_approved_days") {
        data["approved"] = data.avg_approved_days;
        delete data[key];
      } else if (key === "avg_rejected_days") {
        data["rejected"] = data.avg_rejected_days;
        delete data[key];
      }
      return data;
    });
    return data;
  }

  journalListFormat(data) {
    if (data) {
      let values = [];
      data.items.map((item) => {
        values.push({
          id: item.journal.id,
          name: item.journal.name,
          approved_journal: item.total,
        });
        return item;
      });
      return (values["items"] = values);
    } else {
      return [];
    }
  }

  onChangeSwitch = async (checked) => {
    this.setState(
      {
        executed: checked,
        hideTab: checked !== true ? true : false,
      },
      async () => {
        let dashboardType = this.state.executed === true ? 'PREMIUM' : 'BASIC';

        this.getDashboardData(dashboardType);
      }
    );
  };
  getDashboardData = async (dashboardType) => {
    try {
      this.setState({
        executed: dashboardType === 'PREMIUM',
      });

      await this.props.getPremiumDashboardData(dashboardType);

      if (this.props.dashboardcontrol
        && this.props.dashboardcontrol.dashboards
        && this.props.dashboardcontrol.dashboards.DEFAULT !== dashboardType
        && this.props.dashboardcontrol.dashboards.OTHERS.length > 0
      ) {
        let authData = this.props.dashboardcontrol;
        let dashboards = Object.assign({}, authData.dashboards);
        let others = dashboards.OTHERS;

        others = others.filter(item => item !== dashboardType);

        others.push(dashboards.DEFAULT);

        Object.assign(authData.dashboards, {
          DEFAULT: dashboardType,
          OTHERS: others,
        });

        localStorage.setItem("auth", JSON.stringify(authData));
      }

      if (dashboardType === 'PREMIUM') {
        this.setState({
          dashBoardURL: this.props.dashbaordPremiumData.data,
          success: this.props.dashbaordPremiumData.success,
        });
      }
    } catch (error) {
    }
  }
  chartfunderLabels = (data) => data && data.map((item) => item && item.funder);

  render() {
    this.formatMemberData();

    let article_by_member =
      this.props.article_by_members &&
        this.props.article_by_members.status_code === 200
        ? this.props.article_by_members.data
        : [];
    let article_label = [];
    let accepted = [];
    let approved = [];
    let rejected = [];
    article_by_member.map((item) => {
      article_label.push(item.organisation_name);
      accepted.push(item.accepted);
      approved.push(item.approved);
      return rejected.push(item.rejected);
    });
    let articleByMemberStackData = [
      {
        label: "Acceptance",
        backgroundColor: "rgba(129, 119, 194, .9)",
        data: accepted,
      },
      {
        label: "Approved",
        backgroundColor: "rgba(40, 187, 227, 0.9)",
        data: approved,
      },
      {
        label: "Declined",
        backgroundColor: "rgba(235, 239, 242, 0.9)",
        data: rejected,
      },
    ];

    let year = [
      {
        name: "All years",
        id: "all",
      },
    ];

    let publishers = [
      {
        name: "All publishers",
        id: "all",
      },
    ];

    for (let i = 2018; i <= 2050; i++) {
      year.push({
        name: i,
        id: i,
      });
    }

    let dummyAvailableFund = {
      data: { usd: 0, eur: 0, gbp: 0 },
    };
    let dummyTotalDeposit = {
      data: { usd: 0, eur: 0, gbp: 0 },
    };
    let dummyOaSpent = {
      data: { usd: 0, eur: 0, gbp: 0 },
    };
    let dummyOffset = {
      data: { usd: 0, eur: 0, gbp: 0 },
    };

    let deal = [
      { name: "All deals", id: "all" },
      { name: "OA Deal", id: "deal" },
      { name: "Non OA Deal", id: "non-deal" },
    ];

    let dummyApcData = {
      average: {
        gbp: 0,
        eur: 0,
        usd: 0,
      },
      highest: {
        gbp: 0,
        eur: 0,
        usd: 0,
      },
      lowest: {
        gbp: 0,
        eur: 0,
        usd: 0,
      },
    };

    let dummyReadAndPublishData = {
      data: {
        usd: {
          read_fee: 0.0,
          publish_fee: 0.0,
          total_fee: 0.0,
          total_articles: 0,
          average_apc_cost: 0,
        },
        eur: {
          read_fee: 0.0,
          publish_fee: 0.0,
          total_fee: 0.0,
          total_articles: 0,
          average_apc_cost: 0,
        },
        gbp: {
          read_fee: 0.0,
          publish_fee: 0.0,
          total_fee: 0.0,
          total_articles: 0,
          average_apc_cost: 0,
        },
      },
    };

    let dummyAllocatedOffsetData = {
      data: { usd: 0, eur: 0, gbp: 0 },
    };

    let dummySpentOffsetData = {
      data: { usd: 0, eur: 0, gbp: 0 },
    };

    let dummyArticleSummaryData = {
      data: {
        accepted: 0,
        approved: 0,
        rejected: 0,
      },
    };

    let dummyTokenSummaryData = {
      data: {
        available: 1,
        allocated: 2,
        spent: 1,
      },
    };

    let dummyArticleFundersData = {
      data: {
        accepted: 0,
        approved: 0,
        rejected: 0,
      },
    };

    let dummyArticlePublishersData = { data: {} };
    let dummyOaJournalData = {
      data: { gold: 0, hybrid: 0 },
    };

    let dummyJournalsTableData = {
      data: {
        items: [],
      },
    };

    let institutionsTop = [
      {
        name: "All members",
        id: "all",
      },
    ];
    let manage_dashboard = this.props.dashboardcontrol &&  this.props.dashboardcontrol.permissions && this.props.dashboardcontrol.permissions.find(item=>item==="manage_dashboard") 
   
    let manage_premium_dashboard =this.props.dashboardcontrol &&  this.props.dashboardcontrol.permissions && this.props.dashboardcontrol.permissions.find(item=>item==="manage_premium_dashboard") 
    return (
      <HubContent
      manage_premium_dashboard={
        manage_premium_dashboard
        
        }
        manage_dashboard={manage_dashboard}

    hideTab={this.state.hideTab}
    executed={this.state.executed}
    onChangeSwitch={this.onChangeSwitch}
    dashboardcontrol={true}
    article={this.props.dashbaordData.estimated_cost_summary_component}
      >
        {this.state.executed !== true ? (
         manage_dashboard==="manage_dashboard"? <div>
        <div className="row mb-4">
          {/*
            Filtering Dashboard Components
          */}
          <SelectBox
            className="col-md-3"
            data={deal}
            onChange={this.filterData}
            field="filter_deal"
            defaultValue={"all"}
          />
          <SelectBox
            className="col-md-3"
            data={
              this.props.consortia_list.results
                ? this.props.consortia_list.results.concat(institutionsTop)
                : []
            }
            onChange={this.filterData}
            field="filter_member_organisation"
            defaultValue={"all"}
          />
          <SelectBox
            className="col-md-3"
            data={
              this.props.publishers_list
                ? this.props.publishers_list.results.concat(publishers)
                : []
            }
            onChange={this.filterData}
            field="filter_publisher"
            defaultValue={"all"}
          />
          <SelectBox
            className="col-md-3"
            data={year}
            onChange={this.filterData}
            field="filter_year"
            defaultValue={"all"}
          />

          {/*
            End of Filtering Dashboard Components
          */}
        </div>
  

        <div className="row" style={{ marginBottom: 30 }}>
          <DBCard
            icon="mdi mdi-cube-outline"
            title="AVAILABLE FUNDS"
            className="col-md-3"
            body={
              this.props.available_fund || dummyAvailableFund
                ? this.currencyCalculation(
                  this.props.available_fund
                    ? this.props.available_fund
                    : dummyAvailableFund
                )
                : this.defaultCurrency()
            }
          />
          <DBCard
            icon="mdi mdi-buffer"
            title="Total deposit"
            className="col-md-3"
            body={
              this.props.total_deposit || dummyTotalDeposit
                ? this.currencyCalculation(
                  this.props.total_deposit
                    ? this.props.total_deposit
                    : dummyTotalDeposit
                )
                : this.defaultCurrency()
            }
          />
          <DBCard
            icon="mdi mdi-tag-text-outline"
            title="OA spent"
            className="col-md-3"
            body={
              this.props.oa_spent || dummyOaSpent
                ? this.currencyCalculation(
                  this.props.oa_spent ? this.props.oa_spent : dummyOaSpent
                )
                : this.defaultCurrency()
            }
          />
          <DBCard
            icon="mdi mdi-cube-outline"
            title="Credit amount"
            className="col-md-3"
            body={
              this.props.credit_due_data
                ? this.currencyCalculation(this.props.credit_due_data)
                : this.defaultCurrency()
            }
          />
        </div>
        <div className="row" style={{ marginBottom: 30 }}>
          <DBChart
            title="Articles"
            type="doughnut"
            chartMarginTopClassName="m-t-40"
            width={220}
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            height={220}
            className="col-md-4"
            showtitle="This doughnut chart represents all the articles are pending for approval, approved and declined for APC."
            containerHeight={320}
            labels={["Acceptance", "Approved", "Declined"]}

            data={this.chartCalculation(
              this.props.article_summary_data || dummyArticleSummaryData
            )}
          />
          <ReadPublishList
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            showtitle="This table represents the total amount for Read &amp; Publish deal, the number of articles approved and their average APC."
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            data={
              (this.props.read_and_publish_deal &&
                this.props.read_and_publish_deal.data) ||
              dummyReadAndPublishData.data
            }
            className="col-md-5 read_publish no_background color margin"
          />

          <div className="col-md-3" style={{ margin: "0", padding: "0" }}>
            <WhitePublisherCard
              padding="Credit_paid"
              icon="mdi mdi-buffer"
              onClick={(e) => this.HandleClick(e)}
              showpop={this.state.showPop}
              onClose={(e) => this.HandleOnClose(e)}
              titleIcon="info-circle"
              title="Credit paid"
              showtitle="This represents that amount paid by institutions under credit deal"
              body={
                this.props.credit_paid_data
                  ? this.currencyCalculation(this.props.credit_paid_data)
                  : this.defaultCurrency()
              }
            />
            {/* <WhitePublisherCard
              icon="mdi mdi-briefcase-check"
              title="Spent offset"
              body={this.currencyCalculation(this.props.spent_offset_data || dummySpentOffsetData)}
            /> */}
          </div>
        </div>
        <div className="row" style={{ marginBottom: 30 }}>
          <DBChart
            className="col-md-8"
            title="Articles by members"
            type="stacked-bar"
            showtitle="This represents that amount paid by institutions under credit deal."
            height={255}
            stacked={true}
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            minRotation={0}
            barOrientation={"horizontal" || "vertical"}
            isShowLabel={true}
            labels={article_label || []}
            data={articleByMemberStackData || []}
          />
          <DBChart
            height={220}
            containerHeight={320}
            chartMarginTopClassName="m-t-40"
            title="Books"
            showtitle="This doughnut chart represents all the books are pending for approval, approved and declined for APC."
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            className="col-md-4"
            type="doughnut"
 
            labels={["Acceptance", "Approved", "Declined"]}

            data={this.chartCalculation(
              this.props.publishers
                ? this.props.publishers.data
                : [] || dummyArticlePublishersData
            )}
          />
        </div>
        <div className="row" style={{ marginBottom: 30 }}>
          <DBChart
            height={220}
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            containerHeight={320}
            showtitle="This chart represents the top three institutions approved books and journal articles."
            chartMarginTopClassName="m-t-40"
            title="Publishers"
            className="col-md-4"
            type="doughnut"
            labels={["Acceptance", "Approved", "Declined"]}

            data={this.chartCalculation(
              this.props.publishers
                ? this.props.publishers.data
                : [] || dummyArticlePublishersData
            )}
          />

          <div className="col-md-4">
            <div className="row">
              <DBChart
                height={220}
                containerHeight={320}
                onClick={(e) => this.HandleClick(e)}
                showpop={this.state.showPop}
                onClose={(e) => this.HandleOnClose(e)}
                titleIcon="info-circle"
                chartMarginTopClassName="m-t-40"
                title="Funders"
                showtitle="This chart represents the top five funders for the approved articles."
                className="col-md-12"
                type="doughnut"
                  labels={this.chartfunderLabels(
                     this.props.funders &&
                      this.props.funders.data
                        .items
                      
                  )}
                data={this.chartCalculation(
                  this.props.funders
                    ? this.props.funders.data
                    : [] || dummyArticleFundersData
                )}
              />
            </div>
          </div>

          <DBChart
            title="Journals"
            showtitle="This represents total articles approved by journal type."
            height={220}
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            containerHeight={320}
            type="bar"
            labels={["Platinum", "Hybrid", "Gold"]}
            data={this.chartCalculation(
              this.props.journals
                ? this.props.journals.data
                : [] || dummyOaJournalData
            )}
            // height={280}
            //containerHeight={245}
            chartMarginTopClassName="m-t-40"
            className="col-md-4"
            isShowLabel={false}
            grid={false}
            titleClass="mb-4"
          />
        </div>

        <div className="row" style={{ marginBottom: 30 }}>
          <WhitePublisherCard
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            showtitle="This represents the total offset fund given to the institution(s)."
            icon="mdi mdi-briefcase-check"
            className="col-md-4"
            title="Allocated offset"
            body={this.currencyCalculation(
              this.props.allocated_offset_data || dummyAllocatedOffsetData
            )}
          />

          <WhitePublisherCard
            icon="mdi mdi-briefcase-check"
            title="OFFSET"
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            showtitle="This represents the total offset fund available with the institution(s)."
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            className="col-md-4"
            body={
              this.props.offset_data || dummyOffset
                ? this.currencyCalculation(
                  this.props.offset_data
                    ? this.props.offset_data
                    : dummyOffset
                )
                : this.defaultCurrency()
            }
          />
          <WhitePublisherCard
            icon="mdi mdi-briefcase-check"
            title="Spent offset"
            showtitle="This represents the total offset fund spent for approving articles by the institution(s)."
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            className="col-md-4"
            body={this.currencyCalculation(
              this.props.spent_offset_data || dummySpentOffsetData
            )}
          />
        </div>

        <div className="row" style={{ marginBottom: 30 }}>
          <APC
            showtitle="This represents the total offset fund spent for approving articles by the APC."
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            data={
              this.props.apc_revenue_compare_component &&
                this.props.apc_revenue_compare_component.status_code === 200
                ? this.props.apc_revenue_compare_component
                : dummyApcData
            }
            height={320}
            className="col-md-4 no_background"
          />

          <Card
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            showtitle="This represents the total offset fund spent for approving articles by the Average(s)."
            className="col-md-4 no_background "
            title="Average"
            height={320}
          >
            <table className="table table-hover mb-0 mb-0">
              <thead>
                <tr>
                  <td className="padding"></td>
                  <th className="text-center">Days</th>
                </tr>
              </thead>

              {this.renderTableBody(
                this.formatProcessData(
                  this.props.average ? this.props.average.data : {}
                ) || { approval: 0, rejections: 0 }
              )}
            </table>
          </Card>
          <DBChart
            width={220}
            height={220}
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            title="Tokens"
            showtitle="This pie chart represents the total number of tokens given to the institution(s), how many
articles have been used and available tokens."
            className="col-md-4"
            chartMarginTopClassName="m-t-40"
            containerHeight={320}
            type="pie"
            labels={this.getLabels(
              this.props.token_summary_data || dummyTokenSummaryData
            )}
            data={this.chartCalculation(
              this.props.token_summary_data || dummyTokenSummaryData
            )}
          />
        </div>
        <div className="row" style={{ marginBottom: 30 }}>
          <TopJournals
            onClick={(e) => this.HandleClick(e)}
            showpop={this.state.showPop}
            onClose={(e) => this.HandleOnClose(e)}
            titleIcon="info-circle"
            className="col-md-8 top_journals_padding TopJournals"
            data={
              this.props.journal_list
                ? this.journalListFormat(this.props.journal_list.data)
                : dummyJournalsTableData.data
            }
            title="Journals"
            height={320}
            showtitle="This pie chart represents the total number of tokens given to the institution(s), how many
articles have been used and available tokens."
          />
        </div>  </div>:<div className="card">
              <div className="card-body">
              {this.state.executed !== true && <h6>Please slide right to view the dashboard.</h6>}
          </div>
      </div>): manage_premium_dashboard === "manage_premium_dashboard" && this.state.success && this.state.dashBoardURL ? (
            <Iframe
              url={this.state.dashBoardURL}
              width="100%"
              height="800px"
              id="myId"
              frameBorder={0}
              className="myClassname"
              display="initial"
              position="relative"
              allow="fullscreen"
            />
          ) :  null}
      </HubContent>
    );
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth || false,
  data: state.dashboard || false,
  dashbaordData: state.dashboard || false,
  dashboardcontrol: state.auth,
  dashbaordPremiumData: state.dashboard.premium_dashboard || false,
  available_fund:
    state.dashboard &&
    (state.dashboard.consortia_available_fund_component || false),
  total_deposit:
    state.dashboard &&
    (state.dashboard.consortia_total_deposit_component || false),
  oa_spent:
    state.dashboard && (state.dashboard.consotia_oa_spent_component || false),
  offset_data:
    state.dashboard &&
    (state.dashboard.consortia_offset_summary_component || false),
  article_summary_data:
    state.dashboard &&
    (state.dashboard.consortia_article_summary_component || false),
  token_summary_data:
    state.dashboard &&
    (state.dashboard.consortia_token_summary_component || false),
  allocated_offset_data:
    state.dashboard &&
    (state.dashboard.consortia_allocated_offset_component || false),
  spent_offset:
    state.dashboard &&
    (state.dashboard.consortia_spent_offset_component || false),
  article_by_members:
    state.dashboard &&
    (state.dashboard.consortia_article_summary_by_members_component || false),
  read_and_publish_deal: state.dashboard
    ? state.dashboard.consortia_read_and_publish_deal_component
    : false,
  credit_due_data: state.dashboard.credit_due_component || false,
  credit_paid_data: state.dashboard.credit_paid_component || false,
  institution_book_summary_component:
    state.dashboard.institution_book_summary_component || false,
  funders:
    state.dashboard && (state.dashboard.consortia_funder_component || false),
  journals_data:
    state.dashboard && (state.dashboard.available_offset_component || false),
  publishers:
    state.dashboard && (state.dashboard.consortia_publisher_component || false),
  apc_revenue_compare_component:
    state.dashboard &&
    (state.dashboard.consortia_average_apc_component
      ? state.dashboard.consortia_average_apc_component.data
      : false),
  journals:
    state.dashboard && (state.dashboard.consortia_journal_component || false),
  average:
    state.dashboard &&
    (state.dashboard.consortia_avarage_approve_reject_component || false),
  journal_list:
    state.dashboard &&
    (state.dashboard.consortia_journal_list_component || false),
  publishers_list: state.orgs && (state.orgs.publisher_list || false),
  institute_list: (state.institutions && state.institutions.institution_list )|| [],
  consortia_list: state.consortia.consortia_list || false,
  consortia_count: state.consortia.consortia_list
    ? state.consortia.consortia_list.count
    : false || false,
  
  
});
const mapDispatchToProps = (dispatch) => ({
  getConsortiaMembersListAll: () => dispatch(getConsortiaMembersListAll()),
  getDashboardData: (payload) => dispatch(getDashboardData(payload)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
  getPublicPublisher: () => dispatch(getPublicPublisher()),
  getArticlePublisher: () => dispatch(getArticlePublisher()),
  getoaDealFilterList: () => dispatch(getoaDealFilterList()),
  getPremiumDashboardData: (payload) =>
    dispatch(getPremiumDashboardData(payload)),
  getChildOrganisationList: (payload) =>
    dispatch(getChildOrganisationList(payload)),
});
export const ConsDashboard = connect(mapStateToProps, mapDispatchToProps)(DB);
