import config, {
    FETCH_OFFER_DETAILS,
    SUCCESS_OFFER_DETAILS,
    FAIL_OFFER_DETAILS, REQUEST_CREATE_SOCIETY_MEMBER, GET_ALL_SOCIETY_MEMBER_LIST, GET_SOCIETY_LIST, REQUEST_UPDATE_SOCIETY_MEMBER, GET_ALL_SOCIETY_MEMBER, SINGLE_SOCIETY_MEMBER
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

export const recievedUserData = (payload) => ({
    type: REQUEST_CREATE_SOCIETY_MEMBER,
    payload
})

export const updateSocietyMemberShipAction = (payload) => ({
    type: REQUEST_UPDATE_SOCIETY_MEMBER,
    payload
})

export const getAllSocietyMemberShipAction = (payload) => ({
    type: GET_ALL_SOCIETY_MEMBER,
    payload
})

export const singleSocietyMemberShipAction = (id) => ({
    type: SINGLE_SOCIETY_MEMBER,
    id
})

export const getAllSocietyMemberListAction = (payload) => ({
    type: GET_ALL_SOCIETY_MEMBER_LIST,
    payload
})

// Get Society list
export const getSocietyListAction = (payload) => ({
    type: GET_SOCIETY_LIST,
    payload
});

export const getOfferDetailSuccessAction = (payload) => ({
    type: SUCCESS_OFFER_DETAILS,
    payload
});

export const getOfferDetailRequestAction = (payload) => ({
    type: FETCH_OFFER_DETAILS,
});
export const getOfferDetailFailAction = (payload) => ({
    type: FAIL_OFFER_DETAILS,
});




export const CreateSocietyMemberShip = (payload) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            api.post(config.endpoint.society_membership, payload).then((res) => {
                dispatch(recievedUserData(res.results))
                resolve(Object.assign(res, { status: true }));
            }).catch(function (error) {
                resolve(Object.assign(error, { status: false }));
            })
        })
    }
}

export const UpdateSocietyMemberShip = (id, payload) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            api.put(config.endpoint.society_membership + id + '/', payload).then((res) => {
                dispatch(updateSocietyMemberShipAction(res.results))
                resolve(Object.assign(res, { status: true }));
            }).catch(function (error) {
                resolve(Object.assign(error, { status: false }));
            })
        })
    }
}
export const deleteSocietyMemberShip = (id) => {
    return (dispatch, getState) => {
        let url = config.endpoint.society_membership + id + "/";

        return new Promise((resolve, reject) => {
            api.delete(url)
                .then(res => {
                    resolve(Object.assign(res, { status: true }));
                })
                .catch(function (error) {
                    reject(Object.assign(error, { status: false }));
                });
        });
    };
}

export const getSingleSocietyMemberShip = (id) => {
    return dispatch => {
        const url = `${config.endpoint.society_membership}${id}`;
        return new Promise((resolve, reject) => {
            api.get(url)
                .then(res => {
                    dispatch(singleSocietyMemberShipAction(res));
                    resolve(Object.assign(res, { status: true }));
                })
                .catch(e => {
                    reject(e);
                });
        });
    };
}




export const getArticleSocietyOfferDetails = (id, payload) => {
    return dispatch => {
        const url = payload && payload.post_check_membership_update === true ? `${config.endpoint.article_full}${id}/offer-details/?post_check_membership_update=true` : `${config.endpoint.article_full}${id}/offer-details/ `;
        return new Promise((resolve, reject) => {
            dispatch(getOfferDetailRequestAction());

            api.get(url)
                .then(res => {
                    dispatch(getOfferDetailSuccessAction(res));
                    resolve(Object.assign(res, { status: true }));
                })
                .catch(e => {
                    dispatch(getOfferDetailFailAction(e));
                });
        });
    };
}



export const getAllSocietyMemberShip = () => {
    return (dispatch) => {
        let url = `${config.endpoint.society_membership}?page_size=999999`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getAllSocietyMemberShipAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(error => {
                reject(Object.assign(error, { status: false }))
            })
        })
    }
}


export const getAllSocietyMemberList = () => {
    return (dispatch) => {
        let url = `${config.endpoint.society_list}`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getAllSocietyMemberListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(error => {
                reject(Object.assign(error, { status: false }))
            })
        })
    }
}

export const getSocietyList = (payload = {}) => {
    return (dispatch) => {
        let url = config.endpoint.society_list;
        let params = {};

        if (payload.pageNum && payload.pageSize) {
            params['page'] = payload.pageNum;
            params['page_size'] = payload.pageSize;
        } else if (payload.pageNum) {
            params['page'] = payload.pageNum;
        } else if (payload.pageSize) {
            params['page_size'] = payload.pageSize;
        }

        if (!params.page_size) {
            params['page_size'] = 99999;
        }

        url += `?${serialize(params)}`;

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getSocietyListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}
