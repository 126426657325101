import React from "react";
import { Provider } from "react-redux";
import { Spin } from "antd";
import ReactGA from 'react-ga';

import store from "../core/context/store";
import { AppRouter } from "../openaccess/route/Settings";
import { isLoggedIn } from "../core/middlewares";
import CacheBuster from "./CacheBuster";
import * as serviceWorker from "./serviceWorker";

isLoggedIn(store);

class App extends React.Component {
  state = { loading: true };
  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  wait = async (milliseconds = 2000) => {
    await this.sleep(milliseconds);
    this.setState({
      loading: false,
    });
  };
  initReactGA = () => {
    //298135376
    ReactGA.initialize('G-C5ZBHL5798');
    ReactGA.pageview('test-init-pageview');
  };
  componentDidMount() {
    this.wait(2000);
    this.initReactGA();

  }
  render() {
    return this.state.loading ? (
      <div className="flex-container-spiner">
        <div className="spiner-ui">
          <h3>Loading....</h3>
          <div className="spiner">
            <Spin size="large" />
          </div>
        </div>
      </div>
    ) : (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Provider store={store}>
              <AppRouter />
            </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
