import React, { Component } from 'react'
import {
    RadioButtonGroup,
} from "../../../../../../core/components/common";
import VatTable from "../vatTable";
 class SelfCountryName extends Component {
    render() {
        return (
              
                

            <div>
            <div className="vat-question-panel">
              <p> Does your APC fee include VAT?</p>
              <RadioButtonGroup
                onChange={this.props.onChangeHandleRadioBox}
                field="apc_including_vat"
                options={[
                  { id: true, name: "Yes" },
                  { id: false, name: "No" },
                ]}
                value={this.props.apc_including_vat}
              />
            </div>

            {this.props.apc_including_vat === false && (
              <div className="vat-question-panel">
                <p>
                  Do you charge VAT to customers in the {this.props.origin} if
              they provide a valid VAT number?
            </p>
                <RadioButtonGroup
                  onChange={this.props.onChangeHandleRadioBox}
                  field="charge_vat_number"
                  options={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                  value={this.props.charge_vat_number}
                />
              </div>
            )}

            {this.props.apc_including_vat !== true ? (
              this.props.charge_vat_number === true ? (
                <div className="vat-country">
                  <div className="vat-country-details">
                    <div className="vat-info">
                      <p>
                        Add a VAT rate for the customers in the
                    {this.props.origin} who have a valid VAT number.
                  </p>
                    </div>
                  </div>
                  <div className="col-12 vat-margin">
                    <VatTable
                      data={this.props.selfCountryVat_1}
                      editHandler={(value) =>this.props.EditHandler(value)}
                    />
                  </div>
                </div>
              ) : null
            ) : null}
            {this.props.apc_including_vat !== true
              ? this.props.charge_vat_number === true && (
                <div className="vat-question-panel">
                  <p>
                    Do you charge VAT to customers in the{" "}
                    {this.props.origin} if they do not have a valid VAT
                  number?
                </p>

                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="charge_no_vat_number_1"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.charge_no_vat_number_1}
                  />
                </div>
              )
              : null}

            {this.props.apc_including_vat !== true
              ? this.props.charge_vat_number !== false
                ? this.props.charge_no_vat_number_1 === true && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Add VAT rate for the customers in the{" "}
                          {this.props.origin} who have no valid VAT number
                      </p>
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        data={this.props.selfCountryVat_2}
                        editHandler={(value) =>this.props.EditHandler_2(value)}
                      />
                    </div>
                  </div>
                )
                : null
              : null}

            {this.props.apc_including_vat !== true
              ? this.props.charge_vat_number === false && (
                <div className="vat-question-panel">
                  <p>
                    Do you charge VAT to customers in the
                  {this.props.origin} if they do not have a valid VAT
                  number?
                </p>

                  <RadioButtonGroup
                    onChange={this.props.onChangeHandleRadioBox}
                    field="not_charge_vat_number_2"
                    options={[
                      { id: true, name: "Yes" },
                      { id: false, name: "No" },
                    ]}
                    value={this.props.not_charge_vat_number_2}
                  />
                </div>
              )
              : null}
            {this.props.apc_including_vat !== true
              ? this.props.charge_vat_number !== true
                ? this.props.not_charge_vat_number_2 === true && (
                  <div className="vat-country">
                    <div className="vat-country-details">
                      <div className="vat-info">
                        <p>
                          Add VAT rate for the customers in the{" "}
                          {this.props.origin} who have no valid VAT number
                      </p>
                      </div>
                    </div>
                    <div className="col-12 vat-margin">
                      <VatTable
                        heading={this.props.headingforeu}
                        data={this.props.selfCountryVat_2}
                        editHandler={(value) => this.props.EditHandler_2(value)}
                      />
                    </div>
                  </div>
                )
                : null
              : null}
            <div></div>
          </div>
        )
    }
}

export const VatSelfCountryName= SelfCountryName