import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import BaseComponent, { HubContent } from "../../../../../core/components";
import {
  getOaDisciplineList,
  createUpdateOaSubject,
  uploadOaSubject,
} from "../../../../actions";
import {
  PopupBox,
  Loading,
  TextBox,
  InputBox,
  SelectBox,
} from "../../../../../core/components/common";
import DragAndDrop from "../../../partials/DragAndDrop";

class Add extends BaseComponent {
  state = {
    subject: {},
  };

  async componentDidMount() {
    await this.props.getOaDisciplineList();
  }

  onChangeHandler = (value) => {
    this.setState((prevState) => {
      return Object.assign(prevState.subject, value);
    });
  };

  onSubmitHandler = async (e) => {
    e.preventDefault();

    let data = this.state.subject;
    if (!data.discipline || !data.name) {
      this.setState({
        isEmpty: (
          <div>
            {!data.discipline && <p>Discipline must be selected</p>}
            {!data.name && <p>Subject name must be filled</p>}
          </div>
        ),
      });
    } else {
      this.setState({
        isRegistering: true,
      });
      await this.props.createUpdateOaSubject(data);

      this.cancelLoader();

      if (this.props.oa_subject.success) {
        this.props.history.push("/subjects/subject-list");
      }
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      isEmpty: false,
      fileUploadStatus: null,
      fileUploadStatusMsg: null,
    });
  };

  uploadOaSubjectHandler = async (payload) => {
    this.setState({
      isRegistering: true,
    });

    await this.props.uploadOaSubject(payload);

    this.setState({
      fileUploadStatus: this.props.oa_subject_file_upload.status,
      fileUploadStatusMsg: this.props.oa_subject_file_upload.data.message,
      isRegistering: false,
    });
  };

  onFileDrop = (payload) => {
    confirmAlert({
      title: "Upload subject",
      message: "Are you sure, you want to upload file?",
      buttons: [
        {
          label: "Upload",
          onClick: () => this.uploadOaSubjectHandler(payload),
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    let errorHander = (
      <>
        {this.state.isEmpty && (
          <PopupBox
            title="Required Information Missing"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.fileUploadStatus === false && (
          <PopupBox
            title="Bad Request"
            msg={
              this.state.fileUploadStatusMsg && this.state.fileUploadStatusMsg
            }
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.fileUploadStatus === true && (
          <PopupBox
            title="Success"
            msg={this.state.fileUploadStatusMsg}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    return (
      <HubContent>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}
        {/* LoadinG Handling */}
        {this.state.isRegistering && <Loading />}
        {/* LoadinG Handling */}
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Add Subject
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#profile-1"
                      role="tab"
                    >
                      Upload Subjects
                    </a>
                  </li>
                </ul>
                <div className="tab-content books">
                  <div
                    className="tab-pane active p-3"
                    id="home-1"
                    role="tabpanel"
                  >
                    <form>
                      <div className="form-group row">
                        <SelectBox
                          label="Discipline"
                          onChange={this.onChangeHandler}
                          field="discipline"
                          data={this.props.oa_disciplines && this.props.oa_disciplines.results}
                          isRequired={true}
                          className="col-sm-9"
                          labelClass="col-sm-3"
                        />
                      </div>
                      <div className="form-group row">
                        <InputBox
                          label="Name"
                          onChange={this.onChangeHandler}
                          field="name"
                          placeholder="Subject name"
                          className="col-sm-9"
                          labelClass="col-sm-3"
                          isRequired={true}
                        />
                      </div>
                      <div className="form-group row">
                        <TextBox
                          label="Description"
                          onChange={this.onChangeHandler}
                          field="description"
                          placeholder="Description"
                          className="col-sm-9"
                          labelClass="col-sm-3"
                        />
                      </div>

                      <div className="text-center m-t-15">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.onSubmitHandler}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane p-3" id="profile-1" role="tabpanel">
                    <div className="m-b-30">
                      <DragAndDrop onDropHandler={this.onFileDrop} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  oa_disciplines: state.OaDiscipline.oa_disciplines || false,
  oa_subject: state.OaSubject.oa_subject || false,
  oa_subject_file_upload: state.OaSubject.oa_subject_file_upload || false,
});

const mapDispatchToProps = (dispatch) => ({
  getOaDisciplineList: (payload) => dispatch(getOaDisciplineList(payload)),
  createUpdateOaSubject: (payload) => dispatch(createUpdateOaSubject(payload)),
  uploadOaSubject: (payload) => dispatch(uploadOaSubject(payload)),
});

export const AddSubject = connect(mapStateToProps, mapDispatchToProps)(Add);
