import config, {
	GET_MEMBERSHIP_DISCOUNT_LIST,
	GET_MEMBERSHIP_DISCOUNT,
	CREATE_UPDATE_MEMBERSHIP_DISCOUNT,
	DELETE_MEMBERSHIP_DISCOUNT,
	REQUEST_MEMBERSHIP_DISCOUNT, REQUEST_MEMBERSHIP_DISCOUNT_LIST
} from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

// Get Membership Member list
export const getMembershipDiscountListAction = (payload) => ({
	type: GET_MEMBERSHIP_DISCOUNT_LIST,
	payload,
});

// Get Membership Member
export const getMembershipDiscountAction = (payload) => ({
	type: GET_MEMBERSHIP_DISCOUNT,
	payload,
});

// Create-Update Membership Member
export const createUpdateMembershipDiscountAction = (payload) => ({
	type: CREATE_UPDATE_MEMBERSHIP_DISCOUNT,
	payload,
});

// Delete Membership Member
export const deleteMembershipDiscountAction = (payload) => ({
	type: DELETE_MEMBERSHIP_DISCOUNT,
	payload,
});
export const requestMembershipDiscountAction = () => ({
	type: REQUEST_MEMBERSHIP_DISCOUNT,
});
export const requestMembershipDiscountListAction = () => ({
	type: REQUEST_MEMBERSHIP_DISCOUNT_LIST,
});



export const getMembershipDiscountList = (payload = {}) => {
	return (dispatch) => {
		let url = config.endpoint.membership_discount;
		let params = {};

		if (payload.pageNum && payload.pageSize) {
			params["page"] = payload.pageNum;
			params["page_size"] = payload.pageSize;
		} else if (payload.pageNum) {
			params["page"] = payload.pageNum;
		} else if (payload.pageSize) {
			params["page_size"] = payload.pageSize;
		}

		if (!params.page_size) {
			params["page_size"] = 99999;
		}

		url += `?${serialize(params)}`;
		dispatch(requestMembershipDiscountListAction());

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getMembershipDiscountListAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Get discount config
export const getMembershipDiscount = (id) => {
	return (dispatch) => {
		let url = config.endpoint.membership_discount + `${id}/`;
		dispatch(requestMembershipDiscountAction());

		return new Promise((resolve, reject) => {
			api.get(url)
				.then((res) => {
					dispatch(getMembershipDiscountAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Create-Update discount config

export const createUpdateMembershipDiscount = (payload, id) => {
	return (dispatch) => {
		let url = config.endpoint.membership_discount;
		let apiRequest = "";
		return new Promise((resolve, reject) => {
			if (id) {
				url += `${id}/`;
				apiRequest = api.put(url, payload);
			} else {
				apiRequest = api.post(url, payload);
			}

			apiRequest
				.then((res) => {
					dispatch(createUpdateMembershipDiscountAction(res.data));
					resolve(Object.assign(res.data, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};

// Delete discount config
export const deleteMembershipDiscount = (id) => {
	return (dispatch) => {
		let url = config.endpoint.membership_discount + `${id}/`;

		return new Promise((resolve, reject) => {
			api.delete(url)
				.then((res) => {
					dispatch(deleteMembershipDiscountAction(res));
					resolve(Object.assign(res, { status: true }));
				})
				.catch((e) => {
					reject(e);
				});
		});
	};
};
