import React from "react";
import { connect } from "react-redux";
// import moment from "moment";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { getRefundRequestAllInfo } from "../../../../actions";
import {
    ExpandHeadingTable,
    PaginationForm,
} from "../../../../../core/components/common";
import { requestHeading, column } from "../../../../../core/lib";
import RefundRequest from "./RefundForm"
class RefundRequestForm extends BaseComponent {
    state = {
        activeItem: null,
    };

    onItemActive = (item) => {
        this.setState({ activeItem: item });
    };
    componentDidMount() {
        this.getFundRequestList();
    }
    getFundRequestList = async (pageNum = 1, page = 10) => {
        await this.props.getRefundRequestAllInfo({ pageNum: pageNum, pageSize: page });
    };

    pageChange = (pageNumber) => {
        this.getFundRequestList(pageNumber);
    };

    render() {
        let { refund_request_list } = this.props;

        return (
            <HubContent
                title="Refund request"
            >
                <div className="row filter">

                    <div className="col-12">
                        <div className="card m-b-20">
                            <ExpandHeadingTable heading={requestHeading} />
                            <div>
                                {
                                    refund_request_list && refund_request_list.results && refund_request_list.results.map((item, index) => (
                                        <div id="accordion" key={index}>
                                            <RefundRequest
                                                onActionHandler={this.onActionHandler}
                                                invoiceUpdate={true}
                                                key={index}
                                                item={item}
                                                column={column}
                                            />
                                        </div>
                                    ))


                                }
                                {refund_request_list && refund_request_list.results && refund_request_list.results.length > 0 ? (
                                    <PaginationForm
                                        pageSize={10}
                                        pageChange={this.pageChange}
                                    // count={publisher_refund_request_list.count}
                                    />
                                ) : null}
                            </div>

                        </div>
                    </div>
                </div>
            </HubContent>
        );
    }
}

const mapStateToProps = (state) => ({
    refund_request_list: state.RefundRequest.publisher_refund_request_list || [],
});

const mapDispatchToProps = (dispatch) => ({
    getRefundRequestAllInfo: (payload) => dispatch(getRefundRequestAllInfo(payload)),
});

export const RefundRequestList = connect(
    mapStateToProps,
    mapDispatchToProps
)(RefundRequestForm);
