import React from 'react';
import BaseComponent from '..';
import { Switch } from 'antd';

export class OaSwitch extends BaseComponent {
    state = {
        msg: this.props.errMsg || '',
        checked: this.props.checked || false,
    }

    onChange = (checked) => { 
        this.setState({ checked })
        this.props.onChange && this.props.onChange({ [this.props.field]: checked })
    }

    componentDidUpdate() {
        this.props.errMsg && setTimeout(() => {
            this.setState({
                msg: this.props.errMsg
            })
        }, 300)
    }

    render() { 
        let { checked } = this.props;

        return (
                <>
                    {this.props.label && <label
                        htmlFor={this.props.field || ''}
                        className={`col-sm-2 col-form-label ${this.props.labelClass || ""}`}
                    >{this.props.label ? this.props.label : ''} {this.props.isRequired && <span className="text-danger"> * </span>}</label>}

                    <div className={` ${this.props.containerClassName ? this.props.containerClassName : 'col-sm-4'}`} style={{ position: 'relative' }}>

                        {this.state.msg && <span className="text-danger text-small" style={{ fontSize: 12, position: 'absolute', right: 20, top: -10, backgroundColor: '#fff', padding: '0px 10px', zIndex: 2 }}>{this.state.msg}</span>}

                        {this.props.errMsg && <span className="text-danger text-small" style={{ fontSize: 12, position: 'absolute', right: 20, top: -10, backgroundColor: '#fff', padding: '0px 10px', zIndex: 2 }}>{this.props.errMsg}</span>}

                        <Switch 
                            checkedChildren={this.props.checkedChildren || "On"} 
                            unCheckedChildren={this.props.unCheckedChildren || "Off"} 
                            defaultChecked={this.props.defaultChecked || false}
                            checked={checked}
                            size={this.props.size || 'default'}
                            loading={this.props.loading || false}
                            disabled={this.props.disabled || false}
                            id={this.props.field || ''}
                            onChange={this.onChange}
                            field={this.props.field}
                            className=""
                        />
                    </div>
                </>
        )
    }
}
