import {
    SUCCESS_SOCIETY_DISCOUNT_LIST,
    FAILED_SOCIETY_DISCOUNT_LIST,
    REQUEST_SOCIETY_DISCOUNT_LIST,

    GET_SOCIETY_DISCOUNT,
    CREATE_UPDATE_SOCIETY_DISCOUNT,
    DELETE_SOCIETY_DISCOUNT,
} from "../../config";

const SocietyDiscountReducer = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_SOCIETY_DISCOUNT_LIST:
            return state = {
                ...state,
                isLoading: false,
                societyDiscountsList: action.payload
            }
        case REQUEST_SOCIETY_DISCOUNT_LIST:
            return state = {
                ...state,
                isLoading: true,
            }
        case FAILED_SOCIETY_DISCOUNT_LIST:
            return state = {
                ...state,
                isLoading: false,
                societyDiscountsList: action.payload
            }
        case GET_SOCIETY_DISCOUNT:
            return state = {
                ...state,
                society_discount: action.payload
            }
        case CREATE_UPDATE_SOCIETY_DISCOUNT:
            return state = {
                ...state,
                society_discount: action.payload
            }
        case DELETE_SOCIETY_DISCOUNT:
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default SocietyDiscountReducer;
