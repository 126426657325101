import {
  CREATE_OA_TOKEN,
  APPLY_TOKEN,
  CREATE_AND_APPLY_TOKEN,
  CLEAR_TOKEN,
  GET_TOKEN_ORGS_DOWNLOAD,
  GET_TOKEN_AUTHOR_ARTICLE,
  CREATE_ORGANISATION_TOKEN,
  GET_TOKEN_DOWNLOAD,
  GET_ORGS_DOWNLOAD,
  ORGANISATION_OFFSET_FUND,
  GET_OFFSET_FUND,
  GET_ORGANISATION_TOKEN,
  GET_OA_TOKEN,
  DELETE_OA_TOKEN,
  FAILED_DELETE_OA_TOKEN,
  REQUEST_CREATE_TOKEN,
} from "../../config";

const OaTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_OA_TOKEN:
      return (state = {
        ...state,
        create_oa_token: action.payload,
      });
    case APPLY_TOKEN:
    case CREATE_AND_APPLY_TOKEN:
      return (state = {
        ...state,
        apply_token: action.payload,
      });
    case CLEAR_TOKEN:
      return (state = {
        ...state,
        apply_token: null,
      });
    case REQUEST_CREATE_TOKEN:
      return (state = {
        isLoading: true,
      });

    case CREATE_ORGANISATION_TOKEN:
      return (state = {
        ...state,
        isLoading: false,
        create_organisation_token: action.payload,
      });
    case ORGANISATION_OFFSET_FUND:
      return (state = {
        ...state,
        organisation_offset_fund: action.payload,
      });
    case GET_OFFSET_FUND:
      return (state = {
        ...state,
        offset_fund_list: action.payload,
      });
    case GET_ORGANISATION_TOKEN:
      return (state = {
        ...state,
        organisation_token_list: action.payload,
      });
    case GET_OA_TOKEN:
      return (state = {
        ...state,
        oa_token_list: action.payload,
        isLoading: false,
      });
    case DELETE_OA_TOKEN:
      return (state = {
        ...state,
        delete_oa_token_success: action.payload,
      });
    case FAILED_DELETE_OA_TOKEN:
      return (state = {
        ...state,
        delete_oa_token_failed: action.payload,
      });
    case GET_TOKEN_DOWNLOAD:
      return (state = {
        ...state,
        token_download: action.payload,
      });
    case GET_ORGS_DOWNLOAD:
      return (state = {
        ...state,
        orgs_token_download: action.payload,
      });
    case GET_TOKEN_ORGS_DOWNLOAD:
      return (state = {
        ...state,
        token_download_inst: action.payload,
      });
    case GET_TOKEN_AUTHOR_ARTICLE:
      return (state = {
        ...state,
        author_article: action.payload,
      });

    default:
      return state;
  }
};
export default OaTokenReducer;
