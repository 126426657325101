import config, {
    GET_REFUND_REQUEST_LIST,
    GET_REFUND_REQUEST,
    CREATE_UPDATE_REFUND_REQUEST,
    // DELETE_REFUND_REQUEST,
    ACCEPT_REFUND_REQUEST,
    CANCEL_REFUND_REQUEST,
    REJECT_REFUND_REQUEST,
    GET_OADEAL_BALANCE_LIST,
    MUTATE_REFUND_REQUEST,
    AUTHOR_REFUND_REQUEST,
    GET_AUTHOR_REFUND_REQUEST_INFO,
    APPROVE_REFUND_REQUEST,
    GET_ALL_REFUND_INFO_LIST,
    REQUEST_ALL_REFUND_INFO_LIST
} from "../../config";
import { api } from "../../core/api";

export const oaDealBalanceListAction = (payload) => ({
    type: GET_OADEAL_BALANCE_LIST,
    payload
})

export const refundRequestListAction = (payload) => ({
    type: GET_REFUND_REQUEST_LIST,
    payload
})

export const createUpdateRefundRequestAction = (payload) => ({
    type: CREATE_UPDATE_REFUND_REQUEST,
    payload
})

export const getRefundRequestAction = (payload) => ({
    type: GET_REFUND_REQUEST,
    payload
})

export const acceptRefundRequestAction = (payload) => ({
    type: ACCEPT_REFUND_REQUEST,
    payload
})

export const rejectRefundRequestAction = (payload) => ({
    type: REJECT_REFUND_REQUEST,
    payload
})

export const cancelRefundRequestAction = (payload) => ({
    type: CANCEL_REFUND_REQUEST,
    payload
})

export const mutateRefundRequestAction = (payload) => ({
    type: MUTATE_REFUND_REQUEST,
    payload
})
export const authorRefundRequestAction = (payload) => ({
    type: AUTHOR_REFUND_REQUEST,
    payload
})

export const getAuthorRefundInfoAction = (payload) => ({
    type: GET_AUTHOR_REFUND_REQUEST_INFO,
    payload
})

export const authorRefundApproveAction = (payload) => ({
    type: APPROVE_REFUND_REQUEST,
    payload
})

export const getAllRefundInfoAction = (payload) => ({
    type: GET_ALL_REFUND_INFO_LIST,
    payload
})

export const getRequestRefundInfoAction = (payload) => ({
    type: REQUEST_ALL_REFUND_INFO_LIST,
    payload
})




// Get OaDeal Balance List
export const getOaDealBalanceList = (payload) => {
    return (dispatch) => {
        let url = config.endpoint.oa_deal_balance;

        if (payload.pageNum && payload.pageSize) {
            url += `?page=${payload.pageNum}&page_size=${payload.pageSize}`;
        } else if (payload.pageNum) {
            url += `?page=${payload.pageNum}`;
        } else if (payload.pageSize) {
            url += `?page_size=${payload.pageSize}`;
        } else {
            url += `?page_size=10`;
        }

        if (payload.deal_organisation) {
            url += `&deal_organisation=${payload.deal_organisation}`;
        }


        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(oaDealBalanceListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Create Refund Request
export const createUpdateRefundRequest = (payload, id) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request;
        let apiRequest = '';
        return new Promise((resolve, reject) => {
            if (id) {
                url += `${id}/`;
                apiRequest = api.put(url, payload);
            } else {
                apiRequest = api.post(url, payload);
            }

            apiRequest.then((res) => {
                dispatch(createUpdateRefundRequestAction(res.data));
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            });
        })
    }
}

// Get Refund Request List
export const getRefundRequestList = (payload) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request;

        if (payload.pageNum && payload.pageSize) {
            url += `?page=${payload.pageNum}&page_size=${payload.pageSize}`;
        } else if (payload.pageNum) {
            url += `?page=${payload.pageNum}`;
        } else if (payload.pageSize) {
            url += `?page_size=${payload.pageSize}`;
        }

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(refundRequestListAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Get Refund Request
export const getRefundRequest = (id) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request + `${id}/`;

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getRefundRequestAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Delete Refund Request
export const deleteRefundRequest = (id) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request + `${id}/`;
        return new Promise((resolve, reject) => {
            api.delete(url).then((res) => {
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Accept Refund Request
export const acceptRefundRequest = (ids) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request + `${ids}/approve/`;
        return new Promise((resolve, reject) => {
            api.put(url).then((res) => {
                dispatch(acceptRefundRequestAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Reject Refund Request
export const rejectRefundRequest = (ids) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request + `${ids}/reject/`;
        return new Promise((resolve, reject) => {
            api.put(url).then((res) => {
                dispatch(rejectRefundRequestAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Cancel Refund Request
export const cancelRefundRequest = (ids) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request + `${ids}/cancel-request/`;
        return new Promise((resolve, reject) => {
            api.put(url).then((res) => {
                dispatch(cancelRefundRequestAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

// Mutate Refund Request
export const mutateRefundRequest = (ids, action, data) => {
    return (dispatch) => {
        let url = config.endpoint.refund_request + `${ids}/mutate/${action}/`;
        return new Promise((resolve, reject) => {
            api.put(url, data).then((res) => {
                dispatch(mutateRefundRequestAction(res.data))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

/* REFUND REQUEST FOR AUTHOR AND PUBLISHER*/
export const createAuthorRefundRequest = (payload) => {
    return (dispatch) => {
        let url = config.endpoint.article_refund_request;
        return new Promise((resolve, reject) => {
            api.post(url, payload).then((res) => {
                dispatch(authorRefundRequestAction(res.data))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}
export const approveAuthorRefundRequest = (id, payload) => {
    return (dispatch) => {
        let url = `${config.endpoint.article_refund_request}${id}/`;
        return new Promise((resolve, reject) => {
            api.put(url, payload).then((res) => {
                dispatch(authorRefundApproveAction(res.data))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

export const getRefundRequestAllInfo = () => {
    return (dispatch) => {
        let url = `${config.endpoint.article_refund_request}`;

        dispatch(getRequestRefundInfoAction())

        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getAllRefundInfoAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}


export const getAuthorRefundRequestInfo = (id) => {
    return (dispatch) => {
        let url = `${config.endpoint.article_full}${id}?fields=id,title,refund_info`;
        return new Promise((resolve, reject) => {
            api.get(url).then((res) => {
                dispatch(getAuthorRefundInfoAction(res))
                resolve(Object.assign(res, { status: true }));
            }).catch(e => {
                reject(e)
            })
        })
    }
}

