import {
  GET_EMAIL_TAG,
  GET_EMAIL_EDIT,
  GET_DEFAULT_EMAIL_MESSAGE,
  CREATE_EMAIL_MESSAGE,
  GET_EMAIL_MESSAGE,
  GET_EMAIL_MESSAGE_LIST,
  REQUEST_EMAIL_MESSAGE_LIST,
  REQUEST_EMAIL_UPDATE,
} from "../../config";

const EmailMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EMAIL_TAG:
      return (state = {
        ...state,
        email_tag: action.payload,
      });
    case GET_EMAIL_EDIT:
      return (state = {
        ...state,
        isUpdateLoading: false,
        email_edit: action.payload,
      });
    case REQUEST_EMAIL_UPDATE:
      return (state = {
        ...state,
        isUpdateLoading: true,
      });
    case CREATE_EMAIL_MESSAGE:
      return (state = {
        ...state,
        email_message: action.payload,
      });
    case GET_EMAIL_MESSAGE_LIST:
      return (state = {
        ...state,
        isLoading: false,
        email_messages: action.payload,
      });
    case REQUEST_EMAIL_MESSAGE_LIST:
      return (state = {
        ...state,
        isLoading: true,
      });

    case GET_EMAIL_MESSAGE:
      return (state = {
        ...state,
        email_message: action.payload,
      });
    case GET_DEFAULT_EMAIL_MESSAGE:
      return (state = {
        ...state,
        default_email_message: action.payload,
      });
    default:
      return state;
  }
};

export default EmailMessageReducer;
