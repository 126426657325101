import React from 'react';
import BaseComponent from '../../../../../core/components';
import { CorrectionList } from '../../university';

class DB extends BaseComponent {
  render() {
    return (
      <CorrectionList />
    )
  }
}



export const CorrectionApprovalList = (DB)