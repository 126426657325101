import React, { Component } from 'react'
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import NewWindow from "react-new-window";
import {
    PopupBox,
} from "../../../../../../../core/components/common";
import {
    getPaymentAuthorizeUrl,
    stripeCodeSubmit,
    getStripeConnectStatus,
    revokeStripeConnection, 
} from "../../../../../../actions";
import { Tooltip } from "antd";

class Stripe extends Component {
    state = {}
    async componentDidMount() {
        await this.props.getStripeConnectStatus();
    }

    connectWithStripe = async () => {
        var base_url = `${window.location.origin}/stripe-oauth2callback`;
        await this.props.getPaymentAuthorizeUrl({ redirect_uri: base_url });
        this.setState({
            isConnect: true,
            iframeUrl: this.props.payment_authorize_url,
        });
    };

    onRevokeHandler = async (id) => { 
        
        confirmAlert({
            title: "",
            message: "Do you want to disconnect?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        let data = await this.props.revokeStripeConnection(id);
                        if (data) {
                            await this.props.getStripeConnectStatus();
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };
    
    onUnload = async () => {
        this.setState({
            isConnect: false,
            iframeUrl: false,
        });
        await this.props.getStripeConnectStatus();
    };

    render() {
        let errorHander = (
            <>
                {this.state.isEmpty && (
                    <PopupBox
                        Title="Following field cannot be empty"
                        msg={this.state.isEmpty}
                        onCancel={this.cancelLoader}
                    />
                )}
                {this.state.iframeUrl && (
                    <NewWindow
                        url={this.state.iframeUrl}
                        onUnload={() => {
                            this.onUnload();
                        }}
                    />
                )}
            </>
        );
        return (
            <div className={this.props.className || '"col-md-4"'}>
                {errorHander}

                <div className="single-setting">
                    <div className="setting-icon">
                        <img
                            src="https://img.icons8.com/color/48/000000/stripe.png"
                            alt=""
                            className="app-icon  img-fluid"
                        />
                    </div>
                    <div className="setting-info">
                        <h4>Stripe</h4>
                        <p>Accept payments with Stripe.</p>

                        {this.props.stripe_connect_status ? (
                            <Tooltip title={this.props.stripe_connect_description}>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light green"
                                >
                                    Stripe connected
                                </button>
                            </Tooltip>
                        ) : 
                            <button
                                onClick={ () => this.connectWithStripe()}
                                type="button"
                                className="btn btn-primary waves-effect waves-light "
                            >
                                Connect with stripe
                            </button>
                        }

                        <button
                            disabled={
                                !this.props.stripe_connect_status
                            }
                            type="button"
                            onClick={() =>
                                this.onRevokeHandler(this.props.stripe_connection_id)
                            }
                            className="btn btn-primary waves-effect waves-light"
                        >
                            Revoke
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({

    payment_authorize_url:
        (state.payment &&
            state.payment.payment_authorize_url &&
            state.payment.payment_authorize_url.auth_url) ||
        false,
    stripe_connect_status:
        (state.payment &&
            state.payment.stripe_connect_status &&
            state.payment.stripe_connect_status.connected) ||
        false,
    
    stripe_connection_id:
        (state.payment &&
            state.payment.stripe_connect_status &&
            state.payment.stripe_connect_status.object_id) ||
        false,
    stripe_connect_description:
        (state.payment &&
            state.payment.stripe_connect_status &&
            state.payment.stripe_connect_status.description) ||
        false,
});

const mapDispatchToProps = (dispatch) => ({

    getPaymentAuthorizeUrl: (payload) =>
        dispatch(getPaymentAuthorizeUrl(payload)),
    stripeCodeSubmit: (payload) => dispatch(stripeCodeSubmit(payload)),
    getStripeConnectStatus: () => dispatch(getStripeConnectStatus()),
    revokeStripeConnection: (id) => dispatch(revokeStripeConnection(id)),
});
export const StripeConfiguration = connect(mapStateToProps, mapDispatchToProps)(Stripe);
