import React, { Component } from "react";
import ViewVatTable from "../viewVatTable";

export default class USAVIEW extends Component {
  state = {};
  render() {
    const {
      charge_vat_number_usa,
      charge_vat_states_usa_1,
      charage_no_vat_usa_1,
      charge_no_vat_states_usa_2,
      charge_no_vat_usa_2,
      charge_no_vat_states_usa_3,
      charage_no_vat_usa_3,
      charge_no_vat_states_usa_4,
      // viewTable,
      countrychargeusa,
      countrychargeStates,
      countrychargeusa2,
      countrychargeStates2,
      countrychargeusa3,
      countrychargeStates3,
      countrychargeusa4,
      countrychargeStates4,
    } = this.props;
    return (
      <div className="vat-single-view">

        <div className="vat-detail-form">
          <div className="vat-question-panel">
            <p>
              You charge Tax/GST to USA customers if they provide a valid
              VAT/GST/Business Tax Number:{" "}
              {charge_vat_number_usa === true ? (
                <span className="vat_text">Yes</span>
              ) : (
                <span className="vat_text">No</span>
              )}
            </p>
          </div>
          {charge_vat_number_usa === true && (
            <div className="vat-question-panel">
              <p>
                You charge Tax/GST based on the States:{" "}
                {charge_vat_states_usa_1 === true ? (
                  <span className="vat_text">Yes</span>
                ) : (
                  <span className="vat_text">No</span>
                )}
              </p>
            </div>
          )}
          {charge_vat_number_usa !== false
            ? charge_vat_states_usa_1 === true && (
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable data={countrychargeStates} field="USA" />
                  </div>
                </div>
              )
            : null}
          {charge_vat_number_usa !== false
            ? charge_vat_states_usa_1 === false && (
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable data={countrychargeusa} field={"USA"} />
                  </div>
                </div>
              )
            : null}
          {charge_vat_number_usa !== false ? (
            charge_vat_states_usa_1 === true ? (
              <div className="vat-question-panel">
                <p>
                  You charge Tax/GST to USA customers if they do not provide a
                  valid VAT/GST/Business Tax Number:{" "}
                  {charage_no_vat_usa_1 === true ? (
                    <span className="vat_text">Yes</span>
                  ) : (
                    <span className="vat_text">No</span>
                  )}
                </p>
              </div>
            ) : null
          ) : null}
          {charge_vat_number_usa !== false ? (
            charge_vat_states_usa_1 !== false ? (
              // charge_vat_states_usa_1 !== false ? (
              charage_no_vat_usa_1 === true ? (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST based on the States:{" "}
                    {charge_no_vat_states_usa_2 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              ) : null
            ) : // ) : null
            null
          ) : null}
          {charge_vat_number_usa !== false
            ? charge_vat_states_usa_1 !== false
              ? charage_no_vat_usa_1 !== false
                ? charge_no_vat_states_usa_2 === true && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable
                          data={countrychargeStates3}
                          field={"USA"}
                        />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {charge_vat_number_usa !== false
            ? charge_vat_states_usa_1 !== false
              ? charage_no_vat_usa_1 !== false
                ? charge_no_vat_states_usa_2 === false && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable data={countrychargeusa3} field={"USA"} />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {}{" "}
          {charge_vat_number_usa !== false ? (
            charge_vat_states_usa_1 !== true ? (
              charge_vat_states_usa_1 === false ? (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST to USA customers if they do not provide a
                    valid VAT/GST/Business Tax Number:
                    {charge_no_vat_usa_2 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              ) : null
            ) : null
          ) : null}
          {charge_vat_number_usa !== false ? (
            charge_vat_states_usa_1 !== true ? (
              // charge_vat_states_usa_1 !== false ? (
              charge_no_vat_usa_2 === true ? (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST based on the States:
                    {charge_no_vat_states_usa_3 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              ) : null
            ) : // ) : null
            null
          ) : null}
          {charge_vat_number_usa !== false
            ? charge_vat_states_usa_1 !== true
              ? charge_no_vat_usa_2 !== false
                ? charge_no_vat_states_usa_3 === true && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable
                          data={countrychargeStates4}
                          field={"USA"}
                        />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {charge_vat_number_usa !== false
            ? charge_vat_states_usa_1 !== true
              ? charge_no_vat_usa_2 !== false
                ? charge_no_vat_states_usa_3 === false && (
                    <div className="vat-country">
                      <div className="col-12 vat-margin">
                        <ViewVatTable data={countrychargeusa4} field={"USA"} />
                      </div>
                    </div>
                  )
                : null
              : null
            : null}
          {charge_vat_number_usa === false && (
            <div className="vat-question-panel">
              <p>
                You charge Tax/GST to USA customers if they do not provide a
                valid VAT/GST/Business Tax Number:
                {charage_no_vat_usa_3 === true ? (
                  <span className="vat_text">Yes</span>
                ) : (
                  <span className="vat_text">No</span>
                )}
              </p>
            </div>
          )}
          {charge_vat_number_usa !== true
            ? charage_no_vat_usa_3 === true && (
                <div className="vat-question-panel">
                  <p>
                    You charge Tax/GST based on the States:
                    {charge_no_vat_states_usa_4 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              )
            : null}
          {charge_vat_number_usa !== true ? (
            charage_no_vat_usa_3 === true &&
            charge_no_vat_states_usa_4 === true ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <ViewVatTable data={countrychargeStates2} field={"USA"} />
                </div>
              </div>
            ) : null
          ) : null}
          {charge_vat_number_usa !== true ? (
            charage_no_vat_usa_3 === true &&
            charge_no_vat_states_usa_4 === false ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <ViewVatTable data={countrychargeusa2} field={"USA"} />
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    );
  }
}
