import React from "react";

const Card = (props) => {
  let icon = "";
  if (props.icon) {
    icon = (
      <div className="mini-stat-icon2">
        <i
          className={`${props.icon} float-right`}
          style={{
            fontSize: "30px",
            width: "64px",
            height: "64px",
            lineHeight: "64px",
            textAlign: "center",
            color: "#375ba8",
            borderRadius: "50%",
            background: "rgba(88, 39, 140, 0.1)",
            display: "inline-block",
          }}
        ></i>
      </div>
    );
  }

  const style = {
    minHeight: props.height || "auto",
    overflow: "hidden",
  };

  return (
    <div className="db_test">
      <div
        className={`${props.no_background ? props.no_background : "card"}`}
        style={style}
      >
        <div
          className={`${props.className === "col-md-8 top_journals_padding TopJournals" ||
              "col-md-4 no_background"
              ? "card-header text-white"
              : "card-header text-white text-center"
            }`}
        >

          <h6 className={`${props.color ? props.color : "m-0 p-0"}`}>
            {props.title}
          </h6>
          {icon}

        </div>
        <div className="card-body p-0">
          <div className="card-padding">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
