import React from 'react';
import BaseComponent from '../../../../../core/components';
import { Card } from '../../../../../core/components/common';

const TableRowData = () => {
  return (
    <tbody>
    </tbody>
  )
}

class TopJournals extends BaseComponent {
 
  state = {
    error: null,
    errorInfo: null,
    showpop:false,
    onClose:false
  }
    HandleClick = ()=>{
    this.setState({
      showpop:!this.state.showpop,
      onClose:!this.state.onClose
    })
  }
   HandleOnClose = ()=>{
    this.setState({
      showpop:false,
      onClose:false
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  dataTable = (data) => {
    return (
      <tbody>
        {data.map((value, index) => {
          return <tr key={index} className={index % 2 ? "odd" : "even"}><th scope="row">{index + 1}</th><td>{value.name}</td><td className="text-center">{value.approved_journal}</td></tr>
        })}
      </tbody>
    )
  }
  
  render() {
    let { data } = this.props;


    if (this.state.errorInfo) {
      return <Card
        title="Something Error"
        className={this.props.className || "col-md-4"}
        height={this.props.height || 420}
      />
    } else {
      return (
        !data ? <Card
          title="Fetching..."
          className={this.props.className || "col-md-4"}
        /> :
          <Card
       
       onClick={(e)=>this.HandleClick(e)}
            showpop={this.state.showpop}
            onClose={(e)=>this.HandleClick(e)}
                  showtitle={this.props.showtitle}
                titleIcon={this.props.titleIcon}
            title={this.props.title}
            className={this.props.className || "col-md-4"}
            height={this.props.height || 420}
          >
            <div className="morris-chart-height">
              <table className="table table-striped table-hover mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Journal</th>
                    <th className="text-center">Approved articles</th>
                  </tr>
                </thead>
                {data ? this.dataTable(data.items) : <TableRowData />}
              </table>
            </div>
          </Card>
      );
    }
  }
}

export default TopJournals;