import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { ClearStatus, UpdateUser, getSingleUser, deleteUser } from '../../../../actions';
import { PopupBox, Loading } from '../../../../../core/components/common';

class User extends BaseComponent {
    state = {
        user: {},
        roleList: false,
        groupList: false,
        isRegistering: false,
        msg: false,
        loader: false, 
        status: false,
        isEmpty: false,
        deleteConfirm: false,
        deleteStatus: null
    }

    async componentDidMount() {
        let user;
        const userId = this.props.match.params.id;
        user = await this.props.getUser(userId);

        if (!user) {
            user = await this.props.getSingleUser(userId);
        }

        this.setState({
            user
        })
    }

    onDeleteUser = (e) => {
        e.preventDefault();
        this.setState({
            deleteConfirm: true,
        })
    }

    onDeleteHandler = async () => {
        const userId = this.state.user.id;
        this.setState({
            deleteing: true,
            waitingMsg: "Deleting..."
        })
        const user = await this.props.deleteUser(userId);
      
        this.setState({
            deleteConfirm: false,
            deleteing: false,
            deleteStatus: user.status
        })
    }

    onCancel = () => {
        this.setState({
            deleteConfirm: false,
            deleteStatus: null
        })
    }

    OnOkPress = () => this.props.history.push('/user/user-list')

    render() {
        let errorHander = <>
            {this.state.status === 400 && <PopupBox
                title="Invalid form"
                msg="You submitted the form is not valid. Please Check Your Form and Try again"
                onCancel={this.cancelLoader}
            />}
            {this.state.status === 500 && <PopupBox
                title="Network Error Found"
                msg="Please Check Internet Connection and Try Again"
                onCancel={this.cancelLoader}
            />}

            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}

            {this.state.msg && <PopupBox
                Title={this.state.msg}
                msg={this.state.msg}
                onCancel={this.cancelLoader}
            />}
        </>

        let { user } = this.props.location.state;

        let {
            id,
            salute,
            first_name,
            middle_name,
            last_name,
            email,
            username,
            user_meta,
            addresses,
            role_name,
            unique_id,

        } = user
        return (
               <HubContent title={`${first_name} ${last_name}`}>
                {/* ErroR handling */}
                {errorHander}
                {/* ErroR Handling */}

                {/* LoadinG Handling */}
                {this.state.isRegistering && !this.state.status && <Loading />}
                {/* LoadinG Handling */}


                {this.state.deleteConfirm && <PopupBox
                    title="Do you want to delete?"
                    YesText="Yes"
                    yesBtnType="danger"
                    type="danger"
                    NoBtnType="success"
                    onCancel={this.onCancel}
                    onPressOK={this.onDeleteHandler}
                    waitingMsg={this.state.waitingMsg}
                    deleting={this.state.deleteing}
                />}

                {this.state.deleteStatus && <PopupBox
                    title="Successfully deleted"
                    onCancel={this.OnOkPress}
                />}

                {this.state.deleteStatus === false && <PopupBox
                    title="Failed... Try Again"
                    onCancel={this.onCancel}
                    NoBtnType="danger"
                />}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body row viewuser">
                                <div className="col-sm-6">
                                    <table style={styles.table} >{/******** User Basic Info *******/}
                                        <tbody>
                                            <tr>
                                                <th className="w-100 mb-5"><h6>User Basic Information</h6></th>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Salutation</td>
                                                <td style={{ textAlign: 'left' }}>{salute}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>First Name</td>
                                                <td style={{ textAlign: 'left' }}>{first_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Middle Name</td>
                                                <td style={{ textAlign: 'left' }}>{middle_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Last Name</td>
                                                <td style={{ textAlign: 'left' }}>{last_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Username</td>
                                                <td style={{ textAlign: 'left' }}>{username}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Email</td>
                                                <td style={{ textAlign: 'left' }}>{email}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Role Name</td>
                                                <td style={{ textAlign: 'left' }}>{role_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '30%' }}>Unique Id</td>
                                                <td style={{ textAlign: 'left' }}>{unique_id}</td>
                                            </tr>
                                          
                                              <tr>
                                                <td style={{ width: '30%' }}>Orcid Id</td>
                                                <td style={{ textAlign: 'left' }}>{user_meta&&user_meta.orcid_id}</td>
                                            </tr>
                                         
                                            <tr>
                                                <td style={{ width: '30%' }}>Phone</td>
                                                <td style={{ textAlign: 'left' }}>{user_meta&&user_meta.phone}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

            

                                <div className="col-sm-6"> {/******** User Address Information *******/}
                                    {addresses && addresses.map((value, key) => {
                                        return (
                                            <table style={styles.table}>
                                                <tbody key={addresses.id}>
                                                    <tr>
                                                        <td ><h6>Address # {key + 1}</h6></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Title</td>
                                                        <td style={{ textAlign: 'left' }}>{value.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Address1</td>
                                                        <td style={{ textAlign: 'left' }}>{value.address1}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Address2</td>
                                                        <td style={{ textAlign: 'left' }}>{value.address2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Country</td>
                                                        <td style={{ textAlign: 'left' }}>{value.country_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>City</td>
                                                        <td style={{ textAlign: 'left' }}>{value.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>State</td>
                                                        <td style={{ textAlign: 'left' }}>{value.state}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>Postal Code</td>
                                                        <td style={{ textAlign: 'left' }}>{value.postal_code}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    })}
                                </div>

                                <div className="col-sm-12 text-right">
                                  <ul className="userProfile">
                                   <li className="color1"><Link to={`/user/edit-user/${id}`}>Edit</Link></li>
                                   <li className="color2"> <Link to="#" onClick={this.onDeleteUser}>Delete</Link></li>
                                   </ul>
                                    
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const styles = {
    table: {
        borderCollapse: "collapse",
        width: '100%'
    }
};

const mapStateToProps = (state) => ({
    failed: state.user.failed || false,
    getUser: (userId) => {
        return state.user.users ? state.user.users.results && state.user.users.results.find((user) => {
            return user.id.toString() === userId.toString() && user;
        }) : false;
    }
})

const mapDispatchToProps = (dispatch) => ({
    UpdateUser: (payload) => dispatch(UpdateUser(payload)),
    ClearStatus: () => dispatch(ClearStatus()),
    getSingleUser: (id) => dispatch(getSingleUser(id)),
    deleteUser: (id) => dispatch(deleteUser(id))
})

export const ViewProgram = connect(mapStateToProps, mapDispatchToProps)(User);