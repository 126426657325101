import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import BaseComponent, {
  DataTable,
  HubContent,
} from "../../../../../core/components";
import { Loading, Button } from "../../../../../core/components/common";
import { getPubProgramList } from "../../../../actions";
import { Popover } from "antd"

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">Program</a>
    <br />

  </div>
);
class UL extends BaseComponent {
  state = {
    deleteConfirm: false,
    userId: null,
    waitingMsg: null,
    deleteStatus: null,
    data: [],
  };
  ProgramData = async (pageNum = 1, page = 10) => {
    await this.props.getPubProgramList({ pageNum: pageNum, pageSize: page });
  };
  async componentDidMount() {
    this.ProgramData();
  }
  pageChange = (pageNumber) => {
    this.ProgramData(pageNumber);
  };

  onEdit = (id, data) => {
    this.props.history.push("/settings/edit-waiver-policy/" + id, {
      user: data,
    });
  };

  onView = (id, data) => {
    this.props.history.push("/user/" + id, { user: data });
  };

  onDelete = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        userId: id,
      });
    }
  };

  onDeleteUserHander = async () => {
    const userId = this.state.userId;
    this.setState({
      waitingMsg: "Deleting...",
    });
    await this.props.deleteUser(userId);
    let data = this.state.data;
    let newData = data.filter(function (element) {
      return element.id !== userId;
    });
    this.setState({
      waitingMsg: null,
      userId: null,
      deleteConfirm: false,
      data: newData,
    });
  };

  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      waitingMsg: null,
      deleteStatus: null,
    });
  };

  AddlicenseMessageId = () => {
    this.props.history.push({
      pathname: "/settings/add-waiver-policy",
      state: { id: this.state.id },
    });
  };

  render() {
    const heading = [
      "date",
      "program_name",
      "included_countries",
      "mail_to_author",
    ];
    let program = [];
    this.props.programs &&
      this.props.programs.map((data) => {
        let coverage_length = data.coverage && data.coverage.length;
        let excluded_length =
          data.exclude_countries && data.exclude_countries.length;
        let coverage_text = coverage_length < 2 ? `country` : `countries`;
        let excluded_text = excluded_length < 2 ? `country` : `countries`;
        program.push({
          id: data.id,
          date: data.date_created,
          program_name: data.program_name,
          included_countries:
            coverage_length === 0
              ? `No countries included`
              : `${data.coverage && data.coverage.length
              } ${coverage_text} included`,
          excluded_countries:
            excluded_length === 0
              ? `No countries excluded`
              : `${data.exclude_countries && data.exclude_countries.length
              } ${excluded_text} excluded`,
          mail_to_author: data.send_mail_to_author ? "Yes" : "No",
        });
        return program;
      });
    return (
      <HubContent
        title="
      Emerging Countries APC Waiver"
        subtitle="On this page, you can add a new Waiver programme or edit an
        existing Waiver programme. To add a new programme, click on the
        Add programme button"
      >


        <div className="vat-tax-button email d-flex justify-content-end">

          <Link
            to="#"
            onClick={() => this.AddlicenseMessageId()}
            className="btn btn-primary float-right mb-2"
            style={{ marginRight: 10 }}
          >
            Add Program
            </Link>

          <Popover placement="leftTop" content={content} trigger="click">
            <Button
              className="btn btn-secondary float-right mb-2"

              type=""
              name=""
              text="Help"
            >Help</Button>
          </Popover>
          <div className="danger float-right"
          >
            <button
              type="danger"
              className={`btn btn-danger btn- sm" waves-effect`}

              onClick={() => this.props.history.goBack()}
              style={{ marginLeft: 10 }}
            >
              X

            </button>

          </div>

        </div>
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              {this.props.programs && program ? (
                <DataTable
                  heading={heading}
                  data={program}
                  onEdit={this.onEdit}
                  count={this.props.count}
                  pageChange={this.pageChange}
                  pageSize={10}
                />
              ) : (
                  <Loading type="flat" />
                )}
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  programs:
    (state.program &&
      state.program.pub_program_list &&
      state.program.pub_program_list.results) ||
    false,
  count:
    (state.program &&
      state.program.pub_program_list &&
      state.program.pub_program_list.count) ||
    false,
});

const mapDispatchToProps = (dispatch) => ({
  getPubProgramList: (payload) => dispatch(getPubProgramList(payload)),
});

export const PubProgramList = connect(mapStateToProps, mapDispatchToProps)(UL);
