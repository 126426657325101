import {
  ARTICLE_PAYMENT_SUMMARY,
  ARTICLE_PAYMENT,
  ARTICLE_PAYMENT_BY_CARD_LIST,
  ARTICLE_PAYMENT_BY_CARD,
  GET_INVOICE_DOWNLOAD_TRANCTION,
  PAYMENT_BILLING_ADDRESS,
  PAYMENT_TRANSACTION,
  PAYMENT_TRANSACTION_DOWNLOAD,
  PAYMENT_AUTHORIZE_URL,
  POST_STRIPE_CODE,
  STRIPE_CONNECT_STATUS,
  ARTICLE_INVOICE_SUMMARY,
  UPDATE_INVOICE,
  GET_INVOICE_DOWNLOAD,
  PUBLISHER_INVOICE_CONFIG_UPDATE,
  PUBLISHER_INVOICE_PREVIEW,
  GET_INVOICE_TEMPLATE,
  GET_INVOICE_NOTIFICATION,
  UPDATE_INVOICE_NOTIFICATION,
  INVOICE_SEARCH,
  CARD_PAYMENT_SEARCH,
  REQUEST_PAYMENT_SUMMERY,
  ARTICLE_PAYMENT_REQUEST,
  ARTICLE_PAYMENT_CANCEL,
} from "../../config";

const PaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INVOICE_DOWNLOAD:
      return (state = {
        ...state,
        invoice_download: action.payload,
      });
    case UPDATE_INVOICE:
      return (state = {
        ...state,
        update_invoice: action.payload,
      });
    case ARTICLE_INVOICE_SUMMARY:
      return (state = {
        ...state,
        article_invoice_summary: action.payload,
      });
    case ARTICLE_PAYMENT_SUMMARY:
      return (state = {
        ...state,
        article_payment_summary: action.payload,
        isPaymentLoading: false,
      });
    case REQUEST_PAYMENT_SUMMERY:
      return (state = {
        ...state,
        isPaymentLoading: true,
      });
    case ARTICLE_PAYMENT_REQUEST:
      return (state = {
        ...state,
        isPaymentRequestLoading: true,
      });
    case ARTICLE_PAYMENT_CANCEL:
      return (state = {
        ...state,
        isPaymentRequestLoading: false,
      });

    case ARTICLE_PAYMENT:
      return (state = {
        ...state,
        article_payment: action.payload,
        isPaymentRequestLoading: false,
      });
    case PAYMENT_BILLING_ADDRESS:
      return (state = {
        ...state,
        payment_billing_address: action.payload,
      });
    case PAYMENT_TRANSACTION:
      return (state = {
        ...state,
        payment_transaction: action.payload,
      });
    case PAYMENT_TRANSACTION_DOWNLOAD:
      return (state = {
        ...state,
        transction_download_data: action.payload,
      });
    case PAYMENT_AUTHORIZE_URL:
      return (state = {
        ...state,
        payment_authorize_url: action.payload,
      });
    case POST_STRIPE_CODE:
      return (state = {
        ...state,
        post_stripe_code: action.payload,
      });
    case STRIPE_CONNECT_STATUS:
      return (state = {
        ...state,
        stripe_connect_status: action.payload,
      });
    case PUBLISHER_INVOICE_CONFIG_UPDATE:
      return (state = {
        ...state,
        update_invoice_config: action.payload,
      });
    case GET_INVOICE_TEMPLATE:
      return (state = {
        ...state,
        invoice_templates: action.payload,
      });
    case PUBLISHER_INVOICE_PREVIEW:
      return (state = {
        ...state,
        invoice_preview: action.payload,
      });
    case GET_INVOICE_DOWNLOAD_TRANCTION:
      return (state = {
        ...state,
        invoice_download_transction: action.payload,
      });
    case GET_INVOICE_NOTIFICATION:
      return (state = {
        ...state,
        invoice_notificatin: action.payload,
      });
    case UPDATE_INVOICE_NOTIFICATION:
      return (state = {
        ...state,
        update_notificatin: action.payload,
      });
    case INVOICE_SEARCH:
      return (state = {
        ...state,
        invoice_searchList: action.payload,
      });
    case CARD_PAYMENT_SEARCH:
      return (state = {
        ...state,
        card_searchList: action.payload,
      });
    case ARTICLE_PAYMENT_BY_CARD_LIST:
      return (state = {
        ...state,
        paymentCardList: action.payload,
      });
    case ARTICLE_PAYMENT_BY_CARD:
      return (state = {
        ...state,
        paymentCard: action.payload,
      });
    default:
      return state;
  }
};
export default PaymentReducer;
