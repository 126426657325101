import React from "react";
import { connect } from "react-redux";
import { ExpandHeadingTable ,Button} from "../../../../../core/components/common";
import { MemberHeading, column } from "../../../../../core/lib";

import BaseComponent, { HubContent } from "../../../../../core/components";
import { getSingleOaDeal, getSingleOaDealRequest } from "../../../../actions";
import { Skeleton } from "antd";
import moment from "moment";
import ConsortiaMember from "./ConstoriaMembersList";
class ViewTable extends BaseComponent {
  state = {
    info: null,
  };

  async componentDidMount() {
    let id = this.props.match.params.id;
    let regex = RegExp("/view-oadeal-request/:id");
    let res = regex.test(this.props.match.path);

    try {
      if (res) {
        await this.props.getSingleOaDealRequest(id);
      } else {
        await this.props.getSingle(id);
      }

      this.setState({
        info: this.props.singleOaDeal,
      });
    } catch (e) {
    
    }
  }

  dateOfDeal = (date) => {
    const dateofvalue = moment(date).format("Do MMM YY");
    return dateofvalue;
  };

  priceofOadeal = (price) => {
    let dataPrice = Number(price).toFixed(2);
    return dataPrice;
  };

  renderListItems = (data) => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <p key={ item.id}className="oadeal-table-view"> {item.name}</p>
      ));
    }

    return "";
  };

  renderDetails = () => {
    let info = null;
    if (this.props.location.state) {
      info = this.props.location.state;
    } else {
      info = this.props.singleOaDeal;
    }

    return (
      info && (
        <div className="row">
          <div className="col-lg-12">

            <div className="card">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="X"
                  onClick={() => this.props.history.push(this.props.group !=="PUBLISHER" ? "/action/oa-account-list": "/oa-deal-activities/oa-deals-table")}
                />
              </div>
              <div className="card-body row">
                <div className="col-sm-12">
                  <table style={styles.table}>
                    {/******** User Basic Info *******/}
                    <tbody>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Organisation</td>
                        <td style={styles.td2}>
                          {
                            this.props.singleOaDeal.oadeal
                              .counter_organisation_name
                          }
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Deal Type</td>
                        <td style={styles.td2}>
                          {this.props.singleOaDeal.oadeal.deal_name}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Currency</td>
                        <td style={styles.td2}>
                          {this.props.singleOaDeal.oadeal.currency_name}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Discount%</td>
                        <td style={styles.td2}>
                          {this.priceofOadeal(
                            this.props.singleOaDeal.oadeal.discount != null
                              ? this.props.singleOaDeal.oadeal.discount
                              : null
                          )}
                        </td>
                      </tr>
                      <tr style={styles.tr}>
                        <td style={styles.td}>Expiry</td>
                        <td style={styles.td2}>
                          {this.props.singleOaDeal.oadeal.expire_date !== null
                            ? this.dateOfDeal(
                                this.props.singleOaDeal.oadeal.expire_date
                              )
                            : "No Date Included"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row" style={{ padding: 15 }}>
                  <div className="col-md-4">
                    <label className="oadeal-view">Disciplines</label>
                    {this.props.singleOaDeal.oadeal.discipline_details.length >
                    0
                      ? this.renderListItems(
                          this.props.singleOaDeal.oadeal.discipline_details
                        )
                      : `All disciplines`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Subjects </label>
                    {this.props.singleOaDeal.oadeal.subject_details.length > 0
                      ? this.renderListItems(
                          this.props.singleOaDeal.oadeal.subject_details
                        )
                      : `All subjects`}
                  </div>
                  <div className="col-md-4">
                    <label className="oadeal-view">Publication types </label>
                    {this.props.singleOaDeal.oadeal.publication_type_details
                      .length > 0
                      ? this.renderListItems(
                          this.props.singleOaDeal.oadeal
                            .publication_type_details
                        )
                      : `All publication types`}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Publications </label>
                    {this.props.singleOaDeal.oadeal.publication_details.length >
                    0
                      ? this.renderListItems(
                          this.props.singleOaDeal.oadeal.publication_details
                        )
                      : `All publications`}{" "}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Content types</label>
                    {this.props.singleOaDeal.oadeal.content_type_details
                      .length > 0
                      ? this.renderListItems(
                          this.props.singleOaDeal.oadeal.content_type_details
                        )
                      : `All content types`}
                  </div>
                  <div className="col-md-4" style={{ marginTop: 15 }}>
                    <label className="oadeal-view">Members</label>
                    {this.props.singleOaDeal.oadeal.member_organisation_details
                      .length > 0
                      ? this.renderListItems(
                          this.props.singleOaDeal.oadeal
                            .member_organisation_details
                        )
                      : `No Member Selected`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <HubContent>
        {this.state.info ? (
          this.renderDetails()
        ) : (
          <Skeleton active paragraph={{ row: 10 }} />
        )}
        {this.props.singleOaDeal &&
          this.props.singleOaDeal.oadeal &&
          this.props.singleOaDeal.oadeal.member_deals.length > 0 && (
            <div className="row">
              <div className="col-lg-12">
                <div className="card m-b-20">
                  <div
                    id="collapse1"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <ul
                            className="nav nav-tabs nav-tabs-custom nav-justified"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#main-info-1"
                                role="tab"
                              >
                                Members
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#other-info-1"
                                role="tab"
                              >
                                Other
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              className="tab-pane active p-3"
                              id="main-info-1"
                              role="tabpanel"
                            >
                              <ExpandHeadingTable
                                heading={MemberHeading}
                                column={column}
                              />

                              {this.props.singleOaDeal.oadeal &&
                              this.props.singleOaDeal.oadeal.member_deals
                                ? this.props.singleOaDeal.oadeal &&
                                  this.props.singleOaDeal.oadeal.member_deals.map(
                                    (item, index) => (
                                      <div id="accordion" key={index}>
                                        <ConsortiaMember
                                          key={index}
                                          item={item}
                                          column={column}
                                        />
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                            <div
                              className="tab-pane p-3"
                              id="other-info-1"
                              role="tabpanel"
                            >
                              Other Info
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  singleOaDeal: state.OaDeals,
  group:state.auth.group
});

const mapDispatchToProps = (dispatch) => ({
  getSingle: (id) => dispatch(getSingleOaDeal(id)),
  getSingleOaDealRequest: (id) => dispatch(getSingleOaDealRequest(id)),
});

export const SingleTableView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTable);

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  tr: {
    marginTop: 20,
    verticalAlign: "baseline",
  },
  td: {
    paddingTop: 10,
    color: "rgb(0, 122, 206)",
    fontWeight: "500",
  },
  td2: {
    paddingTop: 10,
    width: "75%",
  },
};
