import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Button,Loading,PopupBox } from "../../../../../core/components/common";

import { HubContent } from "../../../../../core/components";
import {
  publisherGetSingleVatCreateConfigure,
  publisherDeleteVatCreateConfigure,
  publisherGetAllVatCreateConfigure,
} from "../../../../actions";
import { Popover } from "antd";

const content = (
  <div className="">
    <a href="/" target="_blank" rel="noopener noreferrer">VAT </a>
    <br />

  </div>
);
class VatForm extends React.Component {
  state = {

  }
   componentDidMount() {
   this.apiDataList()
   }
  apiDataList =async () => {
    await this.props.publisherGetAllVatCreateConfigure();
      this.setState({
        id: this.props.location && this.props.location.state && this.props.location.state.id
      })
   
  }
 
  onEdit = (id) => {
    this.props.history.push(`/settings/update-vat/${id}`);
  };
  VatView = (id) => {
    this.props.history.push(`/settings/vat-configuration-view/${id}`);
  };
  onDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Do you want remove?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.publisherDeleteVatCreateConfigure(id);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  onDelete = (id) => {
    if (id) {
      this.setState({
        deleteConfirm: true,
        id,
      });
    }
  };


  onDeleteHander = async () => {
    this.setState({
      waitingMsg: "Deleting...",
    });
    let result= await this.props.publisherDeleteVatCreateConfigure(this.state.id);
    this.setState({
      waitingMsg: null,
      id: null,
      deleteConfirm: false,
    });
    if (result.status===true) {
      await this.props.publisherGetAllVatCreateConfigure();
      this.props.history.push("/settings")

    }
  };
  onCancel = () => {
    this.setState({
      deleteConfirm: false,
      id: null,
      waitingMsg: null,
      isEmpty: false,
    });
  };
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  render() {
    const VatId = (this.props.vat_publisher_list &&
        this.props.vat_publisher_list.results &&
        this.props.vat_publisher_list.results[0] &&
        this.props.vat_publisher_list.results[0].id) || this.state.id
      ;

    return (
      <HubContent>
{this.state.deleteConfirm && (
  <PopupBox
    title="Do you want to delete?"
    YesText="Yes"
    yesBtnType="danger"
    type="danger"
    btnSize="200px"
    NoBtnType="success"
    onCancel={this.onCancel}
    onPressOK={this.onDeleteHander}
    waitingMsg={this.state.waitingMsg}
  />
        )}
        
      <div className="vat-tax-button d-flex justify-content-end">
        <Popover placement="leftTop" content={content} trigger="click">
          <Button
            className="btn btn-secondary float-right "
            type=""
            name=""
            text="Help"
          >Help</Button>
        </Popover>
        <div className="danger float-right"
        >
          <button
            type="danger"
            className="btn btn-danger float-right "
            onClick={() => this.props.history.push("/settings")}
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            X

</button>                  </div>

        </div>
        {
          this.props.isLoading !== true ? <div className="flex-vat-container">
          <div className="flex-vat-column">
          <div className="vat-page publisher">
      <div className="card-body  profile">
        <p className="vat-info">You have already set up your VAT</p>
        <p className="vat-edit-info">
          Please click below to Edit or Delete
        </p>
    <Button
          type="primary"
          name="primary"
          text="View"
          size={200}
          onClick={() =>this.VatView(VatId)}
        />
       <Button
          className="vat-page-btn"
          type="secondary"
          size={200}
          name="secondary"
          text="Edit"
          onClick={() => this.onEdit(VatId)}
        />
        <Button
          type="danger"
          name="danger"
          text="Delete"
          size={200}
          onClick={() => this.onDelete(VatId)}
        />
      </div>
      </div> 
    
  </div>
</div>:<Loading type="flat"/>}


      
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.app.countries,
  single_publisher_vat: state.vat.single_publisher_vat,
  vat_publisher_delete: state.vat.vat_publisher_delete,
  vat_publisher_list: state.vat.vat_publisher_list,
  isLoading: state.vat.isLoading,

});

const mapDispatchToProps = (dispatch) => ({
  publisherGetSingleVatCreateConfigure: (id) =>
    dispatch(publisherGetSingleVatCreateConfigure(id)),
  publisherDeleteVatCreateConfigure: (id) =>
    dispatch(publisherDeleteVatCreateConfigure(id)),
  publisherGetAllVatCreateConfigure: () =>
    dispatch(publisherGetAllVatCreateConfigure()),
});

export const VatPage = connect(mapStateToProps, mapDispatchToProps)(VatForm);

