import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { connect } from 'react-redux';
import { getOaPolicyAll,ClearStatus,getOrgListAll,getJournalListAll,getOaRequirementAll,getPublicationByPublisher,createOaPublisherPolicy,createOrganisationRequirement,getOaPolicySingle,getOaRequirementSingle } from '../../../../actions';
import { PopupBox, Loading, SelectBox } from '../../../../../core/components/common';
import { history } from '../../../../route';
import ReactHtmlParser from 'react-html-parser';
import { confirmAlert } from 'react-confirm-alert';



class OaPolicy extends BaseComponent {
    state = {
        policy: {},
        isEmpty: false,
        isLoading: false,
        oaPolicy: true,
        oaPolicyList:{},
        journal_disabled: true,
        response_disabled: true,
        choices: false,
        multiple: false
    }

    // data: request data for submit
    // required_fields: array of required field Ex: required_field = ['name', 'number', 'exp_month'];
    // required_field_messages: Object of required field messages Ex: error_message = { name: 'Card holder name is required', number: 'Card number is required', exp_month: 'Expiry month is required'}
    errorMessage=(data,required_fields,required_field_messages)=>{
        let error_field=[];
        required_fields.map((value)=>{
        if(!(value in data)){
            error_field.push(required_field_messages[value]);
        }
            return error_field;
        })
        let errorMessage = ReactHtmlParser(error_field.join('<br>'));
        let errorLength = error_field.length;
        if(errorLength>0){
            return(
                confirmAlert({
                message: errorMessage,
                buttons: [
                    {
                    label: 'Close',
                    onClick: () => {}
                    }
                ]
                })
            )
        }
        return errorLength;
    }

    errorCount=(data,required_fields,required_field_messages)=>{
        let error_field=[];
        required_fields.map((value)=>{
          if(!(value in data)){
           error_field.push(required_field_messages[value]);
          }
          return error_field;
        })
        return error_field.length;
    }


    onChangeHandler = async (value) => {
        let keys = Object.keys(value);
        let values = Object.values(value);
        if(keys[0]==='requirement' || keys[0]==='policy'){
            let data =  keys[0]==='requirement' ? this.props.oa_requirement_list : this.props.oa_policy_list; 
            let index = data && data.findIndex(x=>x.id===values[0]);
            let choices = [];
            data[index].choices && data[index].choices.map((choice,index)=>{
                return choices.push({
                    id:choice,
                    name:choice
                })
            }); 
            this.setState({
                choices:choices,
                response_disabled: false,
                multiple: data[index].response_type ==="multiple-choice" ? true : false
            })
        }
        if(keys[0]==='publisher'){
            let payload={publisher_id:values[0]}
            this.props.group==="PUBLISHER" && values[0] && await this.props.getPublicationByPublisher(payload);
            this.setState({
                journal_disabled:false
            })
        }
        this.setState((prevState) => {
            Object.assign(prevState.policy, value)
        })
    }

    onSubmitHandler = async () => {
        let data = this.state.policy;
        let required_fields = this.props.group==="INSTITUTION" ? ['organisation', 'requirement', 'response'] : ['publisher','policy','response'];
        let required_field_messages = this.props.group==="INSTITUTION" ? {
            organisation: 'Organisation is required',
            requirement: 'Oa requirement is required',
            response: 'Response is required',
        } : {
            publisher: 'Publisher is required',
            policy: 'Policy is required',
            response: 'Response is required',
        }
        let errorCount = this.errorCount(data,required_fields,required_field_messages); 
        if(errorCount>0){
            this.errorMessage(data,required_fields,required_field_messages);
        }else{
            data.response = Array.isArray(data.response) ? data.response.join("|") : data.response;
            this.props.group==="PUBLISHER" && await this.props.createOaPublisherPolicy(data);
            this.props.group==="INSTITUTION" && await this.props.createOrganisationRequirement(data);
            history.push('/oa-policy/oa-policy-list');
        }
    }



    async componentDidMount(){
        this.setState({
            isLoading: true
        })
        let id = this.props.match.params.id;
        await this.props.getOrgListAll();
        await this.props.getOaPolicyAll();
        // this.props.group==="PUBLISHER" && await this.props.getOaPolicyAll();
        this.props.group==="INSTITUTION" &&  await this.props.getOaRequirementAll();
        id && id !== undefined && this.props.group==="INSTITUTION" && this.props.getOaRequirementSingle({id:id});
        id && id !== undefined && this.props.group==="PUBLISHER" && await this.props.getOaPolicySingle({id:id});
        this.setState({
            isLoading: false
        })

        if(this.props.oa_policy_single){
            this.setState({
                policy: this.props.oa_policy_single
            })
        }
            if(this.state.policy && this.state.policy.publiser){
                let payload={publisher_id:this.state.policy.publiser}
                this.props.group==="PUBLISHER" && this.state.policy.publiser && await this.props.getPublicationByPublisher(payload);
            }
            
    }


    cancelLoader = async () => {
        this.setState({
            isRegistering: false,
            status: false,
            isEmpty: false,
            oaPolicy:false
        })
        await this.props.ClearStatus()
    }


    render() {
        let errorHander = <>
            {this.state.isEmpty && <PopupBox
                Title="Following field cannot be empty"
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>
        let organisation = []
        let journals = []
        let oa_policy = []
        let oa_requirement = []
        let response = []
        organisation = this.props.orgs && organisation.concat(this.props.orgs)
        this.props.oa_policy_list && Array.isArray(this.props.oa_policy_list) && this.props.oa_policy_list.map((policy)=>{
            return oa_policy.push({
                id: policy.id,
                name: policy.title,
                respose: policy.respose_type,
                choices: policy.choices
            })
        })
        this.props.oa_requirement_list && this.props.oa_requirement_list.map((policy)=>{
            return oa_requirement.push({
                id: policy.id,
                name: policy.title,
                respose: policy.respose_type,
                choices: policy.choices
            })
        })
        response = this.state.choices ? response.concat(this.state.choices) : response;
        journals = this.props.journals ? journals.concat(this.props.journals) : journals;
    
        return (
            <HubContent title="Add OA policy">
                {/* ErroR handling */}
                    {errorHander}
                {/* ErroR Handling */}

                {/* Loader */}
                    {this.state.isLoading && <Loading />}
                {/* Loader */}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                {/* {this.OaPolicyList()} */}
                                <form>
                                    {this.state.loading && <Loading/>}
                                    <div className="form-group row">
                                        {organisation && <SelectBox
                                            label={this.props.group==="PUBLISHER" ? 'Publisher':'Organisation'}
                                            onChange={this.onChangeHandler}
                                            field={this.props.group==="PUBLISHER" ? 'publisher':'organisation'}
                                            data={ organisation} 
                                            placeholder={this.props.group==="PUBLISHER" ? 'Publisher':'Organisation'}
                                            defaultValue={this.props.group==="HUB" ?  this.state.policy.publisher : this.state.policy.organisation} 
                                        />}
                                        {journals && this.props.group==="PUBLISHER" && <SelectBox
                                            label="Journal"
                                            onChange={this.onChangeHandler}
                                            field='publication'
                                            placeholder="Journals"
                                            data={journals} 
                                            disabled={this.state.policy.publication ? false : this.state.journal_disabled}
                                            defaultValue={this.state.policy.publication} 
                                        />
                                        }
                                    </div>
                                    <div className="form-group row">
                                        {oa_policy && this.props.group==="PUBLISHER" && <SelectBox
                                                    label="Oa policy"
                                                    onChange={this.onChangeHandler}
                                                    field='policy'
                                                    data={oa_policy} 
                                                    placeholder="Oa policy"
                                                    defaultValue={this.state.policy.policy}  
                                                />
                                            }
                                            {oa_requirement && this.props.group==="INSTITUTION" && <SelectBox
                                                    label="Oa requirement"
                                                    onChange={this.onChangeHandler}
                                                    field='requirement'
                                                    data={oa_requirement} 
                                                    placeholder="Oa requirement"
                                                    defaultValue={this.state.policy.requirement} 
                                                />
                                            }
                                            {response && <SelectBox
                                                label="Response"
                                                onChange={this.onChangeHandler}
                                                field='response'
                                                placeholder="Response"
                                                multiple={ this.state.multiple }
                                                data={response} 
                                                disabled={ this.state.policy.response ? false : this.state.response_disabled}
                                                defaultValue={this.state.policy.response} 
                                            />}
                                    </div>
                                </form>
                            </div>
                            <div className="text-right">
                                <button onClick={this.onSubmitHandler} type="button" className="m-3 col-sm-3 btn btn-lg btn-primary waves-effect waves-light">Add Oa policy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    failed: state.orgs.failed || false,
    oa_policy_list: ( state.oa_policy && state.oa_policy.oa_policy_list) || false,
    orgs: (state.orgs && state.orgs.org ) || false,
    oa_requirement_list: (state.oa_policy && state.oa_policy.oa_requirement_list) || false,
    journals : (state.journals && state.journals.journal_list && state.journals.journal_list.results)  || false,
    group: (state.auth && state.auth.group) || false,
    oa_policy_single: (state.oa_policy && state.oa_policy.oa_policy_single) || false
})
const mapDispatchToProps = (dispatch) => ({
    ClearStatus: () => dispatch(ClearStatus()),
    getOaPolicyAll: () => dispatch(getOaPolicyAll()),
    getOrgListAll: () => dispatch(getOrgListAll()),
    getJournalListAll: () => dispatch(getJournalListAll()),
    getOaRequirementAll: () => dispatch(getOaRequirementAll()),
    getPublicationByPublisher: (payload) => dispatch(getPublicationByPublisher(payload)),
    createOaPublisherPolicy: (payload) => dispatch(createOaPublisherPolicy(payload)),
    createOrganisationRequirement: (payload) => dispatch(createOrganisationRequirement(payload)),
    getOaPolicySingle: (payload) => dispatch(getOaPolicySingle(payload)),
    getOaRequirementSingle: (payload) => dispatch(getOaRequirementSingle(payload)),
})
export const AddOaPolicy = connect(mapStateToProps, mapDispatchToProps)(OaPolicy);

