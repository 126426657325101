import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../../core/components";

import ViewEmailModal from "./ViewEmailModal";
import {
  getFunders,
  getPublicatonTypeList,
  Emailtag,
  SingleEmailedit,
  deleteEmailMessage,
  getDefaultEmailMessage,
  getEmailMessageList,
  getEmailMessage,
} from "../../../../actions";
import {
  PopupBox,
  Button,
  SkeletonForm,
} from "../../../../../core/components/common";
// import { serialize } from "../../../../../core/lib";
import { confirmAlert } from "react-confirm-alert";
import { Select, Alert } from "antd";

const { Option } = Select;

class Email extends BaseComponent {
  state = {
    addnew: false,
    editEmailModal: false,
    viewEmailModal: false,
    msg: false,
    selectedTab: 0,
    default_email_message: {},
    selectedTag: "",
    showDialog: true,
    email_tag_list: [],
  };

 componentDidMount() {
    let filter = {
      group: this.props.group,
    };
    this.props.Emailtag(filter).then((res) => {
      let id = this.props.location && this.props.location.state;

      let results = res.results

      results.sort(function(a, b) {
        var labelA = a.label.toLowerCase();
        var labelB = b.label.toLowerCase();

        if (labelA < labelB) {
          return -1;
        }

        if (labelA > labelB) {
          return 1;
        }
      
        return 0;
      }) 

      if (id) {
        let tag = results && results.find((item) => item.id === id);
        this.setState({
          selectedTag: tag && tag.label,
        });
        this.getInitialEmailMessage(results);
      } else { 
        this.setState({
          selectedTag: results && results[0].label,
        });
        if (results.length > 0) {
          this.getInitialEmailMessage(results[0]);
        }
      }

      this.setState({
        email_tag_list: results,
      })
    });

    try {
      this.props.getPublicatonTypeList().then((res) => {
        this.setState({
          publicationTypeList: res.results,
        });
      });
    } catch (error) {
      this.setState({
        publicationTypeList: [],
      });
    }

    try {
      this.props.getFunders().then((res) => {
        this.setState({
          funderList: [{ id: "all", name: "All funders" }, ...res],
        });
      });
    } catch (error) {
      this.setState({
        funderList: [],
      });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  addEmailHandler = () => {
    let listData = {
      createEmailHandler: this.createEmailHandler,
      publicationTypeList: this.state.publicationTypeList,
      funderList: this.state.funderList,
      default_email_message: this.state.default_email_message,
      default_json:
        this.state.default_email_message &&
        this.state.default_email_message.json_body,
    };
    this.props.history.push({
      pathname: "/settings/create-email",
      state: listData,
    });
  };

  openViewEmailModal = () => {
    this.setState({
      viewEmailModal: true,
    });
  };

  closeViewEmailModal = () => {
    this.setState({
      viewEmailModal: false,
    });
  };
  onChange = (tag) => {
    let dataParsed = JSON.parse(tag);
    this.setState({
      selectedTag: dataParsed.label,
      tag_id: dataParsed.id,
    });
    this.getInitialEmailMessage(dataParsed);
  };

  getInitialEmailMessage = (tag) => {
    let payload = {
      tag_id: tag && tag.id,
      tag_name: tag && tag.name.toLowerCase(),
    };
    this.props.getDefaultEmailMessage(payload).then((res) => {
      this.setState({
        default_email_message: res,
      });
    });
    this.props.getEmailMessageList(payload).then((res) => {
      this.setState({
        email_messages: res.results,
      });
    });
  };

  renderEmailListTableRow = (data) => {
    let emailMessageRows =
      data &&
      data.map((emailMessage, index) => {
        return (
          <tr key={emailMessage.id}>
            <td>{emailMessage.title || ""}</td>
            <td>{emailMessage.description || ""}</td>

            <td className="text-center">
              <span>
                {emailMessage.is_deletable && (
                  <>
                    <span
                      className="cursor-pointer"
                      id="deleteBtn"
                      title="Delete"
                      onClick={() =>
                        this.removeEmailMessage(
                          emailMessage.id,
                          emailMessage.tag
                        )
                      }
                    >
                      <i className="mdi mdi-delete"></i>
                    </span>
                    |
                  </>
                )}

                {emailMessage.is_editable && (
                  <>
                    <span
                      className="cursor-pointer"
                      title="Edit"
                      onClick={() => this.editEmailMessage(emailMessage)}
                    >
                      <i className="mdi mdi-pen"></i>
                    </span>
                    |
                  </>
                )}
              </span>

              <span
                className="cursor-pointer"
                onClick={() => this.viewEmailMessage(emailMessage)}
                title="View"
              >
                <i className="mdi mdi-eye"></i>
              </span>
            </td>
          </tr>
        );
      });

    return emailMessageRows;
  };

  renderEmailListTable = (data) => {
    let emailListTables =
      data &&
      data.map((tag, index) => {
        return (
          <div
            className={`tab-pane p-3${this.state.selectedTab === index ? " active" : ""
              }`}
            id={`${tag.name.toLowerCase()}`}
            role="tabpanel"
            key={tag.id}
          >
            <div>
              <table
                className="table table-striped"
                style={{
                  borderCollapse: "collapse",
                  borderSpacing: 0,
                  width: "100%",
                }}
              >
                <thead className="text-white thead-dark">
                  <tr>
                    <th width="25%">Name</th>
                    <th width="60%">Description</th>
                    <th width="15%">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.email_messages ? (
                    this.state.email_messages &&
                    this.renderEmailListTableRow(this.state.email_messages)
                  ) : (
                      <tr>
                        <td>
                          <SkeletonForm rows={1} />
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        );
      });

    return emailListTables;
  };

  editEmailMessage = async (emailMessage) => {
    let data = await this.props.SingleEmailedit(emailMessage.id);
    let listData = {
      createEmailHandler: this.createEmailHandler,
      publicationTypeList: this.state.publicationTypeList,
      funderList: this.state.funderList,
      default_email_message: this.state.default_email_message,
      id: emailMessage.id,
      data,
    };
    if (data) {
      this.props.history.push({
        pathname: `/settings/update-email/${emailMessage.id}`,
        state: listData,
      });
    }
  };

  viewEmailMessage = (emailMessage) => {
    this.setState(
      {
        viewEmailData: emailMessage,
      },
      () => {
        this.openViewEmailModal();
      }
    );
  };

  removeEmailMessage = (id, tag) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this email?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.deleteEmailMessage(id).then((res) => {
              if (res.status) {
                this.setState({
                  deletedMsg: "Email deleted successfully",
                });

                let payload = {
                  tag_id: tag,
                };

                this.props.getEmailMessageList(payload).then((res) => {
                  this.setState({
                    email_messages: res.results,
                  });
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  onSubmit = (payload) => {
    this.props.createEmailMessage(payload).then((res) => {
      if (res.status) {
        let payload = {
          tag_id: this.state.email_tag_list[this.state.selectedTab].id,
        };

        this.setState({
          addnew: !res.status,
        });

        this.props.getEmailMessageList(payload).then((res) => {
          this.setState({
            email_messages: res.results,
          });
        });
      }
    });
  };

  cancelLoader = () => {
    this.setState({
      deletedMsg: false,
    });
  };

  handleClose = () => {
    this.setState({
      showDialog: false,
      isShowToolTip: false,
      button: true,
    });
  };

  alert = (value) => {
    this.setState({ showDialog: false, isShowToolTip: true });
  };
  render() {
    const { publicationTypeList, funderList, viewEmailData, email_tag_list } = this.state;

    let listData = {
      publicationTypeList,
      funderList,
    };

    return (
      <HubContent>
        {this.state.deletedMsg && (
          <PopupBox msg={this.state.deletedMsg} onCancel={this.cancelLoader} />
        )}

        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="vat-tax-button email">
                <Button
                  type="danger"
                  name="danger"
                  text="x"
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div className="email-setup">
                {this.state.showDialog ? (
                  <Alert
                    message="In order to view the default e-mails, please select the category from the drop-down menu. To create a  new email, please select the
                    relevant category from the drop down and click on “Create email” button."
                    closable
                    type="info"
                    showIcon
                    afterClose={this.handleClose}
                  />
                ) : null}
                {this.state.isShowToolTip ? (
                  <Alert
                    message="In order to view the default e-mails, please select the category from the drop-down menu. To create a  new email, please select the
                      relevant category from the drop down and click on “Create email” button."
                    closable
                    type="info"
                    showIcon
                    afterClose={this.handleClose}
                  />
                ) : null}
                <div className="useremail-right float-left col-lg-8">
                  <Select
                    value={this.state.selectedTag}
                    style={{ width: '100%', marginTop: 15, marginLeft: 25 }}
                    onChange={this.onChange}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {email_tag_list &&
                      email_tag_list.map((item, index) => (
                        <Option value={JSON.stringify(item)} key={item.id}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                </div>
                <div className="mb-3 m-t-15 float-left">
                  <div style={{ marginLeft: 15, marginTop: 5, float: "left" }}>
                    <Button
                      type="primary"
                      onClick={this.addEmailHandler}
                      name="primary"
                      text="Create Email"
                    />
                  </div>
                  {this.state.button ? (
                    <div
                      style={{ float: "left", marginLeft: 15, marginTop: 5 }}
                    >
                      <Button
                        type="secondary"
                        name="secondary"
                        onClick={this.alert}
                        text="info"
                        className="secondary-email"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="tab-content">
                  {email_tag_list &&
                    this.renderEmailListTable(email_tag_list)}

                  {this.state.viewEmailModal && (
                    <ViewEmailModal
                      listData={listData}
                      emailData={viewEmailData}
                      closeModal={this.closeViewEmailModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  email_tag: state.emailMessage.email_tag,
  email_message: state.emailMessage.email_message,
  email_messages: state.emailMessage.email_messages,
  default_email_message: state.emailMessage.default_email_message,
  publisher_list: state.orgs && (state.orgs.publisher_list || false),
  publication_types: state.journals.publication_type,
});

const mapDispatchToProps = (dispatch) => ({
  deleteEmailMessage: (id) => dispatch(deleteEmailMessage(id)),
  getEmailMessageList: (payload) => dispatch(getEmailMessageList(payload)),
  getEmailMessage: (payload) => dispatch(getEmailMessage(payload)),
  getDefaultEmailMessage: (payload) =>
    dispatch(getDefaultEmailMessage(payload)),
  getPublicatonTypeList: () => dispatch(getPublicatonTypeList()),
  getFunders: () => dispatch(getFunders()),
  Emailtag: (payload) => dispatch(Emailtag(payload)),
  SingleEmailedit: (payload) => dispatch(SingleEmailedit(payload)),
});

const EmailCommon = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Email)
);

export default EmailCommon;
