import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../../../../core/components';
import { getProgramListAll, getProgramCoverageAll, getAllCountry, getPubProgramSingle } from '../../../actions';
import { SelectBox, ModalSelectBox } from '../../../../core/components/common';
import { Checkbox } from 'antd';

var _ = require('lodash');



class Form extends BaseComponent {
    state = {
        program_data: {
            coverage: [],
            exclude_countries: (this.props.single_program && this.props.single_program.exclude_countries) || [],
            program: (this.props.single_program && this.props.single_program.program) || false,
            send_mail_to_author: (this.props.single_program && this.props.single_program.send_mail_to_author) || false
        },
        program_id: false,
    }

    filterByKeys = (obj, keys = []) => {
        const filtered = {}
        keys.forEach((key) => {
            if (obj.hasOwnProperty(key)) {
                filtered[key] = obj[key]
            }
        })
        return filtered;
    }

    async componentDidMount() {
        await this.props.getAllCountry();
        await this.props.getProgramListAll();
        this.props.plan && await this.props.getProgramCoverageAll({ id: this.props.plan.program });
        this.props.plan && this.setState((prevState) => {
            return Object.assign(prevState.program_data, this.props.plan);
        })
    }

    onChangeHandler = (value) => {
        let keys = Object.keys(value);
        let values = Object.values(value);
        if (keys[0] === 'program') {
            this.setState({
                program_id: values[0]
            })
            this.props.getProgramCoverageAll({ id: values[0] })
        }
        this.setState((prevState) => {
            return Object.assign(prevState.program_data, value);
        })
    }

    onSubmitHandler = async (e) => {
        e.preventDefault();
        let program = this.state.program_data;
        program = this.filterByKeys(program, ['program', 'coverage', 'exclude_countries', 'send_mail_to_author']);
        await this.props.onSubmitHandler(program)
    }

    onChange = (e) => {
        e.target.checked && this.setState((prevState) => {
            return Object.assign(prevState.program_data, { send_mail_to_author: e.target.checked });
        })
    }




    onModalSelectBoxShow = (fieldName) => {
        switch (fieldName) {
            case 'coverage':
                this.setState({
                    isShowCoverageModal: true,
                });
                break;
            case 'exclude_countries':
                this.setState({
                    isShowExcludeModal: true,
                });
                break;
            default:
                break;
        }
    }

    onModalSelectBoxClose = (e) => {
        this.setState({
            isShowCoverageModal: false,
            isShowExcludeModal: false
        });
    }

    showSelectItemsLabel = (fieldName) => {
        let fieldValues = this.state[fieldName];
        let labelText = '';
        switch (fieldName) {
            case 'coverage':
                if (fieldValues && fieldValues.length > 0) {
                    labelText = `${fieldValues.length} coverage selected`;
                } else {
                    labelText = `No coverage selected`;
                }
                break;
            case 'exclude_countries':
                if (fieldValues && fieldValues.length > 0) {
                    labelText = `${fieldValues.length} exclude countries selected`;
                } else {
                    labelText = `No exclude countries selected`;
                }
                break;
            default:
                break;

        }
        return labelText;
    }

    onSaveSelectedItems = (fieldName, data) => {
        this.setState((prevState) => {
            return Object.assign(prevState.program_data, { [fieldName]: data });
        })
    }

    render() {
        let countries = this.props.countries;
        let includes = this.state.program_data && this.state.program_data.coverage;
        let exclude_countries = includes && _.filter(countries, (v) => _.includes(includes, v.id));
        return (
            <div className="tab-content">
                <div className="tab-pane active p-3" id="home-1" role="tabpanel">
                </div>
                <form >
                    <div className="form-group row">
                        {this.props.program && this.props.plan &&
                            <SelectBox
                                label="Program"
                                onChange={this.onChangeHandler}
                                field='program'
                                data={this.props.program}
                                disabled={true}
                                defaultValue={this.state.program_data.program}
                            />
                        }
                        {this.state.program_data && this.state.program_data.coverage &&
                            <div className="col-sm-6" style={{ position: 'relative', }}>
                                {
                                    this.showSelectItemsLabel('coverage')
                                }
                                <button
                                    onClick={() => this.onModalSelectBoxShow('coverage')}
                                    className="btn btn-info waves-effect waves-light float-right"
                                    type="button"
                                >
                                    <i className="fa fa-plus"></i>
                                </button>
                            </div>
                        }
                        {this.state.isShowCoverageModal &&
                            <ModalSelectBox
                                title="Select coverage"
                                label="Coverage"
                                placeholder="Countries"
                                field='coverage'
                                className="col-sm-4"
                                data={this.props.coverage}
                                defaultValue={this.state.program_data.coverage}
                                isRequired={false}
                                submitBtnTxt="Save"
                                onSubmit={this.onSaveSelectedItems}
                                onClose={() => this.onModalSelectBoxClose()}
                            />
                        }

                    </div>
                    <div className="form-group row">
                        {
                            (
                                (
                                    exclude_countries && exclude_countries.length > 0
                                )
                                ||
                                (
                                    this.state.program_data && this.state.program_data.exclude_countries && this.state.program_data.exclude_countries.length > 0
                                )
                            ) && <div className="col-sm-6" style={{ position: 'relative', }}>
                                {
                                    this.showSelectItemsLabel('exclude_countries')
                                }
                                <button
                                    onClick={() => this.onModalSelectBoxShow('exclude_countries')}
                                    className="btn btn-info waves-effect waves-light float-right"
                                    type="button">
                                    <i className="fa fa-plus"></i>
                                </button>
                            </div>
                        }
                        {this.state.isShowExcludeModal && <ModalSelectBox
                            title="Select exclude countries"
                            label="Exclude"
                            field='exclude_countries'
                            className="col-sm-4"
                            data={exclude_countries}
                            defaultValue={this.state.program_data.exclude_countries}
                            isRequired={false}
                            submitBtnTxt="Save"
                            placeholder="Countries"
                            onSubmit={this.onSaveSelectedItems}
                            onClose={() => this.onModalSelectBoxClose()}
                        />}
                        <div className="col-sm-6" style={{ paddingTop: 7 }}>
                            <Checkbox defaultChecked={this.state.program_data.send_mail_to_author} onChange={this.onChange}>Send mail to author</Checkbox>
                        </div>
                    </div>
                    <div className="text-center m-t-15">
                        <button
                            className="btn btn-primary btn-md waves-effect waves-light"
                            onClick={this.onSubmitHandler}
                        >Update Program</button>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    countries: state.app.countries,
    coverage: (state.app && state.app.coverage && state.app.coverage.results) || false,
    program: (state.program && state.program.program_list && state.program.program_list.results) || false,
    single_program: (state.program && state.program.single_program) || false
})

const mapDispatchToProps = (dispatch) => ({
    getProgramListAll: () => dispatch(getProgramListAll()),
    getAllCountry: () => dispatch(getAllCountry()),
    getProgramCoverageAll: (payload) => dispatch(getProgramCoverageAll(payload)),
    getPubProgramSingle: (payload) => dispatch(getPubProgramSingle(payload)),

})
export const PubEditProgramForm = connect(mapStateToProps, mapDispatchToProps)(Form);