import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent } from "../../../../core/components";
import {
  SelectBox,
  DBCard,
  Loading,
  PaginationForm,
} from "../../../../core/components/common";
import { getInstitutionsList, getPubsByFiltering, getUnivByFiltering } from "../../../actions";
import { serialize } from "../../../../core/lib";

class Org extends BaseComponent {
  state = {
    filterParams: {},
    filteringField: "",
    count:0,
  };

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }
  getInstDataList =async (pageNum = 1, page = 10) => {
    let data = {
      filterParams: {
        publisher_deal_type: "all",
        category: "all",
        page: pageNum,
         page_size: 10,
      },
    };

    let filter = serialize(data.filterParams);
    await this.props.getPubsByFiltering({ filter });
    this.setState({
      filterParams: {
        page: pageNum,
      },
      count:this.props.institutions && this.props.institutions.count
    })
  };
  pageChange = (pageNumber) => {
    this.getInstDataList(pageNumber);
  };

 async componentDidMount() {
    if (this.props.type === "publisher") {
      this.setState({
        filteringField: "institution_deal_type",
        filterParams: {
          page:1
        }
        
      });
      let filterParams= {
        publisher_deal_type: "all",
        category: "all",
        page: 1,
        page_size: 10,
      }
      let filter = serialize(filterParams);
      await this.props.getPubsByFiltering({ filter });
      this.setState({
        
        count:this.props.institutions && this.props.institutions.count
      })
    } else if (this.props.type === "university") {
      this.setState({
        filteringField: "publisher_deal_type",
        filterParams: {
          page: 1,
  
        },
      });
      let  filterParams= {
        publisher_deal_type: "all",
        page: 1,
        page_size: 10,
      }
      let filter = serialize(filterParams);
      await this.props.getUnivByFiltering({ filter });
      this.setState({
        count:this.props.institutions && this.props.institutions.count
      })
    } else {
      this.props.getInstitutionsList()

    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}

  filterData = async (value) => {
    await this.setState((prevState) => {
      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });
    let filter = serialize(this.state.filterParams);
    if (this.props.type === "publisher") {
      this.props.getPubsByFiltering({ filter });
    } else if (this.props.type === "university") {
      this.props.getUnivByFiltering({ filter });
    }
  };

  render() {

    let category = [
      { name: "All types", id: "all" },
      { name: "Funder", id: "funder" },
      { name: "University", id: "university" },
      { name: "Research organisation", id: "research" },
      { name: "Consortia", id: "consortium" },
    ];
    let instList = this.props.institutions && this.props.institutions.results 
    let data = Array.isArray(instList) ? instList : []
 
    return (
      <HubContent title={this.props.name || "University"}>
        <div className="row mb-3">
          {this.props.type === "publisher" && (
         
              <SelectBox
                field="category"
                data={category}
                defaultValue="all"
                onChange={this.filterData}
              />
            
          ) }
        </div>

        {this.props.loading !== true ? (
          <>
          <div className="row institution" style={{ marginTop: 25 }}>
            {data && data.map((ins) => {
              return (
                <DBCard
                  key={ins.id}
                  className="col-md-4"
                  title={ins.name}
                  hasLink={this.props.baseUrl + "/" + ins.id}
                  body={[
                    "Pending " + ins.acceptance || 0,
                    "Approved " + ins.accepted || 0,
                    "Declined " + ins.declined || 0,
                  ]}
                />
              );
            })}
                       
            </div>
            {data && data.length === 0 && (
                <div className="card">
                  <div className="card-body">
                    <h6>No Data Found</h6>
                  </div>
                </div>
              )}
          {this.props.institutions && this.props.institutions.results && this.props.institutions.results.length>0 && 
            <PaginationForm
                pageChange={this.pageChange}
                current={this.state.filterParams.page}
                pageSize={10}
                count={this.state.count}
              />}
            </> 
        ) : (
          <Loading type="flat" />
          )}
          

      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  institutions: (state.institutions && state.institutions.institution_list )|| [],
  loading: state.institutions.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getPubsByFiltering: (payload) => dispatch(getPubsByFiltering(payload)),
  getUnivByFiltering: (payload) => dispatch(getUnivByFiltering(payload)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
});

export const Organizations = connect(mapStateToProps, mapDispatchToProps)(Org);
