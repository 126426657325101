import React from "react";
import { ArticleManual } from "./ArticleManual";
import BaseComponent from "../../../../../core/components";

class ArticleManualForm extends BaseComponent {
  render() {
    return <ArticleManual title="Article list" ArticleType="manual" />;
  }
}

export const ArticleManualPage = ArticleManualForm;
