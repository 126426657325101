import React, { Component } from 'react';
import { HubContent } from '../../../../../core/components';
import { Collapse, Button } from 'antd'
import styled from 'styled-components';



const { Panel } = Collapse;

class UnivesityHelpComponent extends Component {
    state = {
        questions: [
            {
                id: 1,
                value: "Institutional user guide",
                buttons: [
                    { pdfbtn: "User guide", link: "https://oametrix-video.s3.eu-west-2.amazonaws.com/university-user-guide/Institutional-user-guide.pdf" },
                ]
            },
        ]
    };

    render() {

        return (

            <HubContent>

                <div className="row">

                    {this.state.questions.map(ques =>
                        <div key={ques.id} className="col-md-6">
                            <Collapse className="my-3" accordion>
                                <Panel className="mb-2" header={ques.value}>
                                    <div className="buttons__area">
                                        {ques.buttons.map(btn => <span key={btn.link}>
                                            <a href={btn.link} target="_blank" rel="noopener noreferrer">
                                                <Button type="link" className="mx-2">
                                                    <PdfIcon className="mr-2"><i className="fas fa-file-pdf"></i></PdfIcon>
                                                    {btn.videobtn}

                                                    {btn.pdfbtn}

                                                    {btn.pptbtn}
                                                </Button>
                                            </a>
                                        </span>)}
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    )}




                </div>

            </HubContent>
        );
    }
}

export const UniversityHelpMenu = UnivesityHelpComponent;



const PdfIcon = styled.span`
    i.fa-file-pdf{
        color:#171a1d;
    }
`;

