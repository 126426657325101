import React from 'react';
import BaseComponent, { HubContent } from '../../../../../core/components';

class DB extends BaseComponent {
   
    render() {
        return (
            <HubContent title="OA policies">
                <h6>We are working with publishers, universities and funders to understand Open Access policies to integrate in OaMetrix.</h6>
            </HubContent>
        )
    }
}



export const OaPolicies = (DB)