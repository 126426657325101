import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import validator from "validator";
import CoAuthorArticle from "../../Articles/CoAuthorArticle";
import moment from "moment";
import {
  Badge,
  InputBox,
  SelectBox,
  OaDatePicker,
  PopupBox,
  InputNewBox
} from "../../../../../../core/components/common";
import { createBookChapter } from "../../../../../actions";
const BtnText = "Add Book Chapter";

class ChapterForm extends React.Component {
  state = {
    bookchapter: {},
    SubmitBtnText: BtnText,
    errMsg: false,
    isCoAuthor: false,
    isAuthor: false,
    edit_modal: false,
    co_authors: [],
    author: null,
    editing: false,
    isEmpty: false,
    loaded: false,
    contactTypes : [
      {
        id: 'customer_service',
        name: 'Customer service',
      },
      {
        id: 'editorial',
        name: 'Editorial',
      },
      {
        id: 'finance',
        name: 'Finance',
      },
      {
        id: 'production',
        name: 'Production',
      },
      {
        id: 'sales',
        name: 'Sales',
      }
    ],
    publication_contacts: [
      {
        contact_department: undefined,
        contact_name: undefined,
        contact_email: undefined,
      }
    ],
  };

  onChangeHandler = (value) =>
    this.setState((prevState) => Object.assign(prevState.bookchapter, value));
    removeEmpty = (obj) => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') this.removeEmpty(obj[key]);
        if (
          !obj[key] ||
          (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
        ) {
          delete obj[key];
        }
      });
      return obj;
    };
  onSubmitHandler = async (e) => {
    e.preventDefault();

    let data = Object.assign(this.state.bookchapter, {
      is_series: true,
      source_pub_type_name: "BOOK_SERIES",
      visible_to_publisher: true,
      publication_contacts: this.state.publication_contacts,

    });

    this.state.author && Object.assign(data, { author: this.state.author });
    Object.assign(data, { co_authors: this.state.co_authors });

    if (typeof data && data.author !== undefined) {
      this.removeEmpty(data && data.author)
    }
    data.co_authors && data.co_authors.map((item) => this.removeEmpty(item))

    if (data.acceptance_date) {
      Object.assign(data, {
        acceptance_date: moment(this.state.bookchapter.acceptance_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        acceptance_date: null
      });
    }

    if (data.submission_date) {
      Object.assign(data, {
        submission_date: moment(this.state.bookchapter.submission_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      Object.assign(data, {
        submission_date: null,
      });
    }

    if (!data.title || !data.content_type) {
      this.setState({
        errMsg: (
          <div>
            {!data.title && <p>Title cannot be empty!</p>}
            {!data.content_type && <p>Content name cannot be empty</p>}
            {data.doi && !validator.isURL(data.doi) && (
              <p>URL format is not correct</p>
            )}
            {data.author &&
              data.author.email &&
              !validator.isEmail(data.author.email) && (
                <p>Email format is not correct</p>
              )}
          </div>
        ),
      });
    } else {
      this.setState({
        errMsg: false,
      });
      let result = await this.props.createBookChapter(data);
      if (result.status === 201) {
        this.setState({
          success: true,
        });
      }
    }
  };
  redirectLink = () => {
    this.setState({
      success: false,
    });
    this.props.history.push("/article/publisher-articles");
  };
  cancelLoader = async () => {
    this.setState({
      SubmitBtnText: BtnText,
      errMsg: false,
      isEmpty: false,
    });
  };

  AddNewAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isAuthor: true,
    });
  };

  AddNewCoAuthor = (e) => {
    e.preventDefault();
    this.setState({
      isCoAuthor: true,
    });
  };

  onCloseAuthor = () => {
    this.setState({
      isCoAuthor: false,
      isAuthor: false,
      editing: false,
    });
  };

  onSaveCoAuthorHander = (author_info) => {
    this.setState((prevState) => {
      if (prevState.editing) {
        prevState.co_authors[prevState.editing] = author_info;
        prevState.editing = false;
      } else {
        prevState.co_authors.push(author_info);
      }
      prevState.isCoAuthor = false;
      return prevState;
    });
  };

  onSaveAuthorHander = (author_info) => {
    this.setState((prevState) => {
      prevState.author = author_info;
      prevState.isAuthor = false;
      return prevState;
    });
  };

  onEditAuthor = () => {
    this.setState({
      isAuthor: true,
    });
  };

  onDeleteAuthor = () => {
    this.setState({
      author: null,
    });
  };

  onEditCoAuthor = (id) => {
    this.setState({
      editing: id.toString(),
      isCoAuthor: true,
    });
  };

  onDeleteCoAuthor = (id) => {
    this.setState((prevState) => {
      prevState.co_authors.splice(id, 1);
      return prevState;
    });
  };
  addNewPubContact = () => {
    let publicationContacts = this.state.publication_contacts

    publicationContacts.push({
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
    })

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  removePubContact = (index) => { 
    let publicationContacts = this.state.publication_contacts

    publicationContacts.splice(index, 1)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }

  renderPublicationContact = (publicationContacts) => { 
    let initialObj = {
      contact_department: undefined,
      contact_name: undefined,
      contact_email: undefined,
    }

    if (!publicationContacts || !Array.isArray(publicationContacts)) {
      publicationContacts = [
        initialObj
      ]
    } else if (publicationContacts.length === 0) {
      publicationContacts.push(
        initialObj
      )
    }
    
    return publicationContacts.map((contact, index) => {
      return (
        <div className="form-group row" key={index}>
          <SelectBox
            labelInfo={true}
            labelInfoTitle="Select department"
            placeholder="Select department"
            label="Department"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_department"
            className="col-sm-4"
            labelClass="col-sm-2"
            data={this.state.contactTypes || []}
            defaultValue={contact.contact_department}
          />
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter contact name"
            placeholder="Enter contact name"
            label="Name"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_name"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_name}
          />
          <InputNewBox
            labelInfo={true}
            labelInfoTitle="Enter contact email"
            placeholder="Enter contact email"
            label="Email"
            onChange={(value) => this.onPubContactChangeHandler(value, index)}
            field="contact_email"
            className="col-sm-4"
            labelClass="col-sm-2"
            defaultValue={contact.contact_email}
          />
          <div 
            className="col-sm-1"
          >
              { index > 0 && <button 
                  type="button"
                  className="btn btn-danger mb-2"
                  onClick={() => this.removePubContact(index)}
                >
                  <i className="mdi mdi-close"></i>
                </button> }
            </div>
        </div>
      )
    })
  }

  onPubContactChangeHandler = (value, index) => { 
    let publicationContacts = this.state.publication_contacts
    Object.assign(publicationContacts[index], value)

    this.setState({
      publication_contacts: publicationContacts,
    })
  }
  render() {
    let {
      bookchapter,
      is_author_affiliation_match,
      is_eissn_match,
      is_pissn_match,
      is_publisher_name_match,
    } = this.state;

    return (
      <div className="">
        {this.state.errMsg && (
          <PopupBox
            title="Following field cannot be empty"
            msg={this.state.errMsg}
            onCancel={this.cancelLoader}
          />
        )}

        <>
          {this.state.success && (
            <PopupBox
              NoText="Close"
              msg="You have successfully entered a book chapter or a book article. You will find it in the Article & Book tab. If you cannot find it in the Article & Book Chapter tab, please check the Failed Transfer tab to rectify it."
              onCancel={this.redirectLink}
            />
          )}
        </>

        <div className="form-group row">
          <InputBox
            label="Article/Chapter"
            onChange={this.onChangeHandler}
            field="title"
            labelInfo={true}
            labelInfoTitle="Enter Article/Chapter Title"
            className="col-sm-9 col-lg-10"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Article/Chapter title"
            isRequired={true}
            defaultValue={bookchapter.title}
          />
        </div>

        <div className="form-group row">
          <InputBox
            label="Article id"
            labelInfo={true}
            labelInfoTitle="Enter Article id"
            onChange={this.onChangeHandler}
            field="article_id"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Article or document id"
            defaultValue={bookchapter.article_id}
          />

          <SelectBox
                     labelInfo={true}
                     labelInfoTitle="Select Article type "
            label="Article type"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            onChange={this.onChangeHandler}
            field="content_type"
            placeholder="Select Article type "
            data={this.props.content_type}
            isRequired={true}
            defaultValue={bookchapter.content_type}
          />
        </div>
        <div className="form-group row">
          <InputBox
            label="Article DOI"
            labelInfo={true}
            labelInfoTitle="Enter Article DOI"
            onChange={this.onChangeHandler}
            field="doi"
            className="col-sm-9 col-lg-10"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Doi"
            InputType="url"
            defaultValue={bookchapter.doi}
          />
        </div>

        <div className="form-group row mt-4">
          <div className="col-sm-12">
            <div className="float-left" style={{ marginRight: 20 }}>
              <h6>Add Author</h6>
            </div>
            <div className="float-left">
              <button
                className="btn btn-light btn-o-light"
                onClick={this.AddNewAuthor}
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="mdi mdi-plus"></i> Add Author
              </button>
            </div>
          </div>
        </div>

        {this.state.isAuthor && (
          <CoAuthorArticle
            counter_org={this.props.counter_org}
            isShow={this.state.isAuthor} // if true modal will show up else it will be hidden
            onClose={this.onCloseAuthor} // Handle Close
            onSaveAuthor={this.onSaveAuthorHander}
            fieldLabel="Author"
            value={this.state.author}
            is_author_affiliation_match={is_author_affiliation_match}
          />
        )}

        <div className="form-group row">
          {this.state.author && (
            <Badge
              label={
                !this.state.author.first_name && !this.state.author.last_name
                  ? "No Name"
                  : (this.state.author.first_name || "") +
                  " " +
                  (this.state.author.last_name || "")
              }
              onEdit={this.onEditAuthor}
              onDelete={this.onDeleteAuthor}
              style={is_author_affiliation_match === false ? styles.error : {}}
            />
          )}
        </div>
        {/* Author Information End */}

        <div className="form-group row">
          <InputBox
                     labelInfo={true}
                     labelInfoTitle="Enter  Name"
            label="Name"
            onChange={this.onChangeHandler}
            field="journal_name"
            className="col-sm-9 col-lg-10"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Name"
            defaultValue={bookchapter.journal_name}
          />
        </div>
        <div className="form-group row">
          <InputBox
            onChange={this.onChangeHandler}
            className="col-sm-9 col-lg-10"
            labelClass="col-sm-3 col-lg-2"
            field="vol_name"
            labelInfo={true}
            labelInfoTitle="Enter  Volume Name"
            label="Volume Name"
            placeholder="Enter Volume name"
          />
        </div>

        <div className="form-group row">
          <InputBox
            onChange={this.onChangeHandler}
            className="col-sm-4 mb-3 col-lg-5"
            labelClass="col-sm-4 col-lg-2"
            field="vol_number"
            label="Volume No/Year."
            labelInfo={true}
            labelInfoTitle="Enter Volume No/Year."
            placeholder="Enter Vol. #"
          />
          <InputBox
            onChange={this.onChangeHandler}
            field="vol_pub_year"
            labelInfo={true}
            labelInfoTitle="Enter Year."
            className="col-sm-4 col-lg-5"
            placeholder="Enter Year"
          />
          <InputBox
            label="Acronym/Site"
            onChange={this.onChangeHandler}
            field="journal_acronym"
            labelClass="col-sm-4 col-lg-2"
            className="col-sm-8  col-lg-10"
            placeholder="Enter Acronym"
            labelInfo={true}
            labelInfoTitle="Enter Acronym."
            defaultValue={bookchapter.journal_acronym}
          />
        </div>

        <div className="form-group row">
          <InputBox
            label="pISSN"
            onChange={this.onChangeHandler}
            field="pissn"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter pISSN"
            labelInfo={true}
            labelInfoTitle="Enter pISSN."
            defaultValue={bookchapter.pissn}
            style={is_pissn_match === false ? styles.error : {}}
          />
          <InputBox
            label="eISSN"
            onChange={this.onChangeHandler}
            field="eissn"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter eISSN"
            labelInfo={true}
            labelInfoTitle="Enter eISSN."
            defaultValue={bookchapter.eissn}
            style={is_eissn_match === false ? styles.error : {}}
          />
        </div>

        <div className="form-group row">
          <InputBox
            label="Publisher"
            onChange={this.onChangeHandler}
            field="publisher_name"
            labelInfo={true}
            labelInfoTitle="Enter Publisher."
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            placeholder="Enter Publisher"
            style={is_publisher_name_match === false ? styles.error : {}}
            defaultValue={bookchapter.publisher_name}
          />

          <InputBox
            label="Funder"
            onChange={this.onChangeHandler}
            field="funder_name"
            labelInfo={true}
            labelInfoTitle="Enter Funder."
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            defaultValue={bookchapter.funder_name}
            placeholder="Enter Funder"
          />
        </div>

        <div className="form-group row">
          <InputBox
            label="Grant Note"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            onChange={this.onChangeHandler}
            field="fund_acknowledgement"
            labelInfo={true}
            labelInfoTitle="Enter grant note"
            placeholder="Enter grant note"
            defaultValue={bookchapter.fund_acknowledgement}
          />
          <InputBox
            label="Grant number"
            className="col-sm-3 col-lg-4"
            labelClass="col-sm-3 col-lg-2"
            onChange={this.onChangeHandler}
            field="grant_number"
            labelInfo={true}
            labelInfoTitle="Enter grant number"
            placeholder="Enter grant number"
            defaultValue={bookchapter.grant_number}
          />
        </div>

        <div className="form-group row">
            <OaDatePicker
            labelInfo={true}
            labelInfoTitle="Select Submission date"
              label="Submission date"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="submission_date"
              placeholder="Select Submission date"
            />



   
            <OaDatePicker
            labelInfo={true}
            labelInfoTitle="Select Acceptance date"
              label="Acceptance date"
              className="col-sm-3 col-lg-4"
              labelClass="col-sm-3 col-lg-2"
              onChange={this.onChangeHandler}
              field="acceptance_date"
              placeholder="Select Acceptance date"
            />
          

        </div>
        {/* Article Information End */}

        <div className="form-group row mt-4">
          <div className="col-sm-12">
            <div className="float-left" style={{ marginRight: 30 }}>
              <h6>Add Co Author</h6>
            </div>
            <div className="float-left">
              <button
                className="btn btn-light btn-o-light"
                onClick={this.AddNewCoAuthor}
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i className="mdi mdi-plus"></i> Add Co-Author
              </button>
            </div>
          </div>
        </div>

        {/* Co Author Form Modal */}
        {this.state.isCoAuthor && (
          <CoAuthorArticle
            isShow={this.state.isCoAuthor}
            onClose={this.onCloseAuthor}
            onSaveAuthor={this.onSaveCoAuthorHander}
            fieldLabel="Co author"
            value={this.state.co_authors[this.state.editing]}
          />
        )}
        {/* Co Author Form Modal End */}

        <div className="form-group row">
          {this.state.co_authors.length > 0 &&
            this.state.co_authors.map((co_author, id) => {
              return (
                <div key={id}>
                  <Badge
                    label={
                      !co_author.first_name && !co_author.last_name
                        ? "No Name"
                        : (co_author.first_name || "") +
                        " " +
                        (co_author.last_name || "")
                    }
                    onEdit={() => this.onEditCoAuthor(id)}
                    onDelete={() => this.onDeleteCoAuthor(id)}
                  />
                </div>
              );
            })}
        </div>
        <div className="form-group row mt-2">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Publication contacts </label>
              </div>
              
            </div>
            <s style={{ borderBottom: "1px solid #375ba8", width: "100%" }}></s>

          </div>

          { this.renderPublicationContact(this.state.publication_contacts) }

          <div className="form-group row mt-4">
            <div className="col-sm-12">
              <div className="float-left">
                <label>Add Contacts </label>
              </div>
              
              <div style={{marginLeft:15,float:"left"}}>
                <button 
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={this.addNewPubContact}
                >
                  <i className="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
          </div>
        <div className="text-center m-t-15">
          <button
            type="button"
            className="btn btn-primary btn-md  waves-effect waves-light"
            onClick={this.onSubmitHandler}
          >
            {this.state.SubmitBtnText}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  publisher_name: state.auth.user && state.auth.user.organisation_name,
  content_type: state.journals.content_type ,
  publication_type: state.journals.publication_type ,
  article_status: state.articles.article_status ,
  status: state.articles.article_status
    ? state.articles.article_status.status
    : null,
});

const mapDispatchToProps = (dispatch) => ({
  createBookChapter: (payload) => dispatch(createBookChapter(payload)),
});

const styles = {
  error: { border: "1px solid red" },
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChapterForm)
);
