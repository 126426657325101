import React from 'react';
import BaseComponent from '../../../../../core/components/BaseComponent';
import { HubContent } from '../../../../../core/components';
import { SelectBox } from '../../../../../core/components/common';
class UAPCFUND extends BaseComponent {
    render() {
        return (
            <HubContent title=" ">
                <div className="row">
                    <div className="col-lg-10">
                        <div id="accordion">
                            <div className="card mb-1">
                                <div className=" p-3" id="headingOne">
                                    <h6 className="m-0 font-14">
                                        <a href="#collapseOne" className="text-dark" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
                                            APC and other charges
                                        </a>
                                    </h6>
                                    <br />
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <p>APC: 1200.00</p>
                                                    <p>Submission fee:100.00</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <p>Colour charge:200.00</p>
                                                    <p>Page & Other charge: 100.00</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <p>Discount%: 10</p>
                                                    <p>VAT%:  </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="container-fluid">
                                                <div className="row" style={{ borderTop: '2px solid #5378' }}>
                                                    <div className="col-sm-12">
                                                        <p>Total: 1200.00</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h6>APC Funding or invoice request</h6>
                                        <br />
                                    </div>

                                    <div className="form-group row">
                                        <SelectBox
                                            label="Select license type"
                                            onChange={this.onChangeHandler}
                                            field='User'
                                            data={this.props.roles}
                                            isRequired={false}
                                        />

                                        <SelectBox
                                            label="Funding/Payment"
                                            onChange={this.onChangeHandler}
                                            field='Institution'
                                            data={this.props.roles}
                                            isRequired={false}
                                        />
                                    </div>

                                    <div className="form-group row">
                                        <SelectBox
                                            label="Select your funder"
                                            onChange={this.onChangeHandler}
                                            field='Institution'
                                            data={this.props.roles}
                                            isRequired={false}
                                        />
                                        <SelectBox
                                            label="Funding/Payment"
                                            onChange={this.onChangeHandler}
                                            field='Institution'
                                            data={this.props.roles}
                                            isRequired={false}
                                        />
                                    </div>

                                    <div className="form-group row">
                                        <SelectBox
                                            label="email address"
                                            onChange={this.onChangeHandler}
                                            field='Institution'
                                            data={this.props.roles}
                                            isRequired={false}
                                        />
                                        <SelectBox
                                            label="Funding/Payment"
                                            onChange={this.onChangeHandler}
                                            field='Institution'
                                            data={this.props.roles}
                                            isRequired={false}
                                        />

                                    </div>

                                    <div className="col-sm-2">
                                        <p>
                                            <a href="#top">
                                                <button type="button" className="btn btn-primary btn-sm waves-effect" align="center" >submit</button>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

export const Uapcf = UAPCFUND;