import React from 'react';
import { connect } from 'react-redux'; 
import { PopupBox } from '../../../../../core/components/common';
import { HubContent } from '../../../../../core/components';
import { RefundRequestForm } from './RefundRequestForm'
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { createUpdateRefundRequest  } from '../../../../actions';


class Create extends BaseComponent {
    state = {
        date: false,
    }
    async componentDidMount() { 
        
    }

    onSubmitHandler = async (refundRequest) => {
        let newObject = {};

        Object.keys(refundRequest).forEach(function (key) {
            var value = refundRequest[key];
            newObject[key] = value;
        });

        if (!newObject.oa_deal || !newObject.requested_amount) {
            this.setState({
                isEmpty: <div>
                    {!newObject.oa_deal && <p>OA Deal must be selected</p>}
                    {!newObject.requested_amount && <p>Amount is required</p>}
                </div>
            })
        } else { 
            try { 
                
                await this.props.createUpdateRefundRequest(newObject);
                this.props.history.push('/refund-request/refund-request-list')
            } catch (error) {
                this.setState({
                    errorMessage: error.data && error.data.message
                });
            }
        }
    }

    cancelLoader = () => {
        this.setState({
            status: false,
            isEmpty: false,
            errorMessage: false,
        });
    }
    // help menu function 
  HelpButtonClick = () => {
    const url = '/links';
    window.open(url, '_blank');
    
  }
    render() { 
        
        let errorHandler = <>
            {this.state.isEmpty && <PopupBox
                msg={this.state.isEmpty}
                onCancel={this.cancelLoader}
            />}
        </>
        
        return (
            <HubContent title="Create refund request" onHelpHandler={ this.HelpButtonClick}>
                { errorHandler }
                {/* Row Start */}
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        {/* Card Start */}
                        <div className="card m-b-20">
                            {/* Card Body Start */}
                            <div className="card-body">
                                
                                <RefundRequestForm 
                                    onSubmit={this.onSubmitHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({ 
    user: state.auth.user,
    refund_request: state.RefundRequest.refund_request || false,
});

const mapDispatchToProps = (dispatch) => ({
    createUpdateRefundRequest: (payload) => dispatch(createUpdateRefundRequest(payload)),
});

export const CreateRefundRequest = connect(mapStateToProps, mapDispatchToProps)(Create)