import React, { Component } from "react";
import ViewVatTable from "../viewVatTable";

export default class Restview extends Component {
  render() {
    const {
      waive_countries_charge,
      vat_tax_other_countries_charge,
      vat_tax_other_countries_charge2,
      rest_billing_address,
      rest_billing_address2,
      other_countries_vat_tax_1,
      billingaddressCountry2,
      other_countries_vat_tax_2,
      billingaddressCountry,
      countrychargewaive,
    } = this.props;

    return (
      <div className="vat-detail-form">
       

        <div className="vat-question-panel">
          <p>
            You waive VAT for the following countries if customers have valid
            VAT Number :
            {waive_countries_charge === true ? (
              <span className="vat_text">Yes</span>
            ) : (
              <span className="vat_text">No</span>
            )}
          </p>
        </div>

        {waive_countries_charge === true && (
          <div>
            <div className="vat-country">
              <div className="vat-country-details"></div>
              <div className="col-12 vat-margin">
                <ViewVatTable data={countrychargewaive} field="REST" />
              </div>
            </div>

            <div className="vat-question-panel">
              <p>
                You charge VAT for the following Countries regardless customers
                provide you valid VAT number or not:
                {vat_tax_other_countries_charge === true ? (
                  <span className="vat_text">Yes</span>
                ) : (
                  <span className="vat_text">No</span>
                )}
              </p>
            </div>
          </div>
        )}

        {waive_countries_charge === false && (
          <div className="vat-question-panel">
            <p>
              there any other countries that you would like to add VAT or Sales
              Tax:{" "}
              {vat_tax_other_countries_charge2 === true ? (
                <span className="vat_text">Yes</span>
              ) : (
                <span className="vat_text">No</span>
              )}
            </p>
          </div>
        )}
        {waive_countries_charge !== true
          ? vat_tax_other_countries_charge2 === true && (
              <div>
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable
                      data={other_countries_vat_tax_2}
                      field="RESTOFTHEWORLD"
                    />
                  </div>
                </div>

                <div className="vat-question-panel">
                  <p>
                    You charge VAT for the following countries' customers based
                    on their billing address.
                    {rest_billing_address === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              </div>
            )
          : null}

        {waive_countries_charge !== false
          ? vat_tax_other_countries_charge === true && (
              <div>
                <div className="vat-country">
                  <div className="col-12 vat-margin">
                    <ViewVatTable
                      data={other_countries_vat_tax_1}
                      field="RESTOFTHEWORLD"
                    />
                  </div>
                </div>

                <div className="vat-question-panel">
                  <p>
                    You charge VAT for the following countries' customers based
                    on their billing address.
                    {rest_billing_address2 === true ? (
                      <span className="vat_text">Yes</span>
                    ) : (
                      <span className="vat_text">No</span>
                    )}
                  </p>
                </div>
              </div>
            )
          : null}
        {waive_countries_charge !== false ? (
          vat_tax_other_countries_charge !== false ? (
            rest_billing_address2 === true ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <ViewVatTable
                    data={billingaddressCountry}
                    field="RESTOFTHEWORLD"
                  />
                </div>
              </div>
            ) : null
          ) : null
        ) : null}
        {waive_countries_charge !== true ? (
          vat_tax_other_countries_charge2 !== false ? (
            rest_billing_address === true ? (
              <div className="vat-country">
                <div className="col-12 vat-margin">
                  <div className="card m-b-20">
                    <ViewVatTable
                      data={billingaddressCountry2}
                      field="RESTOFTHEWORLD"
                    />
                  </div>
                </div>
              </div>
            ) : null
          ) : null
        ) : null}
      </div>
    );
  }
}
