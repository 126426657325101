import React from 'react';
import { connect } from 'react-redux';
import { DataTable, HubContent } from '../../../../../core/components';
import { RoleContext } from '../../../../../core/context/hub/RoleContext';
import { getRoleList } from '../../../../actions';
import { Loading } from '../../../../../core/components/common';

class RL extends RoleContext {
    state = {
        roles: false
    }
    componentDidMount() {
        this.props.getRoleList();
    }
    
    onArticleDownloadClick = async () => {
        // try {
        //   let params = await serialize(this.state.params);
        //   let filter = await serialize(this.state.filterParams);
        //   await this.props.articleDownload({ params, filter });
        //   const url = window.URL.createObjectURL(this.props.article_download_data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   var d = new Date();
    
        //   link.setAttribute(
        //     "download",
        //     `article-full_${moment(d).format("YYYYMMDD_HHmmss")}.xlsx`
        //   );
        //   document.body.appendChild(link);
        //   link.click();
        // } catch (error) {
        
        // }
      };
    render() {
        const heading = ['name', 'is_active'];
        const data = this.props.roles;
        data && data.map((item) => {
            return Object.assign(item, { is_active: 'Active' });
        })
        return (
            <HubContent title="Role List" onArticleDownloadClickHandler={this.onArticleDownloadClick}>
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            {data ?
                                <DataTable
                                    heading={heading}
                                    data={data}
                                    noAction={true}
                                    count={(data && data.length)}
                                /> : <Loading />}
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}


const mapStateToProps = (state) => ({
    roles: state.app.roles
})

const mapDispatchToProps = (dispatch) => ({
    getRoleList: () => dispatch(getRoleList()),
})

export const RoleList = connect(mapStateToProps, mapDispatchToProps)(RL);

