import React from 'react';
import { connect } from 'react-redux';
import BaseComponent, { HubContent } from '../../../../../core/components';
import { DepositForm } from './DepositForm'
import { DepositPubFund } from '../../../../actions'
import { PopupBox } from '../../../../../core/components/common';

// This Component is onfigured By Shahan
class Fund extends BaseComponent {
    state = {
        error: null
    }
    onSubmit = async (deposit) => {
        await this.props.DepositPubFund(deposit);
        if (!this.props.deposit_request) this.props.history.push('/publisher-fund/deposit-list')
    }
    render() {
        const status = this.props.deposit_request && this.props.deposit_request.status;
        let ErrorHandler = true;
        if (status) {
            if (status === 500) {
                ErrorHandler = <PopupBox
                    title="Network Error"
                    msg="Please check your internet connection. If you think internet connection is ok then feel free to contact us"
                />
            } else if (status === 404) {
                ErrorHandler = <PopupBox
                    title="Data Not Found"
                    msg="Something Error. Please contact with admin"
                />
            } else if (status === 400) {
                ErrorHandler = <PopupBox
                    title="Field Incorrect"
                    msg="You may type some incorrect data"
                />
            } else if (status) {
                ErrorHandler = <PopupBox
                    title="Unknown Error"
                    msg="You may type some incorrect data"
                />
            } else {
                ErrorHandler = false
            }
        }
        return (
            <HubContent title="Deposit fund">
                {ErrorHandler}
                {/* Row Start */}
                <div className="row justify-content-center">
                    {/* <div className="col-lg-12">
                        <div className="alert alert-warning d-flex justify-content-between align-items-center">
                            <span>New Oa Deal Comes</span>
                            <button className="btn btn-warning ">Approve</button>
                        </div>
                    </div> */}
                    <div className="col-lg-10">
                        {/* Card Start */}
                        <div className="card m-b-20">
                            {/* Card Body Start */}
                            <div className="card-body">
                                <ul className="nav nav-pills nav-justified" role="tablist">
                                    <li className="nav-item waves-effect waves-light">
                                        <a className="nav-link active" data-toggle="tab" href="#home-1" role="tab">Deposit form</a>
                                    </li>
                                </ul>
                                <DepositForm 
                                    onSubmit={this.onSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </HubContent>
        )
    }
}

const mapStateToProps = (state) => ({
    deposit_request: state.deposit.deposit_request
})

const mapDispatchToProps = (dispatch) => ({
    DepositPubFund: (payload) => dispatch(DepositPubFund(payload)),
})

export const DepositFund = connect(mapStateToProps, mapDispatchToProps)(Fund);
