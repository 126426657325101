import config from "../../config";
import { api } from "../../core/api";
import { serialize } from "../../core/lib";

export const getQuicksightLogList = (payload = {}) => {
  let url = `${config.endpoint.quicksight_log}`;
  let params = {};

  if (payload.pageNum && payload.pageSize) {
    params["page"] = payload.pageNum;
    params["page_size"] = payload.pageSize;
  } else if (payload.pageNum) {
    params["page"] = payload.pageNum;
  } else if (payload.pageSize) {
    params["page_size"] = payload.pageSize;
  }

  if (!params.page_size) {
    params["page_size"] = 99999;
  }

  url += `?${serialize(params)}`;

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then((res) => {
        resolve(Object.assign(res, { status: true }));
      })
      .catch((e) => {
        reject(e);
      });
  });
};

