import React from 'react';
import { BaseComponent } from '../../../../../core/components/BaseComponent';
import { Organizations } from '../../_multiaccess';



class Pubs extends BaseComponent {
  render() {
    return (
      <Organizations
        name="Publishers"
        baseUrl="/publishers"
        type="university"
      />
    )
  }
}


export const UniPubs = Pubs