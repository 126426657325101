import React from "react";
import Iframe from "react-iframe";

import { connect } from "react-redux";
import AppSettings from "../../../../__base__/AppSettings";
import { HubContent } from "../../../../core/components";
import { WhitePublisherCard } from "../../common/Card";
import { DBChart, SelectBox } from "../../../../core/components/common";
import Card from "../../../../core/components/common/cardUnV.jsx";
import {
  getPremiumDashboardData,
  getDashboardData,
  getoaDealFilterList,
  getInstitutionsList,
  getChildOrganisationList,
  getArticlePublisher,
} from "../../../actions";
import { PrimaryPublisherCard } from "../publisher/components/PrimaryPublisherCard";
import NewOAdeals from "../publisher/components/NewOAdeals";
import ReadPublishList from "../publisher/components/ReadPublishList";
import { serialize } from "../../../../core/lib";

const apcAverageStyle = {
  // height: 130,
  width: 140,
  backgroundColor: "white",
  marginTop: 10,
  fontSize: 14,
  justifyContent: "center",
};

class DB extends AppSettings {
  state = {
    filterParams: {},
    showPop: false,
    onClose: false,
    executed: false,
    hideSaveBtn: false,
    hideTab: false,

  };

  HandleClick = () => {
    this.setState({
      showPop: !this.state.showPop,
      onClose: !this.state.onClose,
    });
  };
  HandleOnClose = () => {
    this.setState({
      showPop: false,
      onClose: false,
    });
  };

  defaultCurrency() {
    return ["£ 0.00", "$ 0.00", "€ 0.00"];
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  apcAverageCalculation = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [0.0, 0.0, 0.0];
    }

    let gbp = component.data.gbp
      ? parseFloat(component.data.gbp).toFixed(2)
      : 0.0;
    let usd = component.data.usd
      ? parseFloat(component.data.usd).toFixed(2)
      : 0.0;
    let eur = component.data.eur
      ? parseFloat(component.data.eur).toFixed(2)
      : 0.0;

    return [usd, eur, gbp];
  };

  currencyCalculation = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return ["£ 0.00", "$ 0.00", "€ 0.00"];
    }

    let gbp =
      "£ " +
      this.numberWithCommas(
        component.data.gbp ? parseFloat(component.data.gbp).toFixed(2) : 0.0
      );
    let usd =
      "$ " +
      this.numberWithCommas(
        component.data.usd ? parseFloat(component.data.usd).toFixed(2) : 0.0
      );
    let eur =
      "€ " +
      this.numberWithCommas(
        component.data.eur ? parseFloat(component.data.eur).toFixed(2) : 0.0
      );

    return [gbp, usd, eur];
  };
  async componentDidMount() {
    await this.props.getChildOrganisationList();
    await this.props.getArticlePublisher();
    await this.props.getoaDealFilterList();

    this.getDashboardData(
      (this.props.dashboardcontrol &&
        this.props.dashboardcontrol.dashboards &&
        this.props.dashboardcontrol.dashboards.DEFAULT) || 'BASIC'
    );

  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  pieChartCalculation = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    return Object.values(component.data);
  };

  getLabels = (component) => {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let data = Object.keys(component.data);
    return data.map((item) => this.capitalize(item.replace(/_/g, " ")));
  };

  chartCalculation(component) {
    if (component && component.status_code && component.status_code !== 200) {
      return [];
    }

    let keys = ["funder", "journal", "publisher"];
    let values = [];
    if ("items" in component) {
      let data = component.items ? component.items : [];
      data.map((item) => {
        let ObjectKeys = Object.keys(item);
        let key = this.common(ObjectKeys, keys).toString();
        if (key === "funder") {
          values.push(item.total_funding);
        } else if (key === "publisher") {
          values.push(item.total_publish);
        } else {
          values.push(item.total);
        }
        return item;
      });
      return values;
    } else {
      return "data" in component ? Object.values(component.data) : [];
    }
  }

  filterData = async (value) => {
    await this.setState((prevState) => {
      return (prevState.filterParams = Object.assign(
        prevState.filterParams,
        value
      ));
    });
    let filter = serialize(this.state.filterParams);
    this.props.getDashboardData({ filter });
  };
  capitalize(s) {
    return s ? s[0].toUpperCase() + s.slice(1) : "";
  }

  chartfunderType = (data) => data && data.map((item) => item.total_funding);
  chartfunderLabels = (data) =>
    data && data.map((item) => this.capitalize(item.funder));

  chartPublishersType = (data) =>
    data && data.map((item) => item.total_publish);
  chartPublishersLabels = (data) =>
    data && data.map((item) => item.publisher.name);


  onChangeSwitch = async (checked) => {
    this.setState(
      {
        executed: checked,
        hideTab: checked !== true ? true : false,
      },
      async () => {
        let dashboardType = this.state.executed === true ? 'PREMIUM' : 'BASIC';

        this.getDashboardData(dashboardType);
      }
    );
  };
  getDashboardData = async (dashboardType) => {
    try {
      this.setState({
        executed: dashboardType === 'PREMIUM',
      });

      await this.props.getPremiumDashboardData(dashboardType);

      if (this.props.dashboardcontrol
        && this.props.dashboardcontrol.dashboards
        && this.props.dashboardcontrol.dashboards.DEFAULT !== dashboardType
        && this.props.dashboardcontrol.dashboards.OTHERS.length > 0
      ) {
        let authData = this.props.dashboardcontrol;
        let dashboards = Object.assign({}, authData.dashboards);
        let others = dashboards.OTHERS;

        others = others.filter(item => item !== dashboardType);

        others.push(dashboards.DEFAULT);

        Object.assign(authData.dashboards, {
          DEFAULT: dashboardType,
          OTHERS: others,
        });

        localStorage.setItem("auth", JSON.stringify(authData));
      }

      if (dashboardType === 'PREMIUM') {
        this.setState({
          dashBoardURL: this.props.dashbaordPremiumData.data,
          success: this.props.dashbaordPremiumData.success,
        });
      }
    } catch (error) {
    }
  }

  render() {

    let { publisher_list, child_orgs_list } = this.props;
    let organisations = [
      {
        name: "All affiliated organisations",
        id: "all",
      },
    ];

    let publishers = [
      {
        name: "All publishers",
        id: "all",
      },
    ];

    let year = [
      {
        name: "Years",
        id: "all",
      },
    ];

    function oaDeal(input) {
      var output = [];

      for (var index in input) {
        output.push({
          id: index,
          name: input[index],
        });
      }

      return output;
    }

    for (let i = 2018; i <= 2050; i++) {
      year.push({
        name: i,
        id: i,
      });
    }

    organisations = child_orgs_list && organisations.concat(child_orgs_list);
    publishers =
      publisher_list.results && publishers.concat(publisher_list.results);
      let manage_dashboard = this.props.dashboardcontrol &&  this.props.dashboardcontrol.permissions && this.props.dashboardcontrol.permissions.find(item=>item==="manage_dashboard") 
   
      let manage_premium_dashboard =this.props.dashboardcontrol &&  this.props.dashboardcontrol.permissions && this.props.dashboardcontrol.permissions.find(item=>item==="manage_premium_dashboard") 
    return (
      <HubContent
      manage_premium_dashboard={
        manage_premium_dashboard
        
        }
        manage_dashboard={manage_dashboard}

        hideTab={this.state.hideTab}
        executed={this.state.executed}
        onChangeSwitch={this.onChangeSwitch}
        dashboardcontrol={true}
        article={this.props.dashbaordData.estimated_cost_summary_component}
        article_title="Pending articles"
        article_income_title="Estimated cost"
        className="un-m15"
      >
        {this.state.executed !== true ? (
       
       manage_dashboard==="manage_dashboard"? <div>
            
        <div className="row mb-4">
          <SelectBox
            className="col-md-3"
            data={oaDeal(
              this.props.oadeal_filter && this.props.oadeal_filter.data
            )}
            onChange={this.filterData}
            field="filter_deal"
            defaultValue="All"
          />
          <SelectBox
            className="col-md-3"
            data={publishers}
            onChange={this.filterData}
            field="filter_publisher"
            defaultValue="all"
          />
          <SelectBox
            className="col-md-3"
            data={organisations}
            onChange={this.filterData}
            field="filter_child_organisation"
            defaultValue="all"
          />
          <SelectBox
            className="col-md-3"
            data={year}
            onChange={this.filterData}
            field="filter_year"
            defaultValue="all"
          />


        </div>
     
          <div>
            <div className="row" style={{ marginBottom: 30 }}>
              <PrimaryPublisherCard
                icon="mdi mdi-cube-outline"
                title="AVAILABLE FUNDS"
                body={
                  this.props.available_fund
                    ? this.currencyCalculation(this.props.available_fund)
                    : this.defaultCurrency()
                }
              />
              <PrimaryPublisherCard
                icon="mdi mdi-buffer"
                title="Total deposit"
                body={
                  this.props.total_deposit
                    ? this.currencyCalculation(this.props.total_deposit)
                    : this.defaultCurrency()
                }
              />
              <PrimaryPublisherCard
                icon="mdi mdi-tag-text-outline"
                title="OA spent"
                body={
                  this.props.oa_spent
                    ? this.currencyCalculation(this.props.oa_spent)
                    : this.defaultCurrency()
                }
              />
              <PrimaryPublisherCard
                icon="mdi mdi-cube-outline"
                title="Card and Invoice"
                body={
                  this.props.institution_payment_invoice_component
                    ? this.currencyCalculation(
                      this.props.institution_payment_invoice_component
                    )
                    : this.defaultCurrency()
                }
              />
            </div>
            <div className="row" style={{ marginBottom: 30 }}>
              <div className="col-md-9">
                <div className="row">
                  {this.props.article_summary && (
                    <DBChart
                      onClick={(e) => this.HandleClick(e)}
                      showpop={this.state.showPop}
                      onClose={(e) => this.HandleOnClose(e)}
                      titleIcon="info-circle"
                      title="Articles"
                      showtitle="This doughnut chart represents all the articles are pending for approval, approved and
declined for APC."
                      type="doughnut"
                      width={220}
                      height={220}
                      containerHeight={320}
                      chartMarginTopClassName="m-t-40"
                      className="col-md-5"
                      labels={this.getLabels(this.props.article_summary)}
                      data={this.pieChartCalculation(this.props.article_summary)}
                    />
                  )}

                  <ReadPublishList
                    onClick={(e) => this.HandleClick(e)}
                    showpop={this.state.showPop}
                    onClose={(e) => this.HandleOnClose(e)}
                    showtitle="This table represents the total amount for Read &amp; Publish deal, the number of articles
approved and their average APC."
                    data={
                      this.props.read_and_publish_deal &&
                      this.props.read_and_publish_deal.data
                    }
                    className="col-md-7 read_publish no_background color margin"
                    titleIcon="info-circle"
                  />
                </div>
              </div>
              <div className="col-md-3 credit-paid">
                <div className="row">
                  <WhitePublisherCard
                    icon="mdi mdi-cube-outline"
                    title="Credit amount"
                    component_class="pub_credit_amount"
                    body={
                      this.props.credit_due_data
                        ? this.currencyCalculation(this.props.credit_due_data)
                        : this.defaultCurrency()
                    }
                  />
                  <WhitePublisherCard
                    onClick={(e) => this.HandleClick(e)}
                    showpop={this.state.showPop}
                    onClose={(e) => this.HandleOnClose(e)}
                    showtitle="This represents that amount you have paid to publishers under credit OA deal."
                    titleIcon="info-circle"
                    icon="mdi mdi-buffer"
                    title="Credit paid"
                    body={
                      this.props.credit_paid_data
                        ? this.currencyCalculation(this.props.credit_paid_data)
                        : this.defaultCurrency()
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: 30 }}>
              <div className="col-md-12">
                <div className="row">
                  {this.props.institution_book_summary_component && (
                    <DBChart
                      title="Books"
                      type="pie"
                      onClick={(e) => this.HandleClick(e)}
                      showpop={this.state.showPop}
                      onClose={(e) => this.HandleOnClose(e)}
                      titleIcon="info-circle"
                      showtitle="This doughnut chart represents all the books are pending for approval, approved and
declined for APC."
                      chartMarginTopClassName="m-t-40"
                      labels={this.getLabels(
                        this.props.institution_book_summary_component
                      )}
                      data={
                        this.props.institution_book_summary_component &&
                        Object.values(
                          this.props.institution_book_summary_component.data
                        )
                      }
                      height={220}
                      containerHeight={320}
                      className="col-md-4"
                      isShowLabel={false}
                      grid={false} // Should Implement
                      titleClass="mb-4"
                    />
                  )}

                  <DBChart
                    height={220}
                    containerHeight={320}
                    title="Funders"
                    showtitle="This chart represents the top five funders for the approved books and articles."
                    titleIcon="info-circle"
                    onClick={(e) => this.HandleClick(e)}
                    showpop={this.state.showPop}
                    onClose={(e) => this.HandleOnClose(e)}
                    chartMarginTopClassName="m-t-40"
                    className="col-md-4"
                    type="pie"
                    labels={this.chartfunderLabels(
                      this.props.institution_funder_component
                        ? this.props.institution_funder_component &&
                        this.props.institution_funder_component.data.items
                        : null
                    )}
                    data={this.chartfunderType(
                      this.props.institution_funder_component
                        ? this.props.institution_funder_component &&
                        this.props.institution_funder_component.data.items
                        : null
                    )}
                  />

                  <DBChart
                    chartMarginTopClassName="m-t-40"
                    height={220}
                    onClick={(e) => this.HandleClick(e)}
                    showpop={this.state.showPop}
                    onClose={(e) => this.HandleOnClose(e)}
                    showtitle="This chart represents the top three publishers approved books and journal articles."
                    titleIcon="info-circle"
                    containerHeight={320}
                    title="Publishers"
                    className="col-md-4"
                    type="doughnut"
                    labels={this.chartPublishersLabels(
                      this.props.institution_publisher_component
                        ? this.props.institution_publisher_component &&
                        this.props.institution_publisher_component.data.items
                        : null
                    )}
                    data={this.chartPublishersType(
                      this.props.institution_publisher_component
                        ? this.props.institution_publisher_component &&
                        this.props.institution_publisher_component.data.items
                        : null
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: 30 }}>
              {this.props.open_access && (
                <DBChart
                  title="Journals"
                  showtitle="This represents total articles approved by journal type."
                  onClick={(e) => this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e) => this.HandleOnClose(e)}
                  type="bar"
                  titleIcon="info-circle"
                  chartMarginTopClassName="m-t-40"
                  labels={this.getLabels(this.props.open_access)}
                  data={
                    this.props.open_access &&
                    Object.values(this.props.open_access.data)
                  }
                  height={220}
                  containerHeight={320}
                  className="col-md-4"
                  isShowLabel={false}
                  grid={false} // Should Implement
                  titleClass="mb-4"
                />
              )}

              {this.props.average_apc && (
                <Card
                  showtitle="This represents average APC for all the articles that have approved against all the OA deals
(Pre-payment, Read &amp; Publish and Credit)."
                  onClick={(e) => this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e) => this.HandleOnClose(e)}
                  title="Average APC"
                  height={320}
                  titleIcon="info-circle"
                  icon="mdi mdi-buffer"
                  style={{ backgroundColor: "#fff" }}
                  className="col-md-4 no_background"
                  color="color"
                >
                  <div className="row">
                    <div className="col mt-3">
                      <div
                        className="d-flex align-items-center text-center mx-auto"
                        style={apcAverageStyle}
                      >
                        £ {parseFloat(this.props.average_apc.data.gbp).toFixed(2)}
                      </div>
                    </div>
                    <div className="col mt-3">
                      <div
                        className="d-flex align-items-center text-center mx-auto"
                        style={apcAverageStyle}
                      >
                        $ {parseFloat(this.props.average_apc.data.usd).toFixed(2)}
                      </div>
                    </div>
                    <div className="col mt-3">
                      <div
                        className="d-flex align-items-center text-center mx-auto"
                        style={apcAverageStyle}
                      >
                        € {parseFloat(this.props.average_apc.data.eur).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </Card>
              )}
              {this.props.token_summary && (
                <DBChart
                  showtitle="This pie chart represents the total number of tokens given to the publisher(s), how many
tokens have been used and available tokens."
                  onClick={(e) => this.HandleClick(e)}
                  showpop={this.state.showPop}
                  onClose={(e) => this.HandleOnClose(e)}
                  titleIcon="info-circle"
                  chartMarginTopClassName="m-t-40"
                  className="col-md-4"
                  title="Tokens"
                  type="pie"
                  height={220}
                  labels={this.getLabels(this.props.token_summary)}
                  data={this.pieChartCalculation(this.props.token_summary)}
                />
              )}
            </div>
            <div className="row" style={{ marginBottom: 30 }}>
              <WhitePublisherCard
                showtitle="This represents the total offset fund available given by publisher (s)."
                onClick={(e) => this.HandleClick(e)}
                showpop={this.state.showPop}
                onClose={(e) => this.HandleOnClose(e)}
                className="col-md-4"
                titleIcon="info-circle"
                icon="mdi mdi-briefcase-check info-circle"
                title="Offset"
                body={
                  this.props.offset_summary
                    ? this.currencyCalculation(this.props.offset_summary)
                    : this.defaultCurrency()
                }
              />
              <WhitePublisherCard
                showtitle="This represents the total offset fund given to the publisher(s)."
                onClick={(e) => this.HandleClick(e)}
                showpop={this.state.showPop}
                onClose={(e) => this.HandleOnClose(e)}
                title="Allocated offset"
                className="col-md-4"
                icon="mdi mdi-buffer "
                titleIcon="info-circle"
                body={
                  this.props.credit_payment_deposit
                    ? this.currencyCalculation(this.props.credit_payment_deposit)
                    : this.defaultCurrency()
                }
              />
              <WhitePublisherCard
                showtitle="This represents the total offset fund spent for approving articles(s)."
                onClick={(e) => this.HandleClick(e)}
                showpop={this.state.showPop}
                onClose={(e) => this.HandleOnClose(e)}
                titleIcon="info-circle"
                icon="mdi mdi-briefcase-check"
                title="Spent offset"
                className="col-md-4"
                body={
                  this.props.offset_summary
                    ? this.currencyCalculation(this.props.offset_summary)
                    : this.defaultCurrency()
                }
              />
            </div>
            <div className="row" style={{ marginBottom: 30 }}>
              <NewOAdeals
                onClick={(e) => this.HandleClick(e)}
                showpop={this.state.showPop}
                showtitle="This shows three recent new OA deals that you made."
                onClose={(e) => this.HandleOnClose(e)}
                className="col-md-4 padding_reduce"
                data={this.props.new_deals}
                title="New deals"
                isStyle={true}
                titleIcon="info-circle"
                university={true}
              />
            </div>
            </div>
          </div>:<div className="card">
            <div className="card-body">
              
            {this.state.executed !== true && <h6>Please slide right to view the dashboard.</h6>}
      </div>
  </div> ):  manage_premium_dashboard === "manage_premium_dashboard" && this.state.success && this.state.dashBoardURL ? (
            <Iframe
              url={this.state.dashBoardURL}
              width="100%"
              height="800px"
              id="myId"
              frameBorder={0}
              className="myClassname"
              display="initial"
              position="relative"
              allow="fullscreen"
            />):null}
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth || false,
  data: state.dashboard || false,
  dashbaordData: state.dashboard || false,
  available_fund: state.dashboard.available_fund_component || false,
  total_deposit: state.dashboard.total_deposit_component || false,
  oa_spent: state.dashboard.oa_spent_component || false,
  credit_due_data: state.dashboard.credit_due_component || false,
  credit_paid_data: state.dashboard.credit_paid_component || false,
  offset_summary: state.dashboard.available_offset_component || false,
  article_summary:
    state.dashboard.institution_article_summary_component || false,
  token_summary: state.dashboard.institution_token_summary_component || false,
  read_and_publish_deal: state.dashboard.read_and_publish_deal_component,
  average_apc: state.dashboard.average_apc_component || false,
  new_deals: state.dashboard.new_deals_component
    ? state.dashboard.new_deals_component.data
    : false,
  open_access: state.dashboard.oa_journal_component || false,
  institute_list:( state.institutions &&  state.institutions.institution_list) || [],

  institution_book_summary_component:
    state.dashboard.institution_book_summary_component || false,
  child_orgs_list: state.app.organizations || false,
  publisher_list: state.orgs.publisher_list || false,
  institution_publisher_component:
    state.dashboard.institution_publisher_component || false,
  institution_funder_component:
    state.dashboard.institution_funder_component || false,
  institution_payment_invoice_component:
    state.dashboard.institution_payment_invoice_component || false,
  oadeal_filter: state.institutions.oadeal_filter,
  dashboardcontrol: state.auth,
  dashbaordPremiumData: state.dashboard.premium_dashboard || false,

});


const mapDispatchToProps = (dispatch) => ({
  getDashboardData: (payload) => dispatch(getDashboardData(payload)),
  getInstitutionsList: () => dispatch(getInstitutionsList()),
  getArticlePublisher: () => dispatch(getArticlePublisher()),
  getoaDealFilterList: () => dispatch(getoaDealFilterList()),
  getPremiumDashboardData: (payload) =>
    dispatch(getPremiumDashboardData(payload)),
  getChildOrganisationList: (payload) =>
    dispatch(getChildOrganisationList(payload)),
});

export const UnivDashboard = connect(mapStateToProps, mapDispatchToProps)(DB);
