import React from "react";
import BaseComponent from "../../../../../core/components";


class WhitePublisherCard extends BaseComponent {
  state = {
    showpop: false,
    onClose: false,
  };

  render() {
    let icon = "";
    if (this.props.icon) {
      icon = (
        <div className="mini-stat-icon2">
          <i
            className={`${this.props.icon} float-right`}
            style={{
              fontSize: "18px",
              width: "45px",
              height: "45px",
              lineHeight: "45px",
              textAlign: "center",
              color: "#375ba8",
              borderRadius: "50%",
              background: "rgba(88, 39, 140, 0.1)",
              display: "inline-block",
            }}
          ></i>
        </div>
      );
    }
    const props = this.props;
    return (
      <div className={`${props.className ? props.className : "col-xl-12"}`}>
        <div className="card mini-stat2 bg-white">
          <div
            className={`card-body mini-stat-img ${
              props.padding ? props.padding : ""
            }`}
          >
            {icon}
            <div className="">
      
              <h6 className="mb-4">{props.title}</h6>
       

              {typeof props.body === "object" ? (
                props.body.map((content) => {
                  return (
                    <p className="mb-1" key={content.toString()}>
                      {content}
                    </p>
                  );
                })
              ) : (
                <p className="mb-1">{props.body}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhitePublisherCard;
