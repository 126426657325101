import React from "react";
import { connect } from "react-redux";
import { approveAuthorRefundRequest } from "../../../../actions"
import { InputBox, TextBox, OaDatePicker } from "../../../../../core/components/common";

import AddHocEmailModel from "./AddHocEmailModel"
class ExpandTable extends React.Component {
    state = {
        isDecline: false
    };

    setActive = (action) => {
        this.setState({
            active: !this.state.active,
            action
        });
    };

    onChangeHandler = (value) => {
        this.setState({ ...value })
    }
    isDeclineOpen = (value) => {
        this.setState({
            isDecline: !this.state.isDecline
        })
        this.onSubmit(value)
    }
    onSubmit = (action) => {
        let data = {
            // date_created: this.state.date_created,
            action: this.state.action,
            article: this.props.item.article,
            currency: this.props.item.currency,
            approved_amount: Number(this.props.item.requested_amount),
            approved_note: this.state.approved_note
        }
        this.props.approveAuthorRefundRequest(this.props.item.id, data)
    }
    render() {

        const { item, column } = this.props;
        return (
            <div className="data-table">
                {this.state.isDecline && <AddHocEmailModel msg="you are not eligible for refund" elgible={true} />}
                <div className="row-table">
                    <div className="row table-data">
                        <div className={column[2]}>
                            {item && item.article_info && item.article_info.title}
                        </div>

                        <div className={column[1]}>
                            {item && item.publisher_name && item.article_info.publisher_name.name}
                        </div>

                        <div className={column[1]}>
                            -                        </div>
                        <div className={column[1]}>
                            -
                        </div>
                        <div className={column[1]}>
                            -
                        </div>

                        <div className={column[0]}>


                            <a
                                onClick={() => this.setActive("approve")}
                                className="article_open_arrow ss"
                                href={`#collapse_${item.id}`}
                                data-toggle="collapse"
                                aria-expanded="true"
                                aria-controls={`collapse_${item.id}`}
                                style={{ color: "#5874ab", fontWeight: 500, textDecoration: "underline" }}
                            >
                                Refund

                            </a>
                            <a href="/"

                                onClick={() => this.isDeclineOpen("cancel")}
                                style={{ color: "#5874ab", fontWeight: 500, textDecoration: "underline" }}
                            >
                                Decline

                            </a>
                            <a
                                href="/"
                                onClick={() => this.onSubmit("reject")}
                                style={{ color: "#5874ab", fontWeight: 500, textDecoration: "underline" }}
                            >
                                Reject

</a>


                        </div>
                    </div>

                    <div
                        id={`collapse_${item.id}`}
                        className={`collapse ${false && "show"}`}
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                    >
                        <div
                            className="row table-expand"
                            style={{ paddingTop: 10, paddingBottom: 20 }}
                        >
                            <div className="col-md-12">
                                <div className="form-group row">

                                    <OaDatePicker
                                        field="date_created"
                                        className="col-sm-10"
                                        label="Date"

                                        onChange={this.onChangeHandler}
                                        showTime={false}
                                        format="YYYY-MM-DD"
                                    />
                                </div>

                                <div className="form-group row">
                                    <InputBox
                                        label="Amount"
                                        onChange={this.onChangeHandler}
                                        className="col-sm-10"
                                        field="approved_amount"
                                        placeholder="Enter Amount"
                                        disabled={true}
                                        defaultValue={this.props.item.requested_amount}
                                        isRequired={true}
                                        InputType="number"
                                    />
                                </div>

                                <div className="form-group row">
                                    <TextBox
                                        rows={8}
                                        label="Message"
                                        onChange={this.onChangeHandler}
                                        field="approved_note"
                                        className="col-sm-10"
                                        placeholder="Enter your email message"
                                        defaultValue={this.state.approved_note}
                                        isRequired={true}
                                    />
                                </div>



                                <div className="text-center m-t-15">
                                    <button type="button" onClick={this.onSubmit} className="btn btn-primary waves-effect waves-light"

                                    >Submit</button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({

    refund_request_action: state.refund_request_action
});

const mapDispatchToProps = (dispatch) => ({
    approveAuthorRefundRequest: (id, paylaod) => dispatch(approveAuthorRefundRequest(id, paylaod)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpandTable);
