import React from "react";
import { connect } from "react-redux";
import BaseComponent from "../../../../../core/components";
import EmailCommon from "./EmailCommon";

class AddInstitution extends BaseComponent {

  render() {

    return (
      <EmailCommon group="institution" />
    );
  }
}



const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({

})

export const Institution = connect(mapStateToProps, mapDispatchToProps)(AddInstitution);