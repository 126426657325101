import {
    SUCCESS_PUBLICATION_APC_WAIVER_CONFIG_LIST,
    REQUEST_PUBLICATION_APC_WAIVER_CONFIG_LIST,
    FAIELD_PUBLICATION_APC_WAIVER_CONFIG_LIST,

    GET_PUBLICATION_APC_WAIVER_CONFIG,
    UPLOAD_PUBLICATION_APC_WAIVER_CONFIG,
    CREATE_UPDATE_PUBLICATION_APC_WAIVER_CONFIG,
    DELETE_PUBLICATION_APC_WAIVER_CONFIG,
} from "../../config";

const PublicationApcWaiverConfigReducer = (state = {}, action) => {
    switch (action.type) {
        case SUCCESS_PUBLICATION_APC_WAIVER_CONFIG_LIST:
            return state = {
                ...state,
                isLoading: false,
                publication_apc_waiver_configs: action.payload
            }
        case REQUEST_PUBLICATION_APC_WAIVER_CONFIG_LIST:
            return state = {
                ...state,
                isLoading: true,
            }
        case FAIELD_PUBLICATION_APC_WAIVER_CONFIG_LIST:
            return state = {
                ...state,
                isLoading: false,
                publication_apc_waiver_configs: action.payload
            }

        case GET_PUBLICATION_APC_WAIVER_CONFIG:
            return state = {
                ...state,
                publication_apc_waiver_config: action.payload
            }
        case UPLOAD_PUBLICATION_APC_WAIVER_CONFIG:
            return state = {
                ...state,
                publication_apc_waiver_config_file_upload: action.payload,
            }
        case CREATE_UPDATE_PUBLICATION_APC_WAIVER_CONFIG:
            return state = {
                ...state,
                publication_apc_waiver_config: action.payload
            }
        case DELETE_PUBLICATION_APC_WAIVER_CONFIG:
            return state = {
                ...state,
            }
        default:
            return state;
    }
}

export default PublicationApcWaiverConfigReducer;
