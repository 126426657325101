import { ERROR_MESSAGE,RESET_PASSWORD } from "../../config";

const CommonReducer = (state = {}, action) => {
    switch (action.type) {
        case ERROR_MESSAGE: 
            return state = {
                ...state,
                error_message: action.payload
            }
        case RESET_PASSWORD: 
            return state = {
                ...state,
                auth: action.payload
            }
        default:
            return state;
    }
}
export default CommonReducer;
