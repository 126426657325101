import React from "react";
import { connect } from "react-redux";
import BaseComponent, { HubContent, } from "../../../../../core/components";
import { EditForm } from "../../../partials";
import {
  ClearStatus,
  UpdateUser,
  getSingleUser,
} from "../../../../actions";
import { PopupBox, Loading,Button } from "../../../../../core/components/common";

class editUserForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.error = props.failed;
    this.status = 100;
  }
  state = {
    user: false,
    roleList: false,
    groupList: false,
    isRegistering: false,
    msg: false,
    loader: false,
    status: false,
    isEmpty: false,
  };

  async componentDidMount() {
    let user;
    const userId = this.props.match.params.id;
    user = await this.props.getSingleUser(userId);

    let permissions = user && user.permissions;

    let userGroupKey =
      user &&
      user.permissible_objects &&
      user.permissible_objects.filter(
        (item) => permissions && permissions.includes(item.key)
      );

    delete user.request_source;
    //delete user.organisation_name;
    delete user.group_name;
    delete user.role_name;
    this.setState({
      user,
      permissions,
      userGroupKey,
    });
  }

  componentDidUpdate() {
    this.state.msg &&
      setTimeout(() => {
        this.setState({
          msg: false,
        });
      }, 2000);

    if (this.props.failed) {
      if (this.props.failed.status === 500 && this.state.status !== 500) {
        this.setState({
          status: 500,
        });
      } else if (
        this.props.failed.status === 400 &&
        this.state.status !== 400
      ) {
        this.setState({
          status: 400,
        });
      }
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };

}
  onSubmitHandler = async (user) => {
    if (
      user.email &&
      user.first_name &&
      user.last_name &&
      user.role &&
      user.organisation &&
      user.group
    ) {
      this.setState({
        isRegistering: true,
        msg: false,
      });
      const data = Object.assign(user, { request_source: "hub" });

      if (!data.user_meta) {
        data.user_meta = {}
      }

      const new_user = await this.props.UpdateUser(data);
      if (new_user.status === 201 || new_user.status === 200) {
        this.props.history.push(this.props.auth ==="PUBLISHER"?"/pub-user/user-list" : this.props.auth ==="HUB"? "/user/user-list": "/users/user-list");

        this.setState({
          isRegistering: false,
          msg: "User Updated",
          user: {},
        });
      } else {
        this.setState({
          msg: "Timeout",
        });
      }
    } else {
      this.setState({
        isEmpty: (
          <div>
            {!user.email && <p>Email Cannot Be Empty!</p>}
            {!user.first_name && <p>Firstname Cannot Be Empty</p>}
            {!user.last_name && <p>Lastname Cannot Be Empty</p>}
            {!user.role && <p>Role Cannot Be Empty</p>}
            {!user.group && <p>Group Cannot Be Empty</p>}
            {!user.organisation && <p>Organization Cannot Be Empty</p>}
          </div>
        ),
      });
    }
  };

  cancelLoader = async () => {
    this.setState({
      isRegistering: false,
      status: false,
      isEmpty: false,
    });
    await this.props.ClearStatus();
  };

  render() {
    let errorHander = (
      <>
        {this.state.status === 400 && (
          <PopupBox
            title="Invalid form"
            msg="You submitted the form is not valid. Please Check Your Form and Try again"
            onCancel={this.cancelLoader}
          />
        )}
        {this.state.status === 500 && (
          <PopupBox
            title="Network Error Found"
            msg="Please Check Internet Connection and Try Again"
            onCancel={this.cancelLoader}
          />
        )}

        {this.state.isEmpty && (
          <PopupBox
            Title="Following field cannot be empty"
            msg={this.state.isEmpty}
            onCancel={this.cancelLoader}
          />
        )}

        {this.state.msg && (
          <PopupBox
            Title={this.state.msg}
            msg={this.state.msg}
            onCancel={this.cancelLoader}
          />
        )}
      </>
    );

    let { user, permissions } = this.state;

    return (
      <HubContent title={`${user.first_name || ""} ${user.last_name || " "} `}>
        {/* ErroR handling */}
        {errorHander}
        {/* ErroR Handling */}

        {/* LoadinG Handling */}
        {this.state.isRegistering && !this.state.status && <Loading />}
        {/* LoadinG Handling */}

        <div className="row">
          <div className="col-lg-12">

            <div className="card m-b-20">
              <div className="card-body">
              <div className="vat-tax-button email">
                                <Button
                                    type="danger"
                                    name="danger"
                                    text="x"
                                    onClick={() => this.props.history.goBack()}
                                />
                            </div>
                <ul className="nav nav-pills nav-justified" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#home-1"
                      role="tab"
                    >
                      Update User
                    </a>
                  </li>
                </ul>

                {user && (
                  <EditForm
                    id={this.props.match.params.id}
                    onSubmitHandler={this.onSubmitHandler}
                    msg={this.state.msg}
                    user={user}
                    permissions={permissions}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </HubContent>
    );
  }
}

const mapStateToProps = (state) => ({
  failed: state.user.failed || false,
  auth:state.auth.group

});

const mapDispatchToProps = (dispatch) => ({
  UpdateUser: (payload) => dispatch(UpdateUser(payload)),
  ClearStatus: () => dispatch(ClearStatus()),
  getSingleUser: (id) => dispatch(getSingleUser(id)),
});

export const EditUser = connect(mapStateToProps, mapDispatchToProps)(editUserForm);
